import { useCallback, useContext, useRef, forwardRef } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import * as api from '@api';
import * as API from '@api/interfaces';
import { useSelectGroup } from '@containers/Store';
import { GroupContactsQueryContext } from '@containers/Group.Contacts/Context';
import styles from './style/Leads.css';

type Props = {
  children:  React.ReactNode;
  disabled?: boolean;
  onClose:   () => void;
};

export const Import = ({ onClose, ...props }: Props) => {
  const query = useContext(GroupContactsQueryContext);
  const ref = useRef<HTMLInputElement>();
  const mutation = useImportContactsMutation({
    onSuccess: () => {
      query.refetch();
    },
  });

  const handleClick = useCallback(() => {
    onClose();
    ref.current.value = '';
    ref.current.click();
  }, [onClose]);

  return (
    <div>
      <button
        className={styles.btn}
        disabled={props.disabled}
        onClick={handleClick}>
        {props.children}
      </button>
      <FileInput
        onInput={mutation.mutate}
        ref={ref} />
    </div>
  );
};

Import.displayName = 'Group.Contacts.Table.Toolbar.Action.Import';

type FileInputProps = {
  onInput: (file: File) => unknown;
};

const FileInput = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    props.onInput(file);

  }, [props]);

  return (
    <input
      autoComplete="off"
      accept=".csv"
      ref={ref}
      type="file"
      onChange={handleChange}
      style={{ display: 'none' }} />
  );
});

const useImportContactsMutation = (options: ImportContacts.Options = {}) => {
  const group = useSelectGroup();

  const mutation = useMutation([`post:groups/contacts/import`, group.id], (file: File) => {
    return api.groups.contacts.upload({
      file,
      groupId: group.id,
    });
  }, {
    onError: e => {
      console.log('Error', e);
    },
    onSuccess: () => {
      console.log('success');
    },
    ...options,
  });

  return mutation;
};

namespace ImportContacts {
  export type Variables = File;

  export type Options =
    UseMutationOptions<
      API.Groups.Contacts.Upload.Response,
      unknown,
      Variables>;
}
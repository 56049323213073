import styles from './style/Base.css';

type Props = {
  children:   React.ReactNode;
  className?: string;
};

const ButtonSet = ({ className = '', ...props }: Props) => {
  return (
    <div className={`${styles.btns} ${className}`}>
      {props.children}
    </div>
  );
};

export { ButtonSet };
export default ButtonSet;
import { Product, SurveyQuestion } from '@/types';
import { ProductItem } from 'components/AutoComplete/Product';

export type QuestionTaggingItemProps = {
  canEdit: boolean;
  editing: boolean;
  item: SurveyQuestion;
  toggleEditingOn: () => unknown;
}

export type EditableQuestionTaggingProps = {
  item: SurveyQuestion;
  onClick: () => unknown;
}

export type QuestionTaggingBuilderProps = {
  canSave: boolean;
  item: SurveyQuestion;
  onCancel: () => unknown;
  onSubmit: () => unknown;
}

export type OptionsTaggingBuilderProps = {
  item: SurveyQuestion;
}

export enum FormTagValueState {
  Selected = 1,
  NotSelected = 2,
}

export enum FormTagRelationship {
  UserOfProduct = 1,
  NotUserOfProduct = 2,
}

export type TagRowOnChangeItem = {
  valueState: FormTagValueState;
  relationship: FormTagRelationship;
  entity: ProductItem;
};

export type TagRowProps = {
  valueState: FormTagValueState;
  relationship: FormTagRelationship;
  entity: ProductItem;
  onChange: (item: TagRowOnChangeItem) => unknown;
}
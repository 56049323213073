import { useHasConsultantRole } from '@containers/User';
import { AccountDeletion, AccountReport } from 'components/Settings';
import { ContactInformation } from './Settings.ContactInfo';
import styles from './style/Settings.Account.css';

export const AccountSettings = () => {

  const hasConsultantRole = useHasConsultantRole();

  return (
    <>
      <ContactInformation />
      {hasConsultantRole &&
        <AccountReport className={styles.btn} />}
      {hasConsultantRole &&
        <AccountDeletion />}
    </>
  );
};

export default AccountSettings;
import axios from 'axios';
import http from '@services/http';
import { Conferences as C } from './interfaces';

export function createChatToken(data: C.CreateChatToken): Promise<C.CreateChatTokenResponse> {
  return http.post(`/conferences/chat-token`, data);
}

export function getNotes(data: C.GetConferenceNotes): Promise<C.GetConferenceNotesResponse> {
  return http.get(`/conferences/${data.callId}/notes`);
}

export function updateNotes(data: C.UpdateConferenceNotes): Promise<C.UpdateConferenceNotesResponse> {
  const { callId, ...rest } = data;
  return http.put(`/conferences/${callId}/notes`, rest);
}

const xhr = axios.create({
  baseURL: process.env.BACKEND_BASE_URL,
  withCredentials: true,
});

export function getConferenceEntry({ conferenceIdentifier }: C.GetConferenceEntry.Request): Promise<C.GetConferenceEntry.Response> {
  return xhr.get(`/conferences/${conferenceIdentifier}/entry`).then(({ data }) => data);
}
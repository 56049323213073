import { Helmet } from 'react-helmet';
import { TopicLogo } from '@presentation/Topic.Logo';
import { Button } from 'components/Topic/Following.Button';
import styles from './style/Topic.css';

type Props = {
  item: {
    name: string;
    logo: string;
  };
  following: boolean;
  onFollow: () => unknown;
};

export const Header = ({ item, following, onFollow }: Props) => {
  return (
    <div className={styles.root}>
      <Helmet title="Topic" />
      <div className={styles.left}>
        <TopicLogo
          className={styles.logo}
          url={item.logo} />
        <div>
          <div className={styles.name}>
            {item.name}
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div>
          <Button
            following={following}
            onClick={onFollow} />
        </div>
      </div>
    </div>
  );
};

Header.displayName = 'Topic.Header';
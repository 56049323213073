import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { WorkspaceObjectType } from '@enums';
import { getFileNameVersion } from '@utils';
import { ExistingFilesModal } from 'components/WorkspaceFiles/ExistingFilesModal';
import { ExistingFilesMap, RenamedFilesMap, UploadFilesParams } from '../Context';

type Props = {
  onUpload: (data: UploadFilesParams) => unknown;
};

const selectGetChildFiles = (state: Store.State) => (parentObjectId: number) => {
  return state.objects.ids
    .map(id => state.objects.values[id])
    .filter(f => f.parentObjectId === parentObjectId &&
      f.typeId === WorkspaceObjectType.File);
};

export const useExistingFilesCheck = ({ onUpload }: Props) => {
  const [dupeFiles, setDupeFiles] = useState<File[]>([]);
  const dupeFilesMap = useRef<ExistingFilesMap>({});
  const pendingFiles = useRef<File[]>([]);
  const params = useRef<Omit<UploadFilesParams, 'files'>>();

  const getChildFiles = useSelector(selectGetChildFiles);

  const findExistingFile = useCallback((name: string, parentObjectId: number) => {
    const children = getChildFiles(parentObjectId);
    return children.find(s => s.name === name);
  }, [getChildFiles]);

  const check = useCallback(({ files, ...other }: UploadFilesParams) => {
    const {
      dupesMap,
      dupeValues,
    } = files.reduce((acc, file) => {
      const existing = findExistingFile(file.name, other.parentObjectId);

      if (existing) {
        return {
          dupesMap: {
            ...acc.dupesMap,
            [file.name]: existing.entityId,
          },
          dupeValues: [...acc.dupeValues, file],
        };
      }  else {
        return acc;
      }
    }, {
      dupesMap: {} as ExistingFilesMap,
      dupeValues: [] as File[],
    });

    if (!dupeValues.length) {
      onUpload({
        files,
        ...other,
      });
    } else {
      setDupeFiles(dupeValues);
      pendingFiles.current = files;
      params.current = other;
      dupeFilesMap.current = dupesMap;
    }

  }, [
    findExistingFile,
    onUpload,
  ]);

  const getRenamedFiles = useCallback(() => {
    const children = getChildFiles(params.current.parentObjectId);

    return dupeFiles.reduce((acc, file) => {

      let version = 1;
      let newName = getFileNameVersion(file.name, version);
      while (children.some(s => s.name === newName)) {
        version = version + 1;
        newName = getFileNameVersion(file.name, version);
      }

      return {
        ...acc,
        [file.name]: newName,
      };
    }, {} as RenamedFilesMap);
  }, [
    dupeFiles,
    getChildFiles,
  ]);

  const closeModal = useCallback(() => {
    pendingFiles.current = [];
    setDupeFiles([]);
    dupeFilesMap.current = {};
    params.current = null;
  }, []);

  const handleModalConfirmation = useCallback((updateExisting: boolean) => {
    if (updateExisting) {
      onUpload({
        files: pendingFiles.current,
        ids: dupeFilesMap.current,
        ...params.current,
      });
    } else {

      onUpload({
        files: pendingFiles.current,
        names: getRenamedFiles(),
        ...params.current,
      });
    }

    closeModal();
  }, [
    closeModal,
    getRenamedFiles,
    onUpload,
  ]);

  const Modal = useCallback(() => {
    return (
      <ExistingFilesModal
        files={dupeFiles}
        onClose={closeModal}
        onConfirm={handleModalConfirmation}
        open={!!dupeFiles.length} />
    );
  }, [
    closeModal,
    dupeFiles,
    handleModalConfirmation,
  ]);

  return [check, Modal] as const;
};

export default useExistingFilesCheck;
import { useCallback, useMemo } from 'react';
import { CloseRow, OpenedResponseOptionRow, ResponsesDataRow } from '@presentation/ProjectSurveyResponses';
import { formatPlurality, formatNumber, roundToPlace } from '@utils';
import { NumberInputTableQuestion } from '@/types';
import * as chart from '@containers/SurveyResponses/utils';
import { NumberInputTable } from './interfaces';
import ResponseOptionUser from './ResponseOptionUser';
import { useGetNumberInputTableBucketUsers } from './hooks/number-input-table';
import styles from './style/NumberInputTable.Data.css';

type Props = {
  colors?: string[];
  bucket: NumberInputTable.Bucket;
  item: NumberInputTableQuestion.Question;
  onClick: (rowId: number, optionId: number) => void;
  onReset: () => void;
  optionId: number;
  rowId: number;
  responses: NumberInputTableQuestion.Aggregate.Data;
};

export const NumberInputTableBucketData = ({ colors = chart.options.OptionsColors, bucket, item, onClick, onReset, optionId, rowId, responses }: Props) => {

  const getBucketUsers = useGetNumberInputTableBucketUsers();

  const users = useMemo(() => {
    return getBucketUsers(rowId, optionId)(bucket);
  }, [
    bucket,
    getBucketUsers,
    optionId,
    rowId,
  ]);

  const row = useMemo(() => {
    return item.matrixRows.find(f => f.base.id === rowId);
  }, [
    item.matrixRows,
    rowId,
  ]);

  const option = useMemo(() => {
    return item.options.find(f => f.base.id === optionId);
  }, [
    item.options,
    optionId,
  ]);

  const optionSubtitle = useMemo(() => {
    const avg = responses[rowId].options[optionId].avg;

    return `(Avg: ${formatNumber(roundToPlace(avg, 1), 1)})`;
  }, [
    optionId,
    rowId,
    responses,
  ]);

  const goBack = useCallback(() => {
    onClick(rowId, optionId);
  }, [
    optionId,
    rowId,
    onClick,
  ]);

  const rendeBucketRow = useCallback(() => {
    const count = Object.keys(users).length;

    const label = `[${bucket.minValue} - ${bucket.maxValue})`;
    const subtitle = `(${count} ${formatPlurality('respondent', count)})`;

    return (
      <ResponsesDataRow
        className={styles.bucketRow}
        label={label}
        subtitle={subtitle}>
        <CloseRow
          onClick={goBack} />
      </ResponsesDataRow>
    );
  }, [
    bucket,
    goBack,
    users,
  ]);

  return (
    <>
      <div className={styles.row}>{row.value}</div>
      <OpenedResponseOptionRow
        color={colors[option.ordinal - 1]}
        label={option.value}
        subtitle={optionSubtitle}
        onClose={onReset} />
      {rendeBucketRow()}
      {Object.keys(users)
        .sort((a, b) => users[+a] - users[+b])
        .map(userId => (
          <ResponseOptionUser
            key={userId}
            className={styles.user}
            value={users[+userId].toString()}
            userId={+userId} />
        ))}
    </>
  );
};

export default NumberInputTableBucketData;
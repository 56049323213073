import { MatrixGridQuestion, MatrixMultiselectQuestion } from '@/types';
import MatrixRows from './MatrixRows';
import Options from './Options';
import styles from './style/MatrixQuestion.css';

type Props = {
  item: MatrixGridQuestion.Question | MatrixMultiselectQuestion.Question;
};

export const MatrixQuestion = ({ item }: Props) => {
  return (
    <>
      <div className={styles.header}>Rows</div>
      <div className={styles.rows}>
        <MatrixRows item={item} />
      </div>
      <div className={styles.header}>Choices</div>
      <Options item={item} />
    </>
  );
};

export default MatrixQuestion;
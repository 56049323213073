import { bindActionCreators } from 'redux';
import * as actions from '@actions';
import store from '@store';

const boundActions = bindActionCreators({
  connectionChange: actions.connectionChange,
}, store.dispatch);

const handleNetworkStateChange = (isConnected: boolean) => () => {
  boundActions.connectionChange({ isConnected });
};

window.addEventListener('offline', handleNetworkStateChange(false));
window.addEventListener('online', handleNetworkStateChange(true));
import { LongTextResponseNavigationContainer } from './LongTextResponse.Navigation';

type Props =
  ChildrenProps;

export const LongTextResponseFormContainer = (props: Props) => {

  // TODO: move component logic here

  return (
    <LongTextResponseNavigationContainer>
      {props.children}
    </LongTextResponseNavigationContainer>
  );
};

export default LongTextResponseFormContainer;
import {
  StripeConnectButtonProps,
  StripeDashboardButtonProps,
} from './interfaces';
import styles from './style.css';

export const StripeConnectButton = (props: StripeConnectButtonProps) => {
  return (
    <div className={styles.item}>
      <button
        type="button"
        onClick={props.handleConnect}
        className={styles.stripeConnect}>
        <span>Connect with Stripe</span>
      </button>
    </div>
  );
};

export const StripeDashboardButton = (props: StripeDashboardButtonProps) => {
  return (
    <div className={styles.item}>
      <a
        href={props.stripeDashboardUrl}
        className={styles.stripeConnect}
        rel="noopener noreferrer"
        target="_blank">
        <span>Stripe Dashboard</span>
      </a>
    </div>
  );
};
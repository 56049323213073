import TextInput from './TextInput';
import { Address1FieldProps } from './interfaces';

type Props =
    Address1FieldProps;

export default function Address1Field(props: Props) {
  if (!props.show) return null;

  return (
    <TextInput
      className={props.className}
      labelClassName={props.labelClassName}
      error={props.error}
      placeholder={props.placeholder}
      label={props.label}
      name='address1'
      value={props.value}
      required={props.required}
      onChange={props.onChange} />
  );
}

Address1Field.defaultProps = {
  label: 'Address 1',
  placeholder: 'Address Line 1',
  show: true,
};

export { Address1Field as Address1 };
import { ComplianceRules } from './interfaces';

const data: ComplianceRules = [
  {
    title: `Do not share any information that you are required by law, regulation, professional obligation or other duty to maintain as confidential, including but not limited to the following:`,
    bullets: [
      `Information that you have a duty or have agreed to keep confidential (e.g., by contract, fiduciary duty, etc.)`,
      `Information that was disclosed to you or to which you were permitted access under the assumption or expectation that you keep it confidential`,
      `Information you believe may be confidential`,
      `Proprietary information, such as trade secrets, copyrighted information, business information, belonging to past or present employers, companies for whom you have consulted, or any other third party`,
    ],
  },
  {
    title: `Do not share certain other types of information, regardless of whether it is confidential.`,
    bullets: [
      `If you are an auditor or former auditor, you will not consult about organizations that you or your employer currently audit or have audited in the last three years. Similarly, if you have been a member of a company’s audit committee at any point in the prior three years, you will not consult about such company.`,
      `If you have worked in the accounting or finance department of a company within the last year, you will not discuss accounting or financial issues relating to that company or its affiliates.`,
      `If you are an employee or director of an entity issuing securities in an initial public offering (IPO) or that has made, or is the subject of, a tender offer, or an entity that has acted on behalf of such a company in connections with such tender offer, you will decline all Project invitations until the commencement of such offering or while the tender offer process is ongoing.`,
    ],
  },
  {
    title: `Do not solicit or share Material Non-Public Information (“MNPI”).`,
    bullets: [
      `Material Information is information for which there is a likelihood that a reasonable investor would consider important in making a decision to buy or sell an company’s securities.`,
      `Non-Public Information is information that has not been effectively communicated to the marketplace in which the company operates its business. One must be able to point to some fact to show that the information is generally public.`,
      `Do not share MNPI related to any company, security, industry, or pending government action or legislation.`,
      `If you are an employee or director of a company or are otherwise in possession of MNPI, you will not provide it. Restricted activities include but are not limited to: (i) discuss or disclose any information about that company (such as its performance, strategy, processes, operations, or products), (ii) accept or engage in any Projects or Platform Activities about that company, or (iii) consult for any Client you reasonably believe to be a direct competitor of that company in each case without the express written consent of the company and Vancery.`,
      `If you are a government employee or elected or appointed government official you will not share MNPI with respect to any governmental activities.`,
    ],
  },
  {
    title: `Do not provide legal or investment advice.`,
    bullets: [
      `You are prohibited from providing advice as to the value of any security, or providing any advice regarding the advisability of investing in, purchasing, or selling any security`,
      `You may not provide legal advice or communicate over the Platform in connection with an attorney-client relationship.`,
    ],
  },
  {
    title: `You hereby confirm that all biographical information contained in your profile on the Platform (including your employment history and your affiliation with any company) is complete and accurate as of the date hereof.`,
    bullets: [],
  },
  {
    title: `Do not accept a Project if doing so:`,
    bullets: [
      `Presents a conflict of interest (or is reasonably likely to do so).`,
      `Results in a breach of any agreement or other legal duty or obligation you have to a third party.`,
    ],
  },
  {
    title: `You hereby confirm that you:`,
    bullets: [
      `Have completed Vancery’s compliance training regarding your compliance obligations for each Project.`,
      `Will comply with all applicable laws and any agreements or obligations you may have to a past or present employer or any other third party.`,
      `Will treat the information you receive from a Client in connection with a Project as confidential (unless required otherwise by applicable law).`,
      `Have not been sued for, accused of, or convicted of a felony or any offense involving dishonesty or deception.`,
      `Have not been subject to any order, judgment, action, or investigation of a court or any national or state regulatory or self-regulatory organization, such as the U.S. Securities and Exchange Commission (SEC), the Securities and Futures Commission (SFC) or Financial Industry Regulatory Authority (FINRA), relating to a violation of securities laws or an accusation of civil or criminal fraud or deceptive practices.`,
    ],
  },
];

export default data;
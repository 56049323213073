import { ApprovalStatus, CallType } from '@enums';
import http from '@services/http';
import * as xform from '@transformers';
import Toast from 'components/Toast';
import * as Scheduling from './interfaces/scheduling.project';

const approveCall = (data: Scheduling.ApproveCall.Request): Promise<Scheduling.ApproveCall.Response> => {
  return http.patch(`/scheduling/calls/${data.callId}/approvals`, {
    approvalStatusId: ApprovalStatus.Approved,
    callId: data.callId,
    approvalNotes: data.notes,
    projectId: data.projectId,
  });
};

const cancelCall = (data: Scheduling.CancelCall.Request): Promise<Scheduling.CancelCall.Response> => {
  return http.put(`/scheduling/calls/${data.callId}/cancellations`, data)
    .then((res: Scheduling.CancelCall.Response<string>) => ({
      call: xform.normalizeCall(res.call),
    }));
};

const confirmCall = (data: Scheduling.ConfirmCall.Request): Promise<Scheduling.ConfirmCall.Response> => {
  return http.patch(`/scheduling/calls/${data.callId}/confirmations`, data)
    .then((res: Scheduling.ConfirmCall.Response<string>) => ({
      call: xform.normalizeCall(res.call),
      conference: xform.normalizeConference(res.conference),
      pipeline: res.pipeline,
    }));
};

const createCall = (data: Scheduling.CreateCall.Request): Promise<Scheduling.CreateCall.Response> => {
  return http.post(`/scheduling/calls`, {
    ...data,
    type: CallType.Project,
  })
    .then((res: Scheduling.CreateCall.Response<string>) => ({
      call: xform.normalizeCall(res.call),
      contacts: res.contacts,
      pipeline: res.pipeline,
    }));
};

const rejectCall = (data: Scheduling.RejectCall.Request): Promise<Scheduling.RejectCall.Response> => {
  return http.patch(`/scheduling/calls/${data.callId}/approvals`, {
    approvalStatusId: ApprovalStatus.Rejected,
    callId: data.callId,
    approvalNotes: data.notes,
    projectId: data.projectId,
  });
};

const rescheduleCall = (data: Scheduling.RescheduleCall.Request) => {
  return http.post(`/scheduling/calls/${data.callId}/proposals`, data)
  .then((res: Scheduling.RescheduleCall.Response) => ({
    call: xform.normalizeCall(res.call),
  }));
};

export const getRecordingConsentState = (data: Scheduling.GetRecordingConsentState.Request): Promise<Scheduling.GetRecordingConsentState.Response> => {
  return http.get(`/scheduling/projects/${data.projectId}`);
};

export const updateRecordingStatus = (data: Scheduling.UpdateRecordingStatus.Request) => {
  return http.patch(`/scheduling/calls/${data.callId}/agreements`, data)
    .then((res: Scheduling.UpdateRecordingStatus.Response) => ({
      call: xform.normalizeCall(res.call),
    }));
};

export const requestQuant2QualCall = (data: Scheduling.RequestQuant2QualCall.Request) => {
  return http.post(`/scheduling/calls/quant-2-qual`, data);
};

export const projectCall = {
  approve: approveCall,
  cancel: cancelCall,
  confirm: confirmCall,
  create: createCall,
  propose: rescheduleCall,
  reject: rejectCall,
  requestQuant2QualCall: requestQuant2QualCall,
};
import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { SurveyActionType } from '@enums/Survey';
import { cx } from '@utils';
import { SurveyLogic } from '@/types/survey';
import styles from './style/ActionOrdinal.css';

type Props = {
  action: SurveyLogic.Action;
  className?: string;
};

export const SurveyActionOrdinal = (props: Props) => {

  const [state] = useSurveyBuilderState();

  const findSection = useCallback((identifier: string) => {
    return state.survey.sections.find(f => f.identifier === identifier);
  }, [state.survey.sections]);

  const findQuestion = useCallback((identifier: string) => {
    return state.survey.questions.find(f => f.base.identifier === identifier);
  }, [state.survey.questions]);

  const ordinalText = useMemo(() => {

    switch (props.action.type) {
      case SurveyActionType.SkipToQuestion: {
        const question = findQuestion(props.action.identifier);
        return `Q${question.ordinal}`;
      }

      case SurveyActionType.IncludeSection:
      case SurveyActionType.SkipToSection: {
        const section = findSection(props.action.identifier);
        return `S${section.ordinal}`;
      }

      default:
        return null;
    }

  }, [
    findQuestion,
    findSection,
    props.action,
  ]);

  return (
    <div className={cx(styles.root, props.className)}>
      {ordinalText}
    </div>
  );
};

export default SurveyActionOrdinal;
import { useCallback, useEffect, useState } from 'react';

export const useIsVideo = (url: string) => {
  const [isVideo, setIsVideo] = useState<boolean>(null);

  const checkVideo = useCallback((): Promise<boolean> => {
    return new Promise((res, rej) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = handleMetadataLoaded;
      video.src = url;

      function handleMetadataLoaded(e: Event) {
        res(!!(video.videoHeight && video.videoWidth));
        video.src = null;
      }
    });
  }, [url]);

  useEffect(() => {
    if (!url) return;

    checkVideo()
    .then(res => {
      setIsVideo(res);
    });
  }, [checkVideo, url]);

  return isVideo;
};
import { useCallback, useContext } from 'react';
import { WorkspaceGlobalSearchBarFilter } from '@/types';
import { useHasGroupFeature } from '@store/hooks';
import { WorkspaceGlobalSearchBarFiltersContext, WorkspaceGlobalSearchBarQueryContext } from './Context';
import FiltersCheckbox from './Filters.Checkbox';
import styles from './style/Filters.css';

export default function Filters() {
  const { activeFilters, setActiveFilters, aggregationsFilters } = useContext(WorkspaceGlobalSearchBarFiltersContext);
  const { query } = useContext(WorkspaceGlobalSearchBarQueryContext);
  const hasTagging = useHasGroupFeature('userTagging');

  const handleChange = useCallback((filter: WorkspaceGlobalSearchBarFilter, checked: boolean) => {
    setActiveFilters(prev => ({ ...prev, [filter]: checked }));
  }, [setActiveFilters]);

  if (!query.length) return null;

  return (
    <div className={styles.root}>
      <div className={styles.title}>Filter By</div>
      <div className={styles.filters}>
        <FiltersCheckbox
          aggregation={aggregationsFilters['project']}
          className={styles.check}
          checked={activeFilters['project']}
          onChange={(_, checked) => handleChange('project', checked)}
          label="Projects" />
        <FiltersCheckbox
          aggregation={aggregationsFilters['member']}
          className={styles.check}
          checked={activeFilters['member']}
          onChange={(_, checked) => handleChange('member', checked)}
          label="Members" />
        <FiltersCheckbox
          aggregation={aggregationsFilters['folder']}
          className={styles.check}
          checked={activeFilters['folder']}
          onChange={(_, checked) => handleChange('folder', checked)}
          label="Folders" />
        <FiltersCheckbox
          aggregation={aggregationsFilters['file']}
          className={styles.check}
          checked={activeFilters['file']}
          onChange={(_, checked) => handleChange('file', checked)}
          label="Files" />
        {hasTagging &&
          <FiltersCheckbox
            aggregation={aggregationsFilters['tag']}
            className={styles.check}
            checked={activeFilters['tag']}
            onChange={(_, checked) => handleChange('tag', checked)}
            label="Tags" />
        }
      </div>
    </div>
  );
}

export { Filters };
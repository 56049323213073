import { init } from '@sentry/react';
import { Dedupe } from '@sentry/integrations';

init({
  dsn: 'https://974e745bea7849ac965e8693a3213ebc@s.vancery.com/2',
  enabled: process.env.ERROR_TRACKING as unknown as boolean,
  environment: process.env.VANCERY_ENV,
  integrations: [
    new Dedupe(),
  ],
});
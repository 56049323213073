import extend from '@enums/extend';

export enum ConferenceType  {
  Video = 1,
  Voice = 2,
}

const map = new Map([
  [ConferenceType.Video,      { name: 'Video' }],
  [ConferenceType.Voice,      { name: 'Voice' }],
]);

export default {
  ConferenceType: extend(map),
};
import { useCallback, useEffect } from 'react';
import {
  useEditorState,
  useEditorFocus,
  EditorComponent,
} from '@remirror/react';
import cuid from 'cuid';
import {
  useTranscriptCommentFocus,
  useTranscriptCommentIdentifiers,
  useTranscriptCommentReplyVisibility,
  useTranscriptCommentReplyActionsVisibility,
} from '@containers/Transcript';
import { cx } from '@utils';
import { MentionsAutocomplete } from 'components/Remirror';
import { useIsEditorEmpty } from 'components/Remirror/hooks';
import {
  CommentActions,
  CommentCancelButton as CancelButton,
  CommentSaveButton as SaveButton,
} from './Comment.Actions';
import {
  useCanSaveComment,
  useCommentEditor,
  useResetCommentEditor,
  useSaveCommentReply,
} from './hooks';
import { CommentEditor } from './Comment.Editor';
import styles from './style/Comment.Reply.css';

type Props = {
  className?: string;
};

const ContainerWithEditorState = (props: ChildrenProps) => {

  const editorIsEmpty = useIsEditorEmpty();

  const { parentComment } = useTranscriptCommentIdentifiers();
  const [isCommentFocused] = useTranscriptCommentFocus(parentComment.identifier);
  const [isOpen, setIsOpen] = useTranscriptCommentReplyVisibility();
  const [actionsVisible, setActionsVisible] = useTranscriptCommentReplyActionsVisibility();

  const [editorFocused] = useEditorFocus({
    blurOnInactive: true,
  });

  useEffect(() => {
    if (!isCommentFocused && editorIsEmpty) {
      setIsOpen(false);
      setActionsVisible(false);
    }
  }, [
    editorIsEmpty,
    isCommentFocused,
    setActionsVisible,
    setIsOpen,
  ]);

  useEffect(() => {
    if (editorFocused) {
      setActionsVisible(true);
    }
  }, [
    editorFocused,
    setActionsVisible,
  ]);

  return (
    <>
      {props.children}
    </>
  );
};

const ReplyToComment = ({ className }: Props) => {

  const { highlight, parentComment } = useTranscriptCommentIdentifiers();

  const editor = useEditorState();
  const [actionsVisible, setActionsVisible] = useTranscriptCommentReplyActionsVisibility();

  const resetState = useResetCommentEditor();

  const {
    isLoading: isSaving,
    mutateAsync: saveReply,
  } = useSaveCommentReply({
    onSuccess: resetState,
  });

  const canSave = useCanSaveComment();

  const handleCancel = useCallback(() => {
    resetState();
    setActionsVisible(false);
  }, [resetState, setActionsVisible]);

  const handlePost = useCallback(() => {

    return saveReply({
      comment: {
        highlightId: highlight.id,
        identifier: cuid(),
        value: editor.doc,
        parentCommentId: parentComment.id,
      },
    });

  }, [
    highlight.id,
    parentComment.id,
    editor,
    saveReply,
  ]);

  return (
    <div className={cx(styles.root, className)}>
      <EditorComponent />
      <MentionsAutocomplete />

      {actionsVisible &&
        <CommentActions>
          <SaveButton
            disabled={!canSave}
            implicitDisable={isSaving}
            loading={isSaving}
            onClick={handlePost}>
            Post
          </SaveButton>
          <CancelButton
            onClick={handleCancel} />
        </CommentActions>
      }
    </div>
  );
};

const WithState = (props: Props) => {

  const { manager } = useCommentEditor({
    placeholder: 'Reply or add others with @',
  });

  const [isOpen] = useTranscriptCommentReplyVisibility();

  if (!isOpen) return null;

  return (
    <CommentEditor
      editable={true}
      manager={manager}>
      <ContainerWithEditorState>
        <ReplyToComment {...props} />
      </ContainerWithEditorState>
    </CommentEditor>
  );
};

export { WithState as ReplyToComment };
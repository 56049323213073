import cuid from 'cuid';
import {
  SurveyActionType,
  SurveyQuestionConditionType,
} from '@enums/Survey';
import {
  CommonTemplateQuestion,
  NetPromoterScoreQuestion,
  NetPromoterScoreTemplateSection,
  SurveyTemplateEntityType,
  SurveyTemplateType,
} from '@enums/survey.template';
import { SurveyTemplate } from '@/types/survey.templates';
import {
  MatrixSliderQuestion,
  MultipleChoiceQuestion,
  SurveyQuestion,
  SurveySection,
  SurveyVersion,
  MultiselectQuestion,
} from '@/types/survey';
import * as $question from './question';
import * as $shared from './template.shared-questions';
import * as $template from './template.utils';

export const defaultTemplateData: SurveyTemplate.NetPromoterScore = {
  category: {
    id: cuid(),
    value: '',
  },
  competitors: $template.generateInitialTextItems(10),
  target: {
    id: cuid(),
    value: '',
  },
};

// export const defaultTemplateData: SurveyTemplate.NetPromoterScore = {
//   category: {
//     id: cuid(),
//     value: 'Category',
//   },
//   competitors: $template.generateInitialTextItems(5, 'Competitor'),
//   target: {
//     id: cuid(),
//     value: 'Target',
//   },
// };

export function generateSurveyData(data: SurveyTemplate.NetPromoterScore): SurveyVersion {

  const screeningSection = $template.generateSection({
    metadata: {
      canAddQuestion: true,
      canDelete: false,
      canModifyVisibility: false,
      template: { key: NetPromoterScoreTemplateSection.Screening },
    },
    hidden: false,
    name: 'Screening',
    ordinal: 1,
  });

  const targetNPSection = $template.generateSection({
    metadata: {
      canAddQuestion: false,
      canDelete: false,
      canModifyVisibility: false,
      template: {
        key: NetPromoterScoreTemplateSection.NetPromoterTargt,
        linkedEntity: {
          id: data.target.id,
          type: SurveyTemplateEntityType.Target,
        },
      },
    },
    hidden: true,
    name: 'Net Promoter Score Target',
    ordinal: 2,
  });

  const competitorNPSections = data.competitors.map((m, i) => generateNetPromoterCompetitorSection(m, i + 3));

  function categoryNode() {
    return $template.generateCategoryNode(data.category);
  }

  function OrgSizeQuestion(): MultipleChoiceQuestion.Question {
    return $shared.OrgSizeQuestion({ section: screeningSection });
  }

  function OrgIndustryQuestion(): MultipleChoiceQuestion.Question {
    return $shared.OrgIndustryQuestion({ section: screeningSection });
  }

  function OrgRoleQuestion() {
    return $shared.OrgRoleDQQuestion({ section: screeningSection });
  }

  function OrgDepartmentQuestion() {
    return $shared.OrgDepartmentQuestion({ section: screeningSection });
  }

  function RespondentRoleQuestion() {

    const noInvolvementOption = $template.generateOption({
      metadata: {
        canModifyValue: false,
        canDelete: false,
        template: {},
      },
      ordinal: 1,
      value: `No involvement in the evaluation / usage`,
    });

    const otherOptions = [
      `User`,
      `Decision-maker / Relationship Manager`,
      `User and Decision-maker / Relationship Manager`,
    ];

    return $template.generateMultipleChoiceQuestion({
      logic: {
        base: {
          action: {
            type: SurveyActionType.None,
          },
        },
        response: [{
          id: cuid(),
          action: {
            type: SurveyActionType.Disqualification,
          },
          condition: {
            value: {
              option: { identifier: noInvolvementOption.base.identifier },
            },
            type: SurveyQuestionConditionType.Chosen,
          },
        }],
      },
      metadata: {
        canModifyValue: false,
        canModifyLogic: false,
        canDelete: false,
        template: {
          key: NetPromoterScoreQuestion.RespondentEvaluationRole,
        },
      },
      options: [
        noInvolvementOption,
        ...otherOptions.map((value, i) => $template.generateOption({
          metadata: {
            canModifyValue: false,
            canDelete: false,
            template: {},
          },
          ordinal: i + 2,
          value,
        })),
      ],
      section: {
        identifier: screeningSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode(`What best describes your role in the evaluation / usage of `),
        $template.generateCategoryNode(data.category),
        $template.generateTextNode(`?`),
      ]),
    });
  }

  function OrgProductsQuestion() {

    const targetOption = $template.generateTargetOption({
      ordinal: 1,
      target: data.target.value,
    });

    const competitorOptions = data.competitors.map((item, i) => $template.generateCompetitorOption({
      item,
      ordinal: i + 2,
    }));

    const targetResponseLogic: MultiselectQuestion.Logic.Response = {
      id: cuid(),
      action: {
        type: SurveyActionType.IncludeSection,
        identifier: targetNPSection.identifier,
      },
      condition: {
        value: {
          option: { identifier: targetOption.base.identifier },
        },
        type: SurveyQuestionConditionType.Chosen,
      },
    };

    const competitorResponseLogic: MultiselectQuestion.Logic.Response[] = competitorOptions.map(m => {
      const section = competitorNPSections.find(f => f.metadata.template.linkedEntity.id === m.metadata.template.linkedEntity.id);
      return {
        id: cuid(),
        action: {
          type: SurveyActionType.IncludeSection,
          identifier: section.identifier,
        },
        condition: {
          value: {
            option: { identifier: m.base.identifier },
          },
          type: SurveyQuestionConditionType.Chosen,
        },
      };
    });

    return $template.generateMultiselectQuestion({
      logic: {
        base: {
          action: {
            type: SurveyActionType.None,
          },
        },
        response: [
          targetResponseLogic,
          ...competitorResponseLogic,
        ],
      },
      metadata: {
        canModifyValue: false,
        canModifyLogic: false,
        canDelete: false,
        template: {
          key: NetPromoterScoreQuestion.OrgCurrentCustomer,
        },
      },
      options: [
        targetOption,
        ...competitorOptions,
      ],
      section: {
        identifier: screeningSection.identifier,
      },
      value: $template.generateRichTextValue([
        $template.generateTextNode('Please select all of '),
        categoryNode(),
        $template.generateTextNode(` offerings that your organization is a customer of?`),
      ]),
    });
  }

  function generateNetPromoterTargetQuestions() {
    const params: GenerateNetPromoterQuestion = {
      isTarget: true,
      linkedEntity: data.target,
      ordinal: 0,
      section: targetNPSection,
    };

    const recommendationQuestion = RecommendationQuestion(params);

    return [
      recommendationQuestion,
      RecommendationReasonQuestion({
        ...params,
        recommendationQuestion,
      }),
      RecommendationHigherRating(params),
    ];
  }

  function generateNetScoreQuestions() {

    return data.competitors.reduce<SurveyQuestion[]>((acc, competitor) => {
      const section = competitorNPSections.find(f => f.metadata.template.linkedEntity.id === competitor.id);

      return acc.concat(generateNetScoreCompetitorQuestions({
        competitor,
        section,
        startingOrdinal: 0,
      }));

    }, []);
  }

  const questions: SurveyQuestion[] = [
    OrgSizeQuestion(),
    OrgIndustryQuestion(),
    OrgRoleQuestion(),
    OrgDepartmentQuestion(),
    RespondentRoleQuestion(),
    OrgProductsQuestion(),
    ...generateNetPromoterTargetQuestions(),
    ...generateNetScoreQuestions(),
  ];

  return {
    createdOn: null,
    id: null,
    questions: questions.map((m, i) => ({
      ...m,
      ordinal: i + 1,
    })),
    quotas: [],
    sections: [
      screeningSection,
      targetNPSection,
      ...competitorNPSections,
    ],
    surveyId: null,
    template: {
      data,
      type: SurveyTemplateType.NetPromoterScore,
    },
  };
}

export function generateNetPromoterCompetitorSection(competitor: SurveyTemplate.LinkedEntity, ordinal: number) {
  return $template.generateSection({
    metadata: {
      canAddQuestion: false,
      canDelete: false,
      canModifyVisibility: false,
      template: {
        key: NetPromoterScoreTemplateSection.NetPromoterCompetitor,
        linkedEntity: {
          id: competitor.id,
          type: SurveyTemplateEntityType.Competitor,
        },
      },
    },
    hidden: true,
    name: 'Net Promoter Score Competitor',
    ordinal,
  });
}

type GenerateNetPromoterCompetitorQuestions = {
  competitor: SurveyTemplate.Competitor;
  section: SurveySection;
  startingOrdinal: number;
};

type GenerateNetPromoterQuestion = {
  linkedEntity: {
    id: string;
    value: string;
  };
  isTarget: boolean;
  ordinal: number;
  recommendationQuestion?: MatrixSliderQuestion.Question;
  section: Pick<SurveySection, 'identifier' | 'ordinal'>;
};

export function generateNetScoreCompetitorQuestions({ competitor, section, startingOrdinal }: GenerateNetPromoterCompetitorQuestions) {

  const params: GenerateNetPromoterQuestion = {
    isTarget: false,
    linkedEntity: competitor,
    ordinal: startingOrdinal,
    section,
  };

  const recommendationQuestion = RecommendationQuestion(params);

  return [
    recommendationQuestion,
    RecommendationReasonQuestion({
      ...params,
      ordinal: startingOrdinal + 1,
      recommendationQuestion,
    }),
    RecommendationHigherRating({
      ...params,
      ordinal: startingOrdinal + 1,
    }),
  ];
}

function RecommendationQuestion({ isTarget, linkedEntity, ordinal, section }: GenerateNetPromoterQuestion) {

  const node = !isTarget
    ? $template.generateCompetitorNode(linkedEntity)
    : $template.generateTargetNode(linkedEntity.value);

  return $template.generateSlidersQuestion({
    metadata: {
      canModifyValue: false,
      canModifyLogic: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: linkedEntity.id,
          type: isTarget ? SurveyTemplateEntityType.Target : SurveyTemplateEntityType.Competitor,
        },
        key: !isTarget
          ? NetPromoterScoreQuestion.CompetitorRecommendation
          : NetPromoterScoreQuestion.TargetRecommendation,
      },
    },
    matrixRows: [$template.generateRow({
      metadata: {
        canDelete: false,
        canModifyValue: false,
        template: {},
      },
      ordinal: 1,
      value: 'Likelihood',
    })],
    ordinal,
    section: {
      identifier: section.identifier,
    },
    settings: {
      ...$question.matrixSlider.DefaultSettings,
      slider: {
        hideSlider: false,
        increment: 1,
        label: null,
        minValue: 0,
        maxValue: 10,
        ensureAnswerTotalEqualsMax: false,
      },
    },
    value: $template.generateRichTextValue([
      $template.generateTextNode(`How likely is it that you would recommend `),
      node,
      $template.generateTextNode(` to a friend or colleague?`),
    ]),
  });
}

function RecommendationReasonQuestion({ isTarget, linkedEntity, ordinal, recommendationQuestion, section }: GenerateNetPromoterQuestion) {

  const node = !isTarget
    ? $template.generateCompetitorNode(linkedEntity)
    : $template.generateTargetNode(linkedEntity.value);

  return $template.generateShortTextQuestion({
    metadata: {
      canModifyValue: false,
      canModifyLogic: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: linkedEntity.id,
          type: isTarget ? SurveyTemplateEntityType.Target : SurveyTemplateEntityType.Competitor,
        },
        key: !isTarget
          ? NetPromoterScoreQuestion.CompetitorRecommendationReason
          : NetPromoterScoreQuestion.TargetRecommendationReason,
      },
    },
    ordinal,
    section: {
      identifier: section.identifier,
    },
    value: $template.generateRichTextValue([
      $template.generateTextNode(`Please tell us why you gave `),
      node,
      $template.generateTextNode(` a `),
      $template.generatePipedResponseNode({
        condition: {
          type: SurveyQuestionConditionType.Chosen,
          value: {
            row: {
              identifier: recommendationQuestion.matrixRows[0].base.identifier,
            },
          },
        },
        questionIdentifier: recommendationQuestion.base.identifier,
      }),
      $template.generateTextNode(` rating.`),
    ]),
  });
}

function RecommendationHigherRating({ isTarget, linkedEntity, ordinal, section }: GenerateNetPromoterQuestion) {
  const node = !isTarget
    ? $template.generateCompetitorNode(linkedEntity)
    : $template.generateTargetNode(linkedEntity.value);

  return $template.generateShortTextQuestion({
    metadata: {
      canModifyValue: false,
      canModifyLogic: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: linkedEntity.id,
          type: isTarget ? SurveyTemplateEntityType.Target : SurveyTemplateEntityType.Competitor,
        },
        key: !isTarget
          ? NetPromoterScoreQuestion.CompetitorRecommendationReason
          : NetPromoterScoreQuestion.TargetRecommendationReason,
      },
    },
    ordinal,
    section: {
      identifier: section.identifier,
    },
    value: $template.generateRichTextValue([
      $template.generateTextNode(`What changes would `),
      node,
      $template.generateTextNode(` have to make for you to give it a higher rating?`),
    ]),
  });
}
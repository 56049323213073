
type Props = {
  size: number;
} & React.SVGAttributes<SVGElement>;

const Logo = ({ size, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M24.2442 0.976466C23.4053 0.976466 22.6159 1.29706 22.0236 1.81529L21.9989 1.84C21.8759 1.96294 21.7524 2.08647 21.6289 2.21C20.9877 2.77764 20.1242 3.12294 19.1371 3.12294C18.1995 3.12294 17.3359 2.80235 16.7195 2.25882C16.103 1.46941 15.1406 0.951172 14.0548 0.951172C12.2042 0.951172 10.6995 2.45647 10.6995 4.30706C10.6995 6.15764 12.2042 7.66235 14.0548 7.66235C14.8689 7.66235 15.6336 7.36647 16.2012 6.87294C16.3736 6.72529 16.5471 6.55235 16.6942 6.3547C17.3106 5.81235 18.1748 5.49176 19.1118 5.49176C20.0989 5.49176 20.9871 5.86176 21.6036 6.42882C21.7018 6.55235 21.8254 6.67588 21.9489 6.79882C21.9489 6.82353 21.9736 6.82353 21.9977 6.84823C22.59 7.36647 23.3548 7.68705 24.2183 7.68705C26.0689 7.68705 27.5736 6.18235 27.5736 4.33117C27.6001 2.48117 26.0953 0.976466 24.2442 0.976466Z" fill="#1DB955" />
      <path d="M11.859 11.5111C11.4396 10.7711 10.7737 10.2529 10.0331 10.0064C10.0084 10.0064 10.0084 10.0064 9.98369 10.0064C9.81133 9.957 9.63839 9.90817 9.46545 9.88347C8.65133 9.6117 7.91075 9.01994 7.41781 8.18111C6.94898 7.367 6.80134 6.47876 6.94898 5.66406C7.34369 4.72641 7.31898 3.6417 6.77604 2.70405C5.8631 1.09994 3.79075 0.532877 2.18722 1.46994C0.5831 2.38288 0.0160283 4.45523 0.953087 6.05876C1.3725 6.77406 1.98956 7.26758 2.70485 7.53935C2.92661 7.61288 3.14839 7.687 3.39544 7.7117C4.16015 7.98288 4.87603 8.57523 5.34426 9.38994C5.83779 10.2535 5.96132 11.1911 5.78838 12.0299C5.73956 12.1782 5.68955 12.3505 5.64073 12.5235C5.64073 12.5482 5.64074 12.5482 5.61603 12.5723C5.46838 13.3376 5.56662 14.1764 5.98603 14.9164C6.89838 16.5211 8.94661 17.087 10.5748 16.1505C12.2284 15.1629 12.796 13.1152 11.859 11.5111Z" fill="#0A68EF" />
      <path d="M20.889 10.4747C19.2855 9.56175 17.2378 10.1053 16.3002 11.7094C15.7578 12.6465 15.7331 13.7576 16.1278 14.67C16.2761 15.4847 16.1278 16.3729 15.6596 17.187C15.1655 18.0253 14.4255 18.6176 13.612 18.89C13.4396 18.9135 13.2667 18.9635 13.0937 19.0123C13.069 19.0123 13.069 19.0123 13.0443 19.0123C12.3043 19.26 11.6384 19.7782 11.219 20.5176C10.3061 22.1212 10.849 24.1694 12.4525 25.1076C14.0567 26.0206 16.1043 25.4776 17.0414 23.8735C17.4608 23.1329 17.5843 22.3182 17.4114 21.5288C17.4114 21.5047 17.4114 21.5047 17.3867 21.4806C17.362 21.307 17.3125 21.1594 17.2384 20.9871C17.0661 20.1476 17.1896 19.21 17.6825 18.3459C18.1514 17.5318 18.8667 16.94 19.6314 16.6694C19.8784 16.6435 20.1002 16.5694 20.3219 16.4959C21.0378 16.2247 21.679 15.7312 22.0737 15.0147C23.0602 13.4365 22.4931 11.3882 20.889 10.4747Z" fill="#E92D21" />
    </svg>
  );
};

const defaultProps = {
  size: 30,
};

Logo.defaultProps = defaultProps;

export { Logo };
export default Logo;
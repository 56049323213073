import { ButtonActivityIndicator } from '@presentation';
import { cx, useAsyncStateLazy } from '@utils';
import * as api from '$admin/api';
import { Project } from '$admin/interfaces';
import { Button } from 'components/Button';
import { ButtonSet } from 'components/Modal/ButtonSet';
import { Header } from 'components/Modal/Header';
import { Modal, ModalProps } from 'components/Modal/Modal';
import styles from './style/Project.EngageMembers.css';

type EngageItem = {
  user: Pick<Project.Members.Item['user'], 'id'>;
};

type Props = {
  className?: string;
  onConfirm?: () => unknown;
  items: EngageItem[];
} & IProjectId
  & Pick<ModalProps, 'open' | 'onClose'>;

export const EngageMembers = (props: Props) => {
  const [state, handleConfirm] = useAsyncStateLazy(() => {
    return api.projects.members.engageMembers({
      projectId: props.projectId,
      userIds: props.items.map(i => i.user.id),
    })
    .then(props.onConfirm);
  }, [
    props.onConfirm,
    props.projectId,
    props.items,
  ]);

  return (
    <Modal
      className={cx(styles.root, props.className)}
      disableOverlayClick
      hideCloseIcon
      onClose={props.onClose}
      open={props.open}>
      <Header>
        {`Notify the client to take action on ${props.items.length} member${props.items.length > 1 ? 's' : ''}?`}
      </Header>
      <ButtonSet className={styles.buttonset}>
        <Button
          className={styles.cancel}
          color="destructive"
          disabled={state.loading}
          onClick={props.onClose}
          variant="brick">
          Cancel
        </Button>
        <ButtonActivityIndicator
          className={styles.btn}
          color="primary"
          disabled={state.loading}
          onClick={handleConfirm}
          variant="brick">
          Notify
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

EngageMembers.displayName = 'Modal.Project.EngageMembers';
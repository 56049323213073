import { useCallback } from 'react';
import { SurveyFormQuestion, RespondentAnswer } from '@/types';
import { SurveyFormProps } from './interfaces';
import QuestionnaireItem from './QuestionnaireItem';
import styles from './style/SurveyForm.css';

export const Questionnaire = ({ answers, questions, setAnswer }: SurveyFormProps) => {
  const renderQuestion = useCallback((item: SurveyFormQuestion) => {
    const updateAnswer = (value: RespondentAnswer) => {
      setAnswer({
        questionId: item.id,
        value,
      });
    };

    return (
      <div key={item.id} className={styles.question}>
        <QuestionnaireItem
          answer={answers[item.id]}
          item={item}
          setAnswer={updateAnswer} />
      </div>
    );
  }, [setAnswer, answers]);

  return (
    <>{questions.map(renderQuestion)}</>
  );
};

export default Questionnaire;
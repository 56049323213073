import { useMemo } from 'react';
import { useSurveyBuilderState, useIsEditingSurvey } from '@containers/SurveyBuilder';
import { useSurveyBuilderOptions } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { Header, Subtitle, Title } from '@screens/ProjectCreation/Header';
import { ProjectSurveyBuilder, useProjectSurveyBuilderTabs } from 'components/SurveyBuilder';
import { useProjectCreationNavigation, useProjectSurveyStepperActions } from './context';
import { BackButton, Footer, NextButton } from './Footer';
import styles from './style.css';

const ProjectSurvey = () => {
  const { maxQuestions } = useSurveyBuilderOptions();
  const tabs = useProjectSurveyBuilderTabs();

  const { next: nextScreen } = useProjectCreationNavigation();
  const stepperActions = useProjectSurveyStepperActions();

  const [state] = useSurveyBuilderState();
  const isEditing = useIsEditingSurvey();
  const disabled = isEditing ||
    !state.survey.questions.length ||
    !state.survey.sections
      .every(e => !!e.name && state.survey.questions.some(s => s.section.identifier === e.identifier));

  return (
    <>
      <div className={styles.section}>
        <div className={styles.wrap}>
          <Header className={styles.row}>
            <Title className={styles.row}>Survey Questions</Title>
            <Subtitle className={styles.row}>{`Please provide up to ${maxQuestions} questions for the survey.`}</Subtitle>
          </Header>

          <ProjectSurveyBuilder tabs={tabs} />
        </div>
        <Footer>
          <BackButton onClick={stepperActions.back} />
          <NextButton
            disabled={disabled}
            onClick={nextScreen} />
        </Footer>
      </div>
    </>
  );
};

export { ProjectSurvey };
export default ProjectSurvey;
import styles from './style/Base.css';

type Props = {
  children?:  React.ReactText;
  className?: string;
  text?:      string;
};

const Header = ({
  className = '',
  text = '',
  ...props
}: Props) => {
  return (
    <div className={`${styles.header} ${className}`}>{props.children || text}</div>
  );
};

export { Header };
export default Header;
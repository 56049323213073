import { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHasGroupFeature } from '@store/hooks';
import { CellMemberActions, CellMemberName, CellTags, CellMemberTitle } from '../Cells';

export default function useMembersTableColumns() {
  const isDesktop = useMediaQuery('(min-width:900px)');
  const hasTagging = useHasGroupFeature('userTagging');

  return useMemo(() => [
    {
      accessor: 'name',
      id: 'name',
      Header: 'Name',
      Cell: CellMemberName,
      minWidth: 75,
    },
    {
      accessor: 'title',
      id: 'title',
      Header: 'Title',
      Cell: CellMemberTitle,
      minWidth: 75,
    },
    isDesktop && hasTagging ? {
      accessor: 'tags',
      id: 'tags',
      Header: 'Tags',
      Cell: CellTags,
      minWidth: 350,
      maxWidth: 0,
    } : null,
    isDesktop ? {
      accessor: 'actions',
      id: 'actions',
      Cell: CellMemberActions,
      width: 50,
      minWidth: 100,
      maxWidth: 0,
    } : null,
  ].filter(Boolean), [isDesktop, hasTagging]);
}

export { useMembersTableColumns };
import { useMemo } from 'react';
import { CallStatus } from '@enums';
import { useSelectProjectCall, useSelectUser } from '@containers/Store';
import { useHasClientRole } from '@containers/User';
import { getLocationFor } from '@utils';

export const useRescheduleProjectCall = (callId: number) => {
  const call = useSelectProjectCall(callId);
  const hasClientRole = useHasClientRole();
  const me = useSelectUser();

  const canReschedule = useMemo(() => {
    if (!hasClientRole) return false;

    return call.statusId === CallStatus.Scheduled &&
      me.id === call.creatorUserId;
  }, [
    call.creatorUserId,
    call.statusId,
    hasClientRole,
    me.id,
  ]);

  const location = getLocationFor.scheduling.proposing({
    callId,
    projectId: call.projectId,
    schedulerId: me.id,
    scheduleeId: call.userId,
  });

  return [canReschedule, location] as const;
};

export default useRescheduleProjectCall;
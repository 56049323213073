import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as consts from '@consts';
import { RateOnboarding } from '@containers';
import { useRouteStepper } from '@utils/hooks';
import { LinearProgress } from 'components/Progress/LinearProgress';
import styles from '../style.css';
import routes from './routes';

const mapState = (state: Store.State) => ({
  user: state.user,
});

export const HourlyRateWizard = () => {
  const history = useHistory();
  const { user } = useSelector(mapState);

  useEffect(() => {
    if (user.profile.hourlyRate) {
      history.push(consts.pathname.Home);
    }
  }, []);

  const [Routes, stepperActions, step] = useRouteStepper(routes);

  const handleCompletion = () => {
    history.push({
      pathname: consts.pathname.Home,
      state: { from: consts.pathname.Home },
    });
  };

  const progress = (step / routes.length) * 100;

  return (
    <>
      <div className={styles.progress}>
        <LinearProgress value={progress} />
      </div>
      <RateOnboarding
        back={stepperActions.back}
        onCompletion={handleCompletion}
        next={stepperActions.next}>
        <Routes />
      </RateOnboarding>
    </>
  );
};

export default HourlyRateWizard;
import { useCallback } from 'react';
import {
  ReactComponentExtension,
  useRemirror,
} from '@remirror/react';
import { InvalidContentHandler } from '@remirror/core';
import { DocExtension } from '@remirror/extension-doc';
import { TranscribeRT } from '@/types';
import {
  HighlightExtension,
  KaraokeExtension,
  MonologueDetailsExtension,
  MonologueTextExtension,
  TimestampExtension,
} from '../Extension';

type Props = {
  transcript: TranscribeRT.DocumentNode;
};

export const useRichTextTranscript = ({ transcript }: Props) => {

  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
    console.error('Invalid Content', invalidContent);
    return transformers.remove(json, invalidContent);
  }, []);

  return useRemirror({
    content: transcript,
    extensions: () => [
      new DocExtension({}),
      new HighlightExtension<TranscribeRT.Highlight>({}),
      new KaraokeExtension(),
      new MonologueDetailsExtension(),
      new MonologueTextExtension(),
      new ReactComponentExtension({}),
      new TimestampExtension(),
    ],
    onError,
  });
};
import { Check } from 'react-feather';
import { useSelectUser } from '@containers/Store';
import * as utils from '@utils';
import {
  MicrosoftCalendarSync,
  GoogleCalendarSync,
  ConnectAccountProvider,
} from 'components/ConnectAccount';
import Body from 'components/Modal/Body';
import Header from 'components/Modal/Header';
import Modal from 'components/Modal/Modal.Legacy';
import styles from './style.css';

type Props = {
  onClose: () => void;
};

const ConnectCalendar = (props: Props) => {

  const user = useSelectUser();

  const otherRole = utils.hasClientRole(user)
    ? 'members'
    : 'clients';

  return (
    <Modal
        visible={true}
        onClose={props.onClose}>
      <div className={styles.mWrapper}>
        <Header>Connect Your Calendar</Header>
        <Body>
          <h2 className={styles.mDesc}>To avoid scheduling back and forth, connect your calendar to Vancery.</h2>
          <div className={styles.mLine}>
            <Check className={styles.mCheck} />
            <div className={styles.p}>Display your calendar events within Vancery so you can book calls around your schedule.</div>
          </div>
          <div className={styles.mLine}>
            <Check className={styles.mCheck} />
            <div className={styles.p}>{`Allow ${otherRole} to see your availability when booking times.`}</div>
          </div>
          <div className={styles.mLine}>
            <Check className={styles.mCheck} />
            <div className={styles.p}>Automatically add booked calls to your external calendar.</div>
          </div>
          <div className={styles.mLine}>
            <Check className={styles.mCheck} />
            <div className={styles.p}>100% privacy and security - no calendar details will be shared with invitees or with any third parties.</div>
          </div>
        </Body>
        <div className={styles.mButtons}>
          <GoogleCalendarSync onConnected={props.onClose} />
          <MicrosoftCalendarSync onConnected={props.onClose} />
        </div>
        <div className={styles.footer}>Manage synced calendar settings in your account settings to remove or add a calendar.</div>
      </div>
    </Modal>
  );
};

const ConnectCalendarModal = (props: Props) => (
  <ConnectAccountProvider>
    <ConnectCalendar {...props} />
  </ConnectAccountProvider>
);

export { ConnectCalendarModal };
export default ConnectCalendarModal;
import { useCallback } from 'react';
import { MatrixGridQuestion as MGQ } from '@/types';
import { SurveyMatrixGridContainer } from 'components/SurveyMatrixGrid';
import { MatrixGridQuestionProps } from './interfaces';

type Props =
  MatrixGridQuestionProps;

export const MatrixGridQuestion = ({ answer, item, setAnswer }: Props) => {

  const updateRowAnswer = useCallback((rowId: number) => (optionId: number) => {
    const existingItems = answer?.items || [];
    const items = !existingItems.some(s => s.matrixRowId === rowId)
      ? [...existingItems, { matrixRowId: rowId, optionId } ]
      : existingItems.reduce((acc, x) => {
        return [
          ...acc,
          {
            matrixRowId: x.matrixRowId,
            optionId: x.matrixRowId === rowId ? optionId : x.optionId,
          },
        ];
      }, [] as MGQ.RespondentAnswer.Item[]);

    setAnswer({ items });
  }, [setAnswer, answer?.items]);

  return (
    <SurveyMatrixGridContainer
      variant="single"
      answers={answer?.items || []}
      options={item.options}
      rows={item.matrixRows}
      updateRowAnswer={updateRowAnswer} />
  );
};

export default MatrixGridQuestion;
import { Cell } from 'react-table';

type GenericOwnerItem = {
  owner: string;
}

export default function Owner({ row: { original: data } }: Cell<GenericOwnerItem>) {
  return data.owner || '';
}

export { Owner };
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { Calls } from '@api/interfaces';
import { Comment } from '@/types/transcribe.rich-text';
import { useTranscriptCommentActions, useTranscriptComments } from '@containers/Transcript';
import { UnsavedHighlightColor, highlightColors } from '../utils';
import { useTranscriptChainedCommands } from '../context';
import { useSaveHighlight } from './useSaveHighlight';

type Data = Comment;
type Variables = {
  comment: Calls.SaveTranscriptComment.Request['comment'];
};
type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

function useSaveComment(options?: Options) {

  const { saveComment } = useTranscriptCommentActions();

  return useMutation(['save-transcript-comment'], ({ comment }) => {

    return saveComment({ comment });

  }, options);
}

const DefaultColor = highlightColors[0].highlight;
export function usePersistCommentHighlight() {
  const commands = useTranscriptChainedCommands();

  return useSaveHighlight({
    onSuccess: (highlight, v, c) => {
      commands
        .blurHighlight()
        .updateHighlight({
          id: highlight.id,
          color: DefaultColor,
          dbId: highlight.dbId,
        })
        .run();
    },
    onError: (e, v, ctx) => {
      commands
        .updateHighlight({
          id: v.highlight.id,
          color: UnsavedHighlightColor,
        })
        .run();
    },
  });
}

export function useSaveParentComment(options?: Options) {

  const [_, dispatch] = useTranscriptComments();

  return useSaveComment({
    ...options,
    onSuccess: (res, v, ctx) => {
      dispatch({
        comment: res,
        type: 'comment-saved',
      });

      options?.onSuccess?.(res, v, ctx);
    },
  });
}

export function useSaveCommentReply(options?: Options) {

  const [_, dispatch] = useTranscriptComments();

  return useSaveComment({
    ...options,
    onSuccess: (res, v, ctx) => {
      dispatch({
        comment: res,
        parentCommentId: v.comment.parentCommentId,
        type: 'reply-saved',
      });

      options?.onSuccess?.(res, v, ctx);
    },
  });
}
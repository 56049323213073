import MatrixRows from './MatrixRows';
import { MatrixSliderQuestionProps } from './interfaces';
import styles from './style/MatrixSliderQuestion.css';

export const MatrixSliderQuestion = (props: MatrixSliderQuestionProps) => {
  return (
    <>
      <div className={styles.header}>Rows</div>
      <MatrixRows item={props.item} />
    </>
  );
};

export default MatrixSliderQuestion;
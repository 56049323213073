import { Discovery, Website } from '@consts/path';
import { Blog, FAQ } from '@consts/urls';
import { Menu } from './interfaces';

const product = {
  name: `Product`,
  items: [{
    name: `Customer Research`,
    to: Website.CustomerResearch,
  }, {
    name: `Quant2Qual`,
    to: Website.Quant2Qual,
  }, {
    name: `Collaboration Tools`,
    to: Website.CollaborationTools,
  }, {
    name: `Compliance`,
    to: Website.Compliance,
  },
  // {
  //   name: `Pricing`,
  //   to: Website.Pricing,
  // },
  // {
  //   name: `Request a Demo`,
  //   to: Website.Demo,
  // }
  ],
};

const learn = {
  name: `Learn`,
  items: [{
    name: `Discovery`,
    to: Discovery.Root,
  }, {
    name: `Blog`,
    href: Blog,
  }],
};

const about = {
  name: `About Us`,
  items: [{
    name: `Our Story`,
    to: Website.About,
  },
  // {
  //   name: `FAQs`,
  //   href: FAQ,
  // },
  // {
  //   name: `Careers`,
  //   to: Website.Careers,
  // },
  // {
  //   name: `Contact Us`,
  //   to: Website.Contact,
  // }
  ],
};

const legal = {
  name: `Legal`,
  items: [{
    name: `Member Terms & Conditions`,
    to: Website.Terms.Root,
  }, {
    name: `Client Terms & Conditions`,
    to: Website.ClientTerms,
  }, {
    name: `Privacy Policy`,
    to: Website.PrivacyPolicy.Root,
  }],
};

export const menus: Menu[] = [
  product,
  about,
  legal,
  learn,
];
import { SeeMore } from '@presentation/SeeMore';
import { SurveyQuestion } from '@/types/survey.question';
import { SurveyQuestionText } from 'components/Survey.QuestionText';
import styles from './style/ResponsesHeader.css';

type Props = {
  children?: React.ReactNode;
  question: SurveyQuestion;
};

export const SurveyResponsesHeader = ({ children, question }: Props) => {

  return (
    <div className={styles.root}>
      <SeeMore
        lineHeight={19}
        maxLines={10}>
        <SurveyQuestionText value={question.value} />
      </SeeMore>
      {children}
    </div>
  );
};

export default SurveyResponsesHeader;
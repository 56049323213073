import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import { LongTextResponseQuestion } from '@/types';
import MinimumResponseLengthSelect from './MinimumResponseLengthSelect';
import styles from './style/LongText.Question.css';

export type Props = {
  item: LongTextResponseQuestion.Question;
};

export const LongTextQuestionBuilder = ({ item }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();
  const updateMinLength = useCallback((characterMinimum: number) => {
    dispatch({
      type: 'update-question-settings',
      ordinal: item.ordinal,
      settings: {
        ...item.settings,
        characterMinimum,
      },
    });
  }, [item, dispatch]);

  return (
    <>
      <QuestionSettingTitle
        title="Character Minimum" />
      <div className={styles.select}>
        <MinimumResponseLengthSelect
          onSelect={updateMinLength}
          value={item.settings.characterMinimum} />
      </div>
    </>
  );
};

export default LongTextQuestionBuilder;
import { useCallback, useState, useMemo, ChangeEvent, memo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import MuiMenu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import { HardDrive } from 'react-feather';
import { ConnectedAccountType, ConnectedAccountProvider } from '@enums';
import { cx } from '@utils';
import { GoogleDrive, Zoom } from 'components/icons';
import styles from './style/Menu.css';
import stylesF from './style/FilesTable.css';

const useActionStyles = makeStyles({
  divider: {
    marginLeft: '4px',
    marginRight: '4px',
  },
  icon: {
    height: '20px',
    width: '20px',
  },
  menu: {
    borderRadius: '4px',
    transition: 'duration 0 !important',
    fontFamily: 'var(--font-reg)',
  },
  list: {
    padding: '0',
  },
});

type Props = {
  onMyComputerSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  onDriveSelect: () => void;
  onZoomSelect: () => void;
};

const selector = (state: Store.State) => state.user.connected.filter(c => c.typeId == ConnectedAccountType.Files);

export const Menu = memo(({ onMyComputerSelect, onDriveSelect, onZoomSelect }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const muiStyles = useActionStyles();

  const value = useMemo(() => ({
    close: handleClose,
  }), [handleClose]);

  const connectedFileAccounts = useSelector(selector);
  const hasDrive = !!connectedFileAccounts.find(a => a.providerId == ConnectedAccountProvider.Google);
  const hasZoom = !!connectedFileAccounts.find(a => a.providerId == ConnectedAccountProvider.Zoom);

  return (
    <>
      <div
        className={cx(styles.btn, { [styles.open]: open })}
        onClick={handleClick}>
        <div className={styles.btnLabel}>
          Upload
        </div>
        <Divider
          classes={{ root: muiStyles.divider }}
          variant="middle"
          orientation="vertical" />
        <KeyboardArrowDownIcon classes={{ root: muiStyles.icon }} />
      </div>
      <MuiMenu
        classes={{ paper: muiStyles.menu }}
        disableScrollLock={true}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={{
          appear: 0,
          enter: 0,
          exit: 0,
        }}>
        <MenuItem
          className={styles.menuItem}>
          <label className={styles.flexContainer}>
            <input
              className={stylesF.fileInput}
              multiple={true}
              onChange={onMyComputerSelect}
              type="file" />
            <HardDrive
              className={styles.menuIcon}
              size={22} /> My Computer
          </label>
        </MenuItem>
        {hasDrive &&
          <MenuItem
            className={cx(styles.menuItem, styles.flexContainer)}
            onClick={() => { onDriveSelect(); handleClose(); }}>
            <GoogleDrive
              className={styles.menuIcon}
              size={22} /> Google Drive
          </MenuItem>
        }
        {hasZoom &&
          <MenuItem
            className={cx(styles.menuItem, styles.flexContainer)}
            onClick={() => { onZoomSelect(); handleClose(); }}>
            <Zoom
              className={styles.menuIcon}
              size={22} /> Zoom
          </MenuItem>
        }
      </MuiMenu>
    </>
  );
});
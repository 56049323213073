import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RecordingConsentContainer } from '@containers/Call';
import { getLocationFor, useStepper } from '@utils';
import { AnimatedLoader } from 'components/ActivityIndicator';
import { ProjectOnboardingContext } from './Context';
import { useMatchProjectIdFromRoute } from './hooks';
import { Steps, StepScreens, selectProjectTestDriveSteps } from './Steps';
import { ProjectOnboardingContextValue } from './interfaces';

type Props = unknown;

export const ProjectTestDriveContainer = (props: Props) => {
  const getSteps = useSelector(selectProjectTestDriveSteps);
  const { projectId } = useMatchProjectIdFromRoute();

  const history = useHistory();

  const [steps, setSteps] = useState<Steps[]>([]);
  const [Screen, stepperActions, step] = useStepper(steps.map(step => StepScreens[step]));

  const initializeOnboarding = useCallback(() => {
    const steps = getSteps(projectId);
    setSteps(steps);
  }, [
    projectId,
    setSteps,
    getSteps,
  ]);

  const handleDisqualification = useCallback(() => {
    const loc = getLocationFor.project.previewComplete({
      id: projectId,
      isDisqualified: true,
    });

    history.push(loc.pathname);
  }, [
    history,
    projectId,
  ]);

  const handleCompletion = useCallback(() => {
    const loc = getLocationFor.project.previewComplete({
      id: projectId,
      isDisqualified: false,
    });

    history.push(loc.pathname);

  }, [
    history,
    projectId,
  ]);

  useEffect(() => {
    if (!steps.length) {
      initializeOnboarding();
    }
  }, [
    initializeOnboarding,
    steps,
  ]);

  const hasNextStep = useMemo(() => {
    return step !== steps.length - 1;
  }, [
    step,
    steps,
  ]);

  const next = useCallback(() => {
    if (!hasNextStep) {
      handleCompletion();
    } else {
      stepperActions.next();
    }
  }, [
    handleCompletion,
    hasNextStep,
    stepperActions,
  ]);

  if (!steps.length) {
    return (
      <AnimatedLoader
        color="var(--blue-l)"
        size={12} />
    );
  }

  const context: ProjectOnboardingContextValue = {
    back: stepperActions.back,
    onDisqualification: handleDisqualification,
    goTo: stepperActions.goTo,
    hasNextStep,
    next,
    projectId,
  };

  return (
    <ProjectOnboardingContext.Provider value={context}>
      <RecordingConsentContainer projectId={context.projectId}>
        <Screen />
      </RecordingConsentContainer>
    </ProjectOnboardingContext.Provider>
  );
};

export { ProjectOnboardingContext };
export default ProjectTestDriveContainer;
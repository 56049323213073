import { useCallback, useEffect, useMemo, useState, useRef, memo, Fragment } from 'react';
import { format } from 'date-fns';
import * as Twilio from '@twilio/conversations';
import { useChat, useConferenceInstance, IConference } from '@containers/Conference';
import { ParticipantWebMember } from '@/types/conferences.live';
import { ActivityIndicator } from 'components/ActivityIndicator';
import styles from './style/Bar.Right.Chat.css';

export function BarRightChat() {
  const chat = useChat();
  const top = useRef<HTMLDivElement>();
  const [input, setInput] = useState<string>('');

  useEffect(() => {
    chat.consumeAll();
  }, [chat, chat.messages]);

  useEffect(() => {
    if (top.current) {
      setTimeout(() => {
        if (top.current) {
          top.current.scrollTo({
            top: top.current.scrollHeight,
          });
        }
      }, 0);
    }
  }, [chat.messages, chat.ready]);

  const handleInputChange = useCallback((value: string) => {
    setInput(value);
  }, []);

  const handleSendMessage = useCallback(() => {
    chat.send(input);
    setInput('');
  }, [input, chat]);

  if (!chat.ready) {
    return (
      <div className={styles.root}>
        <div className={styles.header}>Chat</div>
        <ActivityIndicator.Spinner />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>Chat</div>
      <div className={styles.container}>
        <div
          ref={top}
          className={styles.top}>
          <div className={styles.messages}>
            <Messages />
          </div>
        </div>
        <div className={styles.bottom}>
          <Messenger
            input={input}
            onChange={handleInputChange}
            send={handleSendMessage} />
        </div>
      </div>
    </div>
  );
}

type MessageProps = {
  author: string;
  text: string;
  time: Date;
};

const Message = memo(({ author, time, text }: MessageProps) => {
  const authorClassname =
    author === 'Vancery'
      ? styles.authorVancery
      : author === 'You'
        ? styles.authorYou
        : styles.author;

  return (
    <div className={styles.message}>
      <div className={styles.messageHeader}>
        <div className={authorClassname}>{author}:</div>
        <div className={styles.time}>{format(time, 'h:mmaaaaa')}</div>
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  );
});

function Messages() {
  const { messages, participants } = useChat();
  const mappedMessages = useChatMessages(messages, participants);

  if (!messages.length) {
    return (
      <div className={styles.none}>
        No messages.
      </div>
    );
  }

  return (
    <Fragment>
      {mappedMessages.map(message => (
        <Message
          key={message.id}
          author={message.author}
          text={message.text}
          time={message.time} />
      ))}
    </Fragment>
  );
}

type MessengerProps = {
  input: string;
  onChange: (value: string) => void;
  send: () => void;
};

function Messenger({ input, onChange, send }: MessengerProps) {
  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault();

      const value = input.trim();
      if (!value.length) return;

      send();
    }
  }, [input, send]);

  return (
    <textarea
      className={styles.entry}
      placeholder="Type here..."
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      value={input} />
  );
}

function useChatMessages(messages: Twilio.Message[], participants: Twilio.Participant[]) {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const webParticipants = useMemo(() => instance.participants.filter(p => p.type === 'web' && p.auth !== 'guest') as ParticipantWebMember[], [instance.participants]);

  const getAuthor = useCallback((author: string, participant: Twilio.Participant) => {
    if (author === 'system') return 'Vancery';
    const [_, id] = author.split('_');

    const attributes = participant?.attributes as TwilioParticipantAttributes;
    const selectedParticipant = webParticipants.find(p => p.userId === +id);

    return selectedParticipant
      ? selectedParticipant.id === instance.pid
        ? 'You'
        : selectedParticipant.name
      : (attributes?.name ?? `Participant ${id}`);
  }, [instance.pid, webParticipants]);

  return useMemo(() => messages.map(message => ({
    id: message.sid,
    author: getAuthor(message.author, participants.find(m => m.sid === message.participantSid)),
    text: message.body,
    time: message.dateCreated,
  })), [getAuthor, messages, participants]);
}

type TwilioParticipantAttributes = {
  name: string;
};
import bg1 from 'static/images/website/careers/innovators-s.png';
import bg2 from 'static/images/website/careers/innovators-m.png';
import bg3 from 'static/images/website/careers/innovators-l.png';
import styles from './style/Innovation.css';

type Props = unknown;

export const Innovation = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.graphic}>
              <picture>
                <source
                  srcSet={bg3}
                  media="(min-width: 1000px)" />
                <source
                  srcSet={bg2}
                  media="(min-width: 800px)" />
                <img
                  alt="innovators"
                  className={styles.img}
                  src={bg1} />
              </picture>
            </div>
            <div className={styles.info}>
              <h2 className={styles.title}>Vancery is on a mission to support those who innovate.</h2>
              <div className={styles.subtitle}>We and our customers are on the cutting edge of technology.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Innovation.displayName = 'Careers.Innovation';
import { useSelector } from 'react-redux';
import { useProjectState } from './useProjectState';

const mapState = (state: Store.State) => state.pipeline.project;

const useProjectAnonymity = () => {
  const { latestProject } = useProjectState();
  const pipeline = useSelector(mapState);

  return pipeline[latestProject.id]?.anonymity;
};

export { useProjectAnonymity };
export default useProjectAnonymity;
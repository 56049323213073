import { useCallback, useMemo } from 'react';
import {
  EditorComponent,
  useEditorState,
} from '@remirror/react';
import { useSelectUser } from '@containers/Store/hooks';
import {
  useTranscriptComments,
} from '@containers/Transcript';
import { cx } from '@utils';
import { MentionsAutocomplete } from 'components/Remirror';
import { UserAvatar } from 'components/UserAvatar';
import { useTranscriptChainedCommands, useTranscriptHelpers } from './context';
import {
  CommentActions,
  CommentCancelButton as CancelButton,
  CommentSaveButton as SaveButton,
} from './Comment.Actions';
import { CommentEditor } from './Comment.Editor';
import { CommentFocusContainer } from './Comment.Focus';
import {
  useCanSaveComment,
  useCommentEditor,
  usePersistCommentHighlight,
  useSaveParentComment,
} from './hooks';
import styles from './style/Comment.New.css';

type Props = {
  className?: string;
  identifier: string;
  highlight: {
    id: number;
    identifier: string;
  };
};

const NewComment = ({ className, identifier, highlight }: Props) => {

  const editor = useEditorState();
  const me = useSelectUser();

  const {
    isLoading: commentSaving,
    mutateAsync: saveComment,
  } = useSaveParentComment();
  const {
    isLoading: highlightSaving,
    mutateAsync: persistHighlight,
  } = usePersistCommentHighlight();

  const canSave = useCanSaveComment();
  const { getHighlight } = useTranscriptHelpers();

  const removeComment = useRemoveNewComment(identifier);

  const save = useCallback((highlightId: number) => {

    return saveComment({
      comment: {
        highlightId,
        identifier,
        value: editor.doc,
        parentCommentId: null,
      },
    });

  }, [
    identifier,
    editor,
    saveComment,
  ]);

  const handlePost = useCallback(() => {
    if (highlight.id) {
      return save(highlight.id);
    } else {
      const fullHighlight = getHighlight(highlight.identifier);

      return persistHighlight({
        highlight: fullHighlight,
      }).then(h => {
        return save(h.dbId);
      });
    }
  }, [
    getHighlight,
    highlight,
    persistHighlight,
    save,
  ]);

  return (
    <CommentFocusContainer
      identifier={identifier}
      onBlur={removeComment}
      className={cx(styles.root, className)}>

      <div className={styles.author}>
        <UserAvatar
          size={32}
          pictureUrl={me.profile.pictureUrl} />
        <div className={styles.name}>{me.profile.fullname}</div>
      </div>

      <EditorComponent />
      <MentionsAutocomplete />

      <CommentActions>
        <SaveButton
          disabled={!canSave}
          implicitDisable={commentSaving || highlightSaving}
          loading={commentSaving || highlightSaving}
          onClick={handlePost}>
          Post
        </SaveButton>
        <CancelButton
          onClick={removeComment} />
      </CommentActions>
    </CommentFocusContainer>
  );
};

const NewCommentWithState = ({ className, ...props }: Props) => {

  const { manager } = useCommentEditor({
    placeholder: 'Comment or add others with @',
  });

  return (
    <CommentEditor
      className={className}
      autoFocus={true}
      editable={true}
      manager={manager}>
      <NewComment {...props} />
    </CommentEditor>
  );
};

const useRemoveNewComment = (identifier: string) => {
  const [state, dispatch] = useTranscriptComments();
  const commands = useTranscriptChainedCommands();

  const highlight = useMemo(() => {
    const comment = state.items.find(f => f.parentComment.identifier === identifier);
    return comment.highlight;
  }, [identifier, state.items]);

  const remove = useCallback(() => {
    dispatch({
      type: 'comment-removed',
      identifier,
    });

    if (!highlight.id) {
      commands
        .blurHighlight()
        .removeHighlights([highlight.identifier])
        .run();
    }

  }, [
    commands,
    dispatch,
    highlight,
    identifier,
  ]);

  return remove;
};

export { NewCommentWithState as NewComment };
import { useCallback, useMemo } from 'react';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks/useProjectState';
import * as enums from '@enums';
import { Contact } from '@/types';
import { Accordion } from 'components/Accordion';
import ProjectPipelineItem from './ProjectPipelineItem';
import SearchBanner from './SearchBanner';
import styles from './style/BasicPipeline.css';
import { usePipelineUsers } from './hooks';

const BasicPipeline = () => {
  const project = useProjectOrLatestChild();

  const renderUsers = useCallback((items: Contact[]) => {
    return items.map(x =>
      <ProjectPipelineItem
        item={x}
        key={x.id}
        project={project} />
    );
  }, [project]);

  const users = usePipelineUsers(project.id);

  const invited = useMemo(() => users.invited.concat(users.inreview), [users]);
  const available = useMemo(() => users.bookmarked.concat(users.recommended), [users]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        {project.projectStatusId === enums.ProjectStatus.Active &&
          <SearchBanner item={project} />}
        <Accordion
          className={styles.accordion}
          label="Active"
          tooltipText={tooltip.active}>
          {renderUsers(users.active)}
        </Accordion>
        <Accordion
          className={styles.accordion}
          label="Invited"
          tooltipText={tooltip.pending}>
          {renderUsers(invited)}
        </Accordion>
        <Accordion
          className={styles.accordion}
          label="Available"
          tooltipText={tooltip.available}>
          {renderUsers(available)}
        </Accordion>
        <Accordion
          collapsed
          className={styles.accordion}
          label="Inactive"
          tooltipText={tooltip.inactive}>
          {renderUsers(users.inactive)}
        </Accordion>
        <Accordion
          collapsed
          className={styles.accordion}
          label="Rejected"
          tooltipText={tooltip.removed}>
          {renderUsers(users.removed)}
        </Accordion>
      </div>
    </div>
  );
};

const tooltip = {
  active: `Experts marked as Active have completed project onboarding and are ready to engage on the project.`,
  available: `Experts marked as Available have not yet been invited to the project, but may be a good fit for the topic of the project.`,
  inactive: `Experts marked as Inactive have completed project onboarding, but have been hidden from the active view.`,
  pending: `Experts marked as Invited are in the process of completing project onboarding.`,
  removed: `Rejected Experts have not completed project onboarding, or have been removed from engaging on the project.`,
};

export { BasicPipeline };
export default BasicPipeline;
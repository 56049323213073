import { useCallback } from 'react';
import { TableInstance } from 'react-table';
import { cx } from '@utils';
import { GroupTableCell, GroupTableRow } from './Body';
import { GroupTableHeader, GroupTableHeaderCell, GroupTableHeaderRow } from './Header';
import { GroupTableSortBy } from './SortBy';

type TableClasses = {
  body?: string;
  header?: string;
  table?: string;
  td?: string;
  th?: string;
  tr?: string;
};

type Props = {
  classes?: TableClasses;
  instance: TableInstance;
  header?: boolean;
};

export const GroupTable = ({
  classes = {},
  instance: {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    rows,
  },
  header = true,
}: Props) => {

  const renderBody = useCallback(() => {
    return (
      <>
        {(page || rows).map(row => {
          prepareRow(row);
          const { key: rowKey, ...rowProps } = row.getRowProps();
          return (
            <GroupTableRow key={rowKey} {...rowProps} className={cx(rowProps.className, classes.tr)}>
              {row.cells.map(cell => {
                const { key: cellKey, ...cellProps } = cell.getCellProps();
                return (
                  <GroupTableCell key={cellKey} {...cellProps} className={cx(cellProps.className, classes.td)}>
                    {cell.render('Cell')}
                  </GroupTableCell>
                );
              })}
            </GroupTableRow>
          );
        })}
      </>
    );
  }, [
    classes,
    page,
    prepareRow,
    rows,
  ]);

  const renderHeader = useCallback(() => {
    return (
      <GroupTableHeader className={classes.header}>
        {headerGroups.map(headerGroup => (
          // eslint-disable-next-line react/jsx-key
          <GroupTableHeaderRow
            {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => {
              const { key: headerGroupKey, ...rest } = column.getHeaderProps();

              return (
                <GroupTableHeaderCell
                  key={headerGroupKey}
                  {...rest}
                  className={cx(rest.className, classes.th)}>
                  {column.canSort
                    ? <GroupTableSortBy
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                        toggleSort={column.toggleSortBy}>
                        {column.render('Header')}
                      </GroupTableSortBy>
                    : column.render('Header')
                  }
                </GroupTableHeaderCell>
              );
            })}
          </GroupTableHeaderRow>
        ))}
      </GroupTableHeader>
    );
  }, [
    classes,
    headerGroups,
  ]);

  const tableProps = getTableProps();

  return (
    <div {...tableProps} className={cx(tableProps.className, classes.table)}>
      {header && renderHeader()}
      <div className={classes.body}>
        {renderBody()}
      </div>
    </div>
  );
};

export default GroupTable;
import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/QuestionSettingTitle.css';

type Props = {
  className?: string;
  title: React.ReactText;
} & Partial<ChildrenProps>;

export const QuestionSettingTitle = memo(({ children, className, title }: Props) => {
  return (
    <div className={cx(styles.root, className)}>
      <span className={styles.title}>{title}</span>
      {children}
    </div>
  );
});

export default QuestionSettingTitle;
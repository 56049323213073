import { useCallback, useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import {
  useConjointAnalysisFormContext,
  useConjointAnalysisNavigationContext,
} from './Context';
import {
  useSubmitAnswer,
  useSurveyResponseGoBack,
  useSurveyStepperNavigation,
  useSurveyFormQuestionContext,
} from './hooks';
import { SurveyFormNavigationContext } from './Context';

type Props =
  ChildrenProps;

export const ConjointAnalysisNavigationContainer = (props: Props) => {
  const {
    answer,
    question,
  } = useSurveyFormQuestionContext<SurveyQuestionType.ConjointAnalysis>();

  const submitAnswer = useSubmitAnswer();
  const goBack = useSurveyResponseGoBack();
  const { currentSet } = useConjointAnalysisFormContext();
  const conjoint = useConjointAnalysisNavigationContext();
  const navigation = useSurveyStepperNavigation(question.ordinal);

  const back = useCallback(() => {
    if (currentSet === 1) {
      return goBack.mutateAsync();
    } else {
      return Promise.resolve(conjoint.back());
    }
  }, [
    conjoint.back,
    currentSet,
    goBack.mutateAsync,
  ]);

  const next = useCallback(() => {
    if (currentSet === question.sets.length) {
      return submitAnswer.mutateAsync();
    } else {
      return Promise.resolve(conjoint.next());
    }
  }, [
    answer,
    currentSet,
    conjoint.next,
    question.sets.length,
    submitAnswer.mutateAsync,
  ]);

  const showBack = useMemo(() => {
    return currentSet === 1
      ? navigation.showBack
      : true
    ;
  }, [
    currentSet,
    navigation.showBack,
  ]);

  const showComplete = useMemo(() => {
    return currentSet === question.sets.length
      ? navigation.showComplete
      : false
    ;
  }, [
    currentSet,
    question.sets.length,
    navigation.showComplete,
  ]);

  const value = {
    back: {
      hidden: !showBack,
      isError: goBack.isError,
      isLoading: goBack.isLoading,
      submit: back,
    },
    next: {
      disabled: conjoint.nextDisabled,
      isError: submitAnswer.isError,
      isLoading: submitAnswer.isLoading,
      submit: next,
    },
    showComplete,
  };

  return (
    <SurveyFormNavigationContext.Provider value={value}>
      {props.children}
    </SurveyFormNavigationContext.Provider>
  );
};

export default ConjointAnalysisNavigationContainer;
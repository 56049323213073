import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import * as api from '@api';
import { Payment } from '@api/interfaces';

type Data = Payment.Enterprise.GetBillingHistory.Response;

export default function usePaginatedEnterpriseBillingHistory<TData = Data>(
  groupId: number,
  page?: number,
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {

  return useQuery(['enterprise-billing-history', page], () => {
    return api.payment.enterprise.getBillingHistory({
      groupId,
      cursor: page ? +page : null,
    });
  }, {
    keepPreviousData: true,
    staleTime: 60*1000,
    ...options,
  });
}

export { usePaginatedEnterpriseBillingHistory };
import { Link } from 'react-router-dom';
import { getLocationFor } from '@utils';
import {
  UserDetailsProps,
} from './interfaces';
import styles from './style.css';

export const UserDetails = (props: UserDetailsProps) => (
  <Link
    className={styles.name}
    to={getLocationFor.user.profile({ slug: props.user.profile.slug })}>
    {`${props.user.profile.firstName} ${props.user.profile.lastName}`}
  </Link>
);

export default UserDetails;
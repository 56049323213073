import { cx } from '@utils';
import { Switch } from 'components/Switch';
import styles from './style.css';

type Props = {
  active: boolean;
  className?: string;
  label?: string;
  onClick: () => void;
};

export function SwitchField(props: Props) {
  return (
    <div className={cx(styles.root, props.className)}>
      {props.label && <div className={styles.label}>{props.label}</div>}
      <Switch
        active={props.active}
        onClick={props.onClick} />
    </div>
  );
}

export default SwitchField;
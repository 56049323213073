import { Folder } from 'react-feather';
import { Cell } from 'react-table';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { ObjectAccessContainer } from '@containers/ObjectAccess/ObjectAccessContainer';
import { FolderMenuContainer } from '@containers/WorkspaceFolders/FolderMenuContainer';
import { useSelectObject } from '@containers/Store';
import { getLocationFor } from '@utils';
import { FolderActionsContextMenuPopper } from 'components/WorkspaceFolders/FolderActionsContextMenu';
import { FoldersTableItem } from './interfaces';
import styles from './style/FoldersTableCells.css';

export function Name({ row: { original: data } }: Cell<FoldersTableItem>) {

  const to = getLocationFor.workspaces.folder({
    folderId: data.id,
  });

  return (
    <div className={styles.name}>
      <Folder
        className={styles.icon}
        color="#737373"
        size={26} />
      <Link className={styles.link} to={to}>
        <div className={styles.trunc}>{data.name}</div>
      </Link>
    </div>
  );
}

export function Owner({ row: { original: data } }: Cell<FoldersTableItem>) {
  return data.owner;
}

export function CreatedOn({ row: { original: data } }: Cell<FoldersTableItem>) {
  return format(data.createdOn, 'MM/dd/yyyy');
}

export function Actions({ row: { original: data } }: Cell<FoldersTableItem>) {

  const object = useSelectObject(data.objectId);

  return (
    <ObjectAccessContainer objectId={object.id} workspaceId={object.workspaceId}>
      <FolderMenuContainer folderId={data.id}>
        <FolderActionsContextMenuPopper />
      </FolderMenuContainer>
    </ObjectAccessContainer>
  );
}
import { useReducer } from 'react';
import {
  SurveyVersion,
} from '@/types';
import { SurveyBuilder } from './interfaces';
import { SurveyBuilderStateContext } from './Context';
import {
  editingReducer,
  questionsReducer,
  quotasReducer,
  sectionsReducer,
  templateReducer,
} from './utils/state';
import * as template from './utils/template';

type Props = {
  initialValue?: SurveyVersion;
} & ChildrenProps;

const InitialValue = template.custom.initialSurveyData;

export const SurveyBuilderState = ({
  children,
  initialValue = InitialValue,
}: Props) => {
  const reducer = useReducer(surveyStateReducer, {
    committed: {
      questions: initialValue?.questions || [],
      quotas: initialValue?.quotas || [],
    },
    editing: {
      logicOrdinal: null,
      questionOrdinal: null,
      quota: {
        identifier: null,
        isNew: false,
      },
    },
    savedSurvey: initialValue,
    survey: initialValue,
  });

  return (
    <SurveyBuilderStateContext.Provider value={reducer}>
      {children}
    </SurveyBuilderStateContext.Provider>
  );
};

function batchActions(state: SurveyBuilder.State, { actions }: SurveyBuilder.BatchActions.State): SurveyBuilder.State['survey'] {

  let survey = state.survey;
  for (const action of actions) {
    survey = surveyReducer({
      ...state,
      survey,
    }, action);
  }

  return survey;
}

function surveyReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyBuilder.State['survey'] {

  switch (action.type) {

    case 'reset-survey':
      return action.value;

    case 'batch-actions':
      return batchActions(state, action);

    default:
      return {
        ...state.survey,
        questions: questionsReducer(state, action),
        quotas: quotasReducer(state, action),
        sections: sectionsReducer(state, action),
        template: templateReducer(state.survey.template, action),
      };
  }
}

function surveyStateReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyBuilder.State {

  return {
    ...editingReducer(state, action),
    survey: surveyReducer(state, action),
  };
}

export default SurveyBuilderState;
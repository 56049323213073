import { useCallback } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeElements, StripeNewCardContainer, useStripeNewCardForm } from '@containers/Stripe';
import { cx } from '@utils';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { FormAddress } from './FormAddress';
import FormInput from './FormInput';
import { FormValidatedField, FormAddressItem, OnErrorEvent, OnSaveEvent, OnTokenEvent } from './interfaces';
import { updateAddressSchema } from './form-utils';
import styles from './style.css';

type Props = {
  className?: string;
  isLoading?: boolean;
  onError?: OnErrorEvent;
  onSave?: OnSaveEvent;
  onToken: OnTokenEvent;
};

type ComponentProps =
  Pick<Props, 'className'>;

function NewCardComponent(props: ComponentProps) {
  const {
    card: [card, setCard],
    form: [form, setForm],
    state: {
      error,
      loading,
    },
    submit,
  } = useStripeNewCardForm();

  const handleInputChange = useCallback(<T extends Target>(e: React.ChangeEvent<HTMLInputElement & T>) => {
    setForm({
      ...form,
      [e.target.name]: {
        ...form[e.target.name],
        value: e.target.value,
      },
    });
  }, [form, setForm]);

  const handleAddressChange = useCallback((address: FormAddressItem) => {
    setForm({
      ...form,
      address: updateAddressSchema(address),
    });
  }, [form, setForm]);

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    return submit();

  }, [submit]);

  const canSave = !loading;

  return (
    <form className={cx(props.className)} onSubmit={handleSubmit}>
      <div className={styles.row}>
        <label className={cx(styles.label, { [styles.labelError]: !!form.cardError })} htmlFor="card">
          Card Details <span className={styles.star}>*</span>
        </label>
        <CardElement
          id="card"
          options={CardElementOpts}
          onChange={setCard} />
      </div>
      <FormInput
        className={styles.row}
        error={form.name.error}
        placeholder='Full Name'
        label='Account Holder Name'
        name='name'
        value={form.name.value}
        required={true}
        onChange={handleInputChange} />
      <FormAddress
        address={form.address}
        onChange={handleAddressChange} />
      <div className={styles.error}>
        {error}
      </div>
      <div className={styles.actions}>
        <ButtonActivityIndicator
          color='primary'
          loading={loading}
          disabled={!canSave}
          className={styles.save}
          variant='brick'>
          Save
        </ButtonActivityIndicator>
      </div>
    </form>
  );
}

export function NewCard({ className, ...props }: Props) {
  return (
    <StripeElements>
      <StripeNewCardContainer {...props}>
        <NewCardComponent className={className} />
      </StripeNewCardContainer>
    </StripeElements>
  );
}

export default NewCard;

type NewCardState = {
  cardError?: string;
  name: FormValidatedField;
  address: FormAddressItem;
};

type FormValues = Omit<NewCardState, 'cardError'>;

type Target<T extends FormValues = FormValues, K extends keyof T = keyof T> = {
  name: K;
  value: T[K];
};

const CardElementOpts = {
  classes: {
    base: styles.base,
    focus: styles.focus,
  },
  style: {
    base: {
      fontSize: '14px',
      color: '#323232',
      '::placeholder': {
        color: '#bdbdbd',
      },
    },
    invalid: {
      color: '#e92d21',
    },
  },
  hidePostalCode: true,
};
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@store/actions';
import { useSelectProjectScreeningQuestions } from '@containers/Store';
import { useScreeningQuestions, ScreenerBuilderContext  } from '@containers/SurveyBuilder';
import { FormButtons } from '@presentation';
import * as api from '@services/api';
import { Project } from '@/types';
import { Editable } from 'components/Editable';
import { ScreenerBuilder, ScreeningQuestionsPreview } from 'components/ScreeningQuestions';
import styles from './style.css';

const ScreeningQuestionsEditable = ({
  projectId,
  onCancel,
  onSave,
}: {
  projectId: number;
  onCancel: () => void;
  onSave: (items: string[]) => Promise<void>;
}) => {
  const initialQuestions = useSelectProjectScreeningQuestions(projectId);
  const screener = useScreeningQuestions(initialQuestions);

  const canSubmit = () => {
    return !screener.questions.some(s => !s);
  };

  return (
    <>
      <div className={styles.body}>
        <div className={styles.row}>
          <div className={styles.label}>Screening Questions</div>
          <div className={styles.editableSurveyField}>
            <ScreenerBuilderContext.Provider value={screener}>
              <ScreenerBuilder />
            </ScreenerBuilderContext.Provider>
          </div>
        </div>
      </div>
      <FormButtons
        disabled={!canSubmit()}
        onCancel={onCancel}
        onSubmit={() => onSave(screener.questions)} />
    </>
  );
};

const ScreeningQuestions = ({ projectId }) => {
  const questions = useSelectProjectScreeningQuestions(projectId);
  return (
    <div className={styles.readOnlyRoot}>
      <div className={styles.body}>
        <div className={styles.row}>
          <div className={styles.label}>Screening Questions</div>
          <div className={styles.readOnlySurvey}>
            {questions && questions.length ?
              <ScreeningQuestionsPreview items={questions} /> :
              <div className={styles.surveyPlaceholder}>No additional screening questions are required.</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectScreeningQuestions = ({
  editable,
  item,
}: {
  editable: boolean;
  item: Project;
}) => {
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();

  const handleSave = (screeningQuestions: string[]) => {
    return api.project.updateProjectScreeningQuestions({
      projectId: item.id,
      screeningQuestions,
    })
    .then(data => {
      return {
        ...item,
        screeningQuestions,
      };
    })
    .then(data => dispatch(actions.projectUpdated({
      projectId: item.id,
      project: data,
    })))
    .then(toggleEditing);
  };

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const renderScreeningQuestions = () => {
    if (editing) {
      return (
        <ScreeningQuestionsEditable
          projectId={item.id}
          onCancel={toggleEditing}
          onSave={handleSave} />
      );
    }

    if (editable) {
      return (
        <Editable
          onClick={toggleEditing}>
          <ScreeningQuestions projectId={item.id} />
        </Editable>
      );
    }

    return (
      <ScreeningQuestions projectId={item.id} />
    );
  };

  return (
    <div className={styles.card}>
      {renderScreeningQuestions()}
    </div>
  );
};

export default ProjectScreeningQuestions;

import { useContext, Dispatch } from 'react';
import { SurveyQuotaType } from '@enums';
import { createNamedContext } from '@utils';
import { SurveyQuota as SQ } from '@/types';
import { SurveyQuota } from './interfaces';

export type SurveyQuotaStateContextValue =
  [SurveyQuota.State, Dispatch<SurveyQuota.Action>];

export const SurveyQuotaStateContext = createNamedContext<SurveyQuotaStateContextValue>(null, 'SurveyQuotaStateContext');

export const useSurveyQuotaState = <T extends SurveyQuotaType = SurveyQuotaType>() => {
  const [state, dispatch] = useContext(SurveyQuotaStateContext);
  return [state as SQ.Record<T>, dispatch] as const;
};
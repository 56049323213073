import { useFlexLayout, usePagination, useSortBy, useTable } from 'react-table';
import { GroupTable } from '@presentation/GroupTable';
import { useFoldersTableColumns } from 'components/WorkspaceFolders/hooks/useFoldersTableColumns';
import { FoldersTableItem } from 'components/WorkspaceFolders/interfaces';
import styles from './style/FoldersTable.css';

type Props = {
  data: FoldersTableItem[];
  maxItems: number;
};

export const FoldersPanelTable = ({ data, maxItems }: Props) => {
  const columns = useFoldersTableColumns();

  const tableInstance = useTable<FoldersTableItem>(
    {
      columns,
      data,
      disableSortBy: true,
      initialState: {
        pageIndex: 0,
        pageSize: maxItems,
        sortBy: [{
          id: 'createdOn',
          desc: true,
        }],
      },
    },
    useFlexLayout,
    useSortBy,
    usePagination,
  );

  const tableClasses = {
    td: styles.td,
    tr: styles.tr,
  };

  return (
    <GroupTable
      classes={tableClasses}
      instance={tableInstance} />
  );
};

export default FoldersPanelTable;
import { useCallback } from 'react';
import {
  useQueryParams, StringParam,
  NumberParam, QueryParamConfig,
  encodeString, decodeString,
} from 'use-query-params';
import { SurveyResponses } from '../interfaces';

type Bucket = SurveyResponses.QueryState.Bucket;

const BucketParam: QueryParamConfig<Bucket, Bucket> = {
  encode: (bucket: Bucket) => bucket ? encodeString(`[${bucket.minValue}-${bucket.maxValue})`) : undefined,
  decode: (bucket: string) => {
    const s = decodeString(bucket);

    if (!s) return null;

    const regex = /\[(-?[0-9]*)-(-?[0-9]*)\)/g;
    const matches = regex.exec(s);

    const minValue = matches[1];
    const maxValue = matches[2];

    if (minValue === undefined || maxValue === undefined) return null;

    return {
      minValue: +minValue,
      maxValue: +maxValue,
    } as Bucket;
  },
};

export const useMatchResponsesStateFromUrl = () => {

  const [query, setQuery] = useQueryParams({
    bucket: BucketParam,
    key: StringParam,
    optionId: NumberParam,
    page: NumberParam,
    projectId: NumberParam,
    questionId: NumberParam,
    rowId: NumberParam,
    attributeId: NumberParam,
    levelId: NumberParam,
  });

  const reset = useCallback(() => {
    setQuery({
      bucket: undefined,
      key: undefined,
      optionId: undefined,
      page: undefined,
      projectId: undefined,
      questionId: query.questionId,
      rowId: undefined,
      attributeId: undefined,
      levelId: undefined,
    });
  }, [
    query.questionId,
    setQuery,
  ]);

  return [query, setQuery, reset] as const;
};

export default useMatchResponsesStateFromUrl;
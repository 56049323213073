import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { useOnResize } from '@utils';

export const useCommentsBounds = () => {

  const [element, ref] = useState<HTMLDivElement>();
  const [bounds, setBounds] = useState<RefPoint>({
    top: 0,
    left: 0,
    width: 0,
  });

  const updateBounds = useCallback((el: HTMLDivElement) => {
    const rect = el.getBoundingClientRect();

    setBounds({
      top: el.offsetTop + rect.height + 20,
      left: el.offsetLeft,
      width: rect.width,
    });
  }, []);

  const [observer] = useState(() => new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
    if (entry) {
      updateBounds(entry.target as HTMLDivElement);
    }
  }));

  useLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element, observer]);

  const handleResize = useCallback(() => {
    updateBounds(element);
  }, [element, updateBounds]);

  useOnResize(handleResize);

  return [ref, bounds] as const;
};

type RefPoint = {
  top: number;
  left: number;
  width: number;
};
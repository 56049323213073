import mixpanel from 'mixpanel-browser';
import { Share } from './interfaces';
import { getProjectTypeLabel } from './utils';

export function projectByClient({ project, type }: Share.ProjectByClient) {
  mixpanel.track('share:project:by-client', {
    id: project.id,
    name: project.name,
    type: getProjectTypeLabel(type),
  });
}
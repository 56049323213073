import { useCallback, memo } from 'react';
import CameraOnIcon from '@mui/icons-material/Videocam';
import CameraOffIcon from '@mui/icons-material/VideocamOff';
import { cx } from '@utils';
import styles from './style/Camera.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
  value: boolean;
  visible?: boolean;
  onClick?: (value: boolean) => void;
};

export const Camera = memo(({ className, children, value, visible, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick?.(!value);
  }, [onClick, value]);

  if (!visible) return null;

  return (
    <div
      className={cx(styles.camera, className)}
      onClick={handleClick}>
      {children ?? <CameraIcon value={value} />}
    </div>
  );
});

const CameraIcon = memo(({ value }: { value: boolean; }) => {
  return value
    ? (
      <div className={styles.iconCamera}>
        <CameraOnIcon />
      </div>
    )
    : (
      <div className={styles.iconCameraOff}>
        <CameraOffIcon />
      </div>
    );
});
import { Seniority } from '@enums';

const MaxHourlyRate = 1500;
const SliderRange = 300;
const RangeModifier = 0.4;

export const calculateMinSliderRate = (rate: number) => {
  return rate - calculateSliderRange(rate);
};

export const calculateMaxSliderRate = (rate: number, seniority: Seniority) => {
  if (seniority === Seniority.CLevel) {
    return MaxHourlyRate;
  }

  return rate + calculateSliderRange(rate);
};

function calculateSliderRange(rate: number) {
  return rate <= (SliderRange * RangeModifier)
    ? rate * RangeModifier
    : SliderRange * RangeModifier;
}
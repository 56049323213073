import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProjectRateOnboarding } from '@containers/RateOnboarding/Project';
import { useStepper } from '@utils';
import { useProjectOnboardingContext } from './hooks/useProjectOnboardingContext';
import { selectRateScreens } from './selectors';

export const RateStepper = () => {
  const ctx = useProjectOnboardingContext();
  const getScreens = useSelector(selectRateScreens);

  const [screens, setScreens] = useState<React.ComponentType[]>([]);

  useEffect(() => {
    setScreens(getScreens(ctx.projectId));
  }, []);

  const [Screen, actions, step] = useStepper(screens);

  const back = () => {
    if (step === 0) {
      ctx.back();
    } else {
      actions.back();
    }
  };

  const next = () => {
    if (step === screens.length - 1) {
      ctx.next();
    } else {
      actions.next();
    }
  };

  if (!screens.length) {
    return null;
  }

  return (
    <ProjectRateOnboarding
      back={back}
      next={next}
      projectId={ctx.projectId}
      step={step}>
      <Screen />
    </ProjectRateOnboarding>
  );
};

export default RateStepper;
import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { SurveyQuestionType, SurveyQuotaType } from '@enums';
import { useSurveyQuotaState } from '@containers/SurveyBuilder.Quota';
import { AddQuotaSetButton } from '@presentation/SurveyBuilder';
import { AdvancedSurveyQuotaSetBuilder } from './Builder.Advanced.Set';
import { QuotaBuilderTitle } from './Title';
import styles from './style/Builder.Advanced.css';

const minSets = 1;
const maxSets = 3;

const useValidQuestionItems = () => {
  const [surveyState] = useSurveyBuilderState();

  const questions = useMemo(() => {
    const validTypes = [
      SurveyQuestionType.MultipleChoice,
      SurveyQuestionType.Multiselect,
    ];

    return surveyState.survey.questions
      .filter(f => validTypes.includes(f.typeId));

  }, [
    surveyState.survey.questions,
  ]);

  return questions;
};

export const AdvancedSurveyQuotaBuilder = () => {
  const [state, dispatch] = useSurveyQuotaState<SurveyQuotaType.Advanced>();

  const validQuestions = useValidQuestionItems();
  const filteredQuestions = useMemo(() => {
    return validQuestions
      .filter(f => !state.data.sets.some(s => s.question?.identifier === f.base.identifier));
  }, [validQuestions, state.data.sets]);

  const canAddSet = useMemo(() => {
    return state.data.sets.length < Math.min(maxSets, validQuestions.length);
  }, [
    validQuestions.length,
    state.data.sets.length,
  ]);

  const canRemoveSet = useMemo(() => {
    return state.data.sets.length > minSets;
  }, [
    state.data.sets.length,
  ]);

  const addSet = useCallback(() => {
    dispatch({
      type: 'add-advanced-item',
    });
  }, [dispatch]);

  return (
    <>
      <QuotaBuilderTitle label="Questions" />
      {state.data.sets.map(set => (
        <AdvancedSurveyQuotaSetBuilder
          key={set.identifier}
          className={styles.item}
          canRemove={canRemoveSet}
          questions={filteredQuestions}
          set={set} />
      ))}
      <div className={styles.footer}>
        {canAddSet &&
          <AddQuotaSetButton onClick={addSet} />}
      </div>
    </>
  );
};

export default AdvancedSurveyQuotaBuilder;
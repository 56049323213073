import { useCallback, useReducer, Reducer } from 'react';
import { SurveyActionType } from '@enums';
import { SurveyQuestion } from '@/types';
import { DefaultLogic } from './interfaces';
import { BaseLogicContext } from './Context';
import { baseActionReducer } from './reducer.base';
import { useGetDefaultIdentifier } from './hooks';

type Props = {
  item: SurveyQuestion;
} & ChildrenProps;

export const DefaultQuestionLogicContainer = (props: Props) => {

  const [state, dispatch] = useReducer<Reducer<DefaultLogic.State, DefaultLogic.Action>>(defaultReducer, props.item.logic);

  const getDefaultIdentifier = useGetDefaultIdentifier();

  const updateActionType = useCallback((type: SurveyActionType) => {
    dispatch({
      action: {
        identifier: getDefaultIdentifier(type),
        type,
      },
      type: 'update-base-action',
    });

  }, [
    dispatch,
    getDefaultIdentifier,
  ]);

  const updateActionIdentifier = useCallback((identifier: string) => {
    dispatch({
      action: {
        identifier,
        type: state.base.action.type,
      },
      type: 'update-base-action',
    });
  }, [
    dispatch,
    state.base.action,
  ]);

  const base = {
    item: state.base,
    updateActionIdentifier,
    updateActionType,
  };

  return (
    <BaseLogicContext.Provider value={base}>
      {props.children}
    </BaseLogicContext.Provider>
  );
};

function defaultReducer(state: DefaultLogic.State, action: DefaultLogic.Action) {
  return {
    base: baseActionReducer(state.base, action),
  };
}

export default DefaultQuestionLogicContainer;
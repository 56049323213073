import Axios, { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import * as api from '@api';
import { RecentProfile } from '@/types';

export default function useRecentProfiles<TData = RecentProfile[]>(
  options?: UseQueryOptions<RecentProfile[], AxiosError, TData>
) {
  return useQuery('recently-viewed-profiles', async () => {
    const source = Axios.CancelToken.source();

    const p = api.users.getRecentProfiles({
      cancelToken: source.token,
    });

    p.cancel = () => {
      source.cancel();
    };

    return p.then(result => result.items);
  }, {
    staleTime: 60 * 1000,
    ...options,
  });
}

export { useRecentProfiles };

import { useState } from 'react';
import { path } from '@consts';
import { ButtonOutlined } from '@presentation';
import { cx } from '@utils';
import { Button } from 'components/Button';
import styles from './style/Landing.css';

type Props = unknown;

export const Landing = (props: Props) => {
  const [loaded, setLoaded] = useState(false);

  const classes = cx(styles.animation, {
    [styles.ready]: loaded,
  });

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={classes}>
            <video
              className={styles.video}
              autoPlay
              loop
              muted
              onPlay={e => setLoaded(true)}
              playsInline
              preload="auto"
              src={url.video} />
          </div>
          <div className={styles.info}>
            <h1 className={styles.title}>Upgrade your quantitative and qualitative research into a single workflow</h1>
            <div className={styles.subtitle}>Vancery’s survey technology is designed to give you perfect data quality and the option to engage your respondents for in-depth phone calls, quick message follow ups, or diagnostic screenshares at will.</div>
            {/* <div className={styles.btnset}>
              <ButtonOutlined
                className={styles.btn}
                color="blue"
                to={path.Website.Demo}
                variant="rounded">
                Demo
              </ButtonOutlined>
              <Button
                className={styles.btn}
                color="primary"
                to={path.Website.Pricing}>
                View Pricing
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

Landing.displayName = 'Quant2Qual.Landing';

const url = {
  video: `https://cdn.vancery.com/videos/q2q-loop.mp4`,
};
import { useCallback } from 'react';
import { useQuestionTaggingBuilderState } from '@containers/SurveyBuilder.Tagging/hooks/useQuestionTaggingBuilderState';
import { SurveyQuestionTaggingActionType, SurveyQuestionTaggingValueState } from '@enums';
import { QuestionOption } from '@presentation/SurveyPreview';
import { SurveyQuestionOption } from '@/types';
import * as api from '$admin/api';
import TagRow from './TagRow';
import { OptionsTaggingBuilderProps, FormTagValueState, FormTagRelationship, TagRowOnChangeItem } from './interfaces';
import styles from './style/OptionsTaggingBuilder.css';

type Props
  = OptionsTaggingBuilderProps;

export default function OptionsTaggingBuilder({ item }: Props) {
  const { updateQuestionResponseTagging } = useQuestionTaggingBuilderState();

  const handleChange = useCallback(async (option: SurveyQuestionOption, change: TagRowOnChangeItem) => {

    async function getEntity() {
      if (change.entity?.isCustom) {
        const result = await api.products.createProduct({
          name: change.entity.name,
        });
        return result.product;
      }

      if (change.entity) {
        return {
          id: +change.entity.id,
          name: change.entity.name,
        };
      }

      return null;
    }

    const entity = await getEntity();

    const valueState = FormTagValueStateMap[change.valueState];
    const actionType = FormTagRelationshipMap[change.relationship];

    const existing = item.tagging?.response ?? [];

    const updated = existing
      .filter(o => o.condition.value.option.ordinal !== option.ordinal)
      .concat(entity ? {
        action: [{
          entities: [{ id: +entity.id, name: entity.name }],
          type: actionType,
          weight: 1,
        }],
        condition: {
          value: {
            option: {
              ordinal: option.ordinal,
            },
            state: valueState,
          },
        },
      } : null)
      .filter(Boolean);

    updateQuestionResponseTagging(updated);
  }, [item, updateQuestionResponseTagging]);

  return (
    <div>
      {item.options.map((option, i) => {
        const tagging = item.tagging?.response?.find(f => f.condition.value.option.ordinal === option.ordinal);
        const valueState = tagging ? TaggingValueStateMap[tagging.condition.value.state] : null;
        const relationship = tagging ? TaggingActionMap[tagging.action[0].type] : null;
        const entity = tagging ? tagging.action[0].entities.map(e => ({ id: e.id, name: e.name ?? 'n/a'}))[0] : null;

        return (
          <div className={styles.row} key={i}>
            <QuestionOption
              className={styles.question}
              item={option} />
            <TagRow
              valueState={valueState}
              relationship={relationship}
              entity={entity}
              onChange={item => handleChange(option, item)} />
            {/* <AddTag onClick={() => {}} /> */}
          </div>
        );
      })}
    </div>
  );
}

const FormTagValueStateMap = {
  [FormTagValueState.Selected]: SurveyQuestionTaggingValueState.Selected,
  [FormTagValueState.NotSelected]: SurveyQuestionTaggingValueState.NotSelected,
};

const TaggingValueStateMap = {
  [SurveyQuestionTaggingValueState.Selected]: FormTagValueState.Selected,
  [SurveyQuestionTaggingValueState.NotSelected]: FormTagValueState.NotSelected,
};

const FormTagRelationshipMap = {
  [FormTagRelationship.UserOfProduct]: SurveyQuestionTaggingActionType.ProductPositiveAffinity,
  [FormTagRelationship.NotUserOfProduct]: SurveyQuestionTaggingActionType.ProductNegativeAffinity,
};

const TaggingActionMap = {
  [SurveyQuestionTaggingActionType.ProductPositiveAffinity]: FormTagRelationship.UserOfProduct,
  [SurveyQuestionTaggingActionType.ProductNegativeAffinity]: FormTagRelationship.NotUserOfProduct,
};
import {
  QuestionRow,
} from '@presentation/SurveyPreview';
import { cx } from '@utils';
import { MatrixGridQuestion, MatrixMultiselectQuestion, SurveyQuestionMatrixRow } from '@/types';
import { QuestionOptionsLogicPreview } from './QuestionOptionsLogic';
import styles from './style/MatrixGridLogicPreview.css';

type LogicPreviewProps = {
  className?: string;
  item: MatrixGridQuestion.Question | MatrixMultiselectQuestion.Question;
};

type RowLogicPreviewProps = {
  className?: string;
  item: MatrixGridQuestion.Question | MatrixMultiselectQuestion.Question;
  row: SurveyQuestionMatrixRow;
};

export const MatrixRowLogicPreview = (props: RowLogicPreviewProps) => {
  const logic = props.item.logic.response
    .filter(f => f.condition.value.row.identifier === props.row.base.identifier);

  return (
    <div className={props.className}>
      <QuestionRow
        className={styles.rowValue}
        item={props.row} />
      {!!logic.length &&
        <QuestionOptionsLogicPreview
          className={styles.options}
          includeOrdinalColumn={true}
          logic={logic}
          options={props.item.options} />
      }
    </div>
  );
};

export const MatrixLogicPreview = (props: LogicPreviewProps) => {

  return (
    <div className={cx(styles.root, props.className)}>
      {
        props.item.matrixRows.map((row, i) => (
          <MatrixRowLogicPreview
            key={i}
            className={styles.row}
            item={props.item}
            row={row} />
        ))
      }
    </div>
  );
};
import { PaidMessagingContainer } from '@containers/PlatformMessage';
import { RequestCallContainer } from '@containers/RequestCall';
import { Contact } from '@/types';
import { useProfileMessagingAction, useProfileRequestCallAction } from 'components/UserProfile/hooks';
import { MemberCardContent } from './MemberCard.Content';
import { CardTooltip } from './HtmlTooltip';

type Props = {
  children: React.ReactNode;
  contact: Pick<Contact, 'id' | 'career' |'profile'>;
  className?: string;
};

const MemberBaseballCardWithState = ({
  children,
  className,
  contact,
}: Props) => {

  const params = {
    user: {
      id: contact.id,
      profile: contact.profile,
    },
  };
  const [canInitiateMessages, initiateMessage] = useProfileMessagingAction(params);
  const [canInitiateCalls, initiateCall] = useProfileRequestCallAction(params);

  return (
    <>
      <CardTooltip
        className={className}
        title={(
          <MemberCardContent
            canInitiateCalls={canInitiateCalls}
            canInitiateMessages={canInitiateMessages}
            contact={contact}
            initiateCall={initiateCall}
            initiateMessage={initiateMessage}
            showRate={false} />
        )}>
        {children}
      </CardTooltip>
    </>
  );
};

export const MemberBaseballCard = (props: Props) => (
  <PaidMessagingContainer>
    <RequestCallContainer>
      <MemberBaseballCardWithState {...props} />
    </RequestCallContainer>
  </PaidMessagingContainer>
);

export default MemberBaseballCard;
import { memo, useCallback, useState } from 'react';
import { getOtherAdHocCallParticipant } from '@containers/Scheduler/utils.calls';
import { useSelectContact, useSelectProject, useSelectCall, useSelectUser } from '@containers/Store';
import { useHasClientRole } from '@containers/User';
import * as enums from '@enums';
import { RatingIcon } from '@presentation/RatingIcon';
import { cx } from '@utils';
import { useSubmitCallRating } from '@utils/api';
import { AdHocCall, ProjectCall } from '@/types';
import { Button } from 'components/Button';
import Toast from 'components/Toast';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { CallRatingProps } from './interfaces';
import styles from './style.css';

type Props =
  CallRatingProps;

const CreatorHeader = memo(({
  pictureUrl,
  title,
}: {
  pictureUrl: string;
  title: string;
}) => {
  return (
    <>
      {pictureUrl &&
        <div className={styles.avatar}>
          <UserAvatar
            size={75}
            pictureUrl={pictureUrl} />
        </div>
      }
      <div className={styles.ratingTitle}>{title}</div>
    </>
  );
});

const ParticipantHeader = memo(({
  subject,
}: { subject: string; }) => {
  const title = `How was your ${subject} call?`;

  return (
    <div className={styles.ratingTitle}>{title}</div>
  );
});

const ProjectCallClientHeader = ({ call }: { call: ProjectCall; }) => {
  const project = useSelectProject(call.projectId);
  const contact = useSelectContact(call.userId);

  const title = `How was your ${project.name} call with ${contact.profile.fullname}?`;

  return (
    <CreatorHeader
      pictureUrl={contact.profile.pictureUrl}
      title={title} />
  );
};

const ProjectCallExpertHeader = ({ call }: { call: ProjectCall; }) => {
  const project = useSelectProject(call.projectId);

  return (
    <ParticipantHeader subject={project.name} />
  );
};

const AdHocCallCreatorHeader = ({ call }: { call: AdHocCall; }) => {
  const me = useSelectUser();
  const otherParticipantid = getOtherAdHocCallParticipant(call, me.id);
  const contact = useSelectContact(otherParticipantid);

  const title = `How was your ${call.request.subject} call with ${contact.profile.fullname}?`;

  return (
    <CreatorHeader
      pictureUrl={contact.profile.pictureUrl}
      title={title} />
  );
};

const AdHocCallRecipientHeader = ({ call }: { call: AdHocCall; }) => {

  return (
    <ParticipantHeader subject={call.request.subject} />
  );
};

const CallRating = ({
  callId,
  onSubmit,
}: Props) => {
  const me = useSelectUser();
  const call = useSelectCall(callId);
  const hasClientRole = useHasClientRole();

  const [rating, setRating] = useState<enums.CallRating>(null);

  const { mutateAsync, ...submit } = useSubmitCallRating({ callId }, {
    onSuccess: _ => {
      onSubmit();
    },
    onError: e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
    },
  });

  const handleSubmit = useCallback(() => {
    return mutateAsync({
      isSkipped: false,
      rating,
    });
  }, [
    mutateAsync,
    rating,
  ]);

  const handleSkip = useCallback(() => {
    return mutateAsync({
      isSkipped: true,
      rating: null,
    });
  }, [
    mutateAsync,
  ]);

  const renderHeader = useCallback(() => {
    if (call.typeId === enums.CallType.AdHoc) {
      if (me.id === call.creatorUserId) {
        return <AdHocCallCreatorHeader call={call} />;
      } else {
        return <AdHocCallRecipientHeader call={call} />;
      }
    } else if (call.typeId === enums.CallType.Project) {
      if (hasClientRole) {
        return <ProjectCallClientHeader call={call} />;
      } else {
        return <ProjectCallExpertHeader call={call} />;
      }
    }
  }, [
    call,
    me.id,
    hasClientRole,
  ]);

  const renderOption = useCallback((option: enums.CallRating) => {
    const className = cx(styles.option, {
      [styles.selected]: rating === option,
    });

    return (
      <div
        className={className}
        onClick={() => setRating(option)}>
        <RatingIcon
          className={styles.icon}
          size={50}
          value={option} />
        <div className={styles.optionTitle}>{enums.utils.CallRating.getName(option)}</div>
      </div>
    );
  }, [rating]);

  const renderOptions = useCallback(() => (
    <div className={styles.options}>
      {renderOption(enums.CallRating.Bad)}
      {renderOption(enums.CallRating.Neutral)}
      {renderOption(enums.CallRating.Good)}
    </div>
  ), [renderOption]);

  const renderButtons = useCallback(() => {
    const disabled = rating === null || submit.isLoading;
    const skip = submit.isLoading ? undefined : handleSkip;

    return (
      <div className={styles.btns}>
        <Button.Primary
          className={styles.submit}
          disabled={disabled}
          onClick={handleSubmit}
          variant='brick'>
          Submit
        </Button.Primary>
        <div
          className={styles.skip}
          onClick={skip}>
          {`Skip>`}
        </div>
      </div>
    );
  }, [
    rating,
    handleSkip,
    handleSubmit,
    submit.isLoading,
  ]);

  return (
    <>
      {renderHeader()}
      {renderOptions()}
      <div className={styles.footnote}>Help screen candidates for future projects by rating your call.</div>
      {renderButtons()}
    </>
  );
};

export { CallRating };
export default CallRating;
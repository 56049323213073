import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import * as api from '@api/projects';
import { Projects } from '@api/interfaces/projects';

type Data = Projects.SaveProjectSurvey.Response;
type Vars = Omit<Projects.SaveProjectSurvey.Request, 'projectId'>;

export function useSaveProjectSurvey(
  { projectId }: IProjectId,
  options?: UseMutationOptions<Data, AxiosResponse, Vars>
) {

  return useMutation(['save-project-survey', projectId], ({ survey }) => {

    return api.surveys.save({
      projectId,
      survey,
    });

  }, options);
}
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import * as enums from '@enums';
import { cx, getLocationFor, hasInternalAdminRole } from '@utils';
import { useSelectUser } from '@containers/Store';
import { DocumentDownload } from '$admin/components/DocumentDownload';
import  { EmploymentHistory } from 'scenes/surveys/CompletedSurvey/EmploymentHistory';
import  { SurveyDetail } from 'scenes/surveys/CompletedSurvey/SurveyDetail';
import { SurveyDetailsProps } from './interfaces';
import styles from './style.css';

export const SurveyDetails = (props: SurveyDetailsProps) => {
  const user = useSelectUser();
  const completedOn = format(new Date(props.survey.completedOn), 'MMM do yyyy');

  const renderDocument = () => {
    if (props.project.documentSigning === enums.DocumentSigning.NotRequired) {
      return 'Not Required';
    }

    return (
      <DocumentDownload
        filename={`${props.user.profile.fullname} ${format(new Date(), 'M/d/yyyy')}.pdf`}
        item={props}>
        <div className={styles.link}>Download</div>
      </DocumentDownload>
    );
  };

  const renderVerification = () => (
    <span className={cx({
      [styles.unverified]: props.user.verificationStatusId === enums.VerificationStatus.New,
    })}>
      {enums.utils.VerificationStatus.getName(props.user.verificationStatusId)}
    </span>
  );

  return (
    <div className={styles.details}>
      {hasInternalAdminRole(user) &&
        <SurveyDetail
          name="Group">
          {props.group.name}
        </SurveyDetail>
      }
      <SurveyDetail
        name={'Project'}>
        {props.project.name}
      </SurveyDetail>
      <SurveyDetail
        name={'Completed On'}>
        {completedOn}
      </SurveyDetail>
      <SurveyDetail
        name={'Document'}>
        {renderDocument()}
      </SurveyDetail>
      {hasInternalAdminRole(user) &&
        <SurveyDetail
          name={'Verification Status'}>
          {renderVerification()}
        </SurveyDetail>
      }
      {hasInternalAdminRole(user) &&
        <SurveyDetail name="Client Fee">
          {
            props.billing?.chargeable?.id
              ? (
                <Link to={getLocationFor.admin.billing.chargeables.details({
                  chargeableId: props.billing.chargeable.id,
                })}>
                  ${props.billing.chargeable.amount}
                </Link>
              ) : 'Not available'
          }
        </SurveyDetail>
      }
      {hasInternalAdminRole(user) &&
        <SurveyDetail name="Member Payout">
          {
            props.billing?.payoutable?.id
              ? (
                <Link to={getLocationFor.admin.billing.payoutables.details({
                  payoutableId: props.billing.chargeable.id,
                })}>
                  ${props.billing.payoutable.amount}
                </Link>
              ) : 'Not available'
          }
        </SurveyDetail>
      }
      <div className={styles.label}>Employments</div>
      <EmploymentHistory
        items={props.user.employments} />
    </div>
  );
};

export default SurveyDetails;
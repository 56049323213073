import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import * as selectors from '@store/selectors';
import * as enums from '@enums';
import { useProjectMetrics } from '@containers/Metrics';
import { formatTruncatedName } from '@utils';
import { ProjectsTableItem } from '../interfaces';

type ProjectTableFilter = {
  parentObjectId?: number;
  sharedRoot?: boolean;
  status?: enums.ProjectStatus;
};

const selectProjectTableItems = ({ parentObjectId, sharedRoot, status }: ProjectTableFilter) => createSelector(
  [
    (state: Store.State) => state.user.id,
    selectors.selectProjectValues,
    selectors.selectProjectParentValues,
    selectors.selectProjectParentIds,
    selectors.selectObjectValues,
    (state: Store.State) => state.contacts,
  ],
  (meId, projects, projectParents, projectParentIds, objects, contacts) => {
    return projectParentIds
    .reduce((acc, parentId) => {
      const parent = projectParents[parentId];

      const project = projects[parent.latestChild];

      if (!project) {
        return acc;
      }

      if (status && project.projectStatusId !== status) {
        return acc;
      }

      const object = objects[parent.objectId];

      if (parentObjectId && object.parentObjectId !== parentObjectId) {
        return acc;
      }

      const parentObject = objects[object.parentObjectId];

      if (sharedRoot && parentObject &&
        (parentObject.ownerId === meId || parentObject.typeId !== enums.WorkspaceObjectType.Workspace)
      ) {
        return acc;
      }

      return [
        ...acc,
        {
          id: project.id,
          owner: object.ownerId === meId
            ? 'Me'
            : formatTruncatedName(contacts[object.ownerId]),
          createdOn: project.createdOn,
          description: project.description,
          name: project.name,
          projectStatusId: project.projectStatusId,
          projectType: project.projectType,
          slug: project.slug,
        },
      ];
    }, [] as Omit<ProjectsTableItem, 'metrics'>[]);
  },
);

export const useProjectsTableItems = (filters: ProjectTableFilter) => {
  const items = useSelector(selectProjectTableItems(filters));
  const getMetrics = useProjectMetrics();

  const withMetrics = useMemo(() => {
    return items.map(m => ({
      ...m,
      metrics: getMetrics(m.id),
    }));
  }, [
    getMetrics,
    items,
  ]);

  return withMetrics;
};

export default useProjectsTableItems;
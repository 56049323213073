import http from '@services/http';
import * as xform from '@transformers';
import { GroupSignatureRequest } from '@/types';
import { nullableDate } from '@/utils';
import {
  Application,
} from './interfaces';

export const fetchApp = () => {
  return http.get(`/users/me`)
    .then(({ user: { onboarding, ...user }, ...res }: Application.FetchAppResponse<string>) => ({
      calls: xform.normalizeCalls(res.calls) as Store.Calls,
      chat: xform.normalizeChat(res.chat),
      conferences: xform.normalizeConferences(res.conferences),
      contacts: xform.addUserBindings(res.contacts) as Store.Contacts,
      documents: { group: xform.toMap<number, GroupSignatureRequest>(res.documents.group, 'groupId') },
      files: xform.normalizeFiles(res.files),
      folders: xform.normalizeFolders(res.folders),
      group: res.group,
      objects: xform.normalizeWorkspaceObjects(res.objects),
      onboarding: xform.normalizeOnboarding(onboarding),
      pipeline: xform.normalizePipeline(res.pipeline),
      platform: res.platform,
      projects: xform.normalizeProjects(res),
      settings: res.settings,
      user: xform.addUserBindings(user),
      workspaces: xform.normalizeWorkspaces(res.workspaces),
      session: res.session ? {
        ...res.session,
        expiry: nullableDate(res.session.expiry),
      } : undefined,
    }));
};

export const rehydrateApp = () => {
  return http.get(`/users/me/hydrate`)
    .then((res: Application.RehydrateAppResponse<string>) => ({
      calls: xform.normalizeCalls(res.calls) as Store.Calls,
      chat: xform.normalizeChat(res.chat),
      conferences: xform.normalizeConferences(res.conferences),
      contacts: xform.addUserBindings(res.contacts) as Store.Contacts,
      documents: { group: xform.toMap<number, GroupSignatureRequest>(res.documents.group, 'groupId') },
      files: xform.normalizeFiles(res.files),
      folders: xform.normalizeFolders(res.folders),
      group: res.group,
      objects: xform.normalizeWorkspaceObjects(res.objects),
      pipeline: xform.normalizePipeline(res.pipeline),
      platform: res.platform,
      projects: xform.normalizeProjects(res),
      settings: res.settings,
      user: res.user, // needs xform.addUserBindings when not partial
      workspaces: xform.normalizeWorkspaces(res.workspaces),
      session: res.session ? {
        ...res.session,
        expiry: nullableDate(res.session.expiry),
      } : undefined,
    }));
};
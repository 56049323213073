import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { device } from '@utils';
import { useBuildReturnRoute } from '@routes/hooks/useBuildReturnRoute';
import { SecretParams } from './interfaces';
import { useMobileDevicePath } from './hooks';

const selectState = (state: Store.State) => state.user.state;

const MobileRedirect = (props: SecretParams) => {
  const devicePath = useMobileDevicePath(props);

  window.location.href = devicePath;
  return (
    <div>
      Redirecting you to <a href={devicePath}>{devicePath}</a>...
    </div>
  );
};

const LoginRedirect = () => {
  const redirect = useBuildReturnRoute();

  return <Redirect to={redirect} />;
};

export const RedirectWrapper = (props: SecretParams & ChildrenProps) => {
  const state = useSelector(selectState);
  if (!state.authenticated) {
    if (device.phone) {
      return (<MobileRedirect {...props} />);
    } else {
      return <LoginRedirect />;
    }
  } else {
    return (
      <>
        {props.children}
      </>);
  }
};
import { useSelector } from 'react-redux';
import { ApprovalStatus, CallStatus, ProjectType } from '@enums';
import * as scheduler from '@containers/Scheduler/utils';
import styles from '@screens/ProjectPipeline/style/ProjectPipelineItem.css';
import { UserStatusProps } from './interfaces';

type Props =
  UserStatusProps;

const selectMessage = (data: IProjectId & IUserId) => (state: Store.State) => {
  const pipeline = state.pipeline.project[data.projectId];
  const project = state.projects[data.projectId];
  const record = pipeline.users[data.userId];

  if (scheduler.projects.isAwaitingConsultantAction(record)) {
    return 'Pending Member';
  }

  if (scheduler.projects.isDisqualified(record)) {
    return 'Disqualified';
  }

  if (scheduler.projects.isAwaitingComplianceAction(record)) {
    if (project.projectType === ProjectType.Call) {
      const call = findPendingCall();

      if (!call) return null;

      if (scheduler.calls.isAwaitingInternalCompliance(call)) {
        return 'Pending internal compliance';
      } else if (scheduler.calls.isAwaitingVanceryCompliance(call)) {
        return 'Pending vancery compliance';
      }

      return 'Pending compliance';
    } else if (project.projectType === ProjectType.Survey) {
      const respondent = pipeline.respondents[data.userId];

      if (respondent.projectSurveyResponse.approvalStatusId === ApprovalStatus.NeedsApproval) {
        return 'Pending internal compliance';
      } else if ([
        ApprovalStatus.NeedsApproval,
        ApprovalStatus.ReadyToApprove,
        ApprovalStatus.ReadyToReject,
      ].includes(respondent.projectSurveyResponse.screeningStatusId)) {
        return 'Pending vancery compliance';
      }

      return 'Pending compliance';
    } else {
      return 'Pending compliance';
    }
  }

  return null;

  function findPendingCall() {
    return state.calls.ids
      .map(callId => state.calls[callId])
      .find(call => {
        return call.projectId === data.projectId &&
          call.userId === data.userId &&
          call.statusId === CallStatus.Pending;
      });
  }
};

const UserStatus = (props: Props) => {

  const message = useSelector(selectMessage(props));

  return message && (
    <div className={styles.note}>
      {message}
    </div>
  );
};

export default UserStatus;
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as actions from '@store/actions';
import * as api from '@services/api';
import * as consts from '@consts';
import { ConnectAccountProvider } from 'components/ConnectAccount';
import ShareWithOthers from './ShareWithOthers';
import styles from './style.css';

const mapState = state => ({
  routes: state.routing.routes,
});

const mapDispatch = {
  popFromHistory: actions.popFromHistory,
};

const Referral = props => {
  const handleSend = data => {
    api.user.sendReferral({
      emails: data.emails,
      note: data.note,
      referral: data.referral,
    });

    goBack();
  };

  const goBack = () => {
    if (props.routes.length < 2) {
      props.history.push(consts.pathname.HOME);
    } else {
      const back = props.routes[props.routes.length - 2];

      props.popFromHistory();
      props.history.replace(back);
    }
  };

  return (
    <div className={styles.root}>
      <ConnectAccountProvider>
        <ShareWithOthers onSend={handleSend} />
      </ConnectAccountProvider>
    </div>
  );
};

export default connect(mapState, mapDispatch)(withRouter(Referral));
import { useMemo } from 'react';
import SearchTabAll from './Search.Tab.All';
import SearchTabProjects from './Search.Tab.Projects';
import SearchTabFiles from './Search.Tab.Files';
import SearchTabFolders from './Search.Tab.Folders';
import SearchTabInteractions from './Search.Tab.Interactions'
import SearchTabMembers from './Search.Tab.Members';
import SearchTabView from './Search.Tab.View';
import { SearchRouteKey, SearchSceneMap } from './interfaces';

export default function SearchTabViewContainer() {
  const scenes: SearchSceneMap = useMemo(() => ({
    [SearchRouteKey.All]: {
      component: SearchTabAll,
      title: 'All',
    },
    [SearchRouteKey.Projects]: {
      component: SearchTabProjects,
      title: 'Projects',
    },
    [SearchRouteKey.Members]: {
      component: SearchTabMembers,
      title: 'Members',
    },
    [SearchRouteKey.Interactions] : {
      component: SearchTabInteractions,
      title: 'Interactions',
    },
    [SearchRouteKey.Files]: {
      component: SearchTabFiles,
      title: 'Files',
    },
    [SearchRouteKey.Folders]: {
      component: SearchTabFolders,
      title: 'Folders',
    },
  }), []);

  return <SearchTabView scenes={scenes} />;
}

export { SearchTabViewContainer };
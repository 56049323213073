import { useCallback, useMemo, useState } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { usePopper } from 'react-popper';
import {
  useTranscriptCommentFocus,
  useTranscriptChildComment,
  useTranscriptCommentEditing,
  useTranscriptCommentIdentifiers,
} from '@containers/Transcript';
import { useSelectUser } from '@containers/Store/hooks';
import { MoreHorizontalAnchor } from '@presentation/Anchor';
import { Portal } from 'components/Portal';
import { PopperMenu, PopperMenuItem } from 'components/Popper';
import { useDeleteCommentAlert } from './Alert.RemoveComment';
import { useRemoveComment } from './hooks';

export const CommentContextMenu = () => {

  const comment = useTranscriptChildComment();
  const { highlight, parentComment } = useTranscriptCommentIdentifiers();

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const [_, setFocus] = useTranscriptCommentFocus(parentComment.identifier);

  const popupState = usePopupState({
    popupId: `comment-menu-${comment.id}`,
    variant: 'popper',
  });

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
    placement: 'top-start',
  });

  const [editing, toggleEditing] = useTranscriptCommentEditing();
  const [toggleDeleteAlert, DeleteCommentAlert] = useDeleteCommentAlert();

  const me = useSelectUser();

  const isCommentCreator = useMemo(() => {
    return me.id === comment.creator.id;
  }, [me.id, comment.creator.id]);

  const {
    isLoading: isDeleting,
    mutateAsync: deleteComment,
  } = useRemoveComment({
    onSuccess: popupState.close,
  });

  const handleEdit = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    toggleEditing();
    setFocus(true);
    popupState.close();
  }, [
    popupState,
    setFocus,
    toggleEditing,
  ]);

  const handleDelete = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    toggleDeleteAlert();
    popupState.close();
  }, [
    popupState,
    toggleDeleteAlert,
  ]);

  const handleDeleteSubmit = useCallback(() => {
    return deleteComment({
      commentId: comment.id,
      highlightId: highlight.id,
      parentCommentId: parentComment.id,
    });
  }, [
    comment.id,
    highlight.id,
    parentComment.id,
    deleteComment,
  ]);

  const handleAnchorClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    const { onClick } = bindToggle(popupState);
    onClick(e);
  }, [popupState]);

  if (!isCommentCreator) return null;

  return (
    <>
      <div
        {...bindToggle(popupState)}
        onClick={handleAnchorClick}>
        <MoreHorizontalAnchor
          open={popupState.isOpen}
          ref={setReferenceElement} />
      </div>
      {popupState.isOpen &&
        <Portal>
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <PopperMenu>
                {!editing &&
                  <PopperMenuItem
                    onClick={handleEdit}>
                    Edit
                  </PopperMenuItem>
                }
                <PopperMenuItem
                  onClick={handleDelete}>
                  Delete
                </PopperMenuItem>
              </PopperMenu>
            </ClickAwayListener>
          </div>
        </Portal>
      }
      <DeleteCommentAlert
        isSubmitting={isDeleting}
        onSubmit={handleDeleteSubmit} />
    </>
  );
};
import extend from '@enums/extend';

export enum ProjectGoalType {
  Call   = 1,
  Survey = 2,
}

const goal = new Map([
  [ProjectGoalType.Call,   { name: 'Call' }],
  [ProjectGoalType.Survey, { name: 'Survey' }],
]);

export enum ProjectStatus {
  Inactive = 1,
  Active   = 2,
  Archived = 3,
}

const status = new Map([
  [ProjectStatus.Inactive, { name: 'Inactive' }],
  [ProjectStatus.Active,   { name: 'Active' }],
  [ProjectStatus.Archived, { name: 'Archived' }],
]);

export enum ProjectType {
  Survey      = 1,
  Call        = 2,
  SurveyCall  = 3,
}

const type = new Map([
  [ProjectType.Call, { name: 'Call' }],
  [ProjectType.Survey, { name: 'Survey' }],
  [ProjectType.SurveyCall, { name: 'Survey and Call' }],
]);

export enum ProjectFrequency {
  None = 1,
  Weekly = 2,
  Monthly = 3,
  Quarterly = 4,
  AdHoc = 5,
}

const frequency = new Map([
  [ProjectFrequency.None, { name: 'None' }],
  [ProjectFrequency.Weekly, { name: 'Weekly' }],
  [ProjectFrequency.Monthly, { name: 'Monthly' }],
  [ProjectFrequency.Quarterly, { name: 'Quarterly' }],
  [ProjectFrequency.AdHoc, { name: 'Ad Hoc' }],
]);

export default {
  ProjectFrequency: extend(frequency),
  ProjectGoalType: extend(goal),
  ProjectStatus: extend(status),
  ProjectType: extend(type),
};
import { Fragment, useCallback } from 'react';
import { SurveySectionBuilderContainer } from '@containers/SurveyBuilder.Section';
import { useSurveyBuilderState, useCanAddSection } from '@containers/SurveyBuilder';
import { AddSectionButtonBordered, AddButtonDivider } from '@presentation';
import styles from './style/SurveySectionsBuilder.css';

type Props = {
  includeTagging: boolean;
};

export const SurveySectionsBuilder = ({ includeTagging }: Props) => {
  const [state, dispatch] = useSurveyBuilderState();
  const canAddSection = useCanAddSection();

  const addSection = useCallback((ordinal: number) => () => {
    dispatch({
      type: 'new-section-added',
      ordinal,
    });
  }, [dispatch]);

  const renderSections = useCallback(() => {
    return state.survey.sections.map(m => (
      <Fragment key={m.ordinal}>
        <SurveySectionBuilderContainer
          identifier={m.identifier}
          includeTagging={includeTagging} />
        {m.ordinal !== state.survey.sections.length &&
          <AddButtonDivider
            className={styles.divider}
            disabled={!canAddSection}
            onClick={addSection(m.ordinal + 1)} />
        }
      </Fragment>
    ));
  }, [
    addSection,
    canAddSection,
    includeTagging,
    state.survey.sections,
  ]);

  return (
    <div className={styles.root}>
      {renderSections()}
      <div className={styles.addSection}>
        {canAddSection &&
          <AddSectionButtonBordered
            onClick={addSection(state.survey.sections.length + 1)} />
        }
      </div>
    </div>
  );
};

export default SurveySectionsBuilder;
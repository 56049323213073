import { useSelector } from 'react-redux';
import { selectFolder } from '@store/selectors';

const useSelectFolder = (folderId: number) => {
  const folder = useSelector(selectFolder(folderId));

  return folder;
};

export { useSelectFolder };
export default useSelectFolder;
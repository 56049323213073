import { MatrixRowLogicBuilderContainer } from '@containers/SurveyBuilder.Logic/Matrix.RowContainer';
import { QuestionRow } from '@presentation/SurveyPreview';
import { SurveyQuestionMatrixRow } from '@/types';
import { MatrixOptionsLogicBuilder } from './Matrix.RowOptionsBuilder';
import styles from './style/MatrixLogicBuilder.css';

type Props = {
  row: SurveyQuestionMatrixRow;
};

export const MatrixRowLogicBuilder = (props: Props) => {

  return (
    <div className={styles.rowRoot}>
      <QuestionRow
        className={styles.rowValue}
        item={props.row} />
      <div className={styles.options}>
        <MatrixRowLogicBuilderContainer row={props.row}>
          <MatrixOptionsLogicBuilder />
        </MatrixRowLogicBuilderContainer>
      </div>
    </div>
  );
};
import { useCallback, useRef } from 'react';
import useTimedToggle from '@utils/hooks/useTimedToggle';

const timeoutMillis = 1250;

export const useCopy = <T extends HTMLTextAreaElement | HTMLInputElement = HTMLInputElement>() => {
  const [copied, toggleCopied] = useTimedToggle(false);

  const targetRef = useRef<T>(null);

  const copy = useCallback(() => {
    if (targetRef) {
      targetRef.current.select();
      document.execCommand('copy');
      targetRef.current.blur();

      toggleCopied(timeoutMillis);
    }
  }, [toggleCopied]);

  return {
    copied,
    copy,
    target: targetRef,
  } as const;
};

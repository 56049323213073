import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import * as api from '@api';
import { useSelectGroup } from '@containers/Store';
import { TaggingAutoSuggestContext } from './Context';
import { Tag, TagMap } from './interfaces.tagging';

type Props = {
  children?: React.ReactNode;
};

export const TaggingSearchContainer = (props: Props) => {
  const [keyword, setKeyword] = useState('');
  const [items, setItems] = useState<Tag[]>([]);
  const [tagmap, setTagMap] = useState<TagMap>(new Map());
  const group = useSelectGroup();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {

    setKeyword(e.target.value);

    if (!e.target.value?.length) {
      setItems([]);
    }

  }, [
    setItems,
    setKeyword,
  ]);

  const options = useMemo<Tag[]>(() => {
    const display = formatDisplayValue(keyword);

    if (display.length >=2 && !hasMatch(display, items)) {
      return items.concat({
        id: `tag-${display}`,
        name: display,
        new: true,
      });
    }

    return items;
  }, [
    items,
    keyword,
  ]);

  const handleSelect = useCallback((item: Tag) => {
    setItems([]);
    setKeyword('');
    const updated = tagmap.set(item.id, item);
    setTagMap(new Map(updated));
  }, [
    setItems,
    setKeyword,
    tagmap,
  ]);

  const removeTag = useCallback((item: Tag) => {
    tagmap.delete(item.id);
    setTagMap(new Map(tagmap));
  }, [tagmap]);

  const selected = useMemo(() => {
    const items = [...tagmap.values()];
    const exclude = items
      .filter(x => !x.new)
      .map(x => x.id as number);

    return {
      exclude,
      items,
    };
  }, [tagmap]);

  const query = useQuery([`get:groups/contacts/tags`,
    group?.id,
    keyword,
    selected.exclude,
  ], () => {
    return api.groups.contacts.searchTags({
      groupId: group.id,
      keyword: keyword.trim(),
      exclude: selected.exclude,
    });
  }, {
    enabled: keyword.trim().length > 0,
  });

  useEffect(() => {

    setItems(query.data?.items ?? []);

  }, [query.data]);

  const value = {
    input: {
      value: keyword,
      onChange: handleChange,
    },
    selected: {
      items: selected.items,
      onDelete: removeTag,
    },
    suggestions: {
      items: options,
      onSelect: handleSelect,
      renderOptionValue: formatOptionDisplayValue,
    },
  };

  return (
    <TaggingAutoSuggestContext.Provider value={value}>
      {props.children}
    </TaggingAutoSuggestContext.Provider>
  );
};

TaggingSearchContainer.displayName = 'TaggingSearchContainer';

function formatDisplayValue(value: string) {
  return value
    .trim()
    .split(' ')
    .map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(' ');
}

function hasMatch(value: string, items: Tag[]) {
  return items.some(x => x.name.toLowerCase() === value.trim().toLowerCase());
}

function formatOptionDisplayValue(item: Tag) {
  return item.new
    ? `Add "${item.name}"`
    : item.name;
}
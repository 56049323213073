import { StarToggle } from '@presentation/StarToggle';
import { cx } from '@utils';
import { RespondentProps } from '@/types';
import { MaybeLink } from 'components/MaybeLink';
import { MemberBaseballCard, AnonymousBaseballCard } from 'components/BaseballCard';
import ViewAll from './ViewAll';
import styles from './style/ResponseTextItem.css';

export type ResponseTextItemProps = {
  className?: string;
  respondent: RespondentProps;
  onToggleStar: () => void;
  starred: boolean;
  value: string;
  wrapClassName?: string;
};

export const ResponseTextItem = ({
  className,
  respondent,
  onToggleStar,
  starred,
  value,
  wrapClassName,
}: ResponseTextItemProps) => {
  return (
    <div className={cx(styles.root, className)}>
      <div className={cx(styles.wrap, wrapClassName)}>
        <StarToggle
          className={styles.star}
          onClick={onToggleStar}
          value={starred} />

        <div className={styles.textblock}>
          <div className={styles.comment}>
            <div className={styles.quote}>
              {value}
            </div>
            <div className={styles.author}>
              <BaseballCard {...respondent} className={styles.baseballCard}>
                <MaybeLink to={respondent.paths.profile}>
                  {respondent.profile.fullname}
                </MaybeLink>
              </BaseballCard>
            </div>
          </div>

          <ViewAll
            onClick={respondent.onViewResponse}
            to={respondent.paths.response} />
        </div>
      </div>
    </div>
  );
};

function BaseballCard(props: RespondentProps<string> & { children: React.ReactNode; className?: string; }) {
  if (props.anonymity) {
    return (
      <AnonymousBaseballCard className={props.className} userId={props.id} {...props} displayName={props.respondentName} subtext={props.profile.fullname == props.respondentName ? null : props.profile.fullname}>
        {props.children}
      </AnonymousBaseballCard>
    );
  } else {
    return (
      <MemberBaseballCard.Client className={props.className} userId={props.id} projectId={props.projectId}>
        {props.children}
      </MemberBaseballCard.Client>
    );
  }
}

export const ResponseOptionFreeTextItem = (props: ResponseTextItemProps) => {
  return (
    <ResponseTextItem
      wrapClassName={styles.optionWrap}
      {...props} />
  );
};

export default ResponseTextItem;
import qs from 'query-string';
import * as enums from '@enums';
import http from '@services/http';
import * as xform from '@transformers';
import { Admin, Members, SaveAttributionInfo } from './interfaces';
import { safeJsonDate, slugify } from '@utils';

export const fetchAcquisitionData = () => {
  return http.get<Members.FetchAcquisitionData.Response>(`/admin/members/acquisition`);
};

export const fetchAggregate = (data: Members.FetchAggregate.Request) => {
  return http.get(`/admin/members/dashboard?${qs.stringify(data, { arrayFormat: 'bracket' })}`)
  .then((c: Members.FetchAggregate.Reponse) => ({
    items: c.items.map(xform.setUserSlugGeneric),
  }));
};

export const fetchMember = (data: Members.FetchMember.Request) => {
  return http.get(`/admin/members/${data.userId}`)
  .then((data: Members.FetchMember.Response) => {
    const { referral, ...rest } = data.item;
    return {
      ...rest,
      createdOn: safeJsonDate(data.item.createdOn),
      identities: data.item.identities.map(m => ({
        ...m,
        createdOn: safeJsonDate(m.createdOn),
        lastLogin: safeJsonDate(m.lastLogin),
      })),
      link: xform.assignLinkUrl({ token: { value: referral, typeId: enums.TokenType.Referral }}),
      profile: {
        ...data.item.profile,
        slug: slugify({
          id: data.item.id,
          name: data.item.profile.fullname,
        }),
      },
    };
  });
};

export const fetchMembers = (data: Members.FetchMembers.Request) => {
  const q = qs.stringify(data);

  return http.get<Members.FetchMembers.Response>(`/admin/members?${q}`);
};

export const getProfile = (data: Admin.GetUserProfile.Request): Promise<Admin.GetUserProfile.Response> => {
  return http.get<Admin.GetUserProfile.Response>(`admin/users/${data.userId}/profile`)
  .then(xform.setUserSlugGeneric);
};

export const saveAttributionInfo: SaveAttributionInfo = data => {
  return http.post(`/admin/users/attribution`, data);
};

export const setVerificationStatus = (data: Members.SetVerificationStatus.Request): Promise<Members.SetVerificationStatus.Response> => {
  const { userId, ...rest } = data;
  return http.patch(`/admin/members/${userId}/verification`, rest);
};

export const updateLinkedinInfo = (data: Members.UpdateLinkedinInfo.Request): Promise<Members.UpdateLinkedinInfo.Response> => {
  const { userId, ...rest } = data;

  return http.post(`/admin/members/${userId}/linkedin`, rest);
};

export * as search from './members.search';
import { useCallback, useState } from 'react';
import { useAppReadyState } from '@containers/AppReadyState';
import { useHasClientRole } from '@containers/User';
import * as mixpanel from '@services/mixpanel';
import { RequestProjectCallModal, RequestAdHocCall } from 'components/RequestCall';
import { AuthConnectModal } from '$website/components';
import Toast from 'components/Toast';
import { RequestCallContext } from './Context';
import { useExistingAdHocCallCheck } from './hooks';
import { CallRequestRecipient } from './interfaces';

type Props =
  ChildrenProps;

export const RequestCallContainer = (props: Props) => {
  const [recipient, setRecipient] = useState<CallRequestRecipient>(null);

  const hasClientRole = useHasClientRole();

  const app = useAppReadyState();
  const getExistingAdHocCall = useExistingAdHocCallCheck();

  const auth = (!app.authenticated || app.hydrating) && !!recipient;
  const showModal = app.hydrated && !!recipient;

  const handleAction = useCallback((r: CallRequestRecipient) => {
    if (!r) {
      setRecipient(r);
      return;
    }

    if (!hasClientRole) {
      const existing = getExistingAdHocCall(r?.id);
      if (existing) {
        const awaitingAction = existing.lastModifiedBy !== r.id;

        Toast.info({
          title: 'A call request already exists',
          body: awaitingAction
            ? `You will be notified once ${r.profile.firstName} responds`
            : `Check notifications for next steps`,
        });

        return;
      }

      mixpanel.event.adHocCall.callRequestStart({ userId: r.id });
    }

    setRecipient(r);
  }, [
    getExistingAdHocCall,
    hasClientRole,
  ]);

  const handleClose = useCallback(() => setRecipient(null), [setRecipient]);

  return (
    <RequestCallContext.Provider value={[recipient, handleAction]}>
      {props.children}

      {auth &&
        <AuthConnectModal
          recipient={recipient}
          open={auth}
          onClose={handleClose} />}

      {showModal && !hasClientRole &&
        <RequestAdHocCall
          open={true}
          onClose={handleClose} />
      }

      {showModal && hasClientRole &&
        <RequestProjectCallModal
          open={true}
          onClose={handleClose}
          user={{
            id: recipient.id,
          }} />
      }

    </RequestCallContext.Provider>
  );
};

export default RequestCallContainer;
import { SurveyQuotaType } from '@enums';
import { SurveyQuota } from '@/types';

export namespace SurveyQuotasBuilder {

  export type State = SurveyQuota.Record[];

  export type Action =
    AddItem.Action |
    RemoveItem.Action |
    ToggleEditingOff.Action |
    ToggleEditingOn.Action |
    SaveItem.Action
  ;

  export namespace AddItem {
    export type State = {
      quotaType: SurveyQuotaType;
    };

    export type Action = {
      identifier: string;
      type: 'add-quota-item';
    } & State;
  }

  export namespace RemoveItem {
    export type Action = {
      identifier: string;
      type: 'remove-quota-item';
    };
  }

  export namespace SaveItem {
    export type Action = {
      value: SurveyQuota.Record;
      type: 'save-quota-item';
    };
  }

  export namespace ToggleEditingOff {
    export type Action = {
      identifier: string;
      type: 'toggle-quota-editing-off';
    };
  }

  export namespace ToggleEditingOn {
    export type Action = {
      identifier: string;
      type: 'toggle-quota-editing-on';
    };
  }

}
import extend from '@enums/extend';

export enum CallRating {
  Bad = -1,
  Neutral = 0,
  Good = 1,
}

const map = new Map([
  [CallRating.Bad,      { name: 'Negative'     }],
  [CallRating.Neutral,  { name: 'Neutral'      }],
  [CallRating.Good,     { name: 'Positive'     }],
]);

export default {
  CallRating: extend(map),
};
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { useSelectUser } from '@containers/Store';
import { useAsyncStateLazy } from '@utils';
import ActivityIndicator from 'components/ActivityIndicator';
import { useResolveRedirect } from './hooks';

type Props = {
  children: React.ReactNode;
};

const EmailVerificationContainer = (props: Props) => {
  const dispatch = useDispatch();
  const [redirect, resolveRedirect] = useResolveRedirect();
  const user = useSelectUser();

  const [response, fetch] = useAsyncStateLazy(() => {
    return api.users.verify.refreshEmailVerification({ userId: user.id })
    .then(data => {

      const action = actions.userInfoChange({
        meta: {
          isEmailVerified: data.emailVerified,
        },
      });

      dispatch(action);
    });
  });

  useEffect(() => {
    if (user.meta.isEmailVerified) {
      resolveRedirect();
    } else {
      fetch();
    }
  }, [
    fetch,
    resolveRedirect,
    user.meta.isEmailVerified,
    user.state.initialized,
  ]);

  if (redirect) return <Redirect to={redirect} />;

  if (response.loading) return <ActivityIndicator show />;

  return props.children as JSX.Element;
};

export { EmailVerificationContainer };
export default EmailVerificationContainer;
import { cx } from '@utils';
import { Focusable } from 'components/Focusable';
import { SelectBaseProps } from './interfaces';
import styles from './style.css';

type Props<T> = {
  autoFocus?: boolean;
  className?: string;
  onBlur:     () => unknown;
  onClick:    () => unknown;
} & SelectInputValueProps<T>;

const SelectInput = <T extends unknown>(props: Props<T>) => {
  const className = cx(styles.input, props.className);

  return (
    <Focusable autoFocus={props.autoFocus}>
      <div
        className={className}
        onBlur={props.onBlur}
        onClick={props.onClick}
        tabIndex={0}>
        <SelectInputValue
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          value={props.value} />
        <div className={styles.icon} />
      </div>
    </Focusable>
  );
};

type SelectInputValueProps<T> =
    Pick<SelectBaseProps<T>,
      'defaultValue'
    | 'placeholder'
    | 'value'>;

const SelectInputValue = <T extends unknown>(props: SelectInputValueProps<T>) => {
  const className = cx(styles.text, {
    [styles.placeholder]: !!props.placeholder && !(props.value || props.defaultValue),
  });

  const text = props.value
            || props.defaultValue
            || props.placeholder;
  return (
    <div className={className}>
      {text}
    </div>
  );
};

export { SelectInput };
export default SelectInput;
import { useIsMassProject } from './hooks';
import { BasicPipeline } from './BasicPipeline';
import { MassPipeline } from './MassPipeline';

export const ProjectPipeline = () => {
  const isMassProject = useIsMassProject();

  if (isMassProject) {
    return <MassPipeline />;
  } else {
    return <BasicPipeline />;
  }
};

export default ProjectPipeline;
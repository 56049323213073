import { useCallback, useMemo } from 'react';
import { useSelectProjectCall, useProjectCapabilities, useGetProjectCapabilities } from '@containers/Store';
import { useHasClientRole } from '@containers/User';
import { useCancelProjectCall as useApi } from '@utils/api';
import { useProjectCallSchedulingNotifier } from '@containers/Scheduler/Notifier.ProjectCall';

export const useCancelProjectCall = (callId: number) => {
  const call = useSelectProjectCall(callId);
  const getProjectCapabilities = useGetProjectCapabilities();
  const hasClientRole = useHasClientRole();
  const notifier = useProjectCallSchedulingNotifier();

  const { mutateAsync: cancel } = useApi({ callId }, {
    onSuccess: data => {
      notifier.cancellation.success();
    },
    onError: e => {
      notifier.cancellation.error();
    },
  });

  const handleCancel = useCallback(() => {
    return cancel();
  }, [cancel]);

  const canCancel = useMemo(() => {
    if (!hasClientRole) return true;

    const capabilities = getProjectCapabilities(call.projectId);

    return capabilities.canManage;
  }, [
    call.projectId,
    getProjectCapabilities,
    hasClientRole,
  ]);

  return [canCancel, handleCancel] as const;
};

export default useCancelProjectCall;
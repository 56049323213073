import { useCallback, useState } from 'react';
import { useDevice } from '@utils';
import { ConferenceDialInfo } from '@/types';
import { Tab } from 'components/Conference.Entry';
import { SelectOptionProps } from 'components/Select/interfaces';
import { TabView, TabViewRenderParams } from 'components/TabView';
import { TabKey, TabRoute } from './interfaces';

type Props = {
  cameraVisible?: boolean;
  cameraValue?: number;
  disabledCamera?: boolean;
  microphoneValue?: number;
  disabledMicrophone?: boolean;
  disabledVisibility?: boolean;
  visibilityValue?: number;
  visibilityVisible?: boolean;
  showCameraPreview?: boolean;
  phoneInformation: ConferenceDialInfo;
  onSelectCamera?: (value: SelectOptionProps) => void;
  onSelectMicrophone: (value: SelectOptionProps) => void;
  onSelectVisibility: (value: SelectOptionProps) => void;
};

export const Tabs = (props: Props) => {
  const device = useDevice();
  const [index, setIndex] = useState<number>(0);
  const routes = getTabViewRoutes(device.phone);

  const handleIndexChange = useCallback((i: number) => {
    setIndex(i);
  }, []);

  const View = (params: TabViewRenderParams) => {
    switch (params.route.key) {
      case TabKeys.dial:
        return <Tab.Dial
                  visibilityValue={props.visibilityValue}
                  disabledVisibility={props.disabledVisibility}
                  phoneInformation={props.phoneInformation}
                  visibilityVisible={props.visibilityVisible}
                  onSelectVisibility={props.onSelectVisibility} />;
      case TabKeys.desktop:
      default:
        return <Tab.Desktop
                  cameraVisible={props.cameraVisible}
                  cameraValue={props.cameraValue}
                  disabledCamera={props.disabledCamera}
                  microphoneValue={props.microphoneValue}
                  disabledMicrophone={props.disabledMicrophone}
                  visibilityValue={props.visibilityValue}
                  disabledVisibility={props.disabledVisibility}
                  visibilityVisible={props.visibilityVisible}
                  showCameraPreview={props.showCameraPreview}
                  onSelectCamera={props.onSelectCamera}
                  onSelectMicrophone={props.onSelectMicrophone}
                  onSelectVisibility={props.onSelectVisibility} />;
    }
  };

  return (
    <TabView
      index={index}
      onIndexChange={handleIndexChange}
      routes={routes}
      renderTabView={View} />
  );
};

const TabKeys: TabKey = {
  desktop: 'desktop',
  dial: 'dial',
};

const getTabViewRoutes = (isMobile: boolean): TabRoute[] => {
  return [
    !isMobile
      ? {
        title: <Tab.DesktopTitle />,
        key: TabKeys.desktop,
      }
      : null,
    {
      title: <Tab.DialTitle />,
      key: TabKeys.dial,
    },
  ].filter(Boolean);
};

export default Tabs;
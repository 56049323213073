import { useCallback, useMemo } from 'react';
import { useSelectUser } from '@containers/Store/hooks';
import { useCallAccess } from '@utils/api';
import { SuggestedMentionsContext } from 'components/Remirror/Context';

type Props =
  ICallId &
  ChildrenProps;

export const CallTranscriptMentionsContainer = ({ callId, children }: Props) => {

  const {
    data: callAccess,
  } = useCallAccess({ callId }, {
    initialData: [],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const user = useSelectUser();

  const sortedUsers = useMemo(() => {
    return callAccess
      .map(p => ({
        id: p.user.id,
        name: `${p.user.firstName} ${p.user.lastName}`,
      })).sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
  }, [callAccess]);

  const fetchMentions = useCallback((query: string) => {

    if (!query) return Promise.resolve(sortedUsers);

    const filtered = sortedUsers
      .filter(f => f.name.toLowerCase().includes(query) && f.id !== user.id);

    return Promise.resolve(filtered);
  }, [sortedUsers, user.id]);

  return (
    <SuggestedMentionsContext.Provider value={[queryKey, fetchMentions]}>
      {children}
    </SuggestedMentionsContext.Provider>
  );
};

const queryKey = 'call-transcript-users';
import { useCallback } from 'react';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useHasUncommittedQuestionChanges = () => {
  const [state] = useSurveyBuilderState();

  const hasUncommittedQuestionChanges = useCallback(() => {
    return JSON.stringify(state.survey.questions) !== JSON.stringify(state.committed.questions);
  }, [
    state.committed.questions,
    state.survey.questions,
  ]);

  return hasUncommittedQuestionChanges;
};

export const useHasUncommittedQuotaChanges = () => {
  const [state] = useSurveyBuilderState();

  const hasUncommittedQuotaChanges = useCallback(() => {
    return JSON.stringify(state.survey.quotas) !== JSON.stringify(state.committed.quotas);
  }, [
    state.committed.quotas,
    state.survey.quotas,
  ]);

  return hasUncommittedQuotaChanges;
};

export const useHasUncommittedChanges = () => {
  const [state] = useSurveyBuilderState();

  const hasUncommittedQuotaChanges = useCallback(() => {
    return JSON.stringify(state.survey.quotas) !== JSON.stringify(state.committed.quotas) ||
      JSON.stringify(state.survey.questions) !== JSON.stringify(state.committed.questions)
    ;
  }, [
    state.committed.questions,
    state.committed.quotas,
    state.survey.questions,
    state.survey.quotas,
  ]);

  return hasUncommittedQuotaChanges;
};
import { CompletedSurveyContext, CompletedSurveyContextValue } from './Context';
import { CompletedSurveyResponse } from './interfaces';

type Props = {
  children: React.ReactNode;
  response: CompletedSurveyResponse;
};

export function CompletedSurveyContainer({ children, response }: Props) {
  const value: CompletedSurveyContextValue = {
    response,
  };

  return (
    <CompletedSurveyContext.Provider value={value}>
      {children}
    </CompletedSurveyContext.Provider>
  );
}
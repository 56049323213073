import { useCallback } from 'react';
import { useSelectAdHocCall } from '@containers/Store';
import { useCancelAdHocCall as useApi } from '@utils/api';
import { useAdHocCallSchedulingNotifier } from '@containers/Scheduler/Notifier.AdHocCall';

export const useCancelAdHocCall = (callId: number) => {
  const call = useSelectAdHocCall(callId);
  const notifier = useAdHocCallSchedulingNotifier();

  const { mutateAsync: cancel } = useApi({ callId }, {
    onSuccess: data => {
      notifier.cancellation.success(callId);
    },
    onError: e => {
      notifier.cancellation.error();
    },
  });

  const handleCancel = useCallback(() => {
    return cancel();
  }, [cancel]);

  return [true, handleCancel] as const;
};

export default useCancelAdHocCall;
import { useCallback } from 'react';
import { useTable, useFlexLayout, usePagination, useSortBy } from 'react-table';
import { LinkProps } from 'react-router-dom';
import { ProjectStatus } from '@enums';
import { Card, CardContent, Divider, GroupCardHeader } from '@presentation/Main';
import { GroupTable, GroupTableFooter } from '@presentation/GroupTable';
import { Button } from 'components/Button';
import { ProjectStatusFilter, ProjectsEmptyState, ProjectsTableItem } from 'components/GroupProject';
import { useProjectsTableColumns } from 'components/GroupProject/hooks/useProjectsTableColumns';
import styles from './style/Table.css';

type Props = {
  canCreateNew?: boolean;
  createNewLocation?: LinkProps['to'];
  data: ProjectsTableItem[];
  parentObjectId?: number;
  setStatusFilter: (status: ProjectStatus) => unknown;
  statusFilter: ProjectStatus;
};

export const ProjectsTable = ({ canCreateNew, createNewLocation, data, setStatusFilter, statusFilter }: Props) => {

  const columns = useProjectsTableColumns();

  const Header = useCallback(() => {
    return (
      <GroupCardHeader className={styles.header}>
        <div className={styles.left}>
          <div>Projects</div>
          <ProjectStatusFilter
            className={styles.filter}
            value={statusFilter}
            onChange={setStatusFilter} />
        </div>
        {canCreateNew &&
          <Button.Primary
            to={createNewLocation}
            variant="brick">
            Create New
          </Button.Primary>}
      </GroupCardHeader>
    );
  }, [
    canCreateNew,
    createNewLocation,
    setStatusFilter,
    statusFilter,
  ]);

  const tableInstance = useTable<ProjectsTableItem>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
        sortBy: [{
          id: 'createdOn',
          desc: true,
        }],
      },
    },
    useFlexLayout,
    useSortBy,
    usePagination,
  );

  const tableClasses = {
    body: styles.body,
    td: styles.td,
  };

  const footerClasses = {
    root: styles.footer,
    pageSize: styles.rows,
    pagination: styles.pagination,
  };

  return (
    <Card className={styles.card}>
      <Header />
      {!data.length && <Divider />}
      <CardContent className={styles.content} fluid>
        {!!data.length &&
          <GroupTable
            classes={tableClasses}
            instance={tableInstance} />}
        {!!data.length &&
          <GroupTableFooter
            classes={footerClasses}
            instance={tableInstance} />}
        {!data.length &&
          <ProjectsEmptyState
            className={styles.empty}
            creationDisabled={!canCreateNew}
            status={statusFilter} />}
      </CardContent>
    </Card>
  );
};

export default ProjectsTable;
import { useSignupSubmit } from '$website/containers/Auth/hooks';
import { AnimatedLoader } from 'components/ActivityIndicator';
import styles from './style/SignUpForm.css';

type Props = unknown;

const SignupButton = (props: Props) => {
  const [state] = useSignupSubmit();

  const content = state.loading
      ? <AnimatedLoader color="var(--pri-01)" />
      : <div className={styles.text}>Sign Up</div>;

  return (
    <button
      className={styles.btn}
      disabled={state.loading}>
      {content}
    </button>
  );
};

export { SignupButton };
export default SignupButton;
import * as pathname from '@consts/pathname';
import { join } from '@utils/path';

const admin = join(pathname.Admin);
const members = join(admin(`/members`));
const posts = join(admin(`/posts`));
const projects = join(admin(`/projects`));
const search = join(admin(`/search`));

const Members = {
  Acquisition: members(`/acquisition`),
  Activity: {
    Invited: members('/activity/invited'),
    Recommended: members('/activity/recommended'),
    Unverified: members('/activity/unverified'),
  },
  Member: members(`/:userId`),
  Root: members(),
};

const Posts = {
  Root: posts(),
  Videos: {
    Root: posts(`/videos`),
    New: posts(`/videos/new`),
  },
};

const Projects = {
  Root: projects(),
  New: projects(`/new`),
  Overview: projects(`/:projectId`),
  Edit: projects(`/:projectId/edit`),
  SurveyResponses: projects(`/:projectId/survey-responses/:tab`),
  Survey: {
    Root: projects(`/:projectId/survey`),
    Questions: projects(`/:projectId/survey/questions`),
    Logic: projects(`/:projectId/survey/logic`),
    Quotas: projects(`/:projectId/survey/quotas`),
    Tagging: projects(`/:projectId/survey/tagging`),
  },
  Calls: projects(`/:projectId/calls`),
  Cloning: projects(`/:projectId/clone`),
  ComplianceSurveyMem: projects(`/:projectId/members/:userId/compliance-survey`),
  Details: projects(`/:projectId/details`),
  Members: projects(`/:projectId/members`),
  Leads: projects(`/:projectId/leads`),
};

const Search = {
  Root: search(),
  Members: search(`/members`),
};

export const Admin = {
  AccountsActivity:             admin(`/accounts/activity`),
  AccountDetails:               admin(`/accounts/:groupId`),
  AccountUserDetails:           admin(`/accounts/:groupId/users/:userId`),
  AccountUsers:                 admin(`/accounts/:groupId/users`),
  AccountBalance:               admin('/accounts/:groupId/balance-account'),
  Accounts:                     admin('/accounts'),
  Billing:                      admin('/billing'),
  BillingCharge:                admin('/billing/charge/:id'),
  BillingCharges:               admin('/billing/charges'),
  BillingChargeable:            admin('/billing/chargeable/:id'),
  BillingChargeables:           admin('/billing/chargeables'),
  BillingInvoice:               admin('/billing/invoice/:id'),
  BillingInvoiceNew:            admin('/billing/invoice'),
  BillingInvoices:              admin('/billing/invoices'),
  BillingPackages:              admin('/billing/packages'),
  BillingPayout:                admin('/billing/(payout|payouts)/:id'),
  BillingPayouts:               admin('/billing/payouts'),
  BillingPayoutable:            admin('/billing/payoutable/:id'),
  BillingPayoutables:           admin('/billing/payoutables'),
  BillingReports:               admin('/billing/reports'),
  Call:                         admin(`/calls/:callId`),
  Calls:                        admin(`/calls`),
  Companies:                    admin('/companies'),
  CompanyDetails:               admin('/companies/:companyId'),
  CompanyNew:                   admin('/companies/new'),
  GMV:                          admin(`/gmv`),
  GroupComplianceSurvey:        admin(`/accounts/:groupId/compliance-survey`),
  GroupPostCallQuestions:       admin(`/accounts/:groupId/post-call-questions`),
  Keywords:                     admin(`/keywords`),
  KPI:                          admin(`/KPI`),
  LinkedinUpload:               admin(`/linkedin-upload`),
  Members,
  Messages:                     admin(`/messages`),
  MessagesAll:                  admin(`/messages/all`),
  MessagesAwaitingReply:        admin(`/messages/awaiting-reply`),
  MessagesRejected:             admin(`/messages/rejected`),
  MessagesReview:               admin(`/messages/review`),
  MessagesCompliance:           admin(`/messages/compliance`),
  NewAccount:                   admin(`/accounts/new`),
  NewAccountUser:               admin(`/accounts/:groupId/users/new`),
  NewAccountDocument:           admin(`/accounts/:groupId/new-document`),
  NewPackage:                   admin(`/billing/packages/new`),
  Package:                      admin(`/billing/packages/:packageId`),
  Posts,
  Projects,
  ProjectSurveyResponse:        admin(`/project-survey-response/:responseId`),
  Questions:                    admin(`/questions`),
  RecruitmentDashboard:         admin(`/recruitment-dashboard`),
  Referrals:                    admin(`/referrals`),
  Root:                         admin(),
  Search,
  Topics:                       admin(`/topics`),
  TopicCreate:                  admin(`/topics/new`),
  TopicDetails:                 admin(`/topics/:topicId`),
  Users:                        admin(`/users`),
  UserScheduling:               admin(`/users/:userId/scheduling`),
};

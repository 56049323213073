import http from '@services/http';
import * as cancellation from '@services/http/cancellation';
import * as xform from '@transformers';
import { safeJsonDate } from '@utils';
import { Users } from './interfaces';

export * from './users.connected-accounts';
export * as consent from './users.consent';
export * as geolocation from './users.geolocation';
export * as notification from './users.notifications';
export * as topics from './users.topics';
export * as verify from './users.verify';

export const createProjectInvite = (data: Users.CreateProjectInvite.Request): Promise<Users.CreateProjectInvite.Response> => {
  return http.post('/users/invite', data);
};

export const deleteAccount = (): Promise<Users.DeleteAccount.Response> => {
  return http.delete(`/users/me`);
};

export const getCallsToRate = (): Promise<number[]> => {
  return http.get(`/users/calls-to-rate`);
};

export const getOutstandingPostCallQuestionnares = (): Promise<Users.GetOutstandingPostCallQuestionnairesResponse> => {
  return http.get(`/users/post-call-questionnaires`);
};

export const searchContactsCancellable = (data: Users.SearchContactsRequest): ExtendedPromise<Users.SearchContactsResponse> => {
  return http.get<Users.SearchContactsResponse>(`/users/search-contacts?q=${data.query}`, {
    cancelToken: data.cancelToken,
  })
    .catch<Users.SearchContactsResponse>(e => cancellation.ignoreRequestCancellationError(e, {} as Users.SearchContactsResponse));
};

export const searchContacts = (data: Users.SearchContactsRequest): Promise<Users.SearchContactsResponse> => {
  return http.get(`/users/search-contacts?q=${data.query}`);
};

export const updateAccountMetadata = (data: Users.UpdateAccountMetadata.Request): Promise<Users.UpdateAccountMetadata.Response> => {
  return http.patch(`/users/${data.userId}`, { meta: data });
};

export const updateContactInfo = (data: Users.UpdateContactInfo.Request): Promise<Users.UpdateContactInfo.Response> => {
  return http.patch('/users/me', data);
};

export const updateOnboarding = (): Promise<Users.UpdateOnboardingResponse> => {
  return http.post('/users/onboarding', {})
    .then(xform.normalizeOnboarding);
};

export const updateSettings = (data: Users.UpdateSettingsRequest): Promise<Users.UpdateSettingsResponse> => {
  return http.patch(`/users/settings`, data);
};

export const updatePrompts = (data: Users.UpdatePromptsRequest): Promise<Users.UpdatePromptsResponse> => {
  return http.patch(`/users/prompts`, data);
};

export const getRecentProfiles = (data: Users.GetRecentProfiles.Request): ExtendedPromise<Users.GetRecentProfiles.Response> => {
  return http.get('/users/recent-profiles', {
    cancelToken: data.cancelToken,
  })
    .then(({ items }: Users.GetRecentProfiles.Response<string>) => ({
      items: items.map(m => ({
        ...m,
        lastVisited: safeJsonDate(m.lastVisited),
      })),
    }));
};

export const getSessions = (): Promise<Users.GetSessions.Response> => {
  return http.get('/users/sessions')
    .then((response: Users.GetSessions.Response<string>) => ({
      items: response.items.map(item => ({
        ...item,
        created: safeJsonDate(item.created),
      })),
    }));
};

export const logoutSession = ({ sids }: Users.LogoutSession.Request): Promise<Users.LogoutSession.Response> => {
  return http.post(`/users/sessions/logout`, {
    sids,
  });
};

export const exportUserData = () => {
  return http.download('/users/documents/export');
};

export const getConnectedAccountToken = (data: Users.GetConnectedAccountToken.Request): Promise<Users.GetConnectedAccountToken.Response> => {
  return http.get(`/users/connected-account-token?id=${data.id}`);
};
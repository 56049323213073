import { Fragment, useMemo } from 'react';
import * as consts from '@consts';
import { RankingQuestion } from '@/types';
import { RankingAnswerValueProps, RankingAnswerOptionProps } from './interfaces';
import styles from './style/Ranking.css';

const sortOptions = (a: RankingQuestion.Answer.Item, b: RankingQuestion.Answer.Item) => {
  return a.ordinal === consts.surveys.NotApplicableRankingOrdinal
    ? 1
    : b.ordinal === consts.surveys.NotApplicableRankingOrdinal
      ? -1
      : b.ordinal > a.ordinal
        ? -1
        : 1
  ;
};

const RankingAnswerOption = (props: RankingAnswerOptionProps) => {
  const ordinal = props.item.ordinal === consts.surveys.NotApplicableRankingOrdinal
    ? '-'
    : `${props.item.ordinal})`;

  return (
    <div className={styles.item}>
      <div className={styles.ordinal}>{ordinal}</div>
      {props.item.option.value}
    </div>
  );
};

export const RankingAnswerValue = ({ answer, settings }: RankingAnswerValueProps) => {
  const sortedItems = useMemo(() => {
    return answer.items
      .filter(f => !settings.mustRank || f.ordinal !== consts.surveys.NotApplicableRankingOrdinal)
      .sort(sortOptions);
  }, [answer.items, settings.mustRank]);

  return <>
    {sortedItems.map(item => (
      <Fragment key={item.option.id}>
        <RankingAnswerOption item={item} />
      </Fragment>
    ))}
  </>;
};

export default RankingAnswerValue;
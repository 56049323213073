import styles from '$website/screens/Main/style/Products.css';
import dots from 'static/images/website/datapoints-tablet-background.png';
import Section from '../Section';
import { products } from './data';
import ProductList from './ProductList';
import { TabletCarousel, MobileCarousel } from './ProductCarousel';

const Products = () => {
  const headline = `All your market research tools in one platform`;
  const copy = `Vancery's suite of enterprise research tools make up the world's most sophisticated platform to access industry-level intelligence.`;

  return (
    <Section
      className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.headline}>{headline}</div>
        <div className={styles.copy}>{copy}</div>
        <div className={styles.products}>
          <ProductList items={products} />
          <MobileCarousel items={products} />
          <TabletCarousel items={products} />
        </div>
        <img
          className={styles.dots}
          src={dots} />
      </div>
    </Section>
  );
};

export default Products;
import { useCallback, useMemo } from 'react';
import { ComputedSerie, Point } from '@nivo/line';
import { SurveyQuestionType } from '@enums';
import { useRepeatQuestionResponsesData, useRepeatSurveyData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey as RS } from '@containers/RepeatSurveyResponses/interfaces';
import { roundToPlace } from '@utils';
import { SeriesTooltip as BaseSeriesTooltip, PointsTooltip as BasePointsTooltip } from '@presentation';
import { useRepeatRankingChartData } from './hooks';
import { PointsTooltipProps, SeriesTooltipProps } from './interfaces';
import BaseLineChart from './BaseLineChart';

const SeriesTooltip = ({ series: rawSeries }: SeriesTooltipProps) => {
  const { question, aggregate } = useRepeatQuestionResponsesData<SurveyQuestionType.Ranking>();

  const getSerieLabel = useCallback((serie: ComputedSerie) => {
    const option = question.options.find(f => f.base.id === +serie.id);
    return option.value;
  }, [
    question.options,
  ]);

  const getSerieAvg = useCallback((serie: ComputedSerie) => {
    const avg = aggregate[+serie.id].avg;
    return roundToPlace(avg, 1);
  }, [aggregate]);

  const getSerieStat = useCallback((serie: ComputedSerie) => {
    const avg = getSerieAvg(serie);
    return `Avg. Rank ${avg}`;
  }, [
    getSerieAvg,
  ]);

  const series = useMemo(() => {
    return [...rawSeries]
      .sort((a, b) => getSerieAvg(b) - getSerieAvg(a));
  }, [
    getSerieAvg,
    rawSeries,
  ]);

  return (
    <BaseSeriesTooltip
      getSerieLabel={getSerieLabel}
      getSerieStat={getSerieStat}
      series={series} />
  );
};

const PointsTooltip = ({ points: rawPoints }: PointsTooltipProps) => {
  const { question } = useRepeatQuestionResponsesData();

  const getPointLabel = useCallback((point: Point) => {
    const option = question.options.find(f => f.base.id === +point.serieId);
    return option.value;
  }, [
    question.options,
  ]);

  const pointsValueText = useMemo(() => {
    const avg = +rawPoints[0].data.y;
    return `Avg. Rank ${avg}`;
  }, [
    rawPoints,
  ]);

  const points = useMemo(() => {
    return rawPoints
      .sort((a, b) => {
        const { ordinal: ordinalA } = question.options.find(f => f.base.id === +a.serieId);
        const { ordinal: ordinalB } = question.options.find(f => f.base.id === +b.serieId);
        return ordinalA - ordinalB;
      });
  }, [
    rawPoints,
    question.options,
  ]);

  return (
    <BasePointsTooltip
      points={points}
      getPointLabel={getPointLabel}
      pointsValueText={pointsValueText} />
  );
};

export const RepeatRankingLineChart = () => {
  const data = useRepeatRankingChartData();
  const { setQueryState } = useRepeatSurveyResponsesState<RS.QueryState.Ranking>();
  const { projectIds } = useRepeatSurveyData();

  const formatLeftAxisLabel = useCallback((value: string) => {
    return Number.isInteger(+value)
      ? value
      : '';
  }, []);

  const handleSerieClick = useCallback((series: ComputedSerie[]) => {
    setQueryState({
      optionIds: series.map(m => +m.id),
    });
  }, [
    setQueryState,
  ]);

  const handlePointClick = useCallback((points: Point[]) => {
    const x = +points[0].data.x;
    setQueryState({
      optionIds: points.map(m => +m.serieId),
      projectId: projectIds[x - 1],
    });
  }, [
    projectIds,
    setQueryState,
  ]);

  return (
    <BaseLineChart
      data={data}
      axisLeft={{
        format: formatLeftAxisLabel,
      }}
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: true }}
      onPointClick={handlePointClick}
      onSerieClick={handleSerieClick}
      PointsTooltip={PointsTooltip}
      SeriesTooltip={SeriesTooltip} />
  );

};

export default RepeatRankingLineChart;
import { createElement, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getLocationFor } from '@utils';
import { SendMessage } from 'components/Modal/PlatformMessage';
import * as PlatformMessage from '../interfaces';
import { useAllowMessageModal } from './useAllowMessageModal';

type Params = unknown;

const useInitiatePlatformMessage = () => {
  const history = useHistory();
  const [open, setModalState] = useState(false);
  const allowModal = useAllowMessageModal();

  const toggleModal = useCallback(() => {

    setModalState(!open);

  }, [
    open,
    setModalState,
  ]);

  const handleAction = useCallback((user: PlatformMessage.PaidMessageActionParams) => {
    const userId = user.id;

    return !allowModal(userId)
      ? history.push(getLocationFor.messages.root({ userId }))
      : toggleModal();

  }, [
    allowModal,
    history,
    toggleModal,
  ]);

  const bootstrapModal = useCallback((user: PlatformMessage.PaidMessageActionParams) => {
    return function Modal() {
      return open && createElement(SendMessage, {
        onClose: toggleModal,
        user,
        visible: open,
      });
    };
  }, [
    open,
    toggleModal,
  ]);

  const initiatePlatformMessage = (user: PlatformMessage.PaidMessageActionParams) => {
    const PlatformMessageModal = bootstrapModal(user);
    const initiateMessage = () => handleAction(user);

    return [initiateMessage, PlatformMessageModal] as const;
  };

  return useCallback(initiatePlatformMessage, [
    bootstrapModal,
    handleAction,
  ]);
};

export { useInitiatePlatformMessage };
export default useInitiatePlatformMessage;
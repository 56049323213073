import { useSelectGroup } from '@containers/Store';
import { AccountPaymentMethod } from '@enums';
import useEnterprisePaymentMethod from './useEnterprisePaymentMethod';
import useUserPaymentMethod from './useUserPaymentMethod';

export function useHasPaymentMethod() {
  const group = useSelectGroup();
  const enterprise = useEnterprisePaymentMethod();
  const user = useUserPaymentMethod();

  const value = [AccountPaymentMethod.OffPlatformWire].includes(group.billing?.paymentMethod)
      || enterprise?.verified
      || user?.verified;

  return !!value;
}

export default useHasPaymentMethod;
import { useContext } from 'react';
import Cancel from '@mui/icons-material/Cancel';
import { BrandThemingContext } from '@containers/Branding/Context';
import { useRemoveSurveyCompletionImageMutation, useUpdateSurveyCompletionImageMutation } from '@containers/Branding/hooks';
import { ButtonOutlined } from '@presentation';
import { ImageCrop } from 'components/ImageCrop';
import { Modal, ModalProps } from 'components/Modal/Modal';
import { useModal } from 'components/Modal/hooks';
import styles from './style/Settings.Branding.Survey.CompletionImage.css';

type Props = unknown;

export const CompletionImage = (props: Props) => {
  const { query } = useContext(BrandThemingContext);
  const [toggle, UploadModal] = useModal(ImageUploadModal);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>Survey Thank You Page Header Image</h2>
        <div className={styles.main}>
          <div className={styles.upload}>
            <ButtonOutlined
              borderWidth="thin"
              className={styles.btn}
              color="silver"
              fontWeight="light"
              onClick={toggle}>
              Upload
            </ButtonOutlined>
          </div>
          <HeaderImage imageSrc={query.data?.survey?.page?.completion?.headerImage?.url} />
        </div>
      </div>
      <UploadModal />
    </div>
  );
};

CompletionImage.displayName = 'Settings.Branding.Survey.CompletionImage';

type HeaderImageProps = {
  imageSrc?: string;
};

const HeaderImage = (props: HeaderImageProps) => {
  const mutation = useRemoveSurveyCompletionImageMutation();

  if (!props.imageSrc) {
    return (
      <div className={styles.display}>
        <div className={styles.placeholder} />
        <div className={styles.noimage}>No Image</div>
      </div>
    );
  }

  return (
    <div className={styles.display}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${props.imageSrc})` }} />
      <div
        className={styles.remove}
        onClick={mutation.mutate}>
        <Cancel />
      </div>
    </div>
  );
};

type ImageUploadModalProps = {
  imageSrc?: string;
} & Pick<ModalProps, 'onClose' | 'open'>;

const ImageUploadModal = (props: ImageUploadModalProps) => {
  const mutation = useUpdateSurveyCompletionImageMutation({
    onSuccess: res => {
      props.onClose();
    },
  });

  return (
    <Modal
      classes={{ root: styles.modal }}
      onClose={props.onClose}
      open={props.open}>
      <ImageCrop
        classes={{
          root: styles.cropper,
          picker: styles.picker,
          preview: {
            image: styles.cropped,
          },
          original: styles.original,
        }}
        aspect={570/110}
        crop={{
          width: 1140,
          height: 220,
        }}
        imageUrl={props.imageSrc}
        isSubmitting={mutation.isLoading}
        onSave={data => mutation.mutate({ blob: data.blob })}
        ruleOfThirds={true} />
    </Modal>
  );
};
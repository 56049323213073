import mixpanel from 'mixpanel-browser';
import * as adHocCall from './call.adhoc';
import * as auth from './auth';
import * as calendar from './calendar';
import * as conference from './conference';
import * as messages from './messages';
import * as onboarding from './onboarding';
import * as profile from './profile';
import * as project from './project';
import * as refer from './refer';
import * as report from './report';
import * as search from './search';
import * as settings from './settings';
import * as share from './share';
import * as survey from './survey';

export function init() {
  mixpanel.init(process.env.MIXPANEL_TOKEN, {
    ignore_dnt: true,
    ip: true,
    // verbose: true,
    // debug: true,
  });
}

export const event = {
  adHocCall,
  auth,
  calendar,
  conference,
  messages,
  onboarding,
  profile,
  project,
  refer,
  report,
  search,
  settings,
  share,
  survey,
};
import { useCallback, useContext, useState } from 'react';
import { SuggestedTopicsContainer } from '@containers/Topic';
import { FollowedTopicsContext } from '@containers/Topic/Context';
import { TabView, TabViewRenderParams } from 'components/TabView';
import { Followed } from './Following.Followed';
import { Suggested } from './Following.Suggested';
import styles from './style/Following.Modal.css';

type Props = unknown;

export const Tabs = (props: Props) => {
  const [index, setIndex] = useState(0);
  const ctx = useContext(FollowedTopicsContext);

  const routes = [{
    key: `suggested`,
    title: `Discover`,
  }, {
    key: `followed`,
    title: `Following (${ctx.topics?.length ?? 0})`,
  }];

  const renderTabView = useCallback(({ route }: TabViewRenderParams) => {
    switch (route.key) {
      case 'suggested':
        return (
          <div className={styles.view}>
            <Suggested />
          </div>
        );

      case 'followed':
        return (
          <div className={styles.view}>
            <Followed />
          </div>
        );

      default:
        return null;
    }
  }, []);

  return (
    <SuggestedTopicsContainer size={70}>
      <TabView
        index={index}
        onIndexChange={setIndex}
        renderTabView={renderTabView}
        routes={routes} />
    </SuggestedTopicsContainer>
  );
};

Tabs.displayName = 'Following.Tabs';
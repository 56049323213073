import { forwardRef } from 'react';
import ButtonBase from './ButtonBase';
import { LinkButton } from './LinkButton';
import {
  ButtonProps,
  ButtonRef,
  ButtonStatics,
  LinkButtonProps,
  StaticButtonAttributes,
  StaticButtonProps,
} from './interfaces';

type Props =
  & ButtonProps
  & Partial<LinkButtonProps>;

const Button = forwardRef<StaticButtonAttributes, Props>((props, ref) => {
  return props.to || props.href
    ? <LinkButton {...props as LinkButtonProps} ref={ref} />
    : <ButtonBase {...props} ref={ref} />;
}) as ButtonRef<Props> & Partial<ButtonStatics>;

Button.displayName = 'Button';

Button.Affirmative = (props: StaticButtonProps) => <Button color="affirmative" {...props} />;
Button.Destructive = (props: StaticButtonProps) => <Button color="destructive" {...props} />;
Button.Primary     = (props: StaticButtonProps) => <Button color="primary" {...props} />;
Button.Secondary   = (props: StaticButtonProps) => <Button color="secondary" {...props} />;
Button.Transparent = (props: StaticButtonProps) => <Button color="transparent" {...props} />;

export { Button };
export default Button;
import { Component } from 'react';
import { BookmarkUsersScheduler } from '@containers/Scheduler/BookmarkUsers';
import { BookmarkInProjectCallbackParams, BookmarkUsersInjectedProps } from '@containers/Scheduler/interfaces';
import { Contact, Project } from '@/types';
import { Select } from 'components/Select';
import { Button } from 'components/Button';
import Body from './Body';
import Header from './Header';
import Modal from './Modal.Legacy';
import styles from './style/Base.css';

type OwnProps = {
  onClose: () => void;
  users:   Contact[];
};

type Props =
    OwnProps
  & BookmarkUsersInjectedProps;

type State = {
  project: Project | null;
};

class AddToProjectModal extends Component<Props, State> {
  state: State = {
    project: null,
  }

  getSelectText = () => {
    if (this.state.project) return this.state.project.name;

    if (this.props.options.length) return 'Select a project';

    if (this.props.users.length > 1) return 'No projects available';

    return `No projects are available for this expert`;
  }

  handleClose = (data: BookmarkInProjectCallbackParams) => {
    this.props.onClose();
  }

  handleSelected = item => {
    this.setState({ project: item });
  }

  handleSubmit = () => {
    this.props.handleSubmitBookmarkUsers({
      projectId: this.state.project.id,
      userIds: this.props.users.map(x => x.id),
    }, this.props.onClose);
  }

  render() {
    return(
      <Modal className={styles.addToProject} onClose={this.props.onClose}>
        <Header>Add to Project</Header>

        <Body className={styles.row}>
          <div className={styles.label}>Project</div>
          <div className={styles.select}>
            <Select
              autoFocus
              defaultValue={this.getSelectText()}
              getItemValue={v => v.name}
              onSelect={this.handleSelected}
              options={this.props.options} />
          </div>
        </Body>

        <div className={styles.footer}>
          <Button
            color="primary"
            disabled={!this.state.project}
            onClick={this.handleSubmit}
            variant="pill">
            Add to Project
          </Button>
        </div>
      </Modal>
    );
  }
}

const AddToProject = <P extends BookmarkUsersInjectedProps>(props: OwnProps & P) => {
  return (
    <BookmarkUsersScheduler users={props.users}>
      <AddToProjectModal {...props}  />
    </BookmarkUsersScheduler>
  );
};

export default AddToProject;
import { useCallback } from 'react';
import { useConferenceInstance, useCoordinator, useTwilioVideo, IConference } from '@containers/Conference';
import { ButtonActivityIndicator } from '@presentation';
import { SelectOptionProps } from 'components/Select/interfaces';
import Toast from 'components/Toast';
import { Tabs } from './Tabs';
import * as utils from './utils';
import styles from './style/Video.css';

export function Video() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.PreRoom>();
  const coordinator = useCoordinator();
  const { getAVPermission } = useTwilioVideo();

  const handleCamera = useCallback((value: SelectOptionProps) => {
    coordinator.updatePreRoom({
      conferenceIdentifier: instance.conferenceIdentifier,
      settings: {
        camera: utils.cameraToBool(value.id),
      },
    });
  }, [instance.conferenceIdentifier, coordinator]);

  const handleMicrophone = useCallback((value: SelectOptionProps) => {
    const enabled = utils.microphoneToBool(value.id);
    if (enabled) {
      getAVPermission(true, false)
        .then(result => {
          if (!result) {
            coordinator.updatePreRoom({
              conferenceIdentifier: instance.conferenceIdentifier,
              settings: {
                microphone: false,
              },
            });
            Toast.error({
              title: `Failed to get browser permission to use your microphone!`,
            });
          }
        });
    }
    coordinator.updatePreRoom({
      conferenceIdentifier: instance.conferenceIdentifier,
      settings: {
        microphone: enabled,
      },
    });
  }, [getAVPermission, instance.conferenceIdentifier, coordinator]);

  const handleVisibility = useCallback((value: SelectOptionProps) => {
    coordinator.updatePreRoom({
      conferenceIdentifier: instance.conferenceIdentifier,
      settings: {
        visibility: utils.visibilityToBool(value.id),
      },
    });
  }, [instance.conferenceIdentifier, coordinator]);

  const handleJoin = useCallback(() => {
    coordinator.join({
      conferenceIdentifier: instance.conferenceIdentifier,
      visibility: instance.settings.visibility,
    });
  }, [instance.conferenceIdentifier, instance.settings.visibility, coordinator]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.title}>
            {instance.conference.title} Call
          </div>
          <div className={styles.date}>
            {utils.formatCallTime(instance.call.start, instance.call.end)}
          </div>
          <div className={styles.subtitle}>
            {(instance.features.cameraToggle || instance.features.microphoneToggle || instance.features.visibilityToggle)
              ? `Join the conference with the settings below.`
              : `The settings below have been set for you by the Host.`}
          </div>
          <div className={styles.subtitle}>
            {(instance.features.cameraToggle || instance.features.microphoneToggle || instance.features.visibilityToggle)
              ? `You'll also be able to change these after joining.`
              : null}
          </div>
        </div>
        <div className={styles.content}>
          <Tabs
             cameraVisible={true}
             cameraValue={utils.boolToCamera(instance.settings.camera)}
             disabledCamera={!instance.features.cameraToggle}
             disabledMicrophone={!instance.features.microphoneToggle}
             microphoneValue={utils.boolToMicrophone(instance.settings.microphone)}
             disabledVisibility={!instance.features.visibilityToggle}
             visibilityValue={utils.boolToVisibility(instance.settings.visibility)}
             phoneInformation={instance.dial}
             visibilityVisible={instance.features.showVisibility}
             showCameraPreview={instance.settings.camera}
             onSelectCamera={handleCamera}
             onSelectMicrophone={handleMicrophone}
             onSelectVisibility={handleVisibility} />
        </div>
        <div className={styles.actions}>
          <ButtonActivityIndicator
            className={styles.join}
            loading={instance.joiningMeeting}
            variant="brick"
            onClick={handleJoin}>
            Join Conference
          </ButtonActivityIndicator>
        </div>
      </div>
    </div>
  );
}
import { useSelector } from 'react-redux';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks/useProjectState';
import { UserProjectStatus } from '@enums';
import { MassPipelineItem } from '@screens/ProjectPipeline/interfaces';
import { useFilteredResponseUsers } from '@containers/GroupProject/hooks/useFilteredResponseUsers';

const selectMassPipelineUsers = (projectId: number) => (state: Store.State): MassPipelineItem[] => {
  const pipeline = state.pipeline.project[projectId];
  const filteredUserIds = useFilteredResponseUsers();

  return Object.keys(pipeline.users).reduce((acc, id) => {
    const userId = +id;
    const record = pipeline.users[userId];

    if (record.statusId === UserProjectStatus.Disqualified || (filteredUserIds && !filteredUserIds.includes(userId))) {
      return acc;
    }

    const contact = state.contacts[userId];

    return [
      ...acc,
      {
        id: userId,
        contact: {
          career: contact.career,
          profile: contact.profile,
        },
        pipeline: pipeline.users[userId],
        respondent: pipeline.respondents[userId],
      },
    ];
  }, [] as MassPipelineItem[]);
};

export const useMassPipelineUsers = () => {
  const project = useProjectOrLatestChild();
  const users = useSelector(selectMassPipelineUsers(project.id));

  return users;
};
import { SurveyActionType, SurveyQuotaType, SurveyQuestionType } from '@enums';
import { SurveyCondition } from './survey.question.conditions';

export namespace SurveyQuota {
  type Common = {
    actionType: SurveyActionType;
    id: number;
    identifier: string;
    name: string;
    limit: number;
  };

  export type QuestionType =
    SurveyQuestionType.MatrixGrid |
    SurveyQuestionType.MatrixMultiselect |
    SurveyQuestionType.MultipleChoice |
    SurveyQuestionType.Multiselect;

  export type Record<T extends SurveyQuotaType = SurveyQuotaType> = RecordMap[T];

  type RecordMap = {
    [SurveyQuotaType.Basic]: Basic.Record;
    [SurveyQuotaType.Advanced]: Advanced.Record;
  };

  export namespace Basic {

    export type Record = {
      data: Value;
      type: SurveyQuotaType.Basic;
    } & Common;

    export type Item<T extends QuestionType = QuestionType> = {
      condition: SurveyCondition<T>;
      identifier: string;
      question: {
        identifier: string;
      };
    };

    export type Value = {
      items: Item[];
    };
  }

  export namespace Advanced {

    export type Record = {
      data: Value;
      type: SurveyQuotaType.Advanced;
    } & Common;

    export type Value = {
      sets: Set[];
    };

    export type SetItem<T extends QuestionType = QuestionType> = {
      identifier: string;
      pct: number;
      condition: SurveyCondition<T>;
    };

    export type Set<T extends QuestionType = QuestionType> = {
      identifier: string;
      question: {
        identifier: string;
      };
      items: SetItem<T>[];
    };
  }

}
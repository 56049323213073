import { NumberFormatPropsBase } from 'react-number-format';
import { NumberInput } from './NumberInput';
import { InputProps } from './interfaces';

type MoneyProps = {
  onChange: (val: number) => void;
  value: number;
  formatterProps?: Omit<NumberFormatPropsBase, 'className' | 'value'>;
  rootClassName?: string;
} & Pick<InputProps,
  'focusableClasses' |
  'classes' |
  'onBlur' |
  'onFocus' |
  'readOnly' |
  'autoFocus'>;
export const MoneyInput = ({ onChange, formatterProps, ...props }: MoneyProps) => {
  return (
    <NumberInput
      onValueChange={v => onChange(v.floatValue)}
      {...props}
      {...formatterProps}
      prefix={'$'}
      thousandSeparator={true} />
  );
};
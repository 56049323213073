import ListEmpty from './ListEmpty';
import ProjectShareLink from './ProjectShareLink';
import styles from './style.css';

type Props = {
  resultsCount: number;
  totalCount:   number;
  visible:      boolean;
} & IProjectId;

const ProjectSearchListFooter = (props: Props) => {
  if (!props.visible || props.resultsCount !== props.totalCount) return null;

  return (
    <>
      <ListEmpty
        resultsCount={props.resultsCount}
        totalCount={props.totalCount} />

      <div className={styles.root}>
        <div className={styles.wrap}>
          {props.resultsCount > 0 &&
            <div className={styles.zero}>Try expanding your search criteria for more results.</div>}

          <div>
            <div className={styles.vancery}>Our team has been notified and is working on sourcing candidates.</div>
            <ProjectShareLink projectId={props.projectId} />
          </div>
        </div>
      </div>
    </>
  );
};

export { ProjectSearchListFooter };
export default ProjectSearchListFooter;
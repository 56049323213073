import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const checkboxClasses = makeStyles({
  labelRoot: {
    marginLeft: 0,
    userSelect: 'none',
  },
  labelLabel: {
    fontSize: 15,
    fontFamily: 'var(--font-reg)',
    width: '100%',
  },
  checkboxRoot: {
    color: 'var(--gray-d)',
    padding: 0,
    marginRight: 6,
    flexShrink: 0,
  },
  checkboxChecked: {
    color: 'var(--pri-02)',
  },
});

export default function FiltersCheckbox({ aggregation, className, checked, label, onChange }: FiltersCheckboxProps) {
  const classes = checkboxClasses();

  const displayLabel = aggregation !== -1
    ? `${label} (${aggregation ?? 0})`
    : `${label}`;

  return (
    <FormControlLabel
      className={className}
      classes={{
        root: classes.labelRoot,
        label: classes.labelLabel,
      }}
      control={
        <Checkbox
          disableRipple={true}
          color="default"
          classes={{
            root: classes.checkboxRoot,
            checked: classes.checkboxChecked,
          }}
          checked={checked ?? false}
          onChange={onChange} />
      }
      label={displayLabel} />
  );
}

export { FiltersCheckbox };

type FiltersCheckboxProps = {
  aggregation: number;
  className: string;
  checked: boolean;
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}
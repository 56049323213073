import { ProjectSurveyResponsesLoadingContext, SurveyStarredAnswersContainer } from '@containers/SurveyResponses';
import { SurveyContext } from '@containers/Survey/Context';
import { useProjectState } from '@containers/GroupProject/hooks/useProjectState';
import { useFetchRepeatSurveyResponses, useRepeatSurveyResponsesState } from './hooks';
import { RepeatSurveyResponseDataContext } from './Context';

export const MassRepeatResponsesDataContainer = (props: ChildrenProps) => {
  const { projectParent } = useProjectState();
  const { query } = useRepeatSurveyResponsesState();

  const {
    data,
    isLoading,
  } = useFetchRepeatSurveyResponses({
    limit: 1,
    projectParentId: projectParent.id,
    questionId: query.questionId,
  }, {
    keepPreviousData: true,
  });

  return (
    <ProjectSurveyResponsesLoadingContext.Provider value={isLoading && !data?.survey}>
      <RepeatSurveyResponseDataContext.Provider value={data?.data}>
        <SurveyContext.Provider value={data?.survey ?? null}>
          <SurveyStarredAnswersContainer initial={data?.starred || {}}>
            {props.children}
          </SurveyStarredAnswersContainer>
        </SurveyContext.Provider>
      </RepeatSurveyResponseDataContext.Provider>
    </ProjectSurveyResponsesLoadingContext.Provider>
  );
};

export default MassRepeatResponsesDataContainer;
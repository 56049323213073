import { QuestionLogicBuilderContainer } from '@containers/SurveyBuilder.Logic/Question.Container';
import { QuestionLogicBuilder } from './QuestionLogicBuilder';
import { QuestionLogicItemProps } from './interfaces';
import EditableQuestionLogic from './EditableQuestionLogic';

export const QuestionLogicItem = (props: QuestionLogicItemProps) => {

  if (props.editing) {
    return (
      <QuestionLogicBuilderContainer item={props.item}>
        <QuestionLogicBuilder />
      </QuestionLogicBuilderContainer>
    );
  } else {
    return (
      <EditableQuestionLogic
        canEdit={props.canEdit}
        includeOrdinalColumn={props.includeOrdinalColumn}
        item={props.item}
        onClick={props.toggleEditingOn} />
    );
  }

};

export default QuestionLogicItem;
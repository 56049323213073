import { Fragment, memo } from 'react';
import { cx } from '@utils';
import styles from './style/GDPR.css';

export const PrivacyNotice = memo(() => {
  return (
    <Fragment>
      <div className={styles.p}>
        The purpose of this Privacy Notice is: (i) to inform you of the types of personal data that Vancery collects and uses in its operations, (ii) to explain how such personal data is used by Vancery in the course of its business in the United States, (iii) to inform you of the various policies/procedures that Vancery has adopted to protect your personal data, and (iv) to inform you of your rights under GDPR to control how Vancery uses your personal data ({i('e.g')}., your “right to be forgotten”).
      </div>

      <div className={styles.p}>
        In order to comply with GDPR, Vancery must have a “lawful basis” for collecting and using your  personal data.  Vancery complies with this requirement of GDPR by relying on your affirmative and explicit consent to use your personal data.  As set forth in more detail below, GDPR grants you certain rights with respect to your personal data, and you may withdraw your consent to Vancery’s use of your personal data in certain circumstances.
      </div>

      <div className={styles.p}>
        Vancery has appointed Patrick Waring, COO to be responsible for GDPR compliance and is Vancery’s Data Protection Officer (DPO).  As the company’s DPO, Mr. Waring is empowered to evaluate Vancery’s data protection policies and the implementation of those policies. He is responsible for monitoring ongoing GDPR compliance, assessing data protection risks, advising on data protection impact assessments, and cooperating with regulators (if applicable).
      </div>

      <div className={styles.p}>
        If you have any questions about Vancery’s GDPR Privacy Policy or the items discussed in this Notice, please contact Mr. Waring (917-768-6139 or patrick@vancery.com) at any time.
      </div>

      <div className={cx(styles.p, styles.b)}>
        Types of Personal Data Collected and How Such Data is Used:
      </div>

      <div className={styles.p}>
        Vancery has conducted an internal “information audit” as suggested by GDPR, and has determined that it collects the following personal data for the reasons set forth below.
      </div>

      <div className={styles.p}>
        Each user should understand that Vancery is located in the United States, and as a result, all personal data collected (and stored) from users in the EU will be transferred outside the EU to the United States.
      </div>

      <div className={cx(styles.p, styles.b)}>
        Vancery Signup Page:
      </div>

      <div className={styles.in}>
        1. {u('Name:')}
      </div>

      <div className={styles.p}>
        Vancery collects and stores the names of its users in order to create each user’s “profile” on the Vancery platform.  Rationale: Vancery provides the names of its users to its clients so the clients know who they are speaking to and who they can connect with.
      </div>

      <div className={styles.in}>
        2. {u('Employment History:')}
      </div>

      <div className={styles.p}>
        Vancery collects and stores the employment history of its users in order to understand more about the users and their background based on their employment history. {u('Rationale:')} By collecting employment history of users on the Vancery platform, other users are able to identify and invite their peer users based on their individual areas of expertise which makes them attractive to contact with opportunities to collaborate and engage on paid interactions.
      </div>

      <div className={styles.in}>
        3. {u('Phone Number')}
      </div>

      <div className={styles.p}>
        Vancery collects and stores each user’s phone number to facilitate discussions with Vancery’s clients.  Rationale: Vancery collects and stores user phone numbers in order to notify its users of opportunities to collaborate and engage on paid interactions in the platform. The Company utilizes this communication channel to provide an easy mechanism to serve as a reminder to its users.
      </div>

      <div className={styles.in}>
        4. {u('Email Address')}
      </div>

      <div className={styles.p}>
        Vancery collects and stores each user’s email address to facilitate communication with each user (whether by Vancery or one of its clients).  Rationale: Vancery collects and stores user email addresses in order to notify its users of opportunities to collaborate and engage on paid interactions on the platform. The Company utilizes this communication channel to provide an easy mechanism to serve as a reminder to its users. Vancery also uses its users’ email addresses to notify them of payment information and to provide free, educational content to its users.
      </div>

      <div className={cx(styles.p, styles.b)}>
        User Profiles:
      </div>

      <div className={styles.in}>
        1. {u('Profile Picture')}
      </div>

      <div className={styles.p}>
        Vancery collects and stores images of each user in order to build out the user’s profile on the Vancery platform.  Rationale: Vancery collects and stores images of its users in order to curate a virtual environment that can foster personalized connections between users.
      </div>

      <div className={styles.in}>
        2. {u('Employment and Education History')}
      </div>

      <div className={styles.p}>
        Vancery collects and stores the employment and education history of its users in order to create a profile for each user with pertinent information for Vancery clients. Rationale: By collecting employment history of users on the Vancery platform, other users are able to identify and invite their peer users based on their individual areas of expertise which makes them attractive to contact with opportunities to collaborate and engage on paid interactions.
      </div>

      <div className={cx(styles.p, styles.b)}>
        Billing:
      </div>

      <div className={styles.in}>
        1. {u('Credit/Debit Card:')}
      </div>

      <div className={styles.p}>
        Vancery collects and stores a user’s credit/debit card in order to facilitate payment to each user for work performed on behalf of clients.  Rationale: collecting and using this information allows for users to be paid as promptly and easily as possible for paid interactions on the Vancery platform
      </div>

      <div className={styles.in}>
        2. {u('Billing Address:')}
      </div>

      <div className={styles.p}>
        Vancery collects and stores a user’s billing address in order to facilitate payment to each user for work performed on behalf of clients.  Rationale: collecting and using this information allows for users to be paid as promptly and easily as possible for paid interactions on the Vancery platform.
      </div>

      <div className={styles.in}>
        3. {u('Bank Account')} (routing number, account number, country of bank account):
      </div>

      <div className={styles.p}>
        Vancery collects and stores a user’s bank account information in order to facilitate payment to each user for work performed on behalf of clients.  Rationale: collecting and using this information allows for users to be paid as promptly and easily as possible for paid interactions on the Vancery platform.
      </div>

      <div className={cx(styles.p, styles.b)}>
        Third Parties that work with Vancery:
      </div>

      <div className={styles.p}>
        In addition to collecting, storing, and using the personal data set out above, Vancery works with three industry leading companies in order to facilitate: (i) the billing process for each user ({i('i.e')}., Stripe), (ii) creating and maintaining a profile for each user on the Vancery platform ({i('i.e')}., Salesforce), and (iii) monitoring the user experience on the Vancery platform ({i('i.e')}., Mixpanel).
      </div>

      <div className={styles.p}>
        The personal data collected by Vancery is only shared when necessary with Stripe, Salesforce, and Mixpanel, to facilitate the payment of users for their engagement in paid interactions on the Vancery platform.
      </div>

      <div className={styles.p}>
        Vancery works with Stripe, Salesforce, and Mixpanel because these three companies provide services that are not Vancery’s core competency, and because each of the companies is a highly respected, industry leading participant with first-class sophisticated security systems to protect any and all personal data received from Vancery.  Vancery does not provide personal data to any other third parties and limits the information it provides to these three companies to the necessary information required to perform the specific function at issue ({i('e.g')}., Vancery will only share billing information with Stripe and not with Salesforce or Mixpanel).
      </div>

      <div className={cx(styles.p, styles.b)}>
        Safeguards in Place to Protect Such Information:
      </div>

      <div className={styles.p}>
        Vancery takes data protection into account at all times ({i('e.g')}., from the moment a relationship commences with a user to each time a user’s personal information is used).  Vancery’s processing and protection of personal data adheres to the data protection principles outlined in  GDPR.  All personal data stored by Vancery is closely tracked and encrypted to properly protect the information being stored.
      </div>

      <div className={styles.p}>
        Vancery limits the personal data it collects to only the information that is required to provide services to its clients ({i('i.e')}., no unnecessary personal data is collected or maintained).  If appropriate, Vancery deletes personal data that is no longer needed.
      </div>

      <div className={styles.p}>
        Vancery has adopted various security policies and procedures that are designed to ensure that employees are sensitive to, and knowledgeable about, data security.  This includes email security, password requirements, and (when applicable) two-factor authentication and device encryption.   Employees who have direct access to personal data receive additional, recurring training in the requirements of Vancery’s policies and procedures and Vancery limits the access of its employees to employees who “need to know” such information in connection with their daily tasks.
      </div>

      <div className={styles.p}>
        In addition, Vancery will periodically conduct a “data protection impact assessment” to best understand how its use of personal information could be jeopardized (as well as how to minimize any such risks).
      </div>

      <div className={cx(styles.p, styles.b)}>
        Your Rights Under GDPR:
      </div>

      <div className={styles.p}>
        You should be aware that GDPR grants individuals the following “eight data subject rights”:
      </div>

      <ul className={styles.ul}>
        <li>{i('Right to be informed:')} Vancery is required to tell you what data is being collected, how it is being used, how long it will be kept, and whether it will be shared with any third parties.</li>
        <li>{i('Right of access:')} you have the right to request from Vancery a copy of the information that Vancery holds about you.</li>
        <li>{i('Right of rectification:')} you have the right at any time to correct data stored by Vancery that is inaccurate or incomplete.</li>
        <li>{i('Right to be forgotten:')} in certain circumstances, you can ask Vancery to erase any personal information about you that it stores in its records.</li>
        <li>{i('Right of portability:')} in certain circumstances, you can request that Vancery transfer any data that it holds about you to another company.</li>
        <li>{i('Right to restrict processing:')} in certain circumstances, you can request that Vancery limits the way it uses your personal information.</li>
        <li>{i('Right to object:')} if applicable, you have the right to challenge certain types of processing of your personal data.</li>
        <li>{i('Rights related to automated decision-making including profiling:')} if applicable, you can ask Vancery to provide a copy of any automated processing activities if you believe that your personal data is being processed unlawfully. As a general matter, Vancery does not use automated processing activities in the ordinary course of its business.</li>
      </ul>

      <div className={cx(styles.p, styles.b)}>
        Account Deletion:
      </div>

      <div className={styles.p}>
        Vancery allows users to remove all data from the platform through our account deletion option.
      </div>

      <div className={styles.p}>
        Navigate to {b('Settings')} {'>'} {b('Account')} {'>'} {b('Delete Account')} to remove yourself from Vancery.
      </div>

      <div className={styles.p}>
        In the event that you wish to exercise any of your rights under GDPR, please contact Patrick Waring, COO (917-768-6139 or patrick@vancery.com) at any time.
      </div>
    </Fragment>
  );
});

function b(text: string) {
  return <span className={styles.b}>{text}</span>;
}

function u(text: string) {
  return <span className={styles.u}>{text}</span>;
}

function i(text: string) {
  return <span className={styles.i}>{text}</span>;
}

type Props = {
  className?: string;
};

export const PrivacyNoticeHeader = memo((props: Props) => {
  return (
    <div className={cx(styles.header, props.className)}>
      <div className={styles.p}>To be consistent with data protection laws, we’re asking you to take a moment to review key points of Vancery’s GDPR Privacy Notice.</div>
      <div className={styles.p}>Scroll down and click “I agree” when you’re ready to continue.</div>
    </div>
  );
});
import { createRef, Component } from 'react';
import * as api from '@services/api';
import * as enums from '@enums';
import { cx } from '@utils';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import SearchAutocomplete from 'components/SearchAutocomplete';
import Tag from 'components/Tag';
import CreditRangeSlider from '../CreditRangeSlider';
import SortBy from '../SortBy';
import { cutoffTagText, filterTypes } from './utils';
import styles from './style.css';

class AdvancedFilters extends Component {
  constructor(props){
    super(props);

    this.state = {
      companyList: [],
      formerCompanyList: [],
      titleList: [],
      locationList: [],
      jobfunctionList: [],
      sectorList: [],
      industryList: [],
      subindustryList: [],
      keyword: '',
      seniorityList: enums.utils.Seniority.values().map(k => ({
        id: Number(k),
        type: 'seniority',
        name: enums.utils.Seniority.getName(k),
      })),
      productList: [],
    };
  }

  clearRef = createRef();

  componentDidMount = () => {
    this.fetchSectors();
  }

  fetchSectors = () => {
    api.search.fetchSectorFields()
      .then(data => this.setState({ sectorList: data.items }));
  }

  handleItemSelected = (list, filterType) => item => {
    this.props.handleItemAdded({ list, item });

    if (['sector', 'seniority'].includes(item.type)) {
      return;
    }

    const listField = `${list}List`;
    if (filterType !== filterTypes.SELECT) {
      this.setState({
        [listField]: [],
      });
    } else{
      this.setState({
        [listField]: this.state[listField].filter(f => f.id !== item.id),
      });
    }
  }

  handleItemRemoved = data => () => {
    this.props.handleItemRemoved(data);
  }

  handleKeywordChange = list => e => {
    const keyword = e.target.value;

    if(e.key === 'Enter') {
      this.props.handleItemAdded({ list, item: { id: keyword } });

      this.setState({ keyword: '' });
    } else {
      this.setState({
        keyword,
      });
    }
  }

  getSelectedItems = field => {
    return this.props.search[field];
  }

  getFilterItems = () => {
    return [
      {
        name: null,
        type: filterTypes.SLIDER,
        onChange: this.props.handlePriceChange,
      },
      {
        name: 'Keywords',
        type: filterTypes.INPUT,
        field: enums.SearchType.Keyword,
        placeholder: 'Ex: Investments',
      },
      {
        name: 'Current Company',
        type: filterTypes.AUTOCOMPLETE,
        field: enums.SearchType.Company,
        placeholder: 'Ex: Google',
        stateField: 'companyList',
      },
      {
        name: 'Former Company',
        type: filterTypes.AUTOCOMPLETE,
        field: enums.SearchType.FormerCompany,
        placeholder: 'Ex: Microsoft',
        stateField: 'formerCompanyList',
      },
      {
        name: 'Job Function',
        type: filterTypes.AUTOCOMPLETE,
        field: enums.SearchType.JobFunction,
        placeholder: 'Ex: Accounting',
        stateField: 'jobfunctionList',
      },
      {
        name: 'Sector',
        type: filterTypes.SELECT,
        field: enums.SearchType.Sector,
        placeholder: 'Ex: Healthcare',
        stateField: 'sectorList',
      },
      {
        name: 'Industry',
        type: filterTypes.AUTOCOMPLETE,
        field: enums.SearchType.Industry,
        placeholder: 'Ex: Medical Practice',
        stateField: 'industryList',
      },
      {
        name: 'Sub-Industry',
        type: filterTypes.AUTOCOMPLETE,
        field: enums.SearchType.SubIndustry,
        placeholder: 'Ex: Cardiology',
        stateField: 'subindustryList',
      },
      {
        name: 'Title',
        type: filterTypes.AUTOCOMPLETE,
        field: enums.SearchType.Title,
        placeholder: 'Ex: Consultant',
        stateField: 'titleList',
      },
      {
        name: 'Seniority',
        type: filterTypes.SELECT,
        field: enums.SearchType.Seniority,
        placeholder: 'Ex: C-Level',
        stateField: 'seniorityList',
      },
      {
        name: 'Product',
        type: filterTypes.AUTOCOMPLETE,
        field: enums.SearchType.Product,
        placeholder: 'Ex: Salesforce',
        stateField: 'productList',
      },
      {
        name: 'Location',
        type: filterTypes.AUTOCOMPLETE,
        field: enums.SearchType.Location,
        placeholder: 'Ex: New York City',
        stateField: 'locationList',
      },
    ];
  }

  renderTagContainer = opts => {
    return (
      <div className={styles.tagContainer}>
        {
          this.getSelectedItems(opts.field).map(i =>
            <Tag
              key={i.id}
              className={styles.tag}
              onClick={this.handleItemRemoved({
                list: opts.field,
                id: i.id,
              })}
              item={{
                id: i.id,
                name: cutoffTagText(i.originalName || i.name || i.id),
                type: opts.field,
              }}
              editable={true} />
          )
        }
      </div>
    );
  }

  renderAutocompleteFilter = options => {
    const searchType = options.field === enums.SearchType.FormerCompany ? enums.SearchType.Company : options.field;
    return(
      <>
        <SearchAutocomplete
          onSelection={this.handleItemSelected(options.field, filterTypes.AUTOCOMPLETE)}
          searchType={searchType}
          selectedItems={this.getSelectedItems(options.field)}
          placeholder={options.placeholder}
          displaySearchItems={true}
          displayCustomItem={false} />
        {this.renderTagContainer(options)}
      </>
    );
  }

  renderSliderFilter = options => {
    if (!this.props.showRate) {
      return null;
    }

    return (
      <CreditRangeSlider
        maxPrice={this.props.priceRange.priceHigh}
        maxValue={this.props.priceRange.priceHigh}
        minPrice={this.props.priceRange.priceLow}
        minValue={this.props.priceRange.priceLow}
        onChange={options.onChange}
        resetButton={this.clearRef} />
    );
  }

  renderSelectFillter = opts => {
    const selectFilter = item => {
      if (opts.stateField === 'sectorList') {
        return !this.props.search.sector.includes(item.id);
      }
      if (opts.stateField === 'seniorityList') {
        return !this.props.search.seniority.includes(item.id);
      }
      return true;
    };

    const items = this.state[opts.stateField].filter(selectFilter);

    return(
      <>
        <Select
          className={styles.select}
          getItemValue={item => item.name}
          onSelect={this.handleItemSelected(opts.field, filterTypes.SELECT)}
          options={items}
          placeholder={opts.placeholder}
          value="" />
        {this.renderTagContainer(opts)}
      </>
    );
  }

  renderInputFilter = opts => {
    return(
      <>
        <Input
          autoComplete="off"
          className={styles.input}
          name={opts.name}
          placeholder={opts.placeholder}
          onKeyDown={this.handleKeywordChange(opts.field)}
          onChange={this.handleKeywordChange(opts.field)}
          type="text"
          value={this.state.keyword} />
        {this.renderTagContainer(opts)}
      </>
    );
  }

  renderFilters = () => {
    return(
      <div className={styles.filtersContainer}>
        <SortBy
          value={this.props.search.sort}
          direction={this.props.search.sortDir}
          onChange={this.props.onSortChange}
          showRate={this.props.showRate} />
        {
          this.getFilterItems().map(m =>
            <div key={m.name} className={styles.filter}>
              {m.name && <div className={styles.label}>{m.name}</div>}
              {(() => {
                switch(m.type) {
                  case filterTypes.AUTOCOMPLETE:
                    return this.renderAutocompleteFilter(m);
                  case filterTypes.SLIDER:
                    return this.renderSliderFilter(m);
                  case filterTypes.SELECT:
                    return this.renderSelectFillter(m);
                  case filterTypes.INPUT:
                    return this.renderInputFilter(m);
                }
              })()}
            </div>)
        }
      </div>
    );
  }

  render() {
    const rootStyles = cx({
      [styles.hidden]: !this.props.visible,
      [styles.visible]: this.props.visible,
    }, this.props.className);

    return (
      <div className={rootStyles}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.title}>Filters</div>
            <div
              className={styles.reset}
              ref={this.clearRef}
              onClick={this.props.onResetFilters}>
              Clear
            </div>
            <div className={styles.btns}>
              <Button
                className={styles.btn}
                color="primary"
                onClick={this.props.onClose}>
                Done
              </Button>
            </div>
          </div>
          {this.renderFilters()}
        </div>
      </div>
    );
  }
}

export default AdvancedFilters;
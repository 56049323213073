import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createConditional } from '@containers';
import { useProjectGoal, useSaveProjectOverview } from '@containers/GroupProject/hooks';
import { ProjectOverviewFormState } from '@containers/GroupProject/interfaces';
import * as enums from '@enums';
import * as selectors from '@store/selectors';
import { Project } from '@/types';
import { Editable } from 'components/Editable';
import { ProjectOverviewForm } from './ProjectOverviewForm';
import styles from './style.css';
import * as utils from './utils';

const mapState = (state: Store.State) => state.group.features;

type Props = {
  editable: boolean;
  item:     Project;
};

const ProjectOverview = (props: Props) => {
  const projectGoal = useProjectGoal();
  const projectParent = useSelector(selectors.selectProjectParent(props.item.parentId));

  const [goal, setGoalValue] = useState<number>(null);
  const [editing, setEditing] = useState(false);
  const groupFeatures = useSelector(mapState);
  const saveOverview = useSaveProjectOverview();

  useEffect(() => {

    if (projectGoal?.goal?.value) {
      setGoalValue(projectGoal.goal.value);
    }

  }, [projectGoal?.goal?.value]);

  const toggleEditing = useCallback(() => {
    setEditing(!editing);
  }, [
    editing,
    setEditing,
  ]);

  const handleSubmit = useCallback((form: ProjectOverviewFormState) => {
    saveOverview(form)
      .then(data => {
        setGoalValue(data.goal.value);

        toggleEditing();
      });

  }, [
    saveOverview,
    toggleEditing,
  ]);

  const includeFrequency = useMemo(() => {
    return utils.includeFrequency(props.item, groupFeatures);
  }, [
    groupFeatures,
    props.item,
  ]);

  const content = (
    <div className={styles.overview}>
      <div className={styles.body}>
        <div className={styles.row}>
          <div className={styles.label}>Title</div>
          <div className={styles.field}>{props.item.name}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Type</div>
          <div className={styles.field}>{enums.utils.ProjectType.getName(props.item.projectType)}</div>
        </div>
        {includeFrequency &&
          <div className={styles.row}>
            <div className={styles.label}>Frequency</div>
            <div className={styles.field}>{enums.utils.ProjectFrequency.getName(projectParent.frequency)}</div>
          </div>
        }
        <div className={styles.row}>
          <div className={styles.label}>Description</div>
          <div className={styles.field}>{props.item.description}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Goal</div>
          <div className={styles.field}>{goal}</div>
        </div>
      </div>
    </div>
  );

  const ProjectOverview = createConditional(props.editable, content);

  return (
    <div className={styles.card}>
      {editing &&
        <ProjectOverviewForm
          frequency={projectParent.frequency}
          goal={goal}
          item={props.item}
          onCancel={toggleEditing}
          onSubmit={handleSubmit} />}
      {!editing &&
        <ProjectOverview>
          <Editable onClick={toggleEditing}>
            {content}
          </Editable>
        </ProjectOverview>}
    </div>
  );
};

export { ProjectOverview };
export default ProjectOverview;
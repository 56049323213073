import { useQuery } from 'react-query';
import Save from '@mui/icons-material/Save';
import { AxiosResponse } from 'axios';
import { ButtonOutlinedLabeledIcon } from '@presentation/ButtonOutlined.LabeledIcon';
import * as api from '@api';
import { Groups } from '@api/interfaces';
import { ReportSocketContainer, useReportKey } from '@containers/Reports';
import { ActivityIndicator } from 'components/ActivityIndicator';
import styles from './style/ExportUsageButton.css';

type Data = Groups.ExportGroupData.Response;

const ExportUsageButton = () => {
  const [reportKey, setReportKey] = useReportKey();

  const {
    refetch,
    isFetching,
  } = useQuery<Data, AxiosResponse, Data>(['account-export'], () => {
    return api.groups.exportGroupData();
  }, {
    enabled: false,
    onSuccess: res => {
      setReportKey(res.key);
    },
    retry: 1,
  });

  const isBusy = isFetching || !!reportKey;

  return (
    <div className={styles.downloader}>
      <ButtonOutlinedLabeledIcon
        className={styles.export}
        onClick={() => refetch()}
        disabled={isBusy}
        icon={<Save className={styles.export} />}>
        Export Usage Report
      </ButtonOutlinedLabeledIcon>
      {isBusy
        && <ActivityIndicator.Spinner className={styles.spinner} />}
    </div>
  );
};

const ExportUsageButtonWithState = () => {

  return (
    <ReportSocketContainer>
      <ExportUsageButton  />
    </ReportSocketContainer>
  );
};

export { ExportUsageButtonWithState as ExportUsageButton };
export default ExportUsageButtonWithState;
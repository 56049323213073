import { memo } from 'react';
import { ProjectIconProps } from './interfaces';

export const MixedProjectIcon = memo(({ className, size }: ProjectIconProps) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 25">
      <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g id="Group-11" transform="translate(8.000000, 3.000000)" strokeWidth="2">
          <g id="Group-9" transform="translate(5.000000, 17.259759) rotate(-180.000000) translate(-5.000000, -17.259759) translate(0.000000, 15.259759)">
            <g id="Group-2" transform="translate(5.000000, 2.000000) rotate(-180.000000) translate(-5.000000, -2.000000) translate(0.000000, -0.000000)">
              <line x1="5" y1="0" x2="5" y2="4" id="Path-4" stroke="#0A68EF" />
              <line x1="0.555555556" y1="0" x2="0.555555556" y2="2" id="Path-4" stroke="#E92D21" />
              <line x1="9.44444444" y1="0" x2="9.44444444" y2="2" id="Path-4" stroke="#1DB955" />
            </g>
          </g>
          <path d="M0,8.89433836 C0.0486992154,8.30296974 0.388500082,7.7812415 0.896105789,7.51846282 L2.35657251,6.76240452 C2.64448985,6.6133547 2.85956609,6.34449507 2.94944326,6.02127657 C3.03999867,5.69561895 3.00785527,5.3459371 2.85964773,5.04440828 L2.79125331,4.90525958 C2.59461441,4.50519699 2.49201963,4.0616489 2.49201963,3.61158241 L2.49201963,2.67853602 C2.49201963,1.81487365 2.93330949,1.01740474 3.64841244,0.588781864 L3.69825886,0.558904603 C4.38113312,0.149599166 5.22205143,0.161038508 5.89456835,0.588781864 C6.57114829,1.01910944 6.98433144,1.78861324 6.98433144,2.61833422 L6.98433144,3.62458833 C6.98433144,4.06712337 6.89179546,4.50427704 6.71324101,4.90525958 L6.65831554,5.02860656 C6.51960213,5.34011745 6.5010921,5.69578952 6.60665459,6.02127657 C6.71100257,6.34301886 6.94497132,6.60039607 7.24647072,6.72510599 L9.0024065,7.45141792 C9.69379119,7.73739703 10.1005781,8.49105208 9.97846218,9.25975875" id="Path" stroke="#838383" strokeLinejoin="round" />
          <line x1="0" y1="11.7597588" x2="10" y2="11.7597588" id="Path-5" stroke="#838383" />
        </g>
      </g>
    </svg>
  );
});

export default MixedProjectIcon;
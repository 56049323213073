import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useProjectOrLatestChild, useProjectAnonymity } from '@containers/GroupProject/hooks';
import { useSelectProject } from '@containers/Store';
import * as enums from '@enums';
import { getLocationFor } from '@utils';
import { Contact, PipelineProjectAttributes, RespondentProps } from '@/types';
import { useTrackViewResponse } from './useTrackViewResponse';
import { useProjectSurveyResponsesState } from './useProjectSurveyResponsesState';

const mapState = (state: Store.State) => ({
  contacts: state.contacts,
  pipeline: state.pipeline,
});

const blacklistedSeniorities = [
  undefined,
  enums.Seniority.Other,
  enums.Seniority.NonManager,
];

function formatRespondentName(info: Pick<Contact, 'career'>, order: number) {
  return info.career.current.title
    ? info.career.current.title
    : info.career.former.title
      ? `Former ${info.career.former.title}`
      : !blacklistedSeniorities.includes(info.career.current.seniority)
        ? enums.utils.Seniority.getName(info.career.current.seniority)
        : `Respondent #${order}`;
}

function formatAnonymousRespondentName(info: Pick<Contact, 'career'>, order: number) {
  return !blacklistedSeniorities.includes(info.career.current.seniority)
    ? enums.utils.Seniority.getName(info.career.current.seniority)
    : !blacklistedSeniorities.includes(info.career.former.seniority)
      ? enums.utils.Seniority.getName(info.career.former.seniority)
      : `Respondent #${order}`;
}

const useResponsesProject = () => {
  const project  = useProjectOrLatestChild();
  const { query } = useProjectSurveyResponsesState();
  const queryProject = useSelectProject(query.projectId);

  const data = useMemo(() => {
    return query.projectId
      ? queryProject
      : project;
  }, [
    project,
    query.projectId,
    queryProject,
  ]);

  return data;
};

const useSelectRespondentProps = () => {
  const state = useSelector(mapState);
  const project = useResponsesProject();
  const handleViewResponse = useTrackViewResponse();
  // const contacts = useResponseContacts();
  const pipeline: PipelineProjectAttributes = state.pipeline.project[project.id];
  const anonymity = useProjectAnonymity();

  const getSurveyResponseLocation = useCallback((projectSurveyResponseId: number) => {
    return getLocationFor.project.surveyResponse({
      slug: project.slug,
      projectSurveyResponseId,
    });
  }, [project.slug]);

  const getProfileLocation = useCallback((userId: number) => {
    if (anonymity) {
      return null;
    }

    const contact = state.contacts[userId];

    return getLocationFor.user.profile({
      slug: contact?.profile?.slug,
    });
  }, [
    anonymity,
    state.contacts,
  ]);

  const getName = useCallback((userId: number) => {
    const respondent = pipeline.respondents[userId];
    const contact = state.contacts[userId];

    if (!anonymity) {
      return formatRespondentName(contact, respondent?.order);
    } else {
      return formatAnonymousRespondentName(contact, respondent?.order);
    }
  }, [
    anonymity,
    state.contacts,
    pipeline.respondents,
  ]);

  const selectRespondentProps = useCallback((userId: number) => {
    const respondent = pipeline.respondents[userId];
    const responseId = respondent?.projectSurveyResponse?.id;
    const contact = state.contacts[userId];
    const fullname = getName(userId);

    return {
      id: userId,
      onViewResponse: handleViewResponse({ id: userId, name: fullname }),
      paths: {
        profile: getProfileLocation(userId),
        response: getSurveyResponseLocation(responseId),
      },
      profile: { fullname, creditRate: contact.profile.creditRate },
      responseId,
      anonymity,
      respondentName: `Respondent #${respondent.order}`,
      projectId: project.id,
    } as RespondentProps<string>;

  }, [
    getName,
    getProfileLocation,
    getSurveyResponseLocation,
    handleViewResponse,
    pipeline?.respondents,
    anonymity,
    state.contacts,
    project,
  ]);

  return selectRespondentProps;
};

export { useSelectRespondentProps };
export default useSelectRespondentProps;
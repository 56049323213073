import * as types from '@store/action-types';
import initialState from '@store/initial-state';
import * as xform from '@transformers';

function contacts(state = initialState.contacts, action) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return { ...state, ...action.contacts };

    case types.CONTACTS_ADDED:
      return action.contacts.reduce((acc, user) => {
        return {
          ...state,
          [user.id]: xform.addSlugToProfileProp(user),
          ids: acc.ids.includes(user.id) ? acc.ids : acc.ids.concat(user.id),
        };
      }, { ...state });

    default:
      return state;
  }
}

export default contacts;
import { SurveyQuestionType } from '@enums';
import { FormQuestionText } from './QuestionText';
import { cx } from '@utils';
import QuestionItem from './QuestionItem';
import { QuestionItemProps } from './interfaces';
import styles from './style/SurveyForm.css';

export const SurveyFormItem = (props: QuestionItemProps) => {

  const itemClassName = cx({
    [styles.offsetLeft]: props.item.typeId !== SurveyQuestionType.Ranking,
  });

  return (
    <div>
      <FormQuestionText
        textClassName={styles.questionText}
        item={props.item} />
      <div className={itemClassName}>
        <QuestionItem item={props.item} {...props} />
      </div>
    </div>
  );
};

export default SurveyFormItem;
import { createContext } from 'react';
import { RepeatSurveyState } from '@containers/RepeatSurveyResponses/interfaces';
import { SurveyQuestionType } from '@enums';
import { ConjointAnalysisQuestion, MaxDifferenceQuestion, ProjectSurveyStarredResponses, SurveyAggregate, SurveyQuestion, SurveyFiltering } from '@/types';
import { createNamedContext } from '@utils';
import { StarringParams, SurveyResponses, SurveyResponsesState } from './interfaces';

export type ProjectSurveyStarredAnswersState = {
  initial: ProjectSurveyStarredResponses;
  onToggle: (data: StarringParams) => () => Promise<void>;
  starred: ProjectSurveyStarredResponses;
};

export type SurveyQuestionResponsesContextValue = {
  question: SurveyQuestion;
  responses: SurveyAggregate.QuestionData;
  total: number;
};

type ColorsMap = {
  [id: number]: string;
};

export type ConjointAnalysisResponsesContextValue = {
  levelColors: ColorsMap;
  onAttributeClick: (attributeId: number) => () => unknown;
  onLevelClick: (levelId: number) => () => unknown;
  onReset: () => unknown;
  query: SurveyResponses.QueryState.TypeValue<SurveyQuestionType.ConjointAnalysis>;
  question: ConjointAnalysisQuestion.Question;
  responses: ConjointAnalysisQuestion.Aggregate.Data;
  sortedValues: ConjointAnalysisQuestion.Attribute[];
};

export type MaxDiffResponsesContextValue = {
  onOptionClick: (optionId: number) => (choice: MaxDifferenceQuestion.Choices) => unknown;
  onReset: () => unknown;
  query: SurveyResponses.QueryState.TypeValue<SurveyQuestionType.MaxDifference>;
  question: MaxDifferenceQuestion.Question;
  responses: MaxDifferenceQuestion.Aggregate.Data;
  sortedOptions: MaxDifferenceQuestion.Question['options'];
};

type State =
  SurveyResponsesState |
  RepeatSurveyState;

export const SurveyQuestionResponsesContext = createNamedContext<SurveyQuestionResponsesContextValue>(undefined, 'SurveyQuestionResponsesContext');
export const SurveyResponsesContactsContext = createContext<SurveyAggregate.Respondents>(undefined);
export const ProjectSurveyResponsesLoadingContext = createContext<boolean>(undefined);
export const ProjectSurveyResponsesStateContext = createContext<State>(undefined);
export const ProjectSurveyResponsesContext = createContext<SurveyAggregate.QuestionMap>(undefined);
export const ProjectSurveyStarredAnswersStateContext = createContext<ProjectSurveyStarredAnswersState>(undefined);

export const ConjointAnalysisResponsesContext = createContext<ConjointAnalysisResponsesContextValue>(undefined);
export const MaxDiffResponsesContext = createContext<MaxDiffResponsesContextValue>(undefined);
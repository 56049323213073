import { useMemo } from 'react';
import { CallStatus } from '@enums';
import { useSelectAdHocCall, useSelectUser } from '@containers/Store';
import { getLocationFor } from '@utils';
import { getOtherAdHocCallParticipant } from '../utils.calls';

export const useRescheduleAdHocCall = (callId: number) => {
  const call = useSelectAdHocCall(callId);
  const me = useSelectUser();

  const canReschedule = useMemo(() => {

    return call.statusId === CallStatus.Scheduled;
  }, [
    call.statusId,
  ]);

  const location = getLocationFor.scheduling.adHoc.proposing({
    callId,
    schedulerId: me.id,
    scheduleeId: getOtherAdHocCallParticipant(call, me.id),
  });

  return [canReschedule, location] as const;
};

export default useRescheduleAdHocCall;
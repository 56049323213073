import { createElement } from 'react';
import { cx } from '@utils';
import styles from './style.css';

type Props<T = number> = {
  className?: string;
  Icon: React.ComponentType<unknown>;
  size: T;
};

const ToastIcon = (props: Props) => {
  const icon = createElement(props.Icon, { size: props.size } as unknown);

  return (
    <div className={cx(styles.icon, props.className)}>
      {icon}
    </div>
  );
};

const defaultProps = {
  size: 20,
};

ToastIcon.defaultProps = defaultProps;
ToastIcon.displayName = 'Toast.Base.Icon';

export { ToastIcon as Icon };
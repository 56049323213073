import { useState, useCallback } from 'react';
import { ThumbsUpRating, ThumbsUpRatingPrompt } from '@presentation';
import * as api from '@api';
import { useProjectSurveyRespondent, useProjectState } from '@containers/GroupProject/hooks';
import { ProjectSurveyResponseRating } from 'types/project-surveys';

type Props = {
  rating: ProjectSurveyResponseRating;
};

export const RatingSelector = (props: Props) => {

  const {
    projectSurveyResponseId,
  } = useProjectSurveyRespondent();
  const { project } = useProjectState();
  const [rating, setRating] = useState(props.rating);

  const saveRating = useCallback((val:boolean) => {
    return api.projects.surveys.saveProjectSurveyResponseRating({
      value: val,
      projectId: project.id,
      projectSurveyResponseId,
      skipped: false,
    }).then(() => setRating({ ...rating, value: val}));
  }, [
    project.id,
    projectSurveyResponseId,
    rating,
  ]);

  if (!rating || rating.skipped) {
    return (
      <ThumbsUpRatingPrompt value={rating?.value} onUpdate={saveRating} />
    );
  } else {
    return (
      <ThumbsUpRating value={rating.value} />
    );
  }
};
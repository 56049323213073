import styles from '$website/screens/Main/style/WhatPeopleAreSaying.css';
import anthony from 'static/images/website/experts/anthony.jpg';
import cecile from 'static/images/website/experts/cecile.jpg';
import jason from 'static/images/website/experts/jason.jpg';
import kasra from 'static/images/website/experts/kasra.jpg';
import lance from 'static/images/website/experts/lance.jpg';
import lisa from 'static/images/website/experts/lisa.jpg';

export const enterpriseItems = [
  {
    className: styles.cardOne,
    name: 'Elizabeth',
    quote: 'I’ve been using the platform a lot more than I even thought that I would when we signed up, the people on the database are high quality and scheduling is so much easier.',
    company: 'Focused, Private Equity Fund',
    title: 'Partner',
  },
  {
    className: styles.cardTwo,
    name: 'Louise',
    quote: 'We’ve tried all your peers, this is next level. It’s like Google for people.',
    company: 'Late Stage Venture Capital Firm',
    title: 'Portfolio Manager',
  },
  {
    className: styles.cardThree,
    name: 'Joseph',
    quote: 'The app is incredibly user friendly and I have found myself starting and managing several research projects at once even while traveling, or from my couch on the weekends.',
    company: 'Global Macro Fund',
    title: 'Senior Analyst',
  },
  {
    className: styles.cardFour,
    name: 'Ivan',
    quote: 'I’m loving the experience so far and the call with [your expert] was very good 🚀🚀🚀',
    company: 'Brazilian Family Office',
    title: 'Investment Associate',
  },
  {
    className: styles.cardFive,
    name: 'Jim',
    quote: 'I can use it on the train and pop in projects for my team to start working on.',
    company: 'Mid-Sized Consulting Firm',
    title: 'Manager',
  },
  {
    className: styles.cardSix,
    name: 'David',
    quote: 'It’s what everyone wants. It’s basically like browsing on Linkedin but I can talk to all these people whenever I want.',
    company: 'Long-Short Equity Fund',
    title: 'Portfolio Manager',
  },
];

export const individualsItems = [
  {
    className: styles.cardOne,
    name: 'Jason Lerman',
    quote: 'I think I got out of it as much as the person I was consulting with and I think that’s something very unique about this type of consulting.',
    company: 'UNTUCKit',
    title: 'Director of Operations',
    img: jason,
  },
  {
    className: styles.cardTwo,
    name: 'Cecile Peters',
    quote: 'It’s an interactive discussion and I really liked that. The client was very knowledgeable on the topic we discussed. I got to ask them questions and learned some new things about my industry.',
    company: 'Moët Hennessy',
    title: 'Senior Director of e-Commerce',
    img: cecile,
  },
  {
    className: styles.cardThree,
    name: 'Anthony Giardina',
    quote: 'Having done a couple of these in the past, I thought Vancery was the smoothest, easiest process I’ve experienced.',
    company: 'Q Mixers',
    title: 'VP of Commercial Operations',
    img: anthony,
  },
  {
    className: styles.cardSix,
    name: 'Kasra Amirdelfan',
    quote: 'The scheduling process was seamless, smooth and easy to navigate on my mobile device.',
    company: 'Integrated Pain Management, Inc.',
    title: 'Medical Director',
    img: kasra,
  },
  {
    className: styles.cardFive,
    name: 'Lance Robinson',
    quote: `I've worked with platforms like this before, Vancery is hands down a more modern and easy to use platform to sign-up and work through.`,
    company: 'MobileIron',
    title: 'Enterprise Sales Manager',
    img: lance,
  },
  {
    className: styles.cardFour,
    name: 'Lisa Hu',
    quote: 'I found the platform very user-friendly and the service was top notch. Scheduling time then canceling and rescheduling was painless and [the Vancery team] were always quick in responding! I highly recommend this service to anyone who is open to sharing their expertise and earning some fast money along the way.',
    company: 'The Kraft Heinz Company',
    title: 'Senior Manager',
    img: lisa,
  },
];
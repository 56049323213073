import { combineReducers } from 'redux';
import * as types from '@store/action-types';
import { chat as initialState } from '@store/initial-state';
import { ApplicationActions, Chat } from '@store/interfaces';
import { IChat } from '@/types';

type ConversationAction =
  | Chat.ConversationAdded.Action
  | Chat.ConversationsLoaded.Action
  | Chat.ConversationRemoved.Action
  | Chat.ConversationUpdated.Action;

function conversationsReducer(state = initialState.conversations, action: ConversationAction): Store.Chat.Conversations {
  switch (action.type) {
    case types.CHAT_CONVERSATION_ADDED: {
      const update = {
        [action.conversationSid]: action.conversation,
        ids: state.ids.concat(action.conversationSid),
      } as Store.Chat.Conversations;

      return {
        ...state,
        ...update,
      };
    }
    case types.CHAT_CONVERSATIONS_LOADED: {
      return {
        ...state,
        ...action.conversations,
      };
    }
    case types.CHAT_CONVERSATION_REMOVED: {
      const { [action.conversationSid]: _, ...conversations } = state;

      return {
        ...conversations,
        ids: state.ids.filter(id => id !== action.conversationSid),
      } as Store.Chat.Conversations;
    }
    case types.CHAT_CONVERSATION_UPDATED: {
      return {
        ...state,
        [action.conversationSid]: action.conversation,
      };
    }
    default: return state;
  }
}

type ConnectionAction =
  | ApplicationActions
  | Chat.ConnectionAdded.Action;

function connectionsReducer(state = initialState.connections, action: ConnectionAction): Store.Chat.Connections {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.chat.connections;
    case types.CHAT_CONNECTION_ADDED: {
      return {
        ...state,
        [action.userId]: {
          userId: action.userId,
          createdOn: action.createdOn,
        },
        ids: state.ids.concat(action.userId),
      };
    }
    default: return state;
  }
}

type MessageAction =
  Chat.ConversationsLoaded.Action
| Chat.ConversationJoined.Action
| Chat.ConversationRemoved.Action
| Chat.MessageAdded.Action
| Chat.MessageSent.Action;

function messagesReducer(state = initialState.messages, action: MessageAction): Store.Chat.Messages {
  switch (action.type) {
    case types.CHAT_CONVERSATIONS_LOADED:
      return {
        ...state,
        ...action.messages,
      };

    case types.CHAT_CONVERSATION_JOINED:
      return {
        ...state,
        [action.conversationSid]: action.messages,
      };

    case types.CHAT_CONVERSATION_REMOVED: {
      const { [action.conversationSid]: _, ...other } = state;
      return other;
    }
    case types.CHAT_MESSAGE_ADDED:
      return {
        ...state,
        [action.conversationSid]: state[action.conversationSid].concat(action.message),
      };

    case types.CHAT_MESSAGE_SENT: {
      const { [action.conversationSid]: messages, ...other } = state;

      const updated = messages.reduce<IChat.Message[]>((acc, item) => {
        if (action.message.type === 'twilio' && item.type === 'pending') {
          if (action.message.data.attributes.tempId === item.data.attributes.tempId) {
            return acc.concat({ ...item, ...action.message });
          }
        }

        return acc.concat(item);
      }, []);

      return {
        ...other,
        [action.conversationSid]: updated,
      };
    }
    default: return state;
  }
}

type ParticipantAction =
  | Chat.ConversationsLoaded.Action
  | Chat.ConversationAdded.Action
  | Chat.ConversationRemoved.Action
  | Chat.ParticipantAdded.Action;

function participantsReducer(state = initialState.participants, action: ParticipantAction): Store.Chat.Participants {
  switch (action.type) {
    case types.CHAT_CONVERSATIONS_LOADED:
      return {
        ...state,
        ...action.participants,
      };
    case types.CHAT_CONVERSATION_ADDED:
      return action.participants.reduce((acc, participant) => {
        acc[participant.userId] = {
          conversationSid: action.conversationSid,
          ...participant,
        };

        return acc;
      }, { ...state });
    case types.CHAT_CONVERSATION_REMOVED:
      return action.userIds.reduce((acc, userId) => {
        const { [userId]: _, ...other } = acc;
        return other;
      }, { ...state });
    case types.CHAT_PARTICIPANT_ADDED: {
      const { [action.participant.userId]: _, ...other } = state;
      return {
        ...other,
        [action.participant.userId]: action.participant,
      };
    }
    default: return state;
  }
}

type PlatformMessageAction =
  | ApplicationActions
  | Chat.PlatformMessageAdded.Action
  | Chat.PlatformMessageUpdated.Action
  | Chat.PlatformMessageRemoved.Action;

function platformMessageReducer(state = initialState.platformMessages, action: PlatformMessageAction): Store.Chat.PlatformMessages {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.chat.platformMessages;
    case types.CHAT_PLATFORM_MESSAGE_ADDED: {
      const { [action.conversationSid]: messages, ...other } = state;
      return {
        ...other,
        [action.conversationSid]: (messages ?? []).concat(action.message),
      };
    }
    case types.CHAT_PLATFORM_MESSAGE_UPDATED: {
      const { [action.conversationSid]: messages, ...other } = state;

      const updated = messages.map(m => {
        if (m.id !== action.message.id) return m;
        return {
          ...m,
          ...action.message,
        };
      });

      return {
        ...other,
        [action.conversationSid]: updated,
      };
    }
    case types.CHAT_PLATFORM_MESSAGE_REMOVED: {
      const { [action.conversationSid]: messages, ...other } = state;

      return {
        ...other,
        [action.conversationSid]: messages.filter(m => m.id !== action.messageId),
      };
    }
    default: return state;
  }
}

type InitializedAction =
  | Chat.ConversationsLoaded.Action;

function initialized(state = initialState.initialized, action: InitializedAction) {
  if (action.type === types.CHAT_CONVERSATIONS_LOADED) {
    return true;
  }

  return state;
}

type ConnectionStateAction =
  | Chat.ConnectionChanged.Action;

function connectionState(state = initialState.connectionState, action: ConnectionStateAction): Store.Chat.ConnectionState  {
  if (action.type === types.CHAT_CONNECTION_STATE_CHANGE) {
    return action.connection;
  }

  return state;
}

export default combineReducers({
  conversations: conversationsReducer,
  connections: connectionsReducer,
  connectionState,
  initialized,
  messages: messagesReducer,
  participants: participantsReducer,
  platformMessages: platformMessageReducer,
});
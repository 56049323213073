import { useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { QuestionTaggingPreview } from '@presentation/SurveyTaggingPreview';
import { Editable } from 'components/Editable';
import { EditableQuestionTaggingProps } from './interfaces';

type Props
  = EditableQuestionTaggingProps;

export default function EditableQuestionTagging({ item, onClick }: Props) {
  const canEdit
    = useMemo(() => [
      SurveyQuestionType.MultipleChoice,
      SurveyQuestionType.Multiselect,
    ].includes(item.typeId), [item.typeId]);

  return (
    <Editable
      canEdit={canEdit}
      onClick={onClick}>
      <QuestionTaggingPreview item={item} />
    </Editable>
  );
}

export { EditableQuestionTagging };
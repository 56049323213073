import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Card.css';

type Props = {
  onClick?: () => void;
};

export const CompetitiveIntelligenceCard = memo(({ onClick }: Props) => {

  const title = `Competitive Intelligence`;

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <div className={cx(styles.poster, styles.lightRed)}>
        <Icon />
      </div>
      <div className={styles.footer}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
});

type IconProps = {
  color?: string;
};

const Icon = memo(({
  color = '#F25653',
}: IconProps) => (
  <svg
    width="38px"
    height="38px"
    viewBox="0 0 32 31"
    version="1.1">
    <g
      id="Survey-Templates---Competitive-Intel"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="1"
        transform="translate(-515.000000, -217.000000)"
        stroke={color}
        strokeWidth="2">
        <g
          id="Group"
          transform="translate(516.254732, 217.397185)">
          <line
            x1="-1"
            y1="4.5"
            x2="9"
            y2="4.5"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(4.000000, 4.500000) rotate(46.000000) translate(-4.000000, -4.500000) " />
          <polyline
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(7.000000, 7.500000) rotate(46.000000) translate(-7.000000, -7.500000) "
            points="5 4 9 7.5 5 11" />
          <line
            x1="20.4905356"
            y1="4.5"
            x2="30.4905356"
            y2="4.5"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(25.490536, 4.500000) scale(-1, 1) rotate(46.000000) translate(-25.490536, -4.500000) " />
          <polyline
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(22.490536, 7.500000) scale(-1, 1) rotate(46.000000) translate(-22.490536, -7.500000) "
            points="20.4905356 4 24.4905356 7.5 20.4905356 11" />
          <line
            x1="20.4905356"
            y1="25.7056308"
            x2="30.4905356"
            y2="25.7056308"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(25.490536, 25.705631) scale(-1, -1) rotate(46.000000) translate(-25.490536, -25.705631) " />
          <polyline
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(22.490536, 22.705631) scale(-1, -1) rotate(46.000000) translate(-22.490536, -22.705631) "
            points="20.4905356 19.2056308 24.4905356 22.7056308 20.4905356 26.2056308" />
          <line
            x1="-1"
            y1="25.7056308"
            x2="9"
            y2="25.7056308"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(4.000000, 25.705631) scale(1, -1) rotate(46.000000) translate(-4.000000, -25.705631) " />
          <polyline
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(7.000000, 22.705631) scale(1, -1) rotate(46.000000) translate(-7.000000, -22.705631) "
            points="5 19.2056308 9 22.7056308 5 26.2056308" />
          <circle
            id="Oval"
            cx="14.7452678"
            cy="15.6028154"
            r="3" />
        </g>
      </g>
    </g>
  </svg>
));
import { Fragment, useContext } from 'react';
import { BrandThemingContext } from '@containers/Branding/Context';
import { useSetColorCategoryMutation } from '@containers/Branding/hooks';
import { Select } from 'components/ColorPalette';
import styles from './style/Settings.Branding.Theming.css';

type Props = unknown;

export const Theming = (props: Props) => {
  const { data, query } = useContext(BrandThemingContext);

  const mutation = useSetColorCategoryMutation();

  if (query.isLoading) return null;

  return <>
    {data.categories.map(x =>
      <Fragment key={x.category.id}>
        <h2 className={styles.label}>{x.category.name}</h2>
        <div className={styles.field}>
          <Select.Menu
            category={x.category}
            items={x.items}
            key={x.category.id}
            onChange={mutation.mutate}
            value={x.paletteId} />
        </div>
      </Fragment>)}
  </>;
};

Theming.displayName = 'Settings.Branding.Theming';
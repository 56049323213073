import { NotificationEvent } from '@enums';
import { safeJsonDate } from '@utils';
import { Notification } from '@/types';

export function normalizeNotification(data: Notification<NotificationEvent, string>): Notification<NotificationEvent, Date> {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
    seenOn: safeJsonDate(data.seenOn),
  };
}
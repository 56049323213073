import { useCallback } from 'react';
import { useRenameFile } from './hooks';
import { useSelectObject, useSelectFile } from '@containers/Store';
import { RenameFileContext } from './Context';

type Props =
  IWorkspaceFileId &
  ChildrenProps;

export const RenameFileContainer = (props: Props) => {

  const file = useSelectFile(props.fileId);
  const object = useSelectObject(file.objectId);
  const renameFile = useRenameFile();

  const handleRename = useCallback((name: string) => {
    return renameFile({
      fileId: props.fileId,
      name,
      workspaceId: object.workspaceId,
    });
  }, [
    renameFile,
    props.fileId,
    object.workspaceId,
  ]);

  const value = {
    file,
    rename: handleRename,
  };

  return (
    <RenameFileContext.Provider value={value}>
      {props.children}
    </RenameFileContext.Provider>
  );
};

export default RenameFileContainer;
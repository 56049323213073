import { Link } from 'react-router-dom';
import { cx } from '@utils';
import { AuthContainer } from '$website/containers';
import { SocialAuthContainer } from '$website/containers/Auth/SocialAuthContainer';
import logo from 'static/images/vancery-logo.svg?url';
import { AuthFrame } from './AuthFrame';
import * as ValueProposition from './ValueProposition';
import styles from './style/SignUp.css';

type Props = unknown;

export const GeneralInvite = (props: Props) => {
  return (
    <div className={styles.root}>
      <Link to="/">
        <img
          className={styles.logo}
          src={logo} />
      </Link>

      <div className={cx(styles.wrap, styles.general)}>
        <div className={styles.signup}>
          <div>
            <AuthContainer>
              <SocialAuthContainer>
                <AuthFrame />
              </SocialAuthContainer>
            </AuthContainer>
          </div>
          <ValueProposition.Visitor />
        </div>
      </div>
    </div>
  );
};

GeneralInvite.displayName = 'Signup.GeneralInvite';
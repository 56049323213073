import qs from 'query-string';
import http from '@services/http';
import * as xform from '@transformers';
import * as xformAdmin from './transformers';
import { Projects } from './interfaces';

export * as calls from './projects.calls';
export * as details from './projects.details';
export * as leads from './projects.leads';
export * as members from './projects.members';
export * as surveys from './projects.surveys';

export const autocomplete = (data: Projects.Autocomplete.Request): Promise<Projects.Autocomplete.Response> => {
  return http.get(`/admin/projects/autocomplete?${qs.stringify(data, { arrayFormat: 'bracket' })}`);
};

export const createProject = (data: Projects.CreateProject.Request) => {
  return http.post(`/admin/projects`, data)
    .then((data: Projects.CreateProject.Response<string>) => xformAdmin.projects.transformProjectDetailsLegacy(data));
};

export const cloneProject = ({ projectParentId, ...body }: Projects.CloneProject.Request) => {
  return http.post(`/admin/projects/parents/${projectParentId}/clone`, body)
    .then((data: Projects.CloneProject.Response<string>) => xformAdmin.projects.transformProjectDetailsLegacy(data));
};

export const fetchAccountManagers = (): Promise<Projects.FetchAccountManagers.Response> => {
  return http.get(`/admin/projects/managers`);
};

export const fetchProjectInfo = (data: Projects.FetchProjectInfo.Request): Promise<Projects.FetchProjectInfo.Response> => {
  return http.get(`/admin/projects/${data.projectId}/info`);
};

export const fetchProjectDetailsLegacy = (data: Projects.FetchProjectDetailsLegacy.Request) => {
  return http.get(`/admin/projects/${data.projectId}/details-legacy`)
    .then((data: Projects.FetchProjectDetailsLegacy.Response<string>) => xformAdmin.projects.transformProjectDetailsLegacy(data));
};

export const fetchProjects = (data: Projects.FetchProjects.Request): Promise<Projects.FetchProjects.Response> => {
  return http.get(`/admin/projects?${qs.stringify(data, { arrayFormat: 'bracket' })}`);
};

export const updateProjectLegacy = (data: Projects.UpdateProjectLegacy.Request) => {
  return http.put(`/admin/projects/${data.id}`, data)
    .then((data: Projects.UpdateProjectLegacy.Response<string>) => xformAdmin.projects.transformProjectDetailsLegacy(data));
};

export const updateProject = (data: Projects.UpdateProject.Request) => {
  return http.patch(`/admin/projects/${data.projectId}`, data)
  .then((data: Projects.UpdateProject.Response<string>) => xformAdmin.projects.transformProjectDetails(data));
};

const getCustomSearchProjectInviteToken = (data: Projects.GetCustomSearchProjectInviteToken.Request): Promise<Projects.GetCustomSearchProjectInviteToken.Response> => {
  return http.get(`/admin/projects/${data.projectId}/link/${data.userId}`)
    .then(xform.assignProjectLinkUrl);
};

export const updateSourcing = (data: Projects.UpdateSourcing.Request): Promise<Projects.UpdateSourcing.Response> => {
  return http.put(`admin/projects/${data.projectId}/attributes/sourcing`, data);
};

export const exportProjectParent = (data: Projects.ExportProjectParent.Request) => {
  return http.download(`/admin/projects/parents/${data.projectParentId}/export?${qs.stringify({ format: data.format })}`);
};

const importExternalProjectLinks = async (projectId: number, linksFile: File): Promise<{ numInserted: number; }> => {
  const formData = new FormData();
  formData.append('linksFile', linksFile, linksFile.name);

  return http.patch(`/admin/projects/${projectId}/import-external-links`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const external = {
  importExternalProjectLinks,
};

export const links = {
  getCustomSearch: getCustomSearchProjectInviteToken,
};
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import * as selectors from '@store/selectors';
import { formatTruncatedName } from '@utils';
import { FilesTableItem } from 'components/WorkspaceFiles/interfaces';

type Filters = {
  parentObjectId?: number;
};

const selectFilesTableItems = (filters: Filters) => createSelector(
  [
    (state: Store.State) => state.user.id,
    (state: Store.State) => state.contacts,
    selectors.selectFileIds,
    selectors.selectFileValues,
    selectors.selectObjectValues,
  ],
  (meId, contacts, fileIds, files, objects) => {
    return fileIds
      .reduce((acc, fileId) => {
        const file = files[fileId];
        const object = objects[file.objectId];

        if (filters.parentObjectId && object.parentObjectId !== filters.parentObjectId) {
          return acc;
        }

        const item = {
          ...file,
          owner: object.ownerId === meId
            ? 'Me'
            : formatTruncatedName(contacts[object.ownerId]),
        };

        return [...acc, item];
      }, [] as FilesTableItem[]);
  },
);

export const useFilesTableItems = (filters: Filters) => {
  const items = useSelector(selectFilesTableItems(filters));

  return items;
};

export default useFilesTableItems;
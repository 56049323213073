import { useCallback, useContext } from 'react';
import { Editor, Transforms } from 'slate';
import { RichTextAutoCompleteContext, RichTextMentionsContext } from 'components/RichTextMentions/Context';
import { TagNode } from 'components/RichTextMentions/interfaces';

export const useMentionsHelpers = (editor: Editor) => {
  const {
    index,
    search,
    setIndex,
    setTarget,
    target,
  } = useContext(RichTextMentionsContext);
  const { getOptionTagProps, options } = useContext(RichTextAutoCompleteContext);

  const createTagNode = useCallback((option: typeof options[number]) => {
    Transforms.select(editor, target);
    insertMention(editor, getOptionTagProps(option));
    setTarget(null);
  }, [
    editor,
    getOptionTagProps,
    setTarget,
    target,
  ]);

  const handleMouseOver = useCallback((i: number) => (e: React.MouseEvent<HTMLDivElement>) => {
    setIndex(i);
  }, [
    setIndex,
  ]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!target) return;

    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        const prev = index >= options.length - 1
            ? 0
            : index + 1;
        setIndex(prev);
        break;
      }

      case 'ArrowUp': {
        e.preventDefault();
        const next = index <= 0
            ? options.length - 1
            : index - 1;
        setIndex(next);
        break;
      }

      case 'Tab':
      case 'Enter':
        e.preventDefault();
        createTagNode(options[index]);
        break;

      case 'Escape':
        e.preventDefault();
        setTarget(null);
        break;
    }

  }, [
    createTagNode,
    index,
    options,
    setIndex,
    setTarget,
    target,
  ]);

  return [{
    handleKeyDown,
    handleMouseOver,
    handleSelectOption: createTagNode,
  }, {
    index,
    search,
    target,
  }] as const;
};

const insertMention = (editor, params: TagNode) => {
  const mention = {
    children: [{ text: params.label }],
    id: params.id,
    label: params.label,
    type: 'mention',
    ...params.to ? { to: params.to } : {},
  };

  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};
import { Fragment, useCallback, useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { getSegmentColor } from '@containers/SurveyResponses/utils/matrix';
import { useProjectSurveyResponsesState } from '@containers/SurveyResponses/hooks/useProjectSurveyResponsesState';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import { MatrixMultiselectQuestion } from '@/types';
import { useMatrixColors } from './hooks/usePaletteColors';
import { MatrixOptionItem, MatrixRowItem } from './interfaces';
import styles from './style/Options.css';
import Chart from './MatrixMultiselect.Chart';
import { MatrixAggregateData } from './Matrix.Data.Aggregate';
import { MatrixRowOptionData } from './Matrix.Data.Option';
import { SegmentedDataTooltip } from './SegmentedDataTooltip';

type Props = {
  question: MatrixMultiselectQuestion.Question;
  responses: MatrixMultiselectQuestion.Aggregate.Data;
};

export const MatrixMultiselectResponses = (props: Props) => {
  const {
    query,
    resetQueryState,
    setQueryState,
  } = useProjectSurveyResponsesState<SurveyQuestionType.MatrixMultiselect>();

  const handleSegmentClick = useCallback((rowId: number, key: string) => {
    setQueryState({
      key,
      rowId,
    });
  }, [setQueryState]);

  const colors = useMatrixColors();

  const items: MatrixRowItem[] = useMemo(() => {

    return [...props.question.matrixRows]
      .map(row => {
        const rowData = props.responses[row.base.id];

        const options = props.question.options.reduce((acc, option) => {
          const optionData = rowData.options[option.base.id];

          return [
            ...acc,
            {
              color: getSegmentColor({
                colors,
                option,
                options: props.question.options,
              }),
              id: option.base.id,
              name: option.value,
              userIds: optionData.userIds,
            } as MatrixOptionItem,
          ];
        }, [] as MatrixOptionItem[]);

        return {
          count: rowData.count,
          id: row.base.id,
          name: row.value,
          options,
        } as MatrixRowItem;
      });
  }, [
    colors,
    props.question,
    props.responses,
  ]);

  const renderData = useCallback(() => {
    if (query.rowId && query.key) {
      const row = props.question.matrixRows.find(f => f.base.id === query.rowId);
      const item = items.find(f => f.id === query.rowId).options.find(f => f.id.toString() === query.key);

      return (
        <MatrixRowOptionData
          item={item}
          onReset={resetQueryState}
          row={{
            value: row.value,
          }} />
      );
    } else {
      return (
        <MatrixAggregateData
          items={items}
          onClick={handleSegmentClick} />
      );
    }
  }, [
    items,
    handleSegmentClick,
    props.question.matrixRows,
    resetQueryState,
    query,
  ]);

  return (
    <Fragment>
      <SurveyResponsesHeader
        question={props.question}>
        <SegmentedDataTooltip questionIdentifier={props.question.base.identifier} />
      </SurveyResponsesHeader>
      <Chart
        question={props.question}
        responses={items}
        onSegmentClick={handleSegmentClick} />
      <div className={styles.options}>
        {renderData()}
      </div>
    </Fragment>
  );
};

export default MatrixMultiselectResponses;
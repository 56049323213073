import { useContext, useMemo } from 'react';
import { TopicItemContext, TopicMembersContext, TopicPostsContext } from '@containers/Topic/Context';
import * as consts from '@consts';
import ActivityIndicator from 'components/ActivityIndicator';
import { Button } from 'components/Button';
import {
  Experts as ExpertsPanel,
  Overview as OverviewPanel,
  PostsCarousel as PostsPanel,
} from './Panel';
import { Card, Header } from './Layout';
import styles from './style/Related.css';

type Props = unknown;

export const Overview = (props: Props) => {
  const { query: topicQuery } = useContext(TopicItemContext);
  const { query: expertsQuery } = useContext(TopicMembersContext);
  const { query: postsQuery } = useContext(TopicPostsContext);

  const ready = useMemo(() =>
    !(topicQuery.status === 'loading' ||
      expertsQuery.status === 'loading' ||
      postsQuery.status === 'loading'), [
    expertsQuery.status,
    topicQuery.status,
    postsQuery.status,
  ]);

  const topic = topicQuery.data;

  const experts = useMemo(() => {
    const page = expertsQuery.data?.pages.length ? expertsQuery.data.pages[0].items : [];
    return {
      items: page.slice(0, 6),
      hasMore: page.length > 6,
    };
  }, [expertsQuery.data?.pages]);

  const posts = useMemo(() => {
    const page = postsQuery.data?.pages.length ? postsQuery.data.pages[0] : null;
    if (!page) {
      return {
        items: [],
        hasMore: false,
      };
    }

    return {
      items: page.items,
      hasMore: !!page.next,
    };
  }, [postsQuery.data?.pages]);

  if (!ready) return <ActivityIndicator show />;

  return (
    <>
      {topicQuery.data.item.description && (
        <Card>
          <Header text="Overview" />
          <OverviewPanel text={topicQuery.data.item.description} />
        </Card>
      )}
      {topicQuery.data.meta.hasExperts && (
        <Card>
          <Header text="Members">
            {experts.hasMore && (
              <Button.Secondary
                variant="brick"
                className={styles.seeMore}
                to={`${consts.path.Topics.Root}/${topicQuery.data.item.slug}/members`}>
                See More
              </Button.Secondary>
            )}
          </Header>
          <ExpertsPanel items={experts.items} />
        </Card>
      )}
      {topicQuery.data.meta.hasPosts && (
        <Card>
          <PostsPanel
            items={posts.items}
            slug={topicQuery.data.item.slug} />
        </Card>
      )}
    </>
  );
};

Overview.displayName = 'Tab.Overview';
import styles from './style/Values.css';

type Props = unknown;

export const Values = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>Our Values</h2>
        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.items}>
              {items.map(x =>
                <div
                  className={styles.item}
                  key={x.title}>
                  <div className={styles.box}>
                    <div className={styles.title}>{x.title}</div>
                    <div className={styles.text}>{x.text}</div>
                  </div>
                </div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Values.displayName = 'Careers.Values';

const items = [{
  title: `Optimism`,
  text: `We believe that technology can be a source of social good. Call us naive, but we think humanity will prevail against today's social ills and in the long-term we shall overcome!`,
}, {
  title: `Innovation`,
  text: `Vancery was founded with the belief that any challenge can solved with sufficient ingenuity and resourcefulness.`,
}, {
  title: `Transparency`,
  text: `Our company's products offer transparency as a differentiator. We seek to embed this trait in all of our communications both external and internal.`,
}, {
  title: `Excellence`,
  text: `Vancery will always strive to be the best-in-class to deliver a higher standard of quality, service, and overall experience for our team and our clients.`,
}];
import { useEffect, useRef, useMemo } from 'react';
import { Track, IVideoTrack } from 'twilio-video';
import { cx } from '@utils';
import { useMediaStreamTrack } from './hooks/useMediaStreamTrack';
import { useVideoTrackDimensions } from './hooks/useVideoTrackDimensions';
import styles from './style/VideoTrack.css';

type VideoTrackProps = {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority;
};

export function VideoTrack({ track, isLocal, priority }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;

    if (track.setPriority && priority) {
      track.setPriority(priority);
    }

    track.attach(el);

    return () => {
      track.detach(el);

      // https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;

      if (track.setPriority && priority) {
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  const isFrontFacing = track.mediaStreamTrack.getSettings().facingMode !== 'environment';
  const classname = cx(styles.root, { [styles.local]: isLocal && isFrontFacing });

  return (
    <video
      ref={ref}
      className={classname} />
  );

  // const style = useMemo(() => ({
  //   transform: isLocal && isFrontFacing ? 'rotateY(180deg)' : '',
  //   objectFit: 'contain' as const,
  // }), [isLocal, isFrontFacing]);

  // return (
  //   <video
  //     ref={ref}
  //     className={styles.root}
  //     style={style} />
  // );
}

export default VideoTrack;
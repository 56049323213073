import { createNamedContext } from '@utils';
import { Chat, Coordinator, LiveRoom, Socket, TwilioVoice, TwilioVideo } from './interfaces';

export const ConferenceChatContext = createNamedContext<Chat.Context>(undefined, 'Conference.Chat.Context');

export const ConferenceCoordinatorContext = createNamedContext<Coordinator.Context>(undefined, 'Conference.Coordinator.Context');

export const ConferenceLiveRoomBarContext = createNamedContext<LiveRoom.Bar.Context>(undefined, 'Conference.LiveRoom.Bar.Context');

export const ConferenceLiveRoomScreenContext = createNamedContext<LiveRoom.Screen.Context>(undefined, 'Conference.LiveRoom.Screen.Context');

export const ConferenceSocketContext = createNamedContext<Socket.Context>(undefined, 'Conference.Socket.Context');

export const ConferenceTwilioVoiceContext = createNamedContext<TwilioVoice.Context>(undefined, 'Conference.TwilioVoice.Context');

export const ConferenceTwilioVideoContext = createNamedContext<TwilioVideo.Context>(undefined, 'Conference.TwilioVideo.Context');
import { useCallback } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { RankingQuestion } from '@/types';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import { RequiredRankings } from './Ranking.Settings.Required';
import { ResponsePiping } from './ResponsePiping';
import styles from './style/Ranking.Question.css';

type Props = {
  item: RankingQuestion.Question;
};

export const RankingQuestionBuilder = ({ item }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const handleChange = useCallback((value: number) => {
    dispatch({
      item: {
        ...item,
        settings: {
          ...item.settings,
          mustRank: value,
        },
      },
      type: 'update-question',
    });
  }, [dispatch, item]);

  return (
    <>
      <QuestionSettingTitle title="Options">
        <ResponsePiping />
      </QuestionSettingTitle>
      <QuestionOptionsBuilderContainer
        item={item}
        maxOptions={surveyBuilder.ranking.MaxOptions}
        minOptions={surveyBuilder.ranking.MinOptions} />
      <div className={styles.settings}>
        <QuestionSettingTitle
          title="Must Rank" />
        <div className={styles.input}>
          <RequiredRankings
            numberOfItems={item.options.length}
            onChange={handleChange}
            value={item.settings.mustRank} />
        </div>
      </div>
    </>
  );
};

export default RankingQuestionBuilder;
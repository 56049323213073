import extend from '@enums/extend';

export enum SurveyQuestionType {
  ShortTextResponse = 1,
  MultipleChoice = 2,
  LongTextResponse = 3,
  Multiselect = 4,
  Ranking = 5,
  MatrixGrid = 6,
  MaxDifference = 7,
  ConjointAnalysis = 8,
  Sliders = 9,
  MatrixMultiselect = 10,
  NumberInputTable = 11,
}

const surveyQuestionTypeMap = new Map([
  [SurveyQuestionType.ShortTextResponse, { name: 'Short Text Response' }],
  [SurveyQuestionType.MultipleChoice, { name: 'Multiple Choice' }],
  [SurveyQuestionType.LongTextResponse, { name: 'Long Text Response' }],
  [SurveyQuestionType.Multiselect, { name: 'Multiselect' }],
  [SurveyQuestionType.Ranking, { name: 'Ranking' }],
  [SurveyQuestionType.MatrixGrid, { name: 'Matrix Grid' }],
  [SurveyQuestionType.MaxDifference, { name: 'MaxDiff' }],
  [SurveyQuestionType.ConjointAnalysis, { name: 'Conjoint Analysis' }],
  [SurveyQuestionType.Sliders, { name: 'Sliders' }],
  [SurveyQuestionType.MatrixMultiselect, { name: 'Matrix Multiselect' }],
  [SurveyQuestionType.NumberInputTable, { name: 'Number Input Table' }],
]);

export enum SurveyType {
  Compliance = 'compliance',
  Project    = 'project',
}

export enum SurveyTypeNew {
  GroupCompliance = 1,
  Project = 2,
  ProjectScreening = 3,
}

export enum SurveyStatus {
  NotRequired = 1,
  Incomplete  = 2,
  Completed   = 3,
}

export enum SurveyActionType {
  None = 0,
  Disqualification = 1,
  Flag = 2,
  SkipToEnd = 3,
  SkipToQuestion = 4,
  SkipToSection = 5,
  IncludeSection = 6,
  QuotaOverLimit = 7,
}

const surveyActionTypeMap = new Map([
  [SurveyActionType.None,             { name: 'None' }],
  [SurveyActionType.Disqualification, { name: 'Disqualify' }],
  [SurveyActionType.Flag,             { name: 'Flag' }],
  [SurveyActionType.SkipToEnd,        { name: 'Skip to end' }],
  [SurveyActionType.SkipToQuestion,   { name: 'Skip to question' }],
  [SurveyActionType.SkipToSection,    { name: 'Skip to section' }],
  [SurveyActionType.IncludeSection,   { name: 'Include section' }],
  [SurveyActionType.QuotaOverLimit,   { name: 'Quota over limit' }],
]);

export enum SurveyQuestionTaggingValueState {
  Selected = 1,
  NotSelected = 2,
}

export enum SurveyQuestionTaggingActionType {
  ProductPositiveAffinity = 1,
  ProductNegativeAffinity = 2,
}

export enum SurveyQuestionConditionType {
  Chosen = 0,
  NotChosen = 1,
  RankedFirst = 2,
  RankedLast = 3,
}

const surveyConditionTypeMap = new Map([
  [SurveyQuestionConditionType.Chosen,             { name: 'Chosen' }],
  [SurveyQuestionConditionType.NotChosen,          { name: 'Not Chosen' }],
  [SurveyQuestionConditionType.RankedFirst,          { name: 'Ranked First' }],
  [SurveyQuestionConditionType.RankedLast,          { name: 'Ranked Last' }],
]);

export enum SurveyQuotaType {
  Basic = 1,
  Advanced = 2,
}

export enum SurveyOptionType {
  Default = 1,
  NotApplicable = 2,
  Other = 3,
  NoneOfTheAbove = 4,
}

export default {
  SurveyQuestionType: extend(surveyQuestionTypeMap),
  SurveyActionType: extend(surveyActionTypeMap),
  SurveyQuestionConditionType: extend(surveyConditionTypeMap),
};
import { useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelectUser } from '@containers';
import { cx } from '@utils';
import { Footer } from '$website/components';
import { Benefits } from '$website/screens/SurveyInvite/Benefits';
import { FAQ } from '$website/screens/SurveyInvite/FAQ';
import { ValueProposition } from '$website/screens/SurveyInvite/ValueProposition';
import styles from './style.css';

type Props = {
  title: string;
};

export default function Body({ title }: Props) {
  const user = useSelectUser();
  const authed = useMemo(() => !!user?.state.authenticated, [user?.state.authenticated]);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <div className={cx(styles.details, styles.info)}>
              <div className={styles.group}>
                <div className={styles.row}>
                  <h1 className={styles.title}>{title}</h1>
                </div>
              </div>
              {authed ? <Authenticated /> : <Anonymous />}
            </div>
            <div className={styles.profiles}>
              <div className={styles.frame}>
                <div className={styles.graphic} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {!authed && <AnonymousFooter />}
    </>
  );
}

function Anonymous() {
  const history = useHistory();

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    history.replace(path);
  }, [history]);

  return (
    <>
      <div className={styles.group}>
        <div className={styles.row}>
          <div className={styles.text}>{`Want to get paid to offer your unique insights in surveys and interviews? Join Vancery to participate in paid consulting projects!`}</div>
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.row}>
          <Link className={styles.href} to={path} onClick={handleClick}>
            {`Learn more about Vancery >`}
          </Link>
        </div>
      </div>
    </>
  );
}

function AnonymousFooter() {
  return (
    <>
      <FAQ />
      <Benefits />
      <ValueProposition />
      <Footer />
    </>
  );
}

function Authenticated() {
  const history = useHistory();

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    history.replace(path);
  }, [history]);

  return (
    <>
      <div className={styles.group}>
        <div className={styles.row}>
          <Link className={styles.href} to={path} onClick={handleClick}>
            {`Home >`}
          </Link>
        </div>
      </div>
    </>
  );
}

export { Body };

const path = `/`;
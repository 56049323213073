import { SurveyQuestionType } from '@enums';
import { NumberInputTableQuestion } from '@/types';
import { Validation } from '../interfaces';
import { assertValidOptions } from './validation.options';
import { assertValidRows } from './validation.rows';
import { assertHasValue, returnAssertFailure } from './validation.base';

export const DefaultColumns = 3;
export const MaxColumns = 11;
export const MinColumns = 2;

export const DefaultRows = 5;
export const MaxRows = 30;
export const MinRows = 1;

export const DefaultSettings: NumberInputTableQuestion.Settings = {
  allowEmptyValues: false,
  displayTotals: false,
  validations: {
    minValue: null,
    maxValue: null,
  },
};

export function assertValidSettings(question: NumberInputTableQuestion.Question): Validation.AssertionResult {
  const { minValue, maxValue } = question.settings.validations;

  if (minValue && maxValue && minValue > maxValue) {
    return {
      message: `Minimum value can't be greater than maximum value`,
      success: false,
    };
  }

  return {
    success: true,
  };
}

export function validateQuestion(question: NumberInputTableQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.NumberInputTable> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinColumns, MaxColumns);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  const hasValidRows = assertValidRows(question.matrixRows, MinRows, MaxRows);
  if (hasValidRows.success === false) {
    return returnAssertFailure(hasValidRows, question);
  }

  const hasValidSettings = assertValidSettings(question);
  if (hasValidSettings.success === false) {
    return returnAssertFailure(hasValidSettings, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}

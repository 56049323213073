import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppReadyState } from '@containers/AppReadyState/hooks';
import { useLogout } from '@containers/AppStateEffect/hooks';
import { Spinner } from 'components/ActivityIndicator';
import styles from './style/Inbound.css';

export function OAuth2Logout() {
  const history = useHistory();
  const logout = useLogout();
  const app = useAppReadyState();

  useEffect(() => {
    if (app.initialized) {
      logout({
        sideEffects: true,
      })
      .then(() => history.replace('/'));
    }
  }, [app.initialized, history, logout]);

  return (
    <div className={styles.root}>
      <Spinner />
    </div>
  );
}
import {
  useCallback,
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';

export const useDebounceCallback = <CallbackReturnType, CallbackArgs extends unknown[] = []>(
  callback: (...args: CallbackArgs) => CallbackReturnType,
  wait = 100,
): ((...args: CallbackArgs) => void) => {
  const timeout = useRef<NodeJS.Timeout>(null);

  useEffect(() => () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  return useCallback((...args: CallbackArgs) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      timeout.current = null;
      callback(...args);
    }, wait);
  }, [callback, wait]);
};

export const useDebounce = <State>(
  initialState: State | (() => State),
  wait?: number,
): [State, Dispatch<SetStateAction<State>>] => {
  const [state, setState] = useState<State>(initialState);
  return [state, useDebounceCallback(setState, wait)];
};

type DebounceRef<CallbackReturnType> = {
  promise: Promise<CallbackReturnType> | void;
  reject: (reason?: unknown) => void;
  resolve: (value?: CallbackReturnType | PromiseLike<CallbackReturnType>) => void;
  timeout: ReturnType<typeof setTimeout>;
};

export const useAsyncDebounce = <CallbackReturnType extends Record<string, unknown> | void, CallbackArgs extends unknown[] = []>(
  callback: (...args: CallbackArgs) => Promise<CallbackReturnType>,
  wait = 100,
): ((...args: CallbackArgs) => Promise<CallbackReturnType>) => {
  const debounceRef = useRef<DebounceRef<CallbackReturnType>>({
    promise: null,
    reject: null,
    resolve: null,
    timeout: null,
  });

  return useCallback(async (...args: CallbackArgs) => {
    if (!debounceRef.current.promise) {
      debounceRef.current.promise = new Promise((resolve, reject) => {
        debounceRef.current.resolve = resolve;
        debounceRef.current.reject = reject;
      });
    }

    if (debounceRef.current.timeout) {
      clearTimeout(debounceRef.current.timeout);
    }

    debounceRef.current.timeout = setTimeout(async () => {
      delete debounceRef.current.timeout;
      try {
        debounceRef.current.resolve(await callback(...args));
      } catch (err) {
        debounceRef.current.reject(err);
      } finally {
        delete debounceRef.current.promise;
      }
    }, wait);

    return debounceRef.current.promise;
  }, [callback, wait]);
};
import { useCallback } from 'react';
import * as Stripe from '@screens/Stripe';
import { useUserPaymentMethodDetails } from '@containers/Payment';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { useLock } from '@utils';
import { ActivityIndicator } from 'components/ActivityIndicator';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import Header from 'components/Modal/Header';
import { useCreateAdHocCallRequest } from './hooks/useCreateAdHocCallRequest';
import {
  useAdHocRequestStepper,
  useAdHocRequestNote,
  useAdHocRequestRate,
  useAdHocRequestRecipient,
  useAdHocRequestSubject,
} from './Context';
import styles from './style/AdHoc.css';

export const AdHocCallRequestConfirmation = () => {
  const [note] = useAdHocRequestNote();
  const recipient = useAdHocRequestRecipient();
  const rate = useAdHocRequestRate();
  const [subject] = useAdHocRequestSubject();
  const save = useCreateAdHocCallRequest();
  const { back } = useAdHocRequestStepper();

  const [busy, lock] = useLock();

  const [details, loading] = useUserPaymentMethodDetails();

  const renderPaymentMethod = useCallback(() => {
    if (loading) return <ActivityIndicator show />;

    return (
      <div className={styles.card}>
        {details.bankAccount
        ? <Stripe.ExistingBank {...details.bankAccount} />
        : <Stripe.ExistingCard {...details.card} />}
        <div className={styles.edit} onClick={back}>Change</div>
      </div>
    );
  }, [back, details, loading]);

  return (
    <>
      <Header
        className={styles.header}
        text="Confirm call request" />

      <div className={styles.recipient}>
        <UserAvatar
          className={styles.avatar}
          size={45}
          pictureUrl={recipient.pictureUrl} />
        {recipient.firstName} {recipient.lastName}
      </div>

      <div className={styles.section}>
        <div className={styles.label}>Length</div>
        <div>60 Minutes</div>
      </div>

      <div className={styles.section}>
        <div className={styles.label}>Subject</div>
        <div>{subject}</div>
      </div>

      {note &&
        <div className={styles.section}>
          <div className={styles.label}>Note</div>
          <div>{note}</div>
        </div>
      }

      <div className={styles.section}>
        <div className={styles.label}>Payment Method</div>
        <div className={styles.payment}>
          {renderPaymentMethod()}
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.label}>Price</div>
        <div>${rate}</div>
      </div>

      <div className={styles.footer}>
        <ButtonActivityIndicator
          className={styles.btn}
          loading={busy}
          variant="brick"
          color="affirmative"
          onClick={lock(save)}>
          Confirm
        </ButtonActivityIndicator>
      </div>

    </>
  );
};

export default AdHocCallRequestConfirmation;
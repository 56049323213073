import { path } from '@consts';
import { LinkLabeledIcon, useSignupModal } from '$website/components';
import { Site, usePageContent } from '$website/containers';
import { useAllowAuthFlow } from '$website/containers/Auth/hooks/useAllowAuthFlow';
import { useLoginModal } from '$website/components/AuthModal/useLoginModal';
import { Button } from 'components/Button';
import styles from './style/Landing.css';

const Landing = () => {
  const content = usePageContent<'main', 'landing'>('landing');
  const { button } = useButtonAttributes();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          <h1 className={styles.title}>{content.text.title}</h1>
          <div className={styles.copy}>{content.text.copy}</div>

          {/* <div className={styles.action}>
            <Button
              className={styles.btn}
              href={button.cta.href}
              onClick={button.cta.onClick}
              to={button.cta.to}
              variant="pill">
              {button.cta.text}
            </Button>
            <Button
              className={styles.btn}
              color="secondary"
              href={content.button.login.href}
              to={content.button.login.to}
              variant="pill">
              {content.button.login.text}
            </Button>
          </div> */}

          <LinkLabeledIcon
            classes={{ link: styles.link }}
            to={path.Website.CustomerResearch}>
            Vancery for Business
          </LinkLabeledIcon>
        </div>
      </div>
    </div>
  );
};

const useButtonAttributes = () => {
  const allowAuthFlow = useAllowAuthFlow();
  const content = usePageContent<'main', 'landing'>('landing');
  const [l, setLoginModalOpen] = useLoginModal();
  const [_, setSignupModalOpen] = useSignupModal();

  if (!allowAuthFlow) return content as ButtonContent;

  return {
    button: {
      cta: {
        onClick: () => setSignupModalOpen(true),
        text: content.button.cta.text,
      },
      login: {
        onClick: () => setLoginModalOpen(true),
        text: content.button.login.text,
      },
    },
  } as ButtonContent;
};

type ButtonAttributeOverrides = {
  onClick?: () => void;
} & Pick<Site.ButtonAttributes, 'text'>
  & Partial<Omit<Site.ButtonAttributes, 'text'>>

type ButtonOverrides = {
  cta: ButtonAttributeOverrides;
};

type ButtonContent = {
  button: ButtonOverrides;
};

export { Landing };
export default Landing;
import { useMemo } from 'react';
import { useRequestCallWithUser } from '@containers/RequestCall/hooks/useRequestCall';
import { useSelectUser } from '@containers/Store';
import { Contact } from '@/types';

type Props = {
  user: Pick<Contact, 'id' | 'profile'>;
};

export const useProfileRequestCallAction = ({ user }: Props) => {
  const toggleModal = useRequestCallWithUser(user);
  const me = useSelectUser();

  const canInitiateCalls = useMemo(() => me.id && me.id !== user.id, [me, user.id]);

  return [canInitiateCalls, toggleModal] as const;
};

export default useProfileRequestCallAction;
import { forwardRef, Ref } from 'react';
import Popper, { PopperProps } from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Link } from 'react-router-dom';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { PopupState as PopupStateProps } from 'material-ui-popup-state/core';
import { getLocationFor } from '@utils';
import { useSelectWorkspaces } from '@containers/Store';
import { PopperMenu, PopperMenuItem } from 'components/Popper';

type MenuProps = {
  onClose: () => unknown;
}

const WorkspacesContextMenu = forwardRef(({ onClose }: MenuProps, ref: Ref<HTMLDivElement>) => {
  const workspaces = useSelectWorkspaces();

  return (
    <PopperMenu ref={ref}>
      <Link to={getLocationFor.workspaces.root({ workspace: 'personal' })} onClick={onClose}>
        <PopperMenuItem>
          {workspaces.personal.name}
        </PopperMenuItem>
      </Link>
      <Link to={getLocationFor.workspaces.root({ workspace: 'group' })} onClick={onClose}>
        <PopperMenuItem>
          {workspaces.group.name}
        </PopperMenuItem>
      </Link>
      <Link to={getLocationFor.workspaces.root({ workspace: 'shared' })} onClick={onClose}>
        <PopperMenuItem>
          Shared
        </PopperMenuItem>
      </Link>
    </PopperMenu>
  );
});

export type WorkspacesMenuPopperProps = {
  renderAnchor: (state: PopupStateProps) => React.ReactNode;
} & Pick<PopperProps, 'placement'>;

export const WorkspacesContextMenuPopper = ({
  placement = 'bottom-start',
  renderAnchor,
}: WorkspacesMenuPopperProps) => {

  return (
    <PopupState variant="popper" popupId="workspaces-popper">
      {popupState => (
        <div>
          <div {...bindToggle(popupState)}>
            {renderAnchor(popupState)}
          </div>
          <Popper {...bindPopper(popupState)} placement={placement} style={{ zIndex: 2 }}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <WorkspacesContextMenu
                onClose={popupState.close} />
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
};

import { useCallback, useMemo } from 'react';
import { Button } from 'components/Button';
import { ButtonSet } from 'components/Modal/ButtonSet';
import { Prompt } from 'components/Modal/Prompt';
import { UserPromptProps } from './interfaces';
import styles from './style/Prompt.css';

type Props =
  UserPromptProps;

export const RemoveUserPrompt = ({
  onClose,
  onConfirm,
  project,
  user,
  visible,
}: Props) => {

  const header = useMemo(() => {
    return `Reject ${user.profile.firstName} from ${project.name} project?`;
  }, [
    project.name,
    user.profile.firstName,
  ]);

  const body = useMemo(() => `If you reject this user they will be marked as Rejected in the project view.`, []);

  const Footer = useCallback(() => {
    return (
      <ButtonSet>
        <Button
          className={styles.btn}
          color="destructive"
          onClick={onClose}
          variant="brick">
          Cancel
        </Button>
        <Button
          className={styles.btn}
          color="primary"
          onClick={onConfirm}
          variant="brick">
          Reject
        </Button>
      </ButtonSet>
    );
  }, [
    onClose,
    onConfirm,
  ]);

  return (
    <Prompt
      body={body}
      footer={Footer()}
      header={header}
      onClose={onClose}
      visible={visible} />
  );
};

export default RemoveUserPrompt;
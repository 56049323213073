import { PaymentCardBrand } from '@/types';
import { cx } from '@utils';
import { getLogoImage } from './logos';
import styles from './style/ExistingCard.css';

type Props = {
  className?: string;
  brand: PaymentCardBrand;
  expMonth: number;
  expYear: number;
  last4: string;
  name: string;
};

export function ExistingCard({ className, brand, expMonth, expYear, last4, name }: Props) {
  return (
    <div className={cx(styles.root, className)}>
      <img className={styles.logo} src={getLogoImage(brand)} />
      <div>
        <div className={styles.card}>
          <div className={styles.number}>•••• {last4}</div>
          <div className={styles.expiry}>Expires {displayExpiration(expMonth, expYear)}</div>
        </div>
        <div className={styles.name}>{name}</div>
      </div>
    </div>
  );
}

function displayExpiration(month: number, year: number) {
  const displayYear = year;
  const displayMonth = new Date(displayYear, month - 1).toLocaleString('default', { month: 'short' });
  return `${displayMonth} ${displayYear}`;
}

export default ExistingCard;
import { usePersistentProjectInvite } from '@containers/ProjectLinkInvitation/hooks/usePersistentProjectInvite';
import { cx } from '@utils';
import { useSignupModal } from '$website/components';
import { useLoginModal } from '$website/components/AuthModal/useLoginModal';
import { Button } from 'components/Button';
import styles from './style/Landing.css';

type Props = unknown;

const Landing = (props: Props) => {
  const [l, setLoginModalOpen] = useLoginModal();
  const [_, setSignupModalOpen] = useSignupModal();
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();
  const isFreeSurvey = !state.data.payment;

  const btn = {
    login: {
      style: state.data.theming ? {
        backgroundColor: `var(--pri-01)`,
        borderColor: state.data.theming.palette.primary.main,
        color: state.data.theming.palette.primary.main,
      } : {},
    },
    signup: {
      style: state.data.theming ? {
        backgroundColor: state.data.theming.palette.primary.main,
        borderColor: state.data.theming.palette.primary.main,
        color: state.data.theming.palette.primary.text,
      } : {},
    },
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={cx(styles.details, styles.info)}>
            <div className={styles.group}>
              <div className={styles.row}>
                <h1 className={styles.title}>{isFreeSurvey ? freeTitle : title}</h1>
              </div>
              <div className={styles.row}>
                <h2 className={styles.text}>{state.data.project.name}</h2>
              </div>
            </div>
            <div className={styles.group}>
              <div className={styles.row}>
                <div className={styles.text}>Estimated Length: 10 - 15 minutes</div>
              </div>
              {!isFreeSurvey &&
                <div className={styles.row}>
                  <div className={styles.text}>Payment: ${state.data.payment}</div>
                </div>
              }
            </div>
            <div className={styles.group}>
              <div
                className={styles.row}
                style={{ whiteSpace: 'pre-line' }}>
                <div className={styles.text}>{state.data.project.description}</div>
              </div>
            </div>
            <div className={styles.group}>
              <div className={cx(styles.row, styles.btns)}>
                <Button
                  className={styles.btn}
                  color="primary"
                  onClick={() => setSignupModalOpen(true)}
                  variant="pill"
                  style={btn.signup.style}>
                  Sign Up
                </Button>
                <Button
                  className={styles.btn}
                  color="secondary"
                  onClick={() => setLoginModalOpen(true)}
                  variant="pill"
                  style={btn.login.style}>
                  Log In
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.profiles}>
            <div className={styles.frame}>
              <div className={styles.graphic} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const title = `You've been invited to join Vancery to take a paid survey and receive future consulting opportunities!`;
const freeTitle = `You've been invited to join Vancery to take a survey and receive future consulting opportunities!`;

export { Landing };
export default Landing;
import { useCallback, useState } from 'react';
import ObjectAccessModal from 'components/ObjectAccess/Modal';

export const useObjectAccessModal = () => {
  const [open, setOpen] = useState(false);
  const toggleVisibility = useCallback(() => setOpen(o => !o), []);

  const Modal = useCallback(() => {

    return (
      <ObjectAccessModal
        onClose={toggleVisibility}
        open={open} />
    );
  }, [
    open,
    toggleVisibility,
  ]);

  return [toggleVisibility, Modal] as const;
};

export default useObjectAccessModal;
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { usePaidMessagingModalState } from '@containers/PlatformMessage/hooks/usePaidMessagingModalState';
import { hasClientRole } from '@utils';
import { Contact } from '@/types';

const mapState = (state: Store.State) => ({
  disableMessaging: state.group.features?.disableMessaging,
  hasClientRole: hasClientRole(state.user),
  meId: state.user.id,
});

type Props = {
  user: Pick<Contact, 'id' | 'profile'>;
};

export const useProfileMessagingAction = ({ user }: Props) => {
  const [_, setRecipient] = usePaidMessagingModalState();

  const { meId, hasClientRole, disableMessaging } = useSelector(mapState);

  const initiate = useCallback(() => {
    setRecipient({
      id: user.id,
      profile: user.profile,
    });
  }, [
    setRecipient,
    user,
  ]);

  const canMessage = useMemo(() => {
    const hasMessaging = !hasClientRole || !disableMessaging;
    return meId && user.id !== meId && hasMessaging;
  }, [
    hasClientRole,
    meId,
    disableMessaging,
    user.id,
  ]);

  return [canMessage, initiate] as const;
};

export default useProfileMessagingAction;
import { useCallback } from 'react';
import * as api from '@api';
import * as enums from '@enums';
import { useSelectObject } from '@containers/Store';
import Toast from 'components/Toast';
import { BaseObjectAccessContainer } from './BaseObjectAccessContainer';
import { ObjectAccessState } from './ObjectAccessState';
import { ObjectAccess as OA } from './interfaces';

type Props =
  IWorkspaceObjectId &
  IWorkspaceId &
  ChildrenProps;

export const ObjectAccessContainer = (props: Props) => {
  const object = useSelectObject(props.objectId);

  const fetchAccess = useCallback(() => {
    return api.workspaces.object.getObjectAccess({
      objectId: props.objectId,
      workspaceId: props.workspaceId,
    });
  }, [
    props.objectId,
    props.workspaceId,
  ]);

  const saveAccess = useCallback((data: OA.SaveAccessParams) => {
    return api.workspaces.object.saveObjectAccess({
      items: data.items,
      objectId: props.objectId,
      workspaceId: props.workspaceId,
    }).then(_ => {
      Toast.alert({
        title: `${enums.utils.WorkspaceObjectType.getName(object.typeId)} Access Updated`,
      });
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue updating access`,
      });
    });
  }, [
    object.typeId,
    props.objectId,
    props.workspaceId,
  ]);

  const fetchGroupUsers: OA.FetchGroupUsers = useCallback(data => {
    return api.groups.getGroupUsers(data);
  }, []);

  const containerProps = {
    fetchAccess,
    fetchGroupUsers,
    saveAccess,
  };

  return (
    <ObjectAccessState>
      <BaseObjectAccessContainer {...containerProps}>
        {props.children}
      </BaseObjectAccessContainer>
    </ObjectAccessState>
  );
};

import { useSelectUser } from '@containers';
import { AccountProfileContainer } from '@containers';
import { EmploymentCheckup } from './EmploymentCheckup';

type Props = unknown;

const AccountProfileCheckup = (props: Props) => {

  const me = useSelectUser();

  return (
    <AccountProfileContainer userId={me.id}>
      <EmploymentCheckup />
    </AccountProfileContainer>
  );
};

export { AccountProfileCheckup };
export default AccountProfileCheckup;
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonOutlined } from '@presentation';
import { useSelectUser } from '@containers';
import * as consts from '@consts';
import * as mixpanel from '@services/mixpanel';
import { useUpdateNewUser } from '@utils/api';
import styles from './style.css';

type Props = unknown;

const WelcomeScreen = (props: Props) => {
  const updateNewUser = useUpdateNewUser();

  const startRateOnboarding = () => {
    mixpanel.event.onboarding.getStarted();
    updateNewUser();
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.earn}>Ready to see how much you can earn?</h1>
        <div className={styles.copy}>By inputting your industry and years of experience, we can recommend how much you should charge for projects.</div>
        <ButtonOutlined
          className={styles.btn}
          onClick={startRateOnboarding}
          to={consts.path.Onboarding.Rate}>
          Get Started
        </ButtonOutlined>
      </div>
    </div>
  );
};

const Welcome = () => {
  const history = useHistory();
  const user = useSelectUser();

  useEffect(() => {

    if (!user.meta.isNew) history.replace(consts.pathname.HOME);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <WelcomeScreen />
  );
};

export { Welcome };
export default Welcome;
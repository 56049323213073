import { FormButtons } from '@presentation/FormButtons';
import { QuestionText } from '@presentation/Survey';
import OptionsTaggingBuilder from './OptionsTaggingBuilder';
import { QuestionTaggingBuilderProps } from './interfaces';
import styles from './style/QuestionTaggingBuilder.css';

type Props
  = QuestionTaggingBuilderProps;

export default function QuestionTaggingBuilder({ canSave, item, onCancel, onSubmit }: Props) {
  return (
    <div className={styles.root}>
      <QuestionText
        className={styles.questionText}
        item={item} />
      <div className={styles.tagging}>
        <OptionsTaggingBuilder item={item} />
      </div>
      <FormButtons
        className={styles.formBtns}
        archivable={false}
        disabled={!canSave}
        deletable={false}
        onSubmit={onSubmit}
        onCancel={onCancel} />
    </div>
  );
}

export { QuestionTaggingBuilder };
import { useCallback } from 'react';
import { cx } from '@utils';
import { SurveyQuestionMatrixRow } from '@/types';
import SurveyMatrixGridListItem from './ListItem';
import { Answer, SurveyMatrixGridProps, MatrixVariant } from './interfaces';
import styles from './style/List.css';

export const SurveyMatrixGridList = <T extends MatrixVariant>({
  updateRowAnswer,
  ...props
}: SurveyMatrixGridProps<T>) => {

  const renderItem = useCallback((row: SurveyQuestionMatrixRow) => {
    if (props.variant === 'single') {
      const value = (props.answers as Answer<'single'>).find(f => f.matrixRowId === row.id)?.optionId;
      return (
        <SurveyMatrixGridListItem
          key={row.id}
          options={props.options}
          row={row}
          value={value}
          updateRowAnswer={updateRowAnswer(row.id)}
          variant="single" />
      );
    } else {
      const value = (props.answers as Answer<'multiselect'>).find(f => f.matrixRowId === row.id)?.optionIds;
      return (
        <SurveyMatrixGridListItem
          key={row.id}
          options={props.options}
          row={row}
          value={value}
          updateRowAnswer={updateRowAnswer(row.id)}
          variant="multiselect" />
      );
    }
  }, [
    props.answers,
    props.options,
    updateRowAnswer,
    props.variant,
  ]);

  return (
    <div className={cx(styles.list, props.className)}>
      {props.rows.map(renderItem)}
    </div>
  );
};

export default SurveyMatrixGridList;
import { cloneElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as actions from '@actions';
import * as api from '@api';
import { getLocationFor } from '@utils';
import { useNotifier } from './Notifier';
import * as utils from './RequestCall.utils';
import * as scheduler from './utils';
import {
  Call,
  PipelineRecord,
  RequestCallCallback,
  RequestCallCallbackParams,
  RequestCallParams,
  RequestCallProps,
  RequestCallSubmitHandler,
} from './interfaces';

const mapState = (state: Store.State) => ({
  contacts: state.contacts,
  findActivelySchedulingCallWithUser: utils.findActivelySchedulingCallWithUser(state),
  hasCallAwaitingCompliance: utils.hasCallAwaitingCompliance(state),
  hasCallAwaitingConsultant: utils.hasCallAwaitingConsultant(state),
  pipeline: state.pipeline,
  projects: state.projects,
  user: state.user,
});

type Props =
  RequestCallProps;

const RequestCallScheduler = (props: Props) => {
  const state = useSelector(mapState);
  const history = useHistory();
  const notifier = useNotifier();
  const dispatch = useDispatch();

  const navigateToProvideScheduling = (data: RequestCallParams) => {
    const location = getLocationFor.scheduling.providing({
      creatorId: state.user.id,
      projectId: data.projectId,
      scheduleeId: data.userId,
      schedulerId: state.user.id,
      userId: data.userId,
    });

    history.push(location);
  };

  const navigateToSelectScheduling = (data: Call) => {
    const location = getLocationFor.scheduling.selecting({
      callId: data.id,
      projectId: data.projectId,
      scheduleeId: data.creatorUserId,
      schedulerId: data.userId,
    });

    history.push(location);
  };

  const attemptScheduling = (record: PipelineRecord) => {
    const call = state.findActivelySchedulingCallWithUser(record);

    if (!call) {
      navigateToProvideScheduling(record);
    } else if (utils.isCallAwaitingAnalyst(call, record)) {
      navigateToSelectScheduling(call);
    } else {
      notifier.callInviteAwaitingConsultant(record);
    }
  };

  const attemptCallRequest = (record: PipelineRecord) => {
    if (state.hasCallAwaitingCompliance(record)) {
      return notifier.callInviteAwaitingCompliance(record);
    } else if (state.hasCallAwaitingConsultant(record)) {
      return notifier.callInviteAwaitingConsultant(record);
    } else {
      return attemptScheduling(record);
    }
  };

  const wrapCallback = (callback?: RequestCallCallback) => {
    return (res: RequestCallCallbackParams) => typeof callback === 'function' && callback(res);
  };

  const createInvite = (data: RequestCallParams, callback: RequestCallCallback) => {
    return api.projects.pipeline.invite({
      projectId: data.projectId,
      userId: data.userId,
    })
    .then(res => {
      dispatch(actions.batchActions([
        actions.projectPipelineUpdated({
          pipeline: res.pipeline,
          projectId: data.projectId,
        }),
        actions.contactsAdded({
          contacts: res.contacts,
        }),
      ]));

      return res;
    })
    .then(res => {
      const users = state.pipeline.project[data.projectId].users;
      const record = users[data.userId];

      notifier.projectInviteSent(record);
      callback(res);
    });
  };

  const handleSubmit = (data: RequestCallParams, callback?: RequestCallCallback) => {
    const users = state.pipeline.project[data.projectId].users;
    const record = users[data.userId];

    if (scheduler.projects.isNotMemberOfProject(record)) {
      createInvite(data, wrapCallback(callback));
    } else if (scheduler.projects.isAwaitingJoin(record)){
      notifier.projectInviteInProgress(record);
    } else if (scheduler.projects.isMemberOfProject(record)) {
      attemptCallRequest(record);
    }
  };

  return cloneElement(props.children, {
    handleSubmitCallRequest: handleSubmit,
  });
};

export const withRequestCallScheduler = <P extends RequestCallSubmitHandler>(WrappedComponent: React.ComponentType<P>) => {
  const WithRequestCallScheduler = props => {

    return (
      <RequestCallScheduler>
        <WrappedComponent {...props} />
      </RequestCallScheduler>
    );
  };

  return WithRequestCallScheduler;
};

export { RequestCallScheduler };
export default RequestCallScheduler;
import { ProjectRateScreen } from '@screens/RateOnboarding/ProjectRateScreen';
import { RateIntroScreen } from '@screens/RateOnboarding/Intro';
import { ProjectDisqualification } from '@screens/ProjectDisqualification/ProjectDisqualification';
import { AccountProfile, EmploymentSetup, Mixed, SurveyBilling, ProjectRequest } from '@screens/ProjectOnboarding';
import * as utils from './utils';

export const selectRateScreens = (state: Store.State) => (projectId: number): React.ComponentType[]  => {
  const project = state.projects[projectId];

  const needsRateSet = utils.needsRateSet(projectId, state);
  const needsEmployment = utils.needsEmploymentUpdated(state);

  const screens = [];

  if (needsRateSet) {
    screens.push(RateIntroScreen);
  }

  if (needsEmployment) {
    const screen = utils.isProjectMixedType(project)
        ? Mixed.EmploymentSetup
        : EmploymentSetup;

    screens.push(screen);
  }

  if (needsRateSet) {
    screens.push(ProjectRateScreen);
  }

  return screens;
};

export const selectCompletedScreens = (projectId: number) => (state: Store.State): React.ComponentType[]  => {
  const project = state.projects[projectId];
  const screens = [];

  if (!utils.isProjectSurveyType(project)) {
    screens.push(ProjectRequest.Complete);
  } else {
    screens.push(SurveyBilling.Stepper);

    if (utils.needsAccountProfileSetup(state)) {
      screens.push(AccountProfile.Stepper);
    }
  }

  return screens;
};
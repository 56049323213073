import { useMemo } from 'react';
import { useAccountProfileBioContext, useProfileState } from '@containers';
import { Header, Subtitle, Title, BackButton, Footer, NextButton } from '@screens/ProjectCreation';
import { TextareaCharacterLimit } from 'components/Textarea';
import { ScreenNavigationProps } from './interfaces';
import styles from './style/AccountProfileSetup.css';

type Props = {
  navigation:
    & Required<Pick<ScreenNavigationProps['navigation'], 'onClick'>>
    & Pick<ScreenNavigationProps['navigation'], 'back'>;
  subtitle?:  string;
  title?:     string;
};

const SummarySetup = (props: Props) => {
  const ctx = useAccountProfileBioContext();
  const [state] = useProfileState();

  function handleSubmit() {
    ctx.form.handleSubmit(props.navigation.onClick);
  }

  const disabled = useMemo(() => {
    return ctx.form.submitting
        || state.bio?.summary?.trim?.()?.length < 3;
  }, [
    ctx.form.submitting,
    state.bio?.summary,
  ]);

  return (
    <div className={styles.root}>
      <Header className={styles.header}>
        <Title className={styles.title}>{props.title}</Title>
        <Subtitle className={styles.subtitle}>{props.subtitle}</Subtitle>
      </Header>

      <div className={styles.main}>
        <TextareaCharacterLimit
          autoFocus
          maxLength={1000}
          name="summary"
          onChange={ctx.form.onChange}
          value={state.bio?.summary ?? ''} />
      </div>

      <Footer className={styles.footer}>
        <BackButton onClick={props.navigation.back} />
        <NextButton
          className={styles.btn}
          color="affirmative"
          disabled={disabled}
          onClick={handleSubmit}
          variant="brick">
          Complete
        </NextButton>
      </Footer>
    </div>
  );
};

const defaultProps = {
  subtitle: `Your profile is almost done! Please provide a brief summary of your areas of expertise and your profile will be complete.`,
  title: `Summary`,
};

SummarySetup.defaultProps = defaultProps;

export { SummarySetup };
export default SummarySetup;
import { useMemo, ComponentType } from 'react';
import { SurveyTemplateType } from '@enums';
import { useStepper } from '@utils/hooks';
import { SurveyTemplateSelection } from '@screens/ProjectCreation/ProjectSurvey.Template';
import { useSurveyTemplate } from '@containers/SurveyBuilder/hooks';
import { ProjectSurveyStepperContext } from './context';
import { CompetitiveIntelligenceScreens } from './ProjectSurvey.Presets.CompetitiveIntelligence';
import { PersonaResearchScreens } from './ProjectSurvey.Presets.PersonaResearch';
import { NetPromoterScoreScreens } from './ProjectSurvey.Presets.NetPromotoreScore';
import { EmployeeSatisfactionScreens } from './ProjectSurvey.Presets.EmployeeSatisfaction';
import { PricingStrategyScreens } from './ProjectSurvey.Presets.PricingStrategy';
import { MarketingCampaignEffectivenessScreens } from './ProjectSurvey.Presets.MCE';
import { ProjectSurvey } from './ProjectSurvey.Builder';

export const ProjectSurveyBuilderStepper = () => {

  const [Screen, actions] = useProjectSurveyStepper();

  return (
    <ProjectSurveyStepperContext.Provider value={actions}>
      <Screen />
    </ProjectSurveyStepperContext.Provider>
  );
};

const useProjectSurveyStepper = () => {
  const [template] = useSurveyTemplate();

  const screens: ComponentType[] = useMemo(() => {

    const templateScreensMap: TemplateScreens = {
      [SurveyTemplateType.None]: [],
      [SurveyTemplateType.CompetitiveIntelligence]: CompetitiveIntelligenceScreens,
      [SurveyTemplateType.PersonaResearch]: PersonaResearchScreens,
      [SurveyTemplateType.NetPromoterScore]: NetPromoterScoreScreens,
      [SurveyTemplateType.EmployeeSatisfaction]: EmployeeSatisfactionScreens,
      [SurveyTemplateType.PricingStrategy]: PricingStrategyScreens,
      [SurveyTemplateType.MarketingCampaignEffectiveness]: MarketingCampaignEffectivenessScreens,
    };

    const templateScreens = templateScreensMap[template];

    return [
      SurveyTemplateSelection,
      ...templateScreens,
      ProjectSurvey,
    ].filter(Boolean);

  }, [template]);

  return useStepper(screens);
};

type TemplateScreens = {
  [v: number]: ComponentType[];
};
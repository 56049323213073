import { pathname } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter, StateRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { VerifyEmail } from '@screens/VerifyEmail';
import { Unsubscribe } from '@screens/Unsubscribe';
import { Welcome } from '@screens/Welcome';
import { hasConsultantRole } from '@utils';

const verifyEmail = [{
  component: VerifyEmail,
  path: pathname.VERIFY_EMAIL,
}];

export const verifyEmailRouter = BasicRouter(verifyEmail, {
  auth: RouteAuth.Authenticated,
  path: pathname.VERIFY_EMAIL,
});

const welcome = (state: Store.State) => {
  const routes: Routing.Route[] = [{
    component: Welcome,
    path: pathname.Welcome,
  }];

  return hasConsultantRole(state.user)
    && state.user.meta.isEmailVerified
    ? routes
    : [];
};

export const welcomeRouter = StateRouter(welcome, {
  auth: RouteAuth.Authenticated,
  path: pathname.Welcome,
});

const unsubscribe = [{
  component: Unsubscribe,
  path: `${pathname.Unsubscribe}/:token?`,
}];

export const unsubscribeRouter = BasicRouter(unsubscribe, {
  auth: RouteAuth.Ignore,
  path: pathname.Unsubscribe,
});
import { useCallback } from 'react';
import { NumberInputTableQuestion, SurveyQuestionOption } from '@/types';
import styles from './style/NumberInputTable.List.css';

type Props = {
  getCellAnswer: (rowId: number, optionId: number) => number;
  getColumnTotal: (optionId: number) => number;
  question: NumberInputTableQuestion.Question;
};

export const NumberInputTableAnswerList = ({
  getCellAnswer,
  getColumnTotal,
  question,
}: Props) => {

  const renderItem = useCallback((option: SurveyQuestionOption) => {

    return (
      <div
        key={option.ordinal}
        className={styles.item}>
        <div className={styles.header}>{option.value}</div>
        <div>
          {question.matrixRows.map(row => (
            <div
              key={row.id}
              className={styles.row}>
              <div className={styles.name}>{row.value}</div>
              <div className={styles.cell}>
                {getCellAnswer(row.id, option.id)}
              </div>
            </div>
          ))}
        </div>
        {question.settings.displayTotals &&
          <div className={styles.footer}>
            <div className={styles.name}>Total</div>
            <div className={styles.cell}>{getColumnTotal(option.id)}</div>
          </div>
        }
      </div>
    );
  }, [
    getCellAnswer,
    getColumnTotal,
    question.matrixRows,
    question.settings.displayTotals,
  ]);

  return (
    <div className={styles.root}>
      {question.options.map(renderItem)}
    </div>
  );
};

export default NumberInputTableAnswerList;
import * as xform from '@transformers/helpers';
import { safeJsonDate } from '@utils';
import { AppOnboarding } from '@/types';

function normalizeOnboarding(data: AppOnboarding) {
  if (!data) {
    return null;
  }

  const runTransforms = xform.compose<AppOnboarding, UnparsedOnboarding>(
    normalizeDates,
  );

  return runTransforms<AppOnboarding>(data);

  function normalizeDates(status: UnparsedOnboarding) {
    return {
      ...status,
      completedOn: safeJsonDate(status.completedOn),
    };
  }
}

type UnparsedOnboarding = {
  [P in keyof Pick<AppOnboarding, 'completedOn'>]: string;
} & Omit<AppOnboarding, 'completedOn'>;

export default { normalizeOnboarding };
export {
  normalizeOnboarding,
};
import { Frown, Meh, Smile, IconProps } from 'react-feather';
import { CallRating } from '@enums';

type Props = {
  value: CallRating;
} & IconProps;

const IconMap = {
  [CallRating.Bad]: Frown,
  [CallRating.Good]: Smile,
  [CallRating.Neutral]: Meh,
};

export const RatingIcon = ({ value, ...iconProps }: Props) => {
  const Icon = IconMap[value];

  return <Icon {...iconProps} />;
};

import { useCallback } from 'react';
import { useSurveyQuotasState, useSurveyQuotasGoal } from '@containers/SurveyBuilder';
import { SurveyQuotaBuilderState } from '@containers/SurveyBuilder.Quota';
import { SurveyQuota } from '@/types';
import { AddQuotaButton, SurveyQuotaBuilder, SurveyQuotaPreview } from 'components/SurveyBuilder.Quota';
import styles from './style/QuotasBuilder.css';

export const SurveyQuotasBuilder = () => {
  const goal = useSurveyQuotasGoal();

  const [state] = useSurveyQuotasState();

  const renderItem = useCallback((item: SurveyQuota.Record) => {
    if (item.identifier === state.editing.identifier) {
      return (
        <SurveyQuotaBuilderState initial={item}>
          <SurveyQuotaBuilder />
        </SurveyQuotaBuilderState>
      );
    } else {
      return (
        <SurveyQuotaPreview
          goal={goal}
          item={item} />
      );
    }
  }, [goal, state.editing]);

  return (
    <div className={styles.root}>
      {state.items.map(quota => (
        <div key={quota.identifier} className={styles.quota}>
          {renderItem(quota)}
          <div className={styles.divider} />
        </div>
      ))}
      <div className={styles.addQuota}>
        {!state.editing.identifier && <AddQuotaButton />}
      </div>
    </div>
  );
};

export default SurveyQuotasBuilder;
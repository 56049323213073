import { useCallback } from 'react';
import { ClickMarkEventHandler } from '@remirror/extension-events';
import { useEvent } from '@remirror/react';
import { MarkType, Timestamp } from '@/types/transcribe.rich-text';
import { useTranscriptMediaPlayer } from '@containers/Transcript';

export const useSeekEvent = () => {

  const player = useTranscriptMediaPlayer();

  const handleClick: ClickMarkEventHandler = useCallback((event, state) => {

    if (!player) return;

    const markRange = state.getMark(MarkType.Timestamp);

    if (!markRange) {
      return;
    }

    const attrs = markRange.mark.attrs as Timestamp.Attributes;

    if (attrs?.s) {
      player.currentTime = Number(attrs.s);
      // player.play();

      return false;
    }

    return false;
  }, [player]);

  useEvent('clickMark', handleClick);

};
import { Fragment } from 'react';
import { ErrorMessage, useField } from 'formik';
import { cx } from '@utils';
import { Input } from 'components/Input';
import styles from './style/AuthForm.css';

type Props = {
  autoFocus?: boolean;
  className?: string;
};

export default function Login({ className, autoFocus = false }: Props) {
  const [field] = useField<string>('email');

  const classes = cx(styles.row, className);

  return (
    <Fragment>
      <div className={classes}>
        <Input
          {...field}
          autoFocus={autoFocus}
          autoComplete="on"
          placeholder="Email"
          type="email" />
        <div className={styles.error}>
          <ErrorMessage name={field.name} />
        </div>
      </div>
    </Fragment>
  );
}

export { Login };
import React, { useContext } from 'react';
import { GroupContactsQueryParamsContext } from '@containers/Group.Contacts/Context';
import styles from './style/Table.Toolbar.css';

type Props = unknown;

export const SearchFilter = (props: Props) => {
  const [queryparams, setQueryParams] = useContext(GroupContactsQueryParamsContext);

  return (
    <div>
      <input
        autoComplete="off"
        autoCorrect="off"
        className={styles.input}
        defaultValue={queryparams.name}
        id="name"
        placeholder="Search name"
        onChange={e => setQueryParams({ name: e.target.value })}
        type="search" />
    </div>
  );
};

SearchFilter.displayName = 'Group.Contacts.Table.Toolbar.Search';
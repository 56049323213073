import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Card.css';

type Props = {
  onClick?: () => void;
};

export const MarketingCampaignEffectivenessCard = memo(({ onClick }: Props) => {

  const title = `Marketing Campaign Effectiveness`;

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <div className={cx(styles.poster, styles.mce)}>
        <Icon />
      </div>
      <div className={styles.footer}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
});

type IconProps = {
  color?: string;
};

const Icon = memo(({
  color = '#B466C5',
}: IconProps) => (
  <svg
    width="48px"
    height="36px"
    viewBox="0 0 40 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g
      id="Survey-Templates---Marketing-Campaign-Effectiveness"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="1"
        transform="translate(-342.000000, -535.000000)"
        stroke={color}
        strokeWidth="2">
        <g
          id="Group-11"
          transform="translate(342.500000, 536.000000)">
          <rect
            id="Rectangle"
            x="23.5"
            y="0"
            width="5"
            height="26"
            rx="2.5" />
          <path
            d="M11.5,20 L11.5,26.2222222 C11.5,27.2040618 10.6045695,28 9.5,28 C8.3954305,28 7.5,27.2040618 7.5,26.2222222 L7.5,20"
            id="Path" />
          <path
            d="M6,11.5 C6,14.1666667 4.83333333,15.5 2.5,15.5 C0.166666667,15.5 -1,14.1666667 -1,11.5"
            id="Path"
            transform="translate(2.500000, 13.500000) rotate(-270.000000) translate(-2.500000, -13.500000) " />
          <path
            d="M23.5,24 C22.7873036,23.20149 21.8396654,22.0237482 20.5432886,21.0809287 C19.6790374,20.4523823 17.9979412,20.0920728 15.5,20 L7,20 C5.61928813,20 4.5,18.8807119 4.5,17.5 L4.5,9.5 C4.5,8.11928813 5.61928813,7 7,7 L15.5,7 C17.5534601,6.62511617 19.0088841,6.19329154 19.8662719,5.7045261 C21.1523536,4.97137793 21.2109541,4.81870894 22.492046,3.84987924 C22.492046,3.84987924 23.5,2.69932495 23.5,2.69932495"
            id="Path" />
          <line
            x1="14"
            y1="7.11875417"
            x2="14"
            y2="20"
            id="Path-8" />
          <line
            x1="32.5344727"
            y1="10"
            x2="35.4157185"
            y2="7.11875417"
            id="Path-10"
            strokeLinecap="round"
            strokeLinejoin="round" />
          <line
            x1="32.5344727"
            y1="22"
            x2="35.4157185"
            y2="19.1187542"
            id="Path-10"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(33.975096, 20.559377) scale(-1, 1) translate(-33.975096, -20.559377) " />
          <line
            x1="33.4103132"
            y1="14.5"
            x2="37.6647894"
            y2="14.5"
            id="Path-11"
            strokeLinecap="round" />
        </g>
      </g>
    </g>
  </svg>
));
import { cx } from '@utils';
import { QuestionNumberProps } from './interfaces';
import styles from './style/QuestionNumber.css';

export const SurveyQuestionNumber = ({ style = {}, ...props }: QuestionNumberProps) => {
  return (
    <div
      className={cx(styles.root, props.className)}
      style={style}>
      {`Q${props.ordinal}`}
    </div>
  );
};

export const SurveySectionNumber = ({ style = {}, ...props }: QuestionNumberProps) => {
  return (
    <div
      className={cx(styles.root, props.className)}
      style={style}>
      {`S${props.ordinal}`}
    </div>
  );
};
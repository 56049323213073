import { useContext } from 'react';
import { createNamedContext } from '@utils';
import { TranscribeRT, Transcribe } from '@/types';

export const TranscriptContext = createNamedContext<TranscribeRT.Transcript>(undefined, 'TranscriptContext');
export const TranscriptMediaContext = createNamedContext<HTMLMediaElement>(undefined, 'TranscriptMediaContext');
export const CallInstanceIdContext = createNamedContext<number>(undefined, 'CallInstanceIdContext');
export const TranscriptSpeakersContext = createNamedContext<Transcribe.SpeakerMap>(undefined, 'TranscriptSpeakersContext');

export const useTranscriptMediaPlayer = () => useContext(TranscriptMediaContext);
export const useTranscript = () => useContext(TranscriptContext);
export const useCallInstanceId = () => useContext(CallInstanceIdContext);
export const useTranscriptSpeakers = () => useContext(TranscriptSpeakersContext);

export * from './context.comment';
export * from './context.comments';
export * from './context.highlights';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import * as api from '@api';
import {
  useEnterprisePaymentMethod,
  useEnterprisePaymentMethodDetails,
  useSelectGroup,
} from '@containers';
import * as consts from '@consts';
import { PaymentMethod } from '@enums';
import * as Stripe from '@screens/Stripe';
import * as actions from '@store/actions';
import { cx } from '@utils';
import { AnimatedLoader } from 'components/ActivityIndicator';
import Toast from 'components/Toast';
import styles from './style/VerifyBankAccount.css';

export function VerifyBankAccount() {
  const method = useEnterprisePaymentMethod();

  if (!method || method.verified) {
    return <Redirect to={consts.path.Settings.EnterpriseBilling} />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1 className={styles.title}>Verify Bank Account</h1>
      </div>
      <div className={styles.section}>
        <Verify />
      </div>
    </div>
  );
}

function Verify() {
  const dispatch = useDispatch();
  const history = useHistory();
  const group = useSelectGroup();
  const [details, detailsLoading] = useEnterprisePaymentMethodDetails();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const handleVerify: Stripe.OnVerifyEvent = useCallback(data => {
    setError(null);
    setLoading(true);

    api.payment.enterprise.verifyBankAccount({
      amount1: data.amount1,
      amount2: data.amount2,
      groupId: group.id,
    })
    .then(result => {
      setLoading(false);

      if (!result.success) {
        const message = result.message
          ? result.message
          : 'An error occured when trying to verify this account.';

        setError(message);
        return;
      }

      const paymentMethod = {
        type: PaymentMethod.BankAccount,
        verified: true,
      };

      dispatch(actions.paymentMethodUpdated({ paymentMethod }));

      Toast.alert({
        title: 'Bank Account Verified!',
        body: 'Your bank account has been verified!',
      });

      history.replace(consts.path.Settings.EnterpriseBilling);
    })
    .catch(() => {
      setLoading(false);
      const message = 'An error occured when trying to verify this account.';
      setError(message);
    });
  }, [dispatch, group.id, history, setError, setLoading]);

  if (detailsLoading) {
    return (
      <div className={cx(styles.root, styles.loading)}>
        <AnimatedLoader />
      </div>
    );
  }

  if (!details || !details.bankAccount || details.bankAccount.verified) {
    return <Redirect to={consts.path.Settings.EnterpriseBilling} />;
  }

  return (
    <Stripe.VerifyBank
      last4={details.bankAccount.last4}
      error={error}
      isLoading={loading}
      onVerify={handleVerify} />
  );
}

export default VerifyBankAccount;
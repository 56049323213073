import TextInput from './TextInput';
import { CityFieldProps } from './interfaces';

type Props =
    CityFieldProps;

export default function CityField(props: Props) {
  if (!props.show) return null;

  return (
    <TextInput
      className={props.className}
      labelClassName={props.labelClassName}
      error={props.error}
      placeholder={props.placeholder}
      label={props.label}
      name='city'
      value={props.value}
      required={props.required}
      onChange={props.onChange} />
  );
}

CityField.defaultProps = {
  label: 'City',
  placeholder: 'City',
  show: true,
};

export { CityField as City };
import { useEffect, useState } from 'react';
import * as services from '@services/api';
import { AnimatedLoader } from 'components/ActivityIndicator';
import {
  useProjectOnboardingContext,
} from './hooks';
import styles from './styles.css';

type Props = unknown;

enum LinkState {
  Loading,
  Error,
  Missing,
  Loaded
}

export const ExternalSurveyContainer = (props: Props) => {
  const ctx = useProjectOnboardingContext();
  const [state, setState] = useState<LinkState>(LinkState.Loading);
  const [link, setLink] = useState<string>(null);
  useEffect(() => {
    services.projects.getExternalLink({ projectId: ctx.projectId }).then(l => {
      if (l.link) {
        setLink(l.link);
        window.location.href = l.link;
      } else {
        setState(LinkState.Missing);
      }
    }).catch(e => {
      setState(LinkState.Error);
    }
    );
    //Get the redirect link
  }, [ctx.projectId]);

  if (state == LinkState.Missing || state == LinkState.Error) {
    return (
      <div className={styles.errorMsg}>
        <div>
          There was an error with third party survey platform.
        </div>
        <div>
          Contact support@vancery.com to participate in the survey.
        </div>
      </div>
    );
  } else if (state == LinkState.Loaded) {
    return (
      <div className={styles.centerText}>
        <div>
          You are now being redirected to {link}.
        </div>
      </div>
    );
  }
  return <AnimatedLoader className={styles.loading} />;
};
import { memo } from 'react';
import { makeStyles } from '@mui/styles';
import CheckboxMUI from '@mui/material/Checkbox';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { CheckboxProps } from './interfaces';
import styles from './style/CheckboxGroup.css';

const useStyles = makeStyles({
  root: {
    color: 'var(--black-l)',
    zIndex: 1,
    padding: 0,
    '&$checked': {
      color: (props: { color: string; }) => props.color,
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  checked: {
    color: (props: { color: string; }) => props.color,
  },
});

export const Checkbox = memo(({ classes = {} as CheckboxProps['classes'], ...props }: CheckboxProps) => {
  const { palette, theme } = useSurveyThemingPalette();

  const color = theme
      ? palette.primary.main
      : `var(--pri-05)`;

  const muistyles = useStyles({ color });

  const displayUnderlay = theme && props.checked;

  return (
    <div className={styles.checkbox}>
      <CheckboxMUI
        classes={{ ...muistyles, ...classes }}
        disableRipple={true}
        color="default"
        {...props} />
      {displayUnderlay &&
        <div
          className={styles.theme}
          style={{ backgroundColor: palette.primary.text }} />}
    </div>
  );
});

export default Checkbox;
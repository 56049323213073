import { CellProps, HeaderProps } from 'react-table';
import { Switch } from 'components/Switch';
import * as Pricing from '$website/containers/Pricing/interfaces';
import styles from './style/Column.Service.css';

type Props = unknown;

export const Cell = (props: CellProps<Pricing.Row>) => {
  const cell = props.row.original.service;

  return cell.type === 'spacer'
    ? ''
    : cell.type === 'header'
      ? <div className={styles.title}>{cell.name}</div>
      : <div className={styles.name}>{cell.name}</div>;
};

Cell.displayName = 'Pricing.Column.Service.Cell';

export const Header = (props: HeaderProps<Pricing.PricingItem> & Pricing.CustomProps) => {
  return (
    <div  className={styles.header}>
      <div>
        <div className={styles.payment}>Payment</div>
        <div className={styles.toggle}>
          <Switch
            active={!props.monthly}
            onClick={() => props.setMonthly(!props.monthly)} />
          <span className={styles.label}>Annual</span>
        </div>
      </div>
    </div>
  );
};

Header.displayName = 'Pricing.Column.Service.Header';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { createNamedContext } from '@utils';
import { Stripe } from './interfaces';

export type NewCardFormValue = [
  Stripe.NewCardState,
  React.Dispatch<React.SetStateAction<Stripe.NewCardState>>,
];

export type CardFormValue = [
  StripeCardElementChangeEvent,
  React.Dispatch<React.SetStateAction<StripeCardElementChangeEvent>>,
];

export type SubmitNewCardForm = () => Promise<unknown>;

type NewCardFormContextValue = {
  card: CardFormValue;
  form: NewCardFormValue;
  state: {
    error: string;
    loading: boolean;
  };
  submit: SubmitNewCardForm;
};

export const NewStripeCardFormContext = createNamedContext<NewCardFormContextValue>(undefined, 'NewCardFormContext');
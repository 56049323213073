import { useContext, useEffect, useState } from 'react';
import { ProjectRateOnboardingContext } from '@containers/RateOnboarding';
import ActivityIndicator from 'components/ActivityIndicator';
import {
  Back,
  NextButton,
  WizardHeader,
  WizardNavigation,
} from 'components/Wizard';
import SetRate from './SetRate';
import { Screen } from './Wizard';

export const ProjectRateScreen = () => {
  const ctx = useContext(ProjectRateOnboardingContext);
  const [loading, setLoading]  = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    ctx.refreshSuggestedRate()
    .then(_ => {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    });
  }, []);

  const renderHeader = () => {
    const title = `Project hourly rate`;
    const subtitle = `Based on your industry and years of experience, we recommend charging between $${ctx.minimumHourlyRate} and $${ctx.maximumHourlyRate} to maximize your project offers from clients. If you'd like to edit your rate, you can do so in the settings page of your account.`;

    return  (
      <WizardHeader
        title={title}
        subtitle={subtitle} />
    );
  };

  const renderBody = () => (
    <>
      <SetRate
        hourlyRate={ctx.hourlyRate}
        onDragEnd={ctx.onHourlyRateChange}
        onPositionChange={ctx.onHourlyRateSlide}
        maximumRate={ctx.maximumHourlyRate}
        minimumRate={ctx.minimumHourlyRate} />
    </>
  );

  const renderBack = () => {
    if (ctx.step === 2)  {
      return (
        <Back
          onClick={ctx.back} />
      );
    }

    return <div />;
  };

  const handleCompletion = () => {
    ctx.handleSaveRate()
      .then(ctx.next);
  };

  const renderNext = () => (
    <NextButton
      disabled={false}
      onClick={handleCompletion} />
  );

  const renderNavigation = () =>  (
    <WizardNavigation
      renderBack={renderBack}
      renderNext={renderNext} />
  );

  if (loading) {
    return <ActivityIndicator show={true} />;
  }

  return (
    <Screen
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderNavigation={renderNavigation} />
  );
};

export default ProjectRateScreen;
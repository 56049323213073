import { useCallback, useState } from 'react';
import * as enums from '@enums';
import { SelectingConfirmation, SelectingDay, SelectingTime } from '@screens/SchedulingStepper';
import { useStepper } from '@utils';
import { useConfirmProjectCall } from '@utils/api';
import { CalendarRange } from '@/types';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { useAvailableTimes } from './hooks';
import { ConfirmationStepperContext } from './Context';
import { ConfirmationStepperContextValue } from './interfaces';

type Props = {
  recordingConsentStatusId: enums.ApprovalStatus;
  scheduleeId: number;
  schedulerId: number;
} & ICallId
  & IProjectId;

const ProjectCallConfirmationStepper = (props: Props) => {
  const [day, setDay] = useState<Date>(null);
  const {
    data: availability,
    isLoading,
  } = useAvailableTimes({
    callId: props.callId,
    scheduleeId: props.scheduleeId,
    schedulerId: props.schedulerId,
  });

  const { mutateAsync } = useConfirmProjectCall({
    callId: props.callId,
  });

  const handleSubmit = useCallback((slot: CalendarRange<Date>) => {
    return mutateAsync({
      recordingConsentStatusId: props.recordingConsentStatusId,
      slot,
    });
  }, [
    mutateAsync,
    props.recordingConsentStatusId,
  ]);

  const [Screen, actions] = useStepper([
    SelectingDay,
    SelectingTime,
    SelectingConfirmation,
  ]);

  if (isLoading) return <ActivityIndicator show />;

  const context: ConfirmationStepperContextValue = {
    back: actions.back,
    callId: props.callId,
    day,
    events: availability,
    next: actions.next,
    setDay,
    onSubmit: handleSubmit,
  };

  return (
    <ConfirmationStepperContext.Provider value={context}>
      <Screen />
    </ConfirmationStepperContext.Provider>
  );
};

export { ProjectCallConfirmationStepper };
export default ProjectCallConfirmationStepper;
import { useCallback } from 'react';
import { useDeleteFile } from './hooks';
import { useSelectObject, useSelectFile } from '@containers/Store';
import { DeleteFileContext } from './Context';

type Props =
  IWorkspaceFileId &
  ChildrenProps;

export const DeleteFileContainer = (props: Props) => {

  const file = useSelectFile(props.fileId)
  const object = useSelectObject(file.objectId);
  const deleteFile = useDeleteFile();

  const handleDelete = useCallback(() => {
    return deleteFile({
      fileId: props.fileId,
      objectId: object.id,
      workspaceId: object.workspaceId,
    });
  }, [
    deleteFile,
    props.fileId,
    object.id,
    object.workspaceId,
  ]);

  return (
    <DeleteFileContext.Provider value={handleDelete}>
      {props.children}
    </DeleteFileContext.Provider>
  );
};

export default DeleteFileContainer;
import { useMemo } from 'react';
import { format } from 'date-fns';
import { useProjectAnonymity, useProjectSurveyRespondent, useProjectState } from '@containers/GroupProject/hooks';
import { ThumbsUpRating } from '@presentation';
import { RatingSelector } from './RatingSelector';
import styles from './style/Header.css';
import RespondentProjectVersion from './Version';
import { RatingPromptContainer } from './RatingPrompt';

export const AnswersHeader = () => {

  const {
    response,
    user,
    versions,
  } = useProjectSurveyRespondent();

  const { project } = useProjectState();

  const completedOn = useMemo(() => {
    return format(new Date(response.completedOn), 'MMM do yyyy');
  }, [response.completedOn]);

  return (
    <div className={styles.responsesHeader}>
      <div className={styles.versionContainer}>
        <div className={styles.sectionTitle}>Responses</div>
        {versions.length > 1 &&
          <RespondentProjectVersion className={styles.version} />
        }
      </div>
      <div className={styles.completedOn}>
        <span className={styles.label}>Completed On:</span>
        {completedOn}
      </div>
      <div className={styles.rating}>
        <div className={styles.label}>Rating:</div>
        <RatingSelector rating={response.rating} />
      </div>

    </div>
  );
};
import { useContext } from 'react';
import { ProjectStateContext } from '../Context';

export const useProjectState = () => {
  const project = useContext(ProjectStateContext);

  return project;
};

export const useProjectOrLatestChild = () => {
  const state = useProjectState();

  return state.project || state.latestProject;
};

export default useProjectState;
import { useMemo } from 'react';
import * as consts from '@consts';
import FolderTabFiles from './FolderTabFiles';
import FolderTabFolders from './FolderTabFolders';
import FolderTabProjects from './FolderTabProjects';
import FolderTabDetails from './FolderTabDetails';
import { FolderRouteKey, FolderSceneMap } from './interfaces';
import FolderTabView from './FolderTabView';

export const FolderTabViewContainer = () => {

  const map: FolderSceneMap = useMemo(() => ({
    [FolderRouteKey.Projects]: {
      component: FolderTabProjects,
      path: consts.path.WorkspaceFolderTabs.Projects,
      title: 'Projects',
    },
    [FolderRouteKey.Folders]: {
      component: FolderTabFolders,
      path: consts.path.WorkspaceFolderTabs.Folders,
      title: 'Folders',
    },
    [FolderRouteKey.Files]: {
      component: FolderTabFiles,
      path: consts.path.WorkspaceFolderTabs.Files,
      title: 'Files',
    },
    [FolderRouteKey.Details]: {
      component: FolderTabDetails,
      path: consts.path.WorkspaceFolderTabs.Details,
      title: 'Details',
    },
  }), [

  ]);

  return (
    <FolderTabView
      sceneMap={map} />
  );
};

export default FolderTabViewContainer;
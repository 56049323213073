import { useFileUploadsState } from '@containers/WorkspaceFileUpload/hooks/useFileUploadsState';
import { Portal } from 'components/Portal';
import FileUpload from './FileUpload';
import InvalidFile from './InvalidFile';
import Header from './Header';
import { useRootStyles } from './hooks/useRootStyles';
import styles from './style/FileUploadsProgress.css';

export const FileUploadsProgress = () => {

  const [state] = useFileUploadsState();

  const style = useRootStyles();

  return (
    <Portal>
      <div className={styles.root} style={style}>
        <Header />
        <div className={styles.items}>
          {state.valid.map(m => <FileUpload key={m.id} fileUpload={m} />)}
          {state.invalid.map((m, i) => <InvalidFile key={i} {...m} />)}
        </div>
      </div>
    </Portal>
  );
};

export default FileUploadsProgress;
import { useContext } from 'react';
import { useHasTransientRole, useHasClientRole } from '@containers/User';
import { useSelectUser, useSelectGroup } from '@containers/Store';
import { UniversalSearchBar } from 'components/UniversalSearchBar';
import { WhitelabelMenuDisplayContext } from 'components/Whitelabel/Context';
import WorkspaceGlobalSearchBar from 'components/WorkspaceGlobalSearchBar';
import styles from './style/Navbar.css';

export const Searchbar = () => {
  const user = useSelectUser();
  const group = useSelectGroup();
  const isTransient = useHasTransientRole();
  const forWorkspace = useHasClientRole() && group.features.workspaces;
  const display = useContext(WhitelabelMenuDisplayContext);
  const canSearch = user.state.initialized && !isTransient && !display?.whitelabel;

  if (!canSearch) return null;

  return (
    <div className={styles.search}>
      <div className={styles.searchbar}>
        <SearchbarComponent workspace={forWorkspace} />
      </div>
    </div>
  );
};

type BarComponentProps = {
  workspace: boolean;
};

function SearchbarComponent({ workspace }: BarComponentProps) {
  return workspace
  ? <WorkspaceGlobalSearchBar />
  : <UniversalSearchBar />;
}

Searchbar.displayName = 'Nav.Searchbar';
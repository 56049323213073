import { useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks/useRepeatQuestionResponsesData';
import { SurveyResponsesHeader } from '@presentation';
import Chart from './ConjointAnalysis.Chart';
import Data from './ConjointAnalysis.Data';

export const ConjointAnalysisRepeatSurveyResponses = () => {
  const { question } = useRepeatQuestionResponsesData();

  return (
    <>
      <SurveyResponsesHeader
        question={question} />
      <Chart />
      <Data />
    </>
  );
};

export default ConjointAnalysisRepeatSurveyResponses;
import { useCallback } from 'react';
import { getOtherAdHocCallParticipant } from '@containers/Scheduler/utils.calls';
import { useSelectContact, useSelectAdHocCall, useSelectUser } from '@containers/Store';
import { useLock } from '@utils';
import Button from 'components/Button';
import ButtonSet from 'components/Modal/ButtonSet';
import { Prompt } from 'components/Modal/Prompt';
import { ModalProps } from './Modal';
import { useModal } from './hooks';
import styles from './style/Base.css';

type Props = {
  onConfirm: () => Promise<unknown>;
} & ICallId
  & Pick<ModalProps,
    'onClose' |
    'open'>;

export const CancelAdHocCall = ({
  callId,
  onConfirm,
  onClose,
  open,
}: Props) => {
  const call = useSelectAdHocCall(callId);
  const me = useSelectUser();
  const participantId = getOtherAdHocCallParticipant(call, me.id);
  const participant = useSelectContact(participantId);

  const [isBusy, lock] = useLock();

  const renderButtons = useCallback(() => {
    return (
      <ButtonSet>
        <Button.Destructive
          className={styles.promptBtn}
          disabled={isBusy}
          title="Cancel"
          onClick={onClose}
          variant="brick" />
        <Button.Primary
          className={styles.promptBtn}
          disabled={isBusy}
          title="Confirm"
          onClick={lock(onConfirm)}
          variant="brick" />
      </ButtonSet>
    );
  }, [
    isBusy,
    lock,
    onConfirm,
    onClose,
  ]);

  const body = `Vancery will notify ${participant.profile.firstName} that the call has been cancelled upon confirmation.`;
  const title = `Cancel Call with ${participant.profile.firstName}`;

  return (
    <Prompt
      body={body}
      footer={renderButtons()}
      header={title}
      onClose={onClose}
      visible={open} />
  );
};

export const useCancelAdHocCallModal = () => useModal(CancelAdHocCall);

export default CancelAdHocCall;
import * as api from '@api';
import { useAsyncState } from '@utils';
import * as Countries from 'components/AddressEntry/Countries';

export default function useUserGuessCountry() {
  return useAsyncState(() => {
    return api.users.geolocation.guessCountry()
      .then(result => findCountry(result.countryId));
  });
}

function findCountry(id: string) {
  const result = Countries.World.find(c => c.id === id);
  return result
    ? { id: result.id, name: result.name }
    : null;
}

export { useUserGuessCountry };
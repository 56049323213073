import { useCallback } from 'react';
import { Download } from 'react-feather';
import { useDownloader } from '@utils/hooks';
import { WaveformData } from '@/types';
import { AudioPlayer, AudioWavePlayer } from 'components/AudioPlayer';
import styles from './style/Player.css';

type Props = {
  downloadName?: string;
  src: string;
  type?: 'track' | 'wave';
  waveform?: WaveformData;
};

export const CallRecordingPlayer = ({
  downloadName,
  src,
  type = 'track',
  waveform,
}: Props) => {

  const download = useDownloader();

  const handleDownload = useCallback(() => {
    return download({
      filename: downloadName,
      url: src,
    });
  }, [download, downloadName, src]);

  const renderPlayer = useCallback(() => {
    const audio =  {
      preload: 'metadata',
      src,
      title: src,
    };

    if (type === 'track' || type  === 'wave' && !waveform) {
      return (
        <AudioPlayer
          audio={audio}
          className={styles.player} />
      );
    } else if (type === 'wave') {
      return (
        <AudioWavePlayer
          audio={audio}
          data={waveform}
          className={styles.player} />
      );
    }
  }, [
    src,
    type,
    waveform,
  ]);

  return (
    <div className={styles.root}>
      {renderPlayer()}
      {downloadName &&
        <div className={styles.download}>
          <Download onClick={handleDownload} />
        </div>
      }
    </div>
  );
};

export default CallRecordingPlayer;
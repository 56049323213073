import { useMemo } from 'react';
import {
  useSurveyFormQuestionContext,
  useSubmitAnswer,
  useSurveyStepperNavigation,
  useSurveyResponseGoBack,
} from './hooks';
import * as surveyForm from './utils';
import { SurveyFormNavigationContext } from './Context';

type Props =
  ChildrenProps;

export const SurveyFormNavigationContainer = (props: Props) => {

  const {
    answer,
    question,
  } = useSurveyFormQuestionContext();

  const submitAnswer = useSubmitAnswer();
  const goBack = useSurveyResponseGoBack();
  const navigation = useSurveyStepperNavigation(question.ordinal);

  const isAnswerValid = useMemo(
    () => surveyForm.isAnswerComplete(answer, question),
    [answer, question],
  );

  const value = {
    back: {
      hidden: !navigation.showBack,
      isError: goBack.isError,
      isLoading: goBack.isLoading,
      submit: goBack.mutateAsync as () => Promise<unknown>,
    },
    next: {
      disabled: !isAnswerValid,
      isError: submitAnswer.isError,
      isLoading: submitAnswer.isLoading,
      submit: submitAnswer.mutateAsync as () => Promise<unknown>,
    },
    showComplete: navigation.showComplete,
  };

  return (
    <SurveyFormNavigationContext.Provider value={value}>
      {props.children}
    </SurveyFormNavigationContext.Provider>
  );
};

export default SurveyFormNavigationContainer;
import { useCallback } from 'react';
import { Download as DownloadIcon } from 'react-feather';
import { Tooltip } from '@presentation';
import { useWorkspaceFileState, useFilePreviewData, useFilePreviewLoading, WorkbookContainer, useDownloadFile } from '@containers';
import { Card, Divider } from '@presentation/Main';
import { Pdf } from 'components/Pdf';
import { FileIcon } from 'components/icons';
import Workbook from './Workbook';
import ImagePreview from './ImagePreview';
import PreviewInProgress from './InProgress';
import PreviewUnavailable from './PreviewUnavailable';
import styles from './style/FilePreviewCard.css';

function Download() {
  const download = useDownloadFile();

  return (
    <Tooltip title="Download">
      <div>
        <DownloadIcon
          className={styles.download}
          onClick={download} />
      </div>
    </Tooltip>
  );
}

export const FilePreviewCard = () => {

  const { file } = useWorkspaceFileState();
  const data = useFilePreviewData();
  const loading = useFilePreviewLoading();

  const renderBody = useCallback(() => {
    if (loading) {
      return null;
    } else if (data.inProgress) {
      return <PreviewInProgress />;
    } else if (data.workbookDataUrl) {
      return (
        <WorkbookContainer url={data.workbookDataUrl}>
          <Workbook />
        </WorkbookContainer>
      );
    } else if (data.pdfPreviewUrl) {
      return (
        <Pdf url={data.pdfPreviewUrl} />
      );
    } else if (data.imgPreviewUrl) {
      return (
        <ImagePreview url={data.imgPreviewUrl} />
      );
    } else {
      return <PreviewUnavailable />;
    }
  }, [
    data,
    loading,
  ]);

  return (
    <Card className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>
          <FileIcon
            className={styles.icon}
            extension={file.extension} />
          <div className={styles.name}>{file.name}</div>
        </div>
        <Download />
      </div>
      <Divider />
      <div className={styles.body}>
        {renderBody()}
      </div>
    </Card>
  );
};

export default FilePreviewCard;
import { useCallback, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as api from '@api';
import { path } from '@consts';
import { AccountProfileContactContext } from '@containers/AccountProfile/Context';
import { ContentFeedStateContainer, useContentFeedState, FetchContentFeedContext, FetchMoreContentFeedContext } from '@containers/ContentFeed';
import { Contact } from '@/types';
import { AuthSignupGuard } from '$website/components/AuthSignupGuard';
import { useMatchUserIdFromUrl } from './hooks';

type Props = {
  children: React.ReactNode;
};

export const StateDependentContainer = (props: Props) => {
  const userId = useMatchUserIdFromUrl();
  const [contact, setContact] = useState<Pick<Contact, 'id' | 'career' | 'profile'>>(null);
  const index = useRef<number>(0);

  const [state, dispatch] = useContentFeedState();

  const fetch = useCallback(() => {
    dispatch({
      type: 'feed-refresh-started',
    });

    const size = 10;

    return api.profiles.activity.fetchItems({
      index: index.current,
      size,
      userId,
    })
    .then(res => {
      dispatch({
        /* @ts-ignore */
        items: res.items,
        morePostsToFetch: res.items.length === size,
        type: 'feed-refreshed',
      });

      setContact(res.user);
    });
  }, [
    dispatch,
    userId,
  ]);

  const fetchMore = useCallback(() => {

    if (!state.morePostsToFetch) return;

    dispatch({
      type: 'fetch-more-started',
    });

    const size = 10;

    index.current = index.current + 1;

    return api.profiles.activity.fetchItems({
      index: index.current,
      size,
      userId,
    })
    .then(res => {
      dispatch({
        /* @ts-ignore */
        items: res.items,
        morePostsToFetch: res.items.length === size,
        type: 'more-posts-fetched',
      });
    });
  }, [
    dispatch,
    state.morePostsToFetch,
    userId,
  ]);

  if (!userId) {
    return (
      <Redirect to={path.Profile.NotFound} />
    );
  }

  return (
    <FetchContentFeedContext.Provider value={fetch}>
      <FetchMoreContentFeedContext.Provider value={fetchMore}>
        <AccountProfileContactContext.Provider value={contact}>
          {props.children}
        </AccountProfileContactContext.Provider>
      </FetchMoreContentFeedContext.Provider>
    </FetchContentFeedContext.Provider>
  );
};

const UserProfilePostsContainer = (props: Props) => {
  // <AuthSignupGuard>
  //   <ContentFeedStateContainer>
  //     <StateDependentContainer {...props} />
  //   </ContentFeedStateContainer>
  // </AuthSignupGuard>

  return (
    <ContentFeedStateContainer>
      <StateDependentContainer {...props} />
    </ContentFeedStateContainer>
  );
};

export { UserProfilePostsContainer };
export default UserProfilePostsContainer;
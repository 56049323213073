import { useCallback, useContext } from 'react';
import { phone } from '@utils';
import { ButtonActivityIndicator } from '@presentation';
import { useLeadFormContext } from '$website/containers/LeadForm/Context';
import { PricingModalContext } from '$website/containers/Pricing/Context';
import { LeadFormFields } from '$website/components/LeadForm';
import { Modal } from 'components/Modal/Modal';
import { Header } from 'components/Modal/Header';
import { PlanModalSubmitted } from './Plan.Modal.Submitted';
import styles from './style/Plan.Modal.css';

type Props = unknown;

export const PlanModalForm = (props: Props) => {
  const { isSubmitted } = useLeadFormContext();
  const [open, toggle] = useContext(PricingModalContext);

  return (
    <Modal
      className={styles.root}
      hideCloseIcon={isSubmitted}
      open={open}
      onClose={toggle}>
      <div className={styles.wrap}>
        {!isSubmitted
          ? <Form />
          : <PlanModalSubmitted />}
      </div>
    </Modal>
  );
};

const Form = () => {
  const {
    errors,
    submitForm,
    isSubmitting,
    setFieldValue,
    values,
  } = useLeadFormContext();

  const handleSubmit = useCallback(() => {
    if (!isSubmitting) {
      return submitForm();
    }
  }, [isSubmitting, submitForm]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'tel'
        ? phone.format(phone.parseDigits(e.target.value))
        : e.target.value;

    setFieldValue(e.target.name, value);
  }, [setFieldValue]);

  return (
    <>
      <Header>Get Started</Header>
      <LeadFormFields
        errors={errors}
        onChange={handleChange}
        values={values} />
      <ButtonActivityIndicator
        className={styles.btn}
        color="affirmative"
        loading={isSubmitting}
        onClick={handleSubmit}>
        Submit
      </ButtonActivityIndicator>
    </>
  );
};

PlanModalForm.displayName = 'Plan.Modal.Form';
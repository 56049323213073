import { useCallback, useMemo } from 'react';
import { ComputedDatum, BarTooltipProps } from '@nivo/bar';
import { MatrixSliderChartTooltip } from '@presentation';
import { formatNumber } from '@utils';
import BarChart from './BarChart';
import { MatrixSliderItem } from './interfaces';

type Props = {
  items: MatrixSliderItem[];
  onClick: (rowId: number) => void;
  reversed: boolean;
};

export const ResponseMatrixSliderChart = (props: Props) => {

  const renderTooltip = useCallback((item: BarTooltipProps<MatrixSliderItem>) => {
    const data = item.data;

    return (
      <MatrixSliderChartTooltip
        avg={data.displayAvg}
        row={data.name} />
    );
  }, []);

  const formatBottomAxisLabel = useCallback((value: string) => {
    return Number.isInteger(+value)
      ? formatNumber(+value, 1)
      : '';
  }, []);

  const formatLabel = useCallback((item: ComputedDatum<MatrixSliderItem>) => {
    const data = item.data;
    return data.displayAvg;
  }, []);

  const keys = useMemo(() => props.items.map(x => x.id.toString()), [props.items]);

  return (
    <BarChart<MatrixSliderItem>
      data={props.items}
      keys={keys}
      reverse={props.reversed}
      axisBottom={{
        format: formatBottomAxisLabel,
        legend: 'Average',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      label={formatLabel}
      margin={{ top: 0, right: 25, bottom: 50, left: 25 }}
      onClick={props.onClick}
      tooltip={renderTooltip} />
  );
};

export default ResponseMatrixSliderChart;
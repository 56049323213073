import { usePipelineActions } from '@containers/GroupProject/hooks/usePipelineActions';
import { useLock } from '@utils';
import { Button } from 'components/Button';
import styles from '../style/ProjectPipelineItem.css';

const ButtonSet = () => {

  const {
    canRequestCall,
    canRemoveUser,
    canHideUser,
    canViewSurvey,
    canSelectTime,
    canSendSurveyInvite,

    onHideUser,
    onRemoveUser,
    onSubmitCallRequest,

    projectSurveyLocation,
    selectingLocation,
  } = usePipelineActions();

  const [_, lock] = useLock();

  return (
    <div className={styles.btns}>
      {canRequestCall &&
        <Button
          color="primary"
          onClick={lock(onSubmitCallRequest)}
          title="Request Call" />}
      {canSelectTime &&
        <Button
          color="secondary"
          to={selectingLocation}
          title="Select Time" />}
      {canSendSurveyInvite &&
        <Button
          color="primary"
          onClick={lock(onSubmitCallRequest)}
          title="Invite" />}
      {canViewSurvey &&
        <Button
          color="primary"
          to={projectSurveyLocation}
          title="View Response" />}
      {canRemoveUser &&
        <Button
          color="destructive"
          onClick={onRemoveUser}
          title="Reject" />}
      {canHideUser &&
        <Button
          color="destructive"
          onClick={onHideUser}
          title="Hide" />}
    </div>
  );
};

export default ButtonSet;
import { useCallback } from 'react';
import { useFileUploadEffects } from './useFileUploadEffects';
import { useFileUploadsState,  } from './useFileUploadsState';

export const useAbortUpload = () => {
  const [state] = useFileUploadsState();
  const effects = useFileUploadEffects();

  const abortUpload = useCallback((fileUploadId: number) => {
    const upload = state.uploads[fileUploadId];
    const fileUpload = state.valid.find(f => f.id === fileUploadId);

    if (upload) {
      upload.abort();
    }

    return effects.abort({
      fileUploadId,
      workspaceId: fileUpload.data.workspaceId,
    });
  }, [
    effects,
    state.uploads,
    state.valid,
  ]);

  return abortUpload;
};

export default useAbortUpload;
import { useCallback } from 'react';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks/useProjectState';
import { ViewUserResponseItem } from '@containers/SurveyResponses/interfaces';
import * as mixpanel from '@services/mixpanel';
import { useActiveQuestion } from './useActiveQuestion';

export const useTrackViewResponse = () => {
  const project = useProjectOrLatestChild();
  const question = useActiveQuestion();

  const handleViewResponse = useCallback((user: ViewUserResponseItem) => () => {
    mixpanel.event.project.userSurveyResponses({
      project: {
        id: project.id,
        name: project.name,
      },
      user,
      question: {
        ordinal: question.ordinal,
      },
    });
  }, [
    project.id,
    project.name,
    question.ordinal,
  ]);

  return handleViewResponse;
};

export default useTrackViewResponse;
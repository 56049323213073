import { MessageCircle } from 'react-feather';
import Notes from '@mui/icons-material/Notes';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import { path } from '@consts';
import { useContentPublisherContext } from '@containers/PostCreation/hooks/useContentPublisher';
import { cx } from '@utils';
import { Button } from 'components/Button';
import MyAvatar from 'components/UserAvatar/MyAvatar';
import styles from './style/StartPost.css';

type Props = {
  className?: string;
};

export const StartPost = (props: Props) => {
  const ctx = useContentPublisherContext();

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.avatar}>
            <MyAvatar
              size={48} />
          </div>
          <div className={styles.launch}>
            <button
              className={styles.start}
              onClick={ctx.text.toggle}>
              Share your expertise
            </button>
            <div className={styles.btns}>
              <Button
                className={styles.text}
                onClick={ctx.text.toggle}
                variant="text">
                <MessageCircle className={styles.icon} />
                Post
              </Button>
              <Button
                className={styles.video}
                onClick={ctx.video.toggle}
                variant="text">
                <PlayArrowRounded
                  className={styles.icon}
                  fontSize="small" />
                Video
              </Button>
              <Button
                className={styles.article}
                to={path.Posts.Articles.New}
                variant="text">
                <Notes
                  className={styles.icon}
                  fontSize="small" />
                Write Article
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
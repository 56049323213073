import { useCallback } from 'react';
import SurveySectionsBuilder from './SectionsBuilder';
import SurveyLogicSectionsBuilder from './LogicSectionsBuilder';
import SurveyTaggingSectionsBuilder from './TaggingSectionsBuilder';
import SurveyBuilder from './SurveyBuilder';
import { SurveyQuotasBuilder } from './QuotasBuilder';
import SurveyTab from './SurveyTab';

type Props = {
  tabs?: SurveyTab[];
};

const defaultTabs = [
  SurveyTab.Questions,
  SurveyTab.Logic,
  SurveyTab.Tagging,
  SurveyTab.Quotas,
];

export const ProjectSurveyBuilder = ({
  tabs = defaultTabs,
}: Props) => {

  const renderTab = useCallback((tab: SurveyTab) => {
    switch (tab) {
      case SurveyTab.Logic:
        return (
          <SurveyLogicSectionsBuilder />
        );

      case SurveyTab.Tagging:
        return (
          <SurveyTaggingSectionsBuilder />
        );

      case SurveyTab.Questions:
        return (
          <SurveySectionsBuilder
            includeTagging={tabs.includes(SurveyTab.Tagging)} />
        );

      case SurveyTab.Quotas:
        return (
          <SurveyQuotasBuilder />
        );
    }

  }, [tabs]);

  return (
    <SurveyBuilder
      tabs={tabs}
      renderTab={renderTab} />
  );
};

export default ProjectSurveyBuilder;
import styles from './style/Header.css';

type Props =
  ChildrenProps;

export const WorkspaceHeader = (props: Props) => {

  return (
    <div className={styles.root}>
      {props.children}
    </div>
  );
};

export default WorkspaceHeader;
import { createContext, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import * as enums from '@enums';
import * as mixpanel from '@services/mixpanel';
import ActivityIndicator from 'components/ActivityIndicator';
import { VICS, VICSMap } from '@/types';
import useSuggestedRate from './useSuggestedRate';
import { RateOnboardingContextValue, RateOnboardingProps } from './interfaces';

const RateOnboardingContext = createContext<RateOnboardingContextValue>({
  back:                       () => {},
  next:                       () => {},
  hourlyRate:                 0,
  minimumHourlyRate:          0,
  maximumHourlyRate:          0,
  onCompletion:               () => {},
  onHourlyRateSlide:          (position: number) => {},
  onHourlyRateChange:         () => {},
  onSeniorityUpdated:         (value: enums.Seniority) => {},
  onVICSSelection:            (type: enums.VICSType) => (item: VICS) => {},
  sectors:                    [],
  seniority:                  null,
  subIndustries:              [],
  suggestedHourlyRate:        0,
  industries:                 [],
  vics:                       { ids: [] },
});

const mapState = (state: Store.State) => ({
  user: state.user,
});

const DefaultHourlyRate = 250;

const RateOnboarding = (props: RateOnboardingProps) => {
  const { user } = useSelector(mapState);
  const dispatch = useDispatch();

  const [sectors, setSectors] = useState<VICS[]>([]);
  const [industries, setIndustries] = useState<VICS[]>([]);
  const [subIndustries, setSubIndustries] = useState<VICS[]>([]);
  const [vics, setVics] = useState<VICSMap>({ ids: [] });
  const [seniority, setSeniority] = useState<enums.Seniority>();
  const [suggestedHourlyRate, setSuggestedHourlyRate] = useState<number>(user.settings.hourlyRate || DefaultHourlyRate);
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleRateDragEnd,
    hourlyRate,
    minimumHourlyRate,
    maximumHourlyRate,
    updateHourlyRate,
  } = useSuggestedRate(suggestedHourlyRate, seniority);

  useEffect(() => {
    fetchUserVics();
  }, []);

  const fetchHourlyRate = useCallback(() => {
    if (sectors.length && seniority) {
      api.profiles.suggestHourlyRate({
        seniority,
      })
      .then(result => {
        setSuggestedHourlyRate(result.value);
      });
    }
  }, [sectors, seniority]);

  useEffect(() => {
    fetchHourlyRate();
  }, [fetchHourlyRate]);

  const fetchUserVics = () => {
    setLoading(true);
    api.profiles.fetchVICS({ userId: user.id })
    .then(({ vics }) => {
      setSectors(vics.sectors);
      setSubIndustries(vics.subindustries);
      setIndustries(vics.industries);
      setVics(vics);
      setLoading(false);
    });
  };

  const handleCompletion = () => {
    if (saving) return;

    mixpanel.event.onboarding.completeHourlyRate({
      value: hourlyRate,
      suggested: suggestedHourlyRate,
    });

    const toSave = {
      hourlyRate,
      seniority,
      vics: vics.ids.map(m => ({
        id: m,
        name: vics[m].name,
        typeId: vics[m].typeId,
        isUserCreated: vics[m].isUserCreated || false,
      })),
    };

    setSaving(true);

    api.profiles.completeOnboarding(toSave)
    .then(() => {
      dispatch(actions.userInfoChange({
        profile: { hourlyRate },
        settings: {
          ...user.settings,
          hourlyRate,
        },
      }));
    })
    .then(() => {
      if (props.onCompletion) {
        props.onCompletion();
      }
    })
    .catch(_ => setSaving(false));
  };

  const isSelected = (item: VICS) => {
    return !!vics[item.id];
  };

  const getUpdatedSelectionsState = (item: VICS, type: enums.VICSType) => {
    let selections = [];
    if (type === enums.VICSType.Sector) {
      selections = sectors;
    } else if (type === enums.VICSType.Industry) {
      selections = industries;
    } else if (type === enums.VICSType.SubIndustry) {
      selections = subIndustries;
    }

    const items = isSelected(item)
      ? selections.filter(x => x.id !== item.id)
      : selections.concat(item);
    return items;
  };

  const getUpdatedVICSState = (item: VICS) => {
    if (isSelected(item)) {
      const { [item.id]: _, ids, ...rest } = vics;

      return {
        ...rest,
        ids: ids.filter(id => id !== item.id),
      };
    } else {
      return {
        ...vics,
        [item.id]: item,
        ids: vics.ids.concat(item.id),
      };
    }
  };

  const handleVICSSelected = (type: enums.VICSType) => (item: VICS) => {
    const updatedSelections = getUpdatedSelectionsState(item, type);

    if (isSelected(item)) {
      mixpanel.event.onboarding.removeVICS(item);
    } else {
      mixpanel.event.onboarding.addVICS(item);
    }

    if (type === enums.VICSType.Sector) {
      setSectors(updatedSelections);
    } else if (type === enums.VICSType.Industry) {
      setIndustries(updatedSelections);
    } else if (type === enums.VICSType.SubIndustry) {
      setSubIndustries(updatedSelections);
    }

    setVics(getUpdatedVICSState(item));
  };

  const context: RateOnboardingContextValue = {
    back: props.back,
    next: props.next,
    hourlyRate,
    minimumHourlyRate,
    maximumHourlyRate,
    onCompletion: handleCompletion,
    onHourlyRateChange: handleRateDragEnd,
    onHourlyRateSlide: updateHourlyRate,
    onSeniorityUpdated: setSeniority,
    onVICSSelection: handleVICSSelected,
    suggestedHourlyRate,
    sectors,
    seniority,
    industries,
    subIndustries,
    vics,
  };

  if (loading) {
    return <ActivityIndicator show={true} />;
  }

  return (
    <RateOnboardingContext.Provider value={context}>
      {props.children}
    </RateOnboardingContext.Provider>
  );
};

export * from './Project';
export { useSuggestedRate };
export { RateOnboarding };
export { RateOnboardingContext };
export default RateOnboarding;
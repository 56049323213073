import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { useMutation, UseMutationOptions } from 'react-query';
import * as api from '@api/scheduling.ad-hoc';
import * as Scheduling from '@api/interfaces/scheduling.ad-hoc';
import * as actions from '@store/actions';

type Data = Scheduling.ProposeTimes.Response;
type Variables = Omit<Scheduling.ProposeTimes.Request, 'callId'>;

export function useProposeAdHocCallTimes(
  data: ICallId,
  options?: UseMutationOptions<Data, AxiosResponse, Variables>
) {
  const dispatch = useDispatch();

  const onSuccess = useCallback((res: Data, v: Variables, ctx) => {
    dispatch(actions.adHocCallUpdated({
      call: res.call,
    }));

    options?.onSuccess(res, v, ctx);
  }, [dispatch, options?.onSuccess]);

  return useMutation(keyAdHocCallProposeTimes(data.callId), params => {

    return api.adHocCall.proposeTimes({
      callId: data.callId,
      new: params.new,
      removed: params.removed,
    });

  }, {
    ...options,
    onSuccess,
  });
}

export function keyAdHocCallProposeTimes(callId: number) {
  return ['ad-hoc-call-propose-time', callId];
}

export default useProposeAdHocCallTimes;
import { useMemo, useState } from 'react';
import { useStepper } from '@utils';
import { useFetchContactInfo } from '@utils/api';
import { useCallRequestRecipient } from '@containers/RequestCall/hooks';
import { Modal, ModalProps } from 'components/Modal/Modal';
import { adHocScreens } from './AdHoc.Screens';
import { AdHocCallRequestContext as Context } from './Context';
import styles from './style/AdHoc.css';

type Props =
  Pick<ModalProps,
    'open' |
    'onClose'>;

export const RequestAdHocCall = (props: Props) => {

  const [Screen, actions] = useStepper(adHocScreens);
  const recipient = useCallRequestRecipient();

  const notesState = useState<string>('');
  const subjectState = useState<string>('');

  const contactQuery = useFetchContactInfo({
    properties: ['profile'],
    userId: recipient.id,
  }, {
    keepPreviousData: true,
  });

  const loading = contactQuery.isLoading;

  const rate = useMemo(() => contactQuery.data?.profile?.hourlyRate || 250, [contactQuery.data]);

  if (loading) return null;

  return (
    <Modal
      classes={{
        root: styles.modalRoot,
        wrap: styles.modalWrap,
      }}
      open={props.open}
      onClose={props.onClose}>
      <Context.Profile.Provider value={contactQuery.data?.profile}>
        <Context.Notes.Provider value={notesState}>
          <Context.Rate.Provider value={rate}>
            <Context.Actions.Provider value={actions}>
              <Context.Subject.Provider value={subjectState}>
                <Screen />
              </Context.Subject.Provider>
            </Context.Actions.Provider>
          </Context.Rate.Provider>
        </Context.Notes.Provider>
      </Context.Profile.Provider>
    </Modal>
  );
};

export default RequestAdHocCall;
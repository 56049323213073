import { useCallback, useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { ResponseLengthModal } from 'components/Modal/ResponseLength';
import { useSurveyResponseGoBack, useSubmitAnswer, useSurveyStepperNavigation, useSurveyFormQuestionContext, useResponseLengthModal } from './hooks';
import { SurveyFormNavigationContext } from './Context';

type Props =
  ChildrenProps;

export const LongTextResponseNavigationContainer = (props: Props) => {

  const {
    answer,
    question,
  } = useSurveyFormQuestionContext<SurveyQuestionType.LongTextResponse>();

  const {
    close: closeModal,
    open: openModal,
    shouldOpen,
    show: showModal,
  } = useResponseLengthModal();

  const submitAnswer = useSubmitAnswer();
  const goBack = useSurveyResponseGoBack();
  const navigation = useSurveyStepperNavigation(question.ordinal);

  const shouldOpenModal = useCallback(() => {
    return shouldOpen(question , answer);
  }, [question, answer, shouldOpen]);

  const handleModalSubmit = useCallback(() => {
    submitAnswer.mutateAsync();
    closeModal();
  }, [
    answer,
    closeModal,
    submitAnswer.mutateAsync,
  ]);

  const next = useCallback(() => {
    if (shouldOpenModal()) {
      return Promise.resolve(openModal());
    } else {
      return submitAnswer.mutateAsync();
    }
  }, [
    answer,
    openModal,
    shouldOpenModal,
    submitAnswer.mutateAsync,
  ]);

  const isAnswerValid = useMemo(
    () => !!answer?.value,
    [answer],
  );

  const value = {
    back: {
      hidden: !navigation.showBack,
      isError: goBack.isError,
      isLoading: goBack.isLoading,
      submit: goBack.mutateAsync as () => Promise<unknown>,
    },
    next: {
      disabled: !isAnswerValid,
      isError: submitAnswer.isError,
      isLoading: submitAnswer.isLoading,
      submit: next,
    },
    showComplete: navigation.showComplete,
  };

  return (
    <>
      <SurveyFormNavigationContext.Provider value={value}>
        {props.children}
      </SurveyFormNavigationContext.Provider>
      {showModal &&
        <ResponseLengthModal
          onClose={closeModal}
          onSubmit={handleModalSubmit} />
      }
    </>
  );
};

export default LongTextResponseNavigationContainer;
import { useEffect } from 'react';
import { useBannerContext } from '@containers/Banner';
import AppBanner from './Banner';

type Props = {
  onToggle: () => unknown;
};

export const LoggedInAppBanner = ({ onToggle }: Props) => {
  const ctx = useBannerContext();

  useEffect(() => {
    onToggle();
  }, [ctx.mobileAppVisible]);

  if (!ctx.mobileAppVisible) {
    return null;
  }

  return (
    <AppBanner
      onDismiss={ctx.dismissMobileApp} />
  );
};

export { AppBanner };
export default LoggedInAppBanner;
import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import * as enums from '@enums';
import * as mixpanel from '@services/mixpanel';
import * as utils from './utils';

export const useSuggestedRate = (suggestedHourlyRate: number, seniority: enums.Seniority) => {
  const [hourlyRate, setHourlyRate] = useState<number>(suggestedHourlyRate);

  useEffect(() => {
    setHourlyRate(suggestedHourlyRate);
  }, [suggestedHourlyRate]);

  const mpTimeout = useRef<ReturnType<typeof setTimeout>>(null);

  const minRate = useMemo(() => {
    return utils.calculateMinSliderRate(suggestedHourlyRate);
  }, [suggestedHourlyRate]);

  const maxRate = useMemo(() => {
    return utils.calculateMaxSliderRate(suggestedHourlyRate, seniority);
  }, [suggestedHourlyRate, seniority]);

  const handleRateDragEnd = useCallback(() => {
    if (mpTimeout.current) {
      clearTimeout(mpTimeout.current);
      mpTimeout.current = null;
    }
    mpTimeout.current = setTimeout(() => {
      mixpanel.event.onboarding.selectHourlyRate({
        value: hourlyRate,
      });
    }, 500);
  }, [hourlyRate]);

  return {
    handleRateDragEnd,
    hourlyRate,
    minimumHourlyRate: minRate,
    maximumHourlyRate: maxRate,
    updateHourlyRate: setHourlyRate,
  };
};

export default useSuggestedRate;
import { SurveyQuestionType } from '@enums';
import { MatrixGridQuestion } from '@/types';
import { Validation } from '../interfaces';
import { assertValidOptions } from './validation.options';
import { assertValidRows } from './validation.rows';
import { assertHasValue, returnAssertFailure } from './validation.base';

export const DefaultColumns = 5;
export const MaxColumns = 13;
export const MinColumns = 2;

export const DefaultRows = 5;
export const MaxRows = 30;
export const MinRows = 1;

export const DefaultSettings: MatrixGridQuestion.Settings = {
  includeNotApplicable: false,
  randomization: false,
};

export function validateQuestion(question: MatrixGridQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.MatrixGrid> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinColumns, MaxColumns);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  const hasValidRows = assertValidRows(question.matrixRows, MinRows, MaxRows);
  if (hasValidRows.success === false) {
    return returnAssertFailure(hasValidRows, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}

import connections from 'static/images/website/connection-graphic.png';
import connectionsMobile from 'static/images/website/connection-graphic-mobile.png';
import Section from './Section';
import styles from './style/FindAProfessional.css';

const FindAProfessional = () => {

  const headline = `Find the best professional to answer your question`;
  const copy = `Vancery's Recommendation Engine filters through the experiences and backgrounds of tens of thousands of professionals to rank the best people for any given query.`;

  return (
    <Section>
      <div className={styles.root}>
        <div>
          <div className={styles.headline}>{headline}</div>
          <div className={styles.copy}>{copy}</div>
        </div>
        <div className={styles.imgContainer}>
          <img
            alt='Vancery Connections'
            className={styles.img}
            src={connections} />
          <img
            alt='Vancery Connections'
            className={styles.imgMobile}
            src={connectionsMobile} />
        </div>
      </div>
    </Section>
  );
};

export default FindAProfessional;
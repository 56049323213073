import { useContext, useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { ProjectResponseFilterContext } from '@containers/GroupProject/Context';
import { validUserFilterMethods } from '@containers/GroupProject/utils/survey-filtering';

export const useValidFilterQuestions = () => {
  const filterContext = useContext(ProjectResponseFilterContext);

  return useMemo(() => {
    if (!filterContext?.questionData) {
      return [];
    }

    const validQuestionTypes =
      Object.keys(validUserFilterMethods)
        .map(q => +q as SurveyQuestionType)
        .filter(k => validUserFilterMethods[k])
    ;

    return filterContext.questionData
      .filter(q => validQuestionTypes.includes(q.typeId));
  }, [
    filterContext?.questionData,
  ]);

};
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Token } from '@stripe/stripe-js';
import * as api from '@api';
import { PaymentMethod } from '@enums';
import * as actions from '@store/actions';

type UserNewPaymentParams = {
  token: Token;
  userId: number;
};

type UserNewPaymentResult = {
  error?: string;
  success?: boolean;
};

type UserNewPaymentMethod =
    (data: UserNewPaymentParams) => Promise<UserNewPaymentResult>;

export function useUserNewPaymentMethod() {
  const dispatch = useDispatch();

  const save: UserNewPaymentMethod = useCallback(({ token, userId }) => {
    return api.payment.user.newMethod({ token, userId })
    .then(result => {
      if (!result.success) {
        return {
          error: result.message ? result.message : 'An error occured when trying to add this payment method.',
        };
      }

      const paymentMethod = {
        type: token.type === 'bank_account' ? PaymentMethod.BankAccount : PaymentMethod.Card,
        verified: token.type !== 'bank_account',
      };

      dispatch(actions.userInfoChange({ paymentMethod }));

      return { success: true };
    })
    .catch(() => ({ error: 'An error occured when trying to add this payment method.' }));
  }, [dispatch]);

  return save;
}

type EnterpriseNewPaymentParams = {
  token: Token;
  groupId: number;
};

type EnterpriseNewPaymentResult = {
  error?: string;
  success?: boolean;
};

type EnterpriseNewPaymentMethod =
    (data: EnterpriseNewPaymentParams) => Promise<EnterpriseNewPaymentResult>;

export function useEnterpriseNewPaymentMethod() {
  const dispatch = useDispatch();

  const save: EnterpriseNewPaymentMethod = useCallback(({ token, groupId }) => {
    return api.payment.enterprise.newMethod({ token, groupId })
    .then(result => {
      if (!result.success) {
        return {
          error: result.message ? result.message : 'An error occured when trying to add this payment method.',
        };
      }

      const paymentMethod = {
        type: token.type === 'bank_account' ? PaymentMethod.BankAccount : PaymentMethod.Card,
        verified: token.type !== 'bank_account',
      };

      dispatch(actions.paymentMethodAdded({ paymentMethod }));

      return { success: true };
    })
    .catch(() => ({ error: 'An error occured when trying to add this payment method.' }));
  }, [dispatch]);

  return save;
}

export default {
  useUserNewPaymentMethod,
  useEnterpriseNewPaymentMethod,
};
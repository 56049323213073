import { useCallback } from 'react';
import { QuestionRowsBuilderContainer } from '@containers/SurveyBuilder.Question/RowsBuilderContainer';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import { MatrixSliderQuestion } from '@/types';
import styles from './style/MatrixSlider.Question.css';
import MatrixSliderSettings from './MatrixSlider.Settings';
import { ResponsePiping } from './ResponsePiping';

export type Props = {
  item: MatrixSliderQuestion.Question;
};

const MatrixSliderQuestionBuilder = ({ item }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const updateSettings = useCallback((settings: MatrixSliderQuestion.SliderSettings) => {
    dispatch({
      type: 'update-question-settings',
      ordinal: item.ordinal,
      settings: {
        ...item.settings,
        slider: settings,
      },
    });
  }, [item.settings, item.ordinal, dispatch]);

  return (
    <>
      <QuestionSettingTitle
        title="Slider" />
      <MatrixSliderSettings
        className={styles.slider}
        settings={item.settings.slider}
        updateSettings={updateSettings} />

      <QuestionSettingTitle title="Rows">
        <ResponsePiping type="rows" />
      </QuestionSettingTitle>
      <QuestionRowsBuilderContainer
        questionIdentifier={item.base.identifier}
        rows={item.matrixRows}
        className={styles.rows}
        maxRows={surveyBuilder.matrixSlider.MaxRows}
        minRows={surveyBuilder.matrixSlider.MinRows} />
    </>
  );
};

export default MatrixSliderQuestionBuilder;
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@store/actions';
import * as api from '@api';
import * as enums from '@enums';
import { WorkspaceObjects } from '@api/interfaces';
import Toast from 'components/Toast';

export const useSaveObjectOwnership = () => {
  const dispatch = useDispatch();

  const saveOwnership = useCallback((data: WorkspaceObjects.UpdateObjectOwnership.Request) => {
    return api.workspaces.object.updateObjectOwnership(data)
      .then(data => {
        dispatch(actions.workspaceObjectOwnerChanged({
          capabilities: data.capabilities,
          object: data.object,
        }));
        Toast.alert({
          title: `${enums.utils.WorkspaceObjectType.getName(data.object.typeId)} Owner Updated`,
        });
      }).catch(e => {
        Toast.error({
          title: 'Error',
          body: `We're sorry, there was an issue changing owners`,
        });
      });
  }, [
    dispatch,
  ]);

  return saveOwnership;
};

export default useSaveObjectOwnership;
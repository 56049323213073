import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as consts from '@consts';
import { useAppReadyState } from '@containers';
import { getLocationFor } from '@utils';

type Props = unknown;

type Params = {
  projectId: string;
};

export const Invitation = (props: Props) => {
  const params = useParams<Params>();
  const history = useHistory();
  const app = useAppReadyState();

  const handleRedirect = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);

    const url = getLocationFor.scheduling.providing({
      creatorId: +queryParams.get('schedulerId'),
      projectId: +params.projectId,
      scheduleeId: +queryParams.get('scheduleeId'),
      schedulerId: +queryParams.get('schedulerId'),
      userId: +queryParams.get('scheduleeId'),
    });

    history.replace({
      ...url,
      search: `?from=${consts.pathname.Home}`,
    });
  }, [
    history,
    params,
  ]);

  useEffect(() => {

    if (app.initialized) {
      handleRedirect();
    }

  }, [
    app.initialized,
    handleRedirect,
  ]);

  return null;
};
import * as types from '@store/action-types';
import { folders as initialState } from '@store/initial-state';
import {
  ApplicationActions,
  Folders,
} from '@store/interfaces';

type Action =
  ApplicationActions |
  Folders.FolderCreated.Action |
  Folders.FolderDeleted.Action |
  Folders.FolderRenamed.Action
;

export function folderIdsReducer(state = initialState.ids, action: Action): Store.Folders.Ids {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.folders.ids;

    case types.FOLDER_CREATED:
      return [
        ...state,
        action.folder.id,
      ];

    case types.FOLDER_DELETED:
      return state.filter(f => f !== action.folderId);

    default:
      return state;
  }
}

export function folderValuesReducer(state = initialState.values, action: Action): Store.Folders.Values {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.folders.values;

    case types.FOLDER_CREATED:
    case types.FOLDER_RENAMED:
      return {
        ...state,
        [action.folder.id]: action.folder,
      };

    case types.FOLDER_DELETED: {
      const { [action.folderId]: _, ...other } = state;

      return other;
    }

    default:
      return state;
  }
}

export function foldersReducer(state = initialState, action: Action): Store.Folders {
  return {
    ids: folderIdsReducer(state.ids, action),
    values: folderValuesReducer(state.values, action),
  };
}

export default foldersReducer;
import { useCallback } from 'react';
import * as survey from '@containers/Survey/utils';
import { SurveyResponseItem } from '@/types';
import { cx } from '@utils';
import ConjointAnalysisAnswer from './ConjointAnalysis';
import TextAnswerValue from './TextResponse';
import MatrixGridAnswerValue from './MatrixGrid';
import MatrixSliderAnswerValue from './MatrixSlider';
import MaxDiff from './MaxDiff';
import MultipleChoiceAnswerValue from './MultipleChoice';
import MultiselectAnswerValue from './Multiselect';
import RankingAnswerValue from './Ranking';
import MatrixMultiselectAnswerValue from './MatrixMultiselect';
import NumberInputTableAnswerValue from './NumberInputTable';
import styles from './style/Answer.css';

type Props = {
  className?: string;
  item: SurveyResponseItem;
  showHidden?: boolean;
};

export const SurveyResponseAnswer = ({ className, item, showHidden }: Props) => {
  const renderContent = useCallback(() => {

    if (survey.response.isMultipleChoice(item)) {
      return <MultipleChoiceAnswerValue answer={item.answer} />;
    } else if (survey.response.isTextResponse(item)) {
      if (item.hidden && !showHidden) {
        return 'Answer is hidden for compliance reasons';
      } else {
        return <TextAnswerValue answer={item.answer} />;
      }
    } else if (survey.response.isMultiSelect(item)) {
      return <MultiselectAnswerValue answer={item.answer} />;
    } else if (survey.response.isRanking(item)) {
      return (
        <RankingAnswerValue
          settings={item.question.settings}
          answer={item.answer} />
      );
    } else if (survey.response.isMatrixGrid(item)) {
      return <MatrixGridAnswerValue answer={item.answer} />;
    } else if (survey.response.isMatrixSlider(item)) {
      return (
        <MatrixSliderAnswerValue
          answer={item.answer}
          settings={item.question.settings.slider} />
      );
    } else if (survey.response.isConjointAnalysis(item)) {
      return (
        <ConjointAnalysisAnswer item={item} />
      );
    } else if (survey.response.isMaxDiff(item)) {
      return <MaxDiff item={item} />;
    } else if (survey.response.isMatrixMultiselect(item)) {
      return (
        <MatrixMultiselectAnswerValue
          answer={item.answer} />
      );
    } else if (survey.response.isNumberInputTable(item)) {
      return (
        <NumberInputTableAnswerValue
          question={item.question}
          answer={item.answer} />
      );
    } else {
      return null;
    }
  }, [item, showHidden]);

  return (
    <div className={cx(styles.root, className)}>
      {renderContent()}
    </div>
  );
};

export default SurveyResponseAnswer;
const year = new Date().getFullYear();

!process.env.__DEV__ && console.log(`%c                     
.*((((((/,        .*(#####(/,        ,((####((*           
  *((((((((((*      ,/##########((((((((##########/.         
 .*((((((((((/.     *(############################/.         
  .*(((((((((*       ,(#######(/,.   ..,/########/,          
    .,*//((((/.        .,***,.           .,****,.            
         .*(((/,.                                            
           *(((((//*,           .,****,.                     
           ,/((((((((/*       ,/((((((((/,                   
           *((((((((((/,     ./((((((((((/                   
           ,/(((((((((*.      /((((((((((/                   
            .*/((((//,        *((((((((/,                    
                             *((((/*.                        
                          .,(((((.                           
                      ,/((((((((,                            
                    ./((((((((((*                            
                    ,/((((((((((/                            
                     *(((((((((/.                            
                       .*/(/*.                              

                    V A N C E R Y

initializing....                                        © 2022`,
`color: #0a68ef; font-size: 10pt; font-family: monospace; white-space: pre;`);
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { useLock } from '@utils';
import Toast from 'components/Toast';

const useCancelCall = () => {
  const dispatch = useDispatch();
  const [_, lock] = useLock();

  function onCancelCall(params: ICallId): Promise<unknown> {

    return api.scheduling.projectCall.cancel({
      callId: params.callId,
    })
    .then(data => {
      const action = actions.callUpdated({ call: data.call });

      dispatch(action);
    })
    .then(() => {
      return Toast.alert({
        title: 'Call Cancelled',
        body: `The expert has been sent a notification that the scheduled call has been cancelled.`,
      });
    });
  }

  return lock(onCancelCall);
};

export { useCancelCall };
export default useCancelCall;
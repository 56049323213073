import { memo } from 'react';
import { Trash2 } from 'react-feather';
import { EventDate } from './EventPopover.Shared';
import styles from './style/EventPopover.css';

type Props = {
  timeStart: Date;
  timeEnd: Date;
  onDelete: () => unknown;
  showActions: boolean;
};

export const AvailabilityEventPopover = memo(({ timeStart, timeEnd, onDelete, showActions }: Props) => {

  return (
    <>
      <div className={styles.body}>
        <EventDate
          start={timeStart}
          end={timeEnd} />
      </div>
      <div className={styles.actions}>
        <Trash2
          className={styles.delete}
          onClick={onDelete}
          size={18} />
      </div>
    </>
  );
});

export default AvailabilityEventPopover;
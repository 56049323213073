import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { GetCallAccess } from '@api/interfaces/calls';
import * as api from '@api';

type Props = ICallId;

type Data = GetCallAccess.Response['access'];
type QueryKey = [string, Props];

export function useCallAccess(
  data: Props,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  return useQuery([CallAccessQueryKey, data], ({ queryKey }) => {
    const params = queryKey[1] as Props;

    return api.calls.getCallAccess(params)
      .then(res => res.access);

  }, options);
}

const CallAccessQueryKey = 'call-access';

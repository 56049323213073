import { useCallback, forwardRef, ChangeEvent } from 'react';
import { Trash2 } from 'react-feather';
import { SurveySectionNumber } from '@presentation/Survey';
import { cx } from '@utils';
import { TextareaAutosize } from 'components/Textarea';
import { SurveySectionHeaderProps } from './interfaces';
import styles from './style/Header.css';

export const SurveySectionHeader = forwardRef<HTMLDivElement, SurveySectionHeaderProps>(({
  updateName,
  ...props
}, ref) => {
  const handleNameChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    updateName(e.target.value);
  }, [updateName]);

  return (
    <div ref={ref} className={cx(styles.root, props.className)}>
      <div className={styles.left}>
        <SurveySectionNumber
          className={styles.number}
          ordinal={props.item.ordinal} />
        <TextareaAutosize
          className={styles.textarea}
          onChange={handleNameChange}
          value={props.item.name} />
      </div>
      {props.canDelete &&
        <Trash2
        className={styles.trash}
        onClick={props.onDelete} />}
    </div>
  );
});

export default SurveySectionHeader;
import { Share2 } from 'react-feather';
import { cx } from '@utils';
import styles from './style.legacy.css';

type Props = {
  className?: string;
  onClick: () => void;
};

export const Icon = (props: Props) => (
  <div
    className={cx(styles.shareBtn, props.className)}
    onClick={props.onClick}>
    <Share2
      height={20}
      width={20} />
    <span className={styles.hideMobile}>Share</span>
  </div>
);

export default Icon;
import { useCallback } from 'react';
import { NumberInputTableWarningsContainer } from '@containers/SurveyForm/NumberInputTable';
import { SurveyQuestionType } from '@enums';
import { QuestionText } from '@presentation/Survey';
import {
  MultipleChoiceQuestion as MCQ,
  TextResponseQuestion as TRQ,
  MultiselectQuestion as MSQ,
  MatrixGridQuestion as MXG,
  MatrixSliderQuestion as MXS,
  MatrixMultiselectQuestion as MMS,
  NumberInputTableQuestion as NIT,
  RankingQuestion as RQ,
} from '@/types';
import {
  MatrixGridQuestion,
  MatrixSliderQuestion,
  MultipleChoiceQuestion,
  ShortTextResponseQuestion,
  MultiselectQuestion,
  RankingQuestion,
  MatrixMultiselectQuestionForm,
  NumberInputTableQuestionForm,
} from 'components/SurveyForm';
import { EditingQuestionItemProps } from './interfaces';
import styles from './style.css';

export const EditingQuestionItem = (props: EditingQuestionItemProps) => {

  const renderContent = useCallback(() => {
    switch (props.item.question.typeId) {
      case SurveyQuestionType.MultipleChoice:
        return (
          <MultipleChoiceQuestion
            answer={props.item.answer as MCQ.RespondentAnswer}
            options={props.item.question.options}
            setAnswer={props.updateAnswer} />
        );

      case SurveyQuestionType.ShortTextResponse:
        return (
          <ShortTextResponseQuestion
            answer={props.item.answer as TRQ.RespondentAnswer}
            setAnswer={props.updateAnswer} />
        );

      case SurveyQuestionType.LongTextResponse:
        return (
          <ShortTextResponseQuestion
            answer={props.item.answer as TRQ.RespondentAnswer}
            setAnswer={props.updateAnswer} />
        );

      case SurveyQuestionType.Multiselect:
        return (
          <MultiselectQuestion
            answer={props.item.answer as MSQ.RespondentAnswer}
            question={props.item.question}
            setAnswer={props.updateAnswer} />
        );

      case SurveyQuestionType.Ranking:
        return (
          <RankingQuestion
            answer={props.item.answer as RQ.RespondentAnswer.Value}
            question={props.item.question}
            setAnswer={props.updateAnswer} />
        );

      case SurveyQuestionType.Sliders: {
        return (
          <MatrixSliderQuestion
            answer={props.item.answer as MXS.RespondentAnswer.Value}
            item={props.item.question}
            setAnswer={props.updateAnswer} />
        );
      }

      case SurveyQuestionType.MatrixGrid: {
        return (
          <MatrixGridQuestion
            answer={props.item.answer as MXG.RespondentAnswer.Value}
            item={props.item.question}
            setAnswer={props.updateAnswer} />
        );
      }

      case SurveyQuestionType.MatrixMultiselect:
        return (
          <MatrixMultiselectQuestionForm
            answer={props.item.answer as MMS.RespondentAnswer.Value}
            item={props.item.question}
            setAnswer={props.updateAnswer} />
        );

      case SurveyQuestionType.NumberInputTable:
        return (
          <NumberInputTableWarningsContainer item={props.item.question}>
            <NumberInputTableQuestionForm
              answer={props.item.answer as NIT.RespondentAnswer.Value}
              item={props.item.question}
              setAnswer={props.updateAnswer} />
          </NumberInputTableWarningsContainer>
        );

      default:
        return null;
    }
  }, [
    props.item.answer,
    props.item.question,
    props.updateAnswer,
  ]);

  return (
    <>
      <QuestionText
        textClassName={styles.questionText}
        item={props.item.question} />
      <div className={styles.content}>
        {renderContent()}
      </div>
    </>
  );
};

export default EditingQuestionItem;
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { useSelectContact, useSelectProject, useSelectUser } from '@containers/Store';
import { withRequestCallScheduler, useDeactivateUser, useRemoveUser, RequestCallSubmitHandler } from '@containers/Scheduler';
import * as scheduler from '@containers/Scheduler/utils';
import { useFindUnconfirmedCallWithUser } from '@containers/Scheduler/hooks/useFindUnconfirmedCallWithUser';
import * as enums from '@enums';
import * as selectors from '@store/selectors';
import { getLocationFor } from '@utils';
import { useHideUserPrompt, useRemoveUserPrompt } from 'components/GroupProject/hooks';
import { ProjectPipelineItemContext } from './Context';

type OwnProps =
  IProjectId &
  IUserId &
  ChildrenProps;

type Props =
  OwnProps &
  RequestCallSubmitHandler
  ;

const Container = ({
  children,
  handleSubmitCallRequest,
  projectId,
  userId,
}: Props) => {
  const contact = useSelectContact(userId);
  const record = useSelector(selectors.pipeline.client.record(projectId, userId));
  const respondent = useSelector(selectors.pipeline.client.respondent(projectId, userId));
  const project = useSelectProject(projectId);
  const { id: meId } = useSelectUser();

  const hideUser = useDeactivateUser();
  const removeUser = useRemoveUser();
  const findUnconfirmedCallWithUser = useFindUnconfirmedCallWithUser();

  const call = findUnconfirmedCallWithUser(record);

  const handleHideUser = useCallback(() => {
    return hideUser({
      projectId,
      userId,
    });
  }, [
    hideUser,
    projectId,
    userId,
  ]);

  const handleRemoveUser = useCallback(() => {
    return removeUser({
      projectId,
      userId,
    });
  }, [
    removeUser,
    projectId,
    userId,
  ]);

  const [toggleRemoveUserPrompt, RemoveUserPrompt] = useRemoveUserPrompt({
    onConfirm: handleRemoveUser,
    project,
    user: contact,
  });

  const [toggleHideUserPrompt, HideUserPrompt] = useHideUserPrompt({
    onConfirm: handleHideUser,
    project,
    user: contact,
  });

  const isProjectActive = useMemo(() => {
    return project.projectStatusId === enums.ProjectStatus.Active;
  }, [
    project.projectStatusId,
  ]);

  const isProjectCreator = useMemo(() => {
    return meId === project.creatorId;
  }, [
    meId,
    project.creatorId,
  ]);

  const requiresAnalystAction = useMemo(() => {
    return call && scheduler.calls.isAwaitingCreatorAction(call);
  }, [
    call,
  ]);

  const canRequestCall = useMemo(() => {
    const types = [
      enums.ProjectType.Survey,
    ];

    return isProjectActive
        && isProjectCreator
        && !requiresAnalystAction
        && !types.includes(project.projectType)
        && !scheduler.projects.isAwaitingJoin(record);
  }, [
    isProjectActive,
    isProjectCreator,
    requiresAnalystAction,
    project.projectType,
    record,
  ]);

  const projectSurveyLocation = useMemo(() => {
    if (!respondent) {
      return null;
    }

    return getLocationFor.project.surveyResponse({
      slug: project.slug,
      projectSurveyResponseId: respondent.projectSurveyResponse.id,
    });
  }, [
    project.slug,
    respondent,
  ]);

  const selectingLocation = useMemo(() => {
    if (!call) {
      return null;
    }

    return getLocationFor.scheduling.selecting({
      callId: call.id,
      projectId: call.projectId,
      scheduleeId: meId,
      schedulerId: call.userId,
    });
  }, [
    call,
    meId,
  ]);

  const onSubmitCallRequest = useCallback(() => {
    return new Promise((resolve, reject) => handleSubmitCallRequest({
      projectId,
      userId,
    }, resolve));
  }, [
    projectId,
    userId,
    handleSubmitCallRequest,
  ]);

  const canHideUser = useMemo(() => {
    return isProjectActive &&
      scheduler.pipeline.canHideUser(record);
  }, [
    isProjectActive,
    record,
  ]);

  const canRemoveUser = useMemo(() => {
    return isProjectActive &&
      scheduler.pipeline.canRemoveUser(record);
  }, [
    isProjectActive,
    record,
  ]);

  const canSendSurveyInvite = useMemo(() => {
    return isProjectActive &&
      scheduler.pipeline.canSendSurveyInvite({
        projectType: project.projectType,
        statusId: record.statusId,
      });
  }, [
    isProjectActive,
    project.projectType,
    record.statusId,
  ]);

  const canViewSurvey = useMemo(() => {
    return scheduler.pipeline.canViewSurvey({
      projectType: project.projectType,
      statusId: record.statusId,
      surveyStatusId: record.surveyStatusId,
    });
  }, [
    project.projectType,
    record.statusId,
    record.surveyStatusId,
  ]);

  const value = useMemo(() => {
    return {
      canHideUser,
      canRemoveUser,
      canRequestCall,
      canSelectTime: requiresAnalystAction,
      canSendSurveyInvite,
      canViewSurvey,
      onHideUser: toggleHideUserPrompt,
      onRemoveUser: toggleRemoveUserPrompt,
      onSubmitCallRequest,
      projectSurveyLocation,
      selectingLocation,
    };
  }, [
    canHideUser,
    canRemoveUser,
    canRequestCall,
    canSendSurveyInvite,
    canViewSurvey,
    requiresAnalystAction,
    toggleHideUserPrompt,
    toggleRemoveUserPrompt,
    onSubmitCallRequest,
    projectSurveyLocation,
    selectingLocation,
  ]);

  return (
    <ProjectPipelineItemContext.Provider value={value}>
      {children}
      <HideUserPrompt />
      <RemoveUserPrompt />
    </ProjectPipelineItemContext.Provider>
  );
};

export const ProjectPipelineItemContainer = compose(
  withRequestCallScheduler,
)(Container);

export default ProjectPipelineItemContainer;
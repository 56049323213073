import { useMemo } from 'react';
import { BrandingContainer } from '@containers/Branding';
import { RepeatQuestionResponsesContainer } from '@containers/RepeatSurveyResponses/QuestionResponsesContainer';
import { QuestionResponsesContainer } from '@containers/SurveyResponses/QuestionResponsesContainer';
import SurveyResponses from '@screens/GroupProject/ProjectTabSurvey';
import { useProjectState } from './hooks';

export const SurveyResponsesContainer = () => {
  const state = useProjectState();

  const ResponsesComponnet = useMemo(() => {
    return state.project
      ? QuestionResponsesContainer
      : RepeatQuestionResponsesContainer;
  }, [state.project]);

  return (
    <BrandingContainer>
      <SurveyResponses ResponsesComponent={ResponsesComponnet} />
    </BrandingContainer>
  );
};
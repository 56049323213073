import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import * as api from '@api';
import { Topics } from '@api/interfaces';

type Data = Topics.FetchOverview.Response;

export function useFetchTopic<TData = Data>(
  slug: string,
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {

  return useQuery(keyUseFetchTopic(slug), () => {
    return api.topics.fetchOverview({
      slug,
    });
  }, options);
}

export function keyUseFetchTopic(slug: string) {
  return ['topic', slug];
}
import { useCallback, useMemo } from 'react';
import {
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import * as consts from '@consts';
import { RankingQuestion, SurveyQuestionOption } from '@/types';
import { RankingOrdinalDropdownItem } from './interfaces';
import type { RefreshItems } from './utils';
import * as utils from './utils';
import RankingList from './RankingList';

type Props = {
  items: SurveyQuestionOption[];
  onUpdate: (data: RankingQuestion.RespondentAnswer.Option[]) => void;
  settings: RankingQuestion.Settings;
};

const NotApplicableOrdinal = consts.surveys.NotApplicableRankingOrdinal;

export const RankingListContainer = ({
  items,
  onUpdate,
  settings,
}: Props) => {
  const sortedItems = useMemo(() => {
    const withOrdinals = items.filter(f => !!f.ordinal);
    const withoutOrdinals = items.filter(f => !f.ordinal);
    return [
      ...withOrdinals.sort((a, b) => a.ordinal > b.ordinal ? 1 : -1),
      ...withoutOrdinals,
    ];
  }, [items]);

  const reorder = useCallback(({
    sourceIndex,
    targetIndex,
    targetOrdinal,
  }: Omit<RefreshItems, 'items' | 'settings'>) => {
    if (sourceIndex === targetIndex &&
        sortedItems[targetIndex].ordinal === targetOrdinal) {
      return;
    }

    onUpdate(utils.refreshItems({
      items: sortedItems,
      settings,
      sourceIndex,
      targetIndex,
      targetOrdinal,
    }));
  }, [
    settings,
    sortedItems,
    onUpdate,
  ]);

  const handleDragEnd = useCallback((result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    reorder({
      sourceIndex: result.source.index,
      targetIndex: result.destination.index,
      targetOrdinal: result.destination.index + 1,
    });
  }, [reorder]);

  const handleDropdownSelect = useCallback((sourceIndex: number) => (item: RankingOrdinalDropdownItem) => {

    if (item.id !== NotApplicableOrdinal) {
      reorder({
        sourceIndex,
        targetIndex: item.id - 1,
        targetOrdinal: item.id,
      });
    } else {
      const targetIndex = Math.max(...sortedItems.map((m, index) => (m.ordinal || index)));
      reorder({
        sourceIndex,
        targetIndex,
        targetOrdinal: NotApplicableOrdinal,
      });
    }
  }, [sortedItems, reorder]);

  const getDropdownItems = useCallback((option: SurveyQuestionOption) => {

    const nonNA = items
      .filter(f => f.ordinal !== NotApplicableOrdinal)
      .map((_, index) => index + 1);

    if (settings.mustRank) {
      const includeNA = items.length < settings.mustRank;

      return [
        ...nonNA,
        ...[option.ordinal === NotApplicableOrdinal ? nonNA.length + 1 : null],
        includeNA && NotApplicableOrdinal,
      ]
      .filter(f => f !== null)
      .filter(f => f <= settings.mustRank);
    } else {

      return [
        ...nonNA,
        ...[option.ordinal === NotApplicableOrdinal ? nonNA.length + 1 : null],
        NotApplicableOrdinal,
      ]
      .filter(f => f !== null);
    }

  }, [
    items,
    settings.mustRank,
  ]);

  return (
    <RankingList
      getDropdownItems={getDropdownItems}
      items={sortedItems}
      onDragEnd={handleDragEnd}
      onOrdinalSelect={handleDropdownSelect} />
  );
};

export default RankingListContainer;
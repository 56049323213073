import { getLocationFor, trunc } from '@utils';
import { ComplianceReviewItem } from '@/types';
import { useSelectProject } from '@containers';
import { ProjectPopoverAnchor } from 'components/GroupProject';
import { MaybeLink } from 'components/MaybeLink';
import styles from './style/ComplianceReview.css';

type Props = {
  className?: string;
  item:       ComplianceReviewItem;
};

const ProjectLink = (props: Props) => {
  const project = useSelectProject(props.item.project?.id);

  const location = project
     && getLocationFor.project.root(project);

  return (
    <ProjectPopoverAnchor item={project}>
      <MaybeLink
        className={styles.link}
        to={location}>
        {trunc(props.item.project?.name, 30)}
      </MaybeLink>
    </ProjectPopoverAnchor>
  );
};

export { ProjectLink };
export default ProjectLink;
import { WorkspaceFolder } from '@/types';
import { safeJsonDate } from '@utils';

export const normalizeFolder = (data: WorkspaceFolder<string>): WorkspaceFolder => {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
};

export const normalizeFolders = (folders: WorkspaceFolder<string>[]): Store.Folders => {

  const values = folders.reduce((acc, folder) => ({
    ...acc,
    [folder.id]: normalizeFolder(folder),
  }), {});

  return {
    ids: folders.map(m => m.id),
    values,
  };
};
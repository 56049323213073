import { useContext } from 'react';
import { ComplianceOnboardingContext } from '@containers';
import {
  NextButton,
} from 'components/Wizard';
import styles from './style.css';

type Props = {
  next: () => void;
  project: string;
};

const ComplianceIntroScreen = () => {
  const ctx = useContext(ComplianceOnboardingContext);

  return (
    <ComplianceIntro
      next={ctx.next}
      project={ctx.project.name} />
  );
};

const ComplianceIntro = (props: Props) => {
  const title = `Great, now let's go over training on the dos and don'ts for projects you participate in on Vancery.`;
  const copy = `This training provides the compliance rules of our platform. After completing the training, you will be able to review specifics for the ${props.project} project.`;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.copy}>{copy}</div>
        <div className={styles.navigation}>
          <NextButton onClick={props.next} />
        </div>
      </div>
    </div>
  );
};

export { ComplianceIntroScreen };
export { ComplianceIntro };
export default ComplianceIntro;
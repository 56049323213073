import { useCallback } from 'react';
import { Trash2 } from 'react-feather';
import { useSelectUser } from '@containers/Store';
import { useRescheduleAdHocCall } from '@containers/Scheduler/hooks';
import { getOtherAdHocCallParticipant } from '@containers/Scheduler/utils.calls';
import { AdHocCall } from '@/types';
import { getLocationFor } from '@utils';
import { Button } from 'components/Button';
import { EventDate, CallParticipant, JoinCall, SelectTime } from './EventPopover.Shared';
import styles from './style/EventPopover.css';

type Props = {
  item: AdHocCall;
  conferenceIdentifier: number;
  onCancel: () => unknown;
  showActions: boolean;
};

export const AdHocCallEventPopover = ({ item, conferenceIdentifier, onCancel, showActions }: Props) => {
  const me = useSelectUser();
  const [canReschedule, reschedulingLocation] = useRescheduleAdHocCall(item.id);

  const renderParticipant = useCallback(() => {

    const participantId = getOtherAdHocCallParticipant(item, me.id);

    return (
      <CallParticipant
        item={item}
        userId={participantId} />
    );

  }, [
    item,
    me.id,
  ]);

  const confirmingLocation = getLocationFor.scheduling.adHoc.selecting({
    callId: item.id,
    scheduleeId: me.id,
    schedulerId: item.lastModifiedBy,
  });

  return (
    <>
      <div className={styles.body}>
        <EventDate
          start={item.timeStart}
          end={item.timeEnd} />

        <div className={styles.title}>
          {item.request.subject}
        </div>

        {renderParticipant()}

        {showActions && canReschedule &&
          <Button
            className={styles.callaction}
            to={reschedulingLocation}>
            Reschedule
          </Button>
        }

        {showActions &&
          <SelectTime
            item={item}
            location={confirmingLocation} />
        }

        {showActions &&
          <JoinCall
            item={item}
            conferenceIdentifier={conferenceIdentifier} />
        }
      </div>
      <div className={styles.actions}>
        {showActions &&
          <Trash2
            className={styles.delete}
            onClick={onCancel}
            size={18} />
        }
      </div>
    </>
  );
};

export default AdHocCallEventPopover;
import { ComponentType, useCallback } from 'react';
import { NodeViewComponentProps } from '@remirror/react';
import { useTranscriptSpeaker } from '@containers/Transcript';
import { MonologueDetails as MD } from '@/types/transcribe.rich-text';
import { useTranscriptMediaPlayer } from '@containers/Transcript';
import styles from './style/Monologue.css';

export const MonologueDetails: ComponentType<NodeViewComponentProps> = ({ node }) => {
  const {
    s: start,
    e: end,
    spk: speakerIndex,
  } = node.attrs as MD.Attributes;

  const speaker = useTranscriptSpeaker(speakerIndex);

  const player = useTranscriptMediaPlayer();

  const handleSeek = useCallback((time: number) => (e: React.MouseEvent<HTMLSpanElement>) => {
    player.currentTime = time;
    player.play();
  }, [player]);

  return (
    <div
      className={styles.details}>
      <div className={styles.speaker}>{speaker}</div>
      <div className={styles.timestamp}>
        [
        <span
          onMouseDown={e => e.preventDefault()}
          onClick={handleSeek(start)}
          className={styles.seek}>
          {secondsToTime(start)}
        </span>
        {` - `}
        <span
          onMouseDown={e => e.preventDefault()}
          onClick={handleSeek(end)}
          className={styles.seek}>
          {secondsToTime(end)}
        </span>
        ]
      </div>
    </div>
  );
};

export default MonologueDetails;

function secondsToTime(value: number) {
  const time = new Date(value * 1000).toISOString().substr(11, 8);
  return time.startsWith('00') ? time.substr(3) : time;
}
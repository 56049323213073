import { useCallback, useEffect, useState } from 'react';
import { ReportSocketEvent } from '@enums';
import * as ws from '@services/websocket';
import { useDownloader } from '@utils/hooks';
import { ReportKeyContext } from './Context';

type Props =
  {
    onDownload?: () => void;
  } & ChildrenProps;

export const ReportSocketContainer = ({ children, onDownload }: Props) => {
  const [reportKey, setReportKey] = useState<string>(null);
  const download = useDownloader();

  const handleDownload = useCallback(({ url }: { url: string; }) => {
    download({
      url,
    });
    setReportKey(null);
    if (onDownload) {
      onDownload();
    }
  }, [download, onDownload]);

  const register = useCallback(() => {
    if (reportKey) {
      ws.reports.on(ReportSocketEvent.ReportAvailable, handleDownload);

      ws.reports.open();
      ws.reports.subscribe(reportKey);
    }
  }, [
    handleDownload,
    reportKey,
  ]);

  const unregister = useCallback(() => {
    ws.reports.close();

    ws.reports.off(ReportSocketEvent.ReportAvailable, handleDownload);

  }, [
    handleDownload,
  ]);

  useEffect(() => {
    register();

    return () => {
      unregister();
    };
  }, [
    register,
    unregister,
  ]);

  return (
    <ReportKeyContext.Provider value={[reportKey, setReportKey]}>
      {children}
    </ReportKeyContext.Provider>
  );
};

export default ReportSocketContainer;
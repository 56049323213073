import * as Branding from '@containers/Branding';
import { BadgeNotificationsContainer, NotificationsContainer } from '@containers/Notifications';
import * as Post from '@containers/PostCreation/Post';
import { RehydrationContainer } from '@containers/Store';
import { ProjectMetricsContainer } from '@containers/Metrics';
import BannerState from '@containers/Banner/BannerState';
import { ConsentManagementContainer } from '@containers/ConsentManagement';
import { WebSocketContainer } from '@containers/WebSocket';
import { WorkspaceFileUploadContainer } from '@containers/WorkspaceFileUpload/UploadContainer';
import { UserProfileReattestation } from './UserProfileReattestation';
import { MuiThemeProvider } from './MuiThemeProvider';

type Props = ChildrenProps;

export const MainSubscriber = (props: Props) => {

  return (
    <RehydrationContainer>
      <BannerState>
        <ConsentManagementContainer>
          <UserProfileReattestation>
            <BadgeNotificationsContainer>
              <NotificationsContainer>
                <ProjectMetricsContainer>
                  <WorkspaceFileUploadContainer>
                    <WebSocketContainer>
                      <Post.Video.UploaderContainer>
                        <MuiThemeProvider>
                          <Branding.SurveyTheming>
                            {props.children}
                          </Branding.SurveyTheming>
                        </MuiThemeProvider>
                      </Post.Video.UploaderContainer>
                    </WebSocketContainer>
                  </WorkspaceFileUploadContainer>
                </ProjectMetricsContainer>
              </NotificationsContainer>
            </BadgeNotificationsContainer>
          </UserProfileReattestation>
        </ConsentManagementContainer>
      </BannerState>
    </RehydrationContainer>
  );
};

export default MainSubscriber;
import { useSelectUser } from '@containers/Store';
import { useSaveUserProfile, useProfileState, useAccountProfileLoading, AccountProfileContainer } from '@containers/AccountProfile';
import * as enums from '@enums';
import * as mixpanel from '@services/mixpanel';
import ActivityIndicator from 'components/ActivityIndicator';
import { ProfileOnboardingContext } from './Context';
import { ProfileOnboardingContextValue, ProfileOnboardingProps } from './interfaces';

const StateDependentContainer = (props: ProfileOnboardingProps) => {
  const [user] = useProfileState();
  const loading = useAccountProfileLoading();

  const save = useSaveUserProfile();

  const saveBio = (value: string) => {
    mixpanel.event.onboarding.profile.complete();

    if (user.bio.summary) {
      return save({
        summary: value,
        type: enums.ProfileEntry.Bio,
        userId: user.id,
      })
      .then(props.onCompletion);
    } else {
      props.onCompletion();
    }
  };

  const context: ProfileOnboardingContextValue = {
    back: props.back,
    next: props.next,
    saveBio,
  };

  if (loading && !user.initialized) return <ActivityIndicator />;

  return (
    <ProfileOnboardingContext.Provider value={context}>
      {props.children}
    </ProfileOnboardingContext.Provider>
  );
};

const ProfileOnboardingContainer = (props: ProfileOnboardingProps) => {
  const user = useSelectUser();

  return (
    <AccountProfileContainer userId={user.id}>
      <StateDependentContainer {...props} />
    </AccountProfileContainer>
  );
};

export { ProfileOnboardingContainer };
export default ProfileOnboardingContainer;
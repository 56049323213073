import { useMemo } from 'react';
import { useMessagingEnabled } from '@containers/Store';
import { useProjectState, useProjectSurveyRespondent, useProjectAnonymity } from '@containers/GroupProject/hooks';
import { Contact } from '@/types';
import { useRequestQuant2Qual } from '@containers/Call/hooks/useRequestQuant2Qual';
import { useProfileMessagingAction } from 'components/UserProfile/hooks';
import { Button } from 'components/Button';
import styles from './style/HeaderActions.css';

export const ProjectSurveyResponseHeaderActions = () => {

  const { project } = useProjectState();
  const { user } = useProjectSurveyRespondent();
  const params = {
    user: user as Contact,
  };

  const projectAnonymity = useProjectAnonymity();
  const messagingEnabled = useMessagingEnabled();
  const [canInitiateMessages, initiateMessage] = useProfileMessagingAction(params);

  const canSendMessages = useMemo(() => {
    return messagingEnabled && canInitiateMessages &&
      !projectAnonymity;
  }, [
    messagingEnabled,
    projectAnonymity,
    canInitiateMessages,
  ]);

  const requestQuant2Qual = useRequestQuant2Qual();

  function callRequestOnClick(e: React.MouseEvent<HTMLElement>) {
    requestQuant2Qual({
      expertId: user.id,
      expertName: user.profile.fullname,
      projectId: project.id,
    });
  }

  function messageOnClick(e: React.MouseEvent<HTMLElement>) {
    initiateMessage();
  }

  return (
    <div className={styles.btns}>
      {
        <Button
          className={styles.btn}
          onClick={callRequestOnClick}
          variant="pill">
          Request Call
        </Button>}
      {canSendMessages &&
        <Button
          className={styles.btn}
          onClick={messageOnClick}
          variant="pill">
          Message
        </Button>}
    </div>
  );
};

export default ProjectSurveyResponseHeaderActions;
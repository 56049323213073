import { useCallback, useContext, useMemo } from 'react';
import { FollowedTopicsContext } from '@containers/Topic/Context';
import { OnboardingChecklistContext } from './Context';
import { useIncompleteOnboardingSteps } from './hooks/useIncompleteOnboardingSteps';
import { ChecklistItem } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const OnboardingChecklistContainer = (props: Props) => {
  const ctx = useContext(FollowedTopicsContext);
  const remaining = useIncompleteOnboardingSteps();

  const isComplete = (key: ChecklistItem) => {
    return !remaining.includes(key);
  };

  const count = useMemo(() => {
    const total = Object.keys(ChecklistItem).length;
    const incomplete = remaining.length;
    const complete = total - incomplete;

    return {
      complete,
      incomplete,
      total,
    };

  }, [remaining]);

  const value = {
    count,
    isComplete: useCallback(isComplete, [remaining]),
    loading: ctx.query.isLoading,
    remaining,
  };

  return (
    <OnboardingChecklistContext.Provider value={value}>
      {props.children}
    </OnboardingChecklistContext.Provider>
  );
};

OnboardingChecklistContainer.displayName = 'OnboardingChecklist.Container';
import { useContext, useRef } from 'react';
import { MenuProvider } from '@containers/AppNavigation';
import * as Whitelabel from 'components/Whitelabel';
import { AppBarContext } from './Context';
import * as Menu from './Menu';
import * as Nav from './Nav';
import * as Navbar from './Navbar';
import { useAnchoredMenuRef } from './hooks/useAnchoredMenuRef';
import styles from './style/Navbar.css';

export const AppBar = (props: unknown) => {

  return (
    <AppBar.Container>

      <AppBar.LayoutRoot>

        <Navbar.Banner />

        <div className={styles.wrap}>
          <div className={styles.content}>
            <Nav.Logo />
            <Nav.Searchbar />
          </div>

          <div className={styles.nav}>
            <Nav.LinkIcons />
            <Menu.Notifications />
            <Menu.Account />
            <Menu.Drawer.Anchor />
          </div>
        </div>

      </AppBar.LayoutRoot>

      <Menu.Drawer />
    </AppBar.Container>
  );
};

export const AppBarAccess = (props: unknown) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const context = {
    drawer: null,
    root: {
      ref: rootRef,
    },
  };

  return (
    <AppBarContext.Provider value={context}>
      <Navbar.LayoutRoot ref={rootRef}>

        <div className={styles.wrap}>
          <div className={styles.content}>
            <Nav.Logo />
            <Nav.Searchbar />
          </div>

          <div className={styles.nav}>
            <Menu.Account />
          </div>
        </div>

      </Navbar.LayoutRoot>
    </AppBarContext.Provider>
  );
};

type Props = {
  children: React.ReactNode;
};

const Container = (props: Props) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [hamburgerRef, open, actions] = useAnchoredMenuRef<HTMLButtonElement>();

  const context = {
    drawer: {
      open,
      anchorRef: hamburgerRef,
      toggle: actions.toggle,
    },
    root: {
      ref: rootRef,
    },
  };

  return (
    <Whitelabel.MenuDisplayContainer>
      <MenuProvider>
        <AppBarContext.Provider value={context}>
          {props.children}
        </AppBarContext.Provider>
      </MenuProvider>
    </Whitelabel.MenuDisplayContainer>
  );
};

const LayoutRoot = (props: Props) => {
  const { root } = useContext(AppBarContext);

  return (
    <Navbar.LayoutRoot ref={root.ref}>
      {props.children}
    </Navbar.LayoutRoot>
  );

};

Container.displayName = 'AppBar.Container';
LayoutRoot.displayName = 'AppBar.LayoutRoot';
AppBar.Container = Container;
AppBar.LayoutRoot = LayoutRoot;
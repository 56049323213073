import * as mp from '@services/mixpanel';
import { Session, LastLoginStrategy } from './interfaces';

export const Keys = Object.freeze({
  Session: 'vancery:session',
  LastLoginStrategy: 'vancery:last-login-strategy',
});

export function destroy()  {
  mp.event.auth.logout();

  localStorage.removeItem(Keys.Session);
}

export function set(data: Session.Set.Params) {
  const value: Session.LocalStorage = {
    authenticated: data.authenticated,
    expiry: data.expiry.getTime(),
    csrf: data.csrf,
    userId: data.userId,
  };
  localStorage.setItem(Keys.Session, JSON.stringify(value));
}

export function get(): Session.LocalStorage {
  try {
    const value = localStorage.getItem(Keys.Session);
    if (!value) return null;
    const session = JSON.parse(value) as Session.LocalStorage;
    return session;
  } catch (e) {
    localStorage.removeItem(Keys.Session);
    return null;
  }
}

export function isAuthenticated() {
  const session = get();
  if (!session) return false;
  return session.authenticated && (Date.now() + (5 * 60 * 1000)) < session.expiry;
}

export function hasSession() {
  const session = get();
  return !session;
}

export function getCSRF() {
  const session = get();
  if (!session) return null;
  return session.csrf;
}

export function setLastLoginStrategy(data: LastLoginStrategy.Set.Params) {
  localStorage.setItem(Keys.LastLoginStrategy, JSON.stringify(data));
}

export function getLastLoginStrategy() {
  try {
    const value = localStorage.getItem(Keys.LastLoginStrategy);
    if (!value) return null;
    const strategy = JSON.parse(value) as LastLoginStrategy.LocalStorage;
    return strategy;
  } catch (e) {
    localStorage.removeItem(Keys.LastLoginStrategy);
    return null;
  }
}
import { useContext, useMemo, Fragment } from 'react';
import { SurveyThemingContext } from '@containers/Branding/Context';
import { cx } from '@utils';
import { BrandingFooter } from './BrandingFooter';
import styles from './style/BrandScreen.css';

type ScreenComponent<T> =
  | React.ComponentType<T>
  | ((props?: T) => JSX.Element);

export const brand = <T,>(Component: ScreenComponent<T>) => {
  const BrandScreenWrapper = (props: T) => {
    const ctx = useContext(SurveyThemingContext);

    const theme = !!ctx?.theming?.data;

    if (!theme) {
      return (
        <Component {...props} />
      );
    }

    return (
      <BrandScreen className={styles.root}>
        <Component {...props} />
      </BrandScreen>
    );
  };

  BrandScreenWrapper.displayName = 'Whitelabel.BrandScreen.Wrapper';

  return BrandScreenWrapper;
};

type BrandScreenProps = {
  children:   React.ReactNode;
  className?: string;
};

export const BrandScreen = (props: BrandScreenProps) => {
  return (
    <Fragment>
      <div className={props.className}>
        {props.children}
      </div>
      <BrandingFooter />
    </Fragment>
  );
};

BrandScreen.displayName = 'Whitelabel.BrandScreen';

type Props = {
  children:   React.ReactNode;
  className?: string;
};

export const ScreenDecorator = (props: Props) => {
  const ctx = useContext(SurveyThemingContext);

  const theme = useMemo(() => {
    return !!ctx?.theming?.data;
  }, [
    ctx?.theming?.data,
  ]);

  if (!theme) return props.children as JSX.Element;

  return (
    <BrandScreen className={cx(styles.root, props.className)}>
      {props.children}
    </BrandScreen>
  );
};

ScreenDecorator.displayName = 'Whitelabel.ScreenDecorator';
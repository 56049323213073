import * as types from '@store/action-types';
import {
  ProjectClonedAction,
  ProjectClonedState,
  ProjectCreatedAction,
  ProjectCreatedState,
  ProjectRemovedAction,
  ProjectRemovedState,
  ProjectUpdatedAction,
  ProjectUpdatedState,
  ProjectParentUpdatedState,
  ProjectParentUpdatedAction,
  ProjectOverviewUpdatedAction,
  ProjectOverviewUpdatedState,
} from '@store/interfaces';

export const projectCreated = (data: ProjectCreatedState): ProjectCreatedAction => {
  return {
    capabilities: data.capabilities,
    object: data.object,
    parent: data.parent,
    project: data.project,
    projectId: data.projectId,
    type: types.PROJECT_CREATED,
  };
};

export const projectRemoved = (data: ProjectRemovedState): ProjectRemovedAction => {
  return {
    callIds: data.callIds,
    projectId: data.projectId,
    type: types.PROJECT_REMOVED,
  };
};

export const projectUpdated = (data: ProjectUpdatedState): ProjectUpdatedAction => {
  return {
    project: data.project,
    projectId: data.projectId,
    type: types.PROJECT_UPDATED,
  };
};

export const projectParentUpdated = (data: ProjectParentUpdatedState): ProjectParentUpdatedAction => {
  return {
    parent: data.parent,
    type: types.PROJECT_PARENT_UPDATED,
  };
};

export const projectCloned = (data: ProjectClonedState): ProjectClonedAction => {
  return {
    parent: data.parent,
    pipeline: data.pipeline,
    previous: data.previous,
    project: data.project,
    type: types.PROJECT_CLONED,
  };
};

export const projectOverviewUpdated = (data: ProjectOverviewUpdatedState): ProjectOverviewUpdatedAction => {
  return {
    object: data.object,
    project: data.project,
    projectId: data.projectId,
    type: types.PROJECT_OVERVIEW_UPDATED,
  };
};
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { menu } from '@consts';
import { selectUnreadMessagesCount } from '@store/selectors';
import { BadgeNotificationsContext } from './Context';

const mapState = (state: Store.State) => ({
  [menu.Messages]: selectUnreadMessagesCount(state),
  [menu.Notifications]: state.notifications.items.length,
});

type Props = {
  children: React.ReactElement;
};

type State = {
  [menu.Messages]: number;
  [menu.Notifications]: number;
};

export const BadgeNotificationsContainer = (props: Props) => {
  const stats: State = useSelector(mapState);
  const total = useMemo(() => Object.values(stats).reduce((acc, n) => acc + n, 0), [stats]);

  return (
    <BadgeNotificationsContext.Provider value={{ ...stats, total }}>
      {props.children}
    </BadgeNotificationsContext.Provider>
  );
};
import { useCallback } from 'react';
import { SurveyActionType } from '@enums/Survey';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { SurveyLogic } from '@/types/survey';

export const useGetOrdinalFromAction = () => {
  const [state] = useSurveyBuilderState();

  const findSection = useCallback((identifier: string) => {
    return state.survey.sections.find(f => f.identifier === identifier);
  }, [state.survey.sections]);

  const findQuestion = useCallback((identifier: string) => {
    return state.survey.questions.find(f => f.base.identifier === identifier);
  }, [state.survey.questions]);

  return useCallback((action: SurveyLogic.Action) => {

    switch (action.type) {
      case SurveyActionType.SkipToQuestion: {
        const question = findQuestion(action.identifier);
        return question.ordinal;
      }

      case SurveyActionType.IncludeSection:
      case SurveyActionType.SkipToSection: {
        const section = findSection(action.identifier);
        return section.ordinal;
      }

      default:
        return null;
    }

  }, [
    findQuestion,
    findSection,
  ]);
};
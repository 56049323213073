import { UserEmployment } from '@presentation';
import { cx, formatTenure } from '@utils';
import { UserEmploymentsItemProps } from '@/types';
import styles from './style.css';

export type ItemEmploymentsProps = {
  current?: UserEmploymentsItemProps | null;
  former?: UserEmploymentsItemProps | null;
};

type Props = {
  className?: string;
} & ItemEmploymentsProps;

const ItemEmployments = (props: Props) => {
  return (
    <div className={cx(styles.copy, props.className)}>
      {props.current && createElement('Current', props.current)}
      {props.former && createElement('Former', props.former)}
    </div>
  );
};

function createElement(label: string, item: UserEmploymentsItemProps) {
  return (
    <UserEmployment
      label={label}
      company={item.company}
      title={item.title}
      tenure={formatTenure(item)} />
  );
}

export default ItemEmployments;
import { useEffect, useState } from 'react';
import * as api from '@api';
import { useConditionalRender } from '@utils';
import { useProjectTargetCompany } from '@utils/api';
import { Project, TargetCompany as TargetCompanyType } from '@/types';
import { Editable } from 'components/Editable';
import { TargetCompanyForm } from './TargetCompanyForm';
import { TargetCompanySubmitData } from './interfaces';
import styles from './style.css';

type Props = {
  editable: boolean;
  item:     Project;
};

const TargetCompany = (props: Props) => {
  const projectTargetCompany = useProjectTargetCompany(props.item.id);
  const [editing, setEditing] = useState(false);
  const [targetCompany, setTargetCompany] = useState<TargetCompanyType>(null);

  useEffect(() => {

    if (projectTargetCompany.value) {
      setTargetCompany(projectTargetCompany.value);
    }

  }, [projectTargetCompany.value]);

  const readonly = (
    <div className={styles.row}>
      <div className={styles.label}>Target Company</div>
      <TargetCompanyStatic />
    </div>
  );

  const form = (
    <div className={styles.row}>
      <div className={styles.label}>Target Company</div>
      <TargetCompanyForm
        onCancel={toggleEditing}
        onSubmit={handleSubmit}
        targetCompany={targetCompany} />
    </div>
  );

  const $EditableField = useConditionalRender(props.editable && !editing, readonly);
  const $StaticField = useConditionalRender(!editing);
  const $FormField = useConditionalRender(props.editable && editing);

  function TargetCompanyStatic() {
    if (projectTargetCompany.loading) {
      return <div className={styles.field} />;
    }

    const company = targetCompany?.name;

    return company
         ? <div className={styles.field}>{company}</div>
         : <div className={styles.noneText}>None</div>;
  }

  function handleSubmit(form: TargetCompanyType, data: TargetCompanySubmitData) {
    api.projects.targetCompany.update({
      projectId: props.item.id,
      targetCompany: data,
    })
    .then(res => {
      const updated = res.targetCompanyId !== null
          ? { id: res.targetCompanyId, name: form.name }
          : null;

      setTargetCompany(updated);
      setEditing(false);
    });
  }

  function toggleEditing() {
    setEditing(!editing);
  }

  return (
    <div className={styles.card}>
      <$StaticField>
        <$EditableField>
          <Editable onClick={toggleEditing}>
            {readonly}
          </Editable>
        </$EditableField>
      </$StaticField>

      <$FormField>
        {form}
      </$FormField>
    </div>
  );
};

export { TargetCompany };
export default TargetCompany;
import { createElement, useCallback, useEffect, useRef, Fragment } from 'react';
import { useMounted } from '@utils/hooks/useMounted';

type InjectedProps = {
  condition: boolean;
};

type ConditionalProps = {
  children: React.ReactNode;
};

const useConditionalRender = (condition: boolean, fallback: React.ReactNode = null) => {
  const state = !!condition;
  const fallbackRef = useRef(fallback);
  const ref = useRef(Conditional);
  const isMounted = useMounted();

  useEffect(() => {
    if (isMounted()) {
      ref.current = Conditional;
    }
  }, [isMounted]);

  useEffect(() => {
    if (isMounted()) {
      fallbackRef.current = fallback;
    }
  }, [fallback, isMounted]);

  function Conditional<T extends InjectedProps>(props: ConditionalProps & T) {
    const children = !props.condition
        ? renderFallback(fallbackRef.current)
        : props.children;

    return createElement(Fragment, null, children);
  }

  return useCallback((props: ConditionalProps) => {
    return ref.current({
      children: props.children,
      condition: state,
    });
  }, [state]);
};

function renderFallback(element: React.ReactNode): React.ReactChild | React.ReactNode {
  const isComponent = (c: React.ReactNode): c is React.ComponentType => typeof c === 'function';

  return isComponent(element)
       ? createElement(element)
       : element;
}

export { useConditionalRender };
export default useConditionalRender;
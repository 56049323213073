import { useCallback, useMemo, Fragment } from 'react';
import { Node } from 'slate';
import { useVideoPostStateContext, useVideoPostStepperContext } from '@containers/PostCreation/hooks/usePostCreationContext';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import * as Editor from './Editor';
import * as Publisher from './Publisher';
import { TopicTags } from './Topics.Tags';
import { ThumbnailFileInput } from './Video.ContentForm.ThumbnailFileInput';
import { ThumbnailFilePreview } from './Video.ContentForm.ThumbnailFilePreview';
import { ThumbnailFileContext } from './Video.Context';
import styles from './style/Video.Frame.ContentForm.css';

type Props = unknown;

export const ContentForm = (props: Props) => {
  const [state, dispatch] = useVideoPostStateContext();
  const actions = useVideoPostStepperContext();

  const handleChangeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'update-video-title',
      value: e.target.value,
    });
  }, [dispatch]);

  const handleChangeDescription= useCallback((value: Node[]) => {
    dispatch({
      type: 'update-video-description',
      value: { value },
    });
  }, [dispatch]);

  const file = state.thumbnail;
  const setThumbnailFile = useCallback((file: File) => {
    dispatch({
      file,
      type: 'set-thumbnail-file',
    });
  }, [dispatch]);

  const nextDisabled = useMemo(() => {
    return !state.title.length;
  }, [state.title]);

  return (
    <Fragment>

      <Publisher.Header>
        Create Post
      </Publisher.Header>

      <Publisher.Author className={styles.author} />

      <div className={styles.main}>

        <div className={styles.upload}>
          <ThumbnailFileContext.Provider value={[file, setThumbnailFile]}>
            <ThumbnailFilePreview />
            <ThumbnailFileInput />
          </ThumbnailFileContext.Provider>
        </div>

        <div className={styles.label}>Title</div>
        <div className={styles.title}>
          <Input
            focusableClasses={{
              invalid: styles.invalid,
              root: styles.input,
              unfocused: styles.unfocused,
            }}
            onChange={handleChangeTitle}
            placeholder="Enter a title"
            value={state.title} />
        </div>

        <div className={styles.label}>Description</div>
        <div className={styles.textarea}>
          <Editor.TextField
            placeholder="Enter a description"
            onChange={handleChangeDescription}
            value={state.description.value} />
        </div>
      </div>

      <TopicTags className={styles.topics} />

      <Publisher.Footer>
        <Button
          className={styles.back}
          onClick={actions.back}
          variant="text">
          Back
        </Button>
        <Button
          className={styles.next}
          disabled={nextDisabled}
          onClick={actions.next}
          variant="brick">
          Next
        </Button>
      </Publisher.Footer>

    </Fragment>
  );
};

ContentForm.displayName = 'PostCreation.Frame.Video.ContentForm';
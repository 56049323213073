import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { useSuggestedMentionsContext } from 'components/Remirror/Context';
import { SuggestedMentionTransformed } from 'components/Remirror/interfaces.mention';

export const useFetchSuggestedMentions = (q: string, options: Options = {}) => {
  const [queryKey, fetchSuggestions] = useSuggestedMentionsContext();

  return useQuery([queryKey, q], ({ queryKey }) => {

    const query = queryKey[1];

    return fetchSuggestions(query)
      .then(res => {
        return res.map(r => ({
          id: r.id.toString(),
          label: r.name,
        }));
      });
  }, options);

};

type QueryKey = [string, string];
type Data = SuggestedMentionTransformed[];
type Error = AxiosResponse;
type Variables = unknown;
type Options = UseQueryOptions<Variables, Error, Data, QueryKey>;
import { Route } from 'react-router-dom';
import { ExtendedStringifyOptions, QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params';

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

type Props = ChildrenProps;

export const QueryParamsClient = (props: Props) => {
  return (
    <QueryParamProvider
      ReactRouterRoute={Route}
      stringifyOptions={queryStringifyOptions}>
      {props.children}
    </QueryParamProvider>
  );
};

export default QueryParamsClient;
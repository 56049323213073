import { useContext, useMemo } from 'react';
import { useMutation } from 'react-query';
import * as api from '@api';
import * as Post from '@containers/Post';
import { TopicItemContext, TopicPostsContext } from '@containers/Topic/Context';

type Props = {
  children: React.ReactNode;
  postId:   number;
};

export const TopicPostsDeleteContainer = (props: Props) => {
  const { query: overview } = useContext(TopicItemContext);
  const { query: posts } = useContext(TopicPostsContext);

  const ids = useMemo(() => {
    return overview.data.meta.hasPosts
      ? posts.data?.pages.flatMap(p => p.items.map(x => x.id))
      : [];
  }, [
    overview.data.meta.hasPosts,
    posts.data?.pages,
  ]);

  const mutation = useMutation(() => {
    return api.posts.deletePost({
      postId: props.postId,
    });
  }, {
    onSuccess: () => {
      const remainingIds = ids.filter(id => id !== props.postId);

      !remainingIds.length
        ? overview.refetch().then(() => posts.remove())
        : posts.refetch();
    },
    onError: e => {
      console.log('posts error');
      console.log(e);
    },
  });

  return (
    <Post.DeleteContainer
      postId={props.postId}
      onConfirm={mutation.mutateAsync}>
      {props.children}
    </Post.DeleteContainer>
  );
};

TopicPostsDeleteContainer.displayName = 'Topic.Post.DeleteContainer';
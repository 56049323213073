import { useCallback, useMemo } from 'react';
import { useProjectSurveyResponsesState, useSurveyQuestionResponsesData } from '@containers/SurveyResponses/hooks';
import { SurveyQuestionType } from '@enums';
import { OpenedResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import { formatPlurality } from '@utils';
import { useOptionsColors } from './hooks/usePaletteColors';
import ResponseOptionUsers from './ResponseOptionUsers';

export const SurveyResponseOptionsOptionData = () => {
  const { query, resetQueryState } = useProjectSurveyResponsesState<SurveyQuestionType.MultipleChoice>();
  const { question, responses } = useSurveyQuestionResponsesData<SurveyQuestionType.MultipleChoice>();
  const colors = useOptionsColors();

  const option = useMemo(() => {
    return question.options.find(f => f.base.id === query.optionId);
  }, [
    question.options,
    query.optionId,
  ]);

  const data = useMemo(() => {
    return responses[option.base.id];
  }, [
    option.base.id,
    responses,
  ]);

  const renderHeader = useCallback(() => {
    const color = colors[option.ordinal - 1];
    const subtitle = `(${data.userIds.length} ${formatPlurality('Respondent', data.userIds.length)})`;

    return (
      <OpenedResponseOptionRow
        onClose={resetQueryState}
        color={color}
        label={option.value}
        subtitle={subtitle} />
    );
  }, [
    data,
    colors,
    option,
    resetQueryState,
  ]);

  const renderUsers = useCallback(() => {
    return (
      <ResponseOptionUsers
        item={data}
        option={option}
        question={question} />
    );
  }, [
    data,
    option,
    question,
  ]);

  return (
    <>
      {renderHeader()}
      {renderUsers()}
    </>
  );
};

export default SurveyResponseOptionsOptionData;
import { useEffect } from 'react';
import {
  useFolderCapabilities,
} from '@containers/Store';
import {
  useFetchObjectAccess,
  useObjectAccessState,
} from '@containers/ObjectAccess';
import { useWorkspaceFolderState } from './hooks';

type Props =
  ChildrenProps;

export const FolderDetailsContainer = (props: Props) => {

  const { object } = useWorkspaceFolderState();
  const capabilities = useFolderCapabilities(object.entityId);

  const fetchAccess = useFetchObjectAccess();
  const [{ items: access }] = useObjectAccessState();

  useEffect(() => {
    if (capabilities.canManageAccess && !access.length) {
      fetchAccess();
    }
  }, [
    access.length,
    capabilities.canManageAccess,
    fetchAccess,
  ]);

  return (
    <>
      {props.children}
    </>
  );
};

export default FolderDetailsContainer;
import * as consts from '@consts';
import Overview from './Overview';
import NewPayoutMethod from './NewPayoutMethod';
import UpdatePayoutMethod from './UpdatePayoutMethod';
import NewPaymentMethod from './NewPaymentMethod';
import VerifyBankAccount from './VerifyBankAccount';

export default [
  {
    name: 'Overview',
    to: consts.path.Settings.UserBilling,
    component: Overview,
    exact: true,
  },
  {
    name: 'New Payout Method',
    to: consts.path.Settings.UserBillingNewPayoutMethod,
    component: NewPayoutMethod,
    exact: true,
  },
  {
    name: 'Update Payout Method',
    to: consts.path.Settings.UserBillingUpdatePayoutMethod,
    component: UpdatePayoutMethod,
    exact: true,
  },
  {
    name: 'New Payment Method',
    to: consts.path.Settings.UserBillingNewMethod,
    component: NewPaymentMethod,
    exact: true,
  },
  {
    name: 'Verify Bank Account',
    to: consts.path.Settings.UserBillingVerify,
    component: VerifyBankAccount,
    exact: true,
  },
];
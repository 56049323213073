import { useCallback, useMemo } from 'react';
import { SurveyOptionType } from '@enums';
import { ResponseOptionFreeTextItem, ResponseTextItemProps } from '@presentation';
import { SurveyQuestion, SurveyQuestionOption, SurveyAggregate } from '@/types';
import { ResponseTextList, useSortStarrableResponses } from './ResponseText';
import { ResponseOptionUser } from './ResponseOptionUser';

type Props = {
  item: SurveyAggregate.Options.Data;
  option: SurveyQuestionOption;
  question: SurveyQuestion;
};

export const ResponseOptionUsers = ({ item, option, question }: Props) => {

  const sortValues = useSortStarrableResponses(question.base.id);

  const items = useMemo(() => {
    return item.userIds.map(userId => {
      return {
        userId: userId,
        value: item.text[userId],
      };
    });
  }, [
    item,
  ]);

  const sortedItems = useMemo(() => {
    return sortValues(items);
  }, [
    items,
    sortValues,
  ]);

  const renderFreeTextItem = useCallback((data: ResponseTextItemProps) => {
    return (
      <ResponseOptionFreeTextItem {...data} />
    );
  }, []);

  if (option.type === SurveyOptionType.Other) {
    return (
      <ResponseTextList
        items={sortedItems}
        question={question}
        renderItem={renderFreeTextItem} />
    );
  } else {
    return (
      <>
        {items.map(m => (
          <ResponseOptionUser
            key={m.userId}
            userId={m.userId}
            value={m.value} />
        ))}
      </>
    );
  }
};

export default ResponseOptionUsers;
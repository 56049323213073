import { useContext } from 'react';
import { cx } from '@utils';
import * as mixpanel from '@services/mixpanel';
import { ComplianceOnboardingContext } from '@containers';
import data from './data';
import { ComplianceRulesProps } from './interfaces';
import styles from './style.css';

export const RulesScreen = (props: Partial<Pick<ComplianceRulesProps, 'title'>>) => {
  const ctx = useContext(ComplianceOnboardingContext);

  return (
    <OnboardingRules
      onComplete={ctx.next}
      title={props.title} />
  );
};

export const OnboardingRules = ({
  items = data,
  title = 'Terms',
  ...props
}: ComplianceRulesProps) => {
  const handleNext = () => {
    mixpanel.event.onboarding.complianceRules.read();
    props.onComplete();
  };

  const headline = `By accepting this agreement you acknowledge that you have reviewed and will comply with the following terms relating to insider trading, securities laws compliance, and other relevant items. Failure to abide by these terms may result in termination of services and could in some situations subject you to civil or criminal penalties.`;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.h2}>{title}</div>
        <div className={styles.scroll}>
          <div className={styles.title}>Vancery Expert Compliance Agreement</div>
          <div className={styles.headline}>{headline}</div>
          {items.map((x, i) =>
            <div key={x.title}>
              <div className={styles.title}>{`${i + 1}. ${x.title}`}</div>
              <ul className={styles.ul}>
                {x.bullets.map(y =>
                  <li
                    className={styles.li}
                    key={y}>
                    {y}
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        <div className={styles.btns}>
          <button
            className={cx(styles.btn, styles.next)}
            onClick={handleNext}
            type="button">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingRules;
import * as enums from '@enums';
import { cx } from '@utils';
import { Briefcase } from 'components/images';
import styles from './style/ProjectsTableEmptyState.css';

type Props = {
  className?: string;
  creationDisabled?: boolean;
  status: enums.ProjectStatus | null;
};

export const ProjectsEmptyState = ({
  className,
  creationDisabled,
  status,
}: Props) => {
  const text = [
    'No',
    status && enums.utils.ProjectStatus.getName(status).toLowerCase(),
    'projects',
  ]
  .filter(Boolean)
  .join(' ');

  return (
    <div className={cx(styles.root, className)}>
      <Briefcase
        className={styles.img}
        color="var(--gray-l)" />
      <div className={styles.title}>{text}</div>
      {!creationDisabled &&
        <div className={styles.text}>Create a project to get started</div>}
    </div>
  );
};

export default ProjectsEmptyState;
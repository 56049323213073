import { useCallback } from 'react';
import { useSelectUser } from '@containers/Store/hooks/useSelectUser';
import * as mixpanel from '@services/mixpanel';
import { Survey } from '@services/mixpanel/interfaces';

const useSurveyQuestionCompleteEvent = () => {
  const user = useSelectUser();

  const completeQuestion = useCallback((data: Omit<Survey.CompleteQuestion, 'user'>) => {
    mixpanel.event.survey.completeQuestion({
      user: {
        id: user.id,
        name: user.profile.fullname,
      },
      id: data.id,
      response: data.response,
      version: data.version,
      value: data.value,
    });
  }, [
    user.id,
    user.profile.fullname,
  ]);

  return completeQuestion;
};

export { useSurveyQuestionCompleteEvent };
export default useSurveyQuestionCompleteEvent;
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as consts from '@consts';
import { ComplianceOnboarding } from '@containers';
import { useRouteStepper } from '@utils/hooks';
import { LinearProgress } from 'components/Progress/LinearProgress';
import Toast from 'components/Toast';
import styles from '../style.css';
import routes from './routes';

const mapState = (state: Store.State) => ({
  onboarding: state.onboarding,
  user: state.user,
});

export const Compliance = () => {
  const state = useSelector(mapState);
  const history = useHistory();

  useEffect(() => {
    if (state.onboarding.completed) {
      history.replace(consts.pathname.HOME);
    }
  }, []);

  const [Routes, stepperActions, step] = useRouteStepper(routes);

  const dispatchNotifications = () => {
    Toast.alert({
      title: 'Vancery Onboarding Completed',
      body: 'Thank you for completing the compliance training. You are now cleared to participate in projects.',
    });
  };

  const exitOnboarding = () => {
    history.push(consts.pathname.HOME);
  };

  const handleCompletion = () => {
    dispatchNotifications();
    exitOnboarding();
  };

  const progress = (step / routes.length) * 100;

  return (
    <>
      <div className={styles.progress}>
        <LinearProgress value={progress} />
      </div>
      <ComplianceOnboarding
        back={stepperActions.back}
        next={stepperActions.next}
        onCompletion={handleCompletion}>
        <Routes />
      </ComplianceOnboarding>
    </>
  );
};

export default Compliance;
import { useLogout } from '@containers/AppStateEffect/hooks';
import { BlankCalendar } from 'components/images';
import Button from 'components/Button';
import styles from './style.css';

function TransientDashboard() {
  const logout = useLogout();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.calendar}>
          <BlankCalendar
            className={styles.calendar} />
        </div>
        <div className={styles.text}>
          Contact your client point of contact to gain access to upcoming calls on Vancery.
        </div>
        <div className={styles.actions}>
          <Button.Primary
            className={styles.btn}
            variant='brick'
            type='button'
            onClick={() => logout({ sideEffects: true })}>
            Sign Out
          </Button.Primary>
        </div>
      </div>
    </div>
  );
}

export { TransientDashboard };
export default TransientDashboard;
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRehydrateApp } from '@containers/Store';
import { useHydrateEffect, useSignOutEffect } from '@containers/AppReadyState';
import { NotificationsSocketEvent } from '@enums';
import * as actions from '@store/actions';
import * as ws from '@services/websocket';
import { Notification } from '@/types';
import { useFetchNotifications } from './hooks';

type Props =
  ChildrenProps;

export const NotificationsContainer = (props: Props) => {
  const fetchNotifications = useFetchNotifications();

  const dispatch = useDispatch();
  const rehydrateApp = useRehydrateApp();

  const handleDismiss = useCallback((data: { notificationIds: number[]; }) => {
    dispatch(actions.notificationsDismissed({
      ids: data.notificationIds,
    }));
  }, [
    dispatch,
  ]);

  const handleSeen = useCallback((data: { notificationIds: number[]; }) => {
    dispatch(actions.notificationsSeen({
      ids: data.notificationIds,
    }));
  }, [
    dispatch,
  ]);

  const handleNew = useCallback(async (notification: Notification) => {
    return rehydrateApp()
      .then(_ => {
        dispatch(actions.notificationAdded({
          notification,
        }));
      });
  }, [
    dispatch,
    rehydrateApp,
  ]);

  const register = useCallback(() => {
    ws.notifications.open();

    ws.notifications.on(NotificationsSocketEvent.New, handleNew);
    ws.notifications.on(NotificationsSocketEvent.Dismiss, handleDismiss);
    ws.notifications.on(NotificationsSocketEvent.Seen, handleSeen);
    ws.notifications.on(NotificationsSocketEvent.AppRehydrate, rehydrateApp);
  }, [
    handleNew,
    handleDismiss,
    handleSeen,
    rehydrateApp,
  ]);

  const unregister = useCallback(() => {
    ws.notifications.close();

    ws.notifications.off(NotificationsSocketEvent.New, handleNew);
    ws.notifications.off(NotificationsSocketEvent.Dismiss, handleDismiss);
    ws.notifications.off(NotificationsSocketEvent.Seen, handleSeen);
    ws.notifications.off(NotificationsSocketEvent.AppRehydrate, rehydrateApp);
  }, [
    handleNew,
    handleDismiss,
    handleSeen,
    rehydrateApp,
  ]);

  useHydrateEffect(fetchNotifications);

  useHydrateEffect(register);
  useSignOutEffect(unregister);

  useEffect(() => {
    return () => {
      unregister();
    };
  }, [unregister]);

  return (
    <>
      {props.children}
    </>
  );
};

export default NotificationsContainer;
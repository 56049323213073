import { useCallback } from 'react';
import { useStepper } from '@utils';
import { CallRatingProps } from './interfaces';
import { CallRating } from './CallRating';
import styles from './style.css';

export type Props = {
  callIds: number[];
  onCompletion?: () => void;
};

const RatingsStepper = ({ callIds, onCompletion }: Props) => {
  const steps = new Array<React.ComponentType<CallRatingProps>>(callIds.length);
  steps.fill(CallRating);
  const [Screen, { next }, step] = useStepper(steps);

  const handleSubmit = useCallback(() => {
    if (step === callIds.length - 1) {
      onCompletion();
    } else {
      next();
    }
  }, [
    callIds.length,
    next,
    onCompletion,
    step,
  ]);

  const renderProgress = useCallback(() => {
    if (callIds.length === 1) {
      return null;
    }

    return (
      <div className={styles.callCount}>
        {step + 1} of {callIds.length}
      </div>
    );
  }, [
    callIds.length,
    step,
  ]);

  return (
    <div className={styles.root}>
      {renderProgress()}
      <Screen
        key={callIds[step]}
        callId={callIds[step]}
        onSubmit={handleSubmit} />
    </div>
  );
};

export { RatingsStepper };
export default RatingsStepper;
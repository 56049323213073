import {
  useSurveyFormQuestionContext,
  useConjointAnalysisFormContext,
} from '@containers/SurveyForm';
import { SurveyQuestionType } from '@enums';
import Set from './ConjointAnalysis.Set';
import styles from './style/ConjointAnalysis.css';

export const ConjointAnalysisForm = () => {
  const { answer, question } = useSurveyFormQuestionContext<SurveyQuestionType.ConjointAnalysis>();
  const {
    currentSet,
    setSetAnswer,
  } = useConjointAnalysisFormContext();

  const progressText = `Set ${currentSet} of ${question.sets.length}`;

  return (
    <div className={styles.root}>
      <div className={styles.progress}>{progressText}</div>
      <div className={styles.packages}>
        <Set
          attributes={question.attributes}
          onChange={setSetAnswer(currentSet)}
          set={question.sets[currentSet - 1]}
          value={answer?.items[currentSet - 1]}  />
      </div>
    </div>
  );
};

export default ConjointAnalysisForm;
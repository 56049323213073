import { useMemo } from 'react';
import { SurveyOptionType, SurveyQuestionType } from '@enums';
import { useSurveyResponseGoBack, useSubmitAnswer, useSurveyStepperNavigation, useSurveyFormQuestionContext, useResponseLengthModal } from './hooks';
import { SurveyFormNavigationContext } from './Context';

type Props =
  ChildrenProps;

export const MatrixMultiselectFormContainer = (props: Props) => {
  const {
    answer,
    question,
  } = useSurveyFormQuestionContext<SurveyQuestionType.MatrixMultiselect>();

  const submitAnswer = useSubmitAnswer();
  const goBack = useSurveyResponseGoBack();
  const navigation = useSurveyStepperNavigation(question.ordinal);

  const canSubmit = useMemo(() => {
    if (!answer?.items) return false;

    const naOption = question.options.find(f => f.type === SurveyOptionType.NotApplicable);

    return question.matrixRows.reduce((acc, row) => {

      const item = answer.items.find(f => f.matrixRowId === row.id);

      if (!item?.optionIds?.length) return false;

      const naChecked = item.optionIds.some(optionId => naOption?.id === optionId);

      if (naChecked) return acc;

      if (question.settings.maximumSelections &&
        item.optionIds.length > question.settings.maximumSelections) {
        return false;
      }

      if (question.settings.minimumSelections &&
        item.optionIds.length < question.settings.minimumSelections) {
        return false;
      }

      return acc;
    }, true);

  }, [
    answer,
    question.options,
    question.matrixRows,
    question.settings,
  ]);

  const value = {
    back: {
      hidden: !navigation.showBack,
      isError: goBack.isError,
      isLoading: goBack.isLoading,
      submit: goBack.mutateAsync as () => Promise<unknown>,
    },
    next: {
      disabled: !canSubmit,
      isError: submitAnswer.isError,
      isLoading: submitAnswer.isLoading,
      submit: submitAnswer.mutateAsync as () => Promise<unknown>,
    },
    showComplete: navigation.showComplete,
  };

  return (
    <SurveyFormNavigationContext.Provider value={value}>
      {props.children}
    </SurveyFormNavigationContext.Provider>
  );
};

export default MatrixMultiselectFormContainer;
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks/useProjectState';
import * as enums from '@enums';
import SearchBanner from './SearchBanner';
import MassPipelineTable from './MassPipelineTable';
import styles from './style/MassPipeline.css';

export const MassPipeline = () => {
  const project = useProjectOrLatestChild();

  return (
    <div className={styles.root}>
      {project.projectStatusId === enums.ProjectStatus.Active &&
        <SearchBanner item={project} />}
      <MassPipelineTable />
    </div>
  );
};

export default MassPipeline;
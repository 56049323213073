import { combineReducers } from 'redux';
import * as enums from '@enums';
import * as types from '@store/action-types';
import { pipeline as initialState } from '@store/initial-state';
import {
  ApplicationActions,
  ProjectClonedAction,
  ProjectCreatedAction,
  ProjectPipelineItemChangedAction,
  ProjectPipelineUpdatedAction,
  ProjectRemovedAction,
} from '@store/interfaces';
import {
  PipelineProjectAttributes,
} from '@/types';

type UserAction =
    ApplicationActions
  | ProjectPipelineUpdatedAction
  | ProjectPipelineItemChangedAction
  | ProjectRemovedAction;

function me(state: Store.Pipeline.Me = initialState.me, action: UserAction): Store.Pipeline.Me {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.pipeline.me;

    case types.PROJECT_PIPELINE_UPDATED: {
      const updated = { [action.projectId]: action.pipeline.me[action.projectId] };

      return { ...state, ...updated };
    }

    case types.PROJECT_PIPELINE_ITEM_CHANGED: {

      return {
        ...state,
        [action.record.projectId]: action.record,
      };
    }

    case types.PROJECT_REMOVED: {
      const { [action.projectId]: _, ...other } = state;

      return other;
    }

    default:
      return state;
  }
}

type ProjectAction =
    ApplicationActions
  | ProjectClonedAction
  | ProjectCreatedAction
  | ProjectPipelineItemChangedAction
  | ProjectPipelineUpdatedAction;

function project(state = initialState.project, action: ProjectAction): Store.Pipeline.Project {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.pipeline.project;

    case types.PROJECT_PIPELINE_ITEM_CHANGED: {
      const { [action.record.projectId]: project } = state;

      return {
        ...state,
        [action.record.projectId]: attributes(project, action),
      };
    }

    case types.PROJECT_CREATED:
      return {
        ...state,
        [action.projectId]: attributes(undefined, action),
      };

    case types.PROJECT_CLONED:
      return {
        ...state,
        [action.project.id]: action.pipeline.project[action.project.id],
        [action.previous.project.id]: action.previous.pipeline.project[action.previous.project.id],
      };

    case types.PROJECT_PIPELINE_UPDATED: {
      const { [action.projectId]: _, ...other } = state;
      const updated = { [action.projectId]: action.pipeline.project[action.projectId] };

      return { ...other, ...updated };
    }

    default:
      return state;
  }
}

type ProjectAttributesAction =
    ProjectCreatedAction
  | ProjectClonedAction
  | ProjectPipelineItemChangedAction
  | ProjectPipelineUpdatedAction;

const initialProjectAttributesState = {
  users: {},
} as PipelineProjectAttributes;

function attributes(state = initialProjectAttributesState, action: ProjectAttributesAction): PipelineProjectAttributes {
  switch (action.type) {
    case types.PROJECT_PIPELINE_ITEM_CHANGED: {
      return {
        ...state,
        users: {
          ...state.users,
          [action.record.userId]: action.record,
        },
      };
    }

    case types.PROJECT_CREATED:
      return {
        respondents: {},
        users: {},
      };

    case types.PROJECT_CLONED:
      return {
        respondents: {},
        users: {},
      };

    default:
      return state;
  }
}

export default combineReducers({
  me,
  project,
});
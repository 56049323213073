import TextInput from './TextInput';
import { ZipFieldProps } from './interfaces';

type Props =
    ZipFieldProps;

export default function ZipField(props: Props) {
  if (!props.show) return null;

  return (
    <TextInput
      className={props.className}
      labelClassName={props.labelClassName}
      error={props.error}
      placeholder={props.placeholder}
      label={props.label}
      name='zip'
      value={props.value}
      required={props.required}
      onChange={props.onChange} />
  );
}

ZipField.defaultProps = {
  label: 'Zip Code',
  placeholder: 'Zip Code',
  show: true,
};

export { ZipField as Zip };
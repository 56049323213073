import { useCallback } from 'react';
import { SurveyTemplateEntityType } from '@enums/survey.template';
import { useSurveyBuilderState, useIsEditingSurvey } from '@containers/SurveyBuilder';
import { AddQuestionRowButton, ConditionalResponseIcon } from '@presentation/SurveyBuilder';
import { TemplateTarget } from '@presentation/SurveyPreview/Template.Target';
import { TemplateCompetitor } from '@presentation/SurveyPreview/Template.Competitor';
import { Tooltip } from '@presentation/Tooltip';
import { SurveyQuestionMatrixRow, SurveyQuestionRowMetadata } from '@/types/survey';
import { TextEntryListItem, RenderTextEntryListInputProps, TextEntryItem, TextEntryListInput } from 'components/TextEntryList';
import { QuestionRowsBuilderProps } from './interfaces';

type Props =
  QuestionRowsBuilderProps;

type ListItem = {
  metadata: SurveyQuestionRowMetadata;
} & TextEntryItem;

export const QuestionRowsBuilder = (props: Props) => {

  const [state] = useSurveyBuilderState();
  const editing = useIsEditingSurvey();

  const renderConditionalIcon = useCallback((option: SurveyQuestionMatrixRow) => {
    if (!option.conditions.length) return null;

    const dependentOrdinal = state.survey.questions
      .find(f => f.base.identifier === option.conditions[0].question.identifier).ordinal;

    const tooltipText = `Conditional upon response to Q${dependentOrdinal}`;

    return (
      <Tooltip title={tooltipText}>
        <div>
          <ConditionalResponseIcon />
        </div>
      </Tooltip>
    );
  }, [state.survey.questions]);

  const renderItem = useCallback((p: RenderTextEntryListInputProps<ListItem>) => {

    if (p.item.metadata.template.linkedEntity?.type === SurveyTemplateEntityType.Target) {
      return (
        <TemplateTarget
          disabled={editing}
          value={p.item.value} />
      );
    } else if (p.item.metadata.template.linkedEntity?.type === SurveyTemplateEntityType.Competitor) {
      return (
        <TemplateCompetitor
          disabled={editing}
          value={p.item.value} />
      );
    } else {
      return <TextEntryListInput<ListItem> {...p} />;
    }

  }, [editing]);

  return (
    <div className={props.className}>
      {props.rows.map(row => (
        <TextEntryListItem<ListItem>
          key={row.ordinal}
          disabled={!row.metadata.canModifyValue}
          item={{
            key: row.ordinal,
            value: row.value,
            metadata: row.metadata,
          }}
          renderInput={renderItem}
          placeholder="Enter a row"
          onRemove={props.removeRow(row.base.identifier)}
          onUpdate={props.updateRowValue(row.base.identifier)}
          removeEnabled={props.removeRowEnabled && row.metadata.canDelete}>
          {renderConditionalIcon(row)}
        </TextEntryListItem>
      ))}
      {props.addRowEnabled &&
        <AddQuestionRowButton
          onClick={props.addRow} />}
    </div>
  );
};

export default QuestionRowsBuilder;
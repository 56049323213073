import { useCallback, useMemo } from 'react';
import { EnumKey } from '@enums/interfaces';
import { cx } from '@utils';
import { DropDown } from './DropDown';
import { EnumDropDownItem, EnumDropDownProps } from './interfaces';
import styles from './style.css';

export function EnumDropDown<T extends EnumKey>(props: EnumDropDownProps<T>) {
  const items = useMemo(() => {
    return (props.items || props.enum.values()).map(k => ({
      id: k,
      name: props.enum.getName(k),
    }));
  }, [props.enum, props.items]);

  const handleSelect = useCallback((item: EnumDropDownItem<T>) => {
    props.onSelect(item.id);
  }, [props.onSelect]);

  const text = useMemo(() => props.enum.getName(props.value), [props.enum, props.value]);

  const textClass = cx({
    [styles.placeholder]: !text,
  });

  return (
    <DropDown<EnumDropDownItem<T>>
      autoFocus={props.autoFocus}
      items={items}
      getItemValue={i => i.name}
      onSelect={handleSelect}
      text={text || props.placeholder}
      textClass={textClass}
    />
  );
}

export default EnumDropDown;
import { Countries } from 'components/AddressEntry';
import { AddressDisplayFullAddressProps } from './interfaces';
import styles from './style.css';

type Props =
  AddressDisplayFullAddressProps;

export default function FullAddress({ address }: Props) {
  const line1 = `${address.address1}`;
  const line2 = `${address.address2}`;
  const line3 = [address.city, address.state, address.zip].filter(v => v?.length).join(', ');
  const line4 = `${lookupCountry(address.country)}`;

  return (
    <div className={styles.root}>
      <div className={styles.line}>{line1}</div>
      {!!line2.length && <div className={styles.line}>{line2}</div>}
      <div className={styles.line}>{line3}</div>
      <div className={styles.line}>{line4}</div>
    </div>
  );
}

function lookupCountry(value: string) {
  const country = Countries.World.find(c => c.id === value);
  return country ? country.name : value;
}

export { FullAddress };
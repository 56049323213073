import { SearchFiltersContext } from './Context';
import { useSearchFiltersState } from './hooks/useSearchFilterState';

export default function Filters({ children }: ChildrenProps) {
  const [filters, actions] = useSearchFiltersState();

  return (
    <SearchFiltersContext.Provider value={[filters, actions]}>
      {children}
    </SearchFiltersContext.Provider>
  );
}

export { Filters };

import http from '@services/http';
import { Tags } from './interfaces';

export const getPersonTags = ({ userId }: Tags.GetGroupPersonTags.Request) => {
  return http.get(`/tags/group/person/${userId}`)
    .then((data: Tags.GetGroupPersonTags.Response) => ({
      tags: data.tags,
    }));
};

export const updatePersonTags = ({ userId, ...payload }: Tags.UpdateGroupPersonTags.Request) => {
  return http.put(`/tags/group/person/${userId}`, payload)
    .then((data: Tags.UpdateGroupPersonTags.Response) => ({
      tags: data.tags,
    }));
};

export const getFileTags = ({ fileId }: Tags.GetGroupFileTags.Request) => {
  return http.get(`/tags/group/file/${fileId}`)
    .then((data: Tags.GetGroupFileTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateFileTags = ({ fileId, ...payload }: Tags.UpdateGroupFileTags.Request) => {
  return http.put(`/tags/group/file/${fileId}`, payload)
    .then((data: Tags.UpdateGroupFileTags.Response) => ({
      tags: data.tags,
    }));
};

export const getCallTags = ({ callId }: Tags.GetGroupCallTags.Request) => {
  return http.get(`/tags/group/call/${callId}`)
    .then((data: Tags.GetGroupCallTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateCallTags = ({ callId, ...payload }: Tags.UpdateGroupCallTags.Request) => {
  return http.put(`/tags/group/call/${callId}`, payload)
    .then((data: Tags.UpdateGroupCallTags.Response) => ({
      tags: data.tags,
    }));
};

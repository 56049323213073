import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ProfileEntry } from '@enums';
import { useProfileState, useAccountProfileEditState, useAccountProfileNewEntry } from '@containers';
import { Header, Subtitle, Title, Footer, NextButton } from '@screens/ProjectCreation';
import { Back } from '@presentation';
import { AddProfileEntryButton, ProfileSectionEntries } from 'components/UserProfile';
import { AnimatedLoader } from 'components/ActivityIndicator';
import { ScreenNavigationProps } from './interfaces';
import styles from './style/AccountProfileSetup.css';

type Props =
  ScreenNavigationProps;

const EducationSetup = (props: Props) => {
  const [state] = useProfileState();
  const [entryId, onEditStateChange] = useAccountProfileEditState();
  const history = useHistory();
  const [_, addNewEntry] = useAccountProfileNewEntry(ProfileEntry.Education);

  const { subtitle, title } = copy;

  const back = useCallback(() => {
    onEditStateChange('')();

    if (props.navigation.back) {
      props.navigation.back();
    } else {
      history.goBack();
    }
  }, [
    history,
    onEditStateChange,
    props.navigation,
  ]);

  return (
    <div className={styles.root}>
      <Header className={styles.header}>
        <Title className={styles.title}>{title}</Title>
        <Subtitle className={styles.subtitle}>{subtitle}</Subtitle>
      </Header>

      {!state?.education &&
        <AnimatedLoader
          className={styles.loader}
          color="var(--sec-01)" />}

      {state?.education &&
        <div className={styles.main}>
          <ProfileSectionEntries
            entryClassName={styles.sectionEntry}
            type={ProfileEntry.Education} />
          <AddProfileEntryButton
            className={styles.create}
            onClick={addNewEntry}>
            Add Education
          </AddProfileEntryButton>

        </div>}

      <Footer className={styles.footer}>
        <Back
          className={styles.back}
          onClick={back} />
        <NextButton
          className={styles.btn}
          color="primary"
          disabled={!!entryId}
          onClick={props.navigation.onClick}
          replace={props.navigation.replace}
          to={props.navigation.to}
          variant="brick">
          Next
        </NextButton>
      </Footer>
    </div>
  );
};

const copy = {
  title: `Education`,
  subtitle: `Please provide your educational experiences to fill out your profile.`,
};

export { EducationSetup };
export default EducationSetup;
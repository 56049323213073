import { useCallback, useMemo, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import {
  useTranscriptComments,
  useTranscriptCommentsRefPoint,
  useTranscriptCommentPositions,
  useSetTranscriptCommentPosition,
} from '@containers/Transcript';
import { TranscriptComment } from '@/types/transcribe.rich-text';
import { Portal } from 'components/Portal';
import { useHelpers } from './hooks';
import { HighlightCommentsContainer } from './Comments.HighlightContainer';

export const TranscriptComments = () => {

  const bounds = useTranscriptCommentsRefPoint();
  const commentPositions = useTranscriptCommentPositions();

  const observer = useCommentsObserver();

  const sortedHighlights = useSortedHighlights();
  const { getHighlightPosition } = useHelpers();

  const highlightPositions = useMemo(() => {
    return sortedHighlights.reduce((acc, h) => {
      const position = getHighlightPosition(h.highlight.identifier);
      return {
        ...acc,
        [h.highlight.identifier]: position,
      };
    }, {} as {
      [highlightId: string]: number;
    });
  }, [
    getHighlightPosition,
    sortedHighlights,
  ]);

  const renderComments = useCallback(() => {

    let y = bounds.top;

    const bottomPadding = 20;

    return sortedHighlights.map(m => {

      const hp = highlightPositions[m.highlight.identifier];

      if (!hp) return null;

      y = Math.max(y, hp);

      const position = {
        width: bounds.width,
        x: bounds.left,
        y,
      };

      const containerHeight = commentPositions[m.highlight.identifier]?.height || 0;

      y = y + containerHeight + bottomPadding;

      return (
        <HighlightCommentsContainer
          key={m.highlight.identifier}
          observer={observer}
          position={position}
          highlight={m.highlight}
          comments={m.comments} />
      );
    }).filter(Boolean);

  }, [
    bounds,
    commentPositions,
    highlightPositions,
    observer,
    sortedHighlights,
  ]);

  return (
    <Portal>
      {bounds.width &&
        renderComments()}
    </Portal>
  );
};

const useCommentsObserver = () => {
  const setCommentPosition = useSetTranscriptCommentPosition();

  const [observer] = useState(() => new ResizeObserver((entries: ResizeObserverEntry[]) => {
    for (const entry of entries) {
      const identifier = entry.target.getAttribute('data-highlight-identifier');
      setCommentPosition(identifier)(entry.contentRect);
    }
  }));

  return observer;
};

const useSortedHighlights = () => {
  const [state] = useTranscriptComments();

  const { getBasicHighlight, getHighlightsInitialized } = useHelpers();

  const initialized = getHighlightsInitialized();

  return useMemo(() => {

    if (!initialized) return [];

    const grouped = state.items.reduce((acc, x) => {
      const identifier = x.highlight.identifier;

      const highlightsMap = {
        ...acc.highlightsMap,
        [identifier]: x.highlight,
      };

      const highlightComments = {
        ...acc.highlightComments,
        [identifier]: (acc.highlightComments[identifier] || []).concat(x),
      };

      const extensionHighlight = getBasicHighlight(identifier);

      const highlightPositions = {
        ...acc.highlightPositions,
        [identifier]: extensionHighlight ? {
          from: extensionHighlight.from,
          to: extensionHighlight.to,
        } : null,
      };

      return {
        highlightComments,
        highlightsMap,
        highlightPositions,
      };
    }, {
      highlightsMap: {} as {
        [identifier: string]: TranscriptComment['highlight'];
      },
      highlightPositions: {} as {
        [identifier: string]: {
          from: number;
          to: number;
        };
      },
      highlightComments: {} as {
        [identifier: string]: TranscriptComment[];
      },
    });

    const sortedHighlights = Object.keys(grouped.highlightPositions)
      .filter(f => !!grouped.highlightPositions[f])
      .sort((a, b) => {

        const hA = grouped.highlightPositions[a];
        const hB = grouped.highlightPositions[b];

        if (hA.from === hB.from) return hA.to - hB.to;

        return hA.from  - hB.from;
      });

    return sortedHighlights.map(m => ({
      highlight: grouped.highlightsMap[m],
      comments: grouped.highlightComments[m],
    }));
  }, [
    getBasicHighlight,
    initialized,
    state.items,
  ]);
};

export default TranscriptComments;
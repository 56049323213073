import { memo } from 'react';
import { LinkProps } from 'react-router-dom';
import { Button } from 'components/Button';
import styles from './style/Notification.Actions.css';

type Props = {
  to?: LinkProps['to'];
  title: string;
};

export const NotificationAction = memo((props: Props) => {

  return (
    <div className={styles.action}>
      <Button
        className={styles.btn}
        {...props} />
    </div>
  );
});

export const NotificationActions = memo((props: ChildrenProps) => (
  <div className={styles.actions}>
    {props.children}
  </div>
));
import { AxiosError } from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error: AxiosError) => {
        if ([403, 404].includes(error?.response?.status)) {
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});

export default function ReactQueryClient({ children }: ChildrenProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <>
        {children}
        {/* <ReactQueryDevtools /> */}
      </>
    </QueryClientProvider>
  );
}
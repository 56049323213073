import { useCallback } from 'react';
import { PopupState as PopupStateProps } from 'material-ui-popup-state/core';
import { Card, CardContent, Divider, GroupCardHeader } from '@presentation/Main';
import { GroupTableHeaderButton } from '@presentation/GroupTable';
import { cx } from '@utils';
import { useNewFolderModal, useFoldersTableItems } from 'components/WorkspaceFolders/hooks';
import { WorkspacesContextMenuPopper } from 'components/Workspace/WorkspacesMenu';
import styles from './style/FoldersPanel.css';
import FoldersTable from './FoldersTable';

function EmptyState() {
  return (
    <div className={styles.empty}>
      <div className={styles.text}>Create a folder to organize projects</div>
    </div>
  );
}

const MaxFolders = 5;

export const FoldersPanel = () => {

  const [toggleModal, NewFolderModal] = useNewFolderModal({});

  const data = useFoldersTableItems({});

  const renderJumpToAnchor = useCallback((state: PopupStateProps) => {
    const className = cx(styles.jumpTo, {
      [styles.open]: state.isOpen,
    });
    return (
      <div className={className}>
        Jump To
        <div className={styles.chevron} />
      </div>
    );
  }, []);

  return (
    <>
      <Card>
        <GroupCardHeader className={styles.header}>
          <div className={styles.left}>
            <div>Folders</div>
            <WorkspacesContextMenuPopper
              renderAnchor={renderJumpToAnchor} />
          </div>
          <GroupTableHeaderButton
            title="Create New"
            onClick={toggleModal} />
        </GroupCardHeader>
        {!data.length && <Divider />}
        <CardContent fluid>
          {!!data.length &&
            <FoldersTable
              data={data}
              maxItems={MaxFolders} />}
          {!data.length && <EmptyState />}
        </CardContent>
      </Card>
      <NewFolderModal />
    </>
  );
};

export default FoldersPanel;
import TextInput from './TextInput';
import { Address2FieldProps } from './interfaces';

type Props =
    Address2FieldProps;

export default function Address2Field(props: Props) {
  if (!props.show) return null;

  return (
    <TextInput
      className={props.className}
      labelClassName={props.labelClassName}
      error={props.error}
      placeholder={props.placeholder}
      label={props.label}
      name='address2'
      value={props.value}
      required={props.required}
      onChange={props.onChange} />
  );
}

Address2Field.defaultProps = {
  label: 'Address 2',
  placeholder: 'Address Line 2',
  show: true,
};

export { Address2Field as Address2 };
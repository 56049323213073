import * as types from '@store/action-types';
import { Group } from '@store/interfaces';

export const brandingUpdated = (data: Group.BrandingUpdated.State): Group.BrandingUpdated.Action => {
  return {
    type: types.GROUP_BRANDING_UPDATED,
    ...data,
  };
};

export const updateGroupSettings = (data: Group.SettingsUpdated.State): Group.SettingsUpdated.Action => {
  return {
    type: types.UPDATE_GROUP_SETTINGS,
    ...data,
  };
};

export const paymentMethodAdded = (data: Group.PaymentMethodAdded.State): Group.PaymentMethodAdded.Action => {
  return {
    type: types.GROUP_PAYMENT_METHOD_ADDED,
    ...data,
  };
};

export const paymentMethodRemoved = (): Group.PaymentMethodRemoved.Action => {
  return {
    type: types.GROUP_PAYMENT_METHOD_REMOVED,
  };
};

export const paymentMethodUpdated = (data: Group.PaymentMethodUpdated.State): Group.PaymentMethodUpdated.Action => {
  return {
    type: types.GROUP_PAYMENT_METHOD_UPDATED,
    ...data,
  };
};
import * as consts from '@consts';
import Overview from './Overview';
import NewPaymentMethod from './NewPaymentMethod';
import VerifyBankAccount from './VerifyBankAccount';

export default [
  {
    name: 'Overview',
    to: consts.path.Settings.EnterpriseBilling,
    component: Overview,
    exact: true,
  },
  {
    name: 'New Payment Method',
    to: consts.path.Settings.EnterpriseBillingNewMethod,
    component: NewPaymentMethod,
    exact: true,
  },
  {
    name: 'Verify Bank Account',
    to: consts.path.Settings.EnterpriseBillingVerify,
    component: VerifyBankAccount,
    exact: true,
  },
];
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import * as api from '@api';
import { Projects } from '@api/interfaces';

type Data = Projects.FetchProjectSurvey.Response;
type QueryKey = [string, number];

type Props =
  IProjectId;

const useFetchProjectSurvey = (
  { projectId }: Props,
  options?: UseQueryOptions<Data, AxiosError, Data, QueryKey>
) => {

  return useQuery(['fetch-project-survey', projectId], ({ queryKey }) => {
    const projectId = queryKey[1] as number;

    return api.projects.surveys.get({
      projectId,
    });
  }, options);
};

export { useFetchProjectSurvey };
export default useFetchProjectSurvey;
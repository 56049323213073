import { useMemo, useCallback } from 'react';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { TabView } from 'components/TabView';
import { useTabViewState } from 'components/TabView/hooks/useTabViewState';
import { WorkspaceSceneMap, WorkspaceRouteKey, WorkspaceRouteParams, TabScene } from './interfaces';

export const WorkspaceTabView = ({ sceneMap: map }: { sceneMap: WorkspaceSceneMap; }) => {

  const { workspace } = useParams<WorkspaceRouteParams>();
  const keys = useMemo(() => Object.keys(map) as WorkspaceRouteKey[], [map]);
  const routes = useMemo(() => keys.map(k => ({ key: k, ...map[k] })), [keys, map]);

  const formatPath = useCallback((tab: WorkspaceRouteKey) => {
    return `/workspaces/${workspace}/${tab}`;
  }, [workspace]);

  const [{ index }, updateLocation] = useTabViewState<WorkspaceRouteKey>({
    formatPath,
    routes: keys,
  });

  const renderTabView = useCallback(({ route }: { route: TabScene; }) => {
    const defaultRoute = formatPath(WorkspaceRouteKey.Projects);

    return (
      <Switch>
        {keys.map((key: WorkspaceRouteKey) =>
          <Route
            key={key}
            path={map[key].path}
            component={map[key].component} />)}
        <Redirect to={defaultRoute} />
      </Switch>
    );
  }, [
    formatPath,
    keys,
    map,
  ]);

  return (
    <TabView
      index={index}
      onIndexChange={updateLocation}
      routes={routes}
      renderTabView={renderTabView} />
  );
};

export default WorkspaceTabView;
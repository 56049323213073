import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { WorkspaceObjectType } from '@enums';
import { FolderMenuContainer } from '@containers/WorkspaceFolders/FolderMenuContainer';
import { Breadcrumbs } from '@presentation/Breadcrumbs';
import { selectGetFolder, selectGetWorkspace } from '@store/selectors';
import { WorkspaceObject } from '@/types';
import { FolderBreadcrumb } from './FolderBreadcrumb';
import { WorkspaceBreadcrumbPath } from './ObjectPath';
import { WorkspaceBreadcrumb } from './WorkspaceBreadcrumb';
import styles from './style/Breadcrumbs.css';

type Props = {
  items: WorkspaceObject[];
};

export const WorkspaceObjectBreadcrumbs = ({ items }: Props) => {

  const getFolder = useSelector(selectGetFolder);
  const getWorkspace = useSelector(selectGetWorkspace);

  const renderItem = useCallback((object: WorkspaceObject) => {
    if (object.typeId === WorkspaceObjectType.Workspace) {
      const workspace = getWorkspace(object.entityId);

      return (
        <div className={styles.item}>
          <WorkspaceBreadcrumb
            workspace={workspace} />
        </div>
      );
    } else if (object.typeId === WorkspaceObjectType.Folder) {
      const folder = getFolder(object.entityId);

      return (
        <FolderMenuContainer folderId={folder.id}>
          <div className={styles.item}>
            <FolderBreadcrumb
              folder={folder} />
          </div>
        </FolderMenuContainer>
      );

    } else {
      return null;
    }
  }, [
    getFolder,
    getWorkspace,
  ]);

  return (
    <Breadcrumbs>
      <WorkspaceBreadcrumbPath
        items={items.slice(0, items.length - 1)} />
      {renderItem(items[items.length - 1])}
    </Breadcrumbs>
  );
};

export default WorkspaceObjectBreadcrumbs;

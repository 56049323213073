import { useSelector } from 'react-redux';
import { WorkspaceFolderContainer } from '@containers/Workspace/WorkspaceFolderContainer';
import { useWorkspaceFolderState } from '@containers/Workspace/hooks/useWorkspaceFolderState';
import * as selectors from '@store/selectors';
import { WorkspaceObjectBreadcrumbs } from 'components/Workspace/ObjectBreadcrumbs';
import FolderTabViewContainer from './FolderTabViewContainer';
import WorkspaceHeader from './Header';

const StateDependent = () => {
  const { folder } = useWorkspaceFolderState();
  const hierarchy = useSelector(selectors.selectObjectHierarchy(folder.objectId));

  return (
    <>
      <WorkspaceHeader>
        <WorkspaceObjectBreadcrumbs
          items={hierarchy} />
      </WorkspaceHeader>
      <FolderTabViewContainer />
    </>
  );
};

export const FolderMain = () => (
  <WorkspaceFolderContainer>
    <StateDependent />
  </WorkspaceFolderContainer>
);

export default FolderMain;
import { Remirror } from '@remirror/react';
import { SurveyRichText } from '@/types/survey.rich-text';
import { useSurveyQuestionTextEditor } from './hooks';
import { QuestionEditorOnChange } from './interfaces';
import styles from './style/Editor.css';

type Props = {
  autoFocus?: boolean;
  initialState: SurveyRichText.RichTextValue;
  onChange?: QuestionEditorOnChange;
} & ChildrenProps;

export const SurveyQuestionTextEditorContainer = ({
  autoFocus,
  children,
  initialState,
  onChange,
}: Props) => {

  const { manager, state } = useSurveyQuestionTextEditor({
    initialState,
    placeholder: 'Enter your question',
  });

  return (
    <Remirror
      classNames={[styles.editor]}
      autoFocus={autoFocus}
      editable={true}
      manager={manager}
      onChange={onChange}
      initialContent={state}>
      {children}
    </Remirror>
  );
};
import { useCallback } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import * as api from '@api';
import * as Branding from '@api/interfaces/groups.branding';
import * as QKey from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';
import { useQueryDataAccessor } from './useQueryDataAccessor';

export const useUpdateSurveyPaletteColorMutation = (options: UpdateSurveyPaletteColor.Options = {}) => {
  const group = useSelectGroup();
  const mutationFn = useMutationFn();
  const helpers = useMutationHelpers();

  const mutation = useMutation([QKey.Groups.Branding.Survey.Palettes.Put, group.id], mutationFn, {
    ...options,
    onMutate: variables => {
      helpers.onMutate(variables);
      options.onMutate?.(variables);
    },
  });

  return mutation;
};

const useMutationFn = () => {
  const accessor = useQueryDataAccessor();
  const group = useSelectGroup();

  const updatePaletteColor = (params: UpdateSurveyPaletteColor.Variables) => {
    const data = accessor.getQueryData();
    const { palette } = data.survey;

    const updated = mergeValues(palette, params);

    return api.groups.branding.surveys.updatePaletteColor({
      groupId: group.id,
      palette: updated,
    });
  };

  return useCallback(updatePaletteColor, [
    accessor,
    group.id,
  ]);
};

const useMutationHelpers = () => {
  const accessor = useQueryDataAccessor();

  const onMutate = useCallback((variables: UpdateSurveyPaletteColor.Variables) => {
    accessor.setQueryData(data => {
      return {
        ...data,
        survey: {
          ...data.survey,
          palette: mergeValues(data.survey.palette, variables),
        },
      };
    });
  }, [accessor]);

  return {
    onMutate,
  };
};

function mergeValues(palette: UpdateSurveyPaletteColor.Palette, params: UpdateSurveyPaletteColor.Variables) {
  const variants = palette[params.color];

  return {
    ...palette,
    [params.color]: {
      ...variants,
      [params.variant]: params.value,
    },
  };
}

declare namespace UpdateSurveyPaletteColor {
  export type Palette = Branding.Surveys.UpdatePaletteColor.Request['palette'];

  type Color = keyof Palette;

  type ColorParam<C extends Color = Color, V extends Palette[C] = Palette[C]>  = {
    color:   C;
    variant: keyof V;
  };

  export type Variables = {
    value: string;
  } & ColorParam;

  export type Options =
    UseMutationOptions<
      Branding.Surveys.UpdatePaletteColor.Response,
      unknown,
      Variables>;
}

import { CompletedSurvey } from 'scenes/surveys/CompletedSurvey/CompletedSurvey';
import SurveyDetails from './SurveyDetails';
import UserDetails from './UserDetails';
import { SurveyResponseProps } from './interfaces';
import styles from './style.css';

export const CompletedProjectSurveyReview = (props: SurveyResponseProps) => {
  return (
    <div className={styles.root}>
      <CompletedSurvey
        buttons={props.renderButtons()}
        results={props.renderBody()}
        surveyDetails={(
          <SurveyDetails {...props} />
        )}
        userDetails={<UserDetails user={props.user} />} />
    </div>
  );
};
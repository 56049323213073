import { useMemo } from 'react';
import { useFileUploadsState } from './useFileUploadsState';

export const useFileUploadProgress = (fileUploadId: number) => {
  const [{ progress: progressMap }] = useFileUploadsState();

  const progress = useMemo(() => {
    return progressMap[fileUploadId];
  }, [
    fileUploadId,
    progressMap,
  ]);

  return progress;
};

export default useFileUploadProgress;
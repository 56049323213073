import { useContext } from 'react';
import * as enums from '@enums';
import { RateOnboardingContext } from '@containers/RateOnboarding';
import {
  WizardHeader,
} from 'components/Wizard';
import SubIndustries from 'scenes/vics-selection/SubIndustries';
import { Navigation, Screen } from './Wizard';

export const SubIndustriesScreen = () => {
  const ctx = useContext(RateOnboardingContext);

  const renderHeader = () => {
    const title = `What sub industries do you work in?`;
    const subtitle = `Select up to five sub-industries to filter projects for your professional experience.`;

    return  (
      <WizardHeader
        title={title}
        subtitle={subtitle} />
    );
  };

  const renderBody = () => (
    <SubIndustries
      industryIds={ctx.industries.map(m => m.id)}
      onSelection={ctx.onVICSSelection(enums.VICSType.SubIndustry)}
      selectedItems={ctx.subIndustries}
      vics={ctx.vics} />
  );

  const renderNavigation = () =>  (
    <Navigation nextEnabled={ctx.subIndustries.length > 0} />
  );

  return (
    <Screen
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderNavigation={renderNavigation} />
  );
};

export default SubIndustriesScreen;
import { useEffect, useState } from 'react';
import * as api from '@api';
import { useSelectUser } from '@containers/Store';
import { PaymentMethodDetails } from './interfaces';

export function useUserPaymentMethodDetails() {
  const user = useSelectUser();
  const [details, setDetails] = useState<PaymentMethodDetails>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    api.payment.user.getMethodDetails({
      userId: user.id,
    })
      .then(result => {
        setDetails(result ? result.paymentMethod : null);
        setLoading(false);
      })
      .catch(() => {
        setDetails(null);
        setLoading(false);
      });
  }, [setDetails, setLoading, user.id]);

  return [ details, loading ] as const;
}

export default useUserPaymentMethodDetails;
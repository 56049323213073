import qs from 'query-string';
import { SearchType } from '@enums';
import http from '@services/http';
import {
  Topics,
  Title,
  VICS,
  ICompanies,
} from '@/types';
import {
  AutoCompleteBaseRequest,
  AutoCompleteRequest,
  AutoCompleteResponse,
} from './interfaces';

const request = <T>(data: AutoCompleteBaseRequest<string | number>) => {
  return http.get(`/search/autocomplete?${qs.stringify({
    ignoreList: data.ids,
    text: data.value,
    type: data.type,
  }, { arrayFormat: 'bracket' })}`)
  .then((res: AutoCompleteResponse<T>) => res.results);
};

const requestWithIndex = <T>(data: AutoCompleteBaseRequest) => {
  return request<T>({
    ids: data.ids.map(id => `${id}_${data.type}`),
    type: data.type,
    value: data.value,
  });
};

const company = (data: AutoCompleteRequest) => {
  return request<ICompanies.BaseCompany>({
    ids: data.ids,
    type: SearchType.Company,
    value: data.value,
  });
};

const industry = (data: AutoCompleteRequest) => {
  return requestWithIndex<VICS>({
    ids: data.ids,
    type: SearchType.Industry,
    value: data.value,
  });
};

const subindustry = (data: AutoCompleteRequest) => {
  return requestWithIndex<VICS>({
    ids: data.ids,
    type: SearchType.SubIndustry,
    value: data.value,
  });
};

const title = (data: AutoCompleteRequest) => {
  return requestWithIndex<Title>({
    ids: data.ids,
    type: SearchType.Title,
    value: data.value,
  });
};

const topic = (data: AutoCompleteRequest) => {
  return request<Topics.Descriptor>({
    ids: data.ids,
    type: SearchType.Topic,
    value: data.value,
  });
};

export const autocomplete = {
  company,
  industry,
  request,
  requestWithIndex,
  subindustry,
  title,
  topic,
};
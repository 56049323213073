import { useReducer } from 'react';
import { ObjectAccessUser } from '@/types';
import { ObjectAccessStateContext } from './Context';
import { ObjectAccess } from './interfaces';

type Props =
  ChildrenProps;

const initialState: ObjectAccess.State = {
  initial: [],
  items: [],
};

export const ObjectAccessState = (props: Props) => {
  const reducer = useReducer(objectAccessReducer, initialState);

  return (
    <ObjectAccessStateContext.Provider value={reducer}>
      {props.children}
    </ObjectAccessStateContext.Provider>
  );
};

function objectAccessReducer(state: ObjectAccess.State, action: ObjectAccess.Actions) {
  switch (action.type) {
    case 'add':
      return {
        ...state,
        items: [
          ...state.items,
          action.value,
        ],
      };

    case 'load':
      return {
        initial: action.items,
        items: action.items,
      };

    case 'remove':
      return {
        ...state,
        items: state.items
          .filter(f => f.user.id !== action.userId),
      };

    case 'update-role':
      return {
        ...state,
        items: state.items.reduce((acc, x) => ([
          ...acc,
          {
            roleId: action.userId === x.user.id
              ? action.roleId
              : x.roleId,
            user: x.user,
          },
        ]), [] as ObjectAccessUser[]),
      };

    default:
      return state;
  }
}

export default ObjectAccessState;
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { cx } from '@utils';
import * as Countries from './Countries';
import { CountryFieldItem, CountryFieldProps } from './interfaces';
import styles from './style.css';

type Props =
    CountryFieldProps;

const useStyles = makeStyles({
  option: {
    '&[data-focus="true"]': {
      backgroundColor: 'var(--blue-l)',
      borderColor: 'transparent',
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'var(--blue-l)',
      borderColor: 'transparent',
    },
  },
  noOptions: {
    color: 'var(--gray-d)',
    fontFamily: 'var(--font-bold)',
    fontSize: 15,
    borderColor: 'transparent',
  },
});

export default function CountryField(props: Props) {
  const autocomplete = useStyles();

  if (!props.show) return null;

  return (
    <div className={props.className}>
      {props.label && (
        <label className={cx(styles.label, { [styles.labelError]: !!props.error }, props.labelClassName)}>
          {props.label} {props.required && <span className={styles.star}>*</span>}
        </label>)
      }
      <Autocomplete
        classes={autocomplete}
        autoHighlight
        disableClearable
        noOptionsText="No matching countries."
        options={props.items}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(_, value) => props.onChange(value as CountryFieldItem)}
        value={props.selected}
        renderInput={params => {
          const { endAdornment, startAdornment, className, ...ContainerRest } = params.InputProps;
          return (
            <div className={styles.inputContainer} {...ContainerRest}>
              <input
                {...params.inputProps}
                className={cx(styles.dropdown, props.dropdownClassName)}
                placeholder="Country"
                autoComplete="new-password" />
              {endAdornment}
            </div>
          );
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <div className={styles.option}>
              <span>{countryToFlag(option.id)}</span>
              {option.name}
            </div>
          </li>
        )} />
    </div>
  );
}

function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

CountryField.defaultProps = {
  label: 'Country',
  items: Countries.World,
  selected: { id: '', name: '' },
  show: true,
};

export { CountryField as Country };
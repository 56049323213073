import { useSelector } from 'react-redux';
import { AdHocCall, ProjectCall } from '@/types';

export const selectCall = (callId: number) => (state: Store.State) => {
  return state.calls[callId];
};

export const selectCalls = (callIds: number[]) => (state: Store.State) => {
  return callIds.map(i => state.calls[i]).filter(Boolean);
};

export const selectConference = (callId: number) => (state: Store.State) => {
  return state.conferences[callId];
};

export const useSelectCall = (callId: number) => {
  return useSelector(selectCall(callId));
};

export const useSelectConference = (callId: number) => {
  return useSelector(selectConference(callId));
};

export const useSelectProjectCall = (callId: number) => {
  return useSelector(selectCall(callId)) as ProjectCall;
};

export const useSelectAdHocCall = (callId: number) => {
  return useSelector(selectCall(callId)) as AdHocCall;
};

export const useSelectCalls = (callIds: number[]) => {
  return useSelector(selectCalls(callIds));
};
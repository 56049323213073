import { useMatchWorkspaceStateFromUrl } from './hooks';
import { WorkspaceStateContext } from './Context';

type Props =
  ChildrenProps;

export const WorkspaceContainer = (props: Props) => {
  const workspace = useMatchWorkspaceStateFromUrl();

  return (
    <WorkspaceStateContext.Provider value={{ workspace }}>
      {props.children}
    </WorkspaceStateContext.Provider>
  );
};

export default WorkspaceContainer;
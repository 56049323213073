import { Fragment, useCallback, useState, CSSProperties } from 'react';
import { cx } from '@utils';
import { useDrag } from '@utils/hooks';
import { Point } from '@/types';
import styles from '$website/screens/Main/style/Products.css';
import { CarouselStepsProps, CarouselProps, TabletCarouselProps, MobileCarouselProps } from './interfaces';
import { MobileCard, TabletCard } from './ProductCard';

const CarouselSteps = (props: CarouselStepsProps) => (
  <div className={styles.carouselSteps}>
    {
      [...Array(props.totalSteps)].map((m, i) => {
        const className = cx(styles.carouselStep, {
          [styles.activeCarouselStep]: i === props.currentIndex,
        });
        return (
          <div
            key={i}
            className={className}
            onClick={() => props.goToStep(i)}>
            <div className={styles.carouselStepInner} />
          </div>
        );
      })
    }
  </div>
);

export const Carousel = (props: CarouselProps) => {
  const TransitionBuffer = 40;
  const Margin = 30;

  const getIndexPosition = index => {
    return (-index - 1) * (props.width + Margin);
  };

  const [dragStartPosition, setDragStartPosition] = useState<number>(null);
  const [dragPosition, setDragPosition] = useState<number>(null);
  const [currentIndex, setIndex] = useState<number>(0);
  const [carouselOffset, setCarouselOffset] = useState<number>(getIndexPosition(0));

  const moveCarouselToIndex = index => {
    setCarouselOffset(getIndexPosition(index));
  };

  const setCurrentIndex = index => {
    moveCarouselToIndex(index);
    setIndex(index);
  };

  const back = useCallback(() => {
    const to = currentIndex === 0
      ? props.items.length - 1
      : currentIndex - 1;
    setCurrentIndex(to);
  }, [currentIndex]);

  const next = useCallback(() => {
    const to = currentIndex === props.items.length - 1
      ? 0
      : currentIndex + 1;
    setCurrentIndex(to);
  }, [currentIndex]);

  const getStartingPosition = useCallback(() => {
    return getIndexPosition(currentIndex);
  }, [currentIndex]);

  const reset = useCallback(() => {
    setCarouselOffset(getStartingPosition());
  }, [getStartingPosition]);

  const getNextPosition = nextPosition => {
    const rightBound = getIndexPosition(props.items.length) + props.width;
    const rightBoundIncludingClone = getIndexPosition(props.items.length + 1) + props.width;
    const leftBound = getIndexPosition(0);

    const leftOfFirst = nextPosition > leftBound;
    const rightOfLast = nextPosition < rightBound;

    if (leftOfFirst) {
      return rightBoundIncludingClone - (leftBound - nextPosition);
    }

    if (rightOfLast) {
      return leftBound - (rightBoundIncludingClone - nextPosition);
    }

    return nextPosition;
  };

  const handleDrag = useCallback((point: Point) => {
    const dragDistance = point.x - dragStartPosition;

    const nextRawPosition = getStartingPosition() + dragDistance;
    const nextPosition = getNextPosition(nextRawPosition);

    setCarouselOffset(nextPosition);
    setDragPosition(point.x);
  }, [getStartingPosition, dragStartPosition]);

  const handleDragStart = useCallback((point: Point) => {
    setDragStartPosition(point.x);
    setDragPosition(point.x);
  }, []);

  const handleDragEnd = useCallback(() => {
    const dragDistance = dragPosition - dragStartPosition;

    if (dragDistance > TransitionBuffer) {
      back();
    } else if(dragDistance < -TransitionBuffer) {
      next();
    } else {
      reset();
    }

    setDragStartPosition(null);
    setDragPosition(null);
  }, [dragStartPosition, dragPosition, back, next, reset]);

  const [isDragging, handleMouseDown, handleTouchStart] = useDrag({
    onDrag: handleDrag,
    onDragEnd: handleDragEnd,
    onDragStart: handleDragStart,
  });

  const getCarouselStyle = useCallback((): CSSProperties => {
    return {
      transform: `translateX(${carouselOffset}px)`,
      transitionDuration: isDragging ? '' : '350ms',
    };
  }, [isDragging, carouselOffset]);

  const renderItem = item => (
    <div
      className={styles.carouselCard}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}>
      {props.renderItem(item)}
    </div>
  );

  const renderItems = () => {
    return (
      <div
        className={styles.carouselItems}
        style={getCarouselStyle()}>
        {renderItem(props.items[props.items.length - 1])}
        {props.items.map(m => (
          <Fragment key={m.id}>
            {renderItem(m)}
          </Fragment>
        ))}
        {renderItem(props.items[0])}
      </div>
    );
  };

  return (
    <div className={props.className}>
      <div className={cx(styles.carouselWrap, props.wrapClassName)}>
        {renderItems()}
      </div>
      <CarouselSteps
        currentIndex={currentIndex}
        goToStep={setCurrentIndex}
        totalSteps={props.items.length} />
    </div>
  );
};

export const MobileCarousel = (props: MobileCarouselProps) => {
  const renderItem = item => (
    <MobileCard
      item={item} />
  );

  return  (
    <Carousel
      className={styles.mobileCarouselRoot}
      wrapClassName={styles.mobileCarouselWrap}
      items={props.items}
      renderItem={renderItem}
      width={260} />
  );
};

export const TabletCarousel = (props: TabletCarouselProps) => {
  const renderItem = item => (
    <TabletCard
      item={item} />
  );

  return  (
    <Carousel
      className={styles.tabletCarouselRoot}
      wrapClassName={styles.tabletCarouselWrap}
      items={props.items}
      renderItem={renderItem}
      width={600} />
  );
};

export default Carousel;
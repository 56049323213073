import { useCallback, useMemo } from 'react';
import {
  EditorComponent,
  useEditorState,
  useRemirrorContext,
} from '@remirror/react';
import { format, isToday, isYesterday } from 'date-fns';
import {
  TranscriptCommentEditingContext,
  useTranscriptChildComment,
  useTranscriptCommentIdentifiers,
  useTranscriptCommentEditing,
} from '@containers/Transcript';
import { cx, useToggle } from '@utils';
import { SeeMore, Collapser } from '@presentation/SeeMore';
import { MentionsAutocomplete } from 'components/Remirror';
import { UserAvatar } from 'components/UserAvatar';
import {
  CommentActions,
  CommentCancelButton as CancelButton,
  CommentSaveButton as SaveButton,
} from './Comment.Actions';
import { CommentEditor } from './Comment.Editor';
import { CommentContextMenu } from './Comment.ContextMenu';
import {
  MinimumCommentLength,
  useCommentEditor,
  useCommentEditorContent,
  useUpdateComment,
} from './hooks';
import styles from './style/Comment.Child.css';

type Props = {
  className?: string;
};

const Comment = ({ children, className }: Props & ChildrenProps) => {

  const item = useTranscriptChildComment();
  const age = formatAge(item.createdOn);

  return (
    <div className={styles.root}>
      <div className={cx(styles.wrap, className)}>

        <div className={styles.header}>
          <div className={styles.details}>
            <UserAvatar
              size={32}
              pictureUrl={item.creator.profile.pictureUrl} />
            <div className={styles.meta}>
              <div className={styles.name}>{item.creator.profile.fullname}</div>
              <div className={styles.age}>{age}</div>
            </div>
          </div>

          <CommentContextMenu />
        </div>

        {children}

      </div>
    </div>
  );
};

function formatAge(dt: Date) {
  const day = isToday(dt)
    ? 'Today'
    : isYesterday(dt)
      ? 'Yesterday'
      : format(dt, 'MMM do');

  const time = format(dt, 'hh:mm aa');

  return `${day} at ${time}`;
}

const Editing = () => {

  const item = useTranscriptChildComment();
  const { parentComment } = useTranscriptCommentIdentifiers();
  const editor = useEditorState();
  const { manager } = useRemirrorContext();

  const [_, toggleEditing] = useTranscriptCommentEditing();

  const editorContent = useCommentEditorContent();

  const canSave = useMemo(() => {

    const isLongEnough = editorContent.text.length > MinimumCommentLength || editorContent.mentions.length > 0;

    if (!isLongEnough) return false;

    const initialState = manager.createState({ content: item.value });
    const initialText = initialState.doc.textContent;

    return editorContent.text !== initialText;
  }, [
    manager,
    editorContent,
    item.value,
  ]);

  const {
    isLoading: isUpdating,
    mutateAsync: updateComment,
  } = useUpdateComment({
    onSuccess: toggleEditing,
  });

  const handleCancel = useCallback(() => {

    const state = manager.createState({ content: item.value });
    manager.view.updateState(state);

    toggleEditing();
  }, [
    manager,
    item.value,
    toggleEditing,
  ]);

  const handleUpdate = useCallback(() => {
    return updateComment({
      commentId: item.id,
      parentCommentId: parentComment.id,
      value: editor.doc,
    });
  }, [
    editor.doc,
    item.id,
    parentComment.id,
    updateComment,
  ]);

  return (
    <>
      <EditorComponent />
      <MentionsAutocomplete />

      <CommentActions>
        <SaveButton
          disabled={!canSave}
          implicitDisable={isUpdating}
          loading={isUpdating}
          onClick={handleUpdate}>
          Save
        </SaveButton>
        <CancelButton
          onClick={handleCancel} />
      </CommentActions>
    </>
  );
};

const ReadOnly = () => {

  return (
    <SeeMore
      CollapserComp={Collapser}
      lineHeight={22}
      maxLines={5}>
      <EditorComponent />
    </SeeMore>
  );
};

const ReadOnlyWithState = () => {
  const item = useTranscriptChildComment();

  const { manager, state } = useCommentEditor({
    initialState: item.value,
    placeholder: '',
  });

  return (
    <CommentEditor
      editable={false}
      initialContent={state}
      manager={manager}>
      <ReadOnly />
    </CommentEditor>
  );
};

const EditingWithState = () => {

  const item = useTranscriptChildComment();

  const { manager, state } = useCommentEditor({
    initialState: item.value,
    placeholder: 'Reply or add others with @',
  });

  return (
    <CommentEditor
      autoFocus={true}
      editable={true}
      initialContent={state}
      manager={manager}>
      <Editing />
    </CommentEditor>
  );
};

export const CommentWithState = (props: Props) => {
  const [isEditing, toggleEditing] = useToggle();

  return (
    <TranscriptCommentEditingContext.Provider value={[isEditing, toggleEditing]}>
      <Comment {...props}>
        {isEditing
          ? <EditingWithState />
          : <ReadOnlyWithState />}
      </Comment>
    </TranscriptCommentEditingContext.Provider>
  );
};

export { CommentWithState as Comment };
import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import * as api from '@api';
import { WorkspaceExpertFilesItem } from '@/types';

type Data = WorkspaceExpertFilesItem[];
type QueryKey = readonly [string, number];
type Options = UseQueryOptions<Data, AxiosResponse, Data, QueryKey>;

export const useMemberFiles = (userId: number, options?: Options) => {

  return useQuery(['member-files', userId], ({ queryKey }) => {

    const userId = queryKey[1];

    return api.workspaces.expert.getExpertFiles({
      userId,
    }).then(res => res.items);

  }, options);
};
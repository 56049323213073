import { useState } from 'react';
import { ThumbsUp } from 'react-feather';
import { cx } from '@utils';
import { useToggle } from '@utils/hooks';
import * as likeService from '@services/api/likes';
import { LikeDescription } from 'types/likes';
import styles from './style.css';

type SectionProps = {
  className?: string;
  likeDescription: LikeDescription;
}

export const LikeSection = (props: SectionProps) => {
  const [userLikes, toggleUserLikes] = useToggle(props.likeDescription.userLikes);
  const [likeCount, changeLikeCount] = useState(props.likeDescription.likeCount);

  function toggleLikeButton() {
    likeService.UpdateLikeStatus(props.likeDescription, !userLikes);
    changeLikeCount(val => val + (userLikes ? -1 : 1));
    toggleUserLikes();
  }

  return (
    <div className={props.className}>
      <button className={cx([styles.likeBtn, userLikes ? styles.liked : styles.notLiked])} type={'button'} onClick={toggleLikeButton}>
        <ThumbsUp className={styles.thumbsUp} height={18} />
        <span className={styles.likeText}>{userLikes ? 'Liked' : 'Like' }</span>
        {likeCount > 0 &&
          <div className={styles.likeCounter}>{likeCount}</div>
        }
      </button>
    </div>
  );
};
import { memo } from 'react';
import { Facebook, Linkedin, Twitter, Youtube } from 'react-feather';
import * as consts from '@consts';
import { SocialLinkProps } from './interfaces';
import styles from './style/Footer.css';

const SocialLink = memo((props: SocialLinkProps) => (
  <a
    className={styles.socialLink}
    href={props.url}
    target="_blank"
    rel="noopener noreferrer">
    {props.children}
  </a>
));

const FollowUs = memo(() => (
  <div className={styles.follow}>
    <div className={styles.followTitle}>Follow Us</div>
    <div className={styles.socialLinks}>
      <SocialLink
        url={consts.urls.LinkedIn}>
        <Linkedin
          className={styles.linkedin} />
      </SocialLink>
      <SocialLink
        url={consts.urls.Facebook}>
        <Facebook
          className={styles.facebook} />
      </SocialLink>
      <SocialLink
        url={consts.urls.YouTube}>
        <Youtube
          className={styles.youtube} />
      </SocialLink>
      <SocialLink
        url={consts.urls.Twitter}>
        <Twitter
          className={styles.twitter} />
      </SocialLink>
    </div>
  </div>
));

export default FollowUs;
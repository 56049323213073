import { useCallback } from 'react';
import { cx } from '@utils';
import { ButtonLinkVariant } from '@presentation/Buttons';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { Button } from 'components/Button';
import styles from './style/Integration.css';

type Props =
  IntegrationProps;

export const Integration = ({
  className,
  connected,
  description,
  disabled,
  disconnectText,
  icon: Icon,
  loading,
  onConnect,
  onDisconnect,
  title,
}: Props) => {

  const renderFooterContent = useCallback(() => {
    if (loading) {
      return <ActivityIndicator show />;
    }

    if (!connected) {
      return (
        <ButtonLinkVariant
          className={styles.connect}
          disabled={disabled}
          color="secondary"
          onClick={onConnect}>
          Connect
        </ButtonLinkVariant>
      );
    } else {
      return (
        <ButtonLinkVariant
          className={styles.disconnect}
          disabled={disabled}
          color="destructive"
          onClick={onDisconnect}>
          {disconnectText ?? 'Disconnect'}
        </ButtonLinkVariant>
      );
    }
  }, [
    connected,
    disabled,
    disconnectText,
    loading,
    onConnect,
    onDisconnect,
  ]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.body}>
        <Icon
          className={styles.icon} />
        <div className={styles.title}>{title}</div>
        <div className={styles.copy}>{description}</div>
      </div>
      <div className={styles.footer}>
        {renderFooterContent()}
      </div>
    </div>
  );
};

export type IntegrationProps = {
  className?: string;
  connected: boolean;
  disabled: boolean;
  disconnectText?: string;
  description: string;
  icon: React.ComponentType<IconProps>;
  loading: boolean;
  onConnect: () => unknown;
  onDisconnect: () => unknown;
  title: string;
};

type IconProps = {
  className: string;
  size?: number;
};

export default Integration;
import http from '@services/http';
import * as cancellation from '@services/http/cancellation';
import * as xform from '@transformers';
import { WorkspaceGlobalSearch } from './interfaces';

export const bar = (data: WorkspaceGlobalSearch.Bar.Request) => {
  return http.post<WorkspaceGlobalSearch.Bar.Response>(`/workspaces/global-search/bar`, {
    query: data.query,
    filters: data.filters,
  }, { cancelToken: data.cancelToken })
  .catch<void>(e => cancellation.ignoreRequestCancellationError(e));
};

export const addPast = (data: WorkspaceGlobalSearch.AddPast.Request) => {
  return http.post(`/workspaces/global-search/past`, {
    query: data.query,
    entityId: data.entityId,
    item: data.item,
  });
};

export const getPast = (): Promise<WorkspaceGlobalSearch.GetPast.Response> => {
  return http.get(`/workspaces/global-search/past`);
};

export const projects = (data: WorkspaceGlobalSearch.Projects.Request): Promise<WorkspaceGlobalSearch.Projects.Response> => {
  return http.post(`/workspaces/global-search/projects`, {
    query: data.query,
    size: data.size,
  }).then(xform.normalizeSearchFullProjectResults);
};

export const members = (data: WorkspaceGlobalSearch.Members.Request): Promise<WorkspaceGlobalSearch.Members.Response> => {
  return http.post(`/workspaces/global-search/members`, {
    query: data.query,
    size: data.size,
  }).then(xform.normalizeSearchFullMemberResults);
};

export const files = (data: WorkspaceGlobalSearch.Files.Request): Promise<WorkspaceGlobalSearch.Files.Response> => {
  return http.post(`/workspaces/global-search/files`, {
    query: data.query,
    size: data.size,
  }).then(xform.normalizeSearchFullFileResults);
};

export const folders = (data: WorkspaceGlobalSearch.Folders.Request): Promise<WorkspaceGlobalSearch.Folders.Response> => {
  return http.post(`/workspaces/global-search/folders`, {
    query: data.query,
    size: data.size,
  }).then(xform.normalizeSearchFullFolderResults);
};

export const interactions = (data: WorkspaceGlobalSearch.Interactions.Request): Promise<WorkspaceGlobalSearch.Interactions.Response> => {
  return http.post(`/workspaces/global-search/interactions`, {
    query: data.query,
    size: data.size,
  }).then(xform.normalizeSearchFullInteractionResults);
};
import { useContext, useEffect } from 'react';
import MUIPopper from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { bindPopper, PopupState } from 'material-ui-popup-state/hooks';
import { QueryContext, ResetContext } from './Context';
import { Form } from './Form';
import Results from './Results';
import styles from './style/Bar.css';

type Props = {
  popupState: PopupState;
}

export default function Popper({ popupState }: Props) {
  const { clickedAwayReset } = useContext(ResetContext);
  const { barRefs } = useContext(QueryContext);

  useEffect(() => {
    if (barRefs.current.form && popupState.anchorEl !== barRefs.current.form) {
      popupState.setAnchorEl(barRefs.current.form);
    }
  }, [popupState, barRefs]);

  return (
    <ClickAwayListener onClickAway={clickedAwayReset}>
      <div>
        <Form />
        <MUIPopper
          {...bindPopper(popupState)}
          placement="bottom-start"
          modifiers={[{
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          }]}>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.results}>
                <Results />
              </div>
            </div>
          </div>
        </MUIPopper>
      </div>
    </ClickAwayListener>
  );
}
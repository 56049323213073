import { WorkspaceFile, WorkspaceFileUpload } from '@/types';
import { safeJsonDate } from '@utils';

export const normalizeFile = (data: WorkspaceFile<string>): WorkspaceFile => {
  if (!data) return null;

  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
    lastDownloadedOn: safeJsonDate(data.lastDownloadedOn),
    lastModifiedOn: safeJsonDate(data.lastModifiedOn),
  };
};

export const normalizeFileUpload = (data: WorkspaceFileUpload<string>): WorkspaceFileUpload => {
  if (!data) return null;

  return {
    ...data,
    initiatedOn: safeJsonDate(data.initiatedOn),
    lastUpdatedOn: safeJsonDate(data.lastUpdatedOn),
  };
};

export const normalizeFiles = (files: WorkspaceFile<string>[]): Store.Files => {

  const values = files.reduce((acc, file) => ({
    ...acc,
    [file.id]: normalizeFile(file),
  }), {});

  return {
    ids: files.map(m => m.id),
    values,
  };
};
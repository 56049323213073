import extend from '@enums/extend';

export enum Permission {
  InternalAdminDashboard         = 3,
  DeveloperBuildInfo             = 4,
  InternalResearchDashboard      = 5,
  InternalAdminBillingDashboard  = 6,
  GroupBillingAdmin              = 7,
}

const map = new Map([
  [Permission.InternalAdminDashboard,        { name: 'internal-admin:dashboard' }],
  [Permission.DeveloperBuildInfo,            { name: 'developer:build-info' }],
  [Permission.InternalResearchDashboard,     { name: 'internal-research:dashboard' }],
  [Permission.InternalAdminBillingDashboard, { name: 'internal-admin:billing:dashboard' }],
  [Permission.GroupBillingAdmin,             { name: 'group:billing-admin' }],
]);

export default {
  Permission: extend(map),
};
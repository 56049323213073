import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import * as API from '@api/interfaces';
import * as consts from '@consts';
import { useAppReadyState } from '@containers/AppReadyState';
import { ActionableLinkType, UserProjectStatus } from '@enums';
import { slugify } from '@utils';
import { ActivityIndicator } from 'components/ActivityIndicator';
import Toast from 'components/Toast';
import * as ActionableLink from './interfaces';

type Props = {
  params: ActionableLink.ActionableParams.RecommendedConsultantApproval;
  token:  ActionableLink.URLParams['token'];
};

export const RecommendedConsultantApproval = (props: Props) => {
  const dispatch = useDispatch();
  const dispatched = useRef(false);
  const [payload, setPayload] = useState<APIResponse>(null);
  const [settled, setSettled] = useState(false);
  const notifyActionSuccessful = useNotifyActionSuccessful();
  const notifyActionFailed = useNotifyActionFailed();
  const history = useHistory();
  const app = useAppReadyState();

  const handleCompletedWithChanges = useCallback((payload: APIResponse) => {

    dispatch(actions.projectPipelineItemChanged({
      record: payload.record,
    }));

    const slug = slugify({
      id: payload.project.id,
      name: payload.project.name,
    });

    history.replace(`${consts.path.Projects.Root}/${slug}`);

  }, [
    dispatch,
    history,
  ]);

  const handleCompleted = useCallback(() => {
    const loc = app.authenticated
        ? consts.pathname.Home
        : consts.path.Website.Login;

    history.replace(loc);
  }, [
    app.authenticated,
    history,
  ]);

  useEffect(() => {
    if (payload && !app.authenticated) {
      return handleCompleted();
    }

    if (payload && app.authenticated && app.hydrated) {
      return handleCompletedWithChanges(payload);
    }

    if (settled && !app.authenticated) {
      return handleCompleted();
    }

    if (settled && app.authenticated) {
      return handleCompleted();
    }
  }, [
    app.authenticated,
    app.hydrated,
    handleCompleted,
    handleCompletedWithChanges,
    payload,
    settled,
  ]);

  useEffect(() => {
    if (dispatched.current) return;

    dispatched.current = true;

    api.tokens.handleActionableLink<ActionableLinkType.RecommendedConsultantApproval>({
      action: props.params.action,
      token: props.token,
    })
    .then(res => {
      notifyActionSuccessful(res);
      setPayload(res);
    })
    .catch(_ => {
      notifyActionFailed();
      setSettled(true);
    });

  }, [
    notifyActionFailed,
    notifyActionSuccessful,
    props.params.action,
    props.token,
  ]);

  return (
    <ActivityIndicator show />
  );
};

const useNotifyActionFailed = () => {
  return useCallback(() => {
    Toast.warn({
      title: `Warning - Request Failed`,
      body: `We’re sorry. This link is no longer available. Changes can be made in the project pipeline.`,
    });
  }, []);
};

const useNotifyActionSuccessful = () => {
  return useCallback((res: APIResponse) => {
    const title = {
      [UserProjectStatus.Invited]: `Call Request Sent`,
      [UserProjectStatus.Rejected]: `Recommendation Rejected`,
    };
    const body = {
      [UserProjectStatus.Invited]: `You have sent a call invitation to ${res.user.profile.fullname} for the ${res.project.name} Project.`,
      [UserProjectStatus.Rejected]: `You have removed ${res.user.profile.fullname} from the ${res.project.name} Project.`,
    };

    Toast.alert({
      title: title[res.record.statusId],
      body: body[res.record.statusId],
    });
  }, []);
};

type APIResponse = API.Tokens.HandleActionableLink.Response<ActionableLinkType.RecommendedConsultantApproval>;
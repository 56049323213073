import PinIcon from '@mui/icons-material/FiberPin';
import { ConferenceDialNumber } from '@/types';
import { phone } from '@utils';
import { Body } from 'components/Modal/Body';
import { Header } from 'components/Modal/Header';
import { Modal, ModalProps } from 'components/Modal/Modal';
import styles from './style/AdditionalPhoneNumbers.Modal.css';

type Props = {
  pin: string;
  phoneNumbers: ConferenceDialNumber[];
  onClose: () => unknown;
} & Pick<ModalProps, 'className' | 'open'>;

export default function AdditionalPhoneNumbersModal({ pin, phoneNumbers, open, onClose }: Props) {
  return (
    <Modal
      hideCloseIcon
      open={open}
      onClose={onClose}>
      <Header className={styles.header}>
        Additional Dial In Numbers
      </Header>
      <Body className={styles.body}>
        <div className={styles.pin}><PinIcon />{pin}</div>
        <div className={styles.numbers}>
          {phoneNumbers.map((n, i) => (
            <div
              className={styles.row}
              key={i}>
              <div className={styles.country}>{n.countryCode}</div>
              <div className={styles.number}>{phone.format(n.phone)}</div>
            </div>
          ))}
        </div>
      </Body>
    </Modal>
  );
}

export { AdditionalPhoneNumbersModal };
import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Template.Label.css';

type Props = {
  className?: string;
  disabled: boolean;
  label: string;
  onClick?: () => void;
};

export const TemplateItemLabel = memo(({
  className,
  disabled,
  label,
  onClick,
}: Props) => {

  return (
    <span
      className={cx(styles.root, className, {
        [styles.disabled]: disabled,
      })}
      onClick={disabled ? undefined : onClick}>
      {label}
    </span>
  );
});
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useProjectAnonymity, useProjectSurveyRespondent } from '@containers/GroupProject/hooks';
import { UserEmploymentText } from '@presentation';
import { getLocationFor, cx } from '@utils';
import { Contact } from '@/types';
import { PaidMessagingContainer } from '@containers/PlatformMessage';
import { CreditRateWithLabel } from 'components/ExpertRate/Credit';
import { MaybeLink } from 'components/MaybeLink';
import { UserAvatar } from 'components/UserAvatar';
import HeaderActions from './HeaderActions';
import styles from './style/Header.css';

const mapState = (state: Store.State) => ({
  group: state.group,
  me: state.user,
});

export const ProjectSurveyResponseHeader = () => {
  const {
    user,
  } = useProjectSurveyRespondent();
  const projectAnonymity = useProjectAnonymity();
  const { group, me } = useSelector(mapState);
  const showRate = group?.features?.showConsultantRates && me.id != user.id;

  const profileLink = useMemo(() => {
    if (projectAnonymity) {
      return null;
    }

    const { slug } = (user as Contact).profile;
    return slug && getLocationFor.user.profile({ slug });
  }, [
    projectAnonymity,
    user,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <div className={styles.header}>
          <MaybeLink to={profileLink} className={styles.avatarWrap}>
            <UserAvatar
              className={styles.avatar}
              pictureUrl={user.profile.pictureUrl}
              size={95} />
          </MaybeLink>
          <div className={styles.mainHeader}>
            <div className={styles.userLinkContainer}>
              <MaybeLink
                className={styles.userLink}
                to={profileLink}>
                <div className={styles.username}>{user.profile.fullname}</div>
              </MaybeLink>
            </div>
            {showRate &&
              <div>
                <CreditRateWithLabel rate={user.profile.creditRate} />
              </div>
            }
          </div>
          <PaidMessagingContainer>
            <HeaderActions />
          </PaidMessagingContainer>
        </div>

      </div>
      <div className={styles.section}>
        <div className={styles.employmentSection}>
          <div className={cx(styles.row, styles.workExperienceHeader)}>
            <div className={styles.sectionTitle}>
              Work Experience
            </div>
            {!projectAnonymity &&
              <Link className={styles.workExperienceLink} to={profileLink}><span>View Profile {'>'}</span></Link>}
          </div>
          {user.career.current &&
            <div className={styles.row}>
              <span className={styles.label}>Current:</span>
              <UserEmploymentText
                company={user.career.current.company}
                tenure={user.career.current.duration}
                title={user.career.current.title} />
            </div>}
          {user.career.former && user.career.former.title &&
            <div className={styles.row}>
              <span className={styles.label}>Former:</span>
              <UserEmploymentText
                company={user.career.former.company}
                tenure={user.career.former.duration}
                title={user.career.former.title} />
            </div>}
        </div>

      </div>
    </div>
  );
};

export default ProjectSurveyResponseHeader;
import { SurveyOptionType } from '@enums';
import { SurveyQuestion } from '@/types';
import { QuestionOption } from './QuestionOption';
import styles from './style/Options.css';

type Props = {
  item: SurveyQuestion;
};

export const Options = ({ item }: Props) => (
  <>
    {item.options
      .filter(f => f.type !== SurveyOptionType.NotApplicable)
      .map((option, i) => (
        <QuestionOption
          key={i}
          className={styles.option}
          item={option} />
      ))
    }
  </>
);

export default Options;
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as api from '@api';
import * as actions from '@store/actions';
import Toast from 'components/Toast';
import { RenameFileParams } from '../interfaces';

export const useRenameFile = () => {
  const dispatch = useDispatch();

  const renameFile = useCallback((data: RenameFileParams) => {
    return api.workspaces.file.renameFile({
      fileId: data.fileId,
      name: data.name,
      workspaceId: data.workspaceId,
    }).then(({ file, object }) => {
      dispatch(actions.fileRenamed({
        file,
        object,
      }));
      Toast.alert({
        title: 'File Renamed',
      });
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });

  }, [dispatch]);

  return renameFile;
};

export default useRenameFile;
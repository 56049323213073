import { ChargeableType, PayoutableType } from '@enums';
import { safeJsonDate } from '@utils';
import { Billing, ChargeableAdjustments, ChargeableInteraction, PayoutableAdjustments, PayoutableInteraction } from '$admin/api/interfaces';

export function transformGetCharge(data: Billing.GetCharge.Response<string>): Billing.GetCharge.Response {
  return {
    ...data,
    details: transformChargeDetails(data.details),
  };
}

export function transformChargeDetails(data: Billing.GetCharge.Response<string>['details']) {
  return {
    ...data,
    created: {
      ...data.created,
      on: safeJsonDate(data.created.on),
    },
  };
}

export function transformGetInvoice(data: Billing.GetInvoice.Response<string>): Billing.GetInvoice.Response {
  return {
    ...data,
    details: transformInvoiceDetails(data.details),
    funding: transformBillingFunding(data.funding),
  };
}

export function transformInvoiceDetails(data: Billing.GetInvoice.Response<string>['details']) {
  return {
    ...data,
    created: {
      ...data.created,
      on: safeJsonDate(data.created.on),
    },
    publishedOn: safeJsonDate(data.publishedOn),
  };
}

export function transformBillingFunding(data: Billing.GetInvoice.Response<string>['funding']) {
  return data.map(data => ({
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  }));
}

export function transformGetChargeable(data: Billing.GetChargeable.Response<string>): Billing.GetChargeable.Response {
  return {
    ...data,
    adjustments: transformChargeableAdjustments(data.adjustments),
    details: {
      ...data.details,
      created: {
        ...data.details.created,
        on: safeJsonDate(data.details.created.on),
      },
    },
    interaction: transformChargeableInteraction(data.details.type, data.interaction),
  };
}

export function transformGetCharges(data: Billing.GetCharges.Response<string>): Billing.GetCharges.Response {
  return {
    ...data,
    items: data.items.map(item => ({
      ...item,
      created: {
        ...item.created,
        on: safeJsonDate(item.created.on),
      },
    })),
  };
}

export function transformGetInvoices(data: Billing.GetInvoices.Response<string>): Billing.GetInvoices.Response {
  return {
    ...data,
    items: data.items.map(item => ({
      ...item,
      created: {
        ...item.created,
        on: safeJsonDate(item.created.on),
      },
    })),
  };
}

export function transformGetPayout(data: Billing.GetPayout.Response<string>): Billing.GetPayout.Response {
  return {
    ...data,
    details: transformPayoutDetails(data.details),
  };
}

export function transformPayoutDetails(data: Billing.GetPayout.Response<string>['details']) {
  return {
    ...data,
    created: {
      ...data.created,
      on: safeJsonDate(data.created.on),
    },
  };
}

export function transformGetPayoutable(data: Billing.GetPayoutable.Response<string>): Billing.GetPayoutable.Response {
  return {
    ...data,
    adjustments: transformPayoutableAdjustments(data.adjustments),
    details: {
      ...data.details,
      created: {
        ...data.details.created,
        on: safeJsonDate(data.details.created.on),
      },
    },
    interaction: transformPayoutableInteraction(data.details.type, data.interaction),
  };
}

export function transformGetPayouts(data: Billing.GetPayouts.Response<string>): Billing.GetPayouts.Response {
  return {
    ...data,
    items: data.items.map(item => ({
      ...item,
      created: {
        ...item.created,
        on: safeJsonDate(item.created.on),
      },
    })),
  };
}

function transformChargeableAdjustments(adj: ChargeableAdjustments<string>): ChargeableAdjustments {
  return {
    ...adj,
    items: adj.items.map(i => ({
      ...i,
      created: {
        ...i.created,
        date: safeJsonDate(i.created.date),
      },
    })),
  };
}

function transformPayoutableAdjustments(adj: PayoutableAdjustments<string>): PayoutableAdjustments {
  return {
    ...adj,
    items: adj.items.map(i => ({
      ...i,
      created: {
        ...i.created,
        date: safeJsonDate(i.created.date),
      },
    })),
  };
}

function transformChargeableInteraction(type: ChargeableType, item: ChargeableInteraction<ChargeableType, string>): ChargeableInteraction<ChargeableType> {
  switch (type) {
    case ChargeableType.ProjectCall: return ProjectCall();
    case ChargeableType.Survey: return Survey();
    case ChargeableType.Message: return Message();
    case ChargeableType.AdHocCall: return AdHocCall();
    default: return item as unknown as ChargeableInteraction<ChargeableType>;
  }

  function AdHocCall(): ChargeableInteraction<ChargeableType.AdHocCall>  {
    const interaction = getTypedChargeableInteraction<ChargeableType.AdHocCall>(item);
    return {
      ...interaction,
      call: {
        ...interaction.call,
        date: safeJsonDate(interaction.call.date),
      },
    };
  }

  function ProjectCall(): ChargeableInteraction<ChargeableType.ProjectCall>  {
    const interaction = getTypedChargeableInteraction<ChargeableType.ProjectCall>(item);
    return {
      ...interaction,
      call: {
        ...interaction.call,
        date: safeJsonDate(interaction.call.date),
      },
    };
  }

  function Survey(): ChargeableInteraction<ChargeableType.Survey>  {
    const interaction = getTypedChargeableInteraction<ChargeableType.Survey>(item);
    return {
      ...interaction,
      survey: {
        ...interaction.survey,
        date: safeJsonDate(interaction.survey.date),
      },
    };
  }

  function Message(): ChargeableInteraction<ChargeableType.Message>  {
    const interaction = getTypedChargeableInteraction<ChargeableType.Message>(item);
    return {
      ...interaction,
      answer: {
        ...interaction.answer,
        date: safeJsonDate(interaction.answer.date),
      },
      question: {
        ...interaction.question,
        date: safeJsonDate(interaction.question.date),
      },
    };
  }
}

function transformPayoutableInteraction(type: PayoutableType, item: PayoutableInteraction<PayoutableType, string>): PayoutableInteraction<PayoutableType> {
  switch (type) {
    case PayoutableType.ProjectCall: return ProjectCall();
    case PayoutableType.Survey: return Survey();
    case PayoutableType.Message: return Message();
    case PayoutableType.AdHocCall: return AdHocCall();
    default: return item as unknown as PayoutableInteraction<PayoutableType>;
  }

  function AdHocCall(): PayoutableInteraction<PayoutableType.AdHocCall>  {
    const interaction = getTypedPayoutableInteraction<PayoutableType.AdHocCall>(item);
    return {
      ...interaction,
      call: {
        ...interaction.call,
        date: safeJsonDate(interaction.call.date),
      },
    };
  }

  function ProjectCall(): PayoutableInteraction<PayoutableType.ProjectCall>  {
    const interaction = getTypedPayoutableInteraction<PayoutableType.ProjectCall>(item);
    return {
      ...interaction,
      call: {
        ...interaction.call,
        date: safeJsonDate(interaction.call.date),
      },
    };
  }

  function Survey(): PayoutableInteraction<PayoutableType.Survey>  {
    const interaction = getTypedPayoutableInteraction<PayoutableType.Survey>(item);
    return {
      ...interaction,
      survey: {
        ...interaction.survey,
        date: safeJsonDate(interaction.survey.date),
      },
    };
  }

  function Message(): PayoutableInteraction<PayoutableType.Message>  {
    const interaction = getTypedPayoutableInteraction<PayoutableType.Message>(item);
    return {
      ...interaction,
      answer: {
        ...interaction.answer,
        date: safeJsonDate(interaction.answer.date),
      },
      question: {
        ...interaction.question,
        date: safeJsonDate(interaction.question.date),
      },
    };
  }
}

function getTypedChargeableInteraction<T extends ChargeableType>(item: ChargeableInteraction<ChargeableType, string>) {
  return item as ChargeableInteraction<T, string>;
}

function getTypedPayoutableInteraction<T extends PayoutableType>(item: PayoutableInteraction<PayoutableType, string>) {
  return item as PayoutableInteraction<T, string>;
}
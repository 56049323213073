export function formatPossessive(name: string) {
  if (!name) return name;

  return name.endsWith('s') ? `${name}'` : `${name}'s`;
}

export function formatPlurality(word: string, count: number) {
  return count == 1
    ? word
    : `${word}s`;
}

export function formatWithOrdinalSuffix(v: number) {
  const suffix = ['th','st','nd','rd'][Math.abs(~[1,2,3].indexOf(+(+v).toFixed().substr(-1)))];
  return `${v}${suffix}`;
}
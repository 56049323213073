import { useSelector } from 'react-redux';
import { CalendarPicker, CalendarPickerHeader, CalendarPickerHeaderParams } from 'components/CalendarPicker';
import { ExternalCalendarSelector } from 'components/Calendar/ExternalCalendarSelector';
import CalendarButton from 'components/Calendar/Button';
import { CalendarMainProps } from 'components/Calendar/interfaces';
import styles from 'components/Calendar/style/Viewing.css';
import { useReapplySubmitButtonDisabledState } from 'components/Calendar/hooks/useReapplySubmitButtonDisabledState';
import { getAvailableEvents, getNewEvents } from 'components/Calendar/utils';
import { TimeZone } from './TimeZone';

const mapState = (state: Store.State) => ({
  user: state.user,
});

type Props =
  CalendarMainProps;

const ViewingMain = (props: Props) => {
  const { user } = useSelector(mapState);
  const newEvents = getNewEvents(props.events);
  const availableEvents = getAvailableEvents(props.events);
  const dates = availableEvents.map(x => x.start);

  const canSubmit = newEvents.length
    || props.removedEvents.length;

  useReapplySubmitButtonDisabledState({
    disabled: !canSubmit,
    fcContainerId: props.id,
  });

  function SubmitButton() {
    const canSubmit = newEvents.length
                   || props.removedEvents.length;

    return (
      <CalendarButton
        className={styles.button}
        disabled={!canSubmit}
        onClick={props.onSubmit}>
        Save
      </CalendarButton>
    );
  }

  function renderCustomHeader(params: CalendarPickerHeaderParams) {
    return (
      <CalendarPickerHeader
        className={styles.picker}
        {...params} />
    );
  }

  return (
    <div
      className={styles.root}
      onClick={props.onClickContainer}>

      <div className={styles.wrap}>
        <div className={styles.sidebar}>
          <div className={styles.gutter}>
            <CalendarPicker
              marked={dates}
              onChange={props.gotoDate}
              renderCustomHeader={renderCustomHeader} />

            <ExternalCalendarSelector
              calendar={props.calendar}
              onChange={props.onChangeExternalCalendar}
              user={user} />
          </div>
        </div>
        <div className={styles.main}>
          <div
            id={props.id}
            className={styles.calendar} />
          <TimeZone />
        </div>
        <SubmitButton />
      </div>
    </div>
  );
};

export { ViewingMain };
export default ViewingMain;
import { useCallback } from 'react';
import { useMutation, MutateOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Surveys } from '@api/interfaces';
import * as api from '@api';
import { SurveyActionType } from '@enums';
import { useSurveyQuestionCompleteEvent } from '@utils/analytics';
import {
  useSaveCompletedSurveyResponse,
  useSaveDisqualifiedSurveyResponse,
  useSurveyFormContext,
  useSurveyResponseIdentifier,
} from '../Context';
import { useSurveyFormQuestionContext } from './useSurveyFormQuestionContext';

type Data = Surveys.Response.SubmitAnswer.Response;
type Options = MutateOptions<Data, AxiosError>;

export const surveyResponseSubmitAnswer = (identifier: string) => ['survey-response-submit-answer', identifier];

const disqualifyingTypes = [
  SurveyActionType.Disqualification,
  SurveyActionType.QuotaOverLimit,
];

export const useSubmitAnswer = (options?: Options) => {
  const questionCompleteEvent = useSurveyQuestionCompleteEvent();
  const saveCompletedResponse = useSaveCompletedSurveyResponse();
  const saveDisqualifiedResponse = useSaveDisqualifiedSurveyResponse();
  const responseIdentifier = useSurveyResponseIdentifier();
  const { answer, question, setState } = useSurveyFormQuestionContext();

  const survey = useSurveyFormContext();

  const handleSuccess = useCallback(({ action, ...state }: Data) => {
    if (disqualifyingTypes.includes(action.type)) {
      saveDisqualifiedResponse(action.type);
    } else if (action.type === SurveyActionType.SkipToEnd) {
      saveCompletedResponse();
    } else {
      if (!state.question) {
        saveCompletedResponse();
      } else {
        setState(state);
      }
    }
  }, [
    saveCompletedResponse,
    saveDisqualifiedResponse,
    setState,
  ]);

  return useMutation(surveyResponseSubmitAnswer(responseIdentifier), () => {
    questionCompleteEvent({
      id: question.id,
      response: answer,
      value: question.value,
      version: survey.id,
    });

    return api.surveys.response.submitAnswer({
      responseIdentifier,
      surveyVersionId: survey.id,
      surveyVersionQuestionId: question.id,
      value: answer,
    });
  }, {
    ...options,
    onSuccess: handleSuccess,
  });

};

export default useSubmitAnswer;
import { useCallback, useMemo } from 'react';
import { NumberFormatValues } from 'react-number-format';
import * as survey from '@containers/Survey/utils';
import * as surveyForm  from '@containers/SurveyForm/utils';
import { cx, useDebounceCallback } from '@utils';
import { MatrixSliderQuestion, SurveyQuestionMatrixRow } from '@/types';
import { NumberInput } from 'components/Input';
import SurveyMatrixSliderHeader from './Header';
import MatrixSlider from './MatrixSlider';
import useSliderTextAnswers from './hooks/useSliderTextAnswers';
import useSliderValidation from './hooks/useSliderValidation';
import { SurveyMatrixSlidersProps } from './interfaces';
import styles from './style/SurveyMatrixSliders.css';

export default function SurveyMatrixSliders({ answer, question, rows, updateRowAnswer }: Props) {
  const [textAnswers, setTextAnswers] = useSliderTextAnswers(answer);
  const validationMessage = useSliderValidation(answer, question);

  const settings = useMemo(() => {
    const increment = survey.questions.isSliderReversed(question)
      ? -question.settings.slider.increment
      : question.settings.slider.increment;
    return {
      ...question.settings.slider,
      increment,
    };
  }, [question]);

  const runningTotal = useMemo(() => {
    return surveyForm.matrixSlider.getRunningTotal(answer);
  }, [answer]);

  const renderHeader = useCallback(() => {
    if (settings.hideSlider) return null;

    return (
      <div className={styles.header}>
        <div className={styles.label} />
        <div className={styles.value} />
        <SurveyMatrixSliderHeader
          className={styles.headerContent}
          settings={settings} />
      </div>
    );
  }, [settings]);

  const updateSliderFromInput = useDebounceCallback(useCallback((rowId: number, value: number) => {
    updateRowAnswer(rowId)(value);
  }, [updateRowAnswer]), 100);

  const handleInputChange = useCallback((rowId: number) => (value: NumberFormatValues) => {
    setTextAnswers(prev => ({ ...prev, [rowId]: value.value }));

    const casted = parseFloat(value.value.length ? value.value : `${getMidValue(settings)}`);
    if (!isNaN(casted)) {
      const validated = validateSliderValue(casted, settings);
      updateSliderFromInput(rowId, validated);
    }
  }, [settings, setTextAnswers, updateSliderFromInput]);

  const handleBlur = useCallback((rowId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!value.length && rowId in textAnswers) {
      const mid = getMidValue(settings);
      updateRowAnswer(rowId)(mid);
      setTextAnswers(prev => ({ ...prev, [rowId]: `${mid}` }));
    } else if (value.length) {
      const rowAnswer = answer.items.find(a => a.matrixRowId === rowId);

      setTextAnswers(prev => ({ ...prev, [rowId]: `${rowAnswer?.value ?? ''}` }));
    }
  }, [answer.items, settings, setTextAnswers, textAnswers, updateRowAnswer]);

  const handlePositionChange = useCallback((rowId: number) =>  (position: number) => {
    setTextAnswers(prev => ({ ...prev, [rowId]: `${position ?? ''}` }));
    updateRowAnswer(rowId)(position);
  }, [setTextAnswers, updateRowAnswer]);

  const renderRow = useCallback((row: SurveyQuestionMatrixRow) => {
    const item = answer.items.find(f => f.matrixRowId === row.id);
    return (
      <div
        key={row.ordinal}
        className={cx(styles.row, {
          [styles.noSlider]: settings.hideSlider,
        })}>
        <div className={styles.label}>
          {`${row.value}`}
        </div>
        <div className={styles.value}>
          <NumberInput
            autoComplete="off"
            placeholder="-"
            value={textAnswers[row.id] ?? ''}
            thousandSeparator={true}
            onValueChange={handleInputChange(row.id)}
            onBlur={handleBlur(row.id)} />
        </div>
        {!settings.hideSlider &&
          <MatrixSlider
            className={styles.content}
            incrementBy={settings.increment}
            maxValue={settings.maxValue}
            minValue={settings.minValue}
            onPositionChange={handlePositionChange(row.id)}
            value={item?.value} />}
      </div>
    );
  }, [answer, settings, textAnswers, handleBlur, handleInputChange, handlePositionChange]);

  return (
    <div className={styles.root}>
      {renderHeader()}
      {rows.map(renderRow)}
      {settings.ensureAnswerTotalEqualsMax &&
        <div className={styles.totalRow}>
          <div className={styles.label}>Total</div>
          <div className={styles.total}>{runningTotal}</div>
        </div>
      }
      {validationMessage &&
        <div className={styles.validation}>{validationMessage}</div>
      }
    </div>
  );
}

function getMidValue(settings: MatrixSliderQuestion.SliderSettings) {
  const increments = Math.abs((settings.maxValue - settings.minValue) / settings.increment);
  return settings.minValue + (settings.increment * Math.ceil(increments / 2));
}

function validateSliderValue(value: number, settings: MatrixSliderQuestion.SliderSettings) {
  return settings.minValue > settings.maxValue
    ? +Math.max(settings.maxValue, Math.min(settings.minValue, value)).toFixed(2)
    : +Math.min(settings.maxValue, Math.max(settings.minValue, value)).toFixed(2);
}

type Props =
  SurveyMatrixSlidersProps;

export { SurveyMatrixSliders };
import { LocationDescriptor } from 'history';
import { Contact } from '@/types';
import { Button } from 'components/Button';
import logo from 'static/images/vancery-landscape.svg?url';
import { UserAvatar } from 'components/UserAvatar';
import styles from './style/TimeNoLongerAvailable.css';

type Props =  {
  location: LocationDescriptor;
  user: Pick<Contact, 'id' | 'profile'>;
};

export const TimeNoLongerAvailable = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <img src={logo} />
          <UserAvatar
            className={styles.img}
            pictureUrl={props.user.profile.pictureUrl} />
          <div className={styles.text}>{`Book a call with ${props.user.profile.fullname}`}</div>
        </div>

        <div className={styles.main}>
          <div className={styles.message}>
            <div className={styles.text}>This time is no longer available</div>
          </div>
          <Button
            className={styles.btn}
            to={props.location}
            variant="link">
            {`View ${props.user.profile.firstName}'s availability>`}
          </Button>
        </div>
      </div>
    </div>
  );
};
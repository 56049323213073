import { useCallback } from 'react';
import { requestQuant2QualCall } from '@services/api/scheduling';
import * as Scheduling from '@services/api/interfaces/scheduling.project';
import Toast from 'components/Toast';

type Props = Scheduling.RequestQuant2QualCall.Request & {
  expertName: string;
};

export const useRequestQuant2Qual = () => {

  return useCallback((data: Props) => {
    requestQuant2QualCall(data);
    Toast.alert({
      title: 'Call Request Sent',
      body: `You have sent a call invitation to ${data.expertName} for this project`,
    });
  }, []);
};
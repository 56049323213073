import * as $session from './session';
import * as $sessionHttp from './session-http';
import { OAuth2 as IOAuth2, SessionHttp } from './interfaces';

export function authorize({ url }: IOAuth2.Authorize.Params) {
  window.location.href = url;
}

export function authorizePopup({ url }: IOAuth2.Authorize.Params) {
  const width = 500;
  const height = 600;

  const options = {
    resizable: 1,
    scrollbars: 1,
    width,
    height,
    top: window.screenY + (window.outerHeight - height) / 2.5,
    left: window.screenX + (window.outerWidth - width) / 2,
  };

  const optionsArray = Object.keys(options).map((k: keyof typeof options) => `${k}=${options[k]}`);

  const popup = window.open(url, '', optionsArray.join(','));

  if (!popup || popup.closed || typeof popup.closed == 'undefined') {
    return Promise.reject(new Error('Login pop-up window was blocked. Please allow Vancery to open pop-ups.'));
  }

  popup.focus();

  return new Promise<SessionHttp.OAuth2.Response>((resolve, reject) => {
    const interval = setInterval(() => {
      if (!popup || popup.closed) {
        window.removeEventListener('message', messageHandler);
        clearInterval(interval);
        return reject(new Error('Login flow cancelled.'));
      }
    }, 100);

    const messageHandler = (message: MessageEvent<unknown>) => {
      const data = message.data as IOAuth2.AuthorizeCallback.Payload;
      if (data?.type === 'oauth2-callback') {
        $sessionHttp.oauth2({
          identifier: data.identifier,
          code: data.code,
          state: data.state,
        })
        .then(session => {
          clearInterval(interval);
          popup.close();

          $session.set({
            authenticated: session.authenticated,
            csrf: session.csrf,
            expiry: session.expiry,
            userId: session.userId,
          });
          $session.setLastLoginStrategy({ strategy: 'enterprise' });
          resolve(session);
        })
        .catch(e => {
          reject(e);
        })
        .finally(() => {
          window.removeEventListener('message', messageHandler);
        });
      }
    };

    window.addEventListener('message', messageHandler);
  });
}

export function callback({ identifier, code, state }: IOAuth2.AuthorizeCallback.Params): Promise<IOAuth2.AuthorizeCallback.Result> {
  const message: IOAuth2.AuthorizeCallback.Payload = {
    type: 'oauth2-callback',
    identifier,
    code,
    state,
  };

  const opener = window.opener as Window;
  if (opener) {
    opener.postMessage(message, process.env.FRONT_BASE_URL);
    return Promise.resolve({ type: 'popup' });
  } else {
    return $sessionHttp.oauth2({
      identifier,
      code,
      state,
    })
    .then(session => {
      $session.set({
        authenticated: session.authenticated,
        csrf: session.csrf,
        expiry: session.expiry,
        userId: session.userId,
      });
      $session.setLastLoginStrategy({ strategy: 'enterprise' });
      return Promise.resolve({
        type: 'redirect',
        returnUrl: session.returnUrl,
      });
    });
  }
}
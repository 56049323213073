import { useCallback } from 'react';
import useSearchLoadingReset from './useSearchLoadingReset';
import useSearchResultsReset from './useSearchResultsReset';

export default function useSearchReset() {
  const loading = useSearchLoadingReset();
  const results = useSearchResultsReset();

  const reset = useCallback(() => {
    loading();
    results();
  }, [loading, results]);

  return reset;
}

export { useSearchReset };
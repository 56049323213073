import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { RequestCallSubmitHandler, useAddToProject, useRemoveUser, withRequestCallScheduler } from '@containers/Scheduler';
import * as scheduler from '@containers/Scheduler/utils';
import * as enums from '@enums';
import { cx, useLock } from '@utils';
import { Button } from 'components/Button';
import { ButtonSetProps } from './interfaces';
import styles from './style.css';

const selectProjects = (state: Store.State) => state.projects;
const selectPipeline = (state: Store.State) => state.pipeline;

type Props =
    ButtonSetProps
  & RequestCallSubmitHandler;

const ButtonSet = (props: Props) => {
  const removeUser = useRemoveUser();
  const addToProject = useAddToProject();
  const projects = useSelector(selectProjects);
  const pipeline = useSelector(selectPipeline);

  const { users } = pipeline.project[props.projectId];
  const record = users[props.userId];

  const statuses = [
    enums.UserProjectStatus.Rejected,
  ];

  const canInteract = !record  || statuses.includes(record.statusId);

  const getRequestButtonText = () => {
    const project = projects[props.projectId];

    return scheduler.projects.isProjectSurveyOnly(project)
         ? 'Invite'
         : 'Request Call';
  };

  const onBookmarkUser = useCallback(() => {
    return addToProject({
      projectId: props.projectId,
      userIds: [ props.userId ],
    });
  }, [
    addToProject,
    props.projectId,
    props.userId,
  ]);

  const onRequestCall = () => {
    return new Promise((resolve, reject) => props.handleSubmitCallRequest({
      projectId: props.projectId,
      userId: props.userId,
    }, resolve));
  };

  const onRemoveUser = useCallback(() => {
    return removeUser({
      projectId: props.projectId,
      userId: props.userId,
    });
  }, [
    props.projectId,
    props.userId,
    removeUser,
  ]);

  const [isBusy, lock] = useLock(false);
  const className = cx(styles.btn, isBusy ? styles.busy : styles.ready);

  return (
    <>
      {canInteract &&
        <Button
          className={className}
          color="secondary"
          onClick={lock(onBookmarkUser)}
          title="Add to Project" />}
      {canInteract &&
        <Button
          className={className}
          color="primary"
          onClick={lock(onRequestCall)}
          title={getRequestButtonText()} />}
      {!canInteract &&
        <Button
          className={className}
          color="destructive"
          onClick={lock(onRemoveUser)}
          title="Remove" />}
    </>
  );
};

export default compose(
  withRequestCallScheduler,
)(ButtonSet);
import { useContext } from 'react';
import { getSelectedGroup } from '@remirror/core';
import { ShortcutHandlerProps, createMarkPositioner } from 'remirror/extensions';
import { createNamedContext } from '@utils';
import { FetchSuggestedMentions } from './interfaces.mention';

type LinkShortcutContextValue = {
  editing: boolean;
  link: ShortcutHandlerProps;
  setEditing: (state: boolean) => void;
};

type LinkFloatingToolbarContextValue = {
  href: string;
  text: string;
  getSelectedGroup: () => ReturnType<typeof getSelectedGroup>;
  popper: boolean;
  positioner: ReturnType<typeof createMarkPositioner>;
  setHref: (href: string) => void;
  setPopperOpen: (state: boolean) => void;
  setText: (text: string) => void;
};

type SuggestedMentionsContextValue = [string, FetchSuggestedMentions];

export const LinkShortcutContext = createNamedContext<LinkShortcutContextValue>(undefined, 'LinkShortcut.Context');
export const LinkFloatingToolbarContext = createNamedContext<LinkFloatingToolbarContextValue>(undefined, 'LinkFloatingToolbar.Context');
export const SuggestedMentionsContext = createNamedContext<SuggestedMentionsContextValue>(undefined, 'SuggestedMentionsContext');

export const useSuggestedMentionsContext = () => useContext(SuggestedMentionsContext);
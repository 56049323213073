import { useProjectOrLatestChild } from '@containers/GroupProject/hooks/useProjectState';
import { SurveyContext } from '@containers/Survey/Context';
import { ProjectSurveyResponsesLoadingContext, ProjectSurveyResponsesContext } from './Context';
import SurveyStarredAnswersContainer from './SurveyStarredAnswersContainer';
import { useFetchProjectSurveyResponses, useProjectSurveyResponsesState } from './hooks';

export const MassSurveyResponsesDataContainer = (props: ChildrenProps) => {
  const project = useProjectOrLatestChild();
  const { query } = useProjectSurveyResponsesState();

  const {
    data,
    isLoading,
  } = useFetchProjectSurveyResponses({
    limit: 1,
    projectId: project.id,
    questionId: query.questionId,
  }, {
    keepPreviousData: true,
  });

  return (
    <ProjectSurveyResponsesLoadingContext.Provider value={isLoading && !data?.survey}>
      <ProjectSurveyResponsesContext.Provider value={data?.items}>
        <SurveyContext.Provider value={data?.survey || null}>
          <SurveyStarredAnswersContainer initial={data?.starred || {}}>
            {props.children}
          </SurveyStarredAnswersContainer>
        </SurveyContext.Provider>
      </ProjectSurveyResponsesContext.Provider>
    </ProjectSurveyResponsesLoadingContext.Provider>
  );
};

export default MassSurveyResponsesDataContainer;
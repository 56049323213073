import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelectWorkspaces } from '@containers/Store';
import { CreateFolderParams } from '@containers/WorkspaceFolders/interfaces';
import { useCreateFolder } from '@containers/WorkspaceFolders/hooks/useCreateFolder';
import { getLocationFor } from '@utils';
import NewFolderModal from 'components/WorkspaceFolders/NewFolderModal';
import { NewFolderModalProps } from 'components/WorkspaceFolders/interfaces';

type Props =
  Pick<NewFolderModalProps,
    'location' |
    'parentObject'>;

export const useNewFolderModal = (props: Props) => {
  const [visible, setVisibility] = useState(false);
  const toggleVisibility = useCallback(() => setVisibility(!visible), [visible]);
  const history = useHistory();

  const workspaces = useSelectWorkspaces();
  const workspacesArr = useMemo(() => [workspaces.personal, workspaces.group], [workspaces]);
  const createFolder = useCreateFolder();

  const handleConfirm = useCallback((data: CreateFolderParams) => {
    return createFolder(data)
      .then(({ folder }) => {
        const path = getLocationFor.workspaces.folder({ folderId: folder.id });
        history.push(path);
      });
  }, [
    createFolder,
    history,
  ]);

  const Modal = useCallback(() => {
    if (!open) return null;
    return (
      <NewFolderModal
        onClose={toggleVisibility}
        onConfirm={handleConfirm}
        open={visible}
        workspaces={workspacesArr}
        {...props} />
    );
  }, [
    handleConfirm,
    visible,
    toggleVisibility,
    props,
    workspacesArr,
  ]);

  return [toggleVisibility, Modal] as const;
};

export default useNewFolderModal;
import { memo, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { Route as RouteProp } from './interfaces';

type Props = {
  routes: RouteProp[];
};

const renderLoader = () => <ActivityIndicator show />;

export const PathSajak = memo(({ routes }: Props) => {
  return (
    <Suspense fallback={renderLoader()}>
      <Switch>
        {routes.map(config => (
          <Route
            key={String(config.path)}
            component={config.component}
            exact={config.exact}
            path={config.path} />
        ))}

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Suspense>
  );
});

export default PathSajak;
import {
  extension,
  ApplySchemaAttributes,
  ExtensionTag,
  MarkExtension,
  MarkExtensionSpec,
} from '@remirror/core';
import { Timestamp } from '@/types/transcribe.rich-text';

@extension({
  defaultOptions: {},
  disableExtraAttributes: true,
})
export class TimestampExtension extends MarkExtension {
  get name() {
    return 'ts' as const;
  }

  createTags() {
    return [ExtensionTag.FormattingMark];
  }

  createMarkSpec(extra: ApplySchemaAttributes): MarkExtensionSpec {
    return {
      attrs: {
        s: {},
        e: {},
      },
      parseDOM: [
        {
          tag: 'span',
          getAttrs: extra.parse,
        },
      ],
      toDOM: mark => {
        const attrs = mark.attrs as Timestamp.Attributes;
        return ['span', {
          'data-st': attrs.s.toString(),
          'data-et': attrs.e.toString(),
          ...extra.dom(mark),
        }, 0];
      },
    };
  }
}
import { Remirror } from '@remirror/react';
import { useTranscript } from '@containers/Transcript';
import { useRichTextTranscript } from './hooks';
import { TranscriptEditor } from './Editor';
import styles from './style/Transcript.css';

type Props = {
  className?: string;
};

const Transcript = ({
  className,
}: Props) => {

  const transcript = useTranscript();

  const { manager, state } = useRichTextTranscript({
    transcript: transcript.document,
  });

  return (
    <Remirror
      classNames={[styles.root, className]}
      editable={false}
      manager={manager}
      initialContent={state}>
      <TranscriptEditor />
    </Remirror>
  );

};

export { Transcript };
export default Transcript;
import { useEffect } from 'react';
import { EditorComponent } from '@remirror/react';
import { useTranscriptHighlights, useTranscriptFocusedComment } from '@containers/Transcript';
import { usePreviousDistinct } from '@utils';
import { TranscriptChainedCommandsContext, TranscriptHelpersContext } from './context';
import {
  useChainedCommands,
  useHelpers,
  useFocusHighlightEvent,
  useHighlightEvents,
  useMediaTimeChangeEvent,
  useSeekEvent,
} from './hooks';
import { TranscriptComments } from './Comments';
import { HighlightTooltipContainer } from './Tooltip.Highlight.Container';
import { KaraokeMarker } from './KaraokeMarker';

const TranscriptEditor = () => {
  const commands = useChainedCommands();
  const helpers = useHelpers();

  useInitializeHighlights();
  useFocusHighlightEvent();
  useHighlightEvents();
  useSeekEvent();
  useMediaTimeChangeEvent();
  useBlurCommentHighlight();

  return (
    <TranscriptChainedCommandsContext.Provider value={commands}>
      <TranscriptHelpersContext.Provider value={helpers}>
        <EditorComponent />
        <HighlightTooltipContainer />
        <KaraokeMarker />
        <TranscriptComments />
      </TranscriptHelpersContext.Provider>
    </TranscriptChainedCommandsContext.Provider>
  );
};

function useInitializeHighlights() {

  const commands = useChainedCommands();

  const highlights = useTranscriptHighlights();
  const { getHighlightsInitialized } = useHelpers();
  const initialized = getHighlightsInitialized();

  useEffect(() => {
    if (!initialized && highlights.length) {
      commands.setHighlights(highlights).run();
    }
  }, [
    commands,
    initialized,
    highlights,
  ]);
}

function useBlurCommentHighlight() {
  const focusedComment = useTranscriptFocusedComment();
  const previouslyFocusedCommentHighlight = usePreviousDistinct(focusedComment?.highlight?.identifier);

  const commands = useChainedCommands();

  useEffect(() => {

    if (focusedComment?.highlight?.identifier) {
      commands.focusHighlight(focusedComment.highlight.identifier, false).run();
    } else if (previouslyFocusedCommentHighlight) {
      commands.blurHighlight().run();
    }
  }, [
    commands,
    focusedComment?.highlight?.identifier,
    previouslyFocusedCommentHighlight,
  ]);
}

export { TranscriptEditor };
export default TranscriptEditor;
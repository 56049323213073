import { useContext } from 'react';
import { addMonths, isDate, isValid, startOfMonth } from 'date-fns/fp';
import { DatePicker } from 'components/DatePicker';
import { FormDerivedEditStateContext, FormStateContext } from './Context';
import { TextDisplayField } from './TextField';

type Props = {
  className?: string;
  name:       string;
  value:      string;
};

export const DateField = (props: Props) => {
  const editing = useContext(FormDerivedEditStateContext);

  return editing
    ? <DateInputField
        className={props.className}
        name={props.name} />
    : <DateDisplayField
        className={props.className}
        value={props.value} />;
};

export const DateDisplayField = (props: Omit<Props, 'name'>) => {
  return (
    <TextDisplayField
      className={props.className}
      value={props.value} />
  );
};

export const DateInputField = (props: Omit<Props, 'value'>) => {
  const [state, dispatch] = useContext(FormStateContext);

  const handleInputEvent = (item: Date) => {
    dispatch({
      type: 'value',
      key: props.name as keyof typeof state.values,
      value: item,
    });
  };

  const formValue = state.values[props.name];
  const hasDateSet = isDate(formValue) && isValid(formValue);

  const value = hasDateSet
      ? formValue
      : null;

  const openToDate = hasDateSet
      ? formValue
      : startOfMonth(addMonths(6, new Date()));

  return (
    <DatePicker
      className={props.className}
      inline={false}
      placeholderText="Click to select a date"
      onChange={handleInputEvent}
      openToDate={openToDate}
      selected={value} />
  );
};

export default DateField;
import { ConferenceCoordinatorContainer } from './CoordinatorContainer';
import { ConferenceSocketContainer } from './SocketContainer';
import { TwilioVoiceContainer } from './TwilioVoiceContainer';
import { TwilioVideoContainer } from './TwilioVideoContainer';

export function ConferenceContainer({ children }: ChildrenProps) {
  return (
    <ConferenceSocketContainer>
      <TwilioVoiceContainer>
        <TwilioVideoContainer>
          <ConferenceCoordinatorContainer>
            {children}
          </ConferenceCoordinatorContainer>
        </TwilioVideoContainer>
      </TwilioVoiceContainer>
    </ConferenceSocketContainer>
  );
}
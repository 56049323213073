import { useState } from 'react';
import { cx } from '@utils';
import NewBank from './NewBank';
import NewCard from './NewCard';
import { OnErrorEvent, OnSaveEvent, OnTokenEvent } from './interfaces';
import styles from './style/NewPayment.css';

type Props = {
  className?: string;
  isLoading?: boolean;
  onError?: OnErrorEvent;
  onSave?: OnSaveEvent;
  onToken: OnTokenEvent;
};

type Method = 'card' | 'bank';

export function NewPayment({ className, isLoading, onError, onSave, onToken }: Props) {
  const [method, setMethod] = useState<Method>('card');

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.row}>
        <div className={styles.label}>Payment Method</div>
        <div className={styles.methods}>
          <div
            className={cx(styles.method, { [styles.active]: method === 'card'})}
            onClick={() => setMethod('card')}>
            Bank Card
          </div>
          <div
            className={cx(styles.method, { [styles.active]: method === 'bank'})}
            onClick={() => setMethod('bank')}>
            ACH Bank Account
          </div>
        </div>
      </div>
      {method === 'card' && <NewCard isLoading={isLoading} onError={onError} onSave={onSave} onToken={onToken} />}
      {method === 'bank' && <NewBank isLoading={isLoading} onError={onError} onSave={onSave} onToken={onToken} />}
    </div>
  );
}

export default NewPayment;
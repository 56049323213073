import { useCallback, useMemo, useState } from 'react';
import * as enums from '@enums';
import { useDebounceCallback } from '@utils';
import { GroupUser } from '@/types';
import { useObjectAccessState } from './hooks';
import {
  FetchObjectAccessContext,
  GroupUsersContext,
  ObjectAccessLoadingContext,
  SaveObjectAccessContext,
  ObjectAccessContext,
  ObjectAccessContextValue,
} from './Context';
import { ObjectAccess as OA } from './interfaces';

type Props =
  OA.ObjectAccessContainerProps;

export const BaseObjectAccessContainer = (props: Props) => {
  const [state, dispatch] = useObjectAccessState();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<ObjectAccessContextValue>({
    object: null,
    workspace: null,
  });

  const fetchAccess = useCallback(() => {
    setLoading(true);

    return props.fetchAccess()
      .then(data => {
        dispatch({
          items: data.items,
          type: 'load',
        });
        setValues({
          object: data.object,
          workspace: data.workspace,
        });
        setLoading(false);
      });

  }, [
    dispatch,
    props.fetchAccess,
  ]);

  const saveAccess = useCallback(() => {
    return props.saveAccess({
      items: state.items.map(m => ({
        roleId: m.roleId,
        userId: m.user.id,
      })),
    }).then(_ => {
      dispatch({
        items: state.items,
        type: 'load',
      });
    });
  }, [
    dispatch,
    props.saveAccess,
    state.items,
  ]);

  const [users, setUsers] = useState<GroupUser[]>(null);

  const fetchGroupUsers = useCallback((value?: string) => {
    if (!values.object?.groupId) {
      return;
    }
    return props.fetchGroupUsers({
      excluded: state.items.map(m => m.user.id),
      groupId: values.object.groupId,
      limit: 15,
      roleIds: [enums.Role.FirmAnalyst],
      value,
    }).then(data => {
      setUsers(data.items);
    });
  }, [
    values.object?.groupId,
    props.fetchGroupUsers,
    state.items,
  ]);

  const fetchGroupUsersDebounced = useDebounceCallback(fetchGroupUsers, 200);

  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter(f => !state.items.some(s => s.user.id === f.id));
  }, [
    state.items,
    users,
  ]);

  const groupCtx = {
    items: filteredUsers,
    fetch: fetchGroupUsersDebounced,
  };

  return (
    <FetchObjectAccessContext.Provider value={fetchAccess}>
      <ObjectAccessLoadingContext.Provider value={loading}>
        <SaveObjectAccessContext.Provider value={saveAccess}>
          <ObjectAccessContext.Provider value={values}>
            <GroupUsersContext.Provider value={groupCtx}>
              {props.children}
            </GroupUsersContext.Provider>
          </ObjectAccessContext.Provider>
        </SaveObjectAccessContext.Provider>
      </ObjectAccessLoadingContext.Provider>
    </FetchObjectAccessContext.Provider>
  );
};

export default BaseObjectAccessContainer;
import { useContext, useMemo } from 'react';
import { SurveyThemingContext } from '@containers/Branding/Context';
import { WhitelabelMenuDisplayContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const MenuDisplayContainer = (props: Props) => {
  const ctx = useContext(SurveyThemingContext);

  const enabled = useMemo(() => {
    return ctx?.theming?.loading
        || !!ctx?.theming?.data;
  }, [
    ctx?.theming?.data,
    ctx?.theming?.loading,
  ]);

  const context = {
    whitelabel: enabled,
  };

  return (
    <WhitelabelMenuDisplayContext.Provider value={context}>
      {props.children}
    </WhitelabelMenuDisplayContext.Provider>
  );
};

MenuDisplayContainer.displayName = 'Whitelabel.MenuDisplayContainer';
import mixpanel from 'mixpanel-browser';
import * as enums from '@enums';
import { BaseVICS } from '@/types';
import { Onboarding } from './interfaces';

function transformVICS(data: BaseVICS) {
  return {
    id: data.id,
    name: data.name,
  };
}

export function getStarted() {
  mixpanel.track('onboarding:get-started');
}

export function addVICS(data: Onboarding.AddVICS) {
  const type = enums.VICSType.getSearchType(data.typeId);
  mixpanel.track(`onboarding:add-${type}`, transformVICS(data));
}

export function removeVICS(data: Onboarding.RemoveVICS) {
  const type = enums.VICSType.getSearchType(data.typeId);
  mixpanel.track(`onboarding:remove-${type}`, transformVICS(data));
}

export function completeVICS(data: Onboarding.CompleteVICS) {
  const type = enums.VICSType.getSearchType(data.typeId);
  mixpanel.track(`onboarding:complete-${type}`, { selected: data.vics });
}

export function selectSeniority(data: Onboarding.SelectSeniority) {
  mixpanel.track(`onboarding:select-seniority`, {
    ...data,
    name: enums.utils.Seniority.getName(data.id),
  });
}

export function completeSeniority(data: Onboarding.CompleteSeniority) {
  mixpanel.track(`onboarding:complete-seniority`, {
    ...data,
    name: enums.utils.Seniority.getName(data.id),
  });
}

export function selectHourlyRate(data: Onboarding.SelectHourlyRate) {
  mixpanel.track(`onboarding:select-hourly-rate`, data);
}

export function completeHourlyRate(data: Onboarding.CompleteHourlyRate) {
  mixpanel.track(`onboarding:complete-hourly-rate`, data);
}

export function complianceCardStart() {
  mixpanel.track(`onboarding:compliance-card-start`);
}

export function profileCardStart() {
  mixpanel.track(`onboarding:profile-card-start`);
}

export function hourlyRateCardStart() {
  mixpanel.track(`onboarding:hourly-rate-card-start`);
}

export function complianceRulesRead() {
  mixpanel.track(`onboarding:compliance-rules:read`);
}

export function complianceTestAnswer(data: Onboarding.ComplianceTestAnswer) {
  mixpanel.track(`onboarding:compliance-test:answer`, data);
}

export function complianceTestSubmit(data: Onboarding.ComplianceTestSubmit) {
  mixpanel.track(`onboarding:compliance-test:submit`, data);
}

export function complianceQuestionsAnswer(data: Onboarding.ComplianceQuestionsAnswer) {
  mixpanel.track(`onboarding:compliance-questions:answer`, data);
}

export function complianceQuestionsSubmit() {
  mixpanel.track(`onboarding:compliance-questions:submit`);
}

export function workAdd() {
  mixpanel.track(`onboarding:profile:work-experience:add`);
}

export function workRemove() {
  mixpanel.track(`onboarding:profile:work-experience:remove`);
}

export function educationAdd() {
  mixpanel.track(`onboarding:profile:education:add`);
}

export function educationRemove() {
  mixpanel.track(`onboarding:profile:education:remove`);
}

export function completeProfile() {
  mixpanel.track(`onboarding:profile:complete`);
}

const complianceRules = {
  read: complianceRulesRead,
};

const complianceTest = {
  answer: complianceTestAnswer,
  submit: complianceTestSubmit,
};

const complianceQuestions = {
  answer: complianceQuestionsAnswer,
  submit: complianceQuestionsSubmit,
};

const profile = {
  addWork: workAdd,
  removeWork: workRemove,
  addEducation: educationAdd,
  removeEducation: educationRemove,
  complete: completeProfile,
};

export {
  complianceRules,
  complianceTest,
  complianceQuestions,
  profile,
};
import { useCallback } from 'react';
import { useDownloader } from '@utils/hooks';
import Button from 'components/Button';
import SearchBarOld from 'components/SearchBarOld';
import QueryStats from './QueryStats';
import styles from './style.css';

type Props = {
  onAddToProject: () => unknown;
  onShowFilters:  () => unknown;
  downloadCSV: () => Promise<{ blob: Blob;  filename: string; }>;
  resultsCount:   number;
  selectedCount:  number;
  totalCount:     number;
  canExport:      boolean;
};

const GeneralSearchListHeader = (props: Props) => {
  const download = useDownloader();

  const downloadCSV = useCallback(() => {
    props.downloadCSV()
      .then(response => {
        download(response);
      });
  }, [download, props]);

  return (
    <>
      <div className={styles.searchbar}>
        <SearchBarOld />
      </div>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.action}>
            <QueryStats count={props.totalCount} />

            {props.resultsCount > 0 &&
              <Button
                color="primary"
                disabled={!props.selectedCount}
                onClick={props.onAddToProject}>
                Add to Project
              </Button>}
          </div>
          {props.downloadCSV && (
            <div className={styles.export}>
              <Button
                color="primary"
                variant="brick"
                onClick={downloadCSV}>
                Members CSV
              </Button>
            </div>)
          }

          <div className={styles.filters}>
            <Button
              color="primary"
              onClick={props.onShowFilters}>
              Filters
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export { GeneralSearchListHeader };
export default GeneralSearchListHeader;
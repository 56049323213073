import { SurveyQuestionType } from '@enums';
import { MatrixMultiselectQuestion } from '@/types';
import { Validation } from '../interfaces';
import { assertValidOptions } from './validation.options';
import { assertValidRows } from './validation.rows';
import { assertHasValue, returnAssertFailure } from './validation.base';
import { assertValidSelectionSettings as assertSelectionSettings, getSelectionSettingsMinMax } from './validation.settings.selections';

export const DefaultColumns = 5;
export const MaxColumns = 11;
export const MinColumns = 2;

export const DefaultRows = 5;
export const MaxRows = 30;
export const MinRows = 1;

export const DefaultSettings: MatrixMultiselectQuestion.Settings = {
  includeNotApplicable: false,
  maximumSelections: null,
  minimumSelections: null,
  randomization: false,
};

export function getSelectionSettingOptions(item: MatrixMultiselectQuestion.Question) {
  return getSelectionSettingsMinMax({
    hasNoneOfTheAbove: item.settings.includeNotApplicable,
    options: item.options.length,
  });
}

export function assertValidSelectionSettings(question: MatrixMultiselectQuestion.Question) {
  const [min, max] = getSelectionSettingOptions(question);

  return assertSelectionSettings({
    min,
    max,
    settings: question.settings,
  });
}

export function validateQuestion(question: MatrixMultiselectQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.MatrixGrid> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinColumns, MaxColumns);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  const hasValidRows = assertValidRows(question.matrixRows, MinRows, MaxRows);
  if (hasValidRows.success === false) {
    return returnAssertFailure(hasValidRows, question);
  }

  const hasValidSettings = assertValidSelectionSettings(question);
  if (hasValidSettings.success === false) {
    return returnAssertFailure(hasValidSettings, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}

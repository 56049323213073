import { ImageCrop } from 'components/ImageCrop';
import Modal from 'components/Modal/Modal.Legacy';
import styles from './style.css';

type Props = {
  onClose: () => void;
  onSave: (data: {
    blob: Blob;
    url: string;
  }) => unknown;
  pictureUrl: string;
  visible: boolean;
};

export const EditLogoModal = ({
  onSave = () => {},
  onClose = () => {},
  pictureUrl = null,
  visible = false,
}: Props) => {
  const save = data => {
    onSave(data);
    onClose();
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}>
      <div className={styles.title}>Edit Company Logo</div>
      <div className={styles.root}>
        <div className={styles.container}>
          <ImageCrop
            circularCrop={false}
            imageUrl={pictureUrl}
            onSave={save}
            ruleOfThirds={false} />
        </div>
      </div>
    </Modal>
  );
};

export default EditLogoModal;
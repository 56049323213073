import {
  SurveyQuestion,
} from '@/types/survey';
import { SurveyRichText } from '@/types/survey.rich-text';
import { Validation } from '../interfaces';

export function returnAssertFailure(assert: Validation.AssertionFailure, question: SurveyQuestion) {
  return {
    success: false,
    result: {
      message: assert.message,
      question,
      data: null,
    },
  };
}

export function assertHasValue(question: SurveyQuestion): Validation.AssertionResult {
  const text = question.value.content.reduce((acc, c) => {
    if (!c.content) return acc;

    const blockContent = c.content.filter(f => f.type === SurveyRichText.NodeType.Text).join('');

    return acc.concat(blockContent);
  }, '');

  const isEmpty = !text?.length;

  if (isEmpty) {
    return {
      success: false,
      message: 'Value is empty.',
    };
  }

  return { success: true };
}

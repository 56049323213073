import { cx } from '@utils';
import { WizardHeaderProps } from './interfaces';
import styles from './style.css';

export const WizardHeader = (props: WizardHeaderProps) => (
  <div className={cx(styles.header, props.className)}>
    <div className={styles.title}>{props.title}</div>
    {props.subtitle && <div className={styles.subtitle}>{props.subtitle}</div>}
  </div>
);

export type { WizardHeaderProps };
export default WizardHeader;
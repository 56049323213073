import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as api from '@api';
import * as API from '@api/interfaces';
import * as QK from '@consts/querykey';
import { pathname } from '@consts';
import Toast from 'components/Toast';

type Params = number;

export const useDeleteArticlePostMutation = (postId: Params) => {
  const history = useHistory();

  const mutation = useMutation<API.Posts.Articles.DeleteArticle.Response>([QK.Posts.Articles.Delete, postId], () => {
    return api.posts.articles.deleteArticle({
      postId,
    });
  }, {
    onSuccess: () => {
      Toast.success({
        title: `Article deleted`,
      });
      history.replace(pathname.Home);
    },
  });

  return mutation;
};
import { cx } from '@utils';
import { TestButtonsProps } from '../interfaces';
import styles from './style.css';

const TestButtons = (props: TestButtonsProps) => {
  const renderBackButton = () => (
    <button
      className={cx(styles.btn, styles.cancel)}
      onClick={props.onBack}
      type='button'>
      Back
    </button>
  );

  const renderSubmitButton = () => (
    <button
      className={cx(styles.btn,  styles.submit)}
      disabled={props.disabled}
      onClick={props.onSubmit}
      type='button'>
      Submit
    </button>
  );

  const renderNextButton = () => (
    <button
      className={cx(styles.btn,  styles.submit)}
      onClick={props.onNext}
      type='button'>
      Next
    </button>
  );

  return (
    <div className={styles.root}>
      {renderBackButton()}
      {props.results?.length ? renderNextButton() : renderSubmitButton()}
    </div>
  );
};

export default TestButtons;

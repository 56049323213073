import { useCallback } from 'react';
import { useTranscriptComments } from '../context';

export const useGetCommentsForHighlight = () => {

  const [state] = useTranscriptComments();

  return useCallback((highlightIdentifier: string) => {
    return state.items
      .filter(f => f.highlight.identifier === highlightIdentifier);

  }, [state.items]);

};
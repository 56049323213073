import { useCallback, useState } from 'react';
import NumberFormat, { NumberFormatPropsBase } from 'react-number-format';
import { cx } from '@utils';
import { FocusableAnimation } from 'components/Focusable';
import { InputProps } from './interfaces';
import styles from './style.css';

type Props =
  Pick<InputProps,
    'focusableClasses' |
    'classes' |
    'onBlur' |
    'onFocus' |
    'readOnly'>
  & Omit<NumberFormatPropsBase, 'className'> & { rootClassName?: string; };

export const NumberInput = ({
  classes = {},
  focusableClasses = {},
  onBlur,
  onFocus,
  rootClassName,
  ...props
}: Props) => {
  const [focused, setFocus] = useState<boolean>(props.autoFocus);

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(false);

    onBlur?.(e);
  }, [
    onBlur,
    setFocus,
  ]);

  const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(true);

    onFocus?.(e);
  }, [
    onFocus,
    setFocus,
  ]);

  return (
    <div className={cx(styles.root, rootClassName, classes.root)}>
      <NumberFormat
        {...props}
        className={cx(styles.numberInput, classes.input)}
        onBlur={handleBlur}
        onFocus={handleFocus} />
      <FocusableAnimation
        classes={{
          focused: focusableClasses.focused,
          root: focusableClasses.root,
        }}
        focused={focused}
        readOnly={props.readOnly} />
    </div>
  );
};

export default NumberInput;
import { LifeCycleSocketEvent, NotificationsSocketEvent, PipelineSocketEvent, SocketNamespace } from '@enums';

type Logger = (namespace: SocketNamespace) => (event: SocketEvent, args?: unknown[]) => void;

const logEvent: Logger = namespace => (event, ...args) => {
  const types = {
    connect: logInfo,
    disconnect: logWarn,
    authenticated: logInfo,
    unauthorized: logWarn,
  } as { [event: string]: Logger; };

  const logger: Logger = types[event] || logInfo;
  logger(namespace)(event, ...args);
};

const logInfo: Logger = namespace => (event, ...args) => {
  if (!process.env.__DEV__) return;

  console.groupCollapsed(
    `%c socket:${namespace} %c${event.toUpperCase()} %c⌁`,
    'color: #979797;',
    'color: #aaffff; font-weight: bold;',
    'color: #d7ff00;'
  );
  console.info(...args);
  console.groupEnd();
};

const logWarn: Logger = namespace => (event, ...args) => {
  if (!process.env.__DEV__) return;

  console.groupCollapsed(
    `%c socket:${namespace} %c${event.toUpperCase()} %c⌁`,
    'color: #979797;',
    'color: #aaffff; font-weight: bold;',
    'color: #c10d34',
  );
  console.info(...args);
  console.groupEnd();
};

export const createLogger = (namespace: SocketNamespace) => ({
  event: logEvent(namespace),
  info: logInfo(namespace),
  warn: logWarn(namespace),
});

type SocketEvent =
  LifeCycleSocketEvent |
  NotificationsSocketEvent |
  PipelineSocketEvent
;
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ProjectType } from '@enums';
import {
  PipelineRecord,
} from '@/types';
import Toast from 'components/Toast';

export const useNotifier = () => {

  const state = useSelector((state: Store.State) => state);

  const callInviteAwaitingCompliance = useCallback((record: PipelineRecord) => {
    // const user = state.contacts[record.userId];

    return Toast.info({
      title: `Call request awaiting compliance review`,
      body: `You will be notified when review completes.`,
    });
  }, []);

  const callInviteAwaitingConsultant = useCallback((record: PipelineRecord) => {
    const user = state.contacts[record.userId];

    return Toast.info({
      title: `A call request has already been sent`,
      body: `You will be notified once ${user.profile.firstName} responds.`,
    });
  }, [state.contacts]);

  const projectInviteInProgress = useCallback((record: PipelineRecord) => {
    const user = state.contacts[record.userId];

    return Toast.info({
      title: `A request has already been sent`,
      body: `You will be notified once ${user.profile.firstName} has completed onboarding.`,
    });
  }, [state.contacts]);

  const projectInviteWithCallSent = useCallback((record: PipelineRecord) => {
    const project = state.projects[record.projectId];
    const user = state.contacts[record.userId];

    return Toast.success({
      title: 'Call Request Sent',
      body: `You have sent a call invitation to ${user.profile.fullname} for the ${project.name} Project.`,
    });
  }, [state.contacts, state.projects]);

  const projectInviteWithSurveySent = useCallback((record: PipelineRecord) => {
    const project = state.projects[record.projectId];
    const user = state.contacts[record.userId];

    return Toast.success({
      title: 'Request Sent',
      body: `You have sent an invitation to ${user.profile.fullname} for the ${project.name} Project.`,
    });
  }, [state.contacts, state.projects]);

  const projectInviteSent = useCallback((record: PipelineRecord) => {
    const types = [
      ProjectType.Survey,
    ];

    const project = state.projects[record.projectId];

    return types.includes(project.projectType)
      ? projectInviteWithSurveySent(record)
      : projectInviteWithCallSent(record);
  }, [
    projectInviteWithSurveySent,
    projectInviteWithCallSent,
    state.projects,
  ]);

  return {
    callInviteAwaitingCompliance,
    callInviteAwaitingConsultant,
    projectInviteInProgress,
    projectInviteSent,
  };

};

export * from './Notifier.AdHocCall';
export * from './Notifier.ProjectCall';
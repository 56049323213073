import { useQuery } from 'react-query';
import Toast from 'components/Toast';
import * as api from '@api';
import { useDownloader } from '@utils/hooks';

export const useDownloadAccountReport = () => {
  const download = useDownloader();

  return useQuery('download-account-report', () => {
    return api.users.exportUserData();
  }, {
    enabled: false,
    onError: () => {
      Toast.error({
        body: 'Account Report',
        title: 'There was an error trying to download the report.',
      });
    },
    retry: 1,
    onSuccess: res => {
      download({
        blob: res.blob,
        filename: res.filename,
      });
    },
  });

};
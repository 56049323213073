import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import { useSurvey } from '@containers/Survey/hooks';
import { useProjectSurveyResponsesLoading, useProjectSurveyResponsesState, useValidateResponsesPath } from '@containers/SurveyResponses/hooks';
import { ResponseQuestion } from '@presentation';
import { cx } from '@utils';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { QuestionResponses } from 'components/SurveyResponses';
import styles from './style/ProjectTabSurvey.css';

type Props = {
  ResponsesComponent?: React.ComponentType;
};

const useQuestionsScrollRef = () => {
  const ctx = useProjectSurveyResponsesState();
  const loading = useProjectSurveyResponsesLoading();

  const [mounted, setMounted] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mounted && ref.current) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setMounted(true);
    }
  }, [
    ctx.query.questionId,
    loading,
    mounted,
    setMounted,
  ]);

  return ref;
};

const useResponsesScrollRef = () => {
  const ctx = useProjectSurveyResponsesState();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({
        behavior: 'auto',
        top: 0,
      });
    }
  }, [
    ctx.query.questionId,
    // @ts-ignore
    ctx.query.page,
  ]);

  return ref;
};

const ProjectTabSurvey = ({
  ResponsesComponent = QuestionResponses,
}: Props) => {
  const ctx = useProjectSurveyResponsesState();
  const loading = useProjectSurveyResponsesLoading();
  const survey = useSurvey();
  const validatePath = useValidateResponsesPath();

  useEffect(validatePath, [validatePath]);

  const questionsScrollRef = useQuestionsScrollRef();
  const responsesScrollRef = useResponsesScrollRef();

  const renderQuestions = useCallback(() => {
    return survey.questions.map(question => {
      const isActive = question.base.id === ctx.query.questionId;
      const ref = isActive ? questionsScrollRef : undefined;

      return (
        <Link
          replace
          key={question.base.id}
          to={`${ctx.basePath}?questionId=${question.base.id}`}>
          <ResponseQuestion
            ref={ref}
            isActive={isActive}
            item={question} />
        </Link>
      );
    });
  }, [
    ctx.basePath,
    ctx.query.questionId,
    questionsScrollRef,
    survey,
  ]);

  const renderResponses = useCallback(() => {
    if (!ctx.query.questionId) {
      return null;
    }

    return (
      <ResponsesComponent />
    );
  }, [
    ctx.query.questionId,
    ResponsesComponent,
  ]);

  if (loading) {
    return <ActivityIndicator show />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={cx(styles.main, { [styles.browsing]: !!ctx.query.questionId })}>

          <div className={cx(styles.section, styles.scroll)}>
            <div className={styles.header}>
              <h2 className={styles.h2}>Questions</h2>
            </div>

            {renderQuestions()}
          </div>

          <div className={styles.section}>
            <div
              className={styles.scroll}
              ref={responsesScrollRef}>
              <div className={styles.header}>
                {ctx.query.questionId &&
                  <Link
                    className={styles.back}
                    to={ctx.basePath}>
                    <ChevronLeftRoundedIcon fontSize="large" />
                  </Link>
                }
                <h2 className={styles.h2}>Responses</h2>
              </div>

              {renderResponses()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProjectTabSurvey };
export default ProjectTabSurvey;
import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import * as api from '@api';
import { Integrations } from '@api/interfaces';

type Data = Integrations.Slack.GetTeams.Response['teams'];

export function useSlackTeams(
  options?: UseQueryOptions<Data, AxiosResponse>
) {

  return useQuery(['slack-teams'], () => {
    return api.integrations.getSlackTeams()
      .then(res => res.teams);
  }, options);
}
import { useCallback, useMemo } from 'react';
import { SurveyOptionType } from '@enums';
import { MultiselectQuestion as MQ } from '@/types';
import { CheckboxGroup } from 'components/Checkbox';
import { CheckboxGroupOnItemToggle, CheckboxGroupOnInputChange } from 'components/Checkbox/interfaces';

type Props = {
  answer: MQ.RespondentAnswer;
  className?: string;
  question: MQ.FormQuestion | MQ.Question;
  setAnswer: (value: MQ.RespondentAnswer) => void;
};

export default function MultiselectQuestion({ answer, className, question, setAnswer }: Props) {
  const itemsMapped =
    useMemo(() => question.options.map(o => ({
      allowText: o.type === SurveyOptionType.Other,
      id: o.id,
      label: o.value,
      text: answer?.items.find(ao => ao.optionId === o.id)?.text,
    })), [answer, question.options]);

  const selected =
    useMemo(() => ({
      items: answer?.items ?? [],
      ids: (answer?.items ?? []).map(i => i.optionId),
    }), [answer?.items]);

  const handleToggle: CheckboxGroupOnItemToggle
    = useCallback(item => {
      const optionId = +item.id;

      const option = question.options.find(f => f.id === optionId);

      if (selected.ids.includes(optionId)) {
        setAnswer({
          items: selected.items.filter(i => i.optionId !== optionId),
        });
      } else {
        if (option.type === SurveyOptionType.NoneOfTheAbove) {
          setAnswer({
            items: [{ optionId }],
          });
        } else {
          setAnswer({
            items: [
              ...selected.items
              .filter(f => {
                const o = question.options.find(ff => f.optionId === ff.id);
                return o.type !== SurveyOptionType.NoneOfTheAbove;
              }),
              { optionId },
            ],
          });
        }
      }
    }, [
      question.options,
      selected,
      setAnswer,
    ]);

  const handleInputChange: CheckboxGroupOnInputChange
    = useCallback(item => setAnswer({
      items: [
        ...selected.items.filter(i => i.optionId !== +item.id),
        { optionId: +item.id, text: item.text },
      ],
    }), [selected, setAnswer]);

  return (
    <CheckboxGroup
      className={className}
      checkedItems={selected.ids}
      items={itemsMapped}
      onToggle={handleToggle}
      onInputChange={handleInputChange} />
  );
}

export { MultiselectQuestion };
import { Component } from 'react';
import { isAfter, isSameDay, addSeconds, addHours, endOfDay, getMinutes, startOfMinute } from 'date-fns';
import { Calendar as FullCalendar } from '@fullcalendar/core';
import * as api from '@api';
import { device } from '@utils';
import CalendarBase from 'components/Calendar/CalendarBase';
import EventPopover from 'components/Calendar/EventPopover';
import MoratoriumHover from './MoratoriumHover';
import { ProvidingMain } from './ProvidingMain';
import { ProvidingMainResponsive } from './ProvidingMainResponsive';
import styles from './style/Calendar.css';
import {
  getAvailableEvents,
  getEventAvailabilityId,
  getFullCalSubmitButtonElement,
  getNewEvents,
  getProvidingDefaults,
  getRemovedEvents,
  getResponsiveCompatibleView,
  transformEvent,
  transformExternalEvents,
} from './utils';

class Providing extends Component {
  state = {
    events: [],
    externalEvents: [],
    removedEvents: [],
    selectedExternalCalendars: [
      this.props.user.id,
      this.props.calendar.with.id,
    ],
  }

  componentDidMount() {
    this.fetchCalendar({ initial: true });
    this.renderCalendar();
  }

  componentDidUpdate() {
    this.calendar.refetchEvents();
  }

  componentWillUnmount() {
    if (this.calendar) {
      this.calendar.destroy();
    }
    if (this.pastTimer) {
      clearInterval(this.pastTimer);
    }
  }

  dismissPopover = () => {
    this.setState({ popover: null });
  }

  fetchCalendar = data => {
    return api.calendars.getPersonalCalendar({ scheduleeId: this.props.calendar.with.id })
    .then(resp => {
      this.setState({
        events: [
          ...(data && data.initial ? this.state.events : []),
          ...resp.events.map(e => transformEvent({ contacts: this.props.contacts, event: e })),
        ],
        externalEvents: transformExternalEvents({ userId: this.props.user.id, events: resp.external.events }),
      });
    });
  }

  generateExternalEventSource = () => {
    return Object.keys(this.state.externalEvents)
    .filter(u => this.state.selectedExternalCalendars.includes(+u))
    .reduce((acc, u) => {
      return acc.concat(...this.state.externalEvents[u]);
    }, []);
  }

  handleClick = (el, event) => {
    if (['external', 'past', 'moratorium'].includes(event.extendedProps.type)) {
      return;
    }

    this.setState({
      popover: {
        event,
        el: el.id,
      },
    });
  }

  handleCalendarClick = e => {
    if (this.state.popover) {
      this.dismissPopover();
    }
  }

  handleExternalSelection = data => {
    this.setState({
      selectedExternalCalendars: data.selected
      ? [...this.state.selectedExternalCalendars, data.id]
      : [...this.state.selectedExternalCalendars.filter(u => u !== data.id)],
    });
  }

  handleSelect = info => {
    this.setState({
      events: this.state.events.concat({
        end: isSameDay(info.end, info.start) ? info.end : addSeconds(endOfDay(info.start), 1),
        id: CalendarBase.createId(),
        start: info.start,
        editable: true,
        title: 'Available',
        extendedProps: {
          isNew: true,
          canRemove: true,
          type: 'available',
        },
      }),
      popover: null,
    });

    this.calendar.unselect();
  }

  handleSend = () => {
    return this.props.onSubmit(this.getCalendarState());
  }

  getCalendarState = () => {
    return {
      new: getNewEvents(this.state.events),
      removed: getRemovedEvents(this.state.removedEvents),
    };
  }

  removeEventAvailability = () => {
    const event = this.state.popover.event;

    if (event.extendedProps.canRemove) {
      const availId = getEventAvailabilityId(event);

      if (availId) {
        const toRemove = this.state.events.filter(e => getEventAvailabilityId(e) == availId);

        this.setState({
          events: this.state.events.filter(e => !toRemove.map(m => m.id).includes(e.id)),
          removedEvents: this.state.removedEvents.concat(toRemove),
        });
      } else {
        this.setState({ events: this.state.events.filter(e => e.id != event.id) });
      }
    }
    this.setState({ popover: null });
  }

  renderCalendar = () => {
    this.calendar = new FullCalendar(document.getElementById(styles.calendar), {
      ...getProvidingDefaults(),
      customButtons: {
        save: {
          text: 'Send',
          click: this.handleSend,
        },
      },
      eventClick: info => this.handleClick(info.el, info.event),
      eventDrop: info => {
        this.setState({
          events: this.state.events.filter(e => e.id != info.event.id).concat(info.event),
          popover: null,
        });
      },
      eventOverlap: (still, moving) => {
        return still.extendedProps.type === 'external' &&
               moving.extendedProps.type === 'available';
      },
      eventSources:[
        { id: 'events', events: (_, cb) => cb(this.state.events) },
        { id: 'external', events: (_, cb) => cb(this.generateExternalEventSource()) },
        { id: 'past', events: (_, cb) => cb(CalendarBase.generateBackgroundEventSource()) },
        { id: 'moratorium', events: (_, cb) => cb(CalendarBase.generateMoratoriumEventSource({ minutes: this.props.user.settings.callSchedulingMoratorium })) },
      ],
      eventResize: info => {
        this.setState({
          events: this.state.events.filter(e => e.id != info.event.id).concat(info.event),
          popover: null,
        });
      },
      select: this.handleSelect,
      selectAllow: info => {
        if (isAfter(Date.now(), new Date(info.start))) {
          return false;
        }

        /* Treated as the event popover losing focus */
        if (this.state.popover) {
          return false;
        }

        return true;
      },
      windowResize: v => {
        this.calendar.changeView(getResponsiveCompatibleView());
      },
    });

    this.calendar.render();
    CalendarBase.scrollToNow();
    this.scrollable = CalendarBase.getScrollableElement();

    if (this.pastTimer) {
      clearInterval(this.pastTimer);
    }

    this.pastTimer = setInterval(() => {
      const pastEventSource = this.calendar.getEventSourceById('past');
      if (pastEventSource) {
        pastEventSource.refetch();
      }
      const moratoriumEventSource = this.calendar.getEventSourceById('moratorium');
      if (moratoriumEventSource) {
        moratoriumEventSource.refetch();
      }
    }, 60*1000);

    if (this.props.testMode) {
      const now = Date.now();
      const delta = 30 - (getMinutes(now) % 30); // match slotDuration
      const start = startOfMinute(now + (delta * 60 * 1000));

      this.handleSelect({
        start,
        end: addHours(start, 1),
      });
    }
  }

  renderPopover = () => {
    const showActions = this.state.popover &&
                        this.state.popover.event.extendedProps.type === 'available';
    return (
      <>
        {this.state.popover &&
          <EventPopover
            dismiss={this.dismissPopover}
            options={{ showActions }}
            popover={this.state.popover}
            remove={this.removeEventAvailability}
            scrollable={this.scrollable} />
        }
        {this.state.popover && <div
          className={styles.eventOverlay}
          onClick={this.dismissPopover} />}
      </>
    );
  }

  render() {
    const props = {
      calendar: this.props.calendar,
      className: this.props.className,
      events: this.state.events,
      gotoDate: date => {
        this.calendar?.gotoDate?.(date);
        const btn = getFullCalSubmitButtonElement();

        if (btn) {
          btn.disabled = !getAvailableEvents(this.state.events).length;
        }
      },
      id: styles.calendar,
      onChangeExternalCalendar: this.handleExternalSelection,
      onClickContainer: this.handleCalendarClick,
      onSubmit: this.handleSend,
      removedEvents: this.state.removedEvents,
    };

    return (
      <>
        {device.phone
          ? <ProvidingMainResponsive {...props} />
          : <ProvidingMain {...props} />}

        {this.renderPopover()}
        <MoratoriumHover
          selector={styles.moratorium}
          moratorium={this.props.user.settings.callSchedulingMoratorium} />
      </>
    );
  }
}

export { Providing };
export default Providing;
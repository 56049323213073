import { useCallback, useMemo } from 'react';
import * as api from '@api';
import { useCompleteFileUpload, useFileUploadsState } from './hooks';
import { FileUploadEffectsContext } from './Context';
import { Effects } from './interfaces';

type Props =
  ChildrenProps;

export const FileUploadEffectsContainer = (props: Props) => {

  const [_, dispatch] = useFileUploadsState();
  const onComplete = useCompleteFileUpload();

  const complete: Effects.OnCompleteUpload.Fn = useCallback(data => {
    return onComplete(data)
      .then(({ fileUpload }) => {
        dispatch({
          type: 'update-valid-upload',
          fileUpload,
        });
      });
  }, [
    dispatch,
    onComplete,
  ]);

  const abort: Effects.OnAbortUpload.Fn = useCallback(data => {
    return api.workspaces.fileUpload.abortFileUpload(data)
      .then(({ fileUpload }) => {
        dispatch({
          type: 'update-valid-upload',
          fileUpload,
        });
      });
  }, [
    dispatch,
  ]);

  const start: Effects.OnStartUpload.Fn = useCallback(data => {
    return api.workspaces.fileUpload.startFileUpload(data)
      .then(res => {
        dispatch({
          type: 'update-valid-upload',
          fileUpload: res.fileUpload,
        });
        return res;
      });
  }, [
    dispatch,
  ]);

  const failure: Effects.OnFailUpload.Fn = useCallback(data => {
    return api.workspaces.fileUpload.failFileUpload(data)
      .then(({ fileUpload }) => {
        dispatch({
          type: 'update-valid-upload',
          fileUpload,
        });
      });
  }, [
    dispatch,
  ]);

  const queue: Effects.OnQueueUpload.Fn = useCallback(data => {
    return api.workspaces.fileUpload.queueFileUpload({
      file: data.file,
      fileId: data.fileId,
      name: data.file.name,
      parentObjectId: data.parentObjectId,
      workspaceId: data.workspaceId,
    }).then(res => {
      dispatch({
        type: 'add-valid-upload',
        fileUpload: res.fileUpload,
      });
      return res;
    });
  }, [
    dispatch,
  ]);

  const value: Effects.FileUploadEffects = useMemo(() => ({
    abort,
    complete,
    failure,
    queue,
    start,
  }), [
    abort,
    complete,
    failure,
    queue,
    start,
  ]);

  return (
    <FileUploadEffectsContext.Provider value={value}>
      {props.children}
    </FileUploadEffectsContext.Provider>
  );
};

export default FileUploadEffectsContainer;
import {
  SurveyTemplateBuilder,
  SurveyBuilder,
} from '../interfaces';
import * as $actions from './state.template';

export function templateReducer(state: SurveyTemplateBuilder.State, action: SurveyBuilder.Actions): SurveyTemplateBuilder.State {

  switch (action.type) {

    case 'template-data-updated': {
      return {
        ...state,
        data: action.value,
      };
    }

    case 'template-updated': {
      return {
        ...state,
        type: action.value,
      };
    }

    case 'template-category-updated':
      return $actions.templateCategoryUpdated(state, action);

    case 'template-target-updated':
      return $actions.templateTargetUpdated(state, action);

    case 'template-competitors-updated':
      return $actions.templateCategoriesUpdated(state, action);

    case 'template-partnership-criteria-updated':
      return $actions.templatePartnershipCriteriaUpdated(state, action);

    case 'template-eval-criteria-updated':
      return $actions.templateEvaluationCriteriaUpdated(state, action);

    case 'template-target-roles-updated':
      return $actions.templateRolesUpdated(state, action);

    default:
      return state;
  }
}
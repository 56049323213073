import { useCallback } from 'react';
import { SurveyRichText } from '@/types/survey.rich-text';
import { parseRichQuestionText } from '../utils/rich-text';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useGetQuestionRawText = () => {

  const [state] = useSurveyBuilderState();

  return useCallback((value: SurveyRichText.RichTextValue) => {
    return parseRichQuestionText(value, state.survey);
  }, [state.survey]);
};
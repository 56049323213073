import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Nav from '$website/components/core/Navbar/Nav.Logo';
import { Navbar } from '$website/components/core/Navbar/Navbar';
import { SurveyMonitoringPasswordRequiredContext } from './Context';
import { SurveyMonitoringAuth } from './AuthScreen';
import { SurveyMonitoringDashboard } from './SurveyMonitoring';
import styles from './style/Container.css';

const selectAuthenticated = (state: Store.State) => state.user.state.authenticated;

export const SurveyMonitoringContainer = () => {
  const [authRequired, setAuthRequired] = useState<boolean>(null);
  const authenticated = useSelector(selectAuthenticated);

  return (
    <>
      {!authenticated &&
        <Navbar.LayoutRoot>
          <div className={styles.nav}>
            <Nav.Logo />
          </div>
        </Navbar.LayoutRoot>
      }
      <SurveyMonitoringPasswordRequiredContext.Provider value={[authRequired, setAuthRequired]}>
        {authRequired
          ? <SurveyMonitoringAuth />
          : <SurveyMonitoringDashboard />
        }
      </SurveyMonitoringPasswordRequiredContext.Provider>
    </>
  );
};

export default SurveyMonitoringContainer;
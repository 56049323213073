import { useCallback, useMemo } from 'react';
import { cx } from '@utils';
import * as Countries from './Countries';
import * as Field from './Field';
import * as utils from './utils';
import {
  FullAddressFormProps, FullFormAddressData, FullFormAddressDataFields,
  CountryFieldOnChange, TextFieldOnChange,
} from './interfaces';
import styles from './style.css';

type Props =
    FullAddressFormProps;

export default function FullAddressForm({ address, onChange, ...props }: Props) {
  const handleCountryChange: CountryFieldOnChange = useCallback(item => {
    const updated = {
      ...address,
      country: {
        ...address.country,
        value: item.id,
      },
    };
    onChange(updated);
  }, [address, onChange]);

  const handleInputChange: TextFieldOnChange = useCallback(event => {
    const name = event.target.name as keyof Omit<FullAddressFormProps['address'], 'country'>;
    const updated = {
      ...address,
      [name]: {
        ...address[name],
        value: event.target.value,
      },
    };
    onChange(updated);
  }, [address, onChange]);

  const { city, state, zip } =
    useMemo(() => getAddressFieldStates(address), [address]);

  return (
    <div>
      {!props.hideCountry &&
        <Field.Country
          className={cx(styles.row, props.fieldClassName)}
          labelClassName={props.labelClassName}
          error={address.country.error}
          selected={Countries.World.find(c => c.id === address.country?.value)}
          onChange={handleCountryChange}
          required />}
      <Field.Address1
        className={cx(styles.row, props.fieldClassName)}
        labelClassName={props.labelClassName}
        error={address.address1.error}
        onChange={handleInputChange}
        value={address.address1.value}
        required />
      <Field.Address2
        className={cx(styles.row, props.fieldClassName)}
        labelClassName={props.labelClassName}
        error={address.address2.error}
        value={address.address2.value}
        onChange={handleInputChange} />
      <Field.City
        className={cx(styles.row, props.fieldClassName)}
        labelClassName={props.labelClassName}
        error={address.city.error}
        placeholder={city.placeholder}
        label={city.label}
        value={address.city.value}
        show={city.show}
        required={city.required}
        onChange={handleInputChange} />
      <Field.State
        className={cx(styles.row, props.fieldClassName)}
        labelClassName={props.labelClassName}
        error={address.state.error}
        placeholder={state.placeholder}
        label={state.label}
        value={address.state.value}
        show={state.show}
        required={state.required}
        onChange={handleInputChange} />
      <Field.Zip
        className={cx(styles.row, props.fieldClassName)}
        labelClassName={props.labelClassName}
        error={address.zip.error}
        placeholder={zip.placeholder}
        label={zip.label}
        value={address.zip.value}
        required={address.zip.required}
        onChange={handleInputChange} />
    </div>
  );
}

function getAddressFieldStates(address: FullFormAddressData) {
  return {
    city: utils.getCityState(address.country.value),
    state: utils.getStateState(address.country.value),
    zip: utils.getZipState(address.country.value),
  };
}

export function resolveFullAddressSchema(address: FullFormAddressData) {
  const { city, state, zip } = getAddressFieldStates(address);

  return {
    ...address,
    city: {
      ...address.city,
      required: !!city.required,
      value: city.show ? address.city.value : '',
    },
    state: {
      ...address.state,
      required: !!state.required,
      value: state.show ? address.state.value : '',
    },
    zip: {
      ...address.zip,
      required: !!zip.required,
      value: zip.show ? address.zip.value : '',
    },
  };
}

function resetErrors(address: FullFormAddressData) {
  const fields: FullFormAddressDataFields[] = ['country', 'address1', 'address2', 'city', 'state', 'zip'];

  const resetAddress = fields.map(field => {
    const { error, ...resetField } = address[field];
    return { [field]: resetField };
  })
  .reduce<FullFormAddressData>((acc, curr) => ({ ...acc, ...curr }), {} as FullFormAddressData);

  return resetAddress;
}

export function validateFullAddress(address: FullFormAddressData) {
  const form = resetErrors(address);

  const fields: FullFormAddressDataFields[] = ['country', 'address1', 'address2', 'city', 'state', 'zip'];

  for (const field of fields) {
    if (!form[field] || (form[field].required && utils.isEmpty(form[field].value))) {
      return {
        error: 'Fill in required fields.',
        address: {
          ...form,
          [field]: {
            ...form[field],
            error: 'Field is required.',
          },
        },
      };
    }
  }

  return {
    error: null,
    address: form,
  };
}

export { FullAddressForm };
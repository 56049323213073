import { useCallback, useMemo } from 'react';
import { ActionOrdinalItem } from '@containers/SurveyBuilder.Logic/interfaces';
import DropDown from 'components/DropDown';

type DropdownItem = {
  id: number;
  identifier: string;
  ordinal: number;
};

type Props = {
  disabled?: boolean;
  items: ActionOrdinalItem[];
  onSelect: (identifier: string) => void;
  value: number;
};

export const QuestionOrdinalDropdown = ({
  items,
  onSelect,
  value,
}: Props) => {
  const getValueFromOrdinal = useCallback((ordinal: number) => {
    return `Q${ordinal}`;
  }, []);

  const getItemValue = useCallback((item: DropdownItem) => {
    return getValueFromOrdinal(item.id);
  }, [getValueFromOrdinal]);

  const handleSelect = useCallback((item: DropdownItem) => {
    onSelect(item.identifier);
  }, [onSelect]);

  const mappedItems: DropdownItem[] = useMemo(() => items.map(m => ({
    id: m.ordinal,
    identifier: m.identifier,
    ordinal: m.ordinal,
  })), [items]);

  return (
    <DropDown
      items={mappedItems}
      getItemValue={getItemValue}
      onSelect={handleSelect}
      text={getValueFromOrdinal(value)} />
  );
};

export default QuestionOrdinalDropdown;
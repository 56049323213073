import { useEffect } from 'react';
import * as api from '@api';
import { useAsyncStateLazy } from '@utils';
import {
  DownloadFileContainer,
} from '@containers/WorkspaceFiles';
import { FileProjectsContainer } from '@containers/TaggedProjects';
import { AskAnExpertContainer } from '@containers/AskAnExpert';
import { GroupFileTagsContainer } from '@containers/GroupTags';
import { useMatchFileStateFromUrl } from './hooks';
import FilePreviewSocketContainer from './SocketContainer';
import {
  WorkspaceFileStateContext,
  WorkspaceFilePreviewContext,
  WorkspaceFilePreviewReloadContext,
  WorkspaceFilePreviewLoadingContext,
} from './Context';

type Props =
  ChildrenProps;

export const WorkspaceFilePreviewContainer = (props: Props) => {

  const state = useMatchFileStateFromUrl();

  const [response, fetch] = useAsyncStateLazy(() => {
    if (!state.file) {
      return Promise.reject(new Error('File not found.'));
    }

    return api.workspaces.file.getFilePreview({
      fileId: state.file.id,
      workspaceId: state.object.workspaceId,
    });
  }, [
    state.file,
    state.object?.workspaceId,
  ]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <WorkspaceFilePreviewReloadContext.Provider value={fetch}>
      <WorkspaceFileStateContext.Provider value={state}>
        <WorkspaceFilePreviewContext.Provider value={response.value}>
          <WorkspaceFilePreviewLoadingContext.Provider value={response.loading || !response.value}>
            <GroupFileTagsContainer fileId={state.file.id}>
              <FileProjectsContainer fileId={state.file.id}>
                <DownloadFileContainer fileId={state.file.id}>
                  <FilePreviewSocketContainer fileId={state.file.id}>
                    <AskAnExpertContainer>
                      {props.children}
                    </AskAnExpertContainer>
                  </FilePreviewSocketContainer>
                </DownloadFileContainer>
              </FileProjectsContainer>
            </GroupFileTagsContainer>
          </WorkspaceFilePreviewLoadingContext.Provider>
        </WorkspaceFilePreviewContext.Provider>
      </WorkspaceFileStateContext.Provider>
    </WorkspaceFilePreviewReloadContext.Provider>
  );
};

export default WorkspaceFilePreviewContainer;

import http from '@services/http';
import * as xform from '@transformers';
import { GroupSignatureRequest, UnparsedPipeline } from '@/types';
import {
  Pipeline,
  Projects,
} from './interfaces';

export const addToProject = (data: Pipeline.AddToProjectRequest): Promise<Pipeline.AddToProjectResponse> => {
  return http.post(`/projects/${data.projectId}/pipeline`, data);
};

const completeOnboarding = (data: Pipeline.CompleteSchedulingOnboardingRequest | Pipeline.CompleteSurveyOnboardingRequest) => {
  return http.patch<Pipeline.CompleteOnboardingResponse>(`/projects/${data.projectId}/pipeline/invites`, data)
  .then(res => ({
    ...res,
    pipeline: xform.normalizePipeline(res.pipeline),
  }));
};

export const deactivateUser = (data: Pipeline.DeactivateUserRequest): Promise<Pipeline.DeactivateUserResponse> => {
  return http.patch(`/projects/${data.projectId}/pipeline`, data);
};

export const declineRequest = (data: Projects.ProjectRequestRejectRequest) => {
  return http.put<Projects.ProjectRequestRejectResponse>(`/projects/${data.projectId}/pipeline/invites`, data)
  .then(res => ({
    pipeline: xform.normalizePipeline(res.pipeline),
  }));
};

export const fetchRecord = (data: Pipeline.FetchRecordRequest): Promise<Pipeline.FetchRecordResponse> => {
  return http.get(`/projects/${data.projectId}/pipeline/users/${data.userId}`);
};

export const invite = (data: Pipeline.InviteRequest): Promise<Pipeline.InviteResponse> => {
  return http.post(`/projects/${data.projectId}/pipeline/invites`, data);
};

export const rehydrate = (data: Pipeline.Rehydrate.Request) => {
  return http.get(`/projects/${data.projectId}/pipeline/users/${data.userId}/hydrate`)
    .then((res: Pipeline.Rehydrate.Response) => ({
      documents: {
        group: xform.toMap<number, GroupSignatureRequest>(res.documents.group, 'groupId'),
      },
      record: res.record,
      sourcing: res.sourcing,
    }));
};

export const removeUser = (data: Pipeline.RemoveUserRequest): Promise<Pipeline.RemoveUserResponse> => {
  return http.put(`/projects/${data.projectId}/pipeline`, data);
};

export const getSurveyPayment = (data: Pipeline.GetSurveyPayment.Request) => {
  return http.get<Pipeline.GetSurveyPayment.Response>(`/projects/${data.projectId}/pipeline/survey-payment`);
};

export const getProjectCallRate = (data: Pipeline.GetProjectCallRate.Request) => {
  return http.get<Pipeline.GetProjectCallRate.Response>(`/projects/${data.projectId}/pipeline/call-rate`);
};

export const fetchConsultantReferralSourcingState = (data: Pipeline.FetchConsultantReferralSourcingState.Request) => {
  return http.get<Pipeline.FetchConsultantReferralSourcingState.Response>(`/projects/${data.projectId}/pipeline/users/${data.userId}/sourcing`);
};

export const onboarding = {
  complete: completeOnboarding,
};
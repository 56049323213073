import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelectFolder, useSelectObject, useFolderCapabilities } from '@containers/Store';
import * as consts from '@consts';
import { selectFolderChildren } from '@store/selectors';
import { useObjectAccessModal } from 'components/ObjectAccess/hooks/useObjectAccessModal';
import { useDeleteFolderPrompt } from 'components/WorkspaceFolders/hooks/useDeleteFolderPrompt';
import { useNewFolderModal } from 'components/WorkspaceFolders/hooks/useNewFolderModal';
import { useRenameFolderModal } from 'components/WorkspaceFolders/hooks/useRenameFolderModal';
import { useObjectOwnershipModal } from 'components/WorkspaceObject/hooks/useObjectOwnershipModal';
import { RenameFolderContainer } from './RenameFolderContainer';
import { DeleteFolderContainer } from './DeleteFolderContainer';
import { FolderActionsContext, FolderMenuItemsContext } from './Context';

type Props = {
  children: React.ReactNode;
} & IWorkspaceFolderId;

export const FolderMenuContainer = ({ children, folderId }: Props) => {

  const folder = useSelectFolder(folderId);
  const object = useSelectObject(folder.objectId);
  const capabilities = useFolderCapabilities(folderId);
  const childObjects = useSelector(selectFolderChildren(folderId));

  const history = useHistory();

  const [toggleNewFolderModal, NewFolderModal] = useNewFolderModal({
    location: folder.name,
    parentObject: object,
  });
  const [toggleObjectAccessModal, ObjectAccessModal] = useObjectAccessModal();
  const [toggleOwnershipModal, ObjectOwnershipModal] = useObjectOwnershipModal();
  const [toggleDeleteFolderPrompt, DeleteFolderPrompt] = useDeleteFolderPrompt();
  const [toggleRenameModal, RenameFolderModal] = useRenameFolderModal();

  const createProject = useCallback(() => {
    history.push(`${consts.path.Projects.New}?parentObjectId=${object.id}`);
  }, [
    history,
    object.id,
  ]);

  const actions = useMemo(() => ({
    changeOwner: toggleOwnershipModal,
    createFolder: toggleNewFolderModal,
    createProject,
    delete: toggleDeleteFolderPrompt,
    manageAccess: toggleObjectAccessModal,
    rename: toggleRenameModal,
  }), [
    createProject,
    toggleDeleteFolderPrompt,
    toggleNewFolderModal,
    toggleObjectAccessModal,
    toggleRenameModal,
    toggleOwnershipModal,
  ]);

  const menu = useMemo(() => ({
    canAddChildren: capabilities.canAddChildren,
    canDelete: !childObjects.length && capabilities.canDelete,
    canManageAccess: capabilities.canManageAccess,
    canRename: capabilities.canRename,
  }), [
    capabilities,
    childObjects.length,
  ]);

  return (
    <FolderMenuItemsContext.Provider value={menu}>
      <FolderActionsContext.Provider value={actions}>
        {children}
        {menu.canAddChildren &&
          <NewFolderModal />}
        {menu.canManageAccess &&
          <ObjectAccessModal />}
        {menu.canDelete &&
          <DeleteFolderContainer folderId={folder.id}>
            <DeleteFolderPrompt />
          </DeleteFolderContainer>}
        {capabilities.canRename &&
          <RenameFolderContainer folderId={folder.id}>
            <RenameFolderModal />
          </RenameFolderContainer>}
      </FolderActionsContext.Provider>
    </FolderMenuItemsContext.Provider>
  );
};

export default FolderMenuContainer;
import { useCallback } from 'react';
import { useHasPaymentMethod } from '@containers/Payment';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import Header from 'components/Modal/Header';
import { Textarea } from 'components/Textarea';
import {
  useAdHocRequestNote,
  useAdHocRequestRate,
  useAdHocRequestRecipient,
  useAdHocRequestStepper,
  useAdHocRequestSubject,
} from './Context';
import styles from './style/AdHoc.css';

export const AdHocCallRequestDetails = () => {
  const [note, setNote] = useAdHocRequestNote();
  const actions = useAdHocRequestStepper();
  const recipient = useAdHocRequestRecipient();
  const rate = useAdHocRequestRate();
  const [subject, setSubject] = useAdHocRequestSubject();

  const hasPaymentMethod = useHasPaymentMethod();

  const handleNoteChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  }, [setNote]);

  const handleSubjectChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  }, [setSubject]);

  const handleSubmit = useCallback(() => {
    if (hasPaymentMethod) {
      actions.goTo(2); // Confirmation Screen
    } else {
      actions.next();
    }
  }, [actions, hasPaymentMethod]);

  const title = `Request a call with ${recipient.firstName}`;

  const nextDisabled = !subject;

  return (
    <>
      <Header
        className={styles.header}
        text={title} />

      <div className={styles.section}>
        <div className={styles.label}>Length</div>
        <div>60 Minutes</div>
      </div>

      <div className={styles.section}>
        <div className={styles.label}>Price</div>
        <div>${rate}</div>
      </div>

      <div className={styles.section}>
        <div className={styles.label}>Subject</div>
        <Input
          onChange={handleSubjectChange}
          value={subject} />
      </div>

      <div className={styles.section}>
        <div className={styles.label}>Send a note</div>
        <Textarea
          placeholder={`Write a message to ${recipient.firstName} about the topic of the call.`}
          onChange={handleNoteChange}
          value={note} />
      </div>

      <div className={styles.footer}>
        <Button
          className={styles.btn}
          disabled={nextDisabled}
          variant="brick"
          onClick={handleSubmit}>
          Next
        </Button>
      </div>

    </>
  );
};

export default AdHocCallRequestDetails;
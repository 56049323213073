import { SurveyQuestionType } from '@enums/Survey';
import {
  SurveyQuestion,
  ConjointAnalysisQuestion,
  MaxDifferenceQuestion,
  MultipleChoiceQuestion,
  MultiselectQuestion,
  MatrixGridQuestion,
  MatrixMultiselectQuestion,
  MatrixSliderQuestion,
  ShortTextResponseQuestion,
  LongTextResponseQuestion,
  TextResponseQuestion,
  RankingQuestion,
  NumberInputTableQuestion,
} from '@/types/survey';

export const isMultipleChoice = (data: SurveyQuestion): data is MultipleChoiceQuestion.Question => {
  return data.typeId === SurveyQuestionType.MultipleChoice;
};

export const isLongTextResponse = (data: SurveyQuestion): data is LongTextResponseQuestion.Question => {
  return data.typeId === SurveyQuestionType.LongTextResponse;
};

export const isShortTextResponse = (data: SurveyQuestion): data is ShortTextResponseQuestion.Question => {
  return data.typeId === SurveyQuestionType.ShortTextResponse;
};

export const isTextResponse = (data: SurveyQuestion): data is TextResponseQuestion.Question => {
  return isShortTextResponse(data) || isLongTextResponse(data);
};

export const isMultiSelect = (data: SurveyQuestion): data is MultiselectQuestion.Question => {
  return data.typeId === SurveyQuestionType.Multiselect;
};

export const isRanking = (data: SurveyQuestion): data is RankingQuestion.Question => {
  return data.typeId === SurveyQuestionType.Ranking;
};

export const isMatrixGridQuestion = (data: SurveyQuestion): data is MatrixGridQuestion.Question => {
  return data.typeId === SurveyQuestionType.MatrixGrid;
};

export const isMatrixSliderQuestion = (data: SurveyQuestion): data is MatrixSliderQuestion.Question => {
  return data.typeId === SurveyQuestionType.Sliders;
};

export const isMaxDifferenceQuestion = (data: SurveyQuestion): data is MaxDifferenceQuestion.Question => {
  return data.typeId === SurveyQuestionType.MaxDifference;
};

export const isConjointAnalysisQuestion = (data: SurveyQuestion): data is ConjointAnalysisQuestion.Question => {
  return data.typeId === SurveyQuestionType.ConjointAnalysis;
};

export const isSliderReversed = (data: Pick<MatrixSliderQuestion.Question, 'settings'>) => {
  return data.settings.slider.minValue > data.settings.slider.maxValue;
};

export type OptionsQuestion =
  MatrixGridQuestion.Question |
  MatrixMultiselectQuestion.Question |
  MaxDifferenceQuestion.Question |
  RankingQuestion.Question |
  MultipleChoiceQuestion.Question |
  MultiselectQuestion.Question |
  NumberInputTableQuestion.Question
;

export function hasOptions(data: SurveyQuestion): data is OptionsQuestion {
  return [
    SurveyQuestionType.MatrixGrid,
    SurveyQuestionType.MatrixMultiselect,
    SurveyQuestionType.MaxDifference,
    SurveyQuestionType.Ranking,
    SurveyQuestionType.MultipleChoice,
    SurveyQuestionType.Multiselect,
    SurveyQuestionType.NumberInputTable,
  ].includes(data.typeId);
}

export type RowsQuestion =
  MatrixGridQuestion.Question |
  MatrixMultiselectQuestion.Question |
  MatrixSliderQuestion.Question |
  NumberInputTableQuestion.Question
;

export function hasRows(data: SurveyQuestion): data is RowsQuestion {
  return [
    SurveyQuestionType.MatrixGrid,
    SurveyQuestionType.MatrixMultiselect,
    SurveyQuestionType.Sliders,
    SurveyQuestionType.NumberInputTable,
  ].includes(data.typeId);
}

export type ResponseLogicQuestion =
  MatrixGridQuestion.Question |
  MatrixMultiselectQuestion.Question |
  MultipleChoiceQuestion.Question |
  MultiselectQuestion.Question
;

export function hasResponseLogic(data: SurveyQuestion): data is ResponseLogicQuestion {
  return [
    SurveyQuestionType.MatrixGrid,
    SurveyQuestionType.MatrixMultiselect,
    SurveyQuestionType.MultipleChoice,
    SurveyQuestionType.Multiselect,
  ].includes(data.typeId);
}
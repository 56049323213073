import { Component } from 'react';
import * as api from '@api';
import * as enums from '@enums';
import Tag from 'components/Tag';
import Tags from '../Tags';
import styles from './style.css';

class Sectors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectors: [],
      showWarning: false,
    };
  }

  componentDidMount() {
    this.fetchSectors();
  }

  fetchSectors = () => {
    api.search.fetchSectorFields()
    .then(data => {
      this.setState({
        sectors: data.items.map(m => ({
          ...m,
          typeId: enums.VICSType.Sector,
        })),
      });
    });
  }

  onSelection = item => {
    if (this.props.selectedItems.length >= enums.VICSType.getMaxSelections(enums.VICSType.Sector)) {
      this.setState({
        showWarning: true,
      });
    } else {
      this.props.onSelection(item);
    }
  }

  onRemoval = item => () => {
    this.props.onSelection(item);
  }

  renderSelectedTags = () => {
    return (
      <div className={styles.tags}>
        {this.props.selectedItems.map(x =>
          <Tag
            key={x.id}
            item={{
              id: x.id,
              name: x.name,
              type: enums.SearchType.Sector,
            }}
            editable={true}
            onClick={this.onRemoval(x)} />
        )}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.vics}>
        <div className={styles.vicsContainerTop}>
          <Tags
            isDeletable={false}
            items={this.state.sectors}
            onClick={this.onSelection}
            vics={this.props.vics} />
        </div>
        <div className={styles.selected}>
          <div className={styles.vicsSubtext}>Selected Sectors</div>
          {<div className={styles.warning}>{this.state.showWarning ? `You may only select up to ${enums.VICSType.getMaxSelections(enums.VICSType.Sector)}` : ''}</div>}
        </div>
        <div className={styles.vicsContainerBottom}>
          {this.renderSelectedTags()}
        </div>
      </div>
    );
  }
}

export default Sectors;
import { Check } from 'react-feather';
import { cx } from '@utils';
import { usePricingContext } from '$website/containers/Pricing/hooks';
import * as Pricing from '$website/containers/Pricing/interfaces';
import { Switch } from 'components/Switch';
import { PlanButton } from './Plan.Button';
import styles from './style/Pricing.Compact.css';

type Props = unknown;

export const PricingCompact = (props: Props) => {
  const ctx = usePricingContext();

  return (
    <div className={styles.root}>
      <div className={styles.controls}>
        <div className={styles.payment}>Payment</div>
        <div className={styles.toggle}>
          <Switch
            active={!ctx.monthly}
            onClick={() => ctx.setMonthly(!ctx.monthly)} />
          <span className={styles.label}>Annual</span>
        </div>
      </div>
      {ctx.compact.items.map(x =>
        <CompactPlan
          features={x.features}
          id={x.id}
          key={x.id}
          tiered={x.tiered}
          usage={x.usage} />)}
    </div>
  );
};

PricingCompact.displayName = 'Pricing.Compact';

const CompactPlan = (props: Pricing.CompactServices) => {
  const ctx = usePricingContext();

  const plan = ctx.plans[props.id];

  const payment = ctx.monthly
    ? plan.payment.monthly
    : plan.payment.yearly;

  const classes = {
    title: cx(styles.title, styles.row),
    text: cx(styles.text, styles.row),
    price: cx(styles.price, styles.row),
    rate: cx(styles.rate, styles.row),
  };

  const bg = {
    [Pricing.ColumnIdentifer.Customer]: '#f9fbff',
    [Pricing.ColumnIdentifer.Market]: '#f3f8ff',
    [Pricing.ColumnIdentifer.Enterprise]: '#f9fbff',
  };

  return (
    <div className={styles.wrap}>

      <div className={styles.header}>
        <div className={styles.name}>{plan.name}</div>
        <div className={styles.description}>{plan.description}</div>
      </div>

      <div
        className={styles.info}
        style={{ backgroundColor: bg[props.id] }}>

        <div className={styles.group}>
          <div className={classes.price}>${payment}</div>
          <div className={classes.rate}>{`per user / month`}</div>
        </div>

        <div className={styles.group}>
          <div className={classes.title}>Features</div>
          {props.tiered.map(x =>
            <div
              className={classes.text}
              key={x.id}>
              {x.name}
            </div>)}
          {props.features.map(x =>
            <div
              className={cx(styles.feature, styles.row)}
              key={x.id}>
              <Check className={styles.check} />
              <span className={styles.text}>{x.name}</span>
            </div>)}
        </div>
        <PlanButton id={props.id} />
      </div>

    </div>
  );
};

CompactPlan.displayName = 'Pricing.Compat.Plan';
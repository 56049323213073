import mixpanel from 'mixpanel-browser';
import { Search } from './interfaces';

export function profileClick(data: Search.ProfileClick) {
  mixpanel.track('search:profile-click', data);
}

export function projectProfileClick(data: Search.ProjectProfileClick) {
  mixpanel.track('search-project:profile-click', data);
}

export function emptyResults(data: Search.EmptyResults) {
  mixpanel.track('search:empty', data);
}

export function projectEmptyResults(data: Search.ProjectEmptyResults) {
  mixpanel.track('search-project:empty', data);
}

export function results(data: Search.Results) {
  mixpanel.track('search:results', data);
}

export function projectResults(data: Search.ProjectResults) {
  mixpanel.track('search-project:results', data);
}

const project = {
  profileClick: projectProfileClick,
  emptyResults: projectEmptyResults,
  results: projectResults,
};

export {
  project,
};
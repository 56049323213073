import { useCallback } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as consts from '@consts';
import SharedWorkspaceMain from './SharedMain';
import WorkspaceFolderMain from './FolderMain';
import WorkspaceMain from './WorkspaceMain';
import styles from './style/Workspaces.css';

export const Workspaces = () => {

  const renderRoutes = useCallback(() => {
    const defaultRoute = `${consts.path.Workspaces.Root}/personal`;

    return (
      <Switch>
        <Route
          exact
          path={[
            consts.path.Workspaces.FolderTabs,
            consts.path.Workspaces.Folder,
          ]}
          component={WorkspaceFolderMain} />
        <Route
          exact
          path={[
            consts.path.Workspaces.SharedTabs,
            consts.path.Workspaces.Shared,
          ]}
          component={SharedWorkspaceMain} />
        <Route
          exact
          path={[
            consts.path.Workspaces.WorkspaceTabs,
            consts.path.Workspaces.Workspace,
          ]}
          component={WorkspaceMain} />
        <Redirect to={defaultRoute} />
      </Switch>
    );
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        {renderRoutes()}
      </div>
    </div>
  );
};

export default Workspaces;
import { useSelectObject } from '@containers/Store';
import { useWorkspaceState } from '@containers/Workspace/hooks/useWorkspaceState';
import { useFoldersTableItems } from 'components/WorkspaceFolders/hooks/useFoldersTableItems';
import { useNewFolderModal } from 'components/WorkspaceFolders/hooks/useNewFolderModal';
import FoldersTable from './FoldersTable';

export const WorkspaceTabFolders = () => {
  const { workspace } = useWorkspaceState();
  const object = useSelectObject(workspace?.objectId);
  const [toggleNewFolderModal, NewFolderModal] = useNewFolderModal({
    location: workspace.name,
    parentObject: object,
  });

  const data = useFoldersTableItems({
    parentObjectId: workspace.objectId,
  });

  return (
    <>
      <FoldersTable
        canCreateNew={true}
        data={data}
        onCreateNew={toggleNewFolderModal} />
      <NewFolderModal />
    </>
  );
};

export default WorkspaceTabFolders;
import { useCallback, useEffect, useMemo } from 'react';
import { useChainedCommands, useRemirrorContext } from '@remirror/react';
import { NodeType } from '@/types/transcribe.rich-text';
import { useTranscriptMediaPlayer } from '@containers/Transcript';
import { TranscriptExtensions } from '../interfaces';
import { Position } from '../interfaces.extension.karaoke';
import { KaraokeExtension } from '../Extension';

export const useMediaTimeChangeEvent = () => {
  const player = useTranscriptMediaPlayer();
  const { view } = useRemirrorContext<TranscriptExtensions>();
  const commands = useChainedCommands<KaraokeExtension>();

  const timestampPositions = useMemo(() => {

    const timeMap = new Map<number, Position>();

    view.state.doc.descendants((node, pos) => {

      if (node.type.name === NodeType.Text) {
        const tsMark = node.marks.find(f => f.type.name === 'ts');
        if (tsMark) {
          timeMap.set(tsMark.attrs.s as number, {
            from: pos,
            to: pos + node.text.length,
          });
        }
      }
    });

    return timeMap;
  }, [view]);

  const handleTimeUpdate = useCallback((e: Event) => {

    const currentTime = (e.target as HTMLAudioElement).currentTime;

    let closestTs: number = null;
    const timestamps = Array.from(timestampPositions.keys());
    for (let i = timestamps.length - 1; i >= 0; i--) {
      const ts = timestamps[i];

      if (ts <= currentTime) {
        closestTs = ts;
        break;
      }
    }

    if (closestTs) {

      const pos = timestampPositions.get(closestTs);

      commands.setKaraokePosition(pos).run();

    } else {
      commands.setKaraokePosition(null).run();
    }

  }, [
    commands,
    timestampPositions,
  ]);

  useEffect(() => {

    if (player) {

      player.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
        player.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [
    handleTimeUpdate,
    player,
  ]);

};
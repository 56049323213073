import { path } from '@consts';
import { useAccountProfilePopulateFields } from '@containers';
import { Skip } from '@presentation';
import { cx } from '@utils';
import { PrepopulatingProfile } from '@screens/AccountProfileSetup/PrepopulatingProfile';
import { ForwardNavigationProps } from '@screens/AccountProfileSetup/interfaces';
import { Button } from 'components/Button';
import styles from './style/AccountProfileEnrichmentCheck.css';

type Props = {
  navigation: ForwardNavigationProps;
};

const SetupPrepopulatePrompt = (props: Props) => {
  const [response, populate] = useAccountProfilePopulateFields(props.navigation.onClick);

  if (response.loading) {
    return <PrepopulatingProfile />;
  }

  return (
    <div className={styles.root}>
      <div className={cx(styles.wrap, styles.sync)}>
        <div className={styles.main}>
          <div className={styles.header}>{title}</div>
          <div className={styles.body}>
            <div className={styles.text}>{msg1}</div>
            <div className={styles.text}>{msg2}</div>
            <div className={styles.text}>{msg3}</div>
          </div>
          <div className={cx(styles.footer, styles.column)}>
            <div className={styles.proceed}>
              <Button
                className={styles.btn}
                disabled={response.loading}
                onClick={populate}
                variant="brick">
                Sync LinkedIn Profile
              </Button>
            </div>
            <div className={styles.text}>{msg4}</div>
            <div className={styles.proceed}>
              <Button
                className={styles.link}
                color="transparent"
                onClick={props.navigation.onClick}
                replace={props.navigation.replace}
                to={props.navigation.to}
                variant="link">
                Create Profile
              </Button>
            </div>
            <div className={styles.proceed}>
              <Skip
                className={styles.skip}
                to={path.Projects.Root} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const title = `Want to participate in more paid surveys? Set up a profile to get started.`;

const msg1 = `By creating a profile, you will become eligible for more paid projects on Vancery!`;
const msg2 = `Give us permission to pull your employment history from LinkedIn and we can fast track the profile setup process.`;
const msg3 = `Once your profile is complete, we’ll begin recommending you for paid projects on the topics you are best fit to discuss.`;
const msg4 = `Or if you don’t have a LinkedIn, you can manually create your profile here:`;

export { SetupPrepopulatePrompt };
export default SetupPrepopulatePrompt;
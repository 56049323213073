import * as consts from '@consts';
import playStoreBadge from 'static/icons/play-store.svg?url';

type Props = {
  className?: string;
  imgClassName?: string;
};

export const PlayStore = (props: Props) => (
  <a
    className={props.className}
    href={consts.urls.PlayStoreUrl}
    target="_blank"
    rel="noopener noreferrer">
    <img
      alt='Google Play Store link to download Vancery'
      className={props.imgClassName}
      src={playStoreBadge} />
  </a>
);
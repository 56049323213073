import * as enums from '@enums';
import SearchAutocompleteWithTags from 'components/SearchAutocomplete/tags';
import { SectorDropDown } from 'components/VICS/SectorDropDown';
import VICSAutocomplete from 'scenes/vics-selection/VICSAutocomplete';
import Tag from 'components/Tag';
import styles from './style.css';

const renderTags = ({
  items,
  type,
  handleClick = () => {},
}) => {
  return (
    <div className={styles.tags}>
      {items.map(x =>
        <Tag
          key={x.id}
          item={{
            id: x.id,
            name: x.name,
            type,
          }}
          editable={true}
          onClick={handleClick(x)} />
      )}
    </div>
  );
};

const ProjectFiltersEditable = ({
  headerTextClass,
  item,
  removeItem,
  addItem,
  onKeywordSelected,
  onKeywordRemoved,
  showVICS = true,
}) => {
  const renderVICS = typeId => {
    const items = item.vics
    .filter(f => f.typeId === typeId)
    .map(m => ({
      id: m.id,
      name: m.name,
    }));

    const parentTypeIdLookup = {
      [enums.VICSType.Industry]: enums.VICSType.Sector,
      [enums.VICSType.SubIndustry]: enums.VICSType.Industry,
    };

    return (
      <div className={styles.vics}>
        <div className={styles.filtersRow}>
          <div className={headerTextClass}>{enums.VICSType.getName(typeId)}</div>
          <div className={styles.dropdown}>
            {typeId === enums.VICSType.Sector ?
              <SectorDropDown
                onSelection={addItem('vics')}
                selectedItems={items} /> :
              <VICSAutocomplete
                autocompleteClass={styles.autocomplete}
                onSelection={addItem('vics')}
                parentIds={item.vics.filter(f => f.typeId === parentTypeIdLookup[typeId]).map(m => m.id)}
                selectedItems={items}
                vicsType={typeId} />
            }
          </div>
          {renderTags({
            items,
            type: enums.VICSType.getSearchType(typeId),
            editable: true,
            handleClick: removeItem('vics'),
          })}
        </div>
      </div>
    );
  };

  const handleKeywordSelected = keyword => {
    onKeywordSelected(keyword.name);
  };

  const handleKeywordRemoved = keyword => () => {
    onKeywordRemoved(keyword.name)();
  };

  return (
    <>
      <div className={styles.filtersRow}>
        <div className={headerTextClass}>Current Company</div>
        <SearchAutocompleteWithTags
          autocompleteClass={styles.autocomplete}
          searchType={enums.SearchType.Company}
          placeholder='Enter Current Companies'
          selectedItems={item.currentCompanies}
          onSelection={addItem('currentCompanies')}
          removeItem={removeItem('currentCompanies')} />
      </div>
      <div className={styles.filtersRow}>
        <div className={headerTextClass}>Former Company</div>
        <SearchAutocompleteWithTags
          autocompleteClass={styles.autocomplete}
          searchType={enums.SearchType.Company}
          filterType='formerCompany'
          placeholder='Enter Former Companies'
          selectedItems={item.formerCompanies}
          onSelection={addItem('formerCompanies')}
          removeItem={removeItem('formerCompanies')} />
      </div>
      <div className={styles.filtersRow}>
        <div className={headerTextClass}>Job Title</div>
        <SearchAutocompleteWithTags
          autocompleteClass={styles.autocomplete}
          searchType={enums.SearchType.Title}
          minCharactersForFetch={2}
          placeholder='Enter Job Titles'
          selectedItems={item.titles}
          onSelection={addItem('titles')}
          removeItem={removeItem('titles')} />
      </div>
      <div className={styles.filtersRow}>
        <div className={headerTextClass}>Keywords</div>
        <SearchAutocompleteWithTags
          autocompleteClass={styles.autocomplete}
          searchType={enums.SearchType.Keyword}
          placeholder='Enter Keywords'
          selectedItems={item.keywords.map(m => ({ id: m, name: m }))}
          onSelection={handleKeywordSelected}
          removeItem={handleKeywordRemoved}
          displaySearchItems={false} />
      </div>
      {!showVICS ||
        <>
          {renderVICS(enums.VICSType.Sector)}
          {renderVICS(enums.VICSType.Industry)}
          {renderVICS(enums.VICSType.SubIndustry)}
        </>
      }
    </>
  );
};

export default ProjectFiltersEditable;

import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { AxiosError } from 'axios';
import * as api from '@api';
import * as path from '@consts/path';
import * as QKey from '@consts/querykey';
import { useIsProjectTypeCallLike, useSelectProject } from '@containers/Store';
import { SurveyThemingContext } from './Context';
import { transformPalette } from './utils';

type Props = {
  children: React.ReactNode;
};

export const SurveyTheming = (props: Props) => {
  const [projectId, setProjectId] = useState<number>(null);
  const match = useRouteMatch<{ projectId: string; }>({
    exact: true,
    path: [
      path.Onboarding.Projects.Closed,
      path.Onboarding.Projects.Completion,
      path.Onboarding.Projects.End,
      path.Onboarding.Projects.Exit,
      path.Onboarding.Projects.Project,
    ],
  });

  const project = useSelectProject(projectId);
  const isCallLike = useIsProjectTypeCallLike(projectId);

  const param = useMemo(() => {
    return match?.params?.projectId
      ? + match.params.projectId
      : null;
  }, [
    match?.params?.projectId,
  ]);

  useEffect(() => {

    if (param !== projectId) {
      setProjectId(param);
    }

  }, [
    param,
    projectId,
  ]);

  const query = useQuery([QKey.Groups.Branding.Survey.Theming.Get, project?.groupId], () => {
    return api.groups.branding.surveys.fetchSurveyTheming({
      groupId: project.groupId,
    })
    .then(res => {
      return {
        logos: res.logos,
        page: res.page,
        palette: transformPalette(res.palette),
      };
    });
  }, {
    enabled: !!project?.groupId && !isCallLike,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: (count, e: AxiosError['response']) => count < 3 && e.status !== 404,
  });

  const theming =  useMemo(() => {
    return {
      data: match ? query.data : null,
      loading: query.isLoading,
    };
  }, [
    match,
    query,
  ]);

  const context = {
    query,
    theming,
  };

  return (
    <SurveyThemingContext.Provider value={context}>
      {props.children}
    </SurveyThemingContext.Provider>
  );
};

SurveyTheming.displayName = 'Branding.SurveyTheming';
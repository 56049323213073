import { memo } from 'react';
import { Redirect, generatePath } from 'react-router-dom';
import { path, pathname } from '@consts';
import { ConferenceType } from '@enums';
import { ChatContainer, LiveRoomContainer, useConferenceParam, useConferenceInstance, useTwilioVideo, useTwilioVoice, IConference } from '@containers/Conference';
import { ConferenceRoomVideo } from '@screens/Conference.Room.Video';
import { ConferenceRoomVoice } from '@screens/Conference.Room.Voice';

export function ConferenceRoom() {
  const conferenceIdentifier = useConferenceParam();
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom | IConference.Coordinator.Conference.Left>();

  if (instance?.status === 'left') {
    if (instance.redirect === 'location') {
      return <Redirect to={instance.location} />;
    }
    return <Redirect to={generatePath(`${path.Conference.Completed}?title=${instance.title}&reason=${instance.redirect}`, { conferenceIdentifier: instance.conferenceIdentifier })} />;
  }

  if (instance?.status !== 'meeting-room') {
    const to = conferenceIdentifier
      ? generatePath(path.Conference.Entry, { conferenceIdentifier })
      : pathname.Home;
    return <Redirect to={to} />;
  }

  switch (instance.conference.type) {
    case ConferenceType.Video: return <RoomVideoGuard />;
    case ConferenceType.Voice: return <RoomVoiceGuard />;
    default: return <Redirect to={pathname.Home} />;
  }
}

function RoomVideoGuard() {
  const conferenceIdentifier = useConferenceParam();
  const twilio = useTwilioVideo();

  if (!twilio?.room) {
    const to = conferenceIdentifier
      ? generatePath(path.Conference.Entry, { conferenceIdentifier })
      : pathname.Home;
    return <Redirect to={to} />;
  }

  return <RoomVideo />;
}

const RoomVideo = memo(() => {
  return (
    <ChatContainer>
      <LiveRoomContainer>
        <ConferenceRoomVideo />
      </LiveRoomContainer>
    </ChatContainer>
  );
});

function RoomVoiceGuard() {
  const conferenceIdentifier = useConferenceParam();
  const twilio = useTwilioVoice();

  if (!twilio?.call) {
    const to = conferenceIdentifier
      ? generatePath(path.Conference.Entry, { conferenceIdentifier })
      : pathname.Home;
    return <Redirect to={to} />;
  }

  return <RoomVoice />;
}

const RoomVoice = memo(() => {
  return (
    <ChatContainer>
      <LiveRoomContainer>
        <ConferenceRoomVoice />
      </LiveRoomContainer>
    </ChatContainer>
  );
});
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProjectType } from '@enums';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks/useProjectState';
import * as selectors from '@store/selectors';

const cutoff = 50;

export const useIsMassProject = () => {
  const project = useProjectOrLatestChild();
  const usersCount = useSelector(selectors.pipeline.client.count(project.id));

  const isMassProject = useMemo(
    () => usersCount >= cutoff && [ProjectType.Survey].includes(project.projectType),
    [project.projectType, usersCount]
  );

  return isMassProject;
};

export default useIsMassProject;
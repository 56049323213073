import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as api from '@api';
import * as actions from '@store/actions';
import Toast from 'components/Toast';
import { DeleteFileParams } from '../interfaces';

export const useDeleteFile = () => {
  const dispatch = useDispatch();

  const deleteFile = useCallback((data: DeleteFileParams) => {
    return api.workspaces.file.deleteFile({
      fileId: data.fileId,
      workspaceId: data.workspaceId,
    }).then(_ => {
      dispatch(actions.fileDeleted({
        fileId: data.fileId,
        objectId: data.objectId,
      }));
      Toast.alert({
        title: 'File Deleted',
      });
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });

  }, [dispatch]);

  return deleteFile;
};

export default useDeleteFile;
import { useEffect, useState } from 'react';
import { useSelectProject } from '@containers/Store';
import { useMatchProjectIdFromRoute } from './useMatchProjectIdFromRoute';

export const useMatchProjectFromRoute = () => {
  const { projectId } = useMatchProjectIdFromRoute();
  const project = useSelectProject(projectId);
  const [state, setState] = useState({
    ...project,
    id: projectId,
  });

  useEffect(() => {
    if (project) {
      setState({
        ...project,
        id: projectId,
      });
    }
  }, [project, projectId]);

  return state;
};

export default useMatchProjectFromRoute;
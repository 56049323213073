import { surveys } from '@consts';
import { RankingQuestion, SurveyQuestionOption } from '@/types';

const NotApplicableOrdinal = surveys.NotApplicableRankingOrdinal;

export type RefreshItems = {
  items: SurveyQuestionOption[];
  settings: RankingQuestion.Settings;
  sourceIndex: number;
  targetIndex: number;
  targetOrdinal: number;
};

export function refreshItems({
  items,
  settings,
  sourceIndex,
  targetIndex,
  targetOrdinal,
}: RefreshItems) {

  function computeAdjustedDestinationIndex() {
    const previouslyNA = targetOrdinal !== NotApplicableOrdinal &&
      items[sourceIndex].ordinal === NotApplicableOrdinal;
    const maxNonNAIndex = Math.max(...items.filter(f => f.ordinal !== NotApplicableOrdinal).map((_, index) => index));
    const maxNewIndex = previouslyNA
      ? maxNonNAIndex + 1
      : maxNonNAIndex;

    return Math.min(maxNewIndex, targetIndex);
  }

  function computeNewOrdinal(item: SurveyQuestionOption, index: number) {
    const isDraggedItem = item.id === items[sourceIndex].id;
    const isNotApplicable = isDraggedItem
      ? targetOrdinal === NotApplicableOrdinal
      : item.ordinal === NotApplicableOrdinal;

    if (isNotApplicable) return NotApplicableOrdinal;

    if (!settings.mustRank) return index + 1;

    return index + 1 > settings.mustRank
      ? NotApplicableOrdinal
      : index + 1;
  }

  function reorderItems() {
    const adjustedDestinationIndex = computeAdjustedDestinationIndex();
    const otherItems = items.filter((_, index) => index !== sourceIndex);

    return [
      ...otherItems.slice(0, adjustedDestinationIndex),
      items[sourceIndex],
      ...otherItems.slice(adjustedDestinationIndex),
    ];
  }

  const reorderedItems = reorderItems();

  return reorderedItems.map((item, index) => ({
    id: item.id,
    ordinal: computeNewOrdinal(item, index),
  }));
}
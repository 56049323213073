import { ThemeProvider, createTheme } from '@mui/material/styles';

type Props =
  ChildrenProps;

const theme = createTheme();

export const MuiThemeProvider = ({ children }: Props) => {
  return (
    <ThemeProvider
      theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default MuiThemeProvider;
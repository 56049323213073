import { useCallback } from 'react';
import { useProfileEditableState, useProfileState } from '@containers/AccountProfile';
import { useProfileAvatarModal } from 'components/Modal/ProfileAvatar';
import { EditableUserAvatar, UserAvatar } from 'components/UserAvatar';
import { useUpdateUserAvatar } from '@utils/api';
import styles from './style/Profile.Header.css';

type Props = unknown;

const HeaderImage = (props: Props) => {

  const editable = useProfileEditableState();
  const [ctx, dispatch] = useProfileState();

  const [toggleModal, ProfileAvatarModal] = useProfileAvatarModal();

  const { mutateAsync } = useUpdateUserAvatar(ctx.id, {
    onSuccess: res => {
      dispatch({
        type: 'update-picture-url',
        value: res.pictureUrl,
      });

      toggleModal();
    },
  });

  const updateImage = useCallback((blob: Blob) => {
    return mutateAsync({ blob });

  }, [
    mutateAsync,
  ]);

  return(
    <>
      <div className={styles.avatar}>
        {editable &&
          <EditableUserAvatar
            onClick={toggleModal}
            size={95}
            pictureUrl={ctx?.profile?.pictureUrl} />}
        {!editable &&
          <UserAvatar
            size={95}
            pictureUrl={ctx?.profile?.pictureUrl} />}
      </div>

      <ProfileAvatarModal
        onSave={updateImage}
        pictureUrl={ctx?.profile?.pictureUrl} />
    </>
  );
};

export { HeaderImage };
export default HeaderImage;
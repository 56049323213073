import { useCallback, useMemo } from 'react';
import { SurveyQuotaType, utils as enumUtils } from '@enums';
import { SurveyQuota } from '@/types';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { QuotaContextMenuPopper } from './Preview.ContextMenu';
import { AdvancedQuotaTableContainer } from './Preview.Advanced.Table';
import { BasicSurveyQuotaPreview } from './Preview.Basic';
import styles from './style/Preview.css';

type Props = {
  goal: number;
  item: SurveyQuota.Record;
};

export const SurveyQuotaPreview = ({ goal, item }: Props) => {
  const [state] = useSurveyBuilderState();

  const canEdit = useMemo(() => {
    return !state.editing.quota.identifier;
  }, [
    state.editing.quota.identifier,
  ]);

  const renderItems = useCallback(() => {
    if (item.type === SurveyQuotaType.Advanced) {
      return (
        <AdvancedQuotaTableContainer quota={item} />
      );
    } else if (item.type === SurveyQuotaType.Basic) {
      return (
        <>
          <div className={styles.row}>
            <span className={styles.title}>Conditions:</span>
          </div>
          <div className={styles.conditions}>
            {item.data.items.map(s => (
              <BasicSurveyQuotaPreview
                key={s.identifier}
                item={s} />
            ))}
          </div>
        </>
      );
    }
  }, [item]);

  return (
    <div className={styles.root}>
      <div className={styles.details}>
        <div className={styles.row}>
          <span className={styles.title}>Name:</span>
          {item.name}
        </div>
        <div className={styles.row}>
          <span className={styles.title}>Limit:</span>
          {item.limit} / {goal}
        </div>
        <div className={styles.row}>
          <span className={styles.title}>Result:</span>
          {enumUtils.SurveyActionType.getName(item.actionType)}
        </div>
      </div>
      {canEdit &&
        <QuotaContextMenuPopper
          className={styles.popper}
          identifier={item.identifier} />
      }
      <div>
        {renderItems()}
      </div>
    </div>
  );
};

export default SurveyQuotaPreview;
import { Helmet } from 'react-helmet';
import { useSelectPayoutSettings } from '@containers';
import * as Screens from '@screens/PayoutSetup';
import styles from './style.css';

export default function PayoutSetup() {
  const settings = useSelectPayoutSettings();

  const hasPayoutMethod = !!settings.method;

  return (
    <div className={styles.wrap}>
      <Helmet title="Payout Setup" />
      <Screens.Stepper.GettingStarted
        hasPayoutMethod={hasPayoutMethod} />
    </div>
  );
}

export { PayoutSetup };
import { UserTopic } from '@presentation';
import { UserTopicsTagsProps } from './interfaces';
import styles from '../style/ProjectPipelineItem.css';

type Props =
  UserTopicsTagsProps;

const UserTopicsTags = (props: Props) => {
  if (!props.items.length) {
    return null;
  }

  return (
    <UserTopic
      className={styles.topics}
      items={props.items} />
  );
};

export default UserTopicsTags;
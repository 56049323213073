import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, MessageSquare } from 'react-feather';
import { getLocationFor } from '@utils';
import { Contact } from '@/types';
import { PaidMessagingContainer } from '@containers/PlatformMessage';
import { RequestCallContainer } from '@containers/RequestCall';
import { Button } from 'components/Button';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useProfileMessagingAction, useProfileRequestCallAction } from './hooks';
import styles from './style/Profile.Preview.css';

type Props = {
  user: Pick<Contact,
    'id' |
    'career' |
    'profile'
  >;
};

const StateDependent = memo(({ user }: Props) => {
  const params = {
    user: {
      id: user.id,
      profile: user.profile,
    },
  };

  const [canInitiateMessages, initiateMessage] = useProfileMessagingAction(params);
  const [canInitiateCalls, initiateCall] = useProfileRequestCallAction(params);

  const career = user.career.current?.display ||
    user.career.former?.display;

  const link = getLocationFor.user.profile(user.profile);

  return (
    <div className={styles.root}>
      <div className={styles.profileContainer}>
        <div className={styles.avatar}>
          <UserAvatar
            size={70}
            to={link}
            pictureUrl={user.profile.pictureUrl} />
        </div>
        <Link to={link}>
          <div className={styles.name}>{user.profile.fullname}</div>
        </Link>
        {career &&
          <div className={styles.career}>{career}</div>}
      </div>
      <div className={styles.btns}>
        {canInitiateMessages &&
          <button
            className={styles.btn}
            onClick={initiateMessage}>
            <MessageSquare />
            Message
          </button>}
        {canInitiateCalls &&
          <button
            className={styles.btn}
            onClick={initiateCall}>
            <Calendar />
            Request Call
          </button>}
      </div>
    </div>
  );
});

const ProfilePreview = (props: Props) => {
  return (
    <PaidMessagingContainer>
      <RequestCallContainer>
        <StateDependent {...props} />
      </RequestCallContainer>
    </PaidMessagingContainer>
  );
};

export { ProfilePreview };
export default ProfilePreview;
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import * as API from '@api/interfaces';
import { formatPossessive } from '@utils';
import { ComplianceReviewItem } from '@/types';
import Toast from 'components/Toast';
import { useAggregateReviewRefetch } from './useAggregateReviewRefetch';

type Params = {
  item:  ComplianceReviewItem<'survey'>;
  notes: string | null;
};

type DispatchChangeParams = {
  project: Pick<ComplianceReviewItem<'call'>['project'], 'id'>;
} & Pick<API.Projects.SurveyApprovalResponse, 'pipeline'>

const useAggregateReviewSurveyActions = () => {
  const dispatch = useDispatch();
  const refetch = useAggregateReviewRefetch();

  const handleChange = useCallback((data: DispatchChangeParams) => {

    dispatch(actions.projectPipelineUpdated({
      pipeline: data.pipeline,
      projectId: data.project.id,
    }));

  }, [dispatch]);

  const approve = useCallback((data: Params) => {

    return api.projects.surveys.approval({
      approved: true,
      approvalNotes: data.notes,
      projectSurveyResponseId: data.item.id,
    })
    .then(res => {

      handleChange({
        pipeline: res.pipeline,
        project: data.item.project,
      });

      notifier.approve({
        analyst: data.item.analyst,
        consultant: data.item.consultant,
        project: data.item.project,
      });

      refetch();

      return res;

    });

  }, [
    handleChange,
    refetch,
  ]);

  const reject = useCallback((data: Params) => {

    return api.projects.surveys.approval({
      approved: false,
      approvalNotes: data.notes,
      projectSurveyResponseId: data.item.id,
    })
    .then(res => {

      handleChange({
        pipeline: res.pipeline,
        project: data.item.project,
      });

      notifier.reject({
        analyst: data.item.analyst,
        consultant: data.item.consultant,
        project: data.item.project,
      });

      refetch();

      return res;

    });

  }, [
    handleChange,
    refetch,
  ]);

  return {
    approve,
    reject,
  };

};

type NotifierDispatchParams = {
  analyst:    Pick<ComplianceReviewItem<'call'>['analyst'], 'name'>;
  consultant: Pick<ComplianceReviewItem<'call'>['consultant'], 'name'>;
  project:    Pick<ComplianceReviewItem<'call'>['project'], 'name'>;
};

type NotifierDispatch = (params: NotifierDispatchParams) => void;

type Notifier = {
  approve: NotifierDispatch;
  reject:  NotifierDispatch;
};

const notifier: Notifier = {
  approve(params) {
    Toast.alert({
      title: 'Survey Response Approved',
      body: `${formatPossessive(params.consultant.name)} survey responses have been shared with ${params.analyst.name} for the ${params.project.name} project.`,
    });
  },
  reject(params) {
    Toast.error({
      title: 'Survey Response Rejected',
      body: `${formatPossessive(params.consultant.name)} survey responses have been rejected. The answers will not be shared with ${params.analyst.name}.`,
    });
  },
};

export { useAggregateReviewSurveyActions };
export default useAggregateReviewSurveyActions;
import { SurveyQuotaType } from '@enums';
import { SurveyQuota } from '@/types';

export function validateQuota(quota: SurveyQuota.Record) {
  if (!quota.name) {
    return {
      message: 'Name is empty',
      success: false,
    };
  }

  // if (!quota.limit) {
  //   return {
  //     message: 'Limit is empty',
  //     success: false,
  //   };
  // }

  if (!quota.actionType) {
    return {
      message: 'Result is empty',
      success: false,
    };
  }

  const dataValidation = quota.type === SurveyQuotaType.Advanced
    ? validateAdvancedQuotaData(quota.data)
    : validateBasicQuotaData(quota.data);

  if (!dataValidation.success) return dataValidation;

  return {
    message: '',
    success: true,
  };
}

function validateAdvancedQuotaData(data: SurveyQuota.Advanced.Value) {
  const messages = [] as string[];
  for (const set of data.sets) {

    if (!set.question?.identifier) {
      messages.push('Question is not set');
    }

    let sum = 0;
    for (const item of set.items) {
      sum += item.pct;
      if (!item.condition.value) {
        messages.push('Option is not set');
      }
    }

    if (sum > 100) {
      messages.push('Sum is > 100');
    }
  }

  return {
    message: messages.join(','),
    success: !messages.length,
  };
}

function validateBasicQuotaData(data: SurveyQuota.Basic.Value) {
  const messages = [] as string[];
  for (const item of data.items) {
    if (!item.question?.identifier) {
      messages.push('Question is not set');
    }

    if (!item.condition.value) {
      messages.push('Option is not set');
    }
  }

  return {
    message: messages.join(','),
    success: !messages.length,
  };
}
import { useMemo } from 'react';
import { useTranscriptComments, useTranscriptFocusedCommentContext } from '../context';

export const useTranscriptFocusedComment = () => {

  const [state] = useTranscriptComments();
  const [focusedIdentifier] = useTranscriptFocusedCommentContext();

  const focusedComment = useMemo(() => {
    return state.items.find(f => f.parentComment.identifier === focusedIdentifier);
  }, [
    focusedIdentifier,
    state.items,
  ]);

  return focusedComment;
};
import { useCallback } from 'react';
import * as enums from '@enums';
import { MultipleChoiceQuestion } from '@/types';
import {
  SurveyLogicActionHeader, SurveyLogicOrdinalHeader,
  SurveyLogicAnswerChoiceHeader, SurveyLogicConditionHeader } from './Header';
import { QuestionOptionsLogicPreviewProps } from './interfaces';
import styles from './style/QuestionOptionsLogic.css';
import SurveyActionOrdinal from './ActionOrdinal';

export const QuestionOptionsLogicPreview = (props: QuestionOptionsLogicPreviewProps) => {

  const renderItem = useCallback((item: MultipleChoiceQuestion.Logic.Response, index: number) => {
    const option = props.options.find(f => f.base.identifier === item.condition.value.option.identifier);

    return (
      <div className={styles.row} key={index}>
        <div className={styles.condition}>
          <SurveyLogicConditionHeader
            className={styles.mobileTitle} />
          {enums.utils.SurveyQuestionConditionType.getName(item.condition.type)}
        </div>
        <div className={styles.option}>
          <SurveyLogicAnswerChoiceHeader
            className={styles.mobileTitle} />
          {option.value}
        </div>
        <div className={styles.action}>
          <SurveyLogicActionHeader
            className={styles.mobileTitle} />
          {enums.utils.SurveyActionType.getName(item.action.type)}
        </div>
        {props.includeOrdinalColumn &&
          <SurveyActionOrdinal
            action={item.action}
            className={styles.ordinal} />}
      </div>
    );
  }, [props.includeOrdinalColumn, props.options]);

  if (!props.logic.length) return null;

  return (
    <div className={props.className}>
      <div className={styles.header}>
        <SurveyLogicConditionHeader
          className={styles.condition} />
        <SurveyLogicAnswerChoiceHeader
          className={styles.option} />
        <SurveyLogicActionHeader
            className={styles.action} />
        {props.includeOrdinalColumn &&
          <SurveyLogicOrdinalHeader
            className={styles.ordinal} />}
      </div>
      {props.logic.map(renderItem)}
    </div>
  );
};

export default QuestionOptionsLogicPreview;
import { useCallback, useRef, useState } from 'react';
import { cx } from '@utils';
import { AnimatedLoader } from 'components/ActivityIndicator';
import Button from 'components/Button';
import { OnVerifyEvent } from './interfaces';
import styles from './style/VerifyBank.css';

type Props = {
  className?: string;
  error?: string;
  onVerify: OnVerifyEvent;
  isLoading?: boolean;
  last4: string;
};

type VerifyBankForm = {
  amount1: string;
  amount2: string;
};

export function VerifyBank(props: Props) {
  const refAmount2 = useRef<HTMLInputElement>();
  const [form, setForm] = useState<VerifyBankForm>({
    amount1: '',
    amount2: '',
  });

  const { error, isLoading, onVerify } = props;

  const handleAmount = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value =  (''+e.target.value).replace(/[^0-9]/g, '');
    const updated = {
      ...form,
      [e.target.name]: value,
    };

    if (e.target.name === 'amount1' && value.length === 2) {
      refAmount2.current.focus();
    }

    setForm(updated);
  }, [form, setForm]);

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onVerify({
      amount1: +form.amount1,
      amount2: +form.amount2,
    });
  }, [form.amount1, form.amount2, onVerify]);

  const canVerify = !isLoading;

  const verifyBtnContent = isLoading
    ? <AnimatedLoader color='#ffffff' />
    : 'Verify';

  return (
    <form className={cx(props.className)} onSubmit={handleSubmit}>
      <div className={styles.notice}>Vancery has sent two microdeposits to your account ending in <span className={styles.bold}>{props.last4}</span>. They will take 1-2 business days to show up on your statement with description <span className={styles.bold}>AMTS</span>. Enter their values to complete bank account verification.</div>
      <div className={styles.deposits}>
        <div className={styles.deposit}>
          <div className={styles.label}>First value</div>
          <div className={styles.dollar}>$0.</div>
          <input
            name='amount1'
            value={form.amount1}
            className={styles.value}
            type='text'
            placeholder='00'
            maxLength={2}
            max={99}
            onChange={handleAmount}
            required
            title='First value is required.' />
        </div>
        <div className={styles.deposit}>
          <div className={styles.label}>Second value</div>
          <div className={styles.dollar}>$0.</div>
          <input
            ref={refAmount2}
            name='amount2'
            value={form.amount2}
            className={styles.value}
            type='text'
            placeholder='00'
            maxLength={2}
            max={99}
            onChange={handleAmount}
            required
            title='Second value is required.' />
        </div>
      </div>
      <div className={styles.error}>
        {error}
      </div>
      <div className={styles.actions}>
        <Button
          className={styles.save}
          color='primary'
          disabled={!canVerify}
          variant='brick'
          type='submit'>
          {verifyBtnContent}
        </Button>
      </div>
    </form>
  );
}

export default VerifyBank;
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as api from '@api';
import * as actions from '@store/actions';
import Toast from 'components/Toast';
import { DeleteFolderParams } from '../interfaces';

export const useDeleteFolder = () => {
  const dispatch = useDispatch();

  const deleteFolder = useCallback((data: DeleteFolderParams) => {
    return api.workspaces.folder.deleteFolder({
      folderId: data.folderId,
      workspaceId: data.workspaceId,
    }).then(_ => {
      dispatch(actions.folderDeleted({
        folderId: data.folderId,
        objectId: data.objectId,
      }));
      Toast.alert({
        title: 'Folder Deleted',
      });
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });

  }, [dispatch]);

  return deleteFolder;
};

export default useDeleteFolder;
import { cloneElement, Component } from 'react';
import { useSelector } from 'react-redux';
import * as enums from '@enums';
import * as selectors from '@store/selectors';
import { Contact } from '@/types';
import Toast from 'components/Toast';
import { useBookmarkUsers } from './injectors';
import {
  AddToProjectInjectedProps,
  BookmarkInProjectCallback,
  BookmarkInProjectParams,
  BookmarkUsersProps,
  PipelineRecord,
} from './interfaces';

const mapState = (state: Store.State) => ({
  pipeline: state.pipeline,
  projects: selectors.selectActiveManagedProjects(state),
});

type Props =
    AddToProjectInjectedProps
  & BookmarkUsersProps
  & ReturnType<typeof mapState>;

class BookmarkUsers extends Component<Props> {
  static defaultProps = {
    users: [] as Contact[],
  }

  filterValidOptions = () => {
    const [ user ] = this.props.users;

    return this.props.projects.filter(x => {
      const projectPipeline = this.props.pipeline.project[x.id].users;
      const record = projectPipeline[user.id];

      return this.shouldDisplayProject(record);
    });
  }

  getSelectOptions = () => {
    return this.props.users.length === 1
         ? this.filterValidOptions()
         : this.props.projects;
  }

  handleSubmitBookmarkUsers = (data: BookmarkInProjectParams, callback?: BookmarkInProjectCallback) => {
    this.props.addToProject({
      projectId: data.projectId,
      userIds: data.userIds,
    })
    .then(res => {
      this.notifyAddedToProject(data);

      if (typeof callback === 'function') callback(res);
    })
    .catch(this.notifyError);
  }

  notifyAddedToProject = (data: BookmarkInProjectParams) => {
    const count = data.userIds.length;
    const project = this.props.projects.find(item => item.id === data.projectId);
    const action = count === 1 ? '1 expert has' : `${count} experts have`;

    Toast.alert({
      title: `${project.name}`,
      body: `${action} been added to the ${project.name} project.`,
    });
  }

  notifyError = _ => {
    Toast.error({
      title: 'Error',
      body: `We're sorry, there was an issue. Please try again.`,
    });
  }

  shouldDisplayProject = (record?: PipelineRecord) => {
    const statuses = [
      enums.UserProjectStatus.Rejected,
    ];

    return !record || statuses.includes(record.statusId);
  }

  render() {
    return cloneElement(this.props.children, {
      handleSubmitBookmarkUsers: this.handleSubmitBookmarkUsers,
      options: this.getSelectOptions(),
    });
  }
}

const BookmarkUsersScheduler = (props: BookmarkUsersProps) => {
  const state = useSelector(mapState);
  const addToProject = useBookmarkUsers();

  return (
    <BookmarkUsers
      addToProject={addToProject}
      pipeline={state.pipeline}
      projects={state.projects}
      users={props.users}>
      {props.children}
    </BookmarkUsers>
  );
};

export { BookmarkUsersScheduler };
export default BookmarkUsersScheduler;
import { useContext } from 'react';
import { TaggingAutoSuggestContext } from '@containers/Group.Contacts.Import/Context';
import { Chip } from '@presentation';
import AutoComplete from 'components/AutoComplete';
import * as Layout from './Layout';
import styles from './style/Tagging.css';

type TagMap<T extends Tag = Tag> = Map<T['id'], T>;

type Props = unknown;

export const Tagging = (props: Props) => {
  const ctx = useContext(TaggingAutoSuggestContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Layout.Header>Apply Tags</Layout.Header>
        <Layout.Subheader>Use tags to organize your contacts in Vancery.</Layout.Subheader>
        <div className={styles.main}>
          <AutoComplete
            autoComplete="off"
            className={styles.input}
            getItemValue={ctx.suggestions.renderOptionValue}
            items={ctx.suggestions.items}
            onChange={ctx.input.onChange}
            onSelect={ctx.suggestions.onSelect}
            placeholder="Input a tag and hit enter to submit"
            value={ctx.input.value} />
          <div className={styles.selections}>
            <div className={styles.tags}>
              {ctx.selected.items.map(x =>
                <Chip
                  className={styles.tag}
                  color="basic"
                  key={x.id}>
                  <Chip.Body.Action
                    className={styles.removable}
                    onClick={() => ctx.selected.onDelete(x)}
                    variant="remove">
                    <div>{x.name}</div>
                  </Chip.Body.Action>
                </Chip>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Tagging.displayName = 'Tagging';

type Tag = {
  id: string | number;
  name: string;
  new?: boolean;
};
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import * as actions from '@actions';
import * as $logout from '@services/auth/logout';

type LogoutParams = {
  sideEffects: boolean;
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const query = useQueryClient();

  const logout = ({ sideEffects = true }: LogoutParams) => {
    if (sideEffects) {
      return $logout.logout()
      .then(() => {
        dispatch(actions.logout({ wasActionLogout: true }));
        Promise.all([query.cancelQueries(), query.cancelMutations()])
          .then(() => query.clear());
      });
    } else {
      dispatch(actions.logout({ wasActionLogout: true }));
      Promise.all([query.cancelQueries(), query.cancelMutations()])
        .then(() => query.clear());
    }
  };

  return useCallback(logout, [query, dispatch]);
};
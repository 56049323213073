import { useEffect, useState } from 'react';
import { Redirect, RedirectProps, useHistory, useParams } from 'react-router-dom';
import * as api from '@api';
import * as API from '@api/interfaces';
import * as consts from '@consts';
import { transformPalette } from '@containers/Branding/utils';
import { useHasClientRole } from '@containers/User';
import { useSelectUser } from '@containers/Store';
import { qs, useAsyncStateLazy, usePrevious } from '@utils';
import ActivityIndicator from 'components/ActivityIndicator';
import { useCacheProjectInviteTheming, useCreateProjectInvite, usePersistentProjectInvite } from './hooks';

type Props = unknown;

const ProjectLinkInvitationContainer = (props: Props) => {
  const urlstate = usePersistentProjectInvite();
  const [redirect, setRedirect] = useState<RedirectProps['to']>(null);
  const user = useSelectUser();
  const prevUser = usePrevious(user);
  const params = useParams<{ token: string; }>();
  const history = useHistory();
  const token = params.token;
  const createProjectInvite = useCreateProjectInvite(token);
  const hasClientRole = useHasClientRole();
  const cacheSurveyTheming = useCacheProjectInviteTheming();

  const [response, handleRedirect] = useAsyncStateLazy(() => {
    return api.tokens.findProject({ token })
    .then(data => {

      if (!data.project) setRedirect(consts.path.Website.Signup.Root);

      const state = {
        data: {
          payment: data.payment,
          project: data.project,
          theming: transformTheming({
            theming: data.theming,
          }),
        },
        return: `${consts.path.Invite.Root}/${token}`,
        token,
      };

      urlstate.set(state);

      if (state.data.theming) {
        cacheSurveyTheming({
          project: state.data.project,
          theming: state.data.theming,
        });
      }

      setRedirect({
        pathname: consts.path.Website.Signup.Root,
        search: qs.base64Encode(state),
        state: {
          project: data.project,
          return: state.return,
          token,
        },
      });

    })
    .catch(e => {
      setRedirect(consts.path.Website.Root);
    });
  }, [
    token,
    urlstate,
  ]);

  useEffect(() => {
    if (!prevUser?.id && user?.id) {
      if (!hasClientRole) {
        createProjectInvite();
      } else {
        urlstate.unset();
        history.replace(consts.pathname.HOME);
      }
    }
  }, [
    createProjectInvite,
    hasClientRole,
    history,
    prevUser?.id,
    urlstate,
    user?.id,
  ]);

  if (
    !user.state.authenticated &&
    !response.loading &&
    !response.error &&
    !redirect
  ) {
    handleRedirect();
  }

  if (redirect) return <Redirect to={redirect} />;

  return <ActivityIndicator show />;
};

export { ProjectLinkInvitationContainer };
export default ProjectLinkInvitationContainer;

const transformTheming = (data: Pick<API.Tokens.FindProject.Response, 'theming'>) => {
  if (!data.theming) return null;

  return {
    logos: data.theming.logos,
    page: data.theming.page,
    palette: transformPalette(data.theming.palette),
  };
};
import { memo } from 'react';
import { cx } from '@utils';
import { ActiveTrackProps, InactiveTrackProps } from './interfaces';
import styles from './style/Tracks.css';

export const ActiveSliderTrack = memo((props: ActiveTrackProps) => {
  const { className, ...otherProps } = props;
  return <div className={cx(styles.active, className)} {...otherProps} />;
});

export const InactiveSliderTrack = memo((props: InactiveTrackProps) => {
  const { className, ...otherProps } = props;
  return <div className={cx(styles.inactive, className)} {...otherProps} />;
});
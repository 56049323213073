import { useContext } from 'react';
import { cx } from '@utils';
import { useConnectAccountContactSync } from 'components/ConnectAccount/hooks/useConnectAccountContactSync';
import { ProjectInviteReferralEmailContext } from './Context';
import { AutoSuggest } from './Email.Input.AutoSuggest';
import { TextField } from './Email.Input.TextField';
import styles from './style/Email.Input.css';

type Props = {
  className?: string;
};

export const AddressInput = (props: Props) => {
  const sync = useConnectAccountContactSync();
  const ctx = useContext(ProjectInviteReferralEmailContext);

  return (
    <div className={cx(styles.root, props.className)}>
      {sync.connection
        ? <AutoSuggest
            addresses={ctx.data.addresses}
            onAdd={ctx.addAddress} />
        : <TextField onAdd={ctx.addAddress} />}
    </div>
  );
};

AddressInput.displayName = 'ProjectInviteReferral.Email.AddressInput';
import { useMemo } from 'react';
import * as consts from '@consts';
import { ProjectPipeline } from '@screens/ProjectPipeline';
import { ProjectSummary } from '@screens/ProjectSummary';
import { ProjectCalls } from '@screens/ProjectCalls';
import { useTabRoutes, useProjectState } from './hooks';
import { SurveyResponsesContainer } from './SurveyResponsesContainer';
import { RouteKey, SceneMap } from './interfaces';
import ProjectTabView from './ProjectTabView';
import { ProjectDetailsContainer } from './ProjectDetailsContainer';

export const ProjectTabViewContainer = () => {
  const tabRoutes = useTabRoutes();
  const project = useProjectState();
  const isExternal = project.latestProject.isExternal;

  const excludeFromExternal = useMemo(() => [RouteKey.SurveyResponses], []);
  const map: SceneMap = useMemo(() => ({
    [RouteKey.Calls]: {
      component: ProjectCalls,
      path: consts.path.ProjectTabs.Calls,
      title: 'Upcoming Calls',
    },
    [RouteKey.Details]: {
      component: ProjectDetailsContainer,
      path: consts.path.ProjectTabs.Details,
      title: 'Project Details',
    },
    [RouteKey.Pipeline]: {
      component: ProjectPipeline,
      path: consts.path.ProjectTabs.Pipeline,
      title: 'Pipeline',
    },
    [RouteKey.Summary]: {
      component: ProjectSummary,
      path: consts.path.ProjectTabs.Summary,
      title: 'Summary',
    },
    [RouteKey.SurveyResponses]: {
      component: SurveyResponsesContainer,
      path: consts.path.ProjectTabs.SurveyResponses,
      title: 'Survey Responses',
    },
  }), []);

  const filteredMap = useMemo(() => {
    return tabRoutes.reduce((acc, x) => {
      if (isExternal && excludeFromExternal.includes(x)) {
        return acc;
      } else {
        return {
          ...acc,
          [x]: map[x],
        };
      }
    }, {} as SceneMap);
  }, [
    map,
    tabRoutes,
    isExternal,
    excludeFromExternal,
  ]);

  return <ProjectTabView sceneMap={filteredMap} />;
};

export default ProjectTabViewContainer;
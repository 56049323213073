import { useContext } from 'react';
import { AggregateReviewRefetchContext } from '@containers/ComplianceReview/Context';

const useAggregateReviewRefetch = () => {
  const refetch = useContext(AggregateReviewRefetchContext);

  return refetch;
};

export { useAggregateReviewRefetch };
export default useAggregateReviewRefetch;
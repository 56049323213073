import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { Comment } from '@/types/transcribe.rich-text';
import { useTranscriptCommentActions, useTranscriptComments } from '@containers/Transcript';

type Data = Comment;
type Variables = {
  commentId: number;
  parentCommentId: number;
  value: unknown;
};
type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

export function useUpdateComment(options?: Options) {

  const { updateComment } = useTranscriptCommentActions();
  const [_, dispatch] = useTranscriptComments();

  return useMutation(['update-transcript-comment'], ({ commentId, value }) => {

    return updateComment({ commentId, value });

  }, {
    ...options,
    onSuccess: (res, v, ctx) => {

      dispatch({
        type: 'comment-updated',
        comment: res,
        parentCommentId: v.parentCommentId,
      });

      options?.onSuccess?.(res, v, ctx);
    },
  });
}
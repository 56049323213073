import { SurveyQuestionType, SurveyQuestionConditionType } from '@enums/Survey';
import { MatrixGridQuestion, SurveyEnrichedQuestionCondition, MultipleChoiceQuestion } from '@/types/survey';

export function generateConditionString(item: SurveyEnrichedQuestionCondition) {
  if (!item) return null;

  const question = `Q${item.question.ordinal}`;
  const type = item.condition.type === SurveyQuestionConditionType.Chosen
    ? '='
    : item.condition.type === SurveyQuestionConditionType.NotChosen
      ? '!='
      : '';

  let value = '';
  switch (item.question.typeId) {

    case SurveyQuestionType.MultipleChoice:
    case SurveyQuestionType.Multiselect: {
      const v = item.condition as MultipleChoiceQuestion.Logic.EnrichedCondition;
      value = `A${v.value.option.ordinal}`;
      break;
    }

    case SurveyQuestionType.MatrixGrid: {
      const v = item.condition as MatrixGridQuestion.Logic.EnrichedCondition;
      value = `R${v.value.row.ordinal}-A${v.value.option.ordinal}`;
      break;
    }
  }

  return `${question} ${type} ${value}`;
}

export function wrapInParentheses(v: string) {
  return `(${v})`;
}

export function formatAvgDuration(d: number) {
  const minutes = Math.floor(d / 60000);
  const seconds = Math.floor((d % 60000) / 1000);
  return `${minutes}m ${seconds}s`;
}
import { createContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import * as enums from '@enums';
import { useSelectUser } from '@containers/Store';
import { AccountProfileContainer } from '@containers/AccountProfile';
import { useSuggestedRate } from './useSuggestedRate';
import { ProjectRateOnboardingProps, ProjectRateOnboardingContextValue } from './interfaces';

const ProjectRateOnboardingContext = createContext<ProjectRateOnboardingContextValue>({
  back:                       () => {},
  handleSaveRate:             ()=> { return new Promise(null); },
  hourlyRate:                 0,
  minimumHourlyRate:          0,
  maximumHourlyRate:          0,
  next:                       () => {},
  onHourlyRateSlide:          (position: number) => {},
  onHourlyRateChange:         () => {},
  projectId:                  0,
  refreshSuggestedRate:       ()=> { return new Promise(null); },
  step:                       0,
  suggestedHourlyRate:        0,
});

const DefaultHourlyRate = 250;

const StateDependentContainer = (props: ProjectRateOnboardingProps) => {
  const user = useSelectUser();
  const dispatch = useDispatch();

  const [seniority, setSeniority] = useState<enums.Seniority>();
  const [suggestedHourlyRate, setSuggestedHourlyRate] = useState<number>(user.settings.hourlyRate || DefaultHourlyRate);

  const {
    handleRateDragEnd,
    hourlyRate,
    minimumHourlyRate,
    maximumHourlyRate,
    updateHourlyRate,
  } = useSuggestedRate(suggestedHourlyRate, seniority);

  const getSuggestedRate = () => {
    return api.profiles.suggestHourlyRate({})
    .then(res => {
      setSeniority(res.seniority);
      setSuggestedHourlyRate(res.value);
    });
  };

  const saveRate = () => {
    return api.users.updateSettings({
      hourlyRate,
      userId: user.id,
    })
    .then(res => {
      dispatch(actions.userInfoChange({
        profile: { hourlyRate },
        settings: res,
      }));
    });
  };

  const context: ProjectRateOnboardingContextValue = {
    back: props.back,
    handleSaveRate: saveRate,
    hourlyRate,
    minimumHourlyRate,
    maximumHourlyRate,
    next: props.next,
    onHourlyRateChange: handleRateDragEnd,
    onHourlyRateSlide: updateHourlyRate,
    projectId: props.projectId,
    refreshSuggestedRate: getSuggestedRate,
    step: props.step,
    suggestedHourlyRate,
  };

  return (
    <ProjectRateOnboardingContext.Provider value={context}>
      {props.children}
    </ProjectRateOnboardingContext.Provider>
  );
};

const ProjectRateOnboarding = (props: ProjectRateOnboardingProps) => {
  const user = useSelectUser();

  return (
    <AccountProfileContainer userId={user.id}>
      <StateDependentContainer {...props} />
    </AccountProfileContainer>
  );
};

export { ProjectRateOnboardingContext };
export { ProjectRateOnboarding };
export default ProjectRateOnboarding;
import { subMinutes, isAfter, isBefore } from 'date-fns';
import * as consts from '@consts';
import * as enums from '@enums';
import { Call, User } from '@/types';

type CallState =
    Pick<Call,
    | 'approvalStatusId'
    | 'screeningStatusId'
    | 'statusId'>;

type CallStatus = Pick<Call, 'statusId'>;
type CallApproval = Pick<Call, 'approvalStatusId'>;
type CallScreening = Pick<Call, 'screeningStatusId'>;

export function canCancelCall(data: CallStatus) {
  const statuses = [
    enums.CallStatus.Pending,
    enums.CallStatus.Rescheduling,
    enums.CallStatus.Scheduled,
  ];

  return statuses.includes(data.statusId);
}

export function canJoinConference(data: Pick<Call, 'timeEnd' | 'timeStart'> & CallStatus) {
  const statuses = [
    enums.CallStatus.Scheduled,
  ];

  return statuses.includes(data.statusId)
      && isAfter(Date.now(), subMinutes(data.timeStart, consts.conference.PreConferenceInterval))
      && isBefore(Date.now(), data.timeEnd);
}

export function isActivelyScheduling(data: CallState) {
  const inProgress = isPending(data)
                  || isRescheduling(data);

  return inProgress
      && passesApproval(data)
      && !needsScreening(data);
}

export function isAwaitingVanceryCompliance(data: CallState) {
  const complianceStatuses = [
    enums.ApprovalStatus.NeedsApproval,
  ];
  const callStatuses = [
    enums.CallStatus.Pending,
  ];

  return callStatuses.includes(data.statusId)
      && complianceStatuses.includes(data.screeningStatusId);
}

export function isAwaitingInternalCompliance(data: CallState) {
  const complianceStatuses = [
    enums.ApprovalStatus.NeedsApproval,
  ];
  const callStatuses = [
    enums.CallStatus.Pending,
  ];

  return callStatuses.includes(data.statusId)
      && complianceStatuses.includes(data.approvalStatusId);
}

export function isAwaitingCompliance(data: CallState) {
  const complianceStatuses = [
    enums.ApprovalStatus.NeedsApproval,
  ];
  const callStatuses = [
    enums.CallStatus.Pending,
  ];

  return callStatuses.includes(data.statusId)
      && (complianceStatuses.includes(data.approvalStatusId)
      || complianceStatuses.includes(data.screeningStatusId));
}

export function isAwaitingCreatorAction(data: Pick<Call, 'creatorUserId' | 'lastModifiedBy'> & CallState) {
  return isActivelyScheduling(data)
      && !isLastModifiedBy(data, { id: data.creatorUserId });
}

export const isCallWithUser = (call: Pick<Call, 'projectId' | 'userId'>, record: IProjectId & IUserId) => {
  return call.projectId === record.projectId
      && call.userId === record.userId;
};

export function isConfirmed(data: CallStatus) {
  const statuses = [
    enums.CallStatus.Scheduled,
  ];

  return statuses.includes(data.statusId);
}

export function isLastModifiedBy(call: Pick<Call, 'lastModifiedBy'>, user: Pick<User, 'id'>) {
  return call.lastModifiedBy === user.id;
}

export function isMissed(call: CallStatus) {
  const statuses = [
    enums.CallStatus.MissedByClient,
  ];

  return statuses.includes(call.statusId);
}

export function isPending(data: CallStatus) {
  const statuses = [
    enums.CallStatus.Pending,
  ];

  return statuses.includes(data.statusId);
}

export function isRescheduling(data: CallStatus) {
  const statuses = [
    enums.CallStatus.Rescheduling,
  ];

  return statuses.includes(data.statusId);
}

export function isUnconfirmed(data: CallStatus) {
  return isPending(data)
      || isRescheduling(data);
}

export function passesApproval(data: CallApproval) {
  const statuses = [
    enums.ApprovalStatus.Approved,
    enums.ApprovalStatus.NotRequired,
  ];

  return statuses.includes(data.approvalStatusId);
}

export function needsScreening(data: CallScreening) {
  const statuses = [
    enums.ApprovalStatus.NeedsApproval,
  ];

  return statuses.includes(data.screeningStatusId);
}

export function isCreator(data: Pick<Call, 'creatorUserId'>, userId: number) {
  return data.creatorUserId === userId;
}

export function getOtherAdHocCallParticipant(data: Pick<Call, 'creatorUserId' | 'userId'>, userId: number) {
  return userId === data.userId
    ? data.creatorUserId
    : data.userId;
}
import { SurveyQuestionType } from '@enums';
import { cx } from '@utils';
import { MatrixQuestion } from './MatrixQuestion';
import { MatrixSliderQuestion } from './MatrixSliderQuestion';
import { QuestionTextWithLogic } from './QuestionTextWithLogic';
import OptionsWithLogic from './OptionsWithLogic';
import { QuestionItemWithLogicProps } from './interfaces';
import styles from './style/QuestionItem.css';
import Options from './Options';
import MaxDiff from './MaxDiff.Question';

export const QuestionPreviewItemWithLogicContent = (props: QuestionItemWithLogicProps) => {
  const renderContent = () => {

    switch (props.item.typeId) {
      case SurveyQuestionType.MultipleChoice:
      case SurveyQuestionType.Multiselect: {
        return (
          <OptionsWithLogic
            item={props.item}
            includeTagging={props.includeTagging} />
        );
      }

      case SurveyQuestionType.Ranking:
        return (
          <Options item={props.item} />
        );

      case SurveyQuestionType.Sliders: {
        return (
          <MatrixSliderQuestion item={props.item} />
        );
      }

      case SurveyQuestionType.MatrixGrid:
      case SurveyQuestionType.MatrixMultiselect: {
        return (
          <MatrixQuestion item={props.item} />
        );
      }

      case SurveyQuestionType.MaxDifference:
        return <MaxDiff item={props.item} />;

      default:
        return null;

    }
  };

  const className = cx(props.className, {
    [styles.content]: ![
      SurveyQuestionType.LongTextResponse,
      SurveyQuestionType.ShortTextResponse,
    ].includes(props.item.typeId),
  });

  return (
    <div className={className}>
      {renderContent()}
    </div>
  );
};

export const QuestionPreviewItemWithLogic = (props: QuestionItemWithLogicProps) => {

  return (
    <div className={cx(styles.root, props.className)}>
      <QuestionTextWithLogic
        item={props.item} />
      <QuestionPreviewItemWithLogicContent
        item={props.item}
        includeTagging={props.includeTagging} />
    </div>
  );
};

export default QuestionPreviewItemWithLogic;
import { useSignupSubmit } from '$website/containers/Auth/hooks/useSignupSubmit';
import styles from './style/SignUpForm.css';

type Props = unknown;

const SignupError = (props: Props) => {
  const [state] = useSignupSubmit();

  const content = state.error
      ? <div className={styles.error}>{state.error.message}</div>
      : null;

  return (
    <div className={styles.notify}>{content}</div>
  );
};

export { SignupError };
export default SignupError;
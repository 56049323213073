import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';
import { CSSProperties } from '@material-ui/styles/withStyles';

const useArrowStyles = makeStyles((theme: Theme) => ({
  tooltip: (props: Props) => ({
    position: 'relative',
    fontSize: 13,
    fontFamily: 'HarmoniaSans W01 Bold',
    backgroundColor: 'var(--black-l)',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    ...props.tooltipstyles,
  }),
  arrow: (props: Props) => ({
    color: props.tooltipstyles?.backgroundColor || 'var(--black-l)',
  }),
}));

type Props = {
  children: React.ReactElement;
  tooltipstyles?: CSSProperties;
} & TooltipProps;

export const Tooltip = ({
  arrow = true,
  ...props
}: Props) => {
  const { children, tooltipstyles, ...other } = props;
  const classes = useArrowStyles(props);

  return (
    <MuiTooltip
      arrow={arrow}
      {...other}
      classes={classes}>
      {props.children}
    </MuiTooltip>
  );
};

export default Tooltip;

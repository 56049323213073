import { SurveyAggregate } from '@/types';
import { formatWithOrdinalSuffix } from '@utils';

export function formatRankedText(value: number | string) {
  return value === 0
    ? 'N/A'
    : `Ranked ${formatWithOrdinalSuffix(+value)}`;
}

export function getSegmentColor(data: {
  key: string;
  keys: SurveyAggregate.StackedBarChart.Keys;
}, {
  extendedStrong = opts.extendedStrong,
  extendedWeak = opts.extendedWeak,
  strong = opts.strong,
  weak = opts.weak,
}: Options = opts) {
  if (data.key === data.keys.notApplicableKey) {
    return 'var(--gray-l)';
  }

  const total = data.keys.ids.length - 1;
  const index = data.keys.ids.findIndex(f => f === data.key);

  const NeutralColor = total <= 12
    ? NeutraRankingColor
    : ExtendedNeutralRankingColor;
  const StrongColors = total <= 12
    ? strong
    : extendedStrong;
  const WeakColors = total <= 12
    ? weak
    : extendedWeak;

  const middle = Math.ceil(total / 2);

  return index + 1 === middle && total % 2
    ? NeutralColor
    : index + 1 <= middle
      ? StrongColors[index]
      : WeakColors[total - index - 1]
  ;
}

type Options = {
  extendedStrong: string[];
  extendedWeak:   string[];
  strong:         string[];
  weak:           string[];
};

export const NeutraRankingColor = '#D1D1D1';
export const ExtendedNeutralRankingColor = '#EAEAEA';

export const WeakRankingColors = [
  '#E92D21',
  '#F25753',
  '#F36E6A',
  '#F5807D',
  '#F69390',
  '#F9B1AF',
];

export const ExtendedWeakRankingColors = [
  '#E01F13',
  '#E92E22',
  '#ED4338',
  '#F45A50',
  '#FE6B62',
  '#FF7C74',
  '#FF918E',
  '#FE9D9A',
  '#FFB9B7',
  '#FFCAC8',
];

export const StrongRankingColors = [
  '#0B4ADA',
  '#2461E9',
  '#5A84E3',
  '#80A0EA',
  '#AAC0F1',
  '#C3D2F5',
];

export const ExtendedStrongRankingColors = [
  '#0A4BDE',
  '#1958E5',
  '#2461E9',
  '#4877E3',
  '#5A84E3',
  '#6D96F4',
  '#8AACFA',
  '#A9C0F5',
  '#C4D6FE',
  '#D4E0FD',
];

const opts = {
  extendedStrong: ExtendedStrongRankingColors,
  extendedWeak: ExtendedWeakRankingColors,
  strong: StrongRankingColors,
  weak: WeakRankingColors,
};
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSurvey } from '@containers/Survey/hooks/useSurvey';
import { useProjectSurveyResponsesState } from './useProjectSurveyResponsesState';

export const useValidateResponsesPath = () => {

  const survey = useSurvey();
  const { basePath, query } = useProjectSurveyResponsesState();
  const history = useHistory();
  const isDesktop = useMediaQuery('(min-width:900px)');

  const reset = useCallback(() => {
    const question = survey.questions[0];
    history.replace(`${basePath}?questionId=${question.base.id}`);
  }, [
    basePath,
    history,
    survey,
  ]);

  const validatePath = useCallback(() => {

    if (survey) {
      if (!query.questionId && isDesktop) {
        reset();
      } else if (query.questionId) {
        const question = survey.questions.find(f => f.base.id === query.questionId);
        if (!question) {
          reset();
        }
      }
    }
  }, [
    isDesktop,
    query.questionId,
    reset,
    survey,
  ]);

  return validatePath;
};

export default useValidateResponsesPath;
import * as types from '@store/action-types';
import { conferences as initialState } from '@store/initial-state';
import {
  AppDataFetchedAction,
  AppStateRehydratedAction,
  ConferenceActions,
} from '@store/interfaces';

type Actions =
    AppDataFetchedAction
  | AppStateRehydratedAction
  | ConferenceActions;

function conferences(state: Store.Conferences = initialState, action: Actions) {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.conferences;
    case types.CONFERENCE_ADDED: {
      return {
        ...state,
        [action.callId]: action.conference,
        ids: [action.callId].concat(state.ids),
      };
    }
    case types.CONFERENCE_UPDATED: {
      return {
        ...state,
        [action.callId]: action.conference,
      };
    }
    default:
      return state;
  }
}

export default conferences;
import { useMemo, useCallback, forwardRef, Ref } from 'react';
import { MoreVertical } from 'react-feather';
import CheckIcon from '@mui/icons-material/Check';
import PopupState from 'material-ui-popup-state';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useQuestionBuilderItemContext, useQuestionBuilderContext, useDeleteQuestion } from '@containers/SurveyBuilder.Question/Context';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { SurveyQuestionType } from '@enums';
import { Anchor } from '@presentation/Anchor';
import { cx } from '@utils';
import { SurveyQuestion, SurveySettings } from '@/types';
import { PopperMenu, PopperMenuItem } from 'components/Popper';
import { QuestionContextMenuPopperProps } from './interfaces';
import styles from './style/QuestionContextMenu.css';

type QuestionContextMenuItem = {
  children: React.ReactNode;
  className?: string;
  onClick: () => void;
};

type ClickEvent = () => unknown;

type MenuProps = {
  onClose: () => unknown;
};

export const QuestionContextMenu = forwardRef(({ onClose }: MenuProps, ref: Ref<HTMLDivElement>) => {
  const item = useQuestionBuilderItemContext();
  const context = useQuestionBuilderContext();
  const [_, dispatch] = useSurveyBuilderState();

  const onDelete = useDeleteQuestion();

  const handleClick = useCallback((fn: ClickEvent) => () => {
    onClose();
    return fn();
  }, [onClose]);

  const edit = useMemo(() => {
    return {
      children: 'Edit',
      onClick: () => {
        dispatch({
          type: 'toggle-editing',
          ordinal: item.ordinal,
        });
      },
    };
  }, [dispatch, item.ordinal]);

  const toggleRandomization = useCallback(() => {
    dispatch({
      type: 'update-question-settings',
      ordinal: item.ordinal,
      settings: {
        ...item.settings,
        randomization: !(item.settings as SurveySettings.Randomization).randomization,
      },
    });
  }, [
    dispatch,
    item.ordinal,
    item.settings,
  ]);

  const toggleAllowOther = useCallback(() => {
    dispatch({
      type: 'toggle-allow-other',
      questionIdentifier: item.base.identifier,
      value: !(item.settings as SurveySettings.MultiQuestion).allowOther,
    });
  }, [item, dispatch]);

  const remove = useMemo(() => {
    if (!item.metadata.canDelete) return null;
    return {
      children: 'Delete',
      onClick: onDelete,
    };
  }, [
    onDelete,
    item.metadata.canDelete,
  ]);

  const randomizationMenuItem = useMemo(() => {
    const enabled = (item.settings as SurveySettings.Randomization).randomization;
    const text = 'Randomize Answer Order';

    const checked = (
      <span className={styles.check}>
        <CheckIcon
          className={styles.checkmark}
          fontSize="small" /> {text}
      </span>
    );

    return {
      className: cx({ [styles.checked]: enabled }),
      children: enabled ? checked : text,
      onClick: toggleRandomization,
    };
  }, [
    item.settings,
    toggleRandomization,
  ]);

  const allowOtherMenuItem = useMemo(() => {
    const enabled = (item.settings as SurveySettings.MultiQuestion).allowOther;
    const text = 'Allow Other';

    const checked = (
      <span className={styles.check}>
        <CheckIcon
          className={styles.checkmark}
          fontSize="small" /> {text}
      </span>
    );

    return {
      className: cx({ [styles.checked]: enabled }),
      children: enabled ? checked : text,
      onClick: toggleAllowOther,
    };
  }, [
    item.settings,
    toggleAllowOther,
  ]);

  const items: QuestionContextMenuItem[] = [
    edit,
    canUseRandomization(item) && randomizationMenuItem,
    canUseOther(item) && allowOtherMenuItem,
    remove,
  ].filter(Boolean);

  return (
    <PopperMenu ref={ref}>
      {items.map((item, i) => (
        <PopperMenuItem
          key={i}
          className={item.className}
          onClick={handleClick(item.onClick)}>
          {item.children}
        </PopperMenuItem>
      ))}
    </PopperMenu>
  );
});

export const QuestionContextMenuPopper = (props: QuestionContextMenuPopperProps) => {

  return (
    <PopupState
      variant="popper"
      popupId="question-menu-popper">
      {popupState => (
        <div className={props.className}>
          <div onClick={popupState.open}>
            <Anchor
              open={popupState.isOpen}
              Icon={MoreVertical} />
          </div>
          <Popper
            open={popupState.isOpen}
            anchorEl={popupState.anchorEl}
            placement="bottom-end">
            <ClickAwayListener
              onClickAway={popupState.close}>
              <QuestionContextMenu
                onClose={popupState.close} />
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
};

function canUseRandomization(item: SurveyQuestion) {
  const types = [
    SurveyQuestionType.MatrixGrid,
    SurveyQuestionType.MatrixMultiselect,
    SurveyQuestionType.Sliders,
    SurveyQuestionType.MultipleChoice,
    SurveyQuestionType.Multiselect,
    SurveyQuestionType.Ranking,
  ];

  return types.includes(item.typeId);
}

function canUseOther(item: SurveyQuestion) {
  const types = [
    SurveyQuestionType.MultipleChoice,
    SurveyQuestionType.Multiselect,
  ];

  return types.includes(item.typeId);
}

export default QuestionContextMenu;
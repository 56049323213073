import { createElement, forwardRef, useMemo } from 'react';
import { MenuItem } from '@containers/AppNavigation/interfaces';
import { cx } from '@utils';
import { Button } from 'components/Button';
import styles from 'components/AppBar/style/Nav.css';

type Props = {
  className?: string;
  classes?:  {
    icon?: string;
  };
  children?: React.ReactNode;
  Icon?:     React.ComponentType<{ className?: string; }>;
  icon?:     React.ReactNode;
  name:      MenuItem['name'] | string;
} & Omit<MenuItem, | 'icon' | 'key' | 'name'>;

const ActionableIcon = ({
  classes = {},
  ...props
}: Props, ref: React.Ref<HTMLDivElement>) => {
  const anchor = useAnchor(props);

  return (
    <div className={cx(styles.item, props.className)}>
      <div
        className={styles.link}
        ref={ref}>
        <Button
          className={styles.btn}
          color="transparent"
          variant="text"
          onClick={props.onClick}
          to={props.to}>
          <div className={cx(styles.box, classes.icon)}>
            {anchor}
            {props.count > 0 && <div className={styles.badge}>{props.count}</div>}
          </div>
          <div className={styles.name}>{props.name}</div>
        </Button>
        {props.children}
      </div>
    </div>
  );
};

const useAnchor = (props: Pick<Props, 'Icon' | 'icon'>) => {

  return useMemo(() => {

    if (props.Icon) {
      return createElement(props.Icon, { className: styles.icon });
    } else if (props.icon) {
      return props.icon;
    } else {
      return null;
    }
  }, [
    props.Icon,
    props.icon,
  ]);
};

ActionableIcon.displayName = 'Menu.ActionableIcon';

const ActionableIconWithRef = forwardRef<HTMLDivElement, Props>(ActionableIcon);

export {
  ActionableIconWithRef as ActionableIcon,
};
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import * as api from '@api/calls';
import * as Calls from '@api/interfaces/calls';

type Data = unknown;
type Variable = Omit<Calls.SubmitCallRatingRequest, 'callId'>;

export function useSubmitCallRating(
  data: ICallId,
  options?: UseMutationOptions<Data, AxiosResponse, Variable>
) {

  return useMutation(keySubmitCallRating(data.callId), params => {

    return api.submitCallRating({
      callId: data.callId,
      isSkipped: params.isSkipped,
      rating: params.rating,
    });

  }, options);
}

export function keySubmitCallRating(callId: number) {
  return ['submit-call-rating', callId];
}
import { useSelector } from 'react-redux';
import * as selectors from '@store/selectors';
import { CalendarState, Contact } from '@/types';
import { Calendar } from 'components/Calendar/Calendar';
import { Providing } from './Providing.js';

const mapState = (state: Store.State) => ({
  connectedCalendar: selectors.connectedCalendar(state),
  contacts: state.contacts,
  user: state.user,
});

type Props = {
  className?: string;
  onSubmit: (data: CalendarState) => unknown;
  with:     Pick<Contact, 'id'>;
};

const Invitations = (props: Props) => {
  const state = useSelector(mapState);

  const calendar = {
    with: props.with,
  };

  return (
    <Calendar calendar={calendar}>
      <Providing
        className={props.className}
        connectedCalendar={state.connectedCalendar}
        contacts={state.contacts}
        onSubmit={props.onSubmit}
        user={state.user} />
    </Calendar>
  );
};

const defaultProps = {
  className: '',
};

Invitations.defaultProps = defaultProps;

export { Invitations };
export default Invitations;
import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import { ActionableLinkContainer, Routing } from '@containers';
import { AdHocScheduling, Auth, HubSpot, ProjectReport, Scheduling, SlackConnected, Stripe, ThankYou } from '@screens/Inbound';

export const anonymousRoutes = [{
  component: ActionableLinkContainer,
  exact: true,
  path: path.Inbound.Actionable.Token,
}, {
  component: Auth.Apple,
  exact: true,
  path: path.Inbound.Apple,
}, {
  component: Auth.Facebook,
  exact: true,
  path: path.Inbound.Facebook,
}, {
  component: Auth.Google,
  exact: true,
  path: path.Inbound.Google,
}, {
  component: Auth.OAuth2Logout,
  exact: true,
  path: path.Inbound.OAuth2Logout,
}, {
  component: Auth.OAuth2,
  exact: true,
  path: path.Inbound.OAuth2,
}, {
  component: ThankYou,
  exact: true,
  path: path.Inbound.Confirmation,
}];

export const authenticatedRoutes: Routing.Route[] = [
  {
    component: AdHocScheduling.Confirmation,
    exact: true,
    path: path.Inbound.Scheduling.AdHoc.Confirmation,
  },
  {
    component: AdHocScheduling.Proposal,
    exact: true,
    path: path.Inbound.Scheduling.AdHoc.Proposal,
  },
  {
    component: Scheduling.Confirmation,
    exact: true,
    path: path.Inbound.Scheduling.Confirmation,
  },
  {
    component: HubSpot,
    exact: true,
    path: path.Inbound.HubSpot,
  },
  {
    component: Scheduling.Invitation,
    exact: true,
    path: path.Inbound.Scheduling.Invitation,
  },
  {
    component: ProjectReport,
    exact: true,
    path: path.Inbound.ProjectReport,
  },
  {
    component: Scheduling.Proposal,
    exact: true,
    path: path.Inbound.Scheduling.Proposal,
  },
  {
    component: SlackConnected,
    exact: true,
    path: path.Inbound.Slack,
  },
  {
    component: Stripe,
    exact: true,
    path: path.Inbound.Stripe,
  },
];

export const anonymousRouter = BasicRouter(anonymousRoutes, {
  auth: RouteAuth.Ignore,
  path: [
    path.Inbound.Actionable.Root,
    path.Inbound.Auth,
    path.Inbound.Confirmation,
  ],
});

export const authenticatedRouter = BasicRouter(authenticatedRoutes, {
  auth: RouteAuth.Authenticated,
  path: [
    path.Inbound.HubSpot,
    path.Inbound.ProjectReport,
    path.Inbound.Scheduling.Root,
    path.Inbound.Slack,
    path.Inbound.Stripe,
  ],
});
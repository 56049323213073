import { useSelectUser } from '@containers/Store';
import { cx } from '@utils';
import { UserAvatar } from 'components/UserAvatar';
import styles from './style/Publisher.Author.css';

type Props = {
  avatarSize?: number;
  classes?: {
    root?:   string;
    avatar?: string;
    name?:   string;
  };
  className?: string;
};

export const Author = (props: Props) => {
  const me = useSelectUser();

  return (
    <div className={cx(styles.root, props.className, props.classes.root)}>
      <UserAvatar
        className={props.classes.avatar}
        size={props.avatarSize}
        pictureUrl={me.profile.pictureUrl} />
      <div className={cx(styles.name, props.classes.name)}>
        {me.profile.fullname}
      </div>
    </div>
  );
};

const defaultProps = {
  avatarSize: 48,
  classes: {},
};

Author.defaultProps = defaultProps;
Author.displayName = 'PostCreation.Publisher.Author';
import * as types from '@store/action-types';
import { files as initialState } from '@store/initial-state';
import {
  ApplicationActions, Files,
} from '@store/interfaces';

type Action =
  ApplicationActions |
  Files.FileCreated.Action |
  Files.FileVersionCreated.Action |
  Files.FileDeleted.Action |
  Files.FileRenamed.Action
;

export function fileIdsReducer(state = initialState.ids, action: Action): Store.Files.Ids {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.files.ids;

    case types.FILE_CREATED:
      return [...state, action.file.id];

    case types.FILE_DELETED:
      return state.filter(fileId => fileId !== action.fileId);

    default:
      return state;
  }
}

export function fileValuesReducer(state = initialState.values, action: Action): Store.Files.Values {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.files.values;

    case types.FILE_CREATED:
    case types.FILE_VERSION_CREATED:
    case types.FILE_RENAMED:
      return {
        ...state,
        [action.file.id]: action.file,
      };

    case types.FILE_DELETED: {
      const { [action.fileId]: _, ...other } = state;

      return other;
    }

    default:
      return state;
  }
}

export function filesReducer(state = initialState, action: Action): Store.Files {
  return {
    ids: fileIdsReducer(state.ids, action),
    values: fileValuesReducer(state.values, action),
  };
}

export default filesReducer;
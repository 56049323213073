import { useContext } from 'react';
import { AggregateFilterDispatchContext } from '@containers/ComplianceReview/Context';
import { ComplianceReview } from '@containers/ComplianceReview/interfaces';

const useAggregateReviewFilterDispatch = () => {
  const dispatch = useContext(AggregateFilterDispatchContext);

  const dispatchFilterChange = (filter: ComplianceReview.AggregateFilter) => {
    dispatch({
      data: { filter },
      type: 'filter',
    });
  };

  return dispatchFilterChange;
};

export { useAggregateReviewFilterDispatch };
export default useAggregateReviewFilterDispatch;
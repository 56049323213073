import { useCallback } from 'react';
import cuid from 'cuid';
import { useSelectUser } from '@containers/Store/hooks';
import { useTranscriptComments, useTranscriptFocusedCommentContext } from '@containers/Transcript';
import { Highlight } from '@/types/transcribe.rich-text';
import { HighlightWithText } from '../interfaces.extension.highlight';
import { UnsavedHighlightColor } from '../utils';
import { useChainedCommands } from './useCommands';
import { useSaveHighlight } from './useSaveHighlight';

export const useAddNewComment = (highlight: HighlightWithText) => {

  const commands = useChainedCommands();
  const [_, dispatch] = useTranscriptComments();
  const [__, setFocusedComment] = useTranscriptFocusedCommentContext();

  const me = useSelectUser();

  const addComment = useCallback((h: Highlight) => {
    const identifier = cuid();
    dispatch({
      type: 'comment-added',
      creator: {
        id: me.id,
        profile: me.profile,
      },
      highlight: {
        id: h.dbId,
        identifier: h.id,
      },
      identifier,
    });
    setFocusedComment(identifier);
  }, [
    dispatch,
    me.id,
    me.profile,
    setFocusedComment,
  ]);

  const saveHighlightAndAddComment = useCallback(() => {
    addComment(highlight);
    commands.blurHighlight().run();
    // if (highlight.dbId) {
    //   addComment(highlight);
    //   commands.blurHighlight().run();
    // } else {
    //   saveHighlight({
    //     highlight: {
    //       ...highlight,
    //       color: DefaultColor,
    //     },
    //   });
    // }

  }, [
    addComment,
    commands,
    highlight,
  ]);

  return saveHighlightAndAddComment;
};
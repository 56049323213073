import styles from './style.css';

const WizardPhases = ({
  phases,
  currentPhase,
}) => {
  return (
    <div className={styles.root}>
      {
        phases.map((phase, index) => {
          const isCurrentPhase = phase.key === currentPhase.key;
          const isComplete = phase.key < currentPhase.key;
          return (
            <div
              key={index}
              className={isCurrentPhase ? styles.inProgress : (isComplete ? styles.completed : styles.incomplete)}>
              <div className={styles.ball} />
              <div className={styles.text}>{phase.title}</div>
            </div>
          );
        })
      }
    </div>
  );
};

export default WizardPhases;

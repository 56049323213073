import { path } from '@consts';
import { ButtonOutlined } from '@presentation';
import { Accordion } from 'components/Accordion';
import { faqs } from './data';
import styles from './style/FAQ.css';

type Props = unknown;

export const FAQ = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>Frequently asked questions</h2>
        {faqs.map(x =>
          <Accordion
            classes={{
              label: styles.label,
              panel: styles.panel,
              value: styles.value,
            }}
            collapsed
            label={x.label}
            key={x.label}
            showCount={false}
            grows={false}>
            <div className={styles.text}>{x.value}</div>
          </Accordion>)}

        {/* <div className={styles.footer}>
          <div className={styles.headline}>
            Want to learn more about how we can help?
          </div>
          <ButtonOutlined
            className={styles.btn}
            color="blue"
            to={path.Website.Demo}>
            Request a Demo
          </ButtonOutlined>
        </div> */}
      </div>
    </div>
  );
};

FAQ.displayName = 'Compliance.FAQ';
import { useState, useCallback, useRef } from 'react';
import { Room, MediaStreamTrackPublishOptions, TwilioError } from 'twilio-video';
import { generateScreenTrackName } from 'components/Conference.Video/constants';

type ErrorCallback = (err: Error) => void;

export function useScreenShareToggle(room: Room, onError: ErrorCallback) {
  const [isAcquiring, setIsAcquiring] = useState<boolean>(false);
  const [isSharing, setIsSharing] = useState<boolean>(false);
  const stopScreenShareRef = useRef<() => void>(null);

  const shareScreen = useCallback(() => {
    if (!isAcquiring) {
      setIsAcquiring(true);
      navigator.mediaDevices
      .getDisplayMedia({
        audio: false,
        video: {
          frameRate: 10,
          height: 1080,
          width: 1920,
        },
      })
      .then(stream => {
        const track = stream.getTracks()[0];
        return room.localParticipant
          .publishTrack(track, {
            name: generateScreenTrackName(),
            priority: 'low',
          } as MediaStreamTrackPublishOptions)
          .then(trackPublication => {
            stopScreenShareRef.current = () => {
              room.localParticipant.unpublishTrack(track);
              // TODO: remove this if the SDK is updated to emit this event
              room.localParticipant.emit('trackUnpublished', trackPublication);
              track.stop();
              setIsSharing(false);
            };

            track.onended = stopScreenShareRef.current;
            setIsSharing(true);
          })
          .catch(onError);
      })
      .catch(err => {
        const error = err as TwilioError;
        if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
          onError(error);
        }
      })
      .finally(() => setIsAcquiring(false));
    }
  }, [room, isAcquiring, onError]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      !isSharing ? shareScreen() : stopScreenShareRef.current();
    }
  }, [isSharing, shareScreen, room]);

  return [isSharing, toggleScreenShare] as const;
}

export default useScreenShareToggle;
import { useCallback, useState } from 'react';
import { ProjectFrequency, ProjectType } from '@enums';
import {
  SurveyQuotasGoalContext,
} from '@containers/SurveyBuilder';
import { ProjectCreationForm } from './interfaces';
import { ProjectFormContext } from './context';

type Props =
  ChildrenProps;

const defaultValue: ProjectCreationForm = {
  currentCompanies: [],
  description: '',
  formerCompanies: [],
  frequency: ProjectFrequency.None,
  goal: 1,
  name: '',
  projectType: ProjectType.Call,
  suggested: [],
  targetCompany: {},
  targetCompleteDate: null,
  titles: [],
  vics: [],
};

export const ProjectCreationFormContainer = ({ children }: Props) => {

  const [form, setForm] = useState(defaultValue);

  const setFormState = useCallback((data: Partial<ProjectCreationForm>) => {
    setForm(s => ({ ...s, ...data }));
  }, []);

  return (
    <SurveyQuotasGoalContext.Provider value={form.goal}>
      <ProjectFormContext.Provider value={[form, setFormState]}>
        {children}
      </ProjectFormContext.Provider>
    </SurveyQuotasGoalContext.Provider>
  );
};

export default ProjectCreationFormContainer;
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { cx } from '@utils';
import RadioButton, { RadioStyles } from './RadioButton';
import { RadioGroupOptionProps } from './interfaces';
import styles from './style.css';

const useStyles = makeStyles({
  root: {
    marginLeft: 0,
  },
  label: {
    fontSize: 16,
    fontFamily: 'var(--font-reg)',
    width: '100%',
  },
});

const useRadioStyles = makeStyles({
  ...RadioStyles,
  root: {
    ...RadioStyles.root,
    marginRight: 10,
  },
});

export default function RadioGroupOption(props: RadioGroupOptionProps) {
  const { palette, theme } = useSurveyThemingPalette();
  const color = theme
      ? palette.primary.main
      : `var(--pri-02)`;
  const classes = useStyles();
  const radioClasses = useRadioStyles({ color });

  return (
    <>
      <FormControlLabel
        className={cx(styles.option, props.className)}
        classes={classes}
        control={<RadioButton classes={radioClasses} />}
        label={(props.isSelected && props.allowText
          ? <input
              className={styles.text}
              name={`${props.value}-text`}
              type="text"
              onChange={props.onInputChange}
              placeholder={`Enter additional text`}
              defaultValue={props.text ?? ''} />
          : props.label)}
        key={props.value}
        value={props.value} />
    </>
  );
}

export { RadioGroupOption };
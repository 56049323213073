import accenture from 'static/images/invite/logo-accenture.svg?url';
import bloomberg from 'static/images/invite/logo-bloomberg.svg?url';
import spotify from 'static/images/invite/logo-spotify.svg?url';
import metlife from 'static/images/invite/logo-metlife.svg?url';
import walmart from 'static/images/invite/logo-walmart.svg?url';
import hubspot from 'static/images/invite/logo-hubspot.svg?url';
import oracle from 'static/images/invite/logo-oracle.svg?url';
import reebok from 'static/images/invite/logo-reebok.svg?url';
import aetna from 'static/images/invite/logo-aetna.svg?url';
import google from 'static/images/invite/logo-google.svg?url';
import styles from './style/ValueProposition.css';

type Props = unknown;

const ValueProposition = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.title}>{content.text.title}</div>
        <div className={styles.main}>
          {content.collection.logos.map(x =>
            <div
              className={styles.item}
              key={x.image.src}>
              <img
                className={styles.img}
                src={x.image.src} />
            </div>)}
        </div>
      </div>
    </div>
  );
};

const content = {
  text: {
    title: `Trusted by 24,000+ Professionals`,
  },
  collection: {
    logos: [
      {
        image : {
          src: accenture,
        },
      }, {
        image : {
          src: bloomberg,
        },
      }, {
        image : {
          src: spotify,
        },
      }, {
        image : {
          src: metlife,
        },
      }, {
        image : {
          src: walmart,
        },
      }, {
        image : {
          src: hubspot,
        },
      }, {
        image : {
          src: oracle,
        },
      }, {
        image : {
          src: reebok,
        },
      }, {
        image : {
          src: aetna,
        },
      }, {
        image : {
          src: google,
        },
      },
    ],
  },
};

export { ValueProposition };
export default ValueProposition;
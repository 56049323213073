import Axios, { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import * as api from '@api/universal-search';
import { GetPast as IGetPast } from '@api/interfaces/universal-search';

type Data = IGetPast.Response;

export default function usePastUniversalSearch<TData = Data>(
  options?: UseQueryOptions<Data, AxiosError, TData>
) {
  const source = Axios.CancelToken.source();

  return useQuery('past-universal-searches', () => {
    const p = api.getPast();

    p.cancel = () => {
      source.cancel();
    };

    return p;
  }, {
    staleTime: 60 * 1000,
    ...options,
  });
}

export { usePastUniversalSearch };
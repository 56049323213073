import {
  EditorComponent,
  Remirror,
} from '@remirror/react';
import { cx } from '@utils';
import { SurveyRichText } from '@/types/survey.rich-text';
import { useSurveyQuestionTextEditor } from './hooks';
import styles from './style/Editor.css';

type Props = {
  className?: string;
  editorClassName?: string;
  value: SurveyRichText.RichTextValue;
};

export const SurveyQuestionText = ({
  className,
  editorClassName,
  value: initialState,
}: Props) => {

  const { manager, state } = useSurveyQuestionTextEditor({
    initialState,
  });

  return (
    <div className={cx(styles.root, className)}>
      <Remirror
        classNames={[styles.editor, editorClassName]}
        editable={false}
        manager={manager}
        initialContent={state}>
        <EditorComponent />
      </Remirror>
    </div>
  );
};
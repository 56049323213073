import { useCallback, useMemo, useState } from 'react';
import { SurveyQuestionType } from '@/enums';
import { MaxDifferenceQuestion } from '@/types';
import { useSurveyFormQuestionContext } from './hooks';
import { MaxDiffFormContext, MaxDiffNavigationContext } from './Context';
import NavigationContainer from './MaxDiff.Navigation';

type SetAnswer = MaxDifferenceQuestion.RespondentAnswer.SetAnswer;

export default function MaxDiffFormContainer({ children }: ChildrenProps) {
  const {
    answer,
    question,
    setAnswer,
  } = useSurveyFormQuestionContext<SurveyQuestionType.MaxDifference>();

  const [currentSet, setCurrentSet] = useState<number>(1);

  const setSetAnswer = useCallback((setNumber: number) => (choice: 'left' | 'right', value: number) => {
    const answerSets = answer?.sets
      ? answer.sets
      : new Array<SetAnswer>(question.settings.sets)
      .fill(null)
      .map<SetAnswer>((_, i) => ({
        left: null,
        right: null,
        options: question.sets[i].options.map(o => o.ordinal),
      }));

    setAnswer({
      sets: answerSets.reduce<SetAnswer[]>((answers, set, i) => {
        if (i+1 !== setNumber) {
          return [...answers, set];
        }

        switch (choice) {
          case 'left': {
            const left = value;
            const right = set.right !== value ? set.right : null;
            const options = set.options;

            return [...answers, { left, right, options }];
          }
          case 'right': {
            const left = set.left !== value ? set.left : null;
            const right = value;
            const options = set.options;

            return [...answers, { left, right, options }];
          }
        }
      }, []),
    });
  }, [answer?.sets, question.sets, question.settings.sets, setAnswer]);

  const back = useCallback(() => {
    return setCurrentSet(s => s - 1);
  }, []);

  const next = useCallback(() => {
    return setCurrentSet(s => s + 1);
  }, []);

  const nextDisabled = useMemo(() => {
    return !answer?.sets[currentSet - 1].left || !answer?.sets[currentSet - 1].right;
  }, [answer?.sets, currentSet]);

  const navigation = {
    back,
    next,
    nextDisabled,
  };

  const form = {
    currentSet,
    setSetAnswer,
  };

  return (
    <MaxDiffFormContext.Provider value={form}>
      <MaxDiffNavigationContext.Provider value={navigation}>
        <NavigationContainer>
          {children}
        </NavigationContainer>
      </MaxDiffNavigationContext.Provider>
    </MaxDiffFormContext.Provider>
  );
}

export { MaxDiffFormContainer };
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useProjectCloningForm, useProjectCloning } from '@containers/GroupProject/hooks';
import { ProjectFrequency, ProjectType, utils as enumUtils } from '@enums';
import { cx } from '@utils';
import { BackButton, Footer, Header, NextButton, Title } from '@screens/ProjectCreation';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { Input } from 'components/Input';
import { GoalInput, ProjectTargetCompletionInput } from 'components/GroupProject';
import { ProjectFrequencyDropdown } from 'components/GroupProject/ProjectFrequencyDropdown';
import { useProjectDescriptionInput } from 'components/GroupProject/hooks/useProjectDescriptionInput';
import styles from './style/ProjectOverview.css';

const frequencies = enumUtils.ProjectFrequency.values()
  .filter(f => f !== ProjectFrequency.None);

export const ProjectOverview = () => {
  const { project, actions } = useProjectCloning();
  const [form, setFormState] = useProjectCloningForm();
  const [ProjectDescriptionInput, validateDescription] = useProjectDescriptionInput();

  const handleChangeName = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    setFormState({ name: target.value });
  }, [setFormState]);

  const handleChangeDescription = useCallback(({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    setFormState({ description: target.value });
  }, [setFormState]);

  const handleChangeGoal = useCallback((value: number) => {
    setFormState({ goal: value  });
  }, [setFormState]);

  const handleChangeFrequency = useCallback((frequency: ProjectFrequency) => {
    setFormState({ frequency  });
  }, [setFormState]);

  const handleTargetCompletionChange = useCallback((value: Date) => {
    setFormState({
      targetCompleteDate: value,
    });
  }, [setFormState]);

  const nextEnabled = useMemo(() => {
    if (!form) {
      return false;
    }

    return form.description &&
      validateDescription(form.description) &&
      !!form.goal &&
      !!form.name;
  }, [
    form,
    validateDescription,
  ]);

  if (!form) {
    return <ActivityIndicator />;
  }

  return (
    <div>

      <Header className={styles.row}>
        <Title className={styles.row}>Overview</Title>
      </Header>

      <div className={cx(styles.row, styles.field)}>
        <div className={styles.header}>
          <div className={styles.title}>Title</div>
        </div>
        <Input
          name="name"
          onChange={handleChangeName}
          placeholder='Enter the industry of your research'
          value={form.name} />
      </div>

      <div className={cx(styles.row, styles.field)}>
        <div className={styles.header}>
          <div className={styles.title}>Description</div>
        </div>
        <ProjectDescriptionInput
          className={styles.description}
          onChange={handleChangeDescription}
          value={form.description} />
      </div>

      <div className={styles.inline}>
        <div className={cx(styles.row, styles.field)}>
          <div className={styles.header}>
            <div className={styles.title}>
              {project.projectType === ProjectType.Survey
                ? 'Total Responses Goal'
                : 'Completed Call Goal'
              }
            </div>
          </div>
          <div className={cx(styles.goal, styles.fieldChild)}>
            <GoalInput
              onChange={handleChangeGoal}
              type={project.projectType}
              value={form.goal} />
          </div>
        </div>

        <div className={cx(styles.row, styles.field)}>
          <div className={styles.header}>
            <div className={styles.title}>Target Completion Date</div>
          </div>
          <div className={styles.fieldChild}>
            <ProjectTargetCompletionInput
              onChange={handleTargetCompletionChange}
              value={form.targetCompleteDate} />
          </div>
        </div>
      </div>

      <div className={cx(styles.row, styles.field)}>
        <div className={styles.header}>
          <div className={styles.title}>Repeat Frequency</div>
        </div>
        <div className={styles.fieldChild}>
          <ProjectFrequencyDropdown
            items={frequencies}
            onSelect={handleChangeFrequency}
            value={form.frequency} />
        </div>
      </div>

      <Footer>
        <BackButton />
        <NextButton
          disabled={!nextEnabled}
          onClick={actions.next} />
      </Footer>
    </div>
  );
};

export default ProjectOverview;
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

type RouteParams = {
  slug: string;
};

const useMatchUserIdFromUrl = () => {
  const params = useParams<RouteParams>();

  return useMemo(() => {
    const [userId] = params.slug.split('-').reverse();

    return Number.isNaN(Number(userId))
      ? null
      : Number(userId);

  }, [params.slug]);

};

export { useMatchUserIdFromUrl };
export default useMatchUserIdFromUrl;
import { useMemo } from 'react';
import * as enums from '@enums';
import { useSurveyBuilderOptions } from '@containers/SurveyBuilder';

export const useIncludeOrdinalColumn = (): boolean => {
  const { actionTypes } = useSurveyBuilderOptions();

  const includeOrdinalColumn = useMemo(() => {
    const valid = [
      enums.SurveyActionType.SkipToQuestion,
      enums.SurveyActionType.SkipToSection,
      enums.SurveyActionType.IncludeSection,
    ];
    return actionTypes.some(s => valid.includes(s));
  }, [actionTypes]);

  return includeOrdinalColumn;
};

export default useIncludeOrdinalColumn;
import { useContext } from 'react';
import { CSVQueriesContext } from '@containers/Group.Contacts.Import/Context';
import { AnimatedLoader } from 'components/ActivityIndicator/AnimatedLoader';
import { CSVFieldMappingTable } from './CSVFieldMapping.Table';
import * as Layout from './Layout';
import styles from './style/CSVFieldMapping.css';

type Props = unknown;

export const CSVFieldMapping = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Layout.Header>Map Fields</Layout.Header>
        <Layout.Subheader>Match the fields from your contact list to fields in Vancery.</Layout.Subheader>
        <div className={styles.main}>
          <CSVFieldMappingContent />
        </div>
      </div>
    </div>
  );
};

CSVFieldMapping.displayName = 'CSVFieldMapping';

const CSVFieldMappingContent = (props: Props) => {
  const { fields } = useContext(CSVQueriesContext);

  if (fields.isLoading) {
    return (
      <div className={styles.indicator}>
        <AnimatedLoader />
      </div>
    );
  }

  const items = fields.data.columns.reduce<TableRow[]>((acc, col) => {
    const unknownvalues = fields.data.rows.reduce((acc2, row, i) => {
      return {
        ...acc2,
        [`row${i + 1}`]: row[col],
      };
    }, {});

    return acc.concat({
      name: col,
      field: null,
      ...unknownvalues,
    });
  }, []);

  const keys = Object.keys(items[0]) as [keyof TableRow];
  const unknownColumns = keys.filter(key => ![`field`, `name`].includes(key)) as UnknownColumn[];

  return (
    <CSVFieldMappingTable
      items={items}
      unknownColumns={unknownColumns} />
  );
};

type UnknownColumn = Exclude<keyof TableRow, 'field' | 'name'>;

type TableRow = {
  name: string;
  field: string;
  [row: `row${number}`]: string;
};
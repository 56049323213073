import { Component } from 'react';
import { cx } from '@utils';
import AnimatedLoader from './AnimatedLoader';
import Spinner from './Spinner';
import styles from './style.css';

type Options = {
  animation?: boolean;
  color?: string;
  position?: 'top';
  size?: number;
}

type Props = {
  className?: string;
  color?: string;
  options?: Options;
  size?: number;
  show?: boolean;
};

class ActivityIndicator extends Component<Props> {
  static defaultProps = {
    options: {},
    show: false,
  }

  static Spinner = Spinner;

  renderAnimatedLoader = ({ color, size }: Partial<Options>) => {
    return (
      <AnimatedLoader
        color={color}
        size={size} />
    );
  }

  renderAnimatedLoaderWithChildren = (options: Options) => {
    if (options.position === 'top') {
      return (
        <>
          {this.renderAnimatedLoader(options)}
          {this.props.children}
        </>
      );
    } else {
      return (
        <>
          {this.props.children}
          {this.renderAnimatedLoader(options)}
        </>
      );
    }
  }

  renderIndicator = () => {
    const { children, options, ...rest } = this.props;

    if (!children) {
      return this.renderAnimatedLoader({ ...options, ...rest });
    } else if (options.animation) {
      return this.renderAnimatedLoaderWithChildren({ ...options, ...rest });
    } else {
      return children;
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div className={cx(styles.root, this.props.className)}>
        {this.renderIndicator()}
      </div>
    );
  }
}

export { ActivityIndicator };
export default ActivityIndicator;
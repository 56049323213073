import { ButtonOutlinedLabeledIcon } from '@presentation/ButtonOutlined.LabeledIcon';
import { cx } from '@utils';
import { Google as GoogleIcon } from 'components/icons';
import styles from './style/AuthButton.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
  onClick?:   () => unknown;
};

export const Google = (props: Props) => {
  return (
    <ButtonOutlinedLabeledIcon
      className={cx(styles.btn, props.className)}
      divider
      icon={<GoogleIcon size={26} />}
      onClick={props.onClick}>
      {props.children}
    </ButtonOutlinedLabeledIcon>
  );
};

Google.displayName = 'AuthButton.Google';
import { useCallback, useState, useMemo } from 'react';
import StripeConsts from '@consts/stripe-countries.json';
import { FormDropdown, FormDropdownOnChange } from '../FormDropdown';
import styles from '../style.css';
import UnitedStates from './UnitedStates';
import { USDProps } from './interfaces';

type Props =
    USDProps;

export function USD(props: Props) {
  const USDCountries = useMemo(() => [
    StripeConsts.Countries.find(c => c.id === 'US'),
  ], []);
  const [country, setCountry] = useState<string>('US');

  const handleCountryChange: FormDropdownOnChange = useCallback(item => {
    setCountry(item.id);
  }, [setCountry]);

  return (
    <div>
      <FormDropdown
        className={styles.row}
        label='Country of Bank Account'
        items={USDCountries}
        selected={USDCountries.find(c => c.id === country)}
        required={true}
        onChange={handleCountryChange} />
      <UnitedStates {...props} />
    </div>
  );
}

export default USD;
import { memo } from 'react';
import { Cell } from 'react-table';
import { ConferenceType } from '@enums';
import { PlayCircle, VideoCall } from 'components/icons';
import ClickContainer from './ResultClickContainer';
import { InteractionsTableItem } from './interfaces';
import styles from './style/Cell.Name.css';

export const Name = memo(({ row: { original: data } }: Cell<InteractionsTableItem>) => {
  const Icon = data.metadata.conferenceType === ConferenceType.Video
    ? <VideoCall className={styles.videoCallIcon} size={26} />
    : <PlayCircle className={styles.voiceCallIcon} size={26} />;

  const prefix = data.metadata.conferenceType === ConferenceType.Video
    ? 'Video Call'
    : 'Call';

  return (
    <ClickContainer item={data} className={styles.overflowWrapper}>
      <div className={styles.root}>
        {Icon}
        <div className={styles.name}>{`${prefix} on ${data.name}`}</div>
      </div>
    </ClickContainer>
  );
});

export default Name;
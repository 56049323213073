import { useContext } from 'react';
import { VideoAdditionalDetailsContext } from '@containers/Discovery/Context'
import { ActivityIndicator } from 'components/ActivityIndicator';
import styles from './style/Tab.Transcript.css';

type Props = unknown;

export const Transcript = (props: Props) => {
  const { transcript } = useContext(VideoAdditionalDetailsContext);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {transcript.query.isLoading &&
            <ActivityIndicator show />}
          {transcript.query.data?.transcript && transcript.query.data.transcript.paragraphs.map((x, i) =>
            <div key={i}>
              <TimestampRange
                end={x.end}
                start={x.start} />
              <div className={styles.paragraph}>
                <div className={styles.sentences}>
                  <div className={styles.sentence}>
                    {x.sentences.map(s => s.value).join(' ')}
                  </div>
                </div>
              </div>
            </div>)}
        </div>
      </div>
    </div>
  );
};

Transcript.displayName = 'Discovery.Post.AdditionalDetails.Tab.Transcript';

type TimestampRangeProps = {
  end:   number;
  start: number;
};

const TimestampRange = (props: TimestampRangeProps) => {
  const start = secondsToTime(props.start);
  const end = secondsToTime(props.end);

  return (
    <div className={styles.time}>
      {`[${start} - ${end}]`}
    </div>
  );
};

TimestampRange.displayName = 'Transcript.TimestampRange';

function secondsToTime(value: number) {
  const time = new Date(value * 1000).toISOString().substr(11, 8);
  return time.startsWith('00') ? time.substr(3) : time;
}
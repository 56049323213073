import { useCallback } from 'react';
import { useSurveyFormNavigationContext } from '@containers/SurveyForm';
import {
  Back,
  CompleteButton,
  NextButton,
  WizardNavigation,
} from 'components/Wizard';

const Navigation = () => {
  const {
    back,
    next,
    showComplete,
  } = useSurveyFormNavigationContext();

  const renderBack = useCallback(() => {
    if (back.hidden) {
      return null;
    }

    const disabled = back.isLoading || next.isLoading;

    return (
      <Back
        disabled={disabled}
        onClick={back.submit} />
    );
  }, [
    back.hidden,
    back.isLoading,
    back.submit,
    next.isLoading,
  ]);

  const renderNext = useCallback(() => {
    const disabled = next.disabled || back.isLoading;

    if (showComplete) {
      return (
        <CompleteButton
          disabled={disabled}
          implicitDisable={next.isLoading}
          loading={next.isLoading}
          onClick={next.submit} />
      );
    } else {
      return (
        <NextButton
          disabled={disabled}
          implicitDisable={next.isLoading}
          loading={next.isLoading}
          onClick={next.submit} />
      );
    }
  }, [
    back.isLoading,
    next.disabled,
    next.isLoading,
    next.submit,
    showComplete,
  ]);

  return (
    <WizardNavigation
      renderBack={renderBack}
      renderNext={renderNext} />
  );
};

export default Navigation;
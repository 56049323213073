import { useMemo } from 'react';
import { SurveyOptionType, SurveyQuestionType } from '@enums';
import { useSurveyResponseGoBack, useSubmitAnswer, useSurveyStepperNavigation, useSurveyFormQuestionContext, useResponseLengthModal } from './hooks';
import { SurveyFormNavigationContext } from './Context';

type Props =
  ChildrenProps;

export const MultiselectFormContainer = (props: Props) => {
  const {
    answer,
    question,
  } = useSurveyFormQuestionContext<SurveyQuestionType.Multiselect>();

  const submitAnswer = useSubmitAnswer();
  const goBack = useSurveyResponseGoBack();
  const navigation = useSurveyStepperNavigation(question.ordinal);

  const canSubmit = useMemo(() => {
    if (!answer?.items) return false;

    const noneOption = question.options.find(f => f.type === SurveyOptionType.NoneOfTheAbove);
    const noneChecked = answer.items.some(s => s.optionId === noneOption?.id);

    if (noneChecked) return true;

    const otherOption = question.options.find(f => f.type === SurveyOptionType.Other);
    const otherAnswer = answer.items.find(s => s.optionId === otherOption?.id);

    if (otherAnswer && !otherAnswer.text) return false;

    if (question.settings.maximumSelections &&
      answer.items.length > question.settings.maximumSelections) {
      return false;
    }
    if (question.settings.minimumSelections &&
      answer.items.length < question.settings.minimumSelections) {
      return false;
    }

    return true;
  }, [
    answer,
    question.options,
    question.settings,
  ]);

  const value = {
    back: {
      hidden: !navigation.showBack,
      isError: goBack.isError,
      isLoading: goBack.isLoading,
      submit: goBack.mutateAsync as () => Promise<unknown>,
    },
    next: {
      disabled: !canSubmit,
      isError: submitAnswer.isError,
      isLoading: submitAnswer.isLoading,
      submit: submitAnswer.mutateAsync as () => Promise<unknown>,
    },
    showComplete: navigation.showComplete,
  };

  return (
    <SurveyFormNavigationContext.Provider value={value}>
      {props.children}
    </SurveyFormNavigationContext.Provider>
  );
};

export default MultiselectFormContainer;
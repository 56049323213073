import { SurveyQuestionType } from '@enums';
import { SurveyVersion } from '@/types';
import {
  SurveyBuilderState,
} from './SurveyBuilderState';
import { SurveyBuilderOptionsContext } from './Context';

type Props = {
  initialValue?: SurveyVersion;
} & ChildrenProps;

const questionTypes = [
  SurveyQuestionType.ShortTextResponse,
  SurveyQuestionType.LongTextResponse,
  SurveyQuestionType.MultipleChoice,
  SurveyQuestionType.Multiselect,
];

export const PostCallSurveyBuilderState = (props: Props) => {

  const details = {
    actionTypes: [],
    maxQuestions: 10,
    questionTypes,
  };

  return (
    <SurveyBuilderOptionsContext.Provider value={details}>
      <SurveyBuilderState initialValue={props.initialValue}>
        {props.children}
      </SurveyBuilderState>
    </SurveyBuilderOptionsContext.Provider>
  );
};

export default PostCallSurveyBuilderState;
import { isValid, parseJSON } from 'date-fns';

// https://github.com/date-fns/date-fns/pull/1463#issuecomment-551228882
export function safeJsonDate(value: string | number): Date {
  return value ? parseJSON(value) : null;
}

type NullableDate<T = unknown> =
  T extends Date
    ? Date
    : T extends null
      ? null
      : string | number;

export function nullableDate<T extends NullableDate>(value: NullableDate<T>) {
  const date = safeJsonDate(value);

  return isValid(date)
    ? date
    : null;
}

export * from './date.age';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { ActiveSliderTrack, InactiveSliderTrack, SliderThumb } from '@presentation/Slider';
import { color, cx } from '@utils';
import { useSlider } from 'components/Slider/hooks/useSlider';
import { MatrixSliderProps } from './interfaces';
import styles from './style/MatrixSlider.css';

export const MatrixSlider = (props: MatrixSliderProps) => {
  const [initialized, setInitialized] = useState(hasValue(props.value));
  const [hover, setHover] = useState(false);

  const { onPositionChange } = props;

  const handlePositionChange = useCallback((position: number) => {
    if (onPositionChange) {
      onPositionChange(position);
    }
  }, [onPositionChange]);

  const {
    activeTrackStyles,
    handleMouseDown,
    handleTouchStart,
    thumbStyles,
    isDragging,
    sliderRef,
    thumbRef,
  } = useSlider({
    ...props,
    onPositionChange: handlePositionChange,
    value: props.value,
  });

  useEffect(() => {
    if (!initialized && hasValue(props.value)) {
      setInitialized(true);
    }
  }, [props.value, initialized]);

  const { palette, theme } = useSurveyThemingPalette();

  const thumb = useMemo(() => {
    return {
      classes: cx(styles.thumb, { [styles.uninitialized]: !initialized }),
      style: theme ? {
        ...thumbStyles,
        backgroundColor: initialized && palette.primary.main,
      } : thumbStyles,
    };
  }, [
    initialized,
    palette,
    theme,
    thumbStyles,
  ]);

  const track = useMemo(() => {
    return {
      classes: cx(styles.activeTrack, { [styles.uninitialized]: !initialized }),
      style: theme ? {
        ...activeTrackStyles,
        backgroundColor: initialized && palette.primary.light,
      } : activeTrackStyles,
    };
  }, [
    activeTrackStyles,
    initialized,
    palette,
    theme,
  ]);

  const onMouseOver = useCallback(() => {
    setHover(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div
      className={cx(styles.root, props.className)}
      ref={sliderRef}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}>
      <ActiveSliderTrack
        className={track.classes}
        style={track.style} />
      <InactiveSliderTrack className={styles.inactiveTrack} />
      <SliderThumb
        className={thumb.classes}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        ref={thumbRef}
        style={thumb.style} />
    </div>
  );
};

function hasValue(value: number) {
  return !!value || value === 0;
}

export default MatrixSlider;
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useProjectOrLatestChild, useProjectState  } from '@containers/GroupProject/hooks';
import { useSelectContact } from '@containers/Store';
import { useHasComplianceRole } from '@containers/User/hooks';
import * as enums from '@enums';
import { CreatedByWhenLabel } from '@presentation';
import * as selectors from '@store/selectors';
import { HomeBreadcrumb } from 'components/Workspace/WorkspaceBreadcrumb';
import { WorkspaceBreadcrumbPath } from 'components/Workspace/ObjectPath';
import ProjectVersion from './ProjectVersion';
import styles from './style/ProjectHeader.css';

type Props = {
  children: React.ReactNode;
};

const selectGroupFeatures = (state: Store.State) => state.group.features;

const ProjectHeader = (props: Props) => {
  const project = useProjectOrLatestChild();
  const { projectParent, latestProject } = useProjectState();
  const creator = useSelectContact(project.creatorId);
  const childProjects = useSelector(selectors.selectProjectsForParent(projectParent.id));
  const path = useSelector(selectors.selectObjectPath(projectParent.objectId));
  const groupFeatures = useSelector(selectGroupFeatures);
  const hasComplianceRole = useHasComplianceRole();

  const showVersion = useMemo(() => {
    return projectParent.frequency !== enums.ProjectFrequency.None &&
      childProjects.length > 1;
  }, [
    childProjects.length,
    projectParent.frequency,
  ]);

  const renderBreadcrumbs = useCallback(() => {
    if (!groupFeatures.workspaces || hasComplianceRole) {
      return <HomeBreadcrumb />;
    }

    return (
      <WorkspaceBreadcrumbPath
        items={path} />
    );
  }, [
    groupFeatures,
    hasComplianceRole,
    path,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.breadcrumbs}>
        {renderBreadcrumbs()}
      </div>
      <div className={styles.details}>
        <div className={styles.info}>
          <h1 className={styles.title}>{latestProject.name}</h1>
          <div className={styles.subtitle}>
            {showVersion &&
              <ProjectVersion
                className={styles.version} />}
            <CreatedByWhenLabel
              className={styles.age}
              by={creator.profile.fullname}
              when={`on ${format(project.createdOn, 'M/d/yyyy')}`} />
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default ProjectHeader;
import { cx } from '@utils';
import { usePageContent } from '$website/containers';
import styles from './style/Benefits.css';
import Section from './Section';

const Benefits = () => {
  const content = usePageContent<'main', 'benefits'>('benefits');

  const items = content.collection.benefits;

  return (
    <Section>
      <div className={styles.wrap}>
        {content.text?.title &&
          <h2 className={styles.headline}>{content.text.title}</h2>}

        <div className={styles.main}>
          {items.map(x =>
            <div
              className={styles.benefit}
              key={x.text.copy}>
              <div className={styles.icon}>
                <img
                  alt={x.text.title.toLowerCase()}
                  className={cx(styles.img)}
                  src={x.image.icon} />
              </div>
              <h3 className={styles.caption}>{x.text.title}</h3>
              <div className={styles.copy}>{x.text.copy}</div>
            </div>)}
        </div>
      </div>
    </Section>
  );
};

export { Benefits };
export default Benefits;
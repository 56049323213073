import * as consts from '@consts';
import {
  QuestionsScreen,
  RulesScreen,
  TestScreen,
} from '@screens/ComplianceOnboarding';

const routes = [
  {
    component: RulesScreen,
    exact: true,
    path: consts.path.Onboarding.ComplianceTerms,
  },
  {
    component: TestScreen,
    exact: true,
    path: consts.path.Onboarding.ComplianceTest,
  },
  {
    component: QuestionsScreen,
    exact: true,
    path: consts.path.Onboarding.ComplianceQuestions,
  },
];

export default routes;
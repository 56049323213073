import { useCallback } from 'react';
import * as api from '@api';
import { useSelectObject } from '@containers/Store';
import { BaseObjectOwnershipContainer } from './BaseOwnershipContainer';
import { useSaveObjectOwnership } from './hooks';

type Props =
  IWorkspaceObjectId &
  IWorkspaceId &
  ChildrenProps

export const ObjectOwnershipContainer = (props: Props) => {
  const object = useSelectObject(props.objectId);
  const saveOwnership = useSaveObjectOwnership();

  const handleSave = useCallback((ownerId: number) => {
    return saveOwnership({
      objectId: props.objectId,
      ownerId,
      workspaceId: props.workspaceId,
    });
  }, [
    props.objectId,
    props.workspaceId,
    saveOwnership,
  ]);

  const fetchUsers = useCallback(() => {
    return api.groups.getGroupUsers({
      groupId: object.groupId,
    }).then(data => data.items);
  }, [
    object.groupId,
  ]);

  const containerProps = {
    fetchUsers,
    object,
    onSave: handleSave,
  };

  return (
    <BaseObjectOwnershipContainer {...containerProps}>
      {props.children}
    </BaseObjectOwnershipContainer>
  );
};

export default ObjectOwnershipContainer;
import { Component } from 'react';
import { cx } from '@utils';
import Input from 'components/Input';
import AutoCompleteTags from './Tags.js';
import AutoCompleteMulti from './Multi.js';
import styles from './style.css';

class AutoComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      index: -1,
      visible: false,
    };
  }

  static defaultProps = {
    className: '',
    items: [],
  }

  static Tags = AutoCompleteTags
  static Multi = AutoCompleteMulti

  getItemFromList = index => {
    return this.props.items[index];
  }

  getResolvedSelection = () => {
    const inputValue = this.props.value.trim();
    const match = this.props.items.find(x => this.getValueFromItem(x) === inputValue);

    if (match) {
      return match;
    }

    if (this.getValueFromItem(this.props.item) === inputValue) {
      return this.props.item;
    }

    return { name: inputValue };
  }

  getValueFromItem = item => {
    return (this.props.getItemValue(item) || '').trim();
  }

  getVisibilityStyles = () => {
    return this.props.items.length && this.state.visible
      ? `${styles.list} ${styles.open}`
      : styles.list;
  }

  handleBlur = e => {
    if (this.props.onBlur) {
      const selection = this.props.item && this.getResolvedSelection();
      this.props.onBlur(e, selection);
    }

    if (!this.state.id && this.props.item) {
      const selection = this.getResolvedSelection();

      this.handleSelect(selection);
    }

    if (this.state.visible) {
      this.toggleVisibility();
    }
  }

  handleChange = e => {
    if (!this.state.visible) {
      this.toggleVisibility();
    }

    this.setState({
      id: '',
      index: -1,
      value: e.target.value,
    });

    this.props.onChange(e);
  }

  handleKeyDown = e => {
    if (!this.props.items.length) {
      return;
    }

    const last = this.props.items.length - 1;

    if (e.key === 'ArrowDown') {
      const next = this.state.index + 1;
      const index = next > last ? 0 : next;
      this.updateActiveSelection(index);
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      const next = this.state.index - 1;
      const index = this.state.index <= 0 ? last : next;
      this.updateActiveSelection(index);
    }
  }

  handleSelect = item => {
    if (this.state.visible) {
      this.toggleVisibility();
    }

    this.props.onSelect(item);

    this.setState({
      id: item.id || '',
      index: -1,
      value: this.getValueFromItem(item),
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const { index } = this.state;

    if (index > -1) {
      const selection = this.getItemFromList(index);

      this.handleSelect(selection);
    } else {
      if (this.props.onSubmit) {
        this.props.onSubmit(this.props.value);
      }
    }
  }

  renderItem = (item, index) => {
    const handleMouseDown = () => {
      const selection = this.getItemFromList(index);

      this.handleSelect(selection);
    };

    return (
      <div
        className={this.state.index === index ? styles.highlight : styles.item}
        key={item.id}
        onMouseDown={handleMouseDown}>
        {this.getValueFromItem(item)}
      </div>
    );
  }

  updateActiveSelection = index => {
    const selection = this.getItemFromList(index);

    this.setState({
      id: selection.id || '',
      index,
    });
  }

  toggleVisibility = () => {
    this.setState({ visible: !this.state.visible });
  }

  handleFocus = e => {
    this.toggleVisibility();

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Input
          autoComplete="off"
          autoCorrect={this.props.autoCorrect}
          autoFocus={this.props.autoFocus}
          className={cx(styles.input, this.props.className)}
          name={this.props.name}
          placeholder={this.props.placeholder}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          readOnly={this.props.readOnly}
          ref={this.props.refProp}
          type="text"
          value={this.props.value} />
        <div className={styles.results}>
          <div className={this.getVisibilityStyles()}>
            {this.props.items.map(this.renderItem)}
          </div>
        </div>
      </form>
    );
  }
}

export default AutoComplete;
import { useState, useCallback } from 'react';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { ReportSocketContainer, useReportKey } from '@containers/Reports';
import { useModal } from 'components/Modal/hooks';
import { Modal, ModalProps } from 'components/Modal/Modal';
import { Header } from 'components/Modal/Header';
import { DropDown } from 'components/DropDown';
import Toast from 'components/Toast';
import { useDownloadCallTranscript } from './hooks';
import styles from './style/TranscriptDownloadModal.css';

type Props = {
  transcriptId: number;
} &
  ICallId &
  Pick<ModalProps, 'open' | 'onClose'>;

type FileFormat = 'pdf' | 'docx';

type DropdownItem = {
  id: FileFormat;
  value: string;
};

export const TranscriptDownloadModal = (props: Props) => {
  return (
    <ReportSocketContainer onDownload={props.onClose}>
      <ModalContent {...props} />
    </ReportSocketContainer>
  );
};

const ModalContent = (props: Props) => {
  const [format, setFormat] = useState<FileFormat>();
  const [reportKey, setReportKey] = useReportKey();

  const dropdownItems: DropdownItem[] = [
    {
      id: 'pdf',
      value: 'PDF',
    }, {
      id: 'docx',
      value: 'DOCX',
    },
  ];

  const { isFetching, refetch: download } = useDownloadCallTranscript({
    callId: props.callId,
    format,
    transcriptId: props.transcriptId,
  } , {
    onSuccess: r => setReportKey(r.key),
    onError: () => {
      Toast.error({
        title: 'Error',
        body: 'There was an error downloading the transcript.',
      });
    },
  });

  const handleSubmit = useCallback(() => {
    download();
  }, [download]);

  return (
    <Modal {...props}>
      <Header text='Select transcript file format' />
      <div className={styles.dropdown}>
        <DropDown<DropdownItem>
          items={dropdownItems}
          onSelect={i => setFormat(i.id)}
          text={format?.toLocaleUpperCase() || 'Choose Format'}
          getItemValue={(item: DropdownItem) => item.value} />
      </div>
      <div className={styles.btns}>
        <ButtonActivityIndicator
          className={styles.btn}
          disabled={!format}
          onClick={handleSubmit}
          loading={isFetching || !!reportKey}>
          Download
        </ButtonActivityIndicator>
      </div>
    </Modal>
  );
};

export const useTranscriptDownloadModal = () => useModal(TranscriptDownloadModal);
import { BaseLogic} from './interfaces';

export function baseActionReducer(state: BaseLogic.State, action: BaseLogic.Action) {
  switch (action.type) {
    case 'update-base-action': {
      return {
        action: action.action,
      };
    }

    default:
      return state;
  }
}
import { useContext } from 'react';
import { DeleteArticleMutationContext } from '@containers/Post.Article/Context';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { Body } from 'components/Modal/Body';
import { ButtonSet } from 'components/Modal/ButtonSet';
import { Modal, ModalProps } from 'components/Modal/Modal';
import styles from './style/DeleteModal.css';

type Props =
  Pick<ModalProps,
  | 'onClose'
  | 'open'>;

export const DeleteModal = (props: Props) => {
  const mutation = useContext(DeleteArticleMutationContext);

  return (
    <Modal
      className={styles.root}
      disableEscapeClose={mutation.isLoading}
      disableOverlayClick={mutation.isLoading}
      hideCloseIcon
      open={props.open}
      onClose={props.onClose}>
      <Body className={styles.body}>
        <div className={styles.message}>{copy.message}</div>
      </Body>
      <ButtonSet className={styles.btns}>
        <ButtonOutlined
          borderWidth="thin"
          className={styles.btn}
          disabled={mutation.isLoading}
          color="silver"
          onClick={props.onClose}>
          Cancel
        </ButtonOutlined>
        <ButtonActivityIndicator
          className={styles.btn}
          color="destructive"
          indicatorSize={12}
          loading={mutation.isLoading}
          onClick={mutation.mutate}>
          Confirm
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

DeleteModal.displayName = 'Post.Article.DeleteModal';

const copy = {
  message: `Are you sure you want to delete this post?`,
};
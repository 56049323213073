import { useContext } from 'react';
import { ProjectResponseFilterContext } from '@containers/GroupProject/Context';
import { ProjectSurveyResponsesContext } from '@containers/SurveyResponses/Context';
import { getFilteredUserIds } from '@containers/GroupProject/utils/survey-filtering';

export const useFilteredResponseUsers = () => {
  const filterContext = useContext(ProjectResponseFilterContext);
  const responses = useContext(ProjectSurveyResponsesContext);

  let userIds: number[];
  if (filterContext?.filter && responses) {
    userIds = getFilteredUserIds(filterContext.filter, filterContext.questionData, responses);
  }

  return userIds;
};
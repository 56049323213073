import { memo } from 'react';
import { cx } from '@utils';
import { MaybeLink } from 'components/MaybeLink';
import User from 'components/icons/User';
import { UserAvatarProps } from './interfaces';
import styles from './style.css';

type Props =
  Omit<UserAvatarProps, 'pictureUrl' | 'resize'>;

export const DefaultAvatar = memo(({ className, onClick, size, to }: Props) => {
  return (
    <MaybeLink
      className={styles.defaultLink}
      to={to}>
      <User
        className={cx(styles.default, className)}
        onClick={onClick}
        size={size} />
    </MaybeLink>
  );
});

DefaultAvatar.displayName = 'DefaultAvatar';

export default DefaultAvatar;
import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState, useGetQuestionRawText } from '@containers/SurveyBuilder/hooks';
import { SurveyQuota, SurveyQuestion } from '@/types/survey';
import { useMeasure } from '@utils/hooks';
import styles from './style/Preview.Advanced.Table.css';

type ContainerProps = {
  quota: SurveyQuota.Advanced.Record;
};

type Data = {
  pct: {
    display: number;
    value: number;
  };
};

type TableItem<T> = {
  children: TableItem<T>[];
  data: T;
  question: Pick<SurveyQuestion, 'ordinal' | 'value'>;
  option: {
    value: string;
  };
};

export const AdvancedQuotaTableContainer = ({ quota }: ContainerProps) => {
  const [state] = useSurveyBuilderState();

  const traverse = useCallback((setIndex: number, pct: number): TableItem<Data>[] => {
    const set = quota.data.sets[setIndex];

    if (!set) return [];

    const question = state.survey.questions.find(f => f.base.identifier === set.question.identifier);

    return set.items.map(item => {
      const option = question.options.find(f => f.base.identifier === item.condition.value.option.identifier);

      return ({
        children: traverse(setIndex + 1, (item.pct / 100) * pct),
        data: {
          pct: {
            display: item.pct,
            value: (item.pct / 100) * pct,
          },
        },
        option,
        question,
      });
    });

  }, [
    quota.data.sets,
    state.survey.questions,
  ]);

  const items = useMemo(() => {
    const firstSet = quota.data.sets[0];
    const question = state.survey.questions.find(f => f.base.identifier === firstSet.question.identifier);

    return firstSet.items.map(item => {
      const option = question.options.find(f => f.base.identifier === item.condition.value.option.identifier);

      return ({
        children: traverse(1, item.pct / 100),
        data: {
          pct: {
            display: item.pct,
            value: item.pct / 100,
          },
        },
        question,
        option,
      });

    });
  }, [
    quota.data.sets,
    state.survey.questions,
    traverse,
  ]);

  const renderLimitCell = useCallback((data: Data) => {
    const limit = Math.ceil(data.pct.value * quota.limit);

    return (
      <>
        <div className={styles.pct}>{data.pct.display}%</div>
        ({limit})
      </>
    );
  }, [quota.limit]);

  return (
    <AdvancedQuotaTable
      items={items}
      numberOfSets={quota.data.sets.length}
      renderLimitCell={renderLimitCell} />
  );
};

type Props<T> = {
  items: TableItem<T>[];
  numberOfSets: number;
  renderLimitCell: (item: T) => React.ReactNode;
};

export const AdvancedQuotaTable = <T extends unknown>({ items, numberOfSets, renderLimitCell }: Props<T>) => {

  const [rootRef, bounds] = useMeasure<HTMLDivElement>();
  const getQuestionText = useGetQuestionRawText();

  const renderItem = useCallback((item: TableItem<T>, index: number, cohortCount: number, previousBottom: boolean) => {
    const isLastSet = !item.children.length;

    const limitWidth = isLastSet ? 50 : 49;
    const limitStyle = {
      width: limitWidth - 1,
    };
    const comboStyle = {
      height: `${100 / cohortCount}%`,
    };

    const parentWidth = bounds.width - 2;
    const width = (parentWidth / numberOfSets) - limitWidth;

    const detailsStyle = {
      minWidth: `${width}px`,
      maxWidth: `${width}px`,
    };
    const isBottom = previousBottom && index + 1 === cohortCount;
    const cellStyle = {
      borderBottom: isBottom
        ? '1px solid transparent'
        : '1px solid var(--gray-l)',
      borderRight: !isLastSet && '1px solid var(--gray-l)',
    };

    const questionText = getQuestionText(item.question.value);

    return (
      <div key={index} className={styles.combo} style={comboStyle}>
        <div className={styles.cell} style={cellStyle}>
          <div className={styles.details} style={detailsStyle}>
            <div className={styles.question}>
              <div className={styles.ordinal}>Q{item.question.ordinal}:</div> {questionText}
            </div>
            <div className={styles.answer}>
              <div className={styles.ordinal} /> {item.option.value}
            </div>
          </div>
          <div className={styles.limit} style={limitStyle}>
            {renderLimitCell(item.data)}
          </div>
        </div>
        <div className={styles.children}>
          {item.children.map((c, i) => renderItem(c, i, item.children.length, isBottom))}
        </div>
      </div>
    );
  }, [
    bounds.width,
    getQuestionText,
    numberOfSets,
    renderLimitCell,
  ]);

  return (
    <div className={styles.root} ref={rootRef}>
      {items.map((item, i) => renderItem(item, i, items.length, i === items.length - 1))}
    </div>
  );
};
import { cx } from '@utils';
import Pencil from 'components/icons/Pencil';
import styles from './style.css';

type Props = {
  className?: string;
  onClick?: () => void;
  size?: number;
};

const Edit = (props: Props) => {
  return (
    <div
      className={cx(styles.root, props.className)}
      onClick={props.onClick}
      style={{
        height: props.size || 24,
        width: props.size || 24,
      }}>
      <Pencil
        className={styles.pencil}
        size={props.size} />
    </div>
  );
};

export { Edit };
export default Edit;
import { useContext } from 'react';
import { Select } from 'components/Select';
import { FormDerivedEditStateContext, FormStateContext } from './Context';
import { TextDisplayField } from './TextField';

type Props<T = unknown> = {
  className?: string;
  helpers:    {
    getName: (x: T) => string;
    values:  () => Array<string | number>;
  };
  name:       string;
  value:      T;
};

const SelectField = (props: Props) => {
  const editing = useContext(FormDerivedEditStateContext);

  return editing
    ? <SelectInputField
        className={props.className}
        helpers={props.helpers}
        name={props.name} />
    : <SelectDisplayField
        className={props.className}
        helpers={props.helpers}
        value={props.value} />;
};

export const SelectDisplayField = (props: Omit<Props, 'name'>) => {
  const value = props.value === undefined || props.value === null
      ? 'Unknown'
      :  props.helpers.getName(props.value);

  return (
    <TextDisplayField
      className={props.className}
      value={value} />
  );
};

export const SelectInputField = (props: Omit<Props, 'value'>) => {
  const [state, dispatch] = useContext(FormStateContext);

  const options = props.helpers.values();

  const handleInputEvent = (item: typeof options[number]) => {
    dispatch({
      type: 'value',
      key: props.name as keyof typeof state.values,
      value: item,
    });
  };

  return (
    <div className={props.className}>
      <Select
        getItemValue={x => props.helpers.getName(x)}
        onSelect={handleInputEvent}
        options={options}
        value={props.helpers.getName(state.values[props.name])} />
    </div>
  );
};

export { SelectField };
export default SelectField;
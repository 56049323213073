import { useMemo } from 'react';
import { useNumberTableWarnings } from '@containers/SurveyForm/Context';
import { SurveyQuestionFormMatrixRow } from '@/types';
import { NumberInputTable } from './interfaces';
import NumberInput from './NumberInputTable.Input';
import styles from './style/NumberInputTable.Grid.css';

type MeasurementProps = {
  matrixRows: SurveyQuestionFormMatrixRow[];
  numOptions: number;
  maxCellWidth: number;
  nameWidthPct: number;
};

export const useNumberInputTableMeasurements = ({ maxCellWidth: mcw, nameWidthPct, ...item }: MeasurementProps) => {
  const cellWidth = useMemo(() => {
    const width = (100 - nameWidthPct) / item.numOptions;
    return `${width}%`;
  }, [
    item.numOptions,
    nameWidthPct,
  ]);

  const maxCellWidth = useMemo(() => {
    return `${mcw * item.matrixRows.length}px`;
  }, [
    item.matrixRows.length,
    mcw,
  ]);

  const firstCellStyles = useMemo(() => {
    const maxRowLength = Math.max(...item.matrixRows.map(m => m.value.length));
    const maxWidthPct = Math.max(Math.min(maxRowLength, 50), 20);
    return {
      maxWidth: `${maxWidthPct}%`,
      width: `${nameWidthPct}%`,
    };
  }, [
    item.matrixRows,
    nameWidthPct,
  ]);

  return {
    cellWidth,
    maxCellWidth,
    firstCellStyles,
  } as const;
};

export const NumberInputTableGrid = ({ answerMap, handleChange, item, totalsMap, from, to, maxOptions }: NumberInputTable.GridProps) => {

  const [warnings] = useNumberTableWarnings();

  const options = useMemo(() => {
    return item.options.slice(from, to);
  }, [
    item.options,
    from,
    to,
  ]);

  const {
    cellWidth,
    maxCellWidth,
    firstCellStyles,
  } = useNumberInputTableMeasurements({
    matrixRows: item.matrixRows,
    numOptions: maxOptions,
    maxCellWidth: 180,
    nameWidthPct: 20,
  });

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div
          className={styles.rowName}
          style={firstCellStyles} />
        {options.map(option => (
          <div
            key={option.ordinal}
            style={{
              width: cellWidth,
              maxWidth: maxCellWidth,
            }}
            className={styles.cell}>
            {option.value}
          </div>
        ))}
      </div>
      <div>
        {item.matrixRows.map(row => (
          <div
            key={row.id}
            className={styles.row}>
            <div
              className={styles.rowName}
              style={firstCellStyles}>
              {row.value}
            </div>
            {options.map(option => (
              <div
                key={option.ordinal}
                style={{ width: cellWidth }}
                className={styles.cell}>
                <div className={styles.input}>
                  <NumberInput
                    invalid={warnings[row.id][option.id]}
                    validations={item.settings.validations}
                    onChange={handleChange(row.id, option.id)}
                    value={answerMap[row.id][option.id]} />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {item.settings.displayTotals &&
        <div className={styles.footer}>
          <div
            className={styles.rowName}
            style={firstCellStyles}>
            Total
          </div>
          {options.map(option => (
            <div
              key={option.ordinal}
              style={{ width: cellWidth }}
              className={styles.cell}>
              {totalsMap[option.id]}
            </div>
          ))}
        </div>
      }
    </div>
  );

};

export default NumberInputTableGrid;
import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import * as api from '@api';
import * as Calls from '@api/interfaces/calls';

type Props = {
  transcriptId: number;
} & ICallId;

export const useCallTranscriptionHighlights = (props: Props, options: Options = {}) => {

  const query = useQuery(['call-transcript-highlights', props], ({ queryKey }) => {
    const params = queryKey[1] as Props;
    return api.calls.getTranscriptHighlights(params)
      .then(res => res.highlights);
  }, {
    ...options,
    placeholderData: [],
  });

  return query;
};

type Data = Calls.GetTranscriptHighlights.Response['highlights'];
type QK = [string, Props];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;
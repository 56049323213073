import { useAccountProfilePopulationState, useAccountProfileEditState, useProfileState, useAccountProfileNewEntry } from '@containers';
import { Header, Subtitle, Title, BackButton, Footer, NextButton } from '@screens/ProjectCreation';
import { ProfileEntry } from '@enums';
import { cx } from '@utils';
import { AddProfileEntryButton, ProfileSectionEntries } from 'components/UserProfile';
import { AnimatedLoader } from 'components/ActivityIndicator';
import { EmploymentSetupForm } from './EmploymentSetup.Form';
import { EmploymentSetupHeader } from './EmploymentSetup.Header';
import { ScreenNavigationProps } from './interfaces';
import styles from './style/AccountProfileSetup.css';

type Props = {
  subtitle?: string;
  title?:    string;
} & ScreenNavigationProps;

const EmploymentSetup = (props: Props) => {
  const populated = useAccountProfilePopulationState();
  const [state] = useProfileState();
  const [entryId] = useAccountProfileEditState();
  const [_, addNewEntry] = useAccountProfileNewEntry(ProfileEntry.Employment);

  const { subtitle, title } = populated
      ? copy.populated
      : props;

  return (
    <div className={styles.root}>
      <Header className={styles.header}>
        <Title className={styles.title}>{title}</Title>
        <Subtitle className={cx(styles.subtitle, { [styles.extended]: populated })}>{subtitle}</Subtitle>
      </Header>

      {!state?.employment &&
        <AnimatedLoader
          className={styles.loader}
          color="var(--sec-01)" />}

      {state?.employment &&
        <div className={styles.main}>
          <ProfileSectionEntries
            entryClassName={styles.sectionEntry}
            type={ProfileEntry.Employment} />
          <AddProfileEntryButton
            className={styles.create}
            onClick={addNewEntry}>
            Add Position
          </AddProfileEntryButton>
        </div>}

      <Footer className={styles.footer}>
        <BackButton onClick={props.navigation.back} />
        <NextButton
          className={styles.btn}
          color="primary"
          disabled={!!entryId || !state?.employment?.length}
          onClick={props.navigation.onClick}
          replace={props.navigation.replace}
          to={props.navigation.to}
          variant="brick">
          Next
        </NextButton>
      </Footer>
    </div>
  );
};

const copy = {
  populated: {
    title: `Great! Below is your work history from Linkedin.`,
    subtitle: `Please update your professional work history below to reflect your current and recent employments. Thorough and up to date profiles allow us to recommend you for projects!`,
  },
};

const defaultProps = {
  title: `Work Experience`,
  subtitle: `Please provide your work experiences so that clients can view your background.`,
};

EmploymentSetup.defaultProps = defaultProps;

EmploymentSetup.Form = EmploymentSetupForm;
EmploymentSetup.Header = EmploymentSetupHeader;

export { EmploymentSetup };
export default EmploymentSetup;
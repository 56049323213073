import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Personal } from 'components/Calendar';
import styles from './style.css';

type Props = unknown;

const PersonalCalendarResponsive = (props: Props) => {
  const history = useHistory();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <button
            className={styles.back}
            onClick={history.goBack}>
            <ArrowBackIcon className={styles.arrow} />
          </button>
          <div className={styles.title}>Availability</div>
        </div>
        <Personal className={styles.calendar} />
      </div>
    </div>
  );
};

export { PersonalCalendarResponsive };
export default PersonalCalendarResponsive;
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { WorkspaceObjectCapabilities } from '@/types';
import { WorkspaceObjectType } from 'enums';

export const selectFolderCapabilities = (state: Store.State) => (folderId: number) => {
  const { objectId } = state.folders.values[folderId];
  const capabilities = state.objects.capabilities[objectId];

  return capabilities as WorkspaceObjectCapabilities<WorkspaceObjectType.Folder>;
};

export const useGetFolderCapabilities = () => {
  const getCapabilities = useSelector(selectFolderCapabilities);

  return useCallback(
    (folderId: number) => getCapabilities(folderId),
    [getCapabilities],
  );
};

export const useFolderCapabilities = (folderId: number) => {
  const getCapabilities = useGetFolderCapabilities();
  return getCapabilities(folderId);
};
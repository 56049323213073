import { useMaxDiffSurveyResponses } from '@containers/SurveyResponses/hooks/useSurveyResponsesContext';
import Option from './MaxDiff.Data.Option';
import styles from './style/MaxDiff.Data.css';

export default function MaxDiffData() {
  const {
    onOptionClick,
    onReset,
    query,
    question,
    responses,
    sortedOptions,
  } = useMaxDiffSurveyResponses();

  return (
    <div className={styles.root}>
      {sortedOptions.map(option => {
        return (
          <Option
            key={option.ordinal}
            activeOptionId={query.optionId}
            activeChoiceId={query.key}
            option={option}
            response={responses.options[option.ordinal]}
            settings={question.settings}
            onClick={onOptionClick(option.base.id)}
            onReset={onReset} />
        );
      })}
    </div>
  );
}

export { MaxDiffData };
import { useMemo } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useMaxDiffQuestionBuilderContext } from '@containers/SurveyBuilder.Question/Context';
import { AddItemButton } from '@presentation/AddItemButton';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import { Input } from 'components/Input';
import { TextEntryList } from 'components/TextEntryList';
import Settings from './MaxDiff.Settings';
import styles from './style/MaxDiff.Question.css';

export default function MaxDiffQuestionBuilder() {
  const {
    item,
    addOption,
    canAddOption,
    removeOption,
    canRemoveOption,
    renameOption,
    updateLeastLabel,
    updateMostLabel,
  } = useMaxDiffQuestionBuilderContext();

  const options =
    useMemo(() => item.options.map(o => ({ key: o.ordinal, value: o.value })), [item.options]);

  return (
    <>
      <div className={styles.row}>
        <QuestionSettingTitle
          title="Labels" />
        <div className={styles.labels}>
          <div className={styles.least}>
            <Input
              type="text"
              name="least"
              onChange={e => updateLeastLabel(e.target.value)}
              placeholder="Label for left side (least)"
              value={item.settings.label.left} />
          </div>
          <div className={styles.most}>
            <Input
              type="text"
              name="most"
              onChange={e => updateMostLabel(e.target.value)}
              placeholder="Label for right side (most)"
              value={item.settings.label.right} />
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <QuestionSettingTitle
          title={`Attributes (${surveyBuilder.maxDiff.MinOptions} minimum)`} />
        <TextEntryList
          items={options}
          placeholder="Enter an attribute choice"
          removeItem={removeOption}
          removeItemEnabled={canRemoveOption}
          updateItemValue={renameOption} />
      </div>
      {canAddOption &&
        <AddItemButton
          className={styles.add}
          label="Add Attribute"
          onClick={addOption} />
      }
      <Settings />
    </>
  );
}

export { MaxDiffQuestionBuilder };
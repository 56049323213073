import { cx } from '@utils';
import { TestQuestionsProps } from '../interfaces';
import styles from './style.css';

const TestQuestions = (props: TestQuestionsProps) => {
  const handleChange = (question: number, choice: number) => () => {
    if (props.results?.length) {
      return;
    }

    props.onChange({ [question]: choice });
  };

  const isChecked = (question, choice: number) => {
    return props.selections[question] === choice;
  };

  const getClassName = (question, choice: number) => {
    const picked = isChecked(question, choice);

    if (props.results?.length) {
      const result = props.results[question];

      return cx(styles.checkbox, {
        [styles.pass]: choice === result.correct,
        [styles.fail]: picked && choice !== result.correct,
      });
    }

    return cx(styles.checkbox, {
      [styles.pick]: picked,
      [styles.todo]: !picked,
    });
  };

  return (
    <div className={styles.items}>
      {props.items.map((x, i) =>
        <div
          className={styles.item}
          key={`q-${i}`}>
          <div className={styles.number}>{i + 1})</div>
          <div className={styles.content}>
            <div className={styles.question}>{x.question}</div>
            {x.choices.map((y, j) =>
              <div
                className={styles.choice}
                key={`c-${i}-${j}`}>
                <div
                  className={getClassName(i, j)}
                  onClick={handleChange(i, j)} />
                <label
                  className={styles.label}
                  htmlFor={`c-${i}-${j}`}>
                  {y}
                </label>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TestQuestions;

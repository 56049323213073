import { useContext } from 'react';
import { createNamedContext, StepperActions } from '@utils';
import { ProjectCreationForm, ProjectCreationSteps } from './interfaces';

type ProjectFormContextValue = [
  ProjectCreationForm,
  (data: Partial<ProjectCreationForm>) => void,
];

type FormErrorContextValue = {
  clearErrors: () => void;
  errors: {
    sector: string;
    industry: string;
    subindustry: string;
  };
  setSubIndustryError: (v: string) => void;
  setIndustryError: (v: string) => void;
  setSectorError: (v: string) => void;
};

type ProjectCreationNavigation = {
  back: () => void;
  index: number;
  step: ProjectCreationSteps;
  next: () => void;
};

export const FormErrorContext = createNamedContext<FormErrorContextValue>(undefined, 'FormErrorContext');
export const ProjectFormContext = createNamedContext<ProjectFormContextValue>(undefined, 'ProjectFormContext');
export const ProjectCreationNavigationContext = createNamedContext<ProjectCreationNavigation>(undefined, 'ProjectCreationNavigationContext');
export const ProjectSurveyStepperContext = createNamedContext<StepperActions>(undefined, 'ProjectSurveyStepperContext');
export const ParentObjectIdContext = createNamedContext<number>(undefined, 'ParentObjectIdContext');

export const useProjectFormContext = () => useContext(ProjectFormContext);
export const useProjectFormErrorContext = () => useContext(FormErrorContext);
export const useProjectCreationNavigation = () => useContext(ProjectCreationNavigationContext);
export const useProjectSurveyStepperActions = () => useContext(ProjectSurveyStepperContext);
export const useProjectParentId = () => useContext(ParentObjectIdContext);
import { addMinutes, format } from 'date-fns';
import { useConferenceInstance, IConference } from '@containers/Conference';
import * as utils from './utils';
import { ColorRing } from './ColorRing';
import styles from './style/WaitingRoom.css';

export function WaitingRoomEarly() {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.PreRoom>();

  const time = addMinutes(instance.call.start, process.env.VANCERY_ENV === 'production' ? -5 : -15);

  const text = `The conference will be available to join at ${format(time, 'h:mm aaa')}!`;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.title}>{`${instance.conference.title} Call`}</div>
        <div className={styles.date}>
          {utils.formatCallTime(instance.call.start, instance.call.end)}
        </div>
        <div className={styles.text}>{text}</div>
        <ColorRing size={35} />
      </div>
    </div>
  );
}
import { memo } from 'react';
import styles from './style/Form.Card.css';

type Props =
  ChildrenProps;

export const FormCard = memo((props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.card}>
        {props.children}
      </div>
    </div>
  );
});

export default FormCard;
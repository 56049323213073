import styles from '$website/screens/Main/style/HowItWorks.css';
import Section from '../Section';
import Video from './Video';
import items from './items';

const How = () => {
  const renderItems = (copy: string, ordinal: number) => (
    <div key={ordinal} className={styles.item}>
      <div className={styles.number}>{ordinal + 1}</div>
      <div className={styles.itemCopy}>{copy}</div>
    </div>
  );

  const desktopCopy = `In a few short steps, you can start engaging on topics of your choosing on Vancery.`;
  const copy = `Vancery is the world’s first Professional Exchange. In a few short steps you can start consulting on topics of your choosing on our platform.`;

  return (
    <div className={styles.how}>
      <div className={styles.copy}>{copy}</div>
      <div className={styles.desktopCopy}>{desktopCopy}</div>
      <div className={styles.items}>
        {items.map(renderItems)}
      </div>
    </div>
  );
};

const Watch = () => {
  const caption = `Watch this video to see how our projects work!`;
  return (
    <div className={styles.watch}>
      <Video />
      <div className={styles.caption}>{caption}</div>
    </div>
  );
};

const HowItWorks = () => (
  <Section className={styles.sectionRoot}>
    <div className={styles.wrap}>
      <h2 className={styles.headline}>How it works</h2>
      <div className={styles.main}>
        <How />
        <Watch />
      </div>
    </div>
  </Section>
);

export default HowItWorks;
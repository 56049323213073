import { useCallback, useContext, useMemo, Dispatch, SetStateAction } from 'react';
import { createNamedContext } from '@utils';
import { Transcript } from './interfaces';

type CommentsStateContextValue =
  [Transcript.Comments.State, Dispatch<Transcript.Comments.Actions>];

type TranscriptCommentsRefPoint = {
  top: number;
  left: number;
  width: number;
};

type CommentEditing =
  [boolean, () => void];

type CommentPositionsContextValue =
  [Transcript.Comments.PositionMap, Transcript.Comments.SetPosition];

type FocusedCommentContextValue =
  [string, Dispatch<SetStateAction<string>>];

export const TranscriptCommentsStateContext = createNamedContext<CommentsStateContextValue>(undefined, 'TranscriptCommentsStateContext');
export const TranscriptCommentsRefPointContext = createNamedContext<TranscriptCommentsRefPoint>(undefined, 'TranscriptCommentsRefPointContext');
export const TranscriptCommentActionsContext = createNamedContext<Transcript.CommentActions>(undefined, 'TranscriptCommentActionsContext');
export const TranscriptCommentEditingContext = createNamedContext<CommentEditing>(undefined, 'TranscriptCommentEditingContxt');
export const TranscriptCommentPositionsContext = createNamedContext<CommentPositionsContextValue>(undefined, 'TranscriptCommentPositionsContext');
export const TranscriptFocusedCommentContext = createNamedContext<FocusedCommentContextValue>(undefined, 'TranscriptFocusedCommentContext');

export const useTranscriptComments = () => useContext(TranscriptCommentsStateContext);
export const useTranscriptCommentActions = () => useContext(TranscriptCommentActionsContext);
export const useTranscriptCommentsRefPoint = () => useContext(TranscriptCommentsRefPointContext);
export const useTranscriptCommentEditing = () => useContext(TranscriptCommentEditingContext);

export const useTranscriptCommentPositions = () => {
  const [positions] = useContext(TranscriptCommentPositionsContext);
  return positions;
};

export const useSetTranscriptCommentPosition = () => {
  const [_, setCommentPosition] = useContext(TranscriptCommentPositionsContext);
  return setCommentPosition;
};

export const useTranscriptFocusedCommentContext = () => useContext(TranscriptFocusedCommentContext);
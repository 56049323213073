import { useCallback, useState } from 'react';
import { FormButtons } from '@presentation/FormButtons';
import { RespondentAnswer } from '@/types';
import { transformResponse } from './utils';
import EditingQuestionItem from './EditingQuestionItem';
import { EditingAnswerProps } from './interfaces';
import styles from './style.css';

export const EditingAnswer = ({ item, onCancel, onConfirm}: EditingAnswerProps) => {
  const [answer, setAnswer] = useState<RespondentAnswer>(transformResponse(item));

  const handleConfirm = useCallback(() => {
    onConfirm(answer);
  }, [onConfirm, answer]);

  return (
    <>
      <div className={styles.root}>
        <EditingQuestionItem
          item={{
            answer,
            question: item.question,
          }}
          updateAnswer={setAnswer} />
      </div>
      <FormButtons
        className={styles.form}
        archivable={false}
        onCancel={onCancel}
        onSubmit={handleConfirm} />
    </>
  );
};

export default EditingAnswer;
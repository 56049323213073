import { useQuery } from 'react-query';
import * as api from '@api';

type Params = {
  exclude?: string[];
  value:    string;
};

export const useSearchImportedContacts = ({
  exclude = [],
  value = '',
}: Params) => {

  return useQuery(['search-imported-contacts', value], async () => {
    const response = await api.users.searchContacts({
      query: value,
    });

    const items = exclude.length > 0
      ? response.results.filter(x => !exclude.includes(x.value))
      : response.results;

    return { items };
  }, {
    enabled: true,
    placeholderData: {
      items: [],
    },
  });
};
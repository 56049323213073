import qs from 'query-string';
import { AxiosResponse } from 'axios';
import http, { xhr } from '@services/http';
import * as enums from '@enums';
import { Projects } from '@services/api/interfaces';
import * as xform from '@transformers';

export * from './projects.surveys';
export * as pipeline from './pipeline';
export * as external from './projects.external';

export const createProject = (data: Projects.CreateProjectRequest) => {
  return http.post('/projects', data)
    .then((data: Projects.CreateProjectResponse<string>) => ({
      capabilities: data.capabilities,
      metrics: data.metrics,
      object: xform.normalizeWorkspaceObject(data.object),
      parent: xform.transformNewProjectParent({ parent: data.parent }),
      project: xform.transformNewProject({ project: data.project }),
    }));
};

const fetchProjectGoal = (data: Projects.FetchProjectGoalRequest): Promise<Projects.FetchProjectGoalResponse> => {
  return http.get(`/projects/${data.projectId}/goals`);
};

const fetchTargetCompany = (data: Projects.FetchTargetCompanyRequest): Promise<Projects.FetchTargetCompanyResponse> => {
  return http.get(`/projects/${data.projectId}/target-company`);
};

export const updateProjectOverview = (data: Projects.UpdateProjectOverview.Request) => {
  return http.patch(`/projects/${data.projectId}`, data)
    .then((res: Projects.UpdateProjectOverview.Response) => ({
      ...res,
      object: xform.normalizeWorkspaceObject(res.object),
    }));
};

const getProjectLink = (data: Projects.GetProjectLinkRequest): Promise<Projects.GetProjectLinkResponse> => {
  const { projectId, ...rest } = data;
  return http.get<Projects.GetProjectLinkResponse>(`/projects/${projectId}/links?${qs.stringify(rest)}`)
    .then(link => xform.assignProjectLinkUrl(link));
};

const sendSelfSourceProjectInviteEmails = (data: Projects.ProjectEmailInviteRequest): Promise<void> => {
  const { projectId, ...rest } = data;
  return http.post(`/projects/${projectId}/links/invite`, {
    ...rest,
    typeId: enums.ProjectLinkType.SelfSource,
  });
};

const sendConsultantRefererProjectInviteEmail = (data: Projects.ProjectEmailInviteRequest): Promise<void> => {
  const { projectId, ...rest } = data;
  return http.post(`/projects/${projectId}/links/invite`, {
    ...rest,
    typeId: enums.ProjectLinkType.ConsultantReferral,
  });
};

const updateProjectTargetCompany = (data: Projects.UpdateTargetCompanyRequest): Promise<Projects.UpdateTargetCompanyResponse> => {
  return http.put(`/projects/${data.projectId}/target-company`, data);
};

export const updateStatus = ({
  projectId,
  ...body
}: Projects.UpdateProjectStatus.Request): Promise<Projects.UpdateProjectStatus.Response> => {
  return http.patch(`/projects/${projectId}/status`, body);
};

export const cloneProject = ({
  projectParentId,
  ...body
}: Projects.CloneProject.Request) => {
  return http.post(`/projects/parents/${projectParentId}/clone`, body)
    .then((res: Projects.CloneProject.Response) => ({
      metrics: res.metrics,
      parent: xform.transformNewProjectParent({ parent: res.parent }),
      pipeline: xform.normalizePipeline(res.pipeline),
      previous: {
        pipeline: xform.normalizePipeline(res.previous.pipeline),
        project: xform.transformNewProject({ project: res.previous.project }),
      },
      project: xform.transformNewProject({ project: res.project }),
    }));
};

export const exportProjectData = (data: Projects.ExportProjectData.Request): Promise<Projects.ExportProjectData.Response> => {
  return xhr.get<Blob>(`/projects/${data.projectId}/export?${qs.stringify({ userIds: data.userIds, format: data.format })}`, { responseType: 'blob' })
    .then((res: Response) => {
      const filename = new RegExp(/filename="(.+)"/).exec(res.headers['content-disposition']);
      return {
        blob: res.data,
        filename: filename.length ? filename[1] : null,
      };
    });
  type Response = {
    headers: Record<string, string>;
  } & Omit<AxiosResponse<Blob>, 'headers'>;
};

export const exportProjectDataAsync = (data: Projects.ExportProjectDataAsync.Request): Promise<Projects.ExportProjectDataAsync.Response> => {
  return http.get(`/projects/${data.projectId}/exportAsync?${qs.stringify({ userIds: data.userIds, format: data.format })}`);
};

export const exportProjectDataType = (data: Projects.ExportProjectDataType.Request): Promise<Projects.ExportProjectDataType.Response> => {
  return http.get(`/projects/${data.projectId}/exportType?${qs.stringify({ userIds: data.userIds, format: data.format, dataType: data.dataType })}`);
};

export const exportProjectParentData = (data: Projects.ExportProjectParentData.Request): Promise<Projects.ExportProjectData.Response> => {
  return http.download(`/projects/parents/${data.projectParentId}/export?${qs.stringify({ userIds: data.userIds, format: data.format })}`);
};

export const exportProjectParentDataAsync = (data: Projects.ExportProjectParentDataAsync.Request): Promise<Projects.ExportProjectDataAsync.Response> => {
  return http.get(`/projects/parents/${data.projectParentId}/exportAsync?${qs.stringify({ userIds: data.userIds, format: data.format })}`);
};

export const exportProjectParentDataType = (data: Projects.ExportProjectParentDataType.Request): Promise<Projects.ExportProjectParentDataType.Response> => {
  return http.get(`/projects/parents/${data.projectParentId}/exportType?${qs.stringify({ userIds: data.userIds, format: data.format, dataType: data.dataType })}`);
};

export const updateProjectSummary = ({ projectId, ...body }: Projects.UpdateProjectSummary.Request) => {
  return http.patch(`/projects/${projectId}/summary`, body);
};

export const downloadProjectSummary = (data: Projects.DownloadProjectSummary.Request): Promise<Projects.DownloadProjectSummary.Response> => {
  return http.get(`/projects/${data.projectId}/download-summary`, { responseType: 'blob' });
};

export const getProjectCompletedCalls = (data: Projects.GetProjectCompletedCalls.Request) => {
  return http.get<Projects.GetProjectCompletedCalls.Response>(`/projects/${data.projectId}/completed-calls`)
    .then(({ calls }) => {
      return calls.map(c => xform.normalizeCompletedCall(c));
    });
};

export const getProjectMetrics = (data: Projects.GetProjectMetrics.Request) => {
  return xhr.get<Projects.GetProjectMetrics.Response>(`/projects/${data.projectId}/metrics`);
};

export const getExternalLink = (data: Projects.GetExternalLink.Request): Promise<Projects.GetExternalLink.Response> => {
  return http.patch(`/projects/${data.projectId}/get-external-link`, {});
};

export const goals = {
  fetch: fetchProjectGoal,
};

export const links = {
  get: getProjectLink,
  sendSelfSourceInviteEmail: sendSelfSourceProjectInviteEmails,
  sendConsultantRefererInviteEmail: sendConsultantRefererProjectInviteEmail,
};

export const targetCompany = {
  fetch: fetchTargetCompany,
  update: updateProjectTargetCompany,
};
import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState, useCanAddQuestion, useSurveySection, useSectionQuestions } from '@containers/SurveyBuilder/hooks';
import { useToggle } from '@utils';
import { DeleteSectionAlert } from 'components/SurveyBuilder.Section/DeleteSectionAlert';
import { SurveySectionBuilder } from 'components/SurveyBuilder.Section/SectionBuilder';

type Props = {
  identifier: string;
  includeTagging: boolean;
};

export const SurveySectionBuilderContainer = (props: Props) => {
  const [showAlert, toggleAlert] = useToggle(false);
  const [state, dispatch] = useSurveyBuilderState();

  const section = useSurveySection(props.identifier);
  const questions = useSectionQuestions(props.identifier);

  const getCanAddQuestion = useCanAddQuestion();

  const canAddQuestion = useMemo(() => {
    return getCanAddQuestion() &&
      section.metadata.canAddQuestion;
  }, [
    getCanAddQuestion,
    section.metadata.canAddQuestion,
  ]);

  const addQuestion = useCallback((ordinal: number) => () => {

    dispatch({
      type: 'new-question-added',
      questionOrdinal: ordinal,
      sectionIdentifier: props.identifier,
    });
  }, [dispatch, props.identifier]);

  const updateSectionName = useCallback((name: string) => {
    dispatch({
      type: 'update-section',
      item: {
        ...section,
        name,
      },
    });
  }, [dispatch, section]);

  const lastOrdinal = useMemo(() => {
    if (questions.length) {
      return Math.max(...questions.map(m => m.ordinal));
    } else {
      const previous = state.survey.questions.filter(f => {
        const fSection = state.survey.sections.find(s => s.identifier === f.section.identifier);
        return fSection.ordinal === section.ordinal - 1;
      }).map(m => m.ordinal);
      return previous.length
        ? Math.max(...previous)
        : 0;
    }

  }, [
    state.survey.questions,
    state.survey.sections,
    questions,
    section.ordinal,
  ]);

  const confirmDeletion = useCallback(() => {
    dispatch({
      type: 'remove-section',
      identifier: props.identifier,
    });
    toggleAlert();
  }, [dispatch, toggleAlert, props.identifier]);

  return (
    <>
      <SurveySectionBuilder
        addQuestion={addQuestion}
        canAddQuestion={canAddQuestion}
        editingOrdinal={state.editing.questionOrdinal}
        item={section}
        lastQuestionOrdinal={lastOrdinal}
        onDeleteSection={toggleAlert}
        questions={questions}
        updateSectionName={updateSectionName}
        includeTagging={props.includeTagging} />
      {showAlert &&
        <DeleteSectionAlert
          open={true}
          onConfirm={confirmDeletion}
          onClose={toggleAlert} />
      }
    </>
  );
};

export default SurveySectionBuilderContainer;
import { useCallback, useContext } from 'react';
import { SurveyQuestionType } from '@enums';
import { RespondentAnswer, SurveyFormQuestion } from '@/types';
import { SurveyFormQuestionContext } from '../Context';

export function useSurveyFormQuestionContext<T extends SurveyQuestionType = SurveyQuestionType>() {
  const [state, setState] = useContext(SurveyFormQuestionContext);

  const setAnswer = useCallback((value: RespondentAnswer<T>) => {
    setState({
      ...state,
      answer: value,
    });
  }, [state, setState]);

  return {
    answer: state.answer as RespondentAnswer<T>,
    ordinal: state.ordinal,
    question: state.question as SurveyFormQuestion<T>,
    setAnswer,
    setState,
  };

}

export default useSurveyFormQuestionContext;
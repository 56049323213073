import { useCallback } from 'react';
import StripeConsts from '@consts/stripe-countries.json';
import { FormDropdown, FormDropdownOnChange } from './FormDropdown';
import FormInput from './FormInput';
import { getAddressFieldStates } from './form-utils';
import styles from './style.css';
import { FormAddressItem } from './interfaces';

type Props = {
  address: FormAddressItem;
  onChange: (item: FormAddressItem) => void;
};

export function FormAddress({ address, onChange }: Props) {
  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const updated = {
      ...address,
      [e.target.name]: {
        ...address[e.target.name],
        value: e.target.value,
      },
    };
    onChange(updated);
  }, [address, onChange]);

  const handleCountryChange: FormDropdownOnChange = useCallback(item => {
    const updated = {
      ...address,
      country: {
        ...address.country,
        value: item.id,
      },
    };
    onChange(updated);
  }, [address, onChange]);

  const { city, state, zip } = getAddressFieldStates(address);

  return (
    <div>
      <FormDropdown
        className={styles.row}
        label='Country'
        items={StripeConsts.Countries}
        selected={StripeConsts.Countries.find(c => c.id === address.country.value)}
        required={true}
        onChange={handleCountryChange} />
      <FormInput
        className={styles.row}
        error={address.address1.error}
        placeholder='Address Line 1'
        label='Address 1'
        name='address1'
        value={address.address1.value}
        required={address.address1.required}
        onChange={handleInputChange} />
      <FormInput
        className={styles.row}
        error={address.address2.error}
        placeholder='Address Line 2'
        label='Address 2'
        name='address2'
        value={address.address2.value}
        required={address.address2.required}
        onChange={handleInputChange} />
      {city.show &&
        <FormInput
        className={styles.row}
        error={address.city.error}
        placeholder={city.placeholder}
        label={city.label}
        name='city'
        value={address.city.value}
        required={city.required}
        onChange={handleInputChange} />}
      {state.show &&
        <FormInput
          className={styles.row}
          error={address.state.error}
          placeholder={state.placeholder}
          label={state.label}
          name='state'
          value={address.state.value}
          required={address.state.required}
          onChange={handleInputChange} />}
      <FormInput
        className={styles.row}
        error={address.zip.error}
        placeholder={zip.placeholder}
        label={zip.label}
        name='zip'
        value={address.zip.value}
        required={address.zip.required}
        onChange={handleInputChange} />
    </div>
  );
}

export default FormAddress;
import { useMemo } from 'react';
import { SurveySection } from '@presentation/Survey';
import { QuestionTaggingItem } from 'components/SurveyBuilder.Question.Tagging';
import { TaggingSectionProps } from './interfaces';
import { SurveyTaggingSectionHeader, ReadOnlySectionHeader } from './Header';
import styles from './style/SurveyTaggingSectionBuilder.css';

type Props
  = TaggingSectionProps;

export default function SurveyTaggingSectionBuilder({
  className, editingOrdinal, questions, section, toggleEditingOn,
}: Props) {

  const Header = useMemo(() => {
    if (editingOrdinal) {
      return (
        <ReadOnlySectionHeader
          className={styles.header}
          item={section} />
      );
    } else {
      return (
        <SurveyTaggingSectionHeader
          className={styles.header}
          item={section} />
      );
    }
  }, [editingOrdinal, section]);

  return (
    <SurveySection className={className}>
      <div className={styles.container}>
        {Header}
        {questions.map(item =>
          <div className={styles.question} key={item.base.identifier}>
            <QuestionTaggingItem
              canEdit={!editingOrdinal}
              editing={editingOrdinal === item.ordinal}
              item={item}
              toggleEditingOn={toggleEditingOn(item.ordinal)} />
          </div>)}
      </div>
    </SurveySection>
  );
}

export { SurveyTaggingSectionBuilder };
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import * as api from '@api';
import * as QKey from '@consts/querykey';
import { path, pathname } from '@consts';
import { useAppReadyState } from '@containers/AppReadyState';
import { PostArticleContainer } from '@containers/Post.Article';
import { qs } from '@utils';
import * as Article from './Article';

type Props = unknown;

export const PostArticle = (props: Props) => {
  const app = useAppReadyState();
  const history = useHistory();
  const params = useParams<{ postId: string; }>();
  const valid = Number.isInteger(+params.postId);

  const query = useQuery([QKey.Posts.Articles.Get, +params.postId], () => {
    return api.posts.articles.fetchPost({ postId: +params.postId });
  }, {
    enabled: valid,
    onError: (e: AxiosResponse) => {
      if (e.status === 404) {
        history.replace(pathname.Home);
      }
    },
    retry: false,
  });

  if (!valid && !app.authenticated) {
    const q = qs.stringify({
      returnUrl: window.location.pathname,
    });

    return (
      <Redirect to={{
        pathname: path.Website.Login,
        search: `?${q}`,
      }} />
    );
  }

  return (
    <PostArticleContainer postId={+params.postId}>
      {!query.data?.item
        ? <Article.Skeleton />
        : <Article.Content item={query.data.item} />}
    </PostArticleContainer>
  );
};

PostArticle.displayName = 'Post.Article';
import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { UseMutationOptions, useMutation } from 'react-query';
import * as api from '@api/scheduling.project';
import * as Scheduling from '@api/interfaces/scheduling.project';
import * as actions from '@store/actions';

type Data = Scheduling.RescheduleCall.Response<Date>;
type Variables = Omit<Scheduling.RescheduleCall.Request, 'callId'>;

export function useProposeProjectCallTimes(
  data: ICallId,
  options?: UseMutationOptions<Data, AxiosResponse, Variables>
) {
  const dispatch = useDispatch();

  const onSuccess = useCallback((res: Data, v: Variables, ctx) => {
    dispatch(actions.callUpdated({
      call: res.call,
    }));

    options?.onSuccess(res, v, ctx);
  }, [dispatch, options?.onSuccess]);

  return useMutation(keyProjectCallProposeTimes(data.callId), params => {

    return api.projectCall.propose({
      callId: data.callId,
      new: params.new,
      removed: params.removed,
    });

  }, {
    ...options,
    onSuccess,
  });
}

export function keyProjectCallProposeTimes(callId: number) {
  return ['project-call-propose-time', callId];
}

export default useProposeProjectCallTimes;
import axios from 'axios';
import { XHR } from '@services/http/interfaces';
import { HTTPInstanceOptions } from './interfaces';

export function create(options: HTTPInstanceOptions) {
  const instance = axios.create({
    baseURL: process.env.BACKEND_BASE_URL,
    withCredentials: true,
  });

  // legacy
  instance.interceptors.request.use((config: XHR.RequestConfig) => {
    config.headers.common.Authorization = `Bearer ${options.token}`;
  });

  return instance;
}

export default { create };
import { useEffect } from 'react';
import { external as $service } from '@services/api/projects';
import { ExternalProjectSecretType } from '@enums';
import { useParams, useCompleteProject } from './hooks';
import { ProjectDisqualification as Disqual } from './../ProjectDisqualification';
import { RedirectWrapper } from './RedirectWrapper';
import { SecretParams } from './interfaces';

export const ProjectDisqualification = () => {
  const params = useParams(ExternalProjectSecretType.Naf);

  return (
    <RedirectWrapper {...params}>
      <ProjectDisqualificationInner {...params} />
    </RedirectWrapper>
  );
};

const ProjectDisqualificationInner = (props: SecretParams) => {
  const completeProject = useCompleteProject(props);

  useEffect(() => {
    $service.completeProject(props)
      .then(r => {
        completeProject();
      })
      .catch(e => {
        alert('There was an error saving your project response.');
        throw e;
      });
  }, []);

  return (
    <Disqual />
  );
};
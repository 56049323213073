import relationships from 'static/images/website/industry-relationships.png';
import relationshipsMobile from 'static/images/website/industry-relationships-mobile.png';
import background from 'static/images/website/grey-background.png';
import Section from './Section';
import styles from './style/IndustryRelationships.css';

const IndustryRelationships = () => {

  const headline = `Understand economic relationships among companies and industries`;
  const copy = `Vancery's platform houses millions of gigabytes of dynamic data, mapping out company level relationships between buyers, sellers, and competitors in the global economy.`;

  return (
    <Section>
      <div className={styles.root}>
        <div className={styles.headline}>{headline}</div>
        <div className={styles.copy}>{copy}</div>
        <div className={styles.content}>
          <div className={styles.imgContainer}>
            <img
              alt='Vancery Industry Relationships'
              className={styles.img}
              src={relationships} />
            <img
              alt='Vancery Industry Relationships'
              className={styles.imgMobile}
              src={relationshipsMobile} />
          </div>
          <img
            className={styles.imgBackground}
            src={background} />
        </div>
      </div>
    </Section>
  );
};

export default IndustryRelationships;
import http from '@services/http';
import { FileUploadStatus } from '@enums';
import * as xform from '@transformers';
import { FileUploads as WS } from './interfaces';

export const queueFileUpload = (data: WS.QueueFileUpload.Request) => {
  const { workspaceId, ...body } = data;
  return http.post(`/workspaces/${workspaceId}/file-uploads`, body)
  .then((res: WS.QueueFileUpload.Response) => ({
    ...res,
    fileUpload: xform.normalizeFileUpload(res.fileUpload),
  }));
};

export const startFileUpload = (data: WS.StartFileUpload.Request) => {
  const { fileUploadId, workspaceId, ...body } = data;
  return http.post(`/workspaces/${workspaceId}/file-uploads/${fileUploadId}`, {
    ...body,
    status: FileUploadStatus.InProgress,
  })
  .then((res: WS.StartFileUpload.Response) => ({
    ...res,
    fileUpload: xform.normalizeFileUpload(res.fileUpload),
  }));
};

export const abortFileUpload = (data: WS.AbortFileUpload.Request) => {
  const { fileUploadId, workspaceId, ...body } = data;
  return http.post(`/workspaces/${workspaceId}/file-uploads/${fileUploadId}`, {
    ...body,
    status: FileUploadStatus.Aborted,
  })
  .then((res: WS.AbortFileUpload.Response) => ({
    ...res,
    fileUpload: xform.normalizeFileUpload(res.fileUpload),
  }));
};

export const failFileUpload = (data: WS.FailFileUpload.Request) => {
  const { fileUploadId, workspaceId, ...body } = data;
  return http.post(`/workspaces/${workspaceId}/file-uploads/${fileUploadId}`, {
    ...body,
    status: FileUploadStatus.Failed,
  })
  .then((res: WS.AbortFileUpload.Response) => ({
    ...res,
    fileUpload: xform.normalizeFileUpload(res.fileUpload),
  }));
};

export const completeFileUpload = (data: WS.CompleteFileUpload.Request) => {
  const { fileUploadId, workspaceId, ...body } = data;
  return http.post(`/workspaces/${workspaceId}/file-uploads/${fileUploadId}`, {
    ...body,
    status: FileUploadStatus.Completed,
  })
  .then((res: WS.CompleteFileUpload.Response) => ({
    ...res,
    file: xform.normalizeFile(res.file),
    fileUpload: xform.normalizeFileUpload(res.fileUpload),
    object: xform.normalizeWorkspaceObject(res.object),
  }));
};

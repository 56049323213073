import { useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { MatrixSliderQuestion } from '@/types';
import {
  useSurveyFormQuestionContext,
  useSubmitAnswer,
  useSurveyResponseGoBack,
  useSurveyStepperNavigation,
} from './hooks';
import { matrixSlider } from './utils';
import { SurveyFormNavigationContext } from './Context';

type Props =
  ChildrenProps;

export const MatrixSliderFormContainer = (props: Props) => {
  const {
    answer,
    question,
  } = useSurveyFormQuestionContext<SurveyQuestionType.Sliders>();
  const navigation = useSurveyStepperNavigation(question.ordinal);
  const submitAnswer = useSubmitAnswer();
  const goBack = useSurveyResponseGoBack();

  const answerValid = useMemo(() => {
    const q = question as MatrixSliderQuestion.FormQuestion;
    const value = answer as MatrixSliderQuestion.RespondentAnswer.Value;

    return matrixSlider.isAnswerValid(value || { items: [] }, q);
  }, [
    answer,
    question,
  ]);

  const value = {
    back: {
      hidden: !navigation.showBack,
      isError: goBack.isError,
      isLoading: goBack.isLoading,
      submit: goBack.mutateAsync as () => Promise<unknown>,
    },
    next: {
      disabled: !answerValid,
      isError: submitAnswer.isError,
      isLoading: submitAnswer.isLoading,
      submit: submitAnswer.mutateAsync as () => Promise<unknown>,
    },
    showComplete: navigation.showComplete,
  };

  return (
    <SurveyFormNavigationContext.Provider value={value}>
      {props.children}
    </SurveyFormNavigationContext.Provider>
  );
};

export default MatrixSliderFormContainer;
import { GroupPersonTagsContainer } from '@containers/GroupTags/GroupPersonTagsContainer';
import { UserProfileEnterprise } from '@screens/UserProfile/UserProfileEnterprise';
import UserProfileContainer from './UserProfileContainer';
import { useMatchUserIdFromUrl } from './hooks';

function ProfileDependentContainer(props: unknown) {
  const userId = useMatchUserIdFromUrl();

  return (
    <GroupPersonTagsContainer userId={userId}>
      <UserProfileEnterprise {...props} />
    </GroupPersonTagsContainer>
  );
}

export default function UserProfileEnterpriseContainer(props: unknown) {
  return (
    <UserProfileContainer>
      <ProfileDependentContainer {...props} />
    </UserProfileContainer>
  );
}

export { UserProfileEnterpriseContainer };
import { useCallback, useEffect, useState } from 'react';
import * as api from '@api';
import { useAsyncStateLazy } from '@utils';
import {
  GroupEntityTagsContext,
  GroupEntityTagsDispatchContext,
  GroupEntityTagsFetchContext,
  GroupEntityTagsLoadingContext,
  GroupEntityTagsUpdateContext,
} from './Context';
import { GroupTagsContextValue, GroupTagUpdateItem, GroupFileTagsProps, GroupCallTagsProps } from './interfaces';

export default function GroupCallTagsContainer(props: GroupCallTagsProps) {
  const [state, dispatch] = useState<GroupTagsContextValue>(GroupCallTagsContainer.initialState);

  const [response, fetch] = useAsyncStateLazy(async () => {
    const result = await api.tags.group.getCallTags({
      callId: props.callId,
    });

    dispatch(result.tags);

    return result.tags;
  }, [props.callId]);

  const update = useCallback(async (tags: GroupTagUpdateItem[]) => {
    const result = await api.tags.group.updateCallTags({
      tags,
      callId: props.callId,
    });
    dispatch(result.tags);
  }, [props.callId]);

  useEffect(() => {
    if (props.callId) {
      fetch();
    }
  }, [fetch, props.callId]);

  return (
    <GroupEntityTagsContext.Provider value={state}>
      <GroupEntityTagsLoadingContext.Provider value={response.loading}>
        <GroupEntityTagsFetchContext.Provider value={fetch}>
          <GroupEntityTagsUpdateContext.Provider value={update}>
            <GroupEntityTagsDispatchContext.Provider value={dispatch}>
              {props.children}
            </GroupEntityTagsDispatchContext.Provider>
          </GroupEntityTagsUpdateContext.Provider>
        </GroupEntityTagsFetchContext.Provider>
      </GroupEntityTagsLoadingContext.Provider>
    </GroupEntityTagsContext.Provider>
  );
}

const initial: GroupTagsContextValue = [];

GroupCallTagsContainer.initialState = initial;

export { GroupCallTagsContainer };
import { memo, useCallback } from 'react';
import { ThumbsUp, ThumbsDown } from 'react-feather';
import { cx } from '@utils';
import styles from './style/ThumbsUpRating.css';

export type Props = {
  className?: string;
  onUpdate: (value: boolean) => void;
  value: boolean;
};

export const ThumbsUpRatingPrompt = memo(({ className, onUpdate, value }: Props) => {

  const thumbsUpClassName = cx(styles.thumb, {
    [styles.active]: value === true,
    [styles.inactive]: value !== true,
  });

  const thumbsDownClassName = cx(styles.thumb, styles.down, {
    [styles.active]: value === false,
    [styles.inactive]: value !== false,
  });

  const handleThumbsUp = useCallback(() => onUpdate(true), [onUpdate]);
  const handleThumbsDown = useCallback(() => onUpdate(false), [onUpdate]);

  return (
    <div className={cx(styles.root, className)}>
      <ThumbsUp
        className={thumbsUpClassName}
        onClick={handleThumbsUp} />
      <ThumbsDown
        className={thumbsDownClassName}
        onClick={handleThumbsDown} />
    </div>
  );
});

export type ThumbsUpRatingProps = {
  className?: string;
  value: boolean;
};

export const ThumbsUpRating = ({ className, value }: ThumbsUpRatingProps) => {
  const Rating = value ? ThumbsUp : ThumbsDown;

  return (
    <Rating className={cx(styles.thumb, className)} />
  );
};

import { useCallback, useMemo } from 'react';
import { Node, createEditor } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';
import { cx } from '@utils';
import { Element, Leaf } from './Render';
import * as utils from './utils';
import styles from './style.css';

export type ReadOnlyProps = {
  className?: string;
  placeholder?: string;
  value: Node[];
};

export const ReadOnly = (props: ReadOnlyProps) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  return (
    <Slate editor={editor} value={props.value || utils.emptyValue} onChange={() => {}}>
      <div className={cx(styles.readonly, props.className)}>
        <Editable
          placeholder={props.placeholder}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          readOnly />
      </div>
    </Slate>
  );
};

export default ReadOnly;
import { Fragment, forwardRef, Ref, useCallback, useRef } from 'react';
import { useQuery } from 'react-query';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { Share } from 'components/icons';
import styles from './style.css';

type Token = {
  token: {
    value: string;
  };
  url: string;
};

type Props = {
  cacheKey: Array<string | number>;
  fetchToken: () => Promise<Token>;
  resetToken?: (value: string) => Promise<Token>;
  placement?: PopperPlacementType;
  size?: number;
};

export function ShareLinkPopper(props: Props) {
  return (
    <PopupState
      variant="popper"
      popupId="share-link-popper">
      {popupState => (
        <div>
          <div
            className={styles.trigger}
            {...bindToggle(popupState)}>
            <Share size={props.size ?? 24} />
          </div>
          <Popper
            {...bindPopper(popupState)}
            placement={props.placement ?? 'bottom-end'}
            className={styles.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <div>
                <ShareLinkBody
                  cacheKey={props.cacheKey}
                  onClose={popupState.close}
                  fetchToken={props.fetchToken}
                  resetToken={props.resetToken} />
              </div>
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
}

type BodyProps = {
  cacheKey: Props['cacheKey'];
  onClose: () => void;
  fetchToken: Props['fetchToken'];
  resetToken?: Props['resetToken'];
};

export const ShareLinkBody = forwardRef(({ cacheKey, onClose, fetchToken, resetToken }: BodyProps, ref: Ref<HTMLDivElement>) => {
  const input = useRef<HTMLInputElement>();

  const query = useQuery(['admin-share-link', ...cacheKey], () => fetchToken(), {
    placeholderData: {
      token: {
        value: null,
      },
      url: '',
    },
    keepPreviousData: true,
    staleTime: 5000,
  });

  const handleCopyLink = useCallback(async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(input.current.value);
      } catch (e) {
        input.current.select();
        document.execCommand('copy');
      }
      input.current.blur();
    }
    else if (document.execCommand) {
      input.current.select();
      document.execCommand('copy');
      input.current.blur();
    }
    onClose();
  }, [onClose]);

  const handleReset = useCallback(() => {
    resetToken(query.data.token.value)
      .then(() => query.refetch());
  }, [query, resetToken]);

  return (
    <Fragment>
      <div className={styles.main}>
        <div className={styles.share} />
        <div className={styles.url}>
          <input
            className={styles.textbox}
            readOnly
            ref={input}
            type="text"
            value={query.data.url} />
        </div>
      </div>
      <div className={styles.btns}>
        <button />
        <button
          disabled={!query.data.token || !resetToken}
          className={styles.reset}
          onClick={handleReset}>
          Reset
        </button>
        <button
          disabled={query.isFetching || query.isLoading}
          className={styles.copy}
          onClick={handleCopyLink}>
          Copy Link
        </button>
      </div>
    </Fragment>
  );
});
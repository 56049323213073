import { EmailVerificationContainer, useSelectUser } from '@containers';
import { Logo } from 'components/Branding';
import { ResendVerificationEmail } from './ResendVerificationEmail';
import styles from './style/VerifyEmail.css';

type Props = unknown;

const VerifyEmail = (props: Props) => {
  const user = useSelectUser();

  return (
    <EmailVerificationContainer>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <Logo className={styles.logo} />
            <div className={styles.header}>Confirm your email address</div>
            <div className={styles.message}>
              <div className={styles.subtext}>
                We have sent an email to <span className={styles.email}>{user.contact.email}</span>
              </div>
            </div>
            <div className={styles.message}>
              <div className={styles.subtext}>Click the verify button in the email to confirm your email address and gain access to your account.</div>
            </div>
            <ResendVerificationEmail />
          </div>
        </div>
      </div>
    </EmailVerificationContainer>
  );
};

export { VerifyEmail };
export default VerifyEmail;
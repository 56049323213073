import { useContext } from 'react';
import { AggregateFilterParamsContext } from '@containers/ComplianceReview/Context';

const useAggregateReviewFilterParams = () => {
  const params = useContext(AggregateFilterParamsContext);

  return params;
};

export { useAggregateReviewFilterParams };
export default useAggregateReviewFilterParams;
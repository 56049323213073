import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, MessageSquare } from 'react-feather';
import { getLocationFor } from '@utils';
import { Contact } from '@/types';
import { CreditRateWithTooltip } from 'components/ExpertRate/Credit';
import { UserAvatar } from 'components/UserAvatar/UserAvatar';
import styles from './BaseballCard.css';
import { useCardContext } from './CardContext';

type Props = {
  canInitiateCalls: boolean;
  canInitiateMessages: boolean;
  contact: Pick<Contact, 'id' | 'career' | 'profile'>;
  initiateCall: () => unknown;
  initiateMessage: () => unknown;
  showRate: boolean;
};

export const MemberCardContent = ({
  canInitiateCalls,
  canInitiateMessages,
  contact,
  initiateCall,
  initiateMessage,
  showRate,
}: Props) => {

  const { closeCard } = useCardContext();

  const callRequestOnClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    closeCard();
    initiateCall();
  }, [
    closeCard,
    initiateCall,
  ]);

  const messageOnClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    closeCard();
    initiateMessage();
  }, [
    closeCard,
    initiateMessage,
  ]);

  const profileLocation = getLocationFor.user.profile(contact.profile);

  return (
    <div className={styles.contentContainer}>
      <div className={styles.textContainer}>
        <div>
          <UserAvatar
            className={styles.avatar}
            pictureUrl={contact.profile.pictureUrl}
            size={50}
            resize={100}
            to={profileLocation} />
        </div>
        <div>
          <div>
            <span className={styles.expertName}>
              <Link to={profileLocation}>{contact.profile.fullname}</Link>
            </span>
            {showRate &&
              <CreditRateWithTooltip rate={contact.profile.creditRate} />
            }
          </div>
          {contact.career.current &&
            <div className={styles.subtext}>{[contact.career.current?.title, contact.career.current?.company].filter(Boolean).join(', ')}</div>
          }
        </div>
      </div>
      {(canInitiateMessages || canInitiateCalls) &&
        <div className={styles.btns}>
          {canInitiateMessages &&
            <button
              className={styles.btn}
              onClick={messageOnClick}>
              <MessageSquare />
              Message
            </button>}
          {canInitiateCalls &&
            <button
              className={styles.btn}
              onClick={callRequestOnClick}>
              <Calendar />
              Request Call
            </button>}
        </div>
      }
    </div>
  );
};

export default MemberCardContent;
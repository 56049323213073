
export namespace ConjointAnalysis {

  export namespace AddAttribute {
    export type State = unknown;
    export type Action = {
      type: 'conjoint-add-attribute';
    } & State;
  }

  export namespace RemoveAttribute {
    export type State = {
      attributeId: string;
    };
    export type Action = {
      type: 'conjoint-remove-attribute';
    } & State;
  }

  export namespace AddLevel {
    export type State = {
      attributeId: string;
    };
    export type Action = {
      type: 'conjoint-add-level';
    } & State;
  }

  export namespace RemoveLevel {
    export type State = {
      attributeId: string;
      levelId: string;
    };
    export type Action = {
      type: 'conjoint-remove-level';
    } & State;
  }

  export namespace UpdateAttributeName {
    export type State = {
      attributeId: string;
      value: string;
    };
    export type Action = {
      type: 'conjoint-update-attribute-name';
    } & State;
  }

  export namespace UpdateLevelName {
    export type State = {
      attributeId: string;
      levelId: string;
      value: string;
    };
    export type Action = {
      type: 'conjoint-update-level-name';
    } & State;
  }

  export namespace UpdateConceptsPerSet {
    export type State = {
      value: number;
    };
    export type Action = {
      type: 'conjoint-update-concepts-per-set';
    } & State;
  }

  export namespace UpdateNumberOfSets {
    export type State = {
      value: number;
    };
    export type Action = {
      type: 'conjoint-update-number-of-sets';
    } & State;
  }

  export namespace ToggleNoneOfTheAbove {
    export type State = unknown;
    export type Action = {
      type: 'conjoint-toggle-none-of-the-above';
    } & State;
  }

  export type Action =
    AddAttribute.Action |
    RemoveAttribute.Action |
    AddLevel.Action |
    RemoveLevel.Action |
    UpdateAttributeName.Action |
    UpdateLevelName.Action |
    UpdateConceptsPerSet.Action |
    UpdateNumberOfSets.Action |
    ToggleNoneOfTheAbove.Action
  ;
}
import { useHistory } from 'react-router-dom';
import { pathname } from '@consts';
import { Back } from '@presentation';
import { Button } from 'components/Button';
import styles from './style/TopicOnboarding.css';

type Props = unknown;

export const Footer = (props: Props) => {
  const history = useHistory();

  return (
    <div className={styles.footer}>
      <div className={styles.overlay} />
      <div className={styles.btns}>
        <div className={styles.navigation}>
          <Back onClick={() => history.goBack()} />

          <Button
            className={styles.btn}
            color="primary"
            replace
            to={pathname.Home}
            variant="brick">
            Complete
          </Button>
        </div>
      </div>
    </div>
  );
};

Footer.displayName = 'Topic.Onboarding.Footer';
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Middleware } from 'redux';
import { path } from '@consts';
import { history } from '@services/history';
import * as types from '@store/action-types';

const routing: Middleware = store => next => action => {
  if (action.type === types.UPDATE_LOCATION) {
    if (action.redirect !== history.location.pathname) {
      return history.replace(action.redirect);
    }
  }

  next(action);

  if (action.type === types.USER_LOGOUT) {
    if (action.wasActionLogout) {
      history.replace(path.Website.Login);
    }
  }
};

const apply = (middleware: Middleware[]) => {
  return middleware.concat(routing);
};

export default {
  apply,
  middleware: routing,
};
import { useCallback, useMemo } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { SurveyActionType, SurveyQuotaType } from '@enums';
import { FormButtons } from '@presentation/FormButtons';
import {
  ActionTypeDropdown,
} from '@presentation/SurveyBuilder/Dropdown';
import { useSurveyQuotasState, useSurveyQuotasGoal } from '@containers/SurveyBuilder';
import { useSurveyQuotaState } from '@containers/SurveyBuilder.Quota';
import { Input, NumberInput } from 'components/Input';
import { QuotaBuilderTitle } from './Title';
import { AdvancedSurveyQuotaBuilder } from './Builder.Advanced';
import { BasicSurveyQuotaBuilder } from './Builder.Basic';
import { validateQuota } from './validate';
import styles from './style/Builder.css';

export const SurveyQuotaBuilder = () => {

  const [state, dispatch] = useSurveyQuotaState();
  const [{ editing }, dispatchQuotas] = useSurveyQuotasState();
  const goal = useSurveyQuotasGoal();

  const canSave = useMemo(() => {
    const validation = validateQuota(state);
    return validation.success;
  }, [state]);

  const updateName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'update-name',
      value: e.target.value,
    });
  }, [dispatch]);

  const updateLimit = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {

    const value = e.target.value === ''
      ? null
      : +e.target.value;

    dispatch({
      type: 'update-limit',
      value,
    });
  }, [dispatch]);

  const handleLimitBlur = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'update-limit',
      value: +e.target.value,
    });
  }, [dispatch]);

  const updateAction = useCallback((value: SurveyActionType) => {
    dispatch({
      type: 'update-action',
      value,
    });
  }, [dispatch]);

  const handleSave = useCallback(() => {
    dispatchQuotas({
      type: 'save-quota-item',
      value: state,
    });
  }, [dispatchQuotas, state]);

  const handleCancel = useCallback(() => {
    dispatchQuotas({
      identifier: editing.identifier,
      type: 'toggle-quota-editing-off',
    });
  }, [dispatchQuotas, editing]);

  return (
    <div>
      <div className={styles.wrap}>
        <div className={styles.row}>
          <span className={styles.title}>Name:</span>
          <div className={styles.name}>
            <Input
              placeholder="Quota Name"
              onChange={updateName}
              value={state.name} />
          </div>
        </div>

        <div className={styles.row}>
          <span className={styles.title}>Limit:</span>
          <div className={styles.limit}>
            <div className={styles.value}>
              <NumberInput
                onBlur={handleLimitBlur}
                onChange={updateLimit}
                isAllowed={({ floatValue, value }: NumberFormatValues) => {
                  if (value.length > 1 && value.startsWith('0')) return false;
                  if (value === '') return true;
                  if (floatValue >= 0 && floatValue <= goal) return true;

                  return false;
                }}
                value={state.limit} />
            </div>
            <div className={styles.slash}>/</div>
            <div className={styles.value}>{goal}</div>
          </div>
        </div>

        <QuotaBuilderTitle label="Result" />
        <div className={styles.action}>
          <ActionTypeDropdown
            items={[
              SurveyActionType.SkipToEnd,
              SurveyActionType.QuotaOverLimit,
            ]}
            onSelect={updateAction}
            value={state.actionType} />
        </div>

        {
          state.type === SurveyQuotaType.Advanced
          ? <AdvancedSurveyQuotaBuilder />
          : <BasicSurveyQuotaBuilder />
        }
      </div>

      <FormButtons
        className={styles.btns}
        disabled={!canSave}
        onCancel={handleCancel}
        onSubmit={handleSave} />
    </div>
  );
};

export default SurveyQuotaBuilder;
import { useMemo } from 'react';
import { Datum, Serie } from '@nivo/line';
import { useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks/useRepeatQuestionResponsesData';
import { SurveyQuestionType } from '@enums';
import { roundToPlace } from '@utils';
import { useOptionsColors } from 'components/SurveyResponses/hooks/usePaletteColors';

export const useRepeatMatrixSliderChartData = () => {
  const qData = useRepeatQuestionResponsesData<SurveyQuestionType.Sliders>();
  const colors = useOptionsColors();

  const chartData: Serie[] = useMemo(() => {

    return qData.question.matrixRows.reduce((acc, row) => {
      const data = qData.projectIds.reduce((acc2, projectId, i) => {
        const projectData = qData.historical[projectId].data;

        const avg = projectData.rows[row.base.id]?.avg;
        if (avg) {
          const value: Datum = {
            x: i + 1,
            y: roundToPlace(avg, 1),
          };

          return [
            ...acc2,
            value,
          ];
        } else {
          return acc2;
        }
      }, [] as Datum[]);

      return [
        ...acc,
        {
          color: colors[row.ordinal - 1],
          data,
          id: row.base.id,
        },
      ];
    }, [] as Serie[]);
  }, [
    colors,
    qData.historical,
    qData.projectIds,
    qData.question.matrixRows,
  ]);

  return chartData;
};

export default useRepeatMatrixSliderChartData;
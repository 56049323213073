import { useProjectInviteWhitelabelState } from '@containers/ProjectLinkInvitation/hooks';
import { Footer } from '$website/components';
import * as Whitelabel from 'components/Whitelabel';
import { Benefits } from './Benefits';
import { FAQ } from './FAQ';
import { Funnel } from './Funnel';
import { Landing } from './Landing';
import { ValueProposition } from './ValueProposition';
import { Navbar } from './SurveyInvite.Navbar';
import styles from './style/SurveyInvite.css';

type Props = unknown;

export const SurveyInvite = (props: Props) => {
  const whitelabel = useProjectInviteWhitelabelState();

  if (whitelabel.enabled) {
    return (
      <Whitelabel.BrandScreen className={styles.theme}>
        <div className={styles.root}>
          <Navbar />
          <Landing />
        </div>
      </Whitelabel.BrandScreen>
    );
  }

  return (
    <div className={styles.root}>
      <Navbar />
      <Landing />
      <FAQ />
      <Benefits />
      <ValueProposition />
      <Funnel />
      <Footer />
    </div>
  );
};

SurveyInvite.displayName = 'SurveyInvite';
import { Redirect } from 'react-router-dom';
import * as consts from '@consts';
import { ObjectAccessContainer } from '@containers/ObjectAccess';
import { useMatchFolderStateFromUrl } from './hooks';
import { WorkspaceFolderStateContext } from './Context';

type Props =
  ChildrenProps;

export const WorkspaceFolderContainer = (props: Props) => {
  const state = useMatchFolderStateFromUrl();

  if (!state.folder) {
    return <Redirect to={`${consts.pathname.WORKSPACES}/personal`} />;
  }

  return (
    <WorkspaceFolderStateContext.Provider value={state}>
      <ObjectAccessContainer objectId={state.object.id} workspaceId={state.object.workspaceId}>
        {props.children}
      </ObjectAccessContainer>
    </WorkspaceFolderStateContext.Provider>
  );
};

export default WorkspaceFolderContainer;
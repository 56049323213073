import { useCallback } from 'react';
import { useRenameFolder } from './hooks';
import { useSelectObject, useSelectFolder } from '@containers/Store';
import { RenameFolderContext } from './Context';

type Props =
  IWorkspaceFolderId &
  ChildrenProps;

export const RenameFolderContainer = (props: Props) => {

  const folder = useSelectFolder(props.folderId);
  const object = useSelectObject(folder.objectId);
  const renameFolder = useRenameFolder();

  const handleRename = useCallback((name: string) => {
    return renameFolder({
      folderId: props.folderId,
      name,
      workspaceId: object.workspaceId,
    });
  }, [
    renameFolder,
    props.folderId,
    object.workspaceId,
  ]);

  const value = {
    folder,
    rename: handleRename,
  };

  return (
    <RenameFolderContext.Provider value={value}>
      {props.children}
    </RenameFolderContext.Provider>
  );
};

export default RenameFolderContainer;
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pathname } from '@consts';
import { useMatchProjectIdFromUrl } from '@containers/GroupProject/hooks';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { Input } from 'components/Input';
import { useSurveyMonitoringPasswordRequired } from './Context';
import { useSubmitSurveyMonitoringAuth } from './hooks/useSubmitAuthForm';
import styles from './style/AuthScreen.css';

const selectAuthenticated = (state: Store.State) => state.user.state.authenticated;

export const SurveyMonitoringAuth = () => {
  const { projectId } = useMatchProjectIdFromUrl();
  const [_, setPasswordRequired] = useSurveyMonitoringPasswordRequired();

  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState(false);
  const authenticated = useSelector(selectAuthenticated);
  const history = useHistory();

  const {
    mutateAsync: submit,
    isLoading,
  } = useSubmitSurveyMonitoringAuth({
    projectId,
  }, {
    onSuccess: () => {
      setError(false);
      setPasswordRequired(false);
    },
    onError: e => {
      if (e.status === 403) {
        setError(true);
      } else {
        history.push(pathname.Home);
      }
    },
  });

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    submit({
      password,
    });

  }, [password, submit]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.card}>
          <div className={styles.title}>Survey Monitoring Dashboard</div>
          <div className={styles.message}>
            <div>Provide a password to access the dashboard.</div>
          </div>

          <form onSubmit={handleSubmit}>

            <Input
              className={styles.input}
              autoComplete="off"
              name="password"
              onChange={handleChange}
              placeholder="Password"
              type="password" />

            <div className={styles.error}>
              {error && 'Password is incorrect'}
            </div>

            <ButtonActivityIndicator
              className={styles.btn}
              loading={isLoading}>
              Submit
            </ButtonActivityIndicator>
          </form>
        </div>

      </div>
    </div>
  );
};

export default SurveyMonitoringAuth;
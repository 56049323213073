import { useCallback } from 'react';
import { useNumberTableWarnings } from '@containers/SurveyForm/Context';
import { SurveyQuestionOption } from '@/types';
import { NumberInputTable } from './interfaces';
import NumberInput from './NumberInputTable.Input';
import styles from './style/NumberInputTable.List.css';

export const NumberInputTableList = ({ answerMap, handleChange, item, totalsMap }: NumberInputTable.Props) => {

  const [warnings] = useNumberTableWarnings();

  const renderItem = useCallback((option: SurveyQuestionOption) => {

    return (
      <div
        key={option.ordinal}
        className={styles.item}>
        <div className={styles.header}>{option.value}</div>
        <div>
          {item.matrixRows.map(row => (
            <div
              key={row.id}
              className={styles.row}>
              <div className={styles.name}>{row.value}</div>
              <div className={styles.cell}>
                <div className={styles.input}>
                  <NumberInput
                    invalid={warnings[row.id][option.id]}
                    validations={item.settings.validations}
                    onChange={handleChange(row.id, option.id)}
                    value={answerMap[row.id][option.id]} />
                </div>
              </div>
            </div>
          ))}
        </div>
        {item.settings.displayTotals &&
          <div className={styles.footer}>
            <div className={styles.name}>Total</div>
            <div className={styles.cell}>{totalsMap[option.id]}</div>
          </div>
        }
      </div>
    );
  }, [
    answerMap,
    handleChange,
    item.matrixRows,
    item.settings.validations,
    totalsMap,
    warnings,
  ]);

  return (
    <div className={styles.root}>
      {item.options.map(renderItem)}
    </div>
  );
};

export default NumberInputTableList;
import { useCallback } from 'react';
import { useQuestionLogicBuilderState } from '@containers/SurveyBuilder.Logic/hooks/useQuestionLogicBuilderState';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { SurveyActionType } from '@enums';
import { SurveyLogic } from '@/types';
import { QuestionOrdinalDropdown } from './Dropdown.Question.Ordinal';
import { SectionDropdown } from './Dropdown.Section';

type Props = {
  action: SurveyLogic.Action;
  updateActionIdentifier: (identifier: string) => void;
};

export const ActionOrdinalDropdown = (props: Props) => {
  const context = useQuestionLogicBuilderState();

  const [state] = useSurveyBuilderState();

  const findSection = useCallback((identifier: string) => {
    return state.survey.sections.find(f => f.identifier === identifier);
  }, [state.survey.sections]);

  const findQuestion = useCallback((identifier: string) => {
    return state.survey.questions.find(f => f.base.identifier === identifier);
  }, [state.survey.questions]);

  if (props.action.type === SurveyActionType.SkipToQuestion) {
    const question = findQuestion(props.action.identifier);
    return (
      <QuestionOrdinalDropdown
        items={context.skipToQuestionOptions}
        value={question.ordinal}
        onSelect={props.updateActionIdentifier} />
    );
  } else if (props.action.type === SurveyActionType.SkipToSection) {
    const section = findSection(props.action.identifier);
    return (
      <SectionDropdown
        items={context.skipToSectionOptions}
        value={section.ordinal}
        onSelect={props.updateActionIdentifier} />
    );
  } else if (props.action.type === SurveyActionType.IncludeSection) {
    const section = findSection(props.action.identifier);
    return (
      <SectionDropdown
        items={context.includeSectionOptions}
        value={section.ordinal}
        onSelect={props.updateActionIdentifier} />
    );
  } else {
    return null;
  }
};

export default ActionOrdinalDropdown;
import { AnalyticsEvent } from '@enums';

type BaseRecord<T extends AnalyticsEvent, D extends Record<string, unknown>> = {
  createdOn:  Date;
  data:       D;
  event:      T;
  id:         number;
  userId:     number;
};

export type Event<T extends AnalyticsEvent = AnalyticsEvent, K = EventRecordList[T]> = K;

export type EventRecordData<T extends AnalyticsEvent = AnalyticsEvent> = {
  page: string;
} & EventData<T>;

export type EventData<T extends AnalyticsEvent = AnalyticsEvent, K = DataList[T]> = K;

type EventRecordList = {
  [AnalyticsEvent.ViewArticle]: ViewArticle.Record;
  [AnalyticsEvent.ViewArticleTopic]: ViewArticleTopic.Record;
  [AnalyticsEvent.ViewPostAuthor]: ViewPostAuthor.Record;
  [AnalyticsEvent.ViewPostCommentAuthor]: ViewPostCommentAuthor.Record;
  [AnalyticsEvent.ViewPostTopic]: ViewPostTopic.Record;
  [AnalyticsEvent.ViewSuggestedTopic]: ViewSuggestedTopic.Record;
};

type DataList = {
  [AnalyticsEvent.ViewArticle]: ViewArticle.Data;
  [AnalyticsEvent.ViewArticleTopic]: ViewArticleTopic.Data;
  [AnalyticsEvent.ViewPostAuthor]: ViewPostAuthor.Data;
  [AnalyticsEvent.ViewPostCommentAuthor]: ViewPostCommentAuthor.Data;
  [AnalyticsEvent.ViewPostTopic]: ViewPostTopic.Data;
  [AnalyticsEvent.ViewSuggestedTopic]: ViewSuggestedTopic.Data;
};

export namespace ViewPostCommentAuthor {
  export type Data = {
    commentId: number;
  } & IPostId
    & IUserId;

  export type Record =
    BaseRecord<AnalyticsEvent.ViewPostCommentAuthor, Data>;

}

export namespace ViewPostTopic {
  export type Data =
    IPostId &
    ITopicId;

  export type Record =
    BaseRecord<AnalyticsEvent.ViewPostTopic, Data>;
}

export namespace ViewPostAuthor {
  export type Data =
    IPostId &
    IUserId;

  export type Record =
    BaseRecord<AnalyticsEvent.ViewPostAuthor, Data>;

}

export namespace ViewArticle {
  export type Data = IArticleId;

  export type Record =
    BaseRecord<AnalyticsEvent.ViewArticle, Data>;
}

export namespace ViewArticleTopic {
  export type Data =
    IArticleId &
    ITopicId;

  export type Record =
    BaseRecord<AnalyticsEvent.ViewArticleTopic, Data>;
}

export namespace ViewSuggestedTopic {
  export type Data = ITopicId;

  export type Record =
    BaseRecord<AnalyticsEvent.ViewSuggestedTopic, Data>;
}
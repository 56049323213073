import { useCallback, useMemo } from 'react';
import { SurveyQuestionMatrixRow } from '@/types';
import { SurveyActionType, SurveyQuestionConditionType } from '@enums';
import { useMatrixQuestionLogic, MatrixRowLogicContext } from './Context';
import { useGetDefaultIdentifier } from './hooks';

type Props = {
  row: SurveyQuestionMatrixRow;
} & ChildrenProps;

export const MatrixRowLogicBuilderContainer = ({ children, row }: Props) => {
  const getDefaultIdentifier = useGetDefaultIdentifier();
  const [logic, dispatch] = useMatrixQuestionLogic();

  const rowResponseLogic = useMemo(() => {
    return logic.response
      .filter(f => f.condition.value.row.identifier === row.base.identifier);
  }, [
    row.base.identifier,
    logic.response,
  ]);

  const updateActionIdentifier = useCallback((id: string) => (identifier: string) => {
    const action = rowResponseLogic
      .find(f => f.id === id)
      .action;

    dispatch({
      action: {
        identifier,
        type: action.type,
      },
      id,
      type: 'update-action',
    });
  }, [
    dispatch,
    rowResponseLogic,
  ]);

  const addItem = useCallback(() => {
    dispatch({
      rowIdentifier: row.base.identifier,
      type: 'add-item',
    });

  }, [
    dispatch,
    row.base.identifier,
  ]);

  const removeItem = useCallback((id: string) => () => {
    dispatch({
      id,
      type: 'remove-item',
    });

  }, [dispatch]);

  const updateActionType = useCallback((id: string) => (type: SurveyActionType) => {

    dispatch({
      action: {
        identifier: getDefaultIdentifier(type),
        type,
      },
      id,
      type: 'update-action',
    });
  }, [
    dispatch,
    getDefaultIdentifier,
  ]);

  const updateConditionValue = useCallback((id: string) =>  (identifier: string) => {
    dispatch({
      id,
      value: {
        option: { identifier },
        row: { identifier: row.base.identifier },
      },
      type: 'update-condition-value',
    });
  }, [
    dispatch,
    row.base.identifier,
  ]);

  const updateConditionType = useCallback((id: string) =>  (type: SurveyQuestionConditionType) => {

    dispatch({
      id,
      value: type,
      type: 'update-condition-type',
    });
  }, [
    dispatch,
  ]);

  const ctx = {
    addItem,
    canAddItem: true,
    items: rowResponseLogic,
    removeItem,
    updateActionIdentifier,
    updateActionType,
    updateConditionType,
    updateConditionValue,
  };

  return (
    <MatrixRowLogicContext.Provider value={ctx}>
      {children}
    </MatrixRowLogicContext.Provider>
  );
};

export default MatrixRowLogicBuilderContainer;
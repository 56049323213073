import { Component } from 'react';
import { connect } from 'react-redux';
import { CallType } from '@enums';
import * as utils from '@utils';
import { AvailabilityEventPopover } from './EventPopover.Availability';
import { AdHocCallEventPopover } from './EventPopover.AdHocCall';
import { ProjectCallEventPopover } from './EventPopover.ProjectCall';
import styles from './style/EventPopover.css';

const mapState = state => ({
  calls: state.calls,
  conferences: state.conferences,
});

class EventPopover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rect: this.getEventRect(),
      height: 0,
      options: {
        ...this.defaultOptions,
        ...props.options,
      },
    };
  }

  static compareFloats = (a, b) => {
    const ai = parseInt(a, 10);
    const bi = parseInt(b, 10);

    return (Number.isNaN(ai) && Number.isNaN(bi)) ||
           (ai === bi);
  }

  static compareRect = (a, b) => {
    if (!EventPopover.compareFloats(a.bottom, b.bottom)) return false;
    if (!EventPopover.compareFloats(a.height, b.height)) return false;
    if (!EventPopover.compareFloats(a.left, b.left)) return false;
    if (!EventPopover.compareFloats(a.right, b.right)) return false;
    if (!EventPopover.compareFloats(a.top, b.top)) return false;
    if (!EventPopover.compareFloats(a.width, b.width)) return false;
    if (!EventPopover.compareFloats(a.x, b.x)) return false;
    if (!EventPopover.compareFloats(a.y, b.y)) return false;
    return true;
  }

  static getEventMetadata = event => {
    if (!event || !event.extendedProps) {
      return {};
    }
    return event.extendedProps.metadata || {};
  }

  get defaultOptions() {
    return {
      showActions: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // listen for resize
    if (!prevProps.popover && this.props.popover) {
      this.hasScrolled = false;
      window.addEventListener('resize', this.windowChanged);
      this.props.scrollable.addEventListener('scroll', this.windowScroll, true);
    }

    // stop listen for resize
    if (!this.props.popover) {
      window.removeEventListener('resize', this.windowChanged);
      this.props?.scrollable.removeEventListener('scroll', this.windowScroll, true);
    }

    // update height of popover after it renders
    if (this.popoverEl && this.popoverEl.current) {
      const height = this.popoverEl.current.clientHeight;
      if (prevState.height !== height) {
        this.setState({ height });
      }
    }

    // update rect of calendar event
    const rect = this.getEventRect();
    if (!EventPopover.compareRect(prevState.rect, rect)) {
      this.setState({
        rect: this.getEventRect(),
      });
    }

    if (prevProps.options !== this.props.options &&
        prevProps.options.showActions !== this.props.options.showActions) {
      this.setState({
        options: {
          ...this.defaultOptions,
          ...this.props.options,
        },
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowChanged);
    this.props?.scrollable?.removeEventListener('scroll', this.windowScroll, true);
  }

  getEventRect = () => {
    if (!this.props.popover) {
      return {};
    }

    const el = document.getElementById(this.props.popover.el);

    if (!el) {
      return {};
    }

    return el.getBoundingClientRect();
  }

  getRootInline = () => {
    const WIDTH = utils.device.phone ? 220 : 300;
    const bodyRect = document.body.getBoundingClientRect();
    const elRect = this.state.rect;
    const bodyWidth = parseInt(bodyRect.width, 10);
    const bodyTop = parseInt(bodyRect.top, 10);
    const rectTop = parseInt(elRect.top, 10) || 0;
    const rectRight = parseInt(elRect.right, 10) || 0;
    const rectLeft = parseInt(elRect.left, 10) || 0;
    const rectX = parseInt(elRect.x, 10) || 0;

    const positionLeft = (bodyWidth / 2) > rectX;

    const x = positionLeft
      ? parseInt(rectLeft - 15, 10)
      : parseInt((rectRight - WIDTH) - 15, 10);
    const y = parseInt(rectTop - bodyTop + 45, 10);

    return {
      className: positionLeft ? styles.left : styles.right,
      style: {
        left: x,
        top: y || 0,
        width: WIDTH,
      },
    };
  };

  windowChanged = () => {
    this.props.dismiss();
  };

  windowScroll = () => {
    if (!this.hasScrolled) {
      this.hasScrolled = true;
    } else {
      // this.props.dismiss();
    }
  };

  renderBody = () => {
    const event = this.props.popover.event;
    const md = EventPopover.getEventMetadata(event);

    if (!md.call || !md.call.id) {
      return (
        <AvailabilityEventPopover
          timeStart={event.start}
          timeEnd={event.end}
          onDelete={this.props.remove} />
      );
    }

    const call = this.props.calls[md.call.id];
    const conference = this.props.conferences[md.call.id];

    if (call.typeId === CallType.Project) {
      return (
        <ProjectCallEventPopover
          item={call}
          conferenceIdentifier={conference.conferenceIdentifier}
          onCancel={this.props.cancelProjectCall}
          showActions={this.state.options.showActions}
          withUserId={md.with.id} />
      );
    } else if (call.typeId === CallType.AdHoc) {
      return (
        <AdHocCallEventPopover
          item={call}
          conferenceIdentifier={conference.conferenceIdentifier}
          onCancel={this.props.cancelAdHocCall}
          showActions={this.state.options.showActions} />
      );
    }
  };

  render() {

    const { className, style } = this.getRootInline();

    // TODO: move cancel call action into container

    return (
      <div
        className={className}
        ref={this.popoverEl}
        style={style}>
        {this.renderBody()}
      </div>
    );
  }
}

export default connect(mapState, null)(EventPopover);
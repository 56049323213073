import { useCallback } from 'react';
import { InvalidContentHandler } from '@remirror/core';
import { useRemirror, ReactComponentExtension } from '@remirror/react';
import { DocExtension } from '@remirror/extension-doc';
import { TextExtension } from '@remirror/extension-text';
import { ParagraphExtension } from '@remirror/extension-paragraph';
import { PlaceholderExtension } from '@remirror/extension-placeholder';
import { SurveyRichText } from '@/types/survey.rich-text';
import {
  PipedResponseExtension,
  TemplateCategoryExtension,
  TemplateCompetitorExtension,
  TemplateTargetExtension,
} from '../Extension';

type Props = {
  initialState?: SurveyRichText.RootNode;
  placeholder?: string;
};

export const useSurveyQuestionTextEditor = ({ initialState, placeholder }: Props) => {

  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
    console.error('Invalid Content', invalidContent);
    return transformers.remove(json, invalidContent);
  }, []);

  return useRemirror({
    content: initialState,
    extensions: () => [
      new DocExtension({}),
      new PlaceholderExtension({ placeholder }),
      new ParagraphExtension({}),
      new TextExtension(),
      new ReactComponentExtension({}),
      new TemplateCategoryExtension({}),
      new TemplateCompetitorExtension({}),
      new TemplateTargetExtension({}),
      new PipedResponseExtension({}),
    ],
    onError,
  });

};
import { useCallback, useState, useRef } from 'react';
import { cx } from '@utils';
import { Button } from 'components/Button';
import bg1 from 'static/images/website/careers/culture-s.png';
import bg2 from 'static/images/website/careers/culture-m.png';
import bg3 from 'static/images/website/careers/culture-l.png';
import styles from './style/Landing.css';

type Props = unknown;

export const Landing = (props: Props) => {
  const [loaded, setLoaded] = useState(false);
  const viewButton = useRef<HTMLButtonElement>();

  const handleView = useCallback(() => {
    // hack to fix required double click to scroll
    viewButton.current.click();
  }, []);

  return (
    <div className={styles.root}>

      <div className={styles.wrap}>

        <div className={styles.main}>
          <div className={styles.graphic}>
            <picture>
              <source
                srcSet={bg3}
                media="(min-width: 1000px)" />
              <source
                srcSet={bg2}
                media="(min-width: 800px)" />
              <img
                alt="creative environment"
                src={bg1}
                className={cx(styles.img, { [styles.ready]: loaded })}
                onLoad={() => setLoaded(true)} />
            </picture>
          </div>

          <div className={styles.info}>
            <h1 className={styles.title}>Accelerate your career in a high-performing and creative environment</h1>
            <div className={styles.subtitle}>{`If you’re ready to seize tremendous opportunity, take ownership, and do meaningful work that moves the needle, you’ve come to the right place.`}</div>
            <div className={styles.btnset}>
              <Button
                ref={viewButton}
                className={styles.btn}
                color="primary"
                href="#opportunities"
                onClick={handleView}>
                View Opportunities
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Landing.displayName = 'Careers.Landing';
import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState, useSurveySection, useSectionQuestions } from '@containers/SurveyBuilder/hooks';
import { SurveySectionLogicBuilder } from 'components/SurveyBuilder.Section.Logic/SurveySectionLogicBuilder';
import { useIncludeOrdinalColumn } from './hooks/useIncludeOrdinalColumn';

type Props = {
  className?: string;
  identifier: string;
};

export const SurveySectionLogicBuilderContainer = (props: Props) => {
  const [state, dispatch] = useSurveyBuilderState();

  const includeOrdinalColumn = useIncludeOrdinalColumn();

  const toggleEditingOn = useCallback((ordinal: number) => () => {
    dispatch({
      type: 'toggle-logic-editing',
      ordinal,
    });
  }, [dispatch]);

  const toggleHiddenSection = useCallback((value: boolean) => {
    dispatch({
      type: 'toggle-section-hidden',
      identifier: props.identifier,
    });
  }, [dispatch, props.identifier]);

  const section = useSurveySection(props.identifier);
  const questions = useSectionQuestions(props.identifier);

  return (
    <SurveySectionLogicBuilder
      className={props.className}
      editingOrdinal={state.editing.logicOrdinal}
      includeOrdinalColumn={includeOrdinalColumn}
      questions={questions}
      section={section}
      toggleEditingOn={toggleEditingOn}
      toggleHidden={toggleHiddenSection}
    />
  );
};

export default SurveySectionLogicBuilderContainer;
import * as consts from '@consts';
import Button from 'components/Button';
import styles from './style/NoPaymentMethod.css';

export function NoPaymentMethod() {
  return (
    <div className={styles.content}>
      <div className={styles.text}>No billing details have been entered.</div>
      <div className={styles.text}>Add a payment method to get started.</div>
      <Button
        className={styles.register}
        variant='brick'
        to={consts.path.Settings.EnterpriseBillingNewMethod}
        title='Add Account' />
    </div>
  );
}

export default NoPaymentMethod;
import { useCallback, useState } from 'react';
import { SelectInput } from './SelectInput';
import SelectList from './SelectList';
import { SelectBaseProps } from './interfaces';

type Props<T> = {
  className?: string;
} & SelectBaseProps<T>;

const SelectBase = <T extends unknown>(props: Props<T>) => {
  const [open, setVisibility] = useState(false);
  const [selected, setSelected] = useState('');

  const toggleList = useCallback(() => {
    setVisibility(!open);
  }, [open, setVisibility]);

  const handleBlur = useCallback(() => {
    if (open) toggleList();
  }, [open, toggleList]);

  const handleSelect = useCallback(item => {

    props.onSelect(item);

    setSelected(props.getItemValue(item));

  }, [setSelected, props.onSelect, props.getItemValue, props.value]);

  const items = props.onChange
              ? props.onChange(props.options)
              : props.options;
  return (
    <>
      <SelectInput
        autoFocus={props.autoFocus}
        className={props.className}
        onBlur={handleBlur}
        onClick={toggleList}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        value={props.value ?? selected} />
      <SelectList
        open={open}
        getOptionItem={props.getItemValue}
        items={items}
        onMouseDown={handleSelect} />
    </>
  );
};

export { SelectBase };
export default SelectBase;
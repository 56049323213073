import { useCallback } from 'react';
import { usePaidMessagingModalState } from '@containers/PlatformMessage/hooks/usePaidMessagingModalState';
import * as PlatformMessage from '@containers/PlatformMessage/interfaces';
import { Button } from 'components/Button';
import { ButtonProps } from 'components/Button/interfaces';

type Props = {
  item: PlatformMessage.PaidMessagingRecipientUser;
} & Omit<ButtonProps, 'onClick'>;

export const PaidMessageButton = (props: Props) => {
  const [_, setRecipient] = usePaidMessagingModalState();

  const setAuthMessagingModalOpen = useCallback(() => {
    setRecipient(props.item);
  }, [
    props.item,
    setRecipient,
  ]);

  if (!props.item) return null;

  return (
    <Button
      {...props}
      onClick={setAuthMessagingModalOpen} />
  );
};
import qs from 'query-string';
import http from '@services/http';
import * as cancellation from '@services/http/cancellation';
import { members, projects } from '$admin/api/transformers';
import { Members } from './interfaces';

export const name = ({ cancelToken, ...data }: Members.SearchName.Request) => {
  const query = qs.stringify(data);

  return http.get<Members.SearchName.ResponseUnparsed>(`/admin/members/search?${query}`, {
    cancelToken,
  })
  .then<Members.SearchName.Response>(members.transformProfileProps)
  .catch<null>(e => cancellation.ignoreRequestCancellationError(e, null));
};

export const unresolvedLinkedinProfiles = (data: Members.SearchUnresolvedLinkedinProfiles.Request) => {
  const query = qs.stringify(data);

  return http.get<Members.SearchUnresolvedLinkedinProfiles.Response>(`/admin/members/search/linkedin?${query}`);
};

export const statuses = (data: Members.SearchStatuses.Request) => {
  const query = qs.stringify(data, { arrayFormat: 'bracket' });

  return http.get<Members.SearchStatuses.Response<string>>(`/admin/members/search/statuses?${query}`)
  .then<Members.SearchStatuses.Response>(data => ({
    items: data.items.map(projects.transformProjectMember),
    pagination: data.pagination,
  }));
};
import {
  ProjectSurveyBuilderState,
} from '@containers/SurveyBuilder';
import { ProjectSurveyEditor } from '@screens/ProjectSurveyEditor';

export const ProjectSurveyEditorContainer = () => {
  return (
    <ProjectSurveyBuilderState>
      <ProjectSurveyEditor />
    </ProjectSurveyBuilderState>
  );
};

export default ProjectSurveyEditorContainer;

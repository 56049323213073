import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks';
import { useProjectCapabilities } from '@containers/Store';
import * as enums from '@enums';
import { useConditionalRender } from '@utils';
import ProjectFilters from './ProjectFilters';
import { ProjectOverview } from './ProjectOverview';
import ProjectScreeningQuestions from './ProjectScreeningQuestions';
import { TargetCompany } from './TargetCompany';
import styles from './style.css';

const mapState = (state: Store.State) => state.group.features;

type Props = unknown;

const ProjectTabDetails = (_: Props) => {
  const project = useProjectOrLatestChild();
  const capabilities = useProjectCapabilities(project.id);
  const groupFeatures = useSelector(mapState);

  const $TargetCompany = useConditionalRender(groupFeatures.projectTargetCompany);
  const $ScreeningQuestions = useConditionalRender(project.projectType === enums.ProjectType.Call);

  const canEdit = useMemo(() => {
    return capabilities.canManage &&
      project.projectStatusId === enums.ProjectStatus.Active;
  }, [capabilities, project.projectStatusId]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <ProjectOverview
          editable={canEdit}
          item={project} />

        <$TargetCompany>
          <TargetCompany
            editable={canEdit}
            item={project} />
        </$TargetCompany>

        <$ScreeningQuestions>
          <ProjectScreeningQuestions
            editable={canEdit}
            item={project} />
        </$ScreeningQuestions>

        <ProjectFilters
            editable={canEdit}
            item={project} />
      </div>
    </div>
  );
};

export default ProjectTabDetails;

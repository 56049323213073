import axios from 'axios';
import { safeJsonDate } from '@utils';
import { Projects } from './interfaces';

const metricsHttp = axios.create({
  baseURL: process.env.BACKEND_BASE_URL,
  withCredentials: true,
});

const getProjectSurveyMetrics = (data: Projects.GetSurveyMetrics.Request) => {
  return metricsHttp.get<Projects.GetSurveyMetrics.Response<string>>(`/projects/${data.projectId}/survey-metrics`)
    .then(res => ({
      ...res.data,
      project: {
        ...res.data.project,
        createdOn: safeJsonDate(res.data.project.createdOn),
      },
    }));
};

const getProjectSurveyMetricsAuth = (data: Projects.FetchSurveyMetricsAuth.Request) => {
  return metricsHttp.get<Projects.FetchSurveyMetricsAuth.Response>(`/projects/${data.projectId}/survey-metrics/auth`)
    .then(res => res.data);
};

const projectSurveyMetricsAuth = ({ projectId, ...body }: Projects.SurveyMetricsAuth.Request) => {
  return metricsHttp.post< Projects.SurveyMetricsAuth.Response>(`/projects/${projectId}/survey-metrics/auth`, body);
};

export const surveyMetrics = {
  auth: {
    get: getProjectSurveyMetricsAuth,
    post: projectSurveyMetricsAuth,
  },
  get: getProjectSurveyMetrics,
};
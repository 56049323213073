import { InputHTMLAttributes, useRef, useCallback, ChangeEvent } from 'react';
import { Search, X } from 'react-feather';
import { cx } from '@utils';
import { Spinner } from 'components/ActivityIndicator';
import inputStyles from 'components/Input/style.css';
import styles from './style/SearchBar.css';

type Props = {
  containerClassName?: string;
  inputClassName?: string;
  loading?: boolean;
} & Pick<InputHTMLAttributes<HTMLInputElement>, 'placeholder' | 'value' | 'onChange'>;

export const SearchBar = ({ containerClassName, inputClassName, loading, ...inputProps }: Props) => {
  const inputRef = useRef<HTMLInputElement>();
  const clearInput = useCallback(() => {
    inputRef.current.value = null;
    const ev = new Event('input', { bubbles: true });
    inputRef.current.dispatchEvent(ev);
  }, []);
  return (
    <div className={cx(styles.container, containerClassName)}>
      <Search className={styles.searchIcon} />
      <input
        ref={inputRef}
        className={cx(styles.input, inputStyles.input, inputClassName)}
        {...inputProps} />
      {loading &&
        <Spinner className={styles.spinner} />}
      {inputProps.value &&
        <X
          className={styles.clear}
          onClick={clearInput} />}
    </div>
  );
};
import * as consts from '@consts';
import { useDevice } from '@utils';
import appStoreBadge from 'static/icons/app-store.svg?url';

type Props = {
  className?: string;
  imgClassName?: string;
};

export const AppStore = (props: Props) => {
  const device = useDevice();
  const url = device.phone || device.tablet ?
                consts.urls.AppStoreUrl :
                consts.urls.AppStoreViewUrl;
  return (
    <a
      className={props.className}
      href={url}
      target="_blank"
      rel="noopener noreferrer">
      <img
        src={appStoreBadge}
        className={props.imgClassName} />
    </a>
  );
};
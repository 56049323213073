import { useCallback, useEffect } from 'react';
import { cx, useEventListener } from '@utils';
import { useScrollLock } from 'components/Modal/hooks/useScrollLock';
import { Portal } from 'components/Portal';
import styles from './style/Base.css';

type Props = {
  classes?: {
    root?:  string;
    wrap?:  string;
    close?: string;
  };
  disableEscapeClose?:  boolean;
  disableOverlayClick?: boolean;
  disablePortal?:       boolean;
  onClose?:             () => unknown;
  open:                 boolean;
} & ChildrenProps;

const ModalBase = ({ classes, open, disableEscapeClose, disableOverlayClick, onClose, ...props }: Props) => {
  const scroll = useScrollLock(open);

  const handleScrollLock = useCallback(() => {
    // open
    //   ? scroll.lock()
    //   : scroll.unlock();
  }, [
    open,
    scroll,
  ]);

  const handleClose = useCallback(() => {
    !disableOverlayClick && onClose?.();
  }, [disableOverlayClick, onClose]);

  const handleKeydown = useCallback((e: KeyboardEvent) => {
    if (!open) return;

    switch (e.key) {
      case 'Escape': {
        if (!disableEscapeClose) {
          handleClose();
        }
        break;
      }
    }
  }, [disableEscapeClose, open, handleClose]);

  useEventListener('keydown', handleKeydown);

  useEffect(() => {
    handleScrollLock();
  }, [
    handleScrollLock,
    open,
  ]);

  if (!open) return null;

  return (
    <Portal disablePortal={props.disablePortal}>
      <div className={styles.root}>
        <div className={cx(styles.wrap, classes.root)}>
          <div className={cx(styles.main, classes.wrap)}>
            {props.children}
          </div>
        </div>
        <div
          className={styles.overlay}
          onClick={handleClose} />
      </div>
    </Portal>
  );
};

ModalBase.defaultProps = {
  classes: {},
  disableEscapeClose: false,
  disablePortal: false,
  disableOverlayClick: false,
};

export type ModalBaseProps = Props;

export { ModalBase };
export default ModalBase;
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { ConferenceSocketEvent } from '@enums';
import { nullableDate } from '@utils';
import Toast from 'components/Toast';
import { ConferenceCoordinatorContext } from './Context';
import { useSocket } from './hooks/useSocket';
import { useTwilioVideo } from './hooks/useTwilioVideo';
import { useTwilioVoice } from './hooks/useTwilioVoice';
import * as $session from './Session';
import { Coordinator } from './interfaces';

export function ConferenceCoordinatorContainer({ children }: ChildrenProps) {
  const [state, dispatch] = useReducer(coordinator, createInitialState());

  const sock = useSocket();
  const video = useTwilioVideo();
  const voice = useTwilioVoice();

  const handleLeave = useCallback((data: Coordinator.Actions.LeaveData) => {
    sock.raw.emit(ConferenceSocketEvent.Leave, {
      conferenceIdentifier: data.conferenceIdentifier,
      end: data.end,
    });

    sock.close();
    video.disconnect();
    voice.disconnect();

    dispatch({
      type: 'leave',
      data: {
        conferenceIdentifier: data.conferenceIdentifier,
        title: data.title,
        redirect: data.redirect,
        location: data.location,
      },
    });
  }, [sock, video, voice]);

  const stateRef = useRef(state);
  const videoRef = useRef(video);
  const voiceRef = useRef(voice);
  const leaveRef = useRef(handleLeave);

  useEffect(() => {
    leaveRef.current = handleLeave;
  }, [handleLeave]);

  useEffect(() => {
    videoRef.current = video;
  }, [video]);

  useEffect(() => {
    voiceRef.current = voice;
  }, [voice]);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const handleNegotiate = useCallback((data: Coordinator.Actions.NegotiateData) => {
    return new Promise<Coordinator.Actions.NegotiateResponseData>(resolve => {
      dispatch({
        type: 'negotiate',
        data,
      });

      sock.initialize();

      sock.raw.emit(ConferenceSocketEvent.Negotiate, {
        conferenceIdentifier: data.conferenceIdentifier,
        name: data.name,
        pin: data.pin,
      });

      sock.raw.once(ConferenceSocketEvent.Negotiate, response => {
        if (response.success !== true) {
          resolve({
            success: false,
            reason: response.reason,
          });
        }

        if (response.success) {
          $session.setSession(response.conferenceIdentifier, response.sessionId);

          sock.raw.io.on('reconnect', () => {
            sock.raw.emit(ConferenceSocketEvent.NegotiateReconnect, {
              conferenceIdentifier: data.conferenceIdentifier,
              sessionId: $session.getSession(data.conferenceIdentifier),
            });
          });

          dispatch({
            type: 'join-pre-room',
            data: {
              conferenceIdentifier: response.conferenceIdentifier,
              joiningMeeting: false,
              pid: response.pid,
              call: {
                ...response.call,
                start: nullableDate(response.call.start),
                end: nullableDate(response.call.end),
              },
              conference: {
                ...response.conference,
                started: nullableDate(response.conference.started),
              },
              dial: response.dial,
              settings: response.settings,
              features: response.features,
            },
          });

          sock.raw.on(ConferenceSocketEvent.ConferenceUpdate, response => {
            dispatch({
              type: 'update-conference',
              data: {
                conferenceIdentifier: data.conferenceIdentifier,
                conference: {
                  ...response.conference,
                  started: nullableDate(response.conference.started),
                },
              },
            });
          });

          sock.raw.on(ConferenceSocketEvent.ConferenceEnd, response => {
            const instance = stateRef.current.conference;
            if (instance.status === 'meeting-room') {
              leaveRef.current({
                conferenceIdentifier: response.conferenceIdentifier,
                title: instance.conference.title,
                redirect: instance.conference.redirect
                  ? 'location'
                  : 'ended',
                location: instance.conference.redirect,
              });
            } else {
              leaveRef.current({
                conferenceIdentifier: response.conferenceIdentifier,
                title: instance.status === 'waiting-room' ? instance.conference.title : null,
                redirect: 'ended',
              });
            }
          });

          resolve({ success: true });
        }
      });
    });
  }, [sock]);

  const handleUpdatePreRoom = useCallback((data: Coordinator.Actions.UpdatePreRoomData) => {
    dispatch({
      type: 'update-pre-room',
      data: {
        conferenceIdentifier: data.conferenceIdentifier,
        settings: data.settings,
        joiningMeeting: data.joiningMeeting,
      },
    });
    return Promise.resolve();
  }, []);

  const setupEnterHandler = useCallback(() => {
    sock.raw.once(ConferenceSocketEvent.Enter, async response => {
      if (response.type === 'video') {
        try {
          const instance = stateRef.current.conference as Coordinator.Conference.PreRoom;

          videoRef.current.disconnect();

          await videoRef.current.connect(response.token, response.roomName);
          videoRef.current.setupLocalTracks(instance.settings.microphone, instance.settings.camera);

          sock.raw.on(ConferenceSocketEvent.MuteParticipant, response => {
            Toast.info({
              title: `You were muted by the Host`,
            });
            videoRef.current.muteSelf();
          });
        } catch (err) {
          console.error(err);
        }
      } else if (response.type === 'voice') {
        try {
          const instance = stateRef.current.conference as Coordinator.Conference.PreRoom;

          await voiceRef.current.connect(response.token, instance.settings.microphone);
        } catch (err) {
          console.error(err);
        }
      }

      dispatch({
        type: 'join-meeting-room',
        data: {
          conferenceIdentifier: response.conferenceIdentifier,
          participants: response.participants,
        },
      });

      sock.raw.on(ConferenceSocketEvent.ParticipantEntered, response => {
        dispatch({
          type: 'participant-entered',
          data: {
            conferenceIdentifier: response.conferenceIdentifier,
            participant: response.participant,
          },
        });
      });

      sock.raw.on(ConferenceSocketEvent.WaitingRoomParticipant, response => {
        dispatch({
          type: 'participant-entered',
          data: {
            conferenceIdentifier: response.conferenceIdentifier,
            participant: response.participant,
          },
        });
      });

      sock.raw.on(ConferenceSocketEvent.ParticipantUpdated, response => {
        dispatch({
          type: 'participant-updated',
          data: {
            conferenceIdentifier: response.conferenceIdentifier,
            participant: response.participant,
          },
        });
      });

      sock.raw.on(ConferenceSocketEvent.ParticipantLeft, response => {
        const instance = stateRef.current.conference;
        if (instance.status === 'meeting-room' && response.pid === instance.pid) {
          leaveRef.current({
            conferenceIdentifier: response.conferenceIdentifier,
            title: instance.conference.title,
            redirect: 'removed-room',
          });
        } else {
          dispatch({
            type: 'participant-left',
            data: {
              conferenceIdentifier: response.conferenceIdentifier,
              pid: response.pid,
            },
          });
        }
      });

      sock.raw.on(ConferenceSocketEvent.ParticipantsList, response => {
        dispatch({
          type: 'participants-list',
          data: {
            conferenceIdentifier: response.conferenceIdentifier,
            participants: response.participants,
          },
        });
      });
    });
  }, [sock]);

  const handleJoin = useCallback((data: Coordinator.Actions.JoinData) => {
    return new Promise<void>(resolve => {

      if (stateRef.current.conference.status === 'pre-room') {
        dispatch({
          type: 'update-pre-room',
          data: {
            conferenceIdentifier: data.conferenceIdentifier,
            joiningMeeting: true,
          },
        });
      }

      sock.raw.emit(ConferenceSocketEvent.Join, {
        conferenceIdentifier: data.conferenceIdentifier,
        visibility: data.visibility,
      });

      setupEnterHandler();

      sock.raw.once(ConferenceSocketEvent.Join, response => {
        if (response.joined === 'waiting-room') {
          sock.raw.once(ConferenceSocketEvent.WaitingRoomReject, response => {
            leaveRef.current({
              conferenceIdentifier: response.conferenceIdentifier,
              title: stateRef.current.conference.status === 'waiting-room' ? stateRef.current.conference.conference.title : null,
              redirect: 'removed-waiting-room',
            });
          });

          sock.raw.on(ConferenceSocketEvent.WaitingRoomStatus, response => {
            dispatch({
              type: 'update-waiting-room-status',
              data: {
                conferenceIdentifier: response.conferenceIdentifier,
                waitingType: response.waitingType,
              },
            });
          });

          dispatch({
            type: 'join-waiting-room',
            data: {
              conferenceIdentifier: response.conferenceIdentifier,
              waitingType: response.waitingType,
            },
          });
        }
        else if (response.joined === 'meeting-room') {
          // do nothing wait for enter socket event
        }
        resolve();
      });
    });
  }, [sock, setupEnterHandler]);

  const handleWaitingRoomAdmit = useCallback((data: Coordinator.Actions.WaitingRoomAdmitData) => {
    sock.raw.emit(ConferenceSocketEvent.WaitingRoomAdmit, {
      conferenceIdentifier: data.conferenceIdentifier,
      pid: data.pid,
    });
  }, [sock]);

  const handleWaitingRoomReject = useCallback((data: Coordinator.Actions.WaitingRoomRejectData) => {
    sock.raw.emit(ConferenceSocketEvent.WaitingRoomReject, {
      conferenceIdentifier: data.conferenceIdentifier,
      pid: data.pid,
    });
  }, [sock]);

  const handleMuteParticipant = useCallback((data: Coordinator.Actions.MuteParticipantData) => {
    sock.raw.emit(ConferenceSocketEvent.MuteParticipant, {
      conferenceIdentifier: data.conferenceIdentifier,
      sid: data.sid,
      pid: data.pid,
    });
  }, [sock]);

  const handleGiveHost = useCallback((data: Coordinator.Actions.GiveHostData) => {
    sock.raw.emit(ConferenceSocketEvent.GiveHost, {
      conferenceIdentifier: data.conferenceIdentifier,
      pid: data.pid,
      selfKeepHost: true,
    });
  }, [sock]);

  const handleRemoveParticipant = useCallback((data: Coordinator.Actions.RemoveParticipantData) => {
    sock.raw.emit(ConferenceSocketEvent.RemoveParticipant, {
      conferenceIdentifier: data.conferenceIdentifier,
      pid: data.pid,
    });
  }, [sock]);

  const handleChangeParticipantVisibility = useCallback((data: Coordinator.Actions.ChangeParticipantVisibilityData) => {
    sock.raw.emit(ConferenceSocketEvent.UpdateParticipantVisibility, {
      conferenceIdentifier: data.conferenceIdentifier,
      pid: data.pid,
      visibility: data.visibility,
    });
  }, [sock]);

  const value = {
    state,
    negotiate: handleNegotiate,
    updatePreRoom: handleUpdatePreRoom,
    join: handleJoin,
    waitingRoomAdmit: handleWaitingRoomAdmit,
    waitingRoomReject: handleWaitingRoomReject,
    muteParticipant: handleMuteParticipant,
    giveHost: handleGiveHost,
    removeParticipant: handleRemoveParticipant,
    changeParticipantVisibility: handleChangeParticipantVisibility,
    leave: handleLeave,
  };

  return (
    <ConferenceCoordinatorContext.Provider value={value}>
      {children}
    </ConferenceCoordinatorContext.Provider>
  );
}

// negotiate -> pre-room -> waiting room -> meeting room
// -or-
// negotiate -> pre-room -> meeting room
function coordinator(state: Coordinator.State, action: Coordinator.Actions.Action): Coordinator.State {
  switch (action.type) {
    case 'negotiate': {
      return {
        ...state,
        conference: {
          conferenceIdentifier: action.data.conferenceIdentifier,
          status: 'negotiating',
          name: action.data.name,
        },
      };
    }
    case 'join-pre-room': {
      return {
        ...state,
        conference: {
          conferenceIdentifier: state.conference.conferenceIdentifier,
          pid: action.data.pid,
          status: 'pre-room',
          joiningMeeting: action.data.joiningMeeting,
          call: action.data.call,
          conference: action.data.conference,
          dial: action.data.dial,
          settings: action.data.settings,
          features: action.data.features,
        },
      };
    }
    case 'update-pre-room': {
      if (state.conference.status !== 'pre-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          settings: action.data.settings != null
            ? {
              ...state.conference.settings,
              ...action.data.settings,
            }
            : state.conference.settings,
          joiningMeeting: action.data.joiningMeeting != null
            ? action.data.joiningMeeting
            : state.conference.joiningMeeting,
        },
      };
    }
    case 'join-waiting-room': {
      if (state.conference.status !== 'pre-room') return state;
      return {
        ...state,
        conference: {
          conferenceIdentifier: state.conference.conferenceIdentifier,
          pid: state.conference.pid,
          status: 'waiting-room',
          waitingType: action.data.waitingType,
          call: state.conference.call,
          conference: state.conference.conference,
          dial: state.conference.dial,
          settings: state.conference.settings,
          features: state.conference.features,
        },
      };
    }
    case 'join-meeting-room': {
      if (state.conference.status !== 'pre-room' && state.conference.status !== 'waiting-room') return state;
      return {
        ...state,
        conference: {
          conferenceIdentifier: state.conference.conferenceIdentifier,
          pid: state.conference.pid,
          status: 'meeting-room',
          call: state.conference.call,
          conference: state.conference.conference,
          dial: state.conference.dial,
          settings: state.conference.settings,
          participants: action.data.participants,
          features: state.conference.features,
        },
      };
    }
    case 'participant-entered':
    case 'participant-updated': {
      if (state.conference.status !== 'meeting-room') return state;
      const existing = state.conference.participants.filter(p => p.id !== action.data.participant.id);
      return {
        ...state,
        conference: {
          ...state.conference,
          participants: [...existing, action.data.participant],
        },
      };
    }
    case 'participant-left': {
      if (state.conference.status !== 'meeting-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          participants: [...state.conference.participants.filter(p => p.id !== action.data.pid)],
        },
      };
    }
    case 'participants-list': {
      if (state.conference.status !== 'meeting-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          participants: action.data.participants,
        },
      };
    }
    case 'leave': {
      return {
        ...state,
        conference: {
          conferenceIdentifier: action.data.conferenceIdentifier,
          status: 'left',
          title: action.data.title,
          redirect: action.data.redirect,
          location: action.data.location,
        },
      };
    }
    case 'update-conference': {
      if (state.conference.status !== 'pre-room' && state.conference.status !== 'waiting-room' && state.conference.status !== 'meeting-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          conference: {
            ...state.conference.conference,
            ...action.data.conference,
          },
        },
      };
    }
    case 'update-waiting-room-status': {
      if (state.conference.status !== 'waiting-room') return state;
      return {
        ...state,
        conference: {
          ...state.conference,
          waitingType: action.data.waitingType,
        },
      };
    }
  }
}

function createInitialState(): Coordinator.State {
  return {
    conference: null,
  };
}
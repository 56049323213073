import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import * as enums from '@enums';
import { useStepper } from '@utils';
import * as consts from '@consts';
import { SurveyCompletion } from '@screens/ProjectOnboarding';
import * as selectors from '@store/selectors';
import { useFetchConsultantReferralSourcingState } from 'components/ProjectInviteReferral/hooks';
import { selectCompletedScreens } from './selectors';
import { ProjectConsultantReferralSourcingContext, ProjectOnboardingContext } from './Context';
import { useMatchProjectIdFromRoute } from './hooks';
import { ProjectOnboardingContextValue } from './interfaces';

type Props = unknown;

export const ProjectOnboardingCompletionContainer = () => {
  const { projectId } = useMatchProjectIdFromRoute();

  const validateRoute = useValidateRoute(projectId);
  const sourcing = useFetchConsultantReferralSourcingState(projectId);

  useEffect(validateRoute, [validateRoute]);

  return (
    <ProjectConsultantReferralSourcingContext.Provider value={sourcing}>
      <Switch>
        <Route
          component={SurveyCompletion}
          exact
          path={consts.path.Onboarding.Projects.Completion} />
        <Route
          component={ProjectOnboardingContinueContainer}
          exact
          path={consts.path.Onboarding.Projects.Continue} />
      </Switch>
    </ProjectConsultantReferralSourcingContext.Provider>
  );
};

ProjectOnboardingCompletionContainer.displayName = 'ProjectOnboarding.Completion.Container';

const ProjectOnboardingContinueContainer = (props: Props) => {
  const { projectId } = useMatchProjectIdFromRoute();

  const screens: React.ComponentType[] = useSelector(selectCompletedScreens(projectId));
  const [steps] = useState(screens);
  const [Screen, stepperActions, step] = useStepper(steps);

  const hasNextStep = useMemo(() => {
    return step !== screens.length - 1;
  }, [
    step,
    screens,
  ]);

  const context: ProjectOnboardingContextValue = {
    back: stepperActions.back,
    goTo: stepperActions.goTo,
    hasNextStep,
    next: stepperActions.next,
    projectId,
  };

  return (
    <ProjectOnboardingContext.Provider value={context}>
      <Screen />
    </ProjectOnboardingContext.Provider>
  );
};

ProjectOnboardingContinueContainer.displayName = 'ProjectOnboarding.Continue.Container';

const useValidateRoute = (projectId: number) => {
  const history = useHistory();

  const record = useSelector(selectors.pipeline.expert.record(projectId));

  const validate = useCallback(() => {
    const statuses = [
      enums.UserProjectStatus.Active,
      enums.UserProjectStatus.Inactive,
      enums.UserProjectStatus.InReview,
    ];

    if (!statuses.includes(record?.statusId)) {
      history.replace(consts.pathname.HOME);
    }
  }, [
    history,
    record?.statusId,
  ]);

  return validate;
};
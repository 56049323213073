import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const mapState = (state: Store.State) => ({
  user: state.user,
});

export function useIdentifyUser() {
  const { user } = useSelector(mapState);
  const location = useLocation();

  const identify = useCallback(() => {

    if (user.id) {
      window.zE('webWidget', 'identify', {
        email: user.contact.email,
        name: user.profile.fullname,
      });

      window.zE('webWidget', 'updatePath', {
        title: 'Vancery',
        url: location.pathname,
      });
    }

  }, [user, location.pathname]);

  return identify;
}
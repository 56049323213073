import { useMemo } from 'react';
import { useCompletedSurvey } from '@containers/SurveyResponse/hooks/useCompletedSurvey';
import * as survey from '@containers/Survey/utils';
import { cx } from '@utils';
import { SurveyResponseQuestionText, SurveyResponseAnswer } from 'components/SurveyResponse';
import { SurveyAnswerProps } from './interfaces';
import styles from './style.css';

const ProjectSurveyResponseReviewItem = ({ children, item }: SurveyAnswerProps) => {
  const context = useCompletedSurvey();

  const className = useMemo(() => cx(styles.question, {
    [styles.hidden]: survey.response.isAnswerHidden(item),
  }), [item]);

  return (
    <div className={className}>
      <div>
        <SurveyResponseQuestionText
          item={item.question} />
        <SurveyResponseAnswer
          className={styles.answer}
          item={item}
          showHidden={true} />
      </div>
      {children}
    </div>
  );
};

export default ProjectSurveyResponseReviewItem;
import { useCallback } from 'react';
import { SurveyBuilderQuestionContainer } from '@containers/SurveyBuilder.Question/QuestionContainer';
import { useSurveyBuilderState, useCanAddQuestion } from '@containers/SurveyBuilder/hooks';
import { AddQuestionButton } from '@presentation';
import { QuestionBuilderItem } from 'components/SurveyBuilder.Question/QuestionBuilderItem';
import styles from './style/SurveyQuestionsBuilder.css';

export const SurveyQuestionsBuilder = () => {
  const [state, dispatch] = useSurveyBuilderState();
  const getCanAddQuestion = useCanAddQuestion();

  const renderItems = useCallback(() => {
    return (
      <div>
        {state.survey.questions.map(item =>
          <div
            className={styles.question}
            key={item.ordinal}>
            <SurveyBuilderQuestionContainer item={item}>
              <QuestionBuilderItem includeTagging={false} />
            </SurveyBuilderQuestionContainer>
          </div>)}
      </div>
    );
  }, [state.survey.questions]);

  const addQuestion = useCallback(() => {
    const section = state.survey.sections[0];
    dispatch({
      type: 'new-question-added',
      questionOrdinal: state.survey.questions.length + 1,
      sectionIdentifier: section.identifier,
    });
  }, [dispatch, state.survey.questions, state.survey.sections]);

  return (
    <div>
      {renderItems()}
      {getCanAddQuestion() &&
        <AddQuestionButton
          className={styles.add}
          onClick={addQuestion} />
      }
    </div>
  );
};

export default SurveyQuestionsBuilder;
import { RespondentProps } from '@/types';
import { ResponsesViewAll } from '@presentation';
import { formatPlurality } from '@utils';
import { RespondentBaseballCard as BaseballCard } from 'components/BaseballCard';
import styles from './style/MaxDiff.Data.User.css';

export default function MaxDiffDataUser(props: Props) {
  const { count, profile } = props;
  return (
    <>
      <div className={styles.text}>
        <div className={styles.name}>
          <BaseballCard {...props}>
            {profile.fullname}
          </BaseballCard>
        </div>
        <div className={styles.count}>{`(${count} ${formatPlurality('time', count)})`}</div>
      </div>
      <ResponsesViewAll />
    </>
  );
}

export { MaxDiffDataUser };

type Props = {
  count: number;
} & RespondentProps;
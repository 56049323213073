import InputBase from '@mui/material/InputBase';
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';

const applyStyle = withStyles((theme: Theme) => {
  return createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      backgroundColor: 'var(--pri-01)',
      border: '1px solid var(--gray-l)',
      borderRadius: 4,
      color: 'var(--black-l)',
      fontFamily: 'var(--font-reg)',
      fontSize: 16,
      padding: '5px 26px 5px 12px',
      position: 'relative',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        backgroundColor: 'var(--pri-01)',
        borderColor: 'var(--gray-l)',
        borderRadius: 4,
        boxShadow: 'none',
      },
    },
  });
});

export const SelectInput = applyStyle(InputBase);
import {
  Calendar,
  CheckSquare,
  Clipboard,
  CreditCard,
  Layers,
  Lock,
  Mail,
  Settings,
  Shield,
} from 'react-feather';
import { useSelector } from 'react-redux';
import { path } from '@consts';
import {
  hasClientRole,
  hasComplianceRole,
  hasConsultantRole,
  hasGroupBillingAdminPermission,
  hasInternalAdminRole,
} from '@utils';
import { PaintRoller } from 'components/icons';
import EnterpriseBilling from 'scenes/settings/EnterpriseBilling';
import ComplianceSettings from 'scenes/settings/ComplianceSettings';
import ComplianceSurvey from 'scenes/settings/ComplianceSurvey';
import PostCallQuestions from 'scenes/settings/PostCallQuestions';
import UserBilling from 'scenes/settings/UserBilling';
import { AccountSettings } from './Settings.Account';
import { Branding } from './Settings.Branding';
import { SecuritySettings } from './Settings.Security';
import { CalendarSettings } from './Settings.Calendar';
import { Integrations } from './Settings.Integrations';
import { NotificationSettings } from './Settings.Notifications';
import { PrivacySettings } from './Settings.Privacy';
import { MenuItem } from './interfaces';

const paths = path.Settings;

export const items: MenuItem[] = [
  {
    name: 'Account',
    path: paths.Account,
    component: AccountSettings,
    icon: Settings,
  },
  {
    name: 'Privacy',
    path: paths.Privacy,
    component: PrivacySettings,
    icon: Lock,
  },
  {
    name: 'Notifications',
    path: paths.Notifications,
    component: NotificationSettings,
    icon: Mail,
  },
  {
    name: 'Integrations',
    path: paths.Integrations,
    component: Integrations,
    icon: Layers,
  },
  {
    name: 'Calendar',
    path: paths.Calendar,
    component: CalendarSettings,
    icon: Calendar,
  },
  {
    name: 'Compliance',
    path: paths.Compliance,
    component: ComplianceSettings,
    icon: Clipboard,
  },
  {
    name: 'Compliance Survey',
    path: paths.ComplianceSurvey,
    component: ComplianceSurvey,
    icon: CheckSquare,
  },
  {
    name: 'Enterprise Billing',
    path: paths.EnterpriseBilling,
    component: EnterpriseBilling,
    icon: CreditCard,
  },
  {
    name: 'Post Call Questions',
    path: paths.PostCallQuestions,
    component: PostCallQuestions,
    icon: CheckSquare,
  },
  {
    name: 'Billing',
    path: paths.UserBilling,
    component: UserBilling,
    icon: CreditCard,
  },
  {
    name: 'Security',
    path: paths.Security,
    component: SecuritySettings,
    icon: Shield,
  },
  {
    name: 'Themes & Branding',
    path: paths.Branding,
    component: Branding,
    icon: PaintRoller,
  },
];

const validations: Validations = {
  [paths.Branding]: (state: Store.State) => state.group?.features?.whitelabeling && (hasGroupBillingAdminPermission(state.user) || hasInternalAdminRole(state.user)),
  [paths.EnterpriseBilling]: (state: Store.State) => hasComplianceRole(state.user) || hasInternalAdminRole(state.user) || hasGroupBillingAdminPermission(state.user),
  [paths.Compliance]: (state: Store.State) => hasComplianceRole(state.user) || hasInternalAdminRole(state.user),
  [paths.ComplianceSurvey]: (state: Store.State) => hasComplianceRole(state.user) || hasInternalAdminRole(state.user),
  [paths.Notifications]: (state: Store.State) => hasConsultantRole(state.user) || hasClientRole(state.user) || hasInternalAdminRole(state.user) || !!state.user.contact.phone,
  [paths.PostCallQuestions]: (state: Store.State) => {
    return (hasComplianceRole(state.user) || hasInternalAdminRole(state.user))
        && state.group.features.postCallAnalystSurvey;
  },
  [paths.UserBilling]: (state: Store.State) => hasConsultantRole(state.user),
};

type Validate = (state: Store.State) => boolean;

type Validations = {
  [path: string]: Validate;
};

const selectSettingsItems = (state: Store.State) => {
  return items.reduce<MenuItem[]>((acc, x) => {
    const validate = validations[x.path as string];
    const valid = validate
      ? validate(state)
      : true;

    if (valid) {
      acc.push(x);
    }

    return acc;
  }, []);
};

export const useSettingsMenuItems = () => {
  return useSelector(selectSettingsItems);
};
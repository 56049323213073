import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';
import { useDimensions } from '@utils';
import { ThemeGroupContext, ThemeGroupSelectContext } from './Context';
import { Option } from './Select.Option';
import styles from './style/Select.css';

type Props = {
  children: React.ReactNode;
};

export const Input = (props: Props) => {
  const ctx = useContext(ThemeGroupContext);
  const dimensions = useDimensions();
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [anchor, setAnchor] = useState<HTMLElement>(null);

  const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchor(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchor(null);
  }, []);

  useEffect(() => {

    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }

  }, [dimensions]);

  const selected = useMemo(() => {
    return ctx.themes.find(x => x.id === ctx.selected);
  }, [
    ctx.selected,
    ctx.themes,
  ]);

  const mui = useStyles({ width });

  const context = {
    visible: !!anchor,
    close: handleClose,
  };

  return (
    <ThemeGroupSelectContext.Provider value={context}>
      <div className={styles.root}>
        <div
          className={styles.btn}
          onClick={handleClick}
          ref={ref}>
          <Option
            readonly
            item={selected} />
          <div className={styles.indicator}>
            <Divider
              classes={{
                root: mui.divider,
              }}
              orientation="vertical"
              variant="middle" />
            <KeyboardArrowDown classes={{
              root: mui.icon,
            }} />
          </div>
        </div>
        <Menu
          anchorEl={anchor}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          className={styles.root}
          classes={{ paper: mui.menu }}
          disableAutoFocusItem
          onClose={handleClose}
          open={!!anchor}
          transitionDuration={{
            appear: 0,
            enter: 0,
            exit: 0,
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}>
          {props.children}
        </Menu>
      </div>
    </ThemeGroupSelectContext.Provider>
  );
};

Input.displayName = 'ColorPalette.Select.Input';

const useStyles = makeStyles({
  divider: {
    marginLeft: '4px',
    marginRight: '4px',
    height: '28px',
  },
  icon: {
    height: '25px',
    width: '25px',
    color: 'var(--trolley)',
  },
  menu: {
    borderRadius: '4px',
    transition: 'duration 0 !important',
    width: (props: { width: number; }) => props.width,
  },
  list: {
    padding: '0',
  },
});
import { WorkspaceGlobalSearchBarResultItem } from '@/types';
import File from './Results.File';
import FindMembers from './Results.FindMembers';
import Member from './Results.Member';
import Folder from './Results.Folder';
import Project from './Results.Project';
import Keyword from './Results.Keyword';
import Tag from './Results.Tag';
import styles from './style/Results.css';

export default function ResultsItems({ activeIndex, items }: ResultsItemsProps) {
  return (
    <>
      {items.map((item, i) => {
        switch (item.type) {
          case 'file':
            return (
              <File
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={i === activeIndex} />
            );
          case 'folder':
            return (
              <Folder
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={i === activeIndex} />
            );
          case 'project':
            return (
              <Project
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={i === activeIndex} />
            );
          case 'keyword':
            return (
              <Keyword
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={i === activeIndex} />
            );
          case 'find-members':
            return (
              <FindMembers
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={i === activeIndex} />
            );
          case 'member': {
            return (
              <Member
                className={styles.result}
                key={item.id}
                item={item}
                index={i}
                highlighted={i === activeIndex} />
            );
          }
          case 'tag' : {
            return (
              <Tag
              className={styles.result}
              key={item.id}
              item={item}
              index={i}
              highlighted={i === activeIndex} />
            );
          }
        }
      })}
    </>
  );
}

export { ResultsItems };

type ResultsItemsProps = {
  activeIndex: number;
  items: WorkspaceGlobalSearchBarResultItem[];
};
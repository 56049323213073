import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { cx, getLocationFor, slugify } from '@utils';
import { ProjectPopoverAnchor } from 'components/GroupProject';
import { InteractionsTableItem } from './interfaces';
import styles from './style/Cell.Name.css';

export default function Name({ row: { original: data } }: Cell<InteractionsTableItem>) {
  const to = useMemo(() => {
    const slug = slugify({ id: data.metadata.project.id, name: data.metadata.project.name });
    return getLocationFor.project.root({ slug });
  }, [data.metadata.project]);

  return (
    <div className={styles.root}>
      <div className={styles.details}>
        <ProjectPopoverAnchor item={data.metadata.project}>
          <Link to={to}>
            <div className={cx(styles.name, styles.unbolded)}>{data.metadata.project.name}</div>
          </Link>
        </ProjectPopoverAnchor>
      </div>
    </div>
  );
}

export { Name };
import { forwardRef, useCallback, useMemo, Ref } from 'react';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { PopupState as PopupStateProps } from 'material-ui-popup-state/core';
import { useFileMenuItems, useFileActions, useShowFileMenu } from '@containers/WorkspaceFiles/hooks';
import { MoreHorizontalAnchorSmall } from '@presentation/Anchor';
import { PopperMenu, PopperMenuItem } from 'components/Popper';
import { FileActionsMenuProps, FileActionsPopperProps } from './interfaces';
import styles from './style/FileActionsContextMenu.css';

const FileActionsContextMenu = forwardRef(({ onClose }: FileActionsMenuProps, ref: Ref<HTMLDivElement>) => {

  const valid = useFileMenuItems();
  const actions = useFileActions();

  const handleClick = useCallback((fn: () => unknown) => () => {
    onClose();
    return fn();
  }, [onClose]);

  const remove = useMemo(() => {
    return {
      children: 'Remove',
      onClick: actions.delete,
    };
  }, [actions.delete]);

  const manageAccess = useMemo(() => {
    return {
      children: 'Manage Access',
      onClick: actions.manageAccess,
    };
  }, [actions.manageAccess]);

  const ownership = useMemo(() => {
    return {
      children: 'Change Owner',
      onClick: actions.changeOwner,
    };
  }, [actions.changeOwner]);

  const move = useMemo(() => {
    return {
      children: 'Move',
      onClick: actions.move,
    };
  }, [
    actions.move,
  ]);

  const download = useMemo(() => {
    return {
      children: 'Download',
      onClick: actions.download,
    };
  }, [
    actions.download,
  ]);

  const rename = useMemo(() => {
    return {
      children: 'Rename',
      onClick: actions.rename,
    };
  }, [actions.rename]);

  const items = useMemo(() => {
    return [
      valid.canChangeOwner && ownership,
      valid.canDownload && download,
      valid.canManageAccess && manageAccess,
      valid.canMove && move,
      valid.canDelete && remove,
      valid.canRename && rename,
    ].filter(Boolean);
  }, [
    valid,
    download,
    manageAccess,
    move,
    ownership,
    remove,
    rename,
  ]);

  return (
    <PopperMenu ref={ref}>
      {items.map((item, i) => (
        <PopperMenuItem
          key={i}
          onClick={handleClick(item.onClick)}>
          {item.children}
        </PopperMenuItem>
      ))}
    </PopperMenu>
  );
});

export const FileActionsContextMenuPopper = ({
  placement = 'bottom-end',
  renderAnchor,
}: FileActionsPopperProps) => {

  const showMenu = useShowFileMenu();

  const defaultAnchor = useCallback((state: PopupStateProps) => {
    return (
      <MoreHorizontalAnchorSmall open={state.isOpen} />
    );
  }, []);

  if (!showMenu) {
    return null;
  }

  return (
    <PopupState variant="popper" popupId="folder-actions-popper">
      {popupState => (
        <div>
          <div {...bindToggle(popupState)}>
            {(renderAnchor || defaultAnchor)(popupState)}
          </div>
          <Popper {...bindPopper(popupState)} placement={placement} className={styles.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <FileActionsContextMenu
                onClose={popupState.close} />
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
};
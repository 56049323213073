import { useCallback } from 'react';
import { useSurveyBuilderOptions } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { ConjointAnalysisQuestionBuilderContainer } from '@containers/SurveyBuilder.Question/ConjointAnalysisContainer';
import { MaxDiffQuestionBuilderContainer } from '@containers/SurveyBuilder.Question/MaxDiffContainer';
import { useQuestionBuilderContext, useQuestionBuilderItemContext } from '@containers/SurveyBuilder.Question/Context';
import { SurveyQuestionType } from '@enums';
import { FormButtons } from '@presentation/FormButtons';
import { SurveyQuestionNumber } from '@presentation/Survey';
import { SurveyQuestionTextEditor } from 'components/Survey.QuestionText';
import QuestionTypeDropdown from './QuestionTypeDropdown';
import ConjointAnalysisQuestionBuilder from './ConjointAnalysis.Question';
import MaxDiffQuestionBuilder from './MaxDiff.Question';
import MatrixGridQuestionBuilder from './MatrixGrid.Question';
import MatrixMultiselectBuilder from './MatrixMultiselect.Question';
import MatrixSliderQuestionBuilder from './MatrixSlider.Question';
import MultipleChoiceQuestionBuilder from './MultipleChoice.Question';
import MultiselectQuestionBuilder from './Multiselect.Question';
import LongTextQuestionBuilder from './LongText.Question';
import RankingQuestionBuilder from './Ranking.Question';
import NumberInputTableBuilder from './NumberInputTable.Question';
import styles from './style/QuestionBuilder.css';

export const QuestionBuilder = () => {
  const context = useQuestionBuilderContext();
  const { questionTypes } = useSurveyBuilderOptions();
  const item = useQuestionBuilderItemContext();

  const renderFormButtons = useCallback(() => {
    return (
      <div className={styles.buttons}>
        <FormButtons
          archivable={false}
          disabled={!context.canSave}
          deletable={false}
          onSubmit={context.onSave}
          onCancel={context.onCancel} />
      </div>
    );
  }, [context.canSave, context.onCancel, context.onSave]);

  const renderQuestionType = useCallback(() => {
    return (
      <div className={styles.question}>
        <SurveyQuestionNumber
          className={styles.number}
          ordinal={item.ordinal} />
        <div className={styles.questionType}>
          <div className={styles.dropdown}>
            <QuestionTypeDropdown
              items={questionTypes} />
          </div>
        </div>
      </div>
    );
  }, [
    item.ordinal,
    questionTypes,
  ]);

  const renderQuestionContent = useCallback(() => {
    switch (item.typeId) {
      case SurveyQuestionType.MultipleChoice:
        return <MultipleChoiceQuestionBuilder item={item} />;

      case SurveyQuestionType.Multiselect:
        return <MultiselectQuestionBuilder item={item} />;

      case SurveyQuestionType.Ranking:
        return <RankingQuestionBuilder item={item} />;

      case SurveyQuestionType.Sliders:
        return <MatrixSliderQuestionBuilder item={item} />;

      case SurveyQuestionType.MatrixGrid:
        return <MatrixGridQuestionBuilder item={item} />;

      case SurveyQuestionType.LongTextResponse:
        return <LongTextQuestionBuilder item={item} />;

      case SurveyQuestionType.ShortTextResponse:
        return null;

      case SurveyQuestionType.MaxDifference:
        return (
          <MaxDiffQuestionBuilderContainer item={item}>
            <MaxDiffQuestionBuilder />
          </MaxDiffQuestionBuilderContainer>
        );

      case SurveyQuestionType.MatrixMultiselect:
        return <MatrixMultiselectBuilder item={item} />;

      case SurveyQuestionType.ConjointAnalysis:
        return (
          <ConjointAnalysisQuestionBuilderContainer item={item}>
            <ConjointAnalysisQuestionBuilder />
          </ConjointAnalysisQuestionBuilderContainer>
        );

      case SurveyQuestionType.NumberInputTable:
        return <NumberInputTableBuilder item={item} />;

    }
  }, [
    item,
  ]);

  const renderQuestion = useCallback(() => {
    return (
      <>
        <SurveyQuestionTextEditor
          className={styles.questionText} />
        {renderQuestionContent()}
      </>
    );
  }, [
    renderQuestionContent,
  ]);

  return (
    <div className={styles.root}>
      {renderQuestionType()}
      <div className={styles.body}>
        {item.typeId && renderQuestion()}
        {renderFormButtons()}
      </div>
    </div>
  );
};

export default QuestionBuilder;
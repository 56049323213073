import { useState } from 'react';
import { ShareProjectWithExpert } from 'components/Modal/ShareProjectWithExpert';
import styles from './style.css';

type Props = IProjectId;

const ProjectShareLink = (props: Props) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  return (
    <>
      <div className={styles.subtitle}>Did you know our software allows you to invite your own experts to projects?</div>
      <div
        className={styles.link}
        onClick={() => setModalVisible(true)}>
        Click here to share a link to your project.
      </div>
      {!isModalVisible || <ShareProjectWithExpert
        projectId={props.projectId}
        onClose={() => setModalVisible(false)} />}
    </>
  );
};

export default ProjectShareLink;
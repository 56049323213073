import { useSearchLoading, useSearchResultsMembers } from '@containers/WorkspaceGlobalSearch/hooks';
import { formatPlurality } from '@utils';
import MemberSearchBanner from './MemberSearchBanner';
import Table from './Search.Table.Members';
import styles from './style/Tab.css';

const max = 50;

export default function SearchTabMembers() {
  const members = useSearchResultsMembers();
  const loading = useSearchLoading();

  const isLoading = loading.members.untouched && loading.members.loading;

  const data = members?.items ?? [];
  const countText = !isLoading
    ? `(${getCountLabel(members?.total ?? 0, 'Member')})`
    : null;
  const pagination = data.length > 25;

  return (
    <div className={styles.root}>
      <MemberSearchBanner />
      <Table
        data={data}
        countText={countText}
        pagination={pagination}
        pageSize={25}
        isLoading={isLoading} />
    </div>
  );
}

function getCountLabel(total: number, label: string) {
  return total > max
    ? `+${max} ${formatPlurality(label, max)}`
    : `${total} ${formatPlurality(label, total)}`;
}

export { SearchTabMembers };
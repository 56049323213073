import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import * as api from '@api';
import { Profiles } from '@api/interfaces';
import { Contact } from '@/types';

type Data = Partial<Contact>;

export default function useFetchContactInfo<TData = Data>(
  data: Profiles.GetContactInfo.Request,
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {

  return useQuery(['post', data.userId, data.properties], async () => {
    const results = await api.profiles.getContactInfo(data);

    return results;
  }, options);
}

export { useFetchContactInfo };
import * as types from '@store/action-types';
import { Objects } from '@store/interfaces';

export const workspaceObjectOwnerChanged = (data: Objects.ObjectOwnerChanged.State): Objects.ObjectOwnerChanged.Action => {
  return {
    capabilities: data.capabilities,
    object: data.object,
    type: types.OBJECT_OWNER_CHANGED,
  };
};

export const workspaceObjectMoved = (data: Objects.ObjectMoved.State): Objects.ObjectMoved.Action => {
  return {
    capabilities: data.capabilities,
    object: data.object,
    type: types.OBJECT_MOVED,
  };
};
import { useCallback } from 'react';
import { useCallRecording } from '@utils/api';
import ActivityIndicator from 'components/ActivityIndicator';
import AudioPlayer from './Player';
import styles from './style/CallRecording.css';

type Props = {
  callId: number;
  conferenceInstanceId: number;
  downloadName?: string;
  type?: 'track' | 'wave';
};

export const CallRecording = ({
  callId,
  conferenceInstanceId,
  downloadName,
  type = 'wave',
}: Props) => {

  const {
    data: recording,
    isLoading,
  } = useCallRecording({
    callId,
    conferenceInstanceId,
    includeWaveform: type === 'wave',
  }, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const renderBody = useCallback(() => {
    if (!isLoading && !recording.url) {
      return <div className={styles.notAvailable}>Recording not available yet.</div>;
    }

    if (isLoading) {
      return <ActivityIndicator show={true} />;
    }

    return (
      <AudioPlayer
        src={recording.url}
        waveform={recording.waveform}
        type={type}
        downloadName={downloadName} />
    );
  }, [
    downloadName,
    isLoading,
    recording,
    type,
  ]);

  return (
    <div className={styles.root}>
      {renderBody()}
    </div>
  );
};

export default CallRecording;
import { createElement } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useAppReadyState } from '@containers/AppReadyState';
import { AllowAnonymous, Authenticated } from '@containers/Routing';
import { path } from '@utils';
import { AuthModalContainer, Navbar } from '$website/components';
import { MarketStrategyContainer } from '$website/containers';
import { AppBar } from 'components/AppBar';

type Props = unknown;

const Private = {
  any: path.match.any([
    ...Authenticated.Paths.any,
    ...AllowAnonymous.Paths.any,
  ]),
  exact: [
    ...Authenticated.Paths.exact,
    ...AllowAnonymous.Paths.exact,
  ],
};

export const AdaptiveNavigation = (props: Props) => {
  const app = useAppReadyState();

  const navigation = app.authenticated
      ? createElement(AppBar)
      : createElement(AnonymousNavigation);

  const paths = app.authenticated
      ? Private
      : AllowAnonymous.Paths;

  return (
    <Switch>
      <Route
        path={paths.any}
        render={_ => navigation} />
      <Route
        exact
        path={paths.exact}
        render={_ => navigation} />
    </Switch>
  );
};

const AnonymousNavigation = (props: Props) => {
  return (
    <MarketStrategyContainer>
      <AuthModalContainer>
        <Navbar />
      </AuthModalContainer>
    </MarketStrategyContainer>
  );
};
import qs from 'query-string';
import http from '@services/http';
import * as xformAdmin from './transformers';
import { Calls } from './interfaces';

export const fetchCall = (data: Calls.FetchCall.Request): Promise<Calls.FetchCall.Response> => {
  return http.get(`/admin/calls/${data.callId}`);
};

export const fetchCalls = (data: Calls.FetchCalls.Request): Promise<Calls.FetchCalls.Response> => {
  return http.get(`/admin/calls?${qs.stringify(data, { arrayFormat: 'bracket' })}`)
  .then((data: Calls.FetchCalls.Response<string>) => ({
    items: data.items.map(xformAdmin.calls.transformCallItem),
    metadata: data.metadata,
    filters: data.filters,
    pagination: data.pagination,
  }));
};

export const markCallComplete = (data: Calls.MarkCallComplete.Request): Promise<Calls.MarkCallComplete.Response> => {
  return http.post(`/admin/calls/${data.callId}/mark-complete`, {
    callId: data.callId,
  });
};

export const rejectCall = (data: Calls.RejectCall.Request) => {
  const { callId, notifyExpert } = data;
  return http.post(`/admin/calls/${callId}/reject`, { notifyExpert });
};

export const updateScreeningStatus = (data: Calls.UpdateScreeningStatus.Request): Promise<Calls.UpdateScreeningStatus.Response> => {
  return http.patch(`/admin/calls/${data.callId}/screening-status`, data);
};

export const updateCallPricing = ({ callId, pricing }: Calls.UpdateCallPricing.Request): Promise<Calls.UpdateCallPricing.Response> => {
  return http.patch(`/admin/calls/${callId}/pricing`, pricing);
};
import * as xform from '@transformers/helpers';
import { safeJsonDate } from '@utils';
import {
  UnparsedPipelineProjectAttributes,
  UnparsedPipelineRespondent,
  UnparsedPipeline,
  UnparsedPipelineUser,
} from '@/types';

export const normalizePipeline = (data: UnparsedPipeline) => {
  if (!data) return null;

  const run = xform.compose<Store.Pipeline, UnparsedPipeline>(
    parsePipeline,
  );

  return run<UnparsedPipeline>(data);
};

function parsePipeline(pipeline: UnparsedPipeline) {
  const me = Object.keys(pipeline.me).reduce<Store.Pipeline.Me>((acc, projectId) => ({
    ...acc,
    [projectId]: parseUserRecord(pipeline.me[projectId]),
  }), { linkUses: pipeline.me.linkUses });

  const project = Object.keys(pipeline.project).reduce<Store.Pipeline.Project>((acc, projectId) => {
    const item = pipeline.project[projectId] as UnparsedPipelineProjectAttributes;

    const data = {
      anonymity: item.anonymity,
      linkUses: item.linkUses,
      respondents: item.respondents && Object.keys(item.respondents).reduce<Store.Pipeline.Project.Respondents>((acc, userId) => {

        return {
          ...acc,
          [userId]: parseRespondentRecord(item.respondents[userId]),
        };
      }, {}),
      users: Object.keys(item.users).reduce<Store.Pipeline.Project.Users>((acc, userId) => {

        return {
          ...acc,
          [userId]:  parseUserRecord(item.users[userId]),
        };
      }, {}),
    };

    return {
      ...acc,
      [projectId]: data,
    };
  }, {});

  return {
    me,
    project,
  };
}

function parseRespondentRecord(item: UnparsedPipelineRespondent) {
  return {
    ...item,
    surveyResponse: {
      ...item.surveyResponse,
      completedOn: safeJsonDate(item.surveyResponse.completedOn),
    },
  };
}

function parseUserRecord(record: UnparsedPipelineUser) {
  return {
    ...record,
    acceptedOn: safeJsonDate(record.acceptedOn),
  };
}
import { isValidElement, cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import { addMinutes, startOfDay, startOfMinute, getMinutes } from 'date-fns';
import tzFormat from 'date-fns-tz/format';
import querystring from 'query-string';
import * as actions from '@actions';
import * as api from '@api';
import * as enums from '@enums';
import google from 'static/icons/google.svg?url';
import outlook from 'static/icons/outlook.svg?url';
import styles from './style/Calendar.css';
import { createId } from './utils';

const mapDispatch = {
  contactsAdded: actions.contactsAdded,
};

class Calendar extends Component {

  static createId = createId;

  state = {
    loading: true,
  };

  componentDidMount() {
    const { calendar } = this.props;
    if (calendar && calendar.with && calendar.with.id) {
      if (!this.props.contacts[calendar.with.id]) {
        return this.fetchMissingContact(calendar.with.id);
      }
    }
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.location.state) return;

    const prevCalendar = prevProps.location.state || {};
    const { calendar } = this.props;

    const prevCalendarWithId = prevCalendar
                               ? prevCalendar.with
                                 ? prevCalendar.with.id
                                 : null
                               : null;

    const calendarWithId = calendar
                           ? calendar.with
                             ? calendar.with.id
                             : null
                           : null;

    if (prevCalendarWithId !== calendarWithId && calendarWithId !== null
      && !this.state.loading) {
      if (!this.props.contacts[calendarWithId]) {
        this.fetchMissingContact(calendar.with.id);
      }
    }
  }

  fetchMissingContact = userId => {
    this.setState({ loading: true });

    return api.profiles.getContactInfo({ userId })
    .then(u => this.props.contactsAdded({ contacts: [u] }))
    .finally(_ => this.setState({ loading: false }));
  }

  static getProviderImage = id => id === enums.ConnectedAccountProvider.Google ? google : outlook;
  static generateBackgroundEventSource = () => {
    const now = Date.now();
    const delta = 30 - (getMinutes(now) % 30); // match slotDuration
    return [
      {
        start: startOfDay(now),
        end: startOfMinute(addMinutes(now, delta)),
        rendering: 'background',
        backgroundColor: '#ccc',
        extendedProps: {
          canRemove: false,
          isNew: false,
          type: 'past',
        },
      },
    ];
  }

  static generateMoratoriumEventSource = data => {
    const minutes = data.minutes || 0;
    if (minutes <= 0) return [];

    const now = Date.now();
    const delta = 30 - (getMinutes(now) % 30); // match slotDuration
    return [
      {
        classNames: [ styles.moratorium ],
        start: startOfMinute(addMinutes(now, delta)),
        end: startOfMinute(addMinutes(now, minutes + delta)),
        rendering: 'background',
        backgroundColor: 'none',
        extendedProps: {
          canRemove: false,
          isNew: false,
          type: 'moratorium',
        },
      },
    ];
  }

  static getScrollableElement() {
    const elements = document.getElementsByClassName('fc-scroller fc-time-grid-container');
    return elements.length ? elements[0] : null;
  }

  static renderTimezone() {
    return <div className={styles.timezone}>{tzFormat(Date.now(), 'zzz')}</div>;
  }

  static scrollToNow() {
    const nowLine = document.getElementsByClassName('fc-now-indicator fc-now-indicator-line');
    if (nowLine && nowLine.length) {
      setTimeout(() => {
        nowLine[0]?.scrollIntoView({
          block: 'center',
        });
      }, 0);
    }
  }

  render() {
    if (this.state.loading) {
      return null; // todo: probably want to propagate loading/blocking down instead of returning null
    }

    const qs = querystring.parse(this.props.location.search);
    const props = {
      ...this.props,
      calendar: this.props.calendar,
      testMode: qs.test,
    };

    if (isValidElement(this.props.children)) {
      return cloneElement(this.props.children, { ...props });
    }
  }
}

export default connect(null, mapDispatch)(Calendar);
import { memo } from 'react';

type Props = {
  className?: string;
  size: number;
};

export const Tagging = memo(({ className, size }: Props) => {
  return (
    <svg
      className={className}
      height={size}
      width={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <g
          transform="translate(10, 12) rotate(-225) translate(-10, -12) translate(0.5, 3)"
          stroke="currentColor"
          strokeWidth="2">
          <path
            d="M18.1610624,11.0378609 L11.4292247,17.7714589 C11.077013,18.1241549 10.599076,18.3223305 10.1006961,18.3223305 C9.60231621,18.3223305 9.12437922,18.1241549 8.77216749,17.7714589 L0.707106781,9.71368056 L0.707106781,0.32233047 L10.0960017,0.32233047 L18.1610624,8.3895002 C18.8891216,9.12209585 18.8891216,10.3052653 18.1610624,11.0378609 Z" />
        </g>
      </g>
    </svg>
  );
});
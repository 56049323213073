import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@actions';
import { Call } from '@/types';
import { Calendar } from 'components/Calendar/Calendar';
import {
  useAdHocCallSchedulingNotifier,
  useProjectCallSchedulingNotifier,
} from '@containers/Scheduler/Notifier';
import { Viewing } from './Viewing.js';

const mapState = (state: Store.State) => ({
  calls: state.calls,
  contacts: state.contacts,
  user: state.user,
});

type Props = {
  className?: string;
};

const Personal = (props: Props) => {
  const dispatch = useDispatch();
  const state = useSelector(mapState);
  const location = useLocation();
  const history = useHistory();

  const adHocCallNotifier = useAdHocCallSchedulingNotifier();
  const projectCallNotifier = useProjectCallSchedulingNotifier();

  const callUpdated = (data: { call: Call; }) => {
    dispatch(actions.callUpdated(data));
  };

  return (
    <Calendar>
      <Viewing
        calls={state.calls}
        className={props.className}
        contacts={state.contacts}
        history={history}
        location={location}
        updateCall={callUpdated}
        user={state.user}
        adHocCallNotifier={adHocCallNotifier}
        projectCallNotifier={projectCallNotifier}
      />
    </Calendar>
  );
};

const defaultProps = {
  className: '',
};

Personal.defaultProps = defaultProps;

export { Personal };
export default Personal;
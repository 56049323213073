import { useCallback, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { GroupTag } from '@presentation';
import { GroupTag as GroupTagType } from '@/types';
import { getLocationFor, cx } from '@utils';
import tagStyles from '@presentation/style/GroupTag.css';
import styles from './style/Cell.Member.Tags.css';

type TagsProps = {
  tags: GroupTagType[];
  maxTags: number;
}

export default function Tags({tags, maxTags}: TagsProps) {
  const history = useHistory();

  const handleClick = useCallback((tag: { name: string; }) => {
    const location = getLocationFor.workspaces.search({
      query: `${tag.name}`,
    });
    history.push(location);
  }, [history]);

  return (
    <div className={styles.root}>
      {tags.slice(0, maxTags).map(tag => (
        <GroupTag
          key={tag.id}
          className={styles.tag}
          onClick={() => handleClick(tag)}>
          {tag.name}
        </GroupTag>
      ))}
      <HiddenTags tags={tags.slice(maxTags)} />
    </div>
  );
}

type HiddenTagProps = {
  tags: GroupTagType[];
}
const HiddenTags = ({ tags }: HiddenTagProps) => {
  const [popperOpen, setPopperOpen] = useState(false);
  const history = useHistory();
  const ref = useRef<HTMLButtonElement>(null);

  const handleClick = useCallback((tag: { name: string; }) => {
    const location = getLocationFor.workspaces.search({
      query: `${tag.name}`,
    });
    history.push(location);
  }, [history]);
  if (!tags.length) {
    return null;
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
        <div style={{marginLeft: '6px'}}>
          <button className={cx(tagStyles.root, styles.tag)} ref={ref}>
            <div className={cx(tagStyles.content, tagStyles.clickable)} onClick={() => setPopperOpen(!popperOpen)}>
              + {tags.length}
            </div>
          </button>
          <Popper className={styles.hiddenPopper} open={popperOpen} anchorEl={ref.current}>
            {tags.map(tag => (
              <GroupTag
                key={tag.id}
                className={styles.tag}
                onClick={() => handleClick(tag)}>
                {tag.name}
              </GroupTag>
            ))}
          </Popper>
        </div>
      </ClickAwayListener>
    </>);
};

export { Tags };
import { Fragment, useCallback, useState } from 'react';
import { Editable } from 'components/Editable';
import { EditingAnswer } from 'scenes/surveys';
import { SurveyResponseItem as ResponseItem, RespondentAnswer } from '@/types';
import { EditableSurveyResponseProps } from './interfaces';

export const EditableSurveyResponse = ({
  QuestionComponent,
  onSave,
  response,
}: EditableSurveyResponseProps) => {
  const [editing, setEditing] = useState<number>(null);

  const toggleEditingOff = useCallback(() => {
    setEditing(null);
  }, [setEditing]);

  const toggleEditingOn = useCallback((ordinal: number) => () => {
    setEditing(ordinal);
  }, [setEditing]);

  const handleConfirm = useCallback((questionId: number) => (answer: RespondentAnswer) => {
    onSave({
      questionId,
      responseId: response.id,
      answer,
    })
    .then(toggleEditingOff);
  }, [
    onSave,
    response.id,
    toggleEditingOff,
  ]);

  const renderQuestion = useCallback((item: ResponseItem) => {
    if (editing === item.question.ordinal) {
      return (
        <EditingAnswer
          item={item}
          onCancel={toggleEditingOff}
          onConfirm={handleConfirm(item.question.id)} />
      );
    } else if (!editing) {
      return (
        <Editable onClick={toggleEditingOn(item.question.ordinal)}>
          <QuestionComponent
            item={item} />
        </Editable>
      );
    } else {
      return (
        <QuestionComponent
          item={item} />
      );
    }
  }, [
    editing,
    handleConfirm,
    toggleEditingOff,
    toggleEditingOn,
    QuestionComponent,
  ]);

  return <>
    {response.items.map(q => (
      <Fragment key={q.question.id}>
        {renderQuestion(q)}
      </Fragment>
    ))}
  </>;
};

export default EditableSurveyResponse;
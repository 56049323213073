import { SurveySectionText } from '@presentation/Survey';
import { cx } from '@utils';
import { BaseSectionHeaderProps, ReadOnlySectionHeaderProps, TaggingSectionHeaderProps } from './interfaces';
import styles from './style/Header.css';

export function SurveyTaggingSectionHeader(props: TaggingSectionHeaderProps) {
  return <BaseTaggingSectionHeader {...props} />;
}

export function ReadOnlySectionHeader(props: ReadOnlySectionHeaderProps) {
  return <BaseTaggingSectionHeader {...props} />;
}

function BaseTaggingSectionHeader(props: BaseSectionHeaderProps) {
  return (
    <div className={cx(styles.root, props.className)}>
      <SurveySectionText item={props.item} />
      <div className={styles.right}>
        {props.children}
      </div>
    </div>
  );
}
import { useCallback } from 'react';
import { cx } from '@utils';
import { LeadFormData } from '$website/types';
import { FormContextValue } from 'components/Form/interfaces';
import Input from './Form.Input';
import styles from './style/Form.css';

type Props = {
  classes?: {
    error?: string;
    field?: string;
    input?: string;
  };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & Pick<FormContextValue<LeadFormData>,
    | 'errors'
    | 'values'>;

export const LeadFormFields = (props: Props) => {

  const classes = {
    error: cx(styles.error, props.classes.error),
    field: cx(styles.item, props.classes.field),
    input: cx(styles.input, props.classes.input),
  };

  const Error = useCallback(({ value }: { value: string; }) => {
    return (
      <div className={classes.error}>{value}</div>
    );
  }, [classes.error]);

  return (
    <>
      <div className={classes.field}>
        <Input
          className={classes.input}
          name="firstName"
          onChange={props.onChange}
          placeholder="First Name"
          value={props.values.firstName} />
        {!!props.errors.firstName && <Error value={props.errors.firstName} />}
      </div>
      <div className={classes.field}>
        <Input
          className={classes.input}
          name="lastName"
          onChange={props.onChange}
          placeholder="Last Name"
          value={props.values.lastName} />
        {!!props.errors.lastName && <Error value={props.errors.lastName} />}
      </div>
      <div className={classes.field}>
        <Input
          className={classes.input}
          name="company"
          onChange={props.onChange}
          placeholder="Company"
          value={props.values.company} />
        {!!props.errors.company && <Error value={props.errors.company} />}
      </div>
      <div className={classes.field}>
        <Input
          className={classes.input}
          name="email"
          onChange={props.onChange}
          placeholder="Email"
          value={props.values.email} />
        {!!props.errors.email && <Error value={props.errors.email} />}
      </div>
      <div className={classes.field}>
        <Input
          className={classes.input}
          name="phone"
          onChange={props.onChange}
          placeholder="Phone Number"
          type="tel"
          value={props.values.phone} />
        {!!props.errors.phone && <Error value={props.errors.phone} />}
      </div>
      <div className={classes.field}>
        <Input
          className={classes.input}
          name="jobTitle"
          onChange={props.onChange}
          placeholder="Job Title"
          value={props.values.jobTitle} />
        {!!props.errors.jobTitle && <Error value={props.errors.jobTitle} />}
      </div>
    </>
  );
};

const defaultProps = {
  classes: {},
};

LeadFormFields.defaultProps = defaultProps;
LeadFormFields.displayName = 'LeadForm.Fields';
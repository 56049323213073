import { useCallback, useMemo } from 'react';
import { canJoinConference } from '@containers/Scheduler/utils.calls';
import { useSelectCall, useSelectConference } from '@containers/Store';
import { getLocationFor } from '@utils';
import * as mixpanel from '@services/mixpanel';

export const useJoinConference = (callId: number) => {
  const call = useSelectCall(callId);
  const conference = useSelectConference(callId);

  const canJoin = useMemo(() => conference && canJoinConference(call), [call, conference]);

  const handleClick = useCallback(() => {
    mixpanel.event.conference.join({
      call: {
        id: callId,
      },
    });
  }, [callId]);

  const location = getLocationFor.call.conference(conference?.conferenceIdentifier);

  return [canJoin, {
    onClick: handleClick,
    to: location,
  }] as const;
};

export default useJoinConference;
import { useCallback, useMemo, useState, ChangeEvent } from 'react';
import { XCircle } from 'react-feather';
import { usePopper } from 'react-popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { SurveyTemplate } from '@/types/survey';
import { cx, toTitleCase } from '@utils';
import { useDebounceValue } from '@utils/hooks';
import { useFetchSurveyTemplateRefData } from '@utils/api';
import { Input } from 'components/Input';
import { useSameWidthModifier } from 'components/Popper';
import { Portal } from 'components/Portal';
import styles from './style/Autocomplete.css';

type Props = {
  className?: string;
  onChange: (value: SurveyTemplate.LinkedEntity) => void;
  placeholder?: string;
  source: Source;
  value: SurveyTemplate.LinkedEntity;
};

type Source = {
  companies?: boolean;
  industries?: boolean;
  products?: boolean;
};

export const SurveyTemplateAutocomplete = ({
  className,
  onChange,
  placeholder,
  source,
  value,
}: Props) => {

  const debouncedValue = useDebounceValue(value, 250);

  const [focused, setFocused] = useState(false);

  const entitySelected = useMemo(() => !!value.entity, [value.entity]);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const { isFetching, data: items } = useFetchSurveyTemplateRefData({
    ...{
      companies: false,
      industries: false,
      products: false,
      ...source,
    },
    value: debouncedValue.value,
  }, {
    enabled: !entitySelected,
    keepPreviousData: true,
    placeholderData: [],
  });

  const sameWidthModifier = useSameWidthModifier();

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [sameWidthModifier],
    placement: 'bottom-start',
  });

  const popperVisible = useMemo(() => {
    return focused &&
      !entitySelected &&
      !!items.length;
  }, [
    focused,
    entitySelected,
    items,
  ]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    onChange({
      id: value.id,
      value: text,
      entity: null,
    });

  }, [
    onChange,
    value.id,
  ]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleClickAway = useCallback((e: MouseEvent) => {
    if (!referenceElement.contains(e.target as Node)) {
      setFocused(false);
    }
  }, [referenceElement]);

  const handleItemClick = useCallback((item: Item) => () => {
    onChange({
      id: value.id,
      value: item.name,
      entity: {
        id: item.id,
        type: item.type,
      },
    });
  }, [
    onChange,
    value.id,
  ]);

  const handleRemove = useCallback(() => {
    onChange({
      id: value.id,
      value: '',
      entity: null,
    });
  }, [
    onChange,
    value.id,
  ]);

  return (
    <>
      <div
        ref={setReferenceElement}
        className={cx(className, styles.root)}>
        <Input
          className={styles.input}
          disabled={!!value.entity}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
          value={value.value} />
        {entitySelected &&
          <XCircle
            onClick={handleRemove}
            className={styles.close} />}
      </div>
      {popperVisible &&
        <Portal>
          <div
            className={styles.popper}
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className={styles.menu}>
                {items.map((item, i) => (
                  <div
                    key={i}
                    className={styles.menuItem}
                    onClick={handleItemClick(item)}>
                    {item.name} <span className={styles.type}>({toTitleCase(item.type)})</span>
                  </div>
                ))}
              </div>
            </ClickAwayListener>
          </div>
        </Portal>
      }
    </>
  );
};

type Item = {
  id: number;
  name: string;
  type: SurveyTemplate.LinkedEntity['entity']['type'];
};
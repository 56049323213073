import * as api from '@api';
import { useAsyncStateLazy } from '@utils';

const useFetchSurveyRespondentAnswers = () => {
  return useAsyncStateLazy((params: IProjectId & { projectSurveyResponseId: number; }) => {

    return api.projects.surveys.fetchResponse(params);

  });
};

export { useFetchSurveyRespondentAnswers };
export default useFetchSurveyRespondentAnswers;
import { createContext } from 'react';
import { CompletedSurveyResponse } from './interfaces';

export type StarredResponseAnswersContextValue = {
  onToggle: (questionId: number) => () => Promise<void>;
  starred: number[];
};

export type CompletedSurveyContextValue = {
  response: CompletedSurveyResponse;
};

export const StarredResponseAnswersContext = createContext<StarredResponseAnswersContextValue>(null!);
export const CompletedSurveyContext = createContext<CompletedSurveyContextValue>(null!);
import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { SurveyQuestionMatrixRow } from '@/types';
import { QuestionRowsBuilder } from 'components/SurveyBuilder.Question/RowsBuilder';

type Props = {
  questionIdentifier: string;
  rows: SurveyQuestionMatrixRow[];
  className?: string;
  maxRows: number;
  minRows: number;
};

export default function QuestionRowsBuilderContainer({ questionIdentifier, ...props }: Props) {
  const [_, dispatch] = useSurveyBuilderState();

  const addRow = useCallback(() => {
    dispatch({
      type: 'add-question-row',
      questionIdentifier,
    });
  }, [
    dispatch,
    questionIdentifier,
  ]);

  const removeRow = useCallback((identifier: string) => () => {

    dispatch({
      questionIdentifier,
      row: {
        identifier,
      },
      type: 'remove-question-row',
    });
  }, [
    dispatch,
    questionIdentifier,
  ]);

  const updateRowValue = useCallback((identifier: string) => (value: string) => {

    dispatch({
      type: 'update-question-row-value',
      questionIdentifier,
      row: {
        identifier,
      },
      value,
    });
  }, [
    dispatch,
    questionIdentifier,
  ]);

  const addRowEnabled = useMemo(() => {
    return props.rows.length < props.maxRows;
  }, [props.rows.length, props.maxRows]);

  const removeRowEnabled = useMemo(() => {
    return props.rows.length > props.minRows;
  }, [props.rows.length, props.minRows]);

  return (
    <QuestionRowsBuilder
      addRow={addRow}
      addRowEnabled={addRowEnabled}
      className={props.className}
      rows={props.rows}
      removeRow={removeRow}
      removeRowEnabled={removeRowEnabled}
      updateRowValue={updateRowValue} />
  );
}

export { QuestionRowsBuilderContainer };
import { useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { QuestionOption } from '@presentation/SurveyPreview';
import { QuestionText } from '@presentation/Survey';
import { cx } from '@utils';
import OptionPreviewTaggingItem from './OptionPreviewTaggingItem';
import { QuestionTaggingPreviewProps } from './interfaces';
import styles from './style/QuestionTaggingPreview.css';

type Props
  = QuestionTaggingPreviewProps;

export default function QuestionTaggingPreview({ className, item }: Props) {
  const Content = useMemo(() => {

    switch(item.typeId) {
      case SurveyQuestionType.MultipleChoice:
      case SurveyQuestionType.Multiselect: {
        return item.options.map((option, i) => {
          const tagging = item.tagging?.response?.find(f => f.condition.value.option.ordinal === option.ordinal);
          return (
            <div key={i} className={styles.content}>
              <QuestionOption item={option} />
              <OptionPreviewTaggingItem
                className={styles.item}
                item={tagging} />
            </div>
          );
        });
      }

      default: {
        return (
          <div className={styles.content}>
            <div className={styles.notsupported}>Tagging not supported for this question type.</div>
          </div>
        );
      }

    }

  }, [item]);

  return (
    <div className={cx(styles.root, className)}>
      <QuestionText
        className={styles.questionText}
        item={item} />
      {Content}
    </div>
  );
}

export { QuestionTaggingPreview };
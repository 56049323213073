import * as enums from '@enums';
import {
  Project,
  TargetCompany,
  Title,
  VICS,
  ICompanies,
} from '@/types';

export enum ProjectCreationSteps {
  ProjectOverview = 'overview',
  Description = 'description',
  ScreeningQuestions = 'screening-questions',
  Survey = 'survey',
  AdditionalFilters = 'search-filters',
}

export type ProjectCreationScreen<T = unknown> = {
  component: React.ComponentType<T>;
  ignoreRef?: boolean;
  key: ProjectCreationSteps;
};

export type AutoFocusRefObject = {
  ref: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
};

type FilterHandler<S> = {
  onAdd:    (item: S) => void;
  onRemove: (item: S) => void;
};

type CKey = 'currentCompanies' | 'formerCompanies';
type SKey = 'suggested';
type TKey = 'titles';
type VKey = 'vics';

type GetFilterHandler = {
  (key: CKey):        FilterHandler<ICompanies.BaseCompany>;
  (key: TKey):        FilterHandler<Title>;
  (key: SKey | VKey): FilterHandler<VICS>;
};

type FiltersConfig = {
  limit: { [type: number]: number; };
};

export type ProjectCreationForm = {
  currentCompanies:   ICompanies.BaseCompany[];
  formerCompanies:    ICompanies.BaseCompany[];
  frequency:          enums.ProjectFrequency;
  goal:               number;
  suggested:          VICS[];
  targetCompany:      TargetCompany;
  targetCompleteDate: Date;
  titles:             Title[];
  vics:               VICS[];
} & Pick<Project,
      'name'
    | 'description'
    | 'projectType'>;

export type ProjectFiltersProps = {
  filters:          FiltersConfig;
  getFilterHandler: GetFilterHandler;
};

export type AdditionalFilter =
  keyof Pick<ProjectCreationForm,
    'currentCompanies' |
    'formerCompanies' |
    'suggested' |
    'vics' |
    'titles'>;
import { useCallback, useMemo } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { MenuProps } from '@material-ui/core/Menu';
import MUISelect from '@material-ui/core/Select';
import { SelectProps } from '@material-ui/core/Select';
import { cx } from '@utils';
import { SelectInput } from './Select.Input';
import styles from './style/Select.css';

type Lookup = { [key: number]: string; };

export type OnChange<T> = (e: React.ChangeEvent<{
  name: string;
  value: T & string;
}>) => unknown;

type Props<T extends Lookup, K extends keyof T = keyof T> = {
  defaultValue?: K;
  onChange:      OnChange<K>;
  options:       T;
  value?:        K extends string ? string : number;
  placeholder?:  string;
} & Omit<SelectProps, 'onChange'>
  & Pick<MenuProps, 'disableAutoFocusItem'>;

const Select = <T extends Lookup>({ classes = {}, ...props }: Props<T>) => {
  const renderValue = useCallback((value: keyof T) => {
    if (props.placeholder) {
      if (!value) return props.placeholder;
    } else {
      return props?.options?.[value];
    }
  }, [props.placeholder, props?.options]);

  const CustomMenuProps: Partial<MenuProps> = useMemo(() => ({
    classes: {
      paper: cx(styles.root, styles.paper),
      list: cx(styles.root, styles.options),
    },
    elevation: 0,
    MenuListProps: {
      classes: {
        root: cx(styles.root, styles.menu),
      },
    },
    PopoverClasses: {
      root: cx(styles.root, styles.options),
    },
    transitionDuration: 80,
    disableScrollLock: true,
    disableAutoFocusItem: props.disableAutoFocusItem,
  }), [props.disableAutoFocusItem]);

  return (
    <MUISelect
      className={cx(styles.select, props.className)}
      classes={classes}
      disabled={props.disabled}
      displayEmpty={!!props.placeholder}
      MenuProps={CustomMenuProps}
      defaultValue={props.defaultValue}
      id={props.id}
      input={<SelectInput />}
      name={props.name}
      onChange={props.onChange}
      onBlur={props.onBlur}
      renderValue={renderValue}
      value={props.value}>
      {Object.keys(props.options).map(x =>
        <MenuItem
          style={{ minHeight: 36 }}
          key={x}
          value={x}>
          {props.options[x]}
        </MenuItem>)}
    </MUISelect>
  );
};

Select.Input = SelectInput;

export { Select };
export default Select;
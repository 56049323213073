import { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useProjectMetricsLoading } from '@containers';
import * as enums from '@enums';
import ActiveProjectsPanel from '@screens/GroupMain/ActiveProjectsPanel';
import { ActivityIndicator } from 'components/ActivityIndicator';
import AgendaCard from './AgendaCard';
import { ScreenLayout } from './Layout';
import styles from './style/Main.css';
import { ReviewPanel } from './ComplianceReview';

const mapState = (state: Store.State) => state.group.features;

const ComplianceMain = () => {
  const groupFeatures = useSelector(mapState);
  const metricsLoading = useProjectMetricsLoading();

  const hideReviewPanel = useMemo(() => {
    return groupFeatures.callReviewStatus === enums.ReviewOption.Disabled
    && groupFeatures.surveyReviewStatus === enums.ReviewOption.Disabled
    && groupFeatures.messagesReviewStatus === enums.ReviewOption.Disabled;
  }, [
    groupFeatures,
  ]);

  const renderMain = useCallback(() => {
    if (metricsLoading && hideReviewPanel) {
      return <ActivityIndicator show />;
    } else if (hideReviewPanel) {
      return <ActiveProjectsPanel />;
    } else {
      return <ReviewPanel />;
    }

  }, [
    hideReviewPanel,
    metricsLoading,
  ]);

  return (
    <ScreenLayout>

      <div className={styles.col}>
        {renderMain()}
      </div>

      <div className={styles.col}>
        <div className={styles.sidebar}>
          <AgendaCard />
        </div>
      </div>

    </ScreenLayout>
  );
};

const ComplianceMainContainer = () => {
  return (
    <>
      <Helmet title="Home" />
      <ComplianceMain />
    </>
  );
};

export { ComplianceMainContainer };
export default ComplianceMainContainer;
import { useCallback } from 'react';
import { MatrixSliderQuestion as MSQ } from '@/types';
import { SurveyMatrixSliders } from 'components/SurveyMatrixSliders';
import { MatrixSliderQuestionProps } from './interfaces';

type Props =
  MatrixSliderQuestionProps;

export const MatrixSliderQuestion = ({ answer, item, setAnswer }: Props) => {

  const updateRowAnswer = useCallback((rowId: number) => (value: number) => {
    const existingItems = answer?.items || [];
    const items = !existingItems.some(s => s.matrixRowId === rowId)
      ? [...existingItems, { matrixRowId: rowId, value } ]
      : existingItems.reduce((acc, x) => {
        return [
          ...acc,
          {
            matrixRowId: x.matrixRowId,
            value: x.matrixRowId === rowId ? value : x.value,
          },
        ];
      }, [] as MSQ.RespondentAnswer.Item[]);

    setAnswer({ items });
  }, [setAnswer, answer?.items]);

  return (
    <SurveyMatrixSliders
      answer={answer || { items: [] }}
      question={item as MSQ.FormQuestion}
      rows={item.matrixRows}
      updateRowAnswer={updateRowAnswer} />
  );
};

export default MatrixSliderQuestion;
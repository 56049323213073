import ConnectPayments from 'static/images/payment/connect-payments.svg?url';
import Modal from 'components/Modal/Modal.Legacy';
import styles from './style/BeforeConnectPayment.css';

type Props = {
  onClose: () => unknown;
  visible: boolean;
};

export function ContactAccountAdmin({ onClose, visible }: Props) {
  return (
    <Modal
      onClose={onClose}
      visible={visible}>
      <div className={styles.root}>
        <div className={styles.header}>
          <img className={styles.image} src={ConnectPayments} />
        </div>
        <h1 className={styles.header}>
          Set up a payments account
        </h1>
        <h2 className={styles.subheader}>
          Want to engage others on the platform?
        </h2>
        <h2 className={styles.subheader}>
          Contact your account administrator to get started.
        </h2>
        <div className={styles.actions} />
      </div>
    </Modal>
  );
}

export default ContactAccountAdmin;
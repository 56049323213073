import { ChangeEvent, Dispatch, useCallback, useEffect, useRef } from 'react';

export type Target = {
  name:  string;
  value: string;
};

const useDebouncedInputEvent = <T extends Dispatch<{ target: Target; }>>(dispatch: T, ms = 500) => {
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const wrappedDispatch = useCallback(<T extends Target>(e: ChangeEvent<T>) => dispatch(e), [dispatch]);

  useEffect(() => () => clearTimeout(timer.current), [timer]);

  const debouncedDispatch = <T extends Target>(e: ChangeEvent<T>) => {
    e.persist();

    clearTimeout(timer.current);

    timer.current = setTimeout(() => wrappedDispatch(e), ms);
  };

  return useCallback(debouncedDispatch, [wrappedDispatch, ms]);
};

export { useDebouncedInputEvent };
export default useDebouncedInputEvent;
import axios from 'axios';
import { nullableDate } from '@utils';
import { SessionHttp } from './interfaces';

const xhr = axios.create({
  baseURL: process.env.BACKEND_BASE_URL,
  withCredentials: true,
});

// switch app session to an authenticated vancery session/cookie after getting the JWT from Auth0
export function jwt({ token }: SessionHttp.JWT.Params): Promise<SessionHttp.JWT.Response> {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // this request will set secure cookie and return, in response body, non-secret details about session
  return xhr.post<SessionHttp.JWT.Response<string>>(`/auth/session`, { }, { headers })
            .then(({ data }) => ({
              authenticated: data.authenticated,
              csrf: data.csrf,
              expiry: nullableDate(data.expiry),
              userId: data.userId,
            }));
}

export function oauth2(data: SessionHttp.OAuth2.Params): Promise<SessionHttp.OAuth2.Response> {
  return xhr.post<SessionHttp.OAuth2.Response<string>>(`/auth/oauth2`, data)
            .then(({ data }) => ({
              authenticated: data.authenticated,
              csrf: data.csrf,
              expiry: nullableDate(data.expiry),
              userId: data.userId,
              returnUrl: data.returnUrl,
            }));
}

export function getSessionInfo(): Promise<SessionHttp.GetSessionInfo.Response> {
  return xhr.get<SessionHttp.GetSessionInfo.Response<string>>(`/auth/session`)
            .then(({ data }) => ({
              authenticated: data.authenticated,
              expiry: nullableDate(data.expiry),
              userId: data.userId,
            }));
}
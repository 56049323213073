import queryString from 'query-string';
import http from '@services/http';
import { transformDates } from '@services/http/transformers';
import * as xform from './transformers';
import { Billing } from './interfaces';

export const getCharge = (data: Billing.GetCharge.Request) => {
  return http.get(`/admin/billing/charge/${data.id}`)
    .then((res: Billing.GetCharge.Response<string>) => xform.billing.transformGetCharge(res));
};

export const updateChargeDetails = (data: Billing.UpdateChargeDetails.Request) => {
  return http.put(`/admin/billing/charge/${data.id}/details`, data)
    .then((res: Billing.UpdateChargeDetails.Response<string>) => ({
      details: xform.billing.transformChargeDetails(res.details),
    }));
};

export const updateChargeStatus = (data: Billing.UpdateChargeStatus.Request) => {
  return http.patch(`/admin/billing/charge/${data.id}/status`, data)
    .then((res: Billing.UpdateChargeStatus.Response) => res);
};

export const updateChargeStatusBulk = (data: Billing.UpdateChargeStatusBulk.Request) => {
  return http.post(`/admin/billing/charge/bulk-status`, data)
    .then((res: Billing.UpdateChargeStatusBulk.Response) => res);
};

export const getChargeable = (data: Billing.GetChargeable.Request) => {
  return http.get(`/admin/billing/chargeable/${data.id}`)
    .then((res: Billing.GetChargeable.Response<string>) => xform.billing.transformGetChargeable(res));
};

export const createChargeableAdjustment = (data: Billing.CreateChargeableAdjustment.Request) => {
  return http.post(`/admin/billing/chargeable/${data.chargeableId}/adjustment`, data)
    .then((res: Billing.CreateChargeableAdjustment.Response) => res);
};

export const editChargeableAdjustment = (data: Billing.EditChargeableAdjustment.Request) => {
  return http.patch(`/admin/billing/chargeable/${data.chargeableId}/adjustment/${data.id}`, data)
    .then((res: Billing.EditChargeableAdjustment.Response) => res);
};

export const deleteChargeableAdjustment = (data: Billing.DeleteChargeableAdjustment.Request) => {
  return http.delete(`/admin/billing/chargeable/${data.chargeableId}/adjustment/${data.id}`)
    .then((res: Billing.DeleteChargeableAdjustment.Response) => res);
};

export const getCharges = (data: Billing.GetCharges.Request) => {
  const qs = queryString.stringify({
    id: data.id,
    after: data.after?.getTime(),
    before: data.before?.getTime(),
    description: data.description,
    status: data.status,
    owner: data.owner,
    limit: data.limit,
    cursor: data.cursor ? data.cursor : undefined,
  });
  return http.get(`/admin/billing/charges?${qs}`)
    .then((res: Billing.GetCharges.Response<string>) => xform.billing.transformGetCharges(res));
};

export const getPackages = (data: Billing.GetPackages.Request): Promise<Billing.GetPackages.Response> => {
  const qs = queryString.stringify({
    id: data.id,
    after: data.after?.getTime(),
    before: data.before?.getTime(),
    account: data.account,
    take: data.take,
    skip: data.skip ? data.skip : undefined,
  });

  return http.get(`/admin/billing/packages?${qs}`, { transformResponse: transformDates });
};

export const getInvoices = (data: Billing.GetInvoices.Request) => {
  const qs = queryString.stringify({
    id: data.id,
    after: data.after?.getTime(),
    before: data.before?.getTime(),
    status: data.status,
    account: data.account,
    limit: data.limit,
    cursor: data.cursor ? data.cursor : undefined,
  });
  return http.get(`/admin/billing/invoices?${qs}`)
    .then((res: Billing.GetInvoices.Response<string>) => xform.billing.transformGetInvoices(res));
};

export const downloadInvoices = (data: Billing.DownloadInvoices.Request): Promise<Billing.DownloadInvoices.Response> => {
  const qs = queryString.stringify({
    ids: data.ids,
    f: data.format,
  });
  return http.download(`/admin/billing/invoices/download?${qs}`);
};

export const newInvoice = (data: Billing.NewInvoice.Request) => {
  return http.post(`/admin/billing/invoice`, data)
    .then((res: Billing.NewInvoice.Response) => res);
};

export const getInvoice = (data: Billing.GetInvoice.Request) => {
  return http.get(`/admin/billing/invoice/${data.id}`)
    .then((res: Billing.GetInvoice.Response<string>) => xform.billing.transformGetInvoice(res));
};

export const updateInvoiceDetails = (data: Billing.UpdateInvoiceDetails.Request) => {
  return http.put(`/admin/billing/invoice/${data.id}/details`, data)
    .then((res: Billing.UpdateInvoiceDetails.Response<string>) => ({
      details: xform.billing.transformInvoiceDetails(res.details),
    }));
};

export const updateInvoiceStatus = (data: Billing.UpdateInvoiceStatus.Request) => {
  return http.patch(`/admin/billing/invoice/${data.id}/status`, data)
    .then((res: Billing.UpdateInvoiceStatus.Response) => res);
};

export const createInvoiceOtherCharge = (data: Billing.CreateInvoiceOtherCharge.Request) => {
  return http.post(`/admin/billing/invoice/${data.id}/other-charge`, data)
    .then((res: Billing.CreateInvoiceOtherCharge.Response) => res);
};

export const removeInvoiceCharge = (data: Billing.RemoveInvoiceCharge.Request) => {
  return http.delete(`/admin/billing/invoice/${data.invoiceId}/charge/${data.id}`)
    .then((res: Billing.RemoveInvoiceCharge.Response) => res);
};

export const getInvoiceAssignableCharges = (data: Billing.GetInvoiceAssignableCharges.Request) => {
  return http.get(`/admin/billing/invoice/${data.id}/assignable-charges`)
    .then((res: Billing.GetInvoiceAssignableCharges.Response) => res);
};

export const linkInvoiceCharges = (data: Billing.LinkInvoiceCharges.Request) => {
  return http.post(`/admin/billing/invoice/${data.id}/link-charge`, data)
    .then((res: Billing.LinkInvoiceCharges.Response) => res);
};

export const downloadInvoice = (data: Billing.DownloadInvoice.Request): Promise<Billing.DownloadInvoice.Response> => {
  const qs = queryString.stringify({
    f: data.format,
  });
  return http.download(`/admin/billing/invoice/${data.id}/download?${qs}`);
};

export const getPayout = (data: Billing.GetPayout.Request) => {
  return http.get(`/admin/billing/payout/${data.id}`)
    .then((res: Billing.GetPayout.Response<string>) => xform.billing.transformGetPayout(res));
};

export const updatePayoutPayment = (data: Billing.UpdatePayoutPayment.Request) => {
  return http.put(`/admin/billing/payout/${data.id}/payment`, data)
    .then((res: Billing.UpdatePayoutPayment.Response<string>) => ({
      details: xform.billing.transformPayoutDetails(res.details),
    }));
};

export const updatePayoutStatus = (data: Billing.UpdatePayoutStatus.Request) => {
  return http.patch(`/admin/billing/payout/${data.id}/status`, data)
    .then((res: Billing.UpdatePayoutStatus.Response) => res);
};

export const updatePayoutStatusBulk = (data: Billing.UpdatePayoutStatusBulk.Request) => {
  return http.post(`/admin/billing/payout/bulk-status`, data)
    .then((res: Billing.UpdatePayoutStatusBulk.Response) => res);
};

export const getPayoutable = (data: Billing.GetPayoutable.Request) => {
  return http.get(`/admin/billing/payoutable/${data.id}`)
    .then((res: Billing.GetPayoutable.Response<string>) => xform.billing.transformGetPayoutable(res));
};

export const createPayoutableAdjustment = (data: Billing.CreatePayoutableAdjustment.Request) => {
  return http.post(`/admin/billing/payoutable/${data.payoutableId}/adjustment`, data)
    .then((res: Billing.CreatePayoutableAdjustment.Response) => res);
};

export const editPayoutableAdjustment = (data: Billing.EditPayoutableAdjustment.Request) => {
  return http.patch(`/admin/billing/payoutable/${data.payoutableId}/adjustment/${data.id}`, data)
    .then((res: Billing.EditPayoutableAdjustment.Response) => res);
};

export const deletePayoutableAdjustment = (data: Billing.DeletePayoutableAdjustment.Request) => {
  return http.delete(`/admin/billing/payoutable/${data.payoutableId}/adjustment/${data.id}`)
    .then((res: Billing.DeletePayoutableAdjustment.Response) => res);
};

export const getPayouts = (data: Billing.GetPayouts.Request) => {
  const qs = queryString.stringify({
    id: data.id,
    after: data.after?.getTime(),
    before: data.before?.getTime(),
    description: data.description,
    status: data.status,
    user: data.user,
    method: data.method,
    stripeTransfer: data.stripeTransfer,
    limit: data.limit,
    cursor: data.cursor ? data.cursor : undefined,
  });
  return http.get(`/admin/billing/payouts${qs?.length ? `?${qs}` : ''}`)
    .then((res: Billing.GetPayouts.Response<string>) => xform.billing.transformGetPayouts(res));
};

export const addPayoutBatch = (data: Billing.AddPayoutBatch.Request) => {
  return http.post(`/admin/billing/payout/add-batch`, data)
    .then((res: Billing.AddPayoutBatch.Response) => res);
};

export const removePayoutBatch = (data: Billing.RemovePayoutBatch.Request) => {
  return http.post(`/admin/billing/payout/remove-batch`, data)
    .then((res: Billing.RemovePayoutBatch.Response) => res);
};

export const createPackage = (data: Billing.CreatePackage.Request): Promise<Billing.CreatePackage.Response> => {
  return http.put(`/admin/billing/packages`, data);
};

export const getPackage = (data: Billing.GetPackage.Request): Promise<Billing.GetPackage.Response> => {
  return http.get(`/admin/billing/packages/${data.packageId}`, { transformResponse: transformDates });
};

export const addPackageLicences = (data: Billing.AddPackageLicences.Request) => {
  return http.put(`/admin/billing/packages/${data.packageId}/licenses`, data);
};

export const getLicensePricing = (): Promise<Billing.GetLicensePricing.Response> => {
  return http.get(`/admin/billing/license-pricing`);
};
import { Link } from 'react-router-dom';
import { path, urls } from '@consts';
import { PayoutMethod } from '@enums';
import { Button } from 'components/Button';
import { BillingDoneProjectProps } from './interfaces';
import styles from './style/Done.css';

type Props = {
  projectName: string;
} & BillingDoneProjectProps;

export const ProjectCall = ({ navigation, method, nextLabel, projectName, referrals }: Props) => {
  const copy2 = method === PayoutMethod.Stripe
      ? `You will be paid through your connected Stripe account if you are selected for the project.`
      : `You will be paid by check to your address if you are selected for the project.`;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>
          {`You're all set for the ${projectName} project!`}
        </h1>
        <div className={styles.copy}>
          {`The client will review your suggested call times, and if you are selected, you will receive a calendar invitation from the system with dial information for the consultation soon!`}
        </div>
        <div className={styles.copy2}>
          {copy2}
        </div>
        <div className={styles.navigation}>
          <Navigation
            navigation={navigation}
            referrals={referrals}
            nextLabel={nextLabel} />
        </div>
      </div>
    </div>
  );
};

ProjectCall.defaultProps = {
  nextLabel: 'Complete',
};

const Navigation = (props: Omit<Props, 'method' | 'projectName'>) => {
  if (!props.referrals) {
    return (
      <>
        <Button
          className={styles.next}
          onClick={props.navigation.next}
          title={props.nextLabel}
          variant="brick" />
        <div className={styles.external}>
          <Button
            href={`${urls.FAQ}`}
            target="_blank"
            variant="link">
            {`Learn more about commonly asked questions`}
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.refer}>
        <div className={styles.header}>{`Know someone else who might be a fit for this project?`}</div>
        <div className={styles.copy}>{`Get paid for colleagues you refer for this project.`}</div>
        <Button
          className={styles.next}
          variant="brick"
          title="Refer a colleague"
          onClick={props.navigation.next} />
      </div>
      <div className={styles.footer}>
        <Link
          className={styles.link}
          to={path.Projects.Root}>
          Back to Home
        </Link>
      </div>
    </>
  );
};
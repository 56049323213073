import { cx } from '@utils';
import styles from './style/Layout.css';

export const Header = (props: NodeAsChildren) => {
  return (
    <div className={cx(styles.header, props.className)}>{props.children}</div>
  );
};

export const Row = (props: NodeAsChildren) => {

  return (
    <div className={cx(styles.row, props.className)}>
      {props.children}
    </div>
  );
};

export const Subtitle = (props: NodeAsChildren) => {
  return (
    <div className={cx(styles.subtitle, props.className)}>{props.children}</div>
  );
};

export const ThematicBreak = (props: TextAsChildren) => {
  return (
    <div className={styles.break}>
      <div className={styles.line} />
      <div className={styles.sub}>{props.children}</div>
      <div className={styles.line} />
    </div>
  );
};

export const Title = (props: TextAsChildren) => {
  return (
    <div className={cx(styles.title, props.className)}>{props.children}</div>
  );
};

type NodeAsChildren = {
  className?: string;
  children?:  React.ReactNode;
};

type TextAsChildren = {
  className?: string;
  children?:  React.ReactText;
};
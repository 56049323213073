import Color from 'color';

export const UnsavedHighlightColor = '#B2F2FB';

export const highlightColors = [
  {
    highlight: '#D4DFF9',
    menu: '#AEC5F7',
    hover: '#9AB9FD',
  },
  {
    highlight: '#D2F1DE',
    menu: '#A9E7BF',
    hover: '#94E3B0',
  },
  {
    highlight: '#FCD3D1',
    menu: '#F9B9B5',
    hover: '#F9A8A5',
  },
  {
    highlight: '#FFF1B6',
    menu: '#FCE67F',
    hover: '#F9E16F',
  },
  {
    highlight: '#F5DFFB',
    menu: '#EDB6FB',
    hover: '#E3B2F1',
  },
  {
    highlight: '#FFEAD9',
    menu: '#FFD1AE',
    hover: '#FDC59B',
  },
];

type ComputeSegmentStyle = {
  color: string;
  isFocused: boolean;
};

export function computeSegmentStyle({ color, isFocused }: ComputeSegmentStyle) {

  const isTextSelection = color === UnsavedHighlightColor;

  const darkenBy = isTextSelection ? .05 : .3;
  const opacity = isFocused
    ? 30
    : isTextSelection ? 80 : 10;

  const modifiedColor = Color(color)
    .darken(darkenBy)
    .saturate(.2)
    .rgb().array();

  const rgbString = `rgb(${modifiedColor[0]}, ${modifiedColor[1]}, ${modifiedColor[2]}, ${opacity}%) 0px 0px 0px 50px inset`;

  return `box-shadow: ${rgbString}, ${rgbString}, ${rgbString}; padding: 5px 0;`;
}
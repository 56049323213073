import extend from '@enums/extend';

export enum SurveyTemplateType {
  None = 0,
  CompetitiveIntelligence = 1,
  PersonaResearch = 2,
  NetPromoterScore = 3,
  EmployeeSatisfaction = 4,
  PricingStrategy = 5,
  MarketingCampaignEffectiveness = 6,
}

const surveyTemplateTypeMap = new Map([
  [SurveyTemplateType.None,                             { name: 'None' }],
  [SurveyTemplateType.CompetitiveIntelligence,          { name: 'Competitive Intelligence' }],
  [SurveyTemplateType.PersonaResearch,                  { name: 'Persona Research' }],
  [SurveyTemplateType.NetPromoterScore,                 { name: 'Net Promoter Score' }],
  [SurveyTemplateType.EmployeeSatisfaction,             { name: 'Employee Satisfaction' }],
  [SurveyTemplateType.PricingStrategy,                  { name: 'Pricing Strategy' }],
  [SurveyTemplateType.MarketingCampaignEffectiveness,   { name: 'Marketing Campaign Effectiveness' }],
]);

export type SurveyTemplateSection =
  CompetitiveIntelligenceTemplateSection |
  PersonaResearchTemplateSection |
  NetPromoterScoreTemplateSection |
  EmployeeSatisfactionTemplateSection |
  PricingStrategySection |
  MarketingCampaignEffectivenessSection
;

export enum CompetitiveIntelligenceTemplateSection {
  Screening            = 'ci-screening',
  CompetitorIndexing   = 'ci-competitor-indexing',
  CompetitorRanking    = 'ci-competitor-ranking',
  CurrentCustomer      = 'ci-current-customer',
  FormerCustomer       = 'ci-former-customer',
}

export enum PersonaResearchTemplateSection {
  Screening            = 'pr-screening',
  BudgetSpend          = 'pr-budget-spend',
  RoleEval             = 'pr-role-eval',
  DecisionMaking       = 'pr-decision-making',
}

export enum NetPromoterScoreTemplateSection {
  Screening             = 'nps-screening',
  NetPromoterTargt      = 'nps-net-promoter-target',
  NetPromoterCompetitor = 'nps-net-promoter-competitor',
}

export enum EmployeeSatisfactionTemplateSection {
  Main  = 'es-main',
}

export enum PricingStrategySection {
  Main  = 'ps-main',
}

export enum MarketingCampaignEffectivenessSection {
  Targeting          = 'mce-targeting',
  Solutions          = 'mce-solutions',
  InHouseSolution    = 'mce-in-hous',
  ThirdPartySolution = 'mce-third-party',
  NoSolution         = 'mce-no-solution',
  AnySolution        = 'mce-any-solution',
  Distribution       = 'mce-distribution',
}

export enum SurveyTemplateOptionKey {
  CurrentCustomer          = 'current-customer',
  FormerCustomer           = 'former-customer',
}

export enum SurveyTemplateEntityType {
  Category                 = 'category',
  Competitor               = 'competitor',
  EvaluationCriteria       = 'evaluation-criteria',
  PartnershipCriteria      = 'partnership-criteria',
  Target                   = 'target',
  TargetRole               = 'target-role',
}

export enum CommonTemplateQuestion {
  OrganizationRevenue                 = 'organization-revenue',
  OrganizationPrimaryIndustry         = 'organization-industry',
  RespondentRole                      = 'respondent-role',
  RespondentDepartment                = 'respondent-department',
}

export enum PersonaResearchQuestion {
  OrgCurrentCustomer                  = 'pr-organization-current-customer',

  RespondentInvolvement               = 'pr-respondent-involvement',
  DepartmentBudget                    = 'pr-department-budget',
  DepartmentSolutionsBudget           = 'pr-department-solutions-budget',
  OrganizationProducts                = 'pr-organization-products',
  OrganizationBusinessModel           = 'pr-organization-business-model',
  TopPriorities                       = 'pr-top-priorities',
  TopChallenges                       = 'pr-top-challenges',
  EvaluationCriteria                  = 'pr-evaluation-critria',
  OtherEvaluationCriteria             = 'pr-other-evaluation-criteria',
  PartnershipCriteria                 = 'pr-partnership-criteria',
  PartnershipCriteriaReason           = 'pr-partnership-criteria-reason',
  TargetRole                          = 'pr-target-role',
  FinalDecisionMaker                  = 'pr-final-decision-maker',
}

export enum CompetitiveIntelligenceQuestion {
  OrgCurrentCustomer                  = 'ci-organization-current-customer',

  SubjectAwareness                    = 'ci-subject-awareness',
  SubjectBrandStrength                = 'ci-subject-brand-strength',
  SubjectBrandStrengthReason          = 'ci-subject-brand-strength-reason',
  SubjectPrice                        = 'ci-subject-price',
  SubjectPriceReason                  = 'ci-subject-price-reason',
  SubjectServiceQuality               = 'ci-subject-service-quality',
  SubjectServiceQualityReason         = 'ci-subject-service-quality-reason',
  SubjectProductValue                 = 'ci-subject-product-value',
  SubjectProductValueReason           = 'ci-subject-product-value-reason',

  CurrentCustomerRationale              = 'ci-current-customer-rationale',
  CurrentCustomerReplacementWillingness = 'ci-current-customer-replacement-willingness',
  CurrentCustomerReplacementRationale   = 'ci-current-customer-replacement-rationale',
  CurrentCustomerReplacement            = 'ci-current-customer-replacement',

  FormerCustomerRationale             = 'ci-former-customer-rationale',
  FormerCustomerReplacement           = 'ci-former-customer-replacement',
}

export enum NetPromoterScoreQuestion {
  RespondentEvaluationRole            = 'nps-evaluation-role',
  OrgCurrentCustomer                  = 'nps-organization-current-customer',

  TargetRecommendation                = 'nps-target-recommendation',
  TargetRecommendationReason          = 'nps-target-recommendation-reason',
  TargetHigherRating                  = 'nps-target-higher-rating',

  CompetitorRecommendation                = 'nps-competitor-recommendation',
  CompetitorRecommendationReason          = 'nps-competitor-recommendation-reason',
  CompetitorHigherRating                  = 'nps-competitor-higher-rating',
}

export enum EmployeeSatisfactionQuestion {
  CurrentEmployee                 = 'es-current-employee',
  RespondentCommitted             = 'es-respondent-committed',
  ColleaguesCommitted             = 'es-colleagues-commited',
  OrgGoalsAligment                = 'es-org-goals-alignment',
  WorkEngaging                    = 'es-work-engaging',
  CareerGrowth                    = 'es-career-growth',
  CareerGoals                     = 'es-career-goals',
  CareerGoalsElaboration          = 'es-career-goals-elaboration',
  NecessaryTools                  = 'es-necessary-tools',
  Encouraged                      = 'es-encouraged',
  FeedbackConsidered              = 'es-feedback-considered',
  Recognization                   = 'es-recognization',
  RecognizationElaboration        = 'es-recognization-elaboration',
  Flexibility                     = 'es-flexibility',
  Informed                        = 'es-informed',
  UnderstandsChanges              = 'es-understands-changes',
  ChangesInLine                   = 'es-changes-in-line',
  SupervisorCares                 = 'es-supervisor-cares',
  SupervisorFeedback              = 'es-supervisor-feedback',
  MyResponsibilitiesClear         = 'es-my-responsibilities-clear',
  TeammateResponsibilitiesClear   = 'es-teammate-responsibilities-clear',
  LeadersTransparent              = 'es-leaders-transparent',
  Recommend                       = 'es-recommend',
  EmployeeExperience              = 'es-employee-experience',
}

export enum PricingStrategyQuestion {
  RecentCustomer                  = 'ps-rececnt-customer',
  EvaluationInvolvement           = 'ps-evaluation-involvement',
  PerceivedValue                  = 'ps-perceived-value',
  Awareness                       = 'ps-awareness',
  GeneralPerception               = 'ps-general-perception',
  PoorQuality                     = 'ps-poor-quality',
  GoodDeal                        = 'ps-good-deal',
  ApproachingExpensive            = 'ps-approaching-expensive',
  BeyondConsideraton              = 'ps-beyond-consideration',
  OtherInfluence                  = 'ps-other-influence',
}

export enum MarketingCampaignEffectivenessQuestion {
  Solutions                       = 'mce-solutions',
  InHouseSolution                 = 'mce-in-house-solution',
  ThirdPartySolution              = 'mce-third-party-solution',
  NoSolution                      = 'mce-no-solution',
  SolutionRating                  = 'mce-solution-rating',
  SolutionRatingExplanation       = 'mce-solution-rating-explanation',
  ImprovementAreas                = 'mce-improvement-areas',
  MainReason                      = 'mce-main-reason',
  Channels                        = 'mce-channels',
  ChannelsUsage                   = 'mce-channels-usage',
  ChannelsRanked                  = 'mce-channels-ranked',
}

export type SurveyTemplateQuestion =
  CommonTemplateQuestion |
  CompetitiveIntelligenceQuestion |
  PersonaResearchQuestion |
  NetPromoterScoreQuestion |
  EmployeeSatisfactionQuestion |
  PricingStrategyQuestion |
  MarketingCampaignEffectivenessQuestion
;

export default {
  SurveyTemplateType: extend(surveyTemplateTypeMap),
};

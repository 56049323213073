import { Button } from 'components/Button';
import { ButtonSet } from 'components/Modal/ButtonSet';
import { Prompt } from 'components/Modal/Prompt';
import { PromptProps } from './interfaces';
import styles from './style/Prompt.css';

type Props =
  PromptProps;

export const ActivateProjectPrompt = ({
  onClose,
  onConfirm,
  project,
  visible,
}: Props) => {

  const header = `Are you sure you want to activate the ${project.name} project?`;
  const body = `Activating this project will make it editable again.`;

  const footer = (
    <ButtonSet>
      <Button.Destructive
        className={styles.btn}
        onClick={onClose}
        variant="brick"
        title="Cancel" />
      <Button.Primary
        className={styles.btn}
        onClick={onConfirm}
        variant="brick"
        title="Activate" />
    </ButtonSet>
  );

  return (
    <Prompt
      body={body}
      footer={footer}
      header={header}
      onClose={onClose}
      visible={visible} />
  );
};

export default ActivateProjectPrompt;
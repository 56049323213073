import { Conference, Discovery, Inbound, Posts, Profile, SelfServeSurvey, Unsubscribe } from '@consts/path';
import { PathConfig } from '@containers/Routing/interfaces';

export const Paths: PathConfig = {
  any: [
    Discovery.Root,
    Inbound.Confirmation,
    Posts.Root,
    Profile.User,
    SelfServeSurvey.Root,
    Unsubscribe.Root,
    Conference.Root,
  ],
  exact: [

  ],
};
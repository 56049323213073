// eslint-disable-next-line import/newline-after-import
require('./sentry');
import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { AppSubscriber } from '@containers/Subscriber';
import { ZendeskScript } from '@containers/Zendesk';
import { useAppRoutes } from '@routes/hooks';
import { PathSajak, useGetReturnRoute } from '@routes';
import * as mixpanel from '@services/mixpanel';
import { BuildInfo } from 'components/BuildInfo';
import { CookieConsent } from 'components/CookieConsent';
import Toast from 'components/Toast';
import '@services/chat';
import '@services/network';
import styles from 'static/css/global/style.css';

mixpanel.init();

type Props = unknown;

export const App = (props: Props) => {
  const routes = useAppRoutes();
  const returnRoute = useGetReturnRoute();

  if (returnRoute) {
    return <Redirect to={returnRoute} />;
  }

  return (
    <AppSubscriber>
      <div className={styles.root}>
        <DocumentHelmet />
        <ZendeskScript />
        <BuildInfo />
        <CookieConsent />
        <PathSajak routes={routes} />

      </div>
      <Toast />
    </AppSubscriber>
  );
};

const DocumentHelmet = memo(() => {
  const description = `Vancery is a Professional Exchange that connects industry experts and decision makers.`;

  const isProd = process.env.VANCERY_ENV === 'production';

  return (
    <Helmet
      defaultTitle="Vancery"
      titleTemplate="%s | Vancery">
      {!isProd &&
        <meta name="robots" content="noindex" />
      }
      {isProd &&
        <link rel="sitemap" href={`${process.env.PUBLIC_URL}/sitemap.xml`} type="application/xml" />
      }
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}/`} />
      <meta
        name='description'
        content={description} />
      <meta
        name='og:description'
        content={description} />
    </Helmet>
  );
});
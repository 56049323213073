import * as types from '@store/action-types';
import { Folders } from '@store/interfaces';

export const folderCreated = (data: Folders.FolderCreated.State): Folders.FolderCreated.Action => {
  return {
    capabilities: data.capabilities,
    folder: data.folder,
    object: data.object,
    type: types.FOLDER_CREATED,
  };
};

export const folderDeleted = (data: Folders.FolderDeleted.State): Folders.FolderDeleted.Action => {
  return {
    folderId: data.folderId,
    objectId: data.objectId,
    type: types.FOLDER_DELETED,
  };
};

export const folderRenamed = (data: Folders.FolderRenamed.State): Folders.FolderRenamed.Action => {
  return {
    folder: data.folder,
    object: data.object,
    type: types.FOLDER_RENAMED,
  };
};
import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { GetRecordingUrl } from '@api/interfaces/calls';
import * as api from '@api';

type Props = GetRecordingUrl.Request;

type Data = GetRecordingUrl.Response;
type QueryKey = [string, Props];

export function useCallRecording(
  data: Props,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  return useQuery(['call-recording-url', data], ({ queryKey }) => {
    const params = queryKey[1] as Props;

    return api.calls.getRecordingUrl(params);

  }, options);
}

import { Fragment, useCallback } from 'react';
import { AddQuestionButtonBordered, AddQuestionDivider } from '@presentation/SurveyBuilder';
import { SurveySection } from '@presentation/Survey';
import { SurveyBuilderQuestionContainer } from '@containers/SurveyBuilder.Question/QuestionContainer';
import { SurveyQuestion } from '@/types/survey';
import { QuestionBuilderItem } from 'components/SurveyBuilder.Question';
import { SurveySectionBuilderProps } from './interfaces';
import SurveySectionHeader from './Header';
import styles from './style/SectionBuilder.css';

export const SurveySectionBuilder = ({
  addQuestion,
  ...props
}: SurveySectionBuilderProps) => {

  const renderQuestion = useCallback((item: SurveyQuestion) => {
    return (
      <div className={styles.question}>
        <SurveyBuilderQuestionContainer item={item}>
          <QuestionBuilderItem includeTagging={props.includeTagging} />
        </SurveyBuilderQuestionContainer>
      </div>
    );
  }, [
    props.includeTagging,
  ]);

  const renderQuestions = useCallback(() => {
    return (
      <>
        {props.questions
          .map((item, i) =>
            <Fragment key={item.base.identifier}>
              {renderQuestion(item)}
              {i !== props.questions.length - 1 &&
                <AddQuestionDivider
                  disabled={!props.canAddQuestion}
                  onClick={addQuestion(item.ordinal + 1)} />
              }
            </Fragment>
          )
        }
      </>
    );
  }, [
    addQuestion,
    props.canAddQuestion,
    renderQuestion,
    props.questions,
  ]);

  const canDelete = !props.editingOrdinal && props.item.metadata.canDelete;

  return (
    <>
      <SurveySection>
        <SurveySectionHeader
          canDelete={canDelete}
          className={styles.header}
          onDelete={props.onDeleteSection}
          item={props.item}
          updateName={props.updateSectionName} />
        <AddQuestionDivider
          lineClassName={styles.firstDivider}
          disabled={!props.canAddQuestion || !props.questions.length}
          onClick={addQuestion(props.questions[0]?.ordinal)} />
        {renderQuestions()}
        {props.canAddQuestion &&
          <div className={styles.addQuestion}>
            <AddQuestionButtonBordered
              onClick={addQuestion(props.lastQuestionOrdinal + 1)} />
          </div>
        }
      </SurveySection>
    </>
  );
};

export default SurveySectionBuilder;
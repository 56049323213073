import { useCallback, useEffect } from 'react';
import {
  useSearchFetchAll,
  useSearchFetchMembers,
  useSearchFetchFiles,
  useSearchFetchFolders,
  useSearchFetchInteractions,
  useSearchFetchProjects,
  useSearchReset,
} from '@containers/WorkspaceGlobalSearch/hooks';
import { FiltersQuery, useSearchFiltersState } from '@containers/WorkspaceGlobalSearch/hooks/useSearchFilterState';
import SearchTabViewContainer from './Search.Tab.ViewContainer';
import styles from './style/Main.css';

export default function SearchMain() {
  const reset = useSearchReset();
  const fetchAll = useSearchFetchAll();
  const fetchProjects = useSearchFetchProjects();
  const fetchMembers = useSearchFetchMembers();
  const fetchFiles = useSearchFetchFiles();
  const fetchFolders = useSearchFetchFolders();
  const fetchInteractions = useSearchFetchInteractions();

  const [query] = useSearchFiltersState();

  const fetcher = useCallback(({ query: { tab, q } }: FetcherData) => {
    switch (tab) {
      case 'all': fetchAll({ query: q }); break;
      case 'projects': fetchProjects({ query: q, size: 50 }); break;
      case 'members': fetchMembers({ query: q, size: 50 }); break;
      case 'files': fetchFiles({ query: q, size: 50 }); break;
      case 'folders': fetchFolders({ query: q, size: 50 }); break;
      case 'interactions': fetchInteractions({ query: q, size: 50}); break;
    }

  }, [fetchAll, fetchProjects, fetchMembers, fetchFiles, fetchFolders, fetchInteractions]);

  // on tab change
  useEffect(() => {
    fetcher({ query });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.tab, fetcher]);

  // on query change
  useEffect(() => {
    reset();
    fetcher({ query });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.q, fetcher, reset]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.query}>{`Search: "${query.q}"`}</div>
        <SearchTabViewContainer />
      </div>
    </div>
  );
}

export { SearchMain };

type FetcherData = {
  query: FiltersQuery;
}
import { MouseEvent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Back } from '@presentation/Back';
import { BackProps } from '@presentation/interfaces';
import { cx } from '@utils';
import { Button } from 'components/Button';
import { ButtonProps } from 'components/Button/interfaces';
import styles from './style.css';

export const BackButton = ({ className, onClick, ...props }: BackProps) => {
  const history = useHistory();

  const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    return onClick
      ? onClick(e)
      : history.goBack();
  }, [history, onClick]);

  if (onClick === null) return null;

  return (
    <Back
      className={cx(styles.back, className)}
      onClick={handleClick}
      {...props} />
  );
};

type FooterProps = {
  children?: React.ReactNode;
  className?: string;
};

export const Footer = (props: FooterProps) => {
  return (
    <div className={cx(styles.btns, props.className)}>
      {props.children}
    </div>
  );
};

export const NextButton = ({ className, ...props }: ButtonProps) => {
  return (
    <Button
      className={cx(styles.btn, className)}
      {...props}>
      {props.children}
    </Button>
  );
};

type NextButtonDefaultProps =
  Pick<ButtonProps,
  | 'color'
  | 'children'
  | 'variant'>;

const defaultProps: NextButtonDefaultProps = {
  color: 'primary',
  children: 'Next',
  variant: 'brick',
};

NextButton.defaultProps = defaultProps;
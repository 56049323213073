import { useState } from 'react';
import { useSelector } from 'react-redux';
import { isBefore } from 'date-fns';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks/useProjectState';
import { Call } from '@/types';
import { Accordion } from 'components/Accordion';
import ProjectCallItem from './CallItem';
import CancelCallAlert from './CancelCallAlert';
import styles from './style/ProjectCalls.css';
import { useCallsByStatus } from './useCallsByStatus';

const mapState = (state: Store.State) => ({
  contacts: state.contacts,
});

type ModalState = {
  item?: Call;
};

export const ProjectCalls = () => {
  const project = useProjectOrLatestChild();
  const state = useSelector(mapState);
  const [modal, updateModal] = useState<ModalState>({});
  const calls = useCallsByStatus(project);

  function openModal(item: Call) {
    updateModal({ item });
  }

  function closeModal() {
    updateModal({});
  }

  function renderCalls(items: Call[]) {
    return [...items]
    .sort((a, b) => isBefore(a.timeStart, b.timeStart) ? -1 : 1)
    .map(x => {
      const user = state.contacts[x.userId];

      const onCancelCall = (item: Call) => {
        return () => openModal(item);
      };

      return (
        <ProjectCallItem
          item={x}
          key={x.id}
          onCancelCall={onCancelCall(x)}
          project={project}
          user={user} />
      );
    });
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <Accordion
            className={styles.accordion}
            label="Rescheduling">
            {renderCalls(calls.rescheduling)}
          </Accordion>
          <Accordion
            className={styles.accordion}
            label="Scheduled">
            {renderCalls(calls.scheduled)}
          </Accordion>
          <Accordion
            className={styles.accordion}
            label="Pending">
            {renderCalls(calls.pending)}
          </Accordion>
          <Accordion
            className={styles.accordion}
            label="Missed">
            {renderCalls(calls.missed)}
          </Accordion>
        </div>
      </div>
      {modal.item &&
        <CancelCallAlert
          item={modal.item}
          onClose={closeModal} /> }
    </>
  );
};

export default ProjectCalls;
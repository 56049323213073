import { cx } from '@utils';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const Container = (props: Props) => {
  return (
    <div className={cx(props.className)}>
      {props.children}
    </div>
  );
};

export { Container };
export default Container;
import { useCallback, useMemo } from 'react';
import * as chart from '@containers/SurveyResponses/utils';
import { ClosedResponseOptionRow, OpenedResponseOptionRow } from '@presentation';
import { MaxDifferenceQuestion } from '@/types';
import { formatPlurality } from '@utils';
import ResponseOptionUser from './ResponseOptionUser';
import MaxDiffUser from './MaxDiff.Data.User';
import styles from './style/MaxDiff.Data.Option.Choices.css';

export default function MaxDiffDataOptionChoices({ active, response, settings, onClick }: OptionChoicesProps) {
  const handleClick = useCallback((choice: MaxDifferenceQuestion.Choices) => () => {
    onClick(choice);
  }, [onClick]);

  const handleClose = useCallback(() => {
    onClick(undefined);
  }, [onClick]);

  return (
    <div className={styles.root}>
      <Choice
        active={active === 'most'}
        choice='most'
        label={settings.label.right}
        value={response.right}
        userIds={response.responses.rightUserIds}
        userResponseMap={response.responses.right}
        onClick={handleClick}
        onClose={handleClose} />
      <Choice
        active={active === 'least'}
        choice='least'
        label={settings.label.left}
        value={response.left}
        userIds={response.responses.leftUserIds}
        userResponseMap={response.responses.left}
        onClick={handleClick}
        onClose={handleClose} />
      <Choice
        active={active === 'nc'}
        choice='nc'
        label={`Not Selected`}
        value={response.nc}
        userIds={response.responses.ncUserIds}
        userResponseMap={response.responses.nc}
        onClick={handleClick}
        onClose={handleClose} />
    </div>
  );
}

function Choice({ active, choice, label, value, userIds, userResponseMap, onClick, onClose }: ChoiceProps) {
  const color = useMemo(() => chart.maxdiff.getChoiceColor(choice), [choice]);
  const subtitle = useMemo(() => `(${value} ${formatPlurality('time', value)})`, [value]);

  return active
    ? (
      <>
        <OpenedResponseOptionRow
          className={styles.choice}
          color={color}
          label={label}
          subtitle={subtitle}
          onClose={onClose} />
        {userIds.map(userId =>
          <ResponseOptionUser
            key={userId}
            renderItem={value => <MaxDiffUser {...value} count={userResponseMap[userId]} />}
            userId={userId} />)}
      </>
      )
    : <ClosedResponseOptionRow
        className={styles.choice}
        color={color}
        label={label}
        subtitle={subtitle}
        onViewAll={onClick(choice)} />;
}

export { MaxDiffDataOptionChoices };

type OptionChoicesProps = {
  active: MaxDifferenceQuestion.Choices;
  response: MaxDifferenceQuestion.Aggregate.OptionResults;
  settings: MaxDifferenceQuestion.Settings;
  onClick: (choice: MaxDifferenceQuestion.Choices) => unknown;
}

type ChoiceProps = {
  active: boolean;
  choice: MaxDifferenceQuestion.Choices;
  label: string;
  value: number;
  userIds: number[];
  userResponseMap: { [userId: number]: number; };
  onClick: (choice: MaxDifferenceQuestion.Choices) => () => unknown;
  onClose: () => unknown;
}
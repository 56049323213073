import { useCallback } from 'react';
import { Trash2 } from 'react-feather';
import { useSelectProject, useSelectUser } from '@containers/Store';
import { useHasClientRole } from '@containers/User';
import { ProjectCall } from '@/types';
import { getLocationFor } from '@utils';
import { useRescheduleProjectCall } from '@containers/Scheduler/hooks';
import { Button } from 'components/Button';
import { EventDate, CallParticipant, JoinCall, SelectTime } from './EventPopover.Shared';
import styles from './style/EventPopover.css';

type Props = {
  item: ProjectCall;
  conferenceIdentifier: number;
  onCancel: () => unknown;
  showActions: boolean;
  withUserId: number;
};

export const ProjectCallEventPopover = ({ item, conferenceIdentifier, onCancel, showActions, withUserId }: Props) => {
  const me = useSelectUser();
  const hasClientRole = useHasClientRole();
  const project = useSelectProject(item.projectId);
  const [canReschedule, reschedulingLocation] = useRescheduleProjectCall(item.id);

  const renderParticipant = useCallback((userId: number) => {

    const hideLink = userId === me.id ||
      (userId !== me.id && !hasClientRole);

    return (
      <CallParticipant
        hideLink={hideLink}
        item={item}
        userId={userId} />
    );

  }, [
    hasClientRole,
    item,
    me.id,
  ]);

  const confirmingLocation = getLocationFor.scheduling.selecting({
    callId: item.id,
    projectId: item.projectId,
    scheduleeId: me.id,
    schedulerId: item.lastModifiedBy,
  });

  return (
    <>
      <div className={styles.body}>
        <EventDate
          start={item.timeStart}
          end={item.timeEnd} />

        <div className={styles.title}>
          {project.name}
        </div>

        {renderParticipant(me.id)}
        {renderParticipant(withUserId)}

        {showActions && canReschedule &&
          <Button
            className={styles.callaction}
            to={reschedulingLocation}>
            Reschedule
          </Button>
        }

        {showActions &&
          <SelectTime
            item={item}
            location={confirmingLocation} />
        }

        {showActions &&
          <JoinCall
            item={item}
            conferenceIdentifier={conferenceIdentifier} />
        }
      </div>
      <div className={styles.actions}>
        {showActions &&
          <Trash2
            className={styles.delete}
            onClick={onCancel}
            size={18} />
        }
      </div>
    </>
  );
};

export default ProjectCallEventPopover;
import { useContext, useEffect } from 'react';
import { WorkspaceGlobalSearchBarResetContext } from './Context';
import PastSearches from './PastSearches';
import RecentProfiles from './RecentProfiles';
import styles from './style/Results.EmptyQuery.css';

export default function ResultsEmptyQuery() {
  const { emptyResultReset } = useContext(WorkspaceGlobalSearchBarResetContext);

  useEffect(() => {
    emptyResultReset();
  }, [emptyResultReset]);

  return (
    <div className={styles.root}>
      <RecentProfiles />
      <PastSearches />
    </div>
  );
}

export { ResultsEmptyQuery };
import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';
import { CardContext } from './CardContext';
import styles from './BaseballCard.css';

export const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'var(--pri-01)',
    color: 'var(--black-l)',
    fontFamily: 'var(--font-reg)',
    width: 300,
    minHeight: 40,
    fontSize: theme.typography.pxToRem(14),
    borderRadius: '8px',
    padding: '0px',
    boxShadow: '0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%)',
    zIndex: 2,
    position: 'relative',
    overflow: 'hidden',
  },
  popper: {
    zIndex: 2,
  },
}))(Tooltip);

export type CardTooltipProps = {
  children: React.ReactNode;
  title: React.ReactNode;
  className?: string;
};

export const CardTooltip = (props: CardTooltipProps) => {
  const [isOpen, changeOpen] = useState(false);
  return (
    <CardContext.Provider value={{
      closeCard: () => {
        changeOpen(false);
      },
    }}>
      <HtmlTooltip
        className={props.className}
        title={props.title}
        enterDelay={750}
        placement={'bottom-start'}
        open={isOpen}
        onOpen={() => changeOpen(true)}
        onClose={() => changeOpen(false)}
        PopperProps={{
          //Stop events from bubbling out of the baseball card, this is for situations where the tooltip is inside links (like viewing survey responses).
          onClick: e => e.stopPropagation(),
          className: styles.titleContainer,
        }}>
        <div>
          {props.children}
        </div>
      </HtmlTooltip>
    </CardContext.Provider>
  );
};
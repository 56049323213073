import { useLock } from '@utils';
import { Button } from 'components/Button';
import { ButtonBaseProps } from 'components/Button/interfaces';
import { useFullCalButtonState } from 'components/Calendar/hooks/useFullCalButtonState';

type Props = {
  children: React.ReactNode;
  disabled: boolean;
  className?: string;
  onClick: <T>() => Promise<T>;
} & Pick<ButtonBaseProps, 'color' | 'variant'>;

const CalendarButton = (props: Props) => {
  const [isBusy, lock] = useLock();

  /*
  * Hack to update the button created in the
  * header of the full calendar component.
  */
  useFullCalButtonState(props.disabled);

  return (
    <Button
      className={props.className}
      color={props.color}
      disabled={props.disabled}
      onClick={lock(props.onClick)}
      variant={props.variant}>
      {props.children}
    </Button>
  );
};

const defaultProps = {
  color: 'affirmative',
  variant: 'brick',
};

CalendarButton.defaultProps = defaultProps;

export default CalendarButton;
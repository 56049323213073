import { SurveyQuestionType, SurveyActionType, utils as enumUtils } from '@enums';
import { SurveyVersion } from '@/types';
import {
  SurveyBuilderState,
} from './SurveyBuilderState';
import { SurveyBuilderOptionsContext } from './Context';

type Props = {
  initialValue?: SurveyVersion;
} & ChildrenProps;

const actionTypes = [
  SurveyActionType.None,
  SurveyActionType.Disqualification,
  SurveyActionType.SkipToEnd,
  SurveyActionType.SkipToQuestion,
  SurveyActionType.SkipToSection,
  SurveyActionType.IncludeSection,
];

export const ProjectSurveyBuilderState = (props: Props) => {

  const options = {
    actionTypes,
    maxQuestions: 500,
    questionTypes: enumUtils.SurveyQuestionType.values(),
  };

  return (
    <SurveyBuilderOptionsContext.Provider value={options}>
      <SurveyBuilderState initialValue={props.initialValue}>
        {props.children}
      </SurveyBuilderState>
    </SurveyBuilderOptionsContext.Provider>
  );
};

export default ProjectSurveyBuilderState;

import { useContext, useEffect, useRef } from 'react';
import { ReactEditor, useEditor } from 'slate-react';
import { cx } from '@utils';
import { Portal } from 'components/Portal';
import { RichTextAutoCompleteContext } from './Context';
import styles from './style/ListboxPopover.css';
import { useMentionsHelpers } from './hooks/useMentionsHelpers';

type Props = {
  className?: string;
};

const RichTextListboxPopover = (props: Props) => {
  const editor = useEditor();
  const { getOptionLabel, options } = useContext(RichTextAutoCompleteContext);
  const [input, meta] = useMentionsHelpers(editor);
  const ref = useRef<HTMLDivElement | null>();

  useEffect(() => {
    if (meta.target && options.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, meta.target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [
    editor,
    meta.index,
    options.length,
    meta.search,
    meta.target,
  ]);

  const open = meta.target && options.length > 0;

  if (!open) return null;

  return (
    <Portal>
      <div
        className={cx(styles.root, props.className)}
        ref={ref}>

        {options.map((x, i) =>
          <div
            className={cx(styles.option, { [styles.active]: i === meta.index })}
            onMouseDown={() => input.handleSelectOption(x)}
            onMouseOver={input.handleMouseOver(i)}
            key={`${getOptionLabel(x)}-${i}`}>
            {getOptionLabel(x)}
          </div>)}

      </div>
    </Portal>
  );
};

const defaultProps = {
  className: '',
};

RichTextListboxPopover.defaultProps = defaultProps;

export { RichTextListboxPopover };
export default RichTextListboxPopover;
import { WorkspaceFilePreviewContainer, useMatchFileStateFromUrl } from '@containers';
import { useHasGroupFeature } from '@store/hooks';
import FileNotFound from './FileNotFound';
import FilePreview from './FilePreview';
import ScreenLayout from './Layout';
import Sidebar from './Sidebar';
import styles from './style/Layout.css';

export const StateDependent = () => {
  const canViewTags = useHasGroupFeature('userTagging');
  const state = useMatchFileStateFromUrl();

  if (!state.file) {
    return <FileNotFound />;
  }

  return (
    <ScreenLayout>
      <div className={styles.col}>
        <FilePreview />
      </div>
      {canViewTags &&
        <div className={styles.col}>
          <Sidebar />
        </div>
      }
    </ScreenLayout>
  );
};

export const WorkspaceFilePreview = () => (
  <WorkspaceFilePreviewContainer>
    <StateDependent />
  </WorkspaceFilePreviewContainer>
);

export default WorkspaceFilePreview;
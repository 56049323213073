import {
  CalendarDateEvent,
  CalendarDateSegment,
  CalendarISOEvent,
  CalendarISOSegment,
} from '@/types';

export const transformEventDates = (items: CalendarISOEvent[]) => {
  return items.map<CalendarDateEvent>(event => ({
    ...event,
    ...transformRange(event),
    segments: {
      hour: transformEventSegments(event),
    },
  }));
};

function transformEventSegments(event: CalendarISOEvent): CalendarDateSegment[] {
  const slots = event.segments?.hour ?? [];

  return slots.map(transformRange);
}

function transformRange(item: CalendarISOSegment) {
  return {
    start: new Date(item.start),
    end: new Date(item.end),
  };
}
import { useContext, useMemo } from 'react';
import { CompletedSurveyContext } from '../Context';
import { SurveyResponseAnswer } from '@/types';

export function useCompletedSurvey() {
  const context = useContext(CompletedSurveyContext);
  if (!context) {
    throw new Error('useCompletedSurvey must be used within a CompletedSurveyContext');
  }

  const questions = useMemo(() => context.response.items.map(a => a.question), [context.response.items]);

  const answersMap = useMemo(() => {
    return context.response.items.reduce<AnswerMap>((acc, curr) => ({
      ...acc,
      [curr.question.id]: curr.answer,
    }), {});
  }, [context.response.items]);

  return {
    answersMap,
    questions,
  };
}

export default useCompletedSurvey;

type AnswerMap = {
  [versionQuestionId: number]: SurveyResponseAnswer;
};
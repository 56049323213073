import { useState } from 'react';
import { cx } from '@utils';
import Button from 'components/Button';
import { ConnectCalendarModal } from 'components/Modal/ConnectCalendar';
import styles from './style/ConnectCalendar.css';

type Props = {
  className?: string;
};

const ConnectCalendar = (props: Props) => {
  const [open, setOpen] = useState(false);

  function toggleModal() {
    setOpen(!open);
  }

  return (
    <>
      <div className={cx(styles.root, props.className)}>
        <div className={styles.wrap}>
          <div className={styles.desc}>
            <div>Connect your calendar for a </div>
            <div>streamlined scheduling experience</div>
          </div>
          <Button.Secondary
            className={styles.connect}
            onClick={toggleModal}
            variant="brick">
            Connect Calendar
          </Button.Secondary>
        </div>
      </div>
      {open &&
        <ConnectCalendarModal onClose={toggleModal} />}
    </>
  );
};

export { ConnectCalendar };
export default ConnectCalendar;
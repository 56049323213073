import { useCallback, useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import {
  useMaxDiffFormContext,
  useMaxDiffNavigationContext,
  SurveyFormNavigationContext,
} from './Context';
import {
  useSubmitAnswer,
  useSurveyStepperNavigation,
  useSurveyFormQuestionContext,
  useSurveyResponseGoBack,
} from './hooks';

export default function MaxDiffNavigationContainer({ children }: ChildrenProps) {
  const { question } =
    useSurveyFormQuestionContext<SurveyQuestionType.MaxDifference>();

  const submitAnswer = useSubmitAnswer();
  const goBack = useSurveyResponseGoBack();
  const { currentSet } = useMaxDiffFormContext();
  const { back: maxdiffBack, next: maxdiffNext, nextDisabled } = useMaxDiffNavigationContext();
  const navigation = useSurveyStepperNavigation(question.ordinal);

  const back = useCallback(() => {
    if (currentSet === 1) {
      return goBack.mutateAsync();
    } else {
      return Promise.resolve(maxdiffBack());
    }
  }, [
    currentSet,
    maxdiffBack,
    goBack.mutateAsync,
  ]);

  const next = useCallback(() => {
    if (currentSet === question.settings.sets) {
      return submitAnswer.mutateAsync();
    } else {
      return Promise.resolve(maxdiffNext());
    }
  }, [
    currentSet, question.settings.sets,
    submitAnswer.mutateAsync, maxdiffNext,
  ]);

  const showBack = useMemo(() => {
    return currentSet === 1
      ? navigation.showBack
      : false;
  }, [currentSet, navigation.showBack]);

  const showComplete = useMemo(() => {
    return currentSet === question.settings.sets
      ? navigation.showComplete
      : false;
  }, [
    currentSet, question.settings.sets,
    navigation.showComplete,
  ]);

  const value = {
    back: {
      hidden: !showBack,
      isError: goBack.isError,
      isLoading: goBack.isLoading,
      submit: back,
    },
    next: {
      disabled: nextDisabled,
      isError: submitAnswer.isError,
      isLoading: submitAnswer.isLoading,
      submit: next,
    },
    showComplete,
  };

  return (
    <SurveyFormNavigationContext.Provider value={value}>
      {children}
    </SurveyFormNavigationContext.Provider>
  );
}

export { MaxDiffNavigationContainer };
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import * as API from '@api/interfaces';
import * as api from '@api';
import { useSelectGroup } from '@containers/Store';
import { CSVFieldMappingContext, CSVFileContext, TaggingAutoSuggestContext } from '@containers/Group.Contacts.Import/Context';
import { Tag } from '@containers/Group.Contacts.Import/interfaces.tagging';
import { BackButton, Footer, NextButton } from '@screens/ProjectCreation/Footer';

type Props = unknown;

export const CommitSummaryNavigation = (props: Props) => {
  const location = useLocation();
  const searchparams = new URLSearchParams(location.search);
  const app = searchparams.get('app');

  if (app === 'hubspot') {
    return (
      <HubSpotCommitSummaryNavigation />
    );
  }

  return (
    <CSVCommitSummaryNavigation />
  );
};

const HubSpotCommitSummaryNavigation = (props: Props) => {
  const history = useHistory();
  const group = useSelectGroup();

  const mutation = useMutation([
    `post:groups/contacts/import/hubspot/run`,
    group?.id,
  ], () => {
    return api.groups.contacts.importHubSpot({
      groupId: group.id,
    });
  }, {
    onSuccess: () => {
      history.replace(`/contacts`);
    },
  });

  return (
    <Footer>
      <BackButton onClick={() => history.push(`/contacts/import`)} />
      <NextButton
        color="affirmative"
        disabled={mutation.isLoading}
        onClick={() => mutation.mutate()}>
        Start Import
      </NextButton>
    </Footer>
  );

};

const CSVCommitSummaryNavigation = (props: Props) => {
  const history = useHistory();
  const group = useSelectGroup();
  const ctx = useContext(CSVFieldMappingContext);
  const [file] = useContext(CSVFileContext);
  const { selected } = useContext(TaggingAutoSuggestContext);

  const mutation = useMutation([
    `post:groups/contacts/import/run`,
    group?.id,
    file,
    ctx.values,
    selected.items,
  ], () => {
    return api.groups.contacts.importCSV({
      company: ctx.values.company,
      email: ctx.values.email,
      file,
      firstName: ctx.values.firstName,
      groupId: group.id,
      lastName: ctx.values.lastName,
      phone: ctx.values.phone,
      title: ctx.values.title,
      tagging: getTaggingParamValue(selected.items),
    });
  }, {
    onSuccess: () => {
      history.replace(`/contacts`);
    },
  });

  return (
    <Footer>
      <BackButton />
      <NextButton
        color="affirmative"
        disabled={mutation.isLoading}
        onClick={() => mutation.mutate()}>
        Start Import
      </NextButton>
    </Footer>
  );
};

CommitSummaryNavigation.displayName = 'CommitSummaryNavigation';

function getTaggingParamValue(items: Tag[]) {
  return items.reduce((acc, x) => ({
    apply: !x.new ? acc.apply.concat(x.id as number) : acc.apply,
    create: x.new ? acc.create.concat(x.name) : acc.create,
  }), {
    apply: [],
    create: [],
  } as API.Groups.Contacts.CSVImport.Request['tagging']);
}
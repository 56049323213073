import { useCallback, useEffect, useMemo, useState } from 'react';
import * as api from '@services/api';
import { SearchType } from '@enums';
import { useAsyncDebounce } from '@utils';
import { GroupTagUpdateItem } from '@/types';
import AutoComplete from 'components/AutoComplete';

type Props = {
  ignoreList?: IgnoreListItem[];
  onSelect: (item: GroupTagUpdateItem) => unknown;
};

export default function GroupTagAutocomplete({ ignoreList, onSelect }: Props) {
  const [text, setText] = useState<string>('');
  const [items, setItems] = useState<GroupTagUpdateItem[]>([]);

  const ignoreListMapped = useMemo(() => ignoreList.map(item => `${item.id}`), [ignoreList]);

  const fetch = useCallback(async (text: string) => {
    const result = await api.search.getAutocompleteResults<SearchType.GroupTag>({
      type: SearchType.GroupTag,
      text,
      ignoreList: ignoreListMapped,
    }).then(result => buildAutocompleteItems(text, ignoreList, result));
    setItems(result);
  }, [ignoreList, ignoreListMapped]);

  const fetchDebounced = useAsyncDebounce((text: string) => fetch(text), 150);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setText(text);
    if (!text?.length) {
      setItems([]);
      return;
    }
    fetchDebounced(text);
  }, [fetchDebounced]);

  const handleSelect = useCallback((item: GroupTagUpdateItem) => {
    setItems([]);
    setText('');
    onSelect(item);
  }, [onSelect]);

  useEffect(() => {
    fetch('');
  }, [fetch]);

  return (
    <AutoComplete
      getItemValue={(item: GroupTagUpdateItem) => item.isCustom ? `Add "${item.name}"` : item.name}
      items={items}
      name="group-tag"
      onChange={handleSearch}
      onSelect={handleSelect}
      onFocus={() => fetch(text)}
      placeholder="Search for or create tags"
      value={text} />
  );
}

GroupTagAutocomplete.defaultProps = {
  ignoreList: [],
};

export { GroupTagAutocomplete };

function buildAutocompleteItems(text: string, ignore: IgnoreListItem[], items: GroupTagAutocompleteItem[]): GroupTagUpdateItem[] {
  const found = items.map(i => ({ id: i.id, name: i.name, isCustom: false }));

  const sanitizedText = text.trim().split(' ').map(c => c.charAt(0).toUpperCase() + c.slice(1)).join(' ');

  const showCustom
    = sanitizedText.length >= 2
    && !ignore.some(s => s.name.toLowerCase() === sanitizedText.toLowerCase())
    && !found.some(s => s.name.toLowerCase() === sanitizedText.toLowerCase());

  const custom
    = showCustom
      ? { id: `TAG~${sanitizedText}`, name: `${sanitizedText}`, isCustom: true }
      : null;

  return [ ...found, custom ].filter(Boolean);
}

type GroupTagAutocompleteItem = {
  id: number;
  name: string;
  type: SearchType.GroupTag;
}

type IgnoreListItem =
  GroupTagUpdateItem;
import { useSearchLoading, useSearchResultsAll } from '@containers/WorkspaceGlobalSearch/hooks';
import { formatPlurality } from '@utils';
import {
  WorkspaceGlobalSearchFullMemberResults, WorkspaceGlobalSearchFullFileResults,
  WorkspaceGlobalSearchFullFolderResults, WorkspaceGlobalSearchFullProjectResults, WorkspaceGlobalSearchFullInteractionResults,
} from '@/types';
import MemberSearchBanner from './MemberSearchBanner';
import ProjectsTable from './Search.Table.Projects';
import MembersTable from './Search.Table.Members';
import FilesTable from './Search.Table.Files';
import FoldersTable from './Search.Table.Folders';
import InteractionsTable from './Search.Table.Interactions';
import styles from './style/Tab.css';

const pageSize = 10;

export default function SearchTabAll() {
  const loading = useSearchLoading();
  const { projects, members, files, folders, interactions } = useSearchResultsAll();

  const isLoading = loading.all.untouched && loading.all.loading;

  return (
    <div className={styles.root}>
      <MemberSearchBanner />
      <Projects
        result={projects}
        isLoading={isLoading} />
      <Members
        result={members}
        isLoading={isLoading} />
      <Interactions
        result={interactions}
        isLoading={isLoading} />
      <Files
        result={files}
        isLoading={isLoading} />
      <Folders
        result={folders}
        isLoading={isLoading} />
    </div>
  );
}

function Projects({ result, isLoading }: ProjectsProps) {
  const data = result?.items ?? [];
  const viewAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(data.length, result?.total, 'Project')})`
    : null;

  return (
    <ProjectsTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      viewAll={viewAll} />
  );
}

function Members({ result, isLoading }: MembersProps) {
  const data = result?.items ?? [];
  const viewAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(data.length, result?.total, 'Member')})`
    : null;

  return (
    <MembersTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      viewAll={viewAll} />
  );
}

function Files({ result, isLoading }: FilesProps) {
  const data = result?.items ?? [];
  const viewAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(data.length, result?.total, 'File')})`
    : null;

  return (
    <FilesTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      viewAll={viewAll} />
  );
}

function Folders({ result, isLoading }: FoldersProps) {
  const data = result?.items ?? [];
  const viewAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(data.length, result?.total, 'Folder')})`
    : null;

  return (
    <FoldersTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      viewAll={viewAll} />
  );
}

function Interactions({ result, isLoading }: InteractionsProps) {
  const data = result?.items ?? [];
  const viewAll = result?.total > pageSize;
  const countText = !isLoading
    ? `(${getCountLabel(data.length, result?.total, 'Interaction')})`
    : null;

  return (
    <InteractionsTable
      data={data}
      countText={countText}
      pageSize={pageSize}
      isLoading={isLoading}
      viewAll={viewAll} />
  );
}

function getCountLabel(pageItems: number, total: number, label: string) {
  return total > pageItems
    ? `+${pageSize} ${formatPlurality(label, pageSize)}`
    : `${pageItems} ${formatPlurality(label, pageItems)}`;
}

export { SearchTabAll };

type ProjectsProps = {
  result: WorkspaceGlobalSearchFullProjectResults;
  isLoading: boolean;
}

type MembersProps = {
  result: WorkspaceGlobalSearchFullMemberResults;
  isLoading: boolean;
}

type FilesProps = {
  result: WorkspaceGlobalSearchFullFileResults;
  isLoading: boolean;
}

type FoldersProps = {
  result: WorkspaceGlobalSearchFullFolderResults;
  isLoading: boolean;
}

type InteractionsProps = {
  result: WorkspaceGlobalSearchFullInteractionResults;
  isLoading: boolean;
}
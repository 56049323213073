import { useSelector } from 'react-redux';

const selectMessagingEnabled = (state: Store.State) => !state.group.features.disableMessaging;

export const useMessagingEnabled = () => {
  const messagingEnabled = useSelector(selectMessagingEnabled);

  return messagingEnabled;
};

export default useMessagingEnabled;
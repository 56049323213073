import { useCallback } from 'react';
import { useTable, useFlexLayout, usePagination, useSortBy } from 'react-table';
import { GroupTable } from '@presentation/GroupTable';
import { ReactTablePagination } from '@presentation/TablePagination';
import { ProjectsTableItem } from 'components/GroupProject';
import { useProjectsTableColumns } from 'components/GroupProject/hooks/useProjectsTableColumns';
import { RowsPerPage } from 'components/TablePagination';
import styles from './style/ProjectsTable.css';

type Props = {
  data: ProjectsTableItem[];
};

const ProjectsTable = ({ data }: Props) => {
  const columns = useProjectsTableColumns();

  const tableInstance = useTable<ProjectsTableItem>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
        sortBy: [{
          id: 'createdOn',
          desc: true,
        }],
      },
    },
    useFlexLayout,
    useSortBy,
    usePagination,
  );

  const updatePageSize = useCallback((pageSize: number) => {
    tableInstance.gotoPage(0);
    tableInstance.setPageSize(pageSize);
  }, [
    tableInstance.gotoPage,
    tableInstance.setPageSize,
  ]);

  const tableClasses = {
    body: styles.body,
    td: styles.td,
  };

  return (
    <div>
      <GroupTable
        classes={tableClasses}
        instance={tableInstance} />
      <div className={styles.footer}>
        <RowsPerPage
          className={styles.rows}
          value={tableInstance.state.pageSize}
          onSelect={updatePageSize}
          options={[25, 50, 100]} />
        <ReactTablePagination
          className={styles.pagination}
          tableInstance={tableInstance} />
      </div>
    </div>
  );
};

export default ProjectsTable;
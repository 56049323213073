import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHasClientRole } from '@containers/User';
import { PipelineSocketEvent } from '@enums';
import * as actions from '@store/actions';
import { useHydrateEffect, useSignOutEffect } from '@containers/AppReadyState';
import * as ws from '@services/websocket';
import { WS } from '@/types';

type Props =
  ChildrenProps;

export const PipelineSocketContainer = (props: Props) => {
  const dispatch = useDispatch();
  const hasClientRole = useHasClientRole();

  const handleRecordUpdated = useCallback((record: WS.Pipeline.RecordUpdated.Payload) => {
    dispatch(actions.projectPipelineItemChanged({
      record,
    }));
  }, [
    dispatch,
  ]);

  const register = useCallback(() => {
    if (!hasClientRole) {
      ws.pipeline.on(PipelineSocketEvent.RecordUpdated, handleRecordUpdated);

      ws.pipeline.open();
    }
  }, [
    handleRecordUpdated,
    hasClientRole,
  ]);

  const unregister = useCallback(() => {
    if (!hasClientRole) {
      ws.pipeline.close();

      ws.pipeline.off(PipelineSocketEvent.RecordUpdated, handleRecordUpdated);
    }
  }, [
    handleRecordUpdated,
    hasClientRole,
  ]);

  useHydrateEffect(register);
  useSignOutEffect(unregister);

  useEffect(() => {
    return () => {
      unregister();
    };
  }, [unregister]);

  return (
    <>
      {props.children}
    </>
  );
};

import { useSelector } from 'react-redux';
import { useCompatSearchQuery, useSearchParams } from '@containers';
import * as enums from '@enums';
import * as mixpanel from '@services/mixpanel';
import { Contact, Project, JobFunction, UserEmploymentsItemProps } from '@/types';
import { ExpertRate } from 'components/ExpertRate';
import { getSearchQueryParamsDiff } from '../utils';
import ItemEmployments from './ItemEmployments';
import ItemMatchTags from './ItemMatchTags';
import ItemTopicTags from './ItemTopicTags';
import ItemUserPicture from './ItemUserPicture';
import ItemUserProfileLink from './ItemUserProfileLink';
import styles from './style.css';

const mapState = (state: Store.State) => ({
  group: state.group,
  me: state.user,
});

type ResultData = {
  current?: UserEmploymentsItemProps | null;
  former?: UserEmploymentsItemProps | null;
  functions: JobFunction[];
  matchedQueries: enums.AdvancedSearchQuery[];
};

type Item =
    Pick<Contact, 'bio' | 'id' | 'profile'>
  & ResultData;

type Props = {
  children:   React.ReactNode;
  item:       Item;
  position:   number;
  project:    Project;
  onClickTag: () => void;
};

const ProjectSearchResultItem = (props: Props) => {
  const [params] = useSearchParams();
  const [query] = useCompatSearchQuery();
  const { group, me } = useSelector(mapState);

  const handleProfileClick = () => {
    mixpanel.event.search.project.profileClick({
      params: getSearchQueryParamsDiff(params),
      profile: {
        name: props.item.profile.fullname,
        userId: props.item.id,
      },
      project: {
        id: props.project.id,
        name: props.project.name,
      },
      results: {
        position: props.position,
        total: query.value?.total,
      },
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.content}>
          <div className={styles.main}>

            <div className={styles.pic}>
              <ItemUserPicture
                item={props.item}
                onClick={handleProfileClick} />
            </div>
            <div className={styles.info}>
              <ItemUserProfileLink
                item={props.item}
                onClick={handleProfileClick} />

              <div className={styles.credit}>
                <ExpertRate
                  user={props.item}
                  observer={me}
                  visible={group.features?.showConsultantRates} />
              </div>

              <ItemEmployments
                className={styles.career}
                current={props.item.current}
                former={props.item.former} />

              <div className={styles.tags}>
                <ItemMatchTags
                  className={styles.matches}
                  items={props.item.matchedQueries} />
                <ItemTopicTags
                  items={props.item.functions}
                  onClick={props.onClickTag} />
              </div>
            </div>
          </div>

          <div className={styles.btns}>
            {props.children}
          </div>
        </div>

      </div>
    </div>
  );
};

export { ProjectSearchResultItem };
export default ProjectSearchResultItem;
import { SurveyQuestionType } from '@enums';
import { RankingQuestion } from '@/types';
import { Validation } from '../interfaces';
import { assertValidOptions } from './validation.options';
import { assertHasValue, returnAssertFailure } from './validation.base';

export const DefaultOptions = 4;
export const MinOptions = 2;
export const MaxOptions = 20;

export const DefaultSettings: RankingQuestion.Settings = {
  randomization: false,
  mustRank: null,
};

export function validateQuestion(question: RankingQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.Ranking> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinOptions, MaxOptions);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}
import { useCallback } from 'react';
import { maxLengths } from '@consts';
import { SearchType } from '@enums';
import { toTitleCase } from '@utils';
import { EmploymentForm, JobFunction } from '@/types';
import { FormButtons } from '@presentation/FormButtons';
import { TextareaCharacterLimit } from 'components/Textarea';
import { useProfileEmploymentForm } from './Context';
import { DateRange as DR, ProfileAutocomplete as PA } from './interfaces';
import Field from './Profile.Form.Field';
import DateRange from './Profile.DateRange.Form';
import Validation from './Profile.Form.Validation';
import { ProfileFormAutocomplete as Autocomplete } from './Profile.Form.Autocomplete';
import { ProfileFormAutocompleteMultiple as AutocompleteMulti } from './Profile.Form.Autocomplete.Multi';
import Tags from './Profile.Tags.Form';
import styles from './style/Profile.Section.Multiple.css';

type Props =
  unknown;

export const ProfileEmploymentForm = (props: Props) => {

  const {
    canSave,
    item,
    onCancel,
    onDelete,
    onSubmit,
    setItem,
    validations,
  } = useProfileEmploymentForm();

  const handleAutocompleteSelect = useCallback((field: keyof EmploymentForm) => (data: PA.AutocompleteItem) => {
    setItem(s => ({
      ...s,
      [field]: data,
    }));
  }, [setItem]);

  const handleJobFunctionSelect = useCallback((value: PA.AutocompleteMultiItem) => {
    setItem(s => ({
      ...s,
      functions: [
        ...s.functions,
        {
          id: !value.isCustom ? value.id as number : null,
          name: toTitleCase(value.name),
        },
      ],
    }));
  }, [setItem]);

  const handleRemoveJobFunction = useCallback((value: JobFunction) => () => {
    setItem(s => ({
      ...s,
      functions: s.functions.filter(f => f.name !== value.name),
    }));
  }, [setItem]);

  const handleInputChange = useCallback((field: keyof EmploymentForm) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setItem(s => ({
      ...s,
      [field]: value,
    }));
  }, [setItem]);

  const handleDateChange: DR.OnChange = useCallback(value => {
    setItem(s => ({
      ...s,
      ...value,
    }));
  }, [setItem]);

  const includeCustomJobFunction = useCallback((value: string) => {
    return value.length > 1 &&
      !item.functions.some(s => s.name.toLowerCase() === value.toLowerCase());
  }, [
    item.functions,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.columns}>
        <div className={styles.topItem}>
          <div className={styles.half}>
            <Field
              required
              className={styles.cell}
              title="Company">
              <Autocomplete
                autoFocus
                name="company"
                onSelect={handleAutocompleteSelect('company')}
                placeholder="Ex: Bloomberg"
                type={SearchType.Company}
                value={item.company} />
            </Field>
          </div>
          <div>
            <DateRange
              startMonth={item.startMonth}
              startYear={item.startYear}
              endMonth={item.endMonth}
              endYear={item.endYear}
              onChange={handleDateChange}
              currentText="This is my current position" />
            {validations['during'] &&
              <Validation message={validations['during']} />}
          </div>
        </div>
      </div>

      <div className={styles.half}>
        <Field
          required
          className={styles.cell}
          title="Title">
          <Autocomplete
            name="title"
            onSelect={handleAutocompleteSelect('title')}
            placeholder="Ex: Consultant"
            type={SearchType.Title}
            value={item.title} />
        </Field>
        <Field
          className={styles.cell}
          title="Location">
          <Autocomplete
            name="location"
            onSelect={handleAutocompleteSelect('location')}
            placeholder="Ex: Greater New York Area"
            type={SearchType.Location}
            value={item.location} />
        </Field>
      </div>

      <div className={styles.field}>
        <Field title="Description">
          <TextareaCharacterLimit
            className={styles.textarea}
            maxLength={maxLengths.EMPLOYMENT_DESCRIPTION}
            name="description"
            onChange={handleInputChange('description')}
            placeholder="Provide a job description"
            value={item.description} />
        </Field>
      </div>

      <div className={styles.half}>
        <Field
          title="Job Functions">
          <AutocompleteMulti
            name="functions"
            includeCustomItem={includeCustomJobFunction}
            onSelect={handleJobFunctionSelect}
            placeholder="Search"
            type={SearchType.JobFunction}
            ignoreList={item.functions.map(m => `${m.id}_${SearchType.JobFunction}`)} />
        </Field>

        <div className={styles.tags}>
          <Tags
            items={item.functions}
            onClick={handleRemoveJobFunction} />
        </div>

      </div>

      <FormButtons
        deletable={!!item.id}
        disabled={!canSave}
        onCancel={onCancel}
        onDelete={onDelete}
        onSubmit={onSubmit} />

    </div>
  );
};

export default ProfileEmploymentForm;
import { useCallback, useMemo } from 'react';
import { SurveyQuestion } from '@/types';
import DropDown from 'components/DropDown';

type DropdownItem = {
  id: string;
  value: string;
};

export type QuestionDropdownItem = {
  value: string;
} & Pick<SurveyQuestion, 'base' | 'ordinal'>;

type Props = {
  disabled?: boolean;
  items: QuestionDropdownItem[];
  onSelect: (identifier: string) => void;
  value: QuestionDropdownItem;
};

function formatDisplay(q: QuestionDropdownItem) {
  return q
    ? `Q${q.ordinal}: ${q.value}`
    : '';
}

export const QuestionDropdown = ({
  disabled = false,
  items,
  onSelect,
  value,
}: Props) => {

  const getValueFromItem = useCallback((item: DropdownItem) => {
    const q = items.find(f => f.base.identifier === item.id);
    return formatDisplay(q);
  }, [items]);

  const handleSelect = useCallback((item: DropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const mappedItems = useMemo(() => {
    return items
      .map(m => ({
        id: m.base.identifier,
        value: m.value,
      }));
  }, [items]);

  const text = useMemo(() => {
    return formatDisplay(value) || 'Select Question';
  }, [value]);

  return (
    <DropDown<DropdownItem>
      disabled={disabled}
      items={mappedItems}
      getItemValue={getValueFromItem}
      onSelect={handleSelect}
      text={text} />
  );
};

export default QuestionDropdown;
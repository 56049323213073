import { forwardRef } from 'react';
import { LocationDescriptor } from 'history';
import { Link } from 'react-router-dom';
import { cx } from '@utils';
import styles from './style/Layout.css';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const Card = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const classes = cx(styles.card, props.className);

  return (
    <div
      className={classes}
      ref={ref}>
      {props.children}
    </div>
  );
});

type CardContentProps = {
  fluid?: boolean;
} & Props;

export const CardContent = (props: CardContentProps) => {
  const classes = cx(
    styles.content,
    { [styles.padded]: !props.fluid },
    props.className,
  );

  return (
    <div className={classes}>
      {props.children}
    </div>
  );
};

export const CardHeader = (props: CardContentProps) => {
  const classes = cx(
    styles.header,
    { [styles.padded]: !props.fluid },
    props.className,
  );

  return (
    <div className={classes}>
      {props.children}
    </div>
  );
};

export const GroupCardHeader = (props: CardContentProps) => {
  return (
    <CardHeader
      {...props}
      className={cx(styles.groupHeader, props.className)} />
  );
};

type ViewMoreProps = {
  to: LocationDescriptor;
};

export const ViewMoreFooter = ({ to }: ViewMoreProps) => {
  return (
    <div className={styles.more}>
      <Link to={to}>View More...</Link>
    </div>
  );
};

type DividerProps = {
  className?: string;
  middle?:    boolean;
};

export const Divider = (props: DividerProps) => {
  const classes = cx(
    styles.divider,
    { [styles.middle]: props.middle },
    props.className,
  );

  return <div className={classes} />;
};

const defaultCardContentProps = {
  fluid: false,
};

CardContent.defaultProps = defaultCardContentProps;
CardHeader.defaultProps = defaultCardContentProps;
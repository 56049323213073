import { SurveyOptionType, SurveyQuestionType } from '@enums';
import {
  MatrixGridQuestion,
  MatrixSliderQuestion,
  RespondentAnswer,
  SurveyFormQuestion,
  MatrixMultiselectQuestion,
} from '@/types';
import * as matrixSlider from './matrix-slider';

export const isAnswerComplete = (data: RespondentAnswer, item: SurveyFormQuestion) => {
  if (!data) return false;

  switch (item.typeId) {
    case SurveyQuestionType.LongTextResponse: {
      const answer = data as RespondentAnswer<SurveyQuestionType.LongTextResponse>;
      return !!answer?.value;
    }

    case SurveyQuestionType.ShortTextResponse: {
      const answer = data as RespondentAnswer<SurveyQuestionType.ShortTextResponse>;
      return !!answer?.value;
    }

    case SurveyQuestionType.MultipleChoice: {
      const answer = data as RespondentAnswer<SurveyQuestionType.MultipleChoice>;
      if (!answer?.optionId) return false;

      const option = item.options.find(o => o.id === answer?.optionId);
      return option.type === SurveyOptionType.Other
        ? !!answer.text?.length
        : true;
    }

    case SurveyQuestionType.Multiselect: {
      const answer = data as RespondentAnswer<SurveyQuestionType.Multiselect>;

      if (!answer?.items?.length) return false;

      const otherOptions = item.options
        .filter(o => o.type === SurveyOptionType.Other)
        .map(o => o.id);
      const emptyTextAnswers
        = answer.items
          .filter(i => otherOptions.includes(i.optionId))
          .filter(i => !i.text?.length);
      return emptyTextAnswers.length === 0;
    }

    case SurveyQuestionType.Ranking: {
      const answer = data as RespondentAnswer<SurveyQuestionType.Ranking>;
      return answer?.options?.length === item.options.length;
    }

    case SurveyQuestionType.Sliders: {
      return matrixSlider.isAnswerValid(data as MatrixSliderQuestion.RespondentAnswer.Value, item);
    }

    case SurveyQuestionType.MatrixGrid: {
      const answer = data as MatrixGridQuestion.RespondentAnswer.Value;
      return answer?.items.length === item.matrixRows.length;
    }

    case SurveyQuestionType.MatrixMultiselect: {
      const answer = data as MatrixMultiselectQuestion.RespondentAnswer.Value;
      return !item.matrixRows.some(s => {
        const rowItems = answer.items.find(f => f.matrixRowId === s.id);
        return !rowItems?.optionIds?.length;
      });
    }

    default: return false;
  }

};
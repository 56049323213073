import { Fragment, useCallback, memo } from 'react';
import { LocalAudioTrack , RemoteAudioTrack, RemoteParticipant, LocalParticipant } from 'twilio-video';
import PhoneIcon from '@mui/icons-material/Phone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MicOnIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { useCoordinator, useTwilioVideo, useConferenceInstance, IConference } from '@containers/Conference';
import { MoreHorizontalAnchor } from '@presentation/Anchor';
import { Tooltip } from '@presentation/Tooltip';
import { Participant as LiveParticipant } from '@/types/conferences.live';
import { PopperMenu, PopperMenuItem } from 'components/Popper';
import { usePublications, useParticipants, useTrack, useIsTrackEnabled } from 'components/Conference.Video/hooks';
import { UserAvatar } from 'components/UserAvatar';
import Toast from 'components/Toast';
import { useYourParticipant, useHostCount } from './hooks';
import styles from './style/Bar.Right.Participants.css';

export function BarRightParticipantsInConference() {
  const { muteParticipant } = useCoordinator();
  const twilio = useTwilioVideo();
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom>();
  const you = useYourParticipant();
  const hostCount = useHostCount();

  const twilioParticipants = useParticipants(twilio.room);

  const participants = instance.participants.filter(p => p.status === 'meeting-room').sort((a, b) => a.id === you?.id ? -1 : a.name.localeCompare(b.name));

  const handleMute = useCallback((sid: string, pid: string) => () => {
    muteParticipant({
      conferenceIdentifier: instance.conferenceIdentifier,
      sid,
      pid,
    });

    const participant = participants.find(p => p.id === pid);
    if (participant) {
      Toast.success({
        title: `You've muted ${participant.name}`,
      });
    }
  }, [participants, instance.conferenceIdentifier, muteParticipant]);

  if (!you.isHost) {
    return (
      <Fragment>
        {participants.filter(p => p.id === you.id || p.isVisible).map(liveParticipant => {
          return (
            <InConferenceParticipant
              key={liveParticipant.id}
              isYou={you.id === liveParticipant.id}
              participant={liveParticipant}
              onMute={handleMute}
              twilioParticipant={null}
              canChangeVisibility={instance.features.visibilityToggle} />
          );
        })}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={styles.subHeader}>
        In Conference
      </div>
      {participants.map(liveParticipant => {
        const twilioParticipant =
          liveParticipant.twilioIdentity === twilio.room.localParticipant.identity
            ? twilio.room.localParticipant
            : twilioParticipants.find(p => p.identity === liveParticipant.twilioIdentity);

        return (
          <InConferenceParticipant
            key={liveParticipant.id}
            isYou={you.id === liveParticipant.id}
            participant={liveParticipant}
            twilioParticipant={twilioParticipant}
            onMute={handleMute}
            hostCount={hostCount}
            hostAbilities
            canChangeVisibility />
        );
      })}
    </Fragment>
  );
}

type InConferenceParticipantProps = {
  isYou: boolean;
  participant: LiveParticipant;
  twilioParticipant: RemoteParticipant | LocalParticipant;
  onMute: (sid: string, pid: string) => () => void;
  hostAbilities?: boolean;
  hostCount?: number;
  canChangeVisibility?: boolean;
};

const InConferenceParticipant = memo(({ isYou, participant, twilioParticipant, onMute, hostCount = 1, hostAbilities = false, canChangeVisibility = false }: InConferenceParticipantProps) => {
  const type = participant.status === 'waiting-room'
    ? ''
    : participant.isHost
      ? ` (${hostCount > 1 ? 'Co-Host' : 'Host'})`
      : ' (Attendee)';

  const name = `${participant.name}${type}`;

  if (!hostAbilities) {
    return (
      <div className={styles.row}>
        <UserAvatar
          className={styles.avatar}
          pictureUrl={participant.auth !== 'guest' ? participant.pictureUrl : null}
          size={30} />
        <div className={styles.details}>
          <div className={styles.name}>
            {name}
          </div>
        </div>
        <div className={styles.actions}>
          {canChangeVisibility && isYou && (
            <Fragment>
              <ParticipantVisibility
                participant={participant}
                isYou={isYou} />
              <InConferenceParticipantPopper
                participant={participant}
                isYou={isYou}
                canMakeHost={false}
                canRemove={false}
                canChangeVisibility={true} />
            </Fragment>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.row}>
      <UserAvatar
        className={styles.avatar}
        pictureUrl={participant.auth !== 'guest' ? participant.pictureUrl : null}
        size={30} />
      <div className={styles.details}>
        <div className={styles.name}>
          {name}
        </div>
        <div className={styles.actions}>
          <ParticipantVisibility
            participant={participant}
            isYou={isYou} />
          <ParticipantTwilioActions
            participant={participant}
            twilioParticipant={twilioParticipant}
            onMute={onMute} />
          <InConferenceParticipantPopper
            participant={participant}
            isYou={isYou}
            canMakeHost={!participant.isHost}
            canRemove={!isYou}
            canChangeVisibility={canChangeVisibility} />
        </div>
      </div>
    </div>
  );
});

type ParticipantVisibilityProps = {
  participant: LiveParticipant;
  isYou: boolean;
};

function ParticipantVisibility({ participant, isYou }: ParticipantVisibilityProps) {
  return (
    participant.isVisible
      ? (
        <Tooltip
          title={isYou ? `You're visible to attendees` : 'Is visible to attendees'}
          placement='top'
          tooltipstyles={{ top: 8, padding: '5px', textAlign: 'center' }}>
          <div className={styles.visibilityOn}>
            <VisibilityIcon />
          </div>
        </Tooltip>
      )
      : (
        <Tooltip
          title={isYou ? `You're not visible to attendees unless your camera or microphone is active` : 'Is not visible to attendees'}
          placement='top'
          tooltipstyles={{ top: 8, padding: '5px', textAlign: 'center' }}>
          <div className={styles.visibilityOff}>
            <VisibilityOffIcon />
          </div>
        </Tooltip>
      )
  );
}

type ParticipantTwilioActionsProps = {
  participant: LiveParticipant;
  twilioParticipant: RemoteParticipant | LocalParticipant;
  onMute: (sid: string, pid: string) => () => void;
};

function ParticipantTwilioActions({ participant, twilioParticipant, onMute }: ParticipantTwilioActionsProps) {
  const publications = usePublications(twilioParticipant);
  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack;
  const audioEnabled = useIsTrackEnabled(audioTrack);

  if (!twilioParticipant) return <div className={styles.micPlaceholder} />;
  if (participant.type === 'phone') {
    return (
      <div className={styles.phoneOn}>
        <PhoneIcon />
      </div>
    );
  }

  return (
    audioEnabled
      ? (
        <div
          className={styles.micOn}
          onClick={onMute(twilioParticipant?.sid, participant.id)}>
          <MicOnIcon />
        </div>
      )
      : (
        <div className={styles.micOff}>
          <MicOffIcon />
        </div>
      )
  );
}

type InConferenceParticipantPopperProps = {
  participant: LiveParticipant;
  isYou: boolean;
  canMakeHost: boolean;
  canChangeVisibility: boolean;
  canRemove: boolean;
};

function InConferenceParticipantPopper({ participant, isYou, canMakeHost, canChangeVisibility, canRemove }: InConferenceParticipantPopperProps) {
  const { giveHost, removeParticipant, changeParticipantVisibility } = useCoordinator();

  const handleGiveHost = useCallback((close: () => void) => () => {
    giveHost({
      conferenceIdentifier: participant.conferenceIdentifier,
      pid: participant.id,
      selfKeepHost: true,
    });
    Toast.success({
      title: `You've given host to ${participant.name}`,
    });
    close();
  }, [giveHost, participant]);

  const handleVisibility = useCallback((close: () => void) => () => {
    changeParticipantVisibility({
      conferenceIdentifier: participant.conferenceIdentifier,
      pid: participant.id,
      visibility: !participant.isVisible,
    });
    Toast.success({
      title: isYou
        ? `You've made yourself ${participant.isVisible ? 'hidden' : 'visible'}`
        : `You've changed ${participant.name} to ${participant.isVisible ? 'hidden' : 'visible'}`,
    });
    close();
  }, [changeParticipantVisibility, isYou, participant]);

  const handleRemove = useCallback((close: () => void) => () => {
    removeParticipant({
      conferenceIdentifier: participant.conferenceIdentifier,
      pid: participant.id,
    });
    Toast.success({
      title: `You've removed ${participant.name} from the conference`,
    });
    close();
  }, [removeParticipant, participant]);

  if (!canMakeHost && !canChangeVisibility && !canRemove) return <div className={styles.popperPlaceholder} />;

  return (
    <PopupState
      variant="popper"
      popupId="participant-actions-popper">
      {popupState => (
        <div>
          <div {...bindToggle(popupState)}>
            <MoreHorizontalAnchor open={popupState.isOpen} />
          </div>
          <Popper
            {...bindPopper(popupState)}
            placement="bottom-end"
            className={styles.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <PopperMenu>
                {canMakeHost && (
                  <PopperMenuItem onClick={handleGiveHost(popupState.close)}>
                    Make Host
                  </PopperMenuItem>
                )}
                {canChangeVisibility && (
                  <PopperMenuItem onClick={handleVisibility(popupState.close)}>
                    {participant.isVisible ? 'Make Hidden' : 'Make Visible'}
                  </PopperMenuItem>
                )}
                {canRemove && (
                  <PopperMenuItem onClick={handleRemove(popupState.close)}>
                    Remove
                  </PopperMenuItem>
                )}
              </PopperMenu>
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
}
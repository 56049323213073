import { useContext, Dispatch } from 'react';
import { SurveyActionType } from '@enums';
import { createNamedContext } from '@utils';
import { RespondentAnswer, SurveyFormQuestion, SurveyFormVersion } from '@/types';
import { NumberTableWarningsMap, SubmitSurveyParams } from './interfaces';

export type ConjointAnalysisFormContextValue = {
  currentSet: number;
  setSetAnswer: (setNo: number) => (conceptNo: number | null) => unknown;
};

export type ConjointAnalysisNavigationContextValue = {
  back: () => void;
  next: () => void;
  nextDisabled: boolean;
};

export type MaxDiffFormContextValue = {
  currentSet: number;
  setSetAnswer: (setNumber: number) => (choice: 'left' | 'right', value: number) => unknown;
};

export type MaxDiffNavigationContextValue = {
  back: () => void;
  next: () => void;
  nextDisabled: boolean;
};

export type SurveyFormNavigationContextValue = {
  back: {
    hidden: boolean;
    isError: boolean;
    isLoading: boolean;
    submit: () => Promise<unknown>;
  };
  next: {
    disabled: boolean;
    isError: boolean;
    isLoading: boolean;
    submit: () => Promise<unknown>;
  };
  showComplete: boolean;
};

export type SubmitSurvey = (data: SubmitSurveyParams) => Promise<void>;

export type SurveyFormQuestionState = {
  answer: RespondentAnswer;
  ordinal: number;
  question: SurveyFormQuestion;
};

export type SurveyFormQuestionContextValue =
  [SurveyFormQuestionState, Dispatch<SurveyFormQuestionState>]
;

export type NumberTableWarningContextValue =
  [NumberTableWarningsMap];

export const SurveyResponseIdentifierContext = createNamedContext<string>(undefined, 'SurveyResponseIdentifierContext');
export const SubmitSurveyResponseContext = createNamedContext<SubmitSurvey>(undefined, 'SubmitSurveyResponseContext');
export const SurveyFormContext = createNamedContext<SurveyFormVersion>(undefined, 'SurveyFormContext');
export const SurveyFormQuestionContext = createNamedContext<SurveyFormQuestionContextValue>(undefined, 'SurveyFormQuestionContext');

export const ConjointAnalysisFormContext = createNamedContext<ConjointAnalysisFormContextValue>(undefined, 'ConjointAnalysisFormContext');
export const ConjointAnalysisNavigationContext = createNamedContext<ConjointAnalysisNavigationContextValue>(undefined, 'ConjointAnalysisNavigationContext');

export const MaxDiffFormContext = createNamedContext<MaxDiffFormContextValue>(undefined, 'MaxDiffFormContext');
export const MaxDiffNavigationContext = createNamedContext<MaxDiffNavigationContextValue>(undefined, 'MaxDiffNavigationContext');

export const SurveyFormNavigationContext = createNamedContext<SurveyFormNavigationContextValue>(undefined, 'SurveyFormNavigationContext');

export type SaveCompletedSurveyResponse = () => Promise<unknown>;
export type SaveDisqualifiedSurveyResponse = (actionType: SurveyActionType) => Promise<unknown>;

export const SaveCompletedSurveyResponseContext = createNamedContext<SaveCompletedSurveyResponse>(undefined, 'SaveCompletedSurveyResponseContext');
export const SaveDisqualifiedSurveyResponseContext = createNamedContext<SaveDisqualifiedSurveyResponse>(undefined, 'SaveDisqualifiedSurveyResponseContext');

export const NumberTableWarningsContext = createNamedContext<NumberTableWarningContextValue>(undefined, 'NumberTableWarningsContext');

export const useSaveCompletedSurveyResponse = () => useContext(SaveCompletedSurveyResponseContext);
export const useSaveDisqualifiedSurveyResponse = () => useContext(SaveDisqualifiedSurveyResponseContext);

export const useSurveyFormContext = () => useContext(SurveyFormContext);

export const useConjointAnalysisFormContext = () => useContext(ConjointAnalysisFormContext);
export const useConjointAnalysisNavigationContext = () => useContext(ConjointAnalysisNavigationContext);

export const useMaxDiffFormContext = () => useContext(MaxDiffFormContext);
export const useMaxDiffNavigationContext = () => useContext(MaxDiffNavigationContext);

export const useSurveyFormNavigationContext = () => useContext(SurveyFormNavigationContext);
export const useSubmitSurveyResponse = () => useContext(SubmitSurveyResponseContext);

export const useSurveyResponseIdentifier = () => useContext(SurveyResponseIdentifierContext);

export const useNumberTableWarnings = () => useContext(NumberTableWarningsContext);
import { UseMutationOptions, useMutation } from 'react-query';
import * as api from '@api';
import * as QK from '@consts/querykey';
import { ImageObject } from '@containers/Post.Article.Form/interfaces';

type MutationParams = {
  identifier: string;
  images:     ImageObject[];
};

type Options =
  UseMutationOptions<
    ImageObject[],
    unknown,
    MutationParams>;

export const useUploadImageObjectsMutation = (options?: Options) => {
  const mutation = useMutation([QK.Posts.Articles.Attachments.Post], (data: MutationParams) => {
    return Promise.all(data.images.map(image => uploadImage({
      identifier: data.identifier,
      image,
    })));
  }, options);

  return mutation;
};

namespace UploadImage {
  export type Params = {
    identifier: string;
    image: ImageObject;
  };
}

const uploadImage = async (data: UploadImage.Params) => {
  const blob = await fetch(data.image.src)
    .then(res => res.blob());

  return api.posts.articles.uploadImageAttachment({
    identifier: data.identifier,
    blob,
  })
  .then(res => {
    return {
      cid: data.image.cid,
      src: res.attachment.url,
    };
  });
};
import styles from './style.css';

type Props = {
  className?: string;
  color?:     string;
  size?:      number;
};

const AnimatedLoader = (props: Props) => {
  function getStyleProps(className: string) {
    return {
      className,
      style: {
        backgroundColor: props.color,
        height: props.size,
        width: props.size,
      },
    };
  }

  return (
    <div className={props.className}>
      <span {...getStyleProps(styles.first)} />
      <span {...getStyleProps(styles.second)} />
      <span {...getStyleProps(styles.third)} />
    </div>
  );
};

AnimatedLoader.defaultProps = {
  className: '',
  color: 'var(--gray)',
  size: 15,
};

export type AnimatedLoaderProps = Props;

export { AnimatedLoader };
export default AnimatedLoader;
import { useMemo } from 'react';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useSectionQuestions = (identifier: string) => {
  const [state] = useSurveyBuilderState();

  const questions = useMemo(() => {
    return state.survey.questions
      .filter(q => q.section.identifier === identifier);
  }, [identifier, state.survey.questions]);

  return questions;
};

export default useSectionQuestions;
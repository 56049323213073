import { Link } from 'react-router-dom';
import { ArrowRight } from 'react-feather';
import * as consts from '@consts';
import {
  Strategy,
  useAssertStrategy,
} from '$website/containers';
import styles from './style/SegmentationPanel.css';

type Props = unknown;

const SegmentationPanel = (props: Props) => {
  const assert = useAssertStrategy();

  if (!assert(Strategy.Enterprise)) return null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>Which of the below best describes you?</h2>
        <div className={styles.main}>
          <div className={styles.item}>
            <Link to={consts.path.Website.Investment}>
              <div className={styles.link}>
                <div className={styles.segment}>Investment Management</div>
                <div className={styles.arrow}><ArrowRight /></div>
              </div>
            </Link>
          </div>
          <div className={styles.item}>
            <Link to={consts.path.Website.Consulting}>
              <div className={styles.link}>
                <div className={styles.segment}>Consulting Firm</div>
                <div className={styles.arrow}><ArrowRight /></div>
              </div>
            </Link>
          </div>
          <div className={styles.item}>
            <Link to={consts.path.Website.Corporate}>
              <div className={styles.link}>
                <div className={styles.segment}>Corporate Strategy</div>
                <div className={styles.arrow}><ArrowRight /></div>
              </div>
            </Link>
          </div>
          <div className={styles.item}>
            <Link to={consts.path.Website.Corporate}>
              <div className={styles.link}>
                <div className={styles.segment}>Product, or other Professional</div>
                <div className={styles.arrow}><ArrowRight /></div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SegmentationPanel };
export default SegmentationPanel;
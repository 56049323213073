import { useContext, useMemo } from 'react';
import { TopicNewsContext } from '@containers/Topic/Context';
import { useInfiniteWindowScroll } from '@utils';
import ActivityIndicator from 'components/ActivityIndicator';
import * as Panel from './Panel';
import styles from './style/Related.css';

export const News = (props: unknown) => {
  const { query } = useContext(TopicNewsContext);

  useInfiniteWindowScroll({
    canFetch: query.hasNextPage && !query.isFetchingNextPage,
    fetchNext: query.fetchNextPage,
    bufferSize: 0.3,
  });

  const ready = useMemo(() => {
    return !query.isLoading
        && query.isSuccess;
  }, [
    query.isLoading,
    query.isSuccess,
  ]);

  const items = useMemo(() => {
    return query.data?.pages.flatMap(p => p.items);
  }, [query.data?.pages]);

  if (!ready) return <ActivityIndicator show />;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Panel.NewsFeed items={items} />
      </div>
    </div>
  );
};

News.displayName = 'Tab.News';
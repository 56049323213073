import { memo } from 'react';
import { Calendar } from 'react-feather';
import { Link } from 'react-router-dom';
import { useSelectUser } from '@containers';
import { cx, getLocationFor } from '@utils';
import { AdHocCall } from '@/types';
import { Button } from 'components/Button';
import styles from './style/Invitations.Item.css';

type Props = {
  className?: string;
  item: AdHocCall;
};

export const AdHocCallTimeProposal = memo(({ className, item }: Props) => {

  const me = useSelectUser();

  const to = getLocationFor.scheduling.adHoc.selecting({
    callId: item.id,
    scheduleeId: me.id,
    schedulerId: item.lastModifiedBy,
  });

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <Calendar className={styles.icon} />
          <div className={styles.details}>
            <Link to={to} className={styles.title}>
              {item.request.subject}
            </Link>
            <div className={styles.description}>
              Review client availability and book a time to speak.
            </div>
          </div>
        </div>
        <Button.Secondary
          className={styles.btn}
          variant="brick"
          to={to}>
          Review
        </Button.Secondary>
      </div>
    </div>
  );
});

export default AdHocCallTimeProposal;

import { Component } from 'react';
import { cssTransition, toast, ToastContainer, ToastId } from 'react-toastify';
import { Error } from './Toast.Error';
import { Info } from './Toast.Info';
import { Success } from './Toast.Success';
import { Warn } from './Toast.Warn';
import { ToastComponentProps, ToastComponentType } from './interfaces';
import styles from './style.css';

const Slide = cssTransition({
  enter: styles.enter,
  exit: styles.exit,
  duration: [200, 400],
});

class Toast extends Component {
  private static render = (ToastComponent: ToastComponentType, props: ToastComponentProps) => {
    const run = () => {
      const duration = props.duration || 5000;

      let toastId: ToastId = null;
      const timer = setTimeout(() => toast.dismiss(toastId), duration);

      toastId = toast(<ToastComponent {...props} />, {
        autoClose: duration,
        closeButton: false,
        onClose: () => clearTimeout(timer),
        position: toast.POSITION.BOTTOM_LEFT,
        transition: Slide,
      });

      return toastId;
    };

    return run();
  };

  static alert = (options: ToastComponentProps) => {
    return Toast.success(options);
  };

  static error = (options: ToastComponentProps) => {
    return Toast.render(Error, options);
  };

  static info = (options: ToastComponentProps) => {
    return Toast.render(Info, options);
  };

  static success = (options: ToastComponentProps) => {
    return Toast.render(Success, options);
  };

  static warn = (options: ToastComponentProps) => {
    return Toast.render(Warn, options);
  };

  render() {
    return (
      <div className={styles.root}>
        <ToastContainer />
      </div>
    );
  }
}

export default Toast;
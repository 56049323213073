import { useCallback, useState } from 'react';
import { compareDesc, format } from 'date-fns';
import { useQuery } from 'react-query';
import * as api from '@api';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks';
import { ProjectSummaryContainer } from '@containers/ProjectSummary';
import Button from 'components/Button';
import CallItem from './CallItem';
import Summary from './Summary';
import styles from './style/ProjectSummary.css';

export const ProjectSummary = () => {

  const project = useProjectOrLatestChild();

  const { data: calls, isLoading } = useQuery(['completed-project-calls', project.id], async () => {
    const calls = await api.projects.getProjectCompletedCalls({
      projectId: project.id,
    });

    return calls
      .sort((a, b) => compareDesc(a.timeStart, b.timeStart));
  });

  const [expanded, setExpanded] = useState(0);

  const handleExpand = useCallback(() => setExpanded(s => s + 1), []);

  const renderExpand = useCallback(() => {
    if (!project.callIds.length) return null;

    return (
      <Button
        className={styles.btn}
        color="secondary"
        onClick={handleExpand}
        variant="brick">
        Expand All
      </Button>
    );
  }, [
    handleExpand,
    project.callIds,
  ]);

  const renderCalls = useCallback(() => {

    if (!calls.length) {
      return <div className={styles.nocalls}>No calls completed yet.</div>;
    }

    return calls.map(c =>
      <CallItem
        item={c}
        expanded={expanded}
        key={c.id} />
    );
  }, [calls, expanded]);

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.section}>
          <div className={styles.header}>
            <h2 className={styles.h2}>Completed Calls</h2>
            {renderExpand()}
          </div>
          {renderCalls()}
        </div>

        <div className={styles.section}>
          <ProjectSummaryContainer projectId={project.id}>
            <Summary
              downloadName={`${project.name} Summary ${format(new Date(), 'MM/dd/yyyy')}.docx`}
              summary={project.notesSummary} />
          </ProjectSummaryContainer>
        </div>

      </div>
    </div>
  );
};

export default ProjectSummary;
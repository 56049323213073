import { useCallback, useMemo } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { QuestionRowsBuilderContainer } from '@containers/SurveyBuilder.Question/RowsBuilderContainer';
import { assertValidSettings } from '@containers/SurveyBuilder/utils/question.number-input-table';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import { NumberInputTableQuestion } from '@/types';
import { CheckboxLabel } from 'components/Checkbox';
import { NumberInput } from 'components/Input';
import QuestionColumnsBuilder from './ColumnsBuilder';
import { ResponsePiping } from './ResponsePiping';
import { Warning } from './Warning';
import styles from './style/NumberInputTable.Question.css';

type Props = {
  item: NumberInputTableQuestion.Question;
};

export const NumberInputTableBuilder = ({ item }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const toggleEmptyValues = useCallback((_, checked: boolean) => {
    dispatch({
      type: 'toggle-allow-empty-values',
      questionIdentifier: item.base.identifier,
      value: checked,
    });
  }, [
    dispatch,
    item.base.identifier,
  ]);

  const toggleDisplayTotals = useCallback((_, checked: boolean) => {

    dispatch({
      ordinal: item.ordinal,
      settings: {
        ...item.settings,
        displayTotals: checked,
      },
      type: 'update-question-settings',
    });
  }, [
    dispatch,
    item.ordinal,
    item.settings,
  ]);

  const handleSettingsChange = useCallback((field: 'minValue' | 'maxValue') => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    dispatch({
      ordinal: item.ordinal,
      settings: {
        ...item.settings,
        validations: {
          ...item.settings.validations,
          [field]: value === '' ? null : +value,
        },
      },
      type: 'update-question-settings',
    });
  }, [
    dispatch,
    item.ordinal,
    item.settings,
  ]);

  const settingsValidation = useMemo(() => {
    return assertValidSettings(item);
  }, [item]);

  return (
    <>
      <QuestionSettingTitle title="Rows">
        <ResponsePiping type="rows" />
      </QuestionSettingTitle>
      <QuestionRowsBuilderContainer
        questionIdentifier={item.base.identifier}
        rows={item.matrixRows}
        className={styles.rows}
        maxRows={surveyBuilder.matrixMultiselect.MaxRows}
        minRows={surveyBuilder.matrixMultiselect.MinRows} />

      <QuestionSettingTitle title="Columns">
        <ResponsePiping type="options" />
      </QuestionSettingTitle>
      <QuestionOptionsBuilderContainer
        className={styles.options}
        Component={QuestionColumnsBuilder}
        item={item}
        maxOptions={surveyBuilder.matrixMultiselect.MaxColumns}
        minOptions={surveyBuilder.matrixMultiselect.MinColumns} />

      <div className={styles.checkbox}>
        <CheckboxLabel
          checked={item.settings.allowEmptyValues}
          label="Allow empty values"
          onChange={toggleEmptyValues} />
      </div>
      <CheckboxLabel
        className={styles.checkbox}
        checked={item.settings.displayTotals}
        label="Display column totals"
        onChange={toggleDisplayTotals} />

      <div className={styles.item}>
        <div className={styles.title}>Minimum Value</div>
        <div className={styles.input}>
          <NumberInput
            onChange={handleSettingsChange('minValue')}
            value={item.settings.validations.minValue} />
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.title}>Maximum Value</div>
        <div className={styles.input}>
          <NumberInput
            onChange={handleSettingsChange('maxValue')}
            value={item.settings.validations.maxValue} />
        </div>
      </div>
      <Warning
        className={styles.warning}
        result={settingsValidation} />
    </>
  );
};

export default NumberInputTableBuilder;
import cuid from 'cuid';
import { SurveyOptionType, SurveyQuestionType, SurveyActionType } from '@enums/Survey';
import {
  SurveyQuestionOption,
  SurveyQuestionMatrixRow,
  SurveyQuestion,
  SurveySection,
  ConjointAnalysisQuestion,
  SurveyQuestionOptionMetadata,
  SurveyRichText,
} from '@/types/survey';
import * as surveyBuilder from './question';

type GenerateNewQuestion = {
  ordinal: number;
  section: {
    identifier: string;
  };
};

export const generateNewQuestion = (data: GenerateNewQuestion) => {
  return {
    attributes: [],
    base: {
      id: null,
      identifier: cuid(),
    },
    id: null,
    logic: {
      base: {
        action: {
          type: SurveyActionType.None,
        },
      },
      response: [],
    },
    metadata: {
      canDelete: true,
      canModifyLogic: true,
      canModifyValue: true,
      template: {},
    },
    matrixRows: [],
    options: [],
    ordinal: data.ordinal,
    section: data.section,
    settings: {},
    typeId: null,
    value: {
      type: 'doc',
      content: [{
        content: [],
        type: SurveyRichText.NodeType.Paragraph,
      }],
    },
    piping: {
      entities: [],
    },
    tagging: {
      response: [],
    },
  } as SurveyQuestion;
};

export const generateNewSection = (ordinal: number): SurveySection => {
  return {
    hidden: false,
    id: null,
    identifier: cuid(),
    metadata: {
      canAddQuestion: true,
      canDelete: true,
      canModifyVisibility: true,
      template: {},
    },
    name: `Section ${ordinal}`,
    ordinal,
  };
};

type GenerateNewOption = {
  metadata: SurveyQuestionOptionMetadata;
  ordinal: number;
  type?: SurveyOptionType;
  value?: string;
};

export const generateNewOption = ({
  metadata,
  ordinal,
  type = SurveyOptionType.Default,
  value = '',
}: GenerateNewOption): SurveyQuestionOption => {
  return {
    base: {
      id: null,
      identifier: cuid(),
    },
    conditions: [],
    id: null,
    metadata,
    ordinal,
    type,
    value,
  };
};

export const generateNewMatrixRow = (ordinal: number): SurveyQuestionMatrixRow => {
  return {
    base: {
      id: null,
      identifier: cuid(),
    },
    conditions: [],
    id: null,
    metadata: {
      canModifyValue: true,
      canDelete: true,
      template: {},
    },
    ordinal,
    value: '',
  };
};

export function getNewAttribute(): ConjointAnalysisQuestion.Attribute {
  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    levels: generateLevels(surveyBuilder.conjointAnalysis.DefaultLevels),
    value: '',
  };
}

export function getNewLevel(): ConjointAnalysisQuestion.Level {
  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    value: '',
  };
}

const generateOptions = (count: number) => {
  return Array(count)
    .fill(0)
    .map((_, i) => generateNewOption({
      metadata: {
        canDelete: true,
        canModifyValue: true,
        template: {},
      },
      ordinal: i + 1,
    }));
};

const generateMatrixRows = (count: number) => {
  return Array(count)
    .fill(0)
    .map((_, i) => generateNewMatrixRow(i + 1));
};

const generateAttributes = (count: number) => {
  return Array(count)
    .fill(0)
    .map((_, i) => getNewAttribute());
};

const generateLevels = (count: number) => {
  return Array(count)
    .fill(0)
    .map((_, i) => getNewLevel());
};

export function generateNewOrdinalsRemoval<T extends { ordinal: number; }>(items: T[], ordinal: number) {
  type OrdinalMap = {
    [ordinal: number]: number;
  };

  return items.reduce<OrdinalMap>((ordinals, item) => {
    return {
      ...ordinals,
      [item.ordinal]: item.ordinal < ordinal
        ? item.ordinal
        : item.ordinal > ordinal
          ? item.ordinal - 1
          : null,
    };
  }, {});
}

export const getInitialAttributes = (typeId: SurveyQuestionType): ConjointAnalysisQuestion.Attribute[] => {
  if (typeId !== SurveyQuestionType.ConjointAnalysis) {
    return [];
  }

  return generateAttributes(surveyBuilder.conjointAnalysis.DefaultAttributes);
};

export const getInitialQuestionOptions = (typeId: SurveyQuestionType): SurveyQuestionOption[] => {
  const minOptions = DefaultOptionsMap[typeId] || 0;
  return generateOptions(minOptions);
};

export const refreshOptionsAfterTypeChange = (options: SurveyQuestionOption[], typeId: SurveyQuestionType): SurveyQuestionOption[] => {
  const newOptions = options
    .filter(f => !!f.value);

  const min = DefaultOptionsMap[typeId];
  while (newOptions.length < min) {
    newOptions.push(generateNewOption({
      metadata: {
        canDelete: true,
        canModifyValue: true,
        template: {},
      },
      ordinal: newOptions.length + 1,
    }));
  }

  return newOptions;
};

export const refreshMatrixRowsAfterTypeChange = (rows: SurveyQuestionMatrixRow[]): SurveyQuestionMatrixRow[] => {
  const newRows = rows
    .filter(f => !!f.value);

  while (newRows.length < surveyBuilder.matrixGrid.MinRows) {
    newRows.push(generateNewMatrixRow(newRows.length + 1));
  }

  return newRows;
};

export const getInitialMatrixRows = (typeId: SurveyQuestionType): SurveyQuestionMatrixRow[] => {
  const types = [
    SurveyQuestionType.MatrixGrid,
    SurveyQuestionType.Sliders,
    SurveyQuestionType.MatrixMultiselect,
    SurveyQuestionType.NumberInputTable,
  ];

  if (!types.includes(typeId)) {
    return [];
  }

  return generateMatrixRows(surveyBuilder.matrixGrid.DefaultRows);
};

export const getDefaultSettingsForType = (typeId: SurveyQuestionType): SurveyQuestion['settings'] => {
  return (DefaultSettingsMap[typeId] || {});
};

export const getDefaultQuestionLogic = (item: SurveyQuestion): SurveyQuestion['logic'] => {
  const action = {
    identifier: undefined,
    type: SurveyActionType.None,
  };

  return {
    base: { action },
    response: [],
  };

};

export function buildAllowOtherOption({ options }: Pick<SurveyQuestion, 'logic' | 'options'>): Pick<SurveyQuestion, 'options'> {
  return {
    options: [
      ...options,
      generateNewOption({
        metadata: {
          canDelete: false,
          canModifyValue: false,
          template: {},
        },
        ordinal: options.length + 1,
        type: SurveyOptionType.Other,
        value: 'Other',
      }),
    ],
  };
}

const DefaultOptionsMap: OptionsMap = {
  [SurveyQuestionType.MatrixGrid]: surveyBuilder.matrixGrid.DefaultColumns,
  [SurveyQuestionType.MaxDifference]: surveyBuilder.maxDiff.DefaultOptions,
  [SurveyQuestionType.MultipleChoice]: surveyBuilder.multipleChoice.DefaultOptions,
  [SurveyQuestionType.Multiselect]: surveyBuilder.multiselect.DefaultOptions,
  [SurveyQuestionType.Ranking]: surveyBuilder.ranking.DefaultOptions,
  [SurveyQuestionType.MatrixMultiselect]: surveyBuilder.matrixMultiselect.DefaultColumns,
  [SurveyQuestionType.NumberInputTable]: surveyBuilder.numberInputTable.DefaultColumns,
};

const DefaultSettingsMap: SettingsMap = {
  [SurveyQuestionType.MultipleChoice]: surveyBuilder.multipleChoice.DefaultSettings,
  [SurveyQuestionType.Multiselect]: surveyBuilder.multiselect.DefaultSettings,
  [SurveyQuestionType.Ranking]: surveyBuilder.ranking.DefaultSettings,
  [SurveyQuestionType.MatrixGrid]: surveyBuilder.matrixGrid.DefaultSettings,
  [SurveyQuestionType.MaxDifference]: surveyBuilder.maxDiff.DefaultSettings,
  [SurveyQuestionType.ShortTextResponse]: surveyBuilder.shortText.DefaultSettings,
  [SurveyQuestionType.LongTextResponse]: surveyBuilder.longText.DefaultSettings,
  [SurveyQuestionType.ConjointAnalysis]: surveyBuilder.conjointAnalysis.DefaultSettings,
  [SurveyQuestionType.Sliders]: surveyBuilder.matrixSlider.DefaultSettings,
  [SurveyQuestionType.MatrixMultiselect]: surveyBuilder.matrixMultiselect.DefaultSettings,
  [SurveyQuestionType.NumberInputTable]: surveyBuilder.numberInputTable.DefaultSettings,
};

type OptionsMap = {
  [typeId: number]: number;
};

type SettingsMap = {
  [typeId: number]: SurveyQuestion['settings'];
};

import { useMemo } from 'react';
import SearchTabAll from './Search.Tab.All';
import SearchTabExperts from './Search.Tab.Experts';
import SearchTabTopics from './Search.Tab.Topics';
import SearchTabPosts from './Search.Tab.Posts';
import SearchTabView from './Search.Tab.View';
import { SearchRouteKey, SearchSceneMap } from './interfaces';

export default function SearchTabViewContainer() {
  const scenes: SearchSceneMap = useMemo(() => ({
    [SearchRouteKey.All]: {
      component: SearchTabAll,
      title: 'All',
    },
    [SearchRouteKey.Members]: {
      component: SearchTabExperts,
      title: 'Members',
    },
    [SearchRouteKey.Topics]: {
      component: SearchTabTopics,
      title: 'Topics',
    },
    [SearchRouteKey.Posts]: {
      component: SearchTabPosts,
      title: 'Posts',
    },
  }), []);

  return <SearchTabView scenes={scenes} />;
}

export { SearchTabViewContainer };
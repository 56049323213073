import { useCallback, useEffect, useReducer } from 'react';
import { addDays, addMinutes, differenceInMinutes, endOfDay, getDate, getMonth, getYear, isBefore, isToday, roundToNearestMinutes, startOfDay, set, subMinutes } from 'date-fns';
import { DatePicker } from './DatePicker';
import { TimePicker } from './TimePicker';

type Props = {
  className?:         string;
  day:                Date;
  includeEndTimes:    Date[] | null;
  includeStartTimes:  Date[] | null;
  onChange?:          (data: State) => unknown;
  onChangeDay?:       (date: Date) => Partial<State>;
  onChangeTimeEnd?:   (date: Date) => Partial<State>;
  onChangeTimeStart?: (date: Date) => Partial<State>;
  timeEnd?:           Date;
  timeInterval:       number;
  timeStart?:         Date;
};

const DateTimePicker = (props: Props) => {
  const [state, dispatch] = useReducer(picker, createInitialState(props));

  useEffect(() => {

    props.onChange?.(state);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    props.onChange,
    state,
  ]);

  const createValidState = useCallback(() => {
    const start = roundToNow();

    return {
      day: start,
      end: addMinutes(start, props.timeInterval),
      start,
    };
  }, [props.timeInterval]);

  const updateCurrentState = useCallback((date: Date) => {
    const minutes = differenceInMinutes(state.end, state.start);
    const start = set(state.start, {
      date: getDate(date),
      month: getMonth(date),
      year: getYear(date),
    });

    return {
      day: start,
      end: addMinutes(start, minutes),
      start,
    };
  }, [state]);

  const handleDayChange = useCallback((date: Date) => {

    const state = isBefore(roundToNow(), date)
        ? updateCurrentState(date)
        : createValidState();

    const data = props.onChangeDay?.(date) || state;

    dispatch({ data, type: 'day-change' });

  }, [
    createValidState,
    dispatch,
    props,
    updateCurrentState,
  ]);

  function handleTimeStartChange(date: Date) {
    const data = props.onChangeTimeStart?.(date)
      || { start: date, end: addMinutes(date, props.timeInterval) };

    dispatch({ data, type: 'time-start-change' });
  }

  function handleTimeEndChange(date: Date) {
    const data = props.onChangeTimeEnd?.(date)
      || { start: state.start, end: date };

    dispatch({ data, type: 'time-end-change' });
  }

  const midnight = endOfDay(state.day);
  const minEnd = !isBefore(addMinutes(state.start, props.timeInterval), midnight)
      ? midnight
      : addMinutes(state.start, props.timeInterval);

  const minStart = isToday(state.day)
      ? roundToNow()
      : startOfDay(state.day);
  const maxStart = subFromMidnight(state.day, props.timeInterval);

  return (
    <>
      <DatePicker
        className={props.className}
        minDate={new Date()}
        selected={state.day}
        onChange={handleDayChange} />
      <TimePicker
        className={props.className}
        selected={state.start}
        onChange={handleTimeStartChange}
        showTimeSelect
        showTimeSelectOnly
        includeTimes={props.includeStartTimes}
        minTime={minStart}
        maxTime={maxStart}
        timeFormat="hh:mm aa"
        timeIntervals={props.timeInterval}
        timeCaption="Start"
        dateFormat="hh:mm aa" />
      <TimePicker
        className={props.className}
        selected={state.end}
        onChange={handleTimeEndChange}
        showTimeSelect
        showTimeSelectOnly
        timeFormat="hh:mm aa"
        timeIntervals={props.timeInterval}
        includeTimes={props.includeEndTimes}
        minTime={minEnd}
        maxTime={midnight}
        timeCaption="End"
        dateFormat="hh:mm aa" />
    </>
  );
};

export type DateTimePickerState = {
  day:      Date;
  end:      Date;
  start:    Date;
};

type State = DateTimePickerState;

type Initial =
  Pick<Props,
  | 'day'
  | 'timeEnd'
  | 'timeInterval'
  | 'timeStart'>;

function createInitialState(state: Initial) {
  const start = state.timeStart || roundToNow(state.day);

  return {
    day: state.day,
    start,
    end: state.timeEnd || addMinutes(start, state.timeInterval),
  };
}

type Action =
  | { type: 'time-start-change'; data: Partial<State>; }
  | { type: 'time-end-change'; data: Partial<State>; }
  | { type: 'day-change'; data: Partial<State>; };

function picker(acc: State, x: Action) {
  switch (x.type) {
    case 'day-change':
    case 'time-end-change':
    case 'time-start-change':
      return { ...acc, ...x.data };

    default:
      return acc;
  }
}

function roundToNow(date = new Date()) {
  return roundToNearestMinutes(date, { nearestTo: 30 });
}

function subFromMidnight(date: Date, minutes: number) {
  return subMinutes(startOfDay(addDays(date, 1)), minutes);
}

const defaultProps = {
  day: new Date(),
  includeEndTimes: null,
  includeStartTimes: null,
  timeInterval: 30,
};

DateTimePicker.defaultProps = defaultProps;

export { DateTimePicker };
export default DateTimePicker;
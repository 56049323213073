import { Fragment } from 'react';
import { cx } from '@utils';
import { SectionListItems } from './SectionListItems';
import {
  SectionListProps,
  SectionListRenderHeader,
  SectionProps,
} from './interfaces';
import styles from './style.css';

type Props<T, U> = {
  className?: string;
} & SectionListProps<T, U>;

const List = <T extends unknown, U extends SectionProps>(props: Props<T, U>) => {
  const SectionHeader: SectionListRenderHeader<T, U> = ({ index, section }) => {
    return props.renderSectionHeader({ index, section });
  };

  return (
    <div className={cx(styles.root, props.className)}>
      {props.sections.map((x, i) =>
        <Fragment key={`section-${i}`}>
          <SectionHeader
            index={i}
            section={x} />
          <SectionListItems
            key={props.key}
            keyExtractor={props.keyExtractor}
            renderItem={props.renderItem}
            section={x} />
        </Fragment>)}
    </div>
  );
};

export { List };
export default List;
import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { schemaMarkup } from '@utils';
import {
  Strategy,
  VisitorSegment,
  useAssertStrategy,
} from '$website/containers';
import AbandonmentModal from './AbandonmentModal';
import { Banner } from './Banner';
import { Benefits } from './Benefits';
import Endorsements from './Endorsements';
import GetApp from './GetApp';
import HowItWorks from './HowItWorks';
import FindAProfessional from './FindAProfessional';
import { Funnel } from './Funnel';
import IndustryRelationships from './IndustryRelationships';
import { Landing } from './Landing';
import { OperationalInsights } from './OperationalInsights';
import { PressFeatures } from './PressFeatures';
import Products from './Products';
import { SegmentationPanel } from './SegmentationPanel';
import { EnterpriseWPAS, IndividualsWPAS } from './WhatPeopleAreSaying';
import styles from './style.css';

const H = memo(() => (
  <Helmet
    script={[schemaMarkup.organizationProp]}
    titleTemplate="%s">
    <title>{`Vancery: The world's first professional exchange`}</title>
  </Helmet>
));

const Main = () => {
  return (
    <>
      <H />
      <main className={styles.root}>
        <VisitorSegment component={Landing} />
        <VisitorSegment component={SegmentationPanel} />
        <VisitorSegment component={Benefits} />
        <VisitorSegment
          component={OperationalInsights}
          include={Strategy.Corporate} />
        <VisitorSegment
          component={Banner}
          include={Strategy.Corporate} />
        <CompatMainContent />
        {/* <VisitorSegment component={Funnel} /> */}
      </main>
    </>
  );
};

const CompatMainContent = () => {
  const assert = useAssertStrategy();

  if (assert(Strategy.Individuals)) {
    return (
      <>
        <HowItWorks />
        <VisitorSegment component={Banner} />
        <GetApp />
        <Endorsements />
        <VisitorSegment component={PressFeatures} />
        <IndividualsWPAS />
        {/* <AbandonmentModal /> */}
      </>
    );
  }

  return (
    <>
      <FindAProfessional />
      <Products />
      <IndustryRelationships />
      <VisitorSegment
        component={Banner}
        exclude={[
          Strategy.Individuals,
          Strategy.Corporate,
        ]} />
      <Endorsements />
      <VisitorSegment component={PressFeatures} />
      <EnterpriseWPAS />
    </>
  );
};

export { Main };
export default Main;
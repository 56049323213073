import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as api from '@api';
import * as actions from '@store/actions';
import Toast from 'components/Toast';
import { CreateFolderParams } from '../interfaces';

export const useCreateFolder = () => {
  const dispatch = useDispatch();

  const createFolder = useCallback((data: CreateFolderParams) => {
    return api.workspaces.folder.createFolder({
      name: data.name,
      parentObjectId: data.parentObjectId,
      workspaceId: data.workspaceId,
    }).then(data => {
      dispatch(actions.folderCreated({
        capabilities: data.capabilities,
        folder: data.folder,
        object: data.object,
      }));
      Toast.alert({
        title: 'New Folder Created',
      });
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue creating your folder`,
      });
      throw e;
    });

  }, [dispatch]);

  return createFolder;
};

export default useCreateFolder;
import { SurveyQuestionType } from '@enums';
import { MultipleChoiceQuestion } from '@/types';
import { Validation } from '../interfaces';
import { assertValidOptions } from './validation.options';
import { assertHasValue, returnAssertFailure } from './validation.base';

export const DefaultOptions = 2;
export const MaxOptions = 50;
export const MinOptions = 2;

export const DefaultSettings: MultipleChoiceQuestion.Settings = {
  allowOther: false,
  randomization: false,
};

export function validateQuestion(question: MultipleChoiceQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.MultipleChoice> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinOptions, MaxOptions);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}
import { useReducer } from 'react';

type Options = {
  index?: number;
  max?:   number;
  min?:   number | null;
};

export const useStepper = <T>(screens: T[], options: Options = { index: 0, min: 0 }): [T, StepperActions, number] => {
  const lastIndex = screens.length - 1;
  const max = options.max ?? lastIndex;
  const [step, dispatch] = useReducer(createReducer(options.min, max), options.index);

  function goToStep(target: number) {
    dispatch({ type: 'GO_TO_STEP', target });
  }

  function nextStep() {
    dispatch({ type: 'NEXT_STEP' });
  }

  function previousStep() {
    dispatch({ type: 'PREVIOUS_STEP' });
  }

  const actions = {
    back: previousStep,
    goTo: goToStep,
    next: nextStep,
  };

  const screen = step > lastIndex
      ? null
      : screens[step];

  return [screen, actions, step];
};

export type StepperActions = {
  back: () => void;
  goTo: (target: number) => void;
  next: () => void;
};

type StepperMessage =
  | { type: 'PREVIOUS_STEP'; }
  | { type: 'NEXT_STEP'; }
  | { type: 'GO_TO_STEP'; target: number; };

function createReducer(min: number, max: number | null) {
  return (index: number, x: StepperMessage) => {
    switch (x.type) {
      case 'PREVIOUS_STEP': {
        const prevIndex = index - 1;

        return prevIndex < min ? min : prevIndex;
      }

      case 'NEXT_STEP': {
        const nextIndex = index + 1;

        return nextIndex > max ? max : nextIndex;
      }

      case 'GO_TO_STEP': {
        if (x.target >= min && x.target <= max) {
          return x.target;
        } else {
          return index;
        }
      }

      default:
        return index;
    }
  };
}
import { useSelectPayoutSettings } from '@containers/Store';
import { useProjectOnboardingContext } from '@containers/ProjectOnboarding/hooks/useProjectOnboardingContext';
import * as PayoutSetup from '@screens/PayoutSetup';
import styles from './style/SurveyBilling.css';
import { useNavigateNextOrRedirect } from './hooks/useNavigateNextOrRedirect';

export function Stepper() {
  const { hasNextStep, projectId } = useProjectOnboardingContext();
  const settings = useSelectPayoutSettings();
  const next = useNavigateNextOrRedirect();

  const hasPayoutMethod = !!settings.method;

  return (
    <div className={styles.wrap}>
      <PayoutSetup.Stepper.Survey
        hasPayoutMethod={hasPayoutMethod}
        hasStepsAfter={hasNextStep}
        onComplete={() => next()}
        onSkip={() => next()}
        projectId={projectId} />
    </div>
  );
}
import http from '@services/http';
import * as xform from '@transformers';
import { WorkspaceObjects as WS } from './interfaces';

export const getObjectAccess = ({ objectId, workspaceId }: WS.GetObjectAccess.Request) => {
  return http.get(`/workspaces/${workspaceId}/objects/${objectId}/access`)
    .then((data: WS.GetObjectAccess.Response) => ({
      items: data.items,
      object: xform.normalizeWorkspaceObject(data.object),
      workspace: xform.normalizeWorkspace(data.workspace),
    }));
};

export const moveObject = (data: WS.MoveObject.Request) => {
  return http.post(`/workspaces/${data.workspaceId}/objects/${data.objectId}/move`, {
    parentObjectId: data.parentObjectId,
  }).then((data: WS.MoveObject.Response) => ({
    capabilities: data.capabilities,
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};

export const saveObjectAccess = (data: WS.SaveObjectAccess.Request) => {
  return http.post(`/workspaces/${data.workspaceId}/objects/${data.objectId}/access`, {
    items: data.items,
  });
};

export const updateObjectOwnership = (data: WS.UpdateObjectOwnership.Request) => {
  return http.post(`/workspaces/${data.workspaceId}/objects/${data.objectId}/owner`, {
    ownerId: data.ownerId,
  }).then((data: WS.UpdateObjectOwnership.Response) => ({
    capabilities: data.capabilities,
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};
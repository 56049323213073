import { useQuery, UseQueryOptions } from 'react-query';
import * as $api from '@api';
import { TranscriptFormat } from './../interfaces';

type Params = ICallId & {
  format: TranscriptFormat;
  transcriptId: number;
};

type Result = {
  key: string;
};

export const useDownloadCallTranscript = (data: Params, options?: UseQueryOptions<Result>) => {
  return useQuery(['download-call-transcript', data], async () => {
    return $api.calls.downloadTranscriptReport(data);
  }, {
    ...options,
    enabled: false,
    retry: 1,
  });
};
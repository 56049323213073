import {
  AvailableCalendarEvent,
  CalendarDateEvent,
  ScheduledCalendarEvent,
} from '@/types';
import {
  AvailableTimeslot,
  BusyTimeslot,
  TimeslotItem,
} from './interfaces';
import { segmentRange } from './utils';

export const buildAvailabilitySegments = (events: CalendarDateEvent[]) => {
  return events.reduce<AvailableTimeslot[]>((acc, item) => {
    return isNotBusy(item)
         ? acc.concat(createAvailableTimeslot(item))
         : acc;
  }, []);
};

export const buildBusySegments = (events: CalendarDateEvent[]) => {
  return events.reduce<BusyTimeslot[]>((acc, item) => {
    return isBusy(item)
         ? acc.concat(createBusyTimeslot(item))
         : acc;
  }, []);
};

export const buildTimeslots = (events: CalendarDateEvent[]) => {
  return events.reduce<TimeslotItem[]>((acc, item) => {
    return isNotBusy(item)
         ? acc.concat(createAvailableTimeslot(item))
         : isBusy(item)
           ? acc.concat(createBusyTimeslot(item))
           : acc;
  }, []);
};

function createAvailableTimeslot(event: AvailableCalendarEvent) {
  const hourRanges = event.segments?.hour ?? [];

  return hourRanges.reduce<AvailableTimeslot[]>((acc, range) => {
    const segments = segmentRange(range).map<AvailableTimeslot>(r => ({
      end: r.end,
      isBusy: event.isBusy,
      metadata: event.metadata,
      start: r.start,
    }));

    return acc.concat(segments);
  }, []);
}

function createBusyTimeslot(event: ScheduledCalendarEvent) {
  return segmentRange(event).map<BusyTimeslot>(range => ({
    end: range.end,
    isBusy: event.isBusy,
    metadata: event.metadata,
    start: range.start,
  }));
}

function isBusy(e: CalendarDateEvent): e is ScheduledCalendarEvent {
  return e.isBusy;
}

function isNotBusy(e: CalendarDateEvent): e is AvailableCalendarEvent {
  return !isBusy(e);
}
import { BasicRouter } from '@routes/Routers';
import { pathname } from '@consts';
import { RouteAuth } from '@enums';
import { Routing } from '@containers/Routing';
import { DebugThrowError } from '@screens/ErrorMessage';

const testRoutes = [{
  component: DebugThrowError,
  path: pathname.DebugThrowError,
}];

const testRouting: Routing.RouteConfig[] = [
  BasicRouter(testRoutes, {
    auth: RouteAuth.Authenticated,
    path: pathname.DebugThrowError,
  }),
];
const devRouting: Routing.RouteConfig[] = [];

const nonProdRoutes = RegExp('test|stage|dev').test(process.env.VANCERY_ENV) ? testRouting : [];
const devRoutes = process.env.VANCERY_ENV === 'dev' ? devRouting : [];

export const routes = [...devRoutes, ...nonProdRoutes].flat();
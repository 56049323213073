import { Fragment, useMemo } from 'react';
import { MatrixSliderQuestion } from '@/types';
import { MatrixSliderAnswerProps, MatrixSliderAnswerItemProps } from './interfaces';
import styles from './style/MatrixSlider.css';

const sortItems = (reversed: boolean) => (a: MatrixSliderQuestion.Answer.Item, b: MatrixSliderQuestion.Answer.Item) => {
  return reversed
    ? a.value > b.value ? 1 : -1
    : a.value > b.value ? -1 : 1
  ;
};

const MatrixSliderAnswerItem = (props: MatrixSliderAnswerItemProps) => {
  return (
    <div className={styles.item}>
      {`${props.item.row.value}: ${props.item.value}`}
    </div>
  );
};

export const MatrixSliderAnswerValue = ({ answer, settings }: MatrixSliderAnswerProps) => {
  const sortedItems = useMemo(() => {
    const reversed = settings.minValue > settings.maxValue;
    return answer.items
      .sort(sortItems(reversed));
  }, [answer.items, settings]);

  return <>
    {sortedItems.map(item => (
      <Fragment key={item.row.id}>
        <MatrixSliderAnswerItem item={item} />
      </Fragment>
    ))}
  </>;
};

export default MatrixSliderAnswerValue;
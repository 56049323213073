import { ProjectStatus } from '@enums';
import { Briefcase } from 'components/images';
import styles from './style/AcceptedProjects.Placeholder.css';

type Props = {
  status: ProjectStatus;
};

const AcceptedProjectsPlaceholder = ({ status }: Props) => {
  const text = status === ProjectStatus.Active
               ? 'No active projects.'
               : status === ProjectStatus.Archived
                 ? 'No archived projects.'
                 : 'You will be notified when you are selected for a project.';
  return (
    <div className={styles.root}>
      <Briefcase
        className={styles.briefcase}
        color={`var(--gray-l)`} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default AcceptedProjectsPlaceholder;
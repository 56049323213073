import { useCallback, useMemo } from 'react';
import { useGetQuestionRawText, useSurveyBuilderState } from '@containers/SurveyBuilder';
import { SurveyQuestion } from '@/types/survey';
import { ActionOrdinalItem } from '../interfaces';

export const useSkipToQuestionOptions = (ordinal: number): ActionOrdinalItem[]  => {
  const [state] = useSurveyBuilderState();

  const getQuestionText = useGetQuestionRawText();

  const checkQuestionOrdinal = useCallback((item: SurveyQuestion) => {
    return item.ordinal > ordinal + 1;
  }, [ordinal]);

  const checkSectionVisibility = useCallback((item: SurveyQuestion) => {

    const propOrdinalQ = state.survey.questions.find(f => f.ordinal === ordinal);
    const propOrdinalSection = state.survey.sections.find(f => f.identifier === propOrdinalQ.section.identifier);

    const itemSection = state.survey.sections.find(f => f.identifier === item.section.identifier);

    const currentSectionOrdinal = propOrdinalSection.ordinal;

    return currentSectionOrdinal === itemSection.ordinal ||
      !state.survey.sections.find(s => s.ordinal === itemSection.ordinal).hidden;
  }, [
    state.survey.questions,
    ordinal,
    state.survey.sections,
  ]);

  const items: ActionOrdinalItem[] = useMemo(() => {
    return state.survey.questions
      .filter(checkQuestionOrdinal)
      .filter(checkSectionVisibility)
      .map(m => ({
        identifier: m.base.identifier,
        ordinal: m.ordinal,
        value: getQuestionText(m.value),
      }));
  }, [
    checkQuestionOrdinal,
    checkSectionVisibility,
    getQuestionText,
    state.survey.questions,
  ]);

  return items;
};

export default useSkipToQuestionOptions;
import { useCallback } from 'react';
import * as consts from '@consts';
import { ProjectDescriptionInput } from 'components/GroupProject/ProjectDescriptionInput';

export const useProjectDescriptionInput = () => {
  const validate = useCallback((value: string) => {
    return value?.length <= consts.maxLengths.PROJECT_DESCRIPTION;
  }, []);

  return [ProjectDescriptionInput, validate] as const;

};

export default useProjectDescriptionInput;
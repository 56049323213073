import { useSelector } from 'react-redux';
import * as scheduler from '@containers/Scheduler/utils';
import { Call, Project } from '@/types';

type CallsByStatus = {
  missed:       Call[];
  pending:      Call[];
  rescheduling: Call[];
  scheduled:    Call[];
};

const mapState = (state: Store.State) => ({
  calls: state.calls,
  contacts: state.contacts,
});

export const useCallsByStatus = (project: Project) => {
  const state = useSelector(mapState);

  return project.callIds.reduce<CallsByStatus>((acc, id) => {
    const call = state.calls[id];
    if (!call) return acc;

    const user = state.contacts[call.userId];
    if (!user) return acc;

    if (scheduler.calls.isMissed(call)) {
      acc.missed = acc.missed.concat(call);
    } else if (scheduler.calls.isPending(call)) {
      acc.pending = acc.pending.concat(call);
    } else if (scheduler.calls.isRescheduling(call)) {
      acc.rescheduling = acc.rescheduling.concat(call);
    } else if (scheduler.calls.isConfirmed(call)) {
      acc.scheduled = acc.scheduled.concat(call);
    }

    return acc;
  }, {
    missed: [],
    pending: [],
    rescheduling: [],
    scheduled: [],
  });
};
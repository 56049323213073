import extend from '@enums/extend';

export enum PayoutMethod {
  NotSet = 0,
  Stripe = 1,
  Check = 2,
  PayPal = 3,
}

const map = new Map([
  [PayoutMethod.NotSet, { name: 'Not Set' }],
  [PayoutMethod.Stripe, { name: 'Stripe' }],
  [PayoutMethod.Check,  { name: 'Check' }],
  [PayoutMethod.PayPal,  { name: 'PayPal' }],
]);

export default {
  PayoutMethod: extend(map),
};
import { useCallback, useContext } from 'react';
import { RequestCallContext } from '@containers/RequestCall/Context';
import { CallRequestRecipient } from '@containers/RequestCall/interfaces';

export const useRequestCall = () => {
  const [_, requestCall] = useContext(RequestCallContext);
  return requestCall;
};

export const useCallRequestRecipient = () => {
  const [recipient] = useContext(RequestCallContext);
  return recipient;
};

export const useRequestCallWithUser = (data: CallRequestRecipient) => {
  const [_, requestCall] = useContext(RequestCallContext);

  return useCallback(() => requestCall(data), [data, requestCall]);
};
import { SurveyQuestionType } from '@enums';
import { MatrixLogicState } from '@containers/SurveyBuilder.Logic/Matrix.State';
import { useQuestionLogicBuilderState } from '@containers/SurveyBuilder.Logic/hooks';
import { QuestionOptionsLogicBuilderContainer } from '@containers/SurveyBuilder.Logic/Options.Container';
import { DefaultQuestionLogicContainer } from '@containers/SurveyBuilder.Logic/Default.State';
import { OptionsQuestionLogicState } from '@containers/SurveyBuilder.Logic/Options.State';
import { QuestionOptionsLogicBuilder } from './Options.Builder';
import { DefaultQuestionLogicBuilder } from './Default.Builder';
import { MatrixLogicBuilder } from './Matrix.Builder';

export const QuestionLogicBuilder = () => {
  const { item } = useQuestionLogicBuilderState();

  switch (item.typeId) {
    case SurveyQuestionType.Multiselect:
    case SurveyQuestionType.MultipleChoice: {
      return (
        <OptionsQuestionLogicState item={item}>
          <QuestionOptionsLogicBuilderContainer>
            <QuestionOptionsLogicBuilder />
          </QuestionOptionsLogicBuilderContainer>
        </OptionsQuestionLogicState>
      );
    }

    case SurveyQuestionType.MatrixGrid:
    case SurveyQuestionType.MatrixMultiselect: {
      return (
        <MatrixLogicState item={item}>
          <MatrixLogicBuilder />
        </MatrixLogicState>
      );
    }

    default: {
      return (
        <DefaultQuestionLogicContainer item={item}>
          <DefaultQuestionLogicBuilder />
        </DefaultQuestionLogicContainer>
      );
    }
  }

};

export default QuestionLogicBuilder;
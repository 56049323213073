export namespace MaxDiff {
  export namespace AddOption {
    export type State = unknown;
    export type Action = {
      type: 'maxdiff-add-option';
    } & State;
  }

  export namespace RemoveOption {
    export type State = {
      ordinal: number;
    };
    export type Action = {
      type: 'maxdiff-remove-option';
    } & State;
  }

  export namespace UpdateOptionName {
    export type State = {
      ordinal: number;
      value: string;
    };
    export type Action = {
      type: 'maxdiff-update-option-name';
    } & State;
  }

  export namespace UpdateLeastLabel {
    export type State = {
      value: string;
    };
    export type Action = {
      type: 'maxdiff-update-least-label';
    } & State;
  }

  export namespace UpdateMostLabel {
    export type State = {
      value: string;
    };
    export type Action = {
      type: 'maxdiff-update-most-label';
    } & State;
  }

  export namespace UpdateOptionsPerSet {
    export type State = {
      value: number;
    };
    export type Action = {
      type: 'maxdiff-update-options-per-set';
    } & State;
  }

  export namespace UpdateSets {
    export type State = {
      value: number;
    };
    export type Action = {
      type: 'maxdiff-update-sets';
    } & State;
  }

  export type Action =
    | AddOption.Action
    | RemoveOption.Action
    | UpdateOptionName.Action
    | UpdateLeastLabel.Action
    | UpdateMostLabel.Action
    | UpdateOptionsPerSet.Action
    | UpdateSets.Action;
}
import qs from 'query-string';
import http from '@services/http';
import * as xform from '@api/transformers';
import { safeJsonDate } from '@utils';
import { Groups as Groups } from '$admin/api/interfaces';
import * as xformAdmin from './transformers';

export * from './groups.balance-account';
export * from './groups.pre-call-recording';

export const getAccounts = (): Promise<Groups.GetAccounts.Response> => {
  return http.get(`/admin/groups`);
};

export const getAccountsDashboard = (): Promise<Groups.GetAccountsDashboard.Response> => {
  return http.get(`/admin/groups/dashboard`);
};

export const getAnalysts = (data: Groups.FetchAnaysts.Request): Promise<Groups.FetchAnaysts.Response> => {
  const { groupId, ...rest } = data;
  return http.get(`/admin/groups/${groupId}/analysts?${qs.stringify(rest, { arrayFormat: 'bracket' })}`);
};

export const getDetails = (data: Groups.FetchAccount.Request) => {
  return http.get(`/admin/groups/${data.groupId}`)
  .then((res: Groups.FetchAccount.Response<string>) => xformAdmin.transformAccountDetails(res));
};

export const getComplianceSurvey = (data: Groups.FetchComplianceSurvey.Request): Promise<Groups.FetchComplianceSurvey.Response> => {
  return http.get(`/admin/groups/${data.groupId}/compliance-survey`);
};

export const exportData = (data: Groups.ExportData.Request): Promise<Groups.ExportData.Response> => {
  return http.get(`/admin/groups/${data.groupId}/export`);
};

export const getGroupUsers = (data: Groups.GetGroupUsers.Request) => {
  return http.get<Groups.GetGroupUsers.Response>(`/admin/groups/${data.groupId}/users?${qs.stringify(data, { arrayFormat: 'bracket' })}`)
  .then(data => {
    return {
      items: data.items.map(m => ({
        ...m,
        activity: {
          ...m.activity,
          lastLogin: safeJsonDate(m.activity.lastLogin),
        },
      })),
      teams: data.teams,
      teamMap: data.teamMap,
    };
  });
};

export const getPostCallAnalystQuestions  = (data: Groups.FetchPostCallAnalustQuestions.Request): Promise<Groups.FetchPostCallAnalustQuestions.Response> => {
  return http.get(`/admin/groups/${data.groupId}/post-call-analyst-questions`);
};

export const getWorkspaces = (data: Groups.GetWorkspaces.Request) => {
  return http.get(`/admin/groups/${data.groupId}/workspaces`)
    .then((data: Groups.GetWorkspaces.Response) => ({
      workspaces: data.workspaces.map(w => xform.normalizeWorkspace(w)),
    }));
};

export const saveComplianceSurvey = (data: Groups.SaveComplianceSurvey.Request): Promise<Groups.SaveComplianceSurvey.Response> => {
  return http.post(`/admin/groups/${data.groupId}/compliance-survey`, data);
};

export const savePostCallAnalystQuestions = (data: Groups.SavePostCallAnaylstQuestions.Request): Promise<Groups.SavePostCallAnaylstQuestions.Response> => {
  return http.post(`/admin/groups/${data.groupId}/post-call-analyst-questions`, data);
};

export const updateAccount = (data: Groups.UpdateAccount.Request) => {
  return http.post(`/admin/groups/${data.id}`, data)
  .then((res: Groups.UpdateAccount.Response<string>) => xformAdmin.transformAccountDetails(res));
};

export const updateSettings = (data: Groups.UpdateSettings.Request): Promise<Groups.UpdateSettings.Response> => {
  const { groupId, ...settings } = data;
  return http.patch(`/admin/groups/${groupId}/settings`, settings);
};
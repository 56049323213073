import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import * as selectors from '@store/selectors';
import * as enums from '@enums';
import { formatTruncatedName } from '@utils';
import { FoldersTableItem } from 'components/WorkspaceFolders/interfaces';
import FoldersTable from './FoldersTable';

const selectSharedFolders = createSelector(
  [
    (state: Store.State) => state.user.id,
    (state: Store.State) => state.contacts,
    selectors.selectFolderIds,
    selectors.selectFolderValues,
    selectors.selectObjectValues,
  ],
  (meId, contacts, folderIds, folders, objects) => {
    return folderIds
    .reduce((acc, folderId) => {
      const folder = folders[folderId];
      const object = objects[folder.objectId];
      const parentObject = objects[object.parentObjectId];

      if (parentObject &&
        (parentObject.ownerId === meId || parentObject.typeId !== enums.WorkspaceObjectType.Workspace)) {
        return acc;
      }

      const item = {
        ...folder,
        owner: object.ownerId === meId
          ? 'Me'
          : formatTruncatedName(contacts[object.ownerId]),
      };

      return [...acc, item];

    }, [] as FoldersTableItem[]);
  },

);

export const SharedTabFolders = () => {

  const data = useSelector(selectSharedFolders);

  return (
    <FoldersTable
      canCreateNew={false}
      data={data} />
  );
};

export default SharedTabFolders;
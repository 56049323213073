import { memo } from 'react';
import { Link } from 'react-router-dom';
import { getLocationFor, slugify, formatTruncatedName } from '@utils';
import { AskAnExpertItem as Item } from '@/types';
import { UserAvatar } from 'components/UserAvatar/UserAvatar';
import Actions from './Actions';
import styles from './style/Item.css';

type Props = {
  item: Item;
};

export const AskAnExpertItem = memo(({ item }: Props) => {
  const profileLocation = getLocationFor.user.profile({
    slug: slugify({
      id: item.id,
      name: item.contact.profile.fullname,
    })});

  return (
    <div className={styles.root}>
      <UserAvatar
        className={styles.avatar}
        pictureUrl={item.contact.profile.pictureUrl}
        to={profileLocation}
        size={45} />
      <div className={styles.details}>
        <div className={styles.top}>
          <Link className={styles.name} to={profileLocation}>
            {formatTruncatedName(item.contact)}
          </Link>
          <Actions item={item} />
        </div>
        <div className={styles.title}>
          {item.contact.career.current?.display || item.contact.career.former?.display}
        </div>
      </div>
    </div>
  );
});

export default AskAnExpertItem;
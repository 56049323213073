import { DollarSign } from 'react-feather';
import { useHasPaymentMethod } from '@containers/Payment/hooks/useHasPaymentMethod';
import { cx, hasClientRole } from '@utils';
import { Contact } from '@/types';
import { Button } from 'components/Button';
import styles from './style/Channel.css';

type Props = {
  isSendingEnabled: boolean;
  member:           Contact;
  onPaymentSetup:   () => unknown;
  onToggle:         () => unknown;
  value:            boolean;
};

export function PaidMessageButton({ isSendingEnabled, value, onPaymentSetup, onToggle, ...props }: Props) {
  const hasPaymentMethod = useHasPaymentMethod();

  if (hasClientRole(props.member.persona)) return null;

  const handleClick = hasPaymentMethod ? onToggle : onPaymentSetup;

  const disabled = !isSendingEnabled;
  const classname = !disabled
    ? value
      ? styles.paidOn
      : styles.paidOff
    : null;

  return (
    <Button
      disabled={disabled}
      className={cx(styles.paidButton, classname)}
      variant='pill'
      onClick={handleClick}>
      <div className={styles.buttonText}>
        <span>Paid Message </span>
        <DollarSign className={styles.paidIcon} />
      </div>
    </Button>
  );
}

export default PaidMessageButton;

import { useMemo } from 'react';
import { format } from 'date-fns';
import { WorkspaceExpertFilesCall } from '@/types';
import { FileIcon } from 'components/icons';
import ClickContainer from './ClickContainer';
import styles from './style.css';

type Props = {
  item: WorkspaceExpertFilesCall;
}

export default function Call({ item }: Props) {
  const time = useMemo(() => format(item.timestamp, 'M/d/yyyy h:mm aa'), [item.timestamp]);

  return (
    <ClickContainer className={styles.file} item={item}>
      <FileIcon
        className={styles.icon}
        extension={item.format === 'video' ? 'mp4' : 'mp3'} />
      <div className={styles.details}>
        <div className={styles.name}>{item.name}</div>
        <div className={styles.type}><span className={styles.time}>{time}</span></div>
      </div>
    </ClickContainer>
  );
}

export { Call };
import { useCallback } from 'react';
import { SurveyTemplateType } from '@enums/survey.template';
import {
  TargetPresetScreen,
  CategoryPresetScreen,
  CompetitorsPresetScreen,
  ThanksPresetScreen,
} from './ProjectSurvey.Presets.Shared';
import { BackButton, Footer, NextButton } from './Footer';
import { useProjectSurveyStepperActions } from './context';
import { BasePresetScreen } from './ProjectSurvey.Presets.Shared';

const LetsGetStarted = () => {

  const title = `Great, let's get started building your survey!`;
  const subtitleOne = `To set up your Marketing Campaign Effectiveness Survey template, we'll ask a couple of questions about the industry, companies, and products you're looking to research in order to pre-populate questions.`;
  const subtitleTwo = `You'll then be able to customize the survey draft with specific questions through our survey builder. Let's jump in!`;

  const actions = useProjectSurveyStepperActions();

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitleOne, subtitleTwo]} />
      <Footer>
        <BackButton onClick={actions.back} />
        <NextButton
          title="Get Started"
          onClick={actions.next} />
      </Footer>
    </>
  );
};

const totalSteps = 3;

const Category = () => {

  const title = `Step 1 of ${totalSteps}: The industry category`;
  const subtitle = `What is the product or industry category you would like to collect campaign effectiveness information on?`;

  return (
    <CategoryPresetScreen
      subtitle={subtitle}
      title={title} />
  );
};

const Target = () => {

  const title = `Step 2 of ${totalSteps}: Your research target`;
  const subtitle = `What is the name of the primary company or product you would like to collect pricing strategy information on?`;

  return (
    <TargetPresetScreen
      subtitle={subtitle}
      title={title} />
  );
};

const Competitors = () => {

  const title = `Step 3 of ${totalSteps}: The competitive landscape`;
  const subtitle = `What are the names of the competing companies or products you would like to collect pricing strategy information on?`;

  return (
    <CompetitorsPresetScreen
      subtitle={subtitle}
      title={title} />
  );
};

export const MarketingCampaignEffectivenessScreens = [
  LetsGetStarted,
  Category,
  Target,
  Competitors,
  ThanksPresetScreen,
];
import { HourlyRateSlider } from 'components/Slider';
import styles from './style.css';

type Props = {
  hourlyRate: number;
  maximumRate: number;
  minimumRate: number;
  onDragEnd: () => void;
  onPositionChange: (position: number) => void;
};

const SetRate = (props: Props) => (
  <div className={styles.hourlyInput}>
    <div className={styles.hourlyRateText}>
      <div className={styles.hourlyRateTitle}>Set your hourly rate:</div>
      <div className={styles.hourlyRate}>${props.hourlyRate}</div>
    </div>
    <div className={styles.rateProgressBar}>
      <HourlyRateSlider
        incrementBy={10}
        minimumRate={props.minimumRate}
        maximumRate={props.maximumRate}
        onPositionChange={props.onPositionChange}
        onDragEnd={props.onDragEnd}
        value={props.hourlyRate} />
    </div>
  </div>
);

export default SetRate;
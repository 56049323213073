import { useContext } from 'react';
import { createNamedContext, StepperActions } from '@utils';
import { UserProfileProps, Contact } from '@/types';

type NoteContextContextValue = [
  string,
  React.Dispatch<React.SetStateAction<string>>,
];

type SubjectContextContextValue = [
  string,
  React.Dispatch<React.SetStateAction<string>>,
];

const NoteContext = createNamedContext<NoteContextContextValue>(undefined, 'AdHocCallRequestNoteContext');
const SubjectContext = createNamedContext<SubjectContextContextValue>(undefined, 'AdHocCallRequestSubjectContext');
const RateContext = createNamedContext<number>(undefined, 'AdHocCallRequestRateContext');
const StepperContext = createNamedContext<StepperActions>(undefined, 'AdHocCallRequestStepperContext');
const ProfileContext = createNamedContext<UserProfileProps>(undefined, 'AdHocCallRequestProfileContext');

export const AdHocCallRequestContext = {
  Actions: StepperContext,
  Notes: NoteContext,
  Profile: ProfileContext,
  Rate: RateContext,
  Subject: SubjectContext,
};

export const useAdHocRequestNote = () => useContext(NoteContext);
export const useAdHocRequestSubject = () => useContext(SubjectContext);
export const useAdHocRequestStepper = () => useContext(StepperContext);
export const useAdHocRequestRecipient = () => useContext(ProfileContext);
export const useAdHocRequestRate = () => useContext(RateContext);
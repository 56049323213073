import { QuestionTaggingBuilderContainer } from '@containers/SurveyBuilder.Tagging/QuestionTaggingBuilderContainer';
import { QuestionTaggingPreview } from '@presentation/SurveyTaggingPreview/QuestionTaggingPreview';
import EditableQuestionTagging from './EditableQuestionTagging';
import { QuestionTaggingItemProps } from './interfaces';

type Props
  = QuestionTaggingItemProps;

export default function QuestionTaggingItem({ canEdit, editing, item, toggleEditingOn }: Props) {
  if (editing) {
    return <QuestionTaggingBuilderContainer item={item} />;
  } else if (canEdit) {
    return <EditableQuestionTagging item={item} onClick={toggleEditingOn} />;
  }

  return <QuestionTaggingPreview item={item} />;
}

export { QuestionTaggingItem };
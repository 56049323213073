import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { Calls } from '@api/interfaces';
import { useTranscriptHighlightActions } from '@containers/Transcript';

type Data = Calls.SaveTranscriptHighlight.Response['highlight'];
type Variables<T extends Record<string, unknown>> = {
  highlight: Calls.SaveTranscriptHighlight.Request['highlight'];
} & T;
type Options<T extends Record<string, unknown>> = UseMutationOptions<Data, AxiosResponse, Variables<T>>;

export function useSaveHighlight<T extends Record<string, unknown> = Record<string, unknown>>(options?: Options<T>) {

  const { saveHighlight } = useTranscriptHighlightActions();

  return useMutation(['save-transcript-highlight'], ({ highlight }) => {

    return saveHighlight({ highlight });

  }, options);
}

export type { Options as SaveHighlightOptions };
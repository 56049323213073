import {
  extension,
  ApplySchemaAttributes,
  ExtensionTag,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
} from '@remirror/core';
import { SurveyRichText } from '@/types/survey.rich-text';
import { PipedResponse } from './Node.PipedResponse';

@extension({
  defaultOptions: {},
})
export class PipedResponseExtension extends NodeExtension {

  get name() {
    return SurveyRichText.NodeType.PipedResponse;
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...override,
      atom: true,
      attrs: {
        question: {},
        condition: {},
        text: {
          default: '',
        },
        ...extra.defaults(),
      },
      inline: true,
      toDOM: node => ['span', {
        ...extra.dom(node),
        contentEditable: 'false',
        selectable: 'false',
      }],
    };
  }

  ReactComponent = PipedResponse;
}
import { ApprovalStatus } from '@enums';
import { Button } from 'components/Button';
import { Flag } from 'components/icons';
import { PlatformMessage } from './interfaces';
import styles from './style/ThreadMessageActions.css';

type Props = {
  item:       PlatformMessage;
  onApprove:  (id: number) => unknown;
  onFlag?:    (id: number) => unknown;
  onReject:   (id: number) => unknown;
  isFlagged?: boolean;
};

const ThreadMessageActions = (props: Props) => {

  function approve() {
    props.onApprove(props.item.message.id);
  }

  function flag() {
    props.onFlag?.(props.item.message.id);
  }

  function reject() {
    props.onReject(props.item.message.id);
  }

  return (
    <div className={styles.root}>

      <FlagGlyph
        isFlagged={props.isFlagged}
        item={props.item}
        onClick={flag} />

      <Button
        className={styles.destructive}
        color="destructive"
        onClick={reject}
        variant="pill">
        Reject
      </Button>

      <Button
        className={styles.affirmative}
        color="affirmative"
        onClick={approve}
        variant="pill">
        Approve
      </Button>

    </div>
  );
};

type FlagGlyphProps = {
  onClick: () => unknown;
} & Pick<Props, 'isFlagged' | 'item'>;

function FlagGlyph(props: FlagGlyphProps) {
  const needsScreening = props.item.message.screeningStatusId === ApprovalStatus.NeedsApproval;

  if (needsScreening) {
    return (
      <button onClick={props.onClick}>
        <Flag
          active={props.isFlagged}
          className={styles.flag} />
      </button>
    );
  }

  if (props.isFlagged) {
    return (
      <div className={styles.static}>
        <Flag
          active={props.isFlagged}
          className={styles.flag} />
      </div>
    );
  }

  return null;
}

export { ThreadMessageActions };
export default ThreadMessageActions;
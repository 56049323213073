import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as api from '@api';
import * as actions from '@store/actions';
import Toast from 'components/Toast';

type MoveFolderParams = {
  parentObjectId: number;
} & IWorkspaceId
  & IWorkspaceObjectId

export const useMoveObject = () => {
  const dispatch = useDispatch();

  const moveObject = useCallback((params: MoveFolderParams) => {
    return api.workspaces.object.moveObject({
      objectId: params.objectId,
      parentObjectId: params.parentObjectId,
      workspaceId: params.workspaceId,
    }).then(data => {
      dispatch(actions.workspaceObjectMoved({
        capabilities: data.capabilities,
        object: data.object,
      }));
      Toast.alert({
        title: 'Project Moved',
      });
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });

  }, [dispatch]);

  return moveObject;
};

export default useMoveObject;
import { useCallback } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NumberInputTableQuestion } from '@/types';
import Grid from './NumberInputTable.Grid';
import List from './NumberInputTable.List';

type Props = {
  answer: NumberInputTableQuestion.Answer.Value;
  question: NumberInputTableQuestion.Question;
};

export const NumberInputTableAnswerValue = ({ answer, question }: Props) => {

  const getCellAnswer = useCallback((rowId: number, optionId: number) => {
    return answer.items
      .find(f => f.option.id === optionId && f.row.id === rowId)?.value;
  }, [answer.items]);

  const getColumnTotal = useCallback((optionId: number) => {

    return answer.items.reduce((sum, item) => {
      if (item.option.id === optionId) {
        return sum + item.value;
      }
      return sum;
    }, 0);
  }, [answer.items]);

  const showGrid = useMediaQuery('(min-width:800px)');

  const params = {
    answer,
    getCellAnswer,
    getColumnTotal,
    question,
  };

  return (
    <>
      {showGrid && <Grid {...params} />}
      {!showGrid && <List {...params} />}
    </>
  );
};

export default NumberInputTableAnswerValue;
import { useMutation, MutateOptions } from 'react-query';
import { AxiosError } from 'axios';
import * as api from '@api';
import { Surveys } from '@api/interfaces';
import { useSurveyResponseIdentifier, useSurveyFormContext  } from '../Context';
import { useSurveyFormQuestionContext } from './useSurveyFormQuestionContext';

type Data = Surveys.Response.GoBack.Response;
type Options = MutateOptions<Data, AxiosError>;

export const useSurveyResponseGoBack = (options?: Options) => {
  const survey = useSurveyFormContext();
  const responseIdentifier = useSurveyResponseIdentifier();
  const { answer, question, setState } = useSurveyFormQuestionContext();

  return useMutation(surveyResponseGoBackKey(responseIdentifier), () => {

    return api.surveys.response.goBack({
      responseIdentifier,
      surveyVersionId: survey.id,
      surveyVersionQuestionId: question.id,
      value: answer,
    });

  }, {
    ...options,
    onSuccess: res => {
      setState(res);
    },
  });
};

export const surveyResponseGoBackKey = (identifier: string) => ['survey-response-go-back', identifier];

export default useSurveyResponseGoBack;
import { useCallback, useState, useMemo } from 'react';
import { TwilioError, LocalTrack, LocalAudioTrack, LocalVideoTrack, Room, Logger } from 'twilio-video';
import * as Sentry from '@sentry/react';
import { isScreenTrack } from 'components/Conference.Video';
import {
  useRoom,
  useScreenShareToggle,
  useLocalTracks,
  useHandleRoomDisconnection,
  useRestartAudioTrackOnDeviceChange,
  useHandleTrackPublicationFailed,
  useIsTrackEnabled,
} from 'components/Conference.Video/hooks';
import Toast from 'components/Toast';
import { ConferenceTwilioVideoContext } from './Context';

const logger = Logger.getLogger('twilio-video');
logger.setLevel('warn');

export function TwilioVideoContainer({ children }: ChildrenProps) {
  const {
    localTracks,
    getLocalAudioTrack,
    removeLocalAudioTrack,
    getLocalVideoTrack,
    removeLocalVideoTrack,
  } = useLocalTracks();

  const onError = useCallback((error: TwilioError | Error) => {
    Sentry.captureException(error);
    console.error(`VIDEO ERROR: ${error.message}`, error);
    Toast.error({
      title: 'Video Error',
      body: `${error.message}`,
    });
  }, []);

  const { room, connect, isConnecting } = useRoom(localTracks, onError, {
    bandwidthProfile: {
      video: {
        trackSwitchOffMode: 'disabled',
        dominantSpeakerPriority: 'standard',
        mode: 'collaboration',
        contentPreferencesMode: 'auto',
        clientTrackSwitchOffControl: 'auto',
      },
    },
    dominantSpeaker: true,
    networkQuality: {
      local: 3,
      remote: 3,
    },
  });

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room, onError);

  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
  );
  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);

  const [isSharingAudio, toggleLocalAudio] = useLocalAudioToggle(
    room,
    localTracks,
    getLocalAudioTrack,
    removeLocalAudioTrack,
    onError,
  );

  const [isSharingVideo, toggleLocalVideo] = useLocalVideoToggle(
    room,
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onError,
  );

  const [previewTrack, enablePreview, disablePreview] = useCameraPreview();

  const setupLocalTracks = useCallback((microphone: boolean, camera: boolean) => {
    if (microphone && !isSharingAudio) {
      toggleLocalAudio();
    }
    if (camera && !isSharingVideo) {
      toggleLocalVideo();
    }
  }, [toggleLocalAudio, isSharingAudio, toggleLocalVideo, isSharingVideo]);

  const muteSelf = useCallback(() => {
    if (isSharingAudio) {
      toggleLocalAudio();
    }
  }, [isSharingAudio, toggleLocalAudio]);

  const disconnect = useCallback(() => {
    room?.disconnect();
  }, [room]);

  const getAVPermission = useCallback((audio: boolean, video: boolean) => {
    if (!navigator.mediaDevices?.getUserMedia) return Promise.resolve(false);
    return navigator.mediaDevices.getUserMedia({ audio, video })
      .then(stream => {
        stream.getTracks().forEach(track => track.stop());
        return true;
      })
      .catch(() => false);
  }, []);

  const value = useMemo(() => ({
    onError,
    room,
    connect,
    isConnecting,
    localTracks,
    setupLocalTracks,
    isSharingScreen,
    toggleScreenShare,
    isSharingAudio,
    toggleLocalAudio,
    isSharingVideo,
    toggleLocalVideo,
    muteSelf,
    disconnect,
    getAVPermission,
    previewTrack,
    enablePreview,
    disablePreview,
  }), [
    onError,
    room,
    connect,
    isConnecting,
    localTracks,
    setupLocalTracks,
    isSharingScreen,
    toggleScreenShare,
    isSharingAudio,
    toggleLocalAudio,
    isSharingVideo,
    toggleLocalVideo,
    muteSelf,
    disconnect,
    getAVPermission,
    previewTrack,
    enablePreview,
    disablePreview,
  ]);

  return (
    <ConferenceTwilioVideoContext.Provider value={value}>
      {children}
    </ConferenceTwilioVideoContext.Provider>
  );
}

function useLocalAudioToggle(
  room: Room,
  localTracks: LocalTrack[],
  getLocalAudioTrack: () => Promise<LocalAudioTrack>,
  removeLocalAudioTrack: () => void,
  onError: (error: TwilioError | Error) => void,
) {
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const [isPublishing, setIsPublishing] = useState(false);
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleLocalAudio = useCallback(() => {
    if (!isPublishing) {
      if (audioTrack) {
        const localTrackPublication = room?.localParticipant?.unpublishTrack(audioTrack);
        // TODO: remove when SDK implements this event.
        room?.localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalAudioTrack();
      } else {
        setIsPublishing(true);
        getLocalAudioTrack()
          .then((track: LocalAudioTrack) => room?.localParticipant?.publishTrack(track, { priority: 'low' }))
          .catch(onError)
          .finally(() => {
            setIsPublishing(false);
          });
      }
    }
  }, [audioTrack, isPublishing, room?.localParticipant, getLocalAudioTrack, removeLocalAudioTrack, onError]);

  return [isEnabled, toggleLocalAudio] as const;
}

function useLocalVideoToggle(
  room: Room,
  localTracks: LocalTrack[],
  getLocalVideoTrack: () => Promise<LocalVideoTrack>,
  removeLocalVideoTrack: () => void,
  onError: (error: TwilioError | Error) => void,
) {
  const videoTrack = localTracks.find(track => !isScreenTrack(track.name) && track.kind === 'video') as LocalVideoTrack;
  const [isPublishing, setIsPublishing] = useState(false);

  const toggleLocalVideo = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = room?.localParticipant?.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event.
        room?.localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setIsPublishing(true);
        getLocalVideoTrack()
          .then((track: LocalVideoTrack) => room?.localParticipant?.publishTrack(track, { priority: 'low' }))
          .catch(onError)
          .finally(() => setIsPublishing(false));
      }
    }
  }, [videoTrack, isPublishing, room?.localParticipant, getLocalVideoTrack, removeLocalVideoTrack, onError]);

  return [!!videoTrack, toggleLocalVideo] as const;
}

function useCameraPreview() {
  const { localTracks, getLocalVideoTrack, removeLocalVideoTrack } = useLocalTracks();
  const [isAcquiring, setIsAcquiring] = useState(false);

  const track = localTracks.find(track => !isScreenTrack(track.name) && track.kind === 'video') as LocalVideoTrack;

  const enable = useCallback(() => {
    if (!isAcquiring) {
      setIsAcquiring(true);
      return getLocalVideoTrack().finally(() => setIsAcquiring(false));
    }
  }, [isAcquiring, getLocalVideoTrack]);

  const disable = useCallback(() => removeLocalVideoTrack(), [removeLocalVideoTrack]);

  return [track, enable, disable] as const;
}
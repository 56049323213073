import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { useSelectUser } from '@containers';
import { useAuthorizeStripeConnect } from '@utils/api';
import img from 'static/images/vancery-logo.svg?url';
import styles from './style/Inbound.css';

export function Stripe() {
  const location = useLocation();
  const user = useSelectUser();
  const connectAuthorize = useAuthorizeStripeConnect();

  const query = QueryString.parse(location.search);

  useEffect(() => {
    if (query.code) {
      connectAuthorize({
        code: query.code as string,
        userId: user.id,
      })
      .finally(() => window.close());
    }
  }, [connectAuthorize, query?.code, user?.id]);

  return (
    <div className={styles.root}>
      <img className={styles.img} src={img} />
      <div className={styles.redirecting}>
        Loading...
      </div>
    </div>
  );
}

export default Stripe;
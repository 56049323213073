import { useCallback } from 'react';
import { useRemirrorContext } from '@remirror/react';

export const useResetCommentEditor = () => {
  const { manager } = useRemirrorContext();

  const resetState = useCallback(() => {
    const emptyState = manager.createState({ content: { type: 'doc', content: [{
      type: 'paragraph',
      content: [],
    }] } });
    manager.view.updateState(emptyState);
  }, [manager]);

  return resetState;
};
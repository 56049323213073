import { memo, useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { cx } from '@utils';
import { useImageState } from '@utils/hooks';
import { MaybeLink } from 'components/MaybeLink';
import { DefaultAvatar } from './Default';
import { UserAvatarProps } from './interfaces';
import { resolveImageSource } from './utils';
import styles from './style.css';

type Props =
  UserAvatarProps;

export const UserAvatar = memo((props: Props) => {

  return props.pictureUrl
    ? <Avatar {...props} />
    : <DefaultAvatar {...props} />
  ;

});

UserAvatar.displayName = 'UserAvatar';

const Avatar = memo((props: UserAvatarProps) => {

  const src = useMemo(() => {
    return resolveImageSource({
      resize: props.resize,
      size: props.size,
      pictureUrl: props.pictureUrl,
    });
  }, [
    props.pictureUrl,
    props.resize,
    props.size,
  ]);

  const img = useImageState({
    src,
    loadInDelay: 150,
  });

  if (img.state === 'error') {
    return (
      <DefaultAvatar
        className={props.className}
        onClick={props.onClick}
        to={props.to}
        size={props.size} />
    );
  }

  const rootStyle = {
    height: props.size,
    width: props.size,
  };

  return (
    <MaybeLink to={props.to}>
      <div
        style={rootStyle}
        className={cx(styles.root, props.className)}>
        {img.state === 'loading' &&
          <Skeleton
            className={styles.avatar}
            style={{
              height: '100%',
              width: '100%',
            }}
            animation="wave"
            variant="circular" />
        }
        <img
          {...img.props}
          draggable="false"
          className={cx(styles.avatar, {
            [styles.hidden]: img.state !== 'loaded',
          })}
          onClick={props.onClick} />
      </div>
    </MaybeLink>
  );
});

Avatar.displayName = 'Avatar';

export default UserAvatar;
import { forwardRef, useCallback } from 'react';
import { VICS as VICSEnum } from '@enums/VICS';
import { VICS } from '@/types/vics';
import { BaseCompany } from '@/types/companies';
import * as api from '@api';
import { useProjectFormContext, useProjectFormErrorContext } from './context';
import { AdditionalFilter } from './interfaces';
import { ProjectFilters } from './Filters';

export const ProjectFiltersContainer = forwardRef<HTMLInputElement>((_, ref) => {

  const [form, setFormState] = useProjectFormContext();
  const {
    clearErrors,
    setIndustryError,
    setSectorError,
    setSubIndustryError,
  } = useProjectFormErrorContext();

  const validateVICSFilter = useCallback((item: VICS) => {
    const { typeId } = item;
    const hasReachedLimit = filters.limit[typeId] === form.vics.filter(x => x.typeId === typeId).length;

    if (!hasReachedLimit) return true;

    switch (typeId) {
      case VICSEnum.Sector:
        setSectorError(`You may only select up to ${filters.limit[typeId]}`);
        return false;

      case VICSEnum.Industry:
        setIndustryError(`You may only select up to ${filters.limit[typeId]}`);
        return false;

      case VICSEnum.SubIndustry:
        setSubIndustryError(`You may only select up to ${filters.limit[typeId]}`);
        return false;
    }
  }, [
    form.vics,
    setIndustryError,
    setSectorError,
    setSubIndustryError,
  ]);

  const handleVICSItemAddedToFilter = useCallback((item: VICS) => {
    if (validateVICSFilter(item)) {
      clearErrors();

      setFormState({
        suggested: form.suggested.filter(x => x.id !== item.id),
        vics: form.vics.concat(item),
      });
    }
  }, [
    clearErrors,
    form.suggested,
    form.vics,
    setFormState,
    validateVICSFilter,
  ]);

  const handleAdditionalFilterAdded = useCallback((field: AdditionalFilter) => (item: BaseCompany) => {
    if (['currentCompanies', 'formerCompanies'].includes(field) && Number.isInteger(item.id)) {
      api.search.getCompanySectors({ ids: [item.id] })
      .then(results => {
        if (results.length) {
          const existingIds = [
            ...form.vics.map(x => x.id),
            ...form.suggested.map(x => x.id),
          ];
          const latestSuggested = results.filter(x => !existingIds.includes(x.id));

          setFormState({
            suggested: latestSuggested.concat(form.suggested),
          });
        }
      });
    }

    setFormState({
      // @ts-ignore
      [field]: form[field].concat(item),
    });
  }, [
    form,
    setFormState,
  ]);

  const handleAddFilter = useCallback((field: AdditionalFilter) => {
    switch (field) {
      case 'suggested':
      case 'vics':
        return handleVICSItemAddedToFilter;

      case 'currentCompanies':
      case 'formerCompanies':
      case 'titles':
      default:
        return handleAdditionalFilterAdded(field);
    }
  }, [
    handleAdditionalFilterAdded,
    handleVICSItemAddedToFilter,
  ]);

  const handleAdditionalFilterRemoved = useCallback((field: AdditionalFilter) => (item: FilterItem) => {
    setFormState({
      [field]: form[field].filter(f => f.id !== item.id),
    });
  }, [form, setFormState]);

  const getProjectFilterHandler = useCallback((field: AdditionalFilter) => {
    return {
      onAdd: handleAddFilter(field),
      onRemove: handleAdditionalFilterRemoved(field),
    };
  }, [handleAddFilter, handleAdditionalFilterRemoved]);

  return (
    <ProjectFilters
      ref={ref}
      filters={filters}
      getFilterHandler={getProjectFilterHandler} />
  );
});

const filters = {
  limit: {
    [VICSEnum.Industry]: 3,
    [VICSEnum.Sector]: 2,
    [VICSEnum.SubIndustry]: 5,
  },
};

type FilterItem = {
  id: number;
};
import { useQuery } from 'react-query';
import * as api from '@api';
import { useSelectUser } from '@containers/Store';

type Params = number;

export const useFetchConsultantReferralSourcingState = (projectId: Params) => {
  const user = useSelectUser();

  return useQuery(['fetch-consultant-referral-sourcing-state'], async () => {
    const response = await api.projects.pipeline.fetchConsultantReferralSourcingState({
      projectId,
      userId: user.id,
    });

    return { referrals: response.sourcing };

  }, { enabled: !!projectId });
};
import { useContext, useMemo } from 'react';
import { ProjectResponseFilterContext } from '@containers/GroupProject/Context';
import { filterRepeatResponses } from '@containers/GroupProject/utils/survey-filtering';
import { RepeatSurveyResponseDataContext } from '../Context';

const useRepeatSurveyData = () => {
  const context = useContext(RepeatSurveyResponseDataContext);
  const filterContext = useContext(ProjectResponseFilterContext);
  const filteredData = useMemo(() => {
    if (filterContext?.questionData && filterContext?.filter) {
      return filterRepeatResponses(filterContext.filter, filterContext.questionData, context);
    } else {
      return context;
    }
  }, [filterContext, context]);

  return filteredData;
};

export { useRepeatSurveyData };
export default useRepeatSurveyData;
import { forwardRef, useCallback, Ref } from 'react';
import Popper, { PopperProps } from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Link } from 'react-router-dom';
import { bindToggle, bindPopper } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { MoreHorizontalAnchorSmall } from '@presentation/Anchor';
import { getLocationFor, trunc } from '@utils';
import { WorkspaceObject } from '@/types';
import { PopperMenu, PopperMenuItem } from 'components/Popper';

type MenuProps = {
  items: WorkspaceObject[];
  onClose: () => unknown;
};

const FolderPathMenu = forwardRef(({ items, onClose }: MenuProps, ref: Ref<HTMLDivElement>) => {
  const renderItem = useCallback((item: WorkspaceObject) => {
    const to = getLocationFor.workspaces.folder({ folderId: item.entityId });

    return (
      <Link key={item.id} to={to} onClick={onClose}>
        <PopperMenuItem>
          {trunc(item.name, 50)}
        </PopperMenuItem>
      </Link>
    );

  }, [onClose]);

  return (
    <PopperMenu ref={ref}>
      {items.map(renderItem)}
    </PopperMenu>
  );
});

type Props = {
  items: WorkspaceObject[];
} & Pick<PopperProps, 'placement'>;

const FolderPathPopper = ({
  items,
  placement = 'bottom-end',
}: Props) => {

  const popupState = usePopupState({
    popupId: 'folder-path-popper',
    variant: 'popper',
  });

  return (
    <div>
      <div {...bindToggle(popupState)}>
        <MoreHorizontalAnchorSmall open={popupState.isOpen} />
      </div>
      <Popper {...bindPopper(popupState)} placement={placement} style={{ zIndex: 2 }}>
        <ClickAwayListener
          onClickAway={popupState.close}>
          <FolderPathMenu
            items={items}
            onClose={popupState.close} />
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default FolderPathPopper;
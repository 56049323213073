import * as xform from '@transformers/helpers';
import { titlecase } from '@transformers/keywords';
import { safeJsonDate } from '@utils';
import { NewsArticle } from '@/types';

const normalizeNewsItem = (item: NewsArticle<string>) => {
  const runTransforms = xform.compose(
    normalizeItemDates,
    normalizeKeywords,
  );

  return runTransforms(item);
};

const normalizeNewsItems = (items: NewsArticle<string>[]): NewsArticle[] => {
  return items
    .map(normalizeNewsItem);
};

function normalizeItemDates(item: NewsArticle<string>) {
  return {
    ...item,
    publishDate: safeJsonDate(item.publishDate),
  };
}

function normalizeKeywords(item: NewsArticle) {
  return {
    ...item,
    keywords: item.keywords.map(k => ({
      id: k.id,
      name: titlecase(k.name),
    })),
  };
}

export {
  normalizeNewsItem,
  normalizeNewsItems,
};

export default {
  normalizeNewsItem,
  normalizeNewsItems,
};
import { SurveyBuilder, SurveyQuotasBuilder } from '../interfaces';
import * as $actions from './state.quotas';

export function quotasReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyQuotasBuilder.State {
  const quotas = state.survey.quotas;

  switch (action.type) {
    case 'add-quota-item': {
      return [
        ...quotas,
        $actions.getDefaultQuotaItem(action),
      ];
    }

    case 'remove-quota-item': {
      return quotas.filter(f => f.identifier !== action.identifier);
    }

    case 'save-quota-item': {
      return quotas.reduce((acc, x) => {
        if (x.identifier === action.value.identifier) {
          acc.push(action.value);
        } else {
          acc.push(x);
        }

        return acc;
      }, []);
    }

    case 'toggle-quota-editing-off': {
      return state.editing.quota.isNew
        ? quotas.filter(f => f.identifier !== action.identifier)
        : quotas;
    }

    case 'revert-uncommitted-changes':
      return state.committed.quotas;

    // side effects
    case 'remove-question-option':
      return $actions.optionRemoved(state.survey.quotas, action);

    case 'remove-question':
      return $actions.questionRemoved(state.survey.quotas, action);

    case 'remove-section':
      return $actions.sectionRemoved(state, action);

    case 'update-question-identifier':
      return $actions.questionIdentifierUpdated(state.survey.quotas, action);

    default:
      return quotas;
  }
}
import { useCallback } from 'react';
import { MinusCircle } from 'react-feather';
import { useQuestionLogicBuilderState } from '@containers/SurveyBuilder.Logic/hooks';
import { useMatrixRowLogicContext } from '@containers/SurveyBuilder.Logic/Context';
import {
  ActionOrdinalDropdown,
  ActionTypeDropdown,
  AddLogicButton,
  ConditionTypeDropdown,
  OptionsDropdown,
} from '@presentation/SurveyBuilder';
import { cx } from '@utils';
import { MatrixGridQuestion } from '@/types';
import styles from './style/MatrixLogicBuilder.css';

export const MatrixOptionsLogicBuilder = () => {
  const {
    addItem,
    canAddItem,
    items,
    removeItem,
    updateActionIdentifier,
    updateActionType,
    updateConditionValue,
    updateConditionType,
  } = useMatrixRowLogicContext();
  const context = useQuestionLogicBuilderState();

  const renderOption = useCallback((item: MatrixGridQuestion.Logic.Response) => {
    const option = context.item.options
      .find(f => f.base.identifier === item.condition.value.option.identifier);

    return (
      <div className={styles.row} key={item.id}>
        <div className={styles.condition}>
          <ConditionTypeDropdown
            onSelect={updateConditionType(item.id)}
            value={item.condition.type} />
        </div>
        <div className={styles.choice}>
          <OptionsDropdown
            items={context.item.options}
            onSelect={updateConditionValue(item.id)}
            value={option} />
        </div>
        <div className={styles.action}>
          <ActionTypeDropdown
            items={context.responseActionTypes}
            onSelect={updateActionType(item.id)}
            value={item.action.type} />
        </div>
        <div className={styles.ordinal}>
          <ActionOrdinalDropdown
            action={item.action}
            updateActionIdentifier={updateActionIdentifier(item.id)} />
        </div>
        <MinusCircle
          className={styles.remove}
          onClick={removeItem(item.id)} />
      </div>
    );
  }, [
    context.responseActionTypes,
    context.item.options,
    updateActionIdentifier,
    removeItem,
    updateActionType,
    updateConditionValue,
    updateConditionType,
  ]);

  return (
    <div>
      {!!items.length &&
        <div className={styles.headerRow}>
          <div className={cx(styles.header, styles.condition)}>Condition</div>
          <div className={cx(styles.header, styles.choice)}>Answer Choice</div>
          <div className={cx(styles.header, styles.action)}>Action</div>
          <div className={styles.ordinal} />
        </div>
      }
      {items.map(renderOption)}
      {canAddItem &&
        <AddLogicButton
          className={styles.add}
          onClick={addItem} />
      }
    </div>
  );
};

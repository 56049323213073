import { useEffect } from 'react';
import { useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks/useRepeatSurveyResponsesState';
import { useAccordionMap } from 'components/Accordion/hooks/useAccordionMap';

type Field =
  | 'attributeIds'
  | 'choices'
  | 'optionIds'
  | 'rowIds';

export const useQueryAccordionMap = (field: Field) => {
  const { query } = useRepeatSurveyResponsesState();
  const [isAccordionOpen, toggleAccordion, reset] = useAccordionMap(query[field]);

  useEffect(() => {
    reset(query[field]);
  }, [
    field,
    query,
    reset,
  ]);

  return [isAccordionOpen, toggleAccordion] as const;
};

export default useQueryAccordionMap;
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { path, pathname } from '@consts';
import { ButtonActivityIndicator } from '@presentation';
import { cx } from '@utils';
import { AuthButton, AuthForm } from '$website/components';
import styles from '$website/components/AuthModal/style/AuthModal.css';
import { AuthContainer, PersistentLinkState, SocialAuthContainer } from '$website/containers';
import { useLoginSubmit, useSocialAuth } from '$website/containers/Auth/hooks';
import { LoginError } from '$website/screens/Auth/LoginError';
import { Logo } from 'components/Branding';

const { Layout } = AuthForm;

type Props = unknown;

const LoginVisitor = (props: Props) => {
  return (
    <AuthContainer>
      <SocialAuthContainer>
        <Form />
      </SocialAuthContainer>
    </AuthContainer>
  );
};

const Form = (props: Props) => {
  const [form, submit] = useLoginSubmit();
  const social = useSocialAuth();

  const handleEmail = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit();
  }, [submit]);

  return (
    <div className={cx(styles.page, styles.wrap)}>

      <Layout.Header>
        <Layout.Row>
          <Link to={path.Website.Root}>
            <Logo size={55} />
          </Link>
        </Layout.Row>

        <Layout.Row>
          <Layout.Title>Log In to Vancery</Layout.Title>
        </Layout.Row>

        {/* <Layout.Row className={styles.row}>
          <Layout.Subtitle>
            <span>or </span>
            <Link
              className={styles.link}
              to={path.Website.Signup.Root}>
              sign up
            </Link>
          </Layout.Subtitle>
        </Layout.Row> */}
      </Layout.Header>

      <div className={styles.body}>
        <Layout.Row>
          <AuthButton.Google onClick={social.authorizeGoogle}>
            Log in with Google
          </AuthButton.Google>
        </Layout.Row>

        <Layout.Row>
          <AuthButton.Facebook onClick={social.authorizeFacebook}>
            Log in with Facebook
          </AuthButton.Facebook>
        </Layout.Row>

        <Layout.Row>
          <AuthButton.Apple onClick={social.authorizeApple}>
            Log in with Apple
          </AuthButton.Apple>
        </Layout.Row>

        <Layout.Row>
          <Layout.ThematicBreak>
            or
          </Layout.ThematicBreak>
        </Layout.Row>

        <form onSubmit={handleEmail}>
          <AuthForm.Email.Login className={cx(styles.visitor, styles.row, styles.input)} />

          <Layout.Row>
            <div className={styles.forgot}>
              <Link to={pathname.FORGOT_PASSWORD}>
                <span>Forgot your password?</span>
              </Link>
            </div>
          </Layout.Row>

          <div className={cx(styles.login, styles.row)}>
            <LoginError className={cx(styles.error)} />
          </div>

          <Layout.Row className={styles.wrap}>
            <ButtonActivityIndicator
              className={styles.submit}
              loading={form.loading}>
              Log In
            </ButtonActivityIndicator>
          </Layout.Row>
          <Layout.Row>
            <div className={styles.otherStrategy}>
              <Link to={path.Website.EnterpriseLogin}>
                <span>Looking for enterprise login?</span>
              </Link>
            </div>
          </Layout.Row>
        </form>
      </div>

    </div>
  );
};

export const Visitor = PersistentLinkState(LoginVisitor, 'Login.Visitor');
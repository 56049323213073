import { SurveyVersion } from '@/types';

export namespace SurveyBuilderEditing {

  export type Action =
    ToggleEditing.Action |
    ToggleLogicEditing.Action |
    ResetSurvey.Action |
    RevertUncommittedChanges.Action
  ;

  export namespace ToggleEditing {
    export type State = {
      ordinal: number;
    };
    export type Action = {
      type: 'toggle-editing';
    } & State;
  }

  export namespace ToggleLogicEditing {
    export type State = {
      ordinal: number;
    };
    export type Action = {
      type: 'toggle-logic-editing';
    } & State;
  }

  export namespace ResetSurvey {
    export type State = {
      value: SurveyVersion;
    };
    export type Action = {
      type: 'reset-survey';
    } & State;
  }

  export namespace RevertUncommittedChanges {
    export type Action = {
      type: 'revert-uncommitted-changes';
    };
  }
}
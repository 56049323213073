import { useMemo, useCallback } from 'react';
import * as consts from '@consts';
import { useScreenerBuilderContext } from '@containers/SurveyBuilder/Context';
import { AddQuestionButton, SurveyQuestionNumber } from '@presentation';
import { TextEntryList } from 'components/TextEntryList';
import { TextEntryItem } from 'components/TextEntryList/interfaces';
import styles from './style/Builder.css';

const ScreenerBuilder = () => {
  function EmptyForm() {
    return (
      <div className={styles.placeholder}>
        No additional screening questions are required
      </div>
    );
  }

  const {
    addQuestion,
    questions,
    removeQuestion,
    updateQuestion,
  } = useScreenerBuilderContext();

  const items: TextEntryItem[] = useMemo(() => {
    return questions.map((q, i) => ({
      key: i + 1,
      value: q,
    }));
  }, [questions]);

  const renderAddItem = useCallback(() => {
    if (questions.length >= consts.surveys.MaxProjectScreenerQuestions) {
      return null;
    }
    return (
      <AddQuestionButton
        className={styles.add}
        onClick={addQuestion} />
    );
  }, [questions.length, addQuestion]);

  const renderItemLabel = useCallback((item: TextEntryItem) => {
    return (
      <SurveyQuestionNumber
        className={styles.screenerOrdinal}
        ordinal={+item.key} />
    );
  }, []);

  const removeItem = useCallback((ordinal: number) => () => {
    removeQuestion(ordinal - 1)();
  }, [removeQuestion]);

  const updateItemValue = useCallback((ordinal: number) => (value: string) => {
    updateQuestion(ordinal - 1)(value);
  }, [updateQuestion]);

  return (
    <>
      {!questions.length && <EmptyForm />}
      <TextEntryList
        placeholder="Add Question"
        items={items}
        removeItem={removeItem}
        removeItemEnabled={true}
        renderAddItemButton={renderAddItem}
        renderLabel={renderItemLabel}
        updateItemValue={updateItemValue} />
    </>
  );
};

export { ScreenerBuilder };
export default ScreenerBuilder;
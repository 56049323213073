import { Flag } from 'react-feather';
import { MultiselectAnswerValueProps } from './interfaces';
import styles from './style/Multiselect.css';

type Props =
  MultiselectAnswerValueProps;

export const MultiselectAnswerValue = ({ answer }: Props) => {
  return (
    <>
      {answer.items.map(({ option: o }) => {
        return (
          <div className={styles.root} key={o.id}>
            {o.text ? `${o.value} - ${o.text}` : o.value}
            {o.flagged && <Flag className={styles.flag} />}
          </div>
        );
      })}
    </>
  );
};

export default MultiselectAnswerValue;
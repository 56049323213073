import { Fragment } from 'react';
import { cx } from '@utils';
import confetti from 'static/images/website/confetti-fullscreen.png';
import confettiMobile from 'static/images/website/confetti-fullscreen-mobile.png';
import confettiTablet from 'static/images/website/confetti-fullscreen-tablet.png';
import styles from '$website/screens/Main/style/WhatPeopleAreSaying.css';
import {
  enterpriseItems,
  individualsItems,
} from './items';
import {
  Props,
  Endorsement,
} from './interfaces';

const WhatPeopleAreSaying = (props: Props) => (
  <div className={styles.main}>
    <h2 className={styles.headline}>What people are saying...</h2>
    <div className={styles.cardContainer}>
      <div className={styles.endorsements}>
        {props.items.map(m => (
          <Fragment key={m.name}>
            {props.renderEndorsement(m)}
          </Fragment>
        ))}
      </div>
      <picture>
        <source
          srcSet={confetti}
          media="(min-width: 1100px)" />
        <source
          srcSet={confettiTablet}
          media="(min-width: 800px)" />
        <img
          className={styles.bg}
          src={confettiMobile} />
      </picture>
    </div>
  </div>
);

export const EnterpriseWPAS = () => {
  const renderEndorsement = (item: Endorsement) => (
    <div className={cx(styles.cardRoot, item.className)}>
      <div className={styles.card}>
        <div className={styles.quote}>{item.quote}</div>
        <div className={styles.name}>{item.name}</div>
        <div className={styles.role}>{`${item.title} at ${item.company}`}</div>
      </div>
    </div>
  );

  return (
    <WhatPeopleAreSaying
      items={enterpriseItems}
      renderEndorsement={renderEndorsement} />
  );
};

export const IndividualsWPAS = () => {
  const renderEndorsement = (item: Endorsement) => (
    <div className={cx(styles.cardRoot, item.className)}>
      <div className={styles.card}>
        <div className={styles.quote}>{item.quote}</div>
        <div className={styles.endorser}>
          <img
            className={styles.img}
            src={item.img} />
          <div>
            <div className={styles.name}>{item.name}</div>
            <div className={styles.title}>{item.title},</div>
            <div className={styles.company}>{item.company}</div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <WhatPeopleAreSaying
      items={individualsItems}
      renderEndorsement={renderEndorsement} />
  );
};
import { useContext } from 'react';
import { ReactFrameworkOutput } from '@remirror/react';
import { createNamedContext } from '@utils';
import { TranscriptExtensions, TranscriptHelpers } from './interfaces';

type TranscriptCommands =
  ReactFrameworkOutput<TranscriptExtensions>['chain'];

// These are mainly for using inside of comments, which get wraped in another remirror context
export const TranscriptChainedCommandsContext = createNamedContext<TranscriptCommands>(undefined, 'TranscriptCommandsContext');
export const TranscriptHelpersContext = createNamedContext<TranscriptHelpers.Helpers>(undefined, 'TranscriptHelpersContext');

export const useTranscriptChainedCommands = () => useContext(TranscriptChainedCommandsContext);
export const useTranscriptHelpers = () => useContext(TranscriptHelpersContext);
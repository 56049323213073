import { useCallback, useMemo } from 'react';
import { useParams, Redirect, Route, Switch } from 'react-router-dom';
import { TabView } from 'components/TabView';
import { useTabViewState } from 'components/TabView/hooks/useTabViewState';
import { FolderRouteParams, FolderSceneMap, FolderRouteKey, TabScene } from './interfaces';

export const FolderTabView = ({ sceneMap: map }: { sceneMap: FolderSceneMap; }) => {

  const { folderId } = useParams<FolderRouteParams>();
  const keys = useMemo(() => Object.keys(map) as FolderRouteKey[], [map]);
  const routes = useMemo(() => keys.map(k => ({ key: k, ...map[k] })), [keys, map]);

  const formatPath = useCallback((tab: FolderRouteKey) => {
    return `/workspaces/folders/${folderId}/${tab}`;
  }, [folderId]);

  const [{ index }, updateLocation] = useTabViewState<FolderRouteKey>({
    formatPath,
    routes: keys,
  });

  const renderTabView = useCallback(({ route }: { route: TabScene; }) => {
    const defaultRoute = formatPath(FolderRouteKey.Projects);

    return (
      <Switch>
        {keys.map((key: FolderRouteKey) =>
          <Route
            key={key}
            path={map[key].path}
            component={map[key].component} />)}
        <Redirect to={defaultRoute} />
      </Switch>
    );
  }, [
    formatPath,
    keys,
    map,
  ]);

  return (
    <TabView
      index={index}
      onIndexChange={updateLocation}
      routes={routes}
      renderTabView={renderTabView} />
  );
};

export default FolderTabView;
import http from '@services/http';
import * as xform from '@transformers';
import { WorkspaceFiles as WS } from './interfaces';

export const deleteFile = (data: WS.DeleteFile.Request) => {
  return http.delete(`/workspaces/${data.workspaceId}/files/${data.fileId}`);
};

export const downloadFile = (data: WS.DownloadFile.Request): Promise<WS.DownloadFile.Response> => {
  return http.get(`/workspaces/${data.workspaceId}/files/${data.fileId}/download`);
};

export const renameFile = (data: WS.RenameFile.Request) => {
  return http.patch(`/workspaces/${data.workspaceId}/files/${data.fileId}/name`, {
    name: data.name,
  }).then((data: WS.RenameFile.Response) => ({
    file: xform.normalizeFile(data.file),
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};

export const getFilePreview = (data: WS.GetFilePreview.Request): Promise<WS.GetFilePreview.Response> => {
  return http.get(`/workspaces/${data.workspaceId}/files/${data.fileId}/preview`);
};

export const getFileProjects = (data: WS.GetFileProjects.Request): Promise<WS.GetFileProjects.Response> => {
  return http.get(`/workspaces/${data.workspaceId}/files/${data.fileId}/projects`);
};

export const saveFileProjects = ({ fileId, workspaceId, ...body }: WS.SaveFileProjects.Request): Promise<WS.SaveFileProjects.Response> => {
  return http.post(`/workspaces/${workspaceId}/files/${fileId}/projects`, body);
};
import { useCallback, useContext, useMemo } from 'react';
import { ConjointAnalysisSurveyResponsesContainer } from '@containers/SurveyResponses/ConjointAnalysisContainer';
import { MaxDiffSurveyResponsesContainer } from '@containers/SurveyResponses/MaxDiffContainer';
import { useSurveyQuestionResponsesData } from '@containers/SurveyResponses/hooks/useSurveyQuestionResponsesData';
import { BrandColorCategory, SurveyQuestionType } from '@enums';
import { SurveyAggregate } from '@/types';
import { Spinner } from 'components/ActivityIndicator';
import styles from './style/QuestionResponses.css';
import { ResponsesEmpty, NotEnoughResponses } from './ResponsesEmpty';
import ResponseOptions from './ResponseOptions';
import ResponseRanking from './ResponseRanking';
import ResponseText from './ResponseText';
import MatrixGridResponses from './MatrixGrid';
import MatrixMultiselectResponses from './MatrixMultiselect';
import MatrixSliderResponses from './MatrixSlider';
import ConjointAnalysisResponses from './ConjointAnalysis';
import MaxDiffResponses from './MaxDiff';
import NumberInputTableResponses from './NumberInputTable';

type ConditionalProps = {
  hasResponses: boolean;
  emptyElement?: () => JSX.Element;
} & ChildrenProps;

const $HasResponses = (props: ConditionalProps) => {
  if (props.hasResponses) {
    return <>{props.children}</>;
  } else {
    if (props.emptyElement) {
      return <props.emptyElement />;
    } else {
      return <ResponsesEmpty />;
    }
  }
};

const QuestionResponses = () => {
  const { question, responses, total } = useSurveyQuestionResponsesData();

  const hasResponses = useMemo(() => {
    if (question.typeId === SurveyQuestionType.ConjointAnalysis) {
      return total > 5;
    }

    return total > 0;
  }, [
    question.typeId,
    total,
  ]);

  if (!responses) {
    return (
      <div className={styles.loading}>
        <Spinner />
      </div>
    );
  }

  switch (question.typeId) {
    case SurveyQuestionType.Multiselect:
    case SurveyQuestionType.MultipleChoice: {
      assertResponses<SurveyQuestionType.Multiselect | SurveyQuestionType.MultipleChoice>(responses);

      return (
        <$HasResponses hasResponses={hasResponses}>
          <ResponseOptions
            key={question.id}
            question={question}
            responses={responses} />
        </$HasResponses>
      );
    }

    case SurveyQuestionType.Ranking: {
      assertResponses<SurveyQuestionType.Ranking>(responses);

      return (
        <$HasResponses hasResponses={hasResponses}>
          <ResponseRanking
            key={question.id}
            question={question}
            responses={responses} />
        </$HasResponses>
      );
    }

    case SurveyQuestionType.LongTextResponse:
    case SurveyQuestionType.ShortTextResponse: {
      assertResponses<SurveyQuestionType.LongTextResponse | SurveyQuestionType.ShortTextResponse>(responses);

      return (
        <$HasResponses hasResponses={hasResponses}>
          <ResponseText
            key={question.id}
            question={question}
            responses={responses} />
        </$HasResponses>
      );
    }

    case SurveyQuestionType.Sliders: {
      assertResponses<SurveyQuestionType.Sliders>(responses);
      return (
        <$HasResponses hasResponses={hasResponses}>
          <MatrixSliderResponses
            key={question.id}
            question={question}
            responses={responses} />
        </$HasResponses>
      );
    }

    case SurveyQuestionType.MatrixGrid: {
      assertResponses<SurveyQuestionType.MatrixGrid>(responses);
      return (
        <$HasResponses hasResponses={hasResponses}>
          <MatrixGridResponses
            key={question.id}
            question={question}
            responses={responses} />
        </$HasResponses>
      );
    }

    case SurveyQuestionType.MatrixMultiselect: {
      assertResponses<SurveyQuestionType.MatrixMultiselect>(responses);
      return (
        <$HasResponses hasResponses={hasResponses}>
          <MatrixMultiselectResponses
            key={question.id}
            question={question}
            responses={responses} />
        </$HasResponses>
      );
    }

    case SurveyQuestionType.ConjointAnalysis: {
      assertResponses<SurveyQuestionType.ConjointAnalysis>(responses);

      return (
        <$HasResponses
          hasResponses={hasResponses}
          emptyElement={NotEnoughResponses}>
          <ConjointAnalysisSurveyResponsesContainer key={question.id}>
            <ConjointAnalysisResponses  />
          </ConjointAnalysisSurveyResponsesContainer>
        </$HasResponses>
      );
    }

    case SurveyQuestionType.MaxDifference: {
      assertResponses<SurveyQuestionType.MaxDifference>(responses);

      return (
        <$HasResponses
          hasResponses={hasResponses}>
          <MaxDiffSurveyResponsesContainer key={question.id}>
            <MaxDiffResponses />
          </MaxDiffSurveyResponsesContainer>
        </$HasResponses>
      );
    }

    case SurveyQuestionType.NumberInputTable: {
      assertResponses<SurveyQuestionType.NumberInputTable>(responses);
      return (
        <$HasResponses
          hasResponses={hasResponses}>
          <NumberInputTableResponses
            key={question.id} />
        </$HasResponses>
      );
    }

    default:
      return null;
  }
};

function assertResponses<T extends SurveyQuestionType = SurveyQuestionType>(data: SurveyAggregate.QuestionData): asserts data is SurveyAggregate.QuestionData<T> { }

export { QuestionResponses };
export default QuestionResponses;
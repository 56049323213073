import { memo } from 'react';
import * as Menu from './Menu';
import * as Nav from './Nav';
import styles from './style/Navbar.css';

type Props = unknown;

export const Navbar = (props: Props) => {
  return (
    <Menu.Container>
      <Navbar.LayoutRoot>
        <div className={styles.navbar}>
          <Nav.Logo />
          <Nav.Links />
          <div className={styles.nav}>
            <Nav.Actions />
            <Menu.Hamburger />
          </div>
        </div>
      </Navbar.LayoutRoot>
      <Menu.Drawer />
    </Menu.Container>
  );
};

type LayoutRootProps = {
  children?: React.ReactNode;
  className?: string;
};

const LayoutRoot = (props: LayoutRootProps) => {
  return (
    <>
      <header className={styles.root}>
        <div className={styles.wrap}>
          {props.children}
        </div>
        <Trinity />
      </header>
      <div className={styles.pad} />
    </>
  );
};

const Trinity = memo(() => {
  return (
    <div style={{
      backgroundColor: 'rgba(204,223,252,1)',
    }}>
      <div
        className={styles.wrap}
        style={{
          color: 'var(--pri-02)',
          fontSize: '16px',
          fontFamily: 'var(--font-bold)',
          backgroundColor: 'rgba(204,223,252,1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div>
          <a
            href="https://www.businesswire.com/news/home/20220804005069/en/Trinity-Life-Sciences-Acquires-Vancery%E2%80%99s-Customer-Research-Technology"
            target="_blank"
            rel="noreferrer">
            {`Trinity Life Sciences Acquires Vancery's Customer Research Technology`}
          </a>
        </div>
        <div>
          <a
            href="https://www.businesswire.com/news/home/20220804005069/en/Trinity-Life-Sciences-Acquires-Vancery%E2%80%99s-Customer-Research-Technology"
            target="_blank"
            rel="noreferrer">
            {`Learn More Here >`}
          </a>
        </div>
      </div>
    </div>
  );
});

LayoutRoot.displayName = 'Navbar.LayoutRoot';

Navbar.LayoutRoot = LayoutRoot;
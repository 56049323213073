import { SurveyQuotaType, SurveyQuestionType } from '@enums/Survey';
import { SurveyQuota } from '@/types';
import {
  getDefaultAdvancedSet,
  getDefaultBasicItem,
} from '@containers/SurveyBuilder.Quota/utils';
import {
  SurveyBuilder,
  SurveyQuotasBuilder,
  SurveyQuestionsBuilder,
  SurveySectionsBuilder,
  SurveyOptionsBuilder,
} from '../interfaces';

export function getDefaultQuotaItem(action: SurveyQuotasBuilder.AddItem.Action): SurveyQuota.Record {
  const common = {
    actionType: null,
    id: null,
    identifier: action.identifier,
    limit: 0,
    name: '',
  };

  if (action.quotaType === SurveyQuotaType.Advanced) {
    return {
      ...common,
      data: {
        sets: [getDefaultAdvancedSet()],
      },
      type: SurveyQuotaType.Advanced,
    };
  } else if (action.quotaType === SurveyQuotaType.Basic) {
    return {
      ...common,
      data: {
        items: [getDefaultBasicItem()],
      },
      type: SurveyQuotaType.Basic,
    };
  }
}

export function handleRemovedQuestions(state: SurveyQuotasBuilder.State, identifiers: string[]): SurveyQuotasBuilder.State {
  return state.reduce<SurveyQuotasBuilder.State>((acc, quota) => {
    if (quota.type === SurveyQuotaType.Advanced) {
      const updatedSets = quota.data.sets
        .filter(f => !identifiers.includes(f.question.identifier));

      acc.push({
        ...quota,
        data: {
          sets: updatedSets,
        },
      });
    } else if (quota.type === SurveyQuotaType.Basic) {
      const updatedItems = quota.data.items
        .filter(f => !identifiers.includes(f.question.identifier));

      acc.push({
        ...quota,
        data: {
          items: updatedItems,
        },
      });
    }

    return acc;
  }, []);
}

export function questionRemoved(state: SurveyQuotasBuilder.State, action: SurveyQuestionsBuilder.RemoveQuestion.Action): SurveyQuotasBuilder.State {

  return handleRemovedQuestions(state, [action.identifier]);
}

export function optionRemoved(state: SurveyQuotasBuilder.State, action: SurveyOptionsBuilder.RemoveOption.Action): SurveyQuotasBuilder.State {
  return state.reduce<SurveyQuotasBuilder.State>((acc, quota) => {
    if (quota.type === SurveyQuotaType.Advanced) {
      const updatedSets = quota.data.sets.reduce<SurveyQuota.Advanced.Set[]>((acc2, x) => {
        const items = x.items
          .filter(f => f.condition.value.option.identifier !== action.option.identifier);

        acc2.push({
          ...x,
          items,
        });

        return acc2;
      }, []);

      acc.push({
        ...quota,
        data: {
          sets: updatedSets,
        },
      });
    } else if (quota.type === SurveyQuotaType.Basic) {
      const updatedItems = quota.data.items
        .filter(f => f.condition.value.option.identifier !== action.option.identifier);

      acc.push({
        ...quota,
        data: {
          items: updatedItems,
        },
      });
    }

    return acc;
  }, []);
}

export function sectionRemoved(state: SurveyBuilder.State, action: SurveySectionsBuilder.RemoveSection.Action): SurveyQuotasBuilder.State {
  const badIdentifiers = state.survey.questions
    .filter(f => f.section.identifier === action.identifier)
    .map(m => m.base.identifier);

  return handleRemovedQuestions(state.survey.quotas, badIdentifiers);
}

export function questionIdentifierUpdated(state: SurveyQuotasBuilder.State, action: SurveyQuestionsBuilder.UpdateQuestionIdentifier.Action): SurveyQuotasBuilder.State {
  return handleRemovedQuestions(state, [action.oldIdentifier]);
}

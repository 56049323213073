import http from '@services/http';
import qs from 'query-string';
import * as xform from '@transformers';
import { Workspaces as AdminWS } from '$admin/api/interfaces';

export const getWorkspaceObjects = ({ workspaceId, ...data }: AdminWS.GetWorkspaceObjects.Request) => {
  return http.get(`/admin/workspaces/${workspaceId}/objects?${qs.stringify(data, { arrayFormat: 'bracket' })}`)
    .then((data: AdminWS.GetWorkspaceObjects.Response) => ({
      objects: data.objects.map(xform.normalizeWorkspaceObject),
    }))
};

export * as object from './workspaces.object';
import { selectObjectChildren } from './objects';

export const selectFolder = (folderId: number) => (state: Store.State) => state.folders.values[folderId];
export const selectGetFolder = (state: Store.State) => (folderId: number) => state.folders.values[folderId];

export const selectFolderValues = (state: Store.State) => state.folders.values;
export const selectFolderIds = (state: Store.State) => state.folders.ids;

export const selectFolderChildren = (folderId: number) => (state: Store.State) =>  {
  const folder = state.folders.values[folderId];
  return selectObjectChildren(folder.objectId)(state);
};
import { X } from 'react-feather';
import * as Toast from './Toast.Base';
import { ToastComponentProps } from './interfaces';
import styles from './style.css';

type Props = ToastComponentProps;

export const Error = (props: Props) => {
  return (
    <Toast.Base
      Icon={X}
      iconClassName={styles.error}
      body={props.body}
      title={props.title} />
  );
};

Error.displayName = 'Toast.Error';
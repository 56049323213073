import { Confirmations } from 'components/Calendar/Confirmations';
import { SchedulingSelectTimeComponentProps } from './interfaces';

type Props =
  SchedulingSelectTimeComponentProps;

export const Selecting = (props: Props) => {

  const screenProps = {
    call: { id: props.callId },
    scheduler: { id: props.schedulerId },
    with: { id: props.schedulerId },
  };

  return (
    <Confirmations
      call={screenProps.call}
      onSubmit={props.onSubmit}
      scheduler={screenProps.scheduler}
      with={screenProps.with} />
  );
};

export default Selecting;
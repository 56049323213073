import { AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { Calls } from '@api/interfaces';
import { Highlight } from '@/types/transcribe.rich-text';
import { useTranscriptHighlightActions, useTranscriptComments } from '@containers/Transcript';
import { useChainedCommands } from './useCommands';

type Data = Calls.RemoveTranscriptHighlight.Response;
type Variables = {
  highlight: Highlight;
};
type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

export function useRemoveHighlight(options?: Options) {

  const { removeHighlight } = useTranscriptHighlightActions();
  const commands = useChainedCommands();
  const [_, dispatch] = useTranscriptComments();

  const remove = useMutation(['remove-transcript-highlight'], removeHighlight, {
    ...options,
    onMutate: v => {
      commands
        .blurHighlight()
        .removeHighlights([v.highlight.id])
        .run();
    },
    onSuccess: (r, v, ctx) => {
      dispatch({
        type: 'highlight-removed',
        highlightIdentifier: v.highlight.id,
      });

      options?.onSuccess?.(r, v, ctx);
    },
    onError: (e, v) => {
      commands
        .addHighlight(v.highlight)
        .run();
    },
  });

  return remove;
}
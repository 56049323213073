import { useCallback, useRef, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import * as api from '@api';
import { useDebounceCallback } from '@utils';
import Autocomplete from 'components/AutoComplete';
import { ProfileAutocomplete as PA } from './interfaces';

export const ProfileFormAutocomplete = ({
  onSelect,
  ...props
}: PA.Props) => {
  const [results, setResults] = useState<PA.AutocompleteItem[]>([]);
  const cancelSourceRef = useRef<CancelTokenSource>();

  const fetchResults = useCallback((t: string) => {
    cancelSourceRef.current = Axios.CancelToken.source();

    return api.search.getAutocompleteResults({
      ignoreList: props.ignoreList,
      text: t,
      type: props.type,
    }).then(data => {
      const items = data.map(m => ({
        id: m.id,
        name: m.name,
      }));

      setResults(items);
    });
  }, [
    props.ignoreList,
    props.type,
  ]);

  const fetchDebounced = useDebounceCallback(fetchResults);

  const handleInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;

    onSelect({
      id: null,
      name: keyword,
    });

    if (!keyword) return setResults([]);

    return fetchDebounced(keyword);

  }, [
    fetchDebounced,
    onSelect,
  ]);

  return (
    <Autocomplete
      autoFocus={props.autoFocus}
      getItemValue={(v: PA.AutocompleteItem) => v.name}
      item={props.value}
      items={results}
      name={props.name}
      onChange={handleInput}
      onSelect={onSelect}
      placeholder={props.placeholder}
      value={props.value?.name || ''} />
  );
};

export default ProfileFormAutocomplete;
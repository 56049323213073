import { SurveyQuestionType } from '@enums';
import { QuestionRawText } from '@presentation/Survey';
import { formatPlurality } from '@utils';
import { SurveyFormQuestion, MultiselectQuestion, MatrixMultiselectQuestion } from '@/types';
import styles from './style/QuestionText.css';

type Props = {
  item: SurveyFormQuestion;
  textClassName?: string;
};

type HelpTextProps = {
  item: SurveyFormQuestion;
};

function multiselectHelpText(item: MultiselectQuestion.FormQuestion) {
  const { maximumSelections, minimumSelections } = item.settings;

  if (maximumSelections && minimumSelections) {
    if (minimumSelections === maximumSelections) {
      return `Select ${minimumSelections} ${formatPlurality('option', minimumSelections)}`;
    } else {
      return `Select at least ${minimumSelections} and at most ${maximumSelections} options`;
    }
  }

  if (minimumSelections) {
    return `Select at least ${minimumSelections} ${formatPlurality('option', minimumSelections)}`;
  }

  if (maximumSelections) {
    return `Select at most ${maximumSelections} ${formatPlurality('option', maximumSelections)}`;
  }

  return 'Select all that apply';
}

function matrixMultiselectHelpText(item: MatrixMultiselectQuestion.FormQuestion) {
  const { maximumSelections, minimumSelections } = item.settings;

  if (maximumSelections && minimumSelections) {
    if (minimumSelections === maximumSelections) {
      return `Select ${minimumSelections} ${formatPlurality('option', minimumSelections)} for each row`;
    } else {
      return `Select at least ${minimumSelections} and at most ${maximumSelections} options for each row`;
    }
  }

  if (minimumSelections) {
    return `Select at least ${minimumSelections} ${formatPlurality('option', minimumSelections)} for each row`;
  }

  if (maximumSelections) {
    return `Select at most ${maximumSelections} ${formatPlurality('option', maximumSelections)} for each row`;
  }

  return 'Select all that apply for each row';
}

function computeHelpText(item: SurveyFormQuestion) {
  switch (item.typeId) {
    case SurveyQuestionType.Multiselect:
      return multiselectHelpText(item);

    case SurveyQuestionType.MatrixMultiselect:
      return matrixMultiselectHelpText(item);

    default:
      return null;
  }
}

export function QuestionHelpText({ item }: HelpTextProps) {
  const helpText = computeHelpText(item);

  if (!helpText) return null;

  return <div className={styles.helpText}>{helpText}</div>;
}

export function FormQuestionText({ item, textClassName }: Props) {

  return (
    <QuestionRawText
      item={item}
      textClassName={textClassName}>
      <QuestionHelpText item={item} />
    </QuestionRawText>
  );
}

export default FormQuestionText;
import { useContext } from 'react';
import { AccountProfileContainer, useProfileState, useAccountProfileNewEntry } from '@containers';
import { Skip } from '@presentation';
import { ProfileEntry } from '@enums';
import { ProjectRateOnboardingContext, useSelectUser, useAccountProfileEditState } from '@containers';
import { EmploymentSetup as Employment } from '@screens/AccountProfileSetup';
import { ScreenNavigationProps } from '@screens/AccountProfileSetup/interfaces';
import { BackButton, Footer, NextButton } from '@screens/ProjectCreation';
import { AnimatedLoader } from 'components/ActivityIndicator';
import styles from './style/EmploymentSetup.css';

type Props = {
  subtitle?: string;
  title?:    string;
} & ScreenNavigationProps;

const EmploymentSetupScreen = (props: Props) => {
  const [state] = useProfileState();
  const [entryId] = useAccountProfileEditState();
  const [_, addNewEntry] = useAccountProfileNewEntry(ProfileEntry.Employment);

  return (
    <div className={styles.root}>
      <Employment.Header
        subtitle={props.subtitle}
        title={props.title} />

      {!state?.employment &&
        <AnimatedLoader
          className={styles.loader}
          color="var(--sec-01)" />}

      {state?.employment &&
        <Employment.Form
          items={state.employment}
          onAddForm={addNewEntry} />}

      <Footer className={styles.footer}>
        <BackButton onClick={props.navigation.back} />
        <div className={styles.proceed}>
          <NextButton
            className={styles.btn}
            color="primary"
            disabled={!!entryId || !state?.employment?.length}
            onClick={props.navigation.onClick}
            replace={props.navigation.replace}
            to={props.navigation.to}
            variant="brick">
            Next
          </NextButton>
          {!state?.employment?.length &&
            <Skip
              onClick={props.navigation.onClick}
              replace={props.navigation.replace}
              to={props.navigation.to} />}
        </div>
      </Footer>
    </div>
  );
};

const EmploymentSetup = () => {
  const ctx = useContext(ProjectRateOnboardingContext);

  const me = useSelectUser();

  const title = `First, fill in your recent work history`;

  const navigation = {
    back: null,
    onClick: ctx.next,
  };

  return (
    <AccountProfileContainer userId={me.id}>
      <EmploymentSetupScreen
        navigation={navigation}
        title={title} />
    </AccountProfileContainer>
  );
};

export { EmploymentSetup };
import { useSelector } from 'react-redux';
import { selectFile } from '@store/selectors';

const useSelectFile = (fileId: number) => {
  const file = useSelector(selectFile(fileId));

  return file;
};

export { useSelectFile };
export default useSelectFile;
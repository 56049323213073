import { memo } from 'react';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { LocationDescriptor } from 'history';
import { MaybeLink } from 'components/MaybeLink';
import styles from './style/ViewAll.css';

type Props = {
  onClick?: () => void;
  to?: string | LocationDescriptor;
  title?: string;
};

export const ResponsesViewAll = memo(({ onClick, to, title }: Props) => {
  return (
    <div className={styles.chevron}>
      <MaybeLink
        to={to}
        className={styles.viewAll}>
        <div className={styles.hoverText}>{title || 'View responses'}</div>
        <ChevronRightRoundedIcon
          className={styles.chevronIcon}
          onClick={onClick}
          fontSize="large" />
      </MaybeLink>
    </div>
  );
});

export default ResponsesViewAll;
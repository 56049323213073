import { useCallback, useState } from 'react';
import { SetQuestionAnswer, SurveyAnswerMap } from '../interfaces';

export const useAnswerMap = () => {
  const [answers, setAnswers] = useState<SurveyAnswerMap>({});

  const updateAnswer: SetQuestionAnswer = useCallback(data => {
    setAnswers({
      ...answers,
      [data.questionId]: data.value,
    });
  }, [answers, setAnswers]);

  return [answers, updateAnswer] as const;
};

export default useAnswerMap;
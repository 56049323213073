import { createElement, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ProjectLinkURLState } from '@containers/ProjectLinkInvitation/interfaces';
import { usePersistentProjectInvite } from '@containers/ProjectLinkInvitation/hooks/usePersistentProjectInvite';

type Params<T extends unknown> = React.ComponentType<T>;

const PersistentLinkState = <T extends unknown>(component: Params<T>, name?: string) => {
  const WithPersistentLinkState = (props: T) => {
    const history = useHistory();
    const location = useLocation<Pick<ProjectLinkURLState, 'return'>>();
    const urlstate = usePersistentProjectInvite();
    const state = urlstate.get();

    useEffect(() => {
      if (state?.token && !location.state?.return) {
        history.replace({
          ...location,
          state: {
            ...(location.state ?? {}),
            ...state,
          },
        });
      }
    }, [
      history,
      location,
      state,
    ]);

    return createElement(component, props);
  };

  WithPersistentLinkState.displayName = name ?? 'WithPersistentLinkState';

  return WithPersistentLinkState;
};

export { PersistentLinkState };
export default PersistentLinkState;
import { useMemo } from 'react';
import * as consts from '@consts';
import { SharedTabFiles } from './SharedTabFiles';
import { SharedTabProjects } from './SharedTabProjects';
import { SharedTabFolders } from './SharedTabFolders';
import { SharedRouteKey, SharedSceneMap } from './interfaces';
import SharedTabView from './SharedTabView';

export const SharedTabViewContainer = () => {

  const map: SharedSceneMap = useMemo(() => ({
    [SharedRouteKey.Projects]: {
      component: SharedTabProjects,
      path: consts.path.SharedWorkspaceTabs.Projects,
      title: 'Projects',
    },
    [SharedRouteKey.Folders]: {
      component: SharedTabFolders,
      path: consts.path.SharedWorkspaceTabs.Folders,
      title: 'Folders',
    },
    [SharedRouteKey.Files]: {
      component: SharedTabFiles,
      path: consts.path.SharedWorkspaceTabs.Files,
      title: 'Files',
    },
  }), [

  ]);

  return (
    <SharedTabView
      sceneMap={map} />
  );
};

export default SharedTabViewContainer;
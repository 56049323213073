import mixpanel from 'mixpanel-browser';
import { Project } from './interfaces';

export function home({ project }: Project.Home) {
  mixpanel.track('project:home', {
    id: project.id,
    name: project.name,
  });
}

export function pipeline({ project }: Project.Pipeline) {
  mixpanel.track('project:pipeline', {
    id: project.id,
    name: project.name,
  });
}

export function surveyResponses({ project }: Project.SurveyResponses) {
  mixpanel.track('project:survey-responses', {
    id: project.id,
    name: project.name,
  });
}

export function userSurveyResponses({ project, question, user }: Project.UserSurveyResponses) {
  mixpanel.track('project:survey-responses:view', {
    projectId: project.id,
    projectName: project.name,
    question: question.ordinal,
    userId: user.id,
    userName: user.name,
  });
}

export function upcomingCalls({ project }: Project.UpcomingCalls) {
  mixpanel.track('project:upcoming-calls', {
    id: project.id,
    name: project.name,
  });
}

export function completedCalls({ project }: Project.CompletedCalls) {
  mixpanel.track('project:completed-calls', {
    id: project.id,
    name: project.name,
  });
}

export function transcript({ project, user }: Project.Transcript) {
  mixpanel.track('project:transcript', {
    projectId: project.id,
    projectName: project.name,
    userId: user.id,
    userName: user.name,
  });
}

export function manageAccess({ project }: Project.ManageAccess) {
  mixpanel.track('project:manage-access', {
    id: project.id,
    name: project.name,
  });
}
import { useFiltersState } from '@containers/UniversalSearch/hooks';
import { useFetchUniversalSearchPageExperts } from '@utils/api';
import { formatPlurality } from '@utils';
import Table from './Search.Table.Experts';
import styles from './style/Tab.css';

const max = 50;

export default function SearchTabExperts() {
  const [query] = useFiltersState();
  const experts = useFetchUniversalSearchPageExperts(query.q, max, {
    enabled: !!query.q?.length,
    refetchOnWindowFocus: false,
  });

  const isLoading = experts.isLoading;
  const data = experts.data?.items ?? [];
  const countText = !isLoading
    ? `(${getCountLabel(experts.data?.total ?? 0, 'Member')})`
    : null;
  const pagination = data.length > 25;

  return (
    <div className={styles.root}>
      <Table
        data={data}
        countText={countText}
        pagination={pagination}
        pageSize={25}
        isLoading={isLoading} />
    </div>
  );
}

function getCountLabel(total: number, label: string) {
  return total > max
    ? `+${max} ${formatPlurality(label, max)}`
    : `${total} ${formatPlurality(label, total)}`;
}
import http from '@services/http';
import { Reports } from './interfaces';

export const downloadAccountSpendReport = () => {
  return http.get<Reports.DownloadAccountSpendReport.Response>(`/admin/reports/account-spend`, {
    responseType: 'blob',
  });
};

export const downloadAccountUserSpendReport = () => {
  return http.get<Reports.DownloadAccountUserSpendReport.Response>(`/admin/reports/user-spend`, {
    responseType: 'blob',
  });
};

export const downloadAccountLoginActivity = () => {
  return http.get<Blob>(`/admin/reports/account-login-activity`, {
    responseType: 'blob',
  });
};

export const downloadAccountUserLoginActivity = () => {
  return http.get<Blob>(`/admin/reports/client-login-activity`, {
    responseType: 'blob',
  });
};

export const downloadMonthlyActiveUsers = (data: Reports.DownloadMonthlyActiveUsers.Request) => {
  return http.download(`/admin/reports/monthly-active-users?year=${data.year}&month=${data.month}`);
};

export const downloadProjectCompletes = () => {
  return http.get<Blob>(`/admin/reports/project-completes`, {
    responseType: 'blob',
  });
};
import { useCallback } from 'react';
import { useSelectPayoutSettings } from '@containers/Store';
import { useProjectOnboardingContext } from '@containers/ProjectOnboarding/hooks/useProjectOnboardingContext';
import * as PayoutSetup from '@screens/PayoutSetup';
import styles from './style/CallBilling.css';
import { useRedirectProjectComplete } from './hooks';

export function Stepper() {
  const { next, hasNextStep, projectId } = useProjectOnboardingContext();
  const settings = useSelectPayoutSettings();
  const redirect = useRedirectProjectComplete();

  const handleCompletion = useCallback(() => {
    if (hasNextStep) {
      next();
    } else {
      redirect();
    }
  }, [hasNextStep, next, redirect]);

  const handleSkip = useCallback(() => {
    if (hasNextStep) {
      next();
    } else {
      redirect();
    }
  }, [hasNextStep, next, redirect]);

  const hasPayoutMethod = !!settings.method;

  return (
    <div className={styles.wrap}>
      <PayoutSetup.Stepper.ProjectCall
        onComplete={handleCompletion}
        onSkip={handleSkip}
        hasStepsAfter={hasNextStep}
        hasPayoutMethod={hasPayoutMethod}
        projectId={projectId} />
    </div>
  );
}
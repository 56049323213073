import { useLocation, useRouteMatch } from 'react-router-dom';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { path } from '@consts';
import { cx } from '@utils';
import { header } from '$website/containers/SiteNavigation';
import { Button } from 'components/Button';
import styles from './style/Nav.Menu.css';

type Props = unknown;

export const Links = (props: Props) => {
  const match = useRouteMatch({
    exact: true,
    path: path.Posts.Articles.Post,
  });

  const location = useLocation();

  /**
   *  Changing the key of each menu on
   *  location change causes a re-render
   *  hiding any visible ones due to hover
   *  state.
   */

  const menus = !match
      ? header.navbar.menus
      : [];
  const links = !match
      ? header.navbar.links
      : [];

  return (
    <nav className={styles.root}>
      {menus.map(item =>
        <Menu
          key={`${item.name}-${location.pathname}`}
          item={item} /> )}
      {links.map(item =>
        <Button
          className={styles.item}
          href={item.href}
          to={item.to}
          key={item.name}
          variant="link">
          {item.name}
        </Button>)}
    </nav>
  );
};

type MenuProps = {
  item: typeof header.navbar.menus[0];
};

const Menu = (props: MenuProps) => {

  return (
    <div className={styles.list}>
      <div className={styles.anchor}>
        <Button
          className={styles.item}
          key={props.item.name}
          variant="link">
          {props.item.name}
          <KeyboardArrowDown />
        </Button>
        <div className={cx(styles.menu, styles.hide)}>
          <div className={styles.pad}>
            {props.item.items.map(x =>
              <Button
                className={styles.btn}
                href={x.href}
                key={x.name}
                to={x.to}
                variant="link">
                <div className={styles.link}>
                  {x.name}
                </div>
              </Button>)}
          </div>
        </div>
      </div>
    </div>
  );
};

Links.displayName = 'Nav.Links';
import { useCallback, useRef, useState } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import { useSearchConsultants } from '@containers/PostCreation/hooks';
import { useDebounceCallback } from '@utils';
import { RichTextMentionsContainer } from 'components/RichTextMentions';
import { Editor } from './interfaces';

type Props = {
  children: React.ReactNode;
};

export const MentionsContainer = (props: Props) => {
  const [items, setItems] = useState<Editor.MentionedConsultant[]>([]);
  const cancelSourceRef = useRef<CancelTokenSource>();

  const search = useSearchConsultants();

  const fetchResults = useCallback(async (value: string) => {

    cancelSourceRef.current = Axios.CancelToken.source();

    const items = await search({
      cancelToken: cancelSourceRef.current.token,
      name: value,
    });

    if (items) {
      setItems(items);
    }

  }, [search]);

  const fetchDebounced = useDebounceCallback(fetchResults);

  const handleInput = useCallback((value: string) => {

    if (cancelSourceRef.current) {
      cancelSourceRef.current.cancel();
    }

    if (!value.trim().length) return setItems([]);

    fetchDebounced(value);
  }, [
    fetchDebounced,
  ]);

  return (
    <RichTextMentionsContainer
      getOptionTagProps={getOptionProps}
      onInput={handleInput}
      options={items}>
      {props.children}
    </RichTextMentionsContainer>
  );
};

MentionsContainer.displayName = 'PostCreation.Editor.MentionsContainer';

function getOptionProps(option: Editor.MentionedConsultant) {
  return {
    id: option.id,
    label: option.profile.fullname,
    to: option.profile.slug,
  };
}
import { useCallback } from 'react';
import { useQuestionBuilderItemContext } from '@containers/SurveyBuilder.Question/Context';
import { QuestionPreviewItemWithLogicContent, QuestionTextWithLogic } from '@presentation/SurveyPreview';
import { QuestionContextMenuPopper } from './QuestionContextMenu';
import styles from './style/EditableQuestion.css';

type Props = {
  canEdit: boolean;
  includeTagging: boolean;
};

const EditableQuestion = ({ canEdit, includeTagging }: Props) => {
  const item = useQuestionBuilderItemContext();

  const renderQuestion = useCallback(() => {
    return (
      <div className={styles.question}>
        <QuestionTextWithLogic
          className={styles.questionText}
          item={item} />
        {canEdit &&
          <QuestionContextMenuPopper className={styles.menu} />}
      </div>
    );
  }, [canEdit, item]);

  return (
    <div className={styles.root}>
      {renderQuestion()}
      <QuestionPreviewItemWithLogicContent
        item={item}
        includeTagging={includeTagging} />
    </div>
  );
};

export default EditableQuestion;
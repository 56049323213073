import { ComponentType, useMemo } from 'react';
import { NodeViewComponentProps } from '@remirror/react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { getResponsePipeNodeText } from '@containers/SurveyBuilder/utils/rich-text';
import { SurveyRichText } from '@/types/survey.rich-text';
import styles from './style/Node.PipedResponse.css';

export const PipedResponse: ComponentType<NodeViewComponentProps> = props => {

  const ctx = useSurveyBuilderState();

  return ctx
    ? <BuilderNode {...props} />
    : <Node {...props} />;
};

function BuilderNode({ node }: NodeViewComponentProps) {

  const [state] = useSurveyBuilderState();

  const text = useMemo(() => {
    return getResponsePipeNodeText((node.attrs as SurveyRichText.PipedResponseNode['attrs']), state.survey);
  }, [node, state]);

  return (
    <span className={styles.root}>{text}</span>
  );
}

function Node({ node }: NodeViewComponentProps) {
  return (
    <span className={styles.root}>{(node.attrs as SurveyRichText.PipedResponseNode['attrs']).text}</span>
  );
}

export default PipedResponse;

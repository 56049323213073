import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { GetConferenceParticipants } from '@api/interfaces/calls';
import * as api from '@api';

type Props = ICallId;

type Data = GetConferenceParticipants.Response['participants'];
type QueryKey = [string, Props];

export function useConferenceParticipants(
  data: Props,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  return useQuery(['call-conference-participants', data], ({ queryKey }) => {
    const params = queryKey[1] as Props;

    return api.calls.getConferenceParticipants(params)
      .then(res => res.participants);

  }, options);
}

import styles from './style.css';
import { DollarRateProps } from './interfaces';

const getClassNames = (props: DollarRateProps) => {
  return `${styles.root} ` +
         `${props.hideDollar ? '' : styles.dollar} ` +
         `${props.hidePerHour ? '' : styles.perHour} `;
};

const DollarRate = (props: DollarRateProps) => {
  if (!props.rate) return null;

  return (
    <div className={getClassNames(props)}>
      {Number(props.rate.toFixed(0)).toLocaleString()}
    </div>
  );
}

export default DollarRate;
import { Cell } from 'react-table';
import { UserAvatar } from 'components/UserAvatar/UserAvatar';
import ClickContainer from './ResultClickContainer';
import { ExpertsTableItem } from './interfaces';
import styles from './style/Cell.Expert.css';

export default function Expert({ row: { original: data } }: Cell<ExpertsTableItem>) {
  return (
    <ClickContainer item={data}>
      <div className={styles.root}>
        <UserAvatar
          className={styles.icon}
          pictureUrl={data.metadata.pictureUrl}
          size={50} />
        <div className={styles.details}>
          <div className={styles.name}>{data.name}</div>
          <div className={styles.title}>{data.metadata.title}</div>
        </div>
      </div>
    </ClickContainer>
  );
}

export { Expert };
import { useCallback, useMemo } from 'react';
import cuid from 'cuid';
import { useSurveyBuilderState, useHasUncommittedQuestionChanges } from '@containers/SurveyBuilder/hooks';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useToggle } from '@utils';
import { SurveyRichText } from '@/types/survey.rich-text';
import { SurveyQuestionTextEditorContainer, QuestionEditorOnChange } from 'components/Survey.QuestionText';
import { NewVersionModal } from 'components/SurveyBuilder.Question/NewVersionModal';
import { QuestionBuilderContextValue, QuestionBuilderContext, useQuestionBuilderItemContext } from './Context';

type Props =
  ChildrenProps;

const QuestionBuilderContainer = (props: Props) => {

  const [showVersionModal, toggleVersionModal] = useToggle(false);
  const [_, dispatch] = useSurveyBuilderState();

  const item = useQuestionBuilderItemContext();

  const hasUncommittedChanges = useHasUncommittedQuestionChanges();

  const revertUncommittedChanges = useCallback(() => {
    dispatch({
      type: 'revert-uncommitted-changes',
    });
  }, [dispatch]);

  const canSave = useMemo(() => {
    const valid = surveyBuilder.validateQuestion(item);

    if (!valid.success) {
      return false;
    }
    return hasUncommittedChanges();
  }, [item, hasUncommittedChanges]);

  const onSaveQuestionFromModal = useCallback((isNew: boolean) => () => {
    if (isNew) {
      dispatch({
        type: 'update-question-identifier',
        oldIdentifier: item.base.identifier,
        newIdentifier: cuid(),
      });
    }

    dispatch({
      type: 'toggle-editing',
      ordinal: null,
    });

    toggleVersionModal();
  }, [
    dispatch,
    toggleVersionModal,
    item,
  ]);

  const onSave = useCallback(() => {
    if (item.base.id) {
      toggleVersionModal();
    } else {
      dispatch({
        type: 'toggle-editing',
        ordinal: null,
      });
    }
  }, [
    dispatch,
    item,
    toggleVersionModal,
  ]);

  const value: QuestionBuilderContextValue = {
    canSave,
    onCancel: revertUncommittedChanges,
    onSave,
  };

  return (
    <>
      <QuestionBuilderContext.Provider value={value}>
        {props.children}
      </QuestionBuilderContext.Provider>
      {showVersionModal &&
        <NewVersionModal
          onClose={toggleVersionModal}
          saveAsNew={onSaveQuestionFromModal(true)}
          saveAsExisting={onSaveQuestionFromModal(false)} />
      }
    </>
  );
};

const Container = (props: Props) => {

  const [_, dispatch] = useSurveyBuilderState();
  const item = useQuestionBuilderItemContext();

  const handleChange: QuestionEditorOnChange = useCallback(params => {
    const value = params.state.doc.toJSON() as SurveyRichText.RichTextValue;

    dispatch({
      type: 'update-question-value',
      ordinal: item.ordinal,
      value,
    });
  }, [
    item.ordinal,
    dispatch,
  ]);

  return (
    <SurveyQuestionTextEditorContainer
      initialState={item.value}
      onChange={handleChange}>
      <QuestionBuilderContainer>
        {props.children}
      </QuestionBuilderContainer>
    </SurveyQuestionTextEditorContainer>
  );
};

export { Container as QuestionBuilderContainer };
export default Container;
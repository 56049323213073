import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRemirrorContext } from '@remirror/react';
import cuid from 'cuid';
import { Point } from '@/types';
import { UnsavedHighlightColor } from '../utils';
import { useChainedCommands } from './useCommands';
import { useHelpers } from './useHelpers';

function getTouchPoint(e: TouchEvent): Point {
  if (e.changedTouches.length !== 1) {
    return null;
  }

  const touch = e.changedTouches[0];
  return {
    x: touch.clientX,
    y: touch.clientY,
  };
}

function getMousePoint(e: MouseEvent): Point {
  return {
    x: e.x,
    y: e.y,
  };
}

export const useHighlightEvents = () => {
  const [highlight, setHighlight] = useState<{
    start: number;
    id: string;
  }>(null);
  const { view } = useRemirrorContext({ autoUpdate: true });

  const commands = useChainedCommands();
  const helpers = useHelpers();

  const isHighlightFocused = useMemo(() => {
    return helpers.isHighlightFocused();
  }, [helpers]);

  const getCoordsFromEvent = useCallback((e: Point) => {
    const editorRect = view.dom.getBoundingClientRect();

    return view.posAtCoords({
      left: Math.min(Math.max(e.x, editorRect.x), editorRect.x + editorRect.width),
      top: Math.min(Math.max(e.y, editorRect.y), editorRect.y + editorRect.height),
    }).pos;
  }, [view]);

  const handleHighlightStart = useCallback((e: Point) => {

    const position = getCoordsFromEvent(e);

    setHighlight({
      id: cuid(),
      start: position,
    });
  }, [
    getCoordsFromEvent,
  ]);

  const handleHighlightEnd = useCallback((e: Point) => {

    const position = getCoordsFromEvent(e);

    if (position !== highlight.start) {
      commands.focusHighlight(highlight.id, true).run();
    }

    setHighlight(null);
  }, [
    commands,
    getCoordsFromEvent,
    highlight,
  ]);

  const handleHighlight = useCallback((e: Point) => {

    const position = getCoordsFromEvent(e);

    if (position !== highlight.start) {
      commands.addHighlight({
        id: highlight.id,
        color: UnsavedHighlightColor,
        dbId: null,
        from: Math.min(highlight.start, position),
        to: Math.max(highlight.start, position),
      }).run();
    } else {
      commands.removeHighlights([highlight.id]).run();
    }
  }, [
    commands,
    getCoordsFromEvent,
    highlight,
  ]);

  const handleTouchStart = useCallback((e: TouchEvent) => {

    const point = getTouchPoint(e);

    if (point) {
      handleHighlightStart(point);
    }

  }, [handleHighlightStart]);

  const handleTouchMove = useCallback((e: TouchEvent) => {

    const point = getTouchPoint(e);

    if (point) {
      handleHighlight(point);
    }

  }, [handleHighlight]);

  const handleTouchEnd = useCallback((e: TouchEvent) => {

    const point = getTouchPoint(e);

    if (point) {
      handleHighlightEnd(point);
    }

  }, [handleHighlightEnd]);

  const handleMouseDown = useCallback((e: MouseEvent) => {

    if (e.button !== 0) {
      return;
    }

    handleHighlightStart(getMousePoint(e));

  }, [handleHighlightStart]);

  const handleMouseUp = useCallback((e: MouseEvent) => {

    handleHighlightEnd(getMousePoint(e));

  }, [handleHighlightEnd]);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    handleHighlight(getMousePoint(e));

  }, [handleHighlight]);

  useEffect(() => {
    if (!isHighlightFocused) {
      window.document.body.addEventListener('mousedown', handleMouseDown, { passive: false });
      window.document.body.addEventListener('touchstart', handleTouchStart, { passive: false });
    }

    return () => {
      window.document.body.removeEventListener('mousedown', handleMouseDown, false);
      window.document.body.removeEventListener('touchstart', handleTouchStart, false);
    };
  }, [
    handleMouseDown,
    handleTouchStart,
    isHighlightFocused,
  ]);

  useEffect(() => {

    if (highlight) {
      window.document.body.addEventListener('mousemove', handleMouseMove, { passive: false });
      window.document.body.addEventListener('mouseup', handleMouseUp, { passive: false });
      window.document.body.addEventListener('touchmove', handleTouchMove, { passive: false });
      window.document.body.addEventListener('touchend', handleTouchEnd, { passive: false });
    }

    return () => {
      window.document.body.removeEventListener('mousemove', handleMouseMove, false);
      window.document.body.removeEventListener('mouseup', handleMouseUp, false);
      window.document.body.removeEventListener('touchmove', handleTouchMove, false);
      window.document.body.removeEventListener('touchend', handleTouchEnd, false);
    };

  }, [
    handleMouseUp,
    handleMouseMove,
    handleTouchEnd,
    handleTouchMove,
    highlight,
  ]);

};

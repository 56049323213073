import { useCallback, useState } from 'react';
import { ShareProjectWithExpert as ShareProjectWithExpertModal } from 'components/Modal/ShareProjectWithExpert';

type Props =
  IProjectId;

export const useShareWithExpertModal = ({ projectId }: Props) => {
  const [visible, setVisibility] = useState(false);
  const toggleVisibility = useCallback(() => setVisibility(!visible), [visible]);

  const Modal = useCallback(() => {
    if (!visible) {
      return null;
    }

    return (
      <ShareProjectWithExpertModal
        onClose={toggleVisibility}
        projectId={projectId} />
    );
  }, [
    projectId,
    toggleVisibility,
    visible,
  ]);

  return [toggleVisibility, Modal] as const;
};

export default useShareWithExpertModal;
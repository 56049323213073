import { useMemo } from 'react';
import useIsEditingSurvey from './useIsEditingSurvey';

export const useCanAddSection = () => {
  const isEditing = useIsEditingSurvey();

  const canAddSection = useMemo(() => {
    return !isEditing;
  }, [
    isEditing,
  ]);

  return canAddSection;
};

export default useCanAddSection;
import { useCallback } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { useRejectAdHocCallRequest } from '@utils/api';
import { pathname } from '@consts';
import { useSelectContact } from '@containers/Store';
import { GrayOutlineButton } from '@presentation/Buttons';
import { getLocationFor } from '@utils';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { Button } from 'components/Button';
import Toast from 'components/Toast';
import { useRequestCall } from './useCall';
import styles from './style/Details.css';

export const AdHocCallRequestDetails = () => {
  const call = useRequestCall();
  const history = useHistory();

  const handleRejected = useCallback(() => {
    Toast.success({
      title: 'Call Rejected',
      body: 'The client has been sent a notification that you do not wish to participate on this call.',
    });

    history.replace(pathname.Home);
  }, [history]);

  const { mutateAsync, ...reject } = useRejectAdHocCallRequest({ callId: call.id }, {
    onSuccess: handleRejected,
  });

  const handleRejection = useCallback(() => {
    return mutateAsync();
  }, [mutateAsync]);

  const requester = useSelectContact(call.creatorUserId);

  const profileLocation = getLocationFor.user.profile(requester.profile);

  const availabilityLocation = getLocationFor.onboarding.call.schedulingTutorial({ callId: call.id });

  const disableSaving = reject.isLoading || reject.isError;

  if (call.request.accepted) {
    return <Redirect to={pathname.Home} />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.section}>
          <div className={styles.label}>Requested By</div>
          <div className={styles.requestedBy}>
            <UserAvatar
              size={40}
              to={profileLocation}
              className={styles.avatar}
              pictureUrl={requester.profile.pictureUrl} />
            <Link className={styles.link} to={profileLocation}>
              {requester.profile.fullname}
            </Link>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.label}>Subject</div>
          {call.request.subject}
        </div>

        <div className={styles.section}>
          <div className={styles.label}>Payment</div>
          ${call.hourlyRate}
        </div>

        {call.request.note &&
          <div className={styles.section}>
            <div className={styles.label}>Note</div>
            <div className={styles.note}>{call.request.note}</div>
          </div>
        }

        <div className={styles.section}>
          <div className={styles.label}>Call Duration</div>
          60 Minutes
        </div>

        <div className={styles.footer}>
          <GrayOutlineButton
            className={styles.reject}
            disabled={disableSaving}
            onClick={handleRejection}
            title="Reject" />
          <Button.Affirmative
            className={styles.btn}
            disabled={disableSaving}
            to={availabilityLocation}
            variant="brick"
            title="Accept" />
        </div>
      </div>
    </div>
  );
};

export default AdHocCallRequestDetails;
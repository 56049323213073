import { useCallback } from 'react';
import { useSurveyBuilderOptions, useSurveyBuilderState } from './useSurveyBuilderContext';
import useIsEditingSurvey from './useIsEditingSurvey';

export const useCanAddQuestion = () => {
  const [state] = useSurveyBuilderState();
  const { maxQuestions } = useSurveyBuilderOptions();
  const isEditing = useIsEditingSurvey();

  const canAddQuestion = useCallback(() => {
    return !isEditing &&
      state.survey.questions.length < maxQuestions;
  }, [
    isEditing,
    maxQuestions,
    state.survey.questions,
  ]);

  return canAddQuestion;
};

export default useCanAddQuestion;
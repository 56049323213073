import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import * as selectors from '@store/selectors';
import { formatTruncatedName } from '@utils';
import { FoldersTableItem } from 'components/WorkspaceFolders/interfaces';

type Filters = {
  parentObjectId?: number;
};

const selectFolderTableItems = (filters: Filters) => createSelector(
  [
    (state: Store.State) => state.user.id,
    (state: Store.State) => state.contacts,
    selectors.selectFolderIds,
    selectors.selectFolderValues,
    selectors.selectObjectValues,
  ],
  (meId, contacts, folderIds, folders, objects) => {
    return folderIds
      .reduce((acc, folderId) => {
        const folder = folders[folderId];
        const object = objects[folder.objectId];

        if (filters.parentObjectId && object.parentObjectId !== filters.parentObjectId) {
          return acc;
        }

        const item = {
          ...folder,
          owner: object.ownerId === meId
            ? 'Me'
            : formatTruncatedName(contacts[object.ownerId]),
        };

        return [...acc, item];
      }, [] as FoldersTableItem[]);
  },
);

export const useFoldersTableItems = (filters: Filters) => {
  const items = useSelector(selectFolderTableItems(filters));

  return items;
};

export default useFoldersTableItems;
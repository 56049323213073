import { useMemo } from 'react';
import { Datum, Serie } from '@nivo/line';
import { SurveyQuestionType } from '@enums';
import { useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks/useRepeatQuestionResponsesData';
import { useOptionsColors } from 'components/SurveyResponses/hooks/usePaletteColors';

export const useRepeatOptionsChartData = () => {
  const qData = useRepeatQuestionResponsesData<SurveyQuestionType.MultipleChoice>();
  const colors = useOptionsColors();

  const chartData: Serie[] = useMemo(() => {

    return qData.question.options.reduce((acc, option) => {
      const data = qData.projectIds.reduce((acc2, projectId, i) => {
        const projectData = qData.historical[projectId]?.data;

        if (projectData?.[option.base.id]) {
          const value: Datum = {
            x: i + 1,
            y: Math.round(projectData[option.base.id].pct),
          };

          return [
            ...acc2,
            value,
          ];
        } else {
          return acc2;
        }
      }, [] as Datum[]);

      return [
        ...acc,
        {
          color: colors[option.ordinal - 1],
          data,
          id: option.base.id,
        },
      ];
    }, [] as Serie[]);
  }, [
    colors,
    qData.historical,
    qData.projectIds,
    qData.question.options,
  ]);

  return chartData;
};

export default useRepeatOptionsChartData;

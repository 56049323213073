import mixpanel from 'mixpanel-browser';
import * as utils from './utils';
import { Auth } from './interfaces';

export function authenticated({ user, group }: Auth.Authenticated) {
  mixpanel.identify(`${user.id}`);
  mixpanel.people.set_once({
    $created: user.createdOn,
  });
  mixpanel.people.set({
    $email: user.contact.email,
    '$first_name': user.profile.firstName,
    '$last_name': user.profile.lastName,
    Group: group.id,
    Roles: utils.rolesToStrings(user.roles),
  });
}

export function forgotPassword(data: Auth.ForgotPassword) {
  mixpanel.track('auth:forgot-password', data);
}

export function login() {
  mixpanel.track('auth:login');
  mixpanel.people.set_once({
    'First Login': new Date(),
  });
}

export function logout() {
  mixpanel.track('auth:logout');
}

export function signup() {
  mixpanel.track('auth:signup');
}
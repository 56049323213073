import { useCallback, useEffect, useRef } from 'react';
import IntlTelInput from 'intl-tel-input';
import { Input, InputProps } from 'components/Input';

type Props = {
  id?:                 string;
  onChange:            (e: React.ChangeEvent<HTMLInputElement>, data: TelInputState) => unknown;
  onChangeCountryCode: (data: TelInputState) => unknown;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>
  & Pick<InputProps, 'invalid' | 'invalidClassName'>;

const TelInput = (props: Props) => {
  const ref = useRef<HTMLInputElement>();
  const iti = useRef<IntlTelInput.Plugin>();

  const initialize = useCallback((r: HTMLInputElement) => {
    ref.current = r;
    iti.current = IntlTelInput(document.getElementById('phone'), {
      dropdownContainer: document.body,
      formatOnDisplay: false,
      separateDialCode: true,
      utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js',
    });
  }, []);

  function getTelState() {
    const num = iti.current.getNumber();

    return {
      empty: num.length === 0,
      valid: iti.current.isValidNumber(),
      value: num,
    };
  }

  function handleChange(e) {
    props.onChange(e, getTelState());
  }

  const { onChangeCountryCode, value, ...rest } = props;

  useEffect(() => {

    const effectRef = ref.current;

    if (value) {
      iti.current.setNumber(value as string);
    }

    const setCountryCode = e => {
      const state = getTelState();
      onChangeCountryCode(state);
      iti.current.setNumber(state.value);
    };

    effectRef.addEventListener('countrychange', setCountryCode);

    return () => {
      effectRef.removeEventListener('countrychange', setCountryCode);
    };

  }, [
    onChangeCountryCode,
    value,
  ]);

  return (
    <Input
      {...rest}
      id={props.id}
      onChange={handleChange}
      ref={initialize}
      invalid={props.invalid}
      invalidClassName={props.invalidClassName}
      type="tel" />
  );
};

export type TelInputProps = Props;

export type TelInputState = {
  empty: boolean;
  valid: boolean;
  value: string;
};

const defaultProps = {
  id: 'phone',
};

TelInput.defaultProps = defaultProps;

export { TelInput };
export default TelInput;
import { addMinutes, getMinutes, startOfMinute, isToday, subMinutes as subMinutesNF } from 'date-fns';
import { addDays, lightFormat, startOfDay, subMinutes } from 'date-fns/fp';
import { CalendarDateEvent } from '@/types';
import {
  TimeslotItem,
  EventsMap,
} from './interfaces';
import { segmentRange } from './utils';

export const buildEventsMap = <T extends TimeslotItem, U extends { [day: string]: T[]; }>(items: T[]) => {
  return items.reduce<U>((acc, event) => {
    const key = dateToEventsMapKey(event.start);

    return {
      ...acc,
      [key]: acc[key] ? acc[key].concat(event) : [event],
    };
  }, {} as U);
};

export const buildTimesMap = (dates: Date[]) => {
  const unusedIntervals = dates.map(d => d.toISOString());

  return unusedIntervals.reduce<{ [ds: string]: Date[]; }>((acc, d) => {
    acc[d] = build(new Date(d));

    return acc;
  }, {});

  function build(d1: Date, arr: Date[] = []): Date[] {
    const d2 = addMinutes(d1, 30);
    const exists = unusedIntervals.includes(d2.toISOString());

    return exists
         ? build(d2, arr.concat(d2))
         : arr.concat(d2);
  }
};

export const createIntervalGenerator = (map: EventsMap) => {

  return function generateUnusedDateIntervals(date: Date) {
    const key = dateToEventsMapKey(date);
    const timeslots = map[key] || [];
    const usedIntervals = timeslots.map(event => event.start.toISOString());

    return generateDateIntervals(date).reduce<Date[]>((acc, d) => {
      return !usedIntervals.includes(d.toISOString())
          ? acc.concat(d)
          : acc;
    }, []);

    function generateDateIntervals(date: Date, interval = 30) {
      const range = {
        end: getDayEndTime(date),
        start: getDayStartTime(date),
      };

      return segmentRange(range, interval).map(x => x.end);
    }
  };

};

export const dateToEventsMapKey = lightFormat('yyyy-MM-dd');

export const eventsMapKeysToDates = (map: EventsMap) => {
  return Object.keys(map).map(key => {
    const [ year, month, day ] = key.split('-');

    return new Date(+year, +month - 1, +day);
  });
};

export const getHalfHourAvailabilityMap = <U extends { [key: string]: Date[]; }>(events: CalendarDateEvent[]) => {
  const subHalfHour = subMinutes(30);

  return events.reduce<U>((acc, x) => {
    const key = dateToEventsMapKey(x.start);

    if (x.isBusy) return acc;

    const date = subHalfHour(x.end);

    return {
      ...acc,
      [key]: acc[key] ? acc[key].concat(date) : [date],
    };
  }, {} as U);
};

function getDayEndTime(date: Date) {
  const addOneDay = addDays(1);

  return subMinutesNF(startOfDay(addOneDay(date)), 30);
}

function getDayStartTime(date: Date) {
  const time = isToday(date)
              ? Date.now()
              : startOfDay(date);

  const delta = 30 - (getMinutes(time) % 30);

  return startOfMinute(addMinutes(time, delta));
}
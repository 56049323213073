import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppReadyState } from '@containers/AppReadyState';
import { useHasClientRole, useUpdateUserPrompts } from '@containers/User';
import { BannerHookReturnType } from './interfaces';

const mapState = (state: Store.State) => ({
  bannerDismissed: state.user.prompts.verifiedBannerDismissed,
  emailVerified: state.user.meta.isEmailVerified,
});

const useVerifiedBanner = (): BannerHookReturnType => {
  const { initialized } = useAppReadyState();
  const hasClientRole = useHasClientRole();
  const { bannerDismissed, emailVerified } = useSelector(mapState);
  const updateUserPrompts = useUpdateUserPrompts();

  const show = useMemo(() => {
    return initialized &&
      emailVerified &&
      !bannerDismissed &&
      !hasClientRole;
  }, [
    bannerDismissed,
    emailVerified,
    hasClientRole,
    initialized,
  ]);

  const dismiss = useCallback(() => {
    updateUserPrompts({
      verifiedBannerDismissed: true,
    });
  }, [updateUserPrompts]);

  return [show, dismiss];
};

export { useVerifiedBanner };
export default useVerifiedBanner;
import { SurveyBuilder, SurveySettingsBuilder, SurveyQuestionsBuilder } from '../interfaces';
import * as $actions from './state.settings';

export function settingsReducer(state: SurveyBuilder.State, action: SurveySettingsBuilder.Action): SurveyQuestionsBuilder.State {

  switch (action.type) {

    case 'toggle-none-of-the-above':
      return $actions.toggleNoneOfTheAbove(state.survey.questions, action);

    case 'toggle-allow-other':
      return $actions.toggleAllowOther(state.survey.questions, action);

    case 'toggle-not-applicable':
      return $actions.toggleNotApplicable(state.survey.questions, action);

    case 'toggle-allow-empty-values':
      return $actions.toggleAllowEmptyValues(state.survey.questions, action);

    default:
      return state.survey.questions;
  }

}
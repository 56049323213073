import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext'
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { ConjointAnalysisQuestion } from '@/types';
import { ConjointAnalysisQuestionBuilderContext } from './Context';

type Props = {
  item: ConjointAnalysisQuestion.Question;
} & ChildrenProps;

const builder = surveyBuilder.conjointAnalysis;

export const ConjointAnalysisQuestionBuilderContainer = ({ children, item }: Props) => {

  const [state, dispatch] = useSurveyBuilderState();

  const canAddLevel = useCallback((attributeId: string) => {
    const attribute = item.attributes.find(f => f.base.identifier === attributeId);

    return attribute.levels.length < builder.MaxLevels;
  }, [item.attributes]);

  const canAddAttribute = useMemo(() => {
    return item.attributes.length < builder.MaxAttributes;
  }, [item.attributes.length]);

  const canRemoveLevel = useCallback((attributeId: string) => {
    const attribute = item.attributes.find(f => f.base.identifier === attributeId);

    return attribute.levels.length > builder.MinLevels;
  }, [item.attributes]);

  const canRemoveAttribute = useMemo(() => {
    return item.attributes.length > builder.MinAttributes;
  }, [item.attributes.length]);

  const addAttribute = useCallback(() => {
    dispatch({
      type: 'conjoint-add-attribute',
    });
  }, [dispatch]);

  const removeAttribute = useCallback((attributeId: string) => () => {
    dispatch({
      type: 'conjoint-remove-attribute',
      attributeId,
    });
  }, [dispatch]);

  const addLevel = useCallback((attributeId: string) => () => {
    dispatch({
      type: 'conjoint-add-level',
      attributeId,
    });
  }, [dispatch]);

  const removeLevel = useCallback((attributeId: string) => (levelId: string) => () => {
    dispatch({
      type: 'conjoint-remove-level',
      attributeId,
      levelId,
    });
  }, [dispatch]);

  const renameAttribute = useCallback((attributeId: string) => (value: string) => {
    dispatch({
      type: 'conjoint-update-attribute-name',
      attributeId,
      value,
    });
  }, [dispatch]);

  const renameLevel = useCallback((attributeId: string) => (levelId: string) => (value: string) => {
    dispatch({
      type: 'conjoint-update-level-name',
      attributeId,
      levelId,
      value,
    });
  }, [dispatch]);

  const updateConceptsPerSet = useCallback((value: number) => {
    dispatch({
      type: 'conjoint-update-concepts-per-set',
      value,
    });
  }, [dispatch]);

  const updateNumberOfSets = useCallback((value: number) => {
    dispatch({
      type: 'conjoint-update-number-of-sets',
      value,
    });
  }, [dispatch]);

  const toggleNoneOfTheAbove = useCallback(() => {
    dispatch({
      type: 'conjoint-toggle-none-of-the-above',
    });
  }, [dispatch]);

  const value = {
    addAttribute,
    addLevel,
    canAddLevel,
    canAddAttribute,
    canRemoveAttribute,
    canRemoveLevel,
    item,
    removeAttribute,
    removeLevel,
    renameAttribute,
    renameLevel,
    toggleNoneOfTheAbove,
    updateConceptsPerSet,
    updateNumberOfSets,
  };

  return (
    <ConjointAnalysisQuestionBuilderContext.Provider value={value}>
      {children}
    </ConjointAnalysisQuestionBuilderContext.Provider>
  );
};

export default ConjointAnalysisQuestionBuilderContainer;

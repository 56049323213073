import { X } from 'react-feather';
import { cx } from '@utils';
import { GroupTagProps, GroupTagsProps } from './interfaces';
import styles from './style/GroupTag.css';

type Props
  = GroupTagProps;

export default function GroupTag({ className, children, selected, onClick, onRemove }: Props) {
  return (
    <button className={cx(styles.root, { [styles.selected]: selected }, className)}>
      <div className={cx(styles.content, { [styles.clickable]: !!onClick })} onClick={onClick}>
        {children}
      </div>
      {selected && (
        <div className={styles.remove} onClick={onRemove}>
          <X size={11} strokeWidth={3} />
        </div>)}
    </button>
  );
}

export function GroupTags({ className, children }: GroupTagsProps) {
  return (
    <div className={cx(styles.tags, className)}>
      {children}
    </div>
  );
}

export { GroupTag };
import { useContext } from 'react';
import { SurveyThemingContext } from '@containers/Branding/Context';

export const useSurveyThemingPalette = () => {
  const ctx = useContext(SurveyThemingContext);

  if (!ctx) {
    return {
      palette: null,
      theme: false,
    };
  }

  return {
    palette: ctx.theming.data?.palette,
    theme: !!ctx.theming.data?.palette,
  };
};
import { SurveyTemplateType } from '@enums/survey.template';
import { SurveyVersion } from '@/types/survey';
import { generateNewSection } from './defaults';

export const defaultTemplateData = {};

export const initialSurveyData: SurveyVersion = {
  createdOn: null,
  id: null,
  questions: [],
  quotas: [],
  sections: [generateNewSection(1)],
  surveyId: null,
  template: {
    data: null,
    type: SurveyTemplateType.None,
  },
};
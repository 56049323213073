import { useCallback, useMemo } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { getSelectionSettingOptions, assertValidSelectionSettings } from '@containers/SurveyBuilder/utils/question.multiselect';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import { MultiselectQuestion } from '@/types';
import { arr } from '@utils';
import { CheckboxLabel } from 'components/Checkbox';
import { SelectionSettings } from './Multiselect.Settings.Selections';
import { ResponsePiping } from './ResponsePiping';
import styles from './style/Multiselect.Question.css';

type Props = {
  item: MultiselectQuestion.Question;
};

export const MultiselectQuestionBuilder = ({ item }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const toggleNoneOfTheAbove = useCallback((_, checked: boolean) => {
    dispatch({
      questionIdentifier: item.base.identifier,
      type: 'toggle-none-of-the-above',
      value: checked,
    });
  }, [
    dispatch,
    item.base.identifier,
  ]);

  const selectItems = useMemo(() => {
    const [min, max] = getSelectionSettingOptions(item);

    return arr.range(max - min + 1, min).reduce((acc, x) => ({
      ...acc,
      [x]: x.toString(),
    }), {
      [0]: '',
    } as { [k: string]: string; });
  }, [
    item,
  ]);

  const settingsValidation = useMemo(() => {
    return assertValidSelectionSettings(item);
  }, [item]);

  return (
    <>
      <QuestionSettingTitle title="Options">
        <ResponsePiping />
      </QuestionSettingTitle>
      <QuestionOptionsBuilderContainer
        item={item}
        maxOptions={surveyBuilder.multiselect.MaxOptions}
        minOptions={surveyBuilder.multiselect.MinOptions} />
      <CheckboxLabel
        className={styles.checkbox}
        checked={item.settings.includeNoneOfTheAbove}
        label="Include none of the above"
        onChange={toggleNoneOfTheAbove} />
      <SelectionSettings
        className={styles.selections}
        item={item}
        selectItems={selectItems}
        validation={settingsValidation} />
    </>
  );
};

export default MultiselectQuestionBuilder;
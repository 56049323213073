import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GroupProjectRouteParams } from '@containers/GroupProject/interfaces';
import { selectProject, selectProjectParent } from '@store/selectors';
import { LegacyGroupProjectState, Project } from '@/types';
import { ProjectState } from '../Context';

type ProjectIds = {
  projectId: number;
  projectParentId: number;
};

const useMatchProjectIdFromUrl = (): ProjectIds => {
  const params = useParams<GroupProjectRouteParams>();

  return useMemo(() => {
    const [id] = params.slug.split('-').reverse();

    if (id.startsWith('P')) {
      return {
        projectId: null,
        projectParentId: Number(id.substring(1, id.length)),
      };
    } else {
      return {
        projectId: +id,
        projectParentId: null,
      };
    }
  }, [
    params.slug,
  ]);
};

const useMatchProjectStateFromUrl = (): ProjectState => {
  const ids = useMatchProjectIdFromUrl();

  const project = useSelector(selectProject(ids.projectId)) as Project<LegacyGroupProjectState>;
  const projectParent = useSelector(selectProjectParent(ids.projectParentId ?? project?.parentId));
  const latestProject = useSelector(selectProject(projectParent?.latestChild)) as Project<LegacyGroupProjectState>;

  return {
    latestProject,
    project,
    projectParent,
  };
};

export {
  useMatchProjectIdFromUrl,
  useMatchProjectStateFromUrl,
};
export default useMatchProjectStateFromUrl;
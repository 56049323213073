import cuid from 'cuid';
import { SurveyQuestionConditionType } from '@enums';
import { SurveyQuota } from '@/types';

export function getDefaultBasicItem(): SurveyQuota.Basic.Item {
  return {
    condition: {
      type: SurveyQuestionConditionType.Chosen,
      value: null,
    },
    identifier: cuid(),
    question: null,
  };
}

export function getDefaultAdvancedSet(): SurveyQuota.Advanced.Set {
  return {
    identifier: cuid(),
    items: [],
    question: null,
  };
}

export function getDefaultAdvancedSetItem(): SurveyQuota.Advanced.SetItem {
  return {
    condition: {
      type: SurveyQuestionConditionType.Chosen,
      value: null,
    },
    identifier: cuid(),
    pct: 0,
  };
}
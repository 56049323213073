import { createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import {
  ComplianceOnboardingProps,
  ComplianceOnboardingContextValue,
  QuestionsCompletedParams,
} from './interfaces';

const mapState = (state: Store.State) => ({
  projects: state.projects,
  user: state.user,
});

const ComplianceOnboardingContext = createContext<ComplianceOnboardingContextValue>({
  back:                       () => {},
  next:                       () => {},
  onCompletion:               () => {},
  project:                    null,
});

export const ComplianceOnboarding = (props: ComplianceOnboardingProps) => {
  const state = useSelector(mapState);
  const dispatch = useDispatch();

  const updateUserSettings = (params: QuestionsCompletedParams) => {
    return api.users.updateSettings({
      ...params,
      userId: state.user.id,
    });
  };

  const completeOnboarding = () => {
    return api.users.updateOnboarding()
      .then(res => dispatch(actions.onboardingCompleted({
        completedOn: res.completedOn,
      })));
  };

  const handleCompletion = (data: QuestionsCompletedParams) => {
    return updateUserSettings(data)
      .then(completeOnboarding)
      .then(props.onCompletion);
  };

  const context: ComplianceOnboardingContextValue = {
    back: props.back,
    next: props.next,
    onCompletion: handleCompletion,
    project: props.projectId ? state.projects[props.projectId] : null,
  };

  return (
    <ComplianceOnboardingContext.Provider value={context}>
      {props.children}
    </ComplianceOnboardingContext.Provider>
  );
};

export { ComplianceOnboardingContext };
export default ComplianceOnboarding;
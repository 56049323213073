import { Filters, Loading, Results } from './Containers';

export default function SearchContainer({ children }: ChildrenProps) {
  return (
    <Filters>
      <Results>
        <Loading>
          {children}
        </Loading>
      </Results>
    </Filters>
  );
}

export { SearchContainer };

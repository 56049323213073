import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { Stripe } from '../interfaces';

export function validateNewCardForm(card: StripeCardElementChangeEvent, currentForm: Stripe.NewCardState) {
  const form = resetErrors(currentForm);

  if (!card || card.empty || card.error) {
    return {
      error: card?.error ? card.error.message : 'Please fill out card details.',
      form: {
        ...form,
        cardError: 'Please fill out card details.',
      },
    };
  }

  if (!form || isEmpty(form.name.value)) {
    return {
      error: 'Fill in required fields.',
      form: {
        ...form,
        name: {
          ...form.name,
          error: 'Name is required.',
        },
      },
    };
  }

  for (const field of ['country', 'address1', 'address2', 'city', 'state', 'zip'] as Stripe.AddressFields[]) {
    if (!form.address[field] || (form.address[field].required && isEmpty(form.address[field].value))) {
      return {
        error: 'Fill in required fields.',
        form: {
          ...form,
          address: {
            ...form.address,
            [field]: {
              ...form.address[field],
              error: 'Field is required.',
            },
          },
        },
      };
    }
  }

  return {
    error: null,
    form,
  };
}

function resetErrors(form: Stripe.NewCardState) {
  const { error, ...resetName } = form.name;

  const addressFields = ['country', 'address1', 'address2', 'city', 'state', 'zip'] as Stripe.AddressFields[];

  const resetAddress = addressFields.map(field => {
    const { error, ...resetField } = form.address[field];
    return {
      [field]: resetField,
    } as { [key in Stripe.AddressFields]: Stripe.FormValidatedField };
  })
  .reduce((acc, curr) => ({ ...acc, ...curr }), {} as Stripe.FormAddressItem);

  return {
    cardError: null,
    name: resetName,
    address: resetAddress,
  };
}

function isEmpty(value: string) {
  return !value || !value.trim().length;
}
import { Permission } from '@enums';

export const permissions = {
  admin: {
    dashboard: Permission.InternalAdminDashboard,
    research: Permission.InternalResearchDashboard,
  },
  billing: {
    dashboard: Permission.InternalAdminBillingDashboard,
  },
  developer: {
    buildInfo: Permission.DeveloperBuildInfo,
  },
};

type HasPermission = (user: Pick<Store.User, 'permissions'>) => boolean;

export const hasInternalAdminAccess: HasPermission = user => {
  return user.permissions.some(p => p === permissions.admin.dashboard);
};

export const hasInternalResearchAccess: HasPermission = user => {
  return user.permissions.some(p => p === permissions.admin.research);
};

export const hasInternalAdminBillingAccess: HasPermission = user => {
  return hasInternalAdminAccess(user) &&
         user.permissions.some(p => p === permissions.billing.dashboard);
};

export const hasGroupBillingAdminPermission: HasPermission = user => {
  return user.permissions.includes(Permission.GroupBillingAdmin);
};
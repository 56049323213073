import qs from 'query-string';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { cx } from '@utils';
import { Button } from 'components/Button';
import styles from './style/Link.css';

type Props = {
  classes?: {
    root?: string;
    icon?: string;
  };
  url: string;
};

export const LinkedIn = (props: Props) => {

  const url = qs.stringifyUrl({
    url: shareUrl,
    query: {
      url: props.url,
    },
  });

  return (
    <Button
      className={cx(styles.linkedin, props.classes.root)}
      color="transparent"
      href={url}
      target="_blank"
      variant="brick">
      <LinkedInIcon className={cx(styles.icon, props.classes.icon)} />
      LinkedIn
    </Button>
  );
};

const defaultProps = {
  classes: {},
};

LinkedIn.defaultProps = defaultProps;
LinkedIn.displayName = 'ProjectInviteReferral.Link.LinkedIn';

const shareUrl = `https://www.linkedin.com/sharing/share-offsite/`;
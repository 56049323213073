import { UploadCloud } from 'react-feather';
import { cx } from '@utils';
import { useFileDragDrop } from 'components/FileDragDrop/hooks/useFileDragDrop';
import styles from './style/FilesTableEmptyState.css';

type Props = {
  className?: string;
};

export const FilesTableEmptyState = ({ className }: Props) => {

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.iconWrap}>
        <UploadCloud
          className={styles.icon} />
      </div>
      <div className={styles.title}>Organize your files in Vancery</div>
    </div>
  );
};

type UploadProps = {
  className?: string;
  onUpload: (files: File[]) => void;
};

export const FilesTableEmptyStateUpload = ({ className, onUpload }: UploadProps) => {
  const { dragging, dropRef } = useFileDragDrop({
    onDrop: onUpload,
  });

  return (
    <div
      ref={dropRef}
      className={cx(styles.root, className, {
        [styles.dragging]: dragging,
      })}>
      <div className={styles.iconWrap}>
        <UploadCloud
          className={styles.icon} />
      </div>
      <div className={styles.title}>Organize your files in Vancery</div>
      <div className={styles.text}>Drop files here to get started</div>
    </div>
  );
};

export default FilesTableEmptyState;
import { safeJsonDate } from '@utils';
import {
  WorkspaceGlobalSearchFullProjectResults, WorkspaceGlobalSearchFullResultProject,
  WorkspaceGlobalSearchFullFileResults, WorkspaceGlobalSearchFullResultFile,
  WorkspaceGlobalSearchFullFolderResults, WorkspaceGlobalSearchFullResultFolder,
  WorkspaceGlobalSearchFullMemberResults, WorkspaceGlobalSearchFullResultMember,
  WorkspaceGlobalSearchFullInteractionResults, WorkspaceGlobalSearchFullResultInteraction,
} from '@/types';

export function normalizeSearchFullProjectResults(data: WorkspaceGlobalSearchFullProjectResults<string>): WorkspaceGlobalSearchFullProjectResults {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullProjectResult),
  };
}

function normalizeSearchFullProjectResult(data: WorkspaceGlobalSearchFullResultProject<string>): WorkspaceGlobalSearchFullResultProject {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
}

export function normalizeSearchFullMemberResults(data: WorkspaceGlobalSearchFullMemberResults): WorkspaceGlobalSearchFullMemberResults {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullMemberResult),
  };
}

function normalizeSearchFullMemberResult(data: WorkspaceGlobalSearchFullResultMember): WorkspaceGlobalSearchFullResultMember {
  return data;
}

export function normalizeSearchFullFileResults(data: WorkspaceGlobalSearchFullFileResults<string>): WorkspaceGlobalSearchFullFileResults {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullFileResult),
  };
}

function normalizeSearchFullFileResult(data: WorkspaceGlobalSearchFullResultFile<string>): WorkspaceGlobalSearchFullResultFile {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
}

export function normalizeSearchFullFolderResults(data: WorkspaceGlobalSearchFullFolderResults<string>): WorkspaceGlobalSearchFullFolderResults {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullFolderResult),
  };
}

function normalizeSearchFullFolderResult(data: WorkspaceGlobalSearchFullResultFolder<string>): WorkspaceGlobalSearchFullResultFolder {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
}

export function normalizeSearchFullInteractionResults(data: WorkspaceGlobalSearchFullInteractionResults<string>): WorkspaceGlobalSearchFullInteractionResults {
  if (!data) return null;

  return {
    ...data,
    items: data.items.map(normalizeSearchFullInteractionResult),
  };
}

function normalizeSearchFullInteractionResult(data: WorkspaceGlobalSearchFullResultInteraction<string>): WorkspaceGlobalSearchFullResultInteraction {
  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
    metadata: {
      ...data.metadata,
      startTime: safeJsonDate(data.metadata.startTime),
      endTime: safeJsonDate(data.metadata.endTime),
    },
  };
}
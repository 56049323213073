import { File } from 'react-feather';
import { useDownloadFile } from '@containers';
import styles from './style/PreviewUnavailable.css';

export const PreviewUnavailable = () => {

  const download = useDownloadFile();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <File className={styles.icon} />
        <div className={styles.text}>No preview available</div>
        {/* <div className={styles.download}>Download File</div> */}
      </div>
    </div>
  );
};

export default PreviewUnavailable;
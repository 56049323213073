import { Check, Calendar, Mail } from 'react-feather';
import { useRequestCallWithUser } from '@containers/RequestCall/hooks';
import { formatPossessive } from '@utils';
import { CallProjectIcon } from 'components/icons';
import { Button } from 'components/Button';
import Header from 'components/Modal/Header';
import { useAdHocRequestRecipient } from './Context';
import styles from './style/AdHoc.NextSteps.css';
import commonStyles from './style/AdHoc.css';

export const AdHocCallRequestNextSteps = () => {

  const recipient = useAdHocRequestRecipient();
  const close = useRequestCallWithUser(null);

  return (
    <div className={styles.root}>
      <div className={styles.checkContainer}>
        <div className={styles.checkCircle}>
          <Check className={styles.check} />
        </div>
      </div>

      <Header text="Your call request has been sent!" />

      <div className={styles.whatsNext}>Here’s what comes next:</div>

      <div className={styles.steps}>
        <div className={styles.step}>
          <Calendar className={styles.icon} />
          {recipient.firstName} will receive your request and will propose times for a call.
        </div>
        <div className={styles.step}>
          <Mail className={styles.icon} />
          You will receive an email with {formatPossessive(recipient.firstName)} calendar availabilty to book a time.
        </div>
        <div className={styles.step}>
          <CallProjectIcon className={styles.callIcon} />
          You will receive a calendar invite for the meeting and can speak with {recipient.firstName}!
        </div>
      </div>

      <div className={commonStyles.footer}>
        <Button.Affirmative
          className={commonStyles.btn}
          onClick={close}
          variant="brick"
          title="Done" />
      </div>
    </div>
  );
};

export default AdHocCallRequestNextSteps;
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelectUser } from '@containers';
import * as consts from '@consts';
import * as Stripe from '@screens/Stripe';
import { useUserNewPaymentMethod } from '@utils/api';
import Toast from 'components/Toast';
import styles from './style/NewPayment.css';

export function NewPaymentMethod() {
  const user = useSelectUser();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const saveNewMethod = useUserNewPaymentMethod();

  const handleError = useCallback(() => setLoading(false), [setLoading]);
  const handleSave = useCallback(() => setLoading(true), [setLoading]);

  const handleToken: Stripe.OnTokenEvent = useCallback(token => {
    return saveNewMethod({
      token,
      userId: user.id,
    })
    .then(result => {
      if (!result.success) {
        setLoading(false);
        return Toast.error({
          title: 'Error Adding Payment Method',
          body: result.error,
        });
      }

      Toast.alert({
        title: 'Payment Method Added',
        body: 'Your new payment method has been added!',
      });

      history.replace(consts.path.Settings.UserBilling);
    });
  }, [history, user.id, saveNewMethod, setLoading]);

  return (
    <div className={styles.root}>
      <Stripe.NewCard
        isLoading={loading}
        onError={handleError}
        onSave={handleSave}
        onToken={handleToken} />
    </div>
  );
}

export default NewPaymentMethod;
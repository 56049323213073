import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GroupProjectRouteParams, RouteKey } from '@containers/GroupProject/interfaces';
import { useTrackTabChange } from './useTrackTabChange';
import { useTabRoutes } from './useTabRoutes';

export const useGroupProjectTabViewState = () => {
  const params = useParams<GroupProjectRouteParams>();
  const history = useHistory();
  const routes = useTabRoutes();
  const keys = useMemo(() => routes.map(x => x), [routes]);
  const trackTabChange = useTrackTabChange();

  const tabIndex = useMemo(() => {
    const idx = keys.indexOf(params.tab as RouteKey);

    return idx >= 0 && idx < routes.length
      ? idx
      : 0;
  }, [
    keys,
    params.tab,
    routes.length,
  ]);

  const [index, setIndex] = useState(tabIndex);

  const setTabIndex = useCallback(() => {

    setIndex(tabIndex);
  }, [
    setIndex,
    tabIndex,
  ]);

  const setLocation = useCallback((idx: number) => {
    const key = routes[idx];

    history.push(`/projects/${params.slug}/${key ?? ''}`);
  }, [
    history,
    params,
    routes,
  ]);

  useEffect(() => {

    if (params.tab) {
      setTabIndex();
      trackTabChange(params.tab as RouteKey);
    }

  }, [
    params.tab,
    setTabIndex,
    trackTabChange,
  ]);

  const state = {
    index,
    routes,
  };

  return [state, setLocation] as const;
};
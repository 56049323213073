import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import * as api from '@api';
import { NewsArticle } from '@/types/news';

export const useRecentNews = (options: Options = {}) => {
  const query = useQuery(['recent-news'], async () => {

    const result = await api.news.getRecentArticles();

    return result.items;

  }, options);

  return query;
};

type Options =
  UseQueryOptions<
    NewsArticle[],
    AxiosResponse,
    NewsArticle[]>;
import { useScreeningQuestions } from './hooks';
import { ScreenerBuilderContext } from './Context';

type Props = {
  children: React.ReactNode;
  items?: string[];
};

export const ScreenerBuilderState = (props: Props) => {
  const context = useScreeningQuestions(props.items || []);

  return (
    <ScreenerBuilderContext.Provider value={context}>
      {props.children}
    </ScreenerBuilderContext.Provider>
  );
};

export default ScreenerBuilderState;
import { useMemo } from 'react';
import { RouteProps } from 'react-router-dom';
import { path } from '@consts';
import { qs } from '@utils';
import { TabView } from 'components/TabView';
import { useTabViewQueryState } from 'components/TabView/hooks/useTabViewQueryState';
import { SearchSceneMap, SearchRouteKey } from './interfaces';

export default function SearchTabView({ scenes }: SearchTabViewProps) {
  const [keys, routes] = useMemo(() => {
    const keys = Object.keys(scenes) as SearchRouteKey[];
    const routes = keys.map(k => ({ key: k, ...scenes[k] }));
    return [keys, routes];
  }, [scenes]);

  const [{ index }, updateLocation] = useTabViewQueryState<SearchRouteKey>({
    formatPath,
    routes: keys,
  });

  return (
    <TabView
      index={index}
      onIndexChange={updateLocation}
      routes={routes}
      renderTabView={({ route }) => <route.component />} />
  );
}

function formatPath(tab: SearchRouteKey, location: RouteProps['location']) {
  const search = qs.append(location.search, { tab });
  return `${path.Workspaces.Search}${search}`;
}

export { SearchTabView };

type SearchTabViewProps = {
  scenes: SearchSceneMap;
}
import * as types from '@store/action-types';
import {
  ConferenceAddedState,
  ConferenceAddedStateAction,
  ConferenceUpdatedState,
  ConferenceUpdatedStateAction,
} from '@store/interfaces';

export const conferenceAdded = (data: ConferenceAddedState): ConferenceAddedStateAction => {
  return {
    callId: data.callId,
    conference: data.conference,
    type: types.CONFERENCE_ADDED,
  };
};

export const conferenceUpdated = (data: ConferenceUpdatedState): ConferenceUpdatedStateAction => {
  return {
    callId: data.callId,
    conference: data.conference,
    type: types.CONFERENCE_UPDATED,
  };
};
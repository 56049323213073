import { useMemo } from 'react';
import { useTranscriptSpeakers } from '../context';

export const useTranscriptSpeaker = (speakerIndex: number) => {
  const speakers = useTranscriptSpeakers();

  return useMemo(() => {

    const speaker = speakers[speakerIndex];

    return speaker
      ? speaker.profile.fullname
      : `Speaker ${speakerIndex + 1}`;

  }, [speakers, speakerIndex]);
};
import { useCallback, ChangeEvent } from 'react';
import { useGroupUsersContext } from '@containers/ObjectAccess';
import { GroupUser } from '@/types';
import AutoComplete from 'components/AutoComplete';

type Props = {
  className?: string;
  onSelect: (item: GroupUser) => unknown;
};

function getDisplayText(data: GroupUser) {
  return [data.firstName, data.lastName].join(' ');
}

export const GroupUsersAutocomplete = ({ className, onSelect }: Props) => {
  const { fetch, items } = useGroupUsersContext();

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    fetch(e.target.value);
  }, [fetch]);

  return (
    <div className={className}>
      <AutoComplete
        getItemValue={getDisplayText}
        items={items}
        name="group-users"
        onChange={onChange}
        onSelect={onSelect}
        placeholder="Enter name" />
    </div>
  );

};

export default GroupUsersAutocomplete;
import { useCallback } from 'react';
import * as api from '@api';
import useSearchLoadingDispatch from './useSearchLoadingDispatch';
import useSearchResultsDispatch from './useSearchResultsDispatch';

export default function useSearchFetchAll() {
  const setResults = useSearchResultsDispatch();
  const setLoading = useSearchLoadingDispatch();

  const projects = useCallback(async ({ query }: FetchData) => {
    const result = await api.workspaces.globalSearch.projects({
      query,
      size: 10,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        all: {
          ...prev.all,
          projects: result,
        },
      }));
    }
  }, [setResults]);

  const members = useCallback(async ({ query }: FetchData) => {
    const result = await api.workspaces.globalSearch.members({
      query,
      size: 10,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        all: {
          ...prev.all,
          members: result,
        },
      }));
    }
  }, [setResults]);

  const files = useCallback(async ({ query }: FetchData) => {
    const result = await api.workspaces.globalSearch.files({
      query,
      size: 10,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        all: {
          ...prev.all,
          files: result,
        },
      }));
    }
  }, [setResults]);

  const folders = useCallback(async ({ query }: FetchData) => {
    const result = await api.workspaces.globalSearch.folders({
      query,
      size: 10,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        all: {
          ...prev.all,
          folders: result,
        },
      }));
    }
  }, [setResults]);

  const interactions = useCallback(async ({ query }: FetchData) => {
    const result = await api.workspaces.globalSearch.interactions({
      query,
      size: 10,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        all: {
          ...prev.all,
          interactions: result,
        },
      }));
    }
  }, [setResults]);

  const fetch = useCallback(({ query }: FetchData) => {
    setLoading(prev => ({ ...prev, all: { ...prev.all, loading: true } }));
    Promise.all([
      new Promise<void>(resolve => setTimeout(() => resolve(), 500)),
      projects({ query }),
      members({ query }),
      files({ query }),
      folders({ query }),
      interactions({ query }),
    ])
    .then(() => {
      setLoading(prev => ({ ...prev, all: { loading: false } }));
    });
  }, [projects, members, files, folders, interactions, setLoading]);

  return fetch;
}

export { useSearchFetchAll };

type FetchData = {
  query: string;
};
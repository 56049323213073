import queryString from 'query-string';
import * as enums from '@enums';
import http from '@services/http';
import * as xform from '@transformers';

export const addGroupDocument = data => {
  return http.post(`/admin/groups/${data.groupId}/documents`, data);
};

export const createGroup = data => {
  return http.post(`/admin/groups`, data);
};

export const createUser = data => {
  return http.post(`/admin/groups/${data.groupId}/users`, data);
};

export const getGroupDashboard = data => {
  return http.get(`/admin/groups/${data.groupId}`);
};

export const updateConsultantReferralPaid = data => {
  return http.put(`/admin/members/referrals-paid`, data)
  .then(r => ({
    ...r,
    paidOn: r.paidOn ? new Date(r.paidOn) : null,
  }));
};

export const updateUserLinkedin = data => {
  return http.patch(`/admin/users/${data.userId}/linkedin`, data);
};

export const getKeywords = data => {
  return http
    .get(`/admin/keywords?${queryString.stringify(data)}`)
    .then(d => {
      return d.map(k => ({
        ...k,
        name: xform.titlecase(k.name),
      }));
    });
};

export const updateKeywordVerificationStatus = data => {
  return http.patch(`/admin/keywords/${data.id}`, { verificationStatusId: data.verificationStatusId });
};

export const updateUserSettings = data => {
  return http.patch(`/admin/users/${data.userId}/settings`, data);
};

export const getReferralToken = data => {
  return http.get(`/admin/members/${data.userId}/referral-token`)
  .then(r => ({
    ...(xform.assignLinkUrl({ token: { value: r, typeId: enums.TokenType.Referral }})),
  }));
};

export const updateProjectStatus = data => {
  return http.patch(`/admin/projects/${data.projectId}/status`, data);
};
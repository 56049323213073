import {
  Calendar,
  FileText,
  MessageSquare,
  Phone,
  PieChart,
  Search,
  User,
} from 'react-feather';
import styles from '$website/screens/Main/style/Products.css';
import conferencing from 'static/images/website/products/conferencing.png';
import conferencingMobile from 'static/images/website/products/conferencing-mobile.png';
import messaging from 'static/images/website/products/messaging.png';
import messagingMobile from 'static/images/website/products/messaging-mobile.png';
import profiles from 'static/images/website/products/profiles.png';
import profilesMobile from 'static/images/website/products/profiles-mobile.png';
import search from 'static/images/website/products/search.png';
import searchMobile from 'static/images/website/products/search-mobile.png';
import scheduling from 'static/images/website/products/scheduling.png';
import schedulingMobile from 'static/images/website/products/scheduling-mobile.png';
import surveys from 'static/images/website/products/surveys.png';
import surveysMobile from 'static/images/website/products/surveys-mobile.png';
import transcripts from 'static/images/website/products/transcripts.png';
import transcriptsMobile from 'static/images/website/products/transcripts-mobile.png';
import Product from './Product';
import { ProductMetadata } from './interfaces';

export const products: ProductMetadata[] = [
  {
    id: Product.Surveys,
    img: surveys,
    mobileImg: surveysMobile,
    title: 'Surveys',
    Icon: PieChart,
    helpItems: [
      {
        className: styles.surveysHelpOne,
        description: `View qualitative and quantitvate data in real time surveys.`,
      },
      {
        className: styles.surveysHelpTwo,
        description: `Interactive charts allow researchers to drill down on segments of the population to see who provided a given answer and why.`,
      },
    ],
  },
  {
    id: Product.Search,
    img: search,
    mobileImg: searchMobile,
    title: 'Search',
    Icon: Search,
    helpItems: [
      {
        className: styles.searchHelpOne,
        description: `Find the right individuals for your research with a series of dynamic search filters.`,
      },
      {
        className: styles.searchHelpTwo,
        description: `Our Recommendation Engine retrieves the best professionals from our database for your search query.`,
      },
    ],
  },
  {
    id: Product.Profiles,
    img: profiles,
    mobileImg: profilesMobile,
    title: 'Profiles',
    Icon: User,
    helpItems: [
      {
        className: styles.profileHelpOne,
        description: `View rich profiles to see work experiences of research candidates on the platform.`,
      },
      {
        className: styles.profileHelpTwo,
        description: `View the key skills and topics that a research candidate can speak to before booking calls.`,
      },
    ],
  },
  {
    id: Product.Messaging,
    img: messaging,
    mobileImg: messagingMobile,
    title: 'Messaging',
    Icon: MessageSquare,
    helpItems: [
      {
        className: styles.messagingHelpOne,
        description: `Communicate directly with individuals on the platform with our compliance-friendly messaging tool.`,
      },
    ],
  },
  {
    id: Product.Scheduling,
    img: scheduling,
    mobileImg: schedulingMobile,
    title: 'Scheduling',
    Icon: Calendar,
    helpItems: [
      {
        className: styles.schedulingHelpOne,
        description: `Our calendar tools remove the friction from call booking.`,
      },
      {
        className: styles.schedulingHelpTwo,
        description: `Integrate your work calendar to allow others to book calls around your schedule.`,
      },
    ],
  },
  {
    id: Product.Conferencing,
    img: conferencing,
    mobileImg: conferencingMobile,
    title: 'Conferencing',
    Icon: Phone,
    helpItems: [
      {
        className: styles.conferencingHelpOne,
        description: `Use our video conferencing tools to conduct recorded face to face interviews.`,
      },
      {
        className: styles.conferencingHelpTwo,
        description: `Use our call notes feature to consolidate findings of your research on the platform.`,
      },
    ],
  },
  {
    id: Product.Transcripts,
    img: transcripts,
    mobileImg: transcriptsMobile,
    title: 'Transcripts',
    Icon: FileText,
    helpItems: [
      {
        className: styles.transcriptsHelpOne,
        description: `View call and video recordings after the fact to better understand the key points of your conversations.`,
      },
      {
        className: styles.transcriptsHelpTwo,
        description: `View transcripts of every conversation you have had on the platform.`,
      },
    ],
  },
];

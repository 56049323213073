import { useCallback } from 'react';
import { useProjectSurveyResponsesState, useSurveyQuestionResponsesData } from '@containers/SurveyResponses/hooks';
import * as chart from '@containers/SurveyResponses/utils';
import { SurveyQuestionType } from '@enums';
import { ClosedResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import { formatPlurality } from '@utils';
import { SurveyQuestionOption } from '@/types';
import { useOptionsColors } from './hooks/usePaletteColors';

export const SurveyResponseOptionsAggregateData = () => {

  const { question, responses } = useSurveyQuestionResponsesData<SurveyQuestionType.MultipleChoice>();
  const { setQueryState } = useProjectSurveyResponsesState<SurveyQuestionType.MultipleChoice>();
  const colors = useOptionsColors();

  const handleClick = useCallback((optionId: number) => () => {
    setQueryState({ optionId });
  }, [setQueryState]);

  const renderOption = useCallback((option: SurveyQuestionOption) => {
    const data = responses[option.base.id];
    const color = colors[option.ordinal - 1];
    const subtitle = `(${data.userIds.length} ${formatPlurality('Respondent', data.userIds.length)})`;

    return (
      <ClosedResponseOptionRow
        key={option.base.id}
        onViewAll={handleClick(option.base.id)}
        color={color}
        label={option.value}
        subtitle={subtitle} />
    );
  }, [
    colors,
    handleClick,
    responses,
  ]);

  const items = [...question.options]
    .sort(chart.options.sortOptions(question, responses));

  return (
    <>
      {items.map(renderOption)}
    </>
  );
};

export default SurveyResponseOptionsAggregateData;
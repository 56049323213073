import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchSurveyRespondentAnswers } from '@utils/api';
import { useSelectContact } from '@containers/Store';
import { RespondentAnswers } from '@screens/ProjectSurveyRespondent';
import { Seniority } from '@enums';
import {
  useProjectAnonymity,
  useProjectState,
  useRespondentVersions,
} from './hooks';
import { GroupProjectState } from './GroupProjectContainer';
import { ProjectSurveyRespondentContext } from './Context';

type RouteParams = {
  slug: string;
  projectSurveyResponseId: string;
};

export const ProjectSurveyRespondentState = (props: ChildrenProps) => {

  const [response, fetchRespondentAnswers] = useFetchSurveyRespondentAnswers();

  const params = useParams<RouteParams>();

  const projectSurveyResponseId = +params.projectSurveyResponseId;

  const { project } = useProjectState();
  const anonymity = useProjectAnonymity();
  const contact = useSelectContact(response?.value?.userId);

  const versions = useRespondentVersions(project.parentId, response?.value?.userId);

  const user = useMemo(() => {
    if (!response.value) { return null; }

    if (!anonymity) {
      return contact;
    }

    //contact.career.current
    return {
      id: contact.id,
      profile: { fullname: `Respondent #${response.value.order}`, creditRate: contact.profile.creditRate },
      career: {
        current: contact.career.current ? {
          title: Seniority[contact.career.current.seniority],
        } : null,
        former: contact.career.former ? {
          title: Seniority[contact.career.former.seniority],
        } : null,
      },
    };
  }, [contact, anonymity, response.value]);

  useEffect(() => {

    fetchRespondentAnswers({
      projectId: project.id,
      projectSurveyResponseId,
    });

  }, [
    fetchRespondentAnswers,
    project.id,
    projectSurveyResponseId,
  ]);

  const value = useMemo(() => ({
    projectSurveyResponseId,
    response: response.value,
    user,
    versions,
  }), [
    projectSurveyResponseId,
    response.value,
    user,
    versions,
  ]);

  return (
    <ProjectSurveyRespondentContext.Provider value={value}>
      {props.children}
    </ProjectSurveyRespondentContext.Provider>
  );
};

export const ProjectSurveyRespondentContainer = () => {

  return (
    <GroupProjectState>
      <ProjectSurveyRespondentState>
        <RespondentAnswers />
      </ProjectSurveyRespondentState>
    </GroupProjectState>
  );
};

export default ProjectSurveyRespondentContainer;
import * as consts from '@consts';
import Section from './Section';
import { Bold } from './Span';
import Title from './Title';
import styles from './style/Terms.css';

const Url = {
  ClientTerms: `https://www.vancery.com${consts.path.Website.ClientTerms}`,
  Pricing: `https://www.vancery.com${consts.path.Website.Pricing}`,
};

export const Client = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.terms}>
        <Section>
          <Title value={`Master Subscription Agreement`} />
          <p>{`This Master Subscription Agreement (this “Agreement”) contains terms and conditions that govern your purchase of subscriptions to, and use of, the Services (as defined below), and is a contract between Vancery, Inc. (“Vancery”), and you or the entity or organization that you represent. This Agreement shall be binding and govern the relationship between Vancery and the Customer (as defined below) with respect to any Services provided pursuant to one or more Order Forms adopted on or after July 14, 2021.`}</p>
          <p>{`If you are an individual using the Services for your own purposes: (1) all references to “Customer” are to you and (2) you represent and warrant that you are at least 18 years of age or have otherwise reached the age of “majority” where you reside, and that you have the right, power and authority to enter into this Agreement.`}</p>
          <p>{`If you are using the Services on behalf of an entity or organization that you represent: (1) all references to “Customer” are to that entity or organization and (2) you represent and warrant that you are at least 18 years of age or have otherwise reached the age of “majority” where you reside, and that you have the right, power and authority to enter into this Agreement on behalf of Customer.`}</p>
          <Bold as="p">{`This Agreement becomes binding and effective on Customer upon the earliest of: (1) when you access or use the Services, (2) when you click an “I Accept,” “Sign up” or similar button or check box referencing this Agreement, or (3) when you enter into an Order (as defined below) with Vancery.`}</Bold>
          <p>{`Capitalized terms not otherwise defined in this Agreement will have the respective meanings assigned to them in Section 23. `}</p>
        </Section>
        <Section>
          <Title value="1. Order Forms" />
          <p>{`This Agreement sets forth the terms pursuant to which Customer may access and use Vancery’s Services in connection with one or more Order Forms. Subject to the terms of an Order Form, the Services will support Customer’s analysis, organization, collaboration, and storage of research data and insights pursuant to separate projects within a workspace.`}</p>
        </Section>
        <Section>
          <Title value="2. Access and Use" />
          <p>{`2.1. Subject to the applicable Order Forms and this Agreement, Vancery hereby grants to Customer the right to access and use the Services in accordance with the Documentation during the Order Term.`}</p>
          <p>{`2.2. As between the Parties, Customer controls its own environment and its individual components (each, a “Customer Component”), whether owned, leased or licensed by Customer, located on Customer’s premises or cloud-based, used by Customer on a software-as-a-service basis or otherwise. Customer will be able to use the Services by establishing integrations or other connections to one or more Customer Components (each, a “Connection”). By implementing a Connection to a Customer Component, Customer hereby grants to Vancery the right, and is expressly instructing Vancery, to access and interoperate with that Customer Component during the Order Term in order to provide and support the Services. Customer is responsible for complying with all applicable third-party terms, policies and licenses governing its access and use of Customer Components and associated data (collectively, “Third-Party Terms”).`}</p>
          <p>{`2.3. Through Customer’s configuration and use of Connections and Services, Customer has control over the types and amounts of data from Customer’s own environment that are submitted for Processing by the Services (collectively, “Customer Data”). By submitting Customer Data to the Services, Customer hereby grants to Vancery the right, and is expressly instructing Vancery, to Process Customer Data during the Order Term in order to provide and support the Services and as otherwise provided in this Agreement.`}</p>
          <p>{`2.4. All rights granted by each Party to the other under this Section 2 are limited, nonexclusive and, except as otherwise provided in this Agreement, non-transferable.`}</p>
          <p>{`2.5. Vancery may, from time to time, offer access to beta products, pre-release products, or other experimental features (“Beta Products”). Any obligations or warranties made by Vancery under this Agreement shall not apply with respect to Beta Products. Furthermore, any data, information, content, or other materials provided or displayed to Customer in connection with any Beta Products, or any knowledge that Customer may have from or relating to Beta Products, is Confidential Information of Vancery.`}</p>
        </Section>
        <Section>
          <Title value="3. Hosting and Other Providers" />
          <p>{`Vancery uses third-party hosting providers, other service providers and Vancery Affiliates to support the provision of the Services in the ordinary course of its business, i.e., not specifically for Customer (collectively, “Ordinary Course Providers”). Vancery reserves the right to engage and substitute Ordinary Course Providers as it deems appropriate, but shall: (a) remain responsible to Customer for the provision of the Services and (b) be liable for the actions and omissions of its Ordinary Course Providers undertaken in connection with Vancery’s performance of this Agreement to the same extent Vancery would be liable if performing the Services directly. In no event shall providers of Customer Components be deemed Ordinary Course Providers for any purpose under this Agreement.`}</p>
        </Section>
        <Section>
          <Title value="4. Security and Privacy" />
          <p>{`4.1. Taking into account the nature and types of Customer Data, Vancery will employ administrative, physical and technical measures in accordance with applicable industry practice to protect the Services and prevent the accidental loss or unauthorized access, use, alteration or disclosure of Customer Data under its control during each Order Term. During the Order Term, Vancery will maintain security measures with respect to Customer Data at a level not materially less protective than the security measures taken by Vancery with respect to its own sensitive information.`}</p>
          <p>{`4.2. Customer is responsible for properly configuring the Services in accordance with the Documentation, enabling single sign-on for Customer’s accounts where available, and securing access passwords or other credentials used by Customer in connection with the Services (collectively, “Customer Credentials”). Customer agrees to use reasonable efforts to prevent unauthorized access or use of the Services and to promptly notify Vancery if Customer believes (a) any Customer Credentials have been lost, stolen or made available to an unauthorized third party or (b) an unauthorized third party has accessed the Services or Customer Data.`}</p>
          <p>{`4.3. In limited situations, Vancery may access Customer Data to provide support and troubleshoot issues, provided that Vancery will not use Customer Data for any purpose except the sole purpose of providing the Services. Vancery may further Process information about Customer’s configuration and use of the Services (“Usage Data”), and Account Data: (a) to manage Customer’s account, including to calculate Fees (as defined in Section 7.1); (b) to provide and improve the Services, including to provide support and troubleshoot other issues; and (c) to provide Customer and Authorized Users insights, service and feature announcements and other reporting. Vancery may also Process Usage Data that has been aggregated and/or anonymized (including, for clarity, that does not allow a third party to identify Customer as the source of the information): (i) to develop new services and features and (ii) to promote Vancery’s services, including, for example, through analyses of patterns and trends. Vancery’s Processing of Usage Data, Customer Data and Account Data, as applicable, shall at all times be subject to Vancery’s obligations under this Agreement, including those of security under Section 4.1 and confidentiality under Section 11; the DPA (as defined in Section 6.1), if applicable.`}</p>
        </Section>
        <Section>
          <Title value="5. Customer Responsibilities and Restrictions" />
          <p>{`5.1. Customer will be solely responsible for: (a) Customer’s own environment, including as necessary to enable Authorized Users’ access and use of the Services; (b) Account Data, Customer Data and Customer Credentials (including activities conducted with Customer Credentials), subject to Vancery’s Processing obligations under this Agreement; (c) providing any required notices to, and receiving any required consents and authorizations from, Customer Component providers, Authorized Users and persons whose Personal Information may be included in Account Data, Customer Data or Customer Credentials; and (d) ensuring use of the Services is only for Customer’s own environment and in accordance with this Agreement, any applicable Order Form, Documentation, and any applicable Third-Party Terms.`}</p>
          <p>{`5.2. No provision of this Agreement includes the right to, and Customer shall not, directly or indirectly: (a) enable any person or entity other than Authorized Users to access and use the Services; (b) attempt to gain unauthorized access to any Service or its related systems or networks; (c) use any Service to access Vancery Intellectual Property Rights except as permitted under this Agreement; (d) modify, copy or create any derivative work based upon a Service or any portion, feature or function of a Service; (e) resell, distribute or otherwise make available any Service to any third party, including as part of a managed services offering; (f) except to the extent limited by Applicable Law, reverse engineer, disassemble or decompile all or any portion of, or attempt to discover or recreate the source code for, the Services or access or use the Services or Documentation in order to (1) copy ideas, features, functions or graphics, (2) develop competing products or services, or (3) perform competitive analyses; (g) remove, obscure or alter any proprietary notice related to the Services; (h) send or store Malicious Code; (i) use or permit others to use the Services in violation of Applicable Law; or (j) use or permit others to use the Services other than as described in the applicable Order Form, Documentation and this Agreement.`}</p>
          <p>{`5.3. Vancery reserves the right to investigate potential violations of the above provisions of this Section 5. In the event Vancery reasonably believes a violation has occurred, in addition to any other remedies available at law or in equity (including termination pursuant to Section 13.2), Vancery will have the right to suspend Authorized Users suspected of the violation from accessing the Services for so long as is reasonably necessary to address the potential violation. Except where Vancery reasonably believes the violations are willful, or in urgent or emergency situations, Vancery will notify Customer of any such suspension in advance (each, a “Suspension Notice”) and work with Customer in good faith to resolve the potential violation. For clarity, Vancery reserves the right, but does not assume any obligation to Customer (except with respect to the Suspension Notice), to take any of the actions described in this Section 5.3.`}</p>
        </Section>
        <Section>
          <Title value="6. Compliance" />
          <p>{`6.1. Each Party shall comply with Applicable Laws concerning the privacy and protection of Personal Information. Without limiting Section 5.1, Customer will be solely responsible for providing any notices required by Applicable Law to, and receiving any consents and authorizations required by Applicable Law from, persons whose Personal Information may be included in Account Data, Customer Data or Customer Credentials. If Customer believes Customer Data may include the Personal Information of natural persons located in the European Economic Area and wishes to execute a Data Processing Addendum (“DPA”) pursuant to the GDPR, or if Customer believes Customer Data may include Personal Information of California residents and wishes to enter into additional terms pursuant to the CCPA, Customer may do so by contacting Vancery and the Parties will work together to complete a DPA consistent with Applicable Law.`}</p>
          <p>{`6.2. Each Party shall comply with Applicable Laws concerning anti-bribery and anti-corruption, which may include the U.S. Foreign Corrupt Practices Act of 1977 and the UK Bribery Act 2010. As of the date of this Agreement and the date of each Order Form, Customer represents that it has neither received nor been offered any illegal or improper bribe, kickback, payment, gift or thing of value from any employee, agent or representative of Vancery or its Affiliates in connection with this Agreement. Customer agrees to promptly notify Vancery if it learns of any violation of the foregoing. This representation is not intended to include customary and reasonable gifts and entertainment provided in the ordinary course of business, to the extent such gifts and entertainment are permitted by Applicable Law.`}</p>
          <p>{`6.3. Each Party shall (a) comply with Applicable Laws administered by the U.S. Commerce Bureau of Industry and Security, U.S. Treasury Office of Foreign Assets Control or other governmental entity imposing export controls and trade sanctions (“Export Laws”), including designating countries, entities and persons (“Sanctions Targets”) and (b) not directly or indirectly export, re-export or otherwise deliver Services to a Sanctions Target, or broker, finance or otherwise facilitate any transaction in violation of any Export Laws. Customer represents that it is not a Sanctions Target or prohibited from receiving Services pursuant to this Agreement under Applicable Laws, including Export Laws.`}</p>
          <p>{`6.4. For the avoidance of doubt, each Party is responsible for its own compliance with Applicable Laws (including but not limited to applicable securities laws). To the extent that the Services include assistance from Vancery with respect to ongoing Customer compliance preferences or settings, such assistance from Vancery shall be considered administrative in nature, and Customer understands that it remains solely responsible for its compliance with all Applicable Laws. Customer acknowledges and agrees that the Services shall not constitute investment, legal, compliance, or tax advice under any circumstances.`}</p>
        </Section>
        <Section>
          <Title value="7. Pricing and Fees" />
          <p>{`7.1. Customer agrees to pay all fees charged by Vancery for Customer’s use of Services in accordance with this Agreement and applicable Order Form(s) and Service Plan(s) (collectively, “Fees”). Except as otherwise provided in an Order Form: (a) prices for Services are set forth on the Pricing Page; and (b) Fees must be paid in U.S. dollars and, subject to Section 7.2 and 7.3, within 30 days of invoice. `}</p>
          <p>{`7.2. Except in the event of a good faith dispute under this Section 7.2, if Customer fails to make payment when due, without limiting Vancery’s other rights and remedies: (a) Vancery may charge interest on the past due amount at a rate of 1.5% per month or, if lower, the highest rate permitted under Applicable Law; (b) Customer shall reimburse Vancery for all reasonable costs incurred by Vancery in collecting any late payments or interest, including attorneys’ fees; and (c) if such failure continues, Vancery may suspend Customer’s and its Authorized Users’ access to the Services until such amounts are paid in full. Customer must assert any dispute with regard to Fees in writing within 10 days of receipt of the invoice giving rise to the dispute. Vancery will not exercise its suspension or termination rights or apply interest on late Fees if Customer disputes the applicable charges reasonably and in good faith and provides reasonable cooperation to resolve the dispute.`}</p>
          <p>{`7.3. Vancery offers three (3) payment options to its Customers: (i) Automated Payment – Customer agrees to fund into an associated Stripe account for purposes of funding its research. Payment processing services for a Customer on Vancery are provided by Stripe and are subject to the `}<a href="https://stripe.com/connect-account/legal" rel="noreferrer" target="_blank">Stripe Connected Account Agreement</a>{`, which includes the `}<a href="https://stripe.com/legal" rel="noreferrer" target="_blank">Stripe Terms of Service</a>{`. If a Customer chooses this option, Vancery will draw funds on a same day basis as the Client consumes Services from either a direct payment or a credit card account at Customer’s preference, (ii) Wire/ACH Arrangement – Customer Fees may be payable by the Customer via a check or wire arrangement, and (iii) Third Party Payment – Customer Fees shall be payable by a third party with whom the Customer has a commission sharing arrangement or similar arrangement. Subject at all times to Applicable Law, Vancery shall take instructions from the Customer as to the third party name in which the invoice is to be issued and the name of the payment provider, provided however, that the Customer shall remain liable for all Fees in the event of any non-payment from such third party.`}</p>
        </Section>
        <Section>
          <Title value="8. Order Form Renewal" />
          <p>{`8.1. Unless either Party gives the other Party written notice of its intention not to renew an Order Form at least 30 days prior to the Order Form’s then current expiration date, the Order Form will automatically renew for additional periods of the same duration as the expiring Order Term (each, a “Renewal Order Term”). Vancery reserves the right to increase fees for a Renewal Order Term, with notice to Customer.`}</p>
        </Section>
        <Section>
          <Title value="9. Taxes" />
          <p>{`Customer shall be responsible for all sales, service, value-added, use, excise, consumption and any other Taxes on amounts payable by Customer under the Order Forms and this Agreement (other than any Taxes on Vancery’s income, revenues, gross receipts, personnel or assets). Without limiting the foregoing, if Customer is required to deduct or withhold any Taxes under Applicable Laws outside the United States, Customer shall remit such Taxes in accordance with those Applicable Laws and all Fees payable shall be increased so that Vancery receives an amount equal to the sum it would have received had no withholding or deduction been made.`}</p>
        </Section>
        <Section>
          <Title value="10. Ownership" />
          <p>{`As between the Parties: (a) Customer owns all right, title and interest in and to Customer’s environment and Customer Data, including in each case all associated Intellectual Property Rights, and (b) Vancery owns all right, title and interest in and to the Services, Documentation and Feedback, including in each case all associated Intellectual Property Rights. Except for the rights expressly granted by one Party to the other in this Agreement, and for any market research or similar deliverables prepared and delivered by Vancery to Customer in connection with the Services, all rights are reserved by the granting Party.`}</p>
        </Section>
        <Section>
          <Title value="11. Confidentiality" />
          <p>{`11.1. As used in this Agreement, “Confidential Information” means any information disclosed by one Party, its Affiliates, business partners or their respective employees, agents or contractors (collectively, the “Discloser”) that is designated as confidential, either orally or in writing, or that, given the nature of the information or circumstances surrounding its disclosure, reasonably should be understood to be confidential. Confidential Information includes without limitation: (a) Customer Data; (b) information relating to the Discloser’s or its Affiliates’ technology, customers, business plans, promotional and marketing activities, finances and other business affairs; (c) third-party information that the Discloser is obligated to keep confidential; and (d) the terms of this Agreement and all Order Forms. However, Confidential Information does not include any information that: (i) was known to the Party that receives any Confidential Information (the “Recipient”) prior to receiving the same from the Discloser in connection with this Agreement; (ii) is independently developed by the Recipient without reference to or use of the Discloser’s Confidential Information; (iii) is acquired by the Recipient from another source without restriction as to use or disclosure; or (iv) is or becomes publicly available through no fault or action of the Recipient.`}</p>
          <p>{`11.2. The Recipient shall not (a) use the Discloser’s Confidential Information for any purpose outside the scope of this Agreement without the Discloser’s prior written consent or (b) disclose the Discloser’s Confidential Information to any person or entity, except to the Recipient’s employees, agents, contractors and service providers who (i) are bound by non-use and non-disclosure obligations at least as protective as those contained in this Agreement and (ii) have a need to know the Confidential Information for the Recipient to exercise its rights or perform its obligations under this Agreement. Notwithstanding the foregoing, the Recipient may disclose the Discloser’s Confidential Information to the limited extent any use or disclosure is required by Applicable Law or a valid and binding order of a governmental body (such as a subpoena or court order), provided that, to the extent permitted under Applicable Law, the Recipient uses reasonable efforts to give the Discloser reasonable advance notice thereof to afford the Discloser an opportunity to intervene and seek an order or other appropriate relief for the protection of its Confidential Information. In the event of any breach or threatened breach by the Recipient of its obligations under this Section, the Discloser will be entitled to seek injunctive and other equitable relief to enforce such obligations.`}</p>
        </Section>
        <Section>
          <Title value="12. Disclaimers" />
          <Bold as="p">{`12.1. EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, NEITHER PARTY MAKES ANY WARRANTY OR GUARANTEE OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, AND EACH PARTY SPECIFICALLY DISCLAIMS ALL WARRANTIES, WHETHER IMPLIED, EXPRESS, OR STATUTORY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE OR TRADE PRACTICE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.`}</Bold>
          <Bold as="p">{`12.2. EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, ALL SERVICES, SUPPORT AND ANY OTHER MATERIAL ARE PROVIDED BY VANCERY ON AN “AS IS” AND “AS AVAILABLE” BASIS. VANCERY MAKES NO REPRESENTATION OR WARRANTY, AND HAS NO SUPPORT OBLIGATIONS OR LIABILITY, WITH RESPECT TO ANY CUSTOMER COMPONENT. WITHOUT LIMITING THE OTHER PROVISIONS OF THIS SECTION 12, VANCERY MAKES NO WARRANTY OF ANY KIND THAT THE SERVICES, DOCUMENTATION, ANCILLARY TOOLS OR ANY OTHER MATERIAL, OR RESULTS OF THE USE THEREOF, WILL: (a) MEET CUSTOMER’S OR ANY OTHER PERSON’S REQUIREMENTS; (b) OPERATE WITHOUT INTERRUPTION; (c) ACHIEVE ANY INTENDED RESULT; (d) BE ERROR FREE OR (e) BE COMPATIBLE, WORK WITH OR CONTINUE TO WORK WITH CUSTOMER COMPONENTS. ANY CHANGES TO CUSTOMER COMPONENTS (INCLUDING THEIR UNAVAILABILITY) OR THIRD-PARTY TERMS DURING AN ORDER TERM DO NOT AFFECT CUSTOMER’S OBLIGATIONS UNDER THE APPLICABLE ORDER FORM OR THIS AGREEMENT.`}</Bold>
        </Section>
        <Section>
          <Title value="13. Term and Termination" />
          <p>{`13.1. The term of this Agreement will continue through the expiration or earlier termination of the last Order Form to be in effect.`}</p>
          <p>{`13.2. Subject to Section 7.2, Vancery may terminate any Order Form upon written notice to Customer if Customer fails to pay any amount due under the Order Form, and such failure continues more than 15 days after Vancery’s delivery of written notice. In addition, either Party may terminate all Order Forms and this Agreement, effective on written notice to the other Party, if the other Party materially breaches this Agreement, and such breach (if capable of cure) remains uncured 30 days after the non-breaching Party provides the breaching Party with written notice of such breach.`}</p>
          <p>{`13.3. Upon expiration or earlier termination of an Order Form: (a) subject to Section 13.5, all rights granted to Customer with respect to Services under such Order Form will terminate effective as of the effective date of termination; (b) subject to Section 13.5, Vancery will have no obligation to provide Services to Customer or Authorized Users after the effective date of the termination; and (c) Customer will, subject to Section 13.4, pay to Vancery any Fees payable for Customer’s and any Authorized User’s use of Services through the effective date of the termination, together with all other amounts in accordance with the Order Form and this Agreement.`}</p>
          <p>{`13.4. If an Order Form is terminated early by Customer pursuant to 13.2: (a) Customer shall not be obligated to pay any additional amounts specified in the Order Form following the effective date of termination and (b) Vancery will refund to Customer a pro rata share of any unused amounts prepaid by Customer under the applicable Order Form for the Services on the basis of the remaining portion of the current Order Term (a “Pro-Rated Refund”). In all other cases, and regardless of whether Customer uses the Services at the levels reflected in the Order Forms or otherwise, Customer will not be entitled to a refund of Fees paid and any unpaid Fees outstanding will become immediately due and payable.`}</p>
          <p>{`13.5. The provisions set forth in the following Sections, and any other right or obligation of the Parties in this Agreement that, by its nature, should survive termination or expiration of this Agreement, will survive any expiration or termination of this Agreement: 4.3, 5.2, 6, 9, 12, and 13 through 26.`}</p>
        </Section>
        <Section>
          <Title value="14. Indemnification" />
          <p>{`14.1. Subject to Sections 14.2 and 14.4, Vancery agrees to defend, indemnify and hold harmless Customer and its employees, contractors, agents, officers and directors (collectively, “Customer Indemnitees”), from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including without limitation attorneys’ fees) (collectively, “Losses”) arising out of or related to any legal claim, suit, action or proceeding (each, an “Action”) by a third party alleging use of the Services as permitted under this Agreement infringes such third party’s United States patent or copyright, or misappropriates such third party’s trade secrets (each, a “Customer Infringement Claim”).`}</p>
          <p>{`14.2. If the Services become, or in Vancery’s opinion are likely to become, the subject of a Customer Infringement Claim, Vancery may in its discretion and at its own expense: (a) obtain for Customer the right to continue using the Services; (b) modify the Services so that they no longer infringe or misappropriate; or (c) terminate this Agreement and all Order Forms and issue a Pro-Rated Refund. Vancery will have no obligation to indemnify Customer for a Customer Infringement Claim to the extent it arises from any of the following (collectively, “Customer-Controlled Matters”): (i) Customer’s own environment, including Connections to Customer Components; (ii) Account Data, Customer Data or Customer Credentials (including activities conducted with Customer Credentials), subject to Vancery’s Processing obligations under this Agreement; or (iii) use of the Services by Customer or an Authorized User in a manner that breaches an Order Form, Service Plan or this Agreement. SECTIONS 14.1 AND 14.2 STATE VANCERY’S ENTIRE LIABILITY AND CUSTOMER’S EXCLUSIVE REMEDIES FOR ANY CLAIM OF INTELLECTUAL PROPERTY RIGHTS INFRINGEMENT OR MISAPPROPRIATION.`}</p>
          <p>{`14.3. Subject to Section 14.4, Customer agrees to defend, indemnify and hold harmless Vancery, its Affiliates and their employees, contractors, agents, officers and directors (collectively, “Vancery Indemnitees”), from and against any and all Losses arising out of or related to any Action by a third party arising out of or relating to Customer-Controlled Matters.`}</p>
          <p>{`14.4. A Customer Indemnitee or Vancery Indemnitee (each, an “Indemnitee”) seeking indemnification shall promptly notify the other Party (each, an “Indemnifying Party”), in writing of any Action for which it seeks indemnification under this Agreement and cooperate with the Indemnifying Party at the Indemnifying Party’s expense. The Indemnifying Party shall promptly take control of the defense and investigation of such Action and shall employ counsel of its choice to handle and defend the same, at the Indemnifying Party’s expense. An Indemnitee may participate in and observe the proceedings at its own expense with counsel of its own choice. A Party’s failure to perform any obligations under this Section 14.4 will not relieve the Indemnifying Party of its obligations under this Agreement, except to the extent that the Indemnifying Party can demonstrate that it has been materially prejudiced as a result of such failure. The Indemnifying Party shall not settle an Action without the Indemnitee’s written consent if such settlement shall require action or payment by the Indemnitee.`}</p>
        </Section>
        <Section>
          <Title value="15. Limitations of Liability" />
          <Bold as="p">{`TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT AS OTHERWISE PROVIDED IN THIS SECTION 15: (a) IN NO EVENT SHALL EITHER PARTY, ITS AFFILIATES OR THEIR EMPLOYEES, AGENTS, CONTRACTORS, OFFICERS OR DIRECTORS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR BUSINESS INTERRUPTION, LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES ARISING OUT OF OR RELATING TO THIS AGREEMENT; AND (b) IN NO EVENT SHALL EITHER PARTY’S CUMULATIVE AND AGGREGATE LIABILITY UNDER THIS AGREEMENT EXCEED THE FEES PAID TO VANCERY BY CUSTOMER UNDER THE APPLICABLE ORDER FORM(S), INCLUDING PRIOR ORDER FORMS FOR THE SAME SERVICES, IN THE 12 MONTHS PRECEDING THE EVENT GIVING RISE TO THE LIABILITY. THE LIMITATIONS IN THIS SECTION APPLY WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER BASIS, EVEN IF THE NON-BREACHING PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE PROVISIONS OF THIS SECTION 15 ALLOCATE THE RISKS UNDER THIS AGREEMENT BETWEEN THE PARTIES, AND THE PARTIES HAVE RELIED ON THE EXCLUSIONS IN DETERMINING TO ENTER INTO THIS AGREEMENT AND THE PRICING FOR THE SERVICES.`}</Bold>
        </Section>
        <Section>
          <Title value="16. Publicity" />
          <p>{`Neither Party shall, except as otherwise required by Applicable Law or stock exchange requirements, issue or release any announcement, statement, press release or other publicity or marketing materials relating to this Agreement or otherwise use the other Party’s marks or logos without the prior written consent of the other Party; provided, however, that Vancery may include Customer’s name and logo in its lists of Vancery customers, its public website and other promotional material.`}</p>
        </Section>
        <Section>
          <Title value="17. Notices" />
          <p>{`Subject to change pursuant to this Section: (a) Vancery’s physical address for notices is ${consts.vancery.addressLine1}, ${consts.vancery.addressLine2}, and its email address for notices is ${consts.vancery.email}. and (b) Customer’s physical and email addresses for notices are those associated with its Order Form(s). Notices required or permitted to be given under this Agreement shall be in writing and shall be deemed to be sufficiently given: (i) one business day after being sent by overnight courier to the Party’s physical address; (ii) three business days after being sent by registered mail, return receipt requested, to the Party’s physical address; or (iii) one business day after being sent by email to the Party’s email address (provided that (1) the sender does not receive a response that the message could not be delivered or an out-of-office reply and (2) any notice for an Indemnifiable Action must be sent by courier or mail pursuant to clause (i) or (ii)). Either Party may change its address(es) for notice by providing notice to the other in accordance with this Section.`}</p>
        </Section>
        <Section>
          <Title value="18. Assignments" />
          <p>{`So long as Customer remains current in the payment of all amounts when due, Customer may assign this Agreement in connection with any merger, consolidation or reorganization involving Customer (regardless of whether Customer is a surviving or disappearing entity), or a sale of all or substantially all of Customer’s business or assets relating to this Agreement to an unaffiliated third party. Subject to the foregoing, Customer may not assign any of its rights or obligation under this Agreement, whether by operation of law or otherwise, without Vancery’s prior written consent, and any purported assignment in violation of this Section is void. Vancery may freely assign this Agreement. This Agreement is binding upon and inures to the benefit of the Parties hereto and their respective permitted successors and assigns.`}</p>
        </Section>
        <Section>
          <Title value="19. Independent Parties; No Third-Party Beneficiaries" />
          <p>{`The Parties expressly understand and agree that their relationship is that of independent contractors. Nothing in this Agreement shall constitute one Party as an employee, agent, joint venture partner or servant of another. This Agreement is for the sole benefit of the Parties hereto and their respective successors and permitted assigns and nothing herein, express or implied, is intended to or shall confer on any other person any legal or equitable right, benefit or remedy of any nature whatsoever under or by reason of this Agreement.`}</p>
        </Section>
        <Section>
          <Title value="20. Force Majeure" />
          <p>{`Except for a Party’s obligation to pay Fees, neither Party shall be liable or responsible to the other Party, nor be deemed to have defaulted under or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement (except for any obligations to make payments), when and to the extent such failure or delay is caused by acts of God; pandemics, plagues, viruses, pestilences, environmental disasters, flood, fire or explosion; war, terrorism, invasion, riot or other civil unrest; embargoes or blockades in effect on or after the date of this Agreement; or national or regional emergency (each of the foregoing, a “Force Majeure Event”), in each case, provided the event is outside the reasonable control of the affected Party, the affected Party provides prompt notice to the other Party, stating the period of time the occurrence is expected to continue, and the affected Party uses diligent efforts to end the failure or delay and minimize the effects of such Force Majeure Event.`}</p>
        </Section>
        <Section>
          <Title value="21. Governing Law; Venue" />
          <p>{`In the event of any dispute arising under this Agreement or Documentation, the Parties hereby agree to appoint a mutually acceptable arbitrator and further agree and undertake to settle such issues by the said arbitrator, and the decision arrived at by the arbitrator shall be final, conclusive, and binding on the Parties. The seat of the arbitration shall be in New York, New York, USA. `}</p>
        </Section>
        <Section>
          <Title value="22. Miscellaneous" />
          <p>{`This Agreement, together with all Order Forms, Documentation, and, as and if applicable, the DPA, is the complete and exclusive statement of the agreement between the Parties and supersedes all proposals, questionnaires and other communications and agreements between the Parties (oral or written) relating to the subject matter of this Agreement. Any terms and conditions of any other instrument issued by Customer in connection with this Agreement which are in addition to, inconsistent with or different from the terms and conditions of this Agreement shall be of no force or effect. Additionally, this Agreement supersedes any confidentiality, non-disclosure, evaluation or trial agreement previously entered into by the Parties with respect Customer’s or an Affiliate’s evaluation of the Services or otherwise with respect to the Services. Except as otherwise provided in Section 25, this Agreement may be modified only by a written instrument duly executed by authorized representatives of the Parties. The failure of a Party to exercise or enforce any condition, term or provision of this Agreement will not operate as a waiver of such condition, term or provision. Any waiver by either Party of any condition, term or provision of this Agreement shall not be construed as a waiver of any other condition, term or provision. If any provision of this Agreement is held invalid or unenforceable, the remainder of the Agreement shall continue in full force and effect. The headings in this Agreement are for reference only and shall not affect the interpretation of this Agreement. For purposes of this Agreement, the words “include,” “includes” and “including” are deemed to be followed by the words “without limitation”; the word “or” is not exclusive; and the words “herein,” “hereof,” “hereby,” “hereto” and “hereunder” refer to this Agreement as a whole.`}</p>
        </Section>
        <Section>
          <Title value="23. Definitions" />
          <p>{`Capitalized terms not otherwise defined in this Agreement shall have the respective meanings assigned to them in this Section 23.`}</p>
          <p>{`“`}<Bold>Account Data</Bold>{`” means information about Customer that Customer provides to Vancery in connection with the creation or administration of its Vancery account, such as first and last name, user name and email address of an Authorized User or Customer’s billing contact. Customer shall ensure that all Account Data is current and accurate at all times during the applicable Order Term, and shall in no event include Sensitive Information in Account Data.`}</p>
          <p>{`“`}<Bold>Affiliate</Bold>{`” means, with respect to a Party, a business entity that directly or indirectly controls, is controlled by or is under common control with, such Party, where “control” means the direct or indirect ownership of more than 50% of the voting securities of a business entity.`}</p>
          <p>{`“`}<Bold>Applicable Laws</Bold>{`” means any and all governmental laws, rules, directives, regulations or orders that are applicable to a particular Party’s performance under this Agreement.`}</p>
          <p>{`“`}<Bold>Authorized User</Bold>{`” means an individual employee, workspace administrator, agent or contractor of Customer for whom subscriptions to Services have been purchased pursuant to the terms of the applicable Order Form and this Agreement, and who has been supplied user credentials for the Services by Customer (or by Vancery at Customer’s request).`}</p>
          <p>{`“`}<Bold>CCPA</Bold>{`” means the California Consumer Privacy Act of 2018, sections 1798.100 through 1798.199 of the California Civil Code.`}</p>
          <p>{`“`}<Bold>Documentation</Bold>{`” means Vancery’s standard user documentation for the Services, including this Agreement and any documented Order Form.`}</p>
          <p>{`“`}<Bold>Feedback</Bold>{`” means bug reports, suggestions or other feedback with respect to the Services or Documentation provided by Customer to Vancery, exclusive of any Customer Confidential Information therein.`}</p>
          <p>{`“`}<Bold>GDPR</Bold>{`” means the General Data Protection Regulation 2016 / 679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing of Directive 95/46/EC.`}</p>
          <p>{`“`}<Bold>Intellectual Property Rights</Bold>{`” means any and all registered and unregistered rights granted, applied for, or otherwise now or hereafter in existence under or related to any patent, copyright, trademark, trade secret, database protection, or other intellectual property rights laws, and all similar or equivalent rights or forms of protection, in any part of the world.`}</p>
          <p>{`“`}<Bold>Malicious Code</Bold>{`” means viruses, worms, time bombs, Trojan horses and other harmful or malicious code, files, scripts, agents or programs.`}</p>
          <p>{`“`}<Bold>Order Form</Bold>{`” means a separate order, checkout page, purchase, or statement of work for Services pursuant to this Agreement: (a) completed and submitted by Customer online at the Vancery website and accepted by Vancery or (b) executed by Vancery and Customer.`}</p>
          <p>{`“`}<Bold>Order Term</Bold>{`” means, with respect to each Order Form, the initial subscription term for the Services specified in the applicable Order Form and all Renewal Order Terms, if any. In the event an Order Form does not specify a fixed term, then the Order Term will run from the Order Form’s effective date until the end of the calendar month in which either Party gives notice of termination in accordance with Section 17, unless the Order Form is otherwise terminated earlier in accordance with this Agreement or the Order Form.`}</p>
          <p>{`“`}<Bold>Party</Bold>{`” means each of Vancery and Customer.`}</p>
          <p>{`“`}<Bold>Personal Information</Bold>{`” means information relating to an identified or identifiable natural person that is protected by Applicable Laws with respect to privacy where the individual resides.`}</p>
          <p>{`“`}<Bold>Pricing Page</Bold>{`” means the publicly available web page(s) where Vancery publishes its list prices for Services, currently available at`} <a href={Url.Pricing}>{Url.Pricing}</a></p>
          <p>{`“`}<Bold>Process</Bold>{`” means to perform an operation or set of operations on data, content or information, including to submit, transmit, post, transfer, disclose, collect, record, organize, structure, store, adapt or alter; “Processing” has a correlative meaning.`}</p>
          <p>{`“`}<Bold>Sensitive Information</Bold>{`” means the following categories of Personal Information: (a) government-issued identification numbers, including Social Security numbers; (b) financial account data; (c) biometric, genetic, health or insurance data; (d) financial information; (e) data revealing race, ethnicity, political opinions, religion, philosophical beliefs or trade union membership; (f) data concerning sex life or sexual orientation; and (g) data relating criminal convictions and offenses. Without limiting the foregoing, the term “Sensitive Information” includes Personal Information that is subject to specific or heightened requirements under Applicable Law or industry standards, such as Social Security numbers in the United States, protected health information under the U.S. Health Insurance Portability and Accountability Act, nonpublic personal information under the U.S. Gramm-Leach-Bliley Act, cardholder data under the PCI Data Security Standard, and special categories of personal data under the GDPR.`}</p>
          <p>{`“`}<Bold>Service Plan</Bold>{`” means the packaged plan and associated features, as detailed at the Pricing Page, for the hosted Vancery Service to which Customer subscribes.`}</p>
          <p>{`“`}<Bold>Services</Bold>{`” means the hosted services to which Customer subscribes through, or otherwise uses following, an Order Form that are made available by Vancery, including without limitation any free trials of the Services. Vancery may make such changes to the Services as Vancery deems appropriate from time to time, provided such changes do not materially decrease the features or functionality of the Services as they existed at the effective date of this Agreement. Notwithstanding the foregoing, Vancery may make any modifications, in its sole discretion, to any Beta Products, including without limitation removing certain functionalities or discontinuing the availability of such Beta Products.`}</p>
        </Section>
        <Section>
          <Title value="24. Counterparts" />
          <p>{`Any written Order Form may be executed in counterparts, each of which shall be deemed an original, but all of which together shall be deemed to be one and the same agreement. Delivery of an executed counterpart of a signature page to an Order Form by email of a scanned copy, or execution and delivery through an electronic signature service (such as DocuSign), shall be effective as delivery of an original executed counterpart of the relevant Order Form.`}</p>
        </Section>
        <Section>
          <Title value="25. Changes to this Agreement" />
          <p>{`Vancery may modify this Agreement at any time by posting a revised version at`} <a href={Url.ClientTerms}>{Url.ClientTerms}</a> {`which modifications will become effective as of the first day of the calendar month following the month in which they were first posted; provided, however, that if an Order Form specifies a fixed term of 12 months or longer, the modifications will instead be effective immediately upon the start of the next Renewal Order Term. In either case, if Customer objects to the updated Agreement, as its sole and exclusive remedy, Customer may choose not to renew. For the avoidance of doubt, any Order Form is subject to the version of the Agreement in effect at the time of the Order Form.`}</p>
        </Section>
        <Section>
          &copy; {new Date().getFullYear()} Vancery. All rights reserved.
        </Section>
      </div>
    </div>
  );
};

Client.displayName = 'Legal.Terms.Client';
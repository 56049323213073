import { forwardRef } from 'react';
import * as api from '@api';
import * as enums from '@enums';
import { Chip } from '@presentation/Chip';
import { useProjectFormContext, useProjectFormErrorContext } from '@screens/ProjectCreation/context';
import { Header, Subtitle, Title } from '@screens/ProjectCreation/Header';
import { Title as TitleType, VICS, ICompanies } from '@/types';
import { SelectAsync } from 'components/Select/SelectAsync';
import AutocompleteSearch from 'components/AutoComplete/Search';
import AutocompleteVICS from 'scenes/vics-selection/VICSAutocomplete';
import { useSubmitNewProject } from './hooks/useSubmitNewProject';
import { SearchQualityMeter } from './SearchQualityMeter';
import { BackButton, Footer, NextButton } from './Footer';
import { ProjectFiltersProps } from './interfaces';
import styles from './style.css';
import * as utils from './utils';

type Props =
  ProjectFiltersProps;

const ProjectFilters = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [form] = useProjectFormContext();
  const { errors } = useProjectFormErrorContext();
  const [submitProject, isSubmitting] = useSubmitNewProject();

  const handler = {
    currentCompanies: props.getFilterHandler('currentCompanies'),
    formerCompanies: props.getFilterHandler('formerCompanies'),
    suggested: props.getFilterHandler('suggested'),
    titles: props.getFilterHandler('titles'),
    vics: props.getFilterHandler('vics'),
  };

  const getLimit = utils.getSuggestedItemsLimit({
    filters: props.filters,
    vics: form.vics,
  });

  function getVICSIdsByType(type: enums.VICS) {
    return utils.getVICSIdsByType(form.vics, type);
  }

  function getVICSItemsByType(type: enums.VICS) {
    return utils.getVICSItemsByType(form.vics, type);
  }

  function getSuggestedVICSByType(type: enums.VICS) {
    return utils.getVICSItemsByType(form.suggested, type).slice(0, getLimit(type));
  }

  function getUnselectedSectors(options: VICS[]) {
    const selectedIds = utils.getVICSIdsByType(form.vics, enums.VICS.Sector);
    const suggestedIds = utils.getVICSIdsByType(form.suggested, enums.VICS.Sector);

    return options.filter(x => !suggestedIds.concat(selectedIds).includes(x.id));
  }

  return (
    <>
      <div className={styles.section}>
        <div className={styles.root}>
          <div className={styles.wrap}>
            <Header className={styles.row}>
              <Title className={styles.row}>Search Filters</Title>
              <Subtitle className={styles.row}>Select the following to filter relevant profiles for this projects.</Subtitle>
            </Header>

            <div className={styles.row}>
              <div className={styles.header}>
                <div className={styles.title}>Current Company</div>
              </div>
              <AutocompleteSearch
                className={styles.input}
                name={enums.SearchType.Company}
                placeholder="Enter Current Companies"
                items={form.currentCompanies}
                onChange={api.autocomplete.company}
                onSelect={handler.currentCompanies.onAdd}
                ref={ref} />

              <div className={styles.tags}>
                <Tags
                  items={form.currentCompanies}
                  onClick={handler.currentCompanies.onRemove} />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.header}>
                <div className={styles.title}>Former Company</div>
              </div>
              <AutocompleteSearch
                className={styles.input}
                name="formerCompany"
                placeholder="Enter Former Companies"
                items={form.formerCompanies}
                onChange={api.autocomplete.company}
                onSelect={handler.formerCompanies.onAdd} />

              <div className={styles.tags}>
                <Tags
                  items={form.formerCompanies}
                  onClick={handler.formerCompanies.onRemove} />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.header}>
                <div className={styles.title}>Sector</div>
                <div className={styles.error}>{errors.sector}</div>
              </div>
              <div className={styles.input}>
                <SelectAsync
                  loadOptions={() => api.search.fetchSectorFields().then(({ items }) => items)}
                  getItemValue={x => x.name}
                  onChange={getUnselectedSectors}
                  onSelect={handler.vics.onAdd}
                  placeholder="Enter Sectors"
                  value="" />
              </div>

              <div className={styles.tags}>
                <Tags
                  items={getVICSItemsByType(enums.VICS.Sector)}
                  onClick={handler.vics.onRemove} />
                <SuggestedTags
                  items={getSuggestedVICSByType(enums.VICS.Sector)}
                  onClick={handler.suggested.onAdd} />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.header}>
                <div className={styles.title}>Industry</div>
                <div className={styles.error}>{errors.industry}</div>
              </div>
              <AutocompleteVICS
                autocompleteClass={styles.input}
                onSelection={handler.vics.onAdd}
                parentIds={getVICSIdsByType(enums.VICS.Sector)}
                selectedItems={getVICSItemsByType(enums.VICS.Industry)}
                vicsType={enums.VICS.Industry} />
              <div className={styles.tags}>
                <Tags
                  items={getVICSItemsByType(enums.VICS.Industry)}
                  onClick={handler.vics.onRemove} />
                <SuggestedTags
                  items={getSuggestedVICSByType(enums.VICS.Industry)}
                  onClick={handler.suggested.onAdd} />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.header}>
                <div className={styles.title}>Sub-Industry</div>
                <div className={styles.error}>{errors.subindustry}</div>
              </div>
              <AutocompleteVICS
                autocompleteClass={styles.input}
                onSelection={handler.vics.onAdd}
                parentIds={getVICSIdsByType(enums.VICS.Industry)}
                selectedItems={getVICSItemsByType(enums.VICS.SubIndustry)}
                vicsType={enums.VICS.SubIndustry} />
              <div className={styles.tags}>
                <Tags
                  items={getVICSItemsByType(enums.VICS.SubIndustry)}
                  onClick={handler.vics.onRemove} />
                <SuggestedTags
                  items={getSuggestedVICSByType(enums.VICS.SubIndustry)}
                  onClick={handler.suggested.onAdd} />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.header}>
                <div className={styles.title}>Job Title</div>
              </div>
              <AutocompleteSearch
                className={styles.input}
                name={enums.SearchType.Title}
                minimumCharacters={2}
                placeholder="Enter Job Titles"
                items={form.titles}
                onChange={api.autocomplete.title}
                onSelect={handler.titles.onAdd} />
              <div className={styles.tags}>
                <Tags
                  items={form.titles}
                  onClick={handler.titles.onRemove} />
              </div>
            </div>
          </div>

          <SearchQualityMeter value={utils.calculateFiltersQuality(form)} />

        </div>
      </div>
      <Footer>
        <BackButton />
        <NextButton
          className={styles.complete}
          disabled={isSubmitting}
          onClick={submitProject}>
          Complete
        </NextButton>
      </Footer>
    </>
  );
});

type TagItem = VICS | ICompanies.BaseCompany | TitleType;

type TagsProps = {
  items: TagItem[];
  onClick: (item: TagItem) => unknown;
};

function SuggestedTags(props: TagsProps) {
  return (
    <>
      {props.items.map(x =>
        <Chip
          className={styles.suggested}
          key={x.id}
          color='basic'
          onClick={() => props.onClick(x)}
          clickable>
          <Chip.Body.Action variant='add' onClick={() => props.onClick(x)}>
            {x.name}
          </Chip.Body.Action>
        </Chip>)}
    </>
  );
}

function Tags(props: TagsProps) {
  return (
    <>
      {props.items.map(x =>
        <Chip
          className={styles.tag}
          key={x.id}
          color='selected'
          onClick={() => props.onClick(x)}
          clickable>
          <Chip.Body.Action variant='remove' onClick={() => props.onClick(x)}>
            {x.name}
          </Chip.Body.Action>
        </Chip>)}
    </>
  );
}

export { ProjectFilters };
export default ProjectFilters;
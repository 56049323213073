import QuestionItemWithLogic from './QuestionItemWithLogic';
import { SurveyPreviewWithLogicProps } from './interfaces';
import styles from './style/SurveyPreview.css';

export const SurveyPreviewWithLogic = (props: SurveyPreviewWithLogicProps) => {
  return (
    <div>
      {
        props.items.map(item =>(
          <QuestionItemWithLogic
            key={item.ordinal}
            className={styles.item}
            item={item}
            includeTagging={props.includeTagging} />
        ))
      }
    </div>
  );
};

export default SurveyPreviewWithLogic;
import { useMemo } from 'react';
import { useSurveyFormContext } from '../Context';

export const useSurveyStepperNavigation = (ordinal: number) => {
  const form = useSurveyFormContext();

  const isFirstQuestion = useMemo(() => {
    return ordinal === 1;
  }, [ordinal]);

  const showComplete = useMemo(() => {
    return ordinal === Math.max(...(form.questions.map(m => m.ordinal)));
  }, [ordinal, form.questions]);

  return {
    showBack: !isFirstQuestion,
    showComplete,
  };
};

export default useSurveyStepperNavigation;

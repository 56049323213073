import { useProjectOnboardingContext, useSubmitOnboardingCalendar } from '@containers/ProjectOnboarding/hooks';
import { useSelectUser, useSelectProject } from '@containers/Store';
import SchedulingInvitation from '@screens/Scheduling/ProjectOnboarding';
import SchedulingTutorial from '@screens/SchedulingTutorial';

type Props = unknown;

export const InvitationCalendar = (props: Props) => {
  const ctx = useProjectOnboardingContext();
  const submit = useSubmitOnboardingCalendar(ctx.projectId);
  const { id: userId } = useSelectUser();
  const project = useSelectProject(ctx.projectId);

  return (
    <SchedulingInvitation
      creatorId={project.creatorId}
      scheduleeId={project.creatorId}
      schedulerId={userId}
      projectId={ctx.projectId}
      onSubmit={submit}
      userId={userId} />
  );
};

export const Tutorial = (props: Props) => {
  const ctx = useProjectOnboardingContext();

  return (
    <SchedulingTutorial
      nextScreen={ctx.next} />
  );
};
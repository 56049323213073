import React, { useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import * as api from '@api';
import { useSelectGroup } from '@containers/Store';
import { qs } from '@utils';

type Props = unknown;

export const HubSpot = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const group = useSelectGroup();

  const queryparams = qs.parse<QueryParams>(location.search);

  useEffect(() => {

    if (queryparams?.code) {
      api.groups.apps.hubspot.connect({
        code: queryparams.code,
        groupId: group.id,
      })
      .then(res => {
        console.log(res);
        if (queryparams.state) {
          history.push(queryparams.state);
        }
      });
    }

  }, [
    group?.id,
    queryparams?.code,
    queryparams?.state,
    history,
  ]);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: `100vh`,
      width: `100vw`,
      backgroundColor: `#002d32`,
      color: `#aaffaa`,
      fontSize: 32,
    }}>
      Loading...
    </div>
  );
};

HubSpot.displayName = 'HubSpot';

type QueryParams = {
  code:  string;
  state: string;
};
import { useCallback, useState } from 'react';
import { Ranking } from '@enums';
import { ButtonActivityIndicator } from '@presentation';
import { useAsyncStateLazy } from '@utils';
import * as api from '$admin/api';
import { MemberRankingInput } from '$admin/components/MemberRankingInput';
import AutoComplete from 'components/AutoComplete';
import { ButtonSet } from 'components/Modal/ButtonSet';
import { Header } from 'components/Modal/Header';
import { Modal, ModalProps } from 'components/Modal/Modal';
import styles from './style/Project.RecommendMember.css';

type Props = {
  projectId: number;
  onConfirm?: () => unknown;
} & Pick<ModalProps, 'open' | 'onClose'>;

export function RecommendMember({ projectId, open, onClose, onConfirm }: Props) {
  const [items, setItems] = useState<Descriptor[]>([]);
  const [message, setMessage] = useState('');
  const [inputs, setInputs] = useState<InputsState>(defaultInputsState);
  const [ranking, setRanking] = useState(Ranking.Normal);

  const fetchUsers = useCallback((text: string) => {
    api.members.search.name({
      name: text,
      projectId,
    })
    .then(data => {
      const items = data.items.map(x => ({
        id: x.id,
        name: `${x.profile.fullname} (${x.contact.email})`,
      }));

      setItems(items);
    });
  }, [
    projectId,
  ]);

  const handleChangeRanking = useCallback((e: React.ChangeEvent<{ name: string; value: Ranking; }>) => {
    const value = e.target.value
        ? +e.target.value
        : ranking;

    setRanking(value);
  }, [
    ranking,
  ]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;

    if (val.length) {
      setInputs({
        search: val,
        selected: null,
      });
      fetchUsers(val);
    } else {
      setItems([]);
      setInputs({
        search: val,
        selected: null,
      });
    }
  }, [
    fetchUsers,
  ]);

  const handleMessageInput = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value.trim());
  }, []);

  const handleItemSelected = useCallback((item: Descriptor) => {
    setInputs({
      search: item.name,
      selected: item,
    });
  }, []);

  const handleSubmit = useCallback(() => {
    return api.projects.members.recommendUser({
      message,
      projectId: projectId,
      ranking,
      userId: inputs.selected.id,
    })
    .then(onConfirm);
  }, [
    inputs.selected?.id,
    message,
    onConfirm,
    projectId,
    ranking,
  ]);

  const [response, submit] = useAsyncStateLazy(handleSubmit, [handleSubmit]);

  return (
    <Modal
      onClose={onClose}
      open={open}>
      <div className={styles.root}>
        <Header text="Add Member to Project" />

        <div className={styles.body}>
          <div className={styles.row}>
            <div className={styles.label}>Name</div>
            <div className={styles.autocomplete}>
              <AutoComplete
                autoFocus
                getItemValue={(item: Descriptor) => item.name}
                item={inputs.selected}
                items={items}
                name="members"
                onChange={handleInputChange}
                onSelect={handleItemSelected}
                placeholder="Search Members"
                value={inputs.search} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Project Bio</div>
            <div className={styles.field}>
              <textarea
                className={styles.textarea}
                onChange={handleMessageInput}
                rows={3} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>Match Quality</div>
            <MemberRankingInput
              className={styles.ranking}
              onChange={handleChangeRanking} />
          </div>
        </div>

        <div className={styles.footer}>
          <ButtonSet>
            <ButtonActivityIndicator
              className={styles.btn}
              disabled={!inputs.selected}
              loading={response.loading}
              onClick={submit}
              variant="brick">
              Add
            </ButtonActivityIndicator>
          </ButtonSet>
        </div>
      </div>
    </Modal>
  );
}

type InputsState = {
  search:   string;
  selected: Descriptor | null;
};

const defaultInputsState = {
  search: '',
  selected: null,
};

RecommendMember.displayName = 'Modal.Project.RecommendMember';
import Popover from '@mui/material/Popover';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { ProjectType } from '@enums';
import { ProgressBar } from '@presentation/ProgressBar';
import { cx } from '@utils';
import {
  ProjectProgressProps,
  ProjectProgressPopoverProps,
} from './interfaces';
import styles from './style/ProjectProgress.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: 'var(--pri-01)',
      border: '1px solid transparent',
      borderRadius: 4,
      boxShadow: '0 2px 6px 2px rgba(60,64,67,.15)',
    },
    popover: {
      pointerEvents: 'none',
      marginTop: 10,
    },
  }),
);

export function ProjectProgressBar(props: ProjectProgressProps) {
  const classes = useStyles();

  const popupState = usePopupState({
    popupId: 'project-progress-popover',
    variant: 'popover',
  });

  return (
    <>
      <ProgressBar
        className={props.className}
        onMouseEnter={popupState.open}
        onMouseLeave={popupState.close}
        pct={props.metrics.progress.percentage} />
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        disableScrollLock
        open={popupState.isOpen}
        anchorEl={popupState.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={popupState.close}
        disableRestoreFocus>
        <ProjectProgressPopover
          includeRejected={props.includeRejected}
          metrics={props.metrics}
          project={props.project} />
      </Popover>
    </>
  );
}

export function ProjectProgressPopover({
  includeRejected = false,
  metrics,
  project,
}: ProjectProgressPopoverProps) {
  const callType = project.projectType === ProjectType.Call;
  const surveyType = project.projectType === ProjectType.Survey;

  const goal = surveyType
      ? 'responses'
      : 'calls';

  const rejected = metrics.members.rejectedCount
      + metrics.members.disqualifiedCount;

  const scheduling = metrics.calls.scheduledCount
      + metrics.calls.pendingCount
      + metrics.calls.reschedulingCount;

  const completedCalls = `${metrics.calls.completedCount} calls`;
  const completedSurveys = `${metrics.surveys.activeCount} responses`;

  const completed = surveyType
      ? completedSurveys
      : callType
        ? completedCalls
        : `${completedCalls}, ${completedSurveys}`;

  const pipeline = metrics.members.recommendedCount
      + metrics.members.addedCount
      + metrics.members.pendingCount
      + metrics.members.onboardedCount
      + metrics.members.joinedCount;

  return (
    <div className={styles.root}>
      <div className={styles.goal}>Goal: {metrics.progress.goal} {goal}</div>
      {includeRejected && <div className={cx(styles.stat, styles.rejected)}>Rejected / Disqualified - {rejected} experts</div>}
      <div className={cx(styles.stat, styles.pipeline)}>Pipeline - {pipeline} experts</div>
      {!surveyType && <div className={cx(styles.stat, styles.scheduling)}>Scheduling - {scheduling} calls</div>}
      <div className={cx(styles.stat, styles.completed)}>Completed - {completed}</div>
    </div>
  );
}
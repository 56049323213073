import { cx } from '@utils';
import { DropDown, DropDownOnSelect } from 'components/DropDown';
import styles from './style.css';

type DropdownItem = {
  id: string;
  name: string;
};

export type FormDropdownOnChange = DropDownOnSelect<DropdownItem>;

type Props = {
  className?: string;
  error?: string;
  label: string;
  placeholder?: string;
  items: DropdownItem[];
  selected: DropdownItem;
  required?: boolean;
  onChange: FormDropdownOnChange;
};

export function FormDropdown(props: Props) {
  return (
    <div className={cx(props.className)}>
      <label className={cx(styles.label, { [styles.labelError]: !!props.error })}>
        {props.label} {props.required && <span className={styles.star}>*</span>}
      </label>
      <DropDown
        items={props.items}
        getItemValue={v => v.name}
        onSelect={props.onChange}
        text={props.selected?.name}
        textClass={styles.dropdown} />
    </div>
  );
}

export default FormDropdown;
import { ProjectType, ConferenceType, CallRole } from '@/enums';
import { GroupTag, ProjectBase } from '@/types';

export type ProjectsTableItem = {
  name: string;
  path: string;
  metadata: {
    type: ProjectType;
  };
  owner: string;
  createdOn: Date;
}

export type FilesTableItem = {
  name: string;
  path: string;
  metadata: {
    extension: string;
  };
  owner: string;
  createdOn: Date;
}

export type FoldersTableItem = {
  name: string;
  path: string;
  metadata: Record<string, unknown>;
  owner: string;
  createdOn: Date;
}

export type MembersTableItem = {
  entityId: number;
  name: string;
  path: string;
  metadata: {
    first: string;
    last: string;
    pictureUrl: string;
    title: string;
  };
  tags: GroupTag[];
}

export type CallParticipant = {
  userId: number;
  name: string;
  particpantType: CallRole;
  isExpert: boolean;
}

export type InteractionsTableItem = {
  entityId: number;
  name: string;
  path: string;
  metadata: {
    project: Pick<ProjectBase, 'id' | 'name' | 'projectType' | 'description'>;
    startTime: Date;
    endTime: Date;
    conferenceType: ConferenceType;
    participants: CallParticipant[];
  };
  tags: GroupTag[];
}

export type TableItem =
  | ProjectsTableItem
  | FilesTableItem
  | FoldersTableItem
  | MembersTableItem
  | InteractionsTableItem;

export enum SearchRouteKey {
  All           = 'all',
  Projects      = 'projects',
  Members       = 'members',
  Files         = 'files',
  Folders       = 'folders',
  Interactions  = 'interactions',
}

export type SearchSceneMap = {
  [key in SearchRouteKey]: TabScene;
};

export type TabScene = {
  component: React.ComponentType;
  title:     string | React.ReactNode;
};

import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useIsFreeProject } from '@containers/ProjectOnboarding/hooks';
import { ProjectRateOnboardingContext } from '@containers/RateOnboarding/Project';
import { GreenOutlineButton } from '@presentation';
import styles from './style.css';

type Props = {
  next: () => void;
  projectName: string;
  isFreeProject: boolean;
};

const mapState = (state: Store.State) => ({
  projects: state.projects,
});

const RateIntroScreen = () => {
  const ctx = useContext(ProjectRateOnboardingContext);
  const { projects } = useSelector(mapState);
  const isFreeProject = useIsFreeProject(ctx.projectId);
  const project = projects[ctx.projectId];

  return (
    <RateIntro
      next={ctx.next}
      isFreeProject={isFreeProject}
      projectName={project.name} />
  );
};

const RateIntro = (props: Props) => {
  const title = `Congratulations on your first project! Now let's set your hourly rate so you can engage ${props.isFreeProject ? 'future clients' : 'the client'} in a paid consultation.`;
  const copy = `By inputting your work history, we can recommend how much you should charge for ${props.isFreeProject ? 'future projects' : `the ${props.projectName} project`}.`;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.copy}>{copy}</div>
        <GreenOutlineButton
          className={styles.btn}
          onClick={props.next}>
          Get Started
        </GreenOutlineButton>
      </div>
    </div>
  );
};

export { RateIntroScreen };
export { RateIntro };
export default RateIntro;
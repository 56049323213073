import { useEffect, useState } from 'react';
import * as api from '@api';
import { useSelectUser } from '@containers/Store';
import { useAsyncStateLazy } from '@utils';
import { GDPRPrivacyNotice } from 'components/Modal/GDPRPrivacyNotice';
import { PrivacyConsentContext } from './Context';

type Props = {
  children: React.ReactElement;
};

const ConsentManagementContainer = (props: Props) => {
  const user = useSelectUser();
  const [consentId, setConsentId] = useState<number>(null);

  const [_, fetchPrivacyConsentRequest] = useAsyncStateLazy(() => {
    return api.users.consent.findPrivacyConsentRequest()
    .then(data => {

      if (data?.notice?.id) setConsentId(data.notice.id);

      return data;

    });
  }, [setConsentId]);

  useEffect(() => {

    if (user.meta.isEmailVerified && user.meta.isNew) {
      fetchPrivacyConsentRequest();
    }

  }, [
    fetchPrivacyConsentRequest,
    user.meta.isEmailVerified,
    user.meta.isNew,
  ]);

  return (
    <PrivacyConsentContext.Provider value={[consentId, () => setConsentId(null)]}>
      {props.children}
      <GDPRPrivacyNotice />
    </PrivacyConsentContext.Provider>
  );
};

export { ConsentManagementContainer };
export default ConsentManagementContainer;
import { useMemo } from 'react';
import { useUserRoles } from './useUserRoles';
import * as utils from '@utils';

export const useHasClientRole = () => {
  const roles = useUserRoles();

  const hasClientRole = useMemo(() => {
    return utils.hasClientRole({ roles });
  }, [roles]);

  return hasClientRole;
};

export default useHasClientRole;
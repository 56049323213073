import { useCallback, useState } from 'react';
import { HideUserPrompt } from 'components/GroupProject/HideUserPrompt';
import { UseUserPromptProps } from 'components/GroupProject/interfaces';

type Props =
  UseUserPromptProps;

export const useHideUserPrompt = ({ onConfirm, project, user }: Props) => {
  const [visible, setVisibility] = useState(false);
  const toggleVisibility = useCallback(() => setVisibility(!visible), [visible]);

  const handleConfirm = useCallback(() => {
    onConfirm()
    .then(toggleVisibility);
  }, [onConfirm, toggleVisibility]);

  const Modal = useCallback(() => (
    <HideUserPrompt
      onConfirm={handleConfirm}
      onClose={toggleVisibility}
      project={project}
      user={user}
      visible={visible} />
  ), [
    handleConfirm,
    project,
    toggleVisibility,
    user,
    visible,
  ]);

  return [toggleVisibility, Modal] as const;
};
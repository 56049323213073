import { createElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSessionValidator } from '@routes/hooks/useSessionValidator';
import * as Routing from './interfaces';
import { PathSajak } from './PathSajak';

const buildIsolatedRouter = (params: Routing.RouterProps): Routing.RouterProps => {
  return {
    auth: params.auth,
    component: params.component,
    exact: params.exact,
    path: params.path,
    permissions: params.permissions,
    roles: params.roles,
  };
};

const buildRoutes = (handler: Routing.IsolatedRouterParams) => () => {
  const routes = useSelector(handler);

  return createElement(PathSajak, { routes });
};

const selectRoutes = (routes: Routing.Route[]) => () => {
  const validateSession = useSessionValidator();

  const filtered = useMemo(() => {
    return routes.filter(x => validateSession({
      permissions: x.permissions,
      roles: x.roles,
    }));
  }, [validateSession]);

  return createElement(PathSajak, { routes: filtered });
};

export const BasicRouter = (routes: Routing.Route[], config: Omit<Routing.Namespace, 'permissions' | 'roles'>) => {

  return buildIsolatedRouter({
    ...config,
    component: () => createElement(PathSajak, { routes }),
  });
};

export const SessionRouter = (routes: Routing.Route[], config: Routing.Namespace) => {
  const component = selectRoutes(routes);

  return buildIsolatedRouter({ ...config, component });
};

export const StateRouter = (handler: Routing.IsolatedRouterParams, config: Omit<Routing.Namespace, 'permissions' | 'roles'>) => {
  const component = buildRoutes(handler);

  return buildIsolatedRouter({ ...config, component });
};
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateUserEmailSettings } from '@utils/api';
import Bucket from './Settings.Email.Bucket';

const selectSettings = (state: Store.State) => state.settings.email;

export function MemberBuckets() {
  const settings = useSelector(selectSettings);
  const { mutateAsync } = useUpdateUserEmailSettings();

  const toggle = useCallback((field: string) => (value: boolean) => {
    return mutateAsync({
      [field]: value,
    });
  }, [mutateAsync]);

  return (
    <>
      <Bucket
        active={settings.billing}
        name='Billing'
        description='Receive billing emails regarding payments and payouts'
        onClick={toggle('billing')} />
      <Bucket
        active={settings.projectUpdate}
        name='Projects'
        description='Receive emails regarding project events'
        onClick={toggle('projectUpdate')} />
      <Bucket
        active={settings.scheduling}
        name='Scheduling'
        description='Receive emails for pre-call scheduling correspondence'
        onClick={toggle('scheduling')} />
      <Bucket
        active={settings.callEvent}
        name='Call Event'
        description='Receive emails regarding scheduled calls'
        onClick={toggle('callEvent')} />
      <Bucket
        active={settings.newMessages}
        name='New Message'
        description='Receive emails when you receive messages on the platform'
        onClick={toggle('newMessages')} />
      <Bucket
        active={settings.marketing}
        name='Marketing'
        description='Receive emails for general platform features, reminders, and updates'
        onClick={toggle('marketing')} />
    </>
  );
}

export default MemberBuckets;
import { useParams } from 'react-router-dom';
import { useSelectFile, useSelectObject } from '@containers/Store';

type RouteParams = {
  fileId: string;
};

export const useMatchFileStateFromUrl = () => {
  const { fileId } = useParams<RouteParams>();
  const file = useSelectFile(+fileId);
  const object = useSelectObject(file?.objectId);

  return {
    file,
    object,
  };
};

export default useMatchFileStateFromUrl;
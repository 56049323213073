import { useCallback, useState } from 'react';
import { useRenameFolderContext } from '@containers/WorkspaceFolders/hooks/useRenameFolderContext';
import { useLock, useMounted } from '@utils';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { ButtonSet } from 'components/Modal/ButtonSet';
import { Header } from 'components/Modal/Header';
import { Modal, ModalProps } from 'components/Modal/Modal';
import styles from './style/RenameFolderModal.css';

type Props =
  Pick<ModalProps, 'open' | 'onClose'>;

export const RenameFolderModal = ({ onClose, open  }: Props) => {

  const { folder, rename } = useRenameFolderContext();
  const isMounted = useMounted();
  const [name, setName] = useState<string>(folder.name);
  const [busy, lock] = useLock();

  const handleSave = useCallback(() => {
    return rename(name)
      .then(_ => {
        if (isMounted()) {
          onClose();
        }
      });
  }, [
    isMounted,
    name,
    onClose,
    rename,
  ]);

  const Footer = useCallback(() => {
    const canSave = !!name && name !== folder.name;
    return (
      <ButtonSet className={styles.footer}>
        <Button.Destructive
          className={styles.btn}
          onClick={onClose}
          variant="brick">
          Cancel
        </Button.Destructive>
        <Button.Affirmative
          className={styles.btn}
          disabled={!canSave}
          onClick={lock(handleSave)}
          variant="brick">
          Save
        </Button.Affirmative>
      </ButtonSet>
    );
  }, [
    lock,
    name,
    handleSave,
    onClose,
    folder.name,
  ]);

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  return (
    <Modal onClose={onClose} open={open}>
      <Header
        className={styles.header}
        text="Rename Folder" />
      <div className={styles.body}>
        <div className={styles.input}>
          <Input
            name="folder-name"
            onChange={handleNameChange}
            value={name} />
        </div>
      </div>
      <Footer />
    </Modal>
  );
};

export default RenameFolderModal;
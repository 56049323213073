import { useCallback, useState, useMemo } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { FormDropdown, FormDropdownOnChange } from '../FormDropdown';
import FormInput from '../FormInput';
import { isEmpty } from '../form-utils';
import styles from '../style.css';
import { UnitedStatesProps, FormValidatedField } from './interfaces';

type Props =
  UnitedStatesProps;

export function UnitedStates(props: Props) {
  const AccountHolderTypes = useMemo(() => [
    { id: 'individual', name: 'Individual' },
    { id: 'company', name: 'Company' },
  ], []);

  const stripe = useStripe();
  const [accountType, setAccountType] = useState<string>('individual');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [form, setForm] = useState<UnitedStatesState>({
    name: { value: '', required: true },
    routingNumber: { value: '', required: true },
    accountNumber: { value: '', required: true },
    confirmAccountNumber: { value: '', required: true },
  });

  const handleAccountTypeChange: FormDropdownOnChange = useCallback(item => {
    setAccountType(item.id);
  }, [setAccountType]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: {
        ...form[e.target.name],
        value: e.target.value,
      },
    });
  }, [form, setForm]);

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = validateForm(form);
    setForm(validation.form);
    setError(validation.error);

    if (validation.error) {
      return setLoading(false);
    }

    setLoading(true);
    props.onSave?.();

    stripe.createToken('bank_account', {
      country: 'US',
      currency: 'USD',
      routing_number: form.routingNumber.value,
      account_number: form.accountNumber.value,
      account_holder_name: form.name.value,
      account_holder_type: accountType,
    })
    .then(result => {
      setLoading(false);
      if (result.error) {
        const message = result.error.message && result.error.type !== 'api_error' ? result.error.message : 'An error occured when trying to add this bank account.';
        props.onError?.({ message });
        return setError(message);
      }

      props.onToken(result.token);
    })
    .catch(() => {
      setLoading(false);
      const message = 'An error occured when trying to add this bank account.';
      setError(message);
      props.onError?.({ message });
    });

  }, [accountType, form, props, setError, stripe]);

  const isLoading = props.isLoading || loading;
  const canSave = !isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <FormDropdown
        className={styles.row}
        label='Account Holder Type'
        items={AccountHolderTypes}
        selected={AccountHolderTypes.find(c => c.id === accountType)}
        required={true}
        onChange={handleAccountTypeChange} />
      <FormInput
        className={styles.row}
        error={form.name.error}
        placeholder='Full Name'
        label='Account Holder Name'
        name='name'
        value={form.name.value}
        required={true}
        onChange={handleInputChange} />
      <FormInput
        autoComplete='off'
        className={styles.row}
        error={form.routingNumber.error}
        placeholder='110000000'
        label='Routing Number'
        name='routingNumber'
        value={form.routingNumber.value}
        required={true}
        onChange={handleInputChange} />
      <FormInput
        autoComplete='off'
        className={styles.row}
        error={form.accountNumber.error}
        placeholder='000123456789'
        label='Account Number'
        name='accountNumber'
        value={form.accountNumber.value}
        required={true}
        onChange={handleInputChange} />
      <FormInput
        autoComplete='off'
        className={styles.row}
        error={form.confirmAccountNumber.error}
        placeholder='000123456789'
        label='Confirm Account Number'
        name='confirmAccountNumber'
        value={form.confirmAccountNumber.value}
        required={true}
        onChange={handleInputChange} />
      <div className={styles.error}>
        {error}
      </div>
      <div className={styles.actions}>
        <ButtonActivityIndicator
          color='primary'
          loading={isLoading}
          disabled={!canSave}
          className={styles.save}
          variant='brick'>
          Save
        </ButtonActivityIndicator>
      </div>
    </form>
  );
}

export default UnitedStates;

type UnitedStatesState = {
  name: FormValidatedField;
  routingNumber: FormValidatedField;
  accountNumber: FormValidatedField;
  confirmAccountNumber: FormValidatedField;
};

type UnitedStatesStateFields = keyof UnitedStatesState;

function resetErrors(form: UnitedStatesState) {
  const fields = ['name', 'routingNumber', 'accountNumber', 'confirmAccountNumber'] as UnitedStatesStateFields[];

  return fields.map(field => {
    const { error, ...resetField } = form[field];
    return {
      [field]: resetField,
    } as { [key in UnitedStatesStateFields]: FormValidatedField };
  })
  .reduce((acc, curr) => ({ ...acc, ...curr }), {} as UnitedStatesState);
}

function validateForm(currentForm: UnitedStatesState) {
  const form = resetErrors(currentForm);

  for (const field of ['name', 'routingNumber', 'accountNumber', 'confirmAccountNumber'] as UnitedStatesStateFields[]) {
    if (field === 'confirmAccountNumber') {
      if (form[field].value !== form.accountNumber.value) {
        return {
          error: 'Account Number does not match Confirm Account Number.',
          form: {
            ...form,
            accountNumber: {
              ...form[field],
              error: 'Account Number does not match Confirm Account Number',
            },
            [field]: {
              ...form[field],
              error: 'Confirm Account Number does not match Account Number.',
            },
          },
        };
      }
    }

    if (!form[field] || (form[field].required && isEmpty(form[field].value))) {
      return {
        error: 'Fill in required fields.',
        form: {
          ...form,
          [field]: {
            ...form[field],
            error: 'Field is required.',
          },
        },
      };
    }
  }

  return {
    error: null,
    form,
  };
}
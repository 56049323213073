import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isCallWithUser } from '@containers/Scheduler/utils.calls';
import { selectUnconfirmedCalls } from '@store/selectors';

export const useFindUnconfirmedCallWithUser = () => {
  const items = useSelector(selectUnconfirmedCalls);

  const findUnconfirmedCallWithUser = (record: IProjectId & IUserId) => {
    return items.find(call => isCallWithUser(call, record));
  };

  return useCallback(findUnconfirmedCallWithUser, [items]);
};
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@store/actions';
import * as api from '@api';
import { CompleteUploadParams } from '../interfaces';

export const useCompleteFileUpload = () => {
  const dispatch = useDispatch();

  const completeUpload = useCallback((data: CompleteUploadParams) => {
    return api.workspaces.fileUpload.completeFileUpload({
      fileUploadId: data.fileUploadId,
      s3VersionId: data.s3VersionId,
      workspaceId: data.workspaceId,
    }).then(res => {
      if (!res.fileUpload.data.fileId) {
        dispatch(actions.fileCreated({
          capabilities: res.capabilities,
          file: res.file,
          object: res.object,
        }));
      } else {
        dispatch(actions.fileVersionCreated({
          file: res.file,
          object: res.object,
        }));
      }

      return { fileUpload: res.fileUpload };
    });
  }, [
    dispatch,
  ]);

  return completeUpload;
};

export default useCompleteFileUpload;
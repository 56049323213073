import { MatrixSliderQuestion } from '@/types';

export function useBucketsCheck(question: MatrixSliderQuestion.Question, responses: MatrixSliderQuestion.Aggregate.RowResult['responses']) {
  const increments = generateIncrements(question);

  return Object.values(responses).reduce((acc, v) => {
    return acc || !increments.find(f => f === v);
  }, false);
}

export function generateIncrements(question: MatrixSliderQuestion.Question) {
  const settings = question.settings.slider;
  const items = [] as number[];

  const reversed = question.settings.slider.minValue > question.settings.slider.maxValue;

  const start = reversed
    ? settings.maxValue
    : settings.minValue;

  const end = reversed
    ? settings.minValue
    : settings.maxValue;

  let counter = start;

  while (counter <= end) {
    items.push(counter);
    counter = counter + settings.increment;
  }

  return items;
}

export type Bucket = [number, number];
export type BucketData = {
  items: Bucket[];
  map: {
    [bucket: string]: number[];
  };
  useBuckets: true;
};
export type ValueData = {
  items: number[];
  map: {
    [value: number]: number[];
  };
  useBuckets: false;
};
export type Data = BucketData | ValueData;

export function formatBucketFromKey(key: string): Bucket {
  const match = /(.*)~(.*)/g.exec(key);
  return [+match[1], +match[2]];
}

export function formatBucketKey(bucket: Bucket) {
  return `${bucket[0]}~${bucket[1]}`;
}

export function formatBucketDisplay(bucket: Bucket) {
  return `[${bucket[0]} - ${bucket[1]})`;
}

export function generateBuckets(question: MatrixSliderQuestion.Question) {
  const settings = question.settings.slider;
  const items = [] as Array<[number, number]>;

  const reversed = question.settings.slider.minValue > question.settings.slider.maxValue;

  const start = reversed
    ? settings.maxValue
    : settings.minValue;

  const end = reversed
    ? settings.minValue
    : settings.maxValue;

  let counter = start;

  while (counter < end) {
    items.push([counter, counter + settings.increment]);
    counter = counter + settings.increment;
  }
  return items;
}

export function generateIncrementsMap(values: number[], responses: MatrixSliderQuestion.Aggregate.RowResult['responses']) {
  const userIds = Object.keys(responses).map(m => +m);

  return values.reduce((acc, v) => {
    const list = [] as number[];

    userIds.forEach(userId => {
      const value = responses[userId];

      if (value === v) {
        list.push(userId);
      }
    });

    acc[v] = list;

    return acc;
  }, {} as { [v: number]: number[]; });
}

export function generateBucketsMap(buckets: Bucket[], responses: MatrixSliderQuestion.Aggregate.RowResult['responses']) {
  const userIds = Object.keys(responses).map(m => +m);

  return buckets.reduce((acc, bucket) => {
    const key = formatBucketKey(bucket);
    const list = [] as number[];

    userIds.forEach(userId => {
      const value = responses[userId];

      if (value >= bucket[0] && value < bucket[1]) {
        list.push(userId);
      }
    });

    acc[key] = list;

    return acc;
  }, {} as { [bucket: string]: number[]; });
}

export function getBucketData(question: MatrixSliderQuestion.Question, responses: MatrixSliderQuestion.Aggregate.RowResult['responses']): BucketData {
  const items = generateBuckets(question);
  const map = generateBucketsMap(items, responses);

  return {
    items,
    map,
    useBuckets: true,
  };
}

export function getValueData(question: MatrixSliderQuestion.Question, responses: MatrixSliderQuestion.Aggregate.RowResult['responses']): ValueData {
  const items = generateIncrements(question);
  const map = generateIncrementsMap(items, responses);

  return {
    items,
    map,
    useBuckets: false,
  };
}

export function getRowData(question: MatrixSliderQuestion.Question, responses: MatrixSliderQuestion.Aggregate.RowResult['responses']): Data {
  const useBuckets = useBucketsCheck(question, responses);

  if (useBuckets) {
    return getBucketData(question, responses);
  } else {
    return getValueData(question, responses);
  }
}

export function assertBucketData(data: Data): asserts data is BucketData {}
export function assertValueData(data: Data): asserts data is ValueData {}
import { io } from 'socket.io-client';
import { LifeCycleSocketEvent, SocketNamespace } from '@enums';
import * as $session from '@services/auth/session';
import { createLogger } from './utils';
import { Conference } from './interfaces';

const log = createLogger(SocketNamespace.Conference);

export const socket: Conference.Socket = io(`${process.env.BACKEND_WS_URL}/${SocketNamespace.Conference}`, {
  path: process.env.BACKEND_WS_PATH,
  auth: {
    'X-CSRF-Token': $session.getCSRF(),
  },
  autoConnect: false,
  withCredentials: true,
  transports: ['websocket', 'polling'],
});

export function open() {
  socket.on(LifeCycleSocketEvent.Connect, () => log.event(LifeCycleSocketEvent.Connect));
  socket.on(LifeCycleSocketEvent.ConnectError, data => log.event(LifeCycleSocketEvent.ConnectError, data));
  socket.on(LifeCycleSocketEvent.Disconnect, () => log.event(LifeCycleSocketEvent.Disconnect));

  socket.open();
}

export function close() {
  socket.close();
  socket.removeAllListeners();
}
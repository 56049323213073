import * as enums from '@enums';
import { SurveyLogicActionHeader, SurveyLogicOrdinalHeader } from './Header';
import { SurveyLogic } from '@/types';
import styles from './style/QuestionBaseLogic.css';
import SurveyActionOrdinal from './ActionOrdinal';

type Props = {
  action: SurveyLogic.Action;
  className?: string;
};

export const QuestionBaseLogicPreview = (props: Props) => {

  return (
    <div className={props.className}>
      <div className={styles.header}>
        <SurveyLogicActionHeader
            className={styles.action} />
        <SurveyLogicOrdinalHeader
          className={styles.ordinal} />
      </div>
      <div className={styles.row}>
        <div className={styles.action}>
          {enums.utils.SurveyActionType.getName(props.action.type)}
        </div>
        <SurveyActionOrdinal
          action={props.action}
          className={styles.ordinal} />
      </div>
    </div>
  );
};

export default QuestionBaseLogicPreview;
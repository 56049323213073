import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as consts from '@consts';
import { ReferralClientModal as ReferralModal } from 'components/Modal/Referral';
import ListEmpty from './ListEmpty';
import styles from './style.css';

const mapState = (state: Store.State) => ({
  user: state.user,
});

type Props = {
  resultsCount: number;
  totalCount:   number;
  visible:      boolean;
};

const GeneralSearchListFooter = (props: Props) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const { user } = useSelector(mapState);

  function openChatSupportWidget() {
    window.zE?.('webWidget', 'open');
  }

  const url = `${process.env.FRONT_BASE_URL}${consts.path.Website.ReferralSignup}/${user.referral}`;

  if (!props.visible || props.resultsCount !== props.totalCount) return null;

  return (
    <>
      <ListEmpty
        resultsCount={props.resultsCount}
        totalCount={props.totalCount} />

      <div className={styles.root}>
        <div className={styles.subtitle}>Still looking for an expert?</div>
        <div className={styles.row}>
          <div
            className={styles.link}
            onClick={() => setModalVisible(true)}>
            Share a sign up link
          </div>
          <div className={styles.text}> to sign up a new expert</div>
        </div>
        <div className={styles.row}>
          <div
            className={styles.link}
            onClick={openChatSupportWidget}>
            Submit a request
          </div>
          <div className={styles.text}> to get support from the Vancery team</div>
        </div>
      </div>

      <ReferralModal
        url={url}
        onClose={() => setModalVisible(false)}
        visible={isModalVisible} />
    </>
  );
};

export { GeneralSearchListFooter };
export default GeneralSearchListFooter;
import extend from '@enums/extend';

export enum IdentityProvider {
  Auth0        = 1,
  LinkedIn     = 2,
  GoogleOAuth2 = 3,
  Facebook     = 4,
  Apple        = 5,
}

const map = new Map([
  [IdentityProvider.Auth0,        { name: 'Userland' }],
  [IdentityProvider.LinkedIn,     { name: 'LinkedIn' }],
  [IdentityProvider.GoogleOAuth2, { name: 'Google' }],
  [IdentityProvider.Facebook,     { name: 'Facebook' }],
  [IdentityProvider.Apple,        { name: 'Apple' }],
]);

export default {
  IdentityProvider: extend(map),
};
import { Component } from 'react';
import * as enums from '@enums';
import Tag from 'components/Tag';
import VICSAutocomplete from '../VICSAutocomplete';
import styles from './style.css';

class SubIndustries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showWarning: false,
    };
  }

  onRemoval = item => () => {
    this.props.onSelection(item);
  }

  handleSearchSelect = item => {
    if (this.props.selectedItems.length >= enums.VICSType.getMaxSelections(enums.VICSType.SubIndustry)) {
      this.setState({
        showWarning: true,
      });
    } else {
      this.props.onSelection(item);
    }
  }

  renderSelectedTags = () => {
    return (
      <div className={styles.tags}>
        {this.props.selectedItems.map(x =>
          <Tag
            key={x.id}
            item={{
              id: x.id,
              name: x.name,
              type: enums.SearchType.SubIndustry,
            }}
            editable={true}
            onClick={this.onRemoval(x)} />
        )}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.vics}>
        <div className={styles.vicsContainerTop}>
          <div className={styles.autocomplete}>
            <VICSAutocomplete
              autoFocus={true}
              refProp={this.props.refProp}
              parentIds={this.props.industryIds}
              selectedItems={this.props.selectedItems}
              vicsType={enums.VICSType.SubIndustry}
              onSelection={this.handleSearchSelect} />
          </div>
        </div>
        <div className={styles.selected}>
          <div className={styles.vicsSubtext}>Selected Sub-Industries</div>
          {<div className={styles.warning}>{this.state.showWarning ? `You may only select up to ${enums.VICSType.getMaxSelections(enums.VICSType.SubIndustry)}` : ''}</div>}
        </div>
        <div className={styles.vicsContainerBottom}>
          {this.renderSelectedTags()}
        </div>
      </div>
    );
  }
}

export default SubIndustries;
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { ProjectInviteValidation } from '@containers/ProjectOnboarding/interfaces';
import * as scheduler from '@containers/Scheduler/utils';
import { useIsProjectTypeSurveyLike, useSelectUser } from '@containers/Store';
import { useAsyncStateLazy } from '@utils';

const useValidateInvite = (projectId: number) => {
  const dispatch = useDispatch();
  const isSurveyLike = useIsProjectTypeSurveyLike(projectId);
  const user = useSelectUser();

  return useAsyncStateLazy<ProjectInviteValidation>(async () => {

    const res = await api.projects.pipeline.rehydrate({
      projectId,
      userId: user.id,
    });

    dispatch(actions.documentsFetched({ documents: res.documents }));

    const invited = scheduler.projects.isInvited(res.record);

    const valid = isSurveyLike
        ? invited && res.sourcing.isSourcing
        : invited;

    return {
      project: {
        sourcing: res.sourcing.isSourcing,
      },
      user: {
        disqualified: scheduler.projects.isDisqualified(res.record),
      },
      valid,
    };

  }, [
    dispatch,
    isSurveyLike,
    projectId,
    user.id,
  ]);
};

export { useValidateInvite };
export default useValidateInvite;
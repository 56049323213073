import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { menu, path, pathname } from '@consts';
import { useLogout } from '@containers/AppStateEffect/hooks';
import { useBadgeNotificationStats } from '@containers/Notifications/Context';
import { useHasInternalAdminRole } from '@containers/User/hooks/useHasInternalAdminRole';
import { useHasConsultantRole } from '@containers/User/hooks/useHasConsultantRole';
import * as mixpanel from '@services/mixpanel';
import { getLocationFor, hasTransientRole, usePrevious } from '@utils';
import { useWhitelabelMenuValidator } from 'components/Whitelabel/hooks/useWhitelabelMenuValidator';
import { MenuItemConfig } from './interfaces';
import { useBuildMenuItem } from './useBuildMenuItem';
import { createValidator } from './utils';
import { MenuContext, defaultValue } from './Context';

const mapState = (state: Store.State) => ({
  validator: createValidator(state),
  onboarding: state.onboarding,
  user: state.user,
});

type Props = {
  children: React.ReactNode;
};

export const MenuProvider = (props: Props) => {
  const [context, setMenu] = useState(defaultValue);
  const stats = useBadgeNotificationStats();
  const prevStats = usePrevious(stats);
  const state = useSelector(mapState);
  const prevState = usePrevious(state);
  const build = useBuildMenuItem();
  const validator = useWhitelabelMenuValidator();
  const hasInternalAdminRole = useHasInternalAdminRole();
  const hasConsultantRole = useHasConsultantRole();

  const admin = useCallback((config: MenuItemConfig, ...args: boolean[]) => {
    return hasInternalAdminRole
        && build(config, ...args);
  }, [build, hasInternalAdminRole]);

  const consultant = useCallback((config: MenuItemConfig, ...args: boolean[]) => {
    return hasConsultantRole
        && build(config, ...args);
  }, [build, hasConsultantRole]);

  const logout = useLogout();

  const getProfileParams = useCallback(() => {
    return getLocationFor.me.profile({
      slug: state.user.profile.slug,
      userId: state.user.id,
    });
  }, [state.user.id, state.user.profile.slug]);

  const handleCalendarClick = useCallback(() => {
    mixpanel.event.calendar.home();
  }, []);

  const handleMessagesClick = useCallback(() => {
    mixpanel.event.messages.home();
  }, []);

  const handleSettingsClick = useCallback(() => {
    mixpanel.event.settings.home();
  }, []);

  const handleLogout = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    logout({
      sideEffects: true,
    });
  }, [logout]);

  const getMenu = useCallback(() => {
    if (hasTransientRole(state.user)) {
      const items = [
        build({ name: menu.Logout, to: path.Website.Login, onClick: handleLogout }),
      ];

      return {
        ...defaultValue,
        account: items,
        drawer: items,
        menu: {
          ...defaultValue.menu,
          drawer: items,
        },
      };
    }

    const account = [
      admin({ name: menu.Admin,    to: path.Admin.Root }, state.validator.admin),
      build({ name: menu.Research, to: path.Research.Members }, state.validator.research),
      build({ name: menu.Settings, to: path.Settings.Account, onClick: handleSettingsClick }),
      build({ name: menu.Logout,   to: path.Website.Login, onClick: handleLogout }),
    ]
    .filter(x => validator(x?.key))
    .filter(Boolean);

    const drawer = [
      build({ name: menu.Home,          to: pathname.HOME }),
      consultant({ name: menu.Share,    to: pathname.REFER }),
      build({ name: menu.Projects,      to: pathname.PROJECTS }, state.validator.projects),
      build({ name: menu.Calendar,      to: pathname.CALENDAR, onClick: handleCalendarClick }, state.validator.calendar),
      build({ name: menu.Messages,      to: pathname.MESSAGES, onClick: handleMessagesClick }, state.validator.messages),
      build({ name: menu.Search,        to: pathname.SEARCH }, state.validator.search),
      build({ name: menu.Notifications, to: pathname.NOTIFICATIONS }, state.validator.notifications),
      consultant({ name: menu.Profile,  to: getProfileParams() }),
      build({ name: menu.Settings,      to: path.Settings.Root, onClick: handleSettingsClick }),
      build({ name: menu.Logout,        to: path.Website.Login, onClick: handleLogout }),
    ]
    .filter(x => validator(x?.key))
    .filter(Boolean);

    const nav = [
      build({ name: menu.Home, to: pathname.HOME }),
      build({ name: menu.Calendar, to: pathname.CALENDAR, onClick: handleCalendarClick }, state.validator.calendar),
      build({ name: menu.Messages, to: pathname.MESSAGES, onClick: handleMessagesClick }, state.validator.messages),
      build({ name: menu.Projects, to: pathname.PROJECTS }, state.validator.projects),
    ]
    .filter(x => validator(x?.key))
    .filter(Boolean);

    const notifications = build({ name: menu.Notifications }, state.validator.notifications, validator(menu.Notifications));

    const navbar = [
      ...nav,
      notifications,
    ].filter(Boolean);

    const legacy = {
      drawer,
      dropdown: account,
      navbar,
    };

    return {
      account,
      drawer,
      menu: legacy,
      nav,
      notifications,
    };

  }, [
    admin,
    build,
    consultant,
    getProfileParams,
    handleCalendarClick,
    handleMessagesClick,
    handleSettingsClick,
    handleLogout,
    state.user,
    state.validator,
    validator,
  ]);

  const setNavigation = useCallback(() => setMenu(getMenu()), [getMenu]);

  useEffect(() => {
    if (state.user.id && (!Object.values(defaultValue.menu).flat().length)) {
      setNavigation();
    }
  }, [state.user?.id, setNavigation]);

  useEffect(() => {
    if (!prevState?.user.id && state.user.id) {
      setNavigation();
    }

    if (prevState?.user.id && !state.user.id) {
      setMenu(defaultValue);
    }

    if (state.user.id) {
      if ((prevStats?.total ?? 0) !== stats.total ||
           prevState?.onboarding.completedOn !== state.onboarding.completedOn) {
        setNavigation();
      }
    }
  }, [
    prevState?.onboarding.completedOn,
    prevState?.user.id,
    prevStats?.total,
    setNavigation,
    state.onboarding.completedOn,
    state.user.id,
    stats.total,
  ]);

  return (
    <MenuContext.Provider value={context}>
      {props.children}
    </MenuContext.Provider>
  );
};
import { getOtherAdHocCallParticipant } from '@containers/Scheduler/utils.calls';
import { useSelectContact, useSelectUser } from '@containers/Store';
import { AdHocCall } from '@/types';
import { AdHocCallAgendaMenuPopper } from 'components/CallsAgenda';
import { BaseCallAgendaItem } from './AgendaItem.Base';

type Props = {
  item: AdHocCall;
};

const AdHocCallAgendaItem = ({ item }: Props) => {
  const me = useSelectUser();
  const participantId = getOtherAdHocCallParticipant(item, me.id);
  const participant = useSelectContact(participantId);

  return (
    <BaseCallAgendaItem
      item={item}
      title={item.request.subject}
      description={participant.profile.fullname}>
      <AdHocCallAgendaMenuPopper item={item} />
    </BaseCallAgendaItem>
  );
};

export { AdHocCallAgendaItem };
export default AdHocCallAgendaItem;
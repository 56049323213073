import * as consts from '@consts';
import {
  BioScreen,
  EducationScreen,
  EmploymentScreen,
} from '@screens/ProfileOnboarding';

const routes = [
  {
    component: EmploymentScreen,
    exact: true,
    path: consts.path.Onboarding.ProfileEmployment,
  },
  {
    component: EducationScreen,
    exact: true,
    path: consts.path.Onboarding.ProfileEducation,
  },
  {
    component: BioScreen,
    exact: true,
    path: consts.path.Onboarding.ProfileSummary,
  },
];

export default routes;
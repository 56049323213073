import useGroupEntityTags from './useGroupEntityTags';
import { getLocationFor } from '@utils';

export const useGroupTagsSearchLink = () => {
  const tags = useGroupEntityTags();

  return getLocationFor.search({
    keyword: (tags || []).map(m => ({
      id: m.name,
      name: m.name,
    })),
  });
};

export default useGroupTagsSearchLink;
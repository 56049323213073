import { useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks/useRepeatQuestionResponsesData';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import { SegmentedDataTooltip } from 'components/SurveyResponses/SegmentedDataTooltip';
import RepeatMatrixSliderLineChart from './MatrixSliderChart';
import MatrixSliderData from './MatrixSliderData';

export const RepeatMatrixSlider = () => {
  const { question } = useRepeatQuestionResponsesData();

  return (
    <>
      <SurveyResponsesHeader
        question={question}>
        <SegmentedDataTooltip questionIdentifier={question.base.identifier} />
      </SurveyResponsesHeader>
      <RepeatMatrixSliderLineChart />
      <MatrixSliderData />
    </>
  );
};

export default RepeatMatrixSlider;
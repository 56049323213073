import { useContext, useState } from 'react';
import { ComplianceOnboardingContext } from '@containers';
import * as mixpanel from '@services/mixpanel';
import TestQuestions from '../Test/TestQuestions';
import data from './data';
import {
  ComplianceQuestionProps,
  TestItemSelection,
} from './interfaces';
import styles from './style.css';

export const QuestionsScreen = (props: Partial<Pick<ComplianceQuestionProps, 'title'>>) => {
  const ctx = useContext(ComplianceOnboardingContext);

  return (
    <ComplianceQuestions
      onComplete={ctx.onCompletion}
      title={props.title} />
  );
};

export const ComplianceQuestions = ({
  items = data,
  title = 'About You',
  ...props
}: ComplianceQuestionProps) =>  {
  const [selections, setSelections] = useState<TestItemSelection>({});

  const handleSelection = selection => {
    const updatedSelections = { ...selections, ...selection };

    const selected = Object.keys(selection)
                    .map(s => ({
                      answer: selection[s] + 1,
                      question: +s + 1,
                    }));

    if (selected.length) {
      mixpanel.event.onboarding.complianceQuestions.answer(selected[0]);
    }

    setSelections(updatedSelections);
  };

  const handleComplete = () => {
    mixpanel.event.onboarding.complianceQuestions.submit();

    props.onComplete({
      inClinicalTrial: getFieldValue('inClinicalTrial'),
      isGovOfficial: getFieldValue('isGovOfficial'),
    });

    function getFieldValue(field: string) {
      return selections[items.findIndex(f => f.field === 'isGovOfficial')] === 1 ? false : true;
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.h2}>{title}</div>
        <div className={styles.subheader}>
          Please provide the following information about yourself to
          enable clients to follow firm specific expert consulting policies.
        </div>
      </div>
      <TestQuestions
        items={items}
        onChange={handleSelection}
        selections={selections} />
      <div className={styles.btns}>
        <button
          className={styles.btn}
          onClick={handleComplete}
          disabled={items.length !== Object.keys(selections).length}
          type="button">
          Complete
        </button>
      </div>
    </div>
  );
};

export default ComplianceQuestions;

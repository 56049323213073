import Button from 'components/Button';
import { ConnectAccountProvider } from 'components/ConnectAccount';
import ManageRecipients from './ManageRecipients';
import { ManageRecipientsProps } from './interfaces';
import styles from './style.css';

type Props = {
  onMessageChange: (message: string) => void;
  message:         string;
} & ManageRecipientsProps;

const EmailInvite = (props: Props) => {
  const {
    message,
    onMessageChange,
    ...recipientProps
  } = props;

  return (
    <>
      <ConnectAccountProvider>
        <ManageRecipients {...recipientProps} />
      </ConnectAccountProvider>
      <textarea
        className={styles.textarea}
        onChange={e => props.onMessageChange(e.target.value)}
        placeholder="Customize the invitation message."
        value={props.message} />
      <div className={styles.footer}>
        <Button
          className={styles.send}
          disabled={!props.sendEnabled}
          onClick={props.onSend}
          variant='brick'>
          Send
        </Button>
      </div>
    </>
  );
};

export default EmailInvite;
import u1 from 'static/images/website/customer-research/user-photo-1.svg?url';
import u2 from 'static/images/website/customer-research/user-photo-2.svg?url';
import u3 from 'static/images/website/customer-research/user-photo-3.svg?url';
import styles from './style/Testimonials.css';

type Props = unknown;

export const Testimonials = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>
          See how our customers have used Vancery to build better products.
        </h2>
        <div className={styles.main}>
          {items.map(x =>
            <div
              className={styles.row}
              key={x.name}>
              <div className={styles.item}>
                <div className={styles.text}>{x.text}</div>
                <div className={styles.info}>
                  <div className={styles.avatar}>
                    <img
                      className={styles.img}
                      src={x.src} />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.name}>{x.name}</div>
                    <div className={styles.position}>{x.subtitle}</div>
                  </div>
                </div>
              </div>
            </div>)}
        </div>
      </div>
    </div>
  );
};

Testimonials.displayName = 'CustomerResearch.Testimonials';

const items = [{
  text: `We improved our marketing campaign effectiveness by 20% after speaking to customers through Vancery. The product is a gamechanger for produt teams looking to gather intel from their customers.`,
  name: `Michael W.`,
  subtitle: `Head of Product Marketing, Technlogy Firm`,
  src: u1,
}, {
  text: `This app is incredibly user friendly and I have found myself starting and managing several research projects at once while traveling, or from my couch on the weekends.`,
  name: `Julie S.`,
  subtitle: `Customer & Market Research, Consulting Firm`,
  src: u2,
}, {
  text: `It's what everyone wants. It's basically like browsing on Linkedin but I can talk to these people whenever I want.`,
  name: `Neel A.`,
  subtitle: `Head of Customer Experience, Startup`,
  src: u3,
}];
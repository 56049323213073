import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import { useSelectPayoutSetup } from '@containers/Store';
import { PayoutSetupContext, PayoutSetupResetContext } from './Context';
import { PayoutSetupProps } from './interfaces';

type Props
    = PayoutSetupProps;

export default function PayoutSetup(props: Props) {
  const dispatch = useDispatch();
  const setup = useSelectPayoutSetup();

  const handleReset = useCallback(() => {
    dispatch(actions.userInfoChange({ payoutSetup: {} }));
  }, [dispatch]);

  // clear on new load
  useEffect(() => {
    handleReset();
  }, []);

  const value = {
    stripeAccountId: setup.stripeAccountId,
  };

  return (
    <PayoutSetupContext.Provider value={value}>
      <PayoutSetupResetContext.Provider value={handleReset}>
        {props.children}
      </PayoutSetupResetContext.Provider>
    </PayoutSetupContext.Provider>
  );
}

export {
  PayoutSetup,
  PayoutSetupContext,
  PayoutSetupResetContext,
};
import { DownloadCloud, FileText, Headphones, Info, Mic, PlayCircle, Sliders, UserCheck } from 'react-feather';
import styles from './style/ValueProposition.css';

type Props = unknown;

export const ValueProposition = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {items.map(x =>
            <div
              className={styles.item}
              key={x.title}>
              <div className={styles.box}>
                <div className={styles.icon}>
                  <x.Icon size={30} />
                </div>
                <div className={styles.title}>
                  {x.title}
                </div>
                <div className={styles.text}>
                  {x.text}
                </div>
              </div>
            </div>)}
        </div>
      </div>
    </div>
  );
};

ValueProposition.displayName = 'Compliance.ValueProposition';

const items = [{
  Icon: Sliders,
  text: `Automatically filter out experts whose profiles do not conform to your firm compliance criteria, and eliminate the need to review each consulting candidate.`,
  title: `Compliance Controls`,
}, {
  Icon: UserCheck,
  text: `The call pre-approval setting ensures that no consultations occur without experts first undergoing review and pre-approval by the compliance officer at the firm.`,
  title: `Call Pre-Approved`,
}, {
  Icon: PlayCircle,
  title: `Pre-Call Audio Recordings`,
  text: `Firms can insert a pre-call audio recording that will read a disclaimer or legal message to all call participants before the call begins. `,
}, {
  Icon: Mic,
  title: `Call Recording`,
  text: `To support internal and external audits, Vancery enables a call recording function for all expert calls that analysts engage in on the platform.`,
}, {
  Icon: Info,
  title: `Expert MNPI Training`,
  text: `All experts must complete a mandatory compliance and MNPI training module before they are allowed to accept any consultations.`,
}, {
  Icon: Headphones,
  title: `Call Chaperoning`,
  text: `This functionality gives compliance officers access to the conference line for all consultations that their analysts conduct, and silently chaperone the conversation`,
}, {
  Icon: FileText,
  title: `Expert Attestations`,
  text: `Experts must review and sign all required client attestations and certification documents through the platform prior to scheduling a consultation.`,
}, {
  Icon: DownloadCloud,
  title: `Research Auditability`,
  text: `Records of all messages and calls exchanged with experts on the platform can be compiled and downloaded in seconds to support audits and regulatory requests.`,
}];
import { useModal } from 'components/Modal/hooks/useModal';
import { DeleteModal } from 'components/Post.Article/DeleteModal';
import { DeleteArticleAlertToggleContext, DeleteArticleMutationContext } from './Context';
import { useDeleteArticlePostMutation } from './hooks/useDeleteArticlePostMutation';

type Props = {
  children: React.ReactNode;
  postId:   number;
};

export const PostArticleDeleteContainer = (props: Props) => {
  const [toggle, Modal] = useModal(DeleteModal);

  const mutation = useDeleteArticlePostMutation(props.postId);

  return (
    <DeleteArticleAlertToggleContext.Provider value={toggle}>
      <DeleteArticleMutationContext.Provider value={mutation}>
        {props.children}
        <Modal />
      </DeleteArticleMutationContext.Provider>
    </DeleteArticleAlertToggleContext.Provider>
  );
};

PostArticleDeleteContainer.displayName = 'Post.Article.Delete.Container';
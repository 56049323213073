import { useCallback, useState } from 'react';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { LoadingProcessData } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { ProgressOverlay } from '@presentation/ProgressOverlay';
import { useUseableCSS, useMeasure } from '@utils/hooks';
// import textLayerCss from 'react-pdf/dist/esm/Page/TextLayer.css';
import annotationLayerCss from 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import styles from './style.css';

type Props = {
  url: string;
};

const Pdf = ({ url }: Props) => {

  const [progress, setProgress] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [containerRef, bounds] = useMeasure();

  // useUseableCSS(textLayerCss);
  useUseableCSS(annotationLayerCss);

  const handleProgress = useCallback(({ loaded, total }: LoadingProcessData) => {
    setProgress((loaded / total) * 100);
  }, []);

  const handleSuccess = useCallback((value: PDFDocumentProxy) => {
    setProgress(100);
    setTotalPages(value.numPages);
  }, []);

  const handleError = useCallback((error: Error) => {
    console.log(error);
  }, []);

  const renderLoading = useCallback(() => {
    return (
      <div className={styles.progress}>
        <ProgressOverlay progress={progress} />
      </div>
    );
  }, [progress]);

  const renderPageLoading = useCallback(() => {
    return (
      <div className={styles.pageLoading}>
        <ProgressOverlay progress={75} />
      </div>
    );
  }, []);

  const renderPages = useCallback(() => {

    if (!totalPages) return null;

    return (
      <>
        {new Array(totalPages).fill(null).map((_, i) => (
          <Page
            key={i}
            className={styles.page}
            loading={renderPageLoading}
            pageNumber={i + 1}
            renderTextLayer={true}
            width={bounds.width - 50} />
        ))}
      </>
    );
  }, [bounds.width, renderPageLoading, totalPages]);

  return (
    <div
      ref={containerRef}
      className={styles.container}>
      <Document
        file={url}
        loading={renderLoading()}
        options={{
          cMapUrl: 'cmaps/',
          cMapPacked: true,
          standardFontDataUrl: 'standard_fonts/',
        }}
        onLoadError={handleError}
        onLoadSuccess={handleSuccess}
        onLoadProgress={handleProgress}>
        {renderPages()}
      </Document>
    </div>
  );
};

export { Pdf };
export default Pdf;
import { Link } from 'react-router-dom';
import { path } from '@consts';
import { useSelectUser } from '@containers';
import { getLocationFor } from '@utils';
import styles from './style.css';

type Props = {
  title?: string;
};

export const ProjectDisqualification = ({
  title = defaultTitle,
}: Props) => {
  const user = useSelectUser();
  const profileLink = getLocationFor.me.profile({
    slug: user.profile.slug,
    userId: user.id,
  });

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>{title}</div>
        <div className={styles.subtitle}>{line1}</div>
        <div className={styles.subtitle}>{line2}</div>
        <div className={styles.profile}>
          <Link to={profileLink}>Update Profile</Link>
        </div>
        <div className={styles.dashboard}>
          <Link to={path.Projects.Root}>Go to Dashboard</Link>
        </div>
      </div>
    </div>
  );
};

const line1 = `Want to improve your chances of getting selected for future projects?`;
const line2 = `Update your profile so that clients will know what kinds of projects you’d like to participate in.`;

const defaultTitle = `Hmm, looks like you aren’t a fit for this project, but we will keep you posted on future opportunites.`;

export default ProjectDisqualification;
import { useEffect, memo } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import pickerCSS from 'react-datepicker/dist/react-datepicker.css';
import { cx } from '@utils';
import styles from './style.css';

type Props = ReactDatePickerProps;

export const TimePicker = memo(({
  className,
  dateFormat = 'hh:mm aa',
  timeCaption = 'Time',
  timeFormat = 'hh:mm',
  timeIntervals = 30,
  ...rest
}: Props) => {

  useEffect(() => {
    pickerCSS.use();

    return () => {
      pickerCSS.unuse();
    };
  }, []);

  return (
    <ReactDatePicker
      className={cx(styles.time, className)}
      showTimeSelect
      showTimeSelectOnly
      dateFormat={dateFormat}
      timeCaption={timeCaption}
      timeFormat={timeFormat}
      timeIntervals={timeIntervals}
      {...rest} />
  );
});

export default TimePicker;
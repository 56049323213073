import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { WorkspaceObjectCapabilities } from '@/types';
import { WorkspaceObjectType } from 'enums';

export const selectProjectCapabilities = (state: Store.State) => (projectId: number) => {
  const { parentId } = state.projects[projectId];
  const { objectId } = state.projects.parents[parentId];
  const capabilities = state.objects.capabilities[objectId];

  return capabilities as WorkspaceObjectCapabilities<WorkspaceObjectType.ProjectParent>;
};

export const useGetProjectCapabilities = () => {
  const getCapabilities = useSelector(selectProjectCapabilities);

  return useCallback(
    (projectId: number) => getCapabilities(projectId),
    [getCapabilities],
  );
};

export const useProjectCapabilities = (projectId: number) => {
  const getCapabilities = useGetProjectCapabilities();
  return getCapabilities(projectId);
};
import { UserProfileIndividual } from '@screens/UserProfile/UserProfileIndividual';
import UserProfileContainer from './UserProfileContainer';

function ProfileDependentContainer(props: unknown) {

  return (
    <>
      <UserProfileIndividual {...props} />
    </>
  );
}

export default function UserProfileIndividualContainer(props: unknown) {

  return (
    <UserProfileContainer>
      <ProfileDependentContainer {...props} />
    </UserProfileContainer>
  );
}

export { UserProfileIndividualContainer };
import { memo } from 'react';
import { cx } from '@utils';
import Archive from 'components/icons/Archive.Legacy';
import Check from 'components/icons/Check';
import Trash from 'components/icons/Trash';
import XCircle from 'components/icons/XCircle';
import styles from './style/FormButtons.css';

type Props = {
  archivable?: boolean;
  className?: string;
  deletable?: boolean;
  disabled?: boolean;
  onArchive?: () => unknown;
  onCancel: () => unknown;
  onDelete?: () => unknown;
  onSubmit?: () => unknown;
  size?: number;
};

const FormButtons = memo((props: Props) => (
  <div className={cx(styles.root, props.className)}>
    {!props.disabled &&
      <Check
        size={props.size}
        onClick={props.onSubmit} />}
    <XCircle
      size={props.size}
      onClick={props.onCancel} />
    {props.archivable &&
      <Archive
        size={props.size}
        onClick={props.onArchive} />}
    {props.deletable &&
      <Trash
        size={props.size}
        onClick={props.onDelete} />}
  </div>
));

export { FormButtons };
export default FormButtons;
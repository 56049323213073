import { useCallback, useState } from 'react';
import * as consts from '@consts';

export const useScreeningQuestions = (initial: string[]) => {
  const [questions, setQuestions] = useState<string[]>(initial);

  const addQuestion = useCallback(() => {
    setQuestions(q => [...q, '']);
  }, [
    setQuestions,
  ]);

  const canAddQuestion = useCallback(() => {
    return questions.length < consts.surveys.MaxProjectScreenerQuestions
      && !questions.some(s => !s);
  }, [questions]);

  const removeQuestion = useCallback((index: number) => () => {
    setQuestions(qs => qs.filter((_, i) => i !== index));
  }, [
    setQuestions,
  ]);

  const updateQuestion = useCallback((index: number) => (value: string) => {

    setQuestions(qs => qs.reduce((acc, q, i) => {
      if (i === index) acc.push(value);
      else acc.push(q);
      return acc;
    }, [] as string[]));
  }, [
    setQuestions,
  ]);

  return {
    addQuestion,
    canAddQuestion,
    questions,
    removeQuestion,
    setQuestions,
    updateQuestion,
  } as const;
};

export default useScreeningQuestions;
import { useCallback, useMemo } from 'react';
import { SurveyOptionType } from '@enums';
import { cx } from '@utils';
import { SurveyQuestionFormOption } from '@/types';
import { RadioButton } from 'components/Radio';
import { Checkbox } from 'components/Checkbox';
import { ItemAnswer, MatrixVariant, SurveyMatrixGridItemProps } from './interfaces';
import styles from './style/Grid.css';

export const SurveyMatrixGridRowItem = <T extends MatrixVariant>({ updateRowAnswer, ...props }: SurveyMatrixGridItemProps<T>) => {
  const onChange = useCallback((optionId: number) => () => {
    updateRowAnswer(optionId);
  }, [updateRowAnswer]);

  const renderToggle = useCallback((optionId: number) => {
    if (props.variant === 'single') {
      const checked = props.value as ItemAnswer<'single'> === optionId;
      return (
        <RadioButton
          onChange={onChange(optionId)}
          checked={checked} />
      );
    } else {
      const checked = (props.value as ItemAnswer<'multiselect'> || []).includes(optionId);
      return (
        <Checkbox
          onChange={onChange(optionId)}
          checked={checked} />
      );
    }
  }, [
    onChange,
    props.value,
    props.variant,
  ]);

  const renderCell = useCallback((option: SurveyQuestionFormOption, width?: string) => {
    const className = cx(styles.cell, props.cellClassName, {
      [styles.cellNa]: option.type === SurveyOptionType.NotApplicable,
    });
    return (
      <div
        key={option.id}
        className={className}
        style={{ width }}>
        <div className={styles.cellWrap}>
          {renderToggle(option.id)}
        </div>
      </div>
    );
  }, [
    renderToggle,
    props.cellClassName,
  ]);

  const naOption = useMemo(() => props.options.find(f => f.type === SurveyOptionType.NotApplicable), [props.options]);

  return (
    <div className={styles.row}>
      <div
        style={{ maxWidth: props.maxNameWidth }}
        className={styles.rowName}>
        {props.row.value}
      </div>
      {props.options
        .filter(f => f.type !== SurveyOptionType.NotApplicable)
        .map(option => renderCell(option, props.cellWidth))}
      {naOption && renderCell(naOption)}
    </div>
  );
};

export default SurveyMatrixGridRowItem;
import { useCallback } from 'react';
import * as mixpanel from '@services/mixpanel';
import { ProjectReportType } from '@/types';
import { useSelectProject } from '@containers/Store';

type Props = {
  projectId: number;
};

export const useTrackProjectReportDownload = ({ projectId }: Props) => {
  const project = useSelectProject(projectId);

  return useCallback((format: ProjectReportType) => {
    mixpanel.event.report.downloadProject({
      format,
      project: {
        id: projectId,
        name: project?.name,
      },
      type: project?.projectType,
    });

  }, [
    project,
    projectId,
  ]);
};

export default useTrackProjectReportDownload;
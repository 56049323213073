import { useCallback, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { path } from '@consts';
import { ButtonActivityIndicator } from '@presentation';
import { cx } from '@utils';
import { AuthForm } from '$website/components';
import { EnterpriseAuthContainer, PersistentLinkState } from '$website/containers';
import * as Auth from '$website/containers/Auth/interfaces';
import { useEnterpriseSubmit } from '$website/containers/Auth/hooks';
import styles from '$website/components/AuthModal/style/AuthModal.css';
import { EnterpriseError } from '$website/screens/Auth/EnterpriseError';
import { Logo } from 'components/Branding';

type Params = {
  identity?: string;
}

const { Layout } = AuthForm;

function LoginEnterprise() {
  return (
    <EnterpriseAuthContainer>
      <LoginForm />
    </EnterpriseAuthContainer>
  );
}

function LoginForm() {
  const params = useParams<Params>();
  const [state, submit] = useEnterpriseSubmit();

  useEffect(() => {
    if (params?.identity) {
      submit({
        provider: params.identity,
      });
    }
  }, []);

  const handleLogin = useCallback((values: Auth.Enterprise.Login.Form) => {
    submit(values);
  }, [submit]);

  return (
    <div className={cx(styles.page, styles.wrap)}>
      <Layout.Header>
        <Layout.Row>
          <Link to={path.Website.Root}>
            <Logo size={55} />
          </Link>
        </Layout.Row>

        <Layout.Row>
          <Layout.Title>Enterprise Login</Layout.Title>
        </Layout.Row>
      </Layout.Header>

      <div className={styles.body}>
        <Formik<Auth.Enterprise.Login.Form>
          initialValues={{ email: '' }}
          onSubmit={(values, actions) => {
            handleLogin(values);
          }}
          validationSchema={Schema}>
          <Form>
            <AuthForm.Enterprise.Login className={cx(styles.visitor, styles.row, styles.input)} />
            <div className={cx(styles.login, styles.row)}>
              <EnterpriseError className={cx(styles.error)} />
            </div>
            <Layout.Row className={styles.wrap}>
              <ButtonActivityIndicator
                className={styles.submit}
                loading={false}>
                Continue
              </ButtonActivityIndicator>
            </Layout.Row>
            <Layout.Row>
              <div className={styles.otherStrategy}>
                <Link to={path.Website.Login}>
                  <span>Not an enterprise user?</span>
                </Link>
              </div>
            </Layout.Row>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export const Enterprise = PersistentLinkState(LoginEnterprise, 'Login.Enterprise');

const Schema = Yup.object().shape({
  email: Yup.string().email('A valid email address is required'),
});
import { useCallback, useState } from 'react';
import { SearchLoadingContext } from './Context';
import { SearchLoadingContextState } from './interfaces';

export default function Loading({ children }: ChildrenProps) {
  const [state, dispatch] = useState<SearchLoadingContextState>(mergeInitial());

  const reset = useCallback(() => {
    dispatch(mergeInitial());
  }, []);

  return (
    <SearchLoadingContext.Provider value={[state, dispatch, reset]}>
      {children}
    </SearchLoadingContext.Provider>
  );
}

function mergeInitial(): SearchLoadingContextState {
  return {
    all:          { untouched: true, loading: true },
    projects:     { untouched: true, loading: true },
    members:      { untouched: true, loading: true },
    files:        { untouched: true, loading: true },
    folders:      { untouched: true, loading: true },
    interactions: { untouched: true, loading: true },
  };
}

export { Loading };

import { Link } from 'react-router-dom';
import styles from './style/FAQ.css';

type Props = unknown;

const FAQ = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>Frequently Asked Questions</h2>
        <div className={styles.main}>
          {items.map(x =>
            <div
              className={styles.item}
              key={x.href}>
              <a
                className={styles.link}
                href={x.href}
                rel="noreferrer"
                target="_blank">
                <div className={styles.text}>{x.title}</div>
              </a>
            </div>)}
        </div>
      </div>
    </div>
  );
};

const items = [{
  href: `https://vancery.zendesk.com/hc/en-us/articles/360044188754-What-is-Vancery-`,
  title: `What is Vancery?`,
}, {
  href: `https://vancery.zendesk.com/hc/en-us/articles/360044189434-How-does-compensation-work-`,
  title: `How does compensation work?`,
}, {
  href: `https://vancery.zendesk.com/hc/en-us/articles/360044713413-Who-are-our-clients-`,
  title: `Who are our clients?`,
}, {
  href: `https://vancery.zendesk.com`,
  title: `View All >`,
}];

export { FAQ };
export default FAQ;
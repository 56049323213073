import { useCallback, useContext, useEffect, useReducer } from 'react';
import * as api from '@services/api';
import { useSelectUser, PayoutSetupContext } from '@containers';
import { PayoutMethod } from '@enums';
import { isValidPaypal } from '@utils';
import { useUserGuessCountry } from '@utils/api';
import Button from 'components/Button';
import { Back } from 'components/Wizard';
import * as SetupCountry from './Setup.Country';
import SetupPayoutOption from './Setup.PayoutOption';
import * as SetupPayoutDetails from './Setup.PayoutDetails';
import { AddressEntry, SetupProps, SetupUpdateProps, SetupState, SetupPayoutOptionValue, UserPayoutCheckAddress } from './interfaces';
import styles from './style/Setup.css';

export function Setup({ navigation, nextLabel }: SetupProps) {
  const { stripeAccountId } = useContext(PayoutSetupContext);
  const user = useSelectUser();
  const [guessedCountry] = useUserGuessCountry();

  const [state, dispatch] = useReducer(reducer, createInitialState({
    stripeAccountId,
  }));

  useEffect(() => {
    if (state.option === 'stripe') {
      if (stripeAccountId) {
        const data = { stripe: { accountId: stripeAccountId } };
        dispatch({ type: 'stripe-connected', data });
      }
    }
  }, [stripeAccountId, state.option]);

  const handleCountryChange = useCallback((country: { id: string; name: string; }) => {
    if (!country) {
      return dispatch({ type: 'reset' });
    }

    const { check } = createInitialState({ country });
    const data = { country };

    dispatch({ type: 'country-change', data });
    dispatch({ type: 'option-change', data: {} });
    dispatch({ type: 'check-address-change', data: { check }});
  }, []);

  useEffect(() => {
    if (!guessedCountry.loading && guessedCountry.value) {
      handleCountryChange(guessedCountry.value);
    }
  }, [handleCountryChange, guessedCountry.loading, guessedCountry.value]);

  const handleOptionChange = useCallback((option: SetupPayoutOptionValue) => {
    const data = { option };
    dispatch({ type: 'option-change', data });
  }, []);

  // const handleAddressChange = useCallback((address: AddressEntry.FullFormAddressData) => {
  //   const data = { check: { address } };
  //   dispatch({ type: 'check-address-change', data });
  // }, []);

  const handlePayPalChange = useCallback((value: string) => {
    const data = { paypal: { account: value } };
    dispatch({ type: 'paypal-account-change', data });
  }, []);

  const handleNext = useCallback(() => {
    if (state.option === 'stripe') {
      api.payout.saveMethod({
        userId: user.id,
        method: PayoutMethod.Stripe,
        accountId: stripeAccountId,
      }).then(() => navigation.next());
    // } else if (state.option === 'check') {
    //   api.payout.saveMethod({
    //     userId: user.id,
    //     method: PayoutMethod.Check,
    //     address: {
    //       country: state.check.address.country.value,
    //       address1: state.check.address.address1.value,
    //       address2: state.check.address.address2.value,
    //       city: state.check.address.city.value,
    //       state: state.check.address.state.value,
    //       zip: state.check.address.zip.value,
    //     },
    //   }).then(() => navigation.next());
    } else if (state.option === 'paypal') {
      api.payout.saveMethod({
        userId: user.id,
        method: PayoutMethod.PayPal,
        account: state.paypal.account,
      }).then(() => navigation.next());
    }
  }, [state.option, state.paypal.account, stripeAccountId, user.id, navigation]);

  const Country =
    guessedCountry.loading
      ? <SetupCountry.Loading />
      : state.step1.visible
        ? !stripeAccountId
            ? <SetupCountry.Picker selected={state.country} onChange={handleCountryChange} />
            : <SetupCountry.Locked selected={state.country} />
        : null;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>{`Connect a payout account to be compensated.`}</h1>
        <div className={styles.copy}>{`Please complete the setup steps to connect a payout account.`}</div>
        <div>
          {Country}
          {state.step2.visible &&
            <SetupPayoutOption
              available={state.available}
              value={state.option}
              onChange={handleOptionChange} />}
          {state.step3.visible && state.option === 'stripe' &&
            <SetupPayoutDetails.SetupPayoutDetailsStripe />}
          {/* {state.step3.visible && state.option === 'check' &&
            <SetupPayoutDetails.SetupPayoutDetailsCheck
              address={state.check.address}
              onChange={handleAddressChange} />} */}
          {state.step3.visible && state.option === 'paypal' &&
            <SetupPayoutDetails.SetupPayoutDetailsPayPal
              account={state.paypal.account}
              onChange={handlePayPalChange} />}
        </div>
        <div className={styles.navigation}>
          {navigation.back
            ? <Back onClick={navigation.back} />
            : <div />}
          <Button
            variant="brick"
            title={nextLabel}
            disabled={!state.canProceed}
            onClick={handleNext} />
        </div>
      </div>
    </div>
  );
}

Setup.defaultProps = {
  nextLabel: 'Next',
};

export function Update({ settings, navigation, nextLabel }: SetupUpdateProps) {
  const user = useSelectUser();

  const [state, dispatch] = useReducer(reducer, createInitialState({
    option: settings.method === PayoutMethod.PayPal ? 'paypal' : 'check',
  }));

  useEffect(() => {
    if (settings.method === PayoutMethod.Check) {
      const { country, check } = createInitialState({
        country: AddressEntry.Countries.World.find(c => c.id === settings.address.country),
        address: settings.address,
      });
      dispatch({ type: 'country-change', data: { country } });
      dispatch({ type: 'option-change', data: { option: 'check' } });
      dispatch({ type: 'check-address-change', data: { check }});
    } else if (settings.method === PayoutMethod.PayPal) {
      const paypal = {
        account: settings.account,
      };
      dispatch({ type: 'paypal-account-change', data: { paypal } });
    }
  }, []);

  const handleCountryChange = useCallback((country: { id: string; name: string; }) => {
    const { check } = createInitialState({ country });
    const data = { country };

    dispatch({ type: 'country-change', data });
    dispatch({ type: 'option-change', data: { option: 'check' } });
    dispatch({ type: 'check-address-change', data: { check }});
  }, []);

  const handleAddressChange = useCallback((address: AddressEntry.FullFormAddressData) => {
    const data = { check: { address } };
    dispatch({ type: 'check-address-change', data });
  }, []);

  const handlePayPalChange = useCallback((value: string) => {
    const data = { paypal: { account: value } };
    dispatch({ type: 'paypal-account-change', data });
  }, []);

  const handleNext = useCallback(() => {
    if (state.option === 'check') {
      api.payout.saveMethod({
        userId: user.id,
        method: PayoutMethod.Check,
        address: {
          country: state.check.address.country.value,
          address1: state.check.address.address1.value,
          address2: state.check.address.address2.value,
          city: state.check.address.city.value,
          state: state.check.address.state.value,
          zip: state.check.address.zip.value,
        },
      }).then(() => navigation.next());
    } else if (state.option === 'paypal') {
      api.payout.saveMethod({
        userId: user.id,
        method: PayoutMethod.PayPal,
        account: state.paypal.account,
      }).then(() => navigation.next());
    }
  }, [state.option, state.check.address, state.paypal.account, user.id, navigation]);

  const Check = useCallback(() => {
    return (
      <>
        <SetupCountry.Picker
          label="Country"
          selected={state.country}
          onChange={handleCountryChange} />
        <SetupPayoutDetails.SetupPayoutDetailsCheck
          label="Details"
          address={state.check.address}
          onChange={handleAddressChange} />
      </>
    );
  }, [state.country, state.check.address, handleCountryChange, handleAddressChange]);

  const PayPal = useCallback(() => {
    return (
      <SetupPayoutDetails.SetupPayoutDetailsPayPal
        label="Details"
        account={state.paypal.account}
        onChange={handlePayPalChange} />
    );
  }, [state.paypal.account, handlePayPalChange]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div>
          {state.option === 'check' ? Check() : null}
          {state.option === 'paypal' ? PayPal() : null}
        </div>
        <div className={styles.navigation}>
          {navigation.back
            ? <Back onClick={navigation.back} />
            : <div />}
          <Button
            variant="brick"
            title={nextLabel}
            disabled={!state.canProceed}
            onClick={handleNext} />
        </div>
      </div>
    </div>
  );
}

Update.defaultProps = {
  nextLabel: 'Next',
};

function createInitialState(data?: CreateInitialState): SetupState {
  return {
    available: [],
    country: data?.country,
    option: data?.option ?? 'stripe',
    step1: { visible: true },
    step2: { visible: false },
    step3: { visible: false },
    paypal: {
      account: data?.paypalAccount,
    },
    stripe: {
      accountId: data?.stripeAccountId,
    },
    check: {
      address: {
        country: { value: data?.country?.id ?? '', required: true },
        address1: { value: data?.address?.address1 ?? '', required: true },
        address2: { value: data?.address?.address2 ?? '' },
        city: { value: data?.address?.city ?? '', required: true },
        state: { value: data?.address?.state ?? '', required: true },
        zip: { value: data?.address?.zip ?? '', required: true },
      },
    },
    canProceed: false,
  };
}

function reducer(state: SetupState, action: Action): SetupState {
  switch (action.type) {
    case 'country-change': {
      const country = action.data.country?.id;
      const stripeSupported = SetupCountry.isStripeSupported(country);

      const available: SetupPayoutOptionValue[] = stripeSupported ? ['stripe'] : ['paypal'];
      const option: SetupPayoutOptionValue = stripeSupported ? 'stripe' : 'paypal';

      const check = {
        ...state.check,
        address: {
          ...state.check.address,
          country: { value: country, required: true },
        },
      };

      const update = {
        ...action.data,
        available,
        option,
        step2: { visible: true },
        check,
        canProceed: false,
      };
      return { ...state, ...update };
    }
    case 'option-change': {
      const resolved = AddressEntry.validation.resolveFullAddressSchema(state.check.address);
      const validation = AddressEntry.validation.validateFullAddress(resolved);

      const update = {
        ...action.data,
        step3: { visible: true },
        canProceed: action.data.option === 'stripe'
          ? !!state.stripe.accountId
          : !validation.error,
      };
      return { ...state, ...update };
    }
    case 'stripe-connected': {
      return {
        ...state,
        ...action.data,
        canProceed: !!action.data.stripe.accountId,
      };
    }
    case 'check-address-change': {
      const address = {
        ...state.check.address,
        ...action.data.check?.address,
      };

      const resolved = AddressEntry.validation.resolveFullAddressSchema(address);
      const validation = AddressEntry.validation.validateFullAddress(resolved);

      return {
        ...state,
        check: {
          address: resolved,
        },
        canProceed: !validation.error,
      };
    }
    case 'paypal-account-change': {
      const paypal = {
        ...state.paypal,
        ...action.data.paypal,
      };

      const valid = isValidPaypal(paypal.account);

      return {
        ...state,
        paypal,
        canProceed: valid,
      };
    }
    case 'reset': {
      return createInitialState();
    }
    default: return state;
  }
}

type CreateInitialState = {
  country?: SetupState['country'];
  option?: SetupState['option'];
  address?: UserPayoutCheckAddress;
  stripeAccountId?: SetupState['stripe']['accountId'];
  paypalAccount?: SetupState['paypal']['account'];
}

type Action =
  | { type: 'country-change'; data: Partial<SetupState>; }
  | { type: 'option-change'; data: Partial<SetupState>; }
  | { type: 'stripe-connected'; data: Partial<SetupState>; }
  | { type: 'check-address-change'; data: Partial<SetupState>; }
  | { type: 'paypal-account-change'; data: Partial<SetupState>; }
  | { type: 'reset'; }

export { SetupCountry, SetupPayoutOption };
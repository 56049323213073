import { useProfileState } from '@containers';
import { Button } from 'components/Button';
import { useProfileMessagingAction, useProfileRequestCallAction } from 'components/UserProfile/hooks';
import styles from './style/Profile.Header.css';

const HeaderActions = () => {
  const [user] = useProfileState();

  const params = {
    user: {
      id: user.id,
      profile: user.profile,
    },
  };

  const [canInitiateMessages, initiateMessage] = useProfileMessagingAction(params);
  const [canInitiateCalls, initiateCall] = useProfileRequestCallAction(params);

  return (
    <div className={styles.btns}>
      {canInitiateCalls &&
        <Button
          className={styles.btn}
          onClick={initiateCall}
          variant="pill">
          Request Call
        </Button>}
      {canInitiateMessages &&
        <Button
          className={styles.btn}
          onClick={initiateMessage}
          variant="pill">
          Message
        </Button>}
    </div>
  );
};

export { HeaderActions };
export default HeaderActions;
import styles from './style.css';

const ArrowDownIcon = ({
  onClick,
  open,
  size = 50,
}) => {
  const classStyles = open
    ? `${styles.root} ${styles.open}`
    : styles.root;

  return (
    <svg
      className={classStyles}
      onClick={onClick}
      x="0px"
      y="0px"
	    width={size}
      height={size}
      viewBox="0 0 349.415 348.183"
      enableBackground="0 0 349.415 348.183">
      <path
        className={styles.fill}
        stroke="#ffffff"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        d="M174.376,331.976 c-87.535,0-158.489-70.96-158.489-158.491c0-87.528,70.954-158.491,158.489-158.491c87.531,0,158.49,70.963,158.49,158.491 C332.866,261.015,261.907,331.976,174.376,331.976z" />
      <rect
        x="132.549"
        y="129.373"
        transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 369.6992 224.1327)"
        fill="none"
        stroke="#626262"
        strokeWidth="19.5116"
        strokeMiterlimit="10"
        width="11.763"
        height="118.522" />
      <rect
        x="208.863"
        y="129.359"
        transform="matrix(0.707 0.7072 -0.7072 0.707 196.3061 -96.6021)"
        fill="none"
        stroke="#626262"
        strokeWidth="19.5116"
        strokeMiterlimit="10"
        width="11.76"
        height="118.525" />
      <circle fill="#E5E5E5" cx="706.09" cy="133.795" r="304.635" />
      <circle fill="#626262" cx="706.09" cy="133.796" r="44.499" />
      <circle fill="#626262" cx="878.178" cy="133.796" r="44.499" />
      <circle fill="#626262" cx="534.002" cy="133.796" r="44.499" />
    </svg>
  );
};

export { ArrowDownIcon };
export default ArrowDownIcon;
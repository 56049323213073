import { SurveyQuestionType } from '@enums';
import { ConjointAnalysisQuestion } from '@/types';
import { Validation } from '../interfaces';
import { assertValidAttributes } from './validation.attributes';
import { assertHasValue, returnAssertFailure } from './validation.base';

export const DefaultConcepts = 3;
export const MinConcepts = 2;
export const MaxConcepts = 5;

export const DefaultAttributes = 3;
export const MinAttributes = 3;
export const MaxAttributes = 5;

export const DefaultLevels = 3;
export const MinLevels = 3;
export const MaxLevels = 7;

export const DefaultSets = 2;
export const MinSets = 2;
export const MaxSets = 20;

export const DefaultSettings: ConjointAnalysisQuestion.Settings = {
  conceptsPerSet: DefaultConcepts,
  includeNoneOfTheAbove: false,
  numberOfSets: DefaultSets,
};

export function validateQuestion(question: ConjointAnalysisQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.MaxDifference> {

  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidAttributes = assertValidAttributes(question.attributes, {
    maxAttributes: MaxAttributes,
    maxLevels: MaxLevels,
    minAttributes: MinAttributes,
    minLevels: MinLevels,
  });

  if (hasValidAttributes.success === false) {
    return returnAssertFailure(hasValidAttributes, question);
  }

  const hasValidSettings = assertValidSettings(question.settings);
  if (hasValidSettings.success === false) {
    return returnAssertFailure(hasValidSettings, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}

function assertValidSettings(settings: ConjointAnalysisQuestion.Settings): Validation.AssertionResult {

  if (incomplete()) {
    return {
      success: false,
      message: 'Incomplete settings.',
    };
  }

  return { success: true };

  function incomplete() {
    return settings.conceptsPerSet === null ||
      settings.numberOfSets === null;
  }
}
import { usePageContent } from '$website/containers';
import { ConversionOptimization } from './ConversionOptimization';
import styles from './style/SocialProof.css';

type Props = unknown;

const SocialProof = (props: Props) => {
  const content = usePageContent<'signup', 'feature'>('feature');

  if (!content) return null;

  return (
    <ConversionOptimization className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.headline}>{content.text.title}</div>
        </div>
        <div className={styles.main}>
          {content.collection.consultants.map(x =>

            <div
              className={styles.item}
              key={x.text.title}>
              <div className={styles.pic}>
                <img
                  className={styles.img}
                  src={x.image.src} />
              </div>
              <div className={styles.title}>{x.text.title}</div>
              <div className={styles.copy}>{x.text.copy}</div>
              <div className={styles.tags}>
                {x.collection.tags.map(tag =>
                  <div
                    key={tag}
                    className={styles.tag}>
                    {tag}
                  </div>)}
              </div>
            </div>)}
        </div>

        <div className={styles.companies}>
          <div className={styles.proof}>{content.text.proof}</div>
          <div className={styles.logos}>
            {content.collection.logos.map((x, i) =>
              <div
                className={styles.company}
                key={`logo-${i}`}>
                <img
                  className={styles.logo}
                  src={x.image.src} />
              </div>)}
          </div>
        </div>
      </div>
    </ConversionOptimization>
  );
};

export { SocialProof };
export default SocialProof;
import { useCallback, useMemo } from 'react';
import { ComputedSerie } from '@nivo/line';
import { useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks/useRepeatQuestionResponsesData';
import { SurveyQuestionType } from '@enums';
import { SeriesTooltip } from '@presentation';
import { roundToPlace } from '@utils';
import { SeriesTooltipProps } from './interfaces';

type Props =
  SeriesTooltipProps;

export default function MaxDiffChartSeriesTooltip({ series: rawSeries }: Props) {
  const { question, aggregate } = useRepeatQuestionResponsesData<SurveyQuestionType.MaxDifference>();

  const getSerieLabel = useCallback((serie: ComputedSerie) => {
    const option = question.options.find(f => f.base.id === +serie.id);
    return option.value;
  }, [question.options]);

  const getSerieAvg = useCallback((serie: ComputedSerie) => {
    const avg = aggregate[+serie.id].avg;
    return roundToPlace(avg, 2);
  }, [aggregate]);

  const getSerieStat = useCallback((serie: ComputedSerie) => {
    const avg = getSerieAvg(serie);
    return `Avg. score ${avg}`;
  }, [getSerieAvg]);

  const series = useMemo(() => {
    return [...rawSeries]
      .sort((a, b) => getSerieAvg(b) - getSerieAvg(a));
  }, [
    getSerieAvg,
    rawSeries,
  ]);

  return (
    <SeriesTooltip
      getSerieLabel={getSerieLabel}
      getSerieStat={getSerieStat}
      series={series} />
  );
}

export { MaxDiffChartSeriesTooltip };

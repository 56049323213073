import { useContext } from 'react';
import { SurveyQuestionType } from '@enums/Survey';
import { createNamedContext } from '@utils';
import { SurveyQuestion, ConjointAnalysisQuestion, MaxDifferenceQuestion } from '@/types';

export type QuestionBuilderEditingContextValue = {
  canEdit: boolean;
  editing: boolean;
};

export type QuestionBuilderDelete = () => void;

export type QuestionBuilderContextValue = {
  canSave: boolean;
  onCancel: () => void;
  onSave: () => void;
};

export type ConjointAnalysisQuestionBuilderContextValue = {
  item: ConjointAnalysisQuestion.Question;

  canAddLevel: (attributeId: string) => boolean;
  canAddAttribute: boolean;
  canRemoveLevel: (attributeId: string) => boolean;
  canRemoveAttribute: boolean;

  addAttribute: () => unknown;
  removeAttribute: (attributeId: string) => () => unknown;

  addLevel: (attributeId: string) => () => unknown;
  removeLevel: (attributeId: string) => (levelId: string) => () => unknown;

  renameAttribute: (attributeId: string) => (value: string) => unknown;
  renameLevel: (attributeId: string) => (levelId: string) => (value: string) => unknown;

  updateConceptsPerSet: (value: number) => unknown;
  updateNumberOfSets: (value: number) => unknown;
  toggleNoneOfTheAbove: () => unknown;
};

export type MaxDiffQuestionBuilderContextValue = {
  item: MaxDifferenceQuestion.Question;

  addOption: () => unknown;
  canAddOption: boolean;
  removeOption: (ordinal: number) => () => unknown;
  canRemoveOption: boolean;

  renameOption: (ordinal: number) => (value: string) => unknown;

  updateLeastLabel: (value: string) => unknown;
  updateMostLabel: (value: string) => unknown;

  updateOptionsPerSet: (value: number) => unknown;
  updateSets: (value: number) => unknown;
};

export const QuestionBuilderContext = createNamedContext<QuestionBuilderContextValue>(null!, 'QuestionBuilderContext');
export const QuestionBuilderItemContext = createNamedContext<SurveyQuestion>(undefined, 'QuestionBuilderItemContext');
export const QuestionBuilderEditingContext = createNamedContext<QuestionBuilderEditingContextValue>(undefined, 'QuestionBuilderEditingContext');
export const QuestionBuilderDeleteContext = createNamedContext<QuestionBuilderDelete>(undefined, 'QuestionBuilderDeleteContext');

export const ConjointAnalysisQuestionBuilderContext = createNamedContext<ConjointAnalysisQuestionBuilderContextValue>(undefined, 'ConjointAnalysisQuestionBuilderContext');
export const MaxDiffQuestionBuilderContext = createNamedContext<MaxDiffQuestionBuilderContextValue>(undefined, 'MaxDiffQuestionBuilderContext');

export const useQuestionBuilderEditingContext = () => useContext(QuestionBuilderEditingContext);
export const useQuestionBuilderItemContext = () => useContext(QuestionBuilderItemContext);
export const useQuestionBuilderContext = () => useContext(QuestionBuilderContext);
export const useDeleteQuestion = () => useContext(QuestionBuilderDeleteContext);

export const useConjointAnalysisQuestionBuilderContext = () => useContext(ConjointAnalysisQuestionBuilderContext);
export const useMaxDiffQuestionBuilderContext = () => useContext(MaxDiffQuestionBuilderContext);

export const useQuestionBuilderItem = <T extends SurveyQuestionType = SurveyQuestionType>() => {
  const ctx = useQuestionBuilderItemContext();
  return ctx as SurveyQuestion<T>;
};
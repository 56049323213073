import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import {
  useProjectMetricsLoading,
} from '@containers/Metrics';
import ActiveProjectsPanel from '@screens/GroupMain/ActiveProjectsPanel';
import { useRecentProfiles, useRecentNews } from '@utils/api';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ScreenLayout } from './Layout';
import AgendaCard from './AgendaCard';
import styles from './style/Main.css';
import NewsFeed from './NewsFeed';
import RecentProfiles from './RecentProfiles';
import FoldersPanel from './FoldersPanel';

const selectWorkspacesEnabled = (state: Store.State) => state.group.features.workspaces;

const AnalystMain = () => {
  const metricsLoading = useProjectMetricsLoading();
  const workspacesEnabled = useSelector(selectWorkspacesEnabled);

  const profiles = useRecentProfiles({
    notifyOnChangeProps: ['isLoading'],
  });
  const newsArticles = useRecentNews({
    notifyOnChangeProps: ['isLoading'],
    staleTime: 5 * 60 * 1000,
  });

  if (metricsLoading || newsArticles.isLoading || profiles.isLoading) {
    return <ActivityIndicator show />;
  }

  return (
    <ScreenLayout>
      <Helmet title="Home" />
      <div className={styles.col}>
        <ActiveProjectsPanel />
        {workspacesEnabled && <FoldersPanel />}
        <NewsFeed newsArticles={newsArticles.data} />
      </div>

      <div className={styles.col}>
        <div className={styles.sidebar}>
          <AgendaCard />
          <RecentProfiles />
        </div>
      </div>

    </ScreenLayout>
  );
};

export { AnalystMain };
export default AnalystMain;
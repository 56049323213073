import { useCallback, memo } from 'react';
import MicOnIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { cx } from '@utils';
import styles from './style/Microphone.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
  value: boolean;
  visible?: boolean;
  onClick?: (value: boolean) => void;
};

export const Microphone = memo(({ className, children, value, visible, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick?.(!value);
  }, [onClick, value]);

  if (!visible) return null;

  return (
    <div
      className={cx(styles.microphone, className)}
      onClick={handleClick}>
      {children ?? <MicrophoneIcon value={value} />}
    </div>
  );
});

const MicrophoneIcon = memo(({ value }: { value: boolean; }) => {
  return value
    ? (
      <div className={styles.iconMicrophone}>
        <MicOnIcon />
      </div>
    )
    : (
      <div className={styles.iconMicrophoneOff}>
        <MicOffIcon />
      </div>
    );
});
import {
  extension,
  ApplySchemaAttributes,
  ExtensionTag,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
} from '@remirror/core';
import { SurveyRichText } from '@/types/survey.rich-text';
import { TemplateCompetitor } from './Node.Template.Competitor';

@extension({
  defaultOptions: {},
})
export class TemplateCompetitorExtension extends NodeExtension {

  get name() {
    return SurveyRichText.NodeType.TemplateCompetitor;
  }

  createTags() {
    return [ExtensionTag.InlineNode];
  }

  createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
    return {
      ...override,
      atom: true,
      attrs: {
        id: {},
        competitor: {},
        ...extra.defaults(),
      },
      inline: true,
      toDOM: node => ['span', {
        ...extra.dom(node),
        contentEditable: 'false',
        selectable: 'false',
      }],
    };
  }

  ReactComponent = TemplateCompetitor;
}
import { UserEmploymentText } from '@presentation';
import { formatTenure, trunc } from '@utils';
import { EmploymentHistoryProps } from './interfaces';
import styles from './style.css';

export const EmploymentHistory = (props: EmploymentHistoryProps) => {
  return (
    <div className={styles.employments}>
      {
        props.items.map((m, i) => {
          return (
            <div key={i} className={styles.employment}>
              <div className={styles.bullet} />
              <UserEmploymentText
                company={m.company}
                title={trunc(m.title, 25)}
                tenure={formatTenure(m)} />
            </div>
          );
        })
      }
    </div>
  );
};

export default EmploymentHistory;
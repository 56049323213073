import { createRef, Component } from 'react';
import * as API from '@api/interfaces';
import embedded from '@services/esign';
import ActivityIndicator from 'components/ActivityIndicator';
import http from './http';
import styles from './style.css';
import {
  RouteProps,
  WebViewRequiredParams,
} from './interfaces';

type Props = RouteProps;

type State = {
  open: boolean;
};

class EmbeddedSignWebView extends Component<Props, State> {
  state: State = {
    open: false,
  }

  private containerRef = createRef<HTMLDivElement>();

  private client = embedded.getInstance();

  componentDidMount() {
    const params = {
      groupId: this.props.match.params.groupId,
      token: this.props.match.params.token,
      userId: this.props.match.params.userId,
    };
    this.fetchEmbeddedSignUrl(params);
    // @ts-ignore
    window.ReactNativeWebView = window.ReactNativeWebView || {};
    // @ts-ignore
    window.ReactNativeWebView.postMessage('init');
  }

  fetchEmbeddedSignUrl = (data: WebViewRequiredParams) => {
    if (this.hasValidParams(data)) {
      const api = http.create({ token: data.token });
      api
        .get(`/documents/embedded/${data.userId}/groups/${data.groupId}`)
        .then(({ data }) => this.renderEmbeddedDocument(data))
        .catch(this.handleError);
    }
  }

  handleError = (e: Error) => {
    console.log(e);
  }

  hasValidParams = (data: WebViewRequiredParams): data is WebViewRequiredParams => {
    const validParams = data.groupId && data.userId && data.token;

    return !!validParams;
  }

  renderEmbeddedDocument = (data: API.GetEmbeddedSignUrlResponse) => {
    if (!data.url || data.alreadySigned) return;

    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
    this.client.open(data.url, {
      allowCancel: false,
      container: this.containerRef.current,
      skipDomainVerification: process.env.HELLOSIGN_SKIP_DOMAIN_VERIF === 'true',
    });

    this.client.on('ready', () => {
      this.setState({ open: true });
      // @ts-ignore
      window.ReactNativeWebView.postMessage('ready');
    });

    this.client.on('finish', () => {
      // @ts-ignore
      window.ReactNativeWebView.postMessage('finish');
    });

    this.client.on('close', () => {
      // @ts-ignore
      window.ReactNativeWebView.postMessage('close');
    });
  }

  render() {
    return (
      <div
        className={styles.root}
        ref={this.containerRef}>
        <ActivityIndicator show={!this.state.open} />
      </div>
    );
  }
}

export default EmbeddedSignWebView;
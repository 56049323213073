import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext'
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { MaxDifferenceQuestion } from '@/types';
import { MaxDiffQuestionBuilderContext } from './Context';

type Props = {
  item: MaxDifferenceQuestion.Question;
} & ChildrenProps;

export default function MaxDiffQuestionBuilderContainer({ children, item }: Props) {
  const [, dispatch] = useSurveyBuilderState();

  const addOption = useCallback(() => {
    dispatch({
      type: 'maxdiff-add-option',
    });
  }, [dispatch]);

  const removeOption = useCallback((ordinal: number) => () => {
    dispatch({
      type: 'maxdiff-remove-option',
      ordinal,
    });
  }, [dispatch]);

  const renameOption = useCallback((ordinal: number) => (value: string)=> {
    dispatch({
      type: 'maxdiff-update-option-name',
      ordinal,
      value,
    });
  }, [dispatch]);

  const updateOptionsPerSet = useCallback((value: number) => {
    dispatch({
      type: 'maxdiff-update-options-per-set',
      value,
    });
  }, [dispatch]);

  const updateSets = useCallback((value: number) => {
    dispatch({
      type: 'maxdiff-update-sets',
      value,
    });
  }, [dispatch]);

  const updateLeastLabel = useCallback((value: string) => {
    dispatch({
      type: 'maxdiff-update-least-label',
      value,
    });
  }, [dispatch]);

  const updateMostLabel = useCallback((value: string) => {
    dispatch({
      type: 'maxdiff-update-most-label',
      value,
    });
  }, [dispatch]);

  const canAddOption =
    useMemo(() => item.options.length < surveyBuilder.maxDiff.MaxOptions, [item.options.length]);

  const canRemoveOption =
    useMemo(() => item.options.length > surveyBuilder.maxDiff.MinOptions, [item.options.length]);

  const value = {
    item,
    canAddOption,
    canRemoveOption,
    addOption,
    removeOption,
    renameOption,
    updateLeastLabel,
    updateMostLabel,
    updateOptionsPerSet,
    updateSets,
  };

  return (
    <MaxDiffQuestionBuilderContext.Provider value={value}>
      {children}
    </MaxDiffQuestionBuilderContext.Provider>
  );
}

export { MaxDiffQuestionBuilderContainer };
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { useMutation, UseMutationOptions } from 'react-query';
import * as actions from '@actions';
import * as api from '@api';
import * as API from '@api/interfaces';

type Data = API.Users.UpdateContactInfo.Response;
type Variables = Partial<{
  firstName: string;
  lastName: string;
  phone: string;
}>;

type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

export const useUpdateUserContactInfo = (options?: Options) => {

  const dispatch = useDispatch();

  return useMutation('update-user-contact-info', (data: Variables) => {

    return api.users.updateContactInfo(data);

  }, {
    ...options,
    onSuccess: (res, variables, context) => {
      dispatch(actions.userInfoChange({
        contact: res.contact,
        profile: res.profile,
      }));
      options?.onSuccess?.(res, variables, context);
    },
  });
};
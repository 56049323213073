import { useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FileText } from 'react-feather';
import * as mixpanel from '@services/mixpanel';
import { getLocationFor } from '@utils';
import { Call, Project, Contact } from '@/types';
import { TranscriptDownloader } from './TranscriptDownloader';
import styles from './style/VoiceTranscript.css';

type RouteParams = {
  slug: string;
};

type Props = {
  call: Pick<Call, 'id' | 'timeEnd' | 'userId'>;
  transcriptId: number;
  project: Project;
  user: Contact;
};

export default function VoiceTranscript(props: Props) {
  const params = useParams<RouteParams>();
  const { call, project, user } = props;

  const handleClick = useCallback(() => {
    mixpanel.event.project.transcript({
      project: {
        id: project.id,
        name: project.name,
      },
      user: {
        id: call.userId,
        name: user.profile.fullname,
      },
    });
  }, [call, project, user]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Link
          className={styles.view}
          to={getLocationFor.call.transcript({
            callId: call.id,
            slug: params.slug,
            type: 'voice',
          })}
          onClick={handleClick}>
          <FileText className={styles.file} />
          <div>View Transcript</div>
        </Link>
        <div>
          <TranscriptDownloader
            callId={call.id}
            transcriptId={props.transcriptId} />
        </div>
      </div>
    </div>
  );
}
export enum ChecklistItem {
  CompleteProfile = 'CompleteProfile',
  PayoutSetup     = 'PayoutSetup',
  SetRate         = 'SetRate',
  TopicFollowing  = 'TopicFollowing',
}

export type OnboardingChecklistContextValue = {
  isComplete: (key: ChecklistItem) => boolean;
  remaining:  ChecklistItem[];
  loading:    boolean;
  count: {
    complete:   number;
    incomplete: number;
    total:      number;
  };
};

export type ListItem = {
  complete: boolean;
  onClick:  () => void;
  title:    string;
};
import { path } from '@consts';
import { RouteAuth } from '@enums';
import { SessionRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { ProjectOnboardingCompletionContainer } from '@containers/ProjectOnboarding/ProjectCompletedContainer';
import { ProjectOnboardingDisqualificationContainer } from '@containers/ProjectOnboarding/ProjectDisqualificationContainer';
import { ProjectOnboardingContainer } from '@containers/ProjectOnboarding/ProjectOnboardingContainer';
import { SurveyDisqualification } from '@screens/ProjectDisqualification';
import { SurveyOnboardingInvalidContainer } from '@containers/ProjectOnboarding/SurveyOnboardingInvalid.Container';
import { ProjectSurveyArchived } from '@screens/ProjectSurveyArchived';
import { TopicOnboarding } from '@screens/Topic.Onboarding';
import { AdHocCallRequest } from '@screens/AdHocCallRequest';
import * as utils from '@utils';
import { Compliance, HourlyRateWizard, ProfileWizard, PayoutSetup } from 'scenes/onboarding';

export const routes: Routing.Route[] = [
  {
    component: ProjectSurveyArchived,
    exact: true,
    path: path.Onboarding.Projects.Archived,
  },
  {
    component: SurveyDisqualification,
    exact: true,
    path: path.Onboarding.Projects.Disqualified,
  },
  {
    component: SurveyOnboardingInvalidContainer,
    exact: true,
    path: [
      path.Onboarding.Projects.Closed,
      path.Onboarding.Projects.End,
    ],
  },
  {
    component: ProjectOnboardingCompletionContainer,
    exact: true,
    path: [
      path.Onboarding.Projects.Completion,
      path.Onboarding.Projects.Continue,
    ],
  },
  {
    component: ProjectOnboardingDisqualificationContainer,
    exact: true,
    path: path.Onboarding.Projects.Exit,
  },
  {
    component: ProjectOnboardingContainer,
    exact: true,
    path: path.Onboarding.Projects.Project,
  },
  {
    component: HourlyRateWizard,
    path: path.Onboarding.Rate,
  },
  {
    component: ProfileWizard,
    path: path.Onboarding.Profile,
  },
  {
    component: Compliance,
    path: path.Onboarding.Compliance,
  },
  {
    component: PayoutSetup,
    path: path.Onboarding.PayoutSetup,
  },
  {
    component: TopicOnboarding,
    exact: true,
    path: path.Onboarding.Topics,
  },
  {
    component: AdHocCallRequest,
    path: path.CallOnboarding.Call,
  },
];

export const router = SessionRouter(routes, {
  auth: RouteAuth.Authenticated,
  path: path.Onboarding.Root,
  roles: utils.roles.consultant,
});
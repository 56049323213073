import http from '@services/http';
import { qs, safeJsonDate } from '@utils';
import { transformCallItem } from './transformers/projects';
import { Projects } from './interfaces';

export const createPendingCall = (data: Projects.CreatePendingCall.Request) => {
  return http.post(`/admin/projects/${data.projectId}/calls`, data)
  .then<Projects.CreatePendingCall.Response>((res: Projects.CreatePendingCall.Response<string>) => ({
    call: {
      ...res.call,
      createdAt: safeJsonDate(res.call.createdAt),
    },
  }));
};

export const fetchCalls = (data: Projects.FetchCalls.Request): Promise<Projects.FetchCalls.Response> => {
  return http.get(`/admin/projects/${data.projectId}/calls?${qs.stringify(data, { arrayFormat: 'bracket' })}`)
  .then((data: Projects.FetchCalls.Response<string>) => ({
    items: data.items.map(transformCallItem),
    filters: data.filters,
    pagination: data.pagination,
  }));
};
import { useEffect, useState } from 'react';
import { SelectBase } from './SelectBase';
import { SelectBaseProps } from './interfaces';

type Props<T> = {
  className?:  string;
  loadOptions: () => Promise<T[]>;
} & Omit<SelectBaseProps<T>, 'options'>;

const SelectAsync = <T extends unknown>(props: Props<T>) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {

    props.loadOptions().then(setOptions);

  }, [props.loadOptions, setOptions]);

  return (
    <SelectBase
      autoFocus={props.autoFocus}
      className={props.className}
      defaultValue={props.defaultValue}
      getItemValue={props.getItemValue}
      onChange={props.onChange}
      onSelect={props.onSelect}
      options={options}
      placeholder={props.placeholder}
      value={props.value} />
  );
};

export { SelectAsync };
export { Props as SelectAsyncProps };
export default SelectAsync;
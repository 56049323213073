import { useContext, useState, useRef, useMemo } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { State as PopperState } from '@popperjs/core';
import { X } from 'react-feather';
import { ProjectResponseFilterContext } from '@containers/GroupProject/Context';
import { SurveyFiltering } from '@/types';
import { SurveyQuestionType } from '@enums';
import { cx } from '@utils';
import { CheckboxGroup, CheckboxGroupItem, CheckboxGroupOnItemToggleItem } from 'components/Checkbox';
import { FilterProps, OrderedFilter } from './utils';
import styles from './style/SurveyResponseFilter.css';
import { Selector } from './Selector';
import { useValidFilterQuestions } from './hooks';

export const MultiChoiceFilter = ({ filterEntry, filterActions }: FilterProps<SurveyQuestionType.MultipleChoice>) => {
  const filterContext = useContext(ProjectResponseFilterContext);
  const [isOpen, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(filterEntry.filter?.optionIds || []);
  const [optionsIsSelected, setOptionsIsSelected] = useState<boolean>(filterEntry.filter ? filterEntry.filter.isSelected : true);

  const options = useMemo(() => {
    return filterContext.questionData.find(q => q.base.id == filterEntry.questionId).options;
  }, [filterContext.questionData, filterEntry.questionId]);

  const validQuestions = useValidFilterQuestions();

  function onCheckboxToggle(item: CheckboxGroupOnItemToggleItem) {
    let newItems:number[] = [];
    if (selectedOptions.includes(+item.id)) {
      newItems = selectedOptions.filter(o => o !== +item.id);
    } else {
      newItems = [...selectedOptions, +item.id];
    }

    setSelectedOptions(newItems);

    passbackFilter({ optionIds: newItems });
  }

  function onIsSelectedChange(val: boolean) {
    setOptionsIsSelected(val);
    passbackFilter({ isSelected: val });
  }

  function onQuestionChange(questionId: number) {
    setSelectedOptions([]);
    filterActions.changeFilter({
      questionId,
      filter: null,
      order: filterEntry.order,
    });
  }

  function passbackFilter(partialFilter?: Partial<SurveyFiltering.QuestionFilter<SurveyQuestionType.MultipleChoice>>) {
    const constructedFilter: OrderedFilter<SurveyQuestionType.MultipleChoice> = {
      questionId: filterEntry.questionId,
      filter: { ...{
        type: 'option-filter',
        optionIds: selectedOptions,
        isSelected: optionsIsSelected,
      }, ...partialFilter },
      order: filterEntry.order,
    };

    filterActions.changeFilter(constructedFilter);
  }

  function clearAll() {
    setSelectedOptions([]);
    passbackFilter({ optionIds: [] });
  }

  function selectAll() {
    const allOptions = options.map(o => o.base.id);
    setSelectedOptions(allOptions);
    passbackFilter({ optionIds: allOptions });
  }

  const checkboxItems = options.map<CheckboxGroupItem>(o => ({
    label: o.value,
    id: o.base.id,
  }));

  const buttonRef = useRef();

  return (
    <div className={styles.filterRow} key={filterEntry.questionId}>
      <Selector
        buttonClass={styles.questionSelectorButton}
        items={validQuestions}
        displayValue={v => `Q${v.ordinal}`}
        onSelect={q => onQuestionChange(q.base.id)}
        value={filterContext.questionData.find(q => q.base.id == filterEntry.questionId)} />
      <Selector
        items={[true, false]}
        displayValue={v => v ? 'Selected' : 'Not Selected'}
        onSelect={onIsSelectedChange}
        value={optionsIsSelected} />
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div className={styles.filterRowInput}>
          <div className={styles.selectorButton} ref={buttonRef} onClick={e => setOpen(!isOpen)}>
            {selectedOptions.length ? selectedOptions.map(o1 => options.find(o2 => o2.base.id == o1)?.value).filter(Boolean).join(', ') : 'Select a value'}
          </div>
          <Popper
            open={isOpen}
            anchorEl={buttonRef.current}
            className={cx(styles.selectorPopover, styles.multichoicePopover)}
            placement={'bottom-start'}
            popperOptions={{
              modifiers: [
                {
                  name: 'update-width',
                  phase: 'afterWrite',
                  enabled: true,
                  fn({ state }: { state: PopperState; }) {
                    state.elements.popper.style.width = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
                  },
                },
              ],
              onFirstUpdate: state => {
                state.elements.popper.style.width = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
              },
            }}>
            <div className={styles.multichoiceToggleAll}>
              <span
                style={{ marginRight: '10px' }}
                onClick={selectAll}>
                Select All
              </span>
              <span onClick={clearAll}>Clear All</span>
            </div>
            <div className={styles.checkboxGroupContainer}>
              <CheckboxGroup
                className={styles.checkboxGroup}
                items={checkboxItems}
                checkedItems={selectedOptions}
                onToggle={onCheckboxToggle} />
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
      <div
        className={styles.deleteFilter}
        onClick={filterActions.deleteFilter}>
        <X  />
      </div>
    </div>
  );
};
import { Redirect, Route, Switch } from 'react-router-dom';
import * as consts from '@consts';
import Routes from './routes';

export function UserBilling() {
  return (
    <Switch>
      {Routes.map(route =>
        <Route
          exact={route.exact}
          key={route.name}
          path={route.to}
          component={route.component} />)}
      <Redirect to={consts.path.Settings.Account} />
    </Switch>
  );
}

export default UserBilling;
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from '@store/selectors';
import { ProjectSurveyResponseStatus } from '@enums';
import { PipelineRespondentsMap } from '@/types';
import { useProjectOrLatestChild } from './useProjectState';

const cutoff = 300;
const validResponseStatuses = [ProjectSurveyResponseStatus.Active];

export const useIsMassSurvey = () => {
  const project = useProjectOrLatestChild();
  const respondents: PipelineRespondentsMap = useSelector(selectors.pipeline.client.respondents(project.id));

  const isMassSurvey = useMemo(() => Object.values(respondents).filter(r => validResponseStatuses.includes(r.projectSurveyResponse.statusId)).length >= cutoff, [respondents]);

  return isMassSurvey;
};
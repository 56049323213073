import { useSelectObject } from '@containers/Store';
import { useUploadFilesContext } from '@containers/WorkspaceFileUpload/hooks/useUploadFilesContext';
import { useWorkspaceState } from '@containers/Workspace/hooks/useWorkspaceState';
import { useFilesTableItems } from 'components/WorkspaceFiles/hooks/useFilesTableItems';
import FilesTable from './FilesTable';

export const WorkspaceTabFiles = () => {
  const { workspace } = useWorkspaceState();
  const object = useSelectObject(workspace?.objectId);

  const data = useFilesTableItems({ parentObjectId: workspace.objectId });

  const uploadFiles = useUploadFilesContext({
    parentObjectId: object.id,
    workspaceId: object.workspaceId,
  });

  return (
    <FilesTable
      canUpload={true}
      data={data}
      onUpload={uploadFiles} />
  );
};

export default WorkspaceTabFiles;
import { useCallback } from 'react';
import { useSelectUser } from '@containers/Store/hooks/useSelectUser';
import * as mixpanel from '@services/mixpanel';
import { Survey } from '@services/mixpanel/interfaces';

const useSurveyQuestionViewEvent = () => {
  const user = useSelectUser();

  const viewQuestion = useCallback((data: Omit<Survey.ViewQuestion, 'user'>) => {
    mixpanel.event.survey.viewQuestion({
      user: {
        id: user.id,
        name: user.profile.fullname,
      },
      id: data.id,
      version: data.version,
      value: data.value,
    });
  }, [
    user.id,
    user.profile.fullname,
  ]);

  return viewQuestion;
};

export { useSurveyQuestionViewEvent };
export default useSurveyQuestionViewEvent;
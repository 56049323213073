import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@store/actions';
import * as api from '@api';
import {
  DownloadProjectSummaryContext,
  SaveProjectSummaryContext,
} from './Context';

type Props =
  IProjectId &
  ChildrenProps;

export const ProjectSummaryContainer = (props: Props) => {

  const dispatch = useDispatch();

  const saveProjectSummary = useCallback((summary: string) => {
    return api.projects.updateProjectSummary({
      projectId: props.projectId,
      summary,
    }).then(_ => {
      dispatch(actions.projectUpdated({
        projectId: props.projectId,
        project: {
          notesSummary: summary,
        },
      }));
    });
  }, [
    dispatch,
    props.projectId,
  ]);

  const downloadProjectSummary = useCallback((downloadName: string) => () => {
    return api.projects.downloadProjectSummary({
      projectId: props.projectId,
    })
    .then(data => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', downloadName);
      document.body.appendChild(link);
      link.click();
    });
  }, [
    props.projectId,
  ]);

  return (
    <DownloadProjectSummaryContext.Provider value={downloadProjectSummary}>
      <SaveProjectSummaryContext.Provider value={saveProjectSummary}>
        {props.children}
      </SaveProjectSummaryContext.Provider>
    </DownloadProjectSummaryContext.Provider>
  );
};

export default ProjectSummaryContainer;
import * as pathname from '@consts/pathname';
import { extend, join } from '@utils/path';

const privacyPolicy = join(pathname.PrivacyPolicy);
const signup = join(pathname.Signup);
const terms = join(pathname.Terms);

const PrivacyPolicy = {
  Root: privacyPolicy(),
};

const Signup = {
  Root: signup(),
};

const Terms = {
  Root: terms(),
};

export const Website = extend({
  About:              `/about`,
  Careers:            `/careers`,
  ClientTerms:        `/client-terms`,
  CollaborationTools: `/collaboration-tools`,
  Compliance:         `/compliance`,
  Consulting:         `/consulting`,
  Contact:            `/contact`,
  Corporate:          `/corporate`,
  CustomerResearch:   `/customer-research`,
  Demo:               `/demo-legacy`,
  Enterprise:         `/enterprise`,
  EnterpriseLogin:    `/enterprise/login`,
  ForgotPassword:     `/forgot-password`,
  Individuals:        `/individuals`,
  Investment:         `/investment`,
  Login:              `/login`,
  Logout:             `/logout`,
  PrivacyPolicy,
  Pricing:            `/pricing`,
  ProductResearch:    `/product-research-guide`,
  Quant2Qual:         `/quant2qual`,
  ReferralSignup:     `/r/signup`,
  ResetPassword:      `/reset-password`,
  Root:               `/`,
  Signup,
  SnowflakeReport:    `/snowflake`,
  SurveyBestPractices:`/survey-best-practices`,
  Terms,
});
import { useCallback, useContext } from 'react';
import { ProjectMetricsContext } from '../Context';

export const useProjectMetrics = () => {
  const map = useContext(ProjectMetricsContext);

  const getProjectMetrics = useCallback((projectId: number) => {
    const metrics = map?.[projectId];

    return metrics;
  }, [
    map,
  ]);

  return getProjectMetrics;
};

export default useProjectMetrics;
import * as xform from '@transformers/helpers';
import { safeJsonDate, slugify } from '@utils';
import { Contact, PipelineRecord, ProfileStatus, User, UserProfileObject, UserProfileProps } from '@/types';

const addUserBindings = user => {
  return !Array.isArray(user)
    ? addBindingsToUser(user)
    : addBindingsToUsers(user);
};

function addBindingsToUser(user) {
  const runTransforms = xform.compose(setUserSlug, normalizeDates, normalizeUserProfile);

  return runTransforms(user);
}

function addBindingsToUsers<T extends Partial<Contact>[]>(users: T) {
  const runTransforms = xform.compose<Contact, T>(setUserSlug, normalizeDates, assignProfileProp);

  return xform.toMap<number, Contact>(users.map(runTransforms));
}

function normalizeDates(user) {
  return Object.keys(user).reduce((acc, key) => {
    const val = acc[key];

    if (!['createdOn'].includes(key)) {
      acc[key] = val;
    } else {
      acc[key] = safeJsonDate(val);
    }

    return acc;
  }, { ...user });
}

function normalizeUserProfile(data: User) {
  return {
    ...data,
    profile: normalizeProfile(data.profile),
  };
}

function normalizeProfile(data: UserProfileObject) {
  return {
    ...data,
    status: normalizeProfileStatus(data.status),
  };
}

function normalizeProfileStatus(data: ProfileStatus) {
  const runTransforms = xform.compose<ProfileStatus, UnparsedProfileStatus>(
    normalizeDates,
  );

  return runTransforms<ProfileStatus>(data);

  function normalizeDates(status: UnparsedProfileStatus) {
    return {
      ...status,
      lastUpdated: safeJsonDate(status.lastUpdated),
    };
  }
}

type ContactLike =
  & Partial<Pick<Contact, 'id' | 'profile'>
  & Pick<PipelineRecord, 'userId'>>;

type GenerateSlugParams =
  & ContactLike
  & Pick<UserProfileProps, 'firstName' | 'lastName'>

type SlugProp =
  Required<Pick<UserProfileProps, 'slug'>>;

type ProfileProp =
  Partial<Pick<Contact, 'profile'>>;

function setUserSlug<T extends ContactLike>(user: T): T {
  const slug = slugify({
    id: user.id || user.userId,
    name: user.profile.fullname || `${user.profile.firstName} ${user.profile.lastName}`,
  });

  Object.assign<T['profile'], SlugProp>(user.profile, { slug });

  return user;
}

const setUserSlugGeneric = <T extends GenerateSlugParams>(user: T) => {
  return Object.assign<T, SlugProp>(user, { slug: generateSlug(user) });
};

function generateSlug(user: GenerateSlugParams) {
  return slugify({
    id: user.id || user.userId,
    name: `${user.firstName} ${user.lastName}`,
  });
}

function assignProfileProp<T extends ContactLike, U extends Contact['profile']>(user: T & U): T | U & ProfileProp {
  if (user.profile) {
    return user;
  }

  const profile = [
    'firstName',
    'fullname',
    'lastName',
    'hourlyRate',
    'pictureUrl',
    'publicUrl',
    'slug',
    'status',
  ].reduce<U>((acc, prop) => ({ ...acc, [prop]: user[prop] }), {} as U);

  return Object.assign<U, ProfileProp>(user, { profile });
}

type UnparsedProfileStatus = {
  [P in keyof Pick<ProfileStatus, 'lastUpdated'>]: string;
} & Omit<ProfileStatus, 'lastUpdated'>;

export {
  addBindingsToUser,
  addBindingsToUsers,
  addUserBindings,
  assignProfileProp,
  generateSlug,
  normalizeProfile,
  setUserSlug,
  setUserSlugGeneric,
};
import { Cell } from 'react-table';
import { TopicLogo } from '@presentation/Topic.Logo';
import ClickContainer from './ResultClickContainer';
import { TopicsTableItem } from './interfaces';
import styles from './style/Cell.Topic.css';

export default function Topic({ row: { original: data } }: Cell<TopicsTableItem>) {
  return (
    <ClickContainer item={data}>
      <div className={styles.root}>
        <div className={styles.picture}>
          <TopicLogo
            className={styles.icon}
            url={data.metadata.pictureUrl} />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>{data.name}</div>
          {data.description?.length ? <div className={styles.description}>{data.description}</div> : null}
        </div>
      </div>
    </ClickContainer>
  );
}

export { Topic };
import { SurveyBuilder, SurveySectionsBuilder } from '../interfaces';
import * as $actions from './state.sections';

export function sectionsReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveySectionsBuilder.State {

  const sections = state.survey.sections;

  switch (action.type) {
    case 'new-section-added':
      return $actions.newSectionAdded(sections, action);

    case 'section-added':
      return $actions.sectionAdded(sections, action);

    case 'remove-section':
      return $actions.removeSection(sections, action);

    case 'update-section':
      return $actions.updateSection(sections, action);

    case 'toggle-section-hidden':
      return $actions.toggleSectionHidden(sections, action);

    default:
      return sections;
  }
}
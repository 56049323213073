import { useCallback, useEffect, useMemo, useState } from 'react';
import { getSelectedWord } from '@remirror/core';
import { useAttrs, useExtension, useRemirrorContext } from '@remirror/react';
import { LinkExtension, ShortcutHandlerProps, createMarkPositioner } from 'remirror/extensions';
import { LinkFloatingToolbarContext, LinkShortcutContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const LinkContainer = (props: Props) => {
  const [linkshortcut, setLinkShortcut] = useState<ShortcutHandlerProps | undefined>();
  const [editing, setEditing] = useState(false);

  useExtension(LinkExtension, ({ addHandler }) => addHandler('onShortcut', props => {
    if (!editing) {
      setEditing(true);
    }

    return setLinkShortcut(props);
  }), [editing]);

  const value = {
    editing,
    link: linkshortcut,
    setEditing,
  };

  return (
    <LinkShortcutContext.Provider value={value}>
      <SelectionContainer>
        {props.children}
      </SelectionContainer>
    </LinkShortcutContext.Provider>
  );
};

LinkContainer.displayName = 'LinkContainer';

const SelectionContainer = (props: Props) => {
  const context = useRemirrorContext();

  const getSelectedGroup = useCallback(() => {
    const state = context.getState();
    const link = getSelectedWord(state);

    return link;
  }, [context]);

  const url = (useAttrs().link()?.href as string) ?? '';
  const linktext = getSelectedGroup()?.text ?? '';
  const [href, setHref] = useState<string>(url);
  const [text, setText] = useState<string>(linktext);
  const [popper, setPopperOpen] = useState(false);

  const positioner = useMemo(() => {
    return createMarkPositioner({ type: 'link' });
  }, []);

  useExtension(LinkExtension, ({ addHandler }) => addHandler('onClick', (e, props) => {

    setPopperOpen(true);
    setHref(props.href);
    setText(props.text);

    return false;
  }), [
    setPopperOpen,
    setHref,
    setText,
  ]);

  useEffect(() => {
    setHref(url);
  }, [url]);

  useEffect(() => {
    setText(linktext);
  }, [linktext]);

  const value = {
    href,
    getSelectedGroup,
    popper,
    positioner,
    setHref,
    setPopperOpen,
    setText,
    text,
  };

  return (
    <LinkFloatingToolbarContext.Provider value={value}>
      {props.children}
    </LinkFloatingToolbarContext.Provider>
  );
};
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, Breadcrumbs } from '@presentation/Breadcrumbs';
import { getLocationFor, trunc } from '@utils';
import { selectGetWorkspace } from '@store/selectors';
import { WorkspaceObject } from '@/types';
import { SharedBreadcrumb, WorkspaceBreadcrumb } from './WorkspaceBreadcrumb';
import FolderPathPopper from './FolderPathPopper';
import styles from './style/Breadcrumbs.css';

type Props = {
  items: WorkspaceObject[];
  levelsToShow?: number;
};

const selectMeId = (state: Store.State) => state.user.id;

export const WorkspaceBreadcrumbPath = ({
  items,
  levelsToShow = 1,
}: Props) => {

  const meId = useSelector(selectMeId);
  const getWorkspace = useSelector(selectGetWorkspace);

  const renderEllipses = useCallback(() => {
    const ellipsesItems = items.slice(1, items.length - levelsToShow);
    return (
      <div className={styles.item}>
        <FolderPathPopper
          items={ellipsesItems} />
      </div>
    );
  }, [
    items,
    levelsToShow,
  ]);

  const renderRoot = useCallback(() => {
    const rootObject = items[0];

    if (rootObject?.ownerId !== meId) {
      return (
        <div className={styles.item}>
          <SharedBreadcrumb />
        </div>
      );
    } else {
      const workspace = getWorkspace(rootObject.entityId);

      return (
        <div className={styles.item}>
          <WorkspaceBreadcrumb
            workspace={workspace} />
        </div>
      );
    }
  }, [
    getWorkspace,
    meId,
    items,
  ]);

  const renderFolders = useCallback(() => {
    const folderItems = items.slice(items.length - levelsToShow, items.length);
    return (
      <>
        {folderItems.map(object => (
          <div key={object.id} className={styles.item}>
            <Link to={getLocationFor.workspaces.folder({ folderId: object.entityId })}>
              <Breadcrumb>
                {trunc(object.name, 30)}
              </Breadcrumb>
            </Link>
          </div>
        ))}
      </>
    );
  }, [
    items,
    levelsToShow,
  ]);

  return (
    <Breadcrumbs>
      {renderRoot()}
      {items.length > levelsToShow + 1 && renderEllipses()}
      {items.length > levelsToShow && renderFolders()}
    </Breadcrumbs>
  );
};

export default WorkspaceBreadcrumbPath;
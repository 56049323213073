import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { path } from '@consts';
import { PayoutSetup } from '@containers/PayoutSetup';
import { Setup } from '@screens/PayoutSetup/Setup';
import styles from './style/NewPayoutMethod.css';

export default function NewPayoutMethod() {
  const history = useHistory();

  const handleNext = useCallback(() => {
    history.replace(path.Settings.UserBilling);
  }, [history]);

  const navigation = {
    back: null,
    next: handleNext,
    skip: null,
  };

  return (
    <div className={styles.root}>
      <PayoutSetup>
        <Setup
          navigation={navigation}
          nextLabel="Complete" />
      </PayoutSetup>
    </div>
  );
}

export { NewPayoutMethod };
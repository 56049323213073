import qs from 'query-string';
import http from '@services/http';
import { User } from '@/types';
import { safeJsonDate } from '@utils';
import { Admin, GetUserDetails } from './interfaces';

export * as privacy from './users.privacy';

export const deleteUserAccount = (data: IUserId) => {
  return http.delete(`/admin/users/${data.userId}`);
};

export const getUserByEmail = (data: Pick<User['contact'], 'email'>) => {
  return http.get(`/admin/users?email=${data.email}`);
};

export const getInternal = (): Promise<Admin.GetInternalUsers.Response> => {
  return http.get(`/admin/users/internal`);
};

export const getDetails = (data: GetUserDetails.Request) => {
  return http.get(`/admin/users/${data.userId}/details`)
    .then((user: GetUserDetails.Response) => {
      return {
        ...user,
        createdOn: safeJsonDate(user.createdOn),
        identities: user.identities.map(m => ({
          ...m,
          createdOn: safeJsonDate(m.createdOn),
          lastLogin: safeJsonDate(m.lastLogin),
        })),
      };
    });
};

export const fetchContactInfo = (data: Admin.FetchUserContactInfo.Request): Promise<Admin.FetchUserContactInfo.Response> => {
  return http.get(`/admin/users/${data.userId}/contact-info`);
};

export const fetchPayoutInfo = (data: Admin.FetchUserPayoutInfo.Request): Promise<Admin.FetchUserPayoutInfo.Response> => {
  return http.get(`/admin/users/${data.userId}/payout-info`);
};

export const saveContactInfo = (data: Admin.SaveUserContactInfo.Request): Promise<Admin.SaveUserContactInfo.Response> => {
  return http.put(`/admin/users/${data.userId}/contact-info`, data);
};

export const savePayoutInfo = (data: Admin.SaveUserPayoutInfo.Request): Promise<Admin.SaveUserPayoutInfo.Response> => {
  return http.put(`/admin/users/${data.userId}/payout-info`, data);
};

export const autocomplete = (data: Admin.FillUserAutocomplete.Request): Promise<Admin.FillUserAutocomplete.Response> => {
  return http.get(`/admin/users/autocomplete?${qs.stringify(data, { arrayFormat: 'bracket' })}`);
};

export const toggleEnabled = (data: Admin.ToggleUserEnabled.Request) => {
  return http.post(`/admin/users/${data.userId}/enabled`, {
    enabled: data.enabled,
  });
};

export const updateUserRole = ({ userId, ...data }: Admin.UpdateUserRole.Request): Promise<Admin.UpdateUserRole.Response> => {
  return http.patch(`/admin/users/${userId}/role`, data);
};

export const updateUserRoles = ({ userId, ...data }: Admin.UpdateUserRoles.Request): Promise<Admin.UpdateUserRoles.Response> => {
  return http.patch(`/admin/users/${userId}/roles`, data);
};

export const updateGroupBillingAdmin = ({ userId, ...data }: Admin.UpdateGroupBillingAdmin.Request): Promise<Admin.UpdateGroupBillingAdmin.Response> => {
  return http.patch(`/admin/users/${userId}/group-billing-admin`, data);
};

export const syncUserToHubspot = (userIds: IUserId[]) => {
  return http.put(`/admin/users/sync-to-hubspot`, userIds);
};

export const updateUserTeam = ({ userId, ...data }: Admin.UpdateUserTeam.Request): Promise<Admin.UpdateUserTeam.Response> => {
  return http.patch(`/admin/users/${userId}/team`, data);
};
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ProjectType } from '@enums';
import { useRequestQuant2Qual } from '@containers/Call/hooks/useRequestQuant2Qual';
import { PaidMessagingContainer } from '@containers/PlatformMessage';
import { RequestCallContainer } from '@containers/RequestCall';
import { useSelectContact, useSelectProject } from '@containers/Store';
import { useProfileMessagingAction, useProfileRequestCallAction } from 'components/UserProfile/hooks';
import { MemberCardContent } from './MemberCard.Content';
import { CardTooltip } from './HtmlTooltip';

type Props = {
  children: React.ReactNode;
  userId: number;
  projectId: number;
  className?: string;
};

const selectShowRate = (contactId: number) => (state: Store.State) => {
  return state.group.features.showConsultantRates &&
    state.user.id != contactId;
};

const MemberBaseballCardWithState = ({
  children,
  className,
  projectId,
  userId,
}: Props) => {
  const contact = useSelectContact(userId);
  const project = useSelectProject(projectId);
  const showRate = useSelector(selectShowRate(userId));

  const params = {
    user: {
      id: contact.id,
      profile: contact.profile,
    },
  };
  const [canInitiateMessages, initiateMessage] = useProfileMessagingAction(params);
  const [canInitiateCalls, initiateCall] = useProfileRequestCallAction(params);
  const requestQuant2Qual = useRequestQuant2Qual();

  // TODO: handle no project passed in
  const handleCallInitiated = useCallback(() => {
    if (!projectId) return null;

    if (project.projectType === ProjectType.Call) {
      initiateCall();
    } else {
      requestQuant2Qual({
        expertId: userId,
        expertName: contact.profile.fullname,
        projectId,
      });
    }
  }, [
    contact.profile.fullname,
    initiateCall,
    projectId,
    project?.projectType,
    requestQuant2Qual,
    userId,
  ]);

  return (
    <>
      <CardTooltip
        className={className}
        title={(
          <MemberCardContent
            canInitiateCalls={canInitiateCalls}
            canInitiateMessages={canInitiateMessages}
            contact={contact}
            initiateCall={handleCallInitiated}
            initiateMessage={initiateMessage}
            showRate={showRate} />
        )}>
        {children}
      </CardTooltip>
    </>
  );
};

export const MemberBaseballCard = (props: Props) => (
  <PaidMessagingContainer>
    <RequestCallContainer>
      <MemberBaseballCardWithState {...props} />
    </RequestCallContainer>
  </PaidMessagingContainer>
);

export default MemberBaseballCard;
import { X } from 'react-feather';
import * as consts from '@consts';
import Button from 'components/Button';
import { useDevice } from '@utils';
import styles from './style.css';

export type BannerProps = {
  onDismiss: () => unknown;
};

export const AppBanner = (props: BannerProps) => {
  const device = useDevice();

  const getDownloadUrl = () => {
    if (device.apple.phone) {
      return consts.urls.AppStoreUrl;
    }

    if (device.android.phone) {
      return consts.urls.PlayStoreUrl;
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.left}>
          <div
            className={styles.close}
            onClick={props.onDismiss}>
            <X />
          </div>
          <div className={styles.body}>
            <div className={styles.title}>The Vancery App</div>
            <div className={styles.subtitle}>Book and manage projects on the go</div>
          </div>
        </div>
        <div className={styles.right}>
          <a
            onClick={props.onDismiss}
            href={getDownloadUrl()}>
            <Button.Primary
              className={styles.btn}
              title='GET THE APP'
              variant='brick' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppBanner;

import { useContext, useState, useRef } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { Filter } from 'react-feather';
import { ProjectResponseFilterContext } from '@containers/GroupProject/Context';
import { getNumberOfActiveFilters } from '@containers/GroupProject/utils/survey-filtering';
import { SurveyFiltering } from '@/types';
import { SurveyQuestionType } from '@enums';
import { cx, arr } from '@utils';
import styles from './style/SurveyResponseFilter.css';
import { MultiChoiceFilter } from './MultichoiceFilter';
import { SliderFilter } from './SliderFilter';
import { TextFilter } from './TextFilter';
import { IndexedFilters, OrderedFilter, FilterActions } from './utils';
import { useValidFilterQuestions } from './hooks';

export const SurveyResponseFilterContainer = () => {
  const filterContext = useContext(ProjectResponseFilterContext);
  const validQuestions = useValidFilterQuestions();

  if (!filterContext || (filterContext.questionData && validQuestions.length <= 0)) {
    return null;
  }

  return (<SurveyResponseFilter />);
};

export const SurveyResponseFilter = () => {
  const filterContext = useContext(ProjectResponseFilterContext);
  const validQuestions = useValidFilterQuestions();
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef();
  const isLoading = filterContext && filterContext.questionData == undefined;
  const numFilters = getNumberOfActiveFilters(filterContext.filter);

  const filterState = (filterContext.filter ?
    arr.indexBy(filterContext.filter.filters, v => v.order)
    : {});
  const keyIncrement = useRef(0);

  if (!filterContext) {
    return null;
  }

  const filterIsActive = numFilters > 0;

  function setFilter(filter: IndexedFilters) {
    filterContext.setFilter({filters: Object.values(filter) });
  }

  function clickAway() {
    if (isOpen) {
      setIsOpen(false);
    }
  }

  function toggleOpen() {
    if (!isLoading) {
      setIsOpen(!isOpen);
    }
  }

  function updateFilter(key: number, filter: OrderedFilter) {
    let keyToUse = key;
    const newFilter = { ...filterState };
    if (filter.questionId != newFilter[key].questionId) {
      delete newFilter[keyToUse];
      keyToUse = keyIncrement.current + 1;
      keyIncrement.current = keyIncrement.current + 1;
    }
    newFilter[keyToUse] = filter;
    setFilter(newFilter);
  }

  function addNewFilter() {
    const newFilter = { ...filterState };
    const newOrder = Object.keys(newFilter).length ? Math.max(...Object.values(newFilter).map(f => f.order)) + 1 : 0;
    newFilter[keyIncrement.current + 1] = { questionId: validQuestions[0].base.id, filter: null, order: newOrder };
    keyIncrement.current = keyIncrement.current + 1;
    setFilter(newFilter);
  }

  function deleteFilter(key: number) {
    const newFilter = { ...filterState };
    delete newFilter[key];
    setFilter(newFilter);
  }

  return (
    <div>
      <ClickAwayListener onClickAway={clickAway}>
        <div>
          <button className={cx(styles.filterBtn, isOpen ? styles.open : null, filterIsActive ? styles.active : null, isLoading ? styles.disabled : null)} onClick={toggleOpen} ref={buttonRef}>
            <span className={styles.filterIconContainer}>
              <Filter height={20} width={20} />
              <span className={styles.filterActiveCircle} />
            </span>
            <span className={styles.hideMobile}>Filter <FilterCount /></span>
          </button>
          <Popper open={isOpen} anchorEl={buttonRef.current} className={styles.filterPopover} placement={'bottom-end'}>
            {filterState && Object.entries(filterState).sort(([, a], [, b]) => a.order - b.order).map(([k, f]) => {
              const key = +k;
              return FilterDisplay(key, filterContext.questionData.find(q => q.base.id == f.questionId).typeId, f);
            })}
            <div className={cx(styles.filterRow, styles.addFilter)} onClick={addNewFilter}>+ Add Filter</div>
          </Popper>
        </div>
      </ClickAwayListener>
    </div>
  );

  function FilterCount() {
    if (numFilters > 0) {
      return <span>({numFilters})</span>;
    } else {
      return null;
    }
  }

  function FilterDisplay(key: number, questionType: SurveyQuestionType, filterEntry: SurveyFiltering.FilterEntry) {
    const filterActions: FilterActions = {
      deleteFilter: () => deleteFilter(key),
      changeFilter: f => updateFilter(key, f),
    };

    switch (questionType) {
      case SurveyQuestionType.MultipleChoice:
      case SurveyQuestionType.Multiselect: {
        return (
          <MultiChoiceFilter
            key={key}
            filterActions={filterActions}
            filterEntry={filterEntry as SurveyFiltering.FilterEntry<SurveyQuestionType.MultipleChoice>} />
        );
      }
      case SurveyQuestionType.LongTextResponse:
      case SurveyQuestionType.ShortTextResponse: {
        return (
          <TextFilter
            key={key}
            filterActions={filterActions}
            filterEntry={filterEntry as SurveyFiltering.FilterEntry<SurveyQuestionType.LongTextResponse>} />
        );
      }
      case SurveyQuestionType.Sliders: {
        return (
          <SliderFilter
            key={key}
            filterActions={filterActions}
            filterEntry={filterEntry as SurveyFiltering.FilterEntry<SurveyQuestionType.Sliders>} />
        );
      }
    }

    return null;
  }
};
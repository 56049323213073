import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { RankingListDropAreaProps } from './interfaces';

export const RankingListDropArea = (props: RankingListDropAreaProps) => {
  return (
    <Droppable droppableId="droppable">
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}>
          {props.children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default RankingListDropArea;
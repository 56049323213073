import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { CallTranscripts } from '@api/interfaces/calls';
import * as api from '@api';

type Data = CallTranscripts.Response;
type QueryKey = [string, ICallId];

export function useCallTranscript(
  data: ICallId,
  options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>
) {

  return useQuery(['call-transcript', data], ({ queryKey }) => {
    const callId = (queryKey[1] as ICallId).callId;

    return api.calls.getTranscript({
      callId,
    });
  }, options);
}

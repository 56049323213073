import { useSurveyFormQuestionContext } from '@containers';
import { SurveyFormItem } from 'components/SurveyForm';
import Navigation from './Navigation';
import { QuestionProps } from './interfaces';
import styles from './style.css';

export const SurveyQuestionScreen = (props: QuestionProps) => {
  const context = useSurveyFormQuestionContext();

  return (
    <div className={styles.root}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.question}>
        <SurveyFormItem
          answer={context.answer}
          item={{
            ...context.question,
            ordinal: context.ordinal,
          }}
          setAnswer={context.setAnswer} />
      </div>
      <div className={styles.footer}>
        <Navigation />
      </div>
    </div>
  );
};

export default SurveyQuestionScreen;
import { Route, Switch } from 'react-router-dom';
import { path } from '@consts';
import { ConnectedAppsContainer } from '@containers/Group.Contacts/ConnectedApps.Container';
import { ImportStepper } from '@screens/Group.Contacts.ImportStepper';
import { ContactRecord } from '@screens/Group.Contacts.Record';
import { Leads } from './Leads';
import { Sidebar } from './Sidebar';
import styles from './style/GroupContacts.css';

type Props = unknown;

export const GroupContacts = (props: Props) => {
  return (
    <ConnectedAppsContainer>
      <Router />
    </ConnectedAppsContainer>
  );
};

GroupContacts.displayName = 'Group.Contacts';

const Router = (props: Props) => {
  return (
    <Switch>
      <Route
        component={Contacts}
        exact
        path={path.Contacts.Root} />
      <Route
        component={ImportStepper}
        path={path.Contacts.Import} />
      <Route
        component={ContactRecord}
        exact
        path={path.Contacts.Record} />
    </Switch>
  );
};

const Contacts = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <Sidebar />
          <Leads />
        </div>
      </div>
    </div>
  );
};
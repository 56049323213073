import { UseMutationOptions, useMutation } from 'react-query';
import * as api from '@api';
import * as Branding from '@api/interfaces/groups.branding';
import * as QKey from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';

export const useCreateChartPaletteMutation = (options: CreatePalette.Options = {}) => {
  const group = useSelectGroup();

  const mutation = useMutation([QKey.Groups.Branding.Palettes.Post, group.id], (params: CreatePalette.Variables) => {
    return api.groups.branding.palettes.createPalette({
      name: params.name,
      categoryId: params.categoryId,
      data: params.data,
      groupId: group.id,
    });
  }, options);

  return mutation;
};

export declare namespace CreatePalette {
  export type Variables = {
    categoryId: number;
    data: {
      colors: string[];
    };
    name: string;
  };

  export type Options =
    UseMutationOptions<
      Branding.Palettes.CreatePalette.Response,
      unknown,
      Variables>;
}

export const useRemoveChartPaletteMutation = (options: RemovePalette.Options = {}) => {
  const group = useSelectGroup();

  const mutation = useMutation([QKey.Groups.Branding.Palettes.Delete, group.id], (params: RemovePalette.Variables) => {
    return api.groups.branding.palettes.deletePalette({
      groupId: group.id,
      paletteId: params.paletteId,
    });
  }, options);

  return mutation;
};

export declare namespace RemovePalette {
  export type Variables = {
    paletteId: number;
  };

  export type Options<Context = unknown> =
    UseMutationOptions<
      Branding.Palettes.DeletePalette.Response,
      unknown,
      Variables,
      Context>;
}

export const useUpdateChartPaletteMutation = (options: UpdatePalette.Options = {}) => {
  const group = useSelectGroup();

  const mutation = useMutation([QKey.Groups.Branding.Palettes.Put, group.id], (params: UpdatePalette.Variables) => {
    return api.groups.branding.palettes.updatePalette({
      data: params.data,
      groupId: group.id,
      name: params.name,
      paletteId: params.paletteId,
    });
  }, options);

  return mutation;
};

export declare namespace UpdatePalette {
  export type Variables = {
    data: {
      colors: string[];
    };
    name:      string;
    paletteId: number;
  };

  export type Options =
    UseMutationOptions<
      Branding.Palettes.UpdatePalette.Response,
      unknown,
      Variables>;
}
import { createContext, useCallback, useContext, useEffect } from 'react';
import { usePersistentProjectInvite } from '@containers/ProjectLinkInvitation/hooks/usePersistentProjectInvite';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { StepperActions, useStepper } from '@utils';
import { useSignupSubmit } from '$website/containers/Auth/hooks/useSignupSubmit';
import { useSocialAuth } from '$website/containers/Auth/hooks/useSocialAuth';
import { SignupError } from '$website/screens/Auth/SignupError';
import { Logo } from 'components/Branding';
import { Modal } from 'components/Modal/Modal';
import * as AuthForm from '$website/components/AuthForm';
import * as AuthButton from '$website/components/AuthButton';
import { useSignupModal } from './useSignupModal';
import { useLoginModal } from './useLoginModal';
import styles from './style/AuthModal.css';

const { Layout } = AuthForm;

const StepperContext = createContext<StepperActions | undefined>(undefined);

type Props = {
  blocking?: boolean;
};

export const SignupModal = ({ blocking }: Props) => {
  const [open, setOpen] = useSignupModal();

  const [Component, actions, step] = useStepper([
    AuthStrategy,
    AuthEmailSignup,
  ]);

  useEffect(() => {

    if (!open && step > 0)  actions.goTo(0);

  }, [
    actions,
    open,
    step,
  ]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <Modal
      className={styles.root}
      blocking={blocking}
      onClose={handleClose}
      open={open}>
      <StepperContext.Provider value={actions}>
        <Component />
      </StepperContext.Provider>
    </Modal>
  );
};

const AuthStrategy = () => {
  const ctx = useContext(StepperContext);
  const social = useSocialAuth();

  const [_, setLoginModal] = useLoginModal();
  const [__, setSignupModal] = useSignupModal();

  const handleLogin = useCallback(() => {
    setLoginModal(true);
    setSignupModal(false);
  }, [
    setSignupModal,
    setLoginModal,
  ]);

  return (
    <div className={styles.wrap}>

      <Layout.Header>
        <Layout.Row>
          <Logo size={55} />
        </Layout.Row>

        <Layout.Row>
          <Layout.Title>Welcome</Layout.Title>
        </Layout.Row>

        <Layout.Row>
          <Layout.Subtitle>Sign up to continue to Vancery.</Layout.Subtitle>
        </Layout.Row>
      </Layout.Header>

      <div className={styles.body}>
        <Layout.Row>
          <AuthButton.Google onClick={social.authorizeGoogle}>
            Sign up with Google
          </AuthButton.Google>
        </Layout.Row>

        <Layout.Row>
          <AuthButton.Facebook onClick={social.authorizeFacebook}>
            Sign up with Facebook
          </AuthButton.Facebook>
        </Layout.Row>

        <Layout.Row>
          <AuthButton.Apple onClick={social.authorizeApple}>
            Sign up with Apple
          </AuthButton.Apple>
        </Layout.Row>

        <Layout.Row>
          <Layout.ThematicBreak>
            or
          </Layout.ThematicBreak>
        </Layout.Row>

        <Layout.Row>
          <AuthButton.Email onClick={ctx.next}>
            Sign up with Email
          </AuthButton.Email>
        </Layout.Row>

        <Layout.Row>
          <Layout.Subtitle>
            <div className={styles.loginLink} onClick={handleLogin}>
              Already a member? Log in.
            </div>
          </Layout.Subtitle>
        </Layout.Row>

      </div>

      <AuthForm.PrivacyPolicy />

    </div>
  );
};

const AuthEmailSignup = () => {
  const [form, submit] = useSignupSubmit();
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();

  function handleForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    submit({ token: state?.token });
  }

  return (
    <div className={styles.wrap}>

      <Layout.Header>
        <Layout.Row>
          <Layout.Title>Sign up with Vancery</Layout.Title>
        </Layout.Row>
      </Layout.Header>

      <div className={styles.body}>
        <form onSubmit={handleForm}>
          <AuthForm.Email.Signup />
          <Layout.Row>
            <div className={styles.error}>
              <SignupError />
            </div>
          </Layout.Row>
          <Layout.Row className={styles.padtop}>
            <ButtonActivityIndicator
              className={styles.submit}
              loading={form.loading}>
              Sign Up
            </ButtonActivityIndicator>
          </Layout.Row>
        </form>
      </div>

      <AuthForm.PrivacyPolicy />

    </div>
  );
};
import { DatePickerInput } from 'components/DatePicker';

type Props = {
  onChange: (value: Date) => void;
  value: Date;
};

export const ProjectTargetCompletionInput = ({ onChange, value }: Props) => {

  return (
    <DatePickerInput
      inline={false}
      isClearable={true}
      excludeDateIntervals={[{
        start: new Date('0001-01-01T00:00:00Z'),
        end: new Date(),
      }]}
      onChange={onChange}
      placeholderText="Select a date"
      selected={value} />
  );
};
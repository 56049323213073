import { cx } from '@utils';
import Section from '$website/screens/Main/Section';
import clipboard from 'static/images/invite/benefits-clipboard-green.svg?url';
import consult from 'static/images/invite/benefits-consult-blue.svg?url';
import mouse from 'static/images/invite/benefits-mouse-yellow.svg?url';
import styles from './style/Benefits.css';

const Benefits = () => {
  const items = collection.benefits;

  return (
    <Section>
      <div className={styles.wrap}>
        <h2 className={styles.headline}>How it works</h2>

        <div className={styles.main}>
          {items.map(x =>
            <div
              className={styles.benefit}
              key={x.text.copy}>
              <div className={styles.icon}>
                <img
                  alt={`Vancery ${x.text.title}`}
                  className={cx(styles.img)}
                  src={x.image.icon} />
              </div>
              <h3 className={styles.caption}>{x.text.title}</h3>
              <div className={styles.copy}>{x.text.copy}</div>
            </div>)}
        </div>
      </div>
    </Section>
  );
};

const collection = {
  benefits: [{
    image: {
      icon: clipboard,
    },
    text: {
      copy: `Sign up, verify your account, and complete surveys on Vancery.`,
      title: `Take Surveys`,
    },
  }, {
    image: {
      icon: mouse,
    },
    text: {
      copy: `Sync a payout account to get paid for surveys you complete.`,
      title: `Get Paid`,
    },
  }, {
    image: {
      icon: consult,
    },
    text: {
      copy: `Use your profile to collaborate on Vancery for future projects! Either get paid to help others or reach out to other members for help on your own goals!`,
      title: `You're all set to get paid and to collaborate with others on Vancery!`,
    },
  }],
};

export { Benefits };
export default Benefits;

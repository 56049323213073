import { useCallback } from 'react';
import { SurveyActionType, SurveyQuestionConditionType } from '@enums';
import { useGetDefaultIdentifier } from './hooks';
import { useOptionsQuestionLogicState, OptionsQuestionLogicContext } from './Context';

type Props =
  ChildrenProps;

export const QuestionOptionsLogicBuilderContainer = (props: Props) => {

  const getDefaultIdentifier = useGetDefaultIdentifier();
  const [logic, dispatch] = useOptionsQuestionLogicState();

  const addItem = useCallback(() => {
    dispatch({
      type: 'add-item',
    });

  }, [
    dispatch,
  ]);

  const removeItem = useCallback((id: string) => () => {
    dispatch({
      id,
      type: 'remove-item',
    });

  }, [dispatch]);

  const updateActionType = useCallback((id: string) => (type: SurveyActionType) => {

    dispatch({
      action: {
        identifier: getDefaultIdentifier(type),
        type,
      },
      id,
      type: 'update-action',
    });
  }, [
    dispatch,
    getDefaultIdentifier,
  ]);

  const updateActionIdentifier = useCallback((id: string) => (identifier: string) => {
    const action = logic.response.find(f => f.id === id).action;

    dispatch({
      action: {
        identifier,
        type: action.type,
      },
      id,
      type: 'update-action',
    });

  }, [
    dispatch,
    logic.response,
  ]);

  const updateConditionValue = useCallback((id: string) =>  (identifier: string) => {
    dispatch({
      id,
      value: {
        option: { identifier },
      },
      type: 'update-condition-value',
    });
  }, [
    dispatch,
  ]);

  const updateConditionType = useCallback((id: string) =>  (type: SurveyQuestionConditionType) => {

    dispatch({
      id,
      value: type,
      type: 'update-condition-type',
    });
  }, [
    dispatch,
  ]);

  const ctx = {
    addItem,
    items: logic.response,
    removeItem,
    updateActionIdentifier,
    updateActionType,
    updateConditionType,
    updateConditionValue,
  };

  return (
    <OptionsQuestionLogicContext.Provider value={ctx}>
      {props.children}
    </OptionsQuestionLogicContext.Provider>
  );
};

export default QuestionOptionsLogicBuilderContainer;
import { ComponentType } from 'react';
import { NodeViewComponentProps } from '@remirror/react';
import { useSurveyBuilderState, useIsEditingSurvey } from '@containers/SurveyBuilder/hooks';
import { TemplateTarget as Label } from '@presentation/SurveyPreview/Template.Target';
import { SurveyRichText, SurveyTemplate } from '@/types/survey';

export const TemplateTarget: ComponentType<NodeViewComponentProps> = props => {

  const ctx = useSurveyBuilderState();

  return ctx
    ? <BuilderNode {...props} />
    : <Node {...props} />;
};

function BuilderNode({ node }: NodeViewComponentProps) {
  const [state] = useSurveyBuilderState();
  const editing = useIsEditingSurvey();

  const target = (state.survey.template.data as { target: SurveyTemplate.Target; }).target;

  return (
    <Label
      disabled={editing}
      value={target.value} />
  );
}

function Node({ node }: NodeViewComponentProps) {
  const target = (node.attrs as SurveyRichText.TargetNode['attrs']).target;

  return (
    <Label
      disabled={true}
      value={target} />
  );
}

export default TemplateTarget;
import { CSSProperties } from 'react';
import { X } from 'react-feather';
import { useFileUploadsState, useToggleUploadDisplay, useUploadInProgress } from '@containers/WorkspaceFileUpload/hooks';
import { Tooltip } from '@presentation/Tooltip';
import { formatPlurality } from '@utils';
import { HeaderHeight } from './consts';
import styles from './style/Header.css';

export const FileUploadsHeader = () => {
  const [state] = useFileUploadsState();
  const toggle = useToggleUploadDisplay();
  const inProgress = useUploadInProgress();

  const count = state.invalid.length + state.valid.length;

  const text = !inProgress
    ? 'Upload Completed'
    : `Uploading ${count} ${formatPlurality('file', count)}`;

  const headerStyle: CSSProperties = {
    height: HeaderHeight,
  };

  return (
    <div className={styles.root} style={headerStyle}>
      <div>{text}</div>
      <Tooltip title="Close" arrow={false}>
        <div className={styles.close} onClick={toggle}>
          <X />
        </div>
      </Tooltip>
    </div>
  );
};

export default FileUploadsHeader;
import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { useSelectUser, useSelectGroup } from '@containers/Store/hooks';
import * as mixpanel from '@services/mixpanel';

export const useInitAnalytics = () => {
  const user = useSelectUser();
  const group = useSelectGroup();

  const initAnalytics = () => {
    Sentry.configureScope(scope => {
      scope.setUser({
        email: user.contact.email,
        id: `${user.id}`,
      });
    });
    gtag('set', { 'user_id': user.id });
    mixpanel.event.auth.authenticated({
      group,
      user,
    });
  };

  return useCallback(initAnalytics, [
    group,
    user,
  ]);
};
import {
  useFetchIntegrations,
} from '@utils/api';
import { Button } from 'components/Button';
import { SettingsTitle } from 'components/Settings';
import {
  useSlackChannelsModal,
  SlackChannelsContainer,
} from 'components/Slack';
import styles from './style/Settings.Notifications.Slack.css';

export const SlackNotificationSettings = () => {

  const {
    data,
    isLoading,
  } = useFetchIntegrations({
    refetchOnMount: false,
  });

  const [toggleChannelsModal, SlackChannelsModal] = useSlackChannelsModal();

  if (isLoading || !data.slack.connected) return null;

  return (
    <>
      <SettingsTitle
        className={styles.title}
        title="Slack Settings" />

      <div className={styles.subtitle}>Subscribed Channels</div>
      <div className={styles.row}>
        {!!data.slack.channels.length &&
          data.slack.channels.map(m => m.name).join(', ')}
        {!data.slack.channels.length && 'None'}
        <Button
          className={styles.btn}
          variant="link"
          color="secondary"
          onClick={toggleChannelsModal}>
          Edit
        </Button>
      </div>

      <SlackChannelsContainer>
        <SlackChannelsModal />
      </SlackChannelsContainer>
    </>
  );
};

export default SlackNotificationSettings;
import { useCallback } from 'react';
import {
  ComplianceAggregateReviewContainer,
  ComplianceReview,
  useAggregateReviewFilterDispatch,
} from '@containers';
import { Select } from '$admin/components';
import styles from './style/ComplianceReview.css';

type Props = unknown;

const ReviewFilter = (props: Props) => {
  const dispatch = useAggregateReviewFilterDispatch();

  const handleChange = useCallback((e: React.ChangeEvent<{
    name: string;
    value: ComplianceReview.AggregateFilter;
  }>) => {

    dispatch(e.target.value);
  }, [dispatch]);

  return (
    <Select<ComplianceReview.AggregateFilterLookup>
      className={styles.filter}
      defaultValue={ComplianceAggregateReviewContainer.filter.defaultOption}
      onChange={handleChange}
      options={ComplianceAggregateReviewContainer.filter.options} />
  );
};

export { ReviewFilter };
export default ReviewFilter;
import { useMemo, useCallback } from 'react';
import { BarTooltipDatum, BarMouseEventHandler, BarDatum } from '@nivo/bar';
import { SurveyOptionType } from '@enums';
import { MatrixChartTooltip } from '@presentation/ProjectSurveyResponses';
import { MatrixMultiselectQuestion } from '@/types';
import { StackedBarChartData, Colors, MatrixRowItem } from './interfaces';
import StackedBarChart from './StackedBarChart';

type Props = {
  question: MatrixMultiselectQuestion.Question;
  responses: MatrixRowItem[];
  onSegmentClick: (rowId: number, key: string) => void;
};

export const MatrixMultiselectResponsesChart = ({
  onSegmentClick,
  ...props
}: Props) => {

  const items: StackedBarChartData[] = useMemo(() => {

    return props.responses.map(m => {

      const values = m.options.reduce<BarDatum>((acc, o) => ({
        ...acc,
        [o.id]: o.userIds.length,
      }), {});
      const colors = m.options.reduce<Colors>((acc, o) => ({
        ...acc,
        [o.id]: o.color,
      }), {});

      return {
        colors,
        indexId: m.id.toString(),
        name: m.name,
        ...values,
      } as StackedBarChartData;
    });

  }, [props.responses]);

  const keys = useMemo(() => {
    return props.question.options
      .map(m => m.base.id.toString());
  }, [
    props.question.options,
  ]);

  const notApplicableKeys = useMemo(() => {
    const naOption = props.question.options.find(f => f.type === SurveyOptionType.NotApplicable);

    return naOption
      ? [naOption.base.id.toString()]
      : [];
  }, [
    props.question.options,
  ]);

  const handleSegmentClick: BarMouseEventHandler<SVGRectElement, StackedBarChartData> = useCallback((item, e) => {
    onSegmentClick(+item.data.indexId, item.id.toString());
  }, [onSegmentClick]);

  const renderTooltip = useCallback((item: BarTooltipDatum) => {

    const data = props.responses
      .find(f => f.id === +item.data.indexId).options.find(f => f.id.toString() === item.id);

    return (
      <MatrixChartTooltip
        rowName={item.data.name}
        count={data.userIds.length}
        optionName={data.name}
        percent={data.pct} />
    );
  }, [props.responses]);

  const maxValue = useMemo(() => {
    const max = Math.max(...props.responses.map(m => m.count));
    return Math.round(max + (max * 0.1 < 1 ? 1 : max * 0.1));
  }, [items]);

  const formatBottomAxisLabel = useCallback((value: string) => {
    return Number.isInteger(+value)
      ? value
      : '';
  }, []);

  return (
    <StackedBarChart
      formatBottomAxisLabel={formatBottomAxisLabel}
      minValue={0}
      maxValue={maxValue}
      handleSegmentClick={handleSegmentClick}
      items={items}
      keys={keys}
      notApplicableKeys={notApplicableKeys}
      renderTooltip={renderTooltip} />
  );
};

export default MatrixMultiselectResponsesChart;
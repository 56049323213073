import video from 'static/images/website/q2q/icon-video-call.svg?url';
import phone from 'static/images/website/q2q/icon-phone-call.svg?url';
import headphones from 'static/images/website/q2q/icon-recording.svg?url';
import transcript from 'static/images/website/q2q/icon-transcript.svg?url';
import screenshare from 'static/images/website/q2q/icon-screen-sharing.svg?url';
import notes from 'static/images/website/q2q/icon-call-notes.svg?url';
import styles from './style/SurveyFeatures.css';

type Props = unknown;

export const InterviewFeatures = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>
          1x1 Interview Features
        </h2>
        <div className={styles.main}>
          {items.map((x, i) =>
            <div
              className={styles.item}
              key={`icon-${i}`}>
              <img
                alt={x.title}
                className={styles.img}
                src={x.src} />
              <div className={styles.title}>{x.title}</div>
              <div className={styles.text}>{x.text}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

InterviewFeatures.displayName = 'Quant2Qual.InterviewFeatures';

const items = [{
  src: video,
  text: `Ability for researchers and experts to conduct video interviews with experts on the platform.`,
  title: `Video Calls`,
}, {
  src: phone,
  text: `Ability for researchers and experts to conduct traditional phone based interviews.`,
  title: `Audio Calls`,
}, {
  src: headphones,
  text: `Ability for individuals to record meetings with experts for future reference on the platform.`,
  title: `Recordings`,
}, {
  src: transcript,
  text: `Calls are automatically transcribed on the platform and can be downloaded or shared with team members.`,
  title: `Call Transcripts`,
}, {
  src: screenshare,
  text: `Share content and receive feedback in real time with experts through our screen sharing feature.`,
  title: `Screen Sharing`,
}, {
  src: notes,
  text: `Take notes on the key points of your conversations through our call notes tool.`,
  title: `Call Notes`,
}];
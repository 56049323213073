import { useMemo } from 'react';
import { useVideoPostUploaderContext } from '@containers/PostCreation/hooks/usePostCreationContext';
import { Video } from '@containers/PostCreation/interfaces';
import { Portal } from 'components/Portal';
import styles from './style/Publisher.UploadProgressPortal.css';

type Props = unknown;

export const UploadProgressPortal = (props: Props) => {
  const [state] = useVideoPostUploaderContext();

  const items = useMemo(() => {
    return Object.entries(state.media).reduce((acc, [key, val]) => {
      if (val.status === Video.Uploader.PostingState.Uploading) {
        const progresses = val.uploadIds.map(fid => state.progress[fid]);
        const combinedProgress = progresses.reduce((total, x) => total + x, 0);
        const progress = combinedProgress / (progresses.length * 100);

        return acc.concat({
          key,
          progress: Math.trunc(progress * 100),
          title: val.post.title,
        });
      }

      return acc;
    }, [] as Item[]);
  }, [
    state.media,
    state.progress,
  ]);

  if (!items.length) return null;

  return (
    <Portal>
      {items.map((x, i) =>
        <div
          className={styles.root}
          key={x.title}
          style={{ bottom: (i + 1) * 20}}>
          <div
            className={styles.wrap}>
            <div className={styles.bar}>
              <div
                className={styles.progress}
                style={{ width: `${x.progress}%` }} />
            </div>
            <div className={styles.main}>
              <div className={styles.percent}>{x.progress}%</div>
              <div className={styles.info}>
                <div className={styles.title}>{x.title}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
};

UploadProgressPortal.displayName = 'PostCreation.Publisher.UploadProgressPortal';

type Item = {
  key:      string;
  progress: number;
  title:    string;
};
import { UserEmployment } from '@presentation';
import { Career } from '@/types';
import { UserEmploymentsProps } from './interfaces';
import styles from '../style/ProjectPipelineItem.css';

type Props =
    UserEmploymentsProps;

const UserEmployments = (props: Props) => {
  const { current, former } = props.item.career;

  return (
    <div className={styles.copy}>
      {current.display && createElement('Current', current)}
      {former.display && createElement('Former', former)}
    </div>
  );
};

function createElement(label: string, item: Career) {
  return <UserEmployment
    label={label}
    company={item.company}
    title={item.title}
    tenure={item.duration}
  />;
}

export default UserEmployments;
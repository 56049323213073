import { useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { SurveyFormQuestion } from '@/types';
import { ConjointAnalysisFormContainer } from './ConjointAnalysis';
import { LongTextResponseFormContainer } from './LongTextResponse';
import { MatrixSliderFormContainer } from './MatrixSlider';
import { MaxDiffFormContainer } from './MaxDiff';
import { MultiselectFormContainer } from './Multiselect';
import { SurveyFormNavigationContainer } from './BaseNavigation';
import { MatrixMultiselectFormContainer } from './MatrixMultiselect';
import { NumberInputTableFormContainer } from './NumberInputTable';
import { useSurveyFormQuestionContext } from './hooks';

type Props =
  ChildrenProps;

function getQuestionContainer(question: SurveyFormQuestion) {
  switch (question.typeId) {
    case SurveyQuestionType.ConjointAnalysis:
      return ConjointAnalysisFormContainer;

    case SurveyQuestionType.LongTextResponse:
      return LongTextResponseFormContainer;

    case SurveyQuestionType.MaxDifference:
      return MaxDiffFormContainer;

    case SurveyQuestionType.Sliders:
      return MatrixSliderFormContainer;

    case SurveyQuestionType.Multiselect:
      return MultiselectFormContainer;

    case SurveyQuestionType.MatrixMultiselect:
      return MatrixMultiselectFormContainer;

    case SurveyQuestionType.NumberInputTable:
      return NumberInputTableFormContainer;

    default:
      return SurveyFormNavigationContainer;
  }
}

export const SurveyFormQuestionContainer = (props: Props) => {

  const state = useSurveyFormQuestionContext();

  const QuestionTypeContainer = useMemo(() => {
    return getQuestionContainer(state.question);
  }, [state.question]);

  return (
    <QuestionTypeContainer>
      {props.children}
    </QuestionTypeContainer>
  );
};

export default SurveyFormQuestionContainer;
import { cloneElement } from 'react';
import { useDownloader } from '@utils/hooks';
import { ClonedDownloader, WrappedDownloader } from './interfaces';

export type Props<T> =
  | ClonedDownloader<T>
  | WrappedDownloader<T>;

const Downloader = <T extends unknown>(props: Props<T>) => {
  const download = useDownloader();

  const handleClick = async () => {

    const data = await props.onClick(props.item);

    if (data) {
      return download({
        blob: data,
        filename: props.filename,
      });
    } else {
      console.error('No data in response');
    }

  };

  if (props.clone) {
    return cloneElement(props.children, { onClick: handleClick });
  }

  return (
    <button onClick={handleClick}>
      {props.children}
    </button>
  );
};

const defaultProps = {
  clone: false,
};

Downloader.defaultProps = defaultProps;

export { Downloader };
export default Downloader;
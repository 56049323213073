import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { formatDistanceStrict } from 'date-fns';
import * as enums from '@enums';
import { Chip } from '@presentation';
import { getLocationFor } from '@utils';
import { NewsArticle } from '@/types';
import styles from './style/NewsFeedArticle.css';

const MaxKeywords = 5;

type Props = {
  item: NewsArticle;
};

const NewsFeedArticle = ({ item }: Props) => {
  const keywords = useMemo(() => {
    return item.keywords
      .slice(0, MaxKeywords);
  }, [
    item.keywords,
  ]);

  const searchAllLink = useMemo(() => {
    return getLocationFor.search({
      [enums.SearchType.Keyword]: keywords.map(k => ({
        id: k.name,
        name: k.name,
      })),
    });
  }, [
    keywords,
  ]);

  const renderKeywords = useCallback(() => {
    return (
      <div className={styles.keywords}>
        {keywords
          .map(k =>
            <Chip
              clickable
              className={styles.tag}
              key={k.id}>
              <Link to={getLocationFor.search({
                [enums.SearchType.Keyword]: [{
                  id: k.name,
                  name: k.name,
                }],
              })}>
                <Chip.Body.Basic>
                  {k.name}
                </Chip.Body.Basic>
              </Link>
            </Chip>
          )}

        {!!keywords.length &&
          <Link className={styles.searchAll} to={searchAllLink}>
            {`Search All >`}
          </Link>
        }
      </div>
    );
  }, [
    keywords,
    searchAllLink,
  ]);

  const dateString = useMemo(
    () => `${formatDistanceStrict(item.publishDate, new Date())} ago`,
    [item.publishDate]
  );

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <a
          className={styles.title}
          href={item.url}
          rel="noopener noreferrer"
          target="_blank">
          {item.title}
        </a>
        <div className={styles.timestamp}>{dateString}</div>
      </div>
      {renderKeywords()}
    </div>
  );
};

export { NewsFeedArticle };
export default NewsFeedArticle;
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import { useFetchProjectGoal } from '@utils/api';
import { ProjectTabDetails } from 'scenes/projects/Project';
import { GroupProjectGoalContext, SaveProjectOverviewContext } from './Context';
import { useProjectOrLatestChild } from './hooks';
import { ProjectOverviewFormState } from './interfaces';

export const ProjectDetailsContainer = () => {
  const project = useProjectOrLatestChild();

  const [goalResponse, fetchProjectGoal] = useFetchProjectGoal();
  const dispatch = useDispatch();

  useEffect(() => {

    fetchProjectGoal(project.id);
  }, [
    fetchProjectGoal,
    project.id,
  ]);

  const saveOverview = useCallback((form: ProjectOverviewFormState) => {
    return api.projects.updateProjectOverview({
      description: form.description,
      frequency: form.frequency,
      name: form.name,
      projectId: project.id,
      projectType: project.projectType,
      goal: form.goal,
    })
    .then(data => {

      dispatch(actions.batchActions([
        actions.projectOverviewUpdated({
          object: data.object,
          projectId: project.id,
          project: {
            name: data.project.name,
            description: data.project.description,
          },
        }),
        actions.projectParentUpdated({
          parent: data.projectParent,
        }),
      ]));

      return data;
    });
  }, [
    dispatch,
    project.id,
    project.projectType,
  ]);

  return (
    <GroupProjectGoalContext.Provider value={goalResponse.value}>
      <SaveProjectOverviewContext.Provider value={saveOverview}>
        <ProjectTabDetails />
      </SaveProjectOverviewContext.Provider>
    </GroupProjectGoalContext.Provider>
  );
};

export default ProjectDetailsContainer;
export const faqs = [{
  label: `Do experts on Vancery sign a written agreement and how often do they reaffirm terms of the agreement?`,
  value: `Yes, Vancery supports signed client-specific attestation documents. Experts are required to annually re-attest to the client specific attestations for firms they engage with on the platform.`,
}, {
  label: `Are all experts on Vancery required to complete compliance training before calls?`,
  value: `Yes, experts are required to complete a compliance training that specifically address material non-public information (“MNPI”), confidential information, & insider trading as a part of their signup process and they are required to retake the compliance training annually.`,
}, {
  label: `If an expert on Vancery has not met the contract or training requirements, are they allowed to consult?`,
  value: `Experts who do not meet the requirements for a project are not allowed to schedule a consultation. Experts are required to complete a compliance training, a compliance test, and a firm specific attestation before engaging in consultations.`,
}, {
  label: `Is there a pre-hire or pre-engagement screening process on Vancery?`,
  value: `Yes, every expert is screened by a Vancery client services representative.`,
}, {
  label: `Are there employment history filters in place on Vancery to prevent employees of public companies from consulting on their current employer?`,
  value: `Vancery can be customized specifically for each client’s policies. If current employees of public companies are restricted per client policies, our system prevents those experts from being available for consultations. We regularly review and confirm employment information with our experts to ensure that all profiles are accurate and up to date.`,
}, {
  label: `Do written agreements with consultants on Vancery restrict their use of client information?`,
  value: `Yes, all client communications are confidential and any sharing or use of such information is strictly prohibited. These policies are outlined in the attestations which our experts universally agree to during the onboarding process.`,
}];
import { useMemo, useCallback } from 'react';
import { BarMouseEventHandler, ResponsiveBar, BarTooltipProps } from '@nivo/bar';
import { NumberInputTableQuestion } from '@/types';
import { formatNumber, trunc } from '@utils';
import * as chart from '@containers/SurveyResponses/utils';
import { NumberInputTableChartTooltip } from '@presentation/ProjectSurveyResponses/Tooltip';
import { theme } from './Chart.Common';
import ChartWrapper from './ChartWrapper';
import { useChartAnimation } from './hooks';
import { NumberInputTable } from './interfaces';

type Props = {
  colors?: string[];
  data: NumberInputTable.ChartData[];
  question: NumberInputTableQuestion.Question;
  onBarClick: (rowId: number, optionId: number) => void;
};

type Data = NumberInputTable.ChartData;

export const NumberInputTableResponsesChart = ({
  colors = chart.options.OptionsColors,
  data,
  question,
  onBarClick,
}: Props) => {

  const animate = useChartAnimation();

  const handleMouseEnter: BarMouseEventHandler<SVGRectElement> = useCallback((_, e) => {
    e.currentTarget.style.cursor = 'pointer';
  }, []);

  const handleClick: BarMouseEventHandler<SVGRectElement> = useCallback((item, e) => {
    const rowId = +item.indexValue;
    const optionId = +item.id;
    onBarClick(rowId, optionId);
  }, [onBarClick]);

  const renderTooltip = useCallback((item: BarTooltipProps<Data>) => {

    const rowId = item.indexValue;
    const optionId = +item.id;

    const avg = item.data[optionId];
    const row = question.matrixRows.find(f => f.base.id === rowId);
    const option = question.options.find(f => f.base.id === optionId);

    return (
      <NumberInputTableChartTooltip
        avg={avg}
        option={option.value}
        row={row.value} />
    );
  }, [
    question.matrixRows,
    question.options,
  ]);

  const formatBottomAxisLabel = useCallback((value: string) => {
    if (!Number.isInteger(+value)) return '';

    return formatNumber(Number(value), 1);
  }, []);

  const formatLeftAxisLabel = useCallback((value: string) => {
    const row = question.matrixRows.find(f => f.base.id == +value);

    return trunc(row.value, 10);
  }, [
    question.matrixRows,
  ]);

  const height = useMemo(() => {
    const bars = question.matrixRows.length * question.options.length;

    return Math.max(bars * 15, 230);
  }, [
    question.matrixRows.length,
    question.options.length,
  ]);

  const keys = useMemo(() => {
    return question.options.map(m => m.base.id.toString());
  }, [question.options]);

  const items = useMemo(() => [...data].reverse(), [data]);

  const maxValue = useMemo(() => {
    const max = items.reduce((max, x) => {
      return Math.max(max, ...question.options.map(o => +x[o.base.id]));
    }, 0);

    return Math.round(max + (max * 0.1 < 1 ? 1 : max * 0.1));
  }, [
    items,
    question.options,
  ]);

  const minValue = useMemo(() => {
    const min = items.reduce((min, x) => {
      return Math.min(min, ...question.options.map(o => +x[o.base.id]));
    }, 0);

    return Math.round(min + (min * 0.1 < 1 ? 1 : min * 0.1));
  }, [
    items,
    question.options,
  ]);

  return (
    <ChartWrapper style={{ height }}>
      <ResponsiveBar
        data={items}
        keys={keys}
        colors={colors}
        groupMode="grouped"
        layout="horizontal"
        indexBy="rowId"
        // minValue={minValue}
        // maxValue={maxValue}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: formatBottomAxisLabel,
        }}
        axisLeft={{
          format: formatLeftAxisLabel,
        }}
        padding={.2}
        enableGridX={true}
        enableGridY={false}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        enableLabel={false}
        animate={animate}
        margin={{ top: 0, right: 10, bottom: 30, left: 75 }}
        theme={theme}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        tooltip={renderTooltip}
        motionConfig={{
          damping: 15,
        }} />
    </ChartWrapper>
  );
};

export default NumberInputTableResponsesChart;
import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Card.css';

type Props = {
  onClick?: () => void;
};

export const PricingStrategyCard = memo(({ onClick }: Props) => {

  const title = `Pricing Strategy`;

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <div className={cx(styles.poster, styles.pricingStrategy)}>
        <Icon />
      </div>
      <div className={styles.footer}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
});

type IconProps = {
  color?: string;
};

const Icon = memo(({
  color = '#84C213',
}: IconProps) => (
  <svg
    width="30px"
    height="45px"
    viewBox="0 0 21 37"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g
      id="Survey-Templates---Pricing-Strategy"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="1"
        transform="translate(-691.000000, -531.000000)"
        stroke={color}
        strokeWidth="2">
        <g
          id="Group-10"
          transform="translate(692.000000, 532.000000)">
          <path
            d="M2.00171358,12.1186981 L8.30068543,7.39868353 C9.01151715,6.86603532 9.98848285,6.86603532 10.6993146,7.39868353 L16.9982864,12.1186981 C18.2583231,13.0628826 19,14.5454425 19,16.1199827 L19,30.5 C19,32.709139 17.209139,34.5 15,34.5 L4,34.5 C1.790861,34.5 2.23265109e-15,32.709139 0,30.5 L0,16.1199827 C2.28276759e-15,14.5454425 0.74167689,13.0628826 2.00171358,12.1186981 Z"
            id="Rectangle" />
          <path
            d="M13.8322458,4.3713687 C13.2999486,1.84904551 11.5619247,0 9.5,0 C7.01471863,0 5,2.6862915 5,6 C5,9.3137085 7.01471863,12 9.5,12"
            id="Path"
            strokeLinecap="round" />
          <path
            d="M11.8662265,18.4445612 L8.76867766,18.423853 C8.03226432,18.4189298 7.34932505,18.8077987 6.97775162,19.4436147 L6.96053952,19.4730671 C6.59837175,20.0927887 6.58628481,20.8566889 6.9286649,21.4875584 C7.26931657,22.115243 7.92220605,22.5102159 8.63629534,22.5206088 L9.44979,22.5324485 L9.44979,22.5324485 L10.3978699,22.5181631 C11.6786913,22.4988405 12.7326474,23.5215067 12.7519465,24.8023285 C12.752122,24.8139756 12.7522097,24.825624 12.7522097,24.8372725 C12.7522097,24.8372725 12.7522097,24.8372725 12.7522097,24.8372725 C12.7522097,24.8372725 12.7522097,24.8372725 12.7522097,24.8372725 C12.7522097,26.035894 11.780535,27.0075687 10.5819134,27.0075652 L9.44979,27.0075652 L9.44979,27.0075652 L6.97856386,27.0075652"
            id="Path-3"
            strokeLinecap="round" />
          <line
            x1="9.564679"
            y1="16.6109683"
            x2="9.564679"
            y2="18"
            id="Path-4"
            strokeLinecap="round" />
          <line
            x1="9.564679"
            y1="27.6109683"
            x2="9.564679"
            y2="29"
            id="Path-4"
            strokeLinecap="round" />
        </g>
      </g>
    </g>
  </svg>
));
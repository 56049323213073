import { useMemo } from 'react';
import { Row } from 'react-table';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isAfter } from 'date-fns';
import * as Cells from 'components/WorkspaceFolders/FoldersTableCells';
import { FoldersTableItem } from 'components/WorkspaceFolders/interfaces';

function sortCreatedOn(a: Row<FoldersTableItem>, b: Row<FoldersTableItem>) {
  return isAfter(a.original.createdOn, b.original.createdOn)
    ? 1
    : -1;
}

export const useFoldersTableColumns = () => {
  const isDesktop = useMediaQuery('(min-width:900px)');

  return useMemo(() => [
    {
      accessor: 'name',
      id: 'name',
      Header: 'Name',
      Cell: Cells.Name,
      minWidth: 305,
    },
    isDesktop ? {
      accessor: 'owner',
      id: 'owner',
      Header: 'Owner',
      Cell: Cells.Owner,
      width: 80,
    } : undefined,
    isDesktop ? {
      accessor: 'createdOn',
      id: 'createdOn',
      Header: 'Created On',
      Cell: Cells.CreatedOn,
      minWidth: 80,
      width: 80,
      sortType: sortCreatedOn,
    }: undefined,
    {
      id: 'actions',
      Cell: Cells.Actions,
      minWidth: 50,
      width: 50,
      disableSortBy: true,
    },
  ].filter(Boolean), [
    isDesktop,
  ]);
};

export default useFoldersTableColumns;
export const selectExpertPipelineRecord = (projectId: number) =>
  (state: Store.State) => state.pipeline.me[projectId];

export const selectClientPipelineRecord = (projectId: number, userId: number) =>
  (state: Store.State) => state.pipeline.project[projectId]?.users[userId];

export const selectClientPipelineRecordCount = (projectId: number) =>
  (state: Store.State) => Object.keys(state.pipeline.project[projectId].users).length;

export const selectProjectSurveyAnonymity = (projectId: number) =>
  (state: Store.State) => state.pipeline.project[projectId]?.anonymity;

export const selectProjectSurveyRespondent = (projectId: number, userId: number) =>
  (state: Store.State) => state.pipeline.project[projectId]?.respondents?.[userId];

export const selectProjectSurveyRespondents = (projectId: number) =>
  (state: Store.State) => state.pipeline.project[projectId]?.respondents;

export const pipeline = {
  client: {
    anonymity: selectProjectSurveyAnonymity,
    count: selectClientPipelineRecordCount,
    record: selectClientPipelineRecord,
    respondent: selectProjectSurveyRespondent,
    respondents: selectProjectSurveyRespondents,
  },
  expert: {
    record: selectExpertPipelineRecord,
  },
};
import { useState } from 'react';
import { ProjectStatus } from '@enums';
import { useProjectMetricsLoading } from '@containers';
import { useProjectsTableItems } from 'components/GroupProject/hooks/useProjectsTableItems';
import ProjectsTable from './ProjectsTable';

export const SharedTabProjects = () => {
  const metricsLoading = useProjectMetricsLoading();
  const [statusFilter, setStatusFilter] = useState<ProjectStatus>(ProjectStatus.Active);

  const data = useProjectsTableItems({
    sharedRoot: true,
    status: statusFilter,
  });

  if (metricsLoading) {
    return null;
  }

  return (
    <ProjectsTable
      canCreateNew={false}
      data={data}
      setStatusFilter={setStatusFilter}
      statusFilter={statusFilter} />
  );
};

export default SharedTabProjects;
import { ButtonOutlined } from '@presentation/ButtonOutlined';
import { cx } from '@utils';
import { ButtonOutlinedProps } from './interfaces/Button';
import styles from './style/ButtonOutlined.LabeledIcon.css';

type Props = {
  children?:         React.ReactNode;
  divider?:          boolean;
  dividerClassName?: string;
  icon:              React.ReactElement;
} & ButtonOutlinedProps;

export const ButtonOutlinedLabeledIcon = (props: Props) => {
  const {
    className,
    children,
    divider,
    dividerClassName,
    icon,
    ...other
  } = props;

  const classes = cx(
    styles.root,
    className,
  );

  return (
    <ButtonOutlined
      className={classes}
      {...other}>
      <div className={styles.wrap}>
        <div className={styles.icon}>
          {icon}
        </div>
        {divider && <div className={cx(styles.divider, dividerClassName)} />}
        <div className={styles.main}>{props.children}</div>
      </div>
    </ButtonOutlined>
  );
};

const defaultProps = {
  color: 'silver',
  borderWidth: 'thin',
  divider: false,
  variant: 'brick',
};

ButtonOutlinedLabeledIcon.defaultProps = defaultProps;
ButtonOutlinedLabeledIcon.displayName = 'ButtonOutlined.LabeledIcon';
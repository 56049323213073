import {
  TestItem,
  TestItemSelection,
  TestItemResult,
} from './interfaces';

export const evaluateTest = (items: TestItem[], selections: TestItemSelection): TestItemResult[] => {
  return items.map((x, i) => {
    const correct = x.answer;
    const selected = selections[i];

    return {
      correct,
      pass: correct === selected,
      selected,
    };
  });
};

export const getScore = (results: TestItemResult[]) => {
  const correct = results.reduce((acc, x) => acc += x.pass ? 1 : 0, 0);
  return Math.floor((correct / results.length) * 100);
};
import { QuestionBuilderContainer } from '@containers/SurveyBuilder.Question/QuestionBuilderContainer';
import { useQuestionBuilderEditingContext  } from '@containers/SurveyBuilder.Question/Context';
import EditableQuestion from './EditableQuestion';
import QuestionBuilder from './QuestionBuilder';

type Props = {
  includeTagging: boolean;
};

export const QuestionBuilderItem = ({ includeTagging }: Props) => {

  const { canEdit, editing } = useQuestionBuilderEditingContext();

  if (editing) {
    return (
      <QuestionBuilderContainer>
        <QuestionBuilder />
      </QuestionBuilderContainer>
    );
  } else {
    return (
      <EditableQuestion
        canEdit={canEdit}
        includeTagging={includeTagging} />
    );
  }
};

export default QuestionBuilderItem;
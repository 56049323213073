import { cloneElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import {
  AddToProjectInjectedProps,
  AddToProjectParams,
  DeactivateUserInjectedProps,
  DeactivateUserParams,
  InjectorType,
  RemoveUserInjectedProps,
  RemoveUserParams,
} from './interfaces';

type Props = {
  children: React.ReactElement;
};

export const useAddToProject = () => {
  const dispatch = useDispatch();

  const addToProject = useCallback((data: AddToProjectParams) => {
    return api.projects.pipeline.addToProject({
      projectId: data.projectId,
      userIds: data.userIds,
    })
    .then(res => {

      dispatch(actions.batchActions([
        actions.projectPipelineUpdated({
          pipeline: res.pipeline,
          projectId: data.projectId,
        }),
        actions.contactsAdded({
          contacts: res.contacts,
        }),
      ]));

      return res;
    });
  }, [
    dispatch,
  ]);

  return addToProject;
};

const AddToProject = ({ children, ...props }: Props) => {
  const addToProject = useAddToProject();

  return cloneElement(children, { ...props, addToProject });
};

export const useDeactivateUser = () => {
  const dispatch = useDispatch();

  const deactivateUser = useCallback((data: DeactivateUserParams) => {
    return api.projects.pipeline.deactivateUser({
      projectId: data.projectId,
      userId: data.userId,
    })
    .then(res => {
      dispatch(actions.batchActions([
        actions.projectPipelineUpdated({
          pipeline: res.pipeline,
          projectId: data.projectId,
        }),
        actions.contactsAdded({
          contacts: res.contacts,
        }),
      ]));

      return res;
    });
  }, [
    dispatch,
  ]);

  return deactivateUser;
};

const DeactivateUser = (props: Props) => {
  const deactivateUser = useDeactivateUser();

  return cloneElement(props.children, { deactivateUser });
};

export const useRemoveUser = () => {
  const dispatch = useDispatch();

  const removeUser = useCallback((data: RemoveUserParams) => {
    return api.projects.pipeline.removeUser({
      projectId: data.projectId,
      userId: data.userId,
    })
    .then(res => {

      dispatch(actions.batchActions([
        actions.projectPipelineUpdated({
          pipeline: res.pipeline,
          projectId: data.projectId,
        }),
        actions.contactsAdded({
          contacts: res.contacts,
        }),
      ]));

      return res;
    });
  }, [
    dispatch,
  ]);

  return removeUser;
};

const RemoveUser = (props: Props) => {
  const removeUser = useRemoveUser();

  return cloneElement(props.children, { removeUser });
};

export const useBookmarkUsers = () => {
  const dispatch = useDispatch();

  function bookmarkUsers(data: AddToProjectParams) {
    return api.projects.pipeline.addToProject({
      projectId: data.projectId,
      userIds: data.userIds,
    })
    .then(res => {
      const action = actions.batchActions([
        actions.projectPipelineUpdated({
          pipeline: res.pipeline,
          projectId: data.projectId,
        }),
        actions.contactsAdded({
          contacts: res.contacts,
        }),
      ]);

      dispatch(action);

      return res;
    });
  }

  return bookmarkUsers;
};

export const withAddToProject = <P extends AddToProjectInjectedProps>(WrappedComponent: React.ComponentType<P>) => {
  const WithAddToProject = (props: P & AddToProjectInjectedProps) => {
    return (
      <AddToProject>
        <WrappedComponent {...props} />
      </AddToProject>
    );
  };

  return WithAddToProject;
};

export const withDeactivateUser: InjectorType<DeactivateUserInjectedProps> = WrappedComponent => {
  const WithDeactivateUser = props => {
    return (
      <DeactivateUser>
        <WrappedComponent {...props} />
      </DeactivateUser>
    );
  };

  return WithDeactivateUser;
};

export const withRemoveUser: InjectorType<RemoveUserInjectedProps> = WrappedComponent => {
  const WithRemoveUser = props => {
    return (
      <RemoveUser>
        <WrappedComponent {...props} />
      </RemoveUser>
    );
  };

  return WithRemoveUser;
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProfileEntry, VICS as VICSEnum, VICSType } from '@enums';
import { FormButtons } from '@presentation/FormButtons';
import { VICS } from '@/types';
import { SectorDropDown } from 'components/VICS/SectorDropDown';
import VICSAutocomplete from 'scenes/vics-selection/VICSAutocomplete';
import { Section } from './interfaces';
import Tags from './Profile.Tags.Form';
import styles from './style/Profile.Industries.css';

type Props =
  Section.SingleEntry.FormProps<ProfileEntry.Industry>;

export const ProfileIndustriesForm = ({ onSave, ...props }: Props) => {

  const [vics, setVics] = useState<VICS[]>(props.value);
  const [warningMap, setWarningMap] = useState<WarningMap>({});

  const handleSave = useCallback(() => {
    return onSave({
      vals: vics,
    });
  }, [
    onSave,
    vics,
  ]);

  const validateSelections = useCallback((typeId: VICSEnum) => {
    const selected = vics.filter(f => f.typeId === typeId);

    setWarningMap(v => ({
      ...v,
      [typeId]: selected.length > VICSType.getMaxSelections(typeId),
    }));
  }, [
    vics,
  ]);

  useEffect(() => {
    validateSelections(VICSEnum.Sector);
    validateSelections(VICSEnum.Industry);
    validateSelections(VICSEnum.SubIndustry);
  }, [
    validateSelections,
  ]);

  const addItem = useCallback((value: VICS) => {
    setVics(s => [
      ...s,
      value,
    ]);
  }, []);

  const removeItem = useCallback((value: VICS) => () => {
    setVics(s => s.filter(s => s.id !== value.id));
  }, []);

  const canSave = useMemo(() => {
    return [VICSEnum.Sector, VICSEnum.Industry, VICSEnum.SubIndustry].reduce((acc, x) => {
      const selected = vics.filter(f => f.typeId === x);
      return acc &&
        !!selected.length &&
        selected.length <= VICSType.getMaxSelections(x);
    }, true);
  }, [
    vics,
  ]);

  const getParentIds = useCallback((typeId: VICSEnum) => {
    const parentMap = {
      [VICSEnum.Sector]: null,
      [VICSEnum.Industry]: VICSEnum.Sector,
      [VICSEnum.SubIndustry]: VICSEnum.Industry,
    };

    return vics.filter(f => f.typeId === parentMap[typeId]).map(m => m.id);
  }, [vics]);

  const renderSection = useCallback((typeId: VICSEnum) => {
    const items = vics.filter(f => f.typeId === typeId);
    const showWarning = warningMap[typeId];

    return (
      <div className={styles.section}>
        <div className={styles.autocomplete}>
          {
            typeId === VICSEnum.Sector
              ? <SectorDropDown
                  selectedItems={items}
                  onSelection={addItem} />
              : <VICSAutocomplete
                  parentIds={getParentIds(typeId)}
                  selectedItems={items}
                  vicsType={typeId}
                  onSelection={addItem} />
          }
        </div>
        {showWarning &&
          <div className={styles.warning}>
            Can only select up to {VICSType.getMaxSelections(typeId)} {VICSType.getPluralName(typeId)}
          </div>
        }
        <Tags
          items={items}
          onClick={removeItem} />
      </div>
    );
  }, [
    addItem,
    getParentIds,
    removeItem,
    vics,
    warningMap,
  ]);

  return (
    <>
      {renderSection(VICSEnum.Sector)}
      {renderSection(VICSEnum.Industry)}
      {renderSection(VICSEnum.SubIndustry)}
      <FormButtons
        disabled={!canSave}
        onSubmit={handleSave}
        onCancel={props.onCancel} />
    </>
  );
};

type WarningMap = {
  [typeId: number]: boolean;
};

export default ProfileIndustriesForm;
import { path } from '@consts';
import { Button } from 'components/Button';
import logo from 'static/images/vancery-landscape.svg?url';
import styles from './style/LinkExpired.css';

type Props = unknown;

export const LinkExpired = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <img src={logo} />
        </div>

        <div className={styles.main}>
          <div className={styles.message}>
            <div className={styles.text}>This link is no longer active.</div>
          </div>
          <div className={styles.message}>
            <div className={styles.text}>Log in to Vancery to schedule a call.</div>
          </div>
          <Button
            className={styles.btn}
            to={path.Website.Login}
            variant="link">
            {`Log in>`}
          </Button>
        </div>
      </div>
    </div>
  );
};
import { useCallback } from 'react';
import { SurveyOptionType } from '@enums';
import { MatrixMultiselectQuestion } from '@/types';
import { SurveyMatrixGridContainer } from 'components/SurveyMatrixGrid';

type Props = {
  answer: MatrixMultiselectQuestion.RespondentAnswer.Value;
  item: MatrixMultiselectQuestion.FormQuestion | MatrixMultiselectQuestion.Question;
  setAnswer: (value: MatrixMultiselectQuestion.RespondentAnswer.Value) => void;
};

export const MatrixMultiselectQuestionForm = ({ answer, item, setAnswer }: Props) => {

  const updateRowAnswer = useCallback((rowId: number) => (optionId: number) => {
    const existingItems = answer?.items || [];

    if (!existingItems.some(s => s.matrixRowId === rowId)) {
      const items = [
        ...existingItems, {
          matrixRowId: rowId,
          optionIds: [optionId],
        }];
      setAnswer({ items });
    } else {
      const option = item.options.find(f => f.id === optionId);
      const isNA = option.type === SurveyOptionType.NotApplicable;
      const items = existingItems.reduce((acc, x) => {
        if (x.matrixRowId === rowId) {
          const optionIds = x.optionIds.includes(optionId)
            ? x.optionIds.filter(f => f != optionId)
            : isNA
              ? [optionId]
              : [...x.optionIds, optionId].filter(f => item.options.find(o => o.id === f).type !== SurveyOptionType.NotApplicable);

          acc.push({
            matrixRowId: rowId,
            optionIds,
          });
        } else {
          acc.push(x);
        }

        return acc;
      }, [] as MatrixMultiselectQuestion.RespondentAnswer.Item[]);

      setAnswer({ items });
    }

  }, [
    answer?.items,
    item.options,
    setAnswer,
  ]);

  return (
    <SurveyMatrixGridContainer
      variant="multiselect"
      answers={answer?.items || []}
      options={item.options}
      rows={item.matrixRows}
      updateRowAnswer={updateRowAnswer} />
  );
};

export default MatrixMultiselectQuestionForm;
import {
  useAskAnExpertData,
  useAskAnExpertLoading,
  useGroupEntityTagsUpdate,
  useGroupEntityTagsLoading,
  useGroupEntityTags,
  useGroupTagsSearchLink,
  useTaggedProjectsLoading,
  useTaggedProjects,
  useTaggedProjectsUpdate,
} from '@containers';
import { Card } from '@presentation/Main';
import { AskAnExpert } from 'components/AskAnExpert';
import { GroupSidebarTaggedProjects } from 'components/GroupSidebarProjects';
import { GroupSidebarTags } from 'components/GroupSidebarTags';
import styles from './style/Sidebar.css';

export const Sidebar = () => {

  const tagsLoading = useGroupEntityTagsLoading();
  const expertsLoading = useAskAnExpertLoading();
  const projectsLoading = useTaggedProjectsLoading();

  const experts = useAskAnExpertData();
  const tags = useGroupEntityTags();
  const updateTags = useGroupEntityTagsUpdate();
  const projects = useTaggedProjects();
  const updateProjects = useTaggedProjectsUpdate();

  const viewMoreLink = useGroupTagsSearchLink();

  if (tagsLoading || expertsLoading || projectsLoading) {
    return null;
  }

  return (
    <>
      <div className={styles.forehead} />
      <div className={styles.sidebar}>
        <Card className={styles.card}>
          <GroupSidebarTaggedProjects
            items={projects}
            onSave={updateProjects} />
          <GroupSidebarTags
            tags={tags}
            onSave={updateTags} />
          {!!experts.length &&
            <AskAnExpert
              viewMoreLink={viewMoreLink} />}
        </Card>
      </div>
    </>
  );
};

export default Sidebar;
import { useCallback } from 'react';
import { Node } from 'slate';
import * as api from '@api';
import { useSelectUser } from '@containers/Store';
import { ContentFeed } from '@/types';
import { LikeSection } from 'components/LikeSection';
import * as Comment from './Comment';
import { Commenting } from './Feed.Commenting';
import { useCommentReplyState } from './hooks/useCommentReplyState';
import styles from './style/SupplementalActions.css';

type Props = {
  className?: string;
  item:       ContentFeed.FeedItem;
};

export const SupplementalActions = ({ item }: Props) => {
  const { id: meId } = useSelectUser();
  const [replying, replymode] = useCommentReplyState(false);

  const handleSubmitComment = useCallback((val: Node[]) => {
    return api.comments.saveComment({
      comment: val,
      itemId: item.id,
      itemTypeId: item.contentType,
    })
    .then(comment => {

      replymode.disable();

      return comment;

    });
  }, [
    item.contentType,
    item.id,
    replymode,
  ]);

  if (!meId) return null;

  return (
    <>
      <div className={styles.actions}>
        {item.likeDescription &&
          <LikeSection
            className={styles.likes}
            likeDescription={item.likeDescription} />}
        <Comment.Button onClick={replymode.toggle} />
      </div>
      <Commenting
        item={item}
        replying={replying}
        onSubmit={handleSubmitComment} />
    </>
  );
};

SupplementalActions.displayName = 'Feed.SupplementalActions';
import { forwardRef, Component } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { cx } from '@utils';
import Button from 'components/Button';
import WizardPhases from './Phases';
import styles from './style.css';

class Wizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalSteps: props.steps.length,
    };
  }

  componentDidMount() {
    const step = this.getCurrentStep();

    if (!step || step !== 1) {
      this.props.history.replace(`${this.props.location.pathname}?step=1`);
    }

    if (this.props.onStep) {
      this.props.onStep(step || 1);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const previousStep = queryString.parse(prevProps.location.search).step;
    const currentStep = this.getCurrentStep();

    if (previousStep !== this.getCurrentStep()) {
      if (this.props.steps[currentStep - 1].autoFocusRef) {
        this.props.steps[currentStep - 1].autoFocusRef.current.focus();
      }
    }
  }

  redirectToStep = step => {
    this.props.history.push(`${this.props.location.pathname}?step=${step}`);
    if (this.props.onStep) {
      this.props.onStep(step);
    }
  }

  getCurrentStep = () => {
    const stepFromURL = queryString.parse(this.props.location.search).step;
    return stepFromURL ? Number(stepFromURL) : null;
  }

  getCurrentPhase = () => {
    if (this.props.phases) {
      const currentStep = this.getCurrentStep();
      const currentStepKey = this.props.steps.find((f, i) => i === currentStep - 1).key;

      return this.props.phases.find(f => f.steps.some(s => s === currentStepKey));
    }

    return null;
  }

  updateStep = step => () => {
    if (step < 1) {
      this.props.history.push(this.props.backRoute);
    } else {
      this.redirectToStep(step);
    }
  }

  renderBody = () => {
    return this.props.steps.map((m, i) => {
      const active = (i + 1) === this.getCurrentStep();

      return (
        <div key={i} className={[active ? styles.show : styles.hide].join(' ')}>
          {this.renderHeader(m)}
          <div className={styles.body}>
            {m.renderBody()}
          </div>
        </div>
      );
    });
  }

  renderCompleteButton = () => {
    const activeStep = this.getCurrentStep();
    const currentStepOptions = this.props.steps[activeStep - 1];

    if (this.props.renderCompleteButton) {
      return this.props.renderCompleteButton();
    }

    return (
      <Button
        className={styles.complete}
        disabled={!currentStepOptions.enableNext}
        onClick={this.props.onComplete}
        variant="brick">
        Complete
      </Button>
    );
  }

  renderNextButton = () => {
    const activeStep = this.getCurrentStep();
    const isLastStep = activeStep == this.state.totalSteps;
    const currentStepOptions = this.props.steps[activeStep - 1];

    if (isLastStep) {
      return this.renderCompleteButton();
    }

    return (
      <Button
        className={styles.next}
        color="primary"
        disabled={!currentStepOptions.enableNext}
        onClick={this.updateStep(activeStep + 1)}
        variant="brick">
        Next
      </Button>
    );
  }

  renderButtons = () => {
    if (!this.props.showButtons) return null;

    const activeStep = this.getCurrentStep();
    const currentStepOptions = this.props.steps[activeStep - 1];

    return (
      <>
        <div className={styles.buttons}>
          <div
            className={styles.back}
            onClick={this.updateStep(activeStep - 1)}>
            {'< Back'}
          </div>
          {this.renderNextButton()}
        </div>
        <div className={styles.lowerButtons}>
          {currentStepOptions.renderSkipButton ? currentStepOptions.renderSkipButton() : null}
        </div>
    </>
    );
  }

  renderHeader = item => {
    if (!item.title) return null;

    return (
      <div className={styles.header}>
        <div className={styles.title}>
          <div className={styles.titleText}>{item.title}</div>
          {item.isOptional &&
            <div className={styles.optionalText}>{`(Optional)`}</div>}
        </div>
        <div className={styles.subtitle}>{item.subtitle}</div>
      </div>
    );
  }

  setIndex = index => {
    this.redirectToStep(index + 1);
  }

  render() {
    if (!this.getCurrentStep()) {
      return null;
    }

    if (this.props.showSidebar) {
      return (
        <div className={styles.phasesRoot}>
          <div className={styles.sidebar}>
            <WizardPhases
              phases={this.props.phases}
              currentPhase={this.getCurrentPhase()} />
          </div>
          <div className={styles.wrap}>
            {this.renderBody()}
            {this.renderButtons()}
          </div>
        </div>
      );
    } else {
      return (
        <div className={cx(styles.root, this.props.className)}>
          <div className={styles.wrap}>
            {this.renderBody()}
            {this.renderButtons()}
          </div>
        </div>
      );
    }
  }
}

const Wiz = (props, ref) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <Wizard
      {...props}
      history={history}
      location={location}
      ref={ref} />
  );
};

const WizRef = forwardRef(Wiz);

export * from './Header';
export * from './Navigation';
export * from './Screen';
export { default as Phases } from './Phases';
export { WizRef as Wizard };
export default WizRef;
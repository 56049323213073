import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as Branding from '@api/interfaces/groups.branding';
import { useSelectGroup } from '@containers/Store';
import { UpdateLogo, useUpdateLogoMutation as useUpdateLogoMutationAPI } from '@utils/api';
import { useQueryDataAccessor } from './useQueryDataAccessor';

export const useUpdateLogoMutation = (options: UpdateLogo.Options = {}) => {
  const helpers = useMutationHelpers();

  const mutation = useUpdateLogoMutationAPI({
    ...options,
    onSuccess: (...args) => {
      helpers.onSuccess(...args);
      options.onSuccess?.(...args);
    },
  });

  return mutation;
};

const useMutationHelpers = () => {
  const accessor = useQueryDataAccessor();
  const store = useStoreHelpers();

  const onSuccess = useCallback((res: Branding.UpdateLogo.Response, variables: UpdateLogo.Variables, context) => {

    accessor.setQueryData(data => {
      return {
        ...data,
        logos: {
          ...data.logos,
          ...res.logo,
        },
        theming: data.theming,
      };
    });

    store.onSuccess.dispatch(res);

  }, [
    accessor,
    store.onSuccess,
  ]);

  return {
    onSuccess,
  };
};

const useStoreHelpers = () => {
  const dispatch = useDispatch();
  const { branding } = useSelectGroup();

  const onSuccess = useCallback((res: Branding.UpdateLogo.Response) => {
    dispatch(actions.brandingUpdated({
      branding: {
        logos: {
          ...branding.logos,
          ...res.logo,
        },
      },
    }));
  }, [
    branding?.logos,
    dispatch,
  ]);

  return {
    onSuccess: { dispatch: onSuccess },
  };
};
import { useRouteMatch } from 'react-router-dom';
import { path } from '@consts';
import { Strategy } from '$website/containers/MarketSegmentation/interfaces';
import { useAssertStrategy } from '$website/containers/MarketSegmentation/hooks/useAssertStrategy';

export const useAllowAuthFlow = () => {
  const assert = useAssertStrategy();

  const routeMatch = useRouteMatch({
    path: [
      path.Discovery.Post,
    ],
  });

  const strategyMatch = assert(Strategy.Individuals);

  return !!(routeMatch || strategyMatch);
};
import * as enums from '@enums';
import { Groups, Surveys } from '@services/api/interfaces';
import http from '@services/http';
import * as xform from '@transformers';
import { nullableDate, qs } from '@utils';
import { Projects } from './interfaces';

export const fetchSurvey = (data: Projects.FetchSurvey.Request): Promise<Projects.FetchSurvey.Response> => {
  return http.get(`/admin/projects/${data.projectId}/survey`)
    .then((data: Projects.FetchSurvey.Response<string>) => ({
      ...data,
      survey: data.survey
        ? {
          ...data.survey,
          createdOn: nullableDate(data.survey.createdOn),
        }
        : null,
    }));
};

export const fetchResponse = (data: Omit<Groups.Compliance.FetchSurveyResponseReview.Request, 'groupId'>) => {
  return http.get(`admin/projects/survey-responses/${data.responseId}`)
    .then((data: Groups.Compliance.FetchSurveyResponseReview.Response) => {
      return {
        ...data,
        user: xform.setUserSlug(data.user),
      };
    });
};

export const fetchResponses = (data: Projects.FetchProjectSurveyResponses.Request): Promise<Projects.FetchProjectSurveyResponses.Response> => {
  const { projectId, ...rest } = data;
  return http.get(`/admin/projects/${projectId}/survey-responses?${qs.stringify(rest as Record<string, unknown>, { arrayFormat: 'bracket' })}`);
};

export const getReadyToPublishCounts = (data: Projects.GetReadyToPublishCounts.Request): Promise<Projects.GetReadyToPublishCounts.Response> => {
  return http.get(`/admin/projects/survey-responses/ready?${qs.stringify(data)}`);
};

export const publishResponses = (data: { projectId: number; }) => {
  return http.post(`/admin/projects/${data.projectId}/survey-responses/publish`, {});
};

export const toggleAnswerVisibility = (data: Surveys.ToggleAnswerVisibility.Request): Promise<Surveys.ToggleAnswerVisibility.Response> => {
  const { responseId, questionId, ...rest } = data;
  return http.post(`/admin/surveys/responses/${data.responseId}/questions/${data.questionId}/visibility`, rest);
};

export const updateScreeningStatus = (data: { projectSurveyResponseId: number; screeningStatusId: enums.ApprovalStatus; }) => {
  const { projectSurveyResponseId, ...rest } = data;
  return http.patch(`/admin/projects/survey-responses/${projectSurveyResponseId}`, rest);
};

export const updateSurvey = (data: Projects.UpdateSurvey.Request): Promise<Projects.UpdateSurvey.Response> => {
  return http.put(`/admin/projects/${data.projectId}/survey`, data)
    .then((data: Projects.UpdateSurvey.Response<string>) => ({
      ...data,
      survey: data.survey
        ? {
          ...data.survey,
          createdOn: nullableDate(data.survey.createdOn),
        }
        : null,
    }));
};

// export const surveys = {
//   fetchUserResponse: fetchProjectSurveyResponse,
//   fetchResponses: fetchProjectSurveyResponses,
//   getReadyToPublishCounts,
//   publishResponses: publishSurveyResponses,
//   toggleAnswerVisibility: toggleSurveyResponseAnswerVisibility,
//   updateScreeningStatus: updateProjectSurveyResponseScreeningStatus,
// };
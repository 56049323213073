import { forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import * as consts from '@consts';
import { VideoPreviewOverlay } from '@presentation';
import { getLocationFor, cx, slugify } from '@utils';
import { Discovery } from '@/types';
import { UserAvatar } from 'components/UserAvatar';
import styles from './style/Category.css';

type Props = {
  className?: string;
  item:       Discovery.VideoTopicPost;
  style?:     React.CSSProperties;
};

const Item = ({
  item,
  style = {},
  ...props
}: Props, ref: React.Ref<HTMLDivElement>) => {
  const pathname = useMemo(() => ({
    content: `${consts.path.Discovery.Root}/${item.post.id}`,
    profile: getLocationFor.user.profile({
      slug: slugify({
        id: item.post.author.id,
        name: item.post.author.profile.fullname,
      }),
    }),
  }), [item.post.id, item.post.author.id, item.post.author.profile.fullname]);

  return (
    <div
      className={cx(styles.root, props.className)}
      ref={ref}
      style={style}>
      <div className={styles.wrap}>
        <VideoPreviewOverlay
          className={styles.preview}
          previewUrl={item.entities.preview.url}
          to={pathname.content} />

        <div className={styles.details}>
          <div className={styles.title}>
            <Link to={pathname.content}>
              {item.post.media.title}
            </Link>
          </div>

          <div className={styles.creator}>
            <div className={styles.main}>
              <Link to={pathname.profile}>
                <UserAvatar
                  className={styles.avatar}
                  pictureUrl={item.post.author.profile.pictureUrl} />
              </Link>
            </div>
            <div className={styles.desc}>
              <div className={styles.name}>
                <Link to={pathname.profile}>
                  {item.post.author.profile.fullname}
                </Link>
              </div>
              <div className={styles.position}>
                {item.post.author.career.current.title}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.main}>
            <Link to={pathname.profile}>
              <UserAvatar
                className={styles.avatar}
                pictureUrl={item.post.author.profile.pictureUrl} />
            </Link>
          </div>
          <div className={styles.headline}>
            <div className={styles.title}>
              <Link to={pathname.content}>
                {item.post.media.title}
              </Link>
            </div>
            <div className={styles.author}>
              <Link
                className={styles.link}
                to={pathname.profile}>
                {item.post.author.profile.fullname}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Item.displayName = 'Category.Item';

const ItemWithRef = forwardRef<HTMLDivElement, Props>(Item);

export { ItemWithRef as Item };
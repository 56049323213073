import { createContext, useContext, Dispatch } from 'react';
import { SurveyActionType, SurveyQuestionType } from '@enums';
import { createNamedContext } from '@utils';
import { SurveyBuilder } from './interfaces';

export type SurveyBuilderOptionsContextValue = {
  actionTypes?: SurveyActionType[];
  maxQuestions: number;
  questionTypes?: SurveyQuestionType[];
};

export type SurveyBuilderStateContextValue =
  [SurveyBuilder.State, Dispatch<SurveyBuilder.Actions>];

export type ScreenerBuilderContextValue = {
  addQuestion: () => void;
  canAddQuestion: () => boolean;
  questions: string[];
  removeQuestion: (index: number) => () => void;
  setQuestions: (items: string[]) => void;
  updateQuestion: (index: number) => (value: string) => void;
};

export type EditableSurveyStateContextValue = {
  fetchSurvey: () => Promise<void>;
  deleteSurvey: () => Promise<void>;
  saveSurvey: () => Promise<void>;
} & ISurveyId;

export type SaveSurveyContextValue = () => Promise<void>;

export const SurveyBuilderStateContext = createNamedContext<SurveyBuilderStateContextValue>(undefined, 'SurveyBuilderContext');
export const SurveyBuilderOptionsContext = createNamedContext<SurveyBuilderOptionsContextValue>(undefined, 'SurveyBuilderOptionsContext');

export const SaveSurveyContext = createContext<SaveSurveyContextValue | undefined>(undefined);
export const ScreenerBuilderContext = createContext<ScreenerBuilderContextValue>(null!);
export const EditableSurveyContext = createContext<EditableSurveyStateContextValue>(null!);

export const SurveyQuotasGoalContext = createNamedContext<number>(null, 'SurveyQuotasGoalContext');

export const useSurveyQuotasState = () => {
  const [state, dispatch] = useContext(SurveyBuilderStateContext);

  return [{
    editing: state.editing.quota,
    items: state.survey.quotas,
  }, dispatch] as const;
};

export const useSurveyQuotasGoal = () => useContext(SurveyQuotasGoalContext);
export const useScreenerBuilderContext = () => useContext(ScreenerBuilderContext);
import { useWorkbookData, useWorkbookDataLoading } from '@containers/WorkspaceFilePreview/hooks';
import { Workbook as WB } from 'components/Workbook';

export const Workbook = () => {
  const loading = useWorkbookDataLoading();
  const workbook = useWorkbookData();

  if (loading) {
    return null;
  }

  return (
    <WB workbook={workbook} />
  );
};

export default Workbook;
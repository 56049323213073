import { useState } from 'react';
import { format } from 'date-fns';
import { cx, useLock } from '@utils';
import Button from 'components/Button';
import { StandardProps } from './interfaces';
import styles from './style/StandardMessage.css';

type Props =
  StandardProps;

export function StandardMessage(props: Props) {
  return props.sender.currentUser
    ? <Sender {...props} />
    : <Recipient {...props} />;
}

function Sender(props: Props) {
  const { item, sender, recipient } = props;

  const complianceReview = item.type === 'platform' && item.data.attributes.inComplianceReview;
  const recipientReview = item.type === 'platform' && !recipient.connection;
  const inReview = complianceReview || recipientReview;

  function Footer() {
    if (!inReview) return null;

    return (
      <div className={styles.status}>
        {complianceReview ? 'Pending Compliance' : 'Pending Member'}
      </div>
    );
  }

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={sender.currentUser ? styles.current : styles.name}>{sender.name}</div>
        <div className={styles.time}>{format(item.data.timestamp, 'h:mm a')}</div>
      </div>
      <div className={cx(styles.body, inReview ? styles.review : null)}>{item.data.body}</div>
      <Footer />
    </div>
  );
}

type RecipientProps =
  Props;

function Recipient(props: RecipientProps) {
  const { item, sender } = props;

  function NotConnected() {
    const [complete, setComplete] = useState(false);
    const [isBusy, lock] = useLock();

    const handleReject = () => {
      setComplete(true);
      return props.onReject({ item })
      .catch(e => {
        console.error(e);
        setComplete(false);
      });
    };

    const handleAccept = () => {
      setComplete(true);
      return props.onAccept({ item })
        .catch(e => {
          console.error(e);
          setComplete(false);
        });
    };

    const btnDiabled = complete || isBusy;

    return (
      <div className={styles.actions}>
        <Button.Destructive
          className={styles.inviteReject}
          variant='brick'
          title='Not Interested'
          disabled={btnDiabled}
          onClick={lock(handleReject)} />
        <Button.Affirmative
          className={styles.inviteAccept}
          variant='brick'
          title='Accept'
          disabled={btnDiabled}
          onClick={lock(handleAccept)} />
      </div>
    );
  }

  const inReview = item.type === 'platform' && !sender.connection;

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={sender.currentUser ? styles.current : styles.name}>{sender.name}</div>
        <div className={styles.time}>{format(item.data.timestamp, 'h:mm a')}</div>
      </div>
      <div className={styles.body}>{item.data.body}</div>
      {inReview && <NotConnected />}
    </div>
  );
}

export default StandardMessage;
import { useCallback, useMemo } from 'react';
import * as api from '@api/calls';
import {
  useCallTranscript,
  useCallTranscriptionComments,
  useCallTranscriptionHighlights,
} from '@utils/api';
import { Transcript } from './interfaces';
import {
  useTranscriptComments,
  CallInstanceIdContext,
  TranscriptContext,
  TranscriptCommentActionsContext,
  TranscriptHighlightsContext,
  TranscriptHighlightActionsContext,
  TranscriptSpeakersContext,
} from './context';
import { CallTranscriptMentionsContainer } from './Call.Mentions';
import { TranscriptCommentsContainer } from './Comments';

type Props =
  ICallId &
  ChildrenProps;

const CallTranscriptContainer = ({ callId, children }: Props) => {

  const [_, dispatchComments] = useTranscriptComments();

  const {
    data: transcriptData,
    isLoading: transcriptLoading,
  } = useCallTranscript({
    callId,
  }, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const transcriptId = useMemo(() => {
    return transcriptData?.transcript?.id;
  }, [transcriptData]);
  const instanceId = useMemo(() => {
    return transcriptData?.instanceId;
  }, [transcriptData]);

  const {
    data: highlights,
    isLoading: highlightsLoading,
  } = useCallTranscriptionHighlights({
    callId,
    transcriptId,
  }, {
    enabled: !!transcriptId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
  });

  const {
    isLoading: commentsLoading,
  } = useCallTranscriptionComments({
    callId,
    transcriptId,
  }, {
    enabled: !!transcriptId,
    onSuccess: res => {
      dispatchComments({
        type: 'comments-fetched',
        items: res,
      });
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
  });

  const saveHighlight: Transcript.SaveHighlight = useCallback(({ highlight }) => {
    return api.saveTranscriptHighlight({
      callId,
      highlight,
      transcriptId,
    }).then(res => res.highlight);
  }, [
    callId,
    transcriptId,
  ]);

  const removeHighlight: Transcript.RemoveHighlight = useCallback(({ highlight }) => {
    return api.removeTranscriptHighlight({
      callId,
      highlightId: highlight.dbId,
      transcriptId,
    });
  }, [
    callId,
    transcriptId,
  ]);

  const removeComment: Transcript.RemoveComment = useCallback(({ commentId }) => {
    return api.removeTranscriptComment({
      callId,
      commentId,
      transcriptId,
    });
  }, [
    callId,
    transcriptId,
  ]);

  const updateComment: Transcript.UpdateComment = useCallback(({ commentId, value }) => {
    return api.updateTranscriptComment({
      callId,
      commentId,
      transcriptId,
      value,
    });
  }, [
    callId,
    transcriptId,
  ]);

  const saveComment: Transcript.SaveComment = useCallback(({ comment }) => {
    return api.saveTranscriptComment({
      callId,
      comment,
      transcriptId,
    });
  }, [
    callId,
    transcriptId,
  ]);

  return (
    <CallInstanceIdContext.Provider value={instanceId}>
      <TranscriptContext.Provider value={transcriptData?.transcript}>
        <TranscriptHighlightsContext.Provider value={highlights}>
          <TranscriptHighlightActionsContext.Provider value={{ removeHighlight, saveHighlight }}>
            <TranscriptCommentActionsContext.Provider value={{ removeComment, saveComment, updateComment }}>
              <TranscriptSpeakersContext.Provider value={transcriptData?.speakers}>
                {children}
              </TranscriptSpeakersContext.Provider>
            </TranscriptCommentActionsContext.Provider>
          </TranscriptHighlightActionsContext.Provider>
        </TranscriptHighlightsContext.Provider>
      </TranscriptContext.Provider>
    </CallInstanceIdContext.Provider>
  );
};

const WithState = ({ children, ...props }: Props) => {
  return (
    <TranscriptCommentsContainer>
      <CallTranscriptMentionsContainer callId={props.callId}>
        <CallTranscriptContainer {...props}>
          {children}
        </CallTranscriptContainer>
      </CallTranscriptMentionsContainer>
    </TranscriptCommentsContainer>
  );
};

export { WithState as CallTranscriptContainer };
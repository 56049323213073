import {
  command,
  extension,
  helper,
  CommandFunction,
  CreateExtensionPlugin,
  ExtensionPriority,
  Helper,
  PlainExtension,
} from '@remirror/core';
import type { EditorState } from '@remirror/pm/state';
import { KaraokeState } from './Extension.Karaoke.State';
import * as KE from './interfaces.extension.karaoke';

@extension({
  defaultOptions: {},
  defaultPriority: ExtensionPriority.Low,
})
export class KaraokeExtension extends PlainExtension {

  get name() {
    return 'karaoke' as const;
  }

  getKaraokeState(): KaraokeState {
    return this.getPluginState();
  }

  createPlugin(): CreateExtensionPlugin {

    const pluginState = new KaraokeState();

    return {
      state: {
        init() {
          return pluginState;
        },
        apply(tr) {
          const action = tr.getMeta(KaraokeExtension.name) as KE.Action;
          return pluginState.apply({ tr, action });
        },
      },
      props: {
        decorations(state: EditorState) {
          return this.getState(state).decorationSet;
        },
      },
    };
  }

  @command()
  setKaraokePosition(position: KE.Position): CommandFunction {
    return ({ tr, dispatch }) => {

      dispatch?.(tr.setMeta(KaraokeExtension.name, {
        position,
        type: 'update-karaoke-position',
      } as KE.UpdateKaraokePosition.Action));

      return true;
    };
  }

  @helper()
  getKaraokePosition(): Helper<KE.Position> {
    return this.getKaraokeState().position;
  }

  @helper()
  getKaraokeText(): Helper<string> {
    const state = this.getKaraokeState();

    return state.position
      ? this.store.view.state.doc.textBetween(state.position.from, state.position.to)
      : null;
  }

}
import { SurveyContext } from '@containers/Survey/Context';
import { useProjectOrLatestChild } from '@containers/GroupProject/hooks/useProjectState';
import { useFetchProjectSurveyResponses } from './hooks/useFetchProjectSurveyResponses';
import SurveyStarredAnswersContainer from './SurveyStarredAnswersContainer';
import { ProjectSurveyResponsesLoadingContext, ProjectSurveyResponsesContext } from './Context';

export const SurveyResponsesDataContainer = (props: ChildrenProps) => {
  const project = useProjectOrLatestChild();

  const {
    data,
    isLoading,
  } = useFetchProjectSurveyResponses({
    projectId: project.id,
  }, {});

  return (
    <ProjectSurveyResponsesLoadingContext.Provider value={isLoading}>
      <ProjectSurveyResponsesContext.Provider value={data?.items}>
        <SurveyContext.Provider value={data?.survey ?? null}>
          <SurveyStarredAnswersContainer initial={data?.starred || {}}>
            {props.children}
          </SurveyStarredAnswersContainer>
        </SurveyContext.Provider>
      </ProjectSurveyResponsesContext.Provider>
    </ProjectSurveyResponsesLoadingContext.Provider>
  );
};

export default SurveyResponsesDataContainer;
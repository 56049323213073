import { useCallback } from 'react';
import { useHasConsultantRole } from '@containers/User';
import { useSelectContact, useSelectProject, useSelectProjectCall } from '@containers/Store';
import { useLock } from '@utils';
import Button from 'components/Button';
import ButtonSet from 'components/Modal/ButtonSet';
import { Prompt } from 'components/Modal/Prompt';
import { ModalProps } from './Modal';
import { useModal } from './hooks';
import styles from './style/Base.css';

type Props = {
  onConfirm: () => Promise<unknown>;
} & ICallId
  & Pick<ModalProps,
    'onClose' |
    'open'>;

export const CancelProjectCall = ({
  callId,
  onConfirm,
  onClose,
  open,
}: Props) => {

  const call = useSelectProjectCall(callId);
  const contact = useSelectContact(call.userId);
  const project = useSelectProject(call.projectId);

  const isConsultant = useHasConsultantRole();

  const [isBusy, lock] = useLock();

  const renderButtons = useCallback(() => {
    return (
      <ButtonSet>
        <Button.Destructive
          className={styles.promptBtn}
          disabled={isBusy}
          title="Cancel"
          onClick={onClose}
          variant="brick" />
        <Button.Primary
          className={styles.promptBtn}
          disabled={isBusy}
          title="Confirm"
          onClick={lock(onConfirm)}
          variant="brick" />
      </ButtonSet>
    );
  }, [
    isBusy,
    lock,
    onConfirm,
    onClose,
  ]);

  const role = isConsultant ? 'client' : 'expert';
  const body = `Vancery will notify the ${role} that the call has been cancelled upon confirmation.`;
  const title = isConsultant
    ? `Cancel Call for ${project.name}`
    : `Cancel Call with ${contact.profile.fullname}`;

  return (
    <Prompt
      body={body}
      footer={renderButtons()}
      header={title}
      onClose={onClose}
      visible={open} />
  );
};

export const useCancelProjectCallModal = () => useModal(CancelProjectCall);

export default CancelProjectCall;
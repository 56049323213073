export type Transcript = {
  id: number;
  document: DocumentNode;
  version: string;
};

export enum MarkType {
  Timestamp   = 'ts',
}

export enum NodeType {
  Document         = 'doc',
  MonologueText    = 'mt',
  MonologueDetails = 'md',
  Text             = 'text',
}

export type DocumentNode = {
  content: MonologueNode[];
  type: NodeType.Document;
};

export type MonologueNode = MonologueDetails.Node | MonologueText.Node;

export declare namespace MonologueDetails {

  export type Attributes = {
    s: number;
    e: number;
    spk: number;
  };

  export type Node = {
    attrs: Attributes;
    type: NodeType.MonologueDetails;
  };
}

export declare namespace MonologueText {

  export type Node = {
    content: TextNode[];
    type: NodeType.MonologueText;
  };
}

export declare namespace Timestamp {
  export type Attributes = {
    s: number;
    e: number;
  };

  export type Mark = {
    attrs: Attributes;
    type: MarkType.Timestamp;
  };
}

export type TextNode = {
  marks?: Timestamp.Mark[];
  type: NodeType.Text;
  text: string;
};

export type Highlight = {
  color: string;
  dbId: number | null;
  id: string;
  from: number;
  to: number;
};

export * from './transcript.rich-text.comments';
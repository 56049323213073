import { Loader } from 'react-feather';
import { cx } from '@utils';
import styles from './style.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
  color?:     string;
  size?:      number;
};

const Spinner = (props: Props) => {
  const children = props.children
                || <Loader color={props.color} size={props.size} />;

  return (
    <div className={cx(styles.spinner, props.className)}>
      <div className={styles.wrap}>
        {children}
      </div>
    </div>
  );
};

export { Spinner };
export default Spinner;
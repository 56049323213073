import { SurveyQuestionType } from '@enums';
import { SurveyAggregate, SurveyQuestion, SurveyQuestionOption } from '@/types';

export const sortOptions = (question: SurveyQuestion, responses: SurveyAggregate.Options.Map) => (a: SurveyQuestionOption, b: SurveyQuestionOption) => {
  return question.typeId === SurveyQuestionType.MultipleChoice
    ? a.ordinal - b.ordinal
    : responses[b.base.id].pct - responses[a.base.id].pct;
};

export const OptionsColors = [
  '#31bf90',
  '#5a85e3',
  '#f25753',
  '#f1c337',
  '#48bdef',
  '#F3833A',
  '#A26BDD',
  '#7BC350',
  '#D04081',
  '#14AFB0',
  '#725DFF',
  '#FF6933',
  '#F06CC0',
  '#16E3D7',
  '#7199C6',
  '#CB7FE0',
  '#FAA531',
  '#FB7C7C',
  '#79C8DE',
  '#FE5581',
  '#E24545',
  '#F9E947',
  '#B8E316',
  '#5AE385',
  '#639D83',
  '#4C8B8A',
  '#546EBF',
  '#805ABE',
  '#DB78B6',
  '#A6436E',
  '#7AE74D',
  '#C13D91',
  '#F97E47',
  '#4499EE',
  '#F9D04D',
  '#F35C3A',
  '#9891E1',
  '#D5E147',
  '#2D7C92',
  '#57D7AD',
  '#FFC900',
  '#F14242',
  '#A2D5FE',
  '#8EC292',
  '#F06C6C',
  '#7445A7',
  '#D94C4C',
  '#50B4C0',
  '#FFA660',
  '#D827C5',
];

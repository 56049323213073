import { useCallback } from 'react';
import { SurveyTemplateType } from '@enums/survey.template';
import {
  useCanSaveTarget,
  useTemplateTarget,
  TemplateTargetInput,
} from 'components/Survey.Templates';
import {
  ThanksPresetScreen,
} from './ProjectSurvey.Presets.Shared';
import { BackButton, Footer, NextButton } from './Footer';
import { useProjectSurveyStepperActions } from './context';
import { BasePresetScreen } from './ProjectSurvey.Presets.Shared';

const LetsGetStarted = () => {

  const title = `Great, let's get started building your survey!`;
  const subtitleOne = `To set up your Employee Satisfaction Survey template, we'll just need to confirm the name of the organization you are gathering feedback for.`;
  const subtitleTwo = `You'll then be able to customize the survey draft with specific questions through our survey builder. Let's jump in!`;

  const actions = useProjectSurveyStepperActions();

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitleOne, subtitleTwo]} />
      <Footer>
        <BackButton onClick={actions.back} />
        <NextButton
          title="Get Started"
          onClick={actions.next} />
      </Footer>
    </>
  );
};

const Target = () => {

  const title = `Your research target`;
  const subtitle = `Please provide the name of the organization that you are gathering employee feedback from.`;

  const { back, next } = useProjectSurveyStepperActions();

  const {
    onChange,
    onSubmit,
    value,
  } = useTemplateTarget();
  const canSave = useCanSaveTarget(value);

  const handleNext = useCallback(() => {
    onSubmit();
    next();
  }, [
    next,
    onSubmit,
  ]);

  const handleBack = useCallback(() => {
    onSubmit();
    back();
  }, [
    back,
    onSubmit,
  ]);

  return (
    <>
      <BasePresetScreen
        title={title}
        subtitles={[subtitle]}>
        <TemplateTargetInput
          template={SurveyTemplateType.EmployeeSatisfaction}
          onChange={onChange}
          value={value} />
      </BasePresetScreen>
      <Footer>
        <BackButton onClick={handleBack} />
        <NextButton
          disabled={!canSave}
          onClick={handleNext} />
      </Footer>
    </>
  );
};

export const EmployeeSatisfactionScreens = [
  LetsGetStarted,
  Target,
  ThanksPresetScreen,
];
import {
  Enum,
  EnumKey,
  EnumMap,
} from './interfaces';

const extend = <T extends EnumKey, U extends string = string>(map: EnumMap<T, U>) => {

  class EnumHelper implements Enum<T, U> {
    getName = (id: T) => {
      const value = Number.isNaN(+id) ? id : +id;

      return (map.get(value as T) || { name: '' as U }).name;
    }

    keys = () => {
      return Array.from(map.values()).map(({ name }) => name);
    }

    values = () => {
      return Array.from(map.keys());
    }
  }

  return new EnumHelper();
};

export { extend };
export default extend;
import { useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { SurveyQuestionOption, MaxDifferenceQuestion, SurveyResponseItem } from '@/types';
import { roundToPlace } from '@utils';
import styles from './style/MaxDiff.css';

export default function MaxDiff({ item }: Props) {
  const details = useMemo(() => {
    const appearedOptions = item.answer.sets.flatMap(s => s.options);

    return item.question.options.map<DetailAggregate>(option => {
      const appeared = appearedOptions.filter(o => o === option.ordinal).length;
      const left = item.answer.sets.filter(s => s.left === option.ordinal).length;
      const right = item.answer.sets.filter(s => s.right === option.ordinal).length;
      const nc = appeared - left - right;
      const score = (right - left) / appeared;

      return {
        option,
        left,
        right,
        nc,
        score,
      };
    })
    .sort((a, b) => b.score - a.score);
  }, [item.answer.sets, item.question.options]);

  const { left, right } = item.question.settings.label;

  return (
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr className={styles.tr}>
          <th className={styles.th}>Attribute</th>
          <th className={styles.th}>{right}</th>
          <th className={styles.th}>{left}</th>
          <th className={styles.th}>Score</th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {details.map((s, i) => {
          return (
            <tr className={styles.tr} key={i}>
              <td className={styles.td}>{s.option.value}</td>
              <td className={styles.td}>{s.right}</td>
              <td className={styles.td}>{s.left}</td>
              <td className={styles.td}>{roundToPlace(s.score, 2)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export { MaxDiff };

type Props = {
  item: SurveyResponseItem<SurveyQuestionType.MaxDifference>;
};

type DetailAggregate = {
  option: SurveyQuestionOption;
  left: number;
  right: number;
  nc: number;
  score: number;
};
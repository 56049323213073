import { useCallback, useMemo } from 'react';
import { SurveyQuestionOption } from '@/types';
import DropDown from 'components/DropDown';

type Props = {
  disabled?: boolean;
  items: SurveyQuestionOption[];
  onSelect: (identifier: string) => void;
  value: SurveyQuestionOption;
};

type DropdownItem = {
  id: string;
  value: string;
};

export const OptionsDropdown = ({
  disabled,
  items,
  onSelect,
  value,
}: Props) => {

  const options = useMemo(() => {
    return items.map(m => ({
      id: m.base.identifier,
      value: m.value,
    }));
  }, [items]);

  const getItemValue = useCallback((item: DropdownItem) => {
    return item?.value || '';
  }, []);

  const handleSelect = useCallback((item: DropdownItem) => {
    onSelect(item.id);
  }, [onSelect]);

  const text = useMemo(() => {
    return value?.value || 'Select Option';
  }, [value]);

  return (
    <DropDown<DropdownItem>
      disabled={disabled}
      items={options}
      getItemValue={getItemValue}
      onSelect={handleSelect}
      text={text} />
  );
};

export default OptionsDropdown;
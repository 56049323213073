enum Product {
  Search = 1,
  Profiles,
  Messaging,
  Scheduling,
  Conferencing,
  Transcripts,
  Surveys,
}

export default Product;
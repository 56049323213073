import { AnimatedLoader } from 'components/ActivityIndicator';
import { AddressEntry } from 'components';
import { SetupCountryProps } from './interfaces';
import styles from './style/Setup.css';

type PickerProps =
    SetupCountryProps;

export function Picker({ label, selected, onChange }: PickerProps) {
  return (
    <div className={styles.step}>
      <div className={styles.stepLabel}>{label}</div>
      <div className={styles.stepContent}>
        <AddressEntry.Field.Country
          className={styles.country}
          dropdownClassName={styles.countryDropdown}
          label={null}
          selected={selected}
          onChange={onChange} />
      </div>
    </div>
  );
}

Picker.defaultProps = {
  label: 'Step 1: Select Your Country',
};

type LockedProps =
    Pick<SetupCountryProps, 'label' | 'selected'>;

export function Locked({ label, selected }: LockedProps) {
  return (
    <div className={styles.step}>
      <div className={styles.stepLabel}>{label}</div>
      <div className={styles.stepContent}>
        <div className={styles.countryLocked}>
          {selected?.name}
        </div>
      </div>
    </div>
  );
}

Locked.defaultProps = {
  label: 'Step 1: Select Your Country',
};

type LoadingProps =
    Pick<SetupCountryProps, 'label'>;

export function Loading({ label }: LoadingProps) {
  return (
    <div className={styles.step}>
      <div className={styles.stepLabel}>{label}</div>
      <div className={styles.stepContent}>
        <div className={styles.country}>
          <AnimatedLoader />
        </div>
      </div>
    </div>
  );
}

Loading.defaultProps = {
  label: 'Step 1: Select Your Country',
};

export function isStripeSupported(id: string) {
  return !!AddressEntry.Countries.StripeSupported.find(c => c.id === id);
}
import FullAddress, { resolveFullAddressSchema, validateFullAddress } from './FullAddressForm';

export const Form = {
  FullAddress,
};

export const validation = {
  resolveFullAddressSchema,
  validateFullAddress,
};

export * as Countries from './Countries';
export * as Field from './Field';

export type { FullFormAddressData, CountryFieldItem } from './interfaces';
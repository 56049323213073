import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getLocationFor } from '@utils';
import { BackLink } from '@presentation/Back';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { useMatchProjectStateFromUrl } from '@containers/GroupProject/hooks';
import { useSurveyBuilderState, useIsEditingSurvey, useHasUnsavedChanges, SurveyQuotasGoalContext } from '@containers/SurveyBuilder';
import { useFetchProjectGoal } from '@utils/api';
import { RouteLeavingGuard, RouteLeavingGuardState } from 'components/RouteLeavingGuard';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { Alert } from 'components/Modal/Alert';
import { ProjectSurveyBuilder, useProjectSurveyBuilderTabs } from 'components/SurveyBuilder';
import { useFetchProjectSurvey } from './useFetchSurvey';
import { useSaveProjectSurvey } from './useSaveSurvey';
import styles from './style/ProjectSurveyEditor.css';

type LocationState = {
  from: string;
};

export const ProjectSurveyEditor = () => {
  const { project } = useMatchProjectStateFromUrl();
  const location = useLocation<LocationState>();
  const [state] = useSurveyBuilderState();
  const isEditing = useIsEditingSurvey();
  const hasUnsavedChanges = useHasUnsavedChanges();
  const [goalResponse, fetchGoal] = useFetchProjectGoal();

  const {
    isLoading,
  } = useFetchProjectSurvey(project.id);
  const {
    isLoading: isSaving,
    mutateAsync: mutateSurvey,
  } = useSaveProjectSurvey(project.id);

  useEffect(() => {
    fetchGoal(project.id);
  }, [fetchGoal, project.id]);

  const saveSurvey = useCallback(() => {
    return mutateSurvey({
      survey: state.survey,
    });
  }, [
    mutateSurvey,
    state.survey,
  ]);

  const backTo = useMemo(() => {
    return location.state?.from ||
      getLocationFor.project.surveyResponses(project);
  }, [project, location.state]);

  const canSave = useMemo(() => {
    return !isEditing &&
      hasUnsavedChanges() &&
      state.survey.sections
        .every(e => !!e.name && state.survey.questions.some(s => s.section.identifier === e.identifier));
  }, [
    hasUnsavedChanges,
    isEditing,
    state.survey,
  ]);

  const tabs = useProjectSurveyBuilderTabs();

  if (isLoading) {
    return <ActivityIndicator show />;
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <BackLink
            className={styles.back}
            to={backTo} />
          <div className={styles.title}>{project.name} Survey</div>
          <SurveyQuotasGoalContext.Provider value={goalResponse.value?.goal?.value}>
            <ProjectSurveyBuilder tabs={tabs} />
          </SurveyQuotasGoalContext.Provider>
          <div className={styles.btns}>
            <ButtonActivityIndicator
              className={styles.save}
              onClick={saveSurvey}
              loading={isSaving}
              implicitDisable={isSaving}
              disabled={!canSave}>
              Save
            </ButtonActivityIndicator>
          </div>
        </div>
      </div>
      <RouteLeavingGuard block={hasUnsavedChanges()}>
        {(guard: RouteLeavingGuardState) => (
          <Alert
            confirmText="Discard"
            message="Are you sure you want to discard changes?"
            onClose={guard.closePrompt}
            onConfirm={guard.confirmNavigation}
            open={guard.open} />
        )}
      </RouteLeavingGuard>
    </>
  );

};

export default ProjectSurveyEditor;
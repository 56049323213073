import { useReducer } from 'react';
import { WorkspaceFileUpload } from '@/types';
import { FileUploadsStateContext } from './Context';
import { FileUploadState } from './interfaces';

type Props =
  ChildrenProps;

const initialState: FileUploadState.State = {
  invalid: [],
  progress: {},
  uploads: {},
  valid: [],
};

export const FileUploadsState = (props: Props) => {
  const reducer = useReducer(fileUploadsReducer, initialState);

  return (
    <FileUploadsStateContext.Provider value={reducer}>
      {props.children}
    </FileUploadsStateContext.Provider>
  );
};

function fileUploadsReducer(state: FileUploadState.State, action: FileUploadState.Actions) {

  switch (action.type) {
    case 'add-valid-upload':
      return {
        ...state,
        valid: [
          ...state.valid,
          action.fileUpload,
        ],
        progress: {
          ...state.progress,
          [action.fileUpload.id]: 0,
        },
      };

    case 'update-valid-upload':
      return {
        ...state,
        valid: state.valid.reduce((acc, x) => {
          return x.id === action.fileUpload.id
            ? [...acc, action.fileUpload]
            : [...acc, x];
        }, [] as WorkspaceFileUpload[]),
      };

    case 'add-invalid-file':
      return {
        ...state,
        invalid: [
          ...state.invalid,
          {
            file: action.file,
            reason: action.reason,
          },
        ],
      };

    case 'update-upload-progress':
      return {
        ...state,
        progress: {
          ...state.progress,
          [action.fileUploadId]: action.value,
        },
      };

    case 'register-s3-upload':
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [action.fileUploadId]: action.upload,
        },
      };
  }

}

export default FileUploadsState;
import { useCallback, useContext } from 'react';
import { Node } from 'slate';
import * as api from '@api';
import { PostArticleAdditionalDetailsContext } from '@containers/Post.Article/Context';
import { FeedItemType } from '@enums';
import { CommentsBlock } from 'components/Posts';
import styles from './style/Comments.css';

type Props = unknown;

export const Comments = (props: Props) => {
  const ctx = useContext(PostArticleAdditionalDetailsContext);

  const handleSubmit = useCallback((val: Node[]) => {
    return api.comments.saveComment({
      comment: val,
      itemId: ctx.postId,
      itemTypeId: FeedItemType.ArticlePost,
    })
    .then(async item => {
      await ctx.comments.query.refetch();

      return item;
    });
  }, [
    ctx.comments.query,
    ctx.postId,
  ]);

  if (!ctx.comments.enabled) return null;

  const items = ctx.comments.query?.data?.items || [];

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.title}>{`Comments (${items.length})`}</div>
          <div className={styles.indicator} />
        </div>
        <div className={styles.main}>
          <CommentsBlock
            items={items}
            loading={ctx.comments.query.isLoading}
            onSubmitComment={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

Comments.displayName = 'Post.Article.Comments';
import { ProjectSurveyResponsesLoadingContext, SurveyStarredAnswersContainer } from '@containers/SurveyResponses';
import { SurveyContext } from '@containers/Survey/Context';
import { useProjectState } from '@containers/GroupProject/hooks/useProjectState';
import { RepeatSurveyResponseDataContext } from './Context';
import { useFetchRepeatSurveyResponses } from './hooks/useFetchRepeatSurveyResponses';

export const RepeatResponsesDataContainer = (props: ChildrenProps) => {

  const { projectParent } = useProjectState();

  const {
    data,
    isLoading,
  } = useFetchRepeatSurveyResponses({
    projectParentId: projectParent.id,
  });

  return (
    <ProjectSurveyResponsesLoadingContext.Provider value={isLoading}>
      <RepeatSurveyResponseDataContext.Provider value={data?.data}>
        <SurveyContext.Provider value={data?.survey ?? null}>
          <SurveyStarredAnswersContainer initial={data?.starred || {}}>
            {props.children}
          </SurveyStarredAnswersContainer>
        </SurveyContext.Provider>
      </RepeatSurveyResponseDataContext.Provider>
    </ProjectSurveyResponsesLoadingContext.Provider>
  );
};
import { useRef, useState } from 'react';
import { format } from 'date-fns';
import { useCallVideoUrl } from '@utils/api';
import { useAudioPlayerRef } from '@containers/AudioPlayer';
import { useCallInstanceId, useTranscript, TranscriptMediaContext } from '@containers/Transcript';
import { useIsVideo } from '@utils/hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { AudioPlayer } from 'components/AudioPlayer';
import { Transcript } from 'components/Transcript';
import VideoPlayer from 'components/VideoPlayer';
import { TranscriptDownloadMenu } from './DownloadMenu';
import { VideoTranscriptProps } from './interfaces';
import styles from './style.css';

type Props =
  VideoTranscriptProps;

export function VideoTranscript({ call, project }: Props) {
  const [videoPlayer, setVideoPlayer] = useState<HTMLVideoElement>();
  const [audioPlayer] = useAudioPlayerRef();
  const transcript = useTranscript();
  const instanceId = useCallInstanceId();

  const {
    data: url,
  } = useCallVideoUrl({
    callId: call.id,
    roomInstanceId: instanceId,
  }, {
    enabled: !!instanceId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const isVideo = useIsVideo(url);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div>
          <div className={styles.label}>Title:</div>
          <div>{project.name}</div>
        </div>
        <div>
          <TranscriptDownloadMenu
            callId={call.id}
            transcriptId={transcript?.id}
            source={{
              type: 'video',
              url,
            }} />
        </div>
      </div>
      <div className={styles.header}>
        <div>
          <div className={styles.label}>Completed On:</div>
          <div>
            {format(call.timeEnd, 'MMM do yyyy hh:mm aa')}
          </div>
        </div>
      </div>
      <div className={styles.sticky}>
        <div className={styles.player}>
          {isVideo === false &&
            <AudioPlayer
              audio={{
                src: url,
              }} />
          }
          {isVideo &&
            <VideoPlayer
              ref={setVideoPlayer}
              controls
              src={url} />}
        </div>
      </div>
      <div>
        {!transcript && (
          <div className={styles.loader}>
            <ActivityIndicator show />
          </div>
        )}
        {transcript && isVideo !== null &&
          <TranscriptMediaContext.Provider value={isVideo ? videoPlayer : audioPlayer}>
            <Transcript />
          </TranscriptMediaContext.Provider>
        }
      </div>
    </div>
  );
}
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import * as api from '@api';
import { Projects } from '@api/interfaces';

type Data = Projects.GetProjectSurveyForm.Response;
type QueryKey = [string, number];

type Props = {
  preview: boolean;
} & IProjectId;

const useFetchProjectSurveyForm = (
  {
    preview,
    projectId,
  }: Props,
  options?: UseQueryOptions<Data, AxiosError, Data, QueryKey>
) => {

  return useQuery(['fetch-project-survey-form', projectId], ({ queryKey }) => {
    const projectId = queryKey[1] as number;

    return api.projects.surveys.getForm({
      preview,
      projectId,
    });
  }, options);
};

export { useFetchProjectSurveyForm };
export default useFetchProjectSurveyForm;
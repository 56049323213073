import { ButtonOutlined } from '@presentation/ButtonOutlined';
import { cx } from '@utils';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { useDownloadAccountReport } from './hooks';
import styles from './style/AccountReport.css';

type Props = {
  className?: string;
};

const AccountReport = ({ className }: Props) => {

  const { refetch, isLoading } = useDownloadAccountReport();

  return (
    <div className={cx(styles.root, className)}>
      <ButtonOutlined
        className={styles.btn}
        borderWidth="thin"
        color="silver"
        fontWeight="light"
        onClick={() => refetch()}>
        Export Documents
      </ButtonOutlined>
      {isLoading &&
        <ActivityIndicator.Spinner
          className={styles.loader} />}
    </div>
  );
};

export { AccountReport };
export default AccountReport;
import { useState } from 'react';
import { Share2 } from 'react-feather';
import * as consts from '@consts';
import { Button } from 'components/Button';
import { ShareProjectFromExpert as ShareProjectFromExpertModal } from 'components/Modal/ShareProjectWithExpert/FromExpert';
import { Project } from './interfaces';
import styles from './style/ShareProject.css';

type Props = {
  project: Project;
};

export const ShareProject = (props: Props) => {
  const [ shareModal, setShareModal ] = useState<number>(null);

  const handleClick = () => {
    setShareModal(props.project.id);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        Know someone who would be a good fit for this project?
      </div>
      <div className={styles.copy}>
        If a contact you refer is selected for the project, we will pay a <span className={styles.amount}>${consts.referral.REFERRAL_AMOUNT}</span> referral fee.
      </div>
      <div className={styles.btns}>
        <Button.Secondary
          className={styles.share}
          onClick={handleClick}
          variant="brick">
          <Share2 /> Share Project
        </Button.Secondary>
      </div>
      {shareModal &&
        <ShareProjectFromExpertModal
          projectId={shareModal}
          onClose={() => setShareModal(null)} />}
    </div>
  );
};
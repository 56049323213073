import { useMemo } from 'react';
import { Check as ApprovedIcon } from 'react-feather';
import { X as RejectedIcon } from 'react-feather';
import * as enums from '@enums';
import { cx } from '@utils';
import { ComplianceState, PipelineRecord } from '@/types';
import styles from './style/ComplianceReview.css';

type Props = {
  item: ComplianceState;
  pipeline?: Pick<PipelineRecord, 'statusId'>;
};

const ApprovalStatus = (props: Props) => {
  const classname = useMemo(() => getTextClassName(props), [props]);

  const text = useMemo(() => {
    return props.item.approvalStatusId === enums.ApprovalStatus.Approved
    ? 'Approved'
    : props.item.approvalStatusId === enums.ApprovalStatus.Rejected
      ? 'Rejected'
      : props.pipeline?.statusId === enums.UserProjectStatus.Disqualified
        ? 'Disqualified'
        : props.item.approvalStatusId === enums.ApprovalStatus.NotRequired
          ? 'Auto Approved'
          : '';
  }, [
    props.item.approvalStatusId,
    props.pipeline?.statusId,
  ]);

  function Icon() {
    if (['Disqualified', 'Rejected'].includes(text)) {
      return <RejectedIcon className={styles.icon} />;
    } else if (['Approved', 'Auto Approved'].includes(text)) {
      return <ApprovedIcon className={styles.icon} />;
    }
  }

  return (
    <div className={classname}>
      <Icon />
      <span>
        {text}
      </span>
    </div>
  );
};

function getTextClassName(props: Props) {
  return cx({
    [styles.warn]: props.item.approvalStatusId === enums.ApprovalStatus.Rejected ||
      props.pipeline?.statusId === enums.UserProjectStatus.Disqualified,
    [styles.affirm]: props.item.approvalStatusId !== enums.ApprovalStatus.Rejected &&
      props.pipeline?.statusId !== enums.UserProjectStatus.Disqualified,
  });
}

export { ApprovalStatus };
export default ApprovalStatus;
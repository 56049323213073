import { useCallback, useMemo } from 'react';
import { cx } from '@utils';
import { Select } from '$admin/components';
import styles from './style/RowsPerPage.css';

type Props = {
  className?: string;
  options: number[];
  onSelect: (count: number) => void;
  value: number;
};

export const RowsPerPage = ({ className, options, onSelect, value }: Props) => {

  const items = useMemo(() => {
    return options.reduce((acc, x) => ({
      ...acc,
      [x]: x.toString(),
    }), {} as { [v: number]: string; });
  }, [options]);

  const handleChange = useCallback((e: React.ChangeEvent<{
    name: string;
    value: string;
  }>) => {
    onSelect(+e.target.value);
  }, [onSelect]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.label}>Rows Per Page</div>
      <div className={styles.dropdown}>
        <Select
          defaultValue={options[0]}
          id="row-count"
          name="row-count"
          onChange={handleChange}
          options={items}
          value={value} />
      </div>
    </div>
  );
};

export default RowsPerPage;
import styles from './style/ComplianceReview.css';

type Props = unknown;

const TableEmpty = (props: Props) => {

  return (
    <div className={styles.empty}>
      No items
    </div>
  );

};

export { TableEmpty };
export default TableEmpty;
import { useCallback, useMemo } from 'react';
import { useSurveyBuilderState, useSurveyBuilderOptions } from '@containers/SurveyBuilder/hooks';
import { SurveyActionType } from '@enums';
import { SurveyQuestion } from '@/types';
import { QuestionLogicBuilderContext, QuestionLogicBuilderState } from './Context';
import { useSkipToSectionOptions, useSkipToQuestionOptions, useIncludeSectionOptions } from './hooks';
import { getValidResponseActionTypes, BaseActionTypes } from './action-types';

type Props = {
  item: SurveyQuestion;
} & ChildrenProps;

export const QuestionLogicBuilderContainer = (props: Props) => {
  const [state, dispatch] = useSurveyBuilderState();
  const { actionTypes } = useSurveyBuilderOptions();

  const section = useMemo(() => {
    return state.survey.sections.find(f => f.identifier === props.item.section.identifier);
  }, [
    props.item.section.identifier,
    state.survey.sections,
  ]);

  const skipToQuestionOptions = useSkipToQuestionOptions(props.item.ordinal);
  const skipToSectionOptions = useSkipToSectionOptions(section.ordinal);
  const includeSectionOptions = useIncludeSectionOptions(section.ordinal);

  const filteredActionTypes = useMemo(() => {
    return actionTypes
      .filter(filterSkipToEnd)
      .filter(filterSkipToQuestion)
      .filter(filterSkipToSection)
      .filter(filterIncludeSection)
    ;

    function filterSkipToEnd(actionType: SurveyActionType) {
      return actionType !== SurveyActionType.SkipToEnd ||
        props.item.ordinal < state.survey.questions.length;
    }
    function filterSkipToQuestion(actionType: SurveyActionType) {
      return actionType !== SurveyActionType.SkipToQuestion ||
        skipToQuestionOptions.length;
    }
    function filterSkipToSection(actionType: SurveyActionType) {
      return actionType !== SurveyActionType.SkipToSection ||
        skipToSectionOptions.length;
    }
    function filterIncludeSection(actionType: SurveyActionType) {
      return actionType !== SurveyActionType.IncludeSection ||
        includeSectionOptions.length;
    }
  }, [
    actionTypes,
    state.survey.questions.length,
    includeSectionOptions,
    props.item.ordinal,
    skipToQuestionOptions,
    skipToSectionOptions,
  ]);

  const baseActionTypes = useMemo(() => {
    return filteredActionTypes
      .filter(actionType => BaseActionTypes.includes(actionType));
  }, [
    filteredActionTypes,
  ]);

  const responseActionTypes = useMemo(() => {
    const actionTypesForQuestionType = getValidResponseActionTypes(props.item);
    return filteredActionTypes
      .filter(actionType => actionTypesForQuestionType.includes(actionType));
  }, [
    props.item,
    filteredActionTypes,
  ]);

  const onSubmit = useCallback((logic: SurveyQuestion['logic']) => {
    dispatch({
      // @ts-ignore
      item: {
        ...props.item,
        logic,
      },
      type: 'update-question',
    });

    dispatch({
      type: 'toggle-logic-editing',
      ordinal: null,
    });
  }, [
    dispatch,
    props.item,
  ]);

  const onCancel = useCallback(() => {
    dispatch({
      type: 'toggle-logic-editing',
      ordinal: null,
    });
  }, [dispatch]);

  const value: QuestionLogicBuilderState = {
    onCancel,
    onSubmit,

    item: props.item,

    baseActionTypes,
    responseActionTypes,

    includeSectionOptions,
    skipToQuestionOptions,
    skipToSectionOptions,
  };

  return (
    <QuestionLogicBuilderContext.Provider value={value}>
      {props.children}
    </QuestionLogicBuilderContext.Provider>
  );
};

export default QuestionLogicBuilderContainer;
import { useContext } from 'react';
import { SurveyThemingContext } from '@containers/Branding/Context';

export const useSurveyThemingPage = () => {
  const ctx = useContext(SurveyThemingContext);

  if (!ctx) {
    return {
      page: null,
      theme: false,
    };
  }

  return {
    page: ctx.theming.data?.page,
    theme: !!ctx.theming.data?.page,
  };
};
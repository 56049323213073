import { useEffect } from 'react';
import { ReadyState } from '@containers/AppReadyState/interfaces';
import { useAppReadySubscription } from './useAppReadySubscription';

export const useSignOutEffect = (onSignOut: () => unknown) => {
  const { off, on } = useAppReadySubscription();

  useEffect(() => {
    on(ReadyState.AppDataReset, onSignOut);

    return () => {
      off(ReadyState.AppDataReset, onSignOut);
    };
  }, [
    onSignOut,
    off,
    on,
  ]);
};
import { useState, useCallback, useRef } from 'react';
import Popper from '@mui/material/Popper';
import { makeStyles } from '@mui/styles';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useGetCommentsForHighlight } from '@containers/Transcript';
import { useToggle } from '@utils/hooks';
import { Alert } from 'components/Modal/Alert';
import { FloatingWrapper } from 'components/Remirror';
import { HighlightTooltip } from './Tooltip.Highlight';
import {
  useBlurHighlight,
  useHighlightTooltipPositioner,
  useHelpers,
  useRemoveHighlight as useRemoveHighlightMutation,
} from './hooks';

export const HighlightTooltipContainer = () => {

  const position = useHighlightTooltipPositioner();
  const blurHighlight = useBlurHighlight();

  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  const containerRef = useRef<HTMLDivElement>();
  const classes = useStyles();
  const {
    handleInitialRemoveHighlightClick,
    handleRemoveHighlight,
    showRemoveHighlightAlert,
    toggleRemoveHighlightAlert,
  } = useRemoveHighlight();

  return (
    <>
      <FloatingWrapper
        containerRef={containerRef}
        position={position}>
        <Popper
          id="highlight-popper"
          className={classes.popper}
          container={containerRef.current}
          placement="top"
          open={position.visible}
          anchorEl={position.element}
          modifiers={[
            {
              name: 'arrow',
              options: {
                element: arrowRef,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 15],
              },
            },
          ]}>
          <ClickAwayListener
            mouseEvent={showRemoveHighlightAlert ? false : 'onMouseDown'}
            touchEvent={showRemoveHighlightAlert ? false : 'onTouchStart'}
            onClickAway={blurHighlight}>
            <HighlightTooltip
              arrow={{
                className: classes.arrow,
                ref: setArrowRef,
              }}
              onRemove={handleInitialRemoveHighlightClick} />
          </ClickAwayListener>
        </Popper>
      </FloatingWrapper>
      <Alert
        open={showRemoveHighlightAlert}
        message="This highlight has comments attached to it, are you sure you want to remove it?"
        onClose={toggleRemoveHighlightAlert}
        onConfirm={handleRemoveHighlight} />
    </>
  );
};

// TODO: handle other placement values
const useStyles = makeStyles(theme => {
  const bgColor = `var(--pri-01)`;
  const borderColor = `var(--gray-l)`;

  const borderWidth = 1;
  const size = 10;

  const borderSize = size + Math.round(borderWidth * Math.sqrt(2));

  return {
    popper: {
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        '&::before': {
          top: '100%',
          left: '50%',
          borderTopColor: borderColor,
          marginLeft: `-${borderSize}px`,
        },
        '&::after': {
          top: '100%',
          left: '50%',
          borderTopColor: bgColor,
          marginLeft: `-${size}px`,
        },
      },
    },
    arrow: {
      position: 'absolute',
      background: bgColor,
      border: `${borderWidth}px solid transparent`,
      '&::before': {
        border: 'solid transparent',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
        borderWidth: `${borderSize}px`,
      },
      '&::after': {
        border: 'solid transparent',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
        borderWidth: `${size}px`,
      },
    },
  };
});

const useRemoveHighlight = () => {

  const [showRemoveHighlightAlert, toggleRemoveHighlightAlert] = useToggle();

  const { getFocusedHighlight } = useHelpers();
  const getComments = useGetCommentsForHighlight();

  const {
    mutateAsync: removeHighlight,
  } = useRemoveHighlightMutation({
    onSuccess: () => {
      if (showRemoveHighlightAlert) {
        toggleRemoveHighlightAlert();
      }
    },
  });

  const handleRemoveHighlight = useCallback(() => {
    const highlight = getFocusedHighlight();
    removeHighlight({ highlight });
  }, [
    getFocusedHighlight,
    removeHighlight,
  ]);

  const handleInitialRemoveHighlightClick = useCallback(() => {
    const highlight = getFocusedHighlight();
    const comments = getComments(highlight.id);

    if (comments.length) {
      toggleRemoveHighlightAlert();
    } else {
      handleRemoveHighlight();
    }
  }, [
    getComments,
    getFocusedHighlight,
    handleRemoveHighlight,
    toggleRemoveHighlightAlert,
  ]);

  return {
    handleInitialRemoveHighlightClick,
    handleRemoveHighlight,
    showRemoveHighlightAlert,
    toggleRemoveHighlightAlert,
  } as const;

};
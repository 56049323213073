import { createNamedContext } from '@utils';
import { WorkspaceFile } from '@/types';

export type FileActionsContextValue = {
  changeOwner: () => unknown;
  delete: () => unknown;
  download: () => Promise<unknown>;
  manageAccess: () => unknown;
  move: () => unknown;
  rename: () => unknown;
};

export type FileMenuItemsContextValue = {
  canChangeOwner: boolean;
  canDelete: boolean;
  canDownload: boolean;
  canManageAccess: boolean;
  canMove: boolean;
  canRename: boolean;
};

export type RenameFileContextValue = {
  file: WorkspaceFile;
  rename: (name: string) => Promise<unknown>;
};

type DeleteFile = () => Promise<unknown>;
type DownloadFile = () => Promise<unknown>;

export const DeleteFileContext = createNamedContext<DeleteFile>(undefined, 'delete-file');
export const DownloadFileContext = createNamedContext<DownloadFile>(undefined, 'download-file');
export const FileActionsContext = createNamedContext<FileActionsContextValue>(undefined, 'file-actions');
export const FileMenuItemsContext = createNamedContext<FileMenuItemsContextValue>(undefined, 'file-menu-items');
export const RenameFileContext = createNamedContext<RenameFileContextValue>(undefined, 'rename-file');
import { useCallback, useState } from 'react';
import { isValidEmail } from '@utils';
import { ConnectAccountProvider } from 'components/ConnectAccount';
import { ProjectInviteReferralEmailContext } from './Context';

type Props = {
  children: React.ReactNode;
};

const ProjectInviteReferralEmail = (props: Props) => {
  const [addresses, setAddresses] = useState<string[]>([]);
  const [message, setMessage] = useState<string>('');

  const addAddress = useCallback((value: string, cb?: (addresses: string[]) => void) => {
    if (isValidEmail(value) && !addresses.includes(value)) {
      const updatedAddresses = [ ...addresses, value ];
      setAddresses(updatedAddresses);
      cb?.(updatedAddresses);
    } else {
      cb?.(addresses);
    }
  }, [
    addresses,
    setAddresses,
  ]);

  const clear = useCallback(() => {
    setAddresses([]);
    setMessage('');
  }, [
    setAddresses,
    setMessage,
  ]);

  const removeAddress = useCallback((value: string) => {
    setAddresses(addresses.filter(x => x !== value));
  }, [
    addresses,
    setAddresses,
  ]);

  const context = {
    addAddress,
    clear,
    data: {
      addresses,
      message,
    },
    removeAddress,
    setMessage,
  };

  return (
    <ProjectInviteReferralEmailContext.Provider value={context}>
      {props.children}
    </ProjectInviteReferralEmailContext.Provider>
  );
};

export const Container = (props: Props) => {

  return (
    <ConnectAccountProvider>
      <ProjectInviteReferralEmail>
        {props.children}
      </ProjectInviteReferralEmail>
    </ConnectAccountProvider>
  );
};

Container.displayName = 'ProjectInviteReferral.Email.Container';
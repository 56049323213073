import { useContext } from 'react';
import { filterResponses } from '@containers/GroupProject/utils/survey-filtering';
import { ProjectSurveyResponsesContext } from '@containers/SurveyResponses/Context';
import { ProjectResponseFilterContext } from '@containers/GroupProject/Context';

const useProjectSurveyResponses = () => {
  const responses = useContext(ProjectSurveyResponsesContext);
  const filterContext = useContext(ProjectResponseFilterContext);

  if (filterContext?.filter) {
    return filterResponses(filterContext.filter, filterContext.questionData, responses);
  }

  return responses;
};

export { useProjectSurveyResponses };
export default useProjectSurveyResponses;
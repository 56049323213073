import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BasicRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { ConferenceCompleted } from '@screens/Conference.Completed';
import { ConferenceEntry, ConferenceNotFound } from '@screens/Conference.Entry';
import { ConferenceEntryJoin } from '@screens/Conference.Entry.Join';
import { ConferenceRoom } from '@screens/Conference.Room';

// todo: support callIds in path for old paths '/conference/callId'

export const routes: Routing.Route[] = [
  {
    exact: true,
    component: ConferenceEntryJoin,
    path: path.Conference.Join,
  },
  {
    exact: true,
    component: ConferenceRoom,
    path: path.Conference.Room,
  },
  {
    exact: true,
    path: path.Conference.NotFound,
    component: ConferenceNotFound,
  },
  {
    exact: true,
    path: path.Conference.Completed,
    component: ConferenceCompleted,
  },
  // keep last
  {
    component: ConferenceEntry,
    path: path.Conference.Entry,
  },
];

export const router = BasicRouter(routes, {
  auth: RouteAuth.Ignore,
  path: path.Conference.Root,
});
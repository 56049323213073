import { useSelector } from 'react-redux';
import { Call } from '@/types';
import { Button } from 'components/Button';
import { ButtonSet } from 'components/Modal/ButtonSet';
import { Prompt } from 'components/Modal/Prompt';
import { useCancelCall } from './useCancelCall';
import styles from './style/CallItem.css';

const mapState = (state: Store.State) => ({
  contacts: state.contacts,
});

type Props = {
  item: Call;
  onClose: () => unknown;
};

const CancelCallAlert = (props: Props) => {
  const { contacts } = useSelector(mapState);
  const onCancelCall = useCancelCall();

  function handleCancel() {
    return props.onClose();
  }

  function handleConfirm() {
    return onCancelCall({ callId: props.item.id })
      .then(props.onClose);
  }

  const user = contacts[props.item.userId];
  const header = `Cancel Call with ${user.profile.fullname}?`;
  const body = 'Vancery will notify the expert that the call has been cancelled upon confirmation.';

  const footer = (
    <ButtonSet>
      <Button
        color="destructive"
        className={styles.btn}
        onClick={handleCancel}
        variant="brick">
        Cancel
      </Button>
      <Button
        color="primary"
        className={styles.btn}
        onClick={handleConfirm}
        variant="brick">
        Confirm
      </Button>
    </ButtonSet>
  );

  return (
    <Prompt
      body={body}
      footer={footer}
      header={header}
      onClose={props.onClose}
      visible={true} />
  );
};

export default CancelCallAlert;
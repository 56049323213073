import { useCallback } from 'react';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { ButtonSet } from 'components/Modal/ButtonSet';
import { useModal } from 'components/Modal/hooks';
import { Alert, useAlert } from 'components/Modal/Alert';
import { ModalProps } from 'components/Modal/Modal';
import { Prompt } from 'components/Modal/Prompt';
import { Button } from 'components/Button';

type Props = {
  isSubmitting: boolean;
  onSubmit: () => unknown;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const DeleteCommentAlert = ({ isSubmitting, open, onClose, onSubmit }: Props) => {

  return (
    <Alert
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
      message="Are you sure you want to remove this comment?"
      confirmText="Remove" />
  );
};

export const useDeleteCommentAlert = () => useModal(DeleteCommentAlert);
import { Button } from 'components/Button';
import { BillingDoneBaseProps } from './interfaces';
import styles from './style/Done.css';

type Props = BillingDoneBaseProps;

export const GettingStarted = ({ navigation, nextLabel }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>{`Thank you, you're all set to receive payouts!`}</h1>
        <div className={styles.copy}>{`Your payout preferences can be managed in the billing section of your account settings.`}</div>
        <div className={styles.navigation}>
          <Button
            className={styles.next}
            variant="brick"
            title={nextLabel}
            onClick={navigation.next} />
        </div>
      </div>
    </div>
  );
};

GettingStarted.defaultProps = {
  nextLabel: 'Complete',
};
import { Role } from '@enums';

export const roles: RolesMap = {
  admin: [
    Role.InternalAdmin,
  ],
  analyst: [
    Role.FirmAnalyst,
  ],
  client: [
    Role.FirmAnalyst,
    Role.FirmCompliance,
  ],
  compliance: [
    Role.FirmCompliance,
  ],
  consultant: [
    Role.Expert,
  ],
  transient: [
    Role.FirmTransient,
  ],
  recruiter: [
    Role.Recruiter,
  ],
};

type RolesMap = {
  [key: string]: Role[];
};

type HasRole = (user: Pick<Store.User, 'roles'>) => boolean;

export const hasInternalAdminRole: HasRole = user => {
  return user.roles.some(r => roles.admin.includes(r));
};

export const hasAnalystRole: HasRole = user => {
  return user.roles.some(r => roles.analyst.includes(r));
};

export const hasClientRole: HasRole = user => {
  return user.roles.some(r => roles.client.includes(r));
};

export const hasComplianceRole: HasRole = user => {
  return user.roles.some(r => roles.compliance.includes(r));
};

export const hasConsultantRole: HasRole = user => {
  return user.roles.some(r => roles.consultant.includes(r));
};

export const hasTransientRole: HasRole = user => {
  return user.roles.some(r => roles.transient.includes(r));
};

export const hasRecruiterRole: HasRole = user => {
  return user.roles.some(r => roles.recruiter.includes(r));
};

type HasChatAccess = (user: Pick<Store.User, 'roles'>, group: Pick<Store.Group, 'features'>) => boolean;

export const hasChatAccess: HasChatAccess = (user, group) => {
  return hasConsultantRole(user)
    ? true
    : !group.features.disableMessaging;
};
import { createElement, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useResolvedProjectInvite  } from '@containers/ProjectLinkInvitation/hooks/useResolvedProjectInvite';
import { ProjectType } from '@enums';
import { useSignupPing } from '$website/containers/Auth/hooks/useSignupPing';
import { Signup } from '$website/screens/Auth';
import { SurveyInvite } from '$website/screens/SurveyInvite';

type Props = RouteComponentProps;

const SignupRouter = (props: Props) => {
  const params = useResolvedProjectInvite();
  const ping = useSignupPing();

  useEffect(() => {

    ping();

  }, [ping]);

  if (params?.token) {
    return ProjectType.Survey === params?.data?.project?.projectType
        ? createElement(SurveyInvite)
        : createElement(Signup.GeneralInvite);
  }

  return createElement(Signup.Visitor);
};

SignupRouter.displayName = 'Router.Signup';

export { SignupRouter as Signup };
import { useMemo } from 'react';
import { QuestionResponses } from 'components/SurveyResponses/QuestionResponses';
import { SurveyQuestionResponsesContext } from './Context';
import { useActiveQuestion } from './hooks/useActiveQuestion';
import { useProjectSurveyResponses } from './hooks/useProjectSurveyResponses';

export const QuestionResponsesContainer = () => {
  const question = useActiveQuestion();
  const allResponses = useProjectSurveyResponses();

  const value = useMemo(() => {
    const r = allResponses?.[question.base.id];
    return {
      total: r?.total || 0,
      question,
      responses: r?.data,
    };
  }, [
    allResponses,
    question,
  ]);

  return (
    <SurveyQuestionResponsesContext.Provider value={value}>
      <QuestionResponses />
    </SurveyQuestionResponsesContext.Provider>
  );
};

export default QuestionResponsesContainer;
import { SurveyTemplateType } from '@enums/survey.template';
import {
  SurveyTemplate,
  SurveyVersion,
} from '@/types/survey';
import * as competitiveIntelligence from './template.ci';
import * as custom from './template.custom';
import * as employeeSatisfaction from './template.employee-satisfaction';
import * as netPromoterScore from './template.nps';
import * as personaResearch from './template.persona-research';
import * as pricingStrategy from './template.pricing-strategy';
import * as marketingCampaignEffectiveness from './template.mce';

export {
  competitiveIntelligence,
  custom,
  employeeSatisfaction,
  netPromoterScore,
  personaResearch,
  pricingStrategy,
  marketingCampaignEffectiveness,
};

export const getInitialTemplateData = (template: SurveyTemplateType): SurveyTemplate.Data => {

  switch (template) {
    case SurveyTemplateType.CompetitiveIntelligence:
      return competitiveIntelligence.defaultTemplateData;

    case SurveyTemplateType.PersonaResearch:
      return personaResearch.defaultTemplateData;

    case SurveyTemplateType.NetPromoterScore:
      return netPromoterScore.defaultTemplateData;

    case SurveyTemplateType.EmployeeSatisfaction:
      return employeeSatisfaction.defaultTemplateData;

    case SurveyTemplateType.PricingStrategy:
      return pricingStrategy.defaultTemplateData;

    case SurveyTemplateType.MarketingCampaignEffectiveness:
      return marketingCampaignEffectiveness.defaultTemplateData;

    default:
      return custom.defaultTemplateData;
  }
};

export const getIntialSurvey = (template: SurveyTemplateType, data: SurveyTemplate.Data): SurveyVersion => {
  switch (template) {
    case SurveyTemplateType.CompetitiveIntelligence:
      return competitiveIntelligence.generateSurveyData(data as SurveyTemplate.CompetitiveIntelligenceTemplate);

    case SurveyTemplateType.PersonaResearch:
      return personaResearch.generateSurveyData(data as SurveyTemplate.PersonaResearchTemplate);

    case SurveyTemplateType.NetPromoterScore:
      return netPromoterScore.generateSurveyData(data as SurveyTemplate.NetPromoterScore);

    case SurveyTemplateType.EmployeeSatisfaction:
      return employeeSatisfaction.generateSurveyData(data as SurveyTemplate.EmployeeSatisfaction);

    case SurveyTemplateType.PricingStrategy:
      return pricingStrategy.generateSurveyData(data as SurveyTemplate.PricingStrategy);

    case SurveyTemplateType.MarketingCampaignEffectiveness:
      return marketingCampaignEffectiveness.generateSurveyData(data as SurveyTemplate.MarketingCampaignEffectiveness);

    default:
      return custom.initialSurveyData;
  }
};
import { useCallback, useMemo } from 'react';
import { SurveyQuestionType } from '@enums';
import { NumberInputTableQuestion } from '@/types';
import { useSurveyResponseGoBack, useSubmitAnswer, useSurveyStepperNavigation, useSurveyFormQuestionContext } from './hooks';
import { NumberTableWarningsContext, SurveyFormNavigationContext } from './Context';
import { useNumberTableValidation } from './hooks';

type WarningsProps = {
  item: NumberInputTableQuestion.Question;
} & ChildrenProps;

export const NumberInputTableWarningsContainer = ({ children, item }: WarningsProps) => {

  const [validations] = useNumberTableValidation({ item });

  return (
    <NumberTableWarningsContext.Provider value={[validations]}>
      {children}
    </NumberTableWarningsContext.Provider>
  );
};

type Props =
  ChildrenProps;

export const NumberInputTableFormContainer = (props: Props) => {
  const {
    answer,
    question,
  } = useSurveyFormQuestionContext<SurveyQuestionType.NumberInputTable>();

  const {
    isError: isSubmitError,
    isLoading: isSubmitting,
    mutateAsync: submitAnswer,
  } = useSubmitAnswer();
  const goBack = useSurveyResponseGoBack();
  const navigation = useSurveyStepperNavigation(question.ordinal);
  const [validations, runValidations] = useNumberTableValidation({ item: question });

  const handleSubmit = useCallback(() => {
    const isValid = runValidations(answer);

    if (isValid) {
      return submitAnswer();
    }
  }, [
    answer,
    runValidations,
    submitAnswer,
  ]);

  const canSubmit = useMemo(() => {
    if (!answer?.items) return false;

    return true;

  }, [
    answer,
  ]);

  const value = {
    back: {
      hidden: !navigation.showBack,
      isError: goBack.isError,
      isLoading: goBack.isLoading,
      submit: goBack.mutateAsync as () => Promise<unknown>,
    },
    next: {
      disabled: !canSubmit,
      isError: isSubmitError,
      isLoading: isSubmitting,
      submit: handleSubmit,
    },
    showComplete: navigation.showComplete,
  };

  return (
    <SurveyFormNavigationContext.Provider value={value}>
      <NumberTableWarningsContext.Provider value={[validations]}>
        {props.children}
      </NumberTableWarningsContext.Provider>
    </SurveyFormNavigationContext.Provider>
  );
};
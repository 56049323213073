import { cx, getLocationFor } from '@utils';
import { MaybeLink } from 'components/MaybeLink';
import { UserAvatar } from 'components/UserAvatar';
import styles from './style/ConsultantAvatar.css';

type Props = {
  className?: string;
  name:       string;
  slug?:      string;
  src:        string;
  size:       number;
};

const ConsultantAvatar = (props: Props) => {

  const location = props.slug
     && getLocationFor.user.profile({ slug: props.slug });

  return (
    <div className={cx(styles.root, props.className)}>

      <MaybeLink to={location}>
        <UserAvatar
          pictureUrl={props.src}
          size={props.size} />
      </MaybeLink>

      <MaybeLink
        className={styles.link}
        to={location}>
        <div className={styles.name}>{props.name}</div>
      </MaybeLink>
    </div>
  );
};

const defaultProps = {
  size: 50,
};

ConsultantAvatar.defaultProps = defaultProps;

export { ConsultantAvatar };
export default ConsultantAvatar;
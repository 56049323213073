import { useCallback } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { QuestionRowsBuilderContainer } from '@containers/SurveyBuilder.Question/RowsBuilderContainer';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import { MatrixGridQuestion } from '@/types';
import { CheckboxLabel } from 'components/Checkbox';
import QuestionColumnsBuilder from './ColumnsBuilder';
import { ResponsePiping } from './ResponsePiping';
import styles from './style/MatrixGrid.Question.css';

type Props = {
  item: MatrixGridQuestion.Question;
};

export const MatrixGridQuestionBuilder = ({ item }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const toggleNotApplicable = useCallback((_, checked: boolean) => {
    dispatch({
      type: 'toggle-not-applicable',
      questionIdentifier: item.base.identifier,
      value: checked,
    });

  }, [
    dispatch,
    item.base.identifier,
  ]);

  return (
    <>
      <QuestionSettingTitle title="Rows">
        <ResponsePiping type="rows" />
      </QuestionSettingTitle>
      <QuestionRowsBuilderContainer
        questionIdentifier={item.base.identifier}
        rows={item.matrixRows}
        className={styles.rows}
        maxRows={surveyBuilder.matrixGrid.MaxRows}
        minRows={surveyBuilder.matrixGrid.MinRows} />

      <QuestionSettingTitle title="Columns">
        <ResponsePiping />
      </QuestionSettingTitle>
      <QuestionOptionsBuilderContainer
        className={styles.options}
        Component={QuestionColumnsBuilder}
        item={item}
        maxOptions={surveyBuilder.matrixGrid.MaxColumns}
        minOptions={surveyBuilder.matrixGrid.MinColumns} />
      <CheckboxLabel
        className={styles.checkbox}
        checked={item.settings.includeNotApplicable}
        label="Include N/A as choice"
        onChange={toggleNotApplicable} />
    </>
  );
};

export default MatrixGridQuestionBuilder;
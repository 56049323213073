import { useCallback, useState } from 'react';
import { Product } from '@/types';
import { ProfileEntry } from '@enums';
import { FormButtons } from '@presentation/FormButtons';
import ProductAutocomplete from 'components/AutoComplete/Product';
import Tags from './Profile.Tags.Form';
import styles from './style/Profile.Products.css';
import { Section } from './interfaces';

type Props =
  Section.SingleEntry.FormProps<ProfileEntry.Product>;

function mapProducts(items: Product[]): Item[] {
  return items.map(p => ({ id: p.id, name: p.name, isCustom: false }));
}

export const ProfileProductsForm = ({ onSave, ...props }: Props) => {

  const [products, setProducts] = useState<Item[]>(mapProducts(props.value));

  const handleSave = useCallback(() => {
    return onSave({
      items: products,
    });
  }, [
    products,
    onSave,
  ]);

  const addItem = useCallback((value: Item) => {
    setProducts(s => [
      ...s,
      value,
    ]);
  }, []);

  const removeItem = useCallback((value: Item) => () => {
    setProducts(s => s.filter(s => s.id !== value.id));
  }, []);

  return (
    <>
      <div className={styles.autocomplete}>
        <ProductAutocomplete
          ignoreList={products}
          onSelect={addItem} />
      </div>
      <Tags
        className={styles.tags}
        onClick={removeItem}
        items={products} />
      <FormButtons
        onSubmit={handleSave}
        onCancel={props.onCancel} />
    </>
  );
};

type Item = {
  isCustom: boolean;
} & Product;

export default ProfileProductsForm;
import { useContext } from 'react';
import { AggregateFilterDispatchContext } from '@containers/ComplianceReview/Context';
import { ComplianceReview } from '@containers/ComplianceReview/interfaces';

const useAggregateReviewPageDispatch = () => {
  const dispatch = useContext(AggregateFilterDispatchContext);

  const dispatchPageChange = (index: number) => {
    dispatch({
      data: { index },
      type: 'page',
    });
  };

  return dispatchPageChange;
};

export { useAggregateReviewPageDispatch };
export default useAggregateReviewPageDispatch;
import { Cell } from 'react-table';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DownloadFileContainer } from '@containers/WorkspaceFiles/DownloadFileContainer';
import { FileMenuContainer } from '@containers/WorkspaceFiles/FileMenuContainer';
import { ObjectAccessContainer } from '@containers/ObjectAccess';
import { useSelectObject } from '@containers/Store';
import { getLocationFor } from '@utils';
import { FileIcon } from 'components/icons';
import { FileActionsContextMenuPopper } from 'components/WorkspaceFiles/FileActionsContextMenu';
import { FilesTableItem } from './interfaces';
import styles from './style/FilesTableCells.css';

function NameCell({ row: { original: data } }: Cell<FilesTableItem>) {

  const to = getLocationFor.workspaces.file({
    fileId: data.id,
  });

  return (
    <div className={styles.name}>
      <FileIcon
        className={styles.icon}
        extension={data.extension} />
      <Link className={styles.link} to={to}>
        <div className={styles.trunc}>{data.name}</div>
      </Link>
    </div>
  );
}

function OwnerCell({ row: { original: data } }: Cell<FilesTableItem>) {
  return data.owner;
}

function CreatedOnCell({ row: { original: data } }: Cell<FilesTableItem>) {
  return format(data.createdOn, 'MM/dd/yyyy');
}

function ActionsCell({ row: { original: data } }: Cell<FilesTableItem>) {
  const object = useSelectObject(data.objectId);

  return (
    <ObjectAccessContainer objectId={object.id} workspaceId={object.workspaceId}>
      <DownloadFileContainer fileId={data.id}>
        <FileMenuContainer fileId={data.id}>
          <FileActionsContextMenuPopper />
        </FileMenuContainer>
      </DownloadFileContainer>
    </ObjectAccessContainer>
  );
}

export default {
  Actions: ActionsCell,
  CreatedOn: CreatedOnCell,
  Name: NameCell,
  Owner: OwnerCell,
};
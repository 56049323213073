import { useMemo, useCallback, useState, useEffect, createContext, useContext } from 'react';
import * as api from '@api';
import { useAppReadyState } from '@containers/AppReadyState';
import { SurveyFormContext } from '@containers/SurveyForm';
import { useStepper } from '@utils';
import { SurveyFormVersion } from '@/types';
import Modal from 'components/Modal/Modal.Legacy';
import RatingsStepper from './RatingsStepper';
import QuestionnareStepper from './QuestionnareStepper';
import {
  CallFeedbackStepperContextValue,
} from './interfaces';

const CallFeedbackStepperContext = createContext<CallFeedbackStepperContextValue>(null!);

const CallFeedbackQuestionnareStepper = () => {
  const ctx = useContext(CallFeedbackStepperContext);

  return (
    <QuestionnareStepper
      onCompletion={ctx.onCompletion}
      callIds={ctx.questionnareItems} />
  );
};

const CallFeedbackRatingsStepper = () => {
  const ctx = useContext(CallFeedbackStepperContext);

  const handleCompletion = () => {
    if (ctx.questionnareItems.length) {
      ctx.next();
    } else {
      ctx.onCompletion();
    }
  };

  return (
    <RatingsStepper
      callIds={ctx.ratingItems}
      onCompletion={handleCompletion} />
  );
};

const CallFeedbackModal = () => {
  const { initialized } = useAppReadyState();
  const [completed, setCompleted] = useState<boolean>(false);
  const [ratingItems, setRatingItems] = useState<number[]>([]);
  const [questionnareItems, setQuestionnareItems] = useState<number[]>([]);
  const [survey, setSurvey] = useState<SurveyFormVersion>(null);

  const hide = useMemo(() => {
    return !initialized ||
    completed ||
    (!ratingItems.length && !questionnareItems.length);
  }, [
    completed,
    initialized,
    questionnareItems.length,
    ratingItems.length,
  ]);

  const fetchQuestionnareItems = useCallback(() => {
    return api.users.getOutstandingPostCallQuestionnares();
  }, []);

  useEffect(() => {
    Promise.all([
      api.users.getCallsToRate(),
      fetchQuestionnareItems(),
    ]).then(result => {
      const [r, q] = result;
      setSurvey(q.survey);
      setRatingItems(r);
      setQuestionnareItems(q.ids);
    });
  }, [fetchQuestionnareItems]);

  const steps = useMemo(() => {
    const steps = [];
    if (ratingItems.length > 0) {
      steps.push(CallFeedbackRatingsStepper);
    }

    if (questionnareItems.length > 0) {
      steps.push(CallFeedbackQuestionnareStepper);
    }

    return steps as React.ComponentType[];
  }, [questionnareItems.length, ratingItems.length]);

  const [Screen, actions] = useStepper(steps);

  const handleCompletion = useCallback(() => {
    setCompleted(true);
  }, []);

  if (hide) {
    return null;
  }

  const context: CallFeedbackStepperContextValue = {
    back: actions.back,
    next: actions.next,
    onCompletion: handleCompletion,
    ratingItems,
    questionnareItems,
  };

  return (
    <Modal
      blocking={true}
      visible={true}>
      <SurveyFormContext.Provider value={survey}>
        <CallFeedbackStepperContext.Provider value={context}>
          <Screen />
        </CallFeedbackStepperContext.Provider>
      </SurveyFormContext.Provider>
    </Modal>
  );
};

const CallFeedback = () => {
  const { authenticated } = useAppReadyState();

  return authenticated && <CallFeedbackModal />;
};

export { CallFeedback };
export default CallFeedback;
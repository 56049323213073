import { useContext } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import { cx, createNamedContext } from '@utils';
import { RadioButton } from 'components/Radio/RadioButton';
import { SetupPayoutOptionProps, SetupPayoutOptionValue } from './interfaces';
import styles from './style/Setup.css';

type Props =
    SetupPayoutOptionProps;

export default function SetupPayoutOption(props: Props) {
  const value = {
    available: props.available,
    value: props.value,
    onChange: props.onChange,
  };

  return (
    <SetupPayoutOptionContext.Provider value={value}>
      <div className={styles.step}>
        <div className={styles.stepLabel}>Step 2: Payout Options</div>
        <div className={styles.stepContent}>
          <RadioGroup
            value={props.value}
            onChange={(_, value: SetupPayoutOptionValue) => props.onChange(value)}>
            <Stripe />
            <PayPal />
            {/* <Check /> */}
          </RadioGroup>
        </div>
      </div>
    </SetupPayoutOptionContext.Provider>
  );
}

function Stripe() {
  const { available, value, onChange } = useContext(SetupPayoutOptionContext);

  if (!available.includes('stripe')) return null;

  const className = cx({
    [styles.paymentOption]: true,
    [styles.paymentOptionSelected]: value === 'stripe',
  });

  return (
    <>
      <div className={className} onClick={() => onChange('stripe')}>
        <RadioButton value="stripe" />
        <div className={styles.paymentOptionLabel}>Debit Card or Bank Account (via Stripe)</div>
      </div>
      <Divider />
    </>
  );
}

function Divider() {
  const { available } = useContext(SetupPayoutOptionContext);

  if (available.length === 1) return null;

  return <div className={styles.divider} />;
}

// function Check() {
//   const { available, value, onChange } = useContext(SetupPayoutOptionContext);

//   if (!available.includes('check')) return null;

//   const className = cx({
//     [styles.paymentOption]: true,
//     [styles.paymentOptionSelected]: value === 'check',
//   });

//   return (
//     <>
//       <div className={className} onClick={() => onChange('check')}>
//         <RadioButton value="check" />
//         <div className={styles.paymentOptionLabel}>Check Payment</div>
//       </div>
//       <Divider />
//     </>
//   );
// }

function PayPal() {
  const { available, value, onChange } = useContext(SetupPayoutOptionContext);

  if (!available.includes('paypal')) return null;

  const className = cx({
    [styles.paymentOption]: true,
    [styles.paymentOptionSelected]: value === 'paypal',
  });

  return (
    <>
      <div className={className} onClick={() => onChange('paypal')}>
        <RadioButton value="paypal" />
        <div className={styles.paymentOptionLabel}>PayPal</div>
      </div>
      <Divider />
    </>
  );
}

const SetupPayoutOptionContext = createNamedContext<SetupPayoutOptionContextValue>(undefined, 'SetupPayoutOption.Context');

type SetupPayoutOptionContextValue =
  Pick<SetupPayoutOptionProps, 'available' | 'value' | 'onChange'>;

export { SetupPayoutOption };
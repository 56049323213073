import compose from 'static/images/website/q2q/icon-programming-tools.svg?url';
import bubble from 'static/images/website/q2q/icon-qual-feedback.svg?url';
import branch from 'static/images/website/q2q/icon-survey-branching.svg?url';
import charts from 'static/images/website/q2q/icon-interactive-charts.svg?url';
import timeseries from 'static/images/website/q2q/icon-time-series.svg?url';
import presentation from 'static/images/website/q2q/icon-presentation.svg?url';
import styles from './style/SurveyFeatures.css';

type Props = unknown;

export const SurveyFeatures = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>
          Survey Features
        </h2>
        <div className={styles.main}>
          {items.map((x, i) =>
            <div
              className={styles.item}
              key={`icon-${i}`}>
              <img
                alt={x.title}
                className={styles.img}
                src={x.src} />
              <div className={styles.title}>{x.title}</div>
              <div className={styles.text}>{x.text}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SurveyFeatures.displayName = 'Quant2Qual.SurveyFeatures';

const items = [{
  src: compose,
  text: `Vancery’s survey programming tools allow researchers to build robust surveys on demand.`,
  title: `Programming Tools`,
}, {
  src: bubble,
  text: `Collect qualitative data in the form of short and long text responses.`,
  title: `Quotes`,
}, {
  src: branch,
  text: `Create sections for your survey that are dynamically displayed to respondents based on the answers they provide.`,
  title: `Branching & Skip Logic`,
}, {
  src: charts,
  text: `Use Vancery’s interactive data visualizations to click into segments of interest and find individuals that would be worth speaking to.`,
  title: `Interactive Charts`,
}, {
  src: timeseries,
  text: `Utilize time series surveys to track historical trends and forecast customer behaviors in the future.`,
  title: `Time Series`,
}, {
  src: presentation,
  text: `Export data from surveys into pre-formatted presentations for your including CSV, SPSS, and PPT formats.`,
  title: `Presentation Exports`,
}];
import { SurveyQuestionType } from '@enums/Survey';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { useQuestionBuilderItem } from '@containers/SurveyBuilder.Question/Context';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import { MultipleChoiceQuestion } from '@/types';
import { ResponsePiping } from './ResponsePiping';

type Props = {
  item: MultipleChoiceQuestion.Question;
};

export const MultipleChoiceQuestionBuilder = (props: Props) => {
  const item = useQuestionBuilderItem<SurveyQuestionType.MultipleChoice>();

  return (
    <>
      <QuestionSettingTitle title="Options">
        <ResponsePiping />
      </QuestionSettingTitle>
      <QuestionOptionsBuilderContainer
        item={item}
        maxOptions={surveyBuilder.multipleChoice.MaxOptions}
        minOptions={surveyBuilder.multipleChoice.MinOptions} />
    </>
  );
};

export default MultipleChoiceQuestionBuilder;
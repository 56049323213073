import { TestItem } from './interfaces';

const items: TestItem[] = [
  {
    question: `Under what circumstances can you share confidential information about your employer?`,
    choices: [
      `Confidential information can be shared as long as it is not Material Non-Public Information.`,
      `Confidential information can be shared as long as the client agrees not to trade securities on the basis of this information.`,
      `Confidential information about your employer cannot be shared under any circumstances.`,
    ],
    answer: 2,
  },
  {
    question: `What is Material Information?`,
    choices: [
      `Material Information is information for which there is a likelihood that someone might consider it important in making an investment decision.`,
      `All non-public information regarding a public company is Material Information regardless of potential influence on a the company’s security prices.`,
      `Material Information is information that is certain and definite.`,
    ],
    answer: 0,
  },
  {
    question: `What is Non Public Information?`,
    choices: [
      `Information regarding a company is non-public until it has been effectively communicated to the marketplace in which the issuer operates its business.`,
      `Non Public Information is information that has not been addressed in a company’s most recent earnings call.`,
      `Non Public Information is information that has not been featured on the front page of at least one newspaper.`,
    ],
    answer: 0,
  },
  {
    question: `When can you disclose information that is Material Non Public Information ("MNPI")?`,
    choices: [
      `Only in calls where the Client has promised not to trade securities based on the information.`,
      `Only in calls where the Client is not employed within the financial services industry.`,
      `Only when the information is either no longer Material or Non-Public and disclosure is otherwise permitted.`,
    ],
    answer: 2,
  },
  {
    question: `When can a government employee or official share governmental information?`,
    choices: [
      `Government information can be shared as long as the government official is not under a duty to keep the information confidential.`,
      `Government information can be shared as long as it is not MNPI.`,
      `Government information can be shared by a government employee only if both of the above are true.`,
    ],
    answer: 2,
  },
  {
    question: `If you have signed a Non-Disclosure Agreement ("NDA") that requires you not to share information, should you share that information in a consultation as long as it is not Material Non-Public Information?`,
    choices: [
      `Yes.`,
      `No.`,
    ],
    answer: 1,
  },
  {
    question: `As long as you have not signed a written agreement with your employer promising to keep information confidential, can you share any information you know about your employer during a consultation?`,
    choices: [
      `Yes.`,
      `No.`,
    ],
    answer: 1,
  },
  {
    question: `Can you share Material Non-Public Information as long as the client is not charged for the consultation?`,
    choices: [
      `No.`,
      `Yes.`,
    ],
    answer: 0,
  },
];

export default items;
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHasConsultantRole } from '@containers/User';
import { useDisconnectUserAccount } from '@utils/api';
import * as selectors from '@store/selectors';
import {
  useGoogleContactSync,
} from 'components/ConnectAccount/hooks';
import { useAlert } from 'components/Modal/Alert';
import {
  GoogleContacts,
} from 'components/icons';
import { Integration } from 'components/Settings';
import styles from './style/Settings.Integrations.css';

export const GoogleContactsIntegration = () => {

  const [toggleAlert, Alert] = useAlert();

  const connected = useSelector(selectors.selectGoogleContacts);
  const outlookContacts = useSelector(selectors.selectMicrosoftContacts);

  const hasConsultantRole = useHasConsultantRole();
  const { mutateAsync: disconnect } = useDisconnectUserAccount({
    onSuccess: _ => {
      toggleAlert();
    },
  });

  const googleContacts = useGoogleContactSync();

  const handleDisconnect = useCallback(() => {
    if (!connected) return;
    return disconnect({
      id: connected.id,
    });
  }, [connected, disconnect]);

  const description = hasConsultantRole
    ? `Connect your Google contacts`
    : `Connect your Google contacts to quickly provide referrals for your projects.`;

  return (
    <>
      <Integration
        className={styles.item}
        icon={GoogleContacts}
        title={title}
        description={description}
        connected={!!connected}
        disabled={!!outlookContacts}
        loading={googleContacts.isLoading}
        onConnect={googleContacts.connect}
        onDisconnect={toggleAlert} />
      <Alert
        message="Are you sure you want to remove your connected Google contacts?"
        onConfirm={handleDisconnect} />
    </>
  );
};

const title = `Google Contacts Sync`;

export default GoogleContactsIntegration;

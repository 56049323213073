import { cloneElement, useState } from 'react';
import * as enums from '@enums';
import{ format } from 'date-fns';
import Header from 'components/Modal/Header';
import Modal from 'components/Modal/Modal.Legacy';
import { Label } from '@presentation/Label';
import {
  ApprovalDetailsProps,
  ApprovalDetailsModalProps,
  ApprovalDetailsAnchorProps,
} from './interfaces';
import styles from './style.css';

export const SurveyApprovalDetailsModal = (props: ApprovalDetailsModalProps) => {
  const { project, user, onClose, ...detailsProps } = props;
  const title = `${user} ${project} project survey compliance notes`;

  return (
    <Modal
      onClose={onClose}
      visible={true}>
      <ApprovalDetails
        title={title}
        {...detailsProps} />
    </Modal>
  );
};

export const CallApprovalDetailsModal = (props: ApprovalDetailsModalProps) => {
  const { project, user, onClose, ...detailsProps } = props;
  const title = `${user} ${project} project call compliance notes`;

  return (
    <Modal
      onClose={onClose}
      visible={true}>
      <ApprovalDetails
        title={title}
        {...detailsProps} />
    </Modal>
  );
};

export const ApprovalDetails = (props: ApprovalDetailsProps) => {
  const by = `${props.approvedBy} on ${format(new Date(props.approvedOn), 'MM/dd/yyyy')}`;

  return (
    <div className={styles.detailsRoot}>
      <Header
        className={styles.header}
        text={props.title} />
      <Label
        className={styles.label}
        label='By'>
        {by}
      </Label>
      <Label
        className={styles.label}
        label='Approval Decision'>
        {enums.utils.ApprovalStatus.getName(props.statusId)}
      </Label>
      <Label
        className={styles.label}
        label='Compliance Notes' />
      <div>{props.notes}</div>
    </div>
  );
};

export const CallApprovalDetailsAnchor = (props: ApprovalDetailsAnchorProps) => {
  const [showModal, toggleModal] = useState<boolean>(false);

  const anchor = cloneElement(
    props.children,
    { onClick: () => toggleModal(true) },
  );

  return (
    <>
      {anchor}
      {showModal &&
        <CallApprovalDetailsModal
          {...props}
          onClose={() => toggleModal(false)} />}
    </>
  );
};

export const SurveyApprovalDetailsAnchor = (props: ApprovalDetailsAnchorProps) => {
  const [showModal, toggleModal] = useState<boolean>(false);

  const anchor = cloneElement(
    props.children,
    { onClick: () => toggleModal(true) },
  );

  return (
    <>
      {anchor}
      {showModal &&
        <SurveyApprovalDetailsModal
          {...props}
          onClose={() => toggleModal(false)} />}
    </>
  );
};

export default ApprovalDetails;
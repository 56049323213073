import { Redirect } from 'react-router-dom';
import { useMemo, useState } from 'react';
import * as consts from '@consts';
import ProjectTabs from '@screens/GroupProject/ProjectTabs';
import { useSurvey } from '@containers/Survey/hooks/useSurvey';
import { SurveyFiltering } from '@/types';
import { useMatchProjectStateFromUrl, usePreventProjectScrollRestoration, useProjectOrLatestChild, useProjectState } from './hooks';
import { ProjectActionsState } from './ProjectActionsContainer';
import { ProjectStateContext, ProjectResponseFilterContext } from './Context';
import { DataContainer } from './DataContainer';

export const GroupProjectState = (props: ChildrenProps) => {
  const state = useMatchProjectStateFromUrl();

  if (!state.projectParent) return <Redirect to={consts.path.Projects.Root} />;

  return (
    <ProjectStateContext.Provider value={state}>
      {props.children}
    </ProjectStateContext.Provider>
  );
};

const GroupProjectActionsContainer = (props: ChildrenProps) => {
  const state = useProjectState();
  const project = useProjectOrLatestChild();

  const actionOnParent = useMemo(() => !state.project, [state.project]);

  return (
    <ProjectActionsState
      actionOnParent={actionOnParent}
      projectId={project.id}>
      {props.children}
    </ProjectActionsState>
  );
};

const GroupProjectFilter = (props: ChildrenProps) => {

  const survey = useSurvey();
  const [filter, setFilter] = useState<SurveyFiltering.FilterEntries>(
    {
      filters: [],
    }
  );

  const filterContextValue = {
    filter, setFilter,
    questionData: survey?.questions,
  };

  return (
    <ProjectResponseFilterContext.Provider value={filterContextValue}>
      {props.children}
    </ProjectResponseFilterContext.Provider>
  );
};

const GroupProjectContainer = () => {

  usePreventProjectScrollRestoration();

  return (
    <GroupProjectState>
      <DataContainer>
        <GroupProjectFilter>
          <GroupProjectActionsContainer>
            <ProjectTabs />
          </GroupProjectActionsContainer>
        </GroupProjectFilter>
      </DataContainer>
    </GroupProjectState>
  );
};

export { GroupProjectContainer };
export default GroupProjectContainer;
import { useEffect } from 'react';
import { external as $service } from '@services/api/projects';
import { ExternalProjectSecretType } from '@enums';
import { useSelectPayoutSettings } from '@containers/Store';
import { Survey } from '@screens/PayoutSetup/Done';
import { useFetchConsultantReferralSourcingState } from 'components/ProjectInviteReferral/hooks';
import { useParams, useCompleteProject } from './hooks';
import { RedirectWrapper } from './RedirectWrapper';
import { SecretParams } from './interfaces';
import styles from './styles.css';

export const ProjectSuccess = () => {
  const params = useParams(ExternalProjectSecretType.Success);

  return (
    <RedirectWrapper {...params}>
      <ProjectSuccessInner {...params} />
    </RedirectWrapper>
  );
};

const ProjectSuccessInner = (props: SecretParams) => {
  const settings = useSelectPayoutSettings();
  const completeProject = useCompleteProject(props);

  useEffect(() => {
    $service.completeProject(props)
      .then(r => {
        completeProject();
      })
      .catch(e => {
        alert('There was an error saving your project response.');
        throw e;
      });
  }, []); //Don't use effect params here, the useFetchConsultantReferralSourcingState call forces an infinite reload of the component triggering multiple useEffects

  const sourcing = useFetchConsultantReferralSourcingState(props.projectId);

  const hasSourcing = sourcing.data?.referrals;
  return (
    <div className={styles.successContainer}>
      <Survey
        method={settings.method}
        navigation={{
          next: () => { /*TODO: Take us to referral*/ },
          back: () => { },
          skip: () => { },
        }}
        nextLabel={'Back to Home'}
        referrals={hasSourcing} />
    </div>
  );
};
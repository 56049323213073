import { useAggregateReviewMessageActions } from '@containers';
import * as enums from '@enums';
import { ApprovalActions } from './ApprovalActions';
import { ApprovalStatus } from './ApprovalStatus';
import { ComplianceReviewItem } from '@/types';

type Props = {
  item: ComplianceReviewItem<'message'>;
};

const MessageApprovalState = (props: Props) => {
  const actions = useAggregateReviewMessageActions();

  function approve() {
    actions.approve({
      analyst: props.item.analyst,
      consultant: props.item.consultant,
      id: props.item.id,
    });
  }

  function reject() {
    actions.reject({
      analyst: props.item.analyst,
      consultant: props.item.consultant,
      id: props.item.id,
    });
  }

  if (props.item.compliance.approvalStatusId === enums.ApprovalStatus.NeedsApproval) {
    return (
      <ApprovalActions
        onApprove={approve}
        onReject={reject} />
    );
  }

  return (
    <ApprovalStatus item={props.item.compliance} />
  );
};

export { MessageApprovalState };
export default MessageApprovalState;
import { memo } from 'react';
import * as consts from '@consts';
import logo from 'static/images/vancery-landscape-grey.png';
import styles from './style/Footer.css';

const ContactInfo = memo(() => (
  <>
    <div className={styles.logo}>
      <img
        alt="Vancery Grey"
        className={styles.img}
        src={logo} />
    </div>
    <div className={styles.details}>
      <a
        className={styles.link}
        href={consts.vancery.googleMapsLink}
        target="_blank"
        rel="noopener noreferrer">
        <div className={styles.addressLine}>{consts.vancery.addressLine1}, {consts.vancery.addressLine2}</div>
        <div className={styles.addressLineMobile}>{consts.vancery.addressLine1},</div>
        <div className={styles.addressLineMobile}> {consts.vancery.addressLine2}</div>
      </a>
      <div>
        <a
          className={styles.email}
          href={`mailto:${consts.vancery.email}`}>
          {consts.vancery.email}
        </a>
        <span className={styles.separator}> | </span>
        <a
          className={styles.phone}
          href={`tel:${consts.vancery.phoneNumber}`}>
          {consts.vancery.phoneNumberDisplay}
        </a>
      </div>
    </div>
    <div>&copy; {new Date().getFullYear()} Vancery, Inc.</div>
  </>
));

export default ContactInfo;
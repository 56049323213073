import { Reducer, useReducer } from 'react';
import { AccountProfile } from '@containers/AccountProfile/interfaces';

type Params = Partial<AccountProfile.State>;

const useProfileFields = (params: Params = {}) => {

  const [state, dispatch] = useReducer<Reducer<AccountProfile.State, AccountProfile.Action>>(
    fields,
    { ...initial, ...params },
  );

  return [state, dispatch] as const;

};

function fields(acc: AccountProfile.State, action: AccountProfile.Action) {
  switch (action.type) {

    case 'bio':
      return {
        ...acc,
        [action.type]: {
          ...acc.bio ?? {},
          ...action.data,
        },
      };
    case 'education':
    case 'employment':
    case 'industry':
    case 'product':
      return {
        ...acc,
        [action.type]: action.data,
      };

    case 'update-picture-url':
      return {
        ...acc,
        profile: {
          ...acc.profile,
          pictureUrl: action.value,
        },
      };

    case 'init':
      return {
        ...action.data,
        initialized: true,
      };

    case 'reset':
      return initial;

    default:
      return acc;
  }
}

const initial: AccountProfile.State = {
  bio: null,
  education: [],
  employment: [],
  industry: [],
  initialized: false,
  profile: null,
  product: [],
};

export { useProfileFields };
export default useProfileFields;
import { SurveyOptionType } from '@enums/Survey';
import { hasResponseLogic, hasOptions, hasRows, OptionsQuestion } from '@containers/Survey/utils/questions';
import { SurveyQuestionOption, SurveyQuestion, ConditionValue } from '@/types/survey';
import { SurveyQuestionsBuilder, SurveyOptionsBuilder, SurveyBuilder } from '../interfaces.state';
import { generateNewOption } from './defaults';

function isExtendedOption(option: SurveyQuestionOption) {
  return option.type !== SurveyOptionType.Default;
}

export function addOption(state: SurveyQuestionsBuilder.State, action: SurveyOptionsBuilder.AddOption.State): SurveyQuestionsBuilder.State {

  const question = state.find(f => f.base.identifier === action.questionIdentifier);
  const options = question.options.filter(f => !isExtendedOption(f));
  const ordinal = options.length + 1;

  const newOption = generateNewOption({
    metadata: {
      canDelete: true,
      canModifyValue: true,
      template: {},
    },
    ordinal,
  });

  return addOptions(state, {
    questionIdentifier: action.questionIdentifier,
    options: [newOption],
    reorder: false,
  });
}

export function addOptions(state: SurveyQuestionsBuilder.State, action: SurveyOptionsBuilder.AddOptions.State): SurveyQuestionsBuilder.State {

  return state.reduce<SurveyQuestionsBuilder.State>((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      acc.push(updateQuestionOptions(q as OptionsQuestion));
    } else {
      acc.push(q);
    }

    return acc;
  }, []);

  function updateQuestionOptions(q: OptionsQuestion): OptionsQuestion {
    const existingOptions = q.options
      .filter(f => !isExtendedOption(f));

    let nonExtendedOptions = [
      ...existingOptions,
      ...action.options,
    ];

    if (action.reorder) {
      nonExtendedOptions.sort(sortOptionsByEmptiness);
      nonExtendedOptions = nonExtendedOptions.map((m, i) => ({
        ...m,
        ordinal: i + 1,
      }));
    }

    return {
      ...q,
      options: [
        ...nonExtendedOptions,
        ...generateExtendedOptions(q),
      ],
    };
  }

  function sortOptionsByEmptiness(a: SurveyQuestionOption, b: SurveyQuestionOption) {
    const aIsNull = a.value === '';
    const bIsNull = b.value === '';

    if (aIsNull === bIsNull) {
      return a.ordinal - b.ordinal;
    } else if (aIsNull) {
      return 1;
    } else if (bIsNull) {
      return -1;
    }
  }

  function generateExtendedOptions(item: OptionsQuestion): SurveyQuestionOption[] {
    const naOption = item.options.find(f => f.type === SurveyOptionType.NotApplicable);
    const otherOption = item.options.find(f => f.type === SurveyOptionType.Other);
    const noneOption = item.options.find(f => f.type === SurveyOptionType.NoneOfTheAbove);

    let lastOrdinal = Math.max(...item.options.map(m => m.ordinal));

    return [
      generateOption(naOption),
      generateOption(otherOption),
      generateOption(noneOption),
    ].filter(Boolean);

    function generateOption(option: SurveyQuestionOption) {
      if (option) {
        lastOrdinal++;
        return {
          ...option,
          ordinal: lastOrdinal,
        };

      }
      return null;
    }
  }

}

export function removeOption(state: SurveyQuestionsBuilder.State, action: SurveyOptionsBuilder.RemoveOption.State): SurveyQuestionsBuilder.State {

  const question = state.find(f => f.base.identifier === action.questionIdentifier);

  const toRemove = question.options.find(f => f.base.identifier === action.option.identifier);

  if (!toRemove) return state;

  const ordinals = generateNewOrdinals();

  const options = question.options
    .filter(f => f.base.identifier !== action.option.identifier)
    .map(option => ({
      ...option,
      ordinal: ordinals[option.ordinal],
    }));

  const responseLogic = hasResponseLogic(question) ?
    question.logic.response
      .filter(f => f.condition.value.option.identifier !== action.option.identifier)
    : question.logic.response;

  return state.reduce<SurveyQuestionsBuilder.State>((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      acc.push({
        ...q,
        logic: {
          ...q.logic,
          response: responseLogic,
        },
        options,
      } as OptionsQuestion);
    } else {
      // filter out options/rows with conditions reliant on deleted option (does this need to be recursive?)
      const filteredOptions = hasOptions(q) ?
        q.options
          .filter(f => !f.conditions.some(s => {
            if (isOptionCondition(s.condition.value)) {
              return s.condition.value.option.identifier === action.option.identifier;
            } else return false;
          }))
        : q.options;
      const filteredRows = hasRows(q) ?
        q.matrixRows
        .filter(f => !f.conditions.some(s => {
          if (isOptionCondition(s.condition.value)) {
            return s.condition.value.option.identifier === action.option.identifier;
          } else return false;
        }))
        : q.matrixRows;

      acc.push({
        ...q,
        matrixRows: filteredRows,
        options: filteredOptions,
      } as SurveyQuestion);
    }
    return acc;
  }, []);

  function isOptionCondition(data: ConditionValue): data is OptionCondition {
    return !!(data as OptionCondition).option;
  }

  type OptionCondition =
    { option: { identifier: string; }; } |
    {
      option: { identifier: string; };
      row: { identifier: string; };
    };

  function generateNewOrdinals() {
    return question.options.reduce((acc, x) => {
      return {
        ...acc,
        [x.ordinal]: x.ordinal < toRemove.ordinal
          ? x.ordinal
          : x.ordinal > toRemove.ordinal
            ? x.ordinal - 1
            : null,
      };
    }, {} as { [ordinal: number]: number; });
  }
}

export function updateOptionValue(state: SurveyQuestionsBuilder.State, action: SurveyOptionsBuilder.UpdateOptionValue.State): SurveyQuestionsBuilder.State {
  return state.reduce<SurveyQuestionsBuilder.State>((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      const options = q.options.reduce((acc2, o) => {
        if (o.base.identifier === action.option.identifier) {
          acc2.push({
            ...o,
            value: action.value,
          });
        } else {
          acc2.push(o);
        }
        return acc2;
      }, []);
      acc.push({
        ...q,
        options,
      } as OptionsQuestion);
    } else {
      acc.push(q);
    }

    return acc;
  }, []);
}

export function optionsReducer(state: SurveyBuilder.State, action: SurveyOptionsBuilder.Action): SurveyQuestionsBuilder.State {

  switch (action.type) {
    case 'add-question-option':
      return addOption(state.survey.questions, action);

    case 'add-question-options':
      return addOptions(state.survey.questions, action);

    case 'remove-question-option':
      return removeOption(state.survey.questions, action);

    case 'update-question-option-value':
      return updateOptionValue(state.survey.questions, action);

    default:
      return state.survey.questions;
  }
}
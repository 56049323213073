import { useEffect, useRef } from 'react';
import { AudioTrack as TwAudioTrack } from 'twilio-video';

type AudioTrackProps = {
  track: TwAudioTrack;
};

export function AudioTrack({ track }: AudioTrackProps) {
  const audioEl = useRef<HTMLAudioElement>();

  useEffect(() => {
    audioEl.current = track.attach();
    document.body.appendChild(audioEl.current);
    return () =>
      track.detach().forEach(el => {
        el.remove();
        // https://github.com/twilio/twilio-video.js/issues/1528
        el.srcObject = null;
      });
  }, [track]);

  // useEffect(() => {
  //   audioEl.current?.setSinkId?.(activeSinkId);
  // }, [activeSinkId]);

  return null;
}

export default AudioTrack;
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { WorkspaceObjectCapabilities } from '@/types';
import { WorkspaceObjectType } from 'enums';

export const selectFileCapabilities = (state: Store.State) => (fileId: number) => {
  const { objectId } = state.files.values[fileId];
  const capabilities = state.objects.capabilities[objectId];

  return capabilities as WorkspaceObjectCapabilities<WorkspaceObjectType.File>;
};

export const useGetFileCapabilities = () => {
  const getCapabilities = useSelector(selectFileCapabilities);

  return useCallback(
    (fileId: number) => getCapabilities(fileId),
    [getCapabilities],
  );
};

export const useFileCapabilities = (fileId: number) => {
  const getCapabilities = useGetFileCapabilities();
  return getCapabilities(fileId);
};
import dollar from 'static/images/website/customer-research/icon-dollar.svg?url';
import eye from 'static/images/website/customer-research/icon-eye.svg?url';
import gears from 'static/images/website/customer-research/icon-gears.svg?url';
import upload from 'static/images/website/customer-research/icon-import.svg?url';
import network from 'static/images/website/customer-research/icon-network.svg?url';
import notes from 'static/images/website/customer-research/icon-notes.svg?url';
import presentation from 'static/images/website/customer-research/icon-presentation.svg?url';
import project from 'static/images/website/customer-research/icon-project.svg?url';
import recruitment from 'static/images/website/customer-research/icon-recruitment.svg?url';
import search from 'static/images/website/customer-research/icon-search.svg?url';
import tagging from 'static/images/website/customer-research/icon-tagging.svg?url';
import video from 'static/images/website/customer-research/icon-video.svg?url';
import styles from './style/Features.css';

type Props = unknown;

export const Features = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>
          Utilize our platform features to unlock insights from your customers
        </h2>
        <div className={styles.main}>
          {items.map((x, i) =>
            <div
              className={styles.item}
              key={`icon-${i}`}>
              <img
                className={styles.img}
                src={x.src} />
              <div className={styles.title}>{x.title}</div>
              <div className={styles.text}>{x.text}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Features.displayName = 'CustomerResearch.Features';

const items = [{
  src: network,
  text: `Search from over 100,000 technology users and decision makers for your surveys, interviews or messaging needs.`,
  title: `Vancery Network`,
}, {
  src: recruitment,
  text: `Vancery’s service teams supplement our Network with hundreds of new users every day on behalf of our customers’ needs.`,
  title: `User Recruitment`,
}, {
  src: tagging,
  text: `Vancery’s tagging tools allow you to set up repeat studies and to share lists of panel respondents across groups within your organization.`,
  title: `Participant Management`,
}, {
  src: search,
  text: `Search for insights with an NLP search engine that allows you to find relevant content across the repository of transcripts, surveys, and profiles.`,
  title: `Search Engine`,
}, {
  src: presentation,
  text: `Export data from surveys into pre-formatted presentations for your including CSV, SPSS, and PPT formats.`,
  title: `Presentation Exports`,
}, {
  src: notes,
  text: `Store notes from projects and calls that can be shared with your team for future reference.`,
  title: `Notes`,
}, {
  src: upload,
  text: `Send surveys and call requests to customers through Vancery’s customer list import feature.`,
  title: `Customer List Import`,
}, {
  src: project,
  text: `Keep your research organized with our project management tools for interviews and surveys.`,
  title: `Project Tools`,
}, {
  src: gears,
  text: `Control approval processes and create checks and balances for your research to protect your firm.`,
  title: `Compliance Settings`,
}, {
  src: eye,
  text: `Control access to projects and files through our team sharing and privacy features.`,
  title: `Privacy Features`,
}, {
  src: video,
  text: `Access recordings of user interview sessions and call audio files.`,
  title: `Video & Audio Downloads`,
}, {
  src: dollar,
  text: `Use our tools to manage incentives for your work or Vancery’s account managers can do it for you.`,
  title: `Incentive Management`,
}];
import { forwardRef } from 'react';
import * as enums from '@enums';
import SearchAutoComplete from 'components/SearchAutocomplete';

type ChangeEventData = {
  ids:   number[];
  value: string;
};

type Props<T = unknown> = {
  className?:         string;
  name:               string;
  items:              T[];
  minimumCharacters?: number;
  onChange:           (data: ChangeEventData) => Promise<T[]>;
  onSelect:           (item: T) => unknown;
  placeholder:        string;
};

const AutoCompleteSearch = (props: Props, ref) => {
  return (
    <SearchAutoComplete
      autocompleteClass={props.className}
      filterType={props.name}
      minCharactersForFetch={props.minimumCharacters || 1}
      onChange={props.onChange}
      onSelection={props.onSelect}
      placeholder={props.placeholder}
      ref={ref}
      searchType={props.name}
      selectedItems={props.items} />
  );
};

const AutoCompleteSearchRef = forwardRef(AutoCompleteSearch);

export { AutoCompleteSearchRef as AutoCompleteSearch };
export default AutoCompleteSearchRef;
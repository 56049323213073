import * as consts from '@consts';
import {
  SectorsScreen,
  IndustriesScreen,
  SubIndustriesScreen,
  SeniorityScreen,
  RateScreen,
} from '@screens/RateOnboarding';

const routes = [
  {
    component: SectorsScreen,
    exact: true,
    path: consts.path.Onboarding.RateSectors,
  },
  {
    component: IndustriesScreen,
    exact: true,
    path: consts.path.Onboarding.RateIndustries,
  },
  {
    component: SubIndustriesScreen,
    exact: true,
    path: consts.path.Onboarding.RateSubIndustries,
  },
  {
    component: SeniorityScreen,
    exact: true,
    path: consts.path.Onboarding.RateSeniority,
  },
  {
    component: RateScreen,
    exact: true,
    path: consts.path.Onboarding.RateSet,
  },
];

export default routes;
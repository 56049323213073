import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { pathname } from '@consts';
import { PayoutSetup, PayoutSetupContext, PayoutSetupResetContext } from '@containers/PayoutSetup';
import { useSelectPayoutSettings } from '@containers/Store';
import { useStepper } from '@utils';
import { PayoutSetupStepperContext } from './Context';
import * as Overview from './Overview';
import * as Setup from './Setup';
import * as Done from './Done';
import { StepperProps } from './interfaces';

export default function AdHocCall(props: StepperProps) {
  return (
    <PayoutSetup>
      <Stepper {...props } />
    </PayoutSetup>
  );
}

function Stepper({ hasPayoutMethod, hasStepsAfter, onComplete, onSkip }: StepperProps) {
  const options = {
    index: hasPayoutMethod ? 2 : 0,
  };

  const [Screen, actions] = useStepper([
    CallOverview,
    CallSetup,
    CallDone,
  ], options);

  const value = {
    ...actions,
    hasPayoutMethod,
    hasStepsAfter,
    onComplete,
    onSkip,
  };

  return (
    <PayoutSetupStepperContext.Provider value={value}>
      <Screen />
    </PayoutSetupStepperContext.Provider>
  );
}

function CallOverview() {
  const history = useHistory();
  const { next, onSkip } = useContext(PayoutSetupStepperContext);

  const handleSkip = useCallback(() => {
    if (onSkip) {
      onSkip();
    } else {
      history.push(pathname.HOME);
    }
  }, [onSkip, history]);

  const navigation = {
    back: null,
    next,
    skip: handleSkip,
  };

  return (
    <Overview.AdHocCall
      navigation={navigation} />
  );
}

function CallSetup() {
  const { stripeAccountId } = useContext(PayoutSetupContext);
  const { back, next } = useContext(PayoutSetupStepperContext);

  const navigation = {
    back: stripeAccountId ? null : back,
    next,
    skip: null,
  };

  return (
    <Setup.Setup navigation={navigation} />
  );
}

function CallDone() {
  const history = useHistory();
  const { hasStepsAfter, onComplete } = useContext(PayoutSetupStepperContext);
  const reset = useContext(PayoutSetupResetContext);
  const settings = useSelectPayoutSettings();

  const handleNext = useCallback(() => {
    reset();

    if (onComplete) {
      onComplete();
    } else {
      history.push(pathname.HOME);
    }
  }, [history, reset, onComplete]);

  const navigation = {
    back: null,
    next: handleNext,
    skip: null,
  };

  return (
    <Done.AdHocCall
      navigation={navigation}
      method={settings.method}
      nextLabel={hasStepsAfter ? 'Next' : 'Back to Home'} />
  );
}

export { AdHocCall };
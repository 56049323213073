import { useEffect, useState } from 'react';
import { TranscriptComment } from '@/types/transcribe.rich-text';
import {
  TranscriptCommentReplyActionsVisibilityContext,
  TranscriptParentCommentIdentifiersContext,
  TranscriptCommentReplyVisibilityContext,
} from './context.comment';
import { useTranscriptCommentFocus } from './hooks';

type Props = {
  item: Pick<TranscriptComment, 'highlight' | 'parentComment'>;
} & ChildrenProps;

export const TranscriptParentCommentContainer = ({ children, item }: Props) => {

  const [isReplyOpen, setReplyOpen] = useState(false);
  const [replyActionsVisible, setReplyActionsVisible] = useState(false);

  const [isCommentFocused] = useTranscriptCommentFocus(item.parentComment.identifier);

  useEffect(() => {
    if (isCommentFocused) {
      setReplyOpen(true);
    }
  }, [
    isCommentFocused,
  ]);

  return (
    <TranscriptCommentReplyVisibilityContext.Provider value={[isReplyOpen, setReplyOpen]}>
      <TranscriptCommentReplyActionsVisibilityContext.Provider value={[replyActionsVisible, setReplyActionsVisible]}>
        <TranscriptParentCommentIdentifiersContext.Provider value={item}>
          {children}
        </TranscriptParentCommentIdentifiersContext.Provider>
      </TranscriptCommentReplyActionsVisibilityContext.Provider>
    </TranscriptCommentReplyVisibilityContext.Provider>
  );
};
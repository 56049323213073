import { footer } from '$website/containers/SiteNavigation';
import { Button } from 'components/Button';
import styles from './style/Menus.css';

type Props = unknown;

export const Menus = (props: Props) => {

  return (
    <nav className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          {footer.menus.map(item =>
            <div
              className={styles.item}
              key={item.name}>
              <div className={styles.title}>{item.name}</div>
              <div className={styles.items}>
                {item.items.map(x =>
                  <div key={x.name} className={styles.link}>
                    <Button
                      href={x.href}
                      to={x.to}
                      variant="link">
                      {x.name}
                    </Button>
                  </div>
                )}
              </div>
            </div>)}
        </div>
      </div>
    </nav>
  );
};

Menus.displayName = 'Menus';
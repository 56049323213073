import { WorkspacePathValue } from '@/types';

export enum FolderRouteKey {
  Details  = 'details',
  Files    = 'files',
  Folders  = 'folders',
  Projects = 'projects',
}

export enum WorkspaceRouteKey {
  Files    = 'files',
  Folders  = 'folders',
  Projects = 'projects',
}

export enum SharedRouteKey {
  Files    = 'files',
  Folders  = 'folders',
  Projects = 'projects',
}

export type WorkspaceSceneMap = {
  [key in WorkspaceRouteKey]: TabScene;
};

export type FolderSceneMap = {
  [key in FolderRouteKey]: TabScene;
};

export type SharedSceneMap = {
  [key in SharedRouteKey]: TabScene;
};

export type WorkspaceRouteParams = {
  tab?: WorkspaceRouteKey;
  workspace: WorkspacePathValue;
};

export type FolderRouteParams = {
  folderId: string;
  tab?: FolderRouteKey;
};

export type SharedRouteParams = {
  tab?: FolderRouteKey;
};

export type TabScene = {
  component: React.ComponentType;
  path:      string;
  title:     string | React.ReactNode;
};
import { useCallback, useMemo } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { MatrixSliderQuestion } from '@/types';
import SliderSettings from './MatrixSlider.Settings.Slider';
import styles from './style/MatrixSlider.Question.css';

type Props = {
  className?: string;
  updateSettings: (settings: MatrixSliderQuestion.SliderSettings) => void;
  settings: MatrixSliderQuestion.SliderSettings;
};

type HandleChange<K extends keyof MatrixSliderQuestion.SliderSettings = keyof MatrixSliderQuestion.SliderSettings, V = MatrixSliderQuestion.SliderSettings[K]> = (key: K) => (value: V) => unknown;

export const MatrixSliderSettings = (props: Props) => {
  const { settings, updateSettings } = props;

  const handleChange: HandleChange = useCallback(key => value => {
    updateSettings({
      ...settings,
      [key]: value,
    });
  }, [settings, updateSettings]);

  const warningText = useMemo(() => {
    if (settings.maxValue &&
      settings.maxValue === settings.minValue) {
      return `Low value can't be the same as high value`;
    }

    if (settings.increment
      && Math.abs(settings.increment) < 0.25) {
      return `Unit increment is smaller than the minimum of 0.25`;
    }

    if (settings.maxValue !== null
      && settings.minValue !== null
      && settings.increment) {
      const steps = (settings.maxValue - settings.minValue) / settings.increment;

      if (!Number.isInteger(steps)) {
        return `Number of steps must be a whole number`;
      } else if (Math.abs(steps) < 2) {
        return `Must have at least 2 steps`;
      } else if (Math.abs(steps) > surveyBuilder.matrixSlider.MaxSteps) {
        return `Can't have more than ${surveyBuilder.matrixSlider.MaxSteps} steps`;
      }
    }

    return null;
  }, [settings]);

  const RenderWarning = useMemo(() => {
    if (!warningText) {
      return null;
    }

    return (
      <div className={styles.warning}>{warningText}</div>
    );
  }, [warningText]);

  return (
    <div className={props.className}>
      <SliderSettings
        onHideSlideChange={handleChange('hideSlider')}
        onEnsureTotalChange={handleChange('ensureAnswerTotalEqualsMax')}
        onIncrementChange={handleChange('increment')}
        onLabelChange={handleChange('label')}
        onMaxValueChange={handleChange('maxValue')}
        onMinValueChange={handleChange('minValue')}
        settings={props.settings} />
      {RenderWarning}
    </div>
  );
};

export default MatrixSliderSettings;
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import * as conditional from '@containers/Conditional/utils';
import * as scheduler from '@containers/Scheduler/utils';
import { useProjectCapabilities }  from '@containers/Store/hooks';
import * as enums from '@enums';
import { getLocationFor } from '@utils';
import { UserAvatar } from 'components/UserAvatar';
import ButtonSet from './ButtonSet';
import { ProjectCallItemProps } from './interfaces';
import styles from './style/CallItem.css';

const mapState = (state: Store.State) => ({
  pipeline: state.pipeline,
  isProjectActive: conditional.isProjectActive(state),
  me: state.user,
  group: state.group,
});

type Props =
    ProjectCallItemProps
  & ReturnType<typeof mapState>;

const ProjectCallItem = ({ item, user, ...props }:  Props) => {
  const pipeline = props.pipeline.project[item.projectId];
  const record = pipeline.users[user.id];
  const requiresAnalystAction = scheduler.calls.isAwaitingCreatorAction(item);
  const requiresConsultantAction = scheduler.projects.isAwaitingConsultantAction(record)
                                || !scheduler.calls.isLastModifiedBy(item, { id: item.userId });

  const isProjectActive = props.isProjectActive({
    projectId: props.project.id,
  });

  const projectCapabilities = useProjectCapabilities(props.project.id);

  const renderHeader = () => {
    if (item.statusId !== enums.CallStatus.Pending) return renderDate();

    let text: string;
    if (scheduler.calls.isAwaitingVanceryCompliance(item)) {
      text = 'Awaiting vancery compliance review';
    } else if (scheduler.calls.isAwaitingInternalCompliance(item)) {
      text = 'Awaiting internal compliance review';
    } else if (requiresConsultantAction) {
      text = 'Awaiting expert scheduling';
    } else if (requiresAnalystAction && projectCapabilities.canManageCalls) {
      text = 'Select a time to schedule a call';
    } else {
      text = 'Awaiting analyst scheduling';
    }

    return (
      <div className={styles.header}>
        <span className={styles.label}>Call Request: </span>
        <span className={styles.callRequestText}>{text}</span>
      </div>
    );
  };

  const renderDate = () => {
    if (!item.timeStart) return null;

    const day = format(item.timeEnd, 'MMMM do');
    const timeStart = format(item.timeStart, 'h:mm a');
    const timeEnd = format(item.timeEnd, 'h:mm a');
    const time = `${timeStart} - ${timeEnd}`;

    return (
      <div className={styles.header}>
        <div className={styles.day}>{day}</div>
        <div className={styles.time}>{time}</div>
      </div>
    );
  };

  const renderRecordingStatus = () => {
    if (item.recordingConsentStatusId === enums.ApprovalStatus.NotRequired ||
      item.statusId !== enums.CallStatus.Scheduled ||
      !props.group.features.recordCalls) {
      return null;
    }

    const recordingAccepted = item.recordingConsentStatusId === enums.ApprovalStatus.Approved;

    return (
      <div className={styles.recordingStatus}>
        {`Call Recording: `}
        <span>{` ${recordingAccepted ? 'Accepted' : 'Rejected'}`}</span>
      </div>
    );
  };

  const renderNotes = () => {
    if (item.statusId !== enums.CallStatus.Rescheduling) return null;

    const line1 = requiresConsultantAction
                  ? `Waiting for ${user.profile.firstName} to select a time.`
                  : requiresAnalystAction
                    ? `${user.profile.firstName} has proposed new times for the call.`
                    : '';

    const line2 = requiresConsultantAction || !projectCapabilities.canManage || !isProjectActive
                  ? null
                  : projectCapabilities.canManageCalls
                    ? `Click Select Time to view ${user.profile.firstName}'s availability and book the call.`
                    : '';

    return (
      <div className={styles.notes}>
        <div className={styles.text}>{line1}</div>
        <div className={styles.text}>{line2}</div>
      </div>
    );
  };

  const url = getLocationFor.user.profile(user.profile);

  return (
    <div className={styles.item}>
      <div className={styles.details}>
        {renderHeader()}
        <div className={styles.user}>
          <div className={styles.avatar}>
            <Link to={url}>
              <UserAvatar
                pictureUrl={user.profile.pictureUrl}
                size={45} />
            </Link>
          </div>
          <div className={styles.fullname}>
            <Link
              className={styles.link}
              to={url}>
              {user.profile.fullname}
            </Link>
          </div>
        </div>
        {renderRecordingStatus()}
      </div>
      {renderNotes()}

      {projectCapabilities.canManage &&
        isProjectActive &&
          <ButtonSet
            item={item}
            onCancelCall={props.onCancelCall}
            project={props.project} />}
    </div>
  );
};

export default connect(mapState)(ProjectCallItem);
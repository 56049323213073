import { Component } from 'react';
import { connect } from 'react-redux';
import { RequestCallScheduler } from '@containers/Scheduler/RequestCall';
import { RequestCallCallbackParams, RequestCallSubmitHandler } from '@containers/Scheduler/interfaces';
import * as enums from '@enums';
import * as selectors from '@store/selectors';
import { Contact, Project } from '@/types';
import { Button } from 'components/Button';
import { Select } from 'components/Select';
import Modal from 'components/Modal/Modal.Legacy';
import Body from 'components/Modal/Body';
import Header from 'components/Modal/Header';
import { ModalProps } from 'components/Modal/Modal';
import styles from './style/Project.css';

const mapState = (state: Store.State) => ({
  me: state.user,
  projects: selectors.selectActiveManagedProjects(state),
});

type OwnProps = {
  user: Pick<Contact, 'id'>;
} & Pick<ModalProps, 'open' | 'onClose'>;

type Props =
  & OwnProps
  & RequestCallSubmitHandler
  & ReturnType<typeof mapState>;

type State = {
  project: Project | null;
};

class RequestProjectCallModal extends Component<Props, State> {
  static defaultProps = {
    projects: [],
    user: {} as Contact,
    open: true,
  }

  state: State = {
    project: null,
  }

  handleClose = (data: RequestCallCallbackParams) => {
    this.props.onClose();
  }

  handleSelected = (item: Project) => {
    this.setState({ project: item });
  }

  handleSubmit = () => {
    this.props.handleSubmitCallRequest({
      projectId: this.state.project.id,
      userId: this.props.user.id,
    }, this.props.onClose);
  }

  render() {
    const items = this.props.projects.filter(x => x.projectType !== enums.ProjectType.Survey);
    const placeholder = this.props.projects.length
        ? 'Select a project'
        : 'No projects available for this expert';

    return (
      <Modal
        className={styles.root}
        onClose={this.props.onClose}
        visible={this.props.open}>
        <Header>Request Call</Header>

        <Body className={styles.row}>
          <div className={styles.label}>Project</div>
          <div className={styles.select}>
            <Select
              autoFocus
              defaultValue={placeholder}
              getItemValue={x => x.name}
              onSelect={this.handleSelected}
              options={items} />
          </div>
        </Body>

        <div className={styles.footer}>
          <Button
            color="primary"
            disabled={!this.state.project}
            onClick={this.handleSubmit}
            variant="pill">
            Schedule Call
          </Button>
        </div>
      </Modal>
    );
  }
}

const RequestProjectCallConnected = connect(mapState, null)(RequestProjectCallModal);

const RequestProjectCall = <P extends RequestCallSubmitHandler>(props: OwnProps) => {
  return (
    <RequestCallScheduler>
      <RequestProjectCallConnected {...props as P & OwnProps} />
    </RequestCallScheduler>
  );
};

export { RequestProjectCall as RequestProjectCallModal };
export default RequestProjectCall;
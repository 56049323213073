import { useMemo } from 'react';
import { Track } from 'twilio-video';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useLiveRoomScreen, useTwilioVideo } from '@containers/Conference';
import { ParticipantTracks } from 'components/Conference.Video';
import { useScreenShareParticipant } from 'components/Conference.Video/hooks';
import { useMainSpeaker, useSelectedParticipant } from './hooks';
import styles from './style/Session.MainParticipant.css';

export function SessionMainParticipant() {
  const twilio = useTwilioVideo();
  const screen = useLiveRoomScreen();

  const mainParticipant = useMainSpeaker(twilio.room);

  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant(twilio.room);

  const videoPriority: Track.Priority = useMemo(() =>
    !screenShareParticipant ||
    mainParticipant === selectedParticipant ||
    mainParticipant === screenShareParticipant
      ? 'high'
      : null,
  [mainParticipant, screenShareParticipant, selectedParticipant]);

  return (
    <div className={styles.root}>
      <div className={styles.actions}>
        <div className={styles.topRight}>
          <div
            className={styles.fs}
            onClick={screen.toggle}>
            {screen.isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </div>
        </div>
      </div>
      <ParticipantTracks
        disableAudio
        participant={mainParticipant}
        room={twilio.room}
        videoPriority={videoPriority} />
    </div>
  );
}
import { useCallback, useReducer, Reducer } from 'react';
import cuid from 'cuid';
import { MultipleChoiceQuestion, MultiselectQuestion } from '@/types/survey';
import { SurveyActionType, SurveyQuestionConditionType } from '@enums';
import { BaseLogicContext, OptionsQuestionLogicStateContext } from './Context';
import { BaseLogic, OptionsQuestionLogic } from './interfaces';
import { useGetDefaultIdentifier } from './hooks';
import { baseActionReducer } from './reducer.base';

type Props = {
  
  item: MultipleChoiceQuestion.Question | MultiselectQuestion.Question;
} & ChildrenProps;

function transformLogic(item: OptionsQuestionLogic.State): OptionsQuestionLogic.State {
  return {
    base: item.base,
    response: item.response
      .filter(f => f.action.type !== SurveyActionType.None)
      .map(x => ({
        ...x,
        id: x.id || cuid(),
        condition: {
          type: x.condition.type || SurveyQuestionConditionType.Chosen,
          value: x.condition.value,
        },
      })),
  };
}

export const OptionsQuestionLogicState = ({ children, item }: Props) => {

  const [state, dispatch] = useReducer<Reducer<OptionsQuestionLogic.State, OptionsQuestionLogic.Action>>(optionsLogicReducer, transformLogic(item.logic));

  const getDefaultIdentifier = useGetDefaultIdentifier();

  const updateActionType = useCallback((type: SurveyActionType) => {
    dispatch({
      action: {
        identifier: getDefaultIdentifier(type),
        type,
      },
      type: 'update-base-action',
    });

  }, [
    dispatch,
    getDefaultIdentifier,
  ]);

  const updateActionIdentifier = useCallback((identifier: string) => {
    dispatch({
      action: {
        identifier,
        type: state.base.action.type,
      },
      type: 'update-base-action',
    });
  }, [
    dispatch,
    state.base.action,
  ]);

  const base = {
    item: state.base,
    updateActionIdentifier,
    updateActionType,
  };

  return (
    <BaseLogicContext.Provider value={base}>
      <OptionsQuestionLogicStateContext.Provider value={[state, dispatch]}>
        {children}
      </OptionsQuestionLogicStateContext.Provider>
    </BaseLogicContext.Provider>
  );
};

function optionsLogicReducer(state: OptionsQuestionLogic.State, action: OptionsQuestionLogic.Action) {
  return {
    base: baseActionReducer(state.base, action as BaseLogic.Action),
    response: optionsLogicResponseReducer(state.response, action as OptionsQuestionLogic.Response.Action),
  };
}

function optionsLogicResponseReducer(state: OptionsQuestionLogic.Response.State, action: OptionsQuestionLogic.Response.Action) {
  switch (action.type) {
    case 'add-item': {
      return [
        ...state,
        {
          id: cuid(),
          action: {
            ordinal: null,
            type: null,
          },
          condition: {
            type: SurveyQuestionConditionType.Chosen,
            value: {
              option: { identifier: null },
            },
          },
        } as MultipleChoiceQuestion.Logic.Response,
      ];
    }

    case 'remove-item': {
      return state.filter(f => f.id !== action.id);
    }

    case 'update-action': {
      return state.reduce<OptionsQuestionLogic.Response.State>((acc, x) => {
        if (x.id === action.id) {
          acc.push({
            ...x,
            action: action.action,
          });
        } else {
          acc.push(x);
        }

        return acc;
      }, []);
    }

    case 'update-condition-value': {
      return state.reduce<OptionsQuestionLogic.Response.State>((acc, x) => {
        if (x.id === action.id) {

          acc.push({
            ...x,
            condition: {
              value: action.value,
              type: x.condition.type,
            },
          });
        } else {
          acc.push(x);
        }

        return acc;
      }, []);
    }

    case 'update-condition-type': {
      return state.reduce<OptionsQuestionLogic.Response.State>((acc, x) => {
        if (x.id === action.id) {

          acc.push({
            ...x,
            condition: {
              value: x.condition.value,
              type: action.value as MultipleChoiceQuestion.Logic.ConditionType,
            },
          });
        } else {
          acc.push(x);
        }

        return acc;
      }, []);
    }

    default:
      return state;
  }
}
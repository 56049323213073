import { useContext } from 'react';
import { RecordingConsentStateContext } from '../Context';

const useRecordingConsentState = () => {
  const state = useContext(RecordingConsentStateContext);

  return state;
};

export { useRecordingConsentState };
export default useRecordingConsentState;
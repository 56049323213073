import { useCallback } from 'react';
import { ComplianceSurveyBuilderState } from '@containers/SurveyBuilder/ComplianceSurveyBuilderState';
import { EditableSurveyState } from '@containers/SurveyBuilder/EditableSurveyState';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { AddQuestionButton } from '@presentation';
import { EditableSurvey } from 'scenes/surveys/EditableSurvey/EditableSurvey';
import { ComplianceSurveyBuilder } from 'components/SurveyBuilder';
import { ComplianceSurveyProps } from './interfaces';
import styles from './style.css';

const helpText = `Experts will be required to provide answers to the following compliance questions the first time they engage on a project with your firm.`;
const title = 'Compliance Survey';

const Content = () => {
  const [state, dispatch] = useSurveyBuilderState();

  const addQuestion = useCallback(() => {
    const section = state.survey.sections[0];
    dispatch({
      type: 'new-question-added',
      questionOrdinal: 1,
      sectionIdentifier: section.identifier,
    });
  }, [dispatch, state.survey.sections]);

  if (!state.survey.questions.length) {
    return (
      <>
        <div className={styles.placeholder}>
          {`No compliance questions required`}
        </div>
        <AddQuestionButton
          onClick={addQuestion} />
      </>
    );
  }

  return <ComplianceSurveyBuilder />;
};

export const EditableComplianceSurvey = (props: ComplianceSurveyProps) => {

  const renderContent = useCallback(() => {
    return <Content />;
  }, []);

  return (
    <ComplianceSurveyBuilderState>
      <EditableSurveyState
        onDelete={props.onDelete}
        onFetch={props.onFetch}
        onSave={props.onSave}>
        <EditableSurvey
          helpText={helpText}
          renderContent={renderContent}
          title={title} />
      </EditableSurveyState>
    </ComplianceSurveyBuilderState>
  );
};

export default EditableComplianceSurvey;
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as actions from '@store/actions';
import { useSelectGroup } from '@containers/Store';
import { useUpdateProjectMetrics } from '@containers/Metrics';
import { useSurveyBuilderState, useScreenerBuilderContext } from '@containers/SurveyBuilder';
import { useProjectFormContext, useProjectParentId } from '@screens/ProjectCreation/context';
import { BaseCompany } from '@/types/companies';
import { getLocationFor } from '@utils';
import { useCreateProject } from '@utils/api';

export const useSubmitNewProject = () => {

  const [surveyState] = useSurveyBuilderState();
  const screening = useScreenerBuilderContext();
  const parentObjectId = useProjectParentId();

  const history = useHistory();
  const dispatch = useDispatch();
  const group = useSelectGroup();
  const [form] = useProjectFormContext();
  const updateProjectMetrics = useUpdateProjectMetrics();

  const {
    mutateAsync: createProject,
    isLoading,
  } = useCreateProject({
    onSuccess: res => {
      updateProjectMetrics({
        metrics: res.metrics,
        projectId: res.project.id,
      });

      dispatch(actions.projectCreated({
        capabilities: res.capabilities,
        object: res.object,
        parent: res.parent,
        project: res.project,
        projectId: res.project.id,
      }));

      const location = getLocationFor.project.search({
        slug: res.project.slug,
      });
      history.push(location);
    },
  });

  const submit =  useCallback(() => {
    const companies = [
      ...form.currentCompanies.map(x => ({ ...x, isCurrent: true })),
      ...form.formerCompanies.map(x => ({ ...x, isCurrent: false })),
    ];

    const { id, ...tc } = form.targetCompany;

    // @ts-ignore
    const targetCompany: BaseCompany = !id
        ? null
        : tc.isUserCreated
          ? { name: form.targetCompany.name }
          : { id: form.targetCompany.id };

    const params = {
      companies,
      description: form.description,
      frequency: form.frequency,
      goal: form.goal,
      groupId: group.id,
      name: form.name,
      parentObjectId: parentObjectId,
      projectType: form.projectType,
      screeningQuestions: screening.questions,
      survey: surveyState.survey,
      targetCompany,
      targetCompleteDate: form.targetCompleteDate,
      titles: form.titles,
      vics: form.vics,
    };

    createProject(params);
  }, [
    createProject,
    form,
    group.id,
    parentObjectId,
    screening.questions,
    surveyState.survey,
  ]);

  return [submit, isLoading] as const;
};
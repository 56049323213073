import { useCallback, useState } from 'react';
import { SearchResultsContext } from './Context';
import { SearchResultsContextState } from './interfaces';

export default function Results({ children }: ChildrenProps) {
  const [state, dispatch] = useState<SearchResultsContextState>(mergeInitial());

  const reset = useCallback(() => {
    dispatch(mergeInitial());
  }, []);

  return (
    <SearchResultsContext.Provider value={[state, dispatch, reset]}>
      {children}
    </SearchResultsContext.Provider>
  );
}

function mergeInitial(): SearchResultsContextState {
  return {
    all: {
      projects: null,
      members: null,
      files: null,
      folders: null,
      interactions: null,
    },
    projects: null,
    members: null,
    files: null,
    folders: null,
    interactions: null,
  };
}

export { Results };
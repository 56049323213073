import { useSignupModal } from '$website/components';
import { Site, usePageContent } from '$website/containers';
import { useAllowAuthFlow } from '$website/containers/Auth/hooks/useAllowAuthFlow';
import { Button } from 'components/Button';
import styles from './style.css';

type Props = unknown;

const Banner = (props: Props) => {
  const content = useButtonAttributes();

  return (
    <div className={styles.bannerRoot}>
      <div className={styles.bannerWrap}>
        <Button
          className={styles.bannerLink}
          color="transparent"
          onClick={content.button.cta.onClick}
          to={content.button.cta.to}
          href={content.button.cta.href}
          variant="link">
          {content.button.cta.text}
        </Button>
      </div>
    </div>
  );
};

const useButtonAttributes = () => {
  const allowAuthFlow = useAllowAuthFlow();
  const content = usePageContent<'main', 'banner'>('banner');
  const [_, setSignupModalOpen] = useSignupModal();

  if (!allowAuthFlow) return content as ButtonContent;

  return {
    button: {
      cta: {
        onClick: () => setSignupModalOpen(true),
        text: content.button.cta.text,
      },
    },
  } as ButtonContent;
};

type ButtonAttributeOverrides = {
  onClick?: () => void;
} & Pick<Site.ButtonAttributes, 'text'>
  & Partial<Omit<Site.ButtonAttributes, 'text'>>

type ButtonOverrides = {
  cta: ButtonAttributeOverrides;
};

type ButtonContent = {
  button: ButtonOverrides;
};

export { Banner };
export default Banner;
import { useCallback } from 'react';
import { SurveySectionLogicBuilderContainer } from '@containers/SurveyBuilder.Logic/Section.Container';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import styles from './style/SurveyLogicSectionsBuilder.css';

export const SurveyLogicSectionsBuilder = () => {
  const [state] = useSurveyBuilderState();

  const renderSections = useCallback(() => {
    return state.survey.sections.map(section => (
      <SurveySectionLogicBuilderContainer
        key={section.ordinal}
        className={styles.section}
        identifier={section.identifier} />
    ));
  }, [
    state.survey.sections,
  ]);

  return (
    <div className={styles.root}>
      {renderSections()}
    </div>
  );
};

export default SurveyLogicSectionsBuilder;
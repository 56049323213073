import { createNamedContext } from '@utils';
import { WorkspaceFolder } from '@/types';

export type FolderActionsContextValue = {
  changeOwner: () => unknown;
  createFolder: () => unknown;
  createProject: () => unknown;
  delete: () => unknown;
  manageAccess: () => unknown;
  rename: () => unknown;
};

export type FolderMenuItemsContextValue = {
  canAddChildren: boolean;
  canDelete: boolean;
  canManageAccess: boolean;
  canRename: boolean;
};

export type RenameFolderContextValue = {
  folder: WorkspaceFolder;
  rename: (name: string) => Promise<unknown>;
};

type DeleteFolder = () => Promise<unknown>;

export const DeleteFolderContext = createNamedContext<DeleteFolder>(undefined, 'WorkspaceFolders.DeleteFolder.Context');
export const FolderActionsContext = createNamedContext<FolderActionsContextValue>(undefined, 'WorkspaceFolders.FolderActions.Context');
export const FolderMenuItemsContext = createNamedContext<FolderMenuItemsContextValue>(undefined, 'WorkspaceFolders.FolderMenuItems.Context');
export const RenameFolderContext = createNamedContext<RenameFolderContextValue>(undefined, 'WorkspaceFolders.RenameFolder.Context');
import { useCallback, useState } from 'react';
import { Redirect, generatePath } from 'react-router-dom';
import { addMinutes, isAfter } from 'date-fns';
import { path, pathname } from '@consts';
import { useConferenceParam, useConferenceInstance, IConference } from '@containers/Conference';
import { ConferenceType } from '@enums';
import { useInterval } from '@utils';
import { Expired } from './Expired';
import { UnsupportedBrowserWarningVideo } from './UnsupportedBrowserWarning.Video';
import { Video } from './Video';
import { Voice } from './Voice';
import { WaitingRoom } from './WaitingRoom';
import { WaitingRoomEarly } from './WaitingRoom.Early';

export function ConferenceEntryJoin() {
  const conferenceIdentifier = useConferenceParam();
  const instance = useConferenceInstance();
  const joinability = useJoinability();

  if (instance?.status === 'pre-room') {
    if (joinability === 'early') return <WaitingRoomEarly />;
    if (joinability === 'expired') return <Expired />;
    switch (instance.conference.type) {
      case ConferenceType.Video: {
        return (
          <UnsupportedBrowserWarningVideo>
            <Video />
          </UnsupportedBrowserWarningVideo>
        );
      }
      case ConferenceType.Voice: {
        return (
          <Voice />
        );
      }
      default: return <Redirect to={pathname.HOME} />;
    }
  }
  else if (instance?.status === 'waiting-room') {
    return <WaitingRoom />;
  }
  else if (instance?.status === 'meeting-room') {
    return <Redirect to={generatePath(path.Conference.Room, { conferenceIdentifier })} />;
  }

  return <Redirect to={generatePath(path.Conference.Entry, { conferenceIdentifier })} />;
}

function useJoinability(): JoinabilityStatus {
  const instance = useConferenceInstance<IConference.Coordinator.Conference.PreRoom>();

  const getJoinability = useCallback((): JoinabilityStatus => {
    if (instance?.status !== 'pre-room') return null;
    if (isAfter(Date.now(), addMinutes(instance.call.end, 15))) return 'expired';
    if (isAfter(Date.now(), addMinutes(instance.call.start, process.env.VANCERY_ENV === 'production' ? -5 : -15))) return 'available';
    return 'early';
  }, [instance?.status, instance?.call?.start, instance?.call?.end]);

  const [state, setState] = useState<JoinabilityStatus>(getJoinability());

  const intervalIsEarly = useCallback(() => {
    setState(getJoinability());
  }, [getJoinability]);

  useInterval(intervalIsEarly, 5000);

  return state;
}

type JoinabilityStatus = 'early' | 'available' | 'expired';
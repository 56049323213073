import Modal from 'components/Modal/Modal.Legacy';
import Header from './Header';
import Body from './Body';
import { PromptModalProps } from './interfaces';
import styles from './style/Base.css';

type Props =
    PromptModalProps;

export const Prompt = ({ className = '', ...props }: Props) => {
  const containerStyles = className
    ? `${styles.promptModal} ${className}`
    : styles.promptModal;

  return (
    <Modal
      blocking={props.blocking}
      className={containerStyles}
      onClose={props.onClose}
      visible={props.visible}>
      <div className={styles.promptRoot}>
        {props.header && <Header className={styles.promptHeader}>{props.header}</Header>}
        {props.body && <Body className={styles.promptBody}>{props.body}</Body>}
        {props.footer && <div className={styles.promptFooter}>{props.footer}</div>}
      </div>
    </Modal>
  );
};

export default Prompt;
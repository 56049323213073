import { EditorState, RemirrorJSON, getRemirrorJSON } from '@remirror/core';
import cuid from 'cuid';
import * as Img from './interfaces.image';

export class ImageState {
  map = new Map<Base64String, string>();

  cacheImage(result: Pick<Img.ImageAttributes, 'src' | 'fileName'>) {
    const cache = this.map;

    const alreadyExists = cache.has(result.src);

    if (!alreadyExists) {
      const cid = cuid();
      cache.set(result.src, cid);

      return {
        cid,
        name: result.fileName,
        src: result.src,
      };
    } else {
      const cid = cache.get(result.src);
      return {
        cid,
        name: result.fileName,
        src: result.src,
      };
    }
  }

  findRenderedImages(editorstate: EditorState) {
    const json = getRemirrorJSON(editorstate);

    return getImages<Base64String>(json).reduce((acc, x) => {
      const exists = acc.find(y => y.cid === x.cid);

      return exists
        ? acc
        : acc.concat(x);
    }, [] as ImageObject<Base64String>[]);
  }

  replaceImageSrc(editorstate: EditorState, lookup: Record<string, URLString>) {
    const json = getRemirrorJSON(editorstate);

    return {
      ...json,
      content: json.content.map(x => replaceImage(x, lookup)),
    };
  }
}

export type Base64String = string;
export type URLString = string;

type SourceType = Base64String | URLString;

export type ImageObject<T extends SourceType = SourceType> = {
  cid: string;
  src: T;
};

const getImages = <T extends SourceType>(x: RemirrorJSON, acc: ImageObject<T>[] = []): ImageObject<T>[] => {
  if (x.content?.length) {
    return x.content.flatMap(y => getImages(y, acc));
  } else if (x.type === 'image') {
    return [...acc, {
      cid: x.attrs.cid as string,
      src: x.attrs.src as T,
    }];
  } else {
    return acc;
  }
};

const replaceImage = (json: RemirrorJSON, lookup: Record<string, URLString>): RemirrorJSON => {
  if (json.content?.length) {
    return {
      ...json,
      content: json.content.map(x => replaceImage(x, lookup)),
    };
  } else if (json.type === 'image') {
    return {
      ...json,
      attrs: {
        ...json.attrs,
        src: lookup[json.attrs.cid as string] || json.attrs.src,
      },
    };
  } else {
    return json;
  }
};
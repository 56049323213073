import { Redirect } from 'react-router-dom';
import { useEnterprisePaymentMethodDetails } from '@containers';
import * as consts from '@consts';
import * as Stripe from '@screens/Stripe';
import { PaymentMethodDetailsBankAccount, PaymentMethodDetailsCard } from '@/types';
import { cx } from '@utils';
import Button from 'components/Button';
import { AnimatedLoader } from 'components/ActivityIndicator';
import styles from './style/ExistingPaymentMethod.css';

export function ExistingPaymentMethod() {
  const [details, loading] = useEnterprisePaymentMethodDetails();

  if (loading) {
    return (
      <div className={cx(styles.root, styles.loading)}>
        <AnimatedLoader />
      </div>
    );
  }

  if (!details) {
    return <Redirect to={consts.path.Settings.EnterpriseBilling} />;
  }

  return details.bankAccount
    ? <BankAccount {...details.bankAccount} />
    : <Card {...details.card} />;
}

function BankAccount({ bankName, currency, last4, verified }: PaymentMethodDetailsBankAccount) {
  return (
    <div className={styles.root}>
      <div className={styles.header}>Payment Method</div>
      <div className={styles.method}>Bank Account</div>
      <Stripe.ExistingBank
        bankName={bankName}
        currency={currency}
        last4={last4}
        verified={verified} />
      <div className={styles.actions}>
        {!verified &&
          <Button
            className={styles.verify}
            color='affirmative'
            variant='brick'
            title='Verify'
            to={consts.path.Settings.EnterpriseBillingVerify} />}
        <Button
          className={styles.change}
          color='primary'
          variant='brick'
          title='Change'
          to={consts.path.Settings.EnterpriseBillingNewMethod} />
      </div>
    </div>
  );
}

function Card({ brand, name, expMonth, expYear, last4 }: PaymentMethodDetailsCard) {
  return (
    <div className={styles.root}>
      <div className={styles.header}>Payment Method</div>
      <div className={styles.method}>Bank Card</div>
      <Stripe.ExistingCard
        brand={brand}
        name={name}
        expMonth={expMonth}
        expYear={expYear}
        last4={last4} />
      <div className={styles.actions}>
        <Button
          className={styles.change}
          color='primary'
          variant='brick'
          title='Change'
          to={consts.path.Settings.EnterpriseBillingNewMethod} />
      </div>
    </div>
  );
}

export default ExistingPaymentMethod;
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as consts from '@consts';
import { ProfileOnboardingContainer } from '@containers';
import { useRouteStepper } from '@utils/hooks';
import { LinearProgress } from 'components/Progress/LinearProgress';
import styles from '../style.css';
import routes from './routes';

const mapState = (state: Store.State) => ({
  user: state.user,
});

export  const ProfileWizard = () => {
  const history = useHistory();
  const { user } = useSelector(mapState);

  useEffect(() => {
    if (user.profile.status?.completed) {
      redirectToProfile();
    }
  }, []);

  const [Routes, stepperActions, step] = useRouteStepper(routes);

  const redirectToProfile = () => {
    const profilePath = `${consts.pathname.USER_PROFILE}/${user.profile.slug}`;

    history.push({
      pathname: profilePath,
      state: { from: consts.pathname.HOME },
    });
  };

  const handleCompletion = () => {
    setTimeout(() => {
      redirectToProfile();
    }, 0);
  };

  const progress = (step / routes.length) * 100;

  return (
    <>
      <div className={styles.progress}>
        <LinearProgress value={progress} />
      </div>
      <ProfileOnboardingContainer
        back={stepperActions.back}
        onCompletion={handleCompletion}
        next={stepperActions.next}
        step={step}>
        <Routes />
      </ProfileOnboardingContainer>
    </>
  );
};

export default ProfileWizard;
import { format } from 'date-fns';
import tzFormat from 'date-fns-tz/format';
import { EnabledOnJoinOption, DisabledOnJoinOption } from 'components/Conference.Entry/Camera';
import { MutedOnJoinOption, UnmutedOnJoinOption } from 'components/Conference.Entry/Microphone';
import { HiddenFromAttendeesOption, VisibleToAttendeesOption } from 'components/Conference.Entry/Visibility';

export function cameraToBool(value: number): boolean {
  return value === EnabledOnJoinOption.id;
}

export function boolToCamera(value: boolean): number {
  return value ? EnabledOnJoinOption.id : DisabledOnJoinOption.id;
}

export function microphoneToBool(value: number): boolean {
  return value === UnmutedOnJoinOption.id;
}

export function boolToMicrophone(value: boolean): number {
  return value ? UnmutedOnJoinOption.id : MutedOnJoinOption.id;
}

export function visibilityToBool(value: number): boolean {
  return value === VisibleToAttendeesOption.id;
}

export function boolToVisibility(value: boolean): number {
  return value ? VisibleToAttendeesOption.id : HiddenFromAttendeesOption.id;
}

export function formatCallTime(start: Date, end: Date) {
  const s = format(start, 'MMM do h:mm aaa');
  const e = format(end, 'h:mm aaa');
  const tz = tzFormat(Date.now(), 'zzz');
  return `${s} - ${e} ${tz}`;
}
import { useCallback, useState } from 'react';
import { Download } from 'react-feather';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { usePopper } from 'react-popper';
import { useDownloader } from '@utils/hooks';
import { useTranscriptDownloadModal } from '@screens/ProjectSummary/TranscriptDownloadModal';
import { Portal } from 'components/Portal';
import { PopperMenu, PopperMenuItem } from 'components/Popper';
import styles from './style/DownloadMenu.css';

type Props = {
  callId: number;
  source: {
    url: string;
    type: 'audio' | 'video';
  };
  transcriptId: number;
};

export const TranscriptDownloadMenu = ({
  callId,
  source,
  transcriptId,
}: Props) => {

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const [toggleTranscriptModal, TranscriptModal] = useTranscriptDownloadModal();

  const popupState = usePopupState({
    popupId: `transcript-actions-menu`,
    variant: 'popper',
  });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
    placement: 'bottom-start',
  });

  const download = useDownloader();

  const handleDownloadSource = useCallback(() => {
    download({ url: source.url });
    popupState.close();
  }, [
    download,
    popupState,
    source.url,
  ]);

  if (!source.url && !transcriptId) return null;

  return (
    <>
      <div
        {...bindToggle(popupState)}
        ref={setReferenceElement}>
        <Download className={styles.download} />
      </div>
      {popupState.isOpen &&
        <Portal>
          <div
            ref={setPopperElement}
            className={styles.popper}
            style={popperStyles.popper}
            {...attributes.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <PopperMenu>
                {source.url &&
                  <PopperMenuItem onClick={handleDownloadSource}>
                    Download {source.type === 'audio' ? 'Recording' : 'Video'}
                  </PopperMenuItem>}
                {transcriptId &&
                  <PopperMenuItem onClick={toggleTranscriptModal}>
                    Download Report
                  </PopperMenuItem>
                }
              </PopperMenu>
            </ClickAwayListener>
          </div>
        </Portal>
      }
      <TranscriptModal
        callId={callId}
        transcriptId={transcriptId} />
    </>
  );
};

export default TranscriptDownloadMenu;
import mixpanel from 'mixpanel-browser';
import { Report } from './interfaces';
import { getProjectTypeLabel } from './utils';

export function downloadProject({ format, project, type }: Report.DownloadProject) {
  mixpanel.track(`report:project:download-${type}`, {
    format,
    id: project.id,
    name: project.name,
    type: getProjectTypeLabel(type),
  });
}

export function downloadProjectCallSummary({ project }: Report.DownloadProjectCallSummary) {
  mixpanel.track('report:project:download-call-summary', {
    id: project.id,
    name: project.name,
  });
}
import { useCallback } from 'react';
import { SurveyActionType } from '@enums';
import {
  SurveyFormContext,
  SurveyFormStepperState,
  SurveyResponseIdentifierContext,
  SaveCompletedSurveyResponseContext,
  SaveDisqualifiedSurveyResponseContext,
} from '@containers/SurveyForm';
import { ProjectSurveyQuestion, ComplianceSurveyQuestion } from '@screens/Survey';
import { useFetchProjectSurveyForm } from '@utils/api';
import { AnimatedLoader } from 'components/ActivityIndicator';
import {
  useProjectOnboardingContext,
  useSubmitProjectSurvey,
  useCompleteSurveyProjectOnboarding,
} from './hooks';
import styles from './styles.css';

export const ProjectSurveyStepper = () => {
  const ctx = useProjectOnboardingContext();
  const {
    data: res,
    isLoading,
    isError,
  } = useFetchProjectSurveyForm({
    preview: false,
    projectId: ctx.projectId,
  }, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  const submitSurvey = useSubmitProjectSurvey(ctx.projectId);
  const completeOnboarding = useCompleteSurveyProjectOnboarding(ctx.projectId);

  const handleSubmitSurvey = useCallback(() => {
    return submitSurvey({
      responseIdentifier: res.response.identifier,
      surveyVersionId: res.survey.id,
    });
  }, [
    res?.response?.identifier,
    res?.survey?.id,
    submitSurvey,
  ]);

  const handleDisqualification = useCallback((actionType: SurveyActionType) => {
    return handleSubmitSurvey()
    .then(() => ctx.onDisqualification(actionType));
  }, [ctx.onDisqualification, handleSubmitSurvey]);

  const handleCompletion = useCallback(() => {
    return handleSubmitSurvey()
    .then(completeOnboarding)
    .then(ctx.next);
  }, [completeOnboarding, ctx.next, handleSubmitSurvey]);

  const getScreenComponent = useCallback((isScreening: boolean) => {
    return isScreening
      ? ComplianceSurveyQuestion
      : ProjectSurveyQuestion;
  }, []);

  if (isLoading) {
    return <AnimatedLoader className={styles.loading}  />;
  }

  return (
    <SurveyFormContext.Provider value={res.survey}>
      <SaveCompletedSurveyResponseContext.Provider value={handleCompletion}>
        <SaveDisqualifiedSurveyResponseContext.Provider value={handleDisqualification}>
          <SurveyResponseIdentifierContext.Provider value={res.response.identifier}>
            <SurveyFormStepperState
              eql={res.eql}
              state={res.response.state}
              getQuestionScreenComponent={getScreenComponent} />
          </SurveyResponseIdentifierContext.Provider>
        </SaveDisqualifiedSurveyResponseContext.Provider>
      </SaveCompletedSurveyResponseContext.Provider>
    </SurveyFormContext.Provider>
  );
};

export default ProjectSurveyStepper;
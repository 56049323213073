import { forwardRef, useCallback } from 'react';
import cuid from 'cuid';
import { usePopupState, bindToggle, bindPopper } from 'material-ui-popup-state/hooks';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { SurveyQuotaType } from '@enums';
import { AddQuotaButton as Button } from '@presentation/SurveyBuilder';
import { useSurveyQuotasState } from '@containers/SurveyBuilder';
import { PopperMenu, PopperMenuItem } from 'components/Popper';

type Props = {
  className?: string;
};

type MenuProps = {
  onClose: () => unknown;
};

const AddQuotaMenu = forwardRef<HTMLDivElement, MenuProps>(({ onClose }, ref) => {
  const [_, dispatch] = useSurveyQuotasState();

  const addItem = useCallback((quotaType: SurveyQuotaType) => () => {
    dispatch({
      identifier: cuid(),
      quotaType,
      type: 'add-quota-item',
    });
    onClose();
  }, [dispatch, onClose]);

  return (
    <PopperMenu ref={ref}>
      <PopperMenuItem onClick={addItem(SurveyQuotaType.Basic)}>Basic Quota</PopperMenuItem>
      <PopperMenuItem onClick={addItem(SurveyQuotaType.Advanced)}>Advanced Quota</PopperMenuItem>
    </PopperMenu>
  );
});

export const AddQuotaButton = ({ className }: Props) => {

  const popupState = usePopupState({
    popupId: 'add-quota-popper',
    variant: 'popper',
  });

  return (
    <>
      <Button
        className={className}
        {...bindToggle(popupState)} />
      <Popper {...bindPopper(popupState)} placement="bottom" style={{ zIndex: 2 }}>
        <ClickAwayListener
          onClickAway={popupState.close}>
          <AddQuotaMenu
            onClose={popupState.close} />
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default AddQuotaButton;
import { useCallback, useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import * as chart from '@containers/SurveyResponses/utils';
import { SurveyQuestionType } from '@enums';
import { RankingRankRow, ProjectVersionHeaderRow, ResponseOption } from '@presentation';
import { roundToPlace } from '@utils';
import { SurveyAggregate, SurveyQuestionOption } from '@/types';
import { SimpleAccordion } from 'components/Accordion';
import { useFilteredRankingOptions, useProjectVersion, useQueryAccordionMap } from './hooks';
import styles from './style/Data.css';

export const RankingVersionData = () => {
  const { query, setQueryState, resetQueryState } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.Ranking>();
  const { historical } = useRepeatQuestionResponsesData<SurveyQuestionType.Ranking>();
  const [isAccordionOpen, toggleAccordion] = useQueryAccordionMap('optionIds');

  const version = useProjectVersion(query.projectId);
  const options = useFilteredRankingOptions();

  const versionData = useMemo(() => {
    return historical[query.projectId].data;
  }, [
    historical,
    query.projectId,
  ]);

  const handleRankClick = useCallback((optionId: number, rank: number) => () => {
    setQueryState({
      optionIds: [optionId],
      projectId: query.projectId,
      rank,
    });
  }, [
    query,
    setQueryState,
  ]);

  const getOptionData = useCallback((option: SurveyQuestionOption) => {
    return versionData.indexes.values[option.base.id].aggregate;
  }, [versionData]);

  const getRankData = useCallback((optionId: number, rank: number) => {
    return versionData.indexes.values[optionId].keys[rank.toString()];
  }, [versionData]);

  const getOptionRanks = useCallback((optionId: number) => {
    return versionData.keys.ids
      .filter(key => versionData.indexes.values[optionId].keys[key].pct > 0)
      .map(m => +m);
  }, [
    versionData,
  ]);

  const renderRank = useCallback((optionId: number) => (rank: number) => {
    const data = getRankData(optionId, rank);

    return (
      <RankingRankRow
        key={optionId}
        onClick={handleRankClick(optionId, rank)}
        count={data.userIds.length}
        rank={rank} />
    );
  }, [
    getRankData,
    handleRankClick,
  ]);

  const renderOption = useCallback((option: SurveyQuestionOption) => {
    const data = getOptionData(option);
    const ranks = getOptionRanks(option.base.id);

    return (
      <SimpleAccordion
        key={option.base.id}
        open={isAccordionOpen(option.base.id)}
        toggleOpen={toggleAccordion(option.base.id)}
        grows={false}
        className={styles.row}
        height={50}
        label={renderAccordionLabel(data, option)}>
        {ranks.map(renderRank(option.base.id))}
      </SimpleAccordion>
    );
  }, [
    getOptionData,
    getOptionRanks,
    isAccordionOpen,
    renderRank,
    toggleAccordion,
  ]);

  const sortedOptions = useMemo(() => {
    return [...options]
      .sort((a, b) => a.ordinal - b.ordinal);
  }, [
    options,
  ]);

  return (
    <>
      <ProjectVersionHeaderRow
        className={styles.row}
        onClick={resetQueryState}
        version={version} />
      {sortedOptions.map(renderOption)}
    </>
  );
};

function renderAccordionLabel(data: SurveyAggregate.StackedBarChart.IndexAggregateData, option: SurveyQuestionOption) {
  const color = chart.options.OptionsColors[option.ordinal - 1];
  const subtitle = `(${roundToPlace(data.avg, 1)} Avg. Rank)`;

  return (
    <ResponseOption
      color={color}
      label={option.value}
      subtitle={subtitle} />
  );
}

export default RankingVersionData;
import { useState, useMemo } from 'react';
import { ProjectStatus } from '@enums';
import { useFolderCapabilities, useWorkspaceFolderState, useProjectMetricsLoading } from '@containers';
import { getLocationFor } from '@utils';
import { useProjectsTableItems } from 'components/GroupProject/hooks/useProjectsTableItems';
import ProjectsTable from './ProjectsTable';

export const FolderTabProjects = () => {
  const { folder } = useWorkspaceFolderState();
  const capabilities = useFolderCapabilities(folder.id);
  const metricsLoading = useProjectMetricsLoading();
  const [statusFilter, setStatusFilter] = useState<ProjectStatus>(ProjectStatus.Active);

  const data = useProjectsTableItems({
    parentObjectId: folder.objectId,
    status: statusFilter,
  });

  const createNewLocation = useMemo(() => getLocationFor.project.create(folder.objectId), [folder.objectId]);

  if (metricsLoading) {
    return null;
  }

  return (
    <ProjectsTable
      canCreateNew={capabilities.canAddChildren}
      createNewLocation={createNewLocation}
      data={data}
      setStatusFilter={setStatusFilter}
      statusFilter={statusFilter} />
  );
};

export default FolderTabProjects;
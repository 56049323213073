import { useMemo, CSSProperties } from 'react';
import { PositionerPortal, useHelpers } from '@remirror/react';
import { KaraokeExtension } from './Extension';
import { useKaraokePositioner } from './hooks';
import styles from './style/KaraokeMarker.css';

export const KaraokeMarker = () => {

  const position = useKaraokePositioner();
  const { getKaraokeText } = useHelpers<KaraokeExtension>();

  const markerStyles = useMemo(() => {
    const verticalPadding = 3;
    const horizontalPadding = 3;

    return {
      position: 'absolute',

      top: position.y - verticalPadding,
      left: position.x - horizontalPadding,
      width: position.width + (2 * horizontalPadding),
      height: position.height + (2 * verticalPadding),

      padding: `${verticalPadding}px ${horizontalPadding}px`,
    } as CSSProperties;
  }, [
    position.x,
    position.y,
    position.width,
    position.height,
  ]);

  return (
    <PositionerPortal>
      {position.active &&
        <div
          ref={position.ref}
          className={styles.marker}
          style={markerStyles}>
          {getKaraokeText()}
        </div>
      }
    </PositionerPortal>
  );

};
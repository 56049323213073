import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import * as Branding from '@api/interfaces/groups.branding';
import * as QKey from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';

export const useQueryDataAccessor = <T extends Branding.FetchBranding.Response>() => {
  const group = useSelectGroup();
  const qc = useQueryClient();

  const getQueryData = useCallback(() => {
    return qc.getQueryData<T>([QKey.Groups.Branding.Get, group.id]);
  }, [
    group.id,
    qc,
  ]);

  const setQueryData = useCallback((updater: Updater<T>) => {
    return qc.setQueryData<T>([QKey.Groups.Branding.Get, group.id], updater);
  }, [
    group.id,
    qc,
  ]);

  return {
    getQueryData,
    setQueryData,
  };
};

type Updater<T> = UpdaterParam<T | undefined, T>;
type UpdaterFn<I, O> = (data: I) => O;
type UpdaterParam<I, O> = O | UpdaterFn<I, O>;
import { Fragment, useCallback } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { Field, FieldProps, Form, Formik, useField, FormikHelpers } from 'formik';
import { bindHover, bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import * as Yup from 'yup';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { path } from '@consts';
import { useCoordinator } from '@containers/Conference/hooks';
import { Button } from 'components/Button';
import { PopperMenu } from 'components/Popper';
import { Input } from 'components/Input';
import styles from './style/ConferenceEntry.Guest.css';

type Props = {
  conferenceIdentifier: number;
  name?: string;
};

type FormData = {
  name: string;
  pin: string;
};

export function ConferenceEntryGuest({ conferenceIdentifier, name = '' }: Props) {
  const history = useHistory();
  const coordinator = useCoordinator();

  const handleSubmit = useCallback((data: FormData, helpers: FormikHelpers<FormData>) => {
    return new Promise<void>(resolve => {
      coordinator.negotiate({
        conferenceIdentifier,
        name: data.name,
        pin: data.pin,
      })
      .then(result => {
        if (result.success !== true) {
          if (result.reason === 'invalid-pin') {
            helpers.setErrors({
              pin: `This PIN is not correct!`,
            });
          }
          return resolve();
        }

        setTimeout(() => {
          history.replace(generatePath(path.Conference.Join, { conferenceIdentifier }));
        }, 0);
      });
    });
  }, [conferenceIdentifier, coordinator, history]);

  return (
    <Formik<FormData>
      initialValues={{
        name,
        pin: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
      isInitialValid={false}
      enableReinitialize>
      {formik => (
        <Form>
          <div className={styles.root}>
            <div className={styles.wrap}>
              <div className={styles.title}>This conference requires a PIN to join.</div>
              <div className={styles.description}>Please enter the PIN provided by the organizer</div>
              <div className={styles.form}>
                <div className={styles.input}>
                  <Field name="name">
                    {({ field, meta }: FieldProps) => (
                      <Fragment>
                        <label
                          className={styles.label}
                          htmlFor="name">
                          Your Name
                        </label>
                        <Input
                        placeholder="Your Name"
                        {...field}
                        invalid={meta.touched && !!meta.error}
                        invalidClassName={styles.inputError} />
                        <InputError field="name" />
                      </Fragment>
                    )}
                  </Field>
                </div>
                <div className={styles.input}>
                  <Field name="pin">
                    {({ field, meta }: FieldProps) => (
                      <Fragment>
                        <label
                          className={styles.label}
                          htmlFor="pin">
                          PIN
                        </label>
                        <Input
                        placeholder="Conference PIN"
                        autoComplete="off"
                        autoCorrect="off"
                        {...field}
                        invalid={meta.touched && !!meta.error}
                        invalidClassName={styles.inputError} />
                        <InputError field="pin" />
                      </Fragment>
                    )}
                  </Field>
                </div>
              </div>
              <Button
                variant="brick"
                title="Enter"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid} />
              <HelpPopper />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

type InputErrorProps = {
  field: keyof FormData;
};

function InputError({ field }: InputErrorProps) {
  const [,meta] = useField(field);

  return meta.error && meta.touched
    ? (
      <div className={styles.formError}>
        {meta.error}
      </div>
    )
    : null;
}

function HelpPopper() {
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'dont-have-a-pin',
  });

  return (
    <Fragment>
      <div
        className={styles.helpClicker}
        {...bindHover(popupState)}>
        {`Don't have a PIN?`}
      </div>
      <Popper
        {...bindPopper(popupState)}
        placement="bottom">
        <ClickAwayListener
          onClickAway={popupState.close}>
          <PopperMenu>
            <div className={styles.help}>
              <div className={styles.helpBullet}>If you are pre-authorized, please log into your Vancery account.</div>
              <div className={styles.helpBullet}>If you are not, please ask the organizer for the guest pin.</div>
            </div>
          </PopperMenu>
        </ClickAwayListener>
      </Popper>
    </Fragment>
  );
}

const FormSchema = Yup.object().shape({
  name: Yup.string()
           .min(2, 'Too short')
           .max(32, 'Maximum length is 32 characters')
           .required('Your name is required'),
  pin: Yup.string()
          .required('PIN is required'),
});
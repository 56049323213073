import { useCallback, useEffect, useState, Fragment } from 'react';
import { useSurveyQuestionViewEvent } from '@utils/analytics';
import { DynamicProgressContainer } from './DynamicProgressContainer';
import { SurveyFormQuestionContext, SurveyFormQuestionState, useSurveyFormContext } from './Context';
import { SurveyFormQuestionContainer } from './SurveyFormQuestion';

type Props = {
  eql?: number;
  getQuestionScreenComponent: (isScreening: boolean) => React.ComponentType;
  state: SurveyFormQuestionState;
};

export const SurveyFormStepperState = ({
  getQuestionScreenComponent,
  ...props
}: Props) => {
  const questionViewEvent = useSurveyQuestionViewEvent();
  const [state, setState] = useState<SurveyFormQuestionState>(props.state);
  const survey = useSurveyFormContext();

  const viewEvent = useCallback(() => {
    questionViewEvent({
      id: state.question.id,
      version: survey.id,
      value: state.question.value,
    });
  }, [
    questionViewEvent,
    state.question.id,
    state.question.value,
    survey.id,
  ]);

  useEffect(() => {
    viewEvent();
  }, [
    state.question.id,
    viewEvent,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.ordinal]);

  const renderScreen = useCallback(() => {
    const Screen = getQuestionScreenComponent(state.question.isScreening);

    return (
      <Fragment>
        <Screen />
      </Fragment>
    );
  }, [
    getQuestionScreenComponent,
    state.question.isScreening,
  ]);

  return (
    <>
      <DynamicProgressContainer
        eql={props.eql}
        ordinal={state.ordinal}
        total={survey.questions.length} />
      <SurveyFormQuestionContext.Provider value={[state, setState]}>
        <SurveyFormQuestionContainer key={state.question.id}>
          {renderScreen()}
        </SurveyFormQuestionContainer>
      </SurveyFormQuestionContext.Provider>
    </>
  );
};

export default SurveyFormStepperState;
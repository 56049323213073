import * as enums from '@enums';
import { Project, ProjectParent } from '@/types';
import { ProjectType } from '@enums';

export function canArchive(item: Project) {
  const statuses = [
    enums.ProjectStatus.Active,
  ];

  return statuses.includes(item.projectStatusId);
}

export function canActivate(item: Project) {
  const statuses = [
    enums.ProjectStatus.Archived,
  ];

  return statuses.includes(item.projectStatusId);
}

export function canManage(item: Project) {
  const statuses = [
    enums.ProjectStatus.Active,
  ];

  return statuses.includes(item.projectStatusId);
}

export function canEditSurvey(item: Project) {
  const statuses = [
    enums.ProjectStatus.Active,
  ];
  const types = [
    enums.ProjectType.Survey,
    enums.ProjectType.SurveyCall,
  ];

  return !item.isExternal && statuses.includes(item.projectStatusId) &&
    types.includes(item.projectType);
}

export function canClone(item: ProjectParent) {
  return item.frequency !== enums.ProjectFrequency.None;
}

export function isSurveyLike(item: Project) {
  return item.projectType != ProjectType.Call;
}

export function isCallLike(item: Project) {
  return item.projectType != ProjectType.Survey;
}

export function canDownload(item: Project) {
  return !item.isExternal;
}

export function canShare(item: Project) {
  const statuses = [
    enums.ProjectStatus.Active,
  ];

  return statuses.includes(item.projectStatusId);
}

export function canPreview(item: Project) {
  return !item.isExternal && isSurveyLike(item);
}

export function canMonitorSurvey(item: Project) {
  return item.projectType === enums.ProjectType.Survey;
}
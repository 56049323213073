import { useCallback } from 'react';
import { ClosedResponseOptionRow } from '@presentation/ProjectSurveyResponses';
import { formatNumber, roundToPlace } from '@utils';
import { NumberInputTableQuestion, SurveyQuestionMatrixRow } from '@/types';
import * as chart from '@containers/SurveyResponses/utils';
import { Accordion } from 'components/Accordion';
import styles from './style/NumberInputTable.Data.css';

type Props = {
  colors?: string[];
  item: NumberInputTableQuestion.Question;
  onClick: (rowId: number, optionId: number) => void;
  responses: NumberInputTableQuestion.Aggregate.Data;
};

export const NumberInputTableAggregateData = ({ colors = chart.options.OptionsColors, item, onClick, responses }: Props) => {

  const renderLabel = useCallback((row: SurveyQuestionMatrixRow) => () => {
    const avg = responses[row.base.id].avg;

    const subtitle = `(Avg: ${formatNumber(roundToPlace(avg, 1), 1)})`;

    return (
      <div className={styles.labelWrap}>
        <span className={styles.label}>{row.value}</span>
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
    );
  }, [
    responses,
  ]);

  const renderItem = useCallback((row: SurveyQuestionMatrixRow) => {

    return (
      <Accordion
        key={row.id}
        collapsed
        height={50}
        grows={false}
        className={styles.row}
        showCount={false}
        label={renderLabel(row)}>
        {item.options.map((o, i) => {
          const avg = responses[row.base.id].options[o.base.id].avg;
          const subtitle = `(Avg: ${formatNumber(roundToPlace(avg, 1), 1)})`;

          return (
            <ClosedResponseOptionRow
              key={o.id}
              className={styles.clickable}
              color={colors[i]}
              label={o.value}
              subtitle={subtitle}
              onViewAll={() => onClick(row.base.id, o.base.id)}
              onClick={() => onClick(row.base.id, o.base.id)} />
          );
        })}
      </Accordion>
    );
  }, [
    colors,
    item.options,
    onClick,
    renderLabel,
    responses,
  ]);

  return (
    <>
      {item.matrixRows.map(renderItem)}
    </>
  );
};

export default NumberInputTableAggregateData;
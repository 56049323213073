import { useCallback, useContext, useRef, forwardRef } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import * as api from '@api';
import * as API from '@api/interfaces';
import { useSelectGroup } from '@containers/Store';
import { GroupContactsQueryContext, RemoveContactsContext } from '@containers/Group.Contacts/Context';
import styles from './style/Leads.css';

type Props = {
  children:  React.ReactNode;
  disabled?: boolean;
  onClose:   () => void;
};

export const Remove = ({ onClose, ...props }: Props) => {

  const { toggle } = useContext(RemoveContactsContext);

  const handleClick = useCallback(() => {
    onClose();
    toggle();
  }, [
    onClose,
    toggle,
  ]);

  return (
    <div>
      <button
        className={styles.btn}
        disabled={props.disabled}
        onClick={handleClick}>
        {props.children}
      </button>
    </div>
  );
};

Remove.displayName = 'Group.Contacts.Table.Toolbar.Action.Remove';
import { path } from '@consts';
import { RouteAuth } from '@enums';
import { StateRouter } from '@routes/Routers';
import {
  Routing,
} from '@containers';
import { Workspaces } from '@screens/Workspaces';
import { WorkspaceFilePreview } from '@screens/WorkspaceFilePreview';
import { Search } from '@screens/WorkspaceGlobalSearch';
import * as utils from '@utils';

export const routes: Routing.Route[] = [
  {
    component: WorkspaceFilePreview,
    exact: true,
    path: path.Workspaces.File,
  },
  {
    component: Search,
    path: path.Workspaces.Search,
  },
  {
    component: Workspaces,
    path: path.Workspaces.Root,
  },
];

const validator = (state: Store.State) => {
  return utils.hasClientRole(state.user) && state.group.features.workspaces
       ? routes
       : [];
};

export const router = StateRouter(validator, {
  auth: RouteAuth.Authenticated,
  path: path.Workspaces.Root,
});
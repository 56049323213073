import * as types from '@store/action-types';
import { objects as initialState } from '@store/initial-state';
import {
  ApplicationActions,
  ProjectCreatedAction,
  Files,
  Folders,
  Objects,
  ProjectOverviewUpdatedAction,
} from '@store/interfaces';

type Action =
  ApplicationActions |
  ProjectCreatedAction |
  ProjectOverviewUpdatedAction |
  Objects.ObjectOwnerChanged.Action |
  Folders.FolderCreated.Action |
  Folders.FolderDeleted.Action |
  Folders.FolderRenamed.Action |
  Files.FileCreated.Action |
  Files.FileVersionCreated.Action |
  Files.FileDeleted.Action |
  Objects.ObjectMoved.Action |
  Files.FileRenamed.Action
;

export function objectCapabilitiesReducer(state = initialState.capabilities, action: Action): Store.Objects.Capabilities {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.objects.capabilities;

    case types.OBJECT_MOVED:
    case types.PROJECT_CREATED:
    case types.FOLDER_CREATED:
    case types.OBJECT_OWNER_CHANGED:
    case types.FILE_CREATED:
      return {
        ...state,
        [action.object.id]: action.capabilities,
      };

    case types.FILE_DELETED:
    case types.FOLDER_DELETED: {
      const { [action.objectId]: _, ...other } = state;

      return other;
    }

    default:
      return state;
  }
}

export function objectValuesReducer(state = initialState.values, action: Action): Store.Objects.Values {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.objects.values;

    case types.PROJECT_CREATED:
    case types.OBJECT_OWNER_CHANGED:
    case types.FOLDER_CREATED:
    case types.FOLDER_RENAMED:
    case types.OBJECT_MOVED:
    case types.PROJECT_OVERVIEW_UPDATED:
    case types.FILE_CREATED:
    case types.FILE_VERSION_CREATED:
    case types.FILE_RENAMED:
      return {
        ...state,
        [action.object.id]: action.object,
      };

    case types.FILE_DELETED:
    case types.FOLDER_DELETED: {
      const { [action.objectId]: _, ...other } = state;

      return other;
    }

    default:
      return state;
  }
}

export function objectIdsReducer(state = initialState.ids, action: Action): Store.Objects.Ids {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
      return action.objects.ids;

    case types.PROJECT_CREATED:
    case types.FOLDER_CREATED:
    case types.FILE_CREATED:
      return [
        ...state,
        action.object.id,
      ];

    case types.FILE_DELETED:
    case types.FOLDER_DELETED:
      return state.filter(f => f !== action.objectId);

    default:
      return state;
  }
}

export function objectsReducer(state = initialState, action: Action) {
  return {
    ids: objectIdsReducer(state.ids, action),
    capabilities: objectCapabilitiesReducer(state.capabilities, action),
    values: objectValuesReducer(state.values, action),
  };
}

export default objectsReducer;
import { memo } from 'react';
import { ProjectIconProps } from './interfaces';

export const SurveyProjectIcon = memo(({ className, size }: ProjectIconProps) => {

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 25">
      <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g id="Group-10" transform="translate(5.000000, 6.000000)" strokeWidth="2">
          <line x1="0.870862531" y1="14.5" x2="14.4638744" y2="14.5" id="Path-5" stroke="#838383" />
          <line x1="7.5" y1="10" x2="7.5" y2="3.46944695e-15" id="Path-4" stroke="#0A68EF" />
          <line x1="2.5" y1="10" x2="2.5" y2="5" id="Path-4" stroke="#1DB955" />
          <line x1="12.5" y1="10" x2="12.5" y2="3" id="Path-4" stroke="#E92D21" />
        </g>
      </g>
    </svg>
  );
});

export default SurveyProjectIcon;
import { useCallback, useContext, useRef } from 'react';
import { useActive, useChainedCommands, useCommands, useCurrentSelection } from '@remirror/react';
import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import InsertLink from '@mui/icons-material/InsertLink';
import InsertPhoto from '@mui/icons-material/InsertPhotoOutlined';
import { LinkShortcutContext } from 'components/Remirror/Context';
import { Button as ToolbarButton } from './Toolbar.Button';
import { Heading } from './Toolbar.Heading';
import styles from './style/Toolbar.css';

type Props = unknown;

export const Commands = (props: Props) => {

  return (
    <div className={styles.commands}>
      <Heading />
      <BoldButton />
      <ItalicButton />
      <UnderlineButton />
      <ListBulletedButton />
      <ListOrderedButton />
      <LinkButton />
      <ImageButton />
    </div>
  );
};

Commands.displayName = 'Toolbar.Commands';

function uploadFile(file: File) {
  const reader = new FileReader();

  return new Promise<{ src: string; }>((resolve, reject) => {
    reader.onload = () => resolve({ src: reader.result as string });
    reader.onerror = () => reject(reader.error);
    setTimeout(() => reader.readAsDataURL(file), 1500);
  });
}

const ImageButton = (props: unknown) => {
  const input = useRef<HTMLInputElement>();
  const commands = useCommands();

  const imageHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (!files) return;

    const file = files[0];
    commands.uploadImage(async () => {
      const result = await uploadFile(file);

      return result;
    });
  }, [
    commands,
  ]);

  return (
    <ToolbarButton>
      <InsertPhoto className={styles.icon} />
      <input
        className={styles.input}
        accept="image/png,image/jpeg,image/gif"
        name="image"
        onChange={imageHandler}
        onClick={() => input.current.value = ''}
        ref={input}
        type="file" />
    </ToolbarButton>
  );
};

const LinkButton = (props: unknown) => {
  const active = useActive();
  const chain = useChainedCommands();
  const selection = useCurrentSelection();
  const { setEditing } = useContext(LinkShortcutContext);

  const handleEdit = useCallback(() => {
    if (selection.empty) {
      console.log('empty');
      chain.selectLink();
    }

    setEditing(true);
  }, [
    chain,
    selection,
    setEditing,
  ]);

  return (
    <ToolbarButton
      enabled={active.link()}
      onClick={handleEdit}>
      <InsertLink />
    </ToolbarButton>
  );
};

const ListBulletedButton = (props: unknown) => {
  const active = useActive();
  const { toggleBulletList } = useCommands();
  const chain = useChainedCommands();

  const onClick = useCallback(() => {
    chain
      .toggleBulletList()
      .focus()
      .run();
  }, [chain]);

  return (
    <ToolbarButton
      disabled={toggleBulletList.enabled() === false}
      enabled={active.bulletList()}
      onClick={onClick}>
      <FormatListBulleted />
    </ToolbarButton>
  );
};

const ListOrderedButton = (props: unknown) => {
  const active = useActive();
  const { toggleOrderedList } = useCommands();
  const chain = useChainedCommands();

  const onClick = useCallback(() => {
    chain
      .toggleOrderedList()
      .focus()
      .run();
  }, [chain]);

  return (
    <ToolbarButton
      disabled={toggleOrderedList.enabled() === false}
      enabled={active.orderedList()}
      onClick={onClick}>
      <FormatListNumbered />
    </ToolbarButton>
  );
};

const BoldButton = (props: unknown) => {
  const active = useActive();
  const { toggleBold } = useCommands();
  const chain = useChainedCommands();

  const onClick = useCallback(() => {
    chain
      .toggleBold()
      .focus()
      .run();
  }, [chain]);

  return (
    <ToolbarButton
      disabled={toggleBold.enabled() === false}
      enabled={active.bold()}
      onClick={onClick}>
      <FormatBold />
    </ToolbarButton>
  );
};

const ItalicButton = (props: unknown) => {
  const active = useActive();
  const { toggleItalic } = useCommands();
  const chain = useChainedCommands();

  const onClick = useCallback(() => {
    chain
      .toggleItalic()
      .focus()
      .run();
  }, [chain]);

  return (
    <ToolbarButton
      disabled={toggleItalic.enabled() === false}
      enabled={active.italic()}
      onClick={onClick}>
      <FormatItalic />
    </ToolbarButton>
  );
};

const UnderlineButton = (props: unknown) => {
  const active = useActive();
  const { toggleUnderline } = useCommands();
  const chain = useChainedCommands();

  const onClick = useCallback(() => {
    chain
      .toggleUnderline()
      .focus()
      .run();
  }, [chain]);

  return (
    <ToolbarButton
      disabled={toggleUnderline.enabled() === false}
      enabled={active.underline()}
      onClick={onClick}>
      <FormatUnderlined />
    </ToolbarButton>
  );
};
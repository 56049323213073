import { useSelector } from 'react-redux';
import * as consts from '@consts';
import { Project, ProjectGroupProps } from '@/types';
import { Button } from 'components/Button';
import { Meter } from 'components/Progress/Meter';
import { ProjectGoalMessage } from './ProjectGoalMessage';
import { ProjectQueryStats } from './ProjectQueryStats';
import { useCalcProjectGoal } from './useCalcProjectGoal';
import styles from './Project.css';

const mapState = (state: Store.State) => ({
  projects: state.projects,
});

type Props = {
  project: Project<ProjectGroupProps>;
  onClick: () => unknown;
};

const ProjectSearchListHeader = (props: Props) => {
  const goal = useCalcProjectGoal(props.project.id);
  const { projects } = useSelector(mapState);
  const project = projects[props.project.id] as Project<ProjectGroupProps>;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.title}>{project.name}</div>

          <div className={styles.feedback}>
            <div className={styles.info}>
              <ProjectQueryStats project={props.project} />
              <Meter
                className={styles.meter}
                min={2}
                orientation="horizontal"
                value={goal?.progress ?? 0} />
            </div>

            <ProjectGoalMessage item={goal} />
          </div>

          <div className={styles.toggle}>
            <Button
              color="primary"
              onClick={props.onClick}>
              Filters
            </Button>
          </div>

          <div className={styles.back}>
            <Button
              className={styles.pipeline}
              to={`${consts.pathname.PROJECTS}/${project.slug}`}>
              Go to Pipeline
            </Button>
          </div>

        </div>
      </div>
    </div>
  );
};

export { ProjectSearchListHeader };
export default ProjectSearchListHeader;
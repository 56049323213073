import { useCallback } from 'react';
import { PopupState as PopupStateProps } from 'material-ui-popup-state/core';
import { useShowFolderMenu } from '@containers/WorkspaceFolders/hooks/useShowFolderMenu';
import { Breadcrumb } from '@presentation/Breadcrumbs';
import { cx, trunc } from '@utils';
import { WorkspaceFolder } from '@/types';
import { FolderActionsContextMenuPopper } from 'components/WorkspaceFolders/FolderActionsContextMenu';
import styles from './style/Breadcrumbs.css';

type Props = {
  folder: WorkspaceFolder;
};

export const FolderBreadcrumb = ({ folder }: Props) => {

  const showMenu = useShowFolderMenu();

  const renderAnchor = useCallback((state: PopupStateProps) => {
    const className = cx(styles.folder, {
      [styles.open]: state.isOpen,
    });
    return (
      <Breadcrumb className={className}>
        {trunc(folder.name, 35)}
      </Breadcrumb>
    );
  }, [folder]);

  if (showMenu) {
    return (
      <FolderActionsContextMenuPopper
        renderAnchor={renderAnchor} />
    );
  } else {
    return (
      <Breadcrumb>
        {trunc(folder.name, 35)}
      </Breadcrumb>
    );
  }
};

export default FolderBreadcrumb;
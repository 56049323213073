import { TranscriptFocusedCommentContainer } from './Comments.Focus';
import { TranscriptCommentPositionsContainer } from './Comments.Positions';
import { TranscriptCommentsState } from './Comments.State';

type Props =
  ChildrenProps;

export const TranscriptCommentsContainer = (props: Props) => {

  return (
    <TranscriptCommentsState>
      <TranscriptFocusedCommentContainer>
        <TranscriptCommentPositionsContainer>
          {props.children}
        </TranscriptCommentPositionsContainer>
      </TranscriptFocusedCommentContainer>
    </TranscriptCommentsState>
  );
};
import { useCallback, useEffect, useMemo } from 'react';
import * as enums from '@enums';
import { GrayOutlineButton } from '@presentation/Buttons';
import {
  useObjectAccessLoading, useObjectAccessState,
  useSaveObjectAccess, useCanSaveObjectAccess, useFetchObjectAccess,
  useGroupUsersContext,
} from '@containers/ObjectAccess';
import { useLock } from '@utils';
import { GroupUser, ObjectAccessUser } from '@/types';
import { Button } from 'components/Button';
import UserObjectAccessPopper from './UserAccessContextMenu';
import GroupUsersAutocomplete from './GroupUsersAutocomplete';
import { sortObjectAccess } from './utils';
import styles from './style/ObjectAccess.css';

type Props = {
  onCancel: () => unknown;
};

export const ObjectAccessForm = ({ onCancel }: Props) => {
  const loading = useObjectAccessLoading();
  const [state, dispatch] = useObjectAccessState();
  const canSave = useCanSaveObjectAccess();
  const saveAccess = useSaveObjectAccess();
  const [busy, lock] = useLock();
  const fetchAccess = useFetchObjectAccess();
  const { fetch: fetchGroupUsers } = useGroupUsersContext();

  useEffect(() => { fetchGroupUsers(); }, [fetchGroupUsers]);
  useEffect(() => { fetchAccess(); }, [fetchAccess]);

  const sorted = useMemo(() => state.items.sort(sortObjectAccess), [state.items]);

  const renderRow = useCallback((item: ObjectAccessUser) => {
    const name = [item.user.firstName, item.user.lastName].join(' ');

    return (
      <div key={item.user.id} className={styles.row}>
        <div className={styles.col}>{name}</div>
        <div className={styles.col}>
          {item.roleId === enums.WorkspaceObjectRole.Owner
            ? <div className={styles.owner}>
                {enums.utils.WorkspaceObjectRole.getName(item.roleId)}
              </div>
            : <UserObjectAccessPopper item={item} />
          }
        </div>
      </div>
    );
  }, []);

  const addUser = useCallback((data: GroupUser) => {
    dispatch({
      value: {
        roleId: enums.WorkspaceObjectRole.Viewer,
        user: {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
        },
      },
      type: 'add',
    });
  }, [dispatch]);

  const handleSave = useCallback(() => {
    return saveAccess()
      .then(onCancel);
  }, [
    onCancel,
    saveAccess,
  ]);

  return (
    <div className={styles.root}>
      <GroupUsersAutocomplete
        className={styles.autocomplete}
        onSelect={addUser} />
      <div className={styles.header}>
        <div className={styles.col}>Who has access</div>
        <div className={styles.col} />
      </div>
      <div className={styles.body}>
        {!loading && sorted.map(renderRow)}
      </div>
      <div className={styles.footer}>
        <GrayOutlineButton
          className={styles.btn}
          onClick={onCancel}
          title="Cancel" />
        <Button.Affirmative
          className={styles.btn}
          disabled={!canSave}
          onClick={lock(handleSave)}
          variant="brick"
          title="Save" />
      </div>
    </div>
  );
};

export default ObjectAccessForm;
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ObjectAccessContainer } from '@containers/ObjectAccess';
import { MoveObjectContainer, ObjectOwnershipContainer } from '@containers/WorkspaceObject';
import { useSelectProject, useSelectProjectParent, useSelectObject, useProjectCapabilities } from '@containers/Store';
import { getLocationFor } from '@utils';
import { Project } from '@/types';
import { useProjectReportModal } from 'components/GroupProject/ProjectReportModal';
import { useExportCallModal, useExportSurveyModel } from 'components/GroupProject/ExportProjectDataModal';
import { useCloneProjectPrompt } from 'components/GroupProject/hooks/useCloneProjectPrompt';
import { useShareWithExpertModal } from 'components/Modal/ShareProjectWithExpert/useShareWithExpertModal';
import { useObjectAccessModal } from 'components/ObjectAccess/hooks/useObjectAccessModal';
import { useObjectOwnershipModal, useMoveObjectModal } from 'components/WorkspaceObject/hooks';
import { ProjectActionsContext, ProjectActionsValidityContext } from './Context';
import { useActivateGroupProjectPrompt, useArchiveGroupProjectPrompt, useProjectActionsValidity } from './hooks';
import * as utils from './utils';

const mapState = (state: Store.State) => state.group.features;

type ValidityProps = {
  children: React.ReactNode;
  project: Project;
};

type Props = {
  children: React.ReactNode;
  actionOnParent?: boolean;
} & IProjectId;

export const ProjectActionsValidityContainer = ({ children, project }: ValidityProps) => {
  const projectParent = useSelectProjectParent(project.parentId);
  const capabilities = useProjectCapabilities(project.id);
  const groupFeatures = useSelector(mapState);

  const value = useMemo(() => ({
    canActivate: utils.canActivate(project) && capabilities.canManage,
    canArchive: utils.canArchive(project) && capabilities.canManage,
    canChangeOwner: capabilities.canChangeOwner,
    canClone: utils.canClone(projectParent) && capabilities.canManage,
    canDownloadCalls: utils.canDownload(project) && utils.isCallLike(project),
    canDownloadSurveys: utils.canDownload(project) && utils.isSurveyLike(project),
    canDownloadReport: utils.canDownload(project),
    canEditSurvey: utils.canEditSurvey(project) && capabilities.canManage,
    canManage: utils.canManage(project) && capabilities.canManageAccess,
    canMonitorSurvey: utils.canMonitorSurvey(project),
    canMove: groupFeatures.workspaces && capabilities.canMove,
    canShare: utils.canShare(project),
    canPreview: utils.canPreview(project),
  }), [
    capabilities,
    groupFeatures.workspaces,
    project,
    projectParent,
  ]);

  return (
    <ProjectActionsValidityContext.Provider value={value}>
      {children}
    </ProjectActionsValidityContext.Provider>
  );
};

export const ProjectActionsContainer = ({ actionOnParent, projectId, children }: Props) => {
  const project = useSelectProject(projectId);
  const projectParent = useSelectProjectParent(project.parentId);
  const object = useSelectObject(projectParent.objectId);

  const validity = useProjectActionsValidity();

  const history = useHistory();

  const [toggleAccessModal, ProjectAccessModal] = useObjectAccessModal();
  const [toggleActivatePrompt, ActivateProjectPrompt] = useActivateGroupProjectPrompt({ project });
  const [toggleArchivePrompt, ArchiveProjectPrompt] = useArchiveGroupProjectPrompt({ project });
  const [toggleClonePrompt, CloneProjectPrompt] = useCloneProjectPrompt({ project });
  const [toggleShareModal, ShareWithExpertModal] = useShareWithExpertModal({ projectId: project.id });
  const [toggleOwnershipModal, OwnershipModal] = useObjectOwnershipModal();
  const [toggleMoveProjectModal, MoveProjectModal] = useMoveObjectModal();
  const [toggleReportModal, ReportModal] = useProjectReportModal();
  const [toggleCallReportModal, CallReportModal] = useExportCallModal();
  const [toggleSurveyReportModal, SurveyReportModal] = useExportSurveyModel();

  const editSurvey = useCallback(() => {
    history.push(getLocationFor.project.surveyEditor(project));
  }, [
    history,
    project,
  ]);

  const monitorSurvey = useCallback(() => {
    history.push(getLocationFor.project.surveyMonitoring(project));
  }, [
    history,
    project,
  ]);

  const value = useMemo(() => ({
    activate: toggleActivatePrompt,
    archive: toggleArchivePrompt,
    changeOwner: toggleOwnershipModal,
    clone: toggleClonePrompt,
    editSurvey,
    downloadCallReport: toggleCallReportModal,
    downloadSurveyReport: toggleSurveyReportModal,
    downloadReport: toggleReportModal,
    manage: toggleAccessModal,
    move: toggleMoveProjectModal,
    share: toggleShareModal,
    projectId,
    monitorSurvey,
  }), [
    editSurvey,
    toggleAccessModal,
    toggleActivatePrompt,
    toggleArchivePrompt,
    toggleCallReportModal,
    toggleClonePrompt,
    toggleMoveProjectModal,
    toggleOwnershipModal,
    toggleReportModal,
    toggleShareModal,
    toggleSurveyReportModal,
    projectId,
    monitorSurvey,
  ]);

  return (
    <>
      <ProjectActionsContext.Provider value={value}>
        {children}
      </ProjectActionsContext.Provider>
      {validity.canManage &&
        <ObjectAccessContainer objectId={object.id} workspaceId={object.workspaceId}>
          <ProjectAccessModal />
        </ObjectAccessContainer>}
      <ReportModal
        isParentReport={actionOnParent}
        project={project} />
      <CallReportModal
        isParentReport={actionOnParent}
        project={project} />
      <SurveyReportModal
        isParentReport={actionOnParent}
        project={project} />
      {validity.canArchive && <ArchiveProjectPrompt />}
      {validity.canActivate && <ActivateProjectPrompt />}
      {validity.canClone && <CloneProjectPrompt />}
      {validity.canShare && <ShareWithExpertModal />}
      {validity.canChangeOwner &&
        <ObjectOwnershipContainer objectId={object.id} workspaceId={object.workspaceId}>
          <OwnershipModal />
        </ObjectOwnershipContainer>}
      {validity.canMove &&
        <MoveObjectContainer objectId={object.id}>
          <MoveProjectModal />
        </MoveObjectContainer>}
    </>
  );
};

export const ProjectActionsState = ({ children, ...props }: Props) => {
  const project = useSelectProject(props.projectId);

  return (
    <ProjectActionsValidityContainer project={project}>
      <ProjectActionsContainer {...props}>
        {children}
      </ProjectActionsContainer>
    </ProjectActionsValidityContainer>
  );
};

export default ProjectActionsContainer;
import { useCallback } from 'react';
import * as api from '@api';
import useSearchLoadingDispatch from './useSearchLoadingDispatch';
import useSearchResultsDispatch from './useSearchResultsDispatch';

export default function useSearchFetchMembers() {
  const setResults = useSearchResultsDispatch();
  const setLoading = useSearchLoadingDispatch();

  const members = useCallback(async ({ query, size }: FetchData) => {
    const result = await api.workspaces.globalSearch.members({
      query,
      size,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        members: result,
      }));
    }
  }, [setResults]);

  const fetch = useCallback(({ query, size }: FetchData) => {
    setLoading(prev => ({ ...prev, members: { ...prev.members, loading: true } }));
    Promise.all([
      new Promise<void>(resolve => setTimeout(() => resolve(), 500)),
      members({ query, size }),
    ])
    .then(() => {
      setLoading(prev => ({ ...prev, members: { loading: false } }));
    });
  }, [members, setLoading]);

  return fetch;
}

export { useSearchFetchMembers };

type FetchData = {
  query: string;
  size: number;
}
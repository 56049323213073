import { useContext, useState } from 'react';
import { X } from 'react-feather';
import { ProjectResponseFilterContext } from '@containers/GroupProject/Context';
import { SurveyFiltering } from '@/types';
import { SurveyQuestionType } from '@enums';
import { cx } from '@utils';
import { FilterProps } from './utils';
import styles from './style/SurveyResponseFilter.css';
import { Selector } from './Selector';
import { useValidFilterQuestions } from './hooks';

export const TextFilter = ({ filterEntry, filterActions }: FilterProps<SurveyQuestionType.LongTextResponse>) => {
  const filterContext = useContext(ProjectResponseFilterContext);

  const [shouldContain, setShouldContain] = useState<boolean>(filterEntry.filter ? filterEntry.filter.shouldContain : true);
  const [textFilter, setTextFilter] = useState(filterEntry.filter?.text || '');
  const validQuestions = useValidFilterQuestions();

  function onQuestionChange(questionId: number) {
    filterActions.changeFilter({
      questionId,
      filter: null,
      order: filterEntry.order,
    });
  }

  function passbackFilter(partialFilter: Partial<SurveyFiltering.QuestionFilter<SurveyQuestionType.LongTextResponse>>) {
    filterActions.changeFilter({
      questionId: filterEntry.questionId,
      order: filterEntry.order,
      filter: { ...{
        type: 'text-filter',
        shouldContain,
        text: textFilter,
      }, ...partialFilter },
    });
  }

  function changeShouldContain(v: boolean) {
    setShouldContain(v);
    passbackFilter({ shouldContain: v });
  }

  function changeTextFilter(v: string) {
    setTextFilter(v);
    passbackFilter({ text: v });
  }

  return (
    <div className={styles.filterRow} key={filterEntry.questionId}>
      <Selector
        buttonClass={styles.questionSelectorButton}
        items={validQuestions}
        displayValue={v => `Q${v.ordinal}`}
        onSelect={q => onQuestionChange(q.base.id)}
        value={filterContext.questionData.find(q => q.base.id == filterEntry.questionId)} />
      <Selector
        items={[true, false]}
        displayValue={v => v ? 'Contains' : 'Does Not Contain'}
        onSelect={changeShouldContain}
        value={shouldContain} />
      <input className={cx(styles.filterRowInput, styles.fancyTextInput)} type={'text'} onChange={e => changeTextFilter(e.target.value)} value={textFilter} placeholder={'Input Text'} />
      <div className={styles.deleteFilter} onClick={filterActions.deleteFilter}>
        <X />
      </div>
    </div>
  );
};
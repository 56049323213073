import qs from 'query-string';
import http from '@services/http';
import {
  FetchCalendarSummariesRequest,
  FetchCalendarSummariesResponse,
  FetchPersonalCalendarRequest,
  FetchPersonalCalendarResponse,
  RescheduleCallRequest,
  RescheduleCallResponse,
  Scheduling,
  UpdateCallSchedulingRolesRequest,
  UpdateCallSchedulingRolesResponse,
  UpdatePersonalCalendarRequest,
  UpdatePersonalCalendarResponse,
} from '$admin/api/interfaces';

export const cancelCall = (data: Scheduling.CancelCall.Request): Promise<Scheduling.CancelCall.Response> => {
  return http.put(`/admin/scheduling/calls/${data.callId}/cancellations`, data);
};

export const uncancelCall = (data: Scheduling.UncancelCall.Request): Promise<Scheduling.UncancelCall.Response> => {
  return http.put(`/admin/scheduling/calls/${data.callId}/cancellations/revert`, data);
};

export const rescheduleCall = (data: RescheduleCallRequest): Promise<RescheduleCallResponse> => {
  return http.put(`/admin/scheduling/calls/${data.callId}/proposals`, data);
};

export const fetchCalendarSummaries = (data: FetchCalendarSummariesRequest): Promise<FetchCalendarSummariesResponse> => {
  return http.get(`/admin/scheduling/users/calendars?${qs.stringify({ ids: data.userIds }, { arrayFormat: 'bracket' })}`);
};

export const fetchPersonalCalendar = (data: FetchPersonalCalendarRequest): Promise<FetchPersonalCalendarResponse> => {
  return http.get(`/admin/scheduling/users/${data.userId}`);
};

export const setConfirmedCallTime = (data: Scheduling.SetConfirmedCallTime.Request): Promise<Scheduling.SetConfirmedCallTime.Response> => {
  const q = data.notify ? `?${qs.stringify({ notify: '1' })}` : '';

  return http.put(`/admin/scheduling/calls/${data.callId}/confirmations${q}`, data);
};

export const updateCallSchedulingRoles = (data: UpdateCallSchedulingRolesRequest): Promise<UpdateCallSchedulingRolesResponse> => {
  return http.put(`/admin/scheduling/calls/${data.callId}/roles`, data);
};

export const updatePersonalCalendar = (data: UpdatePersonalCalendarRequest): Promise<UpdatePersonalCalendarResponse> => {
  return http.patch(`/admin/scheduling/users/${data.userId}`, data);
};
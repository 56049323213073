import { createRef, Component } from 'react';
import { useSelector } from 'react-redux';
import { format, isToday, isSameDay } from 'date-fns';
import NavigationOutlinedIcon from '@mui/icons-material/NavigationOutlined';
import * as Stripe from '@screens/Stripe';
import * as selectors from '@store/selectors';
import { hasClientRole } from '@utils';
import { useUserNewPaymentMethod } from '@utils/api';
import { IChat } from '@/types';
import { Button } from 'components/Button';
import { BeforeReceivePayment, StripeConnectPayment } from 'components/Modal/Stripe';
import Toast from 'components/Toast';
import { useStripeAccountId } from 'components/Stripe/hooks/useStripeAccountId';
import { ChannelHeader } from './ChannelHeader';
import MessageContainer from './MessageContainer';
import PaidMessageButton from './PaidMessageButton';
import styles from './style/Channel.css';

const mapState = (state: Store.State) => ({
  contacts: state.contacts,
  user: state.user,
  connections: state.chat.connections,
  group: state.group,
  participants: state.chat.participants,
  messages: selectors.selectMessageItems(state),
});

type Props = {
  conversation: IChat.DirectoryItemConversation;
  participant: IChat.DirectoryItemParticipant;
  onClose: () => unknown;
  onDelete: (channelSid: string) => unknown;
  onScheduleCall: () => unknown;
  sendMessage: (data: { body: string; paid: boolean; }) => unknown;
};

type OwnProps = {
  hasStripeAccount: boolean;
  saveNewMethod: ReturnType<typeof useUserNewPaymentMethod>;
}
& Props
& ReturnType<typeof mapState>;

class ChannelComponent extends Component<OwnProps> {
  state = {
    input: '',
    paid: false,
    sendModal: false,
    receiveModal: false,
  };

  lastMessageRef = createRef<HTMLDivElement>();
  inputRef = createRef<HTMLTextAreaElement>();

  componentDidMount() {
    this.scrollToLatestMessage();
  }

  componentDidUpdate(prevProps: OwnProps) {
    const oldCount = (prevProps.messages[prevProps.conversation.state.sid] || []).length;
    const newCount = (this.props.messages[this.props.conversation.state.sid] || []).length;
    if (prevProps.conversation.state.sid !== this.props.conversation.state.sid || oldCount !== newCount) {
      this.scrollToLatestMessage();
    }
  }

  scrollToLatestMessage = () => {
    if (this.lastMessageRef.current) {
      setTimeout(() => {
        this.lastMessageRef.current.scrollIntoView({
          block: 'center',
        });
      }, 0);
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDelete = () => {
    this.props.onDelete(this.props.conversation.state.sid);
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const carriageReturn = window.innerWidth < 1100;

    if (e.key === 'Enter') {
      if (!carriageReturn) {
        e.preventDefault();
        this.handleSubmit();
      }
    }
  };

  isPaidMessageResponse = () => {
    if (hasClientRole(this.props.participant.contact.persona)) return false;

    const messages = this.props.messages[this.props.conversation.state.sid];
    const lastMessage = messages.length
      ? messages[messages.length - 1]
      : null;

    if (!lastMessage) return false;

    return lastMessage.data.userId !== this.props.user.id
        && lastMessage.type !== 'platform' && lastMessage.type !== 'pending'
        && lastMessage.data.attributes.paid;
  };

  handleSubmit = () => {
    if (!this.isSendingEnabled()) return;

    this.inputRef.current.focus();

    const value = this.state.input.trim();
    if (!value.length) return;

    if (this.isPaidMessageResponse() && !this.props.hasStripeAccount) {
      setTimeout(() => {
        this.setState({ receiveModal: true });
      }, 2000);
    }

    this.props.sendMessage({
      body: value,
      paid: this.state.paid,
    });

    this.setState({ input: '' });
  };

  renderItems = () => {
    const items = (this.props.messages[this.props.conversation.state.sid] ?? []).filter(i => i.type === 'twilio' || i.type === 'platform');

    const members = this.getMembers();
    const renderTimeBreak = this.renderTimeBreak(items);

    return items
      .map(((item: IChat.ResolvedMessage, index) => {
        const messageKey = item.type === 'platform'
          ? item.data.attributes.messageId
          : item.data.sid;

        return (
          <MessageContainer
            containerRef={this.lastMessageRef}
            item={item}
            key={messageKey}
            members={members}
            onClose={this.props.onClose}>
            {renderTimeBreak(item, index)}
          </MessageContainer>
        );
      }));
  };

  renderTimeBreak = (messages: IChat.Message[]) => (message: IChat.Message, index: number) => {
    const createTimeBreak = (item: IChat.Message) => {
      const text = isToday(item.data.timestamp)
                ? 'Today'
                : format(item.data.timestamp, 'EEEE, MMMM, do');

      return (
        <div className={styles.timebreak}>
          <div className={styles.date}>{text}</div>
        </div>
      );
    };

    const prevItem = messages[index - 1];

    if (!prevItem) {
      return createTimeBreak(message);
    }

    if (isSameDay(message.data.timestamp, prevItem.data.timestamp)) {
      return null;
    }

    return createTimeBreak(message);
  };

  getMembers = () => {
    type GetMembers = {
      [userId: number]: {
        currentUser: boolean;
        connection: boolean;
        userId: number;
        name: string;
      };
    } & { ids: number[]; };

    const { id, profile } = this.props.user;

    const buildMember = (userId: number) => {
      if (userId === id) {
        return {
          currentUser: true,
          connection: null,
          userId,
          name: profile.fullname,
        };
      }

      return {
        currentUser: false,
        connection: this.props.connections.ids.includes(userId),
        userId,
        name: this.props.contacts[userId].profile.fullname,
      };
    };

    const attributes = this.props.conversation.state.attributes as IChat.ConversationAttributes;
    return attributes.userIds.reduce<GetMembers>((acc, userId) => {
      return {
        ...acc,
        [userId]: buildMember(userId),
        ids: acc.ids.concat(userId),
      };
    }, { ids: [] });
  };

  isSendingEnabled = () => {
    const messages = this.props.messages[this.props.conversation.state.sid];

    function isUnresolved(message: IChat.Message) {
      if (message.type !== 'platform') return false;
      if (message.data.attributes.complianceRejected) return false;
      if (message.data.attributes.recipientRejected) return false;

      return true;
    }

    return messages
      ? !messages.filter(isUnresolved).length
      : true;
  }

  handlePaidChange = () => {
    this.setState({ paid: !this.state.paid });
  }

  handlePaymentToken: Stripe.OnTokenEvent = token => {
    return this.props.saveNewMethod({
      token,
      userId: this.props.user.id,
    })
    .then(result => {
      if (!result.success) {
        return Toast.error({
          title: 'Error Adding Payment Method',
          body: result.error,
        });
      }

      this.setState({
        sendModal: false,
        paid: true,
      });

      Toast.alert({
        title: 'Payment Method Added',
        body: 'Your new payment method has been added!',
      });
    });
  };

  render() {
    return (
      <>
        <ChannelHeader
          channelSid={this.props.conversation.state.sid}
          member={this.props.participant.contact}
          onClose={this.props.onClose}
          onDelete={this.handleDelete}
          onScheduleCall={this.props.onScheduleCall} />

        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles.scroll}>
              <div className={styles.items}>
                {this.renderItems()}
              </div>
            </div>
          </div>

          <div className={styles.field}>
            <textarea
              disabled={!this.isSendingEnabled()}
              className={styles.textarea}
              name="input"
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              placeholder="Message"
              ref={this.inputRef}
              value={this.state.input} />
          </div>
        </div>

        <div className={styles.actions}>
          <div className={styles.wrap}>
            <PaidMessageButton
              isSendingEnabled={this.isSendingEnabled()}
              member={this.props.participant.contact}
              value={this.state.paid}
              onPaymentSetup={() => this.setState({ sendModal: true })}
              onToggle={this.handlePaidChange} />
            <Button
              className={styles.sendbtn}
              color="affirmative"
              disabled={!this.isSendingEnabled()}
              onClick={this.handleSubmit}
              variant="pill">
              <div className={styles.buttonText}>
                <span>Send </span>
                <NavigationOutlinedIcon
                  className={styles.sendicon} />
              </div>
            </Button>
          </div>
        </div>
        <StripeConnectPayment
          visible={this.state.sendModal}
          onClose={() => this.setState({ sendModal: false })}
          onToken={this.handlePaymentToken} />
        <BeforeReceivePayment
          visible={this.state.receiveModal}
          onClose={() => this.setState({ receiveModal: false })}
          onSync={() => this.setState({ receiveModal: false })} />
      </>
    );
  }
}

export function Channel(props: Props) {
  const storeState = useSelector(mapState);
  const [_, hasStripeAccount] = useStripeAccountId();
  const saveNewMethod = useUserNewPaymentMethod();

  return <ChannelComponent
    hasStripeAccount={hasStripeAccount}
    saveNewMethod={saveNewMethod}
    {...props}
    {...storeState} />;
}

export default Channel;
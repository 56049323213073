import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as actions from '@actions';
import * as api from '@api';
import * as API from '@api/interfaces';
import { pathname } from '@consts';
import * as enums from '@enums';
import * as utils from '@utils';
import { ProjectLink } from '@/types';
import Toast from 'components/Toast';
import { usePersistentProjectInvite } from './usePersistentProjectInvite';

const mapState = (state: Store.State) => ({
  projects: state.projects,
  user: state.user,
});

const useCreateProjectInvite = (token: string) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector(mapState);
  const urlstate = usePersistentProjectInvite();

  const projectIds = state.projects.ids;
  const userId = state.user.id;

  const dispatchChanges = useCallback((res: API.Users.CreateProjectInvite.Response) => {
    dispatch(actions.batchActions([
      actions.projectUpdated({
        projectId: res.link.projectId,
        project: res.project,
      }),
      actions.projectPipelineUpdated({
        pipeline: res.pipeline,
        projectId: res.link.projectId,
      }),
    ]));

    urlstate.unset();

    return res.link;
  }, [
    dispatch,
    urlstate,
  ]);

  const handleNavigateToHome = useCallback(() => {
    history.replace(pathname.Home);
  }, [history]);

  const handleNavigateToProject = useCallback((link: ProjectLink) => {
    const to = utils.getLocationFor.onboarding.project({ projectId: link.projectId });
    history.replace(to);
  }, [history]);

  const sendToast = useCallback((link: ProjectLink) => {
    if (link.typeId === enums.ProjectLinkType.SelfSource &&
        !projectIds.includes(link.projectId)) {
      Toast.alert({
        title: 'New Project Added',
        body: 'You have been added to a new project. Review the description and accept the project to get started!',
      });
    }

    return link;
  }, [projectIds]);

  const createProjectInvite = useCallback(() => {
    api.users.createProjectInvite({
      token,
      userId,
    })
    .then(dispatchChanges)
    .then(sendToast)
    .then(handleNavigateToProject)
    .catch(() => {
      console.log('navigating home');
      handleNavigateToHome();
    });
  }, [
    dispatchChanges,
    handleNavigateToHome,
    handleNavigateToProject,
    sendToast,
    token,
    userId,
  ]);

  return createProjectInvite;
};

export { useCreateProjectInvite };
export default useCreateProjectInvite;
import { hasResponseLogic, RowsQuestion } from '@containers/Survey/utils/questions';
import { SurveyQuestion, SurveyQuestionMatrixRow } from '@/types/survey';
import { SurveyBuilder, SurveyRowsBuilder, SurveyQuestionsBuilder } from '../interfaces.state';
import { generateNewMatrixRow } from './defaults';

export function addRow(state: SurveyQuestionsBuilder.State, action: SurveyRowsBuilder.AddRow.State): SurveyQuestionsBuilder.State {

  const question = state
    .find(f => f.base.identifier === action.questionIdentifier) as RowsQuestion;

  const newRow = generateNewMatrixRow(question.matrixRows.length + 1);

  return addRows(state, {
    questionIdentifier: action.questionIdentifier,
    rows: [newRow],
    reorder: false,
  });

}

export function addRows(state: SurveyQuestionsBuilder.State, action: SurveyRowsBuilder.AddRows.State): SurveyQuestionsBuilder.State {

  return state.reduce<SurveyQuestionsBuilder.State>((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      acc.push(updateQuestionRows(q as RowsQuestion));
    } else {
      acc.push(q);
    }

    return acc;
  }, []);

  function updateQuestionRows(q: RowsQuestion): RowsQuestion {

    let rows = [
      ...q.matrixRows,
      ...action.rows,
    ];

    if (action.reorder) {
      rows.sort(sortRowsByEmptiness);
      rows = rows.map((m, i) => ({
        ...m,
        ordinal: i + 1,
      }));
    }

    return {
      ...q,
      matrixRows: rows,
    };
  }

  function sortRowsByEmptiness(a: SurveyQuestionMatrixRow, b: SurveyQuestionMatrixRow) {
    const aIsNull = a.value === '';
    const bIsNull = b.value === '';

    if (aIsNull === bIsNull) {
      return a.ordinal - b.ordinal;
    } else if (aIsNull) {
      return 1;
    } else if (bIsNull) {
      return -1;
    }
  }

}

export function removeRow(state: SurveyQuestionsBuilder.State, action: SurveyRowsBuilder.RemoveRow.State): SurveyQuestionsBuilder.State {

  const question = state
    .find(f => f.base.identifier === action.questionIdentifier) as RowsQuestion;

  const toRemove = question.matrixRows.find(f => f.base.identifier === action.row.identifier);

  if (!toRemove) return state;

  const ordinals = generateNewOrdinals();

  const matrixRows = question.matrixRows
    .filter(f => f.base.identifier !== action.row.identifier)
    .map(row => ({
      ...row,
      ordinal: ordinals[row.ordinal],
    }));

  const responseLogic = hasResponseLogic(question) ?
    question.logic.response
      .filter(f => f.condition.value.row.identifier !== action.row.identifier)
    : question.logic.response;

  return state.reduce<SurveyQuestionsBuilder.State>((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      acc.push({
        ...q,
        logic: {
          ...q.logic,
          response: responseLogic,
        },
        matrixRows,
      } as SurveyQuestion);
    } else {
      acc.push(q);
    }
    return acc;
  }, []);

  function generateNewOrdinals() {
    return question.matrixRows.reduce((acc, x) => {
      return {
        ...acc,
        [x.ordinal]: x.ordinal < toRemove.ordinal
          ? x.ordinal
          : x.ordinal > toRemove.ordinal
            ? x.ordinal - 1
            : null,
      };
    }, {} as { [ordinal: number]: number; });
  }
}

export function updateRowValue(state: SurveyQuestionsBuilder.State, action: SurveyRowsBuilder.UpdateRowValue.State): SurveyQuestionsBuilder.State {
  return state.reduce((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      const matrixRows = q.matrixRows.reduce((acc2, o) => {
        if (o.base.identifier === action.row.identifier) {
          acc2.push({
            ...o,
            value: action.value,
          });
        } else {
          acc2.push(o);
        }
        return acc2;
      }, []);
      acc.push({
        ...q,
        matrixRows,
      } as RowsQuestion);
    } else {
      acc.push(q);
    }

    return acc;
  }, [] as SurveyQuestion[]);
}

export function rowsReducer(state: SurveyBuilder.State, action: SurveyRowsBuilder.Action): SurveyQuestionsBuilder.State {

  switch (action.type) {
    case 'add-question-row':
      return addRow(state.survey.questions, action);

    case 'add-question-rows':
      return addRows(state.survey.questions, action);

    case 'remove-question-row':
      return removeRow(state.survey.questions, action);

    case 'update-question-row-value':
      return updateRowValue(state.survey.questions, action);

    default:
      return state.survey.questions;
  }
}
import { useCallback } from 'react';
import { useFetchProjectSurvey as useFetchSurvey } from '@utils/api';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { Projects } from '@api/interfaces/projects';

export const useFetchProjectSurvey = (projectId: number) => {
  const [_, dispatch] = useSurveyBuilderState();

  const onSuccess = useCallback((res: Projects.FetchProjectSurvey.Response) => {
    dispatch({
      type: 'reset-survey',
      value: res.survey,
    });
  }, [dispatch]);

  return useFetchSurvey({ projectId }, {
    // refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess,
  });
};
import { useHelpers as useHelpersRM } from '@remirror/react';
import { TranscriptExtensions, TranscriptHelpers } from '../interfaces';

export const useHelpers = () => {

  // Note: doesn't seem to be returning correct types
  const helpers = useHelpersRM<TranscriptExtensions>();

  return {
    getBasicHighlight: helpers.getBasicHighlight,
    getFocusedHighlight: helpers.getFocusedHighlight,
    getHighlight: helpers.getHighlight,
    getHighlightsAt: helpers.getHighlightsAt,
    getHighlightPosition: helpers.getHighlightPosition,
    isHighlightFocused: helpers.isHighlightFocused,
    getHighlightsInitialized: helpers.getHighlightsInitialized,
  } as TranscriptHelpers.Helpers;
};
import { Link } from 'react-router-dom';
import { cx, getLocationFor } from '@utils';
import { ProjectBase } from '@/types';
import { ProjectPopoverAnchor } from 'components/GroupProject';
import { ProjectIcon } from 'components/icons';
import styles from './style/AcceptedProjects.Item.css';

type Props = {
  className?: string;
  item: ProjectBase;
} & ChildrenProps;

export const AcceptedProjectsItem = ({ children, className, item }: Props) => {

  const to = getLocationFor.project.root({
    slug: item.slug,
  });

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.details}>
        <ProjectIcon
          projectType={item.projectType} />
        <ProjectPopoverAnchor item={item}>
          <Link to={to} className={styles.name}>
            {item.name}
          </Link>
        </ProjectPopoverAnchor>
      </div>
      {children}
    </div>
  );
};

export default AcceptedProjectsItem;
import { useParams } from 'react-router-dom';
import { useSelectFolder, useSelectObject } from '@containers/Store';

type Params = {
  folderId: string;
};

export const useMatchFolderStateFromUrl = () => {
  const { folderId } = useParams<Params>();
  const folder = useSelectFolder(+folderId);
  const object = useSelectObject(folder?.objectId);

  return {
    folder,
    object,
  };
};

export default useMatchFolderStateFromUrl;
import { useEffect, useRef, useState, forwardRef, memo } from 'react';
import { interval } from 'd3-timer';
import { AudioTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import MicOff from '@mui/icons-material/MicOff';
import { useIsTrackEnabled } from './hooks/useIsTrackEnabled';
import { useMediaStreamTrack } from './hooks/useMediaStreamTrack';
import styles from './style/AudioLevelIndicator.css';

let clipId = 0;
const getUniqueClipId = () => clipId++;

const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext: AudioContext;

type Props = {
  background?: string;
  icon: 'phone' | 'web';
  audioTrack?: AudioTrack;
  size?: number;
};

export const AudioLevelIndicator = memo(({ background, icon, audioTrack, size = 24 }: Props) => {
  const SVGRectRef = useRef<SVGRectElement>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const isTrackEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);

  useEffect(() => {
    if (audioTrack && mediaStreamTrack && isTrackEnabled) {
      // Here we create a new MediaStream from a clone of the mediaStreamTrack.
      // A clone is created to allow multiple instances of this component for a single
      // AudioTrack on iOS Safari.
      let newMediaStream = new MediaStream([mediaStreamTrack.clone()]);

      // Here we listen for the 'stopped' event on the audioTrack. When the audioTrack is stopped,
      // we stop the cloned track that is stored in 'newMediaStream'. It is important that we stop
      // all tracks when they are not in use. Browsers like Firefox don't let you create a new stream
      // from a new audio device while the active audio device still has active tracks.
      const stopAllMediaStreamTracks = () => newMediaStream.getTracks().forEach(track => track.stop());
      audioTrack.on('stopped', stopAllMediaStreamTracks);

      const reinitializeAnalyser = () => {
        stopAllMediaStreamTracks();
        newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));

      // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
      // where the analysers stop functioning when the user switches to a new tab
      // and switches back to the app.
      window.addEventListener('focus', reinitializeAnalyser);

      return () => {
        stopAllMediaStreamTracks();
        window.removeEventListener('focus', reinitializeAnalyser);
        audioTrack.off('stopped', stopAllMediaStreamTracks);
      };
    }
  }, [isTrackEnabled, mediaStreamTrack, audioTrack]);

  useEffect(() => {
    const SVGClipElement = SVGRectRef.current;

    if (isTrackEnabled && SVGClipElement && analyser) {
      const sampleArray = new Uint8Array(analyser.frequencyBinCount);

      const timer = interval(() => {
        analyser.getByteFrequencyData(sampleArray);
        let values = 0;

        const length = sampleArray.length;
        for (let i = 0; i < length; i++) {
          values += sampleArray[i];
        }

        const volume = Math.min(21, Math.max(0, 14*Math.log10(values / length)));
        SVGClipElement?.setAttribute('y', String(21 - volume));
      }, 75);

      return () => {
        SVGClipElement.setAttribute('y', '21');
        timer.stop();
      };
    }
  }, [isTrackEnabled, analyser]);

  const clipPathId = `al-clip-${getUniqueClipId()}`;

  return isTrackEnabled
    ? icon === 'web'
      ? <WebSvg
          ref={SVGRectRef}
          background={background}
          clipPathId={clipPathId}
          size={size} />
      : <PhoneSvg
          ref={SVGRectRef}
          background={background}
          clipPathId={clipPathId}
          size={size} />
    : <OffSvg
        icon={icon}
        size={size} />;
});

type SvgProps = {
  background?: string;
  clipPathId: string;
  size: number;
};

const WebSvg = memo(forwardRef<SVGRectElement, SvgProps>(({ background = 'rgba(255, 255, 255, 0.1)', clipPathId, size }, ref) => (
  <svg
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    height={`${size}px`}
    width={`${size}px`}>
    <defs>
      <clipPath id={clipPathId}>
        <rect
          ref={ref}
          x="0"
          y="21"
          width="24"
          height="24" />
      </clipPath>
    </defs>
    <path
      fill={background}
      d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z" />
    <path
      fill="#0c0"
      clipPath={`url(#${clipPathId})`}
      d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z" />
  </svg>
)));

const PhoneSvg = memo(forwardRef<SVGRectElement, SvgProps>(({ background = 'rgba(255, 255, 255, 0.1)', clipPathId, size }, ref) => (
  <svg
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    height={`${size}px`}
    width={`${size}px`}>
    <defs>
      <clipPath id={clipPathId}>
        <rect
          ref={ref}
          x="0"
          y="21"
          width="24"
          height="24" />
      </clipPath>
    </defs>
    <path
      fill={background}
      d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
    <path
      fill="#0c0"
      clipPath={`url(#${clipPathId})`}
      d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
  </svg>
)));

type OffSvgProps = {
  icon: Props['icon'];
  size: number;
};

const OffSvg = memo(({ icon, size }: OffSvgProps) => {
  if (icon !== 'web') return null;
  return (
    <div className={styles.off}>
      <MicOff
        height={`${size}px`}
        width={`${size}px`}
        style={{ width: 'initial', height: 'initial' }} />
    </div>
  );
});

export function initializeAnalyser(stream: MediaStream) {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.5;
  analyser.fftSize = 512;
  analyser.minDecibels = -80;

  audioSource.connect(analyser);

  return analyser;
}

export default AudioLevelIndicator;
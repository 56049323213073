import { useCallback, useMemo } from 'react';
import { SurveyOptionType } from '@enums';
import { RadioGroup } from 'components/Radio';
import { RadioGroupOnOptionChange, RadioGroupOnInputChange } from 'components/Radio/interfaces';
import { MultipleChoiceProps } from './interfaces';

type Props =
  MultipleChoiceProps;

export default function MultipleChoiceQuestion({ answer, className, options, setAnswer }: Props) {
  const optionsMapped =
    useMemo(() => options.map(o => ({
      allowText: o.type === SurveyOptionType.Other,
      value: o.id,
      label: o.value,
      text: answer?.text,
    })), [answer, options]);

  const handleOptionChange: RadioGroupOnOptionChange =
    useCallback(item => setAnswer({ optionId: +item.optionId }), [setAnswer]);

  const handleInputChange: RadioGroupOnInputChange =
    useCallback(item => setAnswer({ optionId: +item.optionId, text: item.text }), [setAnswer]);

  return (
    <RadioGroup
      className={className}
      selectedOption={answer?.optionId ?? ''}
      onOptionChange={handleOptionChange}
      onInputChange={handleInputChange}
      options={optionsMapped} />
  );
}

export { MultipleChoiceQuestion };
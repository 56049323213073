import { SurveyTemplateType } from '@enums/survey.template';
import { SurveyTemplateBuilder } from '../interfaces.state';

export function templateCategoryUpdated<T extends SurveyTemplateType = SurveyTemplateType>(state: SurveyTemplateBuilder.State<T>, action: SurveyTemplateBuilder.TemplateCategoryUpdated.Action): SurveyTemplateBuilder.State<T> {

  return {
    ...state,
    data: {
      ...state.data,
      category: action.value,
    },
  };
}

export function templateTargetUpdated<T extends SurveyTemplateType = SurveyTemplateType>(state: SurveyTemplateBuilder.State<T>, action: SurveyTemplateBuilder.TemplateTargetUpdated.Action): SurveyTemplateBuilder.State<T> {
  return {
    ...state,
    data: {
      ...state.data,
      target: action.value,
    },
  };
}

export function templateCategoriesUpdated<T extends SurveyTemplateType = SurveyTemplateType>(state: SurveyTemplateBuilder.State<T>, action: SurveyTemplateBuilder.TemplateCompetitorsUpdated.Action): SurveyTemplateBuilder.State<T> {
  return {
    ...state,
    data: {
      ...state.data,
      competitors: action.value,
    },
  };
}

export function templateEvaluationCriteriaUpdated<T extends SurveyTemplateType = SurveyTemplateType.PersonaResearch>(state: SurveyTemplateBuilder.State<T>, action: SurveyTemplateBuilder.EvaluationCriteriaUpdated.Action): SurveyTemplateBuilder.State<T> {
  return {
    ...state,
    data: {
      ...state.data,
      evaluationCriteria: action.value,
    },
  };
}

export function templatePartnershipCriteriaUpdated<T extends SurveyTemplateType = SurveyTemplateType.PersonaResearch>(state: SurveyTemplateBuilder.State<T>, action: SurveyTemplateBuilder.PartnershipCriteriaUpdated.Action): SurveyTemplateBuilder.State<T> {
  return {
    ...state,
    data: {
      ...state.data,
      partnershipCriteria: action.value,
    },
  };
}

export function templateRolesUpdated<T extends SurveyTemplateType = SurveyTemplateType.PersonaResearch>(state: SurveyTemplateBuilder.State<T>, action: SurveyTemplateBuilder.TargetRolesUpdated.Action): SurveyTemplateBuilder.State<T> {
  return {
    ...state,
    data: {
      ...state.data,
      roles: action.value,
    },
  };
}
import * as urls from '@consts/urls';
import { Organization } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';

export const organizationProp = helmetJsonLdProp<Organization>({
  '@context': 'https://schema.org',
  '@id': 'https://www.vancery.com',
  '@type': 'Organization',
  email: 'info@vancery.com',
  foundingDate: '2018',
  founders: [
    {
      "@type": "Person",
      'name': "Gunnar Gregory"
    },
  ],
  name: 'Vancery',
  url: 'https://www.vancery.com',
  logo: 'https://cdn.vancery.com/images/vancery-600x600.png',
  sameAs: [
    urls.LinkedIn,
    urls.Twitter,
    urls.Facebook,
    urls.YouTube,
  ],
});
import { useRemirror } from '@remirror/react';
import { PlaceholderExtension } from '@remirror/extension-placeholder';
import { MentionAtomExtension } from '@remirror/extension-mention-atom';
import { CommentDocument } from '@/types/transcript.rich-text.comments';

type Props = {
  initialState?: CommentDocument.RootNode;
  placeholder?: string;
};

export const useCommentEditor = ({ initialState, placeholder }: Props) => {

  return useRemirror({
    content: initialState,
    extensions: () => [
      new PlaceholderExtension({
        placeholder,
      }),
      new MentionAtomExtension({
        matchers: [{ name: 'at', char: '@', appendText: ' ', matchOffset: 0 }],
      }),
    ],
  });

};
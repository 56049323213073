import { useCallback, useMemo } from 'react';
import { MinusCircle } from 'react-feather';
import { SurveyQuestionType, SurveyQuotaType } from '@enums';
import { useSurveyQuotaState } from '@containers/SurveyBuilder.Quota';
import { useGetQuestionRawText, useSurveyBuilderState } from '@containers/SurveyBuilder';
import { AddConditionButton } from '@presentation/SurveyBuilder';
import { OptionsDropdown, QuestionDropdown, QuestionDropdownItem } from '@presentation/SurveyBuilder/Dropdown';
import { QuotaBuilderTitle } from './Title';
import styles from './style/Builder.Basic.css';

const minConditions = 1;
const maxConditions = 3;

const useValidQuestionItems = (): QuestionDropdownItem[] => {
  const [surveyState] = useSurveyBuilderState();
  const [state] = useSurveyQuotaState<SurveyQuotaType.Basic>();
  const getQuestionText = useGetQuestionRawText();

  const questions = useMemo(() => {
    const validTypes = [
      SurveyQuestionType.MultipleChoice,
      SurveyQuestionType.Multiselect,
    ];

    return surveyState.survey.questions
      .filter(f => validTypes.includes(f.typeId))
      .filter(f => {
        if (f.typeId === SurveyQuestionType.MultipleChoice) {
          return !state.data.items.some(s => s.question?.identifier === f.base.identifier);
        }

        return true;
      })
      .map(m => ({
        base: m.base,
        ordinal: m.ordinal,
        value: getQuestionText(m.value),
      }));

  }, [
    getQuestionText,
    state.data.items,
    surveyState.survey.questions,
  ]);

  return questions;
};

export const BasicSurveyQuotaBuilder = () => {
  const [surveyState] = useSurveyBuilderState();
  const [state, dispatch] = useSurveyQuotaState<SurveyQuotaType.Basic>();
  const getQuestionText = useGetQuestionRawText();

  const validQuestions = useValidQuestionItems();

  const canAddCondition = useMemo(() => {
    return state.data.items.length < maxConditions
      && !!validQuestions.length;
  }, [state.data.items.length, validQuestions.length]);

  const canRemoveItem = useMemo(() => {
    return state.data.items.length > minConditions;
  }, [state.data.items.length]);

  const addItem = useCallback(() => {
    dispatch({
      type: 'add-basic-item',
    });
  }, [dispatch]);

  const removeItem = useCallback((identifier: string) => () => {
    dispatch({
      identifier,
      type: 'remove-basic-item',
    });
  }, [dispatch]);

  const updateItemQuestion = useCallback((identifier: string) => (questionIdentifier: string) => {
    dispatch({
      identifier,
      question: {
        identifier: questionIdentifier,
      },
      type: 'update-basic-item-question',
    });
  }, [dispatch]);

  const updateItemCondition = useCallback((identifier: string) => (optionIdentifier: string) => {
    dispatch({
      identifier,
      type: 'update-basic-item-condition',
      value: {
        option: {
          identifier: optionIdentifier,
        },
      },
    });
  }, [dispatch]);

  return (
    <>
      <QuotaBuilderTitle label="Conditions" />
      {state.data.items.map(item => {
        const question = surveyState.survey.questions
          .find(f => f.base.identifier === item.question?.identifier);
        const option = question?.options?.find(f => f.base.identifier === item.condition?.value?.option?.identifier);

        const dropdownItem = question
          ? {
            base: question.base,
            ordinal: question.ordinal,
            value: getQuestionText(question.value),
          } : null;

        return (
          <div
            key={item.identifier}
            className={styles.item}>
            <div className={styles.question}>
              <QuestionDropdown
                items={validQuestions}
                onSelect={updateItemQuestion(item.identifier)}
                value={dropdownItem} />
            </div>
            <div className={styles.option}>
              <OptionsDropdown
                disabled={!question}
                items={question?.options || []}
                onSelect={updateItemCondition(item.identifier)}
                value={option} />
            </div>
            {canRemoveItem &&
              <MinusCircle
                className={styles.remove}
                onClick={removeItem(item.identifier)} />}
          </div>
        );
      })}
      {canAddCondition &&
        <AddConditionButton onClick={addItem} />}
    </>
  );
};

export default BasicSurveyQuotaBuilder;
import { useCallback } from 'react';
import { useSelectUser } from '@containers/Store';
import ConnectPayments from 'static/images/payment/connect-payments.svg?url';
import Button from 'components/Button';
import Modal from 'components/Modal/Modal.Legacy';
import { useStripeSignup } from 'components/Stripe/hooks/useStripeSignup';
import styles from './style/BeforeReceivePayment.css';

type Props = {
  onClose: () => unknown;
  onSync: () => unknown;
  visible: boolean;
};

export function BeforeReceivePayment({ onClose, onSync, visible }: Props) {
  const user = useSelectUser();
  const openStripe = useStripeSignup(user.id);

  const handleClick = useCallback(() => {
    openStripe();
    onSync();
  }, [openStripe, onSync]);

  return (
    <Modal
      onClose={onClose}
      visible={visible}>
      <div className={styles.root}>
        <div className={styles.header}>
          <img className={styles.image} src={ConnectPayments} />
        </div>
        <h1 className={styles.title}>
          Sync a bank account to receive a payment for your message
        </h1>
        <h2 className={styles.subheader}>
          You have a $25 reward pending in your account.
        </h2>
        <h2 className={styles.subheader}>
          Sync a payments account to get started.
        </h2>
        <div className={styles.actions}>
          <Button.Affirmative
            className={styles.sync}
            variant='brick'
            title='Sync Account'
            onClick={handleClick} />
        </div>
      </div>
    </Modal>
  );
}

export default BeforeReceivePayment;
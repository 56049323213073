import PastSearches from './PastSearches';
import RecentProfiles from './RecentProfiles';

export default function ResultsEmptyQuery() {
  return (
    <div>
      <RecentProfiles />
      <PastSearches />
    </div>
  );
}

export { ResultsEmptyQuery };
import { useMemo } from 'react';
import * as surveyForm  from '@containers/SurveyForm/utils';
import { MatrixSliderQuestion } from '@/types';

export const useSliderValidation = (answer: MatrixSliderQuestion.RespondentAnswer.Value, question: MatrixSliderQuestion.FormQuestion) => {

  const message = useMemo(() => {
    const showMessage = surveyForm.matrixSlider.isAnswerComplete(answer, question) &&
      !surveyForm.matrixSlider.isSumValid(answer, question);

    return showMessage
      ? `Please ensure all rows total to ${question.settings.slider.maxValue}`
      : null;
  }, [answer, question]);

  return message;
};

export default useSliderValidation;
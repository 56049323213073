import * as survey from '@containers/Survey/utils';
import { RespondentAnswer, SurveyResponseItem } from '@/types';

export const transformResponse = (item: SurveyResponseItem): RespondentAnswer => {
  if (survey.response.isTextResponse(item)) {
    return {
      value: item.answer.value,
    };
  } else if (survey.response.isMultipleChoice(item)) {
    const answer = item.answer.items[0];
    return {
      optionId: answer.option.id,
      text: answer.option.text,
    };
  } else if (survey.response.isMultiSelect(item)) {
    return {
      items: item.answer.items.map(item => ({
        optionId: item.option.id,
        text: item.option.text,
      })),
    };
  } else if (survey.response.isRanking(item)) {
    return {
      options: item.answer.items.map(item => ({
        id: item.option.id,
        ordinal: item.ordinal,
      })),
    };
  } else if (survey.response.isMatrixGrid(item)) {
    return {
      items: item.answer.items.map(m => ({
        optionId: m.options[0].id,
        matrixRowId: m.row.id,
      })),
    };
  } else if (survey.response.isMatrixSlider(item)) {
    return {
      items: item.answer.items.map(m => ({
        matrixRowId: m.row.id,
        value: m.value,
      })),
    };
  } else if (survey.response.isMatrixMultiselect(item)) {
    return {
      items: item.answer.items.map(m => ({
        matrixRowId: m.row.id,
        optionIds: m.options.map(option => option.id),
      })),
    };
  } else if (survey.response.isNumberInputTable(item)) {
    return {
      items: item.answer.items.map(m => ({
        optionId: m.option.id,
        rowId: m.row.id,
        value: m.value,
      })),
    };
  }
};
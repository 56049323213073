import { useCallback, useMemo } from 'react';
import LongTextResponseInput from './LongTextResponseInput';
import { LongTextResponseProps } from './interfaces';

export const LongTextResponse = ({ className, answer, item, setAnswer }: LongTextResponseProps) => {
  const updateAnswer = useCallback((value: string) => {
    setAnswer({
      value,
    });
  }, [setAnswer]);

  const isLongEnough = useMemo(() => {
    return answer?.value?.length >= item.settings.characterMinimum;
  }, [
    item.settings.characterMinimum,
    answer,
  ]);

  return (
    <LongTextResponseInput
      className={className}
      isLongEnough={isLongEnough}
      updateAnswer={updateAnswer}
      value={answer?.value} />
  );
};

export default LongTextResponse;
import { createNamedContext } from '@utils';
import { GroupUser, WorkspaceObjectTreeItem, Workspace, WorkspaceObject } from '@/types';

export type ObjectOwnershipContextValue = {
  fetchUsers: () => Promise<unknown>;
  onUserSelect: (data: GroupUser) => unknown;
  save: () => Promise<unknown>;
  selectedUser: GroupUser;
  users: GroupUser[];
};

export type MoveObjectContextValue = {
  currentPath: string[];
  data: WorkspaceObjectTreeItem;
  object: WorkspaceObject;
  loadingTree?: boolean;
  loadingWorkspaces?: boolean;
  onSave: () => Promise<unknown>;
  onSelectParentObject: (id: number) => unknown;
  onSelectWorkspace: (value: Workspace) => unknown;
  parentObjectId: number;
  selectedWorkspace: Workspace;
  workspaces: Workspace[];
};

export const MoveObjectContext = createNamedContext<MoveObjectContextValue>(undefined, 'move-object');
export const ObjectOwnershipContext = createNamedContext<ObjectOwnershipContextValue>(undefined, 'object-ownership');
import { cx } from '@utils';
import styles from './style/Cell.Text.css';

type Props = {
  classes?: {
    root?: string;
    text?: string;
  };
  children:  React.ReactText;
  fallback?: React.ReactText;
};

export const Text = ({ classes = {}, fallback = null, ...props }: Props) => {
  const title = createTitle(props);

  return (
    <div className={cx(styles.root, classes.root)}>
      <div
        className={cx(styles.trunc, classes.text)}
        title={title}>
        {props.children || fallback}
      </div>
    </div>
  );
};

Text.displayName = 'Cell.Text';

function createTitle(params: Pick<Props, 'children'>) {
  return typeof params.children === 'string'
      || typeof params.children === 'number'
      ? String(params.children)
      : undefined;
}
import { ChangeEvent, useCallback } from 'react';
import { cx } from '@utils';
import { MatrixSliderQuestion } from '@/types';
import { Input } from 'components/Input';
import { CheckboxLabel } from 'components/Checkbox';
import styles from './style/MatrixSlider.Settings.Slider.css';

type Props = {
  className?: string;
  onEnsureTotalChange: (value: boolean) => void;
  onHideSlideChange: (value: boolean) => void;
  onIncrementChange: (value: number) => void;
  onLabelChange: (value: string) => void;
  onMaxValueChange: (value: number) => void;
  onMinValueChange: (value: number) => void;
  settings: MatrixSliderQuestion.SliderSettings;
};

export const SliderSettings = ({
  className,
  onHideSlideChange,
  onEnsureTotalChange,
  onIncrementChange, onMaxValueChange,
  onMinValueChange, onLabelChange,
  settings,
}: Props) => {

  const handleIncrementChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onIncrementChange(Math.abs(+e.target.value));
  }, [onIncrementChange]);

  const handleMaxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onMaxValueChange(e.target.value !== '' ? +e.target.value : null);
  }, [onMaxValueChange]);

  const handleMinChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onMinValueChange(e.target.value !== '' ? +e.target.value : null);
  }, [onMinValueChange]);

  const handleLabelChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onLabelChange(e.target.value);
  }, [onLabelChange]);

  const handleEnsureTotalChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onEnsureTotalChange(!settings.ensureAnswerTotalEqualsMax);
  }, [onEnsureTotalChange, settings.ensureAnswerTotalEqualsMax]);

  const handleHideSliderChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onHideSlideChange(!settings.hideSlider);
  }, [onHideSlideChange, settings.hideSlider]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.row}>
        <div className={styles.label}>Label</div>
        <div className={styles.labelInput}>
          <Input
            type="text"
            name="label"
            onChange={handleLabelChange}
            placeholder="Enter a label (optional)"
            value={settings.label} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.label}>Low Value</div>
        <div className={styles.input}>
          <Input
            type="number"
            name="minValue"
            onChange={handleMinChange}
            value={settings.minValue !== null ? settings.minValue : ''} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.label}>High Value</div>
        <div className={styles.input}>
          <Input
            type="number"
            name="maxValue"
            onChange={handleMaxChange}
            value={settings.maxValue !== null ? settings.maxValue : ''} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.label}>Unit Increment</div>
        <div className={styles.input}>
          <Input
            type="number"
            name="increment"
            onChange={handleIncrementChange}
            value={settings.increment || ''} />
        </div>
      </div>

      <CheckboxLabel
        className={styles.checkbox}
        label={`Ensure answer values total ${settings.maxValue === null ? 'high value' : settings.maxValue}`}
        onChange={handleEnsureTotalChange}
        checked={settings.ensureAnswerTotalEqualsMax} />

    <div>
      <CheckboxLabel
        className={styles.checkbox}
        label="Hide slider"
        onChange={handleHideSliderChange}
        checked={settings.hideSlider} />
        </div>
    </div>
  );
};

export default SliderSettings;
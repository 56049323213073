import { ProfileEntry } from '@enums';
import { useProfileState, useAccountProfileEditState } from '@containers';
import {
  WizardHeader,
} from 'components/Wizard';
import ProfileEntries from './ProfileEntries';
import { Navigation, Screen } from './Wizard';
import styles from './style.css';

export const EmploymentScreen = () => {
  const [user] = useProfileState();
  const hasSavedEmployment = !!user.employment.length;
  const [entryId] = useAccountProfileEditState();
  const isEditing = !!entryId;

  const renderHeader = () => {
    const title = `Work Experience`;
    const subtitle = `Please provide your work experiences so that clients can view your background.`;

    return  (
      <WizardHeader
        title={title}
        subtitle={subtitle} />
    );
  };

  const renderNavigation = () =>  (
    <Navigation nextEnabled={hasSavedEmployment && !isEditing} />
  );

  const renderBody = () => (
    <div className={styles.bodyWrap}>
      <ProfileEntries<ProfileEntry.Employment>
        type={ProfileEntry.Employment} />
    </div>
  );

  return (
    <Screen
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderNavigation={renderNavigation} />
  );
};

export default EmploymentScreen;
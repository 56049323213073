import { RespondentProps } from '@/types';
import { MemberBaseballCard } from 'components/BaseballCard/MemberCard.Client';
import { AnonymousBaseballCard } from 'components/BaseballCard/AnonymousBaseballCard';

export function RespondentBaseballCard(props: RespondentProps<string> & { children: React.ReactNode; className?: string; }) {
  if (props.anonymity) {
    return (
      <AnonymousBaseballCard
        className={props.className}
        userId={props.id}
        {...props}
        displayName={props.respondentName}
        subtext={props.profile.fullname == props.respondentName ? null : props.profile.fullname}>
        {props.children}
      </AnonymousBaseballCard>
    );
  } else {
    return (
      <MemberBaseballCard
        className={props.className}
        userId={props.id}
        projectId={props.projectId}>
        {props.children}
      </MemberBaseballCard>
    );
  }
}
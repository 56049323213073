import { FormAddressItem } from './interfaces';

type AddressFormFieldState = {
  show: boolean;
  label?: string;
  placeholder?: string;
  required: boolean;
};

export function getStateLabel(countryId: string) {
  switch (countryId) {
    case 'CA': return 'Province';
    case 'IE': return 'County';
    case 'IT': return 'Province';
    case 'JP': return 'Prefecture';
    default: return 'State';
  }
}

export function getZipLabel(countryId: string) {
  switch (countryId) {
    case 'IE': return 'Eircode';
    case 'US': return 'Zip Code';
    default: return 'Postal Code';
  }
}

export function getCityState(countryId: string): AddressFormFieldState {
  if (['SG'].includes(countryId))
    return { show: false, required: false };

  return {
    show: true,
    label: 'City',
    placeholder: 'City',
    required: true,
  };
}

export function getStateState(countryId: string): AddressFormFieldState {
  if (!['AU', 'CA', 'IE', 'IT', 'JP', 'US'].includes(countryId))
    return { show: false, required: false };

  const label = getStateLabel(countryId);

  return {
    show: true,
    label,
    placeholder: label,
    required: true,
  };
}

export function getZipState(countryId: string): AddressFormFieldState {
  const label = getZipLabel(countryId);

  return {
    show: true,
    label,
    placeholder: label,
    required: true,
  };
}

export function getAddressFieldStates(address: FormAddressItem) {
  return {
    city: getCityState(address.country.value),
    state: getStateState(address.country.value),
    zip: getZipState(address.country.value),
  };
}

export function isEmpty(value: string) {
  return !value || !value.trim().length;
}

export function updateAddressSchema(address: FormAddressItem) {
  const { city, state, zip } = getAddressFieldStates(address);

  return {
    ...address,
    city: {
      ...address.city,
      required: !!city.required,
    },
    state: {
      ...address.state,
      required: !!state.required,
    },
    zip: {
      ...address.zip,
      required: !!zip.required,
    },
  };
}
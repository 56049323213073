import { Fragment, useCallback, useMemo } from 'react';
import { useConjointAnalysisAttributeColors, useRepeatSurveyData, useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import { SurveyQuestionType } from '@enums';
import { AggregateDataHeaderRow, ResponseOption, ProjectVersionDataRow } from '@presentation';
import { roundToPlace } from '@utils';
import { ConjointAnalysisQuestion } from '@/types';
import { SimpleAccordion } from 'components/Accordion';
import { useFilteredAttributes, useQueryAccordionMap } from './hooks';
import styles from './style/Data.css';

export const ConjointAnalysisRepeatAggregateData = () => {
  const { query, resetQueryState, setQueryState } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.ConjointAnalysis>();
  const { projectIds } = useRepeatSurveyData();
  const { aggregate, historical } = useRepeatQuestionResponsesData<SurveyQuestionType.ConjointAnalysis>();
  const [isAccordionOpen, toggleAccordion] = useQueryAccordionMap('attributeIds');
  const attributes = useFilteredAttributes();

  const onVersionClick = useCallback((attributeId: number, projectId: number) => () => {
    setQueryState({
      attributeIds: [attributeId],
      projectId,
    });
  }, [setQueryState]);

  const renderVersions = useCallback((attribute: ConjointAnalysisQuestion.Attribute) => {
    return [...projectIds]
      .sort((a, b) => b - a)
      .reduce((acc, projectId, i) => {
        const importance = historical[projectId].data.attributes[attribute.base.id].importance;
        if (importance !== undefined) {
          acc.push(
            <ProjectVersionDataRow
              key={projectId}
              label={`${roundToPlace(importance, 1)}%`}
              onClick={onVersionClick(attribute.base.id, projectId)}
              version={projectIds.length - i} />
          );
        }

        return acc;
      }, []);
  }, [
    historical,
    onVersionClick,
    projectIds,
  ]);

  const renderAttribute = useCallback((attribute: ConjointAnalysisQuestion.Attribute) => {
    const data = aggregate[attribute.base.id];

    return (
      <SimpleAccordion
        key={attribute.base.id}
        open={isAccordionOpen(attribute.base.id)}
        toggleOpen={toggleAccordion(attribute.base.id)}
        grows={false}
        className={styles.row}
        height={50}
        label={(
          <AccordionLabel
            attribute={attribute}
            data={data} />
        )}>
        {renderVersions(attribute)}
      </SimpleAccordion>
    );
  }, [
    aggregate,
    isAccordionOpen,
    renderVersions,
    toggleAccordion,
  ]);

  const sortedAttributes = useMemo(() => {
    return [...attributes]
      .sort((a, b) => {
        const aAvg = aggregate[a.base.id].avg;
        const bAvg = aggregate[b.base.id].avg;
        return bAvg - aAvg;
      });
  }, [
    aggregate,
    attributes,
  ]);

  return <>
    <AggregateDataHeaderRow
      className={styles.row}
      label="Key"
      onClose={query.attributeIds && resetQueryState} />
    {sortedAttributes
      .map(attribute => (
        <Fragment key={attribute.base.id}>
          {renderAttribute(attribute)}
        </Fragment>
      ))
    }
  </>;
};

type AccordionLabelProps = {
  attribute: ConjointAnalysisQuestion.Attribute;
  data: ConjointAnalysisQuestion.RepeatData.Data;
};

function AccordionLabel({ attribute, data }: AccordionLabelProps) {
  const colors = useConjointAnalysisAttributeColors();
  const subtitle = `(${roundToPlace(data.avg, 1)}% Avg.)`;

  return (
    <ResponseOption
      color={colors[attribute.base.id]}
      label={attribute.value}
      subtitle={subtitle} />
  );
}

export default ConjointAnalysisRepeatAggregateData;
import { Project } from '@/types';
import { ScreeningQuestionsPreview } from 'components/ScreeningQuestions';
import { Title } from './Title';
import styles from './style.css';

type Props = Project;

export const ScreeningQuestions = (props: Props) => {
  return (
    <div className={styles.row}>
      <Title text="Screening Questions" />
      {props.screeningQuestions && props.screeningQuestions.length
        ? <ScreeningQuestionsPreview items={props.screeningQuestions} />
        : <div className={styles.surveyPlaceholder}>No additional screening questions are required.</div>}
    </div>
  );
};
import { useCallback, useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { cx } from '@utils';
import { SurveySettings } from '@/types';
import styles from './style/NumberInput.css';

type Props = {
  className?: string;
  invalid?: boolean;
  onChange: (value: number) => unknown;
  validations: SurveySettings.NumberValidations;
  value: number;
};

export const NumberTableInput = ({ className, invalid, onChange, value, validations }: Props) => {
  const { theme } = useSurveyThemingPalette();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;

    onChange(rawValue === '' ? null : +rawValue);
  }, [onChange]);

  const isInputAllowed = useCallback(({ floatValue, value }: NumberFormatValues) => {
    if (value.length > 1 && value.startsWith('0')) return false;
    if (value === '') return true;

    const valid = (!validations?.minValue !== null || floatValue >= validations?.minValue) &&
      (!validations?.maxValue !== null || floatValue <= validations?.maxValue);

    return valid;
  }, [validations]);

  const props = {
    className: cx({
      [styles.input]: !theme,
      [styles.theme]: theme,
    }, className, {
      [styles.invalid]: invalid,
    }),
    decimalScale: 2,
    isAllowed: isInputAllowed,
    onChange: handleChange,
    value,
  };

  return theme
    ? <ThemedNumberTableInput {...props} />
    : <NumberFormat {...props} />;
};

export default NumberTableInput;

type ThemedNumberTableInputProps = {
  decimalScale: number;
  isAllowed:    (values: NumberFormatValues) => boolean;
  onChange:     (e: React.ChangeEvent<HTMLInputElement>) => void;
} & Pick<Props,
    | 'className'
    | 'value'>;

const ThemedNumberTableInput = (props: ThemedNumberTableInputProps) => {
  const [focused, setFocused] = useState(false);
  const { palette } = useSurveyThemingPalette();

  const onBlur = () => setFocused(false);
  const onFocus = () => setFocused(true);

  const handleBlur = useCallback(onBlur, []);
  const handleFocus = useCallback(onFocus, []);

  const style = focused ? {
    border: `2px solid ${palette.primary.main}`,
  } : {};

  return (
    <NumberFormat
      className={props.className}
      decimalScale={2}
      isAllowed={props.isAllowed}
      onBlur={handleBlur}
      onChange={props.onChange}
      onFocus={handleFocus}
      style={style}
      value={props.value} />
  );
};
import { forwardRef } from 'react';
import * as consts from '@consts';
import { useScreenerBuilderContext } from '@containers/SurveyBuilder';
import * as ProjectCreation from '@screens/ProjectCreation/Header';
import { ScreenerBuilder } from 'components/ScreeningQuestions';
import { useProjectCreationNavigation } from './context';
import { BackButton, Footer, NextButton } from './Footer';
import styles from './style.css';
import stylesOld from './style/style.old.css';

export const ProjectScreeningQuestions = forwardRef<HTMLTextAreaElement>((_, ref) => {

  const context = useScreenerBuilderContext();
  const disabled = context.questions.some(s => !s);
  const actions = useProjectCreationNavigation();

  return (
    <>
      <div className={stylesOld.section}>
        <div className={styles.wrap}>
          <ProjectCreation.Header className={styles.row}>
            <ProjectCreation.Title className={styles.row}>Screening Questions</ProjectCreation.Title>
            <ProjectCreation.Subtitle className={styles.row}>
              {`Please provide up to ${consts.surveys.MaxProjectScreenerQuestions} screening questions for the consultation.`}
            </ProjectCreation.Subtitle>
          </ProjectCreation.Header>

          <ScreenerBuilder />
        </div>
      </div>
      <Footer>
        <BackButton />
        <NextButton
          disabled={disabled}
          onClick={actions.next} />
      </Footer>
    </>
  );
});
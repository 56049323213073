import { useMemo } from 'react';
import { format } from 'date-fns';
import { useAudioPlayerRef } from '@containers/AudioPlayer';
import { useCallInstanceId, useTranscript, TranscriptMediaContext } from '@containers/Transcript';
import { useCallRecording } from '@utils/api';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { CallRecordingPlayer } from 'components/CallRecording/Player';
import { Transcript } from 'components/Transcript';
import { TranscriptDownloadMenu } from './DownloadMenu';
import { VoiceTranscriptProps } from './interfaces';
import styles from './style.css';

type Props =
  VoiceTranscriptProps;

export const VoiceTranscript = ({ call, project, user }: Props) => {

  const [player] = useAudioPlayerRef();
  const transcript = useTranscript();
  const instanceId = useCallInstanceId();

  const {
    data: recording,
    isLoading,
  } = useCallRecording({
    callId: call.id,
    conferenceInstanceId: instanceId,
    includeWaveform: true,
  }, {
    enabled: !!instanceId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const displayTime =
    useMemo(() => format(call.timeEnd, 'MMM do yyyy hh:mm aa'), [call.timeEnd]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div>
          <div className={styles.label}>Title:</div>
          <div>{project.name}</div>
        </div>
        <div>
          <TranscriptDownloadMenu
            callId={call.id}
            transcriptId={transcript?.id}
            source={{
              type: 'audio',
              url: recording?.url,
            }} />
        </div>
      </div>
      <div className={styles.header}>
        <div>
          <div className={styles.label}>Completed On:</div>
          <div>{displayTime}</div>
        </div>
      </div>
      <div className={styles.sticky}>
        <div className={styles.audioPlayer}>
          {isLoading && <ActivityIndicator show />}
          {recording &&
            <CallRecordingPlayer
              src={recording.url}
              type="wave"
              waveform={recording.waveform} />}
        </div>
      </div>
      <div>
        {!transcript && (
          <div className={styles.loader}>
            <ActivityIndicator show />
          </div>
        )}
        {transcript &&
          <TranscriptMediaContext.Provider value={player}>
            <Transcript />
          </TranscriptMediaContext.Provider>
        }
      </div>
    </div>
  );
};
import { useCallback, useState, useMemo, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiMenu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { cx } from '@utils';
import styles from './style/Menu.css';

const useActionStyles = makeStyles({
  divider: {
    marginLeft: '4px',
    marginRight: '4px',
  },
  icon: {
    height: '20px',
    width: '20px',
  },
  menu: {
    borderRadius: '4px',
    transition: 'duration 0 !important',
  },
  list: {
    padding: '0',
  },
});

type Props = ChildrenProps;

export const ActionsMenu = memo(({ children }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const muiStyles = useActionStyles();

  const value = useMemo(() => ({
    close: handleClose,
  }), [handleClose]);

  return (
    <>
      <div
        className={cx(styles.btn, { [styles.open]: open })}
        onClick={handleClick}>
        <div className={styles.btnLabel}>
          Actions
        </div>
        <Divider
          classes={{ root: muiStyles.divider }}
          variant="middle"
          orientation="vertical" />
        <KeyboardArrowDownIcon classes={{ root: muiStyles.icon }} />
      </div>
      <MuiMenu
        classes={{ paper: muiStyles.menu }}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={{
          appear: 0,
          enter: 0,
          exit: 0,
        }}>
        {children}
      </MuiMenu>
    </>
  );
});

export * from './ActionButton';
export * from './ActionLink';
import { SessionMainParticipant } from './Session.MainParticipant';
import { SessionParticipantStrip } from './Session.ParticipantStrip';
import styles from './style/Session.Video.css';

export function SessionVideo() {
  return (
    <div className={styles.root}>
      <SessionParticipantStrip />
      <SessionMainParticipant />
    </div>
  );
}
import { useCallback, memo } from 'react';
import { Check } from 'react-feather';
import { useSelectPayoutSetup } from '@containers';
import * as AddressEntry from 'components/AddressEntry';
import { Input } from 'components/Input';
import { StripeAuth } from 'components/Stripe';
import { SetupPayoutDetailsStripeProps, SetupPayoutDetailsCheckProps, SetupPayoutDetailsPayPalProps } from './interfaces';
import styles from './style/Setup.css';

export const SetupPayoutDetailsStripe = ({ label = 'Step 3: Payout Details' }: SetupPayoutDetailsStripeProps) => {
  const setup = useSelectPayoutSetup();

  if (setup.stripeAccountId) {
    return (
      <div className={styles.step}>
        <div className={styles.stepLabel}>{label}</div>
        <div className={styles.stepContent}>
          <div className={styles.stripe}>
            <div className={styles.stripeCompleted}>
              <div className={styles.check}>
                <Check className={styles.checkIcon} size={18} />
              </div>
              <div>Stripe Connected!</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.step}>
      <div className={styles.stepLabel}>{label}</div>
      <div className={styles.stepContent}>
        <div className={styles.stripe}>
          <div className={styles.stripeCopy}>Complete the Stripe setup process.</div>
          <StripeAuth />
        </div>
      </div>
    </div>
  );
};

export const SetupPayoutDetailsCheck = memo(({ label = 'Step 3: Payout Details', address, onChange }: SetupPayoutDetailsCheckProps) => {
  return (
    <div className={styles.step}>
      <div className={styles.stepLabel}>{label}</div>
      <div className={styles.stepContent}>
        <div className={styles.addressEntry}>
          <AddressEntry.Form.FullAddress
            labelClassName={styles.addressEntryLabel}
            fieldClassName={styles.addressEntryField}
            hideCountry
            address={address}
            onChange={onChange} />
        </div>
      </div>
    </div>
  );
});

export const SetupPayoutDetailsPayPal = memo(({ label = 'Step 3: Payout Details', account, onChange }: SetupPayoutDetailsPayPalProps) => {

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <div className={styles.step}>
      <div className={styles.stepLabel}>{label}</div>
      <div className={styles.stepContent}>
        <div className={styles.paypalEntry}>
          <div className={styles.paypalLabel}>PayPal Account <span className={styles.star}>*</span></div>
          <Input
            className={styles.paypal}
            placeholder="PayPal account email address"
            value={account}
            onChange={handleChange} />
        </div>
      </div>
    </div>
  );
});
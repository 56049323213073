import { useSelector } from 'react-redux';
import { CalendarConfirmTimeData } from '@containers/Calendar/interfaces';
import { Call, Contact } from '@/types';
import { Calendar } from 'components/Calendar/Calendar';
import { Selecting } from './Selecting.js';

const mapState = (state: Store.State) => ({
  contacts: state.contacts,
  user: state.user,
});

type Props = {
  call:      Pick<Call, 'id'>;
  onSubmit:  (data: CalendarConfirmTimeData) => unknown;
  scheduler: { id: number; };
  with:      Pick<Contact, 'id'>;
};

const Confirmations = (props: Props) => {
  const state = useSelector(mapState);

  const calendar = {
    call: props.call,
    scheduler: props.scheduler,
    with: props.with,
  };

  return (
    <Calendar calendar={calendar}>
      <Selecting
        contacts={state.contacts}
        onSubmit={props.onSubmit}
        user={state.user} />
    </Calendar>
  );
};

export { Confirmations };
export default Confirmations;
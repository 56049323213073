import {
  SurveyTemplateEntityType,
  SurveyTemplateQuestion,
} from '@enums/survey.template';
import { hasOptions, hasRows } from '@containers/Survey/utils/questions';
import {
  SurveySection,
  SurveyTemplate,
  SurveyQuestion,
  SurveyQuestionOption,
  SurveyQuestionMatrixRow,
} from '@/types/survey';
import { SurveyOptionType } from '@/enums/Survey';
import {
  SurveyOptionsBuilder,
  SurveyRowsBuilder,
  SurveySectionsBuilder,
} from '../interfaces';

type ComputeRemovedOptions = {
  key: SurveyTemplateEntityType;
  questions: SurveyQuestion[];
  removedItems: string[];
};

export function computeRemovedOptions({
  key,
  questions,
  removedItems,
}: ComputeRemovedOptions) {
  return questions.reduce<SurveyOptionsBuilder.RemoveOption.State[]>((acc, q) => {

    if (hasOptions(q)) {
      const removed = q.options
        .filter(f => f.metadata.template?.linkedEntity?.type === key &&
          removedItems.includes(f.metadata.template?.linkedEntity?.id))
        .map(o => ({
          questionIdentifier: q.base.identifier,
          option: {
            identifier: o.base.identifier,
          },
        }));

      return acc.concat(removed);
    } else {
      return acc;
    }

  }, []);
}

type ComputeRemovedRows = {
  key: SurveyTemplateEntityType;
  questions: SurveyQuestion[];
  removedItems: string[];
};

export function computeRemovedRows({
  key,
  questions,
  removedItems,
}: ComputeRemovedRows) {
  return questions.reduce<SurveyRowsBuilder.RemoveRow.State[]>((acc, q) => {

    if (hasRows(q)) {
      const removed = q.matrixRows
        .filter(f => f.metadata.template?.linkedEntity?.type === key &&
          removedItems.includes(f.metadata.template.linkedEntity.id))
        .map(m => ({
          questionIdentifier: q.base.identifier,
          row: {
            identifier: m.base.identifier,
          },
        }));
      return acc.concat(removed);
    } else {
      return acc;
    }

  }, []);
}

type ComputeAddedOptions = {
  addedItems: SurveyTemplate.LinkedEntity[];
  questions: SurveyQuestion[];
  toCheck: SurveyTemplateQuestion[];
  generateOption: (data: {
    ordinal: number;
    item: SurveyTemplate.LinkedEntity;
  }) => SurveyQuestionOption;
};

export function computeAddedOptions({
  generateOption,
  toCheck,
  questions,
  addedItems,
}: ComputeAddedOptions) {
  return questions
    .filter(f => toCheck.includes(f.metadata.template?.key))
    .reduce<SurveyOptionsBuilder.AddOptions.State[]>((acc, x) => {
    if (addedItems.length) {
      const defaultOptions = x.options.filter(f => f.type === SurveyOptionType.Default);
      const lastOptionOrdinal = Math.max(...defaultOptions.map(m => m.ordinal));

      return acc.concat({
        questionIdentifier: x.base.identifier,
        reorder: false,
        options: addedItems.map((item, i) => generateOption({
          item,
          ordinal: i + 1 + lastOptionOrdinal,
        })),
      });
    } else {
      return acc;
    }

  }, []);
}

type GenerateAddedRows = {
  addedItems: SurveyTemplate.LinkedEntity[];
  questions: SurveyQuestion[];
  toCheck: SurveyTemplateQuestion[];
  generateRow: (data: {
    ordinal: number;
    item: SurveyTemplate.LinkedEntity;
  }) => SurveyQuestionMatrixRow;
};

export function computeAddedRows({
  generateRow,
  toCheck,
  questions,
  addedItems,
}: GenerateAddedRows) {
  return questions
    .filter(f => toCheck.includes(f.metadata.template?.key))
    .reduce<SurveyRowsBuilder.AddRows.State[]>((acc, x) => {
    if (addedItems.length) {
      const lastRowOrdinal = Math.max(...x.matrixRows.map(m => m.ordinal));
      return acc.concat({
        questionIdentifier: x.base.identifier,
        reorder: false,
        rows: addedItems.map((item, i) => generateRow({
          item,
          ordinal: i + 1 + lastRowOrdinal,
        })),
      });
    } else {
      return acc;
    }

  }, []);
}

type ComputeUpdatedItems = {
  updatedItems: SurveyTemplate.LinkedEntity[];
  questions: SurveyQuestion[];
  toCheck: SurveyTemplateQuestion[];
};

export function computeUpdatedOptions({
  toCheck,
  questions,
  updatedItems,
}: ComputeUpdatedItems) {
  return questions
  .filter(f => toCheck.includes(f.metadata.template?.key))
  .reduce<SurveyOptionsBuilder.UpdateOptionValue.State[]>((acc, x) => {
    const updated = x.options.reduce((acc2, o) => {
      const competitor = updatedItems.find(s => s.id === o.metadata.template?.linkedEntity?.id);
      if (competitor && competitor.value !== o.value) {
        return acc2.concat({
          questionIdentifier: x.base.identifier,
          option: {
            identifier: o.base.identifier,
          },
          value: competitor.value,
        });
      }

      return acc2;
    }, []);

    return acc.concat(updated);

  }, []);
}

export function computeUpdatedRows({
  toCheck,
  questions,
  updatedItems,
}: ComputeUpdatedItems) {

  return questions
    .filter(f => toCheck.includes(f.metadata.template?.key))
    .reduce<SurveyRowsBuilder.UpdateRowValue.State[]>((acc, x) => {
    const updated = x.matrixRows.reduce((acc2, r) => {
      const competitor = updatedItems.find(s => s.id === r.metadata.template?.linkedEntity?.id);
      if (competitor && competitor.value !== r.value) {
        return acc2.concat({
          questionIdentifier: x.base.identifier,
          row: {
            identifier: r.base.identifier,
          },
          value: competitor.value,
        });
      }

      return acc2;
    }, []);

    return acc.concat(updated);

  }, []);
}

type ComputeRemovedSections = {
  key: SurveyTemplateEntityType;
  removedItems: string[];
  sections: SurveySection[];
};

export function computeRemovedSections({ key, removedItems, sections }: ComputeRemovedSections): SurveySectionsBuilder.RemoveSection.State[] {

  return sections
    .filter(f => f.metadata.template?.linkedEntity?.type === key &&
      removedItems.includes(f.metadata.template.linkedEntity.id))
    .map(m => ({
      identifier: m.identifier,
    }));
}

type ComputeChangedValues = {
  oldValues: SurveyTemplate.LinkedEntity[];
  values: SurveyTemplate.LinkedEntity[];
};

export function computeChangedValues({
  oldValues,
  values,
}: ComputeChangedValues) {
  const added = values
    .filter(f => !oldValues.some(s => s.id === f.id));
  const removed = oldValues
    .filter(f => !values.some(s => s.id === f.id))
    .map(m => m.id);
  const updated = values
    .filter(f => oldValues.some(s => s.id === f.id));

  return {
    added,
    removed,
    updated,
  };
}
import http from '@services/http';
import * as xform from '@transformers';
import { Payment } from '@services/api/interfaces';

export const newMethod = (
  data: Payment.Enterprise.NewMethod.Request,
): Promise<Payment.Enterprise.NewMethod.Response> => {
  return http.post(`/payment/enterprise/${data.groupId}/payment-method`, data);
};

export const getMethodDetails = (
  data: Payment.Enterprise.GetMethodDetails.Request,
): Promise<Payment.Enterprise.GetMethodDetails.Response> => {
  return http.get(`/payment/enterprise/${data.groupId}/method-details`);
};

export const verifyBankAccount = (
  data: Payment.Enterprise.VerifyBankAccount.Request,
): Promise<Payment.Enterprise.VerifyBankAccount.Response> => {
  return http.post(`/payment/enterprise/${data.groupId}/verify-bank-account`, data);
};

export const getBillingHistory = (
  data: Payment.Enterprise.GetBillingHistory.Request,
): Promise<Payment.Enterprise.GetBillingHistory.Response> => {
  return http.get(`/payment/enterprise/${data.groupId}/history${data.cursor ? `?c=${data.cursor}` : ''}`)
             .then(xform.normalizeBillingHistory);
};
import { Switch } from 'components/Switch';
import styles from './style/Settings.Email.css';

type Props = {
  active: boolean;
  name: string;
  description: string;
  onClick: (active: boolean) => void;
};

export function Bucket({ active, name, description, onClick }: Props) {
  return (
    <div className={styles.bucket}>
      <div className={styles.left}>
        <div className={styles.name}>{name}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.right}>
        <Switch
          active={active}
          onClick={onClick} />
      </div>
    </div>
  );
}

export default Bucket;
import { ModalBase, ModalBaseProps } from './ModalBase';

type Props = {
  className?: string;
} & Omit<ModalBaseProps, 'disableOverlayClick' | 'onClose'>
  & typeof defaultProps;

const Dialog = (props: Props) => {
  return (
    <ModalBase
      classes={{ root: props.className }}
      disableOverlayClick
      disablePortal={props.disablePortal}
      open={props.open}>
      {props.children}
    </ModalBase>
  );
};

const defaultProps = {
  disablePortal: false,
};

Dialog.defaultProps = defaultProps;

export type DialogProps = Props;

export { Dialog };
export default Dialog;
import { Middleware } from 'redux';
import { createLogger } from 'redux-logger';

export const logger = createLogger({ collapsed: true });

export const apply = (middlewares: Middleware[]) => {
  return process.env.VANCERY_ENV !== 'production'
    ? middlewares.concat(logger)
    : middlewares;
};

export default {
  apply,
  middleware: logger,
};
import { useCallback, useState, useEffect } from 'react';
import fscreen from 'fscreen';

type ToggleFullscreenParams = {
  element: HTMLElement;
};

export function useFullscreenToggle() {
  const [isFullscreen, setIsFullscreen] = useState(!!fscreen.fullscreenElement);

  useEffect(() => {
    const onFullscreenChange = () => setIsFullscreen(!!fscreen.fullscreenElement);
    fscreen.addEventListener('fullscreenchange', onFullscreenChange);
    return () => {
      fscreen.removeEventListener('fullscreenchange', onFullscreenChange);
    };
  }, []);

  const toggleFullscreen = useCallback((params?: ToggleFullscreenParams) => {
    isFullscreen
      ? fscreen.exitFullscreen()
      : fscreen.requestFullscreen(params?.element || document.documentElement);
  }, [isFullscreen]);

  return [isFullscreen, toggleFullscreen] as const;
}

export default useFullscreenToggle;
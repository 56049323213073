import { useCallback, useEffect } from 'react';
import { BoldExtension, BulletListExtension, HeadingExtension, ItalicExtension, LinkExtension, OrderedListExtension, UnderlineExtension } from 'remirror/extensions';
import css from 'remirror/styles/all.css';
import { CommandsExtension } from '@remirror/core';
import { useRemirror, Remirror, ThemeProvider } from '@remirror/react';
import * as API from '@api/interfaces';
import { useTopicTagsContext } from '@containers/PostCreation/hooks';
import { useUseableCSS } from '@utils';
import { Author } from '@screens/Post.Article.New/Author';
import { CoverImage } from '@screens/Post.Article.New/CoverImage';
import { Title, Topics } from '@screens/Post.Article.New/Form';
import styles from '@screens/Post.Article.New/style/PostNew.css';
import { UpdateButton } from 'components/Post.Article.Form/Toolbar.Update';
import { Toolbar } from 'components/Post.Article.Form/Toolbar';
import { FloatingLinkToolbar, ImageExtension, LinkContainer } from 'components/Remirror';

type Props = {
  item: API.Posts.Articles.FetchPostEditContext.Response['post'];
};

export const Form = (props: Props) => {
  const [, dispatch] = useTopicTagsContext();

  const { manager, state } = useRemirror({
    extensions: () => [
      new CommandsExtension({}),
      new HeadingExtension({}),
      new BoldExtension({}),
      new ItalicExtension(),
      new UnderlineExtension(),
      new BulletListExtension({}),
      new OrderedListExtension(),
      new ImageExtension({
        enableResizing: true,
      }),
      new LinkExtension({
        autoLink: true,
      }),
    ],
    content: props.item.content.html,
    selection: 'end',
    stringHandler: 'html',
  });

  useUseableCSS(css);

  const initialize = useCallback(() => {
    dispatch({
      items: props.item.topics,
      type: 'add-topics',
    });
  }, [
    dispatch,
    props.item.topics,
  ]);

  useEffect(() => {

    if (props.item.topics.length > 0) {
      initialize();
    }

  }, [
    initialize,
    props.item.topics,
  ]);

  return (
    <ThemeProvider>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.main}>
            <Remirror
              autoFocus
              autoRender="end"
              initialContent={state}
              manager={manager}
              placeholder="Share your insights here.">
              <LinkContainer>
                <Toolbar>
                  <UpdateButton />
                </Toolbar>
                <CoverImage />
                <Title />
                <Author />
                <Topics />
                <FloatingLinkToolbar />
              </LinkContainer>
            </Remirror>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

Form.displayName = 'Post.Article.Form';
import { createRef, useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { format } from 'date-fns/fp';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import * as api from '@api';
import * as consts from '@consts';
import { PaidMessagingContainer } from '@containers/PlatformMessage/PaidMessagingContainer';
import { RequestCallContainer } from '@containers/RequestCall/RequestCallContainer';
import { device } from '@utils';
import { useCreateViewVideoMediaMutation } from '@utils/api';
import { useVideoAnalytics } from '@utils/analytics';
import { Posts } from '@/types';
import * as Post from 'components/ContentFeed/Post';
import { AdditionalDetails } from './AdditionalDetails';
import { PaidMessageButton } from './PaidMessageButton';
import { ShortProfile } from './ShortProfile';
import { TopicTags } from './TopicTags';
import { VideoList } from './VideoList';
import styles from './style/Discovery.css';
import { useVideoPlayback } from './useVideoPlayback';

type Props = RouteComponentProps<{ postId: string; }>;

export const DiscoveryPost = (props: Props) => {
  const { mutate: createViewEvent } = useCreateViewVideoMediaMutation();
  const params = useParams<{ postId: string; }>();
  const [state, dispatch] = useState<Posts.Post>(null);
  const ref = createRef<HTMLVideoElement>();

  useVideoAnalytics({
    ref,
    mediaId: state?.media?.id,
  });

  const playing = useVideoPlayback(ref);

  useEffect(() => {

    api.discovery.fetchContentItem({ postId: +params.postId })
    .then(x => dispatch(x));

  }, [params.postId]);

  useEffect(() => {

    if (state?.id) {
      createViewEvent(state.id);
    }

  }, [
    createViewEvent,
    state?.id,
  ]);

  if (!state) return null;

  const timestamp = format('MMMM d, yyy');

  return (
    <PaidMessagingContainer>
      <RequestCallContainer>
        <div className={styles.root}>
          <div className={styles.wrap}>
            <div className={styles.main}>
              <div className={styles.content}>
                <div className={styles.player}>
                  <video
                    controls
                    className={styles.video}
                    poster={state.media.thumbnail.url}
                    preload="metadata"
                    ref={ref}
                    src={state.media.content.url}
                    width="100%" />
                  {(!device.phone && !playing) &&
                    <PlayArrowRounded
                      className={styles.btn}
                      onClick={() => ref.current?.play?.()} />}
                </div>
                <div className={styles.title}>{state.media.title}</div>
                <div className={styles.meta}>
                  {state.author.id !== consts.users.SystemId &&
                    <PaidMessageButton
                      className={styles.link}
                      item={state.author}
                      variant="link">
                      {`Engage with ${state.author.profile.firstName}!`}
                    </PaidMessageButton>}
                  <span className={styles.views}>{state.media.statistics.viewCount} views</span>
                  <span className={styles.date}>{timestamp(state.createdOn)}</span>
                </div>

                <div className={styles.body}>
                  <Post.Body
                    maxLines={10}
                    value={state.media.content.description} />
                </div>

                <TopicTags items={state.topics} />

                {state.author.bio &&
                  <>
                    <div className={styles.divider} />
                    <PaidMessageButton
                      className={styles.link}
                      item={state.author}
                      variant="link">
                      {`Engage with ${state.author.profile.firstName}!`}
                    </PaidMessageButton>
                    <ShortProfile item={state.author} />
                  </>}
                <AdditionalDetails
                  mediaId={state.media.id}
                  hasTranscript={!!state.transcript?.mediaId} />
              </div>

              <div className={styles.sidebar}>
                <VideoList />
              </div>
            </div>
          </div>
        </div>
      </RequestCallContainer>
    </PaidMessagingContainer>
  );
};

DiscoveryPost.displayName = 'Discovery.Post';
import { useState } from 'react';
import { useCallApprovalActions, useCallReviewState } from '@containers';
import * as enums from '@enums';
import styles from '@screens/ComplianceCallReview/style/ApprovalButtons.css';
import { Button } from 'components/Button';

type Props = unknown;

const ApprovalButtons = (props: Props) => {
  const actions = useCallApprovalActions();
  const state = useCallReviewState();
  const [submitting, setSubmitting] = useState(false);

  function wrapAction(fn: typeof actions.approve | typeof actions.reject) {
    return async () => {
      setSubmitting(true);

      await fn();

      setSubmitting(false);
    };
  }

  if (state.call.approvalStatusId !== enums.ApprovalStatus.NeedsApproval) return null;

  return (
    <div className={styles.root}>
      <Button
        className={styles.reject}
        color="secondary"
        disabled={submitting}
        onClick={wrapAction(actions.reject)}
        variant="brick">
        Reject
      </Button>
      <Button
        className={styles.approve}
        color="secondary"
        disabled={submitting}
        onClick={wrapAction(actions.approve)}
        variant="brick">
        Approve
      </Button>
    </div>
  );
};

export { ApprovalButtons };
export default ApprovalButtons;
import { useSelector } from 'react-redux';
import { selectObject } from '@store/selectors';

const useSelectObject = (objectId: number) => {
  const object = useSelector(selectObject(objectId));

  return object;
};

export { useSelectObject };
export default useSelectObject;
import { useCallback } from 'react';
import { RadioGroup, RadioGroupOnOptionChange } from 'components/Radio';
import { CheckboxGroup, CheckboxGroupOnItemToggle } from 'components/Checkbox';
import { ItemAnswer, MatrixVariant, SurveyMatrixGridListItemProps } from './interfaces';
import styles from './style/List.css';

export default function SurveyMatrixGridListItem<T extends MatrixVariant>({ className, options, row, value, variant, updateRowAnswer }: SurveyMatrixGridListItemProps<T>) {

  const handleRadioToggle: RadioGroupOnOptionChange =
    useCallback(item => updateRowAnswer(+item.optionId), [updateRowAnswer]);

  const handleCheckboxToggle: CheckboxGroupOnItemToggle = 
    useCallback(item => updateRowAnswer(+item.id), [updateRowAnswer]);

  const renderToggle = useCallback(() => {
    if (variant === 'single') {
      const selected = (value as ItemAnswer<'single'>) ?? '';
      const optionsMapped = options.map(o => ({ value: o.id, label: o.value }));
      return (
        <RadioGroup
          className={className}
          selectedOption={selected}
          onOptionChange={handleRadioToggle}
          options={optionsMapped} />
      );
    } else {
      const checkedItems = (value as ItemAnswer<'multiselect'> || []);
      const optionsMapped = options.map(o => ({ id: o.id, label: o.value }));
      return (
        <CheckboxGroup
          className={className}
          checkedItems={checkedItems}
          items={optionsMapped}
          onToggle={handleCheckboxToggle} />
      );
    }

  }, [
    className,
    handleCheckboxToggle,
    handleRadioToggle,
    variant,
    value,
  ]);

  return (
    <div className={styles.listItem}>
      <div className={styles.listTitle}>{row.value}</div>
      {renderToggle()}
    </div>
  );
}

export { SurveyMatrixGridListItem };
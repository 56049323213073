import { useCallback, useEffect, useState } from 'react';
import { useAsyncStateLazy } from '@utils';
import * as api from '@api';
import { ProjectMetricsMap } from '@/types';
import { useHasClientRole } from '@containers/User';
import {
  ProjectMetricsContext,
  ProjectMetricsLoadingContext,
  FetchProjectMetricsContext,
  UpdateProjectMetricsContext,
} from './Context';
import { UpdateProjectMetricsParams } from './interfaces';

type Props =
  ChildrenProps;

export const ProjectMetricsContainer = (props: Props) => {
  const hasClientRole = useHasClientRole();
  const [metrics, setMetrics] = useState<ProjectMetricsMap>();
  const fetchProjectMetrics = useCallback(() => {
    return api.metrics.getProjectMetrics()
      .then(({ metrics }) => metrics);
  }, []);

  const [response, fetch] = useAsyncStateLazy(fetchProjectMetrics);

  useEffect(() => {
    if (hasClientRole) {
      fetch()
        .then(data => setMetrics(data as ProjectMetricsMap));
    }
  }, [fetch, hasClientRole]);

  const updateProjectMetrics = useCallback((data: UpdateProjectMetricsParams) => {
    setMetrics(initial => ({
      ...initial,
      [data.projectId]: data.metrics,
    }));
  }, []);

  return (
    <ProjectMetricsContext.Provider value={metrics || {}}>
      <ProjectMetricsLoadingContext.Provider value={response.loading || !metrics}>
        <FetchProjectMetricsContext.Provider value={fetch}>
          <UpdateProjectMetricsContext.Provider value={updateProjectMetrics}>
            {props.children}
          </UpdateProjectMetricsContext.Provider>
        </FetchProjectMetricsContext.Provider>
      </ProjectMetricsLoadingContext.Provider>
    </ProjectMetricsContext.Provider>
  );
};

export default ProjectMetricsContainer;
import { useContext } from 'react';
import { QuestionLogicBuilderContext } from '../Context';

const useQuestionLogicBuilderState = () => {
  const state = useContext(QuestionLogicBuilderContext);

  return state;
};

export { useQuestionLogicBuilderState };
export default useQuestionLogicBuilderState;
import { WizardScreenProps } from './interfaces';

export const WizardScreen = (props: WizardScreenProps) => (
  <>
    {props.renderHeader && props.renderHeader()}
    {props.renderBody()}
    {props.renderNavigation && props.renderNavigation()}
  </>
);

export type { WizardScreenProps };
export default WizardScreen;
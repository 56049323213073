const Bank = props => {
  const { color, size, ...otherProps } = props;

  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      fill={color || 'currentColor'}
      stroke={color || 'currentColor'}
      width={size || 24}
      height={size || 24}
      {...otherProps}>
      <path d="M479.77,426.45a7.86,7.86,0,0,0-6-3.27L44.05,424.38a8.93,8.93,0,0,0-6.24,3.08L2.35,473.8a13.44,13.44,0,0,0-2.35,7v22.42a3.89,3.89,0,0,0,3.88,3.9H508.12a3.9,3.9,0,0,0,3.88-3.9V480.76a15.59,15.59,0,0,0-2.09-7.15Z" />
      <path d="M455.44,226.15a6,6,0,0,0,6-6V208.9a6,6,0,0,0-6-6H390.12a6,6,0,0,0-6,6v11.25a6,6,0,0,0,6,6h2.71V381.32h-2.71a6,6,0,0,0-6,6v11.25a6,6,0,0,0,6,6h65.31a6,6,0,0,0,6-6V387.32a6,6,0,0,0-6-6h-2.71V226.15Z" />
      <path d="M344.25,202.88H278.94a6,6,0,0,0-6,6v11.25a6,6,0,0,0,6,6h2.71V381.32h-2.71a6,6,0,0,0-6,6v11.25a6,6,0,0,0,6,6h65.31a6,6,0,0,0,6-6V387.32a6,6,0,0,0-6-6h-2.71V226.15h2.71a6,6,0,0,0,6-6V208.9A6,6,0,0,0,344.25,202.88Z" />
      <path d="M233.06,202.88H167.74a6,6,0,0,0-6,6v11.25a6,6,0,0,0,6,6h2.72V381.32h-2.72a6,6,0,0,0-6,6v11.25a6,6,0,0,0,6,6h65.32a6,6,0,0,0,6-6V387.32a6,6,0,0,0-6-6h-2.72V226.15h2.72a6,6,0,0,0,6-6V208.9A6,6,0,0,0,233.06,202.88Z" />
      <path d="M121.86,202.88H56.55a6,6,0,0,0-6,6v11.25a6,6,0,0,0,6,6h2.71V381.32H56.55a6,6,0,0,0-6,6v11.25a6,6,0,0,0,6,6h65.31a6,6,0,0,0,6-6V387.32a6,6,0,0,0-6-6h-2.71V226.15h2.71a6,6,0,0,0,6-6V208.9A6,6,0,0,0,121.86,202.88Z" />
      <path d="M0,166.61a12.13,12.13,0,0,0,12.09,12.1H499.91A12.13,12.13,0,0,0,512,166.61V164c0-6.67-4.7-14.85-10.43-18.23L266.43,7.45c-5.74-3.35-15.13-3.35-20.85,0L10.43,145.74C4.69,149.11,0,157.3,0,164Z" />
    </svg>
  );
};

export { Bank };
export default Bank;
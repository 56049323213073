import { useObjectOwnershipContext } from '@containers/WorkspaceObject';
import { GroupUser } from '@/types';
import { DropDown } from 'components/DropDown';

function getDisplay(data: GroupUser) {
  if (!data) return '';
  return [data.firstName, data.lastName].join(' ');
}

export const ObjectOwnershipDropdown = () => {
  const {
    onUserSelect,
    selectedUser,
    users,
  } = useObjectOwnershipContext();

  return (
    <DropDown<GroupUser>
      items={users}
      getItemValue={getDisplay}
      onSelect={onUserSelect}
      text={getDisplay(selectedUser)} />
  );
};

export default ObjectOwnershipDropdown;
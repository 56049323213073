import { useCallback } from 'react';
import * as api from '@api';

type AuthorizeParams = {
  code: string;
  userId: number;
};

export function useAuthorizeStripeConnect() {
  return useCallback((data: AuthorizeParams) => {
    return api.payout.stripeAuthorizeConnect({
      code: data.code,
      userId: data.userId,
    })
    .catch(() => {});
  }, []);
}

export default useAuthorizeStripeConnect;
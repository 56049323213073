import { Clock, Briefcase, Calendar } from 'react-feather';
import { differenceInMinutes } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { path } from '@consts';
import { BaseCall, Contact } from '@/types';
import { Button } from 'components/Button';
import logo from 'static/images/vancery-landscape.svg?url';
import { UserAvatar } from 'components/UserAvatar';
import styles from './style/BookingConfirmed.css';

type Props = {
  call:     BaseCall;
  project?: Descriptor;
  user?:    Pick<Contact, 'id' | 'profile'>;
};

export const BookingConfirmed = (props: Props) => {
  const start = utcToZonedTime(new Date(props.call.timeStart), timeZone);
  const end = utcToZonedTime(new Date(props.call.timeEnd), timeZone);
  const duration = differenceInMinutes(end, start);

  const date = {
    day: format(start, 'd', { timeZone }),
    end: format(end, 'h:mm'),
    meridiem: format(end, 'aa'),
    month: format(start, 'MMMM', { timeZone }),
    start: format(start, 'h:mm'),
    tz: format(start, 'z', { timeZone }),
    weekday: format(start, 'eeee', { timeZone }),
  };

  const message = props.user
      ? `Your call with ${props.user.profile.fullname} is confirmed!`
      : `Your call is confirmed!`;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <img src={logo} />
          {props.user &&
            <UserAvatar
              className={styles.img}
              pictureUrl={props.user.profile.pictureUrl} />}
          <div className={styles.message}>
            <div className={styles.text}>{message}</div>
          </div>
          <div className={styles.message}>
            <div className={styles.text}>An email confirmation has been sent to you.</div>
          </div>
        </div>

        <div className={styles.main}>
          <div className={styles.message}>
            <Clock className={styles.icon} />
            <div className={styles.text}>{`${duration} minute call`}</div>
          </div>
          <div className={styles.message}>
            <Calendar className={styles.icon} />
            <div className={styles.text}>{`${date.weekday}, ${date.month} ${date.day}, ${date.start}-${date.end} ${date.meridiem} ${date.tz}`}</div>
          </div>
          {props.project &&
            <div className={styles.message}>
              <Briefcase className={styles.icon} />
              <div className={styles.text}>{`${props.project.name}`}</div>
            </div>
          }
        </div>
        <div className={styles.footer}>
          <span>To cancel or reschedule, please</span>
          <Button
            className={styles.btn}
            to={path.Website.Login}
            variant="link">
            log in
          </Button>
          <span>.</span>
        </div>
      </div>
    </div>
  );
};

const timeZone = `America/New_York`;
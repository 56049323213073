import { useCallback, useMemo } from 'react';
import { OpenedResponseOptionRow, ResponsesDataRow, ResponsesViewAll } from '@presentation/ProjectSurveyResponses';
import { formatPlurality, formatNumber, roundToPlace } from '@utils';
import { NumberInputTableQuestion } from '@/types';
import * as chart from '@containers/SurveyResponses/utils';
import { NumberInputTable } from './interfaces';
import { useNumberInputTableBuckets, useGetNumberInputTableBucketUsers } from './hooks/number-input-table';
import styles from './style/NumberInputTable.Data.css';

type Props = {
  colors?: string[];
  item: NumberInputTableQuestion.Question;
  onClick: (rowId: number, optionId: number, bucket: NumberInputTable.Bucket) => void;
  onReset: () => void;
  optionId: number;
  rowId: number;
  responses: NumberInputTableQuestion.Aggregate.Data;
};

export const NumberInputTableOptionData = ({ colors = chart.options.OptionsColors, item, onClick, onReset, optionId, rowId, responses }: Props) => {

  const buckets = useNumberInputTableBuckets();
  const getBucketUsers = useGetNumberInputTableBucketUsers();

  const row = useMemo(() => {
    return item.matrixRows.find(f => f.base.id === rowId);
  }, [
    item.matrixRows,
    rowId,
  ]);

  const option = useMemo(() => {
    return item.options.find(f => f.base.id === optionId);
  }, [
    item.options,
    optionId,
  ]);

  const optionSubtitle = useMemo(() => {
    const avg = responses[rowId].options[optionId].avg;

    return `(Avg: ${formatNumber(roundToPlace(avg, 1), 1)})`;
  }, [
    optionId,
    rowId,
    responses,
  ]);

  const renderBucket = useCallback((bucket: NumberInputTable.Bucket, i: number) => {
    const users = getBucketUsers(rowId, optionId)(bucket);
    const count = Object.keys(users).length;

    if (!count) return null;

    const label = `[${bucket.minValue} - ${bucket.maxValue})`;
    const subtitle = `(${count} ${formatPlurality('respondent', count)})`;

    const handleClick = () => onClick(rowId, optionId, bucket);

    return (
      <ResponsesDataRow
        key={i}
        className={styles.bucketRow}
        onClick={handleClick}
        label={label}
        subtitle={subtitle}>
        <ResponsesViewAll
          onClick={handleClick}
          title="View All Respondents" />
      </ResponsesDataRow>
    );
  }, [
    getBucketUsers,
    onClick,
    optionId,
    rowId,
  ]);

  return (
    <>
      <div className={styles.row}>{row.value}</div>
      <OpenedResponseOptionRow
        color={colors[option.ordinal - 1]}
        label={option.value}
        subtitle={optionSubtitle}
        onClose={onReset} />
      {buckets.map(renderBucket)}
    </>
  );
};

export default NumberInputTableOptionData;
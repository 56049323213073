import devices from 'static/images/website/devices.png';
import styles from './style.css';

const Overview = () => {
  return (
    <div className={styles.col}>
      <div className={styles.header}>
        <h1 className={styles.title}>Ready for a better research experience?</h1>
      </div>
      <div className={styles.content}>
        <ul className={styles.ul}>
          <li className={styles.li}><div className={styles.bullet} />Talk to tens of thousands of professionals from top companies</li>
          <li className={styles.li}><div className={styles.bullet} />Create custom, non-anonymous surveys to monitor real-time market trends</li>
          <li className={styles.li}><div className={styles.bullet} />Invite your own experts into Vancery and set their rates yourself</li>
          <li className={styles.li}><div className={styles.bullet} />Interface with experts directly on our HD video research platform</li>
        </ul>
        <img
          alt='Vancery Devices'
          className={styles.devices}
          src={devices} />
      </div>
    </div>
  );
};

export { Overview };
export default Overview;

import { useMemo } from 'react';
import { Cell, ColumnInstance, useFlexLayout, useTable } from 'react-table';
import { ColumnIdentifer, Row } from '$website/containers/Pricing/interfaces';
import { usePricingContext } from '$website/containers/Pricing/hooks';
import * as Column from './Column';
import { ColumnPricingItem } from './interfaces';
import styles from './style/Pricing.Table.css';

type Props = unknown;

export const PricingTable = (props: Props) => {
  const ctx = usePricingContext();

  const columns = useMemo<ColumnPricingItem[]>(() => {
    return [{
      Header: Column.Service.Header,
      id: ColumnIdentifer.Service,
      Cell: Column.Service.Cell,
    }, {
      Header: Column.Plan.Header,
      id: ColumnIdentifer.Customer,
      Cell: Column.Plan.Cell,
      Footer: Column.Plan.Footer,
    }, {
      Header: Column.Plan.Header,
      id: ColumnIdentifer.Market,
      Cell: Column.Plan.Cell,
      Footer: Column.Plan.Footer,
    }, {
      Header: Column.Plan.Header,
      id: ColumnIdentifer.Enterprise,
      Cell: Column.Plan.Cell,
      Footer: Column.Plan.Footer,
    }];
  }, []);

  const {
    footerGroups,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: ctx.table.items,
    monthly: ctx.monthly,
    plans: ctx.plans,
    setMonthly: ctx.setMonthly,
  },
  useFlexLayout,
  );

  return (
    <div
      className={styles.root}
      {...getTableProps()}>

      <div>
        {headerGroups.map(headerGroup => (
          <div
            className={styles.row}
            key={headerGroup.id}
            {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: ColumnInstance<Row>) =>
              <div
                className={styles.cell}
                key={column.id}
                {...column.getHeaderProps()}
                style={getHeaderStyle(column)}>
                {column.render('Header')}
              </div>)}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()}>
        {rows.map((row, i, arr) => {
          prepareRow(row);
          return (
            <div
              className={styles.row}
              key={row.id}
              {...row.getRowProps()}>
              {row.cells.map((cell: Cell<Row>) => {
                return (
                  <div
                    className={styles.cell}
                    key={cell.column.id}
                    {...cell.getCellProps()}
                    style={getCellStyle(cell.column.id as ColumnIdentifer)}>
                    <div {...getInnerCellProps(cell)}>
                      {cell.render('Cell')}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      {footerGroups.map(group =>
        <div
          key={group.id}
          {...group.getFooterGroupProps()}
          style={{ display: 'flex' }}>
          {group.headers.map((column, i) =>
            <div
              className={styles.cell}
              key={column.id}
              {...column.getFooterProps()}
              style={getFooterStyle(column.id as ColumnIdentifer)}>
              {column.render('Footer')}
            </div>)}
        </div>)}

    </div>
  );
};

PricingTable.displayName = 'Pricing.Table';

function getHeaderStyle(column: ColumnInstance<Row>) {
  const id = column.id as Extract<ColumnIdentifer, ColumnIdentifer.Service>;

  const style = {
    [ColumnIdentifer.Service]: {
      minWidth: 200,
      marginRight: 30,
    },
  };

  return style[id] || {};
}

function getInnerCellProps(cell: Cell<Row>) {
  const classnames = {
    [ColumnIdentifer.Customer]: styles.pad,
    [ColumnIdentifer.Enterprise]: styles.pad,
    [ColumnIdentifer.Market]: styles.pad,
  };

  return {
    className: classnames[cell.column.id as Exclude<ColumnIdentifer, ColumnIdentifer.Service>],
  };
}

function getCellStyle(identifier: ColumnIdentifer) {
  const style = {
    [ColumnIdentifer.Service]: {
      backgroundColor: 'var(--pri-01)',
      minWidth: 200,
      marginRight: 30,
    },
    [ColumnIdentifer.Customer]: {
      backgroundColor: '#f9fbff',
    },
    [ColumnIdentifer.Market]: {
      backgroundColor: '#f3f8ff',
    },
    [ColumnIdentifer.Enterprise]: {
      backgroundColor: '#f9fbff',
    },
  };

  return {
    minHeight: 35,
    ...style[identifier],
  };
}

function getFooterStyle(identifer: ColumnIdentifer) {
  return {
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    ...getCellStyle(identifer),
  };
}
import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import * as consts from '@consts';
import { FollowedTopicsContext } from '@containers/Topic/Context';
import { cx } from '@utils';
import { OnboardingChecklistContext } from './Context';
import { List } from './OnboardingChecklist.List';
import { Progress } from './OnboardingChecklist.Progress';
import { ChecklistItem } from './interfaces';
import styles from './style/OnboardingChecklist.css';

type Props = unknown;

export const OnboardingChecklist = (props: Props) => {
  const ctx = useContext(OnboardingChecklistContext);
  const { query } = useContext(FollowedTopicsContext);

  const history = useHistory();

  const items = useMemo(() => {
    return [{
      complete: ctx.isComplete(ChecklistItem.SetRate),
      title: `Set your rate`,
      onClick: () => history.push(consts.path.Onboarding.Rate),
    }, {
      complete: ctx.isComplete(ChecklistItem.CompleteProfile),
      title: `Create your profile`,
      onClick: () => history.push(consts.path.Account.Profile.Setup),
    }, {
      complete: ctx.isComplete(ChecklistItem.TopicFollowing),
      title: `Pick your interests`,
      onClick: () => history.push(consts.path.Onboarding.Topics),
    }, {
      complete: ctx.isComplete(ChecklistItem.PayoutSetup),
      title: `Receive payouts`,
      onClick: () => history.push(consts.path.Onboarding.PayoutSetup),
    }];
  }, [
    ctx,
    history,
  ]);

  return (
    <>
      <Progress
        className={cx({ [styles.hidden]: query.isLoading })}
        loading={ctx.loading}
        total={ctx.count.total}
        value={ctx.count.incomplete} />
      <List
        items={items}
        loading={ctx.loading} />
    </>
  );
};

OnboardingChecklist.displayName = 'OnboardingChecklist';
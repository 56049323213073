import { Fragment, useCallback } from 'react';
import { SurveySection } from '@presentation/Survey';
import { SurveyQuestion, SurveySection as SC } from '@/types';
import { QuestionLogicItem } from 'components/SurveyBuilder.Question.Logic/QuestionItem';
import styles from './style/SurveySectionLogicBuilder.css';
import { ReadOnlySectionHeader, SurveyLogicSectionHeader } from './Header';

type Props = {
  className?: string;
  editingOrdinal: number;
  includeOrdinalColumn: boolean;
  questions: SurveyQuestion[];
  section: SC;
  toggleEditingOn: (ordinal: number) => () => void;
  toggleHidden: (value: boolean) => void;
};

export const SurveySectionLogicBuilder = ({
  className,
  editingOrdinal,
  includeOrdinalColumn,
  questions,
  section,
  toggleHidden,
  toggleEditingOn,
}: Props) => {
  const renderQuestion = useCallback((item: SurveyQuestion) => {
    const canEdit = !editingOrdinal && item.metadata.canModifyLogic;
    return (
      <div className={styles.question}>
        <QuestionLogicItem
          includeOrdinalColumn={includeOrdinalColumn}
          canEdit={canEdit}
          editing={editingOrdinal === item.ordinal}
          item={item}
          toggleEditingOn={toggleEditingOn(item.ordinal)} />
      </div>
    );
  }, [
    includeOrdinalColumn,
    editingOrdinal,
    toggleEditingOn,
  ]);

  const renderQuestions = useCallback(() => {
    return (
      <>
        {questions
          .map(item =>
            <Fragment key={item.base.identifier}>
              {renderQuestion(item)}
            </Fragment>
          )
        }
      </>
    );
  }, [
    renderQuestion,
    questions,
  ]);

  const renderHeader = useCallback(() => {
    if (editingOrdinal) {
      return (
        <ReadOnlySectionHeader
          className={styles.header}
          item={section} />
      );
    } else {
      return (
        <SurveyLogicSectionHeader
          className={styles.header}
          item={section}
          toggleHidden={toggleHidden} />
      );
    }
  }, [
    editingOrdinal,
    section,
    toggleHidden,
  ]);

  return (
    <SurveySection className={className}>
      <div className={styles.container}>
        {renderHeader()}
        {renderQuestions()}
      </div>
    </SurveySection>
  );
};

export default SurveySectionLogicBuilder;
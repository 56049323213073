import { useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import VideoIcon from '@mui/icons-material/OndemandVideo';
import { useCallVideoUrl } from '@utils/api';
import * as mixpanel from '@services/mixpanel';
import { getLocationFor } from '@utils';
import { Call, Project, Contact } from '@/types';
import { TranscriptDownloadMenu } from '@screens/ProjectCallTranscript/DownloadMenu';
import styles from './style/VideoTranscript.css';

type RouteParams = {
  slug: string;
};

type Props = {
  call: Pick<Call, 'id' | 'timeEnd' | 'userId'>;
  roomInstanceId: number;
  transcript: {
    id: number;
    isAvailable: boolean;
  };
  project: Project;
  user: Contact;
};

export default function VideoTranscript(props: Props) {
  const params = useParams<RouteParams>();
  const { call, project, user } = props;
  const {
    data: url,
  } = useCallVideoUrl({
    callId: call.id,
    roomInstanceId: props.roomInstanceId,
  }, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const handleClick = useCallback(() => {
    mixpanel.event.project.transcript({
      project: {
        id: project.id,
        name: project.name,
      },
      user: {
        id: call.userId,
        name: user.profile.fullname,
      },
    });
  }, [call, project, user]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Link
          className={styles.view}
          to={getLocationFor.call.transcript({
            callId: call.id,
            slug: params.slug,
            type: 'video',
          })}
          onClick={handleClick}>
          <VideoIcon className={styles.file} />
          <div>View Video</div>
        </Link>
        {props.transcript?.isAvailable &&
          <div className={styles.actions}>
            <TranscriptDownloadMenu
              callId={call.id}
              transcriptId={props.transcript?.id}
              source={{ url, type: 'video' }} />
          </div>
        }
      </div>
    </div>
  );
}
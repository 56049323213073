export const Groups = {
  Branding: {
    Get: `get:groups/branding`,
    Logo: {
      Delete: `delete:groups/branding/logo`,
      Post: `post:groups/branding/logo`,
    },
    Palettes: {
      Delete: `delete:groups/branding/palettes`,
      Post: `post:groups/branding/palettes`,
      Put: `put:groups/branding/palettes`,
    },
    Theming: {
      Put: `put:groups/branding/theming`,
    },
    Survey: {
      Pages: {
        Completion: {
          Image: {
            Delete: `delete:groups/branding/surveys/pages/completion/image`,
            Post: `post:groups/branding/surveys/pages/completion/image`,
          },
          Text: {
            Put: `put:groups/branding/surveys/pages/completion/text`,
          },
        },
      },
      Palettes: {
        Put: `put:groups/branding/surveys/palettes`,
      },
      Theming: {
        Get: `get:groups/surveys/theming`,
      },
    },
  },
  Settings: {
    Patch: `patch:groups/settings`,
  },
};

export const Integrations = {
  Get: 'get:integrations',
};

export const Discovery = {
  Videos: {
    Comments: {
      Get: `get:discovery/videos/comments`,
    },
    Transcripts: {
      Get: `get:discovery/videos/transcripts`,
    },
  },
};

export const Posts = {
  Articles: {
    $: {
      Publish: `$publish:posts/articles`,
      Update: `$update:posts/articles`,
    },
    Delete: `delete:posts/articles`,
    Get: `get:posts/articles`,
    Post: `post:posts/articles`,
    Put: `put:posts/articles`,
    Attachments: {
      CoverImage: {
        Delete: `delete:posts/articles/attachments/cover-image`,
        Post: `post:posts/articles/attachments/cover-image`,
      },
      Post: `post:posts/articles/attachments`,
    },
    Edit: {
      Get: `get:posts/articles/edit`,
    },
    Comments: {
      Get: `get:posts/articles/comments`,
    },
  },
  External: {
    Get: `get:posts/external`,
  },
  Post: `post:posts`,
};

export const Topics = {
  Suggested: {
    Get: `get:topics/suggested`,
  },
};

export const Users = {
  Topics: {
    Get: `get:users/topics`,
  },
};
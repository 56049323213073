import { RequestCallContainer } from '@containers/RequestCall';
import { RespondentProps } from '@/types';
import { AnonymousCardContent } from './AnonymousCardContent';
import { CardTooltip } from './HtmlTooltip';

type Props = {
  children: React.ReactNode;
  userId: number;
  displayName: string;
  subtext: string;
  className?: string;
} & RespondentProps;

export const AnonymousBaseballCard = (props: Props) => {
  return (
    <RequestCallContainer>
      <CardTooltip className={props.className} title={(<AnonymousCardContent {...props} />)}>
        {props.children}
      </CardTooltip>
    </RequestCallContainer>
  );
};
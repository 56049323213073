import { Cell } from 'react-table';
import { Calendar, MessageSquare } from 'react-feather';
import { useInitiatePlatformMessage } from '@containers/PlatformMessage/hooks/useInitiatePlatformMessage';
import { useRequestCallWithUser } from '@containers/RequestCall/hooks/useRequestCall';
import { ExpertsTableItem } from './interfaces';
import styles from './style/Cell.Expert.Actions.css';

export default function Actions({ row: { original: data } }: Cell<ExpertsTableItem>) {
  return (
    <Content
      id={data.entityId}
      name={data.name}
      first={data.metadata.first}
      last={data.metadata.last} />
  );
}

function Content({ id, name, first, last }: ContentProps) {

  const requestCall = useRequestCallWithUser({
    id,
    profile: {
      firstName: first,
      lastName: last,
      fullname: name,
    },
  });

  const initiatePlatformMessage = useInitiatePlatformMessage();
  const [initiateMessage, MessageModal] = initiatePlatformMessage({
    id,
    profile: {
      fullname: name,
      firstName: first,
      lastName: last,
    },
  });

  return (
    <>
      <div className={styles.root}>
        <MessageSquare
          className={styles.message}
          size={20}
          onClick={initiateMessage} />
        <Calendar
          className={styles.icon}
          size={20}
          onClick={requestCall} />
      </div>

      <MessageModal />
    </>
  );
}

type ContentProps = {
  id: number;
  name: string;
  first: string;
  last: string;
}

export { Actions };
import { SurveyQuestion } from '@/types/survey';
import {
  SurveyBuilder,
} from '../interfaces';
import { conjointAnalysisReducer } from './state.conjoint-analysis';
import { maxDiffReducer } from './state.maxdiff';
import { optionsReducer } from './state.options';
import { rowsReducer } from './state.rows';
import { settingsReducer } from './state.settings.reducer';
import * as $actions from './state.questions';
import {
  templateEvaluationCriteriaUpdated,
  templatePartnershipCriteriaUpdated,
  templateTargetRolesUpdated,
  templateTargetUpdated,
} from './state.questions.template';

export function questionsReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): SurveyQuestion[] {
  const questions = state.survey.questions;

  switch (action.type) {

    case 'question-added':
      return $actions.questionAdded(state.survey, action);

    case 'new-question-added':
      return $actions.newQuestionAdded(state.survey, action);

    case 'remove-question':
      return $actions.removeQuestion(questions, action);

    case 'update-question':
      return $actions.updateQuestion(questions, action);

    case 'update-question-value':
      return $actions.updateQuestion(questions, {
        item: {
          ...questions.find(f => f.ordinal === action.ordinal),
          value: action.value,
        },
      });

    case 'update-question-settings':
      return $actions.updateQuestion(questions, {
        item: {
          ...questions.find(f => f.ordinal === action.ordinal),
          settings: action.settings,
        } as SurveyQuestion,
      });

    case 'update-question-type':
      return $actions.updateQuestionType(questions, action);

    case 'update-question-identifier':
      return $actions.updateQuestionIdentifier(questions, action);

    case 'update-question-logic':
      return $actions.updateQuestionLogic(questions, action);

    case 'revert-uncommitted-changes':
      return state.committed.questions;

    case 'new-section-added':
      return $actions.newSectionAdded(state.survey, action);

    case 'section-added':
      return $actions.sectionAdded(state.survey, action);

    case 'remove-section':
      return $actions.removeSection(state.survey, action);

    case 'toggle-section-hidden':
      return $actions.toggleHiddenSection(state.survey, action);

    case 'maxdiff-add-option':
    case 'maxdiff-remove-option':
    case 'maxdiff-update-least-label':
    case 'maxdiff-update-most-label':
    case 'maxdiff-update-option-name':
    case 'maxdiff-update-options-per-set':
    case 'maxdiff-update-sets':
      return maxDiffReducer(state, action);

    case 'conjoint-add-attribute':
    case 'conjoint-remove-attribute':
    case 'conjoint-add-level':
    case 'conjoint-remove-level':
    case 'conjoint-update-attribute-name':
    case 'conjoint-update-level-name':
    case 'conjoint-update-concepts-per-set':
    case 'conjoint-toggle-none-of-the-above':
    case 'conjoint-update-number-of-sets':
      return conjointAnalysisReducer(state, action);

    case 'add-question-option':
    case 'add-question-options':
    case 'remove-question-option':
    case 'update-question-option-value':
      return optionsReducer(state, action);

    case 'add-question-row':
    case 'add-question-rows':
    case 'remove-question-row':
    case 'update-question-row-value':
      return rowsReducer(state, action);

    case 'toggle-none-of-the-above':
    case 'toggle-allow-other':
    case 'toggle-not-applicable':
    case 'toggle-allow-empty-values':
      return settingsReducer(state, action);

    case 'template-target-updated':
      return templateTargetUpdated(state, action);
    case 'template-partnership-criteria-updated':
      return templatePartnershipCriteriaUpdated(state, action);
    case 'template-eval-criteria-updated':
      return templateEvaluationCriteriaUpdated(state, action);
    case 'template-target-roles-updated':
      return templateTargetRolesUpdated(state, action);

    default:
      return questions;

  }
}
import { ProjectTabViewContainer } from '@containers/GroupProject/TabViewContainer';
import Header from './ProjectHeader';
import Actions from './ProjectActions';
import styles from './style/ProjectTab.css';

const ProjectTabs = () => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Header>
          <Actions />
        </Header>
        <ProjectTabViewContainer />
      </div>
    </div>
  );
};

export { ProjectTabs };
export default ProjectTabs;

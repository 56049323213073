import { useContext } from 'react';
import { RateOnboardingContext } from '@containers/RateOnboarding';
import {
  Back,
  CompleteButton,
  NextButton,
  WizardNavigation,
  WizardScreen,
  WizardScreenProps,
} from 'components/Wizard';
import styles from './style.css';

type NavigationProps = {
  nextEnabled: boolean;
};

export const Navigation = (props: NavigationProps) => {
  const ctx = useContext(RateOnboardingContext);

  const renderBack = () => (
    <Back
      onClick={ctx.back} />
  );

  const renderNext = () => (
    <NextButton
      disabled={!props.nextEnabled}
      onClick={ctx.next} />
  );

  return (
    <WizardNavigation
      renderBack={renderBack}
      renderNext={renderNext} />
  );
};

export const LastStepNavigation = (props: NavigationProps) => {
  const ctx = useContext(RateOnboardingContext);

  const renderBack = () => (
    <Back
      onClick={ctx.back} />
  );

  const renderNext = () => (
    <CompleteButton
      disabled={!props.nextEnabled}
      onClick={ctx.onCompletion} />
  );

  return (
    <WizardNavigation
      renderBack={renderBack}
      renderNext={renderNext} />
  );
};

export const Screen = (props: WizardScreenProps) => {
  const renderBody = () => (
    <div className={styles.body}>
      {props.renderBody()}
    </div>
  );

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <WizardScreen
          renderHeader={props.renderHeader}
          renderBody={renderBody}
          renderNavigation={props.renderNavigation} />
      </div>
    </div>
  );
};
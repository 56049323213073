import { useContext, useState } from 'react';
import * as consts from '@consts';
import { ProfileOnboardingContext, useProfileState } from '@containers';
import { TextareaCharacterLimit } from 'components/Textarea';
import {
  Back,
  CompleteButton,
  WizardHeader,
  WizardNavigation,
} from 'components/Wizard';
import { Screen } from './Wizard';
import styles from './style.css';

export const BioScreen = () => {
  const ctx = useContext(ProfileOnboardingContext);

  const [user] = useProfileState();
  const [bio, setBio] = useState(user.bio?.summary || '');

  const handleUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBio(e.target.value);
  };

  const handleSave = () => {
    return ctx.saveBio(bio);
  };

  const renderHeader = () => {
    const title = `Summary`;
    const subtitle = `Your profile is almost done! Please provide a brief summary of your areas of expertise and your profile will be complete.`;

    return  (
      <WizardHeader
        title={title}
        subtitle={subtitle} />
    );
  };

  const renderBack = () => (
    <Back
      onClick={ctx.back} />
  );

  const renderNext = () => (
    <CompleteButton
      disabled={false}
      onClick={handleSave} />
  );

  const renderNavigation = () => {
    return (
      <WizardNavigation
        renderBack={renderBack}
        renderNext={renderNext} />
    );
  };

  const renderBody = () => (
    <div className={styles.wrap}>
      <div className={styles.bio}>
        <TextareaCharacterLimit
          autoFocus
          maxLength={consts.maxLengths.BIO}
          name="bio"
          onChange={handleUpdate}
          value={bio} />
      </div>
    </div>
  );

  return (
    <Screen
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderNavigation={renderNavigation} />
  );
};

export default BioScreen;
import { memo } from 'react';
import { getLocationFor, slugify } from '@utils';
import { ComplianceReviewItem } from '@/types';
import { MaybeLink } from 'components/MaybeLink';
import styles from './style/ComplianceReview.css';

type Props = {
  className?: string;
  item:       ComplianceReviewItem;
};

export const ConsultantLink = memo((props: Props) => {

  const location = props.item.consultant
     && getLocationFor.user.profile({ slug: slugify(props.item.consultant) });

  return (
    <MaybeLink
      className={styles.link}
      to={location}>
      {props.item.consultant?.name}
    </MaybeLink>
  );
});

export default ConsultantLink;
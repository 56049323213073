import { useContext } from 'react';
import { AggregateReviewStateContext } from '@containers/ComplianceReview/Context';

const useAggregateReviewState = () => {
  const state = useContext(AggregateReviewStateContext);

  return state;
};

export { useAggregateReviewState };
export default useAggregateReviewState;
import * as enums from '@enums';
import { ProjectDisqualification } from '@screens/ProjectDisqualification';
import { AccountProfile, Compliance, ProjectRequest, Scheduling, SurveyBilling } from '@screens/ProjectOnboarding';
import { ComplianceSurveyStepper } from './ComplianceSurveyStepper';
import { ExternalSurveyContainer } from './ExternalSurveyContainer';
import { ProjectSurveyStepper } from './ProjectSurveyStepper';
import { RateStepper } from './RateStepper';
import { SurveyTestDriveStepper } from './SurveyTestDriveStepper';
import { StepCheck } from './interfaces';
import * as utils from './utils';

export enum Steps {
  AccountProfileSetup,
  ComplianceSurvey,
  Disqualification,
  ExternalSurvey,
  GeneralCompliance,
  PreviewSurvey,
  ProjectSurvey,
  RequestComplete,
  Review,
  Scheduling,
  SchedulingTutorial,
  SetRate,
  Signature,
  SurveyBilling,
  SurveyTerms,
}

const CallProjectSteps = [
  Steps.Review,
  Steps.SetRate,
  Steps.GeneralCompliance,
  Steps.ComplianceSurvey,
  Steps.Signature,
  Steps.SchedulingTutorial,
  Steps.Scheduling,
];

const SurveyProjectSteps = [
  Steps.Review,
  Steps.SurveyTerms,
  Steps.ComplianceSurvey,
  Steps.Signature,
  Steps.ProjectSurvey,
  Steps.ExternalSurvey,
];

const MixedProjectSteps = [
  Steps.Review,
  Steps.SetRate,
  Steps.GeneralCompliance,
  Steps.ComplianceSurvey,
  Steps.Signature,
  Steps.ProjectSurvey,
  Steps.SchedulingTutorial,
  Steps.Scheduling,
];

export const ProjectTypeSteps = {
  [enums.ProjectType.Call]: CallProjectSteps,
  [enums.ProjectType.Survey]: SurveyProjectSteps,
  [enums.ProjectType.SurveyCall]: MixedProjectSteps,
};

export const StepScreens = {
  [Steps.AccountProfileSetup]: AccountProfile.Stepper,
  [Steps.ComplianceSurvey]: ComplianceSurveyStepper,
  [Steps.Disqualification]: ProjectDisqualification,
  [Steps.ExternalSurvey]: ExternalSurveyContainer,
  [Steps.GeneralCompliance]: Compliance.OnboardingStepper,
  [Steps.PreviewSurvey] : SurveyTestDriveStepper,
  [Steps.ProjectSurvey]: ProjectSurveyStepper,
  [Steps.RequestComplete]: ProjectRequest.Complete,
  [Steps.Review]: ProjectRequest.Review,
  [Steps.SchedulingTutorial]: Scheduling.Tutorial,
  [Steps.Scheduling]: Scheduling.InvitationCalendar,
  [Steps.SetRate]: RateStepper,
  [Steps.Signature]: Compliance.EmbeddedSign,
  [Steps.SurveyBilling]: SurveyBilling.Stepper,
  [Steps.SurveyTerms]: Compliance.SurveyTerms,
};

export const StepChecks: StepCheck = {
  [Steps.AccountProfileSetup]: () => true,
  [Steps.ComplianceSurvey]: utils.isComplianceSurveyRequired,
  [Steps.ExternalSurvey]: utils.isExternalProject,
  [Steps.GeneralCompliance]: utils.needsComplianceTraining,
  [Steps.ProjectSurvey]: utils.isProjectSurveyRequired,
  [Steps.SetRate]: utils.includeRateSteps,
  [Steps.Signature]: utils.hasPendingSignatureRequest,
  [Steps.SurveyTerms]: utils.needsSurveyTerms,
};

export default Steps;

export const selectProjectOnboardingSteps = (state: Store.State) => (projectId: number): Steps[]  => {
  const project = state.projects[projectId];

  return ProjectTypeSteps[project.projectType]
    .filter(shouldIncludeStep)
  ;

  function shouldIncludeStep(step: Steps) {
    const check = StepChecks[step];
    if (check) {
      return check(projectId, state);
    }
    return true;
  }
};

export const selectProjectTestDriveSteps = (state: Store.State) => (projectId: number): Steps[]  => {
  return [Steps.PreviewSurvey];
};

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import * as API from '@api/interfaces';
import { ComplianceReviewItem } from '@/types';
import Toast from 'components/Toast';
import { useAggregateReviewRefetch } from './useAggregateReviewRefetch';

type Params = {
  item:  ComplianceReviewItem<'call'>;
  notes: string | null;
};

type DispatchChangeParams = {
  call?:   API.Scheduling.Project.ApproveCall.Response['call'];
  project: Pick<ComplianceReviewItem<'call'>['project'], 'id'>;
} & Pick<API.Scheduling.Project.ApproveCall.Response, 'pipeline'>;

const useAggregateReviewCallActions = () => {
  const dispatch = useDispatch();
  const refetch = useAggregateReviewRefetch();

  const handleChange = useCallback((data: DispatchChangeParams) => {

    const callUpdatedAction = data.call
        ? [ actions.callUpdated({ call: data.call }) ]
        : [ ];

    dispatch(actions.batchActions([
      actions.projectPipelineUpdated({
        pipeline: data.pipeline,
        projectId: data.project.id,
      }),
      ...callUpdatedAction as [ReturnType<typeof actions.callUpdated>],
    ]));

  }, [dispatch]);

  const approve = useCallback((data: Params) => {

    return api.scheduling.projectCall.approve({
      callId: data.item.id,
      notes: data.notes,
      projectId: data.item.project.id,
    })
    .then(res => {

      handleChange({
        call: res.call,
        pipeline: res.pipeline,
        project: data.item.project,
      });

      notifier.approve({
        analyst: data.item.analyst,
        consultant: data.item.consultant,
        project: data.item.project,
      });

      refetch();

      return res;

    });

  }, [
    handleChange,
    refetch,
  ]);

  const reject = useCallback((data: Params) => {

    return api.scheduling.projectCall.reject({
      callId: data.item.id,
      notes: data.notes,
      projectId: data.item.project.id,
    })
    .then(res => {

      handleChange({
        pipeline: res.pipeline,
        project: data.item.project,
      });

      notifier.reject({
        analyst: data.item.analyst,
        consultant: data.item.consultant,
        project: data.item.project,
      });

      refetch();

      return res;

    });

  }, [
    handleChange,
    refetch,
  ]);

  return {
    approve,
    reject,
  };

};

type NotifierDispatchParams = {
  analyst:    Pick<ComplianceReviewItem<'call'>['analyst'], 'name'>;
  consultant: Pick<ComplianceReviewItem<'call'>['consultant'], 'name'>;
  project:    Pick<ComplianceReviewItem<'call'>['project'], 'name'>;
};

type NotifierDispatch = (params: NotifierDispatchParams) => void;

type Notifier = {
  approve: NotifierDispatch;
  reject:  NotifierDispatch;
};

const notifier: Notifier = {
  approve(params) {
    Toast.alert({
      title: 'Call Approved',
      body: `${params.consultant.name} has been approved for a call with ${params.analyst.name} for the ${params.project.name} project. You will receive a calendar invitation when the call is booked.`,
    });
  },
  reject(params) {
    Toast.error({
      title: 'Call Rejected',
      body: `${params.consultant.name} has been rejected for a call with ${params.analyst.name} for the ${params.project.name} project. Scheduling will not proceed for this call.`,
    });
  },
};

export { useAggregateReviewCallActions };
export default useAggregateReviewCallActions;

import { useEffect, useState } from 'react';
import * as api from '@api';
import { useIsProjectTypeCallLike } from '@containers/Store';
import { RecordingConsentStateContext } from './Context';
import { RecordingConsentState } from './interfaces';

type Props = {
  children: React.ReactNode;
} & IProjectId;

const RecordingConsentContainer = (props: Props) => {
  const [consent, setConsent] = useState<RecordingConsentState>(null);
  const isCallLike = useIsProjectTypeCallLike(props.projectId);

  useEffect(() => {

    if (props.projectId && isCallLike) {
      api.scheduling.getRecordingConsentState({
        projectId: props.projectId,
      })
      .then(setConsent);
    }

  }, [
    isCallLike,
    props.projectId,
    setConsent,
  ]);

  return (
    <RecordingConsentStateContext.Provider value={consent}>
      {props.children}
    </RecordingConsentStateContext.Provider>
  );
};

export { RecordingConsentContainer };
export default RecordingConsentContainer;
import { Helmet } from 'react-helmet';
import { path } from '@consts';
import { GDPR } from '$website/components/Legal';
import styles from './style.css';

export function PrivacyPolicy() {
  return (
    <>
      <H />
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <h1 className={styles.title}>Privacy Policy</h1>
          </div>
          <div className={styles.body}>
            <GDPR.PrivacyNotice />
          </div>
        </div>
      </div>
    </>
  );
}

function H() {
  const description = `Vancery's Privacy Policy.`;

  return (
    <Helmet title="Privacy Policy">
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Website.PrivacyPolicy.Root}`} />
      <meta
        name='description'
        content={description} />
    </Helmet>
  );
}
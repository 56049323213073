import { memo, useCallback } from 'react';
import { cx } from '@utils';
import styles from './style.css';

type Props = {
  active: boolean;
  className?: string;
  onClick: (active: boolean) => unknown;
};

export const Switch = memo(({ active, className, onClick }: Props) => {
  const rootClass = cx(styles.root, className, {
    [styles.on]: active,
    [styles.off]: !active,
  });

  const handleToggle = useCallback(() => {
    onClick(!active);
  }, [active, onClick]);

  return (
    <div
      className={rootClass}
      onClick={handleToggle}>
      <div className={styles.nob} />
    </div>
  );
});

export default Switch;
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as api from '@api';
import * as actions from '@store/actions';
import Toast from 'components/Toast';
import { RenameFolderParams } from '../interfaces';

export const useRenameFolder = () => {
  const dispatch = useDispatch();

  const renameFolder = useCallback((data: RenameFolderParams) => {
    return api.workspaces.folder.renameFolder({
      folderId: data.folderId,
      name: data.name,
      workspaceId: data.workspaceId,
    }).then(({ folder, object }) => {
      dispatch(actions.folderRenamed({
        folder,
        object,
      }));
      Toast.alert({
        title: 'Folder Renamed',
      });
      return data;
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });

  }, [dispatch]);

  return renameFolder;
};

export default useRenameFolder;
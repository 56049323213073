import { useCallback } from 'react';
import { SurveyTemplateType } from '@/enums/survey.template';
import { template } from '../utils';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useSetSurveyTemplate = () => {

  const [_, dispatch] = useSurveyBuilderState();

  return useCallback((t: SurveyTemplateType) => {

    const templateData = template.getInitialTemplateData(t);

    dispatch({
      value: {
        ...template.custom.initialSurveyData,
        template: {
          type: t,
          data: templateData,
        },
      },
      type: 'reset-survey',
    });

  }, [
    dispatch,
  ]);
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@actions';
import { useSurveyThemingLoading } from '@containers/Branding/hooks/useSurveyThemingLoading';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { RecordingConsentContainer } from '@containers/Call';
import { SurveyActionType } from '@enums';
import * as selectors from '@store/selectors';
import { getLocationFor, usePrevious, useStepper } from '@utils';
import { AnimatedLoader } from 'components/ActivityIndicator';
import * as Whitelabel from 'components/Whitelabel';
import { ProjectOnboardingContext } from './Context';
import { useAbortOnboardingRedirect, useEmitUpdateNewUserFromReferrer, useValidateInvite, useMatchProjectFromRoute } from './hooks';
import { useDismissProjectRequestNotification } from './hooks/useDismissProjectRequestNotification';
import { Steps, StepScreens, selectProjectOnboardingSteps } from './Steps';
import { ProjectOnboardingContextValue } from './interfaces';

type Props = unknown;

export const ProjectOnboardingContainer = (props: Props) => {

  useEmitUpdateNewUserFromReferrer();

  const getSteps = useSelector(selectProjectOnboardingSteps);
  const project = useMatchProjectFromRoute();
  const record = useSelector(selectors.pipeline.expert.record(project.id));
  const previousRecord = usePrevious(record);

  const dispatch = useDispatch();
  const history = useHistory();

  const [response, validateInvite] = useValidateInvite(project?.id);

  const [steps, setSteps] = useState<Steps[]>([]);
  const [Screen, stepperActions, step] = useStepper(steps.map(step => StepScreens[step]));

  const { theme: hasTheming } = useSurveyThemingPalette();

  const abortRedirect = useAbortOnboardingRedirect(project.id);
  const dismissProjectRequestNotification = useDismissProjectRequestNotification(project.id);

  const abortOnboarding = useCallback(() => {
    dismissProjectRequestNotification();

    if (record) {
      dispatch(actions.projectPipelineUpdated({
        pipeline: { me: { [record.projectId]: record }, project: {} },
        projectId: record.projectId,
      }));
    }

    abortRedirect(response.value?.user?.disqualified);

  }, [
    abortRedirect,
    dispatch,
    dismissProjectRequestNotification,
    record,
    response.value,
  ]);

  const initializeOnboarding = useCallback(() => {
    const steps = getSteps(project.id);
    setSteps(steps);
  }, [
    project.id,
    setSteps,
    getSteps,
  ]);

  const handleDisqualification = useCallback((actionType: SurveyActionType) => {
    const loc = getLocationFor.onboarding.project({ projectId: project.id });

    dismissProjectRequestNotification();

    history.push(`${loc.pathname}/exit`, {
      actionType,
    });

  }, [
    dismissProjectRequestNotification,
    history,
    project.id,
  ]);

  const handleCompletion = useCallback(() => {
    const loc = getLocationFor.onboarding.project({ projectId: project.id });

    dismissProjectRequestNotification();

    hasTheming
      ? history.push(`${loc.pathname}/completion`)
      : history.push(`${loc.pathname}/continue`);

  }, [
    dismissProjectRequestNotification,
    hasTheming,
    history,
    project.id,
  ]);

  useEffect(() => {

    if (!record) return abortOnboarding();

    if (initialLoad() || statusChanged()) {
      validateInvite(record);
    }

    function initialLoad() {
      return !response.loading && !response.value;
    }
    function statusChanged() {
      return record.statusId !== previousRecord?.statusId;
    }

  }, [
    abortOnboarding,
    project.id,
    response.loading,
    response.value,
    record,
    previousRecord,
    validateInvite,
  ]);

  useEffect(() => {

    if (!response.value) return;

    if (response.value.valid) {
      if (!steps.length) {
        initializeOnboarding();
      }
    } else {
      abortOnboarding();
    }

  }, [
    abortOnboarding,
    initializeOnboarding,
    response.value,
    steps,
  ]);

  const hasNextStep = useMemo(() => {
    return step !== steps.length - 1;
  }, [
    step,
    steps,
  ]);

  const next = useCallback(() => {
    if (!hasNextStep) {
      handleCompletion();
    } else {
      stepperActions.next();
    }
  }, [
    handleCompletion,
    hasNextStep,
    stepperActions,
  ]);

  const loading = useSurveyThemingLoading();
  const ready = !!(steps.length > 0 && !loading);

  if (!ready) {
    return (
      <AnimatedLoader
        color="var(--blue-l)"
        size={12} />
    );
  }

  const context: ProjectOnboardingContextValue = {
    back: stepperActions.back,
    onDisqualification: handleDisqualification,
    goTo: stepperActions.goTo,
    hasNextStep,
    next,
    projectId: project?.id,
  };

  return (
    <ProjectOnboardingContext.Provider value={context}>
      <RecordingConsentContainer projectId={context.projectId}>
        <Whitelabel.ScreenDecorator>
          <Screen />
        </Whitelabel.ScreenDecorator>
      </RecordingConsentContainer>
    </ProjectOnboardingContext.Provider>
  );
};

export { ProjectOnboardingContext };
export default ProjectOnboardingContainer;
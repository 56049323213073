import { Link } from 'react-router-dom';
import { getLocationFor } from '@utils';
import { LegacyGroupProjectState, Project } from '@/types';
import styles from './style/SearchBanner.css';

type Props = {
  item: Project<LegacyGroupProjectState>;
};

export const SearchBanner = ({ item }: Props) => {
  const hasFilters = item.vics.length
                  || item.companies.length
                  || item.titles.length
                  || item.keywords.length;

  const text = hasFilters
    ? `View members that match your project's filters.`
    : 'Set a project filter to view matching members.';
  const linkText = hasFilters
    ? 'Browse in Search >'
    : null;

  return (
    <Link to={getLocationFor.project.search(item)}>
      <div className={styles.banner}>
        <div className={styles.text}>{text}</div>
        <div className={styles.link}>{linkText}</div>
      </div>
    </Link>
  );
};

export default SearchBanner;
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import * as enums from '@enums';
import { RateOnboardingContext } from '@containers/RateOnboarding';
import {
  WizardHeader,
} from 'components/Wizard';
import SetRate from './SetRate';
import { LastStepNavigation, Screen } from './Wizard';
import styles from './style.css';

const mapState = (state: Store.State) => ({
  user: state.user,
});

export const RateScreen = () => {
  const ctx = useContext(RateOnboardingContext);
  const { user } = useSelector(mapState);

  const renderSignupRateText = () => {
    const rate = user.referrer?.hourlyRate;

    if (!rate) {
      return null;
    }

    const line1 = `The project you were invited to participate on has a rate of ${rate} per hour.`;
    const line2 = `Your below rate will be applied for future projects on Vancery.`;

    return (
      <div className={styles.signupRate}>
        {line1}
        <br />
        {line2}
      </div>
    );
  };

  const renderHeader = () => {
    const title = `Project hourly rate`;
    const subtitle = `Based on your industry and years of experience, we recommend charging between $${ctx.minimumHourlyRate} and $${ctx.maximumHourlyRate} to maximize your project offers from clients. If you'd like to edit your rate, you can do so in the settings page of your account.`;

    return  (
      <WizardHeader
        title={title}
        subtitle={subtitle} />
    );
  };

  const renderBody = () => (
    <>
      {renderSignupRateText()}
      <SetRate
        hourlyRate={ctx.hourlyRate}
        onDragEnd={ctx.onHourlyRateChange}
        onPositionChange={ctx.onHourlyRateSlide}
        maximumRate={ctx.maximumHourlyRate}
        minimumRate={ctx.minimumHourlyRate} />
    </>
  );

  const renderNavigation = () =>  (
    <LastStepNavigation nextEnabled={true} />
  );

  return (
    <Screen
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderNavigation={renderNavigation} />
  );
};

export default RateScreen;
import { SurveyQuestionType } from '@enums';
import {
  MatrixMultiselectQuestion as MMS,
  MultipleChoiceQuestion as MCQ,
  MultiselectQuestion as MSQ,
  MatrixGridQuestion as MXG,
  MatrixSliderQuestion as MXS,
  RankingQuestion as RQ,
  ShortTextResponseQuestion,
  LongTextResponseQuestion,
  NumberInputTableQuestion as NIT,
} from '@/types';
import ConjointAnalysis from './ConjointAnalysis';
import LongTextResponse from './LongTextResponse';
import MatrixGridQuestion from './MatrixGrid';
import MatrixSliderQuestion from './MatrixSlider';
import MaxDiff from './MaxDiff';
import MultipleChoiceQuestion from './MultipleChoice';
import RankingQuestion from './Ranking';
import ShortTextResponse from './ShortTextResponse';
import { QuestionItemProps } from './interfaces';
import styles from './style/SurveyForm.css';
import MultiselectQuestion from './Multiselect';
import MatrixMultiselectQuestion from './MatrixMultiselect';
import NumberInputTableQuestion from './NumberInputTable';

export const QuestionItem = (props: QuestionItemProps) => {

  switch (props.item.typeId) {
    case SurveyQuestionType.MultipleChoice:
      return (
        <MultipleChoiceQuestion
          answer={props.answer as MCQ.RespondentAnswer}
          options={props.item.options}
          setAnswer={props.setAnswer} />
      );

    case SurveyQuestionType.Multiselect:
      return (
        <MultiselectQuestion
          answer={props.answer as MSQ.RespondentAnswer}
          question={props.item}
          setAnswer={props.setAnswer} />
      );

    case SurveyQuestionType.Ranking:
      return (
        <RankingQuestion
          answer={props.answer as RQ.RespondentAnswer.Value}
          question={props.item}
          setAnswer={props.setAnswer} />
      );

    case SurveyQuestionType.Sliders:
      return (
        <MatrixSliderQuestion
          answer={props.answer as MXS.RespondentAnswer.Value}
          item={props.item}
          setAnswer={props.setAnswer} />
      );

    case SurveyQuestionType.MatrixGrid:
      return (
        <MatrixGridQuestion
          answer={props.answer as MXG.RespondentAnswer.Value}
          item={props.item}
          setAnswer={props.setAnswer} />
      );

    case SurveyQuestionType.LongTextResponse:
      return (
        <LongTextResponse
          answer={props.answer as LongTextResponseQuestion.RespondentAnswer}
          item={props.item}
          setAnswer={props.setAnswer} />
      );

    case SurveyQuestionType.ShortTextResponse:
      return (
        <ShortTextResponse
          className={styles.shortInput}
          answer={props.answer as ShortTextResponseQuestion.RespondentAnswer}
          setAnswer={props.setAnswer} />
      );

    case SurveyQuestionType.MaxDifference:
      return <MaxDiff />;

    case SurveyQuestionType.ConjointAnalysis:
      return (
        <ConjointAnalysis />
      );

    case SurveyQuestionType.MatrixMultiselect:
      return (
        <MatrixMultiselectQuestion
          answer={props.answer as MMS.RespondentAnswer.Value}
          item={props.item}
          setAnswer={props.setAnswer} />
      );

    case SurveyQuestionType.NumberInputTable:
      return (
        <NumberInputTableQuestion
          answer={props.answer as NIT.RespondentAnswer.Value}
          item={props.item}
          setAnswer={props.setAnswer} />
      );
  }
};

export default QuestionItem;
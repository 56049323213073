import { useCallback, useState } from 'react';
import { NumberInputTableQuestion, SurveyQuestionFormMatrixRow, SurveyQuestionFormOption } from '@/types';
import { NumberTableWarningsOptionMap, NumberTableWarningsMap } from '../interfaces';

type Item = {
  matrixRows: SurveyQuestionFormMatrixRow[];
  options: SurveyQuestionFormOption[];
  settings: NumberInputTableQuestion.Question['settings'];
};

type Props = {
  item: Item;
};

export const useNumberTableValidation = ({ item }: Props) => {
  const [validations, setValidations] = useState<NumberTableWarningsMap>(generateDefaultMap(item));

  const runValidations = useCallback((answer: NumberInputTableQuestion.RespondentAnswer.Value) => {
    let canSubmit = true;
    const { allowEmptyValues, validations: { minValue, maxValue } } = item.settings;

    const v = item.matrixRows.reduce((acc, row) => {
      const optionMap = item.options.reduce((acc2, option) => {
        const a = answer.items.find(f => f.optionId === option.id && f.rowId === row.id);
        const isEmpty = a?.value === null || a?.value === undefined;

        let isValid = true;

        if (isEmpty) {
          isValid = allowEmptyValues;
        } else if (maxValue !== null && a.value > maxValue) {
          isValid = false;
        } else if (minValue !== null && a.value < minValue) {
          isValid = false;
        }

        canSubmit = isValid && canSubmit;

        return {
          ...acc2,
          [option.id]: !isValid,
        };
      }, {} as NumberTableWarningsOptionMap);

      return {
        ...acc,
        [row.id]: optionMap,
      };
    }, {} as NumberTableWarningsMap);

    setValidations(v);

    return canSubmit;
  }, [
    item.matrixRows,
    item.options,
    item.settings,
  ]);

  return [validations, runValidations] as const;
};

function generateDefaultMap(item: Item) {
  return item.matrixRows.reduce((acc, row) => {
    const optionMap = item.options.reduce((acc2, option) => ({
      ...acc2,
      [option.id]: false,
    }), {} as NumberTableWarningsOptionMap);
    return {
      ...acc,
      [row.id]: optionMap,
    };
  }, {} as NumberTableWarningsMap);
}


import { useCallback, useState, memo } from 'react';
import { format } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeftRounded';
import ChevronRight from '@mui/icons-material/ChevronRightRounded';
import { useSelectGroup } from '@containers';
import * as enums from '@enums';
import { EnterpriseChargeHistoryItem } from '@/types';
import { cx } from '@utils';
import { usePaginatedEnterpriseBillingHistory } from '@utils/api';
import { AnimatedLoader } from 'components/ActivityIndicator';
import styles from './style/ChargeList.css';

export default function ChargeList() {
  const [page, setPage] = useState<number>(1);
  const group = useSelectGroup();
  const history = usePaginatedEnterpriseBillingHistory(group.id, page, {
    refetchOnWindowFocus: false,
  });

  const handlePage = useCallback((mode: 'prev' | 'next') => () => {
    setPage(prev => mode === 'prev' ? Math.max(1, prev - 1) : prev + 1);
  }, []);

  if (history.isLoading) return <Loading />;
  if (!history.data?.items?.length) return <Empty />;

  return (
    <div className={styles.root}>
      <div className={styles.pagination}>
        <div className={styles.controls}>
          <IconButton
            className={styles.iconBtn}
            disableRipple={true}
            disabled={page === 1}
            onClick={handlePage('prev')}>
            <ChevronLeft className={styles.chevron} />
          </IconButton>
          <IconButton
            className={styles.iconBtn}

            disableRipple={true}
            disabled={history.data?.next == null}
            onClick={handlePage('next')}>
            <ChevronRight className={styles.chevron} />
          </IconButton>
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.thead}>
          <div className={styles.tr}>
            <div className={styles.th}>Id</div>
            <div className={styles.th}>Description</div>
            <div className={styles.th}>Amount</div>
            <div className={styles.th}>Status</div>
            <div className={styles.th}>Date</div>
          </div>
        </div>
        <div className={styles.tbody}>
          {history.data?.items?.map(charge => <Charge key={`${charge.id}`} {...charge} />)}
        </div>
      </div>
    </div>
  );
}

type ChargeProps =
  EnterpriseChargeHistoryItem;

const Charge = memo(({ identifier, amount, description, statusId, createdOn }: ChargeProps) => {
  const status = getChargeStatusLabel(statusId);
  const amountFormatted = `$${parseFloat(amount.toString()).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

  return (
    <div className={styles.tr}>
      <div className={styles.td}>{identifier}</div>
      <div className={styles.td}>{description}</div>
      <div className={styles.td}>{amountFormatted}</div>
      <div className={styles.td}>
        <div className={styles[status.toLowerCase()]}>{status}</div>
      </div>
      <div className={styles.td}>{format(createdOn, 'yyyy-MM-dd')}</div>
    </div>
  );
});

const Empty = memo(() => {
  return (
    <div className={styles.nocharges}>
      <div>No billing history available.</div>
      <div>When interactions are completed, your account charge history will display here.</div>
    </div>
  );
});

const Loading = memo(() => {
  return (
    <div className={cx(styles.root, styles.loading)}>
      <AnimatedLoader />
    </div>
  );
});

function getChargeStatusLabel(value: enums.ChargeStatus) {
  switch (value) {
    case enums.ChargeStatus.Paid: return 'Paid';
    case enums.ChargeStatus.Failed: return 'Failed';
    case enums.ChargeStatus.Open: return 'Open';
    case enums.ChargeStatus.Refunded: return 'Refunded';
    default: return 'Pending';
  }
}

export { ChargeList };
import { cx } from '@utils';
import { CreatedByWhenLabelProps } from './interfaces';
import styles from './style/CreatedByWhenLabel.css';

type Props =
  CreatedByWhenLabelProps;

const By = (props: Props) => {
  return <>Created by {props.by}</>;
};

const When = (props: Props) => {
  return <>{` ${props.when}`}</>;
};

export const CreatedByWhenLabel = (props: Props) => {
  const root = cx(styles.root, props.className);

  return (
    <div className={root}>
      <By {...props} />
      <When {...props} />
    </div>
  );
};
import { useContext, useEffect, useMemo } from 'react';
import { pathname } from '@consts';
import { WorkspaceGlobalSearchBarResultFindMembers, WorkspaceGlobalSearchBarResultKeyword } from '@/types';
import { qs, hash64 } from '@utils';
import {
  WorkspaceGlobalSearchBarResultsContext, WorkspaceGlobalSearchBarQueryContext,
  WorkspaceGlobalSearchKeyboardContext, WorkspaceGlobalSearchBarResetContext,
} from './Context';
import EmptyQuery from './Results.EmptyQuery';
import ResultsItems from './Results.Items';
import styles from './style/Results.css';

export default function Results() {
  const { query } = useContext(WorkspaceGlobalSearchBarQueryContext);

  return query.length
    ? <HasQuery />
    : <EmptyQuery />;
}

function findMembersItem(query: string): WorkspaceGlobalSearchBarResultFindMembers {
  const encoded = qs.encode({ keyword: [{ id: query }] });
  return {
    id: hash64(`find-members:${query}`),
    name: query,
    path: `${pathname.SEARCH.substring(1)}?${qs.stringify({ q: encoded })}`,
    type: 'find-members',
  };
}

function keywordItem(query: string) : WorkspaceGlobalSearchBarResultKeyword {
  return {
    id: hash64(`keyword:${query}`),
    name: query,
    path: `${pathname.WORKSPACES.substring(1)}/search?${qs.stringify({ q: query })}`,
    type: 'keyword',
  };
}

function HasQuery() {
  const results = useContext(WorkspaceGlobalSearchBarResultsContext);
  const { index } = useContext(WorkspaceGlobalSearchKeyboardContext);
  const { updatedResultsReset } = useContext(WorkspaceGlobalSearchBarResetContext);
  const { query } = useContext(WorkspaceGlobalSearchBarQueryContext);

  useEffect(() => {
    updatedResultsReset(results.items.length + 1);
  }, [updatedResultsReset, results.items.length]);

  const findMembers = useMemo(() => findMembersItem(query), [query]);
  const keyword = useMemo(() => keywordItem(query), [query]);
  const items = useMemo(() => [keyword, findMembers, ...results.items], [keyword, findMembers, results.items]);

  return (
    <div className={styles.root}>
      <ResultsItems
        activeIndex={index}
        items={items} />
    </div>
  );
}

export { Results };


import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryParams, NumberParam } from 'use-query-params';
import { getLocationFor } from '@utils';
import { ParentObjectIdContext,  ProjectCreationNavigationContext } from './context';
import { useProjectCreationSteps } from './hooks';
import { ProjectCreationSteps } from './interfaces';

type Props =
  ChildrenProps;

export const ProjectCreationNavigationContainer = (props: Props) => {

  const history = useHistory();
  const params = useParams<{ step: ProjectCreationSteps; }>();

  const [queryParams] = useQueryParams({
    parentObjectId: NumberParam,
  });

  const steps = useProjectCreationSteps();

  useEffect(() => {
    if (params?.step !== ProjectCreationSteps.ProjectOverview) {
      const location = getLocationFor.project.create(queryParams.parentObjectId, ProjectCreationSteps.ProjectOverview);
      history.push(location);
    }
  }, [history, queryParams.parentObjectId]);

  const index = useMemo(() => steps.findIndex(f => f.key === params.step), [steps, params.step]);

  const nextScreen = useCallback(() => {
    const nextScreenIndex = index + 1;
    const nextStep = steps[nextScreenIndex];

    const location = getLocationFor.project.create(queryParams.parentObjectId, nextStep.key);
    history.push(location);
  }, [
    history,
    index,
    queryParams,
    steps,
  ]);

  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  const ctx = {
    back,
    index,
    next: nextScreen,
    step: params.step,
  };

  return (
    <ParentObjectIdContext.Provider value={queryParams.parentObjectId}>
      <ProjectCreationNavigationContext.Provider value={ctx}>
        {props.children}
      </ProjectCreationNavigationContext.Provider>
    </ParentObjectIdContext.Provider>
  );
};
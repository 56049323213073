import { useMemo } from 'react';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useIsEditingSurvey = () => {
  const [state] = useSurveyBuilderState();

  const isEditing = useMemo(() => {
    return !!state.editing.logicOrdinal ||
      !!state.editing.questionOrdinal ||
      !!state.editing.quota.identifier;
  }, [
    state.editing,
  ]);

  return isEditing;
};

export default useIsEditingSurvey;
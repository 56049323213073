import { useMemo } from 'react';
import { useGetOrdinalFromAction } from '@containers/SurveyBuilder.Logic/hooks';
import { SurveyActionType as SurveyActionTypeEnum, SurveyQuestionConditionType } from '@enums/Survey';
import { cx } from '@utils';
import { ActionTypeProps } from './interfaces';
import styles from './style/ActionType.css';

export const SurveyActionType = (props: ActionTypeProps) => {

  const className = useMemo(() => {
    const red = [
      SurveyActionTypeEnum.Disqualification,
      SurveyActionTypeEnum.Flag,
    ].includes(props.action.type);

    const gray = [
      SurveyActionTypeEnum.SkipToEnd,
      SurveyActionTypeEnum.SkipToQuestion,
      SurveyActionTypeEnum.SkipToSection,
      SurveyActionTypeEnum.IncludeSection,
    ].includes(props.action.type);

    return cx({
      [styles.red]: red,
      [styles.gray]: gray,
    }, props.className);
  }, [props]);

  const getOrdinalFromAction = useGetOrdinalFromAction();

  const text = useMemo(() => {

    switch (props.action.type) {

      case SurveyActionTypeEnum.Disqualification:
        return 'Disqualify';

      case SurveyActionTypeEnum.Flag:
        return 'Flag';

      case SurveyActionTypeEnum.SkipToEnd:
        return 'Skip to end';

      case SurveyActionTypeEnum.SkipToQuestion: {
        const ordinal = getOrdinalFromAction(props.action);
        return `Skip to Q${ordinal}`;
      }

      case SurveyActionTypeEnum.SkipToSection: {
        const ordinal = getOrdinalFromAction(props.action);
        return `Skip to S${ordinal}`;
      }

      case SurveyActionTypeEnum.IncludeSection: {
        const ordinal = getOrdinalFromAction(props.action);
        return `Include S${ordinal}`;
      }

      default:
        return null;
    }

  }, [
    getOrdinalFromAction,
    props.action,
  ]);

  const ConditionType = () => {
    if (props.conditionType == SurveyQuestionConditionType.NotChosen) {
      return <span className={styles.condition}>If not chosen</span>;
    } else {
      return null;
    }
  };

  if (!props.action?.type) {
    return null;
  }

  return <span className={className}>({text}<ConditionType />)</span>;
};

export default SurveyActionType;
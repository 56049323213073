import { useCallback, useMemo } from 'react';
import { SurveyOptionType } from '@enums';
import { GridColumnIndicator } from '@presentation/GridColumnIndicator';
import { cx } from '@utils';
import { SurveyQuestionMatrixRow, SurveyQuestionFormOption } from '@/types';
import SurveyMatrixGridRowItem from './GridRowItem';
import { Answer, MatrixVariant, SurveyMatrixGridProps } from './interfaces';
import styles from './style/Grid.css';

const NAWidth = 8;
const NameWidth = 20;
const MaxCellWidth = 180;

export const SurveyMatrixGrid = <T extends MatrixVariant>({
  updateRowAnswer,
  ...props
}: SurveyMatrixGridProps<T>) => {
  const nonNAOptions = useMemo(() => {
    return props.options.filter(f => f.type !== SurveyOptionType.NotApplicable);
  }, [props.options]);
  const naOption = useMemo(() => props.options.find(f => f.type === SurveyOptionType.NotApplicable), [props.options]);

  const maxNameWidth = useMemo(() => {
    const maxRowLength = Math.max(...props.rows.map(m => m.value.length));
    const value = Math.max(Math.min(maxRowLength, 50), 20);
    return `${value}%`;
  }, [props.rows]);

  const gridWidth = useMemo(() => {
    return !naOption
      ? 100 - NameWidth
      : 100 - NameWidth - NAWidth;
  }, [naOption]);

  const cellWidth = useMemo(() => {
    const width = gridWidth / nonNAOptions.length;
    return `${width}%`;
  }, [gridWidth, nonNAOptions.length]);

  const renderHeaderNACell = useCallback((option: SurveyQuestionFormOption) => {
    return (
      <div className={styles.headerNa}>
        <div className={styles.headerCellWrap}>
          <div className={styles.headerTitle}>{option.value}</div>
        </div>
      </div>
    );
  }, []);

  const renderHeader = useCallback(() => {
    return (
      <div className={styles.header}>
        <div
          className={styles.rowName}
          style={{ maxWidth: maxNameWidth }} />
        <div style={{
          width: `${gridWidth}%`,
          maxWidth: `${MaxCellWidth * nonNAOptions.length}px`,
        }}>
          <GridColumnIndicator
            values={nonNAOptions.map(o => o.value)} />
        </div>
        {naOption && renderHeaderNACell(naOption)}
      </div>
    );
  }, [
    gridWidth,
    maxNameWidth,
    naOption,
    nonNAOptions,
    renderHeaderNACell,
  ]);

  const renderItem = useCallback((row: SurveyQuestionMatrixRow, index: number) => {
    if (props.variant === 'single') {
      const value = (props.answers as Answer<'single'>).find(f => f.matrixRowId === row.id)?.optionId;
      return (
        <SurveyMatrixGridRowItem
          key={row.id}
          cellClassName={cx({
            [styles.cellLast]: index === props.rows.length - 1,
          })}
          cellWidth={cellWidth}
          maxNameWidth={maxNameWidth}
          options={props.options}
          row={row}
          value={value}
          updateRowAnswer={updateRowAnswer(row.id)}
          variant="single" />
      );
    } else {
      const value = (props.answers as Answer<'multiselect'>).find(f => f.matrixRowId === row.id)?.optionIds;
      return (
        <SurveyMatrixGridRowItem
          key={row.id}
          cellClassName={cx({
            [styles.cellLast]: index === props.rows.length - 1,
          })}
          cellWidth={cellWidth}
          maxNameWidth={maxNameWidth}
          options={props.options}
          row={row}
          value={value}
          updateRowAnswer={updateRowAnswer(row.id)}
          variant="multiselect" />
      );
    }

  }, [
    cellWidth,
    maxNameWidth,
    props.answers,
    props.options,
    props.rows.length,
    props.variant,
    updateRowAnswer,
  ]);

  return (
    <div className={cx(styles.root, props.className)}>
      {renderHeader()}
      {props.rows.map(renderItem)}
    </div>
  );
};

export default SurveyMatrixGrid;
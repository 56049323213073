import {
  MaxDifferenceQuestion, SurveyQuestion,
  SurveyQuestionOption,
} from '@/types';
import { MaxDiff, SurveyBuilder } from '../interfaces';
import { generateNewOption, generateNewOrdinalsRemoval } from './defaults';

type State = SurveyBuilder.State['survey']['questions'];

type UpdateQuestion = {
  ordinal: number;
  state: State;
  mutate: (item: MaxDifferenceQuestion.Question) => MaxDifferenceQuestion.Question;
};

function updateQuestion({ mutate, ordinal, state }: UpdateQuestion) {
  return state.reduce<SurveyQuestion[]>((acc, q) => {
    const question = q.ordinal !== ordinal
      ? q
      : mutate(q as MaxDifferenceQuestion.Question);

    return [...acc, question];
  }, []);
}

const addOption = (data: MaxDiff.AddOption.State) => (item: MaxDifferenceQuestion.Question): MaxDifferenceQuestion.Question => {
  const ordinal = item.options.length + 1;

  return {
    ...item,
    settings: {
      ...item.settings,
      optionsPerSet: item.settings.sets === 1
        ? ordinal
        : item.settings.optionsPerSet,
    },
    options: [
      ...item.options,
      generateNewOption({
        metadata: {
          canDelete: true,
          canModifyValue: true,
          template: {},
        },
        ordinal,
      }),
    ],
  };
};

const removeOption = ({ ordinal }: MaxDiff.RemoveOption.State) => (item: MaxDifferenceQuestion.Question): MaxDifferenceQuestion.Question => {
  const ordinals = generateNewOrdinalsRemoval(item.options, ordinal);

  return {
    ...item,
    settings: {
      ...item.settings,
      optionsPerSet: Math.min(item.options.length - 1, item.settings.optionsPerSet),
    },
    options: item.options
              .filter(o => o.ordinal !== ordinal)
              .map(o => ({ ...o, ordinal: ordinals[o.ordinal] })),
  };
};

const updateOptionName = ({ ordinal, value }: MaxDiff.UpdateOptionName.State) => (item: MaxDifferenceQuestion.Question): MaxDifferenceQuestion.Question => {
  return {
    ...item,
    options: item.options.reduce<SurveyQuestionOption[]>((updated, o) => {
      const option = o.ordinal !== ordinal
        ? o
        : { ...o, value };
      return [...updated, option];
    }, []),
  };
};

const updateLeastLabel = ({ value }: MaxDiff.UpdateLeastLabel.State) => (item: MaxDifferenceQuestion.Question): MaxDifferenceQuestion.Question => {
  return {
    ...item,
    settings: {
      ...item.settings,
      label: {
        ...item.settings.label,
        left: value,
      },
    },
  };
};

const updateMostLabel = ({ value }: MaxDiff.UpdateMostLabel.State) => (item: MaxDifferenceQuestion.Question): MaxDifferenceQuestion.Question => {
  return {
    ...item,
    settings: {
      ...item.settings,
      label: {
        ...item.settings.label,
        right: value,
      },
    },
  };
};

const updateOptionsPerSet = ({ value }: MaxDiff.UpdateOptionsPerSet.State) => (item: MaxDifferenceQuestion.Question): MaxDifferenceQuestion.Question => {
  return {
    ...item,
    settings: {
      ...item.settings,
      optionsPerSet: value,
    },
  };
};

const updateSets = ({ value }: MaxDiff.UpdateSets.State) => (item: MaxDifferenceQuestion.Question): MaxDifferenceQuestion.Question => {
  return {
    ...item,
    settings: {
      ...item.settings,
      sets: value,
      optionsPerSet: value === 1 ? item.options.length : item.settings.optionsPerSet,
    },
  };
};

export function maxDiffReducer(state: SurveyBuilder.State, action: MaxDiff.Action): State {
  const questions = state.survey.questions;
  const ordinal = state.editing.questionOrdinal;

  switch (action.type) {
    case 'maxdiff-add-option':
      return updateQuestion({
        mutate: addOption(action),
        ordinal,
        state: questions,
      });

    case 'maxdiff-remove-option':
      return updateQuestion({
        mutate: removeOption(action),
        ordinal,
        state: questions,
      });

    case 'maxdiff-update-option-name':
      return updateQuestion({
        mutate: updateOptionName(action),
        ordinal,
        state: questions,
      });

    case 'maxdiff-update-least-label':
      return updateQuestion({
        mutate: updateLeastLabel(action),
        ordinal,
        state: questions,
      });

    case 'maxdiff-update-most-label':
      return updateQuestion({
        mutate: updateMostLabel(action),
        ordinal,
        state: questions,
      });

    case 'maxdiff-update-options-per-set':
      return updateQuestion({
        mutate: updateOptionsPerSet(action),
        ordinal,
        state: questions,
      });

    case 'maxdiff-update-sets':
      return updateQuestion({
        mutate: updateSets(action),
        ordinal,
        state: questions,
      });

    default: return questions;
  }
}

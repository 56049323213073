import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useProjectCapabilities, useSelectUser } from '@containers/Store';
import { ProjectPipelineItemContainer } from '@containers/GroupProject/ProjectPipelineItemContainer';
import { getLocationFor } from '@utils';
import { DollarRate, ExpertRate } from 'components/ExpertRate';
import { UserAvatar } from 'components/UserAvatar';
import styles from '../style/ProjectPipelineItem.css';
import ButtonSet from './ButtonSet';
import UserEmployments from './UserEmployments';
import UserProfileLink from './UserProfileLink';
import UserStatus from './UserStatus';
import UserTopicsTags from './UserTopicsTags';
import { ProjectPipelineItemProps } from './interfaces';

const selectShowConsultantRates = (state: Store.State) => state.group.features.showConsultantRates;
const selectProjectLinkUse = (projectId: number, userId: number) => (state: Store.State) => state.pipeline.project[projectId].linkUses[userId];

type Props =
  ProjectPipelineItemProps;

const ProjectPipelineItem = (props: Props) => {
  const showConsultantRates = useSelector(selectShowConsultantRates);
  const me = useSelectUser();
  const projectCapabilities = useProjectCapabilities(props.project.id);
  const linkUse = useSelector(selectProjectLinkUse(props.project.id, props.item.id));

  const renderRate = () => {
    if (linkUse?.hourlyRate) {
      return (
        <DollarRate rate={linkUse.hourlyRate} />
      );
    }

    return (
      <ExpertRate
        user={props.item}
        observer={me}
        visible={showConsultantRates} />
    );
  };

  return (
    <div className={styles.item}>
      <div className={styles.details}>
        <div className={styles.avatar}>
          <Link to={getLocationFor.user.profile(props.item.profile)}>
            <UserAvatar
              pictureUrl={props.item.profile.pictureUrl}
              size={45} />
          </Link>
        </div>

        <div className={styles.info}>
          <div className={styles.header}>
            <div className={styles.col}>
              <UserProfileLink
                slug={props.item.profile.slug}
                text={props.item.profile.fullname} />

              {renderRate()}
            </div>

            <UserStatus
              projectId={props.project.id}
              userId={props.item.id} />
          </div>

          <UserEmployments item={props.item} />
          <UserTopicsTags items={props.item.topics} />
        </div>
      </div>

      {projectCapabilities.canManage &&
        <ProjectPipelineItemContainer
          projectId={props.project.id}
          userId={props.item.id}>
          <ButtonSet />
        </ProjectPipelineItemContainer>}
    </div>
  );
};

export default ProjectPipelineItem;
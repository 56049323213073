import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMenuContext } from '@containers/AppNavigation/Context';
import { cx } from '@utils';
import NotificationsList from 'components/Notifications/Notifications';
import { ActionableIcon } from './Menu.ActionableIcon';
import { useAnchoredMenuRef } from './hooks/useAnchoredMenuRef';
import styles from './style/Nav.css';

type Props = unknown;

export const Notifications = (props: Props) => {
  const { notifications } = useMenuContext();

  const [ref, open, actions] = useAnchoredMenuRef<HTMLDivElement>();

  const toggle = useCallback(() => {
    actions.toggle();
    notifications.onClick?.();
  }, [
    actions,
    notifications,
  ]);

  const classes = cx({
    [styles.hidden]: !open,
    [styles.visible]: open,
  });

  if (!notifications) return null;

  return (
    <div className={styles.root}>
      <ActionableIcon
        count={notifications.count}
        Icon={notifications.icon}
        key={notifications.key}
        name={notifications.name}
        onClick={toggle}
        ref={ref}
        to={notifications.to}>
        <div className={classes}>
          <DropdownMenu open={open} />
        </div>
      </ActionableIcon>
    </div>
  );
};

const mapState = (state: Store.State) => state.notifications.items;

type DropdownMenuProps = {
  open: boolean;
};

const DropdownMenu = (props: DropdownMenuProps) => {
  const items = useSelector(mapState);

  return (
    <NotificationsList
      dropdown
      items={items}
      open={props.open} />
  );
};

Notifications.displayName = 'Menu.Notifications';
DropdownMenu.displayName = 'Menu.Notifications.DropdownMenu';
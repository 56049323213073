import { useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { ActionOrdinalItem } from '../interfaces';

export const useSkipToSectionOptions = (ordinal: number): ActionOrdinalItem[] => {
  const [state] = useSurveyBuilderState();

  const items: ActionOrdinalItem[] = useMemo(() => {
    return state.survey.sections
      .filter(f => f.ordinal > ordinal && !f.hidden)
      .map(m => ({
        identifier: m.identifier,
        ordinal: m.ordinal,
        value: m.name,
      }));
  }, [
    state.survey.sections,
    ordinal,
  ]);

  return items;
};

export default useSkipToSectionOptions;
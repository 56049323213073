import http from '@services/http';
import { TranscribeRT } from '@/types';
import { safeJsonDate } from '@utils';
import { Calls } from './interfaces';

export const getTranscript = ({ callId }: Calls.CallTranscripts.Request): Promise<Calls.CallTranscripts.Response> => {
  return http.get(`/calls/${callId}/transcripts`);
};

export const downloadTranscriptReport = (data: Calls.DownloadTranscriptReport.Request): Promise<Calls.DownloadTranscriptReport.Response> => {
  return http.get(`/calls/${data.callId}/transcripts/${data.transcriptId}/report?format=${data.format}`);
};

export const saveTranscriptHighlight = ({ callId, transcriptId, highlight }: Calls.SaveTranscriptHighlight.Request): Promise<Calls.SaveTranscriptHighlight.Response> => {
  return http.post(`/calls/${callId}/transcripts/${transcriptId}/highlights`, highlight);
};

export const removeTranscriptHighlight = ({ callId, transcriptId, highlightId }: Calls.RemoveTranscriptHighlight.Request): Promise<Calls.RemoveTranscriptHighlight.Response> => {
  return http.delete(`/calls/${callId}/transcripts/${transcriptId}/highlights/${highlightId}`);
};

export const getTranscriptHighlights = ({ callId, transcriptId }: Calls.GetTranscriptHighlights.Request): Promise<Calls.GetTranscriptHighlights.Response> => {
  return http.get(`/calls/${callId}/transcripts/${transcriptId}/highlights`);
};

export const getTranscriptComments = ({ callId, transcriptId }: Calls.GetTranscriptComments.Request) => {
  return http.get<Calls.GetTranscriptComments.Response>(`/calls/${callId}/transcripts/${transcriptId}/comments`)
    .then(res => {
      return {
        comments: res.comments.map(item => ({
          ...item,
          parentComment: {
            ...item.parentComment,
            createdOn: safeJsonDate(item.parentComment.createdOn),
          },
          children: item.children.map(comment => ({
            ...comment,
            createdOn: safeJsonDate(comment.createdOn),
          })),
        })),
      };
    });
};

export const saveTranscriptComment = ({ callId, transcriptId, ...body }: Calls.SaveTranscriptComment.Request): Promise<TranscribeRT.Comment>  => {
  return http.post(`/calls/${callId}/transcripts/${transcriptId}/comments`, body)
    .then((res: Calls.SaveTranscriptComment.Response) => {
      return {
        ...res.comment,
        createdOn: safeJsonDate(res.comment.createdOn),
      };
    });
};

export const updateTranscriptComment = ({ callId, commentId, transcriptId, ...body }: Calls.UpdateTranscriptComment.Request): Promise<TranscribeRT.Comment>  => {
  return http.patch(`/calls/${callId}/transcripts/${transcriptId}/comments/${commentId}`, body)
    .then((res: Calls.UpdateTranscriptComment.Response) => {
      return {
        ...res.comment,
        createdOn: safeJsonDate(res.comment.createdOn),
      };
    });
};

export const removeTranscriptComment = ({ callId, transcriptId, commentId }: Calls.RemoveTranscriptComment.Request) => {
  return http.delete(`/calls/${callId}/transcripts/${transcriptId}/comments/${commentId}`);
};

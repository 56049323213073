import * as Banner from 'components/Banner';
import { Column, ScreenLayout } from './Layout';
import Profile from './Profile';
import Helmet from './Helmet';

function UserProfileIndividual(props: unknown) {
  return (
    <>
      <Helmet />
      <Banner.ProfileComplete />
      <ScreenLayout>
        <Column>
          <Profile />
        </Column>
      </ScreenLayout>
    </>
  );
}

export { UserProfileIndividual };
export default UserProfileIndividual;
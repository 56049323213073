import { useCallback, ComponentType } from 'react';
import { useToggle } from '@utils';
import { ModalProps } from 'components/Modal/Modal';

type Props =
  Pick<ModalProps, 'open' | 'onClose'>;

type Options = {
  keepMounted?: boolean;
};

export const useModal = <T extends Props = Props, K = Omit<T, 'onClose' | 'open'>>(ModalComponent: ComponentType<Props & K>, options: Options = {}) => {
  const [visible, toggleVisibility] = useToggle();

  const Modal = useCallback((props: K) => {
    if (options.keepMounted === false && !visible) {
      return null;
    }

    return (
      <ModalComponent
        onClose={toggleVisibility}
        open={visible}
        {...props} />
    );
  }, [
    ModalComponent,
    options.keepMounted,
    toggleVisibility,
    visible,
  ]);

  /* eslint-disable-next-line */
  // @ts-ignore
  Modal.displayName = `useModal`;

  return [toggleVisibility, Modal, visible] as const;
};
import { useCallback, useState } from 'react';
import { StripeElements } from '@containers/Stripe/StripeElements';
import { FormDropdown, FormDropdownOnChange } from '@screens/Stripe/FormDropdown';
import styles from '@screens/Stripe/style.css';
import Currencies from './Currencies';
import USD from './USD';
import { NewBankProps } from './interfaces';

type Props =
    NewBankProps;

function NewBankComponent(props: Props) {
  const [currency, setCurrency] = useState<string>('USD');

  const handleCurrencyChange: FormDropdownOnChange = useCallback(item => {
    setCurrency(item.id);
  }, [setCurrency]);

  return (
    <div>
      <FormDropdown
        className={styles.row}
        label='Currency'
        items={Currencies}
        selected={Currencies.find(c => c.id === currency)}
        required={true}
        onChange={handleCurrencyChange} />
      <USD {...props} />
    </div>
  );
}

export function NewBank(props: NewBankProps) {
  return (
    <StripeElements>
      <NewBankComponent {...props} />
    </StripeElements>
  );
}

export default NewBank;
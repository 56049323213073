import { useCallback } from 'react';
import * as api from '@api';

export const useStripeSignup = (userId: number) => {
  const stripeSignup = useCallback(async () => {
    const { url } = await api.payout.getStripeAuthorizeUrl({
      userId,
    });

    if (!url) return;

    const width = 600;
    const height = Math.min(1080, Math.max(600, screen.height * 0.9));

    const options = {
      resizable: 1,
      scrollbars: 1,
      width,
      height,
      top: window.screenY + (window.outerHeight - height) / 2.5,
      left: window.screenX + (window.outerWidth - width) / 2,
    };

    type OptionKeys = keyof typeof options;

    const optionsArray = Object.keys(options).map((k: OptionKeys) => `${k}=${options[k]}`);

    const popup = window.open(url, '', optionsArray.join(','));

    if (popup) {
      popup.focus();
    }
  }, [userId]);

  return stripeSignup;
};

export default useStripeSignup;
import { useCallback, useState } from 'react';
import ProductAutocomplete, { ProductItem } from 'components/AutoComplete/Product';
import DropDown from 'components/DropDown';
import { TagRowProps, FormTagValueState, FormTagRelationship } from './interfaces';
import styles from './style/TagRow.css';

type Props
  = TagRowProps;

export default function TagRow({ onChange, ...props }: Props) {
  const [valueState, setValueState] = useState<ValueStateItem>(getValueStateItem(props.valueState));
  const [relationship, setRelationship] = useState<RelationshipItem>(getRelationshipItem(props.relationship));
  const [entity, setEntity] = useState<ProductItem>(props.entity);

  const handleValueActionChange = useCallback((item: ValueStateItem) => {
    setValueState(item);
    onChange({ valueState: item.id, relationship: relationship.id, entity });
  }, [entity, relationship, onChange]);

  const handleRelationshipChange = useCallback((item: RelationshipItem) => {
    setRelationship(item);
    onChange({ valueState: valueState.id, relationship: item.id, entity });
  }, [entity, valueState, onChange]);

  const handleSelectEntity = useCallback((item: ProductItem) => {
    setEntity(item);
    onChange({ valueState: valueState.id, relationship: relationship.id, entity: item });
  }, [valueState, relationship, onChange]);

  const handleRemoveEntity = useCallback(() =>  handleSelectEntity(null), [handleSelectEntity]);

  const SelectedProduct = useCallback(() => {
    return (
      <div className={styles.selected}>
        <div className={styles.selectedText}>{entity.name}</div>
        <div className={styles.selectedRemove} onClick={handleRemoveEntity}>x</div>
      </div>
    );
  }, [entity, handleRemoveEntity]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>Value</div>
      <div className={styles.header}>Relationship</div>
      <div className={styles.header}>Entity</div>
      <div>
        <DropDown<ValueStateItem>
          getItemValue={item => item.text}
          items={ValueStateItems}
          onSelect={handleValueActionChange}
          text={valueState.text} />
      </div>
      <div>
        <DropDown<RelationshipItem>
          getItemValue={item => item.text}
          items={RelationshipItems}
          onSelect={handleRelationshipChange}
          text={relationship.text} />
      </div>
      <div>
        {entity
          ? <SelectedProduct />
          : <ProductAutocomplete onSelect={handleSelectEntity} />}
      </div>
    </div>
  );
}

export { TagRow };

function getValueStateItem(value: FormTagValueState) {
  const item =  ValueStateItems.find(i => i.id === value);
  return item ?? DefaultActionItem;
}

const ValueStateItems = [
  { id: FormTagValueState.Selected, text: 'Selected' },
  { id: FormTagValueState.NotSelected, text: 'Not Selected' },
];

const DefaultActionItem = ValueStateItems.find(i => i.id === FormTagValueState.Selected);

function getRelationshipItem(value: FormTagRelationship) {
  const item =  RelationshipItems.find(i => i.id === value);
  return item ?? DefaultRelationshipItem;
}

const RelationshipItems = [
  { id: FormTagRelationship.UserOfProduct, text: 'User of' },
  { id: FormTagRelationship.NotUserOfProduct, text: 'Not User of' },
];

const DefaultRelationshipItem = RelationshipItems.find(i => i.id === FormTagRelationship.UserOfProduct);

type ValueStateItem = {
  id: FormTagValueState;
  text: string;
}

type RelationshipItem = {
  id: FormTagRelationship;
  text: string;
}
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectUser } from '@containers/Store';
import * as actions from '@actions';
import * as api from '@api';
import { UserPromptProps } from '@/types';

export const useUpdateUserPrompts = () => {
  const user = useSelectUser();
  const dispatch = useDispatch();

  const updatePrompts = useCallback((data: Partial<UserPromptProps>) => {
    return api.users.updatePrompts(data)
      .then(res => {
        dispatch(actions.userInfoChange({
          ...user,
          prompts: res,
        }));
      });
  }, [dispatch, user]);

  return updatePrompts;
};
import { Link } from 'react-router-dom';
import { path } from '@consts';
import { PayoutMethod } from '@enums';
import Button from 'components/Button';
import { BillingDoneProjectProps } from './interfaces';
import styles from './style/Done.css';

type Props = BillingDoneProjectProps;

export const Survey = ({ navigation, method, nextLabel, referrals }: Props) => {
  const title = method === PayoutMethod.Stripe
      ? `Thank you for completing the survey, you will be paid through your connected Stripe account!`
      : `Thank you for completing the survey, you will be paid by check to your address!`;

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.copy}>{`Your payout preferences can be managed in the billing section of your account settings.`}</div>
        <div className={styles.navigation}>
          <Navigation
            navigation={navigation}
            referrals={referrals}
            nextLabel={nextLabel} />
        </div>
      </div>
    </div>
  );
};

Survey.defaultProps = {
  nextLabel: 'Complete',
};

const Navigation = (props: Omit<Props, 'method'>) => {
  if (!props.referrals) {
    return (
      <Button
        className={styles.next}
        variant="brick"
        title={props.nextLabel}
        onClick={props.navigation.next} />
    );
  }

  return (
    <>
      <div className={styles.refer}>
        <div className={styles.header}>{`Know someone else who might be a fit for this project?`}</div>
        <div className={styles.copy}>{`Get paid for colleagues you refer for this project.`}</div>
        <Button
          className={styles.next}
          variant="brick"
          title="Refer a colleague"
          onClick={props.navigation.next} />
      </div>
      <div className={styles.footer}>
        <Link
          className={styles.link}
          to={path.Projects.Root}>
          Back to Home
        </Link>
      </div>
    </>
  );
};
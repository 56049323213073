import { useEffect } from 'react';
import { useMenuContext } from '@containers/AppNavigation/Context';
import { cx } from '@utils';
import styles from 'components/AppBar/style/Drawer.css';
import { DrawerItem } from './DrawerItem';

type Props = {
  open: boolean;
};

type ContainerProps = Props & {
  className?: string;
  children: React.ReactNode;
  overlayClassName?: string;
};

const DrawerContainer = (props: ContainerProps) => {
  const className = cx({
    [styles.visible]: props.open,
    [styles.hidden]: !props.open,
  });

  return (
    <div className={className}>
      <div className={cx(styles.menu, props.className)}>
        <div className={styles.wrap}>
          {props.children}
        </div>
      </div>

      <div className={cx(styles.overlay, props.overlayClassName)} />
    </div>
  );
};

const Drawer = (props: Props) => {
  const { menu } = useMenuContext();

  useEffect(() => {
    if (props.open) {
      document.body.style.overflowY = 'hidden';
    }

    return () => {
      document.body.style.overflowY = '';
    };
  }, [props.open]);

  return (
    <DrawerContainer open={props.open}>
      <div className={styles.links}>
        {menu.drawer.map(x =>
          <DrawerItem
            count={x.count}
            icon={x.icon}
            key={x.key}
            name={x.name}
            onClick={x.onClick}
            to={x.to} />)}
      </div>
    </DrawerContainer>
  );
};

export { Drawer, DrawerContainer };
export default Drawer;
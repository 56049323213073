import { useCallback } from 'react';
import { Button } from 'components/Button';
import { Header } from 'components/Modal/Header';
import { Modal, ModalProps } from 'components/Modal/Modal';
import styles from './style/ExistingFilesModal.css';

type Props = {
  files: File[];
  onConfirm: (updateExisting: boolean) => unknown;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const ExistingFilesModal = ({ files, onClose, onConfirm, open }: Props) => {

  const onUseExisting = useCallback(() => onConfirm(true), [onConfirm]);
  const onUseSeparate = useCallback(() => onConfirm(false), [onConfirm]);

  const Footer = useCallback(() => {
    return (
      <div className={styles.footer}>
        <Button.Destructive
          className={styles.btn}
          onClick={onClose}
          variant="brick">
          Cancel
        </Button.Destructive>
        <div className={styles.actions}>
          <Button.Secondary
            className={styles.btn}
            onClick={onUseSeparate}
            variant="brick">
            Keep Separate
          </Button.Secondary>
          <Button.Affirmative
            className={styles.btn}
            onClick={onUseExisting}
            variant="brick">
            Update Existing
          </Button.Affirmative>
        </div>
      </div>
    );
  }, [
    onUseSeparate,
    onUseExisting,
    onClose,
  ]);

  return (
    <Modal onClose={onClose} open={open}>
      <Header
        className={styles.header}
        text="Duplicate Files" />
      <div className={styles.body}>
        <div className={styles.message}>The following files match existing items in this folder. Would you like to update the existing items or upload separately?</div>
        <ul className={styles.items}>
          {files.map(f => (
            <li key={f.name} className={styles.item}>{f.name}</li>
          ))}
        </ul>
      </div>
      <Footer />
    </Modal>
  );
};
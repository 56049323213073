import { Card, CardContent, GroupCardHeader, Divider } from '@presentation/Main';
import { NewsArticle } from '@/types/news';
import NewsFeedArticle from './NewsFeedArticle';

type Props = {
  newsArticles: NewsArticle[];
};

const NewsFeed = ({ newsArticles }: Props) => {

  return (
    <Card>
      <GroupCardHeader>News</GroupCardHeader>
      <Divider />
      <CardContent fluid>
        {newsArticles.map(item => (
          <NewsFeedArticle
            key={item.id}
            item={item} />
        ))}
      </CardContent>
    </Card>
  );
};

export { NewsFeed };
export default NewsFeed;
import { EmploymentRecord } from '@/types';

export const compose = <U, T>(...fns: Transformer<U, T>[]): Transformer<U, T> => {
  if (!fns.length) {
    return arg => arg;
  }

  if (fns.length === 1) {
    return fns[0];
  }

  return fns.reduceRight((fa, fb) => (...args: T[]): U => fa(fb(...args)));
};

export const toMap = <K, T>(items: T[], key = 'id'): TMap<K, T>  => {
  return items.reduce((acc, item) => {
    return {
      ...acc,
      [item[key]]: item,
      ids: acc.ids.concat(item[key]),
    };
  }, { ids: [] as K[] });
};

export const toSingleElementArray = <T>(item: T): Array<T> => {
  return item ? [ item ] : [];
};

export const toEmptyArray = (items: EmploymentRecord[], key: Extract<keyof EmploymentRecord, 'functions'>) => {
  return items.map(x => ({
    ...x,
    [key]: x[key].filter(y => !!y),
  }));
};

interface TMap<K, T> {
  [key: number]: T;
  ids: K[];
}

type Transformer<U = unknown, T = unknown> = <T>(...arg: T[]) => U;
import * as consts from '@consts';
import { PathConfig } from '@containers/Routing/interfaces';
import { compose } from '@services/api/transformers';
import { device } from '@utils';

const defaults: PathConfig = {
  any: [
    consts.path.Account.Profile.Setup,
    consts.path.Admin.Root,
    consts.path.Companies.Root,
    consts.path.Compliance.Root,
    consts.path.Contacts.Root,
    consts.path.ExternalProject.Root,
    consts.path.Posts.Root,
    consts.path.Projects.Root,
    consts.path.Research.Root,
    consts.path.Search.Root,
    consts.path.Topics.Root,
    consts.path.UniversalSearch.Root,
    consts.path.Workspaces.Root,

    consts.pathname.CONFERENCE,
    consts.pathname.HOME,
    consts.pathname.MESSAGES,
    consts.pathname.NOTIFICATIONS,
    consts.pathname.ONBOARDING,
    consts.pathname.PROJECT_NOTES,
    consts.pathname.REFER,
    consts.pathname.Settings,
    consts.pathname.USER_PROFILE,
    consts.pathname.Welcome,
  ],
  exact: [
    consts.path.Account.Profile.Checkup,
    consts.path.Inbound.Slack,
  ],
};

const buildPaths = (config = defaults) => {
  const build = compose<PathConfig, PathConfig>(
    calendar,
    scheduling,
  );

  const paths = build(config);

  return {
    any: paths.any,
    exact: paths.exact,
  };
};

const calendar = (config: PathConfig): PathConfig => {
  if (device.phone) return config;

  return {
    any: config.any.concat(consts.path.Calendar.Root),
    exact: config.exact,
  };
};

const scheduling = (config: PathConfig): PathConfig => {
  if (device.phone) {
    return {
      any: config.any,
      exact: config.exact.concat(consts.path.Scheduling.Root),
    };
  }

  return {
    any: config.any.concat(consts.path.Scheduling.Root),
    exact: config.exact,
  };
};

export const Paths = buildPaths();
import { useEffect, useState } from 'react';
import * as api from '@api';
import { useSelectGroup } from '@containers/Store';
import { PaymentMethodDetails } from './interfaces';

export function useEnterprisePaymentMethodDetails() {
  const group = useSelectGroup();
  const [details, setDetails] = useState<PaymentMethodDetails>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    api.payment.enterprise.getMethodDetails({
      groupId: group.id,
    })
      .then(result => {
        setDetails(result ? result.paymentMethod : null);
        setLoading(false);
      })
      .catch(() => {
        setDetails(null);
        setLoading(false);
      });
  }, [setDetails, setLoading, group.id, group.paymentMethod]);

  return [ details, loading ] as const;
}

export default useEnterprisePaymentMethodDetails;
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { IUniversalSearch } from '@/types';
import { cx } from '@utils';
import { Search } from 'components/icons';
import ClickContainer from './Results.ClickContainer';
import styles from './style/Results.Keyword.css';

type Props = {
  className?: string;
  index: number;
  item: IUniversalSearch.SearchBarResultKeyword;
  highlighted?: boolean;
}

export default function ResultsKeyword({ className, index, item, highlighted }: Props) {
  return (
    <ClickContainer index={index} item={item}>
      <div className={cx(styles.root, { [styles.highlighted]: highlighted }, className)}>
        <Search
          className={styles.icon}
          size={28} />
        <div className={styles.details}>
          <div className={styles.name}>{item.name}</div>
          <div className={styles.type}>Keyword</div>
        </div>
        {highlighted && (
          <div className={styles.return}>
            <KeyboardReturnIcon />
          </div>
        )}
      </div>
    </ClickContainer>
  );
}

export { ResultsKeyword };
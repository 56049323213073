import { cx } from '@utils';
import styles from './style/Header.css';

type Props = {
  className?: string;
  value: string;
};

export const SurveyLogicHeader = (props: Props) => (
  <div className={cx(styles.root, props.className)}>{props.value}</div>
);

export const SurveyLogicActionHeader = (props: Omit<Props, 'value'>) => (
  <SurveyLogicHeader
    className={props.className}
    value="Action" />
);

export const SurveyLogicOrdinalHeader = (props: Omit<Props, 'value'>) => (
  <SurveyLogicHeader
    className={props.className}
    value="" />
);

export const SurveyLogicAnswerChoiceHeader = (props: Omit<Props, 'value'>) => (
  <SurveyLogicHeader
    className={props.className}
    value="Answer Choice" />
);

export const SurveyLogicConditionHeader = (props: Omit<Props, 'value'>) => (
  <SurveyLogicHeader
    className={props.className}
    value="Condition" />
);

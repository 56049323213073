import { useCallback, useMemo, useState } from 'react';
import cuid from 'cuid';
import { SurveyQuestionConditionType, SurveyOptionType } from '@enums';
import { useSurveyBuilderState, useGetQuestionRawText } from '@containers/SurveyBuilder';
import { useQuestionBuilderItemContext } from '@containers/SurveyBuilder.Question/Context';
import { GrayOutlineButton } from '@presentation/Buttons';
import { ConditionTypeDropdown, QuestionDropdown, QuestionDropdownItem } from '@presentation/SurveyBuilder';
import { SurveyQuestionOption, SurveyQuestionMatrixRow } from '@/types/survey.question';
import { Button } from 'components/Button';
import { Modal, ModalProps } from 'components/Modal/Modal';
import ModalHeader from 'components/Modal/Header';
import { useModal } from 'components/Modal/hooks';
import styles from './style/ResponsePiping.Modal.css';

type Props = {
  pipeableQuestions: QuestionDropdownItem[];
  type?: 'options' | 'rows';
} & Pick<ModalProps,
    'open' |
    'onClose'>;

type ConditionType =
  SurveyQuestionConditionType.Chosen |
  SurveyQuestionConditionType.NotChosen;

export const ResponsePipingModal = ({
  onClose,
  open,
  pipeableQuestions,
  type,
}: Props) => {

  const item = useQuestionBuilderItemContext();
  const [state, dispatch] = useSurveyBuilderState();
  const getQuestionText = useGetQuestionRawText();

  const [conditionType, setConditionType] = useState<ConditionType>(SurveyQuestionConditionType.Chosen);
  const [questionIdentifier, setQuestionIdentifier] = useState<string>(null);

  const selectedQuestion = useMemo(() => {
    return state.survey.questions.find(f => f.base.identifier === questionIdentifier);
  }, [
    questionIdentifier,
    state.survey.questions,
  ]);

  const options = useMemo(() => {
    if (!selectedQuestion) return [];
    return selectedQuestion.options
      .filter(f => f.type === SurveyOptionType.Default);
  }, [
    selectedQuestion,
  ]);

  const handleSubmitOptions = useCallback(() => {
    const lastOrdinal = Math.max(...item.options
      .filter(f => f.type === SurveyOptionType.Default)
      .map(m => m.ordinal)
    );

    const filteredOptions = options.filter(f => !item.options.some(s => s.value === f.value));

    const toAdd: SurveyQuestionOption[] = filteredOptions.map((m, i) => ({
      id: null,
      base: {
        id: null,
        identifier: cuid(),
      },
      metadata: {
        canDelete: true,
        canModifyValue: true,
        template: {},
      },
      ordinal: lastOrdinal + i + 1,
      conditions: [{
        condition: {
          type: conditionType,
          value: {
            option: {
              identifier: m.base.identifier,
            },
          },
        },
        question: {
          identifier: questionIdentifier,
        },
      }],
      type: SurveyOptionType.Default,
      value: m.value,
    }));

    dispatch({
      questionIdentifier: item.base.identifier,
      options: toAdd,
      reorder: true,
      type: 'add-question-options',
    });
  }, [
    conditionType,
    item.base.identifier,
    item.options,
    dispatch,
    options,
    questionIdentifier,
  ]);

  const handleSubmitRows = useCallback(() => {
    const lastOrdinal = Math.max(...item.matrixRows.map(m => m.ordinal));

    const filteredOptions = options.filter(f => !item.matrixRows.some(s => s.value === f.value));

    const toAdd: SurveyQuestionMatrixRow[] = filteredOptions.map((m, i) => ({
      id: null,
      base: {
        id: null,
        identifier: cuid(),
      },
      metadata: {
        canDelete: true,
        canModifyValue: true,
        template: {},
      },
      ordinal: lastOrdinal + i + 1,
      conditions: [{
        condition: {
          type: conditionType,
          value: {
            option: {
              identifier: m.base.identifier,
            },
          },
        },
        question: {
          identifier: questionIdentifier,
        },
      }],
      value: m.value,
    }));

    dispatch({
      questionIdentifier: item.base.identifier,
      reorder: true,
      rows: toAdd,
      type: 'add-question-rows',
    });
  }, [
    conditionType,
    item.base.identifier,
    item.matrixRows,
    dispatch,
    options,
    questionIdentifier,
  ]);

  const handleSubmit = useCallback(() => {
    if (type === 'rows') {
      handleSubmitRows();
    } else {
      handleSubmitOptions();
    }

    onClose();
  }, [
    handleSubmitOptions,
    handleSubmitRows,
    onClose,
    type,
  ]);

  const canSubmit = useMemo(() => {
    return !!options.length;
  }, [options]);

  return (
    <Modal
      open={open}
      onClose={onClose}>

      <ModalHeader text="Pipe Previous Responses" />

      <div className={styles.body}>

        <div className={styles.dropdowns}>
          <div className={styles.conditionDropdown}>
            <ConditionTypeDropdown
              getTextValue={getConditionDisplayText}
              onSelect={setConditionType}
              value={conditionType} />
          </div>
          <div className={styles.questionDropdown}>
            <QuestionDropdown
              items={pipeableQuestions}
              onSelect={setQuestionIdentifier}
              value={selectedQuestion ? {
                base: selectedQuestion.base,
                ordinal: selectedQuestion.ordinal,
                value:  getQuestionText(selectedQuestion.value),
              } : null} />
          </div>
        </div>

        {!!options.length &&
          <div className={styles.options}>
            <div className={styles.optionsTitle}>Options</div>
            {options.map(option => (
              <div
                key={option.base.identifier}
                className={styles.option}>
                {option.value}
              </div>
            ))}
          </div>
        }
      </div>

      <div className={styles.footer}>
        <GrayOutlineButton
          onClick={onClose}
          title="Cancel" />
        <Button.Primary
          className={styles.submit}
          disabled={!canSubmit}
          onClick={handleSubmit}
          title="Insert"
          variant="brick" />
      </div>
    </Modal>
  );
};

function getConditionDisplayText(v: SurveyQuestionConditionType) {
  if (v === SurveyQuestionConditionType.Chosen) {
    return 'Pipe selected from';
  } else if (v === SurveyQuestionConditionType.NotChosen) {
    return 'Pipe not selected from';
  }
}

export const useResponsePipingModal = () => useModal(ResponsePipingModal);

export default ResponsePipingModal;
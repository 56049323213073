import { useMemo } from 'react';
import { useCoordinator, useConferenceInstance, useLiveRoomScreen, useChat, IConference } from '@containers/Conference';
import { cx } from '@utils';
import { RouteLeavingGuard, RouteLeavingGuardState } from 'components/RouteLeavingGuard';
import { ConferenceLeave } from 'components/Modal/Conference.Leave';
import { BarLeft, BarRight } from './Bar';
import { Controls } from './Controls';
import { Notes } from './Notes';
import { RecordingStatus } from './RecordingStatus';
import { Session } from './Session';
import { useYourParticipant, useSlateEditor } from './hooks';
import styles from './style/ConferenceRoomVideo.css';

export function ConferenceRoomVideo() {
  const editor = useSlateEditor();
  const screen = useLiveRoomScreen();
  const instance = useConferenceInstance<IConference.Coordinator.Conference.MeetingRoom | IConference.Coordinator.Conference.Left>();
  const { leave } = useCoordinator();
  const you = useYourParticipant();

  const rootClassname = useMemo(() => cx({ [styles.root]: !screen.isFullscreen, [styles.rootFs]: screen.isFullscreen }), [screen.isFullscreen]);

  return (
    <div
      className={rootClassname}
      ref={screen.containerRef}>
      <div className={styles.wrap}>
        <div className={styles.left}>
          <BarLeft />
          <BarRight />
        </div>
        <div className={styles.right}>
          <div className={styles.top}>
            <Session />
            {instance.status === 'meeting-room' && instance.features.notes && (
              <Notes
                editor={editor}
                callId={instance.call.id} />)
            }
          </div>
          <div className={styles.bottom}>
            <RecordingStatus />
            <Controls />
          </div>
        </div>
      </div>
      {/* todo: move this to a parent so it won't be destroyed on status change (to fix confirmNavigation) */}
      <RouteLeavingGuard block={instance?.status !== 'left'}>
        {(guard: RouteLeavingGuardState) => (
          <ConferenceLeave
            isHost={you?.isHost}
            open={guard.open}
            onClose={guard.closePrompt}
            onConfirm={(end: boolean) => {
              guard.closePrompt();
              leave({
                conferenceIdentifier: instance.conferenceIdentifier,
                end,
                title: instance.status === 'meeting-room' ? instance.conference.title : null,
                location: guard.pendingLocation.current,
                redirect: 'location',
              });
            }} />
        )}
      </RouteLeavingGuard>
    </div>
  );
}
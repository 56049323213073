import { SurveyOptionType } from '@enums';
import { cx } from '@utils';
import { SurveyMatrixGridProps, MatrixVariant } from './interfaces';
import SurveyMatrixGrid from './Grid';
import SurveyMatrixGridList from './List';
import styles from './style/Main.css';

const MaxGridOptions = 6;

export const SurveyMatrixGridContainer = <T extends MatrixVariant>(props: SurveyMatrixGridProps<T>) => {

  const options = props.options.filter(f => f.type !== SurveyOptionType.NotApplicable);

  const gridClassName = cx({
    [styles.hide]: options.length > MaxGridOptions,
    [styles.desktop]: options.length <= MaxGridOptions,
  });

  const listClassName = cx({
    [styles.mobile]: options.length <= MaxGridOptions,
  });

  return (
    <>
      <SurveyMatrixGridList
        className={listClassName}
        {...props} />
      <SurveyMatrixGrid
        className={gridClassName}
        {...props} />
    </>
  );
};

export default SurveyMatrixGridContainer;
import { useCallback, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  useQueryParams, StringParam,
} from 'use-query-params';
import { path, vancery } from '@consts';
import {
  useUpdateSlackChannels,
  useFetchIntegrations,
} from '@utils/api';
import { Slack } from '@/types';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import {
  useSlackChannelsContext,
  SlackChannelsContainer,
  SlackChannelsEditor,
} from 'components/Slack';
import styles from './style/Slack.css';

export const SlackConnected = () => {

  const [query] = useQueryParams({
    errorCode: StringParam,
  });

  if (query.errorCode) {
    return <SlackError />;
  } else {
    return <SlackSuccessConnected />;
  }
};

const SlackError = () => {

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {`We're sorry, there was an issue connecting slack`}
      </div>
      <div className={styles.copy}>
        Please reach out to {vancery.email} if you require assistance
      </div>
      <div className={styles.body} />
      <div className={styles.footer}>
        <Link
          className={styles.link}
          to={path.Settings.Integrations}>
          Return to settings
        </Link>
      </div>
    </div>
  );
};

const SlackSuccessConnected = () => (
  <SlackChannelsContainer>
    <SlackSuccess />
  </SlackChannelsContainer>
);

const SlackSuccess = () => {

  const history = useHistory();

  const [channels] = useSlackChannelsContext();

  const {
    data: integrations,
    isLoading: integrationsLoading,
    refetch: fetchIntegrations,
  } = useFetchIntegrations({
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const {
    isLoading: submitting,
    mutateAsync: updateChannels,
  } = useUpdateSlackChannels({
    onSuccess: res => {
      fetchIntegrations().then(() => {
        history.push(path.Settings.Integrations);
      });
    },
  });

  const handleSubmit = useCallback(() => {
    updateChannels({
      channels: channels.map(m => m.id),
    });
  }, [channels, updateChannels]);

  useEffect(() => {
    if (integrations && !integrations.slack.connected) {
      history.push(path.Settings.Integrations);
    }
  }, [
    integrations,
    history,
  ]);

  if (integrationsLoading || !channels) return null;

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Your Slack account was successfully connected!
      </div>
      <div className={styles.copy}>
        Please choose which channels you would like to receive notifications. These can be changed later in your notification settings.
      </div>

      <div className={styles.body}>
        <SlackChannelsEditor />
      </div>

      <div className={styles.footer}>
        <Link
          className={styles.link}
          to={path.Settings.Integrations}>
          Return to settings
        </Link>
        <ButtonActivityIndicator
          disabled={!channels?.length}
          implicitDisable={false}
          loading={submitting}
          onClick={handleSubmit}
          variant="brick">
          Submit
        </ButtonActivityIndicator>
      </div>
    </div>
  );
};

export default Slack;
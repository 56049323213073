import { useCallback, useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey as RS } from '@containers/RepeatSurveyResponses/interfaces';
import * as chart from '@containers/SurveyResponses/utils';
import { SurveyQuestionType } from '@enums';
import { ProjectVersionHeaderRow, ResponseOption } from '@presentation';
import { roundToPlace } from '@utils';
import { MaxDifferenceQuestion, SurveyQuestionOption } from '@/types';
import { SimpleAccordion } from 'components/Accordion';
import { useProjectVersion, useQueryAccordionMap } from './hooks';
import Choices from './MaxDiff.Data.Option.Choices';
import styles from './style/Data.css';

export default function MaxDiffDataVersion() {
  const { query, setQueryState, resetQueryState } = useRepeatSurveyResponsesState<RS.QueryState.MaxDiff>();
  const { historical, question } = useRepeatQuestionResponsesData<SurveyQuestionType.MaxDifference>();
  const [isAccordionOpen, toggleAccordion] = useQueryAccordionMap('choices');

  const version = useProjectVersion(query.projectId);

  const versionData =
    useMemo(() => historical[query.projectId].data, [query.projectId, historical]);

  const options =
    useMemo(() => question.options.sort((a, b) => versionData.options[b.ordinal].score - versionData.options[a.ordinal].score), [versionData, question.options]);

  const handleChoiceClick = useCallback((choice: MaxDifferenceQuestion.Choices) => {
    setQueryState({ choice });
  }, [setQueryState]);

  return (
    <>
      <ProjectVersionHeaderRow
        version={version}
        onClick={resetQueryState} />
      {options.map(option => {
        return (
          <SimpleAccordion
            key={option.base.id}
            open={isAccordionOpen(option.base.id)}
            toggleOpen={toggleAccordion(option.base.id)}
            grows={false}
            className={styles.row}
            height={50}
            label={<OptionLabel data={versionData.options[option.ordinal]} option={option} />}>
            <Choices
              active={query.choice}
              response={versionData.options[option.ordinal]}
              settings={question.settings}
              onClick={handleChoiceClick} />
          </SimpleAccordion>
        );
      })}
    </>
  );
}

function OptionLabel({ data, option }: OptionLabelProps) {
  const color = chart.maxdiff.getOptionColor(option.ordinal);
  const subtitle = `(Score: ${roundToPlace(data.score, 2)})`;

  return (
    <ResponseOption
      color={color}
      label={option.value}
      subtitle={subtitle} />
  );
}

export { MaxDiffDataVersion };

type OptionLabelProps = {
  data: MaxDifferenceQuestion.Aggregate.OptionResults;
  option: SurveyQuestionOption;
};
import { useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { cx } from '@utils';
import { Contact } from '@/types';
import { UserAvatar } from 'components/UserAvatar';
import styles from './style/Author.css';

type Props = {
  avatarSize?: number;
  classes?: {
    root?:   string;
    avatar?: string;
    name?:   string;
  };
  className?: string;
  item: Pick<Contact,
  | 'id'
  | 'career'
  | 'profile'>;
};

export const Author = (props: Props) => {

  const career = useMemo(() => {

    if (!props.item?.career) return null;

    const value = props.item.career.current?.display ||
          props.item.career.former?.display;

    return value ?? null;

  }, [props.item?.career]);

  return (
    <div className={cx(styles.root, props.className, props.classes.root)}>
      {props.item?.id &&
        <UserAvatar
          className={props.classes.avatar}
          size={props.avatarSize}
          pictureUrl={props.item.profile.pictureUrl} />}
      {!props.item?.id &&
        <Skeleton
          className={styles.skeleton}
          height={props.avatarSize}
          variant="circular"
          width={props.avatarSize} />}
      <div className={styles.info}>
        <div className={cx(styles.name, props.classes.name)}>
          {!props.item?.id
            ? <Skeleton
                height={20}
                width={150}
                variant="text" />
            : props.item.profile.fullname}
        </div>
        <div className={styles.career}>{career}</div>
      </div>
    </div>
  );
};

const defaultProps = {
  avatarSize: 40,
  classes: {},
};

Author.defaultProps = defaultProps;
Author.displayName = 'Post.Article.Author';
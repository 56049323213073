import { useCallback, useState, ComponentType } from 'react';
import { SurveyTemplateType } from '@enums/survey.template';
import { useSetSurveyTemplate, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { useSurveyTemplate } from '@containers/SurveyBuilder/hooks';
import { Header, Subtitle, Title } from '@screens/ProjectCreation/Header';
import {
  CompetitiveIntelligenceCard,
  CustomSurveyCard,
  EmployeeSatisfactionCard,
  NetPromoterScoreCard,
  PersonaResearchCard,
  PricingStrategyCard,
  MarketingCampaignEffectivenessCard,
  useSurveyTemplateChangeAlert,
} from 'components/Survey.Templates';
import { useProjectSurveyStepperActions } from './context';
import { BackButton, Footer } from './Footer';
import commonStyles from './style.css';
import styles from './style/ProjectSurvey.Template.css';

export const SurveyTemplateSelection = () => {

  const [template] = useSurveyTemplate();
  const [pendingTemplate, setPendingTemplate] = useState<SurveyTemplateType>();
  const { next } = useProjectSurveyStepperActions();
  const [toggleChangeAlert, TemplateChangeAlert] = useSurveyTemplateChangeAlert();
  const [state] = useSurveyBuilderState();

  const setTemplate = useSetSurveyTemplate();

  const updateTemplate = useCallback((t: SurveyTemplateType) => {

    setTemplate(t);

    next();
  }, [
    next,
    setTemplate,
  ]);

  const handleTemplateClick = useCallback((t: SurveyTemplateType) => () => {

    const isDifferentTemplate = template !== null && t !== template;
    const hasExistingSurvey = state.survey.questions.length;

    if (hasExistingSurvey && isDifferentTemplate) {
      toggleChangeAlert();
      setPendingTemplate(t);
    } else if (hasExistingSurvey) {
      next();
    } else {
      updateTemplate(t);
    }

  }, [
    next,
    state.survey.questions,
    template,
    toggleChangeAlert,
    updateTemplate,
  ]);

  const renderTemplateCards = useCallback(() => {
    type ComponentMap = {
      [index: number]: ComponentType<{ onClick: () => void; }>;
    };
    const componentMap: ComponentMap = {
      [SurveyTemplateType.None]: CustomSurveyCard,
      [SurveyTemplateType.CompetitiveIntelligence]: CompetitiveIntelligenceCard,
      [SurveyTemplateType.PersonaResearch]: PersonaResearchCard,
      [SurveyTemplateType.NetPromoterScore]: NetPromoterScoreCard,
      [SurveyTemplateType.EmployeeSatisfaction]: EmployeeSatisfactionCard,
      [SurveyTemplateType.MarketingCampaignEffectiveness]: MarketingCampaignEffectivenessCard,
      [SurveyTemplateType.PricingStrategy]: PricingStrategyCard,
    };

    return (
      <div className={styles.items}>
        {Templates.map(template => {
          const CardComponent = componentMap[template];
          return (
            <CardComponent
              key={template}
              onClick={handleTemplateClick(template)} />
          );
        })}
      </div>
    );
  }, [handleTemplateClick]);

  return (
    <>
      <div className={styles.section}>
        <div className={commonStyles.wrap}>
          <Header className={commonStyles.row}>
            <Title className={commonStyles.row}>Survey Builder</Title>
            <Subtitle className={commonStyles.row}>
              Create a custom survey or choose from one of our templates to kick-start the drafting process.
            </Subtitle>
          </Header>

          {renderTemplateCards()}
        </div>
      </div>
      <Footer>
        <BackButton />
      </Footer>
      <TemplateChangeAlert onConfirm={() => updateTemplate(pendingTemplate)} />
    </>
  );
};

const Templates = [
  SurveyTemplateType.None,
  SurveyTemplateType.CompetitiveIntelligence,
  SurveyTemplateType.PersonaResearch,
  SurveyTemplateType.NetPromoterScore,
  SurveyTemplateType.EmployeeSatisfaction,
  SurveyTemplateType.MarketingCampaignEffectiveness,
  SurveyTemplateType.PricingStrategy,
  // SurveyTemplateType.MarketAnalysis,
];

export default SurveyTemplateSelection;
import { Fragment, useMemo } from 'react';
import { SurveyOptionType } from '@enums';
import { MatrixGridQuestion } from '@/types';
import { MatrixGridAnswerItemProps, MatrixGridAnswerProps } from './interfaces';
import styles from './style/MatrixGrid.css';

const sortOptions = (a: MatrixGridQuestion.Answer.Item, b: MatrixGridQuestion.Answer.Item) => {
  const optionA = a.options[0];
  const optionB = b.options[0];

  return optionA.type === SurveyOptionType.NotApplicable
    ? 1
    : optionB.type === SurveyOptionType.NotApplicable
      ? -1
      : optionB.id > optionA.id
        ? -1
        : 1
  ;
};

const MatrixGridAnswerOption = (props: MatrixGridAnswerItemProps) => {
  return (
    <div className={styles.item}>
      {`${props.item.options[0].value} - ${props.item.row.value}`}
    </div>
  );
};

export const MatrixGridAnswerValue = ({ answer }: MatrixGridAnswerProps) => {
  const sortedItems = useMemo(() => {
    return answer.items
      .sort(sortOptions);
  }, [answer.items]);

  return <>
    {sortedItems.map(item => (
      <Fragment key={item.row.id}>
        <MatrixGridAnswerOption item={item} />
      </Fragment>
    ))}
  </>;
};

export default MatrixGridAnswerValue;
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTable, usePagination, useFlexLayout, Column, Cell } from 'react-table';
import { Calendar, Edit, EyeOff, X, IconProps as FeatherProps } from 'react-feather';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocationDescriptor } from 'history';
import { ProjectPipelineItemContainer } from '@containers/GroupProject/ProjectPipelineItemContainer';
import { useProjectState } from '@containers/GroupProject/hooks';
import { usePipelineActions } from '@containers/GroupProject/hooks/usePipelineActions';
import { Tooltip } from '@presentation';
import { GroupTable } from '@presentation/GroupTable';
import * as enums from '@enums';
import { getLocationFor, trunc, useLock } from '@utils';
import { ReactTablePagination } from '@presentation/TablePagination';
import { MaybeLink } from 'components/MaybeLink';
import { RowsPerPage } from 'components/TablePagination';
import { MemberBaseballCard } from 'components/BaseballCard';
import { useMassPipelineUsers } from './hooks';
import { MassPipelineItem } from './interfaces';
import styles from './style/MassPipelineTable.css';

export const MassPipelineTable = () => {
  const columns = useMassPipelineColumns();
  const data = useMassPipelineUsers();

  const tableInstance = useTable<MassPipelineItem>(
    {
      autoResetPage: false,
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25 },
    },
    useFlexLayout,
    usePagination,
  );

  const updatePageSize = useCallback((pageSize: number) => {
    tableInstance.gotoPage(0);
    tableInstance.setPageSize(pageSize);
  }, [
    tableInstance.gotoPage,
    tableInstance.setPageSize,
  ]);

  const tableClasses = {
    body: styles.body,
    header: styles.header,
    table: styles.table,
    td: styles.td,
  };

  return (
    <div className={styles.root}>
      <GroupTable
        classes={tableClasses}
        instance={tableInstance} />
      <div className={styles.footer}>
        <RowsPerPage
          className={styles.rows}
          value={tableInstance.state.pageSize}
          onSelect={updatePageSize}
          options={[10, 25, 50, 100]} />
        <ReactTablePagination
          className={styles.pagination}
          tableInstance={tableInstance} />
      </div>
    </div>
  );
};

function useMassPipelineColumns(): Column<MassPipelineItem>[] {

  const isDesktop = useMediaQuery('(min-width:900px)');

  return useMemo(() => [
    {
      id: 'user',
      Header: 'Name',
      Cell: NameCell,
      minWidth: 150,
      width: 175,
    },
    isDesktop ? {
      id: 'title',
      Header: 'Title',
      Cell: TitleCell,
      width: 175,
    } : undefined,
    isDesktop ? {
      id: 'company',
      Header: 'Company',
      Cell: CompanyCell,
      width: 175,
    } : undefined,
    {
      id: 'status',
      Header: 'Status',
      Cell: StatusCell,
      width: 100,
    },
    {
      id: 'actions',
      Cell: ActionsCell,
      width: 115,
    },
  ].filter(Boolean), [
    isDesktop,
  ]);
}

function NameCell({ row: { original: data } }: Cell<MassPipelineItem>) {
  const to = getLocationFor.user.profile(data.contact.profile);
  const project = useProjectState();

  return (
    <MemberBaseballCard.Client userId={data.id} projectId={project.latestProject.id}>
      <Link to={to} className={styles.name}>
        {data.contact.profile.fullname}
      </Link>
    </MemberBaseballCard.Client>
  );
}

function TitleCell({ row: { original: data } }: Cell<MassPipelineItem>) {
  return trunc(data.contact.career.current.title, 25);
}

function CompanyCell({ row: { original: data } }: Cell<MassPipelineItem>) {
  return trunc(data.contact.career.current.company, 25);
}

function StatusCell({ row: { original: data } }: Cell<MassPipelineItem>) {
  return enums.utils.UserProjectStatus.getName(data.pipeline.statusId);
}

type ActionIconProps = {
  Icon: React.ComponentType<FeatherProps>;
  onClick?: () => unknown;
  to?: LocationDescriptor;
  title: string;
}

const ActionIcon = ({
  Icon,
  onClick,
  to,
  title,
}: ActionIconProps) => {
  return (
    <MaybeLink to={to}>
      <Tooltip title={title}>
        <div className={styles.iconWrap}>
          <div className={styles.iconBg}>
            <Icon
              className={styles.icon}
              onClick={onClick} />
          </div>
        </div>
      </Tooltip>
    </MaybeLink>
  );
};

function SelectTimeIcon(props: FeatherProps) {
  return (
    <div className={styles.select}>
      <Calendar {...props} />
      <div className={styles.alert} />
    </div>
  );
}

function ActionsCell({ row: { original: data } }: Cell<MassPipelineItem>) {
  return (
    <ProjectPipelineItemContainer projectId={data.pipeline.projectId} userId={data.id}>
      <ActionsCellWithuotContext />
    </ProjectPipelineItemContainer>
  );
}

function ActionsCellWithuotContext() {
  const {
    canRequestCall,
    canRemoveUser,
    canHideUser,
    canSelectTime,
    canSendSurveyInvite,
    canViewSurvey,

    onHideUser,
    onRemoveUser,
    onSubmitCallRequest,

    selectingLocation,
    projectSurveyLocation,
  } = usePipelineActions();

  const [_, lock] = useLock();

  return (
    <div className={styles.actions}>
      {canViewSurvey &&
        <ActionIcon
          Icon={Edit}
          title="View Response"
          to={projectSurveyLocation} />
      }
      {canRemoveUser &&
        <ActionIcon
          Icon={X}
          title="Reject"
          onClick={onRemoveUser} />
      }
      {canHideUser &&
        <ActionIcon
          Icon={EyeOff}
          title="Hide"
          onClick={onHideUser} />
      }
      {canSelectTime &&
        <ActionIcon
          Icon={SelectTimeIcon}
          to={selectingLocation}
          title="Select Time" />
      }
      {canSendSurveyInvite &&
        <ActionIcon
          Icon={Calendar}
          title="Invite"
          onClick={lock(onSubmitCallRequest)} />
      }
      {canRequestCall &&
        <ActionIcon
          Icon={Calendar}
          title="Request Call"
          onClick={lock(onSubmitCallRequest)} />
      }
    </div>
  );
}

export default MassPipelineTable;
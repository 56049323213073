import { useState } from 'react';
import { MatrixSliderQuestion } from '@/types';

function generateAnswerMap(answer: MatrixSliderQuestion.RespondentAnswer.Value): TextAnswers {
  return answer.items.reduce((acc, a) => {
    return {
      ...acc,
      [a.matrixRowId]: a.value.toString(),
    };
  }, {} as TextAnswers);
}

export const useSliderTextAnswers = (initialAnswers: MatrixSliderQuestion.RespondentAnswer.Value) => {
  const state = useState<TextAnswers>(generateAnswerMap(initialAnswers));

  return state;
};

type TextAnswers = {
  [id: number]: string;
};

export default useSliderTextAnswers;
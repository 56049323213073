import { BarRightParticipantsWaitingRoom } from './Bar.Right.Participants.WaitingRoom';
import { BarRightParticipantsInConference } from './Bar.Right.Participants.InConference';
import styles from './style/Bar.Right.Participants.css';

export function BarRightParticipants() {
  return (
    <div className={styles.participants}>
      <div className={styles.header}>
        Participants
      </div>
      <BarRightParticipantsWaitingRoom />
      <BarRightParticipantsInConference />
    </div>
  );
}
import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import * as api from '@api';
import { Posts } from '@/types';

type Data =
  & Posts.Post
  & Posts.AdditionalDetails;

export default function useFetchPost<TData = Data>(
  postId: number,
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {

  return useQuery(['post', postId], async () => {
    const results = await api.posts.getPost({
      postId,
    });

    return results;
  }, options);
}

export { useFetchPost };
import { SurveyOptionType } from '@enums';
import {
  SurveyQuestionOption,
} from '@/types';
import { Validation } from '../interfaces';

function isExtendedOption(option: SurveyQuestionOption) {
  return option.type !== SurveyOptionType.Default;
}

function assertValidNumberOfOptions(options: SurveyQuestionOption[], min: number, max: number): Validation.AssertionResult {
  const optionLength = (options ?? []).filter(f => !isExtendedOption(f)).length;

  const isInvalid = optionLength < min || optionLength > max;

  if (isInvalid) {
    return {
      success: false,
      message: 'Invalid number of options.',
    };
  }

  return { success: true };
}

function assertNoDuplicateOptions(options: SurveyQuestionOption[]): Validation.AssertionResult {
  const hasDuplicates
    = options
    .some((s, i) =>
      options.some((ss, ii) => ii !== i && s.value === ss.value)
    );

  if (hasDuplicates) {
    return {
      success: false,
      message: 'Duplicate options present.',
    };
  }

  return { success: true };
}

function assertOptionValues(options: SurveyQuestionOption[]): Validation.AssertionResult {
  const hasEmptyValues = options.some(s => !s.value);

  if (hasEmptyValues) {
    return {
      success: false,
      message: 'Option value is empty.',
    };
  }

  return { success: true };
}

export function assertValidOptions(options: SurveyQuestionOption[], min: number, max: number): Validation.AssertionResult {
  const hasNumberOfOptions
    = assertValidNumberOfOptions(options, min, max);

  if (hasNumberOfOptions.success === false) {
    return {
      success: false,
      message: hasNumberOfOptions.message,
    };
  }

  const hasUniqueOptions
    = assertNoDuplicateOptions(options);

  if (hasUniqueOptions.success === false) {
    return {
      success: false,
      message: hasUniqueOptions.message,
    };
  }

  const hasOptionValues
    = assertOptionValues(options);

  if (hasOptionValues.success === false) {
    return {
      success: false,
      message: hasOptionValues.message,
    };
  }

  return { success: true };
}
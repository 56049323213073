import {
  StringParam, QueryParamConfig, encodeString,
  decodeString, useQueryParams, withDefault,
} from 'use-query-params';
import { WorkspaceGlobalSearchFullQueryTab } from '@/types';

const TabParam: QueryParamConfig<WorkspaceGlobalSearchFullQueryTab, WorkspaceGlobalSearchFullQueryTab> = {
  encode: (tab: WorkspaceGlobalSearchFullQueryTab) => encodeString(tab),
  decode: (tab: string) => decodeString(tab) as WorkspaceGlobalSearchFullQueryTab,
};

export default function useSearchFiltersState() {
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    tab: withDefault(TabParam, 'all'),
  });

  return [query, setQuery] as const;
}

export type FiltersQuery = ReturnType<typeof useSearchFiltersState>[0];

export { useSearchFiltersState };
import { useCallback, useMemo, useState } from 'react';
import { SurveyQuestionType } from '@enums';
import { ConjointAnalysisQuestion } from '@/types';
import {
  useSurveyFormQuestionContext,
} from './hooks';
import { ConjointAnalysisFormContext, ConjointAnalysisNavigationContext } from './Context';
import NavigationContainer from './ConjointAnalysis.Navigation';

type Props =
  ChildrenProps;

export const ConjointAnalysisFormContainer = (props: Props) => {
  const {
    answer,
    question,
    setAnswer,
  } = useSurveyFormQuestionContext<SurveyQuestionType.ConjointAnalysis>();

  const [currentSet, setCurrentSet] = useState((answer?.items?.length || 1));

  const back = useCallback(() => {
    return setCurrentSet(s => s - 1);
  }, []);

  const next = useCallback(() => {
    return setCurrentSet(s => s + 1);
  }, []);

  const setSetAnswer = useCallback((setNo: number) => (conceptNo: number) => {

    const set = question.sets[setNo - 1];

    const items = answer?.items || new Array(question.sets.length).fill({ selected: undefined }) as ConjointAnalysisQuestion.RespondentAnswer.SetAnswer[];

    setAnswer({
      items: items.reduce<ConjointAnalysisQuestion.RespondentAnswer.SetAnswer[]>((acc, x, i) => {
        const v = setNo === i + 1
          ? {
            concepts: set.concepts.reduce((acc2, c) => {
              const items = Object.keys(c).map(attributeId => ({
                attributeId: +attributeId,
                levelId: c[+attributeId].id,
              }));
              return [...acc2, items];
            }, [] as ConjointAnalysisQuestion.RespondentAnswer.Concept[]),
            selected: conceptNo,
          }
          : x;
        return [...acc, v];
      }, [] as ConjointAnalysisQuestion.RespondentAnswer.SetAnswer[]),
    });
  }, [
    answer,
    setAnswer,
    question.sets,
  ]);

  const nextDisabled = useMemo(() => {
    return answer?.items[currentSet - 1]?.selected === undefined;
  }, [answer?.items, currentSet]);

  const navigation = {
    back,
    next,
    nextDisabled,
  };

  const form = {
    currentSet,
    setSetAnswer,
  };

  return (
    <ConjointAnalysisFormContext.Provider value={form}>
      <ConjointAnalysisNavigationContext.Provider value={navigation}>
        <NavigationContainer>
          {props.children}
        </NavigationContainer>
      </ConjointAnalysisNavigationContext.Provider>
    </ConjointAnalysisFormContext.Provider>
  );
};

export default ConjointAnalysisFormContainer;
import { UseMutationOptions, useMutation } from 'react-query';
import * as api from '@api';
import * as Branding from '@api/interfaces/groups.branding';
import * as QKey from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';
import { CategoryTheme } from '@/types/branding';

export const useSetColorCategoryThemeMutation = <Context = unknown>(options: SetColorCategoryTheme.Options<Context> = {}) => {
  const group = useSelectGroup();

  const mutation = useMutation([QKey.Groups.Branding.Theming.Put, group.id], (data: SetColorCategoryTheme.Variables) => {
    return api.groups.branding.setColorCategoryTheme({
      categoryId: data.categoryId,
      groupId: group.id,
      paletteId: data.paletteId,
    });
  }, options);

  return mutation;
};

export declare namespace SetColorCategoryTheme {
  export type Variables = CategoryTheme;

  export type Options<Context = unknown> = {
    onMutate?: (variables: Variables) => unknown;
  } & Omit<
      UseMutationOptions<
        Branding.SetColorCategoryTheme.Response,
        unknown,
        Variables,
        Context>, 'onMutate'>;
}
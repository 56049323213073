import { useMemo, useCallback } from 'react';
import { BarTooltipProps } from '@nivo/bar';
import * as chart from '@containers/SurveyResponses/utils';
import { OptionsBarChartTooltip } from '@presentation';
import { SurveyAggregate, SurveyQuestion } from '@/types';
import { OptionsBarData } from './interfaces';
import BarChart from './BarChart';
import { theme } from './Chart.Common';

type Props = {
  colors?: string[];
  question: SurveyQuestion;
  responses: SurveyAggregate.Options.Map;
  onClick: (optionId: number) => unknown;
};

function getDistinctUserCount(question: SurveyQuestion, responses: SurveyAggregate.Options.Map) {
  const allUserIds = question.options.reduce((acc, x) => [...acc, ...responses[x.base.id].userIds], [] as number[]);
  return Array.from(new Set(allUserIds)).length;
}

const ResponseOptionsChart = ({ colors = chart.options.OptionsColors, ...props }: Props) => {
  const count = useMemo(() => getDistinctUserCount(props.question, props.responses), [props.responses, props.question]);
  const emptyValue = useMemo(() => count * 0.0025, [count]);
  const items = [...props.question.options]
  .sort(chart.options.sortOptions(props.question, props.responses))
  .map<OptionsBarData>((option, i) => {
    const data = props.responses[option.base.id];
    return {
      color: colors[option.ordinal - 1],
      count: data.userIds.length,
      id: option.base.id,
      ordinal: option.ordinal,
      name: option.value,
      percent: Math.round(data.pct),
      [option.base.id]: data.userIds.length || emptyValue,
    };
  });

  const formatLabel = useCallback((value: number) => {
    if (value === emptyValue) return '';
    const val = (value / count) * 100;
    const int = parseInt(`${Math.round(val)}`);
    return int < 3
      ? ''
      : `${int}%`;
  }, [count, emptyValue]);

  const formatBottomAxisLabel = useCallback((value: string) => {
    return Number.isInteger(+value)
      ? value
      : '';
  }, []);

  const renderTooltip = useCallback((item: BarTooltipProps<OptionsBarData>) => {
    return (
      <OptionsBarChartTooltip
        count={item.data.count}
        name={item.data.name}
        percent={item.data.percent} />
    );
  }, []);

  const keys = useMemo(() => items.map(x => x.id.toString()), [items]);

  const maxValue = useMemo(() => {
    const max = Math.max(...items.map(x => x[x.id] as number));
    return Math.round(max + (max * 0.1 < 1 ? 1 : max * 0.1));
  }, [items]);

  return (
    <BarChart
      data={items}
      keys={keys}
      minValue={0}
      maxValue={maxValue}
      axisBottom={{
        format: formatBottomAxisLabel,
      }}
      margin={{ top: 0, right: 25, bottom: 30, left: 25 }}
      onClick={props.onClick}
      label={(x => formatLabel(x.value))}
      tooltip={renderTooltip}
      theme={theme} />
  );
};

export { ResponseOptionsChart };
export default ResponseOptionsChart;
import { forwardRef } from 'react';
import { useGroupEntityTags, useGroupEntityTagsUpdate, useGroupEntityTagsLoading } from '@containers/GroupTags';
import { Card, CardContent, Divider,  SidebarHeader } from '@presentation/Main';
import { useConferenceParticipants } from '@utils/api';
import { hasConsultantRole } from '@utils';
import { GroupSidebarTags } from 'components/GroupSidebarTags';
import styles from './style/Sidebar.css';

type Props =
  ICallId;

export const TranscriptSidebar = forwardRef<HTMLDivElement, Props>(({ callId }, ref) => {

  const {
    data: participants,
    isLoading: participantsLoading,
  } = useConferenceParticipants({ callId }, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const tags = useGroupEntityTags();
  const update = useGroupEntityTagsUpdate();
  const tagsLoading = useGroupEntityTagsLoading();

  if (tagsLoading || participantsLoading) return null;

  return (
    <Card ref={ref}>
      <CardContent>
        <SidebarHeader title="Participants" />
        <Divider />
        <div className={styles.participants}>
          {participants
            .filter(f => f.visibility)
            .map(p => {
              const isMember = hasConsultantRole(p);
              return (
                <div
                  key={p.id}
                  className={styles.participant}>
                  {p.profile.fullname}
                  {isMember && <span className={styles.member}>(Member)</span>}
                </div>
              );
            })}
        </div>

        <GroupSidebarTags
          tags={tags}
          onSave={update} />

      </CardContent>
    </Card>
  );
});
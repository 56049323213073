import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import * as api from '@api';
import * as enums from '@enums';
import { ProjectBase } from '@/types';
import { useArchiveProjectPrompt } from 'components/GroupProject/hooks/useArchiveProjectPrompt';

type Props = {
  project: ProjectBase;
};

export const useArchiveGroupProjectPrompt = ({ project }: Props) => {
  const dispatch = useDispatch();

  const archiveProject = useCallback(() => {
    return api.projects.updateStatus({
      projectId: project.id,
      statusId: enums.ProjectStatus.Archived,
    })
    .then(res => {
      dispatch(actions.batchActions([
        actions.projectUpdated({
          projectId: project.id,
          project: {
            projectStatusId: enums.ProjectStatus.Archived,
          },
        }),
        actions.projectPipelineUpdated({
          pipeline: res.pipeline,
          projectId: project.id,
        }),
      ]));
    });
  }, [
    dispatch,
    project.id,
  ]);

  return useArchiveProjectPrompt({
    project,
    onConfirm: archiveProject,
  });
};

export default useArchiveGroupProjectPrompt;
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Info } from 'react-feather';
import { useAggregateReviewSurveyActions } from '@containers';
import * as enums from '@enums';
import { ApprovalActions } from './ApprovalActions';
import { ApprovalStatus } from './ApprovalStatus';
import { ComplianceReviewItem } from '@/types';
import {
  ApprovalNotesModal,
  SurveyApprovalDetailsAnchor as ApprovalDetailsAnchor,
} from 'components/Modal/Approval';
import styles from './style/ComplianceReview.css';

const mapState = (state: Store.State) => state.group;

type Props = {
  item: ComplianceReviewItem<'survey'>;
};

const SurveyApprovalState = (props: Props) => {
  const [option, setOption] = useState<{ approve: boolean; }>(null);
  const group = useSelector(mapState);
  const actions = useAggregateReviewSurveyActions();

  async function handleApprovalIntent(state: boolean) {

    if (!group.features.approvalNotes) {

      const params = {
        item: props.item,
        notes: null,
      };

      state
        ? await actions.approve(params)
        : await actions.reject(params);

      setOption(null);

    } else {
      setOption({ approve: state });
    }

  }

  async function handleSubmit(notes: string | null) {

    const params = {
      item: props.item,
      notes,
    };

    option.approve
      ? await actions.approve(params)
      : await actions.reject(params);

    setOption(null);

  }

  if (props.item.compliance.approvalStatusId === enums.ApprovalStatus.NeedsApproval) {

    return (
      <>
        <ApprovalActions
          onApprove={() => handleApprovalIntent(true)}
          onReject={() => handleApprovalIntent(false)} />
        {option &&
          <ApprovalNotesModal
            approve={option.approve}
            maxLength={500}
            onClose={() => setOption(null)}
            onSubmit={handleSubmit}
            project={props.item.project.name}
            user={props.item.consultant.name} />}
      </>
    );
  }

  return (
    <>
      <ApprovalStatus
        item={props.item.compliance}
        pipeline={props.item.data.pipeline} />
      {props.item.compliance.approvalNotes &&
        <ApprovalDetailsAnchor
          approvedBy={props.item.compliance.approvalBy.name}
          approvedOn={props.item.compliance.approvalOn}
          notes={props.item.compliance.approvalNotes}
          project={props.item.project.name}
          statusId={props.item.compliance.approvalStatusId}
          user={props.item.consultant.name}>
          <Info
            className={styles.notes}
            size={20} />
        </ApprovalDetailsAnchor>}
    </>
  );
};

export { SurveyApprovalState };
export default SurveyApprovalState;
import { useCallback } from 'react';
import { useIsEditingSurvey } from '@containers/SurveyBuilder/hooks';
import { SurveyTemplateEntityType } from '@enums/survey.template';
import { cx } from '@utils';
import {
  TemplateCompetitor,
  TemplateTarget,
  TemplateEvaluationCriteria,
  TemplatePartnershipCriteria,
  TemplateTargetRole,
} from '@presentation/SurveyPreview/Template';
import { QuestionOptionProps, QuestionRowProps } from './interfaces';
import styles from './style/QuestionOption.css';

export const QuestionOption = (props: QuestionOptionProps) => {

  const editing = useIsEditingSurvey();

  const renderValue = useCallback(() => {

    switch (props.item.metadata.template.linkedEntity?.type) {
      case SurveyTemplateEntityType.Target:
        return (
          <TemplateTarget
            disabled={editing}
            value={props.item.value} />
        );

      case SurveyTemplateEntityType.Competitor:
        return (
          <TemplateCompetitor
            disabled={editing}
            value={props.item.value} />
        );

      case SurveyTemplateEntityType.EvaluationCriteria:
        return (
          <TemplateEvaluationCriteria
            disabled={editing}
            value={props.item.value} />
        );

      case SurveyTemplateEntityType.PartnershipCriteria:
        return (
          <TemplatePartnershipCriteria
            disabled={editing}
            value={props.item.value} />
        );

      case SurveyTemplateEntityType.TargetRole:
        return (
          <TemplateTargetRole
            disabled={editing}
            value={props.item.value} />
        );

      default:
        return (
          <>
            {props.item.value}
            {props.children}
          </>
        );
    }

  }, [
    editing,
    props.item.value,
    props.item.metadata,
    props.children,
  ]);

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.bullet}>{`A${props.item.ordinal}`}</div>
      <div className={styles.value}>
        {renderValue()}
      </div>
    </div>
  );
};

export const QuestionRow = (props: QuestionRowProps) => {

  const editing = useIsEditingSurvey();

  const renderValue = useCallback(() => {

    if (props.item.metadata.template.linkedEntity?.type === SurveyTemplateEntityType.Target) {
      return (
        <TemplateTarget
          disabled={editing}
          value={props.item.value} />
      );
    } else if (props.item.metadata.template.linkedEntity?.type === SurveyTemplateEntityType.Competitor) {
      return (
        <TemplateCompetitor
          disabled={editing}
          value={props.item.value} />
      );
    } else {
      return (
        <>
          {props.item.value}
          {props.children}
        </>
      );
    }

  }, [
    editing,
    props.item.value,
    props.item.metadata,
    props.children,
  ]);

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.bullet}>{`R${props.item.ordinal}`}</div>
      <div className={styles.value}>
        {renderValue()}
      </div>
    </div>
  );
};

import { useCallback, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Hamburger as AppBarHamburger } from 'components/AppBar/Drawer/Hamburger';
import { MenuContext } from './Context';
import styles from './style/Drawer.css';

type Props = unknown;

export const Hamburger = (props: Props) => {
  const { open, ref, setOpen } = useContext(MenuContext);
  const location = useLocation();

  const handleClick = useCallback((e: MouseEvent) => {

    const anchorClick = ref.current && ref.current.contains(e.target as Node);

    if (!anchorClick) {
      setOpen(false);
    }

  }, [
    ref,
    setOpen,
  ]);

  const toggleDrawer = useCallback(() => {
    setOpen(!open);
  }, [
    open,
    setOpen,
  ]);

  useEffect(() => {

    if (open) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };

  }, [
    handleClick,
    open,
  ]);

  useEffect(() => {

    setOpen(false);

  }, [
    location.pathname,
    setOpen,
  ]);

  return (
    <AppBarHamburger
      className={styles.hamburger}
      onClick={toggleDrawer}
      open={open}
      ref={ref} />
  );
};

Hamburger.displayName = 'Menu.Hambuger';
import { useTemplateCompetitorsModal } from 'components/Survey.Templates/Modal.Competitors';
import { TemplateItemLabel } from './Template.Label';
import styles from './style/Template.Label.css';

type Props = {
  disabled: boolean;
  value: string;
};

export const TemplateCompetitor = ({ disabled, value }: Props) => {

  const [toggleModal, Modal] = useTemplateCompetitorsModal();

  return (
    <>
      <TemplateItemLabel
        className={styles.competitor}
        disabled={disabled}
        label={value}
        onClick={toggleModal} />
      {!disabled && <Modal />}
    </>
  );
};
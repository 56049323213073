import { useProjectActions, useProjectActionsValidity } from '@containers/GroupProject/hooks';
import { useProjectState } from '@containers/GroupProject/hooks';
import { ProjectActionsContextMenuPopper } from 'components/GroupProject/ProjectActionsContextMenu';
import { Share } from 'components/ShareLink';
import styles from './style/ProjectActions.css';
import { SurveyResponseFilterContainer } from './SurveyResponseFilter';

const ProjectActions = () => {
  const { share } = useProjectActions();
  const { canShare } = useProjectActionsValidity();
  const project = useProjectState();

  return (
    <div className={styles.root}>
      {!project.project?.isExternal && <SurveyResponseFilterContainer /> }
      {canShare &&
        <div className={styles.share}>
          <Share
            onClick={share} />
        </div>}
      <ProjectActionsContextMenuPopper excluded={['share']} />
    </div>
  );
};

export default ProjectActions;
import * as api from '@api';
import { useAsyncStateLazy } from '@utils';

export function useFetchStripeDashboardUrl() {
  const [response, fetch] = useAsyncStateLazy((userId: number) => {
    return api.payout.getStripeDashboardUrl({ userId });
  });

  return [response, fetch] as const;
}

export default useFetchStripeDashboardUrl;
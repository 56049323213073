import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelectUser, useSelectPayoutSettings } from '@containers';
import { path } from '@consts';
import { PayoutMethod } from '@enums';
import { UserPayoutSettings } from '@/types';
import { useFetchStripeDashboardUrl } from '@utils/api';
import { AnimatedLoader } from 'components/ActivityIndicator';
import { AddressDisplayFullAddress, FullAddress } from 'components/AddressDisplay';
import Button from 'components/Button';
import Edit from 'components/icons/Edit';
import { StripeDashboardButton } from 'components/Stripe';
import styles from './style/ReceivePayouts.css';

export default function ReceivePayouts() {
  const settings = useSelectPayoutSettings();

  const hasMethod =
    useMemo(() => [PayoutMethod.Check, PayoutMethod.PayPal, PayoutMethod.Stripe].includes(settings.method), [settings.method]);

  return !hasMethod
    ? <Add />
    : <Existing settings={settings} />;
}

function Add() {
  return (
    <div className={styles.content}>
      <div className={styles.text}>Set up a payout account to receive</div>
      <div className={styles.text}>compensation for projects you engage in.</div>
      <Button
        className={styles.add}
        variant="brick"
        to={path.Settings.UserBillingNewPayoutMethod}
        title="Add Account" />
    </div>
  );
}

function Existing({ settings }: { settings: UserPayoutSettings; }) {
  return settings.method === PayoutMethod.Stripe
    ? <ExistingStripe />
    : settings.method === PayoutMethod.Check
      ? <ExistingCheck address={settings.address} />
      : settings.method === PayoutMethod.PayPal
        ? <ExistingPayPal account={settings.account} />
        : null;
}

function ExistingCheck({ address }: { address: AddressDisplayFullAddress; }) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(path.Settings.UserBillingUpdatePayoutMethod);
  }, [history]);

  return (
    <div className={styles.check}>
      <div>
        <div className={styles.checkLabel}>Check Mailing Address</div>
        <FullAddress address={address} />
      </div>
      <div className={styles.edit}>
        <Edit size={12} onClick={handleClick} />
      </div>
    </div>
  );
}

function ExistingStripe() {
  const user = useSelectUser();
  const [response, fetch] = useFetchStripeDashboardUrl();

  useEffect(() => {
    fetch(user.id);
  }, [fetch, user?.id]);

  if (response.loading) {
    return (
      <div className={styles.stripe}>
        <div className={styles.loading}>
          <AnimatedLoader />
        </div>
      </div>
    );
  }

  const url = response.value?.url;

  return (
    <div className={styles.stripe}>
      <div className={styles.stripeLabel}>Manage your existing account through the Stripe Dashboard.</div>
      <StripeDashboardButton stripeDashboardUrl={url} />
    </div>
  );
}

function ExistingPayPal({ account }: { account: string; }) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(path.Settings.UserBillingUpdatePayoutMethod);
  }, [history]);

  return (
    <>
      <div className={styles.paypal}>
        <a href="https://www.paypal.com" rel="noopener noreferrer" target="_blank">
          <img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png" alt="PayPal Logo" />
          <div className={styles.paypalAccount}>{account}</div>
        </a>
        <Button
          className={styles.update}
          variant="brick"
          onClick={handleClick}>Update
        </Button>
      </div>
    </>
  );
}

export { ReceivePayouts };
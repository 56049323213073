import { useRecentProfiles } from '@utils/api';
import {
  AskAnExpertContext,
  AskAnExpertLoadingContext,
} from './Context';

type Props =
  ChildrenProps;

export const AskAnExpertContainer = (props: Props) => {
  const profiles = useRecentProfiles({
    notifyOnChangeProps: ['data', 'isLoading'],
  });

  const items = profiles.data ? profiles.data.slice(0, 3) : [];

  return (
    <AskAnExpertContext.Provider value={items}>
      <AskAnExpertLoadingContext.Provider value={profiles.isLoading}>
        {props.children}
      </AskAnExpertLoadingContext.Provider>
    </AskAnExpertContext.Provider>
  );
};

export default AskAnExpertContainer;
import { useCallback } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { useToggle } from '@utils/hooks';
import { DeleteQuestionAlert } from 'components/SurveyBuilder.Question/DeleteQuestionAlert';
import { QuestionBuilderDeleteContext, useQuestionBuilderItemContext } from './Context';

type Props =
  ChildrenProps;

export const QuestionBuilderDeleteContainer = (props: Props) => {

  const [_, dispatch] = useSurveyBuilderState();
  const item = useQuestionBuilderItemContext();

  const [showAlert, toggleAlert] = useToggle(false);

  const confirmDelete = useCallback(() => {
    dispatch({
      type: 'remove-question',
      identifier: item.base.identifier,
    });
    toggleAlert();
  }, [dispatch, toggleAlert, item.base.identifier]);

  return (
    <>
      <QuestionBuilderDeleteContext.Provider value={toggleAlert}>
        {props.children}
      </QuestionBuilderDeleteContext.Provider>
      {showAlert &&
        <DeleteQuestionAlert
          open={true}
          onConfirm={confirmDelete}
          onClose={toggleAlert} />
      }
    </>
  );
};
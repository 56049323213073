import { useCallback, useMemo } from 'react';
import { useHasUncommittedQuestionChanges, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { SurveyQuestion, SurveyResponseTagging } from '@/types';
import { QuestionTaggingBuilder } from 'components/SurveyBuilder.Question.Tagging/QuestionTaggingBuilder';
import { QuestionTaggingBuilderContext, QuestionTaggingBuilderState } from './Context';
import { QuestionTaggingBuilderContainerProps } from './interfaces';

type Props
  = QuestionTaggingBuilderContainerProps;

export default function QuestionTaggingBuilderContainer({ item }: Props) {
  const [state, dispatch] = useSurveyBuilderState();
  const hasUncommittedChanges = useHasUncommittedQuestionChanges();

  const canSave = useMemo(() => hasUncommittedChanges(), [hasUncommittedChanges]);

  const handleSubmit = useCallback(() => {
    dispatch({
      type: 'toggle-logic-editing',
      ordinal: null,
    });
  }, [dispatch]);

  const revertUncommittedChanges = useCallback(() => {
    dispatch({
      type: 'revert-uncommitted-changes',
    });
  }, [dispatch]);

  const updateQuestionResponseTagging = useCallback((data: SurveyResponseTagging[]) => {
    dispatch({
      type: 'update-question',
      item: {
        ...item,
        tagging: {
          ...item.tagging,
          response: data,
        },
      } as SurveyQuestion,
    });
  }, [item, dispatch]);

  const value: QuestionTaggingBuilderState = {
    item,
    updateQuestionResponseTagging,
  };

  return (
    <QuestionTaggingBuilderContext.Provider value={value}>
      <QuestionTaggingBuilder
        canSave={canSave}
        item={item}
        onCancel={revertUncommittedChanges}
        onSubmit={handleSubmit} />
    </QuestionTaggingBuilderContext.Provider>
  );
}

export { QuestionTaggingBuilderContainer };
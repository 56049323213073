import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import * as utils from '@utils';
import { MemberBaseballCard } from 'components/BaseballCard';
import { InteractionsTableItem, CallParticipant } from './interfaces';
import styles from './style/Call.Interaction.Participants.css';

export default function Participants({ row: { original: data } }: Cell<InteractionsTableItem>) {
  const Participant = useCallback((p: CallParticipant) => {
    if (p.isExpert) {
      const slug = utils.slugify({ name: p.name, id: p.userId });
      const to = utils.getLocationFor.user.profile({ slug });
      return (
        <MemberBaseballCard.Client key={p.userId} userId={p.userId} projectId={data.metadata.project.id}>
          <Link to={to}>
            <div className={styles.name}>{p.name}</div>
          </Link>
        </MemberBaseballCard.Client>);
    } else {
      return (<div key={p.userId} className={styles.name}>{p.name}</div>);
    }
  }, [data.metadata.project.id]);
  return (
    <div className={styles.root}>
      {data.metadata.participants.sort((a, b) => a.isExpert ? -1 : 1).map(p => (<Participant key={p.userId} {...p} />))}
    </div>
  );
}

export { Participants };
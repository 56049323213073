import { useCallback, useEffect } from 'react';
import { ConnectedAccountProvider, ConnectedAccountType } from '@enums';
import { useConnectUserAccount } from '@utils/api';
import {
  ProviderSyncProps,
  SyncProps,
} from 'components/ConnectAccount/interfaces';
import { useConnectedAccountContext } from '../Context';

const Scopes = {
  Calendar: 'https://www.googleapis.com/auth/calendar.events',
  Contacts: 'https://www.google.com/m8/feeds/',
  Files: 'https://www.googleapis.com/auth/drive',
};

export const useGoogleSync = ({
  onConnected,
  scopes,
  type,
}: ProviderSyncProps) => {
  const params = {
    provider: ConnectedAccountProvider.Google,
    type,
  };
  const [loading, setLoading] = useConnectedAccountContext(params);

  const { mutateAsync } = useConnectUserAccount(params, {
    onSuccess: () => {
      setLoading(false);
      onConnected?.();
    },
    onError: () => setLoading(false),
  });

  useEffect(() => {
    if (window.gapi?.load) {
      window.gapi.load('auth2', () => setLoading(false));
    } else {
      document.querySelector('#google-js').addEventListener('load', () => {
        window.gapi.load('auth2', () => setLoading(false));
      });
    }
  }, []);

  const connect = useCallback(() => {
    setLoading(true);

    window.gapi.auth2.authorize({
      client_id: process.env.GOOGLE_SYNC_CLIENT_ID,
      scope: scopes,
      prompt: 'consent',
      response_type: 'code',
    },
    resp => {
      if (resp.error) {
        return setLoading(false);
      }

      return mutateAsync({
        code: resp.code,
      });

    });
  }, [
    mutateAsync,
    setLoading,
    scopes,
  ]);

  return {
    connect,
    isLoading: loading,
  };
};

export const useGoogleContactSync = (props?: SyncProps) => {
  const scopes = ['email', 'profile', Scopes.Contacts].join(' ');

  return useGoogleSync({
    onConnected: props?.onConnected,
    scopes,
    type: ConnectedAccountType.Contacts,
  });

};

export const useGoogleCalendarSync = (props?: SyncProps) => {
  const scopes = ['email', 'profile', Scopes.Calendar].join(' ');

  return useGoogleSync({
    onConnected: props?.onConnected,
    scopes,
    type: ConnectedAccountType.Calendar,
  });

};

export const useGoogleDriveSync = (props?: SyncProps) => {
  const scopes = ['email', 'profile', Scopes.Files].join(' ');

  return useGoogleSync({
    onConnected: props?.onConnected,
    scopes,
    type: ConnectedAccountType.Files,
  });
};
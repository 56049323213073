import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, UseMutationOptions } from 'react-query';
import { ConnectedAccountProvider, ConnectedAccountType } from '@enums';
import * as actions from '@actions';
import * as api from '@api';
import * as API from '@api/interfaces';

type Data = API.Users.ConnectAccount.Response;
type Variables = {
  code: string;
};

type Props = {
  provider: ConnectedAccountProvider;
  type: ConnectedAccountType;
};

const selectConnectedAccounts = (state: Store.State) => state.user.connected;

type Options = UseMutationOptions<Data, AxiosResponse, Variables>;

export const useConnectUserAccount = ({
  provider,
  type,
}: Props, options?: Options) => {

  const dispatch = useDispatch();
  const connectedAccounts = useSelector(selectConnectedAccounts);

  return useMutation('connect-user-account', ({ code }: Variables) => {

    return api.users.connectAccount({
      code,
      provider,
      type,
    });

  }, {
    ...options,
    onSuccess: (res, variables, context) => {
      if (res.success) {
        dispatch(actions.connectedAccountsChanged({
          connected: connectedAccounts.concat(res.connection),
        }));
        options?.onSuccess?.(res, variables, context);
      } else {
        options?.onError?.(null, variables, context);
      }
    },
  });
};
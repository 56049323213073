import { useMemo } from 'react';
import * as consts from '@consts';
import { ProjectStatus } from '@enums';
import { Card, CardContent, Divider, GroupCardHeader, ViewMoreFooter } from '@presentation/Main';
import { GroupTableHeaderButton } from '@presentation/GroupTable';
import { getLocationFor } from '@utils';
import { useProjectsTableItems } from 'components/GroupProject/hooks/useProjectsTableItems';
import ActiveProjectsTable from './ActiveProjectsTable';
import styles from './style/ActiveProjectsPanel.css';

const MaxProjects = 5;

function EmptyState() {
  return (
    <div className={styles.empty}>
      <div className={styles.title}>No active projects</div>
      <div className={styles.text}>Create a project to get started</div>
    </div>
  );
}

export const ActiveProjectsPanel = () => {

  const data = useProjectsTableItems({
    status: ProjectStatus.Active,
  });

  const showMore = useMemo(() => data.length > MaxProjects, [data.length]);

  return (
    <Card>
      <GroupCardHeader className={styles.header}>
        <div>Active Projects</div>
        <GroupTableHeaderButton
          title="Create New"
          to={getLocationFor.project.create()} />
      </GroupCardHeader>
      <CardContent fluid>
        {!!data.length &&
          <ActiveProjectsTable
            data={data}
            maxItems={MaxProjects} />}
        {!data.length && <Divider />}
        {!data.length && <EmptyState />}
      </CardContent>
      {showMore && <ViewMoreFooter to={consts.path.Projects.Root} />}
    </Card>
  );
};

export default ActiveProjectsPanel;
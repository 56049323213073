import http from '@services/http';
import { Payout } from '@services/api/interfaces';

export const getStripeAuthorizeUrl = (
  data: Payout.GetStripeAuthorizeUrl.Request,
): Promise<Payout.GetStripeAuthorizeUrl.Response> => {
  return http.get(`/payout/${data.userId}/stripe-authorize`);
};

export const stripeAuthorizeConnect = (
  data: Payout.AuthorizeStripeConnect.Request,
): Promise<Payout.AuthorizeStripeConnect.Response> => {
  const { userId, ...rest } = data;
  return http.post(`/payout/${userId}/stripe-authorize`, rest);
};

export const getStripeDashboardUrl = (
  data: Payout.GetStripeDashboardUrl.Request,
): Promise<Payout.GetStripeDashboardUrl.Response> => {
  return http.get(`/payout/${data.userId}/stripe-dashboard`);
};

export const saveMethod = (
  data: Payout.SaveMethod.Request,
): Promise<Payout.SaveMethod.Response> => {
  const { userId, ...rest } = data;
  return http.post(`/payout/${userId}/method`, rest);
};
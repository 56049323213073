import TextEntryListItem from './TextEntryListItem';
import { TextEntryListProps } from './interfaces';

export const TextEntryList = (props: TextEntryListProps) =>  {
  const renderOptions = () => {
    return props.items.map(item => (
      <TextEntryListItem
        key={item.key}
        item={item}
        onRemove={props.removeItem(item.key)}
        onUpdate={props.updateItemValue(item.key)}
        placeholder={props.placeholder}
        removeEnabled={props.removeItemEnabled}
        renderLabel={props.renderLabel} />
    ));
  };

  return (
    <div className={props.className}>
      {renderOptions()}
      {props.renderAddItemButton?.()}
    </div>
  );
};

export default TextEntryList;
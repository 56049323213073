import { useMemo } from 'react';
import * as enums from '@enums';
import { useSelectUser } from '@containers/Store';
import { SettingsTitle } from 'components/Settings';
import { ExportUsageButton } from './ExportUsageButton';
import styles from './style/ExportComplianceReport.css';

export const ExportData = () => {
  const user = useSelectUser();

  const hasAccess = useMemo(() => {
    const required = [enums.Role.FirmCompliance, enums.Role.InternalAdmin];
    return user.roles.some(role => required.includes(role));
  }, [user.roles]);

  if (!hasAccess) return null;

  return (
    <div className={styles.root}>
      <SettingsTitle title="Export System Data" />
      <div className={styles.main}>
        <div className={styles.subtitle}>Export a full download of survey and call activity on the platform.</div>
        <div className={styles.content}>
          <ExportUsageButton />
        </div>
      </div>
    </div>
  );
};

export default ExportData;
export const Blog = 'https://www.vancery.com/insights';
export const FAQ = 'https://vancery.zendesk.com';

export const AppStoreUrl = 'https://appstore.com/vancery';
export const AppStoreViewUrl = 'https://apps.apple.com/us/app/vancery/id1487739850';
export const PlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.vancery';

export const LinkedIn = 'https://www.linkedin.com/company/vancery/';
export const Twitter = 'https://twitter.com/vancery_';
export const YouTube = 'https://www.youtube.com/channel/UCjGAL21qJWUtr_KN7ttAqmQ';
export const Facebook = 'https://www.facebook.com/Vancery.nyc/';

export const StripeConnectedAccountAgreementUrl = `https://stripe.com/connect-account/legal`;
export const StripeServicesAgreementUrl = `https://stripe.com/legal`;

export const SnowflakeReport = 'https://go.vancery.com/snowflake';
export const SurveyBestPractices = 'https://go.vancery.com/survey-best-practices';
export const UltimateProductResearchGuide = 'https://go.vancery.com/product-research-guide';
import { memo } from 'react';
import ComputerIcon from '@mui/icons-material/Computer';
import { SelectOptionProps } from 'components/Select/interfaces';
import { Camera } from './Camera';
import { CameraPreview } from './Camera.Preview';
import { Microphone } from './Microphone';
import { Visibility } from './Visibility';
import styles from './style/Tab.Desktop.css';

type Props = {
  cameraVisible?: boolean;
  cameraValue?: number;
  disabledCamera?: boolean;
  microphoneValue?: number;
  disabledMicrophone?: boolean;
  disabledVisibility?: boolean;
  visibilityValue?: number;
  visibilityVisible?: boolean;
  showCameraPreview?: boolean;
  onSelectCamera: (value: SelectOptionProps) => void;
  onSelectMicrophone: (value: SelectOptionProps) => void;
  onSelectVisibility: (value: SelectOptionProps) => void;
};

export const Desktop = memo((props: Props) => {
  return (
    <div className={styles.root}>
      <Microphone
        className={styles.row}
        value={props.microphoneValue}
        disabled={props.disabledMicrophone}
        onSelect={props.onSelectMicrophone} />
      <Camera
        className={styles.row}
        value={props.cameraValue}
        disabled={props.disabledCamera}
        visible={props.cameraVisible}
        onSelect={props.onSelectCamera} />
      {props.showCameraPreview && <CameraPreview className={styles.row} />}
      <Visibility
        className={styles.row}
        value={props.visibilityValue}
        disabled={props.disabledVisibility}
        visible={props.visibilityVisible}
        onSelect={props.onSelectVisibility} />
    </div>
  );
});

export const DesktopTitle = memo(() => (
  <div className={styles.desktopTitle}>
    <ComputerIcon />
    Join By Desktop
  </div>
));
import { Link } from 'react-router-dom';
import { getLocationFor } from '@utils';
import { UserProfileLinkProps } from './interfaces';
import styles from '../style/ProjectPipelineItem.css';

type Props = UserProfileLinkProps;

const UserProfileLink = (props: Props) => {
  const url = getLocationFor.user.profile({ slug: props.slug });

  return (
    <div className={styles.name}>
      <Link
        className={styles.link}
        to={url}>
        {props.text}
      </Link>
    </div>
  );
};

export default UserProfileLink;
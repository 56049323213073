import { useCallback } from 'react';
import * as api from '@api';
import useSearchLoadingDispatch from './useSearchLoadingDispatch';
import useSearchResultsDispatch from './useSearchResultsDispatch';

export default function useSearchFetchFolders() {
  const setResults = useSearchResultsDispatch();
  const setLoading = useSearchLoadingDispatch();

  const folders = useCallback(async ({ query, size }: FetchData) => {
    const result = await api.workspaces.globalSearch.folders({
      query,
      size,
    });

    if (result) {
      setResults(prev => ({
        ...prev,
        folders: result,
      }));
    }
  }, [setResults]);

  const fetch = useCallback(({ query, size }: FetchData) => {
    setLoading(prev => ({ ...prev, folders: { ...prev.folders, loading: true }}));
    Promise.all([
      new Promise<void>(resolve => setTimeout(() => resolve(), 500)),
      folders({ query, size }),
    ])
    .then(() => {
      setLoading(prev => ({ ...prev, folders: { loading: false }}));
    });
  }, [folders, setLoading]);

  return fetch;
}

export { useSearchFetchFolders };

type FetchData = {
  query: string;
  size: number;
}
import { cx } from '@utils';
import styles from './style/ChartWrapper.css';

type Props = {
  className?: string;
  style?: React.CSSProperties;
} & ChildrenProps;

export const ChartWrapper = ({ children, className, style }: Props) => {
  return (
    <div className={cx(styles.root, className)} style={style}>
      {children}
    </div>
  );
};

export default ChartWrapper;
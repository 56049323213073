import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isValidEmail } from '@utils';
import * as api from '@api';
import EmailInvite from 'components/EmailInvite';
import Modal from 'components/Modal/Modal.Legacy';
import ShareByLink from './ShareByLink';
import { ProjectLink, FromExpertProps } from './interfaces';
import styles from './style.css';

const mapState = (state: Store.State) => ({
  projects: state.projects,
});

type Props =
    FromExpertProps;

const ShareProjectFromExpert = (props: Props) => {
  const { projects } = useSelector(mapState);

  const [email, setEmail] = useState<string>('');
  const [recipients, setRecipients] = useState<string[]>([]);
  const [invitationMessage, setInvitationMessage] = useState<string>('');

  const [link, setLink] = useState<ProjectLink>(null);

  const project = projects[props.projectId];

  useEffect(() => {
    api.projects.links.get({
      projectId: project.id,
    })
    .then(setLink);
  }, [project.id]);

  const sendEmailInvite = () => {
    api.projects.links.sendConsultantRefererInviteEmail({
      message: invitationMessage,
      projectId: project.id,
      recipients,
    }).then(_ => {
      props.onClose();
    });
  };

  const handleSubmitNewEmail = () => {
    if (isValidEmail(email)) {
      if (!recipients.includes(email)) {
        setRecipients([...recipients, email]);
        setEmail('');
      }
    }
  };

  const handleRemoveRecipient = (recipient: string) => {
    setRecipients(recipients.filter(f => f!== recipient));
  };

  const handleEmailChange = (email: string, submit?: boolean) => {
    if (submit) {
      setEmail('');
      setRecipients([...recipients, email]);
    }
    else {
      setEmail(email);
    }
  };

  return (
    <Modal
      onClose={props.onClose}
      visible={true}>
      <div className={styles.root}>
        <h1
          className={styles.header}>
          {`Invite contacts to the ${project.name} project`}
        </h1>
        <ShareByLink
          copyDisabled={false}
          link={link} />
        <div className={styles.label}>Or send an invite to your contacts</div>
        <EmailInvite
          email={email}
          message={invitationMessage}
          recipients={recipients}
          onEmailChange={handleEmailChange}
          onEmailSubmit={handleSubmitNewEmail}
          onMessageChange={setInvitationMessage}
          onRemoveRecipient={handleRemoveRecipient}
          onSend={sendEmailInvite}
          sendEnabled={invitationMessage !== '' && recipients.length > 0} />
      </div>
    </Modal>
  );
};

export { ShareProjectFromExpert };
export default ShareProjectFromExpert;
import { useCallback, useMemo } from 'react';
import Prompt from 'components/Modal/Prompt';
import { ButtonSet } from 'components/Modal/ButtonSet';
import { Button } from 'components/Button';
import { UserPromptProps } from './interfaces';
import styles from './style/Prompt.css';

type Props =
  UserPromptProps;

export const HideUserPrompt = ({
  onClose,
  onConfirm,
  project,
  user,
  visible,
}: Props) => {

  const header = useMemo(() => {
    return `Hide ${user.profile.firstName} from ${project.name} project?`;
  }, [
    project.name,
    user.profile.firstName,
  ]);

  const body = useMemo(() => `If you hide this user they will be marked as Inactive in the project view.`, []);

  const Footer = useCallback(() => {
    return (
      <ButtonSet>
        <Button.Destructive
          className={styles.btn}
          onClick={onClose}
          variant="brick">
          Cancel
        </Button.Destructive>
        <Button.Primary
          className={styles.btn}
          onClick={onConfirm}
          variant="brick">
          Hide
        </Button.Primary>
      </ButtonSet>
    );
  }, [
    onClose,
    onConfirm,
  ]);

  return (
    <Prompt
      body={body}
      footer={Footer()}
      header={header}
      onClose={onClose}
      visible={visible} />
  );
};

export default HideUserPrompt;
import styles from './style/Base.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
};

const Body = ({ className = '', ...props }: Props) => {
  return (
    <div className={`${styles.content} ${className}`}>
      {props.children}
    </div>
  );
};

export { Body };
export default Body;
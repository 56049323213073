import { useCallback, memo } from 'react';
import MicOnIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { cx } from '@utils';
import { Select, SelectOptionProps } from 'components/Select';
import styles from './style/Microphone.css';

type Props = {
  className?: string;
  value: number;
  disabled?: boolean;
  onSelect: (value: SelectOptionProps) => void;
};

export const Microphone = memo(({ className, value, onSelect, disabled = false }: Props) => {
  const getOptions = useCallback((): SelectOptionProps[] => {
    return [
      MutedOnJoinOption,
      UnmutedOnJoinOption,
    ];
  }, []);

  const options = getOptions();
  const valueOption = options.find(o => o.id === value);
  const unmuted = value === UnmutedOnJoinOption.id;

  return (
    <div className={cx(className)}>
      <div className={styles.header}>
        <div className={styles.optionLabel}>Microphone</div>
        <div className={cx(styles.microphone, { [styles.off]: !unmuted })}>
          {unmuted ? <MicOnIcon /> : <MicOffIcon />}
        </div>
      </div>
      <div>
        {disabled
          ? (
            <div className={styles.optionDisabled}>
              {valueOption?.name}
            </div>
          )
          : (
            <Select
              className={styles.select}
              value={valueOption?.name}
              getItemValue={x => x.name}
              onSelect={onSelect}
              options={options} />
            )}
      </div>
    </div>
  );
});

export const MutedOnJoinOption: SelectOptionProps = { id: 1, name: 'Muted on Join' };
export const UnmutedOnJoinOption: SelectOptionProps = { id: 2, name: 'Unmuted on Join' };

import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import pickerCSS from 'react-datepicker/dist/react-datepicker.css';
import { CalendarPickerHeader } from 'components/CalendarPicker/CalendarPickerHeader';
import { CalendarPickerBaseProps, CalendarPickerHeaderParams} from './interfaces';

type Props =
  CalendarPickerBaseProps;

const CalendarPickerBase = (props: Props ) => {
  useEffect(() => {
    pickerCSS.use();

    return () => pickerCSS.unuse();
  }, []);

  return (
    <DatePicker {...props} />
  );
};

function renderCustomHeader(params: CalendarPickerHeaderParams) {
  return <CalendarPickerHeader {...params} />;
}

const defaultProps = {
  renderCustomHeader,
};

CalendarPickerBase.defaultProps = defaultProps;

export { CalendarPickerBase };
export default CalendarPickerBase;
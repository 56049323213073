import { useMemo, memo } from 'react';
import Video, { LocalAudioTrack, LocalVideoTrack, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { cx } from '@utils';
import { UserAvatar } from 'components/UserAvatar';
import PushPinIcon from 'components/icons/PushPin';
import { AudioLevelIndicator, isCameraTrack, isScreenTrack, ParticipantTracks, NetworkQualityLevel } from 'components/Conference.Video';
import { useParticipantNetworkQualityLevel, usePublications, useIsTrackSwitchedOff, useTrack } from 'components/Conference.Video/hooks';
import { useParticipantIdentity } from './hooks';
import styles from './style/Session.ParticipantStrip.Participant.css';

type Props = {
  disableAudio?: boolean;
  participant: Video.Participant;
  room: Video.Room;
  onClick: () => void;
  isSelected: boolean;
};

export function SessionParticipantStripParticipant({
  disableAudio,
  participant,
  room,
  onClick,
  isSelected,
}: Props) {

  const publications = usePublications(participant);
  const audioPublication = publications.find(p => p.kind === 'audio');
  const cameraPublication = publications.find(p => isCameraTrack(p.trackName));
  const screenPublication = publications.find(p => isScreenTrack(p.trackName));

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack;
  const isAudioEnabled = audioTrack?.isEnabled;

  const cameraTrack = useTrack(cameraPublication) as LocalVideoTrack | RemoteVideoTrack;
  const isCameraEnabled = cameraTrack?.isEnabled;
  const isCameraSwitchedOff = useIsTrackSwitchedOff(cameraTrack);

  // check this
  const screenTrack = useTrack(screenPublication) as LocalVideoTrack | RemoteVideoTrack;
  const isScreenEnabled = screenTrack?.isEnabled;

  const networkQuality = useParticipantNetworkQualityLevel(participant);

  const identity = useParticipantIdentity({
    twilioIdentity: participant.identity,
    hasAudio: isAudioEnabled,
    hasCamera: isCameraEnabled,
    hasScreen: isScreenEnabled, // check this
  });

  if (!identity.visible) return null;

  const rootClassname = cx(styles.root, { [styles.switchedOff]: isCameraSwitchedOff });
  const infoClassname = cx(styles.info);

  return (
    <div
      className={rootClassname}
      onClick={onClick}>
      <div className={infoClassname}>
        <div className={styles.topleft}>
          <Pinned isSelected={isSelected} />
        </div>
        <div className={styles.topright}>
          <Network level={networkQuality?.level} />
        </div>
        <div className={styles.bottomright}>
          <Name value={identity.name} />
        </div>
        <div className={styles.bottomleft}>
          <AudioLevelIndicator
            icon={identity.type === 'phone' ? 'phone' : 'web'}
            background={identity.type === 'phone' ? 'var(--pri-01)' : 'var(--black)'}
            audioTrack={audioTrack}
            size={20} />
        </div>
      </div>
      {!isCameraEnabled && !isScreenEnabled && <NoVideo pictureUrl={identity.pictureUrl} />}
      {isCameraSwitchedOff && <BandwidthWarning />}
      <ParticipantTracks
        disableAudio={disableAudio}
        participant={participant}
        room={room} />
    </div>
  );
}

type NetworkProps = {
  level: number;
};

const Network = memo(({ level }: NetworkProps) => {
  return (
    <div className={styles.network}>
      <NetworkQualityLevel qualityLevel={level} />
    </div>
  );
});

type NameProps = {
  value: string;
};

const Name = memo(({ value }: NameProps) => {
  if (!value) return null;

  return (
    <div className={styles.name}>
      {value}
    </div>
  );
});

type PinnedProps = {
  isSelected: boolean;
};

const Pinned = memo(({ isSelected }: PinnedProps) => {
  const classname = cx(styles.pinned, { [styles.selected]: isSelected });

  return (
    <div className={classname}>
      <PushPinIcon />
    </div>
  );
});

type NoVideoProps = {
  pictureUrl: string;
};

const NoVideo = memo(({ pictureUrl }: NoVideoProps) => {
  return (
    <div className={styles.noVideo}>
      {pictureUrl
        ? (
          <UserAvatar
            pictureUrl={pictureUrl}
            size={40} />
        )
        : <AccountCircleIcon />}
    </div>
  );
});

const BandwidthWarning = memo(() => {
  return (
    <div className={styles.bandwidthWarning}>
      <div><ErrorOutlineIcon /></div>
      <div className={styles.bandwidthWarningCopy}>
        Insufficient Bandwidth
      </div>
    </div>
  );
});
import { useContext } from 'react';
import { Editor, Range } from 'slate';
import { RichTextMentionsContext } from 'components/RichTextMentions/Context';

const useMentionsPlugin = () => {
  const { setIndex, setSearch, setTarget } = useContext(RichTextMentionsContext);

  const withMentions = <T extends Editor>(editor: T): T => {
    const { isInline, isVoid, onChange } = editor;

    editor.isInline = element => {
      return element.type === 'mention'
        ? true
        : isInline(element);
    };

    editor.isVoid = element => {
      return element.type === 'mention'
        ? true
        : isVoid(element);
    };

    editor.onChange = () => {
      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection);
        const wordBefore = Editor.before(editor, start, { unit: 'word' });
        const before = wordBefore && Editor.before(editor, wordBefore);
        const beforeRange = before && Editor.range(editor, before, start);
        const beforeText = beforeRange && Editor.string(editor, beforeRange);
        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
        const after = Editor.after(editor, start);
        const afterRange = Editor.range(editor, start, after);
        const afterText = Editor.string(editor, afterRange);
        const afterMatch = afterText.match(/^(\s|$)/);

        if (beforeMatch && afterMatch) {
          setTarget(beforeRange);
          setSearch(beforeMatch[1]);
          setIndex(0);
          return;
        }
      }

      setTarget(null);

      return onChange();
    };

    return editor;
  };

  return withMentions;

};

export { useMentionsPlugin };
export default useMentionsPlugin;
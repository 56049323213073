import { io } from 'socket.io-client';
import { FileSocketEvent, LifeCycleSocketEvent, SocketNamespace } from '@enums';
import * as $session from '@services/auth/session';
import { createLogger } from './utils';
import { Files } from './interfaces';

const log = createLogger(SocketNamespace.Files);

const socket = io(`${process.env.BACKEND_WS_URL}/${SocketNamespace.Files}`, {
  path: process.env.BACKEND_WS_PATH,
  auth: {
    'X-CSRF-Token': $session.getCSRF(),
  },
  autoConnect: false,
  withCredentials: true,
  transports: ['websocket', 'polling'],
});

function open() {
  socket.on(LifeCycleSocketEvent.Connect, () => log.event(LifeCycleSocketEvent.Connect));
  socket.on(LifeCycleSocketEvent.ConnectError, data => log.event(LifeCycleSocketEvent.ConnectError, data));
  socket.on(LifeCycleSocketEvent.Disconnect, () => log.event(LifeCycleSocketEvent.Disconnect));

  socket.open();
}

function close() {
  socket.close();

  socket.off(LifeCycleSocketEvent.Connect);
  socket.off(LifeCycleSocketEvent.ConnectError);
  socket.off(LifeCycleSocketEvent.Disconnect);
}

const on = <T extends FileSocketEvent>(event: FileSocketEvent, handler: Files.RegisterHandler<T>) => {
  return socket.on(event, handler);
};

const off = <T extends FileSocketEvent>(event: FileSocketEvent, handler: Files.RegisterHandler<T>) => {
  return socket.off(event, handler);
};

const subscribe = (data: IWorkspaceFileId) => {
  socket.emit('subscribe', data.fileId);
};

const unsubscribe = (data: IWorkspaceFileId) => {
  socket.emit('unsubscribe', data.fileId);
};

const files = {
  close,
  off,
  on,
  open,
  subscribe,
  unsubscribe,
};

export { files };
export default files;
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import * as api from '@api';
import { useFetchFollowedTopics } from '@utils/api';
import { Topics } from '@/types';
import { FollowedTopicsContext } from './Context';

type Props = {
  children: React.ReactNode;
};

export const FollowedTopicsContainer = (props: Props) => {
  const [topics, setTopics] = useState<Topics.Following[]>([]);

  const query = useFetchFollowedTopics();

  useEffect(() => {

    if (!query.isLoading && query.data?.items) {
      setTopics(query.data.items);
    }

  }, [
    query.data?.items,
    query.isLoading,
  ]);

  const follow = useMutation((data: Topics.Following) => {
    return api.topics.followTopic({
      slug: data.slug,
    });
  }, {
    onMutate: variables => {

      setTopics([ ...topics, variables]);

      return variables;
    },
    onError: (e, variables) => {
      const updated = topics.filter(x => x.id !== variables.id);

      setTopics(updated);
    },
  });

  const unfollow = useMutation((data: Topics.Following) => {
    return api.topics.unfollowTopic({
      slug: data.slug,
    });
  }, {
    onMutate: variables => {
      const updated = topics.filter(x => x.id !== variables.id);

      setTopics(updated);

      return variables;
    },
    onError: (e, variables) => {
      setTopics([ ...topics, variables]);
    },
  });

  const context = {
    follow,
    query,
    topics,
    unfollow,
  };

  return (
    <FollowedTopicsContext.Provider value={context}>
      {props.children}
    </FollowedTopicsContext.Provider>
  );
};

FollowedTopicsContainer.displayName = 'FollowedTopics.Container';
import { useCallback, useMemo } from 'react';
import { useProjectState } from '@containers/GroupProject/hooks/useProjectState';
import { ProjectSurveyResponsesStateContext } from '@containers/SurveyResponses/Context';
import { getLocationFor, slugify } from '@utils';
import { useMatchRepeatSurveyStateFromUrl } from './hooks';
import { RepeatSurvey } from './interfaces';

export const RepeatResponsesStateContainer = (props: ChildrenProps) => {
  const [query, setQuery, reset] = useMatchRepeatSurveyStateFromUrl();

  const state = useProjectState();

  const basePath = useMemo(() => {

    const slug = slugify({
      id: `P${state.projectParent.id}`,
      name: state.latestProject.name,
    });

    return getLocationFor.project.surveyResponses({ slug }).pathname;
  }, [state]);

  const setQueryState = useCallback((data: Partial<Omit<RepeatSurvey.QueryState.Value, 'questionId'>>) => {
    setQuery({
      ...data,
      questionId: query.questionId,
    }, 'push');
  }, [query.questionId, setQuery]);

  const value = useMemo(() => ({
    basePath,
    query,
    resetQueryState: reset,
    setQueryState,
  }), [
    basePath,
    query,
    reset,
    setQueryState,
  ]);

  return (
    <ProjectSurveyResponsesStateContext.Provider value={value}>
      {props.children}
    </ProjectSurveyResponsesStateContext.Provider>
  );
};

export default RepeatResponsesStateContainer;
import { useContext, useMemo } from 'react';
import { CompanyCurrentEmployeesContext } from '@containers/Company/Context';
import { useInfiniteWindowScroll } from '@utils';
import ActivityIndicator from 'components/ActivityIndicator';
import { Employees } from './Panel';

type Props = unknown;

export function CurrentEmployees(props: Props) {
  const { query, ready } = useContext(CompanyCurrentEmployeesContext);

  useInfiniteWindowScroll({
    canFetch: query.hasNextPage && !query.isFetchingNextPage,
    fetchNext: query.fetchNextPage,
    bufferSize: 0.3,
  });

  const items = useMemo(() => query.data?.pages.flatMap(p => p.items), [query.data?.pages]);

  if (!ready) return <ActivityIndicator show />;

  return <Employees items={items} />;
}
import { Fragment, useCallback } from 'react';
import FormGroup from '@mui/material/FormGroup';
import { CheckboxLabel } from './CheckboxLabel';
import { CheckboxGroupProps, CheckboxGroupItem } from './interfaces';
import styles from './style/CheckboxGroup.css';

type Props =
  CheckboxGroupProps;

export default function CheckboxGroup({ className, checkedItems, items, onToggle, onInputChange }: Props) {
  const renderItem = useCallback((item: CheckboxGroupItem) => {
    const onChange = () => onToggle({ id: item.id });
    const checked = checkedItems.includes(item.id);

    return (
      <CheckboxLabel
        checked={checked}
        label={(checked && item.allowText
            ? <input
                className={styles.text}
                name={`${item.id}-text`}
                type="text"
                onChange={e => onInputChange({ id: item.id, text: e.target.value })}
                placeholder={`Enter additional text`}
                defaultValue={item.text ?? ''} />
            : item.label)}
        onChange={onChange}
        style={{ marginBottom: 15 }} />
    );
  }, [checkedItems, onToggle, onInputChange]);

  return (
    <FormGroup className={className}>
      {items.map(item => (
        <Fragment key={item.id}>
          {renderItem(item)}
        </Fragment>
      ))}
    </FormGroup>
  );
}

export { CheckboxGroup };
import { Redirect } from 'react-router-dom';
import { pathname } from '@consts';
import { useSelectPayoutSettings } from '@containers/Store';
import * as PayoutSetup from '@screens/PayoutSetup';
import { useRequestCall } from './useCall';
import styles from './style/Payment.css';

export const AdHocCallPayoutSetup = () => {

  const call = useRequestCall();

  const payoutMethod = useSelectPayoutSettings();

  if (!call.request.accepted) {
    return <Redirect to={pathname.Home} />;
  }

  return (
    <div className={styles.wrap}>
      <PayoutSetup.Stepper.AdHocCall
        hasPayoutMethod={!!payoutMethod.method} />
    </div>
  );
};

export default AdHocCallPayoutSetup;
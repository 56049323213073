import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as api from '@api';
import * as consts from '@consts';
import { useSelectUser, useUserPaymentMethod } from '@containers';
import * as actions from '@store/actions';
import { SettingsTitle } from 'components/Settings';
import Input from 'components/Input';
import Check from 'components/icons/Check';
import Edit from 'components/icons/Edit';
import XCircle from 'components/icons/XCircle';
import ExistingPaymentMethod from './ExistingPaymentMethod';
import NoPaymentMethod from './NoPaymentMethod';
import ReceivePayments from './ReceivePayouts';
import styles from './style/Overview.css';

export function Overview() {
  const method = useUserPaymentMethod();

  return (
    <div className={styles.root}>
      <SettingsTitle title="Billing Overview" />
      <HourlyRate />
      <div className={styles.sectionShort}>
        <div className={styles.sectionHeader}>
          <h2 className={styles.title}>Receive Payouts</h2>
        </div>
        <ReceivePayments />
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h2 className={styles.title}>Send Payments</h2>
        </div>
        {method ? <ExistingPaymentMethod /> : <NoPaymentMethod />}
      </div>
    </div>
  );
}

function HourlyRate() {
  const dispatch = useDispatch();
  const user = useSelectUser();
  const [editing, setEditing] = useState<boolean>(false);
  const [hourlyRate, setHourlyRate] = useState<string>('');

  const toggleEditing = useCallback(() => {
    if (editing) {
      setHourlyRate('');
    }
    setEditing(!editing);
  }, [editing, setEditing, setHourlyRate]);

  const handleSubmit = useCallback(() => {
    return api.users.updateSettings({
      userId: user.id,
      hourlyRate: +hourlyRate || 0,
    })
    .then(settings => {
      dispatch(actions.userInfoChange({ settings }));
      toggleEditing();
    });
  }, [dispatch, hourlyRate, user, toggleEditing]);

  const handleHourlyRateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.isNaN(parseInt(e.target.value))
      ? ''
      : `${+e.target.value}`;
    setHourlyRate(+value <= consts.rate.MAX_HOURLY ? value : consts.rate.MAX_HOURLY.toString());
  }, [setHourlyRate]);

  const SectionButtons = useCallback(() => {
    if (editing) {
      return (
        <>
          <Check size={12} onClick={handleSubmit} />
          <XCircle className={styles.rateX} size={12} onClick={toggleEditing} />
        </>
      );
    } else {
      return (
        <Edit size={12} onClick={toggleEditing} />
      );
    }
  }, [editing, toggleEditing, handleSubmit]);

  const Editing = useCallback(() => {
    return (
      <div className={styles.field}>
        <div className={styles.dollar}>$</div>
        <Input
          autoFocus
          className={styles.input}
          inputMode="numeric"
          maxLength={consts.rate.MAX_HOURLY.toString().length}
          max={consts.rate.MAX_HOURLY}
          name="hourlyRate"
          pattern="[0-9]*"
          placeholder={`${user.profile.hourlyRate}`}
          onChange={handleHourlyRateChange}
          value={hourlyRate} />
      </div>
    );
  }, [user.profile.hourlyRate, hourlyRate, handleHourlyRateChange]);

  const Readonly = useCallback(() => {
    return <div className={styles.rate}>{`$${user.profile.hourlyRate}`}</div>;
  }, [user.profile.hourlyRate]);

  return (
    <div className={styles.item}>
      <div className={styles.rateContainer}>
        <div>
          <div className={styles.label}>Hourly Rate</div>
          <div className={styles.inputContainer}>
            {editing ? Editing() : Readonly()}
          </div>
        </div>
        {SectionButtons()}
      </div>
      <div className={styles.disclaimer}>Rate updates will not affect call rates that are booked or in scheduling.</div>
    </div>
  );
}

export default Overview;
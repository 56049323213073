import {
  AvailableEventMetadata,
  CalendarISOEvent,
  ScheduledEventMetadata,
} from '@/types';
import styles from './style/Calendar.css';

type UnparsedCalendarViewEvent =
  CalendarISOEvent<
    AvailableEventMetadata
  & ScheduledEventMetadata>;

type UnparsedExternalEvent = {
  end:     string;
  start:   string;
  summary: string;
};

type TransformExternalEventsParams = {
  events: { [uid: string]: UnparsedExternalEvent[]; };
} & IUserId;

type TransformEventParams = {
  contacts: Store.Contacts;
  event: UnparsedCalendarViewEvent;
};

export const createId = getIncrementor();

export const transformEvent = ({ contacts, event }: TransformEventParams) => {
  return {
    id: createId(),
    startEditable: false,
    durationEditable: false,
    extendedProps: {
      isNew: false,
      type: event.isBusy ? 'busy' : 'available',
      segments: event.segments,
      metadata: event.metadata,
      canRemove: !event.isBusy,
    },
    end: new Date(event.end),
    start: new Date(event.start),
    title: event.isBusy ? `${contacts[event.metadata.with.id].profile.fullname}` : 'Available',
    backgroundColor: event.isBusy ? 'var(--gray-d)' : '',
    borderColor: event.isBusy ? 'var(--gray-d)' : '',
    classNames: [ event.isBusy ? styles.busy : styles.available ],
  };
};

export const transformExternalEvents = ({ userId, events }: TransformExternalEventsParams) => {
  return Object.keys(events).reduce((acc, uid) => {
    const isYou = +uid === userId;
    return {
      ...acc,
      [uid]: events[uid].map(event => ({
        id: createId(),
        extendedProps: {
          isNew: false,
          type: 'external',
          canRemove: false,
        },
        startEditable: false,
        durationEditable: false,
        end: new Date(event.end),
        start: new Date(event.start),
        title: event.summary,
        textColor: 'var(--black)',
        backgroundColor: isYou ? 'var(--gray-l)' : '#F8A8A8',
        borderColor: isYou ? 'var(--gray-l)' : '#F8A8A8',
        classNames: [],
      })),
    };
  }, {});
};

function getIncrementor() {
  const state = {
    id: 0,
    inc: () => {
      state.id += 1;

      return state.id;
    },
  };

  return () => state.inc();
}
import { NewPayment, OnTokenEvent } from '@screens/Stripe';
import Modal from 'components/Modal/Modal.Legacy';
import styles from './style/ConnectPayment.css';

type Props = {
  isLoading?: boolean;
  onClose: () => unknown;
  onSave?: () => unknown;
  onToken: OnTokenEvent;
  visible: boolean;
};

export function StripeConnectPayment({ isLoading, onClose, onSave, onToken, visible }: Props) {
  return (
    <Modal
      onClose={onClose}
      visible={visible}>
      <div className={styles.root}>
        <h1 className={styles.header}>
          Set up a payments account
        </h1>
        <h2 className={styles.subheader}>
          Securely connect a bank account, debit card, or credit card to send payments on the platform.
        </h2>
        <div className={styles.scroll}>
          <NewPayment
            isLoading={isLoading}
            onSave={onSave}
            onToken={onToken} />
        </div>
      </div>
    </Modal>
  );
}

export default StripeConnectPayment;
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSearchFiltersState } from '@containers/WorkspaceGlobalSearch/hooks/useSearchFilterState';
import { getLocationFor } from '@utils';
import styles from './style/MemberSearchBanner.css';

export default function MemberSearchBanner() {
  const [query] = useSearchFiltersState();

  const to =
    useMemo(() => getLocationFor.search({ keyword: [{ id: query.q, name: query.q }] }), [query.q]);

  return (
    <Link to={to}>
      <div className={styles.banner}>
        <div className={styles.text}>{`Find members for your projects.`}</div>
        <div className={styles.link}>{`Browse in Search >`}</div>
      </div>
    </Link>
  );
}

export { MemberSearchBanner };
import { useMemo } from 'react';
import * as enums from '@enums';
import { RouteKey } from '@containers/GroupProject/interfaces';
import { useProjectAnonymity } from './useProjectAnonymity';
import { useProjectOrLatestChild } from './useProjectState';

const useTabRoutes = () => {
  const project = useProjectOrLatestChild();
  const anonymity = useProjectAnonymity();

  const routes = useMemo(() => {
    const routesConfig = tabRoutes[project.projectType];

    return anonymity
      ? routesConfig.filter(x => x !== RouteKey.Pipeline)
      : routesConfig;
  }, [
    anonymity,
    project.projectType,
  ]);

  return routes;
};

export const tabRoutes = {
  [enums.ProjectType.Call]: [
    RouteKey.Pipeline,
    RouteKey.Calls,
    RouteKey.Summary,
    RouteKey.Details,
  ],

  [enums.ProjectType.Survey]: [
    RouteKey.Pipeline,
    RouteKey.SurveyResponses,
    RouteKey.Details,
  ],

  [enums.ProjectType.SurveyCall]: [
    RouteKey.Pipeline,
    RouteKey.SurveyResponses,
    RouteKey.Calls,
    RouteKey.Summary,
    RouteKey.Details,
  ],
};

export { useTabRoutes };
export default useTabRoutes;
import { useReducer, Reducer } from 'react';
import { SurveyQuotaStateContext } from './Context';
import { SurveyQuota } from './interfaces';
import { surveyQuotaReducer } from './reducer';

type Props = {
  initial: SurveyQuota.State;
} & ChildrenProps;

export const SurveyQuotaBuilderState = ({
  children,
  initial,
}: Props) => {

  const reducer = useReducer<Reducer<SurveyQuota.State, SurveyQuota.Action>>(surveyQuotaReducer, initial);

  return (
    <SurveyQuotaStateContext.Provider value={reducer}>
      {children}
    </SurveyQuotaStateContext.Provider>
  );
};

export default SurveyQuotaBuilderState;
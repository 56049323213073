import { useMemo } from 'react';
import { useAccountProfileNewEntry, useAccountProfileEditState, useProfileState } from '@containers';
import { ProfileSectionEntries } from 'components/UserProfile';
import AddEntryButton from './AddEntryButton';
import { ProfileEntriesProps, Entry } from './interfaces';
import styles from './style.css';

const ProfileEntries = <T extends Entry>(props: ProfileEntriesProps<T>) => {
  const [user] = useProfileState();
  const items = user[props.type];

  const hasSavedEntries = useMemo(() => !!items.length, [items]);
  const [entryId] = useAccountProfileEditState();
  const [editingNewEntry, toggleNewEntry] = useAccountProfileNewEntry(props.type);

  const isEditing = !!entryId;

  const showAddButton = useMemo(() => {
    return !hasSavedEntries && !isEditing;
  }, [hasSavedEntries, isEditing]);

  const showAddPreviousButton = useMemo(() => {
    return hasSavedEntries && !isEditing;
  }, [hasSavedEntries, isEditing]);

  return (
    <>
      {showAddButton &&
        <AddEntryButton
          className={styles.btnCurrent}
          onClick={toggleNewEntry}
          type={props.type} />
      }
      <ProfileSectionEntries
        type={props.type} />
      {showAddPreviousButton &&
        <AddEntryButton
          className={styles.btnPrev}
          onClick={toggleNewEntry}
          type={props.type} />
      }
    </>
  );
};

export default ProfileEntries;
import { useContext, Dispatch, SetStateAction } from 'react';
import { createNamedContext } from '@utils';
import { TranscribeRT } from '@/types';

type CommentReplyVisibilityContextValue =
  [boolean, Dispatch<SetStateAction<boolean>>];

type CommentReplyActionsVisibilityContextValue =
  [boolean, Dispatch<SetStateAction<boolean>>];

type ParentCommentIdentifiers =
  Pick<TranscribeRT.TranscriptComment, 'highlight' | 'parentComment'>;

type CommentContextValue = TranscribeRT.HighlightComment;

export const TranscriptChildCommentContext = createNamedContext<CommentContextValue>(undefined, 'TranscriptChildCommentContext');
export const TranscriptCommentReplyVisibilityContext = createNamedContext<CommentReplyVisibilityContextValue>(undefined, 'TranscriptCommentReplyVisibilityContext');
export const TranscriptCommentReplyActionsVisibilityContext = createNamedContext<CommentReplyActionsVisibilityContextValue>(undefined, 'TranscriptCommentReplyActionsVisibilityContext');
export const TranscriptParentCommentIdentifiersContext = createNamedContext<ParentCommentIdentifiers>(undefined, 'TranscriptParentCommentIdentifiersContext');

export const useTranscriptChildComment = () => useContext(TranscriptChildCommentContext);
export const useTranscriptCommentReplyVisibility = () => useContext(TranscriptCommentReplyVisibilityContext);
export const useTranscriptCommentReplyActionsVisibility = () => useContext(TranscriptCommentReplyActionsVisibilityContext);
export const useTranscriptCommentIdentifiers = () => useContext(TranscriptParentCommentIdentifiersContext);
import { useCallback } from 'react';
import { Node } from 'slate';
import { ReactEditor, Editable, Slate } from 'slate-react';
import * as RichText from 'components/RichText';
import * as Toolbar from './Toolbar';
import styles from './style/Editor.css';

type Props = {
  editor: ReactEditor;
  value: Node[];
  onChange: (value: Node[]) => void;
};

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

const NotesEditor = (props: Props) => {
  const renderElement = useCallback(props => <RichText.Element {...props} />, []);
  const renderLeaf = useCallback(props => <RichText.Leaf {...props} />, []);

  const handleOnChange = (value: Node[]) => {
    props.onChange(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    for (const hotkey in HOTKEYS) {
      if (RichText.isHotkey(hotkey, event.nativeEvent)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        RichText.toggleMark(props.editor, mark);
      }
    }
  };

  return (
    <div className={styles.root}>
      <Slate editor={props.editor} value={props.value || RichText.emptyValue} onChange={handleOnChange}>
        <div className={styles.editable}>
          <Editable
            style={{ height: '100%' }}
            onKeyDown={handleKeyDown}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={`Type notes on the call...`}
            spellCheck />
        </div>
        <Toolbar.Container className={styles.tools}>
          <RichText.MarkButton className={styles.icon} format='bold' />
          <RichText.MarkButton className={styles.icon} format='italic' />
          <RichText.MarkButton className={styles.icon} format='underline' />
          <RichText.BlockButton className={styles.icon} format='bulleted-list' />
        </Toolbar.Container>
      </Slate>
    </div>
  );
};

export { NotesEditor };
export default NotesEditor;
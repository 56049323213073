import { useCallback, useEffect, useMemo, useRef } from 'react';

const useScrollLock = (locked = false) => {
  const enabled = useRef(locked);

  const scroll = useMemo(() => ({
    get locked() {
      return window
            .getComputedStyle(document.body)
            .getPropertyValue('overflow-y') === 'hidden';
    },
    lock: () => {
      document.body.style.overflowY = 'hidden';
    },
    unlock: () => {
      document.body.style.overflowY = '';
    },
  }), []);

  const enableLock = useCallback(() => {
    if (scroll.locked) {
      enabled.current = true;
    } else {
      scroll.lock();
    }

    if (!enabled.current) {
      enabled.current = true;
    }
  }, [
    scroll,
  ]);

  const disableLock = useCallback(() => {
    if (enabled.current) {
      scroll.unlock();
    }

    enabled.current = false;
  }, [scroll]);

  useEffect(() => {
    return () => enabled.current && disableLock();
  }, [disableLock]);

  return {
    lock: enableLock,
    locked: scroll.locked,
    unlock: disableLock,
  };
};

export { useScrollLock };
export default useScrollLock;
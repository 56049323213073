import { makeStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from './Checkbox';
import { CheckboxLabelProps } from './interfaces';
import styles from './style/CheckboxLabel.css';

const useFormControlLabelStyles = makeStyles({
  root: {
    marginLeft: 0,
  },
  label: {
    fontSize: 16,
    fontFamily: 'var(--font-reg)',
    width: '100%',
  },
});

export const CheckboxLabel = ({
  className,
  label = '',
  checked = false,
  onChange = () => {},
  style,
}: CheckboxLabelProps) => {
  const labelClasses = useFormControlLabelStyles();
  return (
    <FormControlLabel
      className={className}
      classes={labelClasses}
      control={
        <Checkbox
          className={checked ? styles.checked : styles.unchecked}
          checked={checked}
          style={{
            marginRight: 10,
          }}
          onChange={onChange} />
      }
      label={label}
      style={style} />
  );
};

export default CheckboxLabel;
import qs from 'query-string';
import http from '@services/http';
import { Calls } from './interfaces';

export const fetchDialIn = (data: Calls.FetchDialInRequest): Promise<Calls.FetchDialInResponse> => {
  return http.get(`/calls/${data.callId}/dial-in`);
};

export const savePostCallSurveyResponse = (data: Calls.SavePostCallSurveyResponseRequest): Promise<void> => {
  const { callId, ...body } = data;
  return http.post(`/calls/${callId}/post-call-survey-response`, body);
};

export const submitCallRating = (data: Calls.SubmitCallRatingRequest) => {
  const { callId, ...body } = data;
  return http.post(`/calls/${callId}/rating`, body);
};

export const getRecordingUrl = ({ callId, ...data }: Calls.GetRecordingUrl.Request): Promise<Calls.GetRecordingUrl.Response> => {
  return http.get(`/calls/${callId}/audio?${qs.stringify(data)}`);
};

export const getVideoUrl = ({ callId, ...data }: Calls.GetVideoUrl.Request): Promise<Calls.GetVideoUrl.Response> => {
  return http.get(`/calls/${callId}/video?${qs.stringify(data)}`);
};

export const getConferenceParticipants = (data: Calls.GetConferenceParticipants.Request): Promise<Calls.GetConferenceParticipants.Response> => {
  return http.get(`/calls/${data.callId}/conference-participants`);
};

export const getCallAccess = (data: Calls.GetCallAccess.Request): Promise<Calls.GetCallAccess.Response> => {
  return http.get(`/calls/${data.callId}/access`);
};

export * from './calls.transcripts';
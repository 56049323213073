import img from 'static/images/vancery-landscape-grey.png';
import styles from './style/BrandingFooter.css';

type Props = unknown;

export const BrandingFooter = (props: Props) => {

  return (
    <footer className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.content}>
            <span className={styles.span}>Powered by</span>
            <img
              className={styles.img}
              src={img} />
          </div>
        </div>
      </div>
    </footer>
  );
};

BrandingFooter.displayName = 'Branding.Footer';
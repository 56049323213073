import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import * as api from '@api';
import { TranscriptComment } from '@/types/transcribe.rich-text';

type Props = {
  transcriptId: number;
} & ICallId;

export const useCallTranscriptionComments = (props: Props, options: Options = {}) => {

  const query = useQuery(['call-transcript-comments', props], ({ queryKey }) => {
    const params = queryKey[1] as Props;
    return api.calls.getTranscriptComments(params)
      .then(res => res.comments);
  }, {
    ...options,
    placeholderData: [],
  });

  return query;
};

type Data = TranscriptComment[];
type QK = [string, Props];

type Options = UseQueryOptions<Data, AxiosResponse, Data, QK>;
import { useEffect, useMemo } from 'react';
import { useStepper } from '@utils';
import * as Frame from 'components/PostCreation/Frame';
import * as Publisher from 'components/PostCreation/Publisher';
import { VideoObjectURLContext, VideoStepperContext } from './Context';
import { StateContainer } from './Post.Video.StateContainer';
import * as Search from './Search';
import { TopicTagsContainer } from './Topic.Tags.Container';
import { useVideoPostStateContext, useVideoContentPublisher, useVideoPostHasChanged, useVideoPostSubmitState } from './hooks';

type Props = {
  children?: React.ReactNode;
};

export const Container = (props: Props) => {
  const [active, toggle] = useVideoContentPublisher();

  if (!active) return null;

  return (

    <StateContainer>
      <Search.Container>
        <TopicTagsContainer>
          <Stepper />
        </TopicTagsContainer>
      </Search.Container>
    </StateContainer>
  );
};

const Stepper = (props: unknown) => {
  const [FrameComponent, actions, step] = useStepper([
    Frame.Video.UploadVideo,
    Frame.Video.ContentForm,
    Frame.Video.ShareForm,
  ]);

  const [active, toggle] = useVideoContentPublisher();
  const submitState = useVideoPostSubmitState();
  const [state] = useVideoPostStateContext();

  const thumbnail = useMemo(() => {
    return state.thumbnail && URL.createObjectURL(state.thumbnail);
  }, [state.thumbnail]);

  const video = useMemo(() => {
    return state.video && URL.createObjectURL(state.video);
  }, [state.video]);

  useEffect(() => {

    if (active && submitState.uploading) {
      toggle();
    }

  }, [
    active,
    submitState,
    toggle,
  ]);

  const hasChanged = useVideoPostHasChanged();

  return (
    <VideoStepperContext.Provider value={actions}>
      <VideoObjectURLContext.Provider value={{ thumbnail, video }}>
        <Publisher.Modal
          blocking={hasChanged}
          open={active}
          onClose={toggle}>
          <FrameComponent />
        </Publisher.Modal>
      </VideoObjectURLContext.Provider>
    </VideoStepperContext.Provider>
  );
};

Container.displayName = 'PostCreation.Post.Video.Container';
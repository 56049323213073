import useMediaQuery from '@mui/material/useMediaQuery';
import {
  AppStore,
  PlayStore,
} from 'components/AppDownloadLinks';
import confetti from 'static/images/website/grey-confetti-right.png';
import confettiMobile from 'static/images/website/grey-confetti-right-mobile.png';
import confettiTablet from 'static/images/website/grey-confetti-right-tablet.png';
import mobileApp from 'static/images/website/mobile-app.png';
import Section from './Section';
import styles from './style/GetApp.css';

const Main = () => {
  const headline = `Easily book and manage paid consulting projects on the go.`;
  const copy = `Create a profile, engage in paid projects, and connect with professionals– all in the app.`;

  const showAppLinks = useMediaQuery('(min-width:800px)');

  return (
    <div className={styles.main}>
      <h2 className={styles.headline}>{headline}</h2>
      <div className={styles.copy}>{copy}</div>
      {showAppLinks &&
        <div className={styles.storeLinks}>
          <AppStore
            className={styles.storeLink}
            imgClassName={styles.storeImg} />
          <PlayStore
            className={styles.storeLink}
            imgClassName={styles.storeImg} />
        </div>
      }
    </div>
  );
};

const Phone = () => {
  const mobile = useMediaQuery('(max-width:800px)');

  return (
    <div className={styles.phone}>
      <img
        alt="mobile app"
        className={styles.phoneImg}
        src={mobileApp} />
      {mobile &&
        <div className={styles.mobileStoreLinks}>
          <AppStore
            className={styles.storeLink}
            imgClassName={styles.storeImg} />
          <PlayStore
            className={styles.storeLink}
            imgClassName={styles.storeImg} />
        </div>
      }
      {mobile &&
        <img
          className={styles.confettiMobile}
          src={confettiMobile} />
      }
    </div>
  );
};

const Background = () => {
  const desktop = useMediaQuery('(min-width:1100px)');
  const tablet = useMediaQuery('(min-width:800px)');

  return (
    <>
      {desktop && <img
        className={styles.confetti}
        src={confetti} />}
      {tablet && !desktop &&
        <img
          className={styles.confettiTablet}
          src={confettiTablet} />}
    </>
  );
};

const GetApp = () => (
  <Section>
    <div className={styles.root}>
      <div className={styles.wrap}>
        <Main />
        <Phone />
      </div>
      <Background />
    </div>
  </Section>
);

export default GetApp;
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { bindToggle, bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import { useRescheduleProjectCall, useCancelProjectCall } from '@containers/Scheduler/hooks';
import { MoreHorizontalAnchorSmall } from '@presentation/Anchor';
import { ProjectCall } from '@/types';
import { PopperMenu, PopperMenuItem } from 'components/Popper';
import { useCancelProjectCallModal } from 'components/Modal/CancelProjectCall';

type Props = {
  item: ProjectCall;
};

const ProjectCallAgendaMenuPopper = (props: Props) => {

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'project-call-agenda-popper',
  });

  const [canCancel, cancel] = useCancelProjectCall(props.item.id);

  const [toggleCancelModal, CancelProjectCallModal] = useCancelProjectCallModal();

  const [canReschedule, reschedulingLocation] = useRescheduleProjectCall(props.item.id);

  const handleCancel = useCallback(() => {
    return cancel().then(() => popupState.close());
  }, [cancel, popupState.close]);

  const createReschedule = useCallback(() => {
    if (!canReschedule) return;

    return {
      children: (
        <Link to={reschedulingLocation}>Reschedule</Link>
      ),
      onClick: popupState.close,
    };
  }, [
    canReschedule,
    popupState.close,
    reschedulingLocation,
  ]);

  const createCancel = useCallback(() => {
    if (!canCancel) return;

    return {
      children: 'Cancel',
      onClick: toggleCancelModal,
    };
  }, [
    canCancel,
    toggleCancelModal,
  ]);

  if (!canReschedule && !canCancel) return null;

  const items = [
    createCancel(),
    createReschedule(),
  ].filter(Boolean);

  return (
    <>
      <div>
        <div {...bindToggle(popupState)}>
          <MoreHorizontalAnchorSmall open={popupState.isOpen} />
        </div>
        <Popper {...bindPopper(popupState)} placement="bottom-end">
          <ClickAwayListener
            onClickAway={popupState.close}>
            <PopperMenu>
              {items.map((m, i) => (
                <PopperMenuItem
                  key={i}
                  onClick={m.onClick}>
                  {m.children}
                </PopperMenuItem>
              ))}
            </PopperMenu>
          </ClickAwayListener>
        </Popper>
      </div>
      <CancelProjectCallModal
        callId={props.item.id}
        onConfirm={handleCancel} />
    </>
  );
};

export { ProjectCallAgendaMenuPopper };
export default ProjectCallAgendaMenuPopper;
import { useCallback, useMemo } from 'react';
import { AnalyticsEvent, FeedItemType } from '@enums';
import { SystemId } from '@consts/users';
import { useSelectUser } from '@containers/Store';
import { cx, formatDisplayAge, getLocationFor } from '@utils';
import { ArticlePostItem, Posts } from '@/types';
import { useTrackEvent } from '@utils/analytics';
import { Button } from 'components/Button';
import { MemberBaseballCard } from 'components/BaseballCard';
import { UserAvatar } from 'components/UserAvatar';
import { ContextMenu } from './Post.ContextMenu';
import styles from './style/Post.Header.css';

type Props = {
  className?: string;
  item:
    | ArticlePostItem
    | Omit<Posts.Post, 'identifer' | 'transcript'>;
};

export const Header = ({ className, item }: Props) => {
  const me = useSelectUser();
  const isPostContentType = useMemo(() => {
    return item.contentType === FeedItemType.Post;
  }, [item.contentType]);
  const isOwnPost = useMemo(() => {
    return me.id === item.author.id;
  }, [me.id, item.author.id]);

  const Career = useCallback(() => {
    const value = item.author.career.current?.display ||
      item.author.career.former?.display;

    if (!value) return null;

    return (
      <div className={styles.career}>{value}</div>
    );
  }, [item.author.career]);

  const Age = useCallback(() => {

    return (
      <span className={styles.age}>{formatDisplayAge(item.createdOn)}</span>
    );
  }, [item.createdOn]);

  const trackEvent = useTrackEvent(AnalyticsEvent.ViewPostAuthor);
  const trackViewAuthor = useCallback(() => {
    if (!isPostContentType) return;

    trackEvent({
      postId: item.id,
      userId: item.author.id,
    });
  }, [
    item.id,
    item.author.id,
    trackEvent,
    isPostContentType,
  ]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.author}>
        <AuthorDisplayImage
          author={item.author}
          onClick={trackViewAuthor} />
        <div className={styles.meta}>
          <div className={styles.title}>
            <MemberBaseballCard.Member contact={item.author}>
              <AuthorDisplayName
                author={item.author}
                onClick={trackViewAuthor} />
            </MemberBaseballCard.Member>
            <Age />
          </div>
          <Career />
        </div>
      </div>
      {(isOwnPost && isPostContentType) &&
        <ContextMenu />}
    </div>
  );
};

Header.displayName = 'Post.Header';

type AuthorProps = {
  onClick: () => unknown;
} & Pick<Posts.Post, 'author'>;

const AuthorDisplayImage = ({ author, onClick }: AuthorProps) => {
  const admin = author.id === SystemId;
  const { slug } = author.profile || {};

  const location = admin || !slug
      ? null
      : getLocationFor.user.profile({ slug });

  const classes = cx({ [styles.admin]: admin });

  const resize = admin
      ? null
      : 100;

  return (
    <MemberBaseballCard.Member contact={author}>
      <UserAvatar
        onClick={onClick}
        to={location}
        className={classes}
        pictureUrl={author.profile.pictureUrl}
        resize={resize}
        size={48} />
    </MemberBaseballCard.Member>
  );
};

AuthorDisplayImage.displayName = 'Post.Header.AuthorDisplayImage';

const AuthorDisplayName = ({ author, onClick }: AuthorProps) => {

  if (author.id === SystemId) {
    return <div className={styles.name}>{author.profile.fullname}</div>;
  }

  const url = getLocationFor.user.profile(author.profile);

  return (
    <Button
      className={cx(styles.name, styles.link)}
      onClick={onClick}
      to={url}
      variant="link">
      {author.profile.fullname}
    </Button>
  );
};

AuthorDisplayName.displayName = 'Post.Header.AuthorDisplayName';
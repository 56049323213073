import * as enums from '@enums';

export function rolesToStrings(roles: enums.Role[]) {
  return roles.map(r => enums.utils.Role.getName(r));
}

export function getProjectTypeLabel(type: enums.ProjectType) {
  switch (type) {
    case enums.ProjectType.Call: return 'call';
    case enums.ProjectType.Survey: return 'survey';
    case enums.ProjectType.SurveyCall: return 'mixed';
    default: return 'unknown';
  }
}
import { useCallback, useMemo, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useTable, useFlexLayout, usePagination, useSortBy } from 'react-table';
import { Card, CardContent, Divider, GroupCardHeader } from '@presentation/Main';
import { GroupTable, GroupTableFooter } from '@presentation/GroupTable';
import { cx, useDevice } from '@utils';
import { ConnectedAccountType } from '@enums';
import { useGoogleDriveUpload, useZoomRecordingUpload } from '@containers/WorkspaceFileUpload/hooks';
import { FilesTableEmptyState, FilesTableEmptyStateUpload } from 'components/WorkspaceFiles/FilesTableEmptyState';
import { FilesTableItem } from 'components/WorkspaceFiles/interfaces';
import { useFileDragDrop } from 'components/FileDragDrop/hooks/useFileDragDrop';
import { useFilesTableColumns } from 'components/WorkspaceFiles/hooks/useFilesTableColumns';
import { Menu } from './Menu';
import styles from './style/Table.css';
import stylesF from './style/FilesTable.css';

type Props = {
  canUpload: boolean;
  data: FilesTableItem[];
  onUpload?: (files: File[]) => unknown;
};

export const FilesTable = ({ canUpload, data, onUpload }: Props) => {

  const columns = useFilesTableColumns();

  const handleUploadClick = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    return onUpload(Array.from(e.target.files));
  }, [onUpload]);

  const device = useDevice();
  const uploadEnabled = useMemo(() => canUpload && !device.phone && !device.tablet, [canUpload, device]);

  const { dragging, dropRef } = useFileDragDrop({
    onDrop: onUpload,
  });

  const { openPicker, loading } = useGoogleDriveUpload({
    onSelect: onUpload,
  });

  const [openZoomPicker, ZoomPickerModal] = useZoomRecordingUpload();

  const hasConnectedFileAccounts = useSelector((state: Store.State) => !!state.user.connected.filter(a => a.typeId == ConnectedAccountType.Files).length);

  const Upload = useCallback(() => {

    if (!uploadEnabled) {
      return null;
    } else if (hasConnectedFileAccounts) {
      return (
        <>
          <Menu
            onDriveSelect={openPicker}
            onZoomSelect={openZoomPicker}
            onMyComputerSelect={handleUploadClick} />
          <ZoomPickerModal onSelect={onUpload} />
        </>
      );
    } else {
      return (
        <label className={stylesF.upload}>
          <input
            className={stylesF.fileInput}
            multiple={true}
            onChange={handleUploadClick}
            type="file" />
          Upload
        </label>
      );
    }

  }, [handleUploadClick, openPicker, hasConnectedFileAccounts, uploadEnabled, ZoomPickerModal, openZoomPicker, onUpload]);

  const Header = useCallback(() => {
    return (
      <GroupCardHeader className={styles.header}>
        <div className={styles.left}>
          <div>Files</div>
        </div>
        <Upload />
      </GroupCardHeader>
    );
  }, [
    Upload,
  ]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
        sortBy: [{
          id: 'createdOn',
          desc: true,
        }],
      },
    },
    useFlexLayout,
    useSortBy,
    usePagination,
  );

  const tableClasses = useMemo(() => ({
    body: styles.body,
    td: styles.td,
  }), []);

  const footerClasses = {
    root: styles.footer,
    pageSize: styles.rows,
    pagination: styles.pagination,
  };

  const renderTable = useCallback(() => {
    if (!data.length) {
      return null;
    }

    if (canUpload) {
      return (
        <div
          ref={dropRef}
          className={cx({
            [styles.dragging]: dragging,
          })}>
          <GroupTable
            classes={tableClasses}
            instance={tableInstance} />
        </div>
      );
    } else {
      return (
        <GroupTable
          classes={tableClasses}
          instance={tableInstance} />
      );
    }
  }, [
    canUpload,
    data.length,
    dragging,
    dropRef,
    tableClasses,
    tableInstance,
  ]);

  const renderEmptyState = useCallback(() => {
    if (data.length) {
      return null;
    }

    if (uploadEnabled) {
      return (
        <FilesTableEmptyStateUpload
          className={styles.empty}
          onUpload={onUpload} />
      );
    } else {
      return (
        <FilesTableEmptyState
          className={styles.empty} />
      );
    }
  }, [
    data.length,
    onUpload,
    uploadEnabled,
  ]);

  return (
    <Card className={styles.card}>
      <Header />
      {!data.length && <Divider />}
      <CardContent
        className={styles.content}
        fluid>
        {renderTable()}
        {!!data.length &&
          <GroupTableFooter
            classes={footerClasses}
            instance={tableInstance} />}
        {renderEmptyState()}
      </CardContent>
    </Card>
  );
};

export default FilesTable;
import { useEnterprisePaymentMethod } from '@containers/Payment';
import { SettingsTitle } from 'components/Settings';
import ExistingPaymentMethod from './ExistingPaymentMethod';
import NoPaymentMethod from './NoPaymentMethod';
import ChargeList from './ChargeList';
import styles from './style/Overview.css';

export function Overview() {
  const method = useEnterprisePaymentMethod();

  return (
    <div className={styles.root}>
      <SettingsTitle title="Billing Overview" />
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h2 className={styles.title}>Billing Details</h2>
        </div>
        {method ? <ExistingPaymentMethod /> : <NoPaymentMethod />}
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <h2 className={styles.title}>Billing History</h2>
        </div>
        <ChargeList />
      </div>
    </div>
  );
}

export default Overview;
import { Bell, Briefcase, Calendar, Home, Layers, LogOut, MessageSquare, Search, Settings, Share2, User } from 'react-feather';
import { menu } from '@consts';
import { hasChatAccess, hasInternalAdminAccess, hasInternalResearchAccess, hasTransientRole } from '@utils';
import { MenuIconMap } from './interfaces';

export const createValidator = (state: Store.State) => {
  return {
    get admin() {
      return hasInternalAdminAccess(state.user);
    },
    get calendar() {
      return true;
    },
    get messages() {
      return hasChatAccess(state.user, state.group);
    },
    get notifications() {
      return true;
    },
    get projects() {
      return true;
    },
    get research() {
      return hasInternalResearchAccess(state.user);
    },
    get search() {
      return !hasTransientRole(state.user);
    },
  };
};

export const iconMap: MenuIconMap = {
  [menu.Admin]: Layers,
  [menu.Calendar]: Calendar,
  [menu.Home]: Home,
  [menu.Logout]: LogOut,
  [menu.Messages]: MessageSquare,
  [menu.Notifications]: Bell,
  [menu.Profile]: User,
  [menu.Projects]: Briefcase,
  [menu.Search]: Search,
  [menu.Settings]: Settings,
  [menu.Share]: Share2,
};
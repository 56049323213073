import { useCallback, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { TabView } from 'components/TabView';
import { useGroupProjectTabViewState } from './hooks/useGroupProjectTabViewState';
import { GroupProjectRouteParams, SceneMap, TabScene, RouteKey } from './interfaces';

const ProjectTabView = ({ sceneMap: map }: { sceneMap: SceneMap; }) => {

  const match = useRouteMatch<GroupProjectRouteParams>();
  const keys = useMemo(() => Object.keys(map), [map]);
  const routes = useMemo(() => keys.map(k => ({ key: k, ...map[k] })), [keys, map]);
  const [{ index }, updateLocation] = useGroupProjectTabViewState();
  const location = useLocation();

  const defaultRoute = useMemo(() => {
    const key = keys[0];
    return {
      pathname: `/projects/${match.params.slug}/${key}`,
      state: location.state,
    };
  }, [match.params.slug, keys, location.state]);

  const renderTabView = useCallback(() => {
    return (
      <Switch>
        {keys.map((key: RouteKey) =>
          <Route
            key={key}
            path={map[key].path}
            component={map[key].component} />)}
        <Redirect to={defaultRoute} />
      </Switch>
    );
  }, [
    defaultRoute,
    keys,
    map,
  ]);

  return (
    <TabView
      index={index}
      onIndexChange={updateLocation}
      routes={routes}
      renderTabView={renderTabView} />
  );
};

export { ProjectTabView };
export default ProjectTabView;
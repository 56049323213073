import { useCallback } from 'react';
import { useSurveyBuilderState, useSurveySection, useSectionQuestions } from '@containers/SurveyBuilder/hooks';
import { SurveyTaggingSectionBuilder } from 'components/SurveyBuilder.Section.Tagging/SurveyTaggingSectionBuilder';
import { SurveyTaggingSectionBuilderContainerProps } from './interfaces';

type Props =
  SurveyTaggingSectionBuilderContainerProps;

export default function SurveyTaggingSectionBuilderContainer(props: Props) {
  const [state, dispatch] = useSurveyBuilderState();

  const toggleEditingOn = useCallback((ordinal: number) => () => {
    dispatch({
      type: 'toggle-logic-editing',
      ordinal,
    });
  }, [dispatch]);

  const section = useSurveySection(props.identifier);
  const questions = useSectionQuestions(props.identifier);

  return (
    <SurveyTaggingSectionBuilder
      className={props.className}
      editingOrdinal={state.editing.logicOrdinal}
      questions={questions}
      section={section}
      toggleEditingOn={toggleEditingOn} />
  );
}

export { SurveyTaggingSectionBuilderContainer };
import mixpanel from 'mixpanel-browser';
import { Profile } from './interfaces';

export function enter() {
  mixpanel.time_event('profile:view');
}

export function leave(data: Profile.Leave) {
  mixpanel.track('profile:view', data);
  mixpanel.people.increment('Profile Views');
}
import qs from 'query-string';
import http from '@services/http';
import { SurveyType } from '@enums';
import { Projects } from '@services/api/interfaces';
import * as xform from '@transformers';
import { UnparsedPipeline } from '@/types';
import { surveyMetrics } from './projects.survey.metrics';

const getComplianceSurveyResponse = (data: Projects.GetComplianceSurveyResponse.Request): Promise<Projects.GetComplianceSurveyResponse.Response> => {
  return http.get(`/projects/${data.projectId}/users/${data.userId}/compliance-survey`)
  .then(xform.normalizeSurveyResponse);
};

const surveyResponseApproval = (data: Projects.SurveyApprovalRequest): Promise<Projects.SurveyApprovalResponse> => {
  const { projectSurveyResponseId, ...rest } = data;
  return http.post(`/projects/survey-responses/${data.projectSurveyResponseId}/approval`, rest);
};

const saveProjectSurveyResponse = (data: Projects.SaveProjectSurveyResponse.Request): Promise<Projects.SaveProjectSurveyResponse.Response> => {
  return http.patch(`/projects/${data.projectId}/surveys/${data.surveyVersionId}/response?type=${SurveyType.Project}`, data)
    .then((res: Projects.SaveProjectSurveyResponse.Response<UnparsedPipeline>) => ({
      ...res,
      pipeline: xform.normalizePipeline(res.pipeline),
    }));
};

const saveComplianceSurveyResponse = (data: Projects.SaveComplianceSurveyResponse.Request): Promise<Projects.SaveComplianceSurveyResponse.Response> => {
  return http.patch(`/projects/${data.projectId}/surveys/${data.surveyVersionId}/response?type=${SurveyType.Compliance}`, data);
};

const fetchSurveyResponses = ({
  projectId,
  ...query
}: Projects.FetchSurveyResponses.Request): Promise<Projects.FetchSurveyResponses.Response> => {
  return http.get(`/projects/${projectId}/survey-responses?${qs.stringify(query)}`);
};

const fetchRepeatSurveyResponses = ({
  projectParentId,
  ...query
}: Projects.FetchRepeatSurveyResponses.Request): Promise<Projects.FetchRepeatSurveyResponses.Response> => {
  return http.get(`/projects/parents/${projectParentId}/survey-responses?${qs.stringify(query)}`);
};

const fetchSurveyResponse = (data: Projects.FetchSurveyResponse.Request): Promise<Projects.FetchSurveyResponse.Response> => {
  return http.get(`/projects/${data.projectId}/survey-responses/${data.projectSurveyResponseId}`);
};

const saveProjectSurveyResponseRating = ({
  projectId,
  projectSurveyResponseId,
  ...rating
}: Projects.SaveProjectSurveyResponseRating.Request) => {
  return http.post(`/projects/${projectId}/survey-responses/${projectSurveyResponseId}/rating`, { rating });
};

const starProjectSurveyResponseAnswer = ({
  projectId,
  projectSurveyResponseId,
  ...rest
}: Projects.StarProjectSurveyResponseAnswer.Request) => {
  return http.post(`/projects/${projectId}/survey-responses/${projectSurveyResponseId}/star-answer`, { ...rest });
};

const fetchProjectSurvey = (data: Projects.FetchProjectSurvey.Request): Promise<Projects.FetchProjectSurvey.Response> => {
  return http.get(`/projects/${data.projectId}/survey`);
};

const saveProjectSurvey =({
  projectId,
  ...body
}: Projects.SaveProjectSurvey.Request): Promise<Projects.SaveProjectSurvey.Response> => {
  return http.post(`/projects/${projectId}/survey`, body);
};

const getProjectSurveyForm = ({ projectId, ...rest }: Projects.GetProjectSurveyForm.Request) => {
  return http.get<Projects.GetProjectSurveyForm.Response>(`/projects/${projectId}/survey-form?${qs.stringify(rest)}`);
};

const getComplianceSurveyForm = ({ projectId, ...rest }: Projects.GetComplianceSurveyForm.Request) => {
  return http.get<Projects.GetProjectSurveyForm.Response>(`/projects/${projectId}/compliance-survey-form?${qs.stringify(rest)}`);
};

export const surveys = {
  approval: surveyResponseApproval,
  fetchResponse: fetchSurveyResponse,
  fetchResponses: fetchSurveyResponses,
  fetchRepeatResponses: fetchRepeatSurveyResponses,
  get: fetchProjectSurvey,
  getComplianceSurveyResponse,
  getComplianceForm: getComplianceSurveyForm,
  getForm: getProjectSurveyForm,
  metrics: surveyMetrics,
  save: saveProjectSurvey,
  saveComplianceSurveyResponse,
  saveProjectSurveyResponse,
  saveProjectSurveyResponseRating,
  starProjectSurveyResponseAnswer,
};
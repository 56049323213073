import { useCallback, useMemo } from 'react';
import { Workspace } from '@/types';
import { Select } from '$admin/components';

type Props = {
  className?: string;
  options: Workspace[];
  onChange: (w: Workspace) => unknown;
  value: Workspace;
};

export const WorkspaceSelect = ({ className, options, onChange, value }: Props) => {

  const items = useMemo(() => {
    return options.reduce((acc, x) => ({
      ...acc,
      [x.id]: x.name,
    }), {} as { [v: number]: string; });
  }, [options]);

  const handleChange = useCallback((e: React.ChangeEvent<{
    name: string;
    value: string;
  }>) => {
    const option = options.find(f => f.id === +e.target.value);
    onChange(option);
  }, [onChange, options]);

  return (
    <Select
      className={className}
      id="workspace-select"
      name="workspace"
      onChange={handleChange}
      options={items}
      value={value?.id} />
  );
};

export default WorkspaceSelect;
import { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSelectPersonalWorkspace, useSelectObject, useSelectGroupWorkspace } from '@containers/Store';
import { WorkspaceObjectType } from '@enums';
import { selectObjectPathString, selectObjectTree } from '@store/selectors';
import { Workspace } from '@/types';
import { useMoveObject } from './hooks';
import { MoveObjectContext, MoveObjectContextValue } from './Context';

type Props =
  IWorkspaceObjectId &
  ChildrenProps;

export const MoveObjectContainer = (props: Props) => {
  const path = useSelector(selectObjectPathString(props.objectId));

  const object = useSelectObject(props.objectId);
  const personalWorkspace = useSelectPersonalWorkspace();
  const groupWorkspace = useSelectGroupWorkspace();
  const [workspace, setWorkspace] = useState<Workspace>(object.workspaceId === personalWorkspace.id ? personalWorkspace : groupWorkspace);
  const [parentObjectId, setParentObjectId] = useState<number>(null);

  const data = useSelector(selectObjectTree(workspace.objectId, [WorkspaceObjectType.Folder]));

  const moveObject = useMoveObject();

  useEffect(() => {
    setParentObjectId(null);
  }, [workspace.id]);

  const onSave = useCallback(() => {
    return moveObject({
      objectId: object.id,
      parentObjectId,
      workspaceId: object.workspaceId,
    });
  }, [
    moveObject,
    object.id,
    object.workspaceId,
    parentObjectId,
  ]);

  const value: MoveObjectContextValue = {
    currentPath: path,
    data,
    object,
    onSave,
    onSelectParentObject: setParentObjectId,
    onSelectWorkspace: setWorkspace,
    parentObjectId,
    selectedWorkspace: workspace,
    workspaces: [personalWorkspace, groupWorkspace],
  };

  return (
    <MoveObjectContext.Provider value={value}>
      {props.children}
    </MoveObjectContext.Provider>
  );
};

export default MoveObjectContainer;
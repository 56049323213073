import { useMemo } from 'react';
import {
  useAggregateReviewFilterParams,
  useAggregateReviewPageDispatch,
  useAggregateReviewState,
} from '@containers';
import { ControlledTablePagination } from '@presentation/TablePagination';

type Props = {
  className?: string;
};

const Pagination = (props: Props) => {
  const params = useAggregateReviewFilterParams();
  const state = useAggregateReviewState();
  const dispatch = useAggregateReviewPageDispatch();

  const count = state.value?.totalCount || 0;

  const pageCount = useMemo(() => Math.ceil(count / params.limit), [params.limit, count]);

  return (
    <ControlledTablePagination
      className={props.className}
      goToPage={dispatch}
      pageIndex={params.index}
      pageCount={pageCount}
      pageSize={params.limit}
      totalCount={count} />
  );
};

export { Pagination };
export default Pagination;
import { isBefore, addYears } from 'date-fns';
import * as enums from '@enums';
import { Project } from '@/types';

export const hasCallsEnabled = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return !isProjectSurveyType(project);
};

const hasAnyEmployments = (state: Pick<Store.State, 'user'>) => {
  return state.user.profile.status.employmentCount > 0;
};

const isProfileStale = (state: Pick<Store.State, 'user'>) => {
  return isBefore(state.user.profile.status.lastUpdated, addYears(new Date(), -1));
};

const hasHourlyRateSet = (state: Pick<Store.State, 'user'>) => {
  return !!state.user.profile.hourlyRate;
};

const hasCompletedOnboarding = (state: Pick<Store.State, 'onboarding'>) => {
  return state.onboarding.completed;
};

const isOnboardingStale = (state: Pick<Store.State, 'onboarding'>) => {
  return state.onboarding.completed && isBefore(state.onboarding.completedOn, addYears(new Date(), -1));
};

export const needsComplianceTraining = (projectId: number, state: Pick<Store.State, 'onboarding'>) => {
  return !hasCompletedOnboarding(state) || isOnboardingStale(state);
};

export const needsSurveyTerms = (projectId: number, state: Store.State) => {
  return isComplianceSurveyRequired(projectId, state) ||
    isDocumentSigningRequired(projectId, state);
};

const hasComplianceSurvey = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return !!project.complianceSurveyVersionId;
};

export const hasPendingSignatureRequest = (projectId: number, state: Store.State) => {
  if (!isDocumentSigningRequired(projectId, state)) return false;

  return isAwaitingSignatureRequest(projectId, state);
};

const hasProjectSurvey = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return !!project.surveyVersionId;
};

export const isAwaitingSignatureRequest = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  const signature = state.documents.group[project.groupId];

  const required = signature && signature.statusCode === enums.HelloSignSignatureStatus.AwaitingSignature;

  return !!required;
};

export const isComplianceSurveyIncomplete = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return project.complianceSurveyStatus === enums.SurveyStatus.Incomplete;
};

export const isComplianceSurveyRequired = (projectId: number, state: Store.State) => {
  const required = hasComplianceSurvey(projectId, state)
                && isComplianceSurveyIncomplete(projectId, state);

  return !!required;
};

const useRateFromProjectLink = (projectId: number, state: Store.State) => {
  const linkUse = state.pipeline.me.linkUses[projectId];
  return !!linkUse?.hourlyRate;
};

export const isDocumentSigningRequired = (projectId: number, state: Store.State) => {
  const record = state.pipeline.me[projectId];
  return record.documentSigning === enums.DocumentSigning.Required;
};

export const isProjectSurveyIncomplete = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return project.surveyStatus === enums.SurveyStatus.Incomplete;
};

export const isProjectSurveyRequired = (projectId: number, state: Store.State) => {

  const required = hasProjectSurvey(projectId, state)
                && isProjectSurveyIncomplete(projectId, state)
                && !isExternalProject(projectId, state);

  return !!required;
};

export const isProjectMixedType = (item: Pick<Project, 'projectType'>) => {
  return item.projectType === enums.ProjectType.SurveyCall;
};

export const isProjectSurveyType = (item: Pick<Project, 'projectType'>) => {
  return item.projectType === enums.ProjectType.Survey;
};

export const needsRateSet = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];

  return !hasHourlyRateSet(state) &&
    !useRateFromProjectLink(projectId, state) &&
    !isProjectSurveyType(project);
};

export const needsEmploymentUpdated  = (state: Store.State) => {
  return !hasAnyEmployments(state) || isProfileStale(state);
};

/**
 * @summary Used to display the account profile setup for education and employment fields
 */
export const needsAccountProfileSetup = needsEmploymentUpdated;

export const includeRateSteps = (projectId: number, state: Store.State) => {
  return needsRateSet(projectId, state) || needsAccountProfileSetup(state);
};

export const isExternalProject = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return project.isExternal;
};

export const isNonMixedSurvey = (projectId: number, state: Store.State) => {
  const project = state.projects[projectId];
  return isProjectSurveyType({ projectType: project.projectType });
};
import { useCallback } from 'react';
import { FormButtons } from '@presentation/FormButtons';
import { QuestionText } from '@presentation/Survey';
import { useQuestionLogicBuilderState } from '@containers/SurveyBuilder.Logic/hooks/useQuestionLogicBuilderState';
import { useBaseLogicContext } from '@containers/SurveyBuilder.Logic/Context';
import { QuestionBaseLogicBuilder } from './BaseLogic.Builder';
import styles from './style/QuestionLogicBuilder.css';

export const DefaultQuestionLogicBuilder = () => {
  const { item, onCancel, onSubmit } = useQuestionLogicBuilderState();
  const { item: base } = useBaseLogicContext();

  const handleSubmit = useCallback(() => {

    onSubmit({
      base,
      response: [],
    });
  }, [
    base,
    onSubmit,
  ]);

  return (
    <div className={styles.root}>
      <QuestionText
        className={styles.questionText}
        textClassName={styles.text}
        item={item} />
      <div className={styles.logic}>
        <QuestionBaseLogicBuilder />
      </div>
      <FormButtons
        className={styles.formBtns}
        archivable={false}
        disabled={false}
        deletable={false}
        onSubmit={handleSubmit}
        onCancel={onCancel} />
    </div>
  );
};

export default DefaultQuestionLogicBuilder;
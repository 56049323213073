import { useEffect, useState } from 'react';
import { NetworkQualityLevel, NetworkQualityStats, Participant } from 'twilio-video';

export function useParticipantNetworkQualityLevel(participant: Participant) {
  const [stats, setStats] = useState(participant.networkQualityStats);

  useEffect(() => {
    const handleChange = (_: NetworkQualityLevel, stats: NetworkQualityStats) => setStats(stats);

    setStats(participant.networkQualityStats);

    participant.on('networkQualityLevelChanged', handleChange);
    return () => {
      participant.off('networkQualityLevelChanged', handleChange);
    };
  }, [participant]);

  return stats;
}

export default useParticipantNetworkQualityLevel;
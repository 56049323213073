import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateUserEmailSettings } from '@utils/api';
import Bucket from './Settings.Email.Bucket';

const selectSettings = (state: Store.State) => state.settings.email;

export function AnalystBuckets() {

  const settings = useSelector(selectSettings);
  const { mutateAsync } = useUpdateUserEmailSettings();

  const toggle = useCallback((field: string) => (value: boolean) => {
    return mutateAsync({
      [field]: value,
    });
  }, [mutateAsync]);

  return (
    <>
      <Bucket
        active={settings.projectUpdate}
        name='Project Update'
        description='Receive emails when new experts and survey responses are added to projects'
        onClick={toggle('projectUpdate')} />
      <Bucket
        active={settings.scheduling}
        name='Scheduling'
        description='Receive emails for pre-call scheduling correspondence'
        onClick={toggle('scheduling')} />
      <Bucket
        active={settings.callEvent}
        name='Call Event'
        description='Receive emails related to scheduled calls and key scheduling changes'
        onClick={toggle('callEvent')} />
      <Bucket
        active={settings.newMessages}
        name='New Message'
        description='Receive emails when experts message you on the platform'
        onClick={toggle('newMessages')} />
      <Bucket
        active={settings.projectSummary}
        name='Project Summary'
        description={`This email is sent once weekly to recap activity on the projects you're working on`}
        onClick={toggle('projectSummary')} />
    </>
  );
}

export default AnalystBuckets;
import { useToggle } from '@utils';
import { CompanyLogo } from 'components/CompanyLogo/CompanyLogo';
import { PlaceHolderCompanyLogo } from 'components/CompanyLogo/Placeholder';
import EditLogoModal from 'components/CompanyLogo/EditLogoModal';
import Pencil from 'components/icons/Pencil';
import styles from './style.css';

type Props = {
  onSave: (data: {
    blob: Blob;
    url: string;
  }) => unknown;
  pictureUrl: string;
  size?: number;
};

export const EditableCompanyLogo = ({
  size = 90,
  pictureUrl = null,
  onSave = () => {},
}: Props) => {
  const [modalVisible, toggleModal] = useToggle(false);

  return (
    <>
      <div className={styles.avatar}>
        {pictureUrl
        ? <CompanyLogo
          size={size}
          pictureUrl={pictureUrl} />
         : <PlaceHolderCompanyLogo size={size} />
        }
        <div className={styles.edit} />
        <div
          className={styles.editAvatar}
          onClick={toggleModal}>
          <Pencil size={16} />
        </div>
      </div>
      <EditLogoModal
        pictureUrl={pictureUrl}
        visible={modalVisible}
        onSave={onSave}
        onClose={toggleModal} />
    </>
  );
};

export default EditableCompanyLogo;
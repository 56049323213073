import { Route, Switch } from 'react-router-dom';
import { path } from '@consts';
import { useSurveyThemingLoading } from '@containers/Branding/hooks/useSurveyThemingLoading';
import { SurveyDisqualification } from '@screens/ProjectDisqualification';
import { ProjectSurveyArchived } from '@screens/ProjectSurveyArchived';
import * as Whitelabel from 'components/Whitelabel';
import { ActivityIndicator } from 'components/ActivityIndicator';

type Props = unknown;

export const SurveyOnboardingInvalidContainer = (props: Props) => {
  const loading = useSurveyThemingLoading();

  if (loading) {
    return (
      <ActivityIndicator show />
    );
  }

  return (
    <Switch>
      <Whitelabel.ScreenDecorator>
        <Route
          component={ProjectSurveyArchived}
          exact
          path={path.Onboarding.Projects.Closed} />
        <Route
          component={SurveyDisqualification}
          exact
          path={path.Onboarding.Projects.End} />
      </Whitelabel.ScreenDecorator>
    </Switch>
  );
};

SurveyOnboardingInvalidContainer.displayName = 'ProjectOnboarding.SurveyOnboardingInvalid.Container';
import { useState } from 'react';
import { UserProfileEnterpriseContainer } from '@containers/UserProfile/UserProfileEnterpriseContainer';
import { UserProfileIndividualContainer } from '@containers/UserProfile/UserProfileIndividualContainer';
import { useHasClientRole } from '@containers/User/hooks/useHasClientRole';
import { useSelectUser } from '@containers/Store';
import { ActivityIndicator } from 'components/ActivityIndicator';

export default function UserProfile(props: unknown) {
  const user = useSelectUser();
  const isClient = useHasClientRole();

  const [initiallyAuthenticated] = useState(user.state.authenticated);

  if (initiallyAuthenticated && !user.state.initialized) return <ActivityIndicator show />;

  return isClient
    ? <UserProfileEnterpriseContainer {...props} />
    : <UserProfileIndividualContainer {...props} />;
}

export { UserProfile };
import * as consts from '@consts';
import * as scheduler from '@containers/Scheduler/utils';
import { useProjectCapabilities, useSelectUser }  from '@containers/Store/hooks';
import { getLocationFor } from '@utils';
import { Button } from 'components/Button';
import { ButtonProps } from './interfaces';
import styles from './style/CallItem.css';

type Props =
    ButtonProps;

const ButtonSet = ({ item , ...props }: Props) => {
  const me = useSelectUser();
  const projectCapabities = useProjectCapabilities(item.projectId);

  const getProposeNewTimesLocation = () => {
    return getLocationFor.scheduling.proposing({
      callId: item.id,
      projectId: item.projectId,
      scheduleeId: item.userId,
      schedulerId: me.id,
    });
  };

  const getSelectingLocation = () => {
    return getLocationFor.scheduling.selecting({
      callId: item.id,
      projectId: item.projectId,
      scheduleeId: me.id,
      schedulerId: item.userId,
    });
  };

  const canProposeTimeChange = scheduler.calls.isConfirmed(item);
  const canCancelCall = scheduler.calls.canCancelCall(item);
  const canJoinConference = scheduler.calls.canJoinConference(item);
  const requiresAnalystAction = scheduler.calls.isAwaitingCreatorAction(item);

  return (
    <div className={styles.btns}>

      {requiresAnalystAction &&
        projectCapabities.canManageCalls &&
        <Button
          color="secondary"
          to={getSelectingLocation()}>
          Select Time
        </Button>}

      {canJoinConference &&
        <Button
          className={styles.btn}
          color="primary"
          to={`${consts.pathname.CONFERENCE}/${item.id}`}>
          Join Call
        </Button>}

      {canProposeTimeChange &&
        projectCapabities.canManageCalls &&
        <Button
          className={styles.btn}
          color="primary"
          to={getProposeNewTimesLocation()}>
          Reschedule
        </Button>}

      {canCancelCall &&
        <Button
          className={styles.btn}
          color="destructive"
          onClick={props.onCancelCall}>
          Cancel Call
        </Button>}
    </div>
  );
};

export default ButtonSet;
import { useHistory } from 'react-router-dom';
import * as consts from '@consts';
import { AccountProfileBioContainer, AccountProfileFieldsStepper, useSelectUser } from '@containers';
import { path } from '@consts';
import { getLocationFor, slugify } from '@utils';
import { LinearProgress } from 'components/Progress/LinearProgress';
import { Stepper } from 'components/Stepper';
import { useStepperContext } from 'components/Stepper/hooks/useStepperContext';
import { EducationSetup } from './EducationSetup';
import { EmploymentSetup } from './EmploymentSetup';
import { EnrichmentCheck } from './EnrichmentCheck';
import { SummarySetup } from './SummarySetup';
import styles from './style/AccountProfileSetup.css';

const routepath = {
  education: `${path.Account.Profile.Setup}/education`,
  employment: `${path.Account.Profile.Setup}/employment`,
  summary: `${path.Account.Profile.Setup}/summary`,
};

type Props = unknown;

const AccountProfileSetup = (props: Props) => {
  const history = useHistory();
  const me = useSelectUser();

  const slug = slugify({
    id: me.id,
    name: me.profile.fullname,
  });

  const loc = getLocationFor.me.profile({
    from: consts.pathname.HOME,
    slug,
    userId: me.id,
  });

  const handleComplete = () => {
    history.push(loc);
  };

  return (
    <AccountProfileFieldsStepper>
      <Stepper>
        <ProgressBar />
        <Stepper.Route
          exact
          path={path.Account.Profile.Setup}>
          <EnrichmentCheck redirect={{ pathname: routepath.employment }} />
        </Stepper.Route>
        <Stepper.Route
          exact
          path={routepath.employment}>
          <EmploymentSetup
            navigation={{ to: routepath.education }} />
        </Stepper.Route>
        <Stepper.Route
          exact
          path={routepath.education}>
          <EducationSetup
            navigation={{ to: routepath.summary }} />
        </Stepper.Route>
        <Stepper.Route
          exact
          path={routepath.summary}>
          <AccountProfileBioContainer>
            <SummarySetup
              navigation={{ onClick: handleComplete }} />
          </AccountProfileBioContainer>
        </Stepper.Route>
      </Stepper>
    </AccountProfileFieldsStepper>
  );
};

function ProgressBar() {
  const step = useStepperContext();
  const progress = ((step.index + 1) / step.count) * 100;

  return (
    <LinearProgress
      className={styles.progress}
      value={progress} />
  );
}

export { AccountProfileSetup };
export default AccountProfileSetup;
import { getFileExtension } from '@utils';

function assertHasValidFileSize(file: File) {
  const MaxFileSize = 300 * Math.pow(10, 6); // 300mb

  if (file.size === 0) {
    return {
      message: 'File is zero bytes',
      success: false,
    };
  }

  if (file.size > MaxFileSize) {
    return {
      message: 'File is too large',
      success: false,
    };
  }

  return {
    success: true,
  };
}

function isFile(file: File) {
  if (file.type) return true;

  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = function(e) {
      return resolve(true);
    };
    reader.onerror = function(e) {
      return resolve(false);
    };
    reader.readAsArrayBuffer(file.slice(0, 5));
  });
}

async function assertIsFile(file: File) {
  const isValidFile = await isFile(file);

  if (!isValidFile) {
    return {
      message: 'Not a file',
      success: false,
    };
  }

  return {
    success: true,
  };
}

function assertIsAllowedFile(file: File) {
  // note: reject svg because of XSS allowed in untrusted svg uploads, they must be rasterized on upload to safely use
  // note: can refactor to instead check allowed image types (png+jpg+gif) for mime prefix `image/` since there are many
  //       legacy/other image types that could be exploitable

  if (file.type.toLowerCase().includes('svg')) {
    return {
      message: 'SVG images are not supported',
      success: false,
    };
  }

  const ext = getFileExtension(file.name);

  if (ext.toLowerCase().includes('svg')) {
    return {
      message: 'SVG images are not supported',
      success: false,
    };
  }

  return {
    success: true,
  };
}

export async function validateFile(file: File) {
  const hasValidSize = assertHasValidFileSize(file);

  if (!hasValidSize.success) {
    return hasValidSize;
  }

  const isValidFile = await assertIsFile(file);

  if (!isValidFile.success) {
    return isValidFile;
  }

  const isAllowedFile = assertIsAllowedFile(file);

  if (!isAllowedFile.success) {
    return isAllowedFile;
  }

  return {
    success: true,
  };
}
import { useCallback, useMemo, useState } from 'react';
import { ProjectType } from '@enums';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { ProjectReportType, Project, ProjectReportDataType } from '@/types';
import { ReportSocketContainer, useReportKey } from '@containers/Reports';
import { DropDown } from 'components/DropDown';
import { useModal } from 'components/Modal/hooks';
import { Modal, ModalProps } from 'components/Modal/Modal';
import { Header } from 'components/Modal/Header';
import Toast from 'components/Toast';
import { useDownloadProjectTypeReport } from './hooks/useDownloadProjectReport';
import styles from './style/ProjectReportModal.css';
import { useTrackProjectReportDownload } from './hooks/useTrackProjectReportDownload';

type Props = {
  isParentReport?: boolean;
  project: Pick<Project, 'id' | 'parentId' | 'projectType'>;
} & Pick<ModalProps, 'open' | 'onClose'>;

type DropdownItem = {
  id: ProjectReportType;
  value: string;
};

type ModalContentProps = {
  dataType: ProjectReportDataType;
  title: string;
} & Props;

export const ExportCallModal = (props: Props) => {
  return (
    <ReportSocketContainer onDownload={props.onClose}>
      <ModalContents
        {...props}
        dataType={'call'}
        title={'Export Call Data'} />
    </ReportSocketContainer>
  );
};

export const ExportSurveyModal = (props: Props) => {
  return (
    <ReportSocketContainer onDownload={props.onClose}>
      <ModalContents
        {...props}
        dataType={'survey'}
        title={'Export Survey Data'} />
    </ReportSocketContainer>
  );
};

const ModalContents = ({ onClose, open, project, isParentReport, dataType, title }: ModalContentProps) => {

  const [format, setFormat] = useState<ProjectReportType>();
  const [reportKey, setReportKey] = useReportKey();

  const {
    isFetching,
    refetch: download,
  } = useDownloadProjectTypeReport({
    format,
    isParentReport,
    projectId: project.id,
    projectParentId: project.parentId,
    dataType,
  }, {
    onSuccess: data => {
      setReportKey(data.key);
    },
    onError: () => {
      Toast.error({
        body: 'Project Report',
        title: 'There was an error trying to download the report.',
      });
    },
  });

  const track = useTrackProjectReportDownload({ projectId: project.id });

  const handleSubmit = useCallback(() => {
    track(format);
    download();
  }, [
    download,
    format,
    track,
  ]);

  const dropdownItems: DropdownItem[] = useMemo(() => {
    return dataTypeFormats[dataType]
      .map(m => ({
        id: m,
        value: m.toLocaleUpperCase(),
      }));
  }, [dataType]);

  const handleSelect = useCallback((item: DropdownItem) => {
    setFormat(item.id);
  }, []);

  return (
    <Modal className={styles.root} open={open} onClose={onClose}>
      <Header text={title} />
      <div className={styles.dropdown}>
        <DropDown<DropdownItem>
          items={dropdownItems}
          onSelect={handleSelect}
          text={format?.toLocaleUpperCase() || 'Choose Format'}
          getItemValue={(item: DropdownItem) => item.value} />
      </div>
      <div className={styles.btns}>
        <ButtonActivityIndicator
          className={styles.btn}
          disabled={!format}
          onClick={handleSubmit}
          loading={isFetching || !!reportKey}>
          Download
        </ButtonActivityIndicator>
      </div>
    </Modal>
  );
};

const dataTypeFormats = {
  'call': ['csv', 'sav', 'xlsx', 'pdf'],
  'survey': ['csv', 'ppt', 'sav', 'xlsx'],
} as Record<ProjectReportDataType, ProjectReportType[]>;

export const useExportCallModal = () => useModal(ExportCallModal);

export const useExportSurveyModel = () => useModal(ExportSurveyModal);
import { BaseQuestionCondition } from './survey.question.conditions.base';

export namespace SurveyRichText {
  export enum NodeType {
    Paragraph = 'paragraph',
    PipedResponse = 'piped',
    TemplateCategory = 'category',
    TemplateTarget = 'target',
    TemplateCompetitor = 'competitor',
    Text = 'text',
  }

  export type RootNode = {
    type: 'doc';
    content: ParagraphNode[];
  };

  export type RichTextValue = RootNode;

  export type TextNode = {
    type: NodeType.Text;
    text: string;
  };

  export type PipedResponseNode = {
    attrs: {
      text?: string;
    } & BaseQuestionCondition;
    type: NodeType.PipedResponse;
  };

  export type CategoryNode = {
    attrs: {
      category: string;
    };
    type: NodeType.TemplateCategory;
  };

  export type TargetNode = {
    attrs: {
      target: string;
    };
    type: NodeType.TemplateTarget;
  };

  export type CompetitorNode = {
    attrs: {
      id: string;
      competitor: string;
    };
    type: NodeType.TemplateCompetitor;
  };

  export type ParagraphContent =
    TextNode |
    CategoryNode |
    CompetitorNode |
    PipedResponseNode |
    TargetNode
  ;

  export type ParagraphNode = {
    content: ParagraphContent[];
    type: NodeType.Paragraph;
  };

}

export type SurveyQuestionValue =
  SurveyRichText.RichTextValue |
  string;
import { TopicPostsDeleteContainer } from '@containers/Topic/TopicPostsDeleteContainer';
import { ContentFeed } from '@/types';
import { Feed } from 'components/ContentFeed';
import { Card } from './Layout';
import styles from './style/Related.css';

type Props = {
  items: ContentFeed.Post[];
};

export function PostsFeed({ items }: Props) {
  return (
    <>
      {items.map(post =>
        <TopicPostsDeleteContainer
          key={post.id}
          postId={post.id}>
          <Card
            className={styles.post}
            key={post.id}>
            <Feed.Post item={post} />
            <Feed.SupplementalActions item={post} />
          </Card>
        </TopicPostsDeleteContainer>)}
    </>
  );
}
import { memo, useCallback, useState } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { usePopper } from 'react-popper';
import { cx } from '@utils';
import { Focusable } from 'components/Focusable';
import { useSameWidthModifier, useZIndexModifier } from 'components/Popper';
import { Portal } from 'components/Portal';
import { ItemProps, ItemsProps, IDropDownItem, Props, SelectionProps } from './interfaces';
import styles from './style.css';

const Item = memo(<T extends IDropDownItem>({ onSelect, ...props }: ItemProps<T>) => {
  const handleMouseDown = useCallback((item: T) => () => {
    onSelect(item);
  }, [onSelect]);

  return (
    <div
      className={styles.item}
      onMouseDown={handleMouseDown(props.item)}>
      {props.value}
    </div>
  );
});

const Items = memo(<T extends IDropDownItem>(props: ItemsProps<T>) => {

  return (
    <div className={styles.list}>
      {props.items.map(item =>
        <Item
          item={item}
          key={item.id}
          onSelect={props.onSelect}
          value={props.getItemValue(item)} />
      )}
    </div>
  );
});

const Selection = <T extends IDropDownItem>(props: SelectionProps<T>) => {
  return (
    <Focusable autoFocus={props.autoFocus} disabled>
      <div
        className={cx(styles.input, {
          [styles.disabled]: props.disabled,
        })}
        tabIndex={0}
        onClick={props.toggleVisibility}>
        <div className={`${styles.selectedText} ${props.textClass}`}>
          {props.text}
        </div>
        <div className={styles.arrowDown} />
      </div>
    </Focusable>
  );
};

export const DropDown = <T extends IDropDownItem>({
  items = [],
  onSelect,
  ...props
}: Props<T>) => {
  const [open, setOpen] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);

  const toggleVisibility = useCallback(() => {
    if (props.disabled) return;

    setOpen(true);
  }, [props.disabled]);

  const handleBlur = useCallback((e: MouseEvent) => {
    if (!referenceElement.contains(e.target as Node)) {
      setOpen(false);
    }
  }, [referenceElement]);

  const handleSelection = useCallback((data: T) => {
    onSelect(data);
    setOpen(false);
  }, [onSelect, setOpen]);

  const sameWidthModifier = useSameWidthModifier();
  const zIndexModifier = useZIndexModifier({ zIndex: 8 });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      sameWidthModifier,
      zIndexModifier,
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top'],
        },
      },
    ],
    placement: 'bottom-start',
  });

  return (
    <>
      <div
        ref={setReferenceElement}
        className={styles.root}>
        <Selection
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          open={open}
          text={props.text}
          textClass={props.textClass}
          toggleVisibility={toggleVisibility} />
      </div>
      {open &&
        <Portal>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={handleBlur}
            touchEvent='onTouchStart'>
            <div
              className={styles.popper}
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}>
              <Items
                getItemValue={props.getItemValue}
                items={items}
                onSelect={handleSelection} />
            </div>
          </ClickAwayListener>
        </Portal>
      }
    </>
  );
};

export default DropDown;
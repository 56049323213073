import { AdHocCallRequestPayment as Payment } from './AdHoc.Payment';
import { AdHocCallRequestConfirmation as Confirmation } from './AdHoc.Confirmation';
import { AdHocCallRequestDetails as Details } from './AdHoc.Details';
import { AdHocCallRequestNextSteps as NextSteps } from './AdHoc.NextSteps';

export const adHocScreens = [
  Details,
  Payment,
  Confirmation,
  NextSteps,
];
import { cx } from '@utils';
import { QuotedTextProps } from './interfaces';
import styles from './style/QuotedText.css';

export const QuotedText = (props: QuotedTextProps) => (
  <>
    <span className={cx(styles.root, props.quoteClassName)}>“</span>
    {props.children}
    <span className={cx(styles.root, props.quoteClassName)}>”</span>
  </>
);

export default QuotedText;
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as api from '@api';
import { CompletedSurveyContainer } from '@containers';
import * as enums from '@enums';
import { ProjectSurveyDetails } from '@/types';
import ActivityIndicator from 'components/ActivityIndicator';
import { ProjectSurveyApprovalView } from './View';
import { ProjectSurveyApprovalReview } from './Review';

const mapState = (state: Store.State) => state.group;

const ComplianceSurveyReview = () => {
  const group = useSelector(mapState);
  const params = useParams<{ responseId: string; }>();
  const [response, setResponse] = useState<ProjectSurveyDetails>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    api.groups.compliance.fetchSurveyResponseReview({
      groupId: group.id,
      responseId: +params.responseId,
    })
    .then(setResponse)
    .then(() => setLoading(false));
  }, [group.id, params.responseId]);

  if (loading || !response) return <ActivityIndicator show />;

  return (
    <CompletedSurveyContainer response={response.survey}>
      {
        response.survey.approvalStatusId === enums.ApprovalStatus.NeedsApproval
          ? <ProjectSurveyApprovalReview item={response} onUpdate={setResponse} />
          : <ProjectSurveyApprovalView item={response} />
      }
    </CompletedSurveyContainer>
  );
};

export { ComplianceSurveyReview };
export default ComplianceSurveyReview;
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { cx } from '@utils';
import { SurveyRichText } from '@/types/survey.rich-text';
import { SurveyQuestionText } from 'components/Survey.QuestionText';
import { SurveyQuestionNumber, SurveySectionNumber } from './QuestionNumber';
import {
  QuestionTextProps,
  SurveySectionTextProps,
  QuestionTextBaseProps,
} from './interfaces';
import styles from './style/QuestionText.css';

export const QuestionRawText = (props: QuestionTextProps<string>) => {

  return (
    <QuestionTextBase
      ordinal={props.item.ordinal}
      className={props.className}
      textClassName={props.textClassName}>
      {props.item.value}
      {props.children}
    </QuestionTextBase>
  );
};

export const QuestionText = (props: QuestionTextProps<SurveyRichText.RichTextValue>) => {
  return (
    <QuestionTextBase
      ordinal={props.item.ordinal}
      className={props.className}>
      <SurveyQuestionText
        editorClassName={props.textClassName}
        value={props.item.value} />
      {props.children}
    </QuestionTextBase>
  );
};

export const QuestionTextBase = (props: QuestionTextBaseProps) => {

  const { palette, theme } = useSurveyThemingPalette();
  const style = theme ? { color: palette.primary.main } : {};

  return (
    <div className={cx(styles.root, props.className)}>
      <SurveyQuestionNumber
        ordinal={props.ordinal}
        style={style} />
      <div className={cx(styles.value, props.textClassName)}>
        {props.children}
      </div>
    </div>
  );
};

export const SurveySectionText = (props: SurveySectionTextProps) => {
  return (
    <div className={cx(styles.root, props.className)}>
      <SurveySectionNumber
        ordinal={props.item.ordinal} />
      <div className={styles.value}>
        {props.item.name}
      </div>
    </div>
  );
};
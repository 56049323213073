import { useCallback, useEffect } from 'react';
import { useFetchFilePreview } from './hooks';
import { FileSocketEvent } from '@enums';
import * as ws from '@services/websocket';

type Props =
  IWorkspaceFileId &
  ChildrenProps;

export const FilePreviewSocketContainer = ({ children, fileId }: Props) => {

  const fetchPreview = useFetchFilePreview();

  const register = useCallback(() => {
    ws.files.on(FileSocketEvent.PreviewAvailable, fetchPreview);

    ws.files.open();
    ws.files.subscribe({ fileId });
  }, [
    fetchPreview,
    fileId,
  ]);

  const unregister = useCallback(() => {
    ws.files.close();

    ws.files.off(FileSocketEvent.PreviewAvailable, fetchPreview);

  }, [
    fetchPreview,
  ]);

  useEffect(() => {
    register();

    return () => {
      unregister();
    };
  }, [
    register,
    unregister,
  ]);

  return (
    <>
      {children}
    </>
  );
};

export default FilePreviewSocketContainer;
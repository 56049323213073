import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { pathname } from '@consts';
import { useMatchProjectIdFromUrl } from '@containers/GroupProject/hooks';
import { useFetchProjectMetrics } from '@utils/api';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { Button } from 'components/Button';
import { ProjectProgressBar, useProjectReportModal } from 'components/GroupProject';
import { useProjectSurveyMetrics } from './hooks/useSurveyMetrics';
import { AdvancedQuotaProgress } from './AdvancedQuotaProgress';
import { BasicQuotasProgressTable } from './BasicQuotasTable';
import { SurveyProgressTable } from './ProgressTable';
import { SurveyTerminationsTable } from './TerminationsTable';
import { formatAvgDuration } from './utils';
import { useSurveyMonitoringPasswordRequired } from './Context';
import { useSurveyMonitoringAccessModal } from './AccessModal';
import styles from './style/SurveyMonitoring.css';

export const SurveyMonitoringDashboard = () => {
  const { projectId } = useMatchProjectIdFromUrl();
  const [passwordRequired, setPasswordRequired] = useSurveyMonitoringPasswordRequired();
  const projectMetrics = useFetchProjectMetrics({ projectId }, {
    enabled: passwordRequired === false,
  });
  const history = useHistory();
  const surveyMetrics = useProjectSurveyMetrics({ projectId }, {
    onError: e => {
      if (e.response.status === 403) {
        setPasswordRequired(true);
      } else {
        history.push(pathname.Home);
      }
    },
    onSuccess: () => {
      setPasswordRequired(false);
    },
    retry: false,
  });
  const [toggleGrantAccessModal, GrantAccessModal] = useSurveyMonitoringAccessModal();
  const [toggleReportModal, ReportModal] = useProjectReportModal();

  if (surveyMetrics.isLoading || projectMetrics.isLoading) return <ActivityIndicator show />;

  const creator = `${surveyMetrics.data.project.creator.firstName} ${surveyMetrics.data.project.creator.lastName}`;
  const createdOn = format(surveyMetrics.data.project.createdOn, 'MM/dd/yyyy');

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.header}>
          <div className={styles.details}>
            <h1 className={styles.name}>{surveyMetrics.data.project.name}</h1>
            <div className={styles.age}>Created by {creator} on {createdOn}</div>
          </div>
          <div className={styles.actions}>
            {surveyMetrics.data.isDashboardPublic &&
              <>
                <Button
                  className={styles.grantAccess}
                  variant="brick"
                  onClick={toggleGrantAccessModal}>
                  Grant Access
                </Button>
                <GrantAccessModal
                  project={{
                    id: projectId,
                  }} />
              </>
            }
            <div className={styles.download} onClick={toggleReportModal}>Download Report</div>

            <ReportModal
              project={surveyMetrics.data.project} />
          </div>
        </div>

        <ProjectProgressBar
          className={styles.progress}
          metrics={projectMetrics.data}
          project={surveyMetrics.data.project} />

        <div>
          <div className={styles.title}>
            Avg Duration: <span className={styles.subtitle}>{formatAvgDuration(surveyMetrics.data.stats.avgDuration)}</span>
          </div>
        </div>

        <div className={styles.topRow}>
          <div className={styles.respondents}>
            <div className={styles.title}>
              Respondents <span className={styles.subtitle}>({projectMetrics.data.surveys.totalCount})</span>
            </div>
            <SurveyProgressTable
              metrics={projectMetrics.data} />
          </div>
          <div className={styles.terminations}>
            <div className={styles.title}>Terminations</div>
            <SurveyTerminationsTable
              items={surveyMetrics.data.terminations} />
          </div>
        </div>

        {!!surveyMetrics.data.progress.basic.length &&
          <div className={styles.basic}>
            <div className={styles.title}>Basic Quotas</div>
            <BasicQuotasProgressTable
              items={surveyMetrics.data.progress.basic} />
          </div>
        }

        {!!surveyMetrics.data.progress.advanced.length &&
          <div className={styles.advanced}>
            {/* <div className={styles.title}>Advanced Quotas</div> */}
            {surveyMetrics.data.progress.advanced.map((m, i) => (
              <AdvancedQuotaProgress
                key={i}
                item={m} />
            ))}
          </div>
        }

      </div>
    </div>
  );
};

export default SurveyMonitoringDashboard;
import { forwardRef } from 'react';
import { Tooltip } from '@presentation/Tooltip';
import { cx } from '@utils';
import { AccordionProps } from './interfaces';
import styles from './style.css';

type Props = {
  children: string;
  count:    number;
} & Pick<AccordionProps,
    | 'className'
    | 'showCount'
    | 'tooltipText'>;

type LabelProps =
  Pick<Props, 'children' | 'className' | 'count' | 'showCount'>;

const Label = forwardRef<HTMLDivElement, LabelProps>(({
  children,
  className,
  count,
  showCount,
  ...props
}, ref) => {
  const renderCount = () => {
    return showCount &&
      <span className={styles.count}> {count}</span>;
  };

  return (
    <div
      {...props}
      className={cx(styles.title, className)}
      ref={ref}>
      <span>{children}{renderCount()}</span>
    </div>
  );
});

const AccordionLabel = ({
  tooltipText,
  ...props
}: Props) => {

  if (tooltipText) {
    return (
      <Tooltip
        title={tooltipText}
        tooltipstyles={{
          lineHeight: '20px',
          padding: '15px',
          width: '225px',
        }}>
        <Label  {...props} />
      </Tooltip>
    );
  } else {
    return <Label  {...props} />;
  }

};

export { AccordionLabel as Label };
export default AccordionLabel;
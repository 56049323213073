import { SurveyQuestionTaggingValueState, SurveyQuestionTaggingActionType } from '@enums';
import { OptionResponseTagging } from '@/types';
import { cx } from '@utils';
import styles from './style/OptionPreviewTaggingItem.css';

type Props = {
  className?: string;
  item: OptionResponseTagging;
}

export default function OptionPreviewTaggingItem({ className, item }: Props) {
  if (!item) return null;

  const action = item.action[0];

  const state = ValueStateDisplayMap[item.condition.value.state];
  const actionType = ActionTypeDisplayMap[action.type];
  const entity = action.entities[0].name ?? `N/A (${action.entities[0].id})`;

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.content}>
        If <span className={styles.state}>{state}</span>, tag as <span className={styles.action}>{actionType}</span> {`"`}<span className={styles.entity}>{entity}</span>{`"`}
      </div>
    </div>
  );
}

export { OptionPreviewTaggingItem };

const ValueStateDisplayMap = {
  [SurveyQuestionTaggingValueState.Selected]: 'Selected',
  [SurveyQuestionTaggingValueState.NotSelected]: 'Not Selected',
};

const ActionTypeDisplayMap = {
  [SurveyQuestionTaggingActionType.ProductPositiveAffinity]: 'User of',
  [SurveyQuestionTaggingActionType.ProductNegativeAffinity]: 'Not User of',
};

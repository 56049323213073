import {
  RepeatSurvey,
  MatrixGridQuestion,
  MatrixMultiselectQuestion,
  MatrixSliderQuestion,
  NumberInputTableQuestion,
  SurveyAggregate,
  SurveyFiltering,
  SurveyQuestion,
} from '@/types';
import { SurveyQuestionType } from '@enums';
import { intersect, indexBy, distinct } from '@utils/array';
import { sum, avg } from '@utils/math';

const userFilterMethods: Record<SurveyQuestionType, SurveyFiltering.UserFilterFunction> = {
  [SurveyQuestionType.LongTextResponse]: applyTextResponseFilter,
  [SurveyQuestionType.MatrixGrid]: applyMatrixGridFilter,
  [SurveyQuestionType.MultipleChoice]: applyMultiselectFilter,
  [SurveyQuestionType.Multiselect]: applyMultiselectFilter,
  [SurveyQuestionType.Ranking]: applyRankingFilter,
  [SurveyQuestionType.ShortTextResponse]: applyTextResponseFilter,
  [SurveyQuestionType.MaxDifference]: applyMaxDiffFilter,
  [SurveyQuestionType.ConjointAnalysis]: applyConjointFilter,
  [SurveyQuestionType.Sliders]: applySlidersFilter,
  [SurveyQuestionType.MatrixMultiselect]: applyMatrixMultiselectFilter,
  [SurveyQuestionType.NumberInputTable]: applyNumberInputTableFilter,
};

export const validUserFilterMethods: Record<SurveyQuestionType, SurveyFiltering.FilterFunction> = {
  [SurveyQuestionType.LongTextResponse]: getValidTextUsers,
  [SurveyQuestionType.Sliders]: getValidSliderUsers,
  [SurveyQuestionType.MultipleChoice]: getValidMultiselectUsers,
  [SurveyQuestionType.Multiselect]: getValidMultiselectUsers,
  [SurveyQuestionType.Ranking]: null,
  [SurveyQuestionType.ShortTextResponse]: getValidTextUsers,
  [SurveyQuestionType.MaxDifference]: null,
  [SurveyQuestionType.ConjointAnalysis]: null,
  [SurveyQuestionType.MatrixGrid]: null,
  [SurveyQuestionType.MatrixMultiselect]: null,
  [SurveyQuestionType.NumberInputTable]: null,
};

function hasValidFilters(filter: SurveyFiltering.FilterEntries) {
  return getNumberOfActiveFilters(filter) > 0;
}

function isFilterValid(filter: SurveyFiltering.QuestionFilter) {
  if (!filter) return false;
  if (filter.type == 'slider-filter') {
    return !isNaN(filter.value) && !isNaN(filter.optionId);
  }

  if (filter.type == 'option-filter') {
    return !!filter.optionIds && filter.optionIds.length;
  }

  if (filter.type == 'text-filter') {
    return !!filter.text;
  }

  return false;
}

export function getNumberOfActiveFilters(filter: SurveyFiltering.FilterEntries) {
  if (!filter || !filter.filters) return 0;

  return filter.filters.filter(f => isFilterValid(f.filter)).length;
}

export function getFilteredUserIds(filter: SurveyFiltering.FilterEntries, questions: SurveyQuestion[], responses: SurveyAggregate.QuestionMap) {
  if (!hasValidFilters(filter)) {
    return null;
  }

  const filters = filter.filters;

  const questionMap = indexBy(questions, q => q.base.id);

  const userArrays = filters.filter(f => isFilterValid(f.filter)).map(f => {
    const questionResponses = responses[f.questionId];

    const questionType = questionMap[f.questionId].typeId;
    return validUserFilterMethods[questionType](f.filter, questionResponses.data);
  });

  //TODO: support AND/OR logic here
  return distinct(userArrays.reduce(intersect));
}

export function filterResponses(filter: SurveyFiltering.FilterEntries, questions: SurveyQuestion[], responses: SurveyAggregate.QuestionMap) {
  if (!hasValidFilters(filter)) {
    return responses;
  }

  const userIds = getFilteredUserIds(filter, questions, responses);

  const questionMap = indexBy(questions, q => q.base.id);

  const filteredResponses = Object.keys(responses).reduce<SurveyAggregate.QuestionMap>((acc, x) => {
    const k = +x;
    const questionType = questionMap[k].typeId;

    acc[k] = {
      data: userFilterMethods[questionType]({ userIds }, responses[k].data),
      total: userIds.length,
    };

    return acc;
  }, {});

  return filteredResponses;
}

export function filterRepeatResponses(filter: SurveyFiltering.FilterEntries, questions: SurveyQuestion[], repeatData: RepeatSurvey.Data) {
  if (!hasValidFilters(filter)) {
    return repeatData;
  }
  else {
    //TODO: rebuild aggregate data
    return {
      ...repeatData,
      historical: repeatData.projectIds.reduce<RepeatSurvey.HistoricalData>((acc, i) => {
        acc[i] = filterResponses(filter, questions, repeatData.historical[i]);

        return acc;
      }, {}),
    };
  }
}

export function applyMultiselectFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Multiselect]) {
  const filtered = Object.keys(data).reduce<SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Multiselect]>((acc, k) => {
    const option = data[+k];

    acc[+k] = {
      ...option,
      userIds: option.userIds.filter(u => filter.userIds.includes(u)),
    };

    return acc;
  }, {});

  const total = sum(Object.keys(data).map(k => filtered[+k].userIds.length));

  Object.values(filtered).forEach(v => {
    v.pct = (v.userIds.length / total) * 100;
  });

  return filtered;
}

export function applyTextResponseFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.LongTextResponse]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.LongTextResponse] {
  return Object.keys(data).filter(k => filter.userIds.includes(+k)).reduce<SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.LongTextResponse]>((acc, k) => {
    acc[+k] = data[+k];
    return acc;
  }, {});
}

export function applySlidersFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Sliders]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Sliders] {
  const rows = Object.keys(data.rows).reduce((acc, x) => {
    const k = +x;

    const entry: MatrixSliderQuestion.Aggregate.RowResult = {
      ...data.rows[k],
      userIds: data.rows[k].userIds.filter(u => filter.userIds.includes(u)),
      responses: Object.entries(data.rows[k].responses).reduce((acc, [k, v]) => {
        if (filter.userIds.includes(+k)) {
          acc[k] = v;
        }
        return acc;
      }, {}),
    };

    entry.avg = entry.userIds.length
      ? avg(Object.values(entry.responses).filter(Boolean))
      : 0;

    acc[k] = entry;

    return acc;
  }, {});

  return {
    rows,
  };
}

export function applyNumberInputTableFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.NumberInputTable]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.NumberInputTable] {
  return Object.keys(data).reduce((acc, rowId) => {
    const rowData = data[+rowId];

    const options = Object.keys(rowData.options).reduce((acc2, optionId) => {
      const optionData = rowData.options[+optionId];
      const users = Object.keys(optionData.users)
        .filter(userId => filter.userIds.includes(+userId))
        .reduce((acc, userId) => ({
          ...acc,
          [+userId]: optionData.users[+userId],
        }), {} as {
          [userId: number]: number;
        });

      return {
        ...acc2,
        [+optionId]: {
          avg: Object.keys(users).length
            ? avg(Object.values(users).filter(Boolean))
            : 0,
          users,
        } as NumberInputTableQuestion.Aggregate.RowOptionResult,
      };
    }, {} as {
      [optionId: number]: {
        avg: number;
        users: {
          [userId: number]: number;
        };
      };
    });

    const rowAvg = avg(Object.values(options).map(m => m.avg));

    return {
      ...acc,
      [+rowId]: {
        avg: rowAvg,
        options,
      },
    };
  }, {} as SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.NumberInputTable]);
}

export function applyMatrixGridFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MatrixGrid]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MatrixGrid] {
  return Object.keys(data).reduce((acc, rowId) => {
    const rowData = data[+rowId];

    const options = Object.keys(rowData.options).reduce((acc2, optionId) => {
      const optionData = rowData.options[+optionId];
      const userIds = optionData.userIds.filter(u => filter.userIds.includes(u));
      return {
        ...acc2,
        [+optionId]: {
          ...optionData,
          pct: (userIds.length / filter.userIds.length) * 100,
          userIds,
        } as MatrixGridQuestion.Aggregate.RowOptionResult,
      };
    }, {} as MatrixGridQuestion.Aggregate.RowOptions);

    return {
      ...acc,
      [+rowId]: {
        ...rowData,
        missingUserIds: rowData.missing.userIds.filter(u => filter.userIds.includes(u)),
        options,
      },
    };
  }, {} as SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MatrixGrid]);
}

export function applyMatrixMultiselectFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MatrixMultiselect]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MatrixMultiselect] {
  return Object.keys(data).reduce((acc, rowId) => {
    const rowData = data[+rowId];

    const options = Object.keys(rowData.options).reduce((acc2, optionId) => {
      const optionData = rowData.options[+optionId];
      const userIds = optionData.userIds.filter(u => filter.userIds.includes(u));
      return {
        ...acc2,
        [+optionId]: {
          userIds,
        } as MatrixMultiselectQuestion.Aggregate.RowOptionResult,
      };
    }, {} as { [optionId: number]: { userIds: number[]; }; });

    return {
      ...acc,
      [+rowId]: {
        ...rowData,
        options,
      },
    };
  }, {} as SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MatrixMultiselect]);
}

function filterStackedBarChart(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.StackedBarChart.Data): SurveyAggregate.StackedBarChart.Data {
  const indexValues = data.indexes.ids.reduce((acc, i) => {
    const val = data.indexes.values[i];

    const filteredKeys = Object.keys(val.keys).reduce<SurveyAggregate.StackedBarChart.IndexKeys>((acc, x) => {
      const k = x;

      const userIds = val.keys[k].userIds.filter(u => filter.userIds.includes(u));
      acc[k] = {
        ...val.keys[k],
        pct: (userIds.length / filter.userIds.length) * 100,
        userIds,
      };

      return acc;
    }, {} as SurveyAggregate.StackedBarChart.IndexKeys);

    acc[i] = {
      ...val,
      keys: filteredKeys,
    };

    return acc;
  }, {});

  return {
    ...data,
    indexes: {
      ...data.indexes,
      values: indexValues,
    },
  };
}

export function applyRankingFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Ranking]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Ranking] {
  return filterStackedBarChart(filter, data);
}

export function applyMaxDiffFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MaxDifference]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.MaxDifference] {
  const options = Object.keys(data.options).reduce((acc, x) => {
    const k = +x;
    const responses = data.options[k].responses;

    const filteredResponses = {
      ...responses,
      leftUserIds: responses.leftUserIds.filter(u => filter.userIds.includes(u)),
      rightUserIds: responses.rightUserIds.filter(u => filter.userIds.includes(u)),
      ncUserIds: responses.ncUserIds.filter(u => filter.userIds.includes(u)),
    };

    acc[k] = { ...data.options[k], responses: filteredResponses };

    return acc;
  }, {});

  return { options };
}

export function applyConjointFilter(filter: SurveyFiltering.UserFilter, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.ConjointAnalysis]): SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.ConjointAnalysis] {
  const attributes = Object.keys(data.attributes).reduce((acc, x) => {
    const k = +x;

    const filteredResponses = { ...data.attributes[k].responses, userIds: data.attributes[k].responses.userIds.filter(u => filter.userIds.includes(u)) };

    acc[k] = { ...data.attributes[k], responses: filteredResponses };

    return acc;
  }, {});

  const levels = Object.keys(data.levels).reduce((acc, x) => {
    const k = +x;

    const filteredResponses = { ...data.levels[k].responses, userIds: data.levels[k].responses.userIds.filter(u => filter.userIds.includes(u)) };

    acc[k] = { ...data.levels[k], responses: filteredResponses };

    return acc;
  }, {});
  return { ...data, attributes, levels };
}

function getValidMultiselectUsers(filter: SurveyFiltering.QuestionFilter<SurveyQuestionType.Multiselect>, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Multiselect]): number[] {
  const selectedUsers = filter.optionIds.flatMap(o => data[o].userIds);
  if (filter.isSelected) {
    return selectedUsers;
  } else {
    return Object.values(data).flatMap(o => o.userIds).filter(u => !selectedUsers.includes(u));
  }
}

function getValidTextUsers(filter: SurveyFiltering.QuestionFilter<SurveyQuestionType.LongTextResponse>, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.LongTextResponse]): number[] {
  const textFilters = filter.text.split(',').map(t => t.trim());
  const regex = new RegExp(textFilters.join('|'), 'i');
  return Object.entries(data).filter(([k, v]) => {
    return regex.test(v) == filter.shouldContain;
  }).map(([k,v]) => +k);
}

function getValidSliderUsers(filter: SurveyFiltering.QuestionFilter<SurveyQuestionType.Sliders>, data: SurveyAggregate.QuestionDataTypeList[SurveyQuestionType.Sliders]): number[] {
  const userIds = Object.entries(data.rows[filter.optionId].responses).filter(([k, v]) => filter.greaterThan ? v > filter.value : v < filter.value).map(([k, v]) => +k);
  return userIds;
}
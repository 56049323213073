import { useMemo } from 'react';
import { Draggable, DraggableStateSnapshot, DraggableProvided } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { makeStyles } from '@mui/styles';
import * as consts from '@consts';
import { cx } from '@utils';
import DropDown from 'components/DropDown';
import { DraggableRankingListItemProps, RankingListItemProps, RankingOrdinalDropdownItem } from './interfaces';
import styles from './style/RankingItem.css';

const getDropdownItemText = (ordinal: number) => {
  return ordinal
    ? ordinal === consts.surveys.NotApplicableRankingOrdinal
      ? 'N/A'
      : ordinal.toString()
    : '-';
};

const useIconStyles = makeStyles({
  fontSizeLarge: {
    height: 28,
    width: 28,
  },
});

export const RankingListItem = (props: RankingListItemProps) => {
  const dropdownItems = useMemo(() => {
    return props.ordinalItems.map(ordinal => ({
      id: ordinal,
      name: getDropdownItemText(ordinal),
    }));
  }, [props.ordinalItems]);

  const iconStyles = useIconStyles();

  return (
    <div className={cx(styles.root, props.className)}>
      <div className={styles.left}>
        <div className={styles.ordinal}>
          <DropDown
            getItemValue={(item: RankingOrdinalDropdownItem) => item.name}
            items={dropdownItems}
            onSelect={props.onOrdinalSelect}
            text={getDropdownItemText(props.item.ordinal)} />
        </div>
        <div>{props.item.value}</div>
      </div>
      <div className={styles.right}>
        <DragHandleIcon
          classes={iconStyles}
          className={styles.handle}
          fontSize="large" />
      </div>
    </div>
  );
};

export const DraggableRankingListItem = (props: DraggableRankingListItemProps) => {
  const { index, ...otherProps } = props;

  return (
    <Draggable
      draggableId={props.item.id.toString()}
      index={props.index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <RankingListItem
            className={cx({
              [styles.dragging]: snapshot.isDragging,
            })}
            {...otherProps} />
        </div>
      )}
    </Draggable>
  );
};

export default RankingListItem;
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LocationDescriptor } from 'history';
import queryString from 'query-string';

const selectAuthenticated = (state: Store.State) => state.user.state.authenticated;

export const useGetReturnRoute = (): LocationDescriptor => {
  const location = useLocation<LocationState>();
  const authenticated = useSelector(selectAuthenticated);

  const search = queryString.parse(location.search) as Search;

  if (authenticated) {
    if (search.returnUrl?.length) return search.returnUrl;
    if (location.state?.return) return location.state?.return;
  }

  return null;

};

type Search = {
  returnUrl: string;
};
type LocationState = {
  return: LocationDescriptor;
};
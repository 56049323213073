import { useCallback } from 'react';
import { getIntialSurvey } from '../utils/template';
import { useSurveyTemplate } from './useSurveyTemplate';
import { useSurveyTemplateData } from './useSurveyTemplateData';
import { useSurveyBuilderState } from './useSurveyBuilderContext';

export const useInitializeTemplateSurvey = () => {

  const [_, dispatch] = useSurveyBuilderState();
  const [template] = useSurveyTemplate();
  const [templateData] = useSurveyTemplateData();

  return useCallback(() => {
    const surveyData = getIntialSurvey(template, templateData);

    dispatch({
      value: surveyData,
      type: 'reset-survey',
    });

  }, [
    dispatch,
    template,
    templateData,
  ]);
};
import { ProjectDisqualification } from './ProjectDisqualification';

const SurveyQuotaOverLimit = () => {

  return (
    <ProjectDisqualification title={title} />
  );
};

const title = `Hmm, looks like a quota limit has been reached for individuals with your background for this survey, but we will keep you posted as future opportunities arise on Vancery.`;

export { SurveyQuotaOverLimit };
export default SurveyQuotaOverLimit;
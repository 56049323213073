import { useSelector } from 'react-redux';
import { device } from '@utils';
import { StripeConnectButton } from 'components/Stripe/Buttons';
import { useStripeAccountId, useStripeSignup } from './hooks';
import NotSupported from './NotSupported';

const selectUserId = (state: Store.State) => state.user.id;

const StripeAuth = () => {
  const userId = useSelector(selectUserId);
  const [_, isSetup] = useStripeAccountId();

  const handleSignUp = useStripeSignup(userId);

  const notSupported = (device.phone || device.tablet) && device.apple;

  if (notSupported) {
    return <NotSupported />;
  }

  return isSetup
      ? null
      : <StripeConnectButton handleConnect={handleSignUp} />;
};

export { StripeAuth };
export default StripeAuth;
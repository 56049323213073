import { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Check, RotateCcw } from 'react-feather';
import { format } from 'date-fns';
import { LocationDescriptor } from 'history';
import { useSelectContact, useSelectUser } from '@containers/Store';
import {
  canJoinConference,
} from '@containers/Scheduler/utils.calls';
import { CallStatus } from '@enums';
import { Call } from '@/types';
import { getLocationFor } from '@utils';
import { Button } from 'components/Button';
import { UserAvatar } from 'components/UserAvatar/UserAvatar';
import styles from './style/EventPopover.css';

type DateProps = {
  start: Date;
  end: Date;
};

export const EventDate = memo(({ start, end }: DateProps) => {
  const day = format(start, 'EEEE MMM d');
  const startDisplay = format(start, 'h:mm a');
  const endDisplay = format(end, 'h:mm a');

  return (
    <>
      <div className={styles.date}>{day}</div>
      <div className={styles.time}>{`${startDisplay} - ${endDisplay}`}</div>
    </>
  );
});

type ParticipantProps = {
  hideLink?: boolean;
  item: Pick<Call, 'lastModifiedBy' | 'statusId'>;
  userId: number;
};

export const CallParticipant = ({ hideLink, item, userId }: ParticipantProps) => {

  const me = useSelectUser();
  const contact = useSelectContact(userId);

  const renderStatusIcon = useCallback(() => {
    switch (item.statusId) {
      case CallStatus.Completed:
      case CallStatus.Scheduled:
        return (
          <div className={styles.check}>
            <Check size={10} />
          </div>
        );

      case CallStatus.Rescheduling: {
        if (item.lastModifiedBy === me.id) {
          return (
            <div className={styles.check}>
              <Check size={10} />
            </div>
          );
        } else {
          return (
            <div className={styles.rotate}>
              <RotateCcw size={8} />
            </div>
          );
        }
      }

      default:
        return null;
    }
  }, [
    item.lastModifiedBy,
    item.statusId,
    me.id,
  ]);

  const renderBody = useCallback(() => {
    return (
      <div className={styles.participant}>
        <div className={styles.avatar}>
          <UserAvatar
            size={26}
            pictureUrl={contact.profile.pictureUrl} />
          {renderStatusIcon()}
        </div>
        <div className={styles.name}>
          {contact.profile.fullname}
        </div>
      </div>
    );
  }, [
    contact.profile,
    renderStatusIcon,
  ]);

  if (hideLink) {
    return (
      <>{renderBody()}</>
    );
  }

  const location = getLocationFor.user.profile({ slug: contact.profile.slug });

  return (
    <Link className={styles.url} to={location}>
      {renderBody()}
    </Link>
  );
};

type SelectTimeProps = {
  item: Pick<Call, 'statusId' | 'lastModifiedBy'>;
  location: LocationDescriptor;
};

export const SelectTime = ({ item, location }: SelectTimeProps) => {

  const me = useSelectUser();

  if (CallStatus.Rescheduling !== item.statusId ||
    item.lastModifiedBy === me.id) return null;

  return (
    <Button
      className={styles.callaction}
      to={location}>
      Select Time
    </Button>
  );
};

type JoinCallProps = {
  item: Pick<Call, 'id' | 'statusId' | 'timeEnd' | 'timeStart'>;
  conferenceIdentifier: number;
};

export const JoinCall = ({ item, conferenceIdentifier }: JoinCallProps) => {
  const canJoin = canJoinConference(item);

  if (!canJoin) return null;

  return (
    <Button
      className={styles.callaction}
      to={getLocationFor.call.conference(conferenceIdentifier)}>
      Join Call
    </Button>
  );
};
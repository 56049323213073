import TextInput from './TextInput';
import { StateFieldProps } from './interfaces';

type Props =
    StateFieldProps;

export default function StateField(props: Props) {
  if (!props.show) return null;

  return (
    <TextInput
      className={props.className}
      labelClassName={props.labelClassName}
      error={props.error}
      placeholder={props.placeholder}
      label={props.label}
      name='state'
      value={props.value}
      required={props.required}
      onChange={props.onChange} />
  );
}

StateField.defaultProps = {
  label: 'State',
  placeholder: 'State',
  show: true,
};

export { StateField as State };
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useMenuContext } from '@containers/AppNavigation/Context';
import { useAppReadyState } from '@containers/AppReadyState';
import { useSelectUser } from '@containers/Store';
import { cx, getLocationFor, roles } from '@utils';
import { useRoleValidator } from '@utils/store';
import { User } from 'components/icons';
import { UserAvatar } from 'components/UserAvatar';
import { ActionableIcon } from './Menu.ActionableIcon';
import { useAnchoredMenuRef } from './hooks/useAnchoredMenuRef';
import styles from './style/Dropdown.css';

type Props = unknown;

export const Account = (props: Props) => {
  const user = useSelectUser();
  const app = useAppReadyState();
  const [ref, open, actions] = useAnchoredMenuRef<HTMLDivElement>();

  const icon = user.profile.pictureUrl
      ? <UserAvatar
          className={styles.icon}
          pictureUrl={user.profile.pictureUrl}
          size={32} />
      : <User
          color="var(--black-l)"
          size={32}
          strokeWidth=".5px" />;

  const classes = cx({
    [styles.hidden]: !open,
    [styles.visible]: open,
  });

  if (!app.hydrated) return null;

  return (
    <div className={styles.account}>
      <ActionableIcon
        className={styles.account}
        classes={{ icon: styles.icon }}
        count={0}
        icon={icon}
        name={`Account`}
        onClick={actions.toggle}
        ref={ref}>
        <div className={classes}>
          <DropdownMenu />
        </div>
      </ActionableIcon>
    </div>
  );
};

const DropdownMenu = (props: Props) => {
  return (
    <Fragment>
      <DropdownHeader />
      <DropdownList />
    </Fragment>
  );
};

const DropdownHeader = (props: Props) => {
  const user = useSelectUser();
  const hasRoles = useRoleValidator();

  if (!user.id || !hasRoles(roles.consultant)) return null;

  const location = getLocationFor.me.profile({
    slug: user.profile.slug,
    userId: user.id,
  });

  return (
    <div className={styles.profile}>
      <div className={styles.avatar}>
        <Link to={location}>
          <UserAvatar
            pictureUrl={user.profile.pictureUrl}
            size={45} />
        </Link>
      </div>
      <div className={styles.info}>
        <div className={styles.firstname}>
          {user.profile.firstName}
        </div>
        <div className={styles.link}>
          <Link to={location}>View Profile</Link>
        </div>
      </div>
    </div>
  );
};

const DropdownList = (props: Props) => {
  const { account } = useMenuContext();

  return (
    <Fragment>
      {account.map(x =>
        <Link
          className={styles.menuItem}
          key={x.name}
          onClick={x.onClick}
          to={x.to}>
          <div className={styles.itemName}>{x.name}</div>
        </Link>)}
    </Fragment>
  );
};

Account.displayName = 'Menu.Account';
DropdownMenu.displayName = 'Menu.Account.DropdownMenu';
DropdownHeader.displayName = 'Menu.Account.DropdownMenu.Header';
DropdownList.displayName = 'Menu.Account.DropdownMenu.List';
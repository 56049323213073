import { DragDropContext } from 'react-beautiful-dnd';
import { RankingListProps } from './interfaces';
import RankingListDropArea from './RankingListDropArea';
import { DraggableRankingListItem } from './RankingListItem';

export const RankingList = (props: RankingListProps) => {
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <RankingListDropArea>
        {
          props.items.map((option, index) => (
            <DraggableRankingListItem
              key={option.id}
              index={index}
              item={option}
              ordinalItems={props.getDropdownItems(option)}
              onOrdinalSelect={props.onOrdinalSelect(index)} />
          ))
        }
      </RankingListDropArea>
    </DragDropContext>
  );
};

export default RankingList;
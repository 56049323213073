import Modal from 'components/Modal/Modal.Legacy';
import { Terms } from '$website/components';
import styles from './style/PrivacyPolicyTerms.css';

type Props = {
  onClose: () => void;
  visible: boolean;
};

const PrivacyPolicyTerms = (props: Props) => {
  return (
    <Modal
      onClose={props.onClose}
      visible={props.visible}>
      <div className={styles.wrapper}>
        <div className={styles.title}>Terms & Conditions</div>
        <div className={styles.scroll}>
          <Terms.Consultant />
        </div>
      </div>
    </Modal>
  );
};

export { PrivacyPolicyTerms };
export default PrivacyPolicyTerms;
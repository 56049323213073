import { useCallback } from 'react';
import { SurveyOptionType } from '@enums';
import { OptionPreviewTaggingItem } from '@presentation/SurveyTaggingPreview/OptionPreviewTaggingItem';
import { SurveyQuestionOption } from '@/types';
import { SurveyActionType } from './ActionType';
import { QuestionOption } from './QuestionOption';
import { OptionsWithLogicProps } from './interfaces';
import styles from './style/Options.css';

export const OptionsWithLogic = (props: OptionsWithLogicProps) => {
  const getOptionLogic = useCallback((option: SurveyQuestionOption) => {
    return props.item.logic.response
      .find(f => f.condition.value.option.identifier === option.base.identifier);
  }, [props.item.logic.response]);

  const optionTagging = useCallback((option: SurveyQuestionOption) => {
    return props.item.tagging?.response?.find(f => f.condition.value.option.ordinal === option.ordinal);
  }, [props.item.tagging]);

  return (
    <>
      {props.item.options
        .filter(f => f.type !== SurveyOptionType.NotApplicable)
        .map((option, i) => {
          const logic = getOptionLogic(option);
          return (
            <QuestionOption
              key={i}
              className={styles.option}
              item={option}>
              {logic &&
                <SurveyActionType
                  className={styles.action}
                  action={logic.action}
                  conditionType={logic.condition.type} />}
              {props.includeTagging &&
                <OptionPreviewTaggingItem
                  className={styles.tagging}
                  item={optionTagging(option)} />}
            </QuestionOption>
          );
        })}
    </>
  );
};

export default OptionsWithLogic;
import { useCallback, useEffect, useMemo, useState, useRef, Fragment } from 'react';
import { useFullscreenToggle } from '@utils';
import { CallDisclaimerModal } from 'components/Conference/Modal';
import { useConferenceInstance } from './hooks';
import { ConferenceLiveRoomBarContext, ConferenceLiveRoomScreenContext } from './Context';
import { Coordinator, LiveRoom } from './interfaces';

export function LiveRoomContainer({ children }: ChildrenProps) {
  return (
    <LiveRoomBarContainer>
      <LiveRoomScreenContainer>
        <LiveRoomDisclaimerContainer>
          {children}
        </LiveRoomDisclaimerContainer>
      </LiveRoomScreenContainer>
    </LiveRoomBarContainer>
  );
}

function LiveRoomBarContainer({ children }: ChildrenProps) {
  const [state, setState] = useState<LiveRoom.Bar.State>({
    active: null,
  });

  const value: LiveRoom.Bar.Context = useMemo(() => [
    state,
    setState,
  ], [state, setState]);

  return (
    <ConferenceLiveRoomBarContext.Provider value={value}>
      {children}
    </ConferenceLiveRoomBarContext.Provider>
  );
}

type DisclaimerProps = {
  children: React.ReactNode;
};

function LiveRoomDisclaimerContainer({ children }: DisclaimerProps) {
  const instance = useConferenceInstance<Coordinator.Conference.MeetingRoom>();

  const [seen, setSeen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  const handleCloseModal = useCallback(() => setModal(false), []);

  useEffect(() => {
    if (!seen) {
      setSeen(true);
      if (instance?.conference?.disclaimer?.length) {
        setModal(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance?.conference?.disclaimer]);

  return (
    <Fragment>
      {children}
      {modal && (
        <CallDisclaimerModal
          text={instance?.conference?.disclaimer}
          onClose={handleCloseModal}
          open={modal} />
      )}
    </Fragment>
  );
}

function LiveRoomScreenContainer({ children }: ChildrenProps) {
  const ref = useRef<HTMLDivElement>();
  const [isFullscreen, toggleFullscreen] = useFullscreenToggle();

  const handleToggle = useCallback(() => {
    toggleFullscreen({
      element: ref.current,
    });
  }, [toggleFullscreen]);

  const value: LiveRoom.Screen.Context = useMemo(() => ({
    isFullscreen,
    containerRef: ref,
    toggle: handleToggle,
  }), [isFullscreen, ref, handleToggle]);

  return (
    <ConferenceLiveRoomScreenContext.Provider value={value}>
      {children}
    </ConferenceLiveRoomScreenContext.Provider>
  );
}
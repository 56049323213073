import { memo } from 'react';

type Props = {
  className?: string;
  size: number;
};

export const PlayCircle = memo(({ className, size }: Props) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <g
          transform="translate(-207, -303)"
          stroke="currentColor"
          strokeWidth="2">
          <g transform="translate(208, 304)">
            <circle
              cx="9"
              cy="9"
              r="9" />
            <polygon
              points="7.2 5.4 12.6 9 7.2 12.6" />
          </g>
        </g>
      </g>
    </svg>
  );
});
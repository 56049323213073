import styles from './style/ContactRecord.css';

type Props = unknown;

export const ContactRecord = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.section}>
            <div className={styles.title}>Contact</div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>Tags</div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>Projects</div>
          </div>
        </div>
      </div>
    </div>
  );
};

ContactRecord.displayName = 'Group.Contacts.Record';
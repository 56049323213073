import { createElement, isValidElement, useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { Call } from '@/types';
import { SectionList, SectionListProps, SectionListRenderHeader, SectionProps } from 'components/SectionList';
import styles from './style/CallsAgenda.css';
import { buildCallsMap } from './utils';

type Props = {
  ListEmptyComponent?: React.ComponentType;
  items:               Call[];
  sectionKeyExtractor: (item: Call) => string;
} & Pick<SectionListProps<Call>, 'renderItem'>;

const CallsAgenda = ({ ListEmptyComponent, ...props }: Props) => {

  const renderSectionHeader: SectionListRenderHeader<Call, SectionProps> = useCallback(({ section }) => {
    return (
      <div className={styles.day}>{section.title}</div>
    );
  }, []);

  const sections = useMemo(() => {
    const callsMap = buildCallsMap(props.items, props.sectionKeyExtractor);

    return Object.keys(callsMap).map(key => ({
      data: callsMap[key],
      title: format(new Date(), 'MMM dd') === key ? 'Today' : key,
    }));
  }, [
    props.items,
    props.sectionKeyExtractor,
  ]);

  if (!props.items.length) {
    return isValidElement(ListEmptyComponent)
      ? null
      : createElement(ListEmptyComponent);
  }

  return (
    <SectionList
      sections={sections}
      keyExtractor={x => String(x.id)}
      renderItem={props.renderItem}
      renderSectionHeader={renderSectionHeader} />
  );
};

export { CallsAgenda };
export default CallsAgenda;
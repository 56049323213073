import { cx } from '@utils';
import { ModalBase, ModalBaseProps } from './ModalBase';
import styles from './style/Base.css';

type Props = {
  classes?: {
    root?: string;
    wrap?: string;
    close?: string;
  };
  className?: string;
  blocking?: boolean;
  hideCloseIcon?: boolean;
  wrapClassName?: string;
} & ModalBaseProps
  & typeof defaultProps;

const Modal = (props: ModalProps) => {
  const classes = {
    root: cx(props.className, props.classes.root),
    wrap: cx(props.wrapClassName, props.classes.wrap),
    close: cx(styles.close, props.classes.close),
  };

  return (
    <ModalBase
      classes={classes}
      disableEscapeClose={props.disableEscapeClose || props.blocking}
      disableOverlayClick={props.disableOverlayClick || props.blocking}
      disablePortal={props.disablePortal}
      onClose={props.onClose}
      open={props.open}>
      {!props.hideCloseIcon && !props.blocking &&
        <div className={styles.closeContainer}>
          <div
            className={classes.close}
            onClick={props.onClose}>
            ✕
          </div>
        </div>}
      {props.children}
    </ModalBase>
  );
};

const defaultProps = {
  ...ModalBase.defaultProps,
  hideCloseIcon: false,
};

Modal.defaultProps = defaultProps;

export type ModalProps = Props;

export { Modal };
export default Modal;
import { useCallback, useContext, useMemo } from 'react';
import ChevronLeftRounded from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRounded from '@mui/icons-material/ChevronRightRounded';
import { FollowedTopicsContext } from '@containers/Topic/Context';
import { useCreateTopicsState } from '@containers/Topic/hooks';
import { AnalyticsEvent } from '@enums';
import { useTrackEvent } from '@utils/analytics';
import { SuggestedTopicsItem } from '@/types';
import { Carousel } from 'components/Discovery';
import { useTopicFollowingModal } from 'components/Topic/hooks';
import { Card } from './SuggestedTopics';
import { useCarousel } from './hooks/useCarousel';
import styles from './style/SuggestedTopics.css';

type Props = {
  items: SuggestedTopicsItem['items'];
};

export const SuggestedTopics = (props: Props) => {
  const followed = useContext(FollowedTopicsContext);
  const [toggleModal, Modal] = useTopicFollowingModal();
  const trackEvent = useTrackEvent(AnalyticsEvent.ViewSuggestedTopic);

  const [ref, width, carousel] = useCarousel(props.items.length, {
    computeTotalVisible: width => width < 550 ? 3 : 4,
  });

  const css = useMemo(() => {
    return {
      translateX: -width * carousel.position,
      width: props.items.length * (width + 15),
    };
  }, [
    props.items.length,
    carousel.position,
    width,
  ]);

  const style = {
    transform: `translateX(${css.translateX}px)`, width: css.width,
  };

  const items = useCreateTopicsState({
    data: props.items,
    followed: followed.topics,
  });

  const track = useCallback((topicId: number) => () => {
    trackEvent({
      topicId,
    });
  }, [
    trackEvent,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>

        <div className={styles.header}>Follow topics to customize your newsfeed and receive relevant projects.</div>

        <div className={styles.main}>
          <div className={styles.left}>
            <button
              className={styles.btn}
              disabled={!carousel.canGoBack}
              onClick={carousel.back}>
              <ChevronLeftRounded />
            </button>
          </div>
          <div className={styles.right}>
            <button
              className={styles.btn}
              disabled={!carousel.canGoNext}
              onClick={carousel.next}>
              <ChevronRightRounded />
            </button>
          </div>

          <Carousel.Slider ref={ref}>
            <div
              className={styles.carousel}
              style={style}>
              {items.map(x =>
                <div
                  className={styles.item}
                  key={x.item.id}
                  style={{ width: `${width}px`}}>
                  <Card
                    following={x.following}
                    item={x.item}
                    onClick={track(x.item.id)}
                    onToggleFollowing={x.onToggleFollowing} />
                </div>)}
            </div>
          </Carousel.Slider>
        </div>

        <div className={styles.footer}>
          <button
            className={styles.launch}
            onClick={toggleModal}>
            See more topics
          </button>
          <Modal />
        </div>
      </div>
    </div>
  );
};

SuggestedTopics.displayName = 'Feed.SuggestedTopics';
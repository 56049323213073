import { useCallback } from 'react';
import { SurveySectionText } from '@presentation/Survey';
import { cx } from '@utils';
import { Switch } from 'components/Switch';
import { BaseSectionHeaderProps, LogicSectionHeaderProps, ReadOnlySectionHeaderProps } from './interfaces';
import styles from './style/Header.css';

const BaseLogicSectionHeader = (props: BaseSectionHeaderProps) => {

  return (
    <div className={cx(styles.root, props.className)}>
      <SurveySectionText
        item={props.item} />
      <div className={styles.right}>
        {props.children}
      </div>
    </div>
  );
};

export const ReadOnlySectionHeader = (props: ReadOnlySectionHeaderProps) => {
  const text = props.item.hidden
    ? 'Hidden by default'
    : ''
  ;

  return (
    <BaseLogicSectionHeader {...props}>
      <div className={styles.text}>{text}</div>
    </BaseLogicSectionHeader>
  );
};

export const SurveyLogicSectionHeader = (props: LogicSectionHeaderProps) => {
  const { toggleHidden, ...otherProps } = props;

  const switchClassName = cx(styles.switch, {
    [styles.hidden]: props.item.hidden,
    [styles.show]: !props.item.hidden,
  });

  const renderToggle = useCallback(() => {

    if (props.item.metadata.canModifyVisibility) {
      return (
        <>
          <div className={styles.text}>Hidden by default</div>
          <Switch
            active={props.item.hidden}
            className={switchClassName}
            onClick={toggleHidden} />
        </>
      );
    } else if (props.item.hidden) {
      return <div className={styles.text}>Hidden by default</div>;
    }
  }, [
    props.item.hidden,
    props.item.metadata.canModifyVisibility,
    toggleHidden,
    switchClassName,
  ]);

  return (
    <BaseLogicSectionHeader {...otherProps}>
      {renderToggle()}
    </BaseLogicSectionHeader>
  );
};
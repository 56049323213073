import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHasConsultantRole } from '@containers/User';
import { useUpdateUserSettings } from '@utils/api';
import { SettingsTitle } from 'components/Settings';
import { Switch } from 'components/Switch';
import styles from './style/Settings.Privacy.css';

const selectSettings = (state: Store.State) => state.user.settings;

export const PrivacySettings = () => {

  const settings = useSelector(selectSettings);

  const hasConsultantRole = useHasConsultantRole();

  const { mutateAsync: updateSettings } = useUpdateUserSettings();

  const handleUpdate = useCallback((field: Fields) => (value: boolean) => {
    return updateSettings({
      [field]: !value,
    });
  }, [
    updateSettings,
  ]);

  const renderItem = useCallback((field: Fields, title: string) => {
    const value = !settings[field];
    return (
      <div className={styles.item}>
        <Switch
          className={styles.toggle}
          onClick={handleUpdate(field)}
          active={value} />
        {title}
      </div>
    );
  }, [
    handleUpdate,
    settings,
  ]);

  return (
    <>
      <SettingsTitle
        title="Privacy Settings" />
      {renderItem('showFullName', 'Hide full name')}
      {renderItem('showProfilePhoto', 'Hide profile photo')}
      {hasConsultantRole && renderItem('showCurrentCompany', 'Hide current company in profile')}
      {hasConsultantRole && renderItem('showLinkedinLink', 'Hide LinkedIn profile link')}
    </>
  );
};

type Fields =
  'showCurrentCompany' |
  'showFullName' |
  'showLinkedinLink' |
  'showProfilePhoto'
;

export default PrivacySettings;
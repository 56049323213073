import { createNamedContext } from '@utils';
import {
  Workbook,
  WorkspaceFile,
  WorkspaceFilePreviewData,
  WorkspaceObject,
} from '@/types';

type FileStateContextValue = {
  file: WorkspaceFile;
  object: WorkspaceObject;
};

type FetchPreview = () => Promise<unknown>;

export const WorkspaceFilePreviewReloadContext = createNamedContext<FetchPreview>(undefined, 'WorkspaceFilePreviewReloadContext');
export const WorkspaceFilePreviewLoadingContext = createNamedContext<boolean>(false, 'file-preview-loading');
export const WorkspaceFilePreviewContext = createNamedContext<WorkspaceFilePreviewData>(undefined, 'file-preview-data');

export const WorkspaceFileStateContext = createNamedContext<FileStateContextValue>(undefined, 'file-state');

export const WorkbookDataContext = createNamedContext<Workbook>(undefined, 'workbook-data');
export const WorkbookDataLoadingContext = createNamedContext<boolean>(undefined, 'workbook-data-loading');
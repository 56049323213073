import { useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks/useRepeatQuestionResponsesData';
import { SurveyResponsesHeader } from '@presentation/ProjectSurveyResponses';
import { SegmentedDataTooltip } from 'components/SurveyResponses/SegmentedDataTooltip';
import RepeatOptionsLineChart from './OptionsChart';
import OptionsData from './OptionsData';

export const RepeatOptions = () => {
  const { question } = useRepeatQuestionResponsesData();

  return (
    <>
      <SurveyResponsesHeader
        question={question}>
        <SegmentedDataTooltip questionIdentifier={question.base.identifier} />
      </SurveyResponsesHeader>
      <RepeatOptionsLineChart />
      <OptionsData />
    </>
  );
};

export default RepeatOptions;
import { useEffect } from 'react';
import { Redirect, generatePath } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as $conferences from '@api/conferences';
import { useAppReadyState } from '@containers/AppReadyState';
import { useConferenceParam } from '@containers/Conference/hooks';
import { useSelectUser } from '@containers/Store';
import * as consts from '@consts';
import { ConferenceEntryAuthorized } from './ConferenceEntry.Authorized';
import { ConferenceEntryGuest } from './ConferenceEntry.Guest';

export function ConferenceEntry() {
  const app = useAppReadyState();
  const user = useSelectUser();

  const conferenceIdentifier = useConferenceParam();

  const { data, isError, isLoading, refetch } = useQuery(['conference-entry', conferenceIdentifier], () => {
    return $conferences.getConferenceEntry({
      conferenceIdentifier,
    });
  }, {
    enabled: !!conferenceIdentifier,
  });

  useEffect(() => {
    refetch();
  }, [refetch, user?.id]);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <Redirect to={generatePath(consts.path.Conference.NotFound, { conferenceIdentifier })} />;
  }

  // support old links that use callIds
  if (data?.conferenceIdentifier && conferenceIdentifier !== data.conferenceIdentifier) {
    return <Redirect to={generatePath(consts.path.Conference.Entry, { conferenceIdentifier: data.conferenceIdentifier })} />;
  }

  if (!app.authenticated) {
    return (
      <ConferenceEntryGuest
        conferenceIdentifier={conferenceIdentifier} />
    );
  }

  if (!app.initialized) {
    return null;
  }

  if (data.requiresPin) {
    return (
      <ConferenceEntryGuest
        conferenceIdentifier={conferenceIdentifier}
        name={user.profile.fullname} />
    );
  }

  return (
    <ConferenceEntryAuthorized
      conferenceIdentifier={conferenceIdentifier}
      name={user.profile.fullname} />
  );
}
import { VICSType } from '@enums';
import { VICS } from '@/types';

export const mapVICSByType = (items: VICS[]) => {
  return items.reduce<VICSMappedByType>((acc, x) => ({
    ...acc,
    [x.id]: x,
    industries: x.typeId === VICSType.Industry ? [...acc.industries, x] : acc.industries,
    sectors: x.typeId === VICSType.Sector ? [...acc.sectors, x] : acc.sectors,
    subindustries: x.typeId === VICSType.SubIndustry ? [...acc.subindustries, x] : acc.subindustries,
    ids: [...acc.ids, x.id ],
  }), {
    ids: [] as VICS['id'][],
    industries: [] as VICS[],
    sectors: [] as VICS[],
    subindustries: [] as VICS[],
  });
};

type VICSMappedByType<T extends VICS = VICS> = {
  [id: number]:  T;
  ids:           T['id'][];
  industries:    T[];
  sectors:       T[];
  subindustries: T[];
};
import * as enums from '@enums';
import { Project } from '@/types';

export enum RouteKey {
  Details          = 'details',
  Calls            = 'calls',
  Pipeline         = 'pipeline',
  Summary          = 'summary',
  SurveyResponses  = 'survey-responses',
}

export type SceneMap = {
  [key in RouteKey]: TabScene;
};

export type GroupProjectRouteParams = {
  slug: string;
  tab?: RouteKey;
};

export type TabScene = {
  component: React.ComponentType;
  path:      string;
  title:     string | React.ReactNode;
};

export type ProjectOverviewFormState = {
  frequency: enums.ProjectFrequency;
  goal: number;
} & Pick<Project,
      'description'
    | 'name'>;
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { cx } from '@utils';
import { ConferenceLink } from 'components/Conference.Entry/ConferenceLink';
import { PhoneInformation } from 'components/Conference.Entry/PhoneInformation';
import CameraQuality from '../CameraQuality';
import NetworkQuality from '../NetworkQuality';
import SecureConnection from '../SecureConnection';
import { InformationProps, InformationIconProps } from './interfaces';
import styles from './style.css';

export function Information(props: InformationProps) {

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <PhoneInformation
          className={styles.row}
          {...props.dial} />
        <ConferenceLink
          className={styles.row}
          conferenceIdentifier={props.conferenceIdentifier}
          pin={props.dial?.guestPin} />
      </div>
      <div className={styles.bottom}>
        <NetworkQuality {...props.network} />
        <CameraQuality {...props.camera} />
        <SecureConnection />
      </div>
    </div>
  );
}

export const InformationIcon = (props: InformationIconProps) => (
  <div
    className={cx(styles.icon, { [styles.active]: props.active }, props.className)}
    onClick={props.onClick}>
    <InfoOutlinedIcon />
  </div>
);

Information.defaultProps = {
  active: false,
};

export default Information;
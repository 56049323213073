import { AxiosError } from 'axios';
import { useQueryClient, useMutation, UseMutationOptions } from 'react-query';
import { addPast } from '@api/universal-search';
import { AddPast as IAddPast, GetPast as IGetPast } from '@api/interfaces/universal-search';

type Context = {
  previousData: IGetPast.Response;
}

export default function useMutatePastUniversalSearch(
  options?: UseMutationOptions<IAddPast.Response, AxiosError, IAddPast.Request, Context>
) {
  const client = useQueryClient();

  return useMutation('past-universal-searches', ({ entityId, item, query }) => {
    return addPast({
      entityId,
      item,
      query,
    });
  }, {
    onSettled: () => {
      client.refetchQueries('past-universal-searches');
    },
    ...options,
  });
}

export { useMutatePastUniversalSearch };
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
  memo,
} from 'react';

import { cx } from '@utils';
import { FocusableAnimation } from './FocusableAnimation';
import { FocusableClasses } from './interfaces';
import styles from './style.css';

type Props = {
  autoFocus?: boolean;
  children:   React.ReactNode;
  className?: string;
  classes?:   FocusableClasses;
  disabled?:  boolean;
  onBlur?:    () => unknown;
  onFocus?:   () => unknown;
};

type FocusableRefObject = React.Ref<{
  blur: () => unknown;
  focus: () => unknown;
}>;

const Focusable = <R extends FocusableRefObject>({
  autoFocus = false,
  classes = {},
  disabled,
  onBlur,
  onFocus,
  ...props
}: Props, ref: R) => {
  const [focused, setFocus] = useState(autoFocus);
  const focusRef = useRef<HTMLDivElement>(null);

  const handleBlur = useCallback(() => {
    setFocus(false);
    onBlur?.();
  }, [onBlur]);

  const handleFocus = useCallback(() => {
    if (disabled) return;
    setFocus(true);
    onFocus?.();
  }, [disabled, onFocus]);

  useImperativeHandle(ref, () => {
    return {
      blur: handleBlur,
      focus: handleFocus,
    };
  }, [handleBlur, handleFocus]);

  const rootClasses = useMemo(() => cx(styles.root, props.className), [props.className]);

  return (
    <div
      className={rootClasses}
      onBlur={handleBlur}
      onFocus={handleFocus}
      ref={focusRef}
      tabIndex={0}>
      {props.children}
      <FocusableAnimation
        classes={classes}
        focused={focused} />
    </div>
  );
};

const FocusableRef = memo(forwardRef<FocusableRefObject, Props>(Focusable));

export { FocusableRef as Focusable };
export default FocusableRef;
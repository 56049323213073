import logoSalad from 'static/images/website/logo-salad-desktop.png';
import logoSaladTablet from 'static/images/website/logo-salad-tablet.png';
import logoSaladMobile from 'static/images/website/logo-salad-mobile.png';
import Section from './Section';
import styles from './style/Endorsements.css';

const Endorsements = () => {
  const headline = `Trusted by tens of thousands of professionals`;

  return (
    <Section className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.headline}>{headline}</h2>
        <div className={styles.endorsements}>
          <picture>
            <source
              srcSet={logoSalad}
              media="(min-width: 1100px)" />
            <source
              srcSet={logoSaladTablet}
              media="(min-width: 800px)" />
            <img
              alt="endorsements"
              src={logoSaladMobile}
              className={styles.img} />
          </picture>
        </div>
      </div>
    </Section>
  );
};

export default Endorsements;
import { useContext } from 'react';
import { Button } from 'components/Button';
import { ProjectInviteReferralEmailContext } from './Context';
import styles from './style/Email.Input.css';

type Props = {
  loading?: boolean;
  onClick: () => unknown;
};

export const SubmitButton = (props: Props) => {
  const ctx = useContext(ProjectInviteReferralEmailContext);

  return (
    <Button
      className={styles.btn}
      color="affirmative"
      disabled={!ctx.data.addresses.length || props.loading}
      onClick={props.onClick}
      variant="brick">
      Send
    </Button>
  );
};

SubmitButton.displayName = 'ProjectInviteReferral.Email.SubmitButton';
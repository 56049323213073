import { Button } from 'components/Button';
import { Site, usePageContent } from '$website/containers';
import Section from './Section';
import styles from './style/OperationalInsights.css';

type Props = unknown;

const OperationalInsights = (props: Props) => {
  const content = usePageContent<'main', 'operations'>('operations');

  return (
    <Section>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.title}>{content.text.header}</div>
          <div className={styles.subtitle}>{content.text.subheader}</div>
          <div className={styles.main}>
            <div className={styles.list}>
              <div className={styles.topic}>{content.text.planning}</div>
              <List items={content.collection.planning} />
            </div>
            <div className={styles.list}>
              <div className={styles.topic}>{content.text.product}</div>
              <List items={content.collection.product} />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

type ListProps = {
  items: Pick<Site.UIProps, 'button'>[];
};

function List(props: ListProps) {

  const renderLink = (params: Site.ButtonAttributes) => {
    return (
      <div
        className={styles.link}
        key={params.text}>
        <Button
          href={params.href}
          to={params.to}
          variant="link">
          {params.text}
        </Button>
      </div>
    );
  };

  return (
    <>
      {props.items.map(x => renderLink(x.button.link))}
    </>
  );
}

const title = `Vancery is where professionals come to gather market insights.`;
const subtitle = `Join our platform to ask top industry leaders questions on problems relevant to your business.`;

const list1 = [
  `How do you set up a digital marketing funnel (i.e. outbound email, digital ads, etc)?`,
  `How should you structure your sales team?`,
  `How should you set up your sales and operational teams?....and when!`,
];

const list2 = [
  `What functions do your clients really want?`,
  `What pricing schemes will work?`,
  `What do your future clients think of market incumbents?`,
];

export { OperationalInsights };
export default OperationalInsights;
import { Cell } from 'react-table';
import { MembersTableItem } from './interfaces';
import styles from './style/Cell.Member.Title.css';

export default function Title({ row: { original: data } }: Cell<MembersTableItem>) {
  return (
    <div className={styles.root}>
      {data.metadata.title}
    </div>
  );
}

export { Title };
import { Call } from '@/types';

export const buildCallsMap = <T extends Call>(items: T[], keyExtractor: (item: T) => string) => {
  return items.reduce((acc, x) => {
    const key = keyExtractor(x);

    return {
      ...acc,
      [key]: acc[key] ? acc[key].concat(x) : [x],
    };
  }, {} as {
    [key: string]: T[];
  });
};
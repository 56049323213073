import { createContext } from 'react';
import { ProjectMetricsMap } from '@/types';
import { UpdateProjectMetricsParams } from './interfaces';

type FetchProjectMetrics = () => Promise<unknown>;
type UpdateProjectMetrics = (data: UpdateProjectMetricsParams) => unknown;

export const ProjectMetricsContext = createContext<ProjectMetricsMap>(undefined);
export const ProjectMetricsLoadingContext = createContext<boolean>(false);
export const FetchProjectMetricsContext = createContext<FetchProjectMetrics>(undefined);
export const UpdateProjectMetricsContext = createContext<UpdateProjectMetrics>(undefined);
import { useState } from 'react';
import { cx, isValidEmail, phone } from '@utils';
import { Button } from 'components/Button';
import { CaptchaCheckbox } from 'components/Captcha';
import { Input } from 'components/Input';
import { useForm } from 'components/Form/hooks/useForm';
import { FormData } from './interfaces';
import styles from './style.css';

type Props = {
  onSubmit: (data: FormData) => Promise<unknown>;
};

const Form = (props: Props) => {
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const form = useForm({
    initialValues: {
      company: '',
      email: '',
      name: '',
      message: '',
    },
    onSubmit: (data: FormData) => {
      const valid = [
        validator.company(),
        validator.email(),
        validator.name(),
      ].every(Boolean);

      if (valid) {
        return props.onSubmit({
          company: data.company,
          email: data.email,
          name: data.name,
          message: data.message,
        });
      }
    },
  });

  function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (e.target.type === 'email') {
      validator.email();
    }
  }

  function handleChangeText(e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) {
    const value = e.target.type === 'tel'
      ? phone.format(phone.parseDigits(e.target.value))
      : e.target.value;

    form.setFieldValue(e.target.name, value);
  }

  const validator = {
    company() {
      const valid = !!form.values.company.length;

      form.setFieldError('company', valid ? '' : 'Company required');

      return valid;
    },
    email() {
      const valid = isValidEmail(form.values.email);

      form.setFieldError('email', valid ? '' : 'Enter a valid email address');

      return valid;
    },
    name() {
      const valid = !!form.values.name.length;

      form.setFieldError('name', valid ? '' : 'Name required');

      return valid;
    },
  };

  function handleSubmit() {
    if (!form.isSubmitting) {
      form.submitForm();
    }
  }

  function RequiredField({ field }: { field: string; }) {
    return (
      <>
        <span className={styles.required}>*</span>  <FormError field={field} />
      </>
    );
  }

  function FormError({ field }: { field: string; }) {
    return field
         ? <span className={styles.error}>{field}</span>
         : null;
  }

  return (
    <div className={cx(styles.col, styles.form)}>
      <h1 className={styles.h5}>Contact Our Team</h1>
      <div className={styles.submitted}>We’re here to help and answer any question you might have. We look forward to hearing from you.</div>
      <div className={styles.row}>
        <div className={styles.label}>Name <RequiredField field={form.errors.name} /></div>
        <Input
          name="name"
          onChange={handleChangeText}
          placeholder="Full name"
          value={form.values.name} />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Company <RequiredField field={form.errors.company} /></div>
        <Input
          name="company"
          onChange={handleChangeText}
          placeholder="Your company"
          value={form.values.company} />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Email <RequiredField field={form.errors.email} /></div>
        <Input
          name="email"
          placeholder="Work email"
          onBlur={handleBlur}
          onChange={handleChangeText}
          type="email"
          value={form.values.email} />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Message</div>
        <div className={styles.box}>
          <textarea
            className={styles.textarea}
            rows={7}
            name="message"
            onChange={handleChangeText} />
        </div>
      </div>

      <div className={cx(styles.row, styles.captcha)}>
        <CaptchaCheckbox onChange={setCaptchaVerified} />
      </div>

      <div className={styles.row}>
        {captchaVerified &&
          <Button
            color="primary"
            className={styles.btn}
            onClick={handleSubmit}
            variant="brick"
            type="button">
            {!form.isSubmitting ? 'Submit' : 'Sending'}
          </Button>}
      </div>
    </div>
  );
};

export { Form };
export default Form;
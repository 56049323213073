import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useDownloader } from '@utils/hooks';
import * as api from '@api';
import { ProjectReportType, ProjectReportDataType } from '@/types';

type Data = {
  blob: Blob;
  filename: string;
};
type QueryKey<T = Params> = [string, T];

type Params = {
  format: ProjectReportType;
  isParentReport: boolean;
  projectId: number;
  projectParentId: number;
};

export function useDownloadProjectReport(data: Params, options?: UseQueryOptions<Data, AxiosResponse, Data, QueryKey>) {

  const download = useDownloader();

  return useQuery<Data, AxiosResponse, Data, QueryKey>(['download-project', data], ({ queryKey }) => {

    const {
      format,
      isParentReport,
      projectId,
      projectParentId,
    } = queryKey[1];

    if (isParentReport) {
      return api.projects.exportProjectParentData({
        format,
        projectParentId,
      });
    } else {
      return api.projects.exportProjectData({
        format,
        projectId,
      });
    }

  }, {
    enabled: false,
    retry: 1,
    ...options,
    onSuccess: res => {
      download({
        blob: res.blob,
        filename: res.filename,
      });
      options?.onSuccess?.(res);
    },
  });
}

type AsyncData = {
  key: string;
};

export function useDownloadProjectReportAsync(data: Params, options?: UseQueryOptions<AsyncData, AxiosResponse, AsyncData, QueryKey>) {
  return useQuery<AsyncData, AxiosResponse, AsyncData, QueryKey>(['download-project-async', data], ({ queryKey }) => {
    const {
      format,
      isParentReport,
      projectId,
      projectParentId,
    } = queryKey[1];

    if (isParentReport) {
      return api.projects.exportProjectParentDataAsync({
        format,
        projectParentId,
      });
    } else {
      return api.projects.exportProjectDataAsync({
        format,
        projectId,
      });
    }
  }, {
    enabled: false,
    retry: 1,
    ...options,
  });
}

type ProjectDataParams = Params & {
  dataType: ProjectReportDataType;
};

export function useDownloadProjectTypeReport(data: ProjectDataParams, options?: UseQueryOptions<AsyncData, AxiosResponse, AsyncData, QueryKey<ProjectDataParams>>) {
  return useQuery<AsyncData, AxiosResponse, AsyncData, QueryKey<ProjectDataParams>>(['download-project-data-type', data], ({ queryKey }) => {
    const {
      format,
      isParentReport,
      projectId,
      projectParentId,
      dataType,
    } = queryKey[1];

    if (isParentReport) {
      return api.projects.exportProjectParentDataType({
        format,
        projectParentId,
        dataType,
      });
    } else {
      return api.projects.exportProjectDataType({
        format,
        projectId,
        dataType,
      });
    }
  }, {
    enabled: false,
    retry: 1,
    ...options,
  });
}

export default useDownloadProjectReport;
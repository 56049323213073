import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { FolderDetailsContainer, useWorkspaceFolderState, useFolderCapabilities, useObjectAccessState, useObjectAccessLoading } from '@containers';
import { Spinner } from 'components/ActivityIndicator';
import { ObjectAccess } from 'components/ObjectAccess';
import styles from './style/FolderTabDetails.css';

const selectLocation = (parentObjectId: number) => (state: Store.State) => {
  const object = state.objects.values[parentObjectId];

  return object
    ? object.name
    : 'Shared';
};

const StateDependent = () => {
  const { folder, object } = useWorkspaceFolderState();
  const capabilities = useFolderCapabilities(folder.id);
  const [{ initial }] = useObjectAccessState();
  const accessLoading = useObjectAccessLoading();

  const location = useSelector(selectLocation(object.parentObjectId));

  const createdOn = format(folder.createdOn, 'MM/dd/yy');

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <div className={styles.item}>
          <div className={styles.label}>Name</div>
          {folder.name}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.item}>
          <div className={styles.label}>Location</div>
          {location}
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Created On</div>
          {createdOn}
        </div>
      </div>
      {capabilities.canManageAccess &&
        !accessLoading &&
          <ObjectAccess items={initial} />}
      {capabilities.canManageAccess &&
        accessLoading &&
        <Spinner />}
    </div>
  );
};

export const FolderTabDetails = () => (
  <FolderDetailsContainer>
    <StateDependent />
  </FolderDetailsContainer>
);

export default FolderTabDetails;
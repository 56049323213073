import {
  useTemplatePartnershipCriteriaModal,
  useTemplateEvaluationCriteriaModal,
} from 'components/Survey.Templates/Modal';
import { TemplateItemLabel } from './Template.Label';
import styles from './style/Template.Label.css';

type Props = {
  disabled: boolean;
  value: string;
};

export const TemplatePartnershipCriteria = ({ disabled, value }: Props) => {

  const [toggleModal, Modal] = useTemplatePartnershipCriteriaModal();

  return (
    <>
      <TemplateItemLabel
        className={styles.partnershipCriteria}
        disabled={disabled}
        label={value}
        onClick={toggleModal} />
      {!disabled && <Modal />}
    </>
  );
};

export const TemplateEvaluationCriteria = ({ disabled, value }: Props) => {

  const [toggleModal, Modal] = useTemplateEvaluationCriteriaModal();

  return (
    <>
      <TemplateItemLabel
        className={styles.evalCriteria}
        disabled={disabled}
        label={value}
        onClick={toggleModal} />
      {!disabled && <Modal />}
    </>
  );
};
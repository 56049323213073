import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { NotificationsSocketEvent, NotificationType } from '@enums';
import * as ws from '@services/websocket';

const mapState = (state: Store.State) => ({
  notifications: state.notifications,
});

type Params = number;

export const useDismissProjectRequestNotification = (projectId: Params) => {
  const state = useSelector(mapState);

  const dismiss = useCallback(() => {

    const notification = state.notifications.items.find(x => {
      return x.typeId === NotificationType.ProjectRequest
          && x.payload?.projectId === projectId;
    });

    ws.notifications.emit(NotificationsSocketEvent.Dismiss, {
      notificationIds: [notification?.id],
    });

  }, [
    projectId,
    state.notifications.items,
  ]);

  return dismiss;
};
import http from '@services/http';
import * as xform from '@transformers';
import { SearchType } from '@enums';
import { qs } from '@utils';
import { Search as S } from '@/types';
import { Search } from './interfaces';

export * from './search.vics';

export const fetchIndividualQueryResults = (data: Search.FetchQueryResults.Request): Promise<Search.FetchIndividualQueryResults.Response> => {
  return fetchQueryResults(data);
};

export const fetchEnterpriseQueryResults = (data: Search.FetchQueryResults.Request) => {
  return fetchQueryResults(data)
  .then((res: Search.FetchEnterpriseQueryResults.Response) => ({
    items: xform.addBindingsToSearchResults(res.results) as S.Legacy.QueryResultItem[],
    total: res.total,
  }));
};

export const fetchEnterpriseQueryResultsExport = (data: Search.FetchQueryResultsExport.Request): Promise<Search.FetchQueryResultsExport.Response> => {
  const query = qs.base64Encode(data);
  return http.download(`/search?${query}`);
};

export const fetchQueryResults = (data: Search.FetchQueryResults.Request) => {
  type Response =
    Search.FetchIndividualQueryResults.Response &
    Search.FetchEnterpriseQueryResults.Response;

  const query = qs.base64Encode(data);

  return http.get<Response>(`/search?${query}`);
};

export const fetchSectorFields = (): Promise<Search.FetchSectorFields.Response> => {
  return http.get(`/search/sectors`);
};

export const getAutocompleteResults = <T extends SearchType>(data: Search.GetAutocompleteResults.Request<T>) => {
  return http.get<Search.GetAutocompleteResults.Response<T>>(`/search/autocomplete?${qs.stringify(data, { arrayFormat: 'bracket' })}`)
    .then(res => res.results);
};

export const getCompanySectors = (data: Search.GetCompanySectors.Request) => {
  return http.post(`/search/company-sectors`, data)
    .then((resp: Search.GetCompanySectors.Response) => resp.results);
};

export const fetchSurveyTemplateData = (data: Search.FetchSurveyTemplateData.Request) => {
  const query = qs.stringify(data);
  return http.get<Search.FetchSurveyTemplateData.Response>(`/search/survey-template-data?${query}`);
};
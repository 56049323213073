import { createElement, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { path } from '@consts';
import { SiteElementContext } from './Context';
import { useSegmentedContent, useSiteStrategyState } from './hooks';
import { Site, Strategy } from './interfaces';

type Props = {
  component: React.ComponentType;
  include?: Strategy | Strategy[];
  exclude?: Strategy | Strategy[];
};

const VisitorSegment = (props: Props) => {
  const state = useSiteStrategyState();
  const content = useSegmentedContent();

  const exclude = !props.exclude
      ? []
      : Array.isArray(props.exclude)
        ? props.exclude
        : [props.exclude];

  const include = !props.include
      ? []
      : Array.isArray(props.include)
        ? props.include
        : [props.include];

  if (
    props.exclude && exclude.includes(state) ||
    props.include && !include.includes(state)
  ) return null;

  return (
    <SiteElementContext.Provider value={content}>
      {createElement(props.component)}
    </SiteElementContext.Provider>
  );
};

const useSegmentKey = <T extends Site.Screen>() => {
  const main = !!useRouteMatch({
    exact: true,
    path: [
      path.Website.Root,
      path.Website.Consulting,
      path.Website.Corporate,
      path.Website.Enterprise,
      path.Website.Individuals,
      path.Website.Investment,
    ],
  });
  const signup = !!useRouteMatch({
    exact: true,
    path: path.Website.Signup.Root,
  });

  const key = main
      ? 'main'
      : signup
        ? 'signup'
        : null;

  return key as T;
};

export const usePageContent = <T extends keyof Site.PageContent, K extends keyof Site.PageContent[T]>(section?: K) => {
  const content = useContext(SiteElementContext);
  const key = useSegmentKey<T>();

  const screen  = content[key];
  const sections = screen?.[section];

  return sections;
};

export { VisitorSegment };
export default VisitorSegment;
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { addDays } from 'date-fns';
import * as consts from '@consts';
import * as utils from '@utils';
import { AuthForm } from '$website/components';
import { AuthContainer, VisitorSegment } from '$website/containers';
import { useSignupPing, useSignupSubmit } from '$website/containers/Auth/hooks';
import logo from 'static/images/vancery-logo.svg?url';
import { SignupButton } from './SignupButton';
import { SignupError } from './SignupError';
import { NavigateToLogin, SignupFooter } from './SignupFooter';
import { SocialProof } from './SocialProof';
import * as ValueProposition from './ValueProposition';
import styles from './style/SignUp.css';

type Props = unknown;

export const Referral = (props: Props) => {
  const { referral } = useParams<{ referral?: string; }>();
  const ping = useSignupPing();

  useEffect(() => {
    ping();
  }, [ping]);

  useEffect(() => {
    if (referral) {
      utils.setCookie({
        key: consts.cookies.referral,
        value: referral,
        expires: addDays(new Date(), 7),
      });
    }
  }, [referral]);

  return (
    <div className={styles.root}>
      <Link to="/">
        <img
          className={styles.logo}
          src={logo} />
      </Link>

      <div className={styles.wrap}>
        <div className={styles.signup}>
          <div>
            <div className={styles.header}>
              <div className={styles.title}>Sign up with Vancery</div>
            </div>
            <AuthContainer>
              <Form />
            </AuthContainer>
          </div>
          <VisitorSegment component={SocialProof} />
          <ValueProposition.Visitor />
        </div>
      </div>
    </div>
  );
};

function Form() {
  const [_, submit] = useSignupSubmit();

  function getCookie() {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${consts.cookies.referral}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  function handleForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    submit({ token: getCookie() });
  }

  return (
    <form
      className={styles.form}
      onSubmit={handleForm}>
      <AuthForm.Email.Signup />
      <div className={styles.submit}>
        <SignupError />
        <SignupButton />
      </div>
      <div className={styles.footer}>
        <SignupFooter />
        <NavigateToLogin />
      </div>
    </form>
  );
}
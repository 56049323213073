import { QuestionLogicPreview } from '@presentation/SurveyLogicPreview';
import { Editable } from 'components/Editable';
import { EditableQuestionLogicProps } from './interfaces';

export const EditableQuestionLogic = (props: EditableQuestionLogicProps) => {
  return (
    <Editable
      canEdit={props.canEdit}
      onClick={props.onClick}>
      <QuestionLogicPreview
        includeOrdinalColumn={props.includeOrdinalColumn}
        item={props.item} />
    </Editable>
  );
};

export default EditableQuestionLogic;
import { useState } from 'react';
import * as api from '@api';
import { useSelectUser } from '@containers';
import styles from './style/VerifyEmail.css';

type Props = unknown;

const ResendVerificationEmail = (props: Props) => {
  const [resent, setResent] = useState(false);
  const user = useSelectUser();

  async function handleResendEmail() {
    if (resent) return;

    setResent(true);

    await api.users.verify.sendEmailVerification({ userId: user.id });
  }

  if (resent) {
    return (
      <div className={styles.message}>
        <div className={styles.subtext}>A new verification email has been sent!</div>
        <div className={styles.subtext}>{`Please check your spam folder if you don't see it.`}</div>
      </div>
    );
  }

  return (
    <div className={styles.message}>
      <div className={styles.subtext}>
        If you do not receive a verification email after 5 minutes, check your spam folder or <a className={styles.resend} onClick={handleResendEmail}>click here</a> to resend the email.
      </div>
    </div>
  );
};

export { ResendVerificationEmail };
export default ResendVerificationEmail;
import { forwardRef, useMemo } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SeeMore } from '@presentation/SeeMore';
import { cx } from '@utils';
import { SurveyQuestionText } from 'components/Survey.QuestionText';
import { ResponseQuestionProps } from './interfaces';
import styles from './style/Question.css';

type Props =
  ResponseQuestionProps;

export const ResponseQuestion = forwardRef(({
  className,
  isActive,
  item,
}: Props, ref: React.Ref<HTMLDivElement>) => {

  const rootClass = useMemo(() => {
    return cx(styles.item, className, {
      [styles.active]: isActive,
      [styles.inactive]: !isActive,
    });
  }, [
    className,
    isActive,
  ]);

  const seeMoreClass = useMemo(() => {
    return cx(styles.seeMore, {
      [styles.active]: isActive,
    });
  }, [isActive]);

  return (
    <div
      className={rootClass}
      ref={ref}>
      {isActive && <div className={styles.indicator} />}
      <div className={styles.content}>
        <div className={styles.q}>Q{item.ordinal}</div>
        <div className={styles.p}>
          <SeeMore
            expanderClassName={seeMoreClass}
            lineHeight={19}
            maxLines={10}>
            <SurveyQuestionText value={item.value} />
          </SeeMore>
        </div>
      </div>
      <div className={styles.arrow}>
        <div className={styles.icon}>
          <ArrowForwardIcon />
        </div>
      </div>
    </div>
  );
});

export default ResponseQuestion;
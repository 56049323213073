import { useContext, useCallback } from 'react';
import { useHistory } from 'react-router';
import { ProjectOnboardingContext } from '@containers/ProjectOnboarding/Context';
import { path, pathname } from '@consts';

export const useRedirectProjectComplete = () => {
  const history = useHistory();
  const ctx = useContext(ProjectOnboardingContext);

  return useCallback(() => {
    if (ctx?.projectId) {
      const loc = `${pathname.PROJECTS}/${ctx.projectId}`;
      history.push(loc);
    } else {
      history.push(path.Projects.Root);
    }
  }, [history, ctx]);

};
import { useContext } from 'react';
import { ProfileOnboardingContext } from '@containers';
import {
  Back,
  NextButton,
  WizardNavigation,
  WizardScreen,
  WizardScreenProps,
} from 'components/Wizard';
import { NavigationProps } from './interfaces';
import styles from './style.css';

export const Navigation = (props: NavigationProps) => {
  const ctx = useContext(ProfileOnboardingContext);

  const renderBack = () => (
    <Back
      onClick={ctx.back} />
  );

  const renderNext = () => (
    <NextButton
      disabled={!props.nextEnabled}
      onClick={ctx.next} />
  );

  return (
    <WizardNavigation
      renderBack={renderBack}
      renderNext={renderNext} />
  );
};

export const Screen = (props: WizardScreenProps) => {
  const renderBody = () => (
    <div className={styles.body}>
      {props.renderBody()}
    </div>
  );

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <WizardScreen
          renderHeader={props.renderHeader}
          renderBody={renderBody}
          renderNavigation={props.renderNavigation} />
      </div>
    </div>
  );
};
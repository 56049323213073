import { createContext } from 'react';
import {
  useAppBanner,
  useProfileCompletedBanner,
  useVerifiedBanner,
} from './hooks';
import { BannerStateContextValue, BannerStateProps } from './interfaces';

const BannerStateContext = createContext<BannerStateContextValue>(null!);

const BannerState = ({ children }: BannerStateProps) => {
  const [mobileAppVisible, dismissMobileApp] = useAppBanner();
  const [profileCompletedVisible, dismissProfileCompleted] = useProfileCompletedBanner();
  const [verifiedVisible, dismissVerified] = useVerifiedBanner();

  // TODO: create better way of determing to show a banner
  const context: BannerStateContextValue = {
    dismissMobileApp,
    dismissProfileCompleted,
    dismissVerified,
    mobileAppVisible: mobileAppVisible && !verifiedVisible && !profileCompletedVisible,
    profileCompletedVisible,
    verifiedVisible: verifiedVisible && !profileCompletedVisible,
  };

  return (
    <BannerStateContext.Provider value={context}>
      {children}
    </BannerStateContext.Provider>
  );
};

export { BannerStateContext };
export { BannerState };
export default BannerState;
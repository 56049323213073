import { useMemo } from 'react';
import { MoveObjectContainer, ObjectOwnershipContainer } from '@containers/WorkspaceObject';
import { useSelectFile, useSelectObject, useFileCapabilities } from '@containers/Store';
import { useObjectAccessModal } from 'components/ObjectAccess/hooks';
import { useDeleteFilePrompt } from 'components/WorkspaceFiles/hooks/useDeleteFilePrompt';
import { useRenameFileModal } from 'components/WorkspaceFiles/hooks/useRenameFileModal';
import { useObjectOwnershipModal, useMoveObjectModal } from 'components/WorkspaceObject/hooks';
import { RenameFileContainer } from './RenameFileContainer';
import { DeleteFileContainer } from './DeleteFileContainer';
import { FileActionsContext, FileMenuItemsContext, FileActionsContextValue, FileMenuItemsContextValue } from './Context';
import { useDownloadFile, useFileMenuItems } from './hooks';

type Props =
  IWorkspaceFileId &
  ChildrenProps;

export const FileMenuItemsContainer = (props: Props) => {
  const capabilities = useFileCapabilities(props.fileId);

  const menu: FileMenuItemsContextValue = useMemo(() => ({
    canChangeOwner: capabilities.canChangeOwner,
    canDelete: capabilities.canDelete,
    canDownload: capabilities.canDownload,
    canManageAccess: capabilities.canManageAccess,
    canMove: capabilities.canMove,
    canRename: capabilities.canRename,
  }), [
    capabilities,
  ]);

  return (
    <FileMenuItemsContext.Provider value={menu}>
      {props.children}
    </FileMenuItemsContext.Provider>
  );
};

export const FileActionsContainer = (props: Props) => {
  const file = useSelectFile(props.fileId);
  const object = useSelectObject(file.objectId);

  const menu = useFileMenuItems();

  const [toggleObjectAccessModal, ObjectAccessModal] = useObjectAccessModal();
  const [toggleOwnershipModal, ObjectOwnershipModal] = useObjectOwnershipModal();
  const [toggleMoveFileModal, MoveFileModal] = useMoveObjectModal();
  const [toggleDeletePrompt, DeleteFilePrompt] = useDeleteFilePrompt();
  const [toggleRenameModal, RenameFileModal] = useRenameFileModal();
  const download = useDownloadFile();

  const actions: FileActionsContextValue = useMemo(() => ({
    changeOwner: toggleOwnershipModal,
    delete: toggleDeletePrompt,
    download,
    manageAccess: toggleObjectAccessModal,
    move: toggleMoveFileModal,
    rename: toggleRenameModal,
  }), [
    download,
    toggleDeletePrompt,
    toggleMoveFileModal,
    toggleObjectAccessModal,
    toggleOwnershipModal,
    toggleRenameModal,
  ]);

  return (
    <FileActionsContext.Provider value={actions}>
      {props.children}
      {menu.canManageAccess &&
        <ObjectAccessModal />}
      {menu.canChangeOwner &&
        <ObjectOwnershipContainer objectId={object.id} workspaceId={object.workspaceId}>
          <ObjectOwnershipModal />
        </ObjectOwnershipContainer>}
      {menu.canMove &&
        <MoveObjectContainer objectId={object.id}>
          <MoveFileModal />
        </MoveObjectContainer>}
      {menu.canDelete &&
        <DeleteFileContainer fileId={file.id}>
          <DeleteFilePrompt />
        </DeleteFileContainer>}
      {menu.canRename &&
        <RenameFileContainer fileId={file.id}>
          <RenameFileModal />
        </RenameFileContainer>}
    </FileActionsContext.Provider>
  );
};

export const FileMenuContainer = (props: Props) => {
  return (
    <FileMenuItemsContainer fileId={props.fileId}>
      <FileActionsContainer fileId={props.fileId}>
        {props.children}
      </FileActionsContainer>
    </FileMenuItemsContainer>
  );
};

export default FileMenuContainer;
import { Fragment, memo, useCallback } from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import Popover from '@mui/material/Popover';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { SurveyQuestionType } from '@enums/Survey';
import { MatrixGridQuestion, MultipleChoiceQuestion, SurveyEnrichedQuestionCondition } from '@/types';
import { wrapInParentheses, generateConditionString } from './utils';
import styles from './style/ConditionsPopover.css';

type Props = {
  items: SurveyEnrichedQuestionCondition[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: 'var(--pri-01)',
      border: '1px solid transparent',
      borderRadius: 4,
      boxShadow: '0 2px 6px 2px rgba(60,64,67,.15)',
    },
    popover: {
      pointerEvents: 'none',
      marginTop: 10,
    },
  }),
);

export const ConditionsPopper = ({ items }: Props) => {
  const classes = useStyles();

  const popupState = usePopupState({
    popupId: 'survey-conditions-popover',
    variant: 'popover',
  });

  return (
    <>
      <div
        onMouseEnter={popupState.open}
        onMouseLeave={popupState.close}>
        <ConditionsShorthand items={items} />
      </div>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        disableScrollLock
        open={popupState.isOpen}
        anchorEl={popupState.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={popupState.close}
        disableRestoreFocus>
        <ConditionsPopover items={items} />
      </Popover>
    </>
  );
};

export const ConditionsShorthand = memo(({ items }: Props) => {

  const text = items
    .map(generateConditionString)
    .map(m => items.length > 1 ? wrapInParentheses(m) : m)
    .join(' AND ');

  if (items.some(s => !s)) return null;

  return (
    <div className={styles.root}>
      {text}
    </div>
  );
});

const ConditionsPopover = memo(({ items }: Props) => {

  const renderCondition = useCallback((item: SurveyEnrichedQuestionCondition) => {
    switch (item.question.typeId) {
      case SurveyQuestionType.MultipleChoice:
      case SurveyQuestionType.Multiselect: {
        const v = (item.condition as MultipleChoiceQuestion.Logic.EnrichedCondition).value;
        return (
          <div className={styles.condition}>
            <div className={styles.ordinal}>A</div>
            {v.option.value}
          </div>
        );
      }

      case SurveyQuestionType.MatrixGrid: {
        const v = (item.condition as MatrixGridQuestion.Logic.EnrichedCondition).value;
        return (
          <>
            <div className={styles.condition}>
              <div className={styles.ordinal}>R</div>
              {v.row.value}
            </div>
            <div className={styles.condition}>
              <div className={styles.ordinal}>A</div>
              {v.option.value}
            </div>
          </>
        );
      }

      default:
        return null;
    }
  }, []);

  const renderItem = useCallback((item: SurveyEnrichedQuestionCondition, i: number) => {
    const isLast = i === items.length - 1;

    return (
      <Fragment key={i}>
        <div className={styles.item}>
          <div className={styles.question}>
            <div className={styles.ordinal}>Q{item.question.ordinal}</div>
            {item.question.value}
          </div>
          {renderCondition(item)}
        </div>
        {!isLast &&
          <div className={styles.divider}>
            <div className={styles.line} />
            <div className={styles.and}>AND</div>
          </div>
        }
      </Fragment>
    );
  }, [
    items.length,
    renderCondition,
  ]);

  return (
    <div className={styles.popover}>
      {items.map(renderItem)}
    </div>
  );
});
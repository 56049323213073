import { cx } from '@utils';
import { Button } from 'components/Button';
import { ButtonProps } from 'components/Button/interfaces';
import styles from './style/Skip.css';

type Props = {
  children?:  React.ReactNode;
  className?: string;
} & ButtonProps;

const Skip = ({ children, className, ...props }: Props) => {
  const classes = cx(
    styles.root,
    styles.skip,
    className,
  );

  return (
    <Button
      className={classes}
      {...props}>
      {children}
    </Button>
  );
};

const defaultProps = {
  children: 'Skip',
  variant: 'link',
};

Skip.defaultProps = defaultProps;

export { Skip };
export default Skip;
import { useMemo } from 'react';
import { PlusCircle } from 'react-feather';
import * as enums from '@enums';
import { AddEntryButtonProps } from './interfaces';
import styles from './style.css';

const AddEntryButton = (props: AddEntryButtonProps) => {
  const entryName = useMemo(() => {
    if (props.type === enums.ProfileEntry.Education) {
      return 'Education';
    } else if (props.type === enums.ProfileEntry.Employment) {
      return 'Position';
    }
  }, [props.type]);

  return (
    <div
      className={props.className}
      onClick={props.onClick}>
      <PlusCircle className={styles.plus} />
      {`Add ${entryName}`}
    </div>
  );
};

export default AddEntryButton;
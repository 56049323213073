import { Header } from 'components/Modal/Header';
import { Modal, ModalProps } from 'components/Modal/Modal';
import ObjectAccessForm from './ObjectAccess';
import styles from './style/Modal.css';

type Props =
  Pick<ModalProps,
    'onClose' |
    'open'>;

export const ObjectAccessModal = ({ onClose, open }: Props) => {

  return (
    <Modal className={styles.modal} onClose={onClose} open={open}>
      <div className={styles.root}>
        <div className={styles.wrap}>
          <Header
            className={styles.header}
            text="Manage Access" />
          <ObjectAccessForm onCancel={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default ObjectAccessModal;
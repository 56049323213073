import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as api from '@api';
import * as actions from '@store/actions';
import { useCallRequestRecipient } from '@containers/RequestCall/hooks';
import * as mixpanel from '@services/mixpanel';
import Toast from 'components/Toast';
import {
  useAdHocRequestNote,
  useAdHocRequestRate,
  useAdHocRequestStepper,
  useAdHocRequestSubject,
} from '../Context';

export const useCreateAdHocCallRequest = () => {

  const recipient = useCallRequestRecipient();
  const [note] = useAdHocRequestNote();
  const [subject] = useAdHocRequestSubject();
  const rate = useAdHocRequestRate();
  const { next } = useAdHocRequestStepper();
  const dispatch = useDispatch();

  return useCallback(() => {
    return api.scheduling.adHocCall.create({
      hourlyRate: rate,
      note: note ? note : null,
      subject,
      user: {
        id: recipient.id,
      },
    }).then(data => {
      dispatch(actions.batchActions([
        actions.adHocCallCreated({
          call: data.call,
        }),
        actions.contactsAdded({
          contacts: data.contacts,
        }),
      ]));

      mixpanel.event.adHocCall.callRequestCallComplete({ userId: recipient.id });

      next();
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: 'There was an issue creating your request.',
      });
    });
  }, [
    next,
    dispatch,
    note,
    rate,
    recipient.id,
    subject,
  ]);
};

export default useCreateAdHocCallRequest;
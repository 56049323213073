import { Link } from 'react-router-dom';
import { useSelectUser } from '@containers/Store';
import { cdn, getLocationFor } from '@utils';
import { UserAvatar } from 'components/UserAvatar';
import styles from './style/AccountProfile.css';

type Props = unknown;

const AccountProfile = (props: Props) => {
  const me = useSelectUser();

  const profileLink = getLocationFor.me.profile({
    userId: me.id,
    slug: me.profile.slug,
  });

  const pictureUrl = cdn.buildAvatarUrl(me.profile.pictureUrl, 100);

  return (
    <div className={styles.root}>
      <UserAvatar
        pictureUrl={pictureUrl}
        to={profileLink}
        size={52} />
      <div className={styles.details}>
        <div className={styles.name}>{me.profile.fullname}</div>
        <Link
          className={styles.link}
          to={profileLink}>
          View Profile
        </Link>
      </div>
    </div>
  );
};

export { AccountProfile };
export default AccountProfile;
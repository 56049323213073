import { useCallback, useMemo } from 'react';
import * as matrixSlider from '@containers/SurveyResponses/utils/matrix-slider';
import { OpenedResponseOptionRow, MatrixSliderValueRow } from '@presentation/ProjectSurveyResponses';
import { formatPlurality } from '@utils';
import { MatrixSliderQuestion } from '@/types';
import { MatrixSliderItem } from './interfaces';
import Respondent from './ResponseOptionUser';
import styles from './style/MatrixSlider.Data.css';

type Props = {
  item: MatrixSliderItem;
  onSelect: (bucket: string) => unknown;
  onClose: () => unknown;
  question: MatrixSliderQuestion.Question;
  selectedKey: string;
};

export const MatrixSliderRowData = ({ item, onSelect, onClose, question, selectedKey }: Props) => {

  const data = useMemo(() => {
    return matrixSlider.getRowData(question, item.responses);
  }, [
    item.responses,
    question,
  ]);

  const renderOpenedBucket = useCallback((bucket: matrixSlider.Bucket) => {
    matrixSlider.assertBucketData(data);
    const userIds = data.map[matrixSlider.formatBucketKey(bucket)];
    const subtitle = `(${userIds.length} ${formatPlurality('Respondent', userIds.length)})`;
    const display = matrixSlider.formatBucketDisplay(bucket);

    return (
      <>
        <OpenedResponseOptionRow
          color="var(--pri-01)"
          onClose={() => onSelect(undefined)}
          subtitle={subtitle}
          label={display} />
        {userIds.map(userId => (
          <Respondent
            key={userId}
            value={item.responses[userId].toString()}
            userId={userId} />
        ))}
      </>
    );
  }, [
    data,
    item.responses,
    onSelect,
  ]);

  const renderOpenedValue = useCallback((value: number) => {
    matrixSlider.assertValueData(data);
    const userIds = data.map[value];
    const subtitle = `(${userIds.length} ${formatPlurality('Respondent', userIds.length)})`;

    return (
      <>
        <OpenedResponseOptionRow
          color="var(--pri-01)"
          onClose={() => onSelect(undefined)}
          subtitle={subtitle}
          label={value.toString()} />
        {userIds.map(userId => (
          <Respondent
            key={userId}
            userId={userId} />
        ))}
      </>
    );
  }, [
    data,
    onSelect,
  ]);

  const renderBuckets = useCallback(() => {
    matrixSlider.assertBucketData(data);
    return data.items
      .map(b => {
        const key = matrixSlider.formatBucketKey(b);
        const values = data.map[key];

        if (!values.length) return null;

        return (
          <MatrixSliderValueRow
            key={key}
            count={values.length}
            onClick={() => onSelect(key)}
            value={matrixSlider.formatBucketDisplay(b)} />
        );
      }).filter(Boolean);
  }, [
    data,
    onSelect,
  ]);

  const renderValues = useCallback(() => {
    matrixSlider.assertValueData(data);
    return data.items
      .map(b => {
        const values = data.map[b];

        if (!values.length) return null;

        return (
          <MatrixSliderValueRow
            key={b.toString()}
            count={values.length}
            onClick={() => onSelect(b.toString())}
            value={b.toString()} />
        );
      }).filter(Boolean);
  }, [
    data,
    onSelect,
  ]);

  const renderClosed = useCallback(() => {
    if (selectedKey) {
      return null;
    }

    if (data.useBuckets) {
      return renderBuckets();
    } else {
      return renderValues();
    }
  }, [
    data.useBuckets,
    renderBuckets,
    renderValues,
    selectedKey,
  ]);

  const renderOpened = useCallback(() => {
    if (!selectedKey) {
      return null;
    }

    if (data.useBuckets) {
      return renderOpenedBucket(matrixSlider.formatBucketFromKey(selectedKey));
    } else {
      return renderOpenedValue(+selectedKey);
    }
  }, [
    data.useBuckets,
    renderOpenedBucket,
    renderOpenedValue,
    selectedKey,
  ]);

  const subtitle = `(Avg: ${item.displayAvg})`;

  return (
    <div className={styles.options}>
      <OpenedResponseOptionRow
        color={item.color}
        onClose={onClose}
        subtitle={subtitle}
        label={item.name} />
      {renderOpened()}
      {renderClosed()}
    </div>
  );
};

export default MatrixSliderRowData;
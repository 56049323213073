import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, GroupCardHeader, Divider } from '@presentation/Main';
import { cx, getLocationFor, slugify } from '@utils';
import { useRecentProfiles } from '@utils/api';
import { RecentProfile } from '@/types';
import { UserAvatar } from 'components/UserAvatar/UserAvatar';
import { AvatarOutline } from 'components/images';
import styles from './style/RecentProfiles.css';

export const RecentProfiles = () => {
  const profiles = useRecentProfiles({
    enabled: false,
    notifyOnChangeProps: ['data'],
  });

  return (
    <Card className={cx(styles.desktop, styles.root)}>
      <GroupCardHeader>Recent Profiles</GroupCardHeader>
      <Divider />
      <CardContent
        fluid
        className={styles.content}>
        {!profiles.data.length && <EmptyState />}
        {profiles.data.map(p => (
          <Profile
            key={p.id}
            {...p} />
        ))}
      </CardContent>
    </Card>
  );
};

const Profile = memo((item: RecentProfile) => {
  const profileLocation = getLocationFor.user.profile({
    slug: slugify({
      id: item.id,
      name: item.contact.profile.fullname,
    })});

  return (
    <div className={styles.row}>
      <UserAvatar
        className={styles.avatar}
        pictureUrl={item.contact.profile.pictureUrl}
        to={profileLocation}
        size={45} />
      <div className={styles.details}>
        <Link
          className={styles.name}
          to={profileLocation}>
          {item.contact.profile.fullname}
        </Link>
        <div className={styles.title}>
          {item.contact.career.current?.display || item.contact.career.former?.display}
        </div>
      </div>
    </div>
  );
});

const EmptyState = memo(() => {
  return (
    <div className={styles.empty}>
      <AvatarOutline
        className={styles.emptyImg}
        size={100} />
      <div className={styles.emptyTxt}>No recent profiles</div>
    </div>
  );
});

export default RecentProfiles;
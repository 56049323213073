import { useCallback, useContext } from 'react';
import { UploadFilesContext } from '../Context';

type Props = {
  parentObjectId: number;
  workspaceId: number;
};

export const useUploadFilesContext = (props: Props) => {
  const uploadCtx = useContext(UploadFilesContext);

  const upload = useCallback((files: File[]) => {
    return uploadCtx({
      files,
      parentObjectId: props.parentObjectId,
      workspaceId: props.workspaceId,
    });
  }, [
    props,
    uploadCtx,
  ]);

  return upload;
};

export default useUploadFilesContext;
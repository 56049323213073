import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { compareDesc, format, isToday, isThisWeek } from 'date-fns';
import * as enums from '@enums';
import { selectChatDirectoryProjects, selectMessageItems, isMessageInvitation } from '@store/selectors';
import { IChat } from '@/types';
import { cx } from '@utils';
import Channels from './Channels';
import Directory from './Directory';
import styles from './style/Sidebar.css';

type Props = {
  activeConversationSid: string;
  messengerActive: boolean;
  sidebar: enums.ChatSidebar;
  onLoadUserId: number;
  setChannel: (item: IChat.DirectoryItem) => unknown;
  toggleView: (value: enums.ChatSidebar) => unknown;
};

const mapState = (state: Store.State) => ({
  conversations: state.chat.conversations,
  contacts: state.contacts,
  connections: state.chat.connections,
  messages: selectMessageItems(state),
  projects: selectChatDirectoryProjects(state),
  participants: state.chat.participants,
  user: state.user,
});

export const Sidebar = ({
  activeConversationSid,
  messengerActive,
  sidebar,
  onLoadUserId,
  setChannel,
  toggleView,
}: Props) => {
  const {
    conversations,
    contacts,
    connections,
    messages,
    projects,
    participants,
    user,
  } = useSelector(mapState);

  const getConversationMessagesCount = useCallback((conversationSid: string) => {
    const items = messages[conversationSid];
    return !items || !items.length ? 0 : items.length;
  }, [messages]);

  const getConversationLastMessageDate = useCallback((message: IChat.Message) => {
    const date = message.data.timestamp;

    if (!date) return null;

    return isToday(date)
      ? 'Today'
      : isThisWeek(date)
        ? format(date, 'EEE')
        : format(date, 'M/dd/yy');
  }, []);

  const getConversationLastMessageAuthorName = useCallback((message: IChat.Message) => {
    const userId = message.data.userId;

    if (userId === user.id) return 'Me';

    const contact = contacts[userId];

    if (!contact) console.error(`No author found with id ${userId}`);

    return contact.profile?.firstName;
  }, [contacts, user?.id]);

  const getConversationLastMessage = useCallback((conversationSid: string) => {
    const items = messages[conversationSid];

    if (!items || !items.length) return null;

    const message = items[items.length - 1 || 0];

    const author = getConversationLastMessageAuthorName(message);
    const date = getConversationLastMessageDate(message);

    return {
      body: `${author}: ${message.data.body}`,
      date,
    };
  }, [messages, getConversationLastMessageAuthorName, getConversationLastMessageDate]);

  const getConversationUnreadMessagesCount = useCallback((conversation: IChat.Conversation) => {
    const items = messages[conversation.sid];

    if (!items || !items.length) return 0;

    const lastIndex = Number(conversation.lastMessage && conversation.lastMessage.index) || 0;
    const lastConsumedIndex = Number(conversation.lastReadMessageIndex) || 0;

    const invitations = items.filter(message => {
      if (message.type === 'pending') return false;
      return message.data.userId !== user.id && isMessageInvitation(message, connections.ids.includes(message.data.userId));
    }).length;

    const twilio = conversation.lastReadMessageIndex === null
      ? conversation.lastMessage
        ? lastIndex + 1
        : 0
      : Math.max(0, lastIndex - lastConsumedIndex);

    return twilio + invitations;
  }, [messages, connections?.ids, user?.id]);

  const getConversation = useCallback((conversationSid: string): IChat.DirectoryItemConversation => {
    if (!conversationSid) return null;

    const conversation = conversations[conversationSid];

    return {
      state: conversation,
      messagesCount: getConversationMessagesCount(conversation.sid),
      mostRecent: getConversationLastMessage(conversation.sid),
      unreadCount: getConversationUnreadMessagesCount(conversation),
    };
  }, [conversations, getConversationMessagesCount, getConversationLastMessage, getConversationUnreadMessagesCount]);

  const getConversationParticipant = useCallback((conversationSid: string): IChat.DirectoryItemParticipant => {
    if (!conversationSid) return null;

    const conversation = conversations[conversationSid];
    const attributes = conversation.attributes as IChat.ConversationAttributes;
    const userId = attributes.userIds.find(id => id !== user.id);
    const contact = contacts[userId];
    const userChannel = participants[userId];

    return {
      contact,
      status: userChannel.status,
    };

  }, [participants, conversations, contacts, user.id]);

  const getConversationTimestamp = useCallback((conversation: IChat.DirectoryItemConversation) => {
    const items = messages[conversation.state.sid];
    const lastMessage = items?.length ? items[items.length - 1] : null;
    return lastMessage
      ? lastMessage.data.timestamp
      : conversation.state.dateCreated;
  }, [messages]);

  const getConversationItems = useCallback(() => {
    return conversations.ids.map(id => {
      const count = getConversationMessagesCount(id);
      if (!count) return;

      return {
        conversation: getConversation(id),
        participant: getConversationParticipant(id),
      };
    })
    .filter(Boolean)
    .sort((a, b) => {
      const aCount = getConversationMessagesCount(a.conversation.state.sid);
      const bCount = getConversationMessagesCount(b.conversation.state.sid);

      if (aCount === 0) {
        return bCount > 0 ? 1 : -1;
      }

      return compareDesc(
        getConversationTimestamp(a.conversation),
        getConversationTimestamp(b.conversation),
      );
    });
  }, [conversations.ids, getConversation, getConversationParticipant, getConversationMessagesCount, getConversationTimestamp]);

  const getDirectoryProjectItems = useCallback((map: Record<number, Pick<IChat.DirectoryItem, 'participant'>>) => (userIds: number[]) => {
    return !userIds || !userIds.length
      ? []
      : userIds.map(userId => map[userId])
               .sort((a, b) => a.participant.contact.profile.fullname.localeCompare(b.participant.contact.profile.fullname));
  }, []);

  const getDirectoryItems = useCallback(() => {
    const itemsByUserId = contacts.ids.reduce((acc, id) => {
      const userChannel = participants[id];
      const conversationSid = userChannel && userChannel.conversationSid;

      return {
        ...acc,
        [id]: {
          conversation: getConversation(conversationSid),
          participant: {
            contact: contacts[id],
            status: userChannel?.status,
          },
        },
      };
    }, {});

    const getItems = getDirectoryProjectItems(itemsByUserId);

    return projects.reduce((acc, project) => {
      return acc.concat({
        project: { id: project.id, name: project.name },
        items: getItems(project.userIds),
      });
    }, []);
  }, [contacts, projects, participants, getConversation, getDirectoryProjectItems]);

  const handleToggleView = useCallback((value: enums.ChatSidebar) => () => {
    toggleView(value);
  }, [toggleView]);

  return (
    <div className={cx(styles.root, {
      [styles.hidden]: !messengerActive,
      [styles.visible]: messengerActive,
    })}>
      {sidebar === enums.ChatSidebar.Channels
        ? <Channels
            activeConversationSid={activeConversationSid}
            items={getConversationItems()}
            onClick={handleToggleView(enums.ChatSidebar.Directory)}
            setChannel={setChannel}
            onLoadUserId={onLoadUserId} />
        : <Directory
            items={getDirectoryItems()}
            onClick={handleToggleView(enums.ChatSidebar.Channels)}
            setChannel={setChannel} />}
    </div>
  );
};

export default Sidebar;
import { PostDeleteTogglePromptContext } from '@containers/Post/Context';
import { DeletePostPrompt } from 'components/Posts/DeletePostPrompt';
import { useModal } from 'components/Modal/hooks/useModal';
import { PostDeleteContext } from './Context';

type Props = {
  children:  React.ReactNode;
  onConfirm: () => Promise<unknown>;
  postId:    number;
};

export const DeleteContainer = (props: Props) => {
  const [toggle, Prompt] = useModal(DeletePostPrompt);

  return (
    <PostDeleteTogglePromptContext.Provider value={toggle}>
      <PostDeleteContext.Provider value={props.onConfirm}>
        {props.children}
        <Prompt />
      </PostDeleteContext.Provider>
    </PostDeleteTogglePromptContext.Provider>
  );
};

DeleteContainer.displayName = 'Post.DeleteContainer';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import * as selectors from '@store/selectors';
import * as enums from '@enums';
import { formatTruncatedName } from '@utils';
import { FilesTableItem } from 'components/WorkspaceFiles/interfaces';
import FilesTable from './FilesTable';

const selectSharedFiles = createSelector(
  [
    (state: Store.State) => state.user.id,
    (state: Store.State) => state.contacts,
    selectors.selectFileIds,
    selectors.selectFileValues,
    selectors.selectObjectValues,
  ],
  (meId, contacts, fileIds, files, objects) => {
    return fileIds
    .reduce((acc, fileId) => {
      const file = files[fileId];
      const object = objects[file.objectId];
      const parentObject = objects[object.parentObjectId];

      if (parentObject &&
        (parentObject.ownerId === meId || parentObject.typeId !== enums.WorkspaceObjectType.Workspace)) {
        return acc;
      }

      const item = {
        ...file,
        owner: object.ownerId === meId
          ? 'Me'
          : formatTruncatedName(contacts[object.ownerId]),
      };

      return [...acc, item];

    }, [] as FilesTableItem[]);
  },

);

export const SharedTabFiles = () => {

  const data = useSelector(selectSharedFiles);

  return (
    <FilesTable
      canUpload={false}
      data={data} />
  );
};

export default SharedTabFiles;
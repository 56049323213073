import { forwardRef, useCallback, useMemo, Ref } from 'react';
import { Link } from 'react-router-dom';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { useProjectActions, useProjectActionsValidity } from '@containers/GroupProject/hooks';
import { MoreHorizontalAnchor } from '@presentation/Anchor';
import { getLocationFor } from '@utils';
import { PopperMenu, PopperMenuItem } from 'components/Popper';
import { ProjectActionsContextMenuItem, ProjectActionsPopperProps, ProjectActionsMenuProps } from './interfaces';
import styles from './style/ProjectActionsContextMenu.css';

type ClickEvent = () => unknown;

const ProjectActionsContextMenu = forwardRef(({ excluded = [], onClose }: ProjectActionsMenuProps, ref: Ref<HTMLDivElement>) => {
  const actions = useProjectActions();
  const valid = useProjectActionsValidity();

  const handleClick = useCallback((fn: ClickEvent) => () => {
    onClose();
    return fn();
  }, [onClose]);

  const activate = useMemo(() => {

    return {
      children: 'Activate',
      onClick: actions.activate,
    };
  }, [
    actions.activate,
  ]);

  const archive = useMemo(() => {

    return {
      children: 'Archive',
      onClick: actions.archive,
    };
  }, [
    actions.archive,
  ]);

  const cloneSurvey = useMemo(() => {

    return {
      children: 'Clone Survey',
      onClick: actions.clone,
    };

  }, [
    actions.clone,
  ]);

  const manage = useMemo(() => {
    return {
      children: 'Manage Access',
      onClick: actions.manage,
    };
  }, [
    actions.manage,
  ]);

  const callReport = useMemo(() => {
    return {
      children: 'Export Call Data',
      onClick: actions.downloadCallReport,
    };
  }, [actions.downloadCallReport]);

  const surveyReport = useMemo(() => {
    return {
      children: 'Export Survey Data',
      onClick: actions.downloadSurveyReport,
    };
  }, [actions.downloadSurveyReport]);

  const editSurvey = useMemo(() => {
    return {
      children: 'Edit Survey',
      onClick: actions.editSurvey,
    };
  }, [
    actions.editSurvey,
  ]);

  const share = useMemo(() => {
    return {
      children: 'Share',
      onClick: actions.share,
    };
  }, [
    actions.share,
  ]);

  const ownership = useMemo(() => {
    return {
      children: 'Change Owner',
      onClick: actions.changeOwner,
    };
  }, [
    actions.changeOwner,
  ]);

  const move = useMemo(() => {
    return {
      children: 'Move',
      onClick: actions.move,
    };
  }, [
    actions.move,
  ]);

  const preview = useMemo(() =>
  ({
    children: (<Link className={styles.linkButton} target={'_blank'} to={getLocationFor.project.preview({ id: actions.projectId })}>Preview Survey</Link>),
    onClick: () => { },
  }), [actions.projectId]);

  const surveyMonitoring = useMemo(() => {
    return {
      children: 'Survey Monitoring',
      onClick: actions.monitorSurvey,
    };
  }, [
    actions.monitorSurvey,
  ]);

  const items: ProjectActionsContextMenuItem[] = useMemo(() => [
    valid.canShare && !excluded.includes('share') && share,
    valid.canManage && !excluded.includes('manage') && manage,
    valid.canChangeOwner && !excluded.includes('ownership') && ownership,
    valid.canClone && !excluded.includes('clone') && cloneSurvey,
    valid.canDownloadCalls && !excluded.includes('download-report') && callReport,
    valid.canDownloadSurveys && !excluded.includes('download-report') && surveyReport,
    valid.canArchive && !excluded.includes('update-status') && archive,
    valid.canActivate && !excluded.includes('update-status') && activate,
    valid.canMove && move,
    valid.canEditSurvey && !excluded.includes('edit-survey') && editSurvey,
    valid.canPreview && preview,
    valid.canMonitorSurvey && surveyMonitoring,
  ].filter(Boolean), [
    archive,
    activate,
    callReport,
    cloneSurvey,
    editSurvey,
    excluded,
    manage,
    move,
    ownership,
    share,
    valid,
    preview,
    surveyMonitoring,
    surveyReport,
  ]);

  return (
    <PopperMenu ref={ref}>
      {items.map((item, i) => (
        <PopperMenuItem
          key={i}
          onClick={handleClick(item.onClick)}>
          {item.children}
        </PopperMenuItem>
      ))}
    </PopperMenu>
  );
});

export const ProjectActionsContextMenuPopper = ({
  Anchor = MoreHorizontalAnchor,
  excluded,
  placement = 'bottom-end',
}: ProjectActionsPopperProps) => {

  return (
    <PopupState variant="popper" popupId="project-actions-popper">
      {popupState => (
        <div>
          <div {...bindToggle(popupState)}>
            <Anchor open={popupState.isOpen} />
          </div>
          <Popper {...bindPopper(popupState)} placement={placement} className={styles.popper}>
            <ClickAwayListener
              onClickAway={popupState.close}>
              <ProjectActionsContextMenu
                onClose={popupState.close}
                excluded={excluded} />
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </PopupState>
  );
};
import http from '@services/http';
import { normalizeSurveyResponse } from '@transformers/surveys';
import { qs } from '@utils';
import { Admin, Pipeline, Projects } from './interfaces';
import { transformProjectMember } from './transformers/projects';

export const engageMembers = (data: Projects.EngageMembers.Request) => {
  return http.post(`/admin/projects/${data.projectId}/members/engagements`, data);
};

export const fetchComplianceSurveyResponse = (data: Admin.GetComplianceSurveyResponse.Request): Promise<Admin.GetComplianceSurveyResponse.Response> => {
  return http.get(`/admin/projects/${data.projectId}/members/${data.userId}/compliance-survey`)
  .then(normalizeSurveyResponse);
};

export const fetchMembers = (data: Projects.FetchProjectMembers.Request): Promise<Projects.FetchProjectMembers.Response> => {
  return http.get(`/admin/projects/${data.projectId}/members?${qs.stringify(data, { arrayFormat: 'bracket' })}`)
  .then((data: Projects.FetchProjectMembers.Response<string>) => ({
    items: data.items.map(transformProjectMember),
    pagination: data.pagination,
  }));
};

export const fetchMembersExport = (data: Projects.FetchProjectMembersExport.Request) => {
  return http.download(`/admin/projects/${data.projectId}/members?${qs.stringify(data, { arrayFormat: 'bracket' })}`);
};

export const inviteUser = (data: Pipeline.InviteUser.Request) => {
  return http.patch(`/admin/projects/${data.projectId}/members/${data.userId}/invite`, {});
};

export const inviteUsers = (data: Pipeline.InviteUsers.Request) => {
  return http.post(`/admin/projects/${data.projectId}/members/invitations`, data);
};

export function followupMembers(params: Pipeline.FollowupMembers.Request) {
  const { projectId, ...data } = params;
  return http.post(`/admin/projects/${projectId}/members/followups`, data);
}

export const recommendUser = (data: Projects.RecommendUser.Request): Promise<Projects.RecommendUser.Response> => {
  return http.post(`/admin/projects/${data.projectId}/members/recommendations`, data);
};

export const rejectUser = (data: Projects.RejectProjectUser.Request): Promise<Projects.RejectProjectUser.Response> => {
  const { projectId, userId, notifyExpert } = data;
  return http.patch(`/admin/projects/${projectId}/members/${userId}/reject`, { notifyExpert });
};

export const rejectUsers = (data: Projects.RejectProjectUsers.Request): Promise<Projects.RejectProjectUsers.Response> => {
  return http.post(`/admin/projects/${data.projectId}/members/rejections`, data);
};

export const rejectSurveyRespondentFromProject = (data: Projects.RejectSurveyRespondentFromProject.Request): Promise<Projects.RejectSurveyRespondentFromProject.Response> => {
  return http.put(`/admin/projects/${data.projectId}/members/${data.userId}/responses/reject`, data);
};

export const resetDisqualifiedUser = (data: Pipeline.ResetDisqualifiedUser.Request) => {
  return http.patch(`/admin/projects/${data.projectId}/members/${data.userId}/reset-disqualification`, {});
};

export const resetMembers = (params: Pipeline.ResetMembers.Request) => {
  const { projectId, ...data } = params;
  return http.post(`/admin/projects/${projectId}/members/reset`, data);
};

export const updateMemberRecommendation = (data: Projects.UpdateMemberRecommendation.Request): Promise<Projects.UpdateMemberRecommendation.Response> => {
  return http.put(`/admin/projects/${data.projectId}/members/recommendations/${data.recommendationId}`, data);
};

export const uploadProjectDocument = (data: Projects.UploadProjectDocument.Request): Promise<Projects.UploadProjectDocument.Response> => {
  return http.post(`admin/projects/${data.projectId}/members/${data.userId}/documents`, data.formData, {
    headers: {
      'Content-Type': `multipart/form-data;`,
    },
  });
};

export const bulkAddToAProject = (params: Projects.BulkAddToAProject.Request): Promise<Projects.BulkAddToAProject.Response> => {
  const { targetProjectId, ...data } = params;
  return http.post(`/admin/projects/${targetProjectId}/members/bulk`, data);
};
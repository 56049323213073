import { io } from 'socket.io-client';
import { LifeCycleSocketEvent, PipelineSocketEvent, SocketNamespace } from '@enums';
import * as $session from '@services/auth/session';
import { createLogger } from './utils';
import { Pipeline } from './interfaces';

const log = createLogger(SocketNamespace.Pipeline);

const socket = io(`${process.env.BACKEND_WS_URL}/${SocketNamespace.Pipeline}`, {
  path: process.env.BACKEND_WS_PATH,
  auth: {
    'X-CSRF-Token': $session.getCSRF(),
  },
  autoConnect: false,
  withCredentials: true,
  transports: ['websocket', 'polling'],
});

function open() {
  socket.on(LifeCycleSocketEvent.Connect, () => log.event(LifeCycleSocketEvent.Connect));
  socket.on(LifeCycleSocketEvent.ConnectError, data => log.event(LifeCycleSocketEvent.ConnectError, data));
  socket.on(LifeCycleSocketEvent.Disconnect, () => log.event(LifeCycleSocketEvent.Disconnect));

  socket.open();
}

function close() {
  socket.close();

  socket.off(LifeCycleSocketEvent.Connect);
  socket.off(LifeCycleSocketEvent.ConnectError);
  socket.off(LifeCycleSocketEvent.Disconnect);
}

const on = <T extends PipelineSocketEvent>(event: PipelineSocketEvent, handler: Pipeline.RegisterHandler<T>) => {
  return socket.on(event, handler);
};

const off = <T extends PipelineSocketEvent>(event: PipelineSocketEvent, handler: Pipeline.RegisterHandler<T>) => {
  return socket.off(event, handler);
};

const pipeline = {
  close,
  off,
  on,
  open,
};

export { pipeline };
export default pipeline;
import { useCallback, useMemo } from 'react';
import { useConjointAnalysisAttributeColors, useRepeatQuestionResponsesData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey } from '@containers/RepeatSurveyResponses/interfaces';
import { SurveyQuestionType } from '@enums';
import { ConjointAnalysisLevelRow, ProjectVersionHeaderRow, ResponseOption } from '@presentation';
import { roundToPlace } from '@utils';
import { ConjointAnalysisQuestion } from '@/types';
import { SimpleAccordion } from 'components/Accordion';
import { useFilteredAttributes, useProjectVersion, useQueryAccordionMap } from './hooks';
import styles from './style/Data.css';

export const ConjointAnalysisVersionData = () => {
  const { query, setQueryState, resetQueryState } = useRepeatSurveyResponsesState<RepeatSurvey.QueryState.ConjointAnalysis>();
  const { historical, question } = useRepeatQuestionResponsesData<SurveyQuestionType.ConjointAnalysis>();
  const [isAccordionOpen, toggleAccordion] = useQueryAccordionMap('attributeIds');

  const version = useProjectVersion(query.projectId);
  const attributes = useFilteredAttributes();

  const versionData = useMemo(() => {
    return historical[query.projectId].data;
  }, [
    historical,
    query.projectId,
  ]);

  const handleLevelClick = useCallback((levelId: number) => () => {
    setQueryState({
      projectId: query.projectId,
      levelId,
    });
  }, [
    query.projectId,
    setQueryState,
  ]);

  const getLevels = useCallback((attributeId: number) => {
    const attribute = question.attributes.find(f => f.base.id === attributeId);

    return attribute.levels
      .sort((a, b) => {
        const utilityA = versionData.levels[a.base.id].utility;
        const utilityB = versionData.levels[b.base.id].utility;
        return utilityB - utilityA;
      });
  }, [
    versionData,
    question.attributes,
  ]);

  const renderLevel = useCallback((level: ConjointAnalysisQuestion.Level) => {
    const data = versionData.levels[level.base.id];

    return (
      <ConjointAnalysisLevelRow
        key={level.base.id}
        level={level}
        utility={data.utility}
        onClick={handleLevelClick(level.base.id)} />
    );
  }, [
    versionData,
    handleLevelClick,
  ]);

  const renderAttribute = useCallback((attribute: ConjointAnalysisQuestion.Attribute) => {
    const data = versionData.attributes[attribute.base.id];
    const levels = getLevels(attribute.base.id);

    return (
      <SimpleAccordion
        key={attribute.base.id}
        open={isAccordionOpen(attribute.base.id)}
        toggleOpen={toggleAccordion(attribute.base.id)}
        grows={false}
        className={styles.row}
        height={50}
        label={(
          <AccordionLabel
            attribute={attribute}
            data={data} />
        )}>
        {levels.map(renderLevel)}
      </SimpleAccordion>
    );
  }, [
    getLevels,
    isAccordionOpen,
    renderLevel,
    toggleAccordion,
    versionData,
  ]);

  const sortedAttributes = useMemo(() => {

    return [...attributes]
      .sort((a, b) => {
        const aImportance = versionData.attributes[a.base.id].importance;
        const bImportance = versionData.attributes[b.base.id].importance;
        return bImportance - aImportance;
      });
  }, [
    attributes,
    versionData,
  ]);

  return (
    <>
      <ProjectVersionHeaderRow
        className={styles.row}
        onClick={resetQueryState}
        version={version} />
      {sortedAttributes.map(renderAttribute)}
    </>
  );
};

type AccordionLabelProps = {
  attribute: ConjointAnalysisQuestion.Attribute;
  data: ConjointAnalysisQuestion.Aggregate.AttributeResults;
};

function AccordionLabel({ attribute, data }: AccordionLabelProps) {
  const colors = useConjointAnalysisAttributeColors();
  const subtitle = `(${roundToPlace(data.importance, 1)}%)`;

  return (
    <ResponseOption
      color={colors[attribute.base.id]}
      label={attribute.value}
      subtitle={subtitle} />
  );
}

export default ConjointAnalysisVersionData;
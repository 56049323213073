import { Fragment } from 'react';
import { useAppReadyState } from '@containers/AppReadyState';
import { Footer as SiteFooter } from '$website/components/core/Footer';
import * as Banner from './Banner';

type Props = unknown;

export const Footer = (props: Props) => {
  const app = useAppReadyState();

  if (app.authenticated) return null;

  return (
    <Fragment>
      {/* <Banner.Join /> */}
      <SiteFooter />
    </Fragment>
  );
};

Footer.displayName = 'Discovery.Footer';
import { SurveyQuestionType } from '@enums';
import { MultiselectQuestion } from '@/types';
import { Validation } from '../interfaces';
import { assertValidOptions } from './validation.options';
import { assertHasValue, returnAssertFailure } from './validation.base';
import { assertValidSelectionSettings as assertSelectionSettings, getSelectionSettingsMinMax } from './validation.settings.selections';

export const DefaultOptions = 4;
export const MinOptions = 2;
export const MaxOptions = 50;

export const DefaultSettings: MultiselectQuestion.Settings = {
  allowOther: false,
  includeNoneOfTheAbove: false,
  maximumSelections: null,
  minimumSelections: null,
  randomization: false,
};

export function getSelectionSettingOptions(item: MultiselectQuestion.Question) {
  return getSelectionSettingsMinMax({
    hasNoneOfTheAbove: item.settings.includeNoneOfTheAbove,
    options: item.options.length,
  });
}

export function assertValidSelectionSettings(question: MultiselectQuestion.Question) {
  const [min, max] = getSelectionSettingOptions(question);

  return assertSelectionSettings({
    min,
    max,
    settings: question.settings,
  });
}

export function validateQuestion(question: MultiselectQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.Multiselect> {
  const hasValue = assertHasValue(question);
  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  const hasValidOptions = assertValidOptions(question.options, MinOptions, MaxOptions);
  if (hasValidOptions.success === false) {
    return returnAssertFailure(hasValidOptions, question);
  }

  const hasValidSettings = assertValidSelectionSettings(question);
  if (hasValidSettings.success === false) {
    return returnAssertFailure(hasValidSettings, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Twilio from '@twilio/conversations';
import * as $api from '@api';
import { ConferenceChatContext } from './Context';

export function ChatContainer({ children }: ChildrenProps) {
  const [conversationName, setConversationName] = useState<string>();
  const [conversation, setConversation] = useState<Twilio.Conversation>();
  const [client, setClient] = useState<Twilio.Client>();
  const [unread, setUnread] = useState<number>(0);
  const [participants, setParticipants] = useState<Twilio.Participant[]>([]);

  const [messages, ready] = useConversation(conversation);

  useEffect(() => {
    if (client) {
      const onConnectionStateChanged = async (state: Twilio.ConnectionState) => {
        if (state === 'connected') {
          const conversation = await client.getConversationByUniqueName(conversationName);
          await Promise.all([
            conversation.getParticipants().then(participants => setParticipants(participants)),
            conversation.getUnreadMessagesCount()
            .then(count => {
              if (count == null) {
                conversation.getMessagesCount().then(total => setUnread(Math.min(99, total)));
              } else {
                setUnread(count);
              }
            }),
          ]);
          setConversation(conversation);
        }
      };
      client.on('connectionStateChanged', onConnectionStateChanged);
      return () => {
        client.off('connectionStateChanged', onConnectionStateChanged);
      };
    }
  }, [client, conversationName]);

  useEffect(() => {
    if (conversation) {
      const participantJoined = (participant: Twilio.Participant) => {
        setParticipants(prev => [...prev.filter(p => p.sid !== participant.sid), participant]);
      };

      const participantUpdated = ({ participant, updateReasons }: { participant: Twilio.Participant; updateReasons: string[]; }) => {
        if (updateReasons.some(r => ['attributes'].includes(r))) {
          setParticipants(prev => [...prev.filter(p => p.sid !== participant.sid), participant]);
        }
      };

      const messageAdded = () => {
        // note: arbitrary delay to make sure we get correct/latest value from server
        setTimeout(() => {
          conversation.getUnreadMessagesCount().then(count => setUnread(Math.min(99, count ?? 0)));
        }, 1000);
      };

      conversation.on('participantJoined', participantJoined);
      conversation.on('participantUpdated', participantUpdated);

      conversation.on('messageAdded', messageAdded);

      return () => {
        conversation.off('participantJoined', participantJoined);
        conversation.off('participantUpdated', participantUpdated);

        conversation.off('messageAdded', messageAdded);
      };
    }
  }, [conversation]);

  const initialize = useCallback(async (conferenceIdentifier: number) => {
    const response = await $api.conferences.createChatToken({
      conferenceIdentifier,
    });
    const newClient = new Twilio.Client(response.token);
    setConversationName(response.channel);
    setClient(newClient);
  }, []);

  const send = useCallback((text: string) => {
    return conversation?.sendMessage(text);
  }, [conversation]);

  const consumeAll = useCallback(() => {
    setUnread(0);
    if (conversation) {
      conversation.setAllMessagesRead()
        .then(count => setUnread(count));
    }
  }, [conversation]);

  const value = useMemo(() => ({
    messages,
    participants,
    ready,
    unread,
    initialize,
    send,
    consumeAll,
  }), [
    messages,
    participants,
    ready,
    unread,
    initialize,
    send,
    consumeAll,
  ]);

  return (
    <ConferenceChatContext.Provider value={value}>
      {children}
    </ConferenceChatContext.Provider>
  );
}

function useConversation(channel: Twilio.Conversation) {
  const [ready, setReady] = useState<boolean>(false);
  const [messages, setMessages] = useState<Twilio.Message[]>([]);

  const processPage = useCallback((items: Twilio.Message[], paginator: Twilio.Paginator<Twilio.Message>): Promise<Twilio.Message[]> => {
    if (paginator.hasPrevPage) {
      return paginator.prevPage()
                      .then(p => processPage(items.concat(paginator.items), p));
    } else {
      return Promise.resolve(items.concat(paginator.items));
    }
  }, []);

  const handleMessageAdded = useCallback((message: Twilio.Message) => {
    setMessages(prevMessages => [...prevMessages, message]);
  }, []);

  useEffect(() => {
    if (channel) {
      channel.on('messageAdded', handleMessageAdded);

      channel.getMessages(100)
        .then(p => processPage([], p))
        .then(messages => {
          setMessages(messages);
          setReady(true);
        });

      return () => {
        channel.off('messageAdded', handleMessageAdded);
      };
    }
  }, [channel, handleMessageAdded, processPage]);

  return [messages, ready] as const;
}

import { useCallback, useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNumberTableWarnings } from '@containers/SurveyForm/Context';
import { NumberInputTableQuestion, SurveyQuestionFormOption, SurveyQuestionFormMatrixRow } from '@/types';
import { NumberInputTable } from './interfaces';
import Grid from './NumberInputTable.Grid';
import List from './NumberInputTable.List';
import styles from './style/NumberInputTable.css';

type Props = {
  answer: NumberInputTableQuestion.RespondentAnswer.Value;
  item: NumberInputTableQuestion.Question | NumberInputTableQuestion.FormQuestion;
  setAnswer: (value: NumberInputTableQuestion.RespondentAnswer.Value) => void;
};

export function NumberInputTableQuestionForm({ answer, item, setAnswer }: Props) {
  const [warnings] = useNumberTableWarnings();

  const answerMap = useMemo(() => {

    return (item.matrixRows as SurveyQuestionFormMatrixRow[]).reduce((acc, row) => {

      const rowMap = (item.options as SurveyQuestionFormOption[]).reduce((acc2, option) => {

        const v = (answer?.items || [])
          .find(f => f.optionId === option.id && f.rowId === row.id);

        return {
          ...acc2,
          [option.id]: v?.value,
        };
      }, {} as { [optionId: number]: number; });

      return {
        ...acc,
        [row.id]: rowMap,
      };
    }, {} as NumberInputTable.AnswerMap);

  }, [
    answer,
    item.matrixRows,
    item.options,
  ]);

  const handleChange = useCallback((rowId: number, optionId: number) => (value: number) => {
    const items = (answer?.items || []).reduce((acc, x) => {

      if (x.optionId !== optionId || x.rowId !== rowId) {
        acc.push(x);
      }

      return acc;
    }, [{ optionId, rowId, value }] as NumberInputTableQuestion.RespondentAnswer.Item[]);

    setAnswer({ items });
  }, [
    answer?.items,
    setAnswer,
  ]);

  const totalsMap = useMemo(() => {
    return (item.options as SurveyQuestionFormOption[]).reduce((acc, o) => {
      const total = (item.matrixRows as SurveyQuestionFormMatrixRow[]).reduce((sum, r) => {
        const answer = answerMap[r.id][o.id];
        return answer
          ? sum + answer
          : sum;
      }, 0);

      return {
        ...acc,
        [o.id]: total,
      };
    }, {} as NumberInputTable.TotalsMap);

  }, [
    answerMap,
    item.options,
    item.matrixRows,
  ]);

  const showWarnings = useMemo(() => {
    return (item.matrixRows as SurveyQuestionFormMatrixRow[]).reduce((acc, row) => {
      return acc || (item.options as SurveyQuestionFormOption[]).reduce((acc2, option) => {
        return acc2 || warnings[row.id][option.id];
      }, false);
    }, false);
  }, [
    item.options,
    item.matrixRows,
    warnings,
  ]);

  const renderWarning = useCallback(() => {
    if (!showWarnings) return null;

    const items: string[] = [];

    if (!item.settings.allowEmptyValues) {
      items.push('All values must be filled out');
    }

    const { minValue, maxValue } = item.settings.validations;

    if (minValue !== null && maxValue === null) {
      items.push(`Answers must be greater than or equal to ${minValue}`);
    }

    if (minValue === null && maxValue !== null) {
      items.push(`Answers must be less than or equal to ${maxValue}`);
    }

    if (minValue !== null && maxValue !== null) {
      items.push(`Answers must be greater than or equal to ${minValue} and less than or equal to ${maxValue}`);
    }

    if (!items.length) return null;

    return (
      <div className={styles.warnings}>
        {items.map((w, i) => (
          <div
            key={i}
            className={styles.warning}>
            {w}
          </div>
        ))}
      </div>
    );
  }, [
    item.settings,
    showWarnings,
  ]);

  const showGrid = useMediaQuery('(min-width:800px)');

  const params = useMemo(() => ({
    answerMap,
    handleChange,
    item,
    totalsMap,
  }), [
    answerMap,
    handleChange,
    item,
    totalsMap,
  ]);

  const renderGrid = useCallback(() => {
    if (!showGrid) {
      return null;
    }

    const splitUpGrid = item.options.length > MaxGridOptions;
    const optionsPerGrid = splitUpGrid
      ? Math.ceil(item.options.length / 2)
      : item.options.length;

    return (
      <>
        <Grid
          {...params}
          maxOptions={optionsPerGrid}
          from={0}
          to={optionsPerGrid} />
        {splitUpGrid &&
          <Grid
            {...params}
            maxOptions={optionsPerGrid}
            from={optionsPerGrid}
            to={item.options.length} />
        }
      </>
    );

  }, [
    item,
    params,
    showGrid,
  ]);

  return (
    <>
      {renderGrid()}
      {!showGrid &&
        <List {...params} />}
      {renderWarning()}
    </>
  );
}

const MaxGridOptions = 6;

export default NumberInputTableQuestionForm;
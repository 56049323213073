import { QuestionItems } from './interfaces';

const items: QuestionItems[] = [
  {
    question: `Are you currently working as a Government Official?`,
    choices: [
      `Yes`,
      `No`,
    ],
    field: `isGovOfficial`,
  },
  {
    question: `Are you currently involved in a clinical trial?`,
    choices: [
      `Yes`,
      `No`,
    ],
    field: `inClinicalTrial`,
  },
];

export default items;
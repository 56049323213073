import { useContext, useEffect } from 'react';
import { useMenuContext } from '@containers/AppNavigation/Context';
import { cx } from '@utils';
import { Hamburger } from 'components/AppBar/Drawer';
import { AppBarContext } from './Context';
import { DrawerItem } from './Drawer';
import styles from './style/Drawer.css';

export const Drawer = (props: unknown) => {
  const { menu } = useMenuContext();
  const { drawer } = useContext(AppBarContext);

  useEffect(() => {
    if (drawer.open) {
      document.body.style.overflowY = 'hidden';
    }

    return () => {
      document.body.style.overflowY = '';
    };
  }, [drawer.open]);

  return (
    <Drawer.Container open={drawer.open}>
      <div className={styles.links}>
        {menu.drawer.map(x =>
          <DrawerItem
            count={x.count}
            icon={x.icon}
            key={x.key}
            name={x.name}
            onClick={x.onClick}
            to={x.to} />)}
      </div>
    </Drawer.Container>
  );
};

type ContainerProps = {
  className?:        string;
  children:          React.ReactNode;
  open:              boolean;
  overlayClassName?: string;
};

const Container = (props: ContainerProps) => {
  const className = cx({
    [styles.visible]: props.open,
    [styles.hidden]: !props.open,
  });

  return (
    <div className={className}>
      <div className={cx(styles.menu, props.className)}>
        <div className={styles.wrap}>
          {props.children}
        </div>
      </div>

      <div className={cx(styles.overlay, props.overlayClassName)} />
    </div>
  );
};

type AnchorProps = {
  className?: string;
};

const Anchor = (props: AnchorProps) => {
  const { drawer } = useContext(AppBarContext);

  return (
    <Hamburger
      className={props.className}
      onClick={drawer.toggle}
      open={drawer.open}
      ref={drawer.anchorRef} />
  );
};

Drawer.displayName = 'Menu.Drawer';
Anchor.displayName = 'Menu.Drawer.Anchor';
Container.displayName = 'Menu.Drawer.Container';
Drawer.Anchor = Anchor;
Drawer.Container = Container;
import {
  AppStore,
  PlayStore,
} from 'components/AppDownloadLinks';
import styles from './style/Footer.css';

const copy = `Keep work moving on the go.`;

const DownloadApp = () => (
  <div className={styles.download}>
    <div className={styles.downloadTitle}>
      Download our app
    </div>
    <div className={styles.downloadText}>{copy}</div>
    <div className={styles.storeLinks}>
      <AppStore
        className={styles.storeLink}
        imgClassName={styles.storeImg} />
      <PlayStore
        className={styles.storeLink}
        imgClassName={styles.storeImg} />
    </div>
  </div>
);

export default DownloadApp;
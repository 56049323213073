import { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link, useLocation } from 'react-router-dom';
import { AudioPlayerContainer } from '@containers/AudioPlayer';
import { useSelectCall, useSelectContact, useSelectProject } from '@containers/Store';
import { GroupCallTagsContainer } from '@containers/GroupTags';
import { CallTranscriptContainer, TranscriptCommentsRefPointContext } from '@containers/Transcript';
import { getLocationFor } from '@utils';
import { Breadcrumb, Breadcrumbs } from '@presentation/Breadcrumbs';
import { HomeBreadcrumb } from 'components/Workspace/WorkspaceBreadcrumb';
import { TranscriptSidebar as Sidebar } from './Sidebar';
import { VoiceTranscript } from './VoiceTranscript';
import { VideoTranscript } from './VideoTranscript';
import { useCommentsBounds } from './useCommentsBounds';
import styles from './style/ProjectCallTranscript.css';

type RouteParams = {
  callId: string;
  type: MediaType;
};

type MediaType = 'voice' | 'video';

export const ProjectCallTranscript = () => {
  const location = useLocation<{ from: string; }>();
  const params = useParams<RouteParams>();
  const callId = +params.callId;

  const [commentsRef, sidebarBounds] = useCommentsBounds();

  const call = useSelectCall(callId);
  const project = useSelectProject(call?.projectId);
  const user = useSelectContact(call?.userId);

  const summaryLocation = location.state?.from || getLocationFor.project.summary({ slug: project.slug });

  const renderContent = useCallback(() => {
    switch (params.type) {
      case 'video': {
        return (
          <VideoTranscript
            call={call}
            project={project}
            user={user} />
        );
      }
      case 'voice': {
        return (
          <VoiceTranscript
            call={call}
            project={project}
            user={user} />
        );
      }
      default: return null;
    }
  }, [call, params, project, user]);

  return (
    <GroupCallTagsContainer callId={callId}>
      <CallTranscriptContainer callId={callId}>
        <TranscriptCommentsRefPointContext.Provider value={sidebarBounds}>
          <Helmet title="Call Transcript" />

          <div className={styles.root}>
            <div className={styles.primary}>
              <div className={styles.header}>
                <Breadcrumbs className={styles.breadcrumbs}>
                  <HomeBreadcrumb />
                  <Link to={summaryLocation}>
                    <Breadcrumb>
                      {project.name}
                    </Breadcrumb>
                  </Link>
                </Breadcrumbs>
              </div>
              <AudioPlayerContainer>
                {renderContent()}
              </AudioPlayerContainer>
            </div>
            <div className={styles.sidebar}>
              <Sidebar
                ref={commentsRef}
                callId={callId} />
            </div>
          </div>
        </TranscriptCommentsRefPointContext.Provider>
      </CallTranscriptContainer>
    </GroupCallTagsContainer>
  );
};

export default ProjectCallTranscript;
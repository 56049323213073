import { RenderElementProps, useSelected, useFocused } from 'slate-react';
import { cx } from '@utils';
import { MaybeLink } from 'components/MaybeLink';
import styles from './style/Tag.css';

type Props = RenderElementProps;

const RichTextTag = (props: Props) => {

  return (
    <span
      {...props.attributes}
      className={styles.readOnly}
      contentEditable={false}>
      <MaybeLink to={props.element.to}>
        @{props.element.label}
      </MaybeLink>
    </span>
  );
};

const RichTextTagEditor = (props: Props) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <span
      {...props.attributes}
      className={cx(styles.editing, { [styles.focused]: selected && focused })}
      contentEditable={false}>
      @{props.element.label}
      {props.children}
    </span>
  );
};

export { RichTextTag, RichTextTagEditor };
export default RichTextTag;
import { memo } from 'react';
import { cx } from '@utils';
import styles from './style/Card.css';

type Props = {
  onClick?: () => void;
};

export const PersonaResearchCard = memo(({ onClick }: Props) => {

  const title = `Persona Research`;

  return (
    <div
      className={styles.root}
      onClick={onClick}>
      <div className={cx(styles.poster, styles.lightGreen)}>
        <Icon />
      </div>
      <div className={styles.footer}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
});

type IconProps = {
  color?: string;
};

const Icon = memo(({
  color = '#32BF90',
}: IconProps) => (
  <svg
    width="53px"
    height="29px"
    viewBox="0 0 44 24"
    version="1.1">
    <g
      id="Survey-Templates---Persona-Research"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <g
        id="1"
        transform="translate(-678.000000, -221.000000)"
        stroke={color}
        strokeWidth="2">
        <g
          id="Group-2"
          transform="translate(679.000000, 222.000000)">
          <path
            d="M0,20 L0,19.3946133 C-2.93466022e-15,17.1888756 1.25124736,15.1740854 3.22837344,14.1962045 L5.34341707,13.1501099 C6.02524445,12.81288 6.45674687,12.1180639 6.45674687,11.357398 L6.45674687,9.97066132 L6.45674687,9.97066132 C5.68984513,9.23006019 5.25672295,8.2096761 5.25672295,7.14354837 L5.25672295,4.90589589 C5.25672295,2.74872915 7.00545211,1 9.16261884,1 L9.36706564,1 C11.5660739,1 13.3854655,2.71102732 13.5203331,4.90589589 L13.6580227,7.14668888 C13.7229471,8.20328442 13.3217924,9.23529752 12.560314,9.97066132 L12.560314,9.97066132 L12.560314,9.97066132 L12.560314,11.3680964 C12.560314,12.1231861 12.9855782,12.8139068 13.6597963,13.1538926 L16,14.3339796 L16,14.3339796"
            id="Path-9" />
          <path
            d="M27,13.6796526 L29.269425,12.5422155 C29.9460492,12.203091 30.3732809,11.5110731 30.3732809,10.7542206 L30.3732809,9.49852125 L30.3732809,9.49852125 C29.6557919,8.79638751 29.2513946,7.83482732 29.2513946,6.83094373 L29.2513946,4.70032242 C29.2513946,2.65669078 30.9080853,1 32.951717,1 L33.0454371,1 C35.1281482,1 36.850875,2.62142968 36.9769333,4.70032242 L37.1063028,6.83382159 C37.1666441,7.82894253 36.7919568,8.80123787 36.0794243,9.49852125 L36.0794243,9.49852125 L36.0794243,9.49852125 L36.0794243,10.7649687 C36.0794243,11.5162049 36.5004029,12.2040821 37.16936,12.5459187 L39.0397121,13.5016674 C40.8566134,14.4301027 42,16.2983913 42,18.3387643 L42,19 L42,19"
            id="Path-9" />
          <path
            d="M11,22 L11,21.1653398 C11,18.6864345 12.3847575,16.4151976 14.5883635,15.2798157 L17.0927619,13.989454 C17.7584224,13.6464803 18.1767269,12.96039 18.1767269,12.2115677 L18.1767269,10.3870815 L18.1767269,10.3870815 C17.3224111,9.52763369 16.8428905,8.36504819 16.8428905,7.15322884 L16.8428905,4.45915149 C16.8428905,1.99643012 18.8393207,4.52394575e-16 21.302042,0 C23.8061317,-4.59993805e-16 25.8763131,1.95166962 26.0237609,4.45141442 L26.1833215,7.15651166 C26.2542075,8.35827084 25.8095878,9.53328715 24.9608917,10.3870815 L24.9608917,10.3870815 L24.9608917,10.3870815 L24.9608917,12.2224157 C24.9608917,12.9655395 25.3729157,13.6473948 26.0307917,13.9929845 L28.4804458,15.2798157 C30.6446014,16.4166712 32,18.6597093 32,21.1042972 L32,22 L32,22"
            id="Path-9" />
        </g>
      </g>
    </g>
  </svg>
));
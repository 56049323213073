import { useMemo } from 'react';
import * as consts from '@consts';
import WorkspaceTabFiles from './WorkspaceTabFiles';
import WorkspaceTabFolders from './WorkspaceTabFolders';
import WorkspaceTabProjects from './WorkspaceTabProjects';
import { WorkspaceRouteKey, WorkspaceSceneMap } from './interfaces';
import WorkspaceTabView from './WorkspaceTabView';

export const WorkspaceTabViewContainer = () => {

  const map: WorkspaceSceneMap = useMemo(() => ({
    [WorkspaceRouteKey.Projects]: {
      component: WorkspaceTabProjects,
      path: consts.path.WorkspaceTabs.Projects,
      title: 'Projects',
    },
    [WorkspaceRouteKey.Folders]: {
      component: WorkspaceTabFolders,
      path: consts.path.WorkspaceTabs.Folders,
      title: 'Folders',
    },
    [WorkspaceRouteKey.Files]: {
      component: WorkspaceTabFiles,
      path: consts.path.WorkspaceTabs.Files,
      title: 'Files',
    },
  }), [

  ]);

  return (
    <WorkspaceTabView
      sceneMap={map} />
  );
};

export default WorkspaceTabViewContainer;
import { forwardRef, useCallback, useMemo } from 'react';
import { cx } from '@utils';
import { MaxCharacterProps } from './interfaces';
import styles from './style.css';
import Textarea from './Textarea';

export const TextareaCharacterLimit = forwardRef(({
  maxLength,
  ...props
}: MaxCharacterProps, ref: React.Ref<HTMLTextAreaElement>) => {
  const showWarning = useMemo(() => {
    return (props.value as string).length > maxLength;
  }, [
    maxLength,
    props.value,
  ]);

  const count = useCallback(() => {
    const className = cx(styles.count, {
      [styles.warning]: showWarning,
    });

    return (
      <div className={className}>
        {maxLength - (props.value as string).length}
      </div>
    );
  }, [
    maxLength,
    props.value,
    showWarning,
  ]);

  return (
    <div className={styles.limitRoot}>
      <Textarea
        ref={ref}
        {...props} />
      {count()}
    </div>
  );
});

export default TextareaCharacterLimit;
import { path } from '@consts';
import { RouteAuth } from '@enums';
import { StateRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import { Role } from '@enums';
import { IndividualSearch } from '@screens/Search';
import Search from 'scenes/search';
import * as utils from '@utils';

const rootConfig = {
  exact: true,
  path: path.Search.Root,
};

const consultant: Routing.Route[] =  [
  {...rootConfig,
    component: IndividualSearch,
    roles: [Role.Expert],
  },
];

const routes: Routing.Route[] =  [
  {
    ...rootConfig,
    component: Search,
    roles: [Role.InternalAdmin, Role.FirmCompliance, Role.FirmAnalyst],
  },

];

const validator = (state: Store.State) => {
  if (utils.hasConsultantRole(state.user)) {
    return consultant;
  } else if (utils.hasClientRole(state.user)) {
    return routes;
  } else {
    return [];
  }
};

export const router = StateRouter(validator, {
  auth: RouteAuth.Authenticated,
  path: path.Search.Root,
});
import * as enums from '@enums';
import { PipelineRecord, Project } from '@/types';
import { isProjectSurveyOnly } from './utils.projects';

type ProjectType = Pick<Project, 'projectType'>;
type RecordStatus = Pick<PipelineRecord, 'statusId'>;
type RecordSurveyStatus = Pick<PipelineRecord, 'surveyStatusId'>;

export function canHideUser(record: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.Active,
  ];

  return statuses.includes(record.statusId);
}

export function canRemoveUser(record: RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.Added,
    enums.UserProjectStatus.InReview,
    enums.UserProjectStatus.Invited,
    enums.UserProjectStatus.Recommended,
  ];

  return statuses.includes(record.statusId);
}

export function canSendSurveyInvite(data: ProjectType & RecordStatus) {
  const statuses = [
    enums.UserProjectStatus.Added,
    enums.UserProjectStatus.Recommended,
    enums.UserProjectStatus.Rejected,
  ];

  return isProjectSurveyOnly(data)
      && statuses.includes(data.statusId);
}

export function canViewSurvey(data: ProjectType & RecordStatus & RecordSurveyStatus) {
  const types = [
    enums.ProjectType.Call,
  ];
  const statuses = [
    enums.UserProjectStatus.Active,
    enums.UserProjectStatus.Inactive,
  ];
  const surveyStatuses = [
    enums.ProjectSurveyResponseStatus.Active,
  ];

  return !types.includes(data.projectType)
      && statuses.includes(data.statusId)
      && surveyStatuses.includes(data.surveyStatusId);
}
import { format } from 'date-fns';
import { Cell } from 'react-table';

type GenericCreatedOnItem = {
  createdOn: Date;
}

export default function CreatedOn({ row: { original: data } }: Cell<GenericCreatedOnItem>) {
  return format(data.createdOn, 'MM/dd/yyyy \'at\' h:m aa');
}

export { CreatedOn };
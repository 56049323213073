import { Helmet } from 'react-helmet';
import { path } from '@consts';
import { Terms } from '$website/components';
import styles from './style.css';

const H = () => {
  const description = `Vancery's Terms and Policies.`;

  return (
    <Helmet title="Member Terms">
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Website.Terms.Root}`} />
      <meta
        name='description'
        content={description} />
    </Helmet>
  );
};

const ConsultantTerms = () => {
  return (
    <>
      <H />
      <div className={styles.root}>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <h1 className={styles.title}>Member Terms of Use</h1>
            <div className={styles.subtitle}>
              Our rules of the road are simple: Members may not share non-public information or information for which they have a duty to keep confidential.
            </div>
          </div>
          <div className={styles.body}>
            <Terms.Consultant />
          </div>
        </div>
      </div>
    </>
  );
};

export { ConsultantTerms };
export default ConsultantTerms;
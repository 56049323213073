import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as admin from '$admin/api';
import { ShareLinkPopper } from './ShareLink';
import { ProjectLinkProps } from './interfaces';

const mapState = (state: Store.State) => state.user.id;

export const CustomSearchProjectLink = ({ projectId, placement }: ProjectLinkProps) => {
  const userId = useSelector(mapState);

  const fetchToken = useCallback(() => {
    return admin.projects.links.getCustomSearch({
      projectId: projectId,
      userId,
    });
  }, [projectId, userId]);

  const cacheKey = useMemo(() => ['custom-project', projectId, 'user', userId], [userId, projectId]);

  return (
    <ShareLinkPopper
      cacheKey={cacheKey}
      fetchToken={fetchToken}
      placement={placement} />
  );
};

export default CustomSearchProjectLink;
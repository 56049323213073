import { Website } from '@consts/path';
import { domain, qs } from '@utils';
import { SegmentQueryValue, Site } from '$website/containers/MarketSegmentation/interfaces';
import consultant1 from 'static/images/signup/1.png';
import consultant2 from 'static/images/signup/2.png';
import consultant3 from 'static/images/signup/3.png';
import consultant4 from 'static/images/signup/4.png';
import logo1 from 'static/images/signup/logo-zendesk.svg?url';
import logo2 from 'static/images/signup/logo-twilio.svg?url';
import logo3 from 'static/images/signup/logo-stripe.svg?url';
import logo4 from 'static/images/signup/logo-airbnb.svg?url';
import chart from 'static/images/website/benefit-chart.png';
import press1 from 'static/images/website/logo-quartz.svg?url';
import press2 from 'static/images/website/logo-forbes.svg?url';
import press3 from 'static/images/website/logo-businessinsider.svg?url';
import press4 from 'static/images/website/logo-bloomberg.svg?url';
import press5 from 'static/images/website/logo-wsj.svg?url';
import discovery from 'static/images/website/benefit-discovery.png';
import efficiency from 'static/images/website/benefit-efficiency.png';
import quality from 'static/images/website/benefit-quality.png';
import speed from 'static/images/website/benefit-speed.png';
import value from 'static/images/website/benefit-value.png';

const authLinkAttr = (pathname: string) => domain.primary
    ? { to: pathname }
    : { href: domain.toFQDN(pathname) };

const link = {
  login: authLinkAttr(Website.Login) as Site.ButtonAttributes,
  signup: authLinkAttr(Website.Signup.Root) as Site.ButtonAttributes,
  demo: {
    to: Website.Demo,
  },
};

const button = {
  login: {
    text: 'Log In',
    ...link.login,
  },
  signup: {
    text: `Sign Up`,
    ...link.signup,
  },
};

const landing = {
  button: {
    demo: {
      text: `Request a Demo`,
      to: Website.Demo,
    },
    enterprise: {
      text: 'Enterprise',
      to: Website.Enterprise,
    },
    login: button.login,
    individuals: {
      text: 'Individuals',
      to: Website.Individuals,
    },
    signup: button.signup,
  },
  text: {
    copy: `We are an information marketplace where professionals exchange ideas, knowledge, and experiences in paid consulting projects.` ,
    title: `Access the world’s brightest professionals on our research platform.`,
  },
};

const benefits = {
  image: {
    chart,
    discovery,
    efficiency,
    quality,
    speed,
    value,
  },
};

const feature = {
  collection: {
    consultants: [
      {
        collection: {
          tags: ['Analytics', 'Product Design'],
        },
        image: {
          src: consultant1,
        },
        text: {
          title: `SVP Growth, Casper`,
          copy: `Responsible for all ecommerce P&L and digital product roadmap for sleep products from startup stage through IPO process.`,
        },
      }, {
        collection: {
          tags: ['Digital Marketing', 'SEO'],
        },
        image: {
          src: consultant2,
        },
        text: {
          title: `Head of Marketing, Lyft`,
          copy: `Led growth for Lyft Bikes in New York City, after years of leading Citi Bike, the largest bike sharing system in the US.`,
        },
      }, {
        collection: {
          tags: ['Loyalty Programs', 'Pricing'],
        },
        image: {
          src: consultant3,
        },
        text: {
          title: `VP of Product Strategy, Fiserv`,
          copy: `Created product strategy and launched a data-driven loyalty program for a leading fincancial technology product.`,
        },
      }, {
        collection: {
          tags: ['Marketing Strategy', 'Design'],
        },
        image: {
          src: consultant4,
        },
        text: {
          title: `CMO, Venmo`,
          copy: `Doubled search volume for Venmo and estabilshed the firm’s marketing strategy following acquisition by PayPal.`,
        },
      },
    ],
    logos: [
      {
        image: {
          src: logo1,
        },
      }, {
        image: {
          src: logo2,
        },
      }, {
        image: {
          src: logo3,
        },
      }, {
        image: {
          src: logo4,
        },
      },
    ],
  },
  text: {
    title: `Talk to professionals who can answer your questions`,
    proof: `Trusted by 24,000+ Professionals`,
  },
};

const funnel = {
  button: {
    demo: {
      text: `Request a Demo`,
      to: Website.Demo,
    },
    signup: button.signup,
  },
  text: {
    demo: `Schedule a demo to see how Vancery can improve your research process.`,
    signup: `Join for free today and find out how you can earn on Vancery.`,
  },
};

const press: Site.Content['main']['press'] = {
  text: {
    title: `Featured in`,
  },
  collection: {
    logos: [
      {
        image : {
          alt: 'quartz',
          src: press1,
        },
      }, {
        image : {
          alt: 'forbes',
          src: press2,
        },
      }, {
        image : {
          alt: 'business-insider',
          src: press3,
        },
      }, {
        image : {
          alt: 'bloomberg',
          src: press4,
        },
      }, {
        image : {
          alt: 'wsj',
          src: press5,
        },
      },
    ],
  },
};

export const nav = {
  button: {
    demo: {
      text: `Demo`,
      to: Website.Demo,
    },
    login: button.login,
    signup: button.signup,
  } as Site.ButtonProps,
};

export const main = {
  landing,
  benefits,
  funnel,
  press,
};

export const signup = {
  feature,
};

export const ui = {
  button,
  link,
};

export const setSegmentQueryParams = (value: SegmentQueryValue) => <T extends Partial<Site.ButtonAttributes>>(params: T) => {
  const linkPropNames = ['href', 'to'];

  return Object.keys(params).reduce<T>((acc, key) => {
    return linkPropNames.includes(key)
      ? { ...acc, [key]: qs.setParams(acc[key], { s: value }) }
      : acc;
  }, params);
};
import * as api from '@api';
import { useCallReviewState, useCallReviewDispatch, useSelectGroup } from '@containers';
import { useAsyncStateLazy } from '@utils';

type Props = unknown;

const CallChaperone = (props: Props) => {
  const group = useSelectGroup();
  const state = useCallReviewState();
  const dispatch = useCallReviewDispatch();

  const [response, update] = useAsyncStateLazy(() => {

    return api.groups.compliance.updateCallChaperone({
      callId: state.call.id,
      groupId: group.id,
      chaperone: !state.call.chaperone,
    })
    .then(data => dispatch({
      ...state,
      call: {
        ...state.call,
        chaperone: data.call.chaperone,
      },
    }));

  }, [
    dispatch,
    group.id,
    state.call,
    state.call?.chaperone,
    state.call?.id,
  ]);

  return (
    <input
      checked={state.call.chaperone}
      disabled={response.loading}
      onChange={update}
      type="checkbox"  />
  );
};

export { CallChaperone };
export default CallChaperone;
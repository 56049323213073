import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

let stripePromise: Promise<Stripe> | undefined;

const getStripe = () => {
  if (typeof stripePromise === 'undefined') {
    // console.log('Loading stripe');
    stripePromise = loadStripe(process.env.STRIPE_KEY);
  }

  return stripePromise;
};

export function StripeElements({ children }: Props) {

  return (
    <Elements stripe={getStripe()}>
      {children}
    </Elements>
  );
}

type Props = {
  children: React.ReactNode;
};

export default StripeElements;
import { useContext } from 'react';
import * as enums from '@enums';
import { RateOnboardingContext } from '@containers/RateOnboarding';
import {
  WizardHeader,
} from 'components/Wizard';
import { EnumDropDown } from 'components/DropDown';
import { Navigation, Screen } from './Wizard';
import styles from './style.css';

export const SeniorityScreen = () => {
  const ctx = useContext(RateOnboardingContext);

  const renderHeader = () => {
    const title = `Which of the following best describes your professional seniority level?`;
    const subtitle = `Please select your professional seniority level to optimize your profile for client project searches.`;

    return  (
      <WizardHeader
        title={title}
        subtitle={subtitle} />
    );
  };

  const renderBody = () => (
    <div className={styles.seniority}>
      <EnumDropDown<enums.Seniority>
        autoFocus
        enum={enums.utils.Seniority}
        onSelect={ctx.onSeniorityUpdated}
        value={ctx.seniority}
        placeholder={`Seniority Level`} />
    </div>
  );

  const renderNavigation = () =>  (
    <Navigation nextEnabled={!!ctx.seniority} />
  );

  return (
    <Screen
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderNavigation={renderNavigation} />
  );
};

export default SeniorityScreen;
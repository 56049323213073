import { useCallback } from 'react';
import { useSelectContact, useSelectCall } from '@containers/Store';
import { useSurveyFormContext, useAnswerMap } from '@containers/SurveyForm';
import { useSubmitPostCallSurveyResponse } from '@utils/api';
import { Button } from 'components/Button';
import { Questionnaire as QuestionnaireSurvey } from 'components/SurveyForm/Questionnaire';
import Toast from 'components/Toast';
import { QuestionnareProps } from './interfaces';
import styles from './style.css';

const Questionnare = ({
  callId,
  onSubmit,
}: QuestionnareProps) => {
  const [answers, setAnswer] = useAnswerMap();
  const call = useSelectCall(callId);
  const contact = useSelectContact(call.userId);
  const survey = useSurveyFormContext();

  const { mutateAsync, ...submit } = useSubmitPostCallSurveyResponse({ callId }, {
    onSuccess: _ => {
      onSubmit();
    },
    onError: e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
    },
  });

  const handleSubmit = useCallback(() => {
    const items = survey.questions
      .map(q => ({
        answer: answers[q.id],
        question: {
          id: q.id,
          ordinal: q.ordinal,
        },
      }));

    return mutateAsync({
      items,
      surveyVersionId: survey.id,
    });
  }, [
    answers,
    survey.id,
    survey.questions,
    mutateAsync,
  ]);

  const renderButtons = useCallback(() => {
    const disabled = submit.isLoading || survey.questions.some(s => !answers[s.id]);

    return (
      <div className={styles.btns}>
        <Button.Primary
          className={styles.submit}
          disabled={disabled}
          onClick={handleSubmit}
          variant='brick'>
          Submit
        </Button.Primary>
      </div>
    );
  }, [
    answers,
    handleSubmit,
    submit.isLoading,
    survey.questions,
  ]);

  return (
    <div>
      <div className={styles.qTitle}>
        Please answer the following compliance questions about your call with {contact.profile.firstName}.
      </div>
      <QuestionnaireSurvey
        answers={answers}
        questions={survey.questions}
        setAnswer={setAnswer} />
      {renderButtons()}
    </div>
  );
};

export { Questionnare };
export default Questionnare;
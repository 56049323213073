import { ProfileEntry } from '@enums';
import { useAccountProfileEditState } from '@containers';
import {
  WizardHeader,
} from 'components/Wizard';
import ProfileEntries from './ProfileEntries';
import { Navigation, Screen } from './Wizard';
import styles from './style.css';

export const EducationScreen = () => {
  const [entryId] = useAccountProfileEditState();
  const isEditing = !!entryId;

  const renderHeader = () => {
    const title = `Education`;
    const subtitle = `Please provide your educational experiences to fill out your profile.`;

    return  (
      <WizardHeader
        title={title}
        subtitle={subtitle} />
    );
  };

  const renderNavigation = () =>  (
    <Navigation nextEnabled={!isEditing} />
  );

  const renderBody = () => (
    <div className={styles.bodyWrap}>
      <ProfileEntries<ProfileEntry.Education>
        type={ProfileEntry.Education} />
    </div>
  );

  return (
    <Screen
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderNavigation={renderNavigation} />
  );
};

export default EducationScreen;
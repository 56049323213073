import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { fetchSurveyTemplateData } from '@api/search';
import { Search } from '@api/interfaces/search';

export const useFetchSurveyTemplateRefData = (props: Props, options: Options = {}) => {

  return useQuery(['survey-template-ref-data', props], ({ queryKey }) => {

    const query = queryKey[1];

    if (!query.value.length) return [];

    return fetchSurveyTemplateData(query)
      .then(res => res.items);

  }, options);

};

type Props = Search.FetchSurveyTemplateData.Request;

type QueryKey = readonly [string, Props];
type Data = Search.FetchSurveyTemplateData.Response['items'];
type Error = AxiosResponse;
type Variables = unknown;
type Options = UseQueryOptions<Variables, Error, Data, QueryKey>;
import { useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Clock, Clipboard, FileText, PlayCircle, XCircle } from 'react-feather';
import { format } from 'date-fns';
import { Node } from 'slate';
import { AudioPlayerContainer } from '@containers/AudioPlayer';
import { useSelectContact, useSelectProject } from '@containers/Store';
import { RatingIcon, Tooltip } from '@presentation';
import * as enums from '@enums';
import { cx, getLocationFor } from '@utils';
import { CompletedCall } from '@/types';
import ArrowDownIcon from 'components/icons/ArrowDown';
import { UserAvatar } from 'components/UserAvatar';
import Spinner from 'components/ActivityIndicator/Spinner';
import { ReadOnly } from 'components/RichText';
import { CallRecording } from 'components/CallRecording';
import VideoTranscript from './VideoTranscript';
import VoiceTranscript from './VoiceTranscript';
import styles from './style/CallItem.css';

type Props = {
  expanded: number;
  item: CompletedCall;
};

const CallItem = (props: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [hasBeenExpanded, setHasBeenExpanded] = useState(false);

  const creator = useSelectContact(props.item.creatorUserId);
  const project = useSelectProject(props.item.projectId);
  const user = useSelectContact(props.item.userId);

  const toggleExpanded = useCallback(() => {
    setExpanded(v => !v);
    setHasBeenExpanded(true);
  }, []);

  useEffect(() => {
    if (props.expanded) {
      setExpanded(true);
      setHasBeenExpanded(true);
    }
  }, [props.expanded]);

  const hasNotes = useMemo(() => {
    return props.item.notes.body?.length ||
           props.item.notes.rich?.length;
  }, [props.item.notes]);

  const hasRecording = useMemo(() => {
    return props.item.conference.record &&
      (props.item.conference.hasAudio ||
      props.item.conference.hasVideo);
  }, [props.item]);

  const renderDate = useCallback(() => {
    return `${format(props.item.timeStart, 'M/dd/yy')} at ${format(props.item.timeStart, 'h:mma')} with ${creator.profile.fullname}`;
  }, [
    creator,
    props.item.timeStart,
  ]);

  const renderArrow = useCallback(() => {
    if (!hasNotes && !hasRecording) {
      return null;
    }

    return (
      <div className={styles.arrow}>
        <ArrowDownIcon onClick={toggleExpanded} open={expanded} />
      </div>
    );
  }, [
    expanded,
    hasNotes,
    hasRecording,
    toggleExpanded,
  ]);

  const renderAudioRecording = useCallback(() => {
    if (props.item.conference.typeId !== enums.ConferenceType.Voice) return null;

    const downloadName = `${user.profile.fullname.replace(' ', '_')}_${format(props.item.timeStart, 'M/dd/yyyy')}.mp3`;

    return (
      <div className={styles.recording}>
        <CallRecording
          type="track"
          conferenceInstanceId={props.item.conference.recordingInstanceId}
          callId={props.item.id}
          downloadName={downloadName} />
      </div>
    );
  }, [
    props.item,
    user,
  ]);

  const renderNotes = useCallback(() => {
    return (
      <div className={styles.notes}>
        <div className={styles.notesTitle}>Call Notes</div>
        {props.item.notes.rich
          ? <ReadOnly placeholder='None' value={JSON.parse(props.item.notes.rich) as Node[]} />
          : props.item.notes.body || 'None'
        }
      </div>
    );
  }, [props.item.notes]);

  const renderVideoTranscript = useCallback(() => {

    if (props.item.conference.typeId !== enums.ConferenceType.Video) return null;

    if (!props.item.conference.hasVideo) {
      return (
        <div className={styles.transcript}>
          <div className={styles.isProcessing}>
            <Spinner className={styles.spinner} />
            <div>{`We're still processing this video. Check in with us later.`}</div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.transcript}>
        <VideoTranscript
          roomInstanceId={props.item.conference.recordingInstanceId}
          transcript={props.item.transcript}
          call={props.item}
          project={project}
          user={user} />
      </div>
    );
  }, [
    props.item,
    project,
    user,
  ]);

  const renderVoiceTranscript = useCallback(() => {
    if (props.item.conference.typeId !== enums.ConferenceType.Voice) return null;

    if (!props.item.conference.hasAudio || !props.item.transcript?.isAvailable) {
      return (
        <div className={styles.transcript}>
          <div className={styles.isProcessing}>
            <Spinner className={styles.spinner} />
            <div>Transcription in progress.</div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.transcript}>
        <VoiceTranscript
          transcriptId={props.item.transcript.id}
          call={props.item}
          project={project}
          user={user} />
      </div>
    );
  }, [
    props.item,
    project,
    user,
  ]);

  const renderRating = useCallback(() => {

    if (!props.item.rating) {
      return (
        <Tooltip title={`Call Hasn't Been Rated`}>
          <span className={styles.iconTooltip}>
            <Clock className={styles.icon} />
          </span>
        </Tooltip>
      );
    } else if (props.item.rating.isSkipped) {
      return (
        <Tooltip title={`Skipped Rating`}>
          <span className={styles.iconTooltip}>
            <XCircle className={styles.icon} />
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={`${enums.utils.CallRating.getName(props.item.rating.rating)} Rating Given`}>
          <span className={`${styles.icon} ${styles.iconTooltip}`}>
            <RatingIcon
              value={props.item.rating.rating} />
          </span>
        </Tooltip>
      );
    }
  }, [
    props.item.rating,
  ]);

  const renderIcons = useCallback(() => {

    return (
      <>
        {hasRecording ?
          <Tooltip title={props.item.conference.typeId === enums.ConferenceType.Video ? 'Video Recorded' : `Audio Recorded`}>
            <span className={styles.iconTooltip}>
              <PlayCircle size={20} className={styles.icon} />
            </span>
          </Tooltip> : null
        }
        {props.item.transcript ?
          <Tooltip title={`Transcript`}>
            <span className={styles.iconTooltip}>
              <FileText size={20} className={styles.icon} />
            </span>
          </Tooltip> : null
        }
        {hasNotes ?
          <Tooltip title={`Call Notes`}>
            <span className={styles.iconTooltip}>
              <Clipboard size={20} className={styles.icon} />
            </span>
          </Tooltip> : null
        }
        {renderRating()}
      </>
    );
  }, [
    hasNotes,
    hasRecording,
    props.item,
    renderRating,
  ]);

  const renderExpandedCall = useCallback(() => {
    if (!hasBeenExpanded) {
      return null;
    }

    return (
      <div className={cx({
        [styles.hide]: !expanded,
      })}>
        {!hasRecording || renderAudioRecording()}
        {hasRecording ? renderVoiceTranscript() : null}
        {hasRecording ? renderVideoTranscript(): null}
        {renderNotes()}
      </div>
    );
  }, [
    expanded,
    hasBeenExpanded,
    hasRecording,
    renderNotes,
    renderAudioRecording,
    renderVideoTranscript,
    renderVoiceTranscript,
  ]);

  const linkTo = getLocationFor.user.profile(user.profile);

  return (
    <AudioPlayerContainer>
      <div className={styles.root}>
        <div className={styles.call}>
          <div>
            <Link to={linkTo}>
              <UserAvatar
                pictureUrl={user.profile.pictureUrl}
                size={46} />
            </Link>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.name}>
              <Link
                className={styles.link}
                to={linkTo}>
                {user.profile.fullname}
              </Link>
              <div className={styles.date}>
                {renderDate()}
              </div>
              <div className={styles.icons}>
                {renderIcons()}
              </div>
            </div>
          </div>
          {renderArrow()}
        </div>
        <div className={styles.expand}>
          {renderExpandedCall()}
        </div>
      </div>
    </AudioPlayerContainer>
  );
};

export default CallItem;
import { Folder } from 'react-feather';
import { cx } from '@utils';
import styles from './style/FoldersTableEmptyState.css';

type Props = {
  className?: string;
  creationDisabled?: boolean;
};

export const FoldersEmptyState = ({
  className,
  creationDisabled,
}: Props) => {

  return (
    <div className={cx(styles.root, className)}>
      <Folder
        className={styles.icon}
        color="var(--gray-l)" />
      <div className={styles.title}>No Folders</div>
      {!creationDisabled && <div className={styles.text}>Create a folder to organize projects</div>}
    </div>
  );
};

export default FoldersEmptyState;
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import * as actions from '@actions';
import * as consts from '@consts';
import * as $oauth2 from '@services/auth/oauth2';
import { qs } from '@utils';
import { Spinner } from 'components/ActivityIndicator';
import styles from './style/Inbound.css';

type Search = {
  code: string;
  state: string;
};

type Params = {
  identifier: string;
}

export function OAuth2() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { identifier } = useParams<Params>();

  useEffect(() => {
    const search = qs.parse<Search>(location.search);

    if (search.code && search.state) {
      $oauth2.callback({
        identifier,
        code: search.code,
        state: search.state,
      })
      .then(data => {
        if (data.type === 'redirect') {
          dispatch(actions.userAuthenticationChange());
          history.replace(data.returnUrl ? data.returnUrl : consts.pathname.Home);
        }
      });
    }

  }, [dispatch, history, identifier, location.search]);

  return (
    <div className={styles.root}>
      <Spinner />
    </div>
  );
}
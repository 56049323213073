import { GreenOutlineButton, RedOutlineButton } from '@presentation';
import { cx } from '@utils';
import styles from './style/ComplianceReview.css';

type Props = {
  className?: string;
  onApprove:  () => unknown;
  onReject:   () => unknown;
};

const ApprovalActions = (props: Props) => {
  return (
    <div className={cx(styles.actions, props.className)}>
      <GreenOutlineButton
        className={styles.btn}
        title="Approve"
        onClick={props.onApprove} />
      <RedOutlineButton
        className={styles.btn}
        title="Reject"
        onClick={props.onReject} />
    </div>
  );
};

export { ApprovalActions };
export default ApprovalActions;
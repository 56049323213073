import { XHR } from '@services/http/interfaces';

export enum ReadyState {
  AppDataLoaded      = 'app-data-loaded',
  AppDataReset       = 'app-data-reset',
  Error              = 'error',
  NoCredentialsFound = 'no-credentials-found',
  UserSignedIn       = 'user-signed-in',
  UserSignedOut      = 'user-signed-out',
}

export type AppReadyStateContextValue = {
  authenticated: boolean;
  error?:        XHR.Error;
  hydrated:      boolean;
  hydrating:     boolean;
  initialized:   boolean;
};

export type ReadyStateRun = (state: ReadyState) => unknown;

export type ReadyStateSubscriber = (state: ReadyState, fn: () => unknown) => unknown;

export type ReadyStateSubscription = {
  on:  ReadyStateSubscriber;
  off: ReadyStateSubscriber;
};

export type ReadyStateChangeHandler = (state?: boolean) => unknown;
import * as xform from '@services/api/transformers/helpers';
import { safeJsonDate } from '@utils';
import { Conference, ConferenceSession, ConferenceValidity } from '@/types';

export type UnparsedConference = {
  session: {
    [P in keyof Pick<ConferenceSession, 'startedOn' | 'endedOn'>]: string;
  };
  validity: {
    [P in keyof Pick<ConferenceValidity, 'start' | 'end'>]: string;
  };
} & Omit<Conference, 'session' | 'validity'>;

export function normalizeConferences(conferences: UnparsedConference[]) {
  return xform.toMap<number, Conference>(conferences.map(normalizeConference), 'callId');
}

export function normalizeConference(conference: UnparsedConference): Conference {
  const runTransforms = xform.compose(
    normalizeConferenceDates,
  );

  return runTransforms(conference);
}

function normalizeSession(data: UnparsedConference['session']): ConferenceSession {
  return {
    startedOn: safeJsonDate(data?.startedOn),
    endedOn: safeJsonDate(data?.endedOn),
  };
}

function normalizeValidity(data: UnparsedConference['validity']): ConferenceValidity {
  return {
    start: safeJsonDate(data?.start),
    end: safeJsonDate(data?.end),
  };
}

function normalizeConferenceDates(conference: UnparsedConference): Conference {
  return {
    ...conference,
    session: normalizeSession(conference.session),
    validity: normalizeValidity(conference.validity),
  };
}

export const conferences = {
  normalizeConference,
  normalizeConferences,
};
import { useCallback, useState } from 'react';
import RemoveUserPrompt from '../RemoveUserPrompt';
import { UseUserPromptProps } from '../interfaces';

type Props =
UseUserPromptProps;

export const useRemoveUserPrompt = ({ onConfirm, project, user }: Props) => {
  const [visible, setVisibility] = useState(false);
  const toggleVisibility = useCallback(() => setVisibility(!visible), [visible]);

  const handleConfirm = useCallback(() => {
    onConfirm()
    .then(toggleVisibility);
  }, [onConfirm, toggleVisibility]);

  const Modal = useCallback(() => (
    <RemoveUserPrompt
      onConfirm={handleConfirm}
      onClose={toggleVisibility}
      project={project}
      user={user}
      visible={visible} />
  ), [
    handleConfirm,
    project,
    toggleVisibility,
    user,
    visible,
  ]);

  return [toggleVisibility, Modal] as const;
};

export default useRemoveUserPrompt;
import http from '@services/http';
import { Admin } from './interfaces';

export const fetch = (data: Admin.FetchUserPrivacySettings.Request): Promise<Admin.FetchUserPrivacySettings.Response> => {
  return http.get(`/admin/users/${data.userId}/privacy`);
};

export const saveSettings = (data: Admin.SaveUserPrivacySettings.Request): Promise<Admin.SaveUserPrivacySettings.Response> => {
  return http.put(`/admin/users/${data.userId}/privacy/settings`, data);
};

export const saveVisibility = (data: SaveVisibility.Request): Promise<SaveVisibility.Response> => {
  return http.put(`/admin/users/${data.userId}/privacy/visibility`, data);
};

declare namespace SaveVisibility {
  export type Request = {
    profile: {
      public: boolean;
    };
    userId: number;
  };

  export type Response = {
    visibility: Pick<Request, 'profile'>;
  };
}
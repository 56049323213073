import * as consts from '@consts';
import img1 from 'static/images/website/q2q/insights-1.png';
import img2 from 'static/images/website/q2q/insights-2.png';
import img3 from 'static/images/website/q2q/insights-3.png';
import styles from './style/Insights.css';

type Props = unknown;

export const Insights = (props: Props) => {

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.header}>Explore our resources to optimize your research process</h2>
        <div className={styles.main}>
          <div className={styles.items}>
            {items.map(x =>
              <a
                key={x.text}
                target="_blank"
                rel="noreferrer"
                href={x.href}>
                <div className={styles.item}>
                  <div className={styles.thumb}>
                    <img
                      alt={x.alt}
                      className={styles.img}
                      src={x.src} />
                  </div>
                  <div className={styles.info}>
                    <div className={styles.text}>{x.text}</div>
                    <div className={styles.subtext}>{x.subtext}</div>
                  </div>
                </div>
              </a>)}
          </div>
          <div className={styles.con} />
          <div className={styles.bg} />
        </div>
      </div>
    </div>
  );
};

Insights.displayName = 'Quant2Qual.Insights';

const items = [{
  alt: 'product research',
  href: consts.urls.UltimateProductResearchGuide,
  src: img1,
  text: `Ultimate Product Research Guide`,
  subtext: 'This guide was designed to be your go-to for launching new products and monitoring customer needs.',
}, {
  alt: 'survey best practices',
  href: consts.urls.SurveyBestPractices,
  src: img3,
  text: `Survey Best Practices`,
  subtext: `Learn the most common survey question formats and the do's and don'ts of writing effective surveys.`,
}, {
  alt: 'snowflake',
  href: consts.urls.SnowflakeReport,
  src: img2,
  text: `Snowflake Customer Adoption Survey`,
  subtext: 'Learn how Vancery was used to connect with Snowflake customers and understand their buying preferences amid rising competition.',
}];
import mixpanel from 'mixpanel-browser';
import { Survey } from './interfaces';

export function completeQuestion(data: Survey.CompleteQuestion) {
  mixpanel.track('survey:question:complete', {
    id: data.id,
    response: data.response,
    user: data.user,
    value: data.value,
    version: data.version,
  });
}

export function viewQuestion(data: Survey.ViewQuestion) {
  mixpanel.track('survey:question:view', {
    id: data.id,
    user: data.user,
    value: data.value,
    version: data.version,
  });
}
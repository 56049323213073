import {
  SurveyQuestionType,
} from '@enums/Survey';
import { SurveyQuestion } from '@/types/survey';
import { SurveyBuilder, SurveyQuestionsBuilder, SurveySectionsBuilder, OrdinalMap } from '../interfaces';
import {
  syncQuestionOrdinals,
  syncSectionOrdinals,
  syncHiddenSections,
  syncIncludedSections,
} from './sync';
import {
  getInitialAttributes,
  getInitialMatrixRows,
  getInitialQuestionOptions,
  getDefaultSettingsForType,
  generateNewQuestion,
  getDefaultQuestionLogic,
  refreshOptionsAfterTypeChange,
  refreshMatrixRowsAfterTypeChange,
} from './defaults';

type State = SurveyBuilder.State['survey']['questions'];

export function toggleHiddenSection(state: SurveyBuilder.State['survey'], action: SurveySectionsBuilder.ToggleSectionHidden.Action): State {
  const section = state.sections.find(f => f.identifier === action.identifier);
  const hidden = !section.hidden;

  return hidden
    ? syncHiddenSections(section.identifier, state.questions)
    : syncIncludedSections(section.identifier, state.questions);
}

export function newSectionAdded(state: SurveyBuilder.State['survey'], action: SurveySectionsBuilder.NewSectionAdded.State): State {
  const ordinals = generateNewOrdinals();
  const syncQuestion = syncSectionOrdinals(ordinals);

  return state.questions.map(syncQuestion);

  function generateNewOrdinals() {
    return state.sections.reduce<OrdinalMap>((acc, x) => {
      return {
        ...acc,
        [x.identifier]: x.ordinal < action.ordinal
          ? x.ordinal
          : x.ordinal + 1,
      };
    }, {});
  }
}

export function sectionAdded(state: SurveyBuilder.State['survey'], action: SurveySectionsBuilder.SectionAdded.State): State {
  return newSectionAdded(state, {
    ordinal: action.value.ordinal,
  });
}

export function questionAdded(state: SurveyBuilder.State['survey'], action: SurveyQuestionsBuilder.QuestionAdded.State): State {
  const questionOrdinal = action.value.ordinal;
  const ordinals = generateNewOrdinals();
  const syncQuestion = syncQuestionOrdinals(ordinals);

  return [
    ...state.questions
      .filter(f => f.ordinal < questionOrdinal)
      .map(syncQuestion),
    action.value,
    ...state.questions
      .filter(f => f.ordinal >= questionOrdinal)
      .map(syncQuestion),
  ];

  function generateNewOrdinals() {
    return state.questions.reduce<OrdinalMap>((acc, x) => {
      return {
        ...acc,
        [x.base.identifier]: x.ordinal < questionOrdinal
          ? x.ordinal
          : x.ordinal + 1,
      };
    }, {});
  }
}

export function newQuestionAdded(state: SurveyBuilder.State['survey'], {
  questionOrdinal,
  sectionIdentifier,
}: SurveyQuestionsBuilder.NewQuestionAdded.State): State {

  const section = state.sections.find(f => f.identifier === sectionIdentifier);

  const question = generateNewQuestion({
    ordinal: questionOrdinal,
    section,
  });

  return questionAdded(state, { value: question });

}

export function removeQuestion(state: State, { identifier }: SurveyQuestionsBuilder.RemoveQuestion.Action): State {
  const ordinal = state.find(f => f.base.identifier === identifier).ordinal;
  const ordinals = generateNewOrdinals();
  const syncQuestion = syncQuestionOrdinals(ordinals);

  return state
    .filter(f => f.base.identifier !== identifier)
    .map(syncQuestion)
    .map(removePipedConditions);

  function removePipedConditions(q: SurveyQuestion) {
    const filteredOptions = q.options.filter(f => !f.conditions.some(s => s.question.identifier === identifier));
    const filteredRows = q.matrixRows.filter(f => !f.conditions.some(s => s.question.identifier === identifier));

    return {
      ...q,
      matrixRows: filteredRows,
      options: filteredOptions,
    } as SurveyQuestion;
  }

  function generateNewOrdinals() {
    return state.reduce<OrdinalMap>((acc, x) => {
      return {
        ...acc,
        [x.base.identifier]: x.ordinal < ordinal
          ? x.ordinal
          : x.ordinal > ordinal
            ? x.ordinal - 1
            : null,
      };
    }, {});
  }
}

export function updateQuestion(state: State, { item }: SurveyQuestionsBuilder.UpdateQuestion.State): State {
  return state.reduce((acc, x) => {
    if (x.ordinal === item.ordinal) {
      acc.push(item);
    } else {
      acc.push(x);
    }

    return acc;
  }, []);
}

export function updateQuestionType(state: State, action: SurveyQuestionsBuilder.UpdateQuestionType.State): State {
  const existing = state.find(f => f.ordinal === action.ordinal);
  const options = [
    SurveyQuestionType.MultipleChoice,
    SurveyQuestionType.MatrixGrid,
    SurveyQuestionType.MatrixMultiselect,
    SurveyQuestionType.MaxDifference,
    SurveyQuestionType.Ranking,
  ].includes(action.typeId) && existing.options.length
    ? refreshOptionsAfterTypeChange(existing.options, action.typeId)
    : getInitialQuestionOptions(action.typeId)
  ;

  const matrixRows = [
    SurveyQuestionType.MatrixGrid,
    SurveyQuestionType.MatrixMultiselect,
    SurveyQuestionType.Sliders,
  ].includes(action.typeId) && existing.matrixRows.length
    ? refreshMatrixRowsAfterTypeChange(existing.matrixRows)
    : getInitialMatrixRows(action.typeId)
  ;

  const attributes = getInitialAttributes(action.typeId);

  const settings = getDefaultSettingsForType(action.typeId);

  const item = {
    ...existing,
    attributes,
    options,
    matrixRows,
    settings,
    typeId: action.typeId,
  } as SurveyQuestion;

  const logic = getDefaultQuestionLogic(item);

  return updateQuestion(state, {
    item: {
      ...item,
      logic,
    } as SurveyQuestion,
  });
}

export function updateQuestionIdentifier(state: State, action: SurveyQuestionsBuilder.UpdateQuestionIdentifier.Action): State {
  return state.reduce((acc, q) => {
    if (q.base.identifier === action.oldIdentifier) {
      acc.push({
        ...q,
        base: {
          id: null,
          identifier: action.newIdentifier,
        },
        tagging: {
          response: [],
        },
      });
    } else {
      acc.push(q);
    }
    return acc;
  }, []);
}

export function removeSection(state: SurveyBuilder.State['survey'], action: SurveySectionsBuilder.RemoveSection.State): SurveyQuestion[] {
  const toRemove = state.sections.find(f => f.identifier === action.identifier);

  const sectionOrdinals = generateNewSectionOrdinals();
  const questionOrdinals = generateNewQuestionOrdinals();

  return state.questions
    .filter(f => f.section.identifier !== action.identifier)
    .map(syncQuestionOrdinals(questionOrdinals))
    .map(syncSectionOrdinals(sectionOrdinals));

  function generateNewQuestionOrdinals() {
    const removedQuestionsLength = state.questions.filter(f => {
      const section = state.sections.find(s => s.identifier === f.section.identifier);
      return section.ordinal === toRemove.ordinal;
    }).length;

    return state.questions.reduce<OrdinalMap>((acc, x) => {
      const section = state.sections.find(s => s.identifier === x.section.identifier);
      return {
        ...acc,
        [x.base.identifier]: section.ordinal === toRemove.ordinal
            ? null
            : section.ordinal > toRemove.ordinal
              ? x.ordinal - removedQuestionsLength
              : x.ordinal,
      };
    }, {});
  }
  function generateNewSectionOrdinals() {
    return state.sections.reduce<OrdinalMap>((acc, x) => {
      return {
        ...acc,
        [x.identifier]: x.ordinal < toRemove.ordinal
            ? x.ordinal
            : x.ordinal > toRemove.ordinal
              ? x.ordinal - 1
              : null,
      };
    }, {});
  }
}

export function updateQuestionLogic(state: State, action: SurveyQuestionsBuilder.UpdateQuestionLogic.State): State {
  return state.reduce((acc, q) => {
    return q.base.identifier === action.identifier
      ? acc.concat({
        ...q,
        logic: action.logic,
      })
      : acc.concat(q);
  }, []);
}
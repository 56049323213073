import { SurveyQuestionType } from '@enums';
import { ShortTextResponseQuestion, TextResponseQuestion } from '@/types';
import { Validation } from '../interfaces';
import { assertHasValue, returnAssertFailure } from './validation.base';

export const DefaultSettings: ShortTextResponseQuestion.Settings = {};

export function validateQuestion(question: TextResponseQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.ShortTextResponse> {
  const hasValue = assertHasValue(question);

  if (hasValue.success === false) {
    return returnAssertFailure(hasValue, question);
  }

  return {
    success: true,
    result: {
      question,
    },
  };
}
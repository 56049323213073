import ErrorIcon from '@mui/icons-material/ErrorOutline';
import * as consts from '@consts';
import { BackLink } from '@presentation';
import styles from './style/FileNotFound.css';

export default function FileNotFound() {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.icon}><ErrorIcon /></div>
        <div className={styles.copy}>File does not exist</div>
        <div className={styles.back}>
          <BackLink to={`${consts.pathname.WORKSPACES}/personal`} />
        </div>
      </div>
    </div>
  );
}

export { FileNotFound };
import { useCallback, memo } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { Button } from 'components/Button';
import { SettingsTitle } from 'components/Settings';
import { ButtonLinkVariant } from '@presentation/Buttons';
import { useFetchSessions, useSessionLogout } from './hooks';
import styles from './style/Settings.Sessions.css';

export default function Sessions() {
  const sessions = useFetchSessions();
  const logout = useSessionLogout();

  const logoutSessions = useCallback((sids: string[]) => () => {
    logout.mutate({ sids });
  }, [logout]);

  return (
    <>
      <SettingsTitle
        title="Sessions" />
      <div className={styles.sessions}>
        {sessions.data?.items?.map((session, i) => (
          <Session
            key={i}
            {...session}
            logout={logoutSessions([session.id])} />
        ))}
      </div>
    </>
  );
}

type SessionProps = {
  id: string;
  isCurrent: boolean;
  ip: string;
  useragent: string;
  created: Date;
  logout: () => void;
};

const Session = memo(({ isCurrent, ip, useragent, created, logout }: SessionProps) => {
  return (
    <div className={styles.session}>
      <div className={styles.left}>
        <div className={styles.ip}>{ip}</div>
        <div className={styles.ua}>{useragent}</div>
      </div>
      <div className={styles.right}>
        {isCurrent
          ? <div className={styles.current}>Current Session</div>
          : (
            <>
              <div>{`${formatDistanceToNowStrict(created)} ago`}</div>
              <ButtonLinkVariant
                className={styles.logout}
                color="secondary"
                onClick={logout}>
                Log out
              </ButtonLinkVariant>
            </>
          )}
      </div>
    </div>
  );
});

export { Sessions };
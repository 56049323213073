import { Link, LinkProps } from 'react-router-dom';
import { ArrowRight } from 'react-feather';
import { cx } from '@utils';
import styles from './style/LinkLabeledIcon.css';

type Props = {
  className?: string;
  classes?: {
    root?: string;
    link?: string;
    icon?: string;
  };
} & Omit<LinkProps, 'href' | 'to'>
  & Partial<Pick<LinkProps, 'href' | 'to'>>

export const LinkLabeledIcon = ({ children, className, classes, href, to, ...props }: Props) => {
  const LinkElement = href ? 'a' : Link;
  const linkProps = {
    className: cx(styles.link, classes.link),
    href,
    to,
    ...props,
  };

  return (
    <div className={cx(styles.root, classes.root, className)}>
      <LinkElement {...linkProps}>
        {children} <ArrowRight className={cx(styles.icon, classes.icon)} />
      </LinkElement>
    </div>
  );
};

LinkLabeledIcon.displayName = 'LinkLabeledIcon';

const defaultProps = {
  classes: {},
};

LinkLabeledIcon.defaultProps = defaultProps;
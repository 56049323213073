import {
  ReactExtensions,
  Remirror,
} from '@remirror/react';
import { EditorState, RemirrorManager } from '@remirror/core';
import { cx } from '@utils';
import { CommentExtensions } from './interfaces';
import styles from './style/Comment.Editor.css';

type Manager = RemirrorManager<ReactExtensions<CommentExtensions>>;
type State = Readonly<EditorState>;

type Props = {
  autoFocus?: boolean;
  className?: string;
  editable: boolean;
  manager: Manager;
  initialContent?: State;
} & ChildrenProps;

export const CommentEditor = ({
  autoFocus,
  children,
  className,
  editable,
  initialContent,
  manager,
}: Props) => {

  return (
    <div className={cx(styles.root, className)}>
      <Remirror
        classNames={[styles.editor]}
        autoFocus={autoFocus}
        editable={editable}
        manager={manager}
        initialContent={initialContent}>
        {children}
      </Remirror>
    </div>
  );
};
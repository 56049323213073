import { Link, useParams } from 'react-router-dom';
import { useQueryParam, BooleanParam } from 'use-query-params';
import { path } from '@consts';
import { getLocationFor } from '@utils';
import styles from './style.css';

type Props = {
  title?:    string;
};

type RouteParams = {
  projectId: string;
};

export const SurveyPreviewComplete = (props: Props) => {

  const params = useParams<RouteParams>();
  const [isDisqualified] = useQueryParam('isDisqualified', BooleanParam);

  const displayText = isDisqualified ? 'Your answers resulted in you being disqualified from the survey.' : 'You have completed the survey!';

  return (
    <div className={styles.root}>
      <div className={styles.header}>{displayText}</div>
      <div className={styles.profile}>
        <Link to={getLocationFor.project.preview({ id: +params.projectId })}>Retry</Link>
      </div>
      <div className={styles.dashboard}>
        <Link to={path.Projects.Root}>Go to Dashboard</Link>
      </div>
    </div>
  );
};

export default SurveyPreviewComplete;
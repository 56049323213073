import { useContext, useMemo } from 'react';
import { TopicMembersContext } from '@containers/Topic/Context';
import { useInfiniteWindowScroll } from '@utils';
import ActivityIndicator from 'components/ActivityIndicator';
import {
  Experts as ExpertsPanel,
} from './Panel';
import styles from './style/Related.css';

type Props = unknown;

export const Members = (props: Props) => {
  const { query } = useContext(TopicMembersContext);

  useInfiniteWindowScroll({
    canFetch: query.hasNextPage && !query.isFetchingNextPage,
    fetchNext: query.fetchNextPage,
    bufferSize: 0.3,
  });

  const ready =
    useMemo(() => !query.isLoading && query.isSuccess, [query.isLoading, query.isSuccess]);

  const items = useMemo(() => query.data?.pages.flatMap(p => p.items), [query.data?.pages]);

  if (!ready) return <ActivityIndicator show />;

  return (
    <div className={styles.root}>
      <ExpertsPanel items={items} />
    </div>
  );
};

Members.displayName = 'Tab.Members';
import AccountInvoicingMethod from '@enums/AccountInvoicingMethod';
import AccountPaymentMethod from '@enums/AccountPaymentMethod';
import ApprovalStatus from '@enums/ApprovalStatus';
import BillingPeriod from '@enums/BillingPeriod';
import CallRating from '@enums//CallRating';
import CallStatus from '@enums/CallStatus';
import CallType from '@enums/CallType';
import ChargebleType from '@enums/ChargeableType';
import ChargeStatus from '@enums/ChargeStatus';
import ConferenceType from '@enums/ConferenceType';
import DocumentSigning from '@enums/DocumentSigning';
import IdentityProvider from '@enums/IdentityProvider';
import LicenseType from '@enums/LicenseType';
import Payment from '@enums/Payment';
import PayoutableType from '@enums/PayoutableType';
import PayoutMethod from '@enums/PayoutMethod';
import PayoutStatus from '@enums/PayoutStatus';
import Permission from '@enums/Permission';
import Project from '@enums/Project';
import InvoiceStatus from '@enums/InvoiceStatus';
import ReviewOption from '@enums/ReviewOption';
import Role from '@enums/Role';
import Seniority from '@enums/Seniority';
import Survey from '@enums/Survey';
import SurveyTemplate from '@enums/survey.template';
import StripePayoutConnectTransferStatus from '@enums/StripePayoutConnectTransferStatus';
import TokenType from '@enums/TokenType';
import UserProjectStatus from '@enums/UserProjectStatus';
import VerificationStatus from '@enums/VerificationStatus';
import VICS from '@enums/VICS';
import Workspace from '@enums/Workspace';

const enums = {
  ...AccountInvoicingMethod,
  ...AccountPaymentMethod,
  ...ApprovalStatus,
  ...BillingPeriod,
  ...CallRating,
  ...CallStatus,
  ...CallType,
  ...ChargebleType,
  ...ChargeStatus,
  ...ConferenceType,
  ...DocumentSigning,
  ...IdentityProvider,
  ...LicenseType,
  ...Payment,
  ...PayoutableType,
  ...PayoutMethod,
  ...PayoutStatus,
  ...Permission,
  ...Project,
  ...InvoiceStatus,
  ...ReviewOption,
  ...Role,
  ...Seniority,
  ...Survey,
  ...SurveyTemplate,
  ...StripePayoutConnectTransferStatus,
  ...TokenType,
  ...UserProjectStatus,
  ...VerificationStatus,
  ...VICS,
  ...Workspace,
};

type Helpers = Helper<typeof enums>;

type Helper<T> = {
  [P in keyof T]: T[P];
};

export const utils = Object.keys(enums).reduce((acc, key) => ({
  ...acc,
  [key]: enums[key],
}), {} as Helpers);
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Editable, Slate, withReact } from 'slate-react';
import { Node, createEditor } from 'slate';
import * as utils from '@utils';
import { FeedItemComment } from '@/types/comments';
import { MyAvatar } from 'components/UserAvatar/MyAvatar';
import styles from './style/Comment.Section.css';

type Props = {
  className?: string;
  onSubmit:   (comment: Node[]) => Promise<FeedItemComment>;
};

export const Input = ({ onSubmit }: Props) => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const editor = useMemo(() => withReact(createEditor()), []);
  const [commentValue, setCommentValue] = useState<Node[]>(defaultValue);
  const [submittable, setSubmittable] = useState(isCommentValid(commentValue));

  const handleChange = useCallback((val: Node[]) => {
    setCommentValue(val);
    setSubmittable(isCommentValid(val));
  }, [
    setCommentValue,
    setSubmittable,
  ]);

  const handleSubmit = useCallback(() => {
    setSubmittable(false);
    //Block input here somehow
    onSubmit(commentValue).then(c => {
      if (isMounted.current) {
        setCommentValue(defaultValue);
        setSubmittable(false);
      }
    });
  }, [
    commentValue,
    onSubmit,
    setCommentValue,
    setSubmittable,
  ]);

  return (
    <div className={styles.root}>
      <MyAvatar
        className={styles.avatar}
        size={48} />
      <div className={styles.input}>
        <Slate
          editor={editor}
          value={commentValue}
          onChange={handleChange}>
          <Editable
            className={styles.editor}
            placeholder="Post a comment" />
        </Slate>
        <button
          className={styles.btn}
          disabled={!submittable}
          onClick={handleSubmit}>
          Post
        </button>
      </div>
    </div>
  );
};

function isCommentValid(text: Node[]) {
  return utils.slate.isValid(text, 3, 1000);
}

const defaultValue: Node[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];
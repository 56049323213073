import { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { searchTopics } from '@api/topics';
import { useDebouncedInputEvent, Target } from '@utils';
import { SearchBar } from './../SearchBar';

export const useSearchBar = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { data, isLoading, refetch } = useQuery([searchTerm, 'topic-search-autocomplete'], ({ queryKey: [term] }) => {
    if (term?.length < 3) return null;
    return searchTopics({ term });
  }, {
    enabled: false,
    keepPreviousData: true,
  });

  const wrappedFetch = useCallback(() => {
    refetch();
  }, [refetch]);

  const debouncedFetch = useDebouncedInputEvent(wrappedFetch, 250);

  const onChange = useCallback((e: React.ChangeEvent<Target>) => {
    setSearchTerm(e.target.value);
    debouncedFetch(e);

  }, [setSearchTerm, debouncedFetch]);

  const SearchComponent = useCallback(({ term, isLoading }: { term: string; isLoading: boolean; }) => {
    return (
      <div>
        <SearchBar
          value={term}
          placeholder={'Search'}
          loading={isLoading}
          onChange={onChange} />
      </div>
    );
  }, [onChange]);

  return [{ returnedItems: data, isLoading, searchTerm }, SearchComponent] as const;
};
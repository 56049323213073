import { Payment } from '@api/interfaces';
import { safeJsonDate } from '@utils';

export function normalizeBillingHistory(data: Payment.Enterprise.GetBillingHistory.Response<string>): Payment.Enterprise.GetBillingHistory.Response {
  return {
    ...data,
    items: data.items.map(item => ({
      ...item,
      createdOn: safeJsonDate(item.createdOn),
    })),
  };
}
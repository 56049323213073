import { useCallback } from 'react';
import { SurveyActionType, SurveyQuestionType } from '@enums';
import { QuestionText } from '@presentation/Survey';
import { cx } from '@utils';
import { MatrixLogicPreview } from './MatrixLogicPreview';
import QuestionOptionsLogicPreview from './QuestionOptionsLogic';
import QuestionBaseLogic from './QuestionBaseLogic';
import { QuestionLogicPreviewProps } from './interfaces';
import styles from './style/QuestionLogic.css';

export const QuestionLogicPreview = (props: QuestionLogicPreviewProps) => {

  const renderResponseLogic = useCallback(() => {
    if (props.item.logic.base.action.type !== SurveyActionType.None) {
      return null;
    }

    switch (props.item.typeId) {
      case SurveyQuestionType.MultipleChoice:
      case SurveyQuestionType.Multiselect: {
        return (
          <QuestionOptionsLogicPreview
            className={styles.responseLogic}
            includeOrdinalColumn={props.includeOrdinalColumn}
            logic={props.item.logic.response}
            options={props.item.options} />
        );
      }

      case SurveyQuestionType.MatrixGrid:
      case SurveyQuestionType.MatrixMultiselect: {
        return (
          <MatrixLogicPreview
            className={styles.responseLogic}
            item={props.item} />
        );
      }

      default: {
        return null;
      }
    }
  }, [props.includeOrdinalColumn, props.item]);

  return (
    <div className={cx(styles.root, props.className)}>
      <QuestionText
        textClassName={cx(styles.text, props.textClassName)}
        item={props.item} />
      <div className={styles.logic}>
        {props.item.logic.base.action.type !== SurveyActionType.None &&
          <QuestionBaseLogic
            className={styles.baseLogic}
            action={props.item.logic.base.action} />
        }
        {renderResponseLogic()}
      </div>
    </div>
  );
};

export default QuestionLogicPreview;
/* eslint-disable react/jsx-no-target-blank */
import { urls } from '@consts';
import { Skip } from '@presentation';
import Button from 'components/Button';
import { ProjectCallOverviewProps } from './interfaces';
import styles from './style/Overview.css';

export default function ProjectCall({ projectName, navigation }: ProjectCallOverviewProps) {
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h1 className={styles.title}>
          {`You're all set for the ${projectName} project!`}
        </h1>
        <div className={styles.copy}>
          {`The client will review your suggested call times, and if you are selected, you will receive a calendar invitation from the system with dial information for the consultation soon!`}
        </div>
        <div className={styles.copy2}>
          {`While you are waiting, confirm your billing information to receive payouts for the projects you engage in.`}
        </div>
        <div className={styles.navigation}>
          <Button
            variant="brick"
            title="Add Payout Account"
            onClick={navigation.next} />
          <div className={styles.external}>
            <a target="_blank" href={`${urls.FAQ}`}>
              {`Learn more about commonly asked questions`}
            </a>
          </div>
          <div className={styles.skip}>
            <Skip onClick={navigation.skip}>
              {`I'll do this later`}
            </Skip>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ProjectCall };
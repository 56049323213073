import React, { useContext } from 'react';
import { RemoveContactsContext, SelectedContext } from '@containers/Group.Contacts/Context';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { formatPlurality } from '@utils';
import { Body } from 'components/Modal/Body';
import { ButtonSet } from 'components/Modal/ButtonSet';
import { Modal, ModalProps } from 'components/Modal/Modal';
import styles from './style/Modal.RemoveContacts.css';

type Props =
  Pick<ModalProps,
  | 'onClose'
  | 'open'>;

export const RemoveContacts = (props: Props) => {
  const { mutation } = useContext(RemoveContactsContext);
  const [selected] = useContext(SelectedContext);

  const message = `Remove ${selected.ids.size} ${formatPlurality('contact', selected.ids.size)}?`;

  return (
    <Modal
      className={styles.root}
      disableEscapeClose={mutation.isLoading}
      disableOverlayClick={mutation.isLoading}
      hideCloseIcon
      open={props.open}
      onClose={props.onClose}>
      <Body className={styles.body}>
        <div className={styles.message}>{message}</div>
      </Body>
      <ButtonSet className={styles.btns}>
        <ButtonOutlined
          borderWidth="thin"
          className={styles.btn}
          disabled={mutation.isLoading}
          color="silver"
          onClick={props.onClose}>
          Cancel
        </ButtonOutlined>
        <ButtonActivityIndicator
          className={styles.btn}
          color="destructive"
          indicatorSize={12}
          loading={mutation.isLoading}
          onClick={mutation.mutate}>
          Confirm
        </ButtonActivityIndicator>
      </ButtonSet>
    </Modal>
  );
};

RemoveContacts.displayName = 'Group.Contacts.RemoveContact.Modal';
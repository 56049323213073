import { useCallback } from 'react';
import * as Stripe from '@screens/Stripe';
import { useSelectUser } from '@containers/Store';
import { useUserNewPaymentMethod } from '@utils/api';
import Toast from 'components/Toast';
import Header from 'components/Modal/Header';
import { useAdHocRequestStepper } from './Context';
import styles from './style/AdHoc.css';

export const AdHocCallRequestPayment = () => {
  const actions = useAdHocRequestStepper();
  const me = useSelectUser();

  const newPaymentMethod = useUserNewPaymentMethod();

  const savePaymentMethod: Stripe.OnTokenEvent = useCallback(token => {
    return newPaymentMethod({
      token,
      userId: me.id,
    })
    .then(result => {
      if (!result.success) {
        return Toast.error({
          title: 'Error Adding Payment Method',
          body: result.error,
        });
      }

      actions.next();
    });
  }, [
    actions,
    newPaymentMethod,
    me.id,
  ]);

  const subtitle = 'Connect a debit or credit card to send payments on the platform.';

  return (
    <>
      <Header
        className={styles.header}
        text="Set up a payments account" />

      <div className={styles.subtitle}>{subtitle}</div>

      <Stripe.NewCard onToken={savePaymentMethod} />
    </>
  );
};

export default AdHocCallRequestPayment;
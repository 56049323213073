import { useCallback, useRef, useState } from 'react';
import { UploadCloud } from 'react-feather';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { cx } from '@utils';
import { useDownloadProjectSummary, useSaveProjectSummary } from '@containers/ProjectSummary/hooks';
import Button from 'components/Button';
import styles from './style/Summary.css';

type Props = {
  downloadName: string;
  summary: string;
};

export const SummaryNotes = (props: Props) => {

  const download = useDownloadProjectSummary(props.downloadName);
  const save = useSaveProjectSummary();

  const [saving, setSaving] = useState(false);
  const [downloadEnabled, setDownloadEnabled] = useState(!!props.summary);
  const timerRef = useRef<NodeJS.Timeout>(null);

  const savingClass = cx(styles.cloud, saving ? styles.saving : styles.saved);

  const handleNotesChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    const value = event.target.value;

    timerRef.current = setTimeout(() => {
      setSaving(true);

      save(value)
      .then(async () => {

        await new Promise(r => setTimeout(r, 200));

        setSaving(false);
        setDownloadEnabled(!!value);
      })
      .catch(e => console.error(e));
    }, 1000);
  }, [
    save,
  ]);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.trunc}>
          <h2 className={styles.h2}>Summary</h2>
        </div>
        <UploadCloud className={savingClass} />
      </div>
      <div className={styles.notepad}>
        <div className={styles.pad}>
          <TextareaAutosize
            onChange={handleNotesChange}
            className={styles.textarea}
            autoFocus
            placeholder="Summary"
            cols={1}
            minRows="1"
            defaultValue={props.summary} />
        </div>
        <div className={styles.actions}>
          <Button
            className={styles.download}
            disabled={!downloadEnabled}
            onClick={download}
            variant="brick">
            Download
          </Button>
        </div>
      </div>
    </>
  );
};

export default SummaryNotes;
import { useCallback, useState } from 'react';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import StripeConsts from '@consts/stripe-countries.json';
import { Stripe } from './interfaces';
import { validateNewCardForm } from './utils';
import { CardFormValue, NewStripeCardFormContext, NewCardFormValue } from './Context.NewCard';

type Props = {
  isLoading?: boolean;
  onError?: Stripe.OnErrorEvent;
  onSave?: Stripe.OnSaveEvent;
  onToken: Stripe.OnTokenEvent;
} & ChildrenProps;

export const StripeNewCardContainer = (props: Props) => {

  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [card, setCard] = useState<StripeCardElementChangeEvent>();
  const [form, setForm] = useState<Stripe.NewCardState>({
    cardError: null,
    name: { value: '', required: true },
    address: {
      country: { value: StripeConsts.Countries.find(c => c.id === 'US').id, required: true },
      address1: { value: '', required: true },
      address2: { value: '', required: false },
      city: { value: '', required: true },
      state: { value: '', required: true },
      zip: { value: '', required: true },
    },
  });

  const handleSubmit = useCallback(() => {

    const validation = validateNewCardForm(card, form);
    setForm(validation.form);
    setError(validation.error);

    if (validation.error) {
      return Promise.resolve(setLoading(false));
    }

    setLoading(true);
    props.onSave?.();

    const cardElement = elements.getElement(CardElement);

    return stripe.createToken(cardElement, {
      name: form.name.value,
      address_country: form.address.country.value,
      address_state: form.address.state.value,
      address_city: form.address.city.value,
      address_line1: form.address.address1.value,
      address_line2: form.address.address2.value,
      address_zip: form.address.zip.value,
    })
    .then(async result => {

      if (result.error) {
        const message = result.error.message && result.error.type !== 'api_error' ? result.error.message : 'An error occured when trying to add this card.';
        setLoading(false);
        props.onError?.({ message });
        return setError(message);
      }

      await props.onToken(result.token);

      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
      const message = 'An error occured when trying to add this card.';
      setError(message);
      props.onError?.({ message });
    });

  }, [card, elements, form, props, setError, stripe]);

  const ctx = {
    card: [card, setCard] as CardFormValue,
    form: [form, setForm] as NewCardFormValue,
    state: {
      error,
      loading: loading || props.isLoading,
    },
    submit: handleSubmit,
  };

  return (
    <NewStripeCardFormContext.Provider value={ctx}>
      {props.children}
    </NewStripeCardFormContext.Provider>
  );
};

export default StripeNewCardContainer;
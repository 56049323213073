import { ComponentProps } from 'react';
import Radio from '@mui/material/Radio';
import { makeStyles } from '@mui/styles';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';

type Props = Omit<ComponentProps<typeof Radio>, 'color' | 'disableRipple'>;

export const RadioStyles = {
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$checked': {
      color: (props: { color: string; }) => props.color,
    },
    padding: 0,
  },
  checked: {
    color: (props: { color: string; }) => props.color,
  },
};

const useStyles = makeStyles(RadioStyles);

export const RadioButton = (props: Props) => {
  const { palette, theme } = useSurveyThemingPalette();
  const color = theme
      ? palette.primary.main
      : `var(--pri-02)`;
  const classes = useStyles({ color });

  return (
    <Radio
      className={props.className}
      classes={props.classes || classes}
      disableRipple
      color='default'
      {...props} />
  );
};

export default RadioButton;
import { useSelectObject, useFolderCapabilities } from '@containers/Store';
import { useWorkspaceFolderState } from '@containers/Workspace/hooks/useWorkspaceFolderState';
import { useFoldersTableItems } from 'components/WorkspaceFolders/hooks/useFoldersTableItems';
import { useNewFolderModal } from 'components/WorkspaceFolders/hooks/useNewFolderModal';
import FoldersTable from './FoldersTable';

export const FolderTabFolders = () => {
  const { folder } = useWorkspaceFolderState();
  const object = useSelectObject(folder.objectId);
  const [toggleNewFolderModal, NewFolderModal] = useNewFolderModal({
    location: folder.name,
    parentObject: object,
  });
  const capabilities = useFolderCapabilities(folder.id);

  const data = useFoldersTableItems({
    parentObjectId: folder.objectId,
  });

  return (
    <>
      <FoldersTable
        canCreateNew={capabilities.canAddChildren}
        onCreateNew={toggleNewFolderModal}
        data={data} />
      <NewFolderModal />
    </>
  );
};

export default FolderTabFolders;
import { useSelector } from 'react-redux';
import { PayoutMethod } from '@enums';

const mapState = (state: Store.State) => state.user?.payout;

export function useStripeAccountId() {
  const payout = useSelector(mapState);

  const accountId =
    payout?.method === PayoutMethod.Stripe
      ? payout.accountId
      : null;

  const setup = !!accountId;

  return [accountId, setup] as const;
}

export default useStripeAccountId;
import cuid from 'cuid';
import { SurveyActionType, SurveyOptionType, SurveyQuestionType } from '@enums/Survey';
import { SurveyTemplateEntityType } from '@enums/survey.template';
import {
  BaseCondition,
  BaseQuestionCondition,
  LongTextResponseQuestion,
  SurveyLogic,
  SurveyQuestionMatrixRow,
  SurveyQuestionOption,
  SurveySection,
  SurveyPiping,
  MultipleChoiceQuestion,
  MultiselectQuestion,
  RankingQuestion,
  MatrixGridQuestion,
  MatrixSliderQuestion,
  ShortTextResponseQuestion,
  SurveyRichText,
  SurveyQuestion,
  SurveyTemplate,
} from '@/types/survey';
import * as question from './question';

const defaultPiping = {
  entities: [],
} as SurveyPiping;

export const defaultBaseLogic: SurveyLogic.Base = {
  action: {
    type: SurveyActionType.None,
  },
};

export function generateOption({
  conditions = [],
  metadata,
  ordinal,
  type = SurveyOptionType.Default,
  value,
}: GenerateOption): SurveyQuestionOption {
  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    conditions,
    metadata,
    ordinal,
    type,
    value,
  };
}

export function generateRow({
  conditions = [],
  metadata,
  ordinal,
  value,
}: GenerateRow): SurveyQuestionMatrixRow {
  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    conditions,
    metadata,
    ordinal,
    value,
  };
}

export function generateSection({
  hidden = false,
  ...data
}: GenerateSection): SurveySection {
  return {
    hidden,
    id: null,
    identifier: cuid(),
    metadata: data.metadata,
    ordinal: data.ordinal,
    name: data.name,
  };
}

export function generateMultipleChoiceQuestion({
  logic = {
    base: defaultBaseLogic,
    response: [] as MultipleChoiceQuestion.Logic.Response[],
  },
  ordinal = 1,
  settings = question.multipleChoice.DefaultSettings,
  ...q
}: GenerateMultipleChoiceQuestion): MultipleChoiceQuestion.Question {
  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    logic,
    ordinal,
    piping: defaultPiping,
    settings,
    tagging: { response: [] },
    typeId: SurveyQuestionType.MultipleChoice,
    ...q,
  };
}

export function generateMultiselectQuestion({
  logic = {
    base: defaultBaseLogic,
    response: [] as MultiselectQuestion.Logic.Response[],
  },
  ordinal = 1,
  settings = question.multiselect.DefaultSettings,
  ...q
}: GenerateMultiselectQuestion): MultiselectQuestion.Question {
  return {
    ...q,
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    logic,
    ordinal,
    piping: defaultPiping,
    settings,
    tagging: { response: [] },
    typeId: SurveyQuestionType.Multiselect,
  };
}

export function generateRankingQuestion({
  logic = {
    base: defaultBaseLogic,
  },
  ordinal = 1,
  settings = question.ranking.DefaultSettings,
  ...q
}: GenerateRankingQuestion): RankingQuestion.Question {
  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    logic,
    ordinal,
    piping: defaultPiping,
    settings,
    typeId: SurveyQuestionType.Ranking,
    ...q,
  };
}

export function generateShortTextQuestion({
  logic = {
    base: defaultBaseLogic,
  },
  ordinal = 1,
  settings = question.shortText.DefaultSettings,
  ...q
}: GenerateShortTextQuestion): ShortTextResponseQuestion.Question {

  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    logic,
    ordinal,
    piping: defaultPiping,
    settings,
    typeId: SurveyQuestionType.ShortTextResponse,
    ...q,
  };
}

export function generateLongTextQuestion({
  logic = {
    base: defaultBaseLogic,
  },
  ordinal = 1,
  settings = question.longText.DefaultSettings,
  ...q
}: GenerateLongTextQuestion): LongTextResponseQuestion.Question {

  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    logic,
    ordinal,
    piping: defaultPiping,
    settings,
    typeId: SurveyQuestionType.LongTextResponse,
    ...q,
  };
}

export function generateSlidersQuestion({
  logic = {
    base: defaultBaseLogic,
  },
  ordinal = 1,
  settings = question.matrixSlider.DefaultSettings,
  ...q
}: GenerateSlidersQuestion): MatrixSliderQuestion.Question {
  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    ordinal,
    logic,
    piping: defaultPiping,
    settings,
    typeId: SurveyQuestionType.Sliders,
    ...q,
  };
}

export function generateMatrixGridQuestion({
  logic = {
    base: defaultBaseLogic,
    response: [] as MatrixGridQuestion.Logic.Response[],
  },
  ordinal = 1,
  settings = question.matrixGrid.DefaultSettings,
  ...q
}: GenerateMatrixGridQuestion): MatrixGridQuestion.Question {
  return {
    id: null,
    base: {
      id: null,
      identifier: cuid(),
    },
    logic,
    ordinal,
    piping: defaultPiping,
    settings,
    typeId: SurveyQuestionType.MatrixGrid,
    ...q,
  };
}

export function generateRichTextValue(content: SurveyRichText.ParagraphContent[]): SurveyRichText.RichTextValue {
  return {
    type: 'doc',
    content: [generateParagraphNode(content)],
  };
}

export function generateParagraphNode(content: SurveyRichText.ParagraphContent[]): SurveyRichText.ParagraphNode {
  return {
    content,
    type: SurveyRichText.NodeType.Paragraph,
  };
}

export function generateTextNode(text: string): SurveyRichText.TextNode {
  return {
    text,
    type: SurveyRichText.NodeType.Text,
  };
}

export function generateCategoryNode(category: SurveyTemplate.Category): SurveyRichText.CategoryNode {
  return {
    attrs: {
      category: category.value,
    },
    type: SurveyRichText.NodeType.TemplateCategory,
  };
}

export function generateTargetNode(text: string): SurveyRichText.TargetNode {
  return {
    attrs: {
      target: text,
    },
    type: SurveyRichText.NodeType.TemplateTarget,
  };
}

export function generateCompetitorNode(item: SurveyTemplate.Competitor): SurveyRichText.CompetitorNode {
  return {
    attrs: {
      id: item.id,
      competitor: item.value,
    },
    type: SurveyRichText.NodeType.TemplateCompetitor,
  };
}

export function generatePipedResponseNode(data: GeneratePipedResponseNode): SurveyRichText.PipedResponseNode {
  return {
    attrs: {
      condition: data.condition,
      question: {
        identifier: data.questionIdentifier,
      },
    },
    type: SurveyRichText.NodeType.PipedResponse,
  };
}

export function generateTargetOption(data: GenerateTargetOption) {
  return generateOption({
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: null,
          type: SurveyTemplateEntityType.Target,
        },
        key: null,
      },
    },
    ordinal: data.ordinal,
    value: data.target,
  });
}

export function generateTargetRow(data: GenerateTargetOption) {
  return generateRow({
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: null,
          type: SurveyTemplateEntityType.Target,
        },
      },
    },
    ordinal: data.ordinal,
    value: data.target,
  });
}

export function generateCompetitorOption(data: GenerateTextItemOption) {
  return generateOption({
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.Competitor,
        },
        key: null,
      },
    },
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateCompetitorRow(data: GenerateTextItemOption) {
  return generateRow({
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.Competitor,
        },
      },
    },
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateEvaluationCriteriaOption(data: GenerateTextItemOption) {
  return generateOption({
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.EvaluationCriteria,
        },
        key: null,
      },
    },
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generatePartnershipCriteriaOption(data: GenerateTextItemOption) {
  return generateOption({
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.PartnershipCriteria,
        },
        key: null,
      },
    },
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateTargetRoleOption(data: GenerateTextItemOption) {
  return generateOption({
    conditions: data.conditions,
    metadata: {
      canModifyValue: false,
      canDelete: false,
      template: {
        linkedEntity: {
          id: data.item.id,
          type: SurveyTemplateEntityType.TargetRole,
        },
        key: null,
      },
    },
    ordinal: data.ordinal,
    value: data.item.value,
  });
}

export function generateInitialTextItems(count: number, fill = ''): SurveyTemplate.LinkedEntity[] {
  return Array<string>(count)
    .fill('')
    .map((x, i) => ({
      id: cuid(),
      value: fill ? `${fill} ${i + 1}` : x,
    }));
}

export function attachQuestionOrdinals(questions: SurveyQuestion[]) {
  return questions.map((m, i) => ({
    ...m,
    ordinal: i + 1,
  }));
}

export const IndustryOptions = [
  'Manufacturing',
  'Retail',
  'Healthcare',
  'Government',
  'Agriculture/Mining',
  'Hospitality',
  'Financial Services',
  'Consulting/IT Services',
  'Transportation',
  'Energy',
  'Education',
  'Telecommunications',
  'Business Services (Law, Accounting, etc)',
  'Advertising/Media',
];

export const RoleOptions = [
  'Student, retired, or not currently employed ',
  'Contractor or consultant',
  'Individual Contributor',
  'Manager / Supervisor',
  'Director / Senior Director',
  'Vice President',
  'Executive / President',
  'Owner / C-Level',
];

export const DepartmentOptions = [
  'Customer service, client service, or call center',
  'Finance/Accounting/ procurement/ tax and revenue',
  'IT/technology',
  'Executive management, board, or executive office',
  'Legal/compliance/privacy',
  'Manufacturing, production, or operations',
  'Marketing/Direct Marketing/Digital',
  'Product Management/Development/Engineering',
  'Public relations/corporate communications',
  'Sales',
  'Strategy/corporate development',
];

export const RevenueOptions = [
  '$0 - $1M',
  '$1.0 - $10.0M',
  '$10.0 - $50.0M',
  '$50.0 - $100.0M',
  '$100.0 - $250.0M',
  '$250.0 - $500.0M',
  '$500.0 - $1.0B',
  '$1.0B - $5.0B',
  '$5.0B - $10.0B',
  '$10B+',
];

export const AgreeDisagreeOptions = [
  'Strongly disagree',
  'Disagree',
  'Neither agree nor disagree',
  'Agree',
  'Strongly Agree',
];

type GenerateTargetOption = {
  conditions?: SurveyQuestionOption['conditions'];
  ordinal: number;
  target: string;
};

type GenerateTextItemOption = {
  conditions?: BaseQuestionCondition[];
  ordinal: number;
  item: SurveyTemplate.LinkedEntity;
};

type GenerateOption =
  Pick<SurveyQuestionOption, 'metadata' | 'ordinal' | 'value'> &
  Partial<Pick<SurveyQuestionOption, 'conditions' | 'type'>>;

type GenerateRow =
  Pick<SurveyQuestionMatrixRow, 'metadata' | 'ordinal' | 'value'> &
  Partial<Pick<SurveyQuestionMatrixRow, 'conditions'>>;

type GenerateMultipleChoiceQuestion =
  Omit<MultipleChoiceQuestion.Question, 'id' | 'base' | 'logic' | 'piping' | 'settings' | 'tagging' | 'typeId' | 'ordinal'> &
  Partial<Pick<MultipleChoiceQuestion.Question, 'logic' | 'settings' | 'ordinal'>>;

type GenerateMultiselectQuestion =
  Omit<MultiselectQuestion.Question, 'id' | 'base' | 'logic' | 'piping' | 'settings' | 'tagging' | 'typeId' | 'ordinal'> &
  Partial<Pick<MultiselectQuestion.Question, 'logic' | 'settings' | 'ordinal'>>;

type GenerateRankingQuestion =
  Omit<RankingQuestion.Question, 'id' | 'base' | 'logic' | 'piping' | 'settings' | 'tagging' | 'typeId' | 'ordinal'> &
  Partial<Pick<RankingQuestion.Question, 'logic' | 'settings' | 'ordinal'>>;

type GenerateShortTextQuestion =
  Omit<ShortTextResponseQuestion.Question, 'id' | 'base' | 'logic' | 'piping' | 'settings' | 'tagging' | 'typeId' | 'ordinal'> &
  Partial<Pick<ShortTextResponseQuestion.Question, 'logic' | 'settings' | 'ordinal'>>;

type GenerateLongTextQuestion =
  Omit<LongTextResponseQuestion.Question, 'id' | 'base' | 'logic' | 'piping' | 'settings' | 'tagging' | 'typeId' | 'ordinal'> &
  Partial<Pick<LongTextResponseQuestion.Question, 'logic' | 'settings' | 'ordinal'>>;

type GenerateSlidersQuestion =
  Omit<MatrixSliderQuestion.Question, 'id' | 'base' | 'logic' | 'piping' | 'settings' | 'tagging' | 'typeId' | 'ordinal'> &
  Partial<Pick<MatrixSliderQuestion.Question, 'logic' | 'settings' | 'ordinal'>>;

type GenerateMatrixGridQuestion =
  Omit<MatrixGridQuestion.Question, 'id' | 'base' | 'logic' | 'piping' | 'settings' | 'tagging' | 'typeId' | 'ordinal'> &
  Partial<Pick<MatrixGridQuestion.Question, 'logic' | 'settings' | 'ordinal'>>;

type GenerateSection =
  Partial<Pick<SurveySection, 'hidden'>> &
  Pick<SurveySection, 'metadata' | 'name' | 'ordinal'>;

type GeneratePipedResponseNode = {
  condition: BaseCondition;
  questionIdentifier: string;
};
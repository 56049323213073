import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { path } from '@consts';
import { SurveyThemingContext } from '@containers/Branding/Context';
import { useSurveyThemingPage } from '@containers/Branding/hooks/useSurveyThemingPage';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { Button } from 'components/Button';
import { ButtonColor } from 'components/Button/interfaces';
import styles from './style/SurveyCompletion.css';

type Props = unknown;

export const SurveyCompletion = (props: Props) => {
  const params = useParams<RouteParams>();
  const history = useHistory();
  const { page, theme: hasPage } = useSurveyThemingPage();
  const { palette, theme: hasPalette } = useSurveyThemingPalette();
  const { theming } = useContext(SurveyThemingContext);

  const next = `${path.Onboarding.Projects.Root}/${+params.projectId}/continue`;

  const hasImage = hasPage && page.completion?.headerImage?.url;
  const hasText = hasPage && page.completion?.text;

  const hasPageContent = useMemo(() => {
    return hasImage && hasText;
  }, [
    hasImage,
    hasText,
  ]);

  const redirect = useCallback(() => {
    history.push(next);
  }, [
    history,
    next,
  ]);

  useEffect(() => {

    if (!theming?.loading && !hasPageContent) {
      redirect();
    }

  }, [
    hasPageContent,
    redirect,
    theming?.loading,
  ]);

  if (theming?.loading) {
    return (
      <ActivityIndicator show />
    );
  }

  const btn = {
    color: hasPalette ? `transparent` : `primary` as ButtonColor,
    style: hasPalette ? {
      backgroundColor: palette.primary.main,
      color: palette.primary.text,
    } : {},
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>

          {hasImage &&
            <div className={styles.header}>
              <div className={styles.banner}>
                <div
                  className={styles.img}
                  style={{ backgroundImage: `url(${page.completion.headerImage.url})` }} />
              </div>
            </div>}

          <h2 className={styles.title}>
            {copy.title}
          </h2>

          {hasText &&
            <div className={styles.text}>{page.completion.text}</div>}

          <div className={styles.footer}>
            <div className={styles.info}>
              {copy.info}
            </div>

            <Button
              className={styles.btn}
              color={btn.color}
              style={btn.style}
              to={next}
              variant="brick">
              Next
            </Button>
          </div>

        </div>
      </div>
    </div>
  );
};

SurveyCompletion.displayName = 'Survey.Completion';

const copy = {
  info: `Next, you’ll be directed to Vancery to confirm payment details and set up a profile for future project opportunities.`,
  title: `Thank you for completing the survey!`,
};

type RouteParams = {
  projectId: string;
};
import { UseQueryOptions, useQuery } from 'react-query';
import * as api from '@api';
import * as Branding from '@api/interfaces/groups.branding';
import * as QKey from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';

export const useFetchBrandingQuery = (options: Options = {}) => {
  const group = useSelectGroup();

  const query = useQuery([QKey.Groups.Branding.Get, group.id], () => {
    return api.groups.branding.fetchBranding({
      groupId: group.id,
    });
  }, options);

  return query;
};

type Options = UseQueryOptions<Branding.FetchBranding.Response>;
import { useContext } from 'react';
import * as enums from '@enums';
import { RateOnboardingContext } from '@containers/RateOnboarding';
import {
  WizardHeader,
} from 'components/Wizard';
import Sectors from 'scenes/vics-selection/Sectors';
import { Navigation, Screen } from './Wizard';

export const SectorsScreen = () => {
  const ctx = useContext(RateOnboardingContext);

  const renderHeader = () => {
    const title = `What sector do you work in?`;
    const subtitle = `Select up to two sectors to filter projects for your professional experience.`;

    return  (
      <WizardHeader
        title={title}
        subtitle={subtitle} />
    );
  };

  const renderBody = () => (
    <Sectors
      onSelection={ctx.onVICSSelection(enums.VICSType.Sector)}
      selectedItems={ctx.sectors}
      vics={ctx.vics} />
  );

  const renderNavigation = () =>  (
    <Navigation nextEnabled={ctx.sectors.length > 0} />
  );

  return (
    <Screen
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderNavigation={renderNavigation} />
  );
};

export default SectorsScreen;
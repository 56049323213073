import { SurveyQuota } from '@/types/survey';
import { SurveyBuilder } from '../interfaces';

type EditingState =
  Pick<SurveyBuilder.State,
    'editing' |
    'savedSurvey' |
    'committed'>;

export function editingReducer(state: SurveyBuilder.State, action: SurveyBuilder.Actions): EditingState {

  switch (action.type) {

    case 'toggle-editing':
      return {
        ...state,
        committed: {
          questions: state.survey.questions,
          quotas: state.survey.quotas,
        },
        editing: {
          logicOrdinal: null,
          questionOrdinal: action.ordinal,
          quota: {
            identifier: null,
            isNew: false,
          },
        },
      };

    case 'toggle-logic-editing':
      return {
        ...state,
        committed: {
          questions: state.survey.questions,
          quotas: state.survey.quotas,
        },
        editing: {
          logicOrdinal: action.ordinal,
          questionOrdinal: null,
          quota: {
            identifier: null,
            isNew: false,
          },
        },
      };

    case 'reset-survey':
      return {
        ...state,
        committed: {
          questions: action.value.questions,
          quotas: action.value.quotas,
        },
        savedSurvey: action.value,
      };

    case 'revert-uncommitted-changes':
      return {
        ...state,
        editing: {
          logicOrdinal: null,
          questionOrdinal: null,
          quota: {
            identifier: null,
            isNew: false,
          },
        },
      };

    case 'new-question-added':
      return {
        ...state,
        editing: {
          logicOrdinal: null,
          questionOrdinal: action.questionOrdinal,
          quota: {
            identifier: null,
            isNew: false,
          },
        },
      };

    case 'toggle-quota-editing-off':
      return {
        ...state,
        editing: {
          logicOrdinal: null,
          questionOrdinal: null,
          quota: {
            identifier: null,
            isNew: false,
          },
        },
      };

    case 'save-quota-item': {
      const quotas = state.survey.quotas.reduce<SurveyQuota.Record[]>((acc, x) => {
        if (x.identifier === action.value.identifier) {
          acc.push(action.value);
        } else {
          acc.push(x);
        }

        return acc;
      }, []);

      return {
        ...state,
        committed: {
          questions: state.survey.questions,
          quotas,
        },
        editing: {
          logicOrdinal: null,
          questionOrdinal: null,
          quota: {
            identifier: null,
            isNew: false,
          },
        },
      };
    }

    case 'add-quota-item':
      return {
        ...state,
        editing: {
          logicOrdinal: null,
          questionOrdinal: null,
          quota: {
            identifier: action.identifier,
            isNew: true,
          },
        },
      };

    case 'toggle-quota-editing-on':
      return {
        ...state,
        editing: {
          logicOrdinal: null,
          questionOrdinal: null,
          quota: {
            identifier: action.identifier,
            isNew: false,
          },
        },
      };

    default:
      return state;

  }
}
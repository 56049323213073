import { Link } from 'react-router-dom';
import { path } from '@consts';
import { usePersistentProjectInvite } from '@containers/ProjectLinkInvitation/hooks/usePersistentProjectInvite';
import { ButtonActivityIndicator } from '@presentation';
import { cx } from '@utils';
import { AuthButton, AuthForm } from '$website/components';
import styles from '$website/components/AuthModal/style/AuthModal.css';
import { AuthContainer, SocialAuthContainer, PersistentLinkState } from '$website/containers';
import { useSignupSubmit, useSocialAuth } from '$website/containers/Auth/hooks';
import { Logo } from 'components/Branding';
import { SignupError } from './SignupError';

const { Layout } = AuthForm;

type Props = unknown;

const SignUpVisitor = (props: Props) => {
  return (
    <AuthContainer>
      <SocialAuthContainer>
        <Form />
      </SocialAuthContainer>
    </AuthContainer>
  );
};

function Form() {
  const [form, submit] = useSignupSubmit();
  const social = useSocialAuth();
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();

  function handleForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    submit({ token: state?.token });
  }

  return (
    <div className={cx(styles.page, styles.wrap)}>

      <Layout.Header>
        <Layout.Row>
          <Link to={path.Website.Root}>
            <Logo size={55} />
          </Link>
        </Layout.Row>

        <Layout.Row>
          <Layout.Title>Sign up with Vancery</Layout.Title>
        </Layout.Row>

        <Layout.Row>
          <Layout.Subtitle>
            <span>or </span>
            <Link
              className={styles.link}
              to={path.Website.Login}>
              log in
            </Link>
          </Layout.Subtitle>
        </Layout.Row>
      </Layout.Header>

      <div className={styles.body}>
        <Layout.Row>
          <AuthButton.Google
            className={cx(styles.vistor, styles.btn)}
            onClick={social.authorizeGoogle}>
            Sign up with Google
          </AuthButton.Google>
        </Layout.Row>

        <Layout.Row>
          <AuthButton.Facebook
            className={cx(styles.vistor, styles.btn)}
            onClick={social.authorizeFacebook}>
            Sign up with Facebook
          </AuthButton.Facebook>
        </Layout.Row>

        <Layout.Row>
          <AuthButton.Apple
            className={cx(styles.vistor, styles.btn)}
            onClick={social.authorizeApple}>
            Sign up with Apple
          </AuthButton.Apple>
        </Layout.Row>

        <Layout.Row>
          <Layout.ThematicBreak>
            or
          </Layout.ThematicBreak>
        </Layout.Row>

        <form onSubmit={handleForm}>
          <AuthForm.Email.Signup />
          <Layout.Row>
            <div className={styles.error}>
              <SignupError />
            </div>
          </Layout.Row>
          <Layout.Row className={styles.padtop}>
            <ButtonActivityIndicator
              className={styles.submit}
              loading={form.loading}>
              Sign Up
            </ButtonActivityIndicator>
          </Layout.Row>
        </form>
      </div>

      <AuthForm.PrivacyPolicy />

    </div>
  );
}

export const Visitor = PersistentLinkState(SignUpVisitor, 'Signup.Visitor');
import { Fragment } from 'react';
import { MatrixMultiselectQuestion } from '@/types';
import styles from './style/MatrixMultiselect.css';

type Props = {
  answer: MatrixMultiselectQuestion.Answer.Value;
};

export const MatrixMultiselectAnswerValue = ({ answer }: Props) => {

  return <>
    {answer.items.map(item => (
      <Fragment key={item.row.id}>
        <div className={styles.row}>
          <div className={styles.rowText}>{item.row.value}</div>
          {item.options.map(option => (
            <div key={option.id} className={styles.option}>{option.value}</div>
          ))}
        </div>
      </Fragment>
    ))}
  </>;
};

export default MatrixMultiselectAnswerValue;
import { io } from 'socket.io-client';
import { ReportSocketEvent, LifeCycleSocketEvent, SocketNamespace } from '@enums';
import * as $session from '@services/auth/session';
import { createLogger } from './utils';
import { Reports } from './interfaces';

const log = createLogger(SocketNamespace.Reports);

const socket = io(`${process.env.BACKEND_WS_URL}/${SocketNamespace.Reports}`, {
  path: process.env.BACKEND_WS_PATH,
  auth: {
    'X-CSRF-Token': $session.getCSRF(),
  },
  autoConnect: false,
  withCredentials: true,
  transports: ['websocket', 'polling'],
});

export function open() {
  socket.on(LifeCycleSocketEvent.Connect, () => log.event(LifeCycleSocketEvent.Connect));
  socket.on(LifeCycleSocketEvent.ConnectError, data => log.event(LifeCycleSocketEvent.ConnectError, data));
  socket.on(LifeCycleSocketEvent.Disconnect, () => log.event(LifeCycleSocketEvent.Disconnect));

  socket.open();
}

export function close() {
  socket.close();

  socket.off(LifeCycleSocketEvent.Connect);
  socket.off(LifeCycleSocketEvent.ConnectError);
  socket.off(LifeCycleSocketEvent.Disconnect);
}

export const on = <T extends ReportSocketEvent>(event: ReportSocketEvent, handler: Reports.RegisterHandler<T>) => {
  return socket.on(event, handler);
};

export const off = <T extends ReportSocketEvent>(event: ReportSocketEvent, handler: Reports.RegisterHandler<T>) => {
  return socket.off(event, handler);
};

export const subscribe = (key: string) => {
  socket.emit('subscribe', key);
};

export const unsubscribe = (key: string) => {
  socket.emit('unsubscribe', key);
};
import { useState } from 'react';
import { ProjectStatus } from '@enums';
import { Card, CardContent, Divider, GroupCardHeader } from '@presentation/Main';
import { GroupTableHeaderButton } from '@presentation/GroupTable';
import { getLocationFor } from '@utils';
import { ProjectStatusFilter, ProjectsEmptyState } from 'components/GroupProject';
import { useProjectsTableItems } from 'components/GroupProject/hooks/useProjectsTableItems';
import ProjectsTable from './ProjectsTable';
import styles from './style/ProjectsPanel.css';

export const ProjectsPanel = () => {
  const [statusFilter, setStatusFilter] = useState<ProjectStatus>(ProjectStatus.Active);
  const data = useProjectsTableItems({
    status: statusFilter,
  });

  return (
    <Card className={styles.card}>
      <GroupCardHeader className={styles.header}>
        <div className={styles.left}>
          <div>Projects</div>
          <ProjectStatusFilter
            className={styles.filter}
            value={statusFilter}
            onChange={setStatusFilter} />
        </div>
        <GroupTableHeaderButton
          title="Create New"
          to={getLocationFor.project.create()} />
      </GroupCardHeader>
      {!data.length && <Divider />}
      <CardContent className={styles.content} fluid>
        <div className={styles.content}>
          {!!data.length &&
            <ProjectsTable
              data={data} />}
          {!data.length &&
            <ProjectsEmptyState status={statusFilter} />}
        </div>
      </CardContent>
    </Card>
  );
};

export default ProjectsPanel;
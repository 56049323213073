import { useCallback } from 'react';
import { FileUploadStatus } from '@enums';
import { getFileExtension, useThrottleCallback, useBeforeUnload } from '@utils';
import { FileUploadsProgress } from 'components/FileUploadsProgress';
import { AbortFileUploadContext, ToggleUploadDisplayContext, UploadFilesContext, UploadFilesParams } from './Context';
import FileUploadEffectsContainer from './EffectsContainer';
import FileUploadsState from './UploadState';
import { useFileUploadEffects, useFileUploadsState, useUploadFile, useAbortUpload, useShowUploadDisplay, useUploadInProgress, useExistingFilesCheck } from './hooks';
import { validateFile } from './utils';

type Props = {
  children: React.ReactNode;
};

const MaxConcurrentFileUploads = 3;

const WorkspaceFileUploadContainer = (props: Props) => {
  const [state, dispatch] = useFileUploadsState();
  const [showDisplay, toggleDisplay] = useShowUploadDisplay();

  const effects = useFileUploadEffects();

  const inProgress = useUploadInProgress();

  useBeforeUnload({ block: inProgress });

  const uploadFile = useUploadFile();
  const throttledUploadFile = useThrottleCallback(uploadFile, MaxConcurrentFileUploads);

  const abortUpload = useAbortUpload();

  const abortUploads = useCallback(() => {
    return Promise.all(
      state.valid
      .filter(f => [FileUploadStatus.InProgress, FileUploadStatus.Enqueued].includes(f.status))
      .map(m => abortUpload(m.id))
    );
  }, [
    abortUpload,
    state.valid,
  ]);

  const uploadFiles = useCallback((data: UploadFilesParams) => {
    data.files.forEach(async file => {
      const valid = await validateFile(file);

      const fileData = {
        extension: getFileExtension(file.name),
        lastModified: file.lastModified,
        name: data.names?.[file.name] || file.name,
        size: file.size,
        type: file.type,
      };

      if (!valid.success) {
        return dispatch({
          type: 'add-invalid-file',
          file: fileData,
          reason: valid.message,
        });
      }

      try {
        const { fileUpload } = await effects.queue({
          file: fileData,
          fileId: data.ids?.[file.name],
          parentObjectId: data.parentObjectId,
          workspaceId: data.workspaceId,
        });

        return throttledUploadFile(file, fileUpload);
      } catch (e) {
        dispatch({
          type: 'add-invalid-file',
          file: fileData,
          reason: `Couldn't upload file`,
        });
      }
    });
  }, [
    dispatch,
    effects,
    throttledUploadFile,
  ]);

  const [handleUpload, ExistingFilesModal] = useExistingFilesCheck({
    onUpload: uploadFiles,
  });

  return (
    <>
      <UploadFilesContext.Provider value={handleUpload}>
        <AbortFileUploadContext.Provider value={abortUpload}>
          <ToggleUploadDisplayContext.Provider value={toggleDisplay}>
            {props.children}
            {showDisplay && <FileUploadsProgress />}
          </ToggleUploadDisplayContext.Provider>
        </AbortFileUploadContext.Provider>
      </UploadFilesContext.Provider>
      <ExistingFilesModal />
    </>
  );
};

const UploadWrapper = (props: Props) => {
  return (
    <FileUploadsState>
      <FileUploadEffectsContainer>
        <WorkspaceFileUploadContainer>
          {props.children}
        </WorkspaceFileUploadContainer>
      </FileUploadEffectsContainer>
    </FileUploadsState>
  );
};

export {
  UploadWrapper as WorkspaceFileUploadContainer,
};
export default UploadWrapper;
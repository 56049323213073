import { forwardRef } from 'react';
import { VideoPlayerProps } from './interfaces';

type Props =
  VideoPlayerProps;

const VideoPlayer = ({
  children = (<p>Your browser does not support the <code>video</code> tag.</p>),
  ...props
}: Props, ref: React.Ref<HTMLVideoElement>) => {
  return (
    <video
      ref={ref}
      controls={props.controls}
      src={props.src}>
      <source
        src={props.src}
        type="video/mp4" />
      {children}
    </video>
  );
};

const VideoPlayerWithRef = forwardRef<HTMLVideoElement, Props>(VideoPlayer);

export { VideoPlayerWithRef as VideoPlayer };
export default VideoPlayerWithRef;
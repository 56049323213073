import { cx } from '@utils';
import styles from './style.css';

type Props = {
  autoComplete?: string;
  labelClassName?: string;
  className?: string;
  error?: string;
  name: string;
  label: string;
  placeholder?: string;
  value: string | number | string[];
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function TextInput(props: Props) {
  return (
    <div className={cx(props.className)}>
      <label className={cx(styles.label, { [styles.labelError]: !!props.error }, props.labelClassName)} htmlFor={props.name}>
        {props.label} {props.required && <span className={styles.star}>*</span>}
      </label>
      <input
        autoComplete={props.autoComplete}
        name={props.name}
        className={styles.input}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        title={props.required ? `${props.label} is required.` : `${props.label} is optional.`} />
    </div>
  );
}

export { TextInput };
import { memo, forwardRef, Ref } from 'react';
import { cx } from '@utils';
import styles from './style.css';

export type PopperMenuProps = {
  className?: string;
} & ChildrenProps;

export const PopperMenu = forwardRef((props: PopperMenuProps, ref: Ref<HTMLDivElement>) => {
  return (
    <div
    className={cx(styles.menu, props.className)}
    ref={ref}>
      {props.children}
    </div>
  );
});

export type PopperMenuItemProps = {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => unknown;
} & ChildrenProps;

export const PopperMenuItem = memo((props: PopperMenuItemProps) => {
  return (
    <div
      className={cx(styles.item, props.className)}
      onClick={props.onClick}>
      {props.children}
    </div>
  );
});

export default PopperMenu;
import * as types from '@store/action-types';
import { Files } from '@store/interfaces';

export const fileCreated = (data: Files.FileCreated.State): Files.FileCreated.Action => {
  return {
    capabilities: data.capabilities,
    file: data.file,
    object: data.object,
    type: types.FILE_CREATED,
  };
};

export const fileDeleted = (data: Files.FileDeleted.State): Files.FileDeleted.Action => {
  return {
    fileId: data.fileId,
    objectId: data.objectId,
    type: types.FILE_DELETED,
  };
};

export const fileVersionCreated = (data: Files.FileVersionCreated.State): Files.FileVersionCreated.Action => {
  return {
    file: data.file,
    object: data.object,
    type: types.FILE_VERSION_CREATED,
  };
};

export const fileRenamed = (data: Files.FileRenamed.State): Files.FileRenamed.Action => {
  return {
    file: data.file,
    object: data.object,
    type: types.FILE_RENAMED,
  };
};
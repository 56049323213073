import { useCallback, useMemo } from 'react';
import * as surveyBuilder from '@containers/SurveyBuilder/utils';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { QuestionOptionsBuilderContainer } from '@containers/SurveyBuilder.Question/OptionsBuilderContainer';
import { QuestionRowsBuilderContainer } from '@containers/SurveyBuilder.Question/RowsBuilderContainer';
import { QuestionSettingTitle } from '@presentation/SurveyBuilder';
import { getSelectionSettingOptions, assertValidSelectionSettings } from '@containers/SurveyBuilder/utils/question.matrix-multiselect';
import { MatrixMultiselectQuestion } from '@/types';
import { arr } from '@utils';
import { CheckboxLabel } from 'components/Checkbox';
import QuestionColumnsBuilder from './ColumnsBuilder';
import { SelectionSettings } from './Multiselect.Settings.Selections';
import { ResponsePiping } from './ResponsePiping';
import styles from './style/MatrixGrid.Question.css';

type Props = {
  item: MatrixMultiselectQuestion.Question;
};

export const MatrixMultiselectBuilder = ({ item }: Props) => {
  const [_, dispatch] = useSurveyBuilderState();

  const toggleNotApplicable = useCallback((_, checked: boolean) => {
    dispatch({
      type: 'toggle-not-applicable',
      questionIdentifier: item.base.identifier,
      value: checked,
    });
  }, [
    dispatch,
    item.base.identifier,
  ]);

  const selectItems = useMemo(() => {
    const [min, max] = getSelectionSettingOptions(item);

    return arr.range(max - min + 1, min).reduce((acc, x) => ({
      ...acc,
      [x]: x.toString(),
    }), {
      [0]: '',
    } as { [k: string]: string; });
  }, [
    item,
  ]);

  const settingsValidation = useMemo(() => {
    return assertValidSelectionSettings(item);
  }, [item]);

  return (
    <>
      <QuestionSettingTitle title="Rows">
        <ResponsePiping type="rows" />
      </QuestionSettingTitle>
      <QuestionRowsBuilderContainer
        questionIdentifier={item.base.identifier}
        rows={item.matrixRows}
        className={styles.rows}
        maxRows={surveyBuilder.matrixMultiselect.MaxRows}
        minRows={surveyBuilder.matrixMultiselect.MinRows} />

      <QuestionSettingTitle title="Columns">
        <ResponsePiping />
      </QuestionSettingTitle>
      <QuestionOptionsBuilderContainer
        className={styles.options}
        Component={QuestionColumnsBuilder}
        item={item}
        maxOptions={surveyBuilder.matrixMultiselect.MaxColumns}
        minOptions={surveyBuilder.matrixMultiselect.MinColumns} />
      <CheckboxLabel
        className={styles.checkbox}
        checked={item.settings.includeNotApplicable}
        label="Include N/A as choice"
        onChange={toggleNotApplicable} />
      <SelectionSettings
        item={item}
        selectItems={selectItems}
        validation={settingsValidation} />
    </>
  );
};

export default MatrixMultiselectBuilder;
import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import * as api from '@api';
import { Projects } from '@api/interfaces';

type Data = Projects.GetSurveyMetrics.Response;
type QueryKey =[string, number];

export const useProjectSurveyMetrics = (
  { projectId }: IProjectId,
  options?: UseQueryOptions<Data, AxiosError, Data, QueryKey>
) => {

  return useQuery(['fetch-project-survey-metrics', projectId], ({ queryKey }) => {
    const projectId = queryKey[1] as number;

    return api.projects.surveys.metrics.get({
      projectId,
    });
  }, options);
};
import mixpanel from 'mixpanel-browser';
import { Refer } from './interfaces';

export function inviteYourPeersModal(data: Refer.InviteYourPeersModal) {
  mixpanel.track(`refer:invite-peers:${data.state}`);
}

export function inviteYourPeersCopyLink() {
  mixpanel.track(`refer:invite-peers:copy-link`);
}

export function inviteYourPeersSent(data: Refer.InviteYourPeersSent) {
  mixpanel.track(`refer:invite-peers:sent`, data);
}

const invite = {
  copyLink: inviteYourPeersCopyLink,
  modalToggle: inviteYourPeersModal,
  sent: inviteYourPeersSent,
};

export {
  invite,
};
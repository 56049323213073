import { Cell } from 'react-table';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { ProjectActionsState } from '@containers/GroupProject/ProjectActionsContainer';
import { getLocationFor } from '@utils';
import { MoreHorizontalAnchorSmall } from '@presentation/Anchor';
import { ProjectActionsContextMenuPopper, ProjectPopoverAnchor, ProjectProgressBar, ProjectsTableItem } from 'components/GroupProject';
import { ProjectIcon } from 'components/icons';
import styles from './style/ProjectsTable.css';

function NameCell({ row: { original: data } }: Cell<ProjectsTableItem>) {
  const to = getLocationFor.project.root(data);

  return (
    <div className={styles.name}>
      <ProjectIcon
        className={styles.icon}
        projectType={data.projectType} />
      <ProjectPopoverAnchor className={styles.anchor} item={data}>
        <Link className={styles.link} to={to}>
          <div className={styles.trunc}>{data.name}</div>
        </Link>
      </ProjectPopoverAnchor>
    </div>
  );

}

function OwnerCell({ row: { original: data } }: Cell<ProjectsTableItem>) {
  return data.owner;
}

function CreatedOnCell({ row: { original: data } }: Cell<ProjectsTableItem>) {
  return format(data.createdOn, 'MM/dd/yyyy');
}

function ProgressCell({ row: { original: data } }: Cell<ProjectsTableItem>) {
  return (
    <div className={styles.progress}>
      {data.metrics &&
        <ProjectProgressBar
          metrics={data.metrics}
          project={data} />}
    </div>
  );
}

function ActionsCell({ row: { original: data } }: Cell<ProjectsTableItem>) {

  return (
    <ProjectActionsState projectId={data.id}>
      <ProjectActionsContextMenuPopper
        Anchor={MoreHorizontalAnchorSmall} />
    </ProjectActionsState>
  );
}

export const Cells = {
  Actions: ActionsCell,
  CreatedOn: CreatedOnCell,
  Name: NameCell,
  Owner: OwnerCell,
  Progress: ProgressCell,
};
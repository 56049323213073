import styles from '$website/screens/Main/style/Products.css';
import { cx } from '@utils';
import { Tooltip } from '@presentation';
import { ProductCardProps, ProductHelpTextProps, DesktopCardProps } from './interfaces';

const NameTag = (props: ProductCardProps) => (
  <div className={styles.nameTag}>
    <props.item.Icon className={styles.nameTagIcon} />
    <div className={styles.nameTagName}>{props.item.title}</div>
  </div>
);

const ProductHelpText = (props: ProductHelpTextProps) => (
  <div className={cx(styles.helpContainer, props.className)}>
    <Tooltip
      placement='right'
      title={props.description}
      tooltipstyles={{
        backgroundColor: 'var(--pri-02)',
        fontSize: 16,
        padding: 15,
        width: 210,
      }}>
      <div className={styles.help}>
        <div className={styles.helpInner}>
          {props.ordinal}
        </div>
      </div>
    </Tooltip>
  </div>
);

export const DesktopCard = (props: DesktopCardProps) => {
  const renderHelpItems = () => {
    return props.item.helpItems.map((item, i) => (
      <ProductHelpText
        key={i}
        className={item.className}
        description={item.description}
        ordinal={i + 1} />
    ));
  };

  const className = cx({
    [styles.transparent]: !props.visible,
  });

  return (
    <>
      <img
        className={cx(styles.productImg, className)}
        alt={`Vancery ${props.item.title}`}
        src={props.item.img} />
      {props.visible && renderHelpItems()}
    </>
  );
};

export const TabletCard = (props: ProductCardProps) => (
  <div className={styles.tabletCarouselCard}>
    <NameTag item={props.item} />
    <img
      draggable={false}
      className={styles.productImg}
      alt={`Vancery ${props.item.title}`}
      src={props.item.img} />
  </div>
);

export const MobileCard = (props: ProductCardProps) => {
  return (
    <div className={styles.mobileCarouselCard}>
      <NameTag item={props.item} />
      <img
        draggable={false}
        className={styles.productImgMobile}
        alt={`Vancery ${props.item.title} Mobile`}
        src={props.item.mobileImg} />
    </div>
  );
};

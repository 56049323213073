import { useContext } from 'react';
import { BrandThemingContext } from '@containers/Branding/Context';
import { useUpdateSurveyPaletteColorMutation } from '@containers/Branding/hooks';
import { OptimisticIndicator } from '@presentation';
import { ColorInput } from 'components/ColorInput';
import styles from './style/Settings.Branding.Survey.Palette.css';

type Props = unknown;

export const Palette = (props: Props) => {
  const { query } = useContext(BrandThemingContext);
  const mutation = useUpdateSurveyPaletteColorMutation();

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.label}>
          <h2 className={styles.header}>Survey Button Color</h2>
          <OptimisticIndicator
            classes={{ root: styles.indicator }}
            isError={mutation.isError}
            isLoading={mutation.isLoading}
            isSuccess={mutation.isSuccess} />
        </div>
        <div className={styles.main}>
          <ColorInput
            initialValue={query.data.survey.palette.primary.main}
            onChangeValidHex={value => mutation.mutate({
              color: 'primary',
              variant: 'main',
              value,
            })} />
        </div>
      </div>
    </div>
  );
};

Palette.displayName = 'Settings.Branding.Survey.Palette';
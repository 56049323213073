import http from '@services/http';
import {
  DownloadConsultantGroupDocumentRequest,
  DownloadConsultantProjectDocumentRequest,
} from '@services/api/interfaces';
import {
  GetQuestions,
  UpdateSurveyResponseAnswerRequest,
  UpdateSurveyResponseAnswerResponse,
} from './interfaces';

export * from './admin-old.js';

const downloadConsultantGroupDocument = (data: DownloadConsultantGroupDocumentRequest): Promise<Blob> => {
  return http.get(`/admin/documents/consultants/${data.userId}/groups/${data.groupId}`, { responseType: 'blob' });
};

const downloadConsultantProjectDocument = (data: DownloadConsultantProjectDocumentRequest): Promise<Blob> => {
  return http.get(`/admin/documents/consultants/${data.userId}/projects/${data.projectId}`, { responseType: 'blob' });
};

const updateSurveyResponseAnswer = (data: UpdateSurveyResponseAnswerRequest): Promise<UpdateSurveyResponseAnswerResponse> => {
  const { questionId, responseId, answer } = data;
  return http.patch(`/admin/surveys/responses/${data.responseId}/answers/${questionId}`, answer);
};

export const documents = {
  group: {
    download: downloadConsultantGroupDocument,
  },
  project: {
    download: downloadConsultantProjectDocument,
  },
};

const getQuestions = () => {
  return http.get<GetQuestions.Response>(`/admin/questions`)
  .then(data => ({
    ...data,
    questions: data.questions.map(q => ({
      ...q,
      createdOn: q.createdOn ? new Date(q.createdOn) : null,
    })),
  }));
};

export const questions = {
  getDashboard: getQuestions,
};

export const surveys = {
  updateResponseAnswer: updateSurveyResponseAnswer,
};
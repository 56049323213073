import http from '@services/http';
import { Surveys } from './interfaces';

const toggleSurveyResponseAnswerVisibility = (data: Surveys.ToggleAnswerVisibility.Request): Promise<Surveys.ToggleAnswerVisibility.Response> => {
  const { responseId, questionId, ...rest } = data;
  return http.post(`/surveys/responses/${data.responseId}/questions/${data.questionId}/visibility`, rest);
};

const goBack = (data: Surveys.Response.GoBack.Request): Promise<Surveys.Response.GoBack.Response> => {
  return http.patch(`/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'back',
    body: data,
  });
};

const submitAnswer = (data: Surveys.Response.SubmitAnswer.Request): Promise<Surveys.Response.SubmitAnswer.Response> => {
  return http.patch(`/survey-versions/${data.surveyVersionId}/responses`, {
    type: 'submit-answer',
    body: data,
  });
};

export const response = {
  goBack,
  submitAnswer,
};

export const responses = {
  toggleAnswerVisibility: toggleSurveyResponseAnswerVisibility,
};
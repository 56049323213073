import Alert from 'components/Modal/Alert';
import { DeleteSectionAlertProps } from './interfaces';

export const DeleteSectionAlert = (props: DeleteSectionAlertProps) => (
  <Alert
    confirmText='Delete'
    message='Are you sure you want to delete this section?'
    {...props} />
);

export default DeleteSectionAlert;
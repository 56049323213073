import * as enums from '@enums';
import * as API from '@api/interfaces';
import { Group, PreCallRecording, Workspace, AdminRecruitment } from '@/types';
import { BalanceAccountHistoryFundingItem, BalanceAccountHistoryItem, GroupUser } from '$admin/interfaces/group';

type GroupAccountData<T = Date> = {
  billing:  Partial<Group.Billing<T>>;
  features: Partial<Group.Features>;
  settings: Partial<Group.Settings>;
} & Pick<Group<T>,
    | 'blacklistedCompanies'
    | 'id'
    | 'name'>;

export declare namespace GetAccounts {
  export type Request = undefined;
  export type Response =
    Pick<Group, 'id' | 'name' | 'typeId' | 'createdOn' | 'features'>[];
}

export declare namespace GetAccountsDashboard {
  export type AccountItem = {
    id: number;
    name: string;
    template?: {
      id: string;
      title: string;
    };
    usersCount: number;
    projectsCount: number;
    balanceAmount?: number;
  };

  export type Request = undefined;
  export type Response = {
    items: AccountItem[];
  };
}

export declare namespace ExportData {
  export type Request = IGroupId;
  export type Response = {
    key: string;
  };
}

export declare namespace FetchAccount {
  export type Request = IGroupId;

  export type Response<T = Date> =
    & GroupAccountData<T>
    & Pick<Group<T>, 'createdOn'>;
}

export declare namespace FetchComplianceSurvey {
  export type Request = IGroupId;

  export type Response = API.Groups.GetComplianceSurveyResponse;
}

export declare namespace FetchPostCallAnalustQuestions {
  export type Request = IGroupId;

  export type Response = API.Groups.GetPostCallAnalystQuestionsResponse;
}

export declare namespace FetchAnaysts {

  export type Request = {
    idsToExclude: number[];
    input: string;
  } & IGroupId;

  export type Response = {
    id: number;
    email: string;
    name: string;
  }[];

}

export declare namespace UpdateAccount {
  export type Request = Omit<GroupAccountData, 'createdOn'>;

  export type Response<T = Date> =
    & GroupAccountData<T>
    & Pick<Group<T>, 'createdOn'>;
}

export declare namespace SavePostCallAnaylstQuestions {
  export type Request =
    & IGroupId
    & API.Groups.SavePostCallAnalystQuestionsRequest;

  export type Response = API.Groups.SavePostCallAnalystQuestionsResponse;
}

export declare namespace SaveComplianceSurvey {
  export type Request =
    & IGroupId
    & API.Groups.SaveComplianceSurveyRequest;

  export type Response = API.Groups.SaveComplianceSurveyResponse;
}

export declare namespace UpdateSettings {
  export type Request =
    & IGroupId
    & API.Groups.UpdateGroupSettingsRequest;

  export type Response = Group.Settings | void;
}

export declare namespace GetWorkspaces {
  export type Request = IGroupId;
  export type Response = {
    workspaces: Workspace<string>[];
  };
}

export declare namespace GetPreCallRecording {
  export type Request = IGroupId;
  export type Response = PreCallRecording;
}

export declare namespace SavePreCallRecording {
  export type Request =
    PreCallRecording &
    IGroupId;
  export type Response = PreCallRecording;
}

export declare namespace GetPreCallRecordingPreviewUrl {
  export type Request = IGroupId;
  export type Response = {
    url: string;
  };
}

export declare namespace GeneratePreCallRecordingPreview {
  export type Request = {
    text: string;
  } & IGroupId;
  export type Response = {
    tmpS3Key: string;
    url: string;
  };
}

export namespace GetGroupUsers {
  export type Request = {
    excluded?: number[];
    limit?: number;
    roleIds?: enums.Role[];
    value?: string;
  } & IGroupId;
  export type Response = {
    items: GroupUser<string>[];
    teams: AdminRecruitment.TeamInfo[];
    teamMap: Record<number, AdminRecruitment.TeamInfo>;
  };
}

export namespace GetBalanceAccountOverview {
  export type Request = IGroupId;
  export type Response<D = Date> = {
    balanceAmount: number;
    items: BalanceAccountHistoryItem<D>[];
  };
}

export namespace AddBalanceFunding {
  export type Request = {
    amount: number;
    notes: string;
    invoiceId?: number;
  } & IGroupId;
  export type Response<D = Date> = {
    item: BalanceAccountHistoryFundingItem<D>;
  };
}

export namespace RemoveBalanceFunding {
  export type Request = {
    id: number;
  } & IGroupId;
  export type Response = undefined;
}
import { nullableDate } from '@utils';
import { Members } from '$admin/interfaces/search';

export function transformMembers(items: Members.Results.Item<string>[]): Members.Results.Item[] {
  if (!items) return [];
  return items.map(transformMember);
}

export function transformMember(item: Members.Results.Item<string>): Members.Results.Item {
  if (!item) return null;
  return {
    ...item,
    current: transformMemberEmployment(item.current),
    employments: transformMembersEmployments(item.employments),
  };
}

export function transformMembersEmployments(items: Members.Results.ItemEmployment<string>[]): Members.Results.ItemEmployment[] {
  if (!items) return [];
  return items.map(transformMemberEmployment);
}

export function transformMemberEmployment(item: Members.Results.ItemEmployment<string>): Members.Results.ItemEmployment {
  if (!item) return null;
  return {
    ...item,
    start: nullableDate(item.start),
    end: nullableDate(item.end),
  };
}
import { forwardRef, useCallback, useState } from 'react';
import { MessageCircle, Trash2 } from 'react-feather';
import {
  useAddNewComment,
  useHelpers,
  useUpdateHighlightColor,
} from './hooks';
import { highlightColors } from './utils';
import styles from './style/Tooltip.Highlight.css';

type Props = {
  arrow?: {
    className: string;
    ref: React.Ref<HTMLDivElement>;
  };
  onRemove: () => unknown;
};

export const HighlightTooltip = forwardRef<HTMLDivElement, Props>(({ arrow, onRemove }, ref) => {

  const [hoveredColor, setHoveredColor] = useState<string>(null);

  const { getFocusedHighlight } = useHelpers();
  const highlight = getFocusedHighlight();

  const {
    mutateAsync: saveColor,
  } = useUpdateHighlightColor();

  const addComment = useAddNewComment(highlight);

  const handleColorClick = useCallback((color: string) => () => {

    return saveColor({
      highlight: {
        ...highlight,
        color,
      },
      previousColor: highlight.color,
    });
  }, [
    highlight,
    saveColor,
  ]);

  return (
    <div
      ref={ref}
      className={styles.tooltip}>
      {arrow &&
        <div
          ref={arrow.ref}
          className={arrow.className} />}
      <div className={styles.wrap}>
        <div className={styles.colors}>
          {highlightColors.map(color => (
            <div
              key={color.highlight}
              onMouseEnter={() => setHoveredColor(color.highlight)}
              onMouseLeave={() => setHoveredColor(null)}
              onClick={handleColorClick(color.highlight)}
              style={{
                backgroundColor: hoveredColor === color.highlight
                  ? color.hover
                  : color.menu,
              }}
              className={styles.color} />
          ))}
        </div>
        <div className={styles.divider} />
        <MessageCircle
          className={styles.commentIcon}
          onClick={addComment} />
        {highlight.dbId &&
          <Trash2
            className={styles.trashIcon}
            onClick={onRemove} />}
      </div>
    </div>
  );
});

export default HighlightTooltip;
import { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHasGroupFeature } from '@store/hooks';
import { CellInteractionCreatedOn, CellProjectName, CellOwner, CellInteractionTags, CellInteractionName, CellInteractionProject, CellInteractionParticipants } from '../Cells';

export default function useInteractionsTableColumns() {
  const isDesktop = useMediaQuery('(min-width:900px)');
  const hasTagging = useHasGroupFeature('userTagging');

  return useMemo(() => [
    {
      accessor: 'name',
      id: 'name',
      Header: 'Name',
      Cell: CellInteractionName,
      minWidth: 150,
      maxWidth: 2,
    },
    isDesktop ? {
      accessor: 'participants',
      id: 'attendees',
      Header: 'Attendees',
      Cell: CellInteractionParticipants,
      minWidth: 175,
      maxWidth: 1,
    } : null,
    isDesktop ? {
      accessor: 'project',
      id: 'project',
      Header: 'Project',
      Cell: CellInteractionProject,
      minWidth: 100,
      maxWidth: 1,
    } : null,
    isDesktop && hasTagging ? {
      accessor: 'tags',
      id: 'tags',
      Header: 'Tags',
      Cell: CellInteractionTags,
      minWidth: 175,
      maxWidth: 0,
    } : null,
    isDesktop ? {
      accessor: 'owner',
      id: 'owner',
      Header: 'Owner',
      Cell: CellOwner,
      minWidth: 100,
      maxWidth: 0,
    } : null,
    isDesktop ? {
      accessor: 'createdOn',
      id: 'createdOn',
      Header: 'Created On',
      Cell: CellInteractionCreatedOn,
      minWidth: 175,
      maxWidth: 0,
    } : undefined,
  ].filter(Boolean), [isDesktop, hasTagging]);
}

export { useInteractionsTableColumns };
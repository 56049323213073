import { useCallback, useMemo } from 'react';
import {
  useMatrixQuestionLogic,
} from '@containers/SurveyBuilder.Logic/Context';
import {
  useQuestionLogicBuilderState,
} from '@containers/SurveyBuilder.Logic/hooks';
import { FormButtons } from '@presentation/FormButtons';
import { QuestionText } from '@presentation/Survey';
import { MatrixGridQuestion } from '@/types';
import { QuestionBaseLogicBuilder } from './BaseLogic.Builder';
import { MatrixRowLogicBuilder } from './Matrix.RowBuilder';
import styles from './style/MatrixLogicBuilder.css';
import baseStyles from './style/QuestionLogicBuilder.css';

export const MatrixLogicBuilder = () => {

  const { item, onCancel, onSubmit } = useQuestionLogicBuilderState();
  const [logic] = useMatrixQuestionLogic();
  const question = item as MatrixGridQuestion.Question;

  const disabled = useMemo(() => {
    return logic.response.some(s => {
      return !s.condition.value.option.identifier ||
        !s.action.type;
    });
  }, [logic.response]);

  const handleSubmit = useCallback(() => {
    onSubmit(logic);
  }, [
    logic,
    onSubmit,
  ]);

  const renderResponseLogic = useCallback(() => {
    if (logic.base.action.type) return null;

    return (
      <div className={styles.root}>
        {
          question.matrixRows.map((row, i) => (
            <MatrixRowLogicBuilder
              key={row.base.identifier}
              row={row} />
          ))
        }
      </div>
    );
  }, [
    logic,
    question.matrixRows,
  ]);

  return (
    <div className={baseStyles.root}>
      <QuestionText
        className={baseStyles.questionText}
        textClassName={baseStyles.text}
        item={item} />
      <div className={baseStyles.logic}>
        <QuestionBaseLogicBuilder />
        {renderResponseLogic()}
      </div>
      <FormButtons
        className={baseStyles.formBtns}
        archivable={false}
        disabled={disabled}
        deletable={false}
        onSubmit={handleSubmit}
        onCancel={onCancel} />
    </div>
  );
};

export default MatrixLogicBuilder;
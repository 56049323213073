import { memo } from 'react';
import { format } from 'date-fns/fp';
import { useJoinConference } from '@containers/Scheduler/hooks';
import { Call } from '@/types';
import { Button } from 'components/Button';
import styles from './style/AgendaItem.css';

type Props = {
  description: string;
  item: Pick<Call, 'id' | 'timeStart' | 'timeEnd'>;
  title: string;
} & ChildrenProps;

const BaseCallAgendaItem = memo(({
  children,
  description,
  item,
  title,
}: Props) => {

  const time = format('h:mm a');

  const start = time(item.timeStart);
  const end = time(item.timeEnd);

  const [canJoin, joinConferenceProps] = useJoinConference(item.id);

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.main}>
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
          <div className={styles.time}>{`${start} - ${end}`}</div>
        </div>
        <div className={styles.menu}>
          {children}
        </div>
      </div>
      <div className={styles.footer}>
        {canJoin &&
          <Button
            {...joinConferenceProps}
            className={styles.join}
            color="primary"
            variant="pill">
            Join Call
          </Button>
        }
      </div>
    </div>
  );
});

export { BaseCallAgendaItem };
export default BaseCallAgendaItem;
import { cx } from '@utils';
import { ChipProps } from './interfaces/Chip';
import { ChipBody } from './ChipBody';
import { ChipContext } from './Chip.Context';
import styles from './style/Chip.css';

export function Chip({ clickable, disabled, readonly, ...props }: ChipProps) {
  const styleMap = {
    [styles.clickable]: clickable,
    [styles.disabled]: disabled,
    [styles.readonly]: readonly,
  };

  const classes
    = cx(styles.root, props.className, styleMap, Colors[props.color]);

  const value = {
    clickable,
    disabled,
    readonly,
  };

  return (
    <button
      className={classes}
      disabled={disabled}
      onClick={!disabled ? props.onClick : undefined}>
      <ChipContext.Provider value={value}>
        {props.children}
      </ChipContext.Provider>
    </button>
  );
}

const Colors = {
  basic: styles.basic,
  selected: styles.selected,
  recommended: styles.recommended,
};

Chip.defaultProps = {
  color: 'basic',
} as Partial<ChipProps>;

Chip.Body = ChipBody;
import { SurveyQuestionType } from '@enums/Survey';
import { utils as enumUtils } from '@enums/utils';
import { MatrixGridQuestion } from '@/types/survey.question';
import { SurveyRichText } from '@/types/survey.rich-text';
import { SurveyVersion, SurveyTemplate } from '@/types/survey';

export function parseRichQuestionText(value: SurveyRichText.RichTextValue, data: SurveyVersion) {
  return value.content.map(p => (p.content || [])
    .map(t => {

      switch (t.type) {
        case SurveyRichText.NodeType.Text:
          return t.text;

        case SurveyRichText.NodeType.PipedResponse:
          return getResponsePipeNodeText(t.attrs, data);

        case SurveyRichText.NodeType.TemplateCategory:
          return getCategoryNodeText(t, data);

        case SurveyRichText.NodeType.TemplateTarget:
          return getTargetNodeText(t, data);

        case SurveyRichText.NodeType.TemplateCompetitor:
          return getCompetitorNodeText(t, data);

        default:
          return '';
      }

    }).join('')).join('\n');
}

export function getCategoryNodeText(node: SurveyRichText.CategoryNode, data: SurveyVersion) {
  return (data.template.data as SurveyTemplate.ICategory).category;
}

export function getCompetitorNodeText(node: SurveyRichText.CompetitorNode, data: Pick<SurveyVersion, 'template'>) {
  return (data.template.data as SurveyTemplate.ICompetitors).competitors.find(f => f.id === node.attrs.id).value;
}

export function getTargetNodeText(node: SurveyRichText.TargetNode, data: Pick<SurveyVersion, 'template'>) {
  return (data.template.data as SurveyTemplate.ITarget).target.value;
}

export function getResponsePipeNodeText(attrs: SurveyRichText.PipedResponseNode['attrs'], data: SurveyVersion) {
  const question = data.questions.find(f => f.base.identifier === attrs.question.identifier);

  if (!question) return '[Invalid]';

  const valueText = getConditionValueText();

  const text = [
    `Q${question.ordinal}`,
    valueText,
    enumUtils.SurveyQuestionConditionType.getName(attrs.condition.type),
  ].filter(Boolean).join(`~`);

  return `[${text}]`;

  function getConditionValueText(): string {
    if (!attrs.condition.value) return null;

    switch (question.typeId) {

      case SurveyQuestionType.MatrixGrid: {
        const conditon = (attrs.condition.value as MatrixGridQuestion.Logic.Condition['value']);
        const option = question.options.find(f => f.base.identifier === conditon.option.identifier);
        return `A${option?.ordinal}`;
      }

      default:
        return null;
    }
  }
}
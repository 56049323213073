import { useEffect } from 'react';
import { getFullCalSubmitButtonElement } from 'components/Calendar/utils';

const useFullCalButtonState = (disabled: boolean) => {

  useEffect(() => {
    const btn = getFullCalSubmitButtonElement();

    if (btn) {
      btn.disabled = disabled;
    }

  }, [disabled]);
};

export { useFullCalButtonState };
export default useFullCalButtonState;
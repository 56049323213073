import { Fragment, useCallback, useState, useMemo } from 'react';
import PinIcon from '@mui/icons-material/FiberPin';
import PhoneIcon from '@mui/icons-material/Phone';
import { cx, phone, pin } from '@utils';
import { ConferenceDialInfo } from '@/types';
import AdditionalLabel from './AdditionalPhoneNumbers.Label';
import AdditionalModal from './AdditionalPhoneNumbers.Modal';
import styles from './style/PhoneInformation.css';

type Props = {
  className?: string;
} & ConferenceDialInfo;

export const PhoneInformation = (props: Props) => {
  return (
    <Fragment>
      <YourInformation {...props} />
      {props.guestPin && <GuestInformation  {...props} />}
    </Fragment>
  );
};

function YourInformation(props: Props) {
  const [modal, setModal] = useState<boolean>(false);

  const handleOpenModal = useCallback(() => setModal(true), []);
  const handleCloseModal = useCallback(() => setModal(false), []);

  const additional = useMemo(() => props.phoneNumbers.slice(1), [props.phoneNumbers]);

  const displayPhone =
    useMemo(() => `${phone.format(props.phoneNumbers[0].phone)} (${props.phoneNumbers[0].countryCode})`, [props.phoneNumbers]);

  const displayPin =
    useMemo(() => pin.format(props.pin), [props.pin]);

  return (
    <>
      <div className={cx(props.className)}>
        <div className={styles.header}>
          Your Dial Information
        </div>
        <div className={styles.phone}>
          <PhoneIcon />
          <div className={styles.details}>
            {displayPhone}
          </div>
        </div>
        <div className={styles.pin}>
          <PinIcon />
          <div className={styles.details}>
            {displayPin}
          </div>
        </div>
        {!!additional.length && (
          <div className={styles.additional}>
            <AdditionalLabel onClick={handleOpenModal} />
          </div>
        )}
      </div>
      <AdditionalModal
        pin={displayPin}
        phoneNumbers={additional}
        open={modal}
        onClose={handleCloseModal} />
    </>
  );
}

function GuestInformation(props: Props) {
  const [modal, setModal] = useState<boolean>(false);

  const handleOpenModal = useCallback(() => setModal(true), []);
  const handleCloseModal = useCallback(() => setModal(false), []);

  const additional = useMemo(() => props.phoneNumbers.slice(1), [props.phoneNumbers]);

  const displayPhone =
    useMemo(() => `${phone.format(props.phoneNumbers[0].phone)} (${props.phoneNumbers[0].countryCode})`, [props.phoneNumbers]);

  const displayPin =
    useMemo(() => pin.format(props.guestPin), [props.guestPin]);

  return (
    <Fragment>
      <div className={styles.guest}>
        <div className={styles.header}>
          Guest Dial Information
        </div>
        <div className={styles.phone}>
          <PhoneIcon />
          <div className={styles.details}>
            {displayPhone}
          </div>
        </div>
        <div className={styles.pin}>
          <PinIcon />
          <div className={styles.details}>
            {displayPin}
          </div>
        </div>
        {!!additional.length && (
          <div className={styles.additional}>
            <AdditionalLabel onClick={handleOpenModal} />
          </div>
        )}
      </div>
      <AdditionalModal
        pin={displayPin}
        phoneNumbers={additional}
        open={modal}
        onClose={handleCloseModal} />
    </Fragment>
  );
}
import { useCallback, useContext, useLayoutEffect, useMemo } from 'react';
import { FloatingWrapper as RemirrorFloatingWrapper, useActive, useChainedCommands, useCurrentSelection, useUpdateReason } from '@remirror/react';
import { X } from 'react-feather';
import { ButtonOutlined } from '@presentation';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { LinkFloatingToolbarContext, LinkShortcutContext } from './Context';
import styles from './style/Link.FloatingToolbar.css';

function useFloatingLinkState() {
  const chain = useChainedCommands();
  const { editing, link, setEditing } = useContext(LinkShortcutContext);
  const { href, getSelectedGroup, popper, positioner, setHref, setPopperOpen, setText, text } = useContext(LinkFloatingToolbarContext);
  const selection = useCurrentSelection();
  const active = useActive();

  const onRemove = useCallback(() => {
    return chain
      .removeLink()
      .focus()
      .run();
  }, [chain]);

  const updateReason = useUpdateReason();

  useLayoutEffect(() => {
    if (!editing) {
      return;
    }

    if (updateReason.doc || updateReason.selection) {
      setEditing(false);
    }
  }, [
    editing,
    setEditing,
    updateReason.doc,
    updateReason.selection,
  ]);

  const onUpdateHref = useCallback((text: string) => {
    setEditing(false);
    const range = link ?? undefined;
    const word = getSelectedGroup();
    const quickedit = selection.empty && active.link()
        ? { to: word.to, from: word.from }
        : range;

    if (href === '') {
      chain.removeLink();
    } else if (active.link()) {

      chain.updateLink({ href, auto: false }, range);

    } else {
      chain
        .replaceText({
          content: text || href,
          keepSelection: true,
          range: quickedit,
        })
        .updateLink({ href, auto: false }, range);
    }

    chain.focus(range?.to ?? selection.to).run();

    if (popper) {
      setPopperOpen(false);
    }
  }, [
    active,
    chain,
    getSelectedGroup,
    href,
    link,
    popper,
    selection,
    setEditing,
    setPopperOpen,
  ]);

  const onCancel = useCallback(() => {
    setPopperOpen(false);
    setEditing(false);
  }, [
    setEditing,
    setPopperOpen,
  ]);

  const onClickEdit = useCallback(() => {
    if (selection.empty) {
      chain.selectLink();
    }

    setEditing(true);
  }, [
    chain,
    selection,
    setEditing,
  ]);

  return useMemo(
    () => ({
      onCancel,
      onClickEdit,
      editing,
      getSelectedText: getSelectedGroup,
      href,
      link,
      popper,
      positioner,
      onRemove,
      setHref,
      setPopperOpen,
      setText,
      onUpdateHref,
      text,
    }),
    [
      onCancel,
      onClickEdit,
      editing,
      getSelectedGroup,
      href,
      link,
      popper,
      positioner,
      onRemove,
      setHref,
      setPopperOpen,
      setText,
      onUpdateHref,
      text,
    ],
  );
}

export const FloatingLinkToolbar = () => {
  const { editing, popper, positioner, onClickEdit, onRemove, onUpdateHref, href, setHref, onCancel, text, setText, getSelectedText } = useFloatingLinkState();
  const { empty } = useCurrentSelection();
  const linktext = getSelectedText();

  return (
    <>
      <RemirrorFloatingWrapper
        // @ts-ignore
        positioner={positioner}
        placement="bottom"
        enabled={!editing && empty && popper}>
        <div className={styles.popper}>
          <div className={styles.box}>
            <div className={styles.row}>
              <div className={styles.display}>{linktext?.text}</div>
              <div className={styles.link}>
                <a
                  href={href}
                  rel="noreferrer"
                  target="_blank">
                  {href}
                </a>
              </div>
            </div>
            <div className={styles.row}>
              <div
                className={styles.close}
                onClick={() => onCancel()}>
                <X />
              </div>
            </div>
          </div>
          <div className={styles.btns}>
            <ButtonOutlined
              borderWidth="thin"
              className={styles.btn}
              color="silver"
              onClick={() => onClickEdit()}>
              Edit
            </ButtonOutlined>
            <ButtonOutlined
              borderWidth="thin"
              className={styles.btn}
              color="red"
              onClick={onRemove}>
              Remove
            </ButtonOutlined>
          </div>
        </div>
      </RemirrorFloatingWrapper>

      {editing &&
        <div className={styles.root}>
          <div className={styles.wrap}>
            <div className={styles.main}>
              <div className={styles.form}>

                <div className={styles.row}>
                  <div className={styles.label}>Text</div>
                  {popper
                    ? <div className={styles.input}>{linktext?.text}</div>
                    : <Input
                        defaultValue={linktext?.text}
                        className={styles.input}
                        onChange={e => setText(e.target.value)} />}
                </div>

                <div className={styles.row}>
                  <div className={styles.label}>Link</div>

                  <Input
                    className={styles.input}
                    autoFocus
                    onChange={e => setHref(e.target.value)}
                    value={href}
                    onKeyPress={({ key }: React.KeyboardEvent<HTMLInputElement>) => {
                      if (key === 'Enter') {
                        onUpdateHref(text);
                      }

                      if (key === 'Escape') {
                        onCancel();
                      }
                    }} />
                </div>

                <div className={styles.row}>
                  <div className={styles.btns}>
                    <Button
                      className={styles.btn}
                      color="destructive"
                      onClick={onCancel}
                      variant="brick">
                      Cancel
                    </Button>
                    <Button
                      className={styles.btn}
                      onClick={() => onUpdateHref(text)}
                      variant="brick">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  );
};

FloatingLinkToolbar.displayName = 'LinkExtensions.FloatingLinkToolbar';
import { useCallback, useMemo } from 'react';
import { useTranscriptFocusedCommentContext } from '../context';

export const useTranscriptCommentFocus = (identifier: string) => {
  const [focusedIdentifier, setFocusedIdentifier] = useTranscriptFocusedCommentContext();

  const isFocused = useMemo(() => {
    return focusedIdentifier === identifier;
  }, [focusedIdentifier, identifier]);

  const setFocused = useCallback((focused: boolean) => {
    if (focused) {
      setFocusedIdentifier(identifier);
    } else {
      setFocusedIdentifier(s => {
        return s === identifier ? null : s;
      });
    }
  }, [identifier, setFocusedIdentifier]);

  return [isFocused, setFocused] as const;
};
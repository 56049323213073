import { useEffect } from 'react';
import { useBannerContext } from '@containers/Banner';
import { useSelectUser } from '@containers/Store';
import VerifiedBanner from './Verified';

export const Banner = () => {
  const ctx = useBannerContext();
  const user = useSelectUser();

  useEffect(() => {
    if (ctx.verifiedVisible) {
      document.documentElement.style.setProperty('--top-offset', '110px');
    } else {
      const offset = window.getComputedStyle(document.documentElement).getPropertyValue('--navbar-height');
      document.documentElement.style.setProperty('--top-offset', offset);
    }
  }, [
    ctx.verifiedVisible,
  ]);

  if (!user.state.authenticated) {
    return null;
  }

  return (
    <>
      {ctx.verifiedVisible && <VerifiedBanner dismiss={ctx.dismissVerified} />}
    </>
  );
};

Banner.displayName = 'Banner';
import { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { path } from '@consts';
import { useUTMParameters } from '@containers/UTMTracking';
import { cx } from '@utils';
import * as api from '$website/api';
import { FormCard } from '$website/components/LeadForm';
import { useLeadForm, LeadFormContext } from '$website/containers/LeadForm';
import { Overview } from './Overview';
import { FormData } from './interfaces';
import styles from './style.css';
import hbsptStyles from './hubspotStyle.css';

type HubspotEvent = {
  type: string;
  eventName: string;
}
const H = memo(() => {
  const description = ``;
  useEffect(() => {

    const formTag = document.createElement('script');
    formTag.src = '//js.hsforms.net/forms/v2.js';
    formTag.charset = 'utf-8';
    formTag.type = 'text/javascript';
    formTag.onload = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      hbspt.forms.create({
        region: 'na1',
        portalId: '20714748',
        formId: '1e4d93de-579f-4e7e-a676-ac1143669fb2',
        target: '.hubspot-demo-target',
        submitButtonClass: 'submit-btn',
      });
    };

    window.addEventListener('message', event => {
      const eventData = event.data as HubspotEvent;
      if (eventData?.type === 'hsFormCallback') {
        if (eventData.eventName === 'onFormReady') {
          //console.log("Form Submitted!");
          document.querySelectorAll<HTMLInputElement>('.hubspot-demo-target input[placeholder]').forEach(e => {
            e.placeholder = e.placeholder.replace('*', '');
          });
        }
      }
    });

    document.head.appendChild(formTag);
  }, []);
  return (
    <Helmet
      title="Request a Demo">
      <link
        rel="canonical"
        href={`${process.env.FRONT_BASE_URL}${path.Website.Demo}`} />
      {/* <meta
        name='description'
        content={description} />
      <meta
        name='og:description'
        content={description} /> */}
    </Helmet>
  );
});

const Form = () => {
  return (
    <>
      <FormCard>
        <h2 className={styles.title}>Request a Demo</h2>
        <div className={cx('hubspot-demo-target', hbsptStyles.formContainer)} />
      </FormCard>
    </>
  );
};

const Demo = () => {
  const history = useHistory();

  const utm = useUTMParameters();

  const {
    mutateAsync: onSubmit,
  } = useMutation<unknown, unknown, Omit<FormData, 'utm'>>('demo-form', data => {
    return api.visitors.demo({
      ...data,
      utm,
    });
  }, {
    onSuccess: () => {
      history.push('/_/confirmation', {
        prevPath: location.pathname,
        subtitle: 'A member of our team will be in touch soon.',
      });
    },
  });

  const form = useLeadForm({ onSubmit });

  return (
    <>
      <H />
      <LeadFormContext.Provider value={form}>
        <div className={styles.root}>
          <div className={styles.wrap}>
            <div className={styles.main}>
              <Overview />
              <div className={styles.col}>
                <Form />
              </div>
            </div>
          </div>
        </div>
      </LeadFormContext.Provider>
    </>
  );
};

export { Demo };
export default Demo;
import { useMemo } from 'react';
import { FileUploadStatus } from '@enums';
import { useFileUploadsState } from './useFileUploadsState';

export const useUploadInProgress = () => {
  const [state] = useFileUploadsState();

  const inProgress = useMemo(() =>
    state.valid.some(s => [FileUploadStatus.InProgress, FileUploadStatus.Enqueued].includes(s.status))
  , [state.valid]
  );

  return inProgress;
};

export default useUploadInProgress;
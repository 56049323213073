import { useCallback } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import * as api from '@api';
import * as Branding from '@api/interfaces/groups.branding';
import * as QKey from '@consts/querykey';
import { useSelectGroup } from '@containers/Store';
import { useQueryDataAccessor } from './useQueryDataAccessor';

export const useUpdateSurveyCompletionTextMutation = (options: UpdateSurveyCompletionText.Options = {}) => {
  const helpers = useUpdateSurveyCompletionTextHelpers();
  const group = useSelectGroup();

  const mutation = useMutation([QKey.Groups.Branding.Survey.Pages.Completion.Text.Put, group.id], (data: UpdateSurveyCompletionText.Variables) => {
    return api.groups.branding.surveys.updateText({
      groupId: group.id,
      value: data.value,
    });
  }, {
    ...options,
    onMutate: variables => {
      helpers.onMutate(variables);
      options.onMutate?.(variables);
    },
  });

  return mutation;
};

const useUpdateSurveyCompletionTextHelpers = () => {
  const accessor = useQueryDataAccessor();

  const onMutate = useCallback((variables: UpdateSurveyCompletionText.Variables) => {
    accessor.setQueryData(data => {
      return {
        ...data,
        survey: {
          ...data.survey,
          page: {
            ...data.survey.page,
            completion: {
              ...data.survey.page.completion,
              text: variables.value,
            },
          },
        },
      };
    });
  }, [accessor]);

  return {
    onMutate,
  };
};

export declare namespace UpdateSurveyCompletionText {
  export type Variables = Omit<Branding.Surveys.UpdateText.Request, 'groupId'>;

  export type Options =
    UseMutationOptions<
      Branding.Surveys.UpdateText.Response,
      unknown,
      Variables>;
}
import { useCallback, useEffect, useState } from 'react';
import css from 'intl-tel-input/build/css/intlTelInput.css';
import { useUseableCSS } from '@utils';
import { TelInput, TelInputProps, TelInputState } from 'components/TelInput/TelInput';
import overrides from 'static/css/useable/intl-tel-input.css';

type Props = {
  children?:     React.ReactNode;
  className?:    string;
  defaultValue?: string;
  editing?:      boolean;
} & Pick<TelInputProps,
  | 'invalidClassName'
  | 'name'
  | 'onChange'
  | 'onChangeCountryCode'>

const PhoneInput = (props: Props) => {
  useUseableCSS(css, overrides);

  const computeDefaultMeta = useCallback(() => {
    return {
      empty: (props.defaultValue ?? '').length === 0,
      valid: true,
    };
  }, [props.defaultValue]);

  const [value, dispatch] = useState<string>(props.defaultValue ?? '');
  const [meta, setMeta] = useState<Omit<TelInputState, 'value'>>(computeDefaultMeta());

  const reinitializeState = useCallback(() => {
    dispatch(props.defaultValue);
    setMeta(computeDefaultMeta());
  }, [
    computeDefaultMeta,
    dispatch,
    props.defaultValue,
    setMeta,
  ]);

  useEffect(() => {

    if (props.defaultValue) {
      reinitializeState();
    }

  }, [
    reinitializeState,
    props.defaultValue,
  ]);

  useEffect(() => {

    if (!props.editing) {
      reinitializeState();
    }

  }, [
    reinitializeState,
    props.editing,
  ]);

  function dispatchChange(data: TelInputState) {
    dispatch(data.value);
    setMeta({
      empty: data.empty,
      valid: data.valid,
    });
  }

  function handleChangeCountryCode(data: TelInputState) {
    dispatchChange(data);
    props.onChangeCountryCode(data);
  }

  function handleChange(e, data) {
    dispatchChange(data);
    props.onChange(e, data);
  }

  if (props.editing === false) return <>{props.children}</>;

  return (
    <TelInput
      className={props.className}
      id="phone"
      name={props.name}
      onChange={handleChange}
      onChangeCountryCode={handleChangeCountryCode}
      invalid={!meta.empty && !meta.valid}
      invalidClassName={props.invalidClassName}
      value={value} />
  );
};

const defaultProps = {
  editing: true,
};

PhoneInput.defaultProps = defaultProps;

export { PhoneInput };
export default PhoneInput;
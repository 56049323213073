import { useCallback, useState } from 'react';
import { TextareaAutosizeProps } from '@mui/material/TextareaAutosize';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { TextareaAutosize } from 'components/Textarea';
import { TextResponseProps } from './interfaces';

type Props = TextResponseProps;

export const ShortTextResponseQuestion = ({ answer, className, setAnswer }: Props) => {
  const { theme } = useSurveyThemingPalette();

  const updateAnswer = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnswer({
      value: e.target.value,
    });
  }, [setAnswer]);

  const props = {
    className,
    onChange: updateAnswer,
    placeholder: 'Enter an answer',
    value: answer?.value,
  };

  return theme
    ? <ThemedInput {...props} />
    : <TextareaAutosize {...props} />;
};

export default ShortTextResponseQuestion;

type ThemedInputProps = TextareaAutosizeProps;

const ThemedInput = (props: ThemedInputProps) => {
  const [focused, setFocused] = useState(false);
  const { palette } = useSurveyThemingPalette();

  const onBlur = () => setFocused(false);
  const onFocus = () => setFocused(true);

  const handleBlur = useCallback(onBlur, []);
  const handleFocus = useCallback(onFocus, []);

  const style = focused ? {
    borderBottom: `2px solid ${palette.primary.main}`,
  } : {};

  return (
    <TextareaAutosize
      {...props}
      onBlur={handleBlur}
      onFocus={handleFocus}
      style={style} />
  );
};
import { useSelector } from 'react-redux';
import { useSelectProject } from '@containers/Store';
import { useHasClientRole } from '@containers/User';
import { ProjectCall } from '@/types';
import { ProjectCallAgendaMenuPopper } from 'components/CallsAgenda';
import { BaseCallAgendaItem } from './AgendaItem.Base';

type Props = {
  item: ProjectCall;
};

const selectContact = (state: Store.State) => (userId: number) => state.contacts[userId];

const ProjectCallAgendaItem = ({ item }: Props) => {
  const project = useSelectProject(item.projectId);
  const hasClientRole = useHasClientRole();
  const getContact = useSelector(selectContact);

  const description = hasClientRole
    ? getContact(item.userId).profile.fullname
    : '';

  return (
    <BaseCallAgendaItem
      item={item}
      title={project.name}
      description={description}>
      <ProjectCallAgendaMenuPopper item={item} />
    </BaseCallAgendaItem>
  );
};

export { ProjectCallAgendaItem };
export default ProjectCallAgendaItem;
import { PlusCircle } from 'react-feather';
import { cx } from '@utils';
import styles from './style/Divider.css';

type DividerProps = {
  btnClassName?: string;
  disabled?: boolean;
  className?: string;
  lineClassName?: string;
  onClick: () => void;
};

export const AddButtonDivider = (props: DividerProps) => {
  return (
    <div className={cx(styles.root, props.className)}>
      <div className={cx(styles.line, props.lineClassName)} />
      {!props.disabled &&
        <PlusCircle
          className={cx(styles.btn, props.btnClassName)}
          onClick={props.onClick} />
      }
    </div>
  );
};

export const AddQuestionDivider = (props: DividerProps) => (
  <AddButtonDivider
    btnClassName={cx(styles.questionBtn, props.btnClassName)}
    disabled={props.disabled}
    lineClassName={props.lineClassName}
    className={cx(styles.question, props.className)}
    onClick={props.onClick} />
);
import * as consts from '@consts';
import Button from 'components/Button';
import styles from './style/NoPaymentMethod.css';

export function NoPaymentMethod() {
  return (
    <div className={styles.content}>
      <div className={styles.text}>Pay others on the platform.</div>
      <div className={styles.text}>Add a billing account to start transacting with peers.</div>
      <Button
        className={styles.register}
        variant='brick'
        to={consts.path.Settings.UserBillingNewMethod}
        title='Add Account' />
    </div>
  );
}

export default NoPaymentMethod;
import http from '@services/http';
import * as xform from '@transformers';
import { WorkspaceFolders as WS } from './interfaces';

export const createFolder = (data: WS.CreateFolder.Request) => {
  return http.post(`/workspaces/${data.workspaceId}/folders`, {
    name: data.name,
    parentObjectId: data.parentObjectId,
  }).then((data: WS.CreateFolder.Response) => ({
    capabilities: data.capabilities,
    folder: xform.normalizeFolder(data.folder),
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};

export const deleteFolder = (data: WS.DeleteFolder.Request) => {
  return http.delete(`/workspaces/${data.workspaceId}/folders/${data.folderId}`);
};

export const renameFolder = (data: WS.RenameFolder.Request) => {
  return http.patch(`/workspaces/${data.workspaceId}/folders/${data.folderId}/name`, {
    name: data.name,
  }).then((data: WS.RenameFolder.Response) => ({
    folder: xform.normalizeFolder(data.folder),
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};
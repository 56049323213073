import { Fragment, useMemo } from 'react';
import { CallApprovalState } from './CallApprovalState';
import { MessageApprovalState } from './MessageApprovalState';
import { SurveyApprovalState } from './SurveyApprovalState';
import { ComplianceReviewItem } from '@/types';

type Props = {
  className?: string;
  item:       ComplianceReviewItem;
};

const ApprovalState = (props: Props) => {

  const children = useMemo(() => {
    switch (props.item.type) {

      case 'call':
        return <CallApprovalState item={props.item} />;

      case 'message':
        return <MessageApprovalState item={props.item} />;

      case 'survey':
        return <SurveyApprovalState item={props.item} />;

      default:
        return <Fragment />;

    }
  }, [props.item]);

  return (
    <>
      {children}
    </>
  );
};

export { ApprovalState };
export default ApprovalState;
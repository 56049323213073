import { QuestionRawText } from '@presentation/Survey';
import { cx } from '@utils';
import styles from './style/Preview.css';

type Props = {
  className?: string;
  items: string[];
};

export const ScreeningQuestionsPreview = ({ className, items }: Props ) => {

  return (
    <div className={cx(styles.root, className)}>
      {items.map((m, i) => (
        <QuestionRawText
          key={i}
          className={styles.item}
          item={{
            ordinal: i + 1,
            value: m,
          }} />
      ))}
    </div>
  );
};

export default ScreeningQuestionsPreview;
import { path } from '@consts';
import { RouteAuth } from '@enums';
import { StateRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import {
  GroupProjectCloningContainer,
  GroupProjectContainer,
  ProjectSurveyEditorContainer,
  ProjectSurveyRespondentContainer,
} from '@containers/GroupProject';
import { ProjectTestDriveContainer } from '@containers/ProjectOnboarding/ProjectTestDriveContainer';
import { GroupProjects } from '@screens/GroupProjects';
import * as Member from '@screens/Projects.Member';
import { SurveyPreviewComplete} from '@screens/SurveyPreviewComplete';
import * as utils from '@utils';
import { ProjectDetails } from '@screens/ProjectDetails';
import { ProjectCreation } from '@screens/ProjectCreation';
import Search from 'scenes/search/ProjectSearch';
import { ProjectCallTranscript as CallTranscript } from '@screens/ProjectCallTranscript';

const Client = {
  Creation: ProjectCreation,
  Projects: GroupProjects,
  Search,
  CallTranscript,
};

const Consultant = {
  Project: ProjectDetails,
  Projects: Member.Projects,
};

const client: Routing.Route[]  = [
  {
    component: Client.Creation,
    path: path.Projects.New,
  },
  {
    component: Client.Projects,
    exact: true,
    path: path.Projects.Root,
  },
  {
    exact: true,
    component: Client.Search,
    path: path.Projects.Search,
  },
  {
    component: Client.CallTranscript,
    exact: true,
    path: path.Projects.Transcript,
  },
  {
    component: ProjectSurveyRespondentContainer,
    exact: true,
    path: path.Projects.SurveyResponse,
  },
  {
    component: ProjectSurveyEditorContainer,
    exact: true,
    path: path.Projects.SurveyEditor,
  },
  {
    component: GroupProjectCloningContainer,
    path: path.Projects.Clone,
  },
  {
    component: SurveyPreviewComplete,
    path: path.Projects.PreviewComplete,
  },
  {
    component: ProjectTestDriveContainer,
    path: path.Projects.Preview,
  },
  {
    component: GroupProjectContainer,
    path: [
      path.Projects.ProjectTabs,
      path.Projects.Project,
    ],
  },
];

const consultant: Routing.Route[] = [
  {
    component: Consultant.Projects,
    exact: true,
    path: path.Projects.Root,
  },
  {
    component: Consultant.Project,
    exact: true,
    path: path.Projects.Project,
  },
];

const validator = (state: Store.State) => {

  return utils.hasClientRole(state.user)
       ? client
       : consultant;
};

export const router = StateRouter(validator, {
  auth: RouteAuth.Authenticated,
  path: path.Projects.Root,
});
import Axios, { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import * as api from '@api/universal-search';
import { UniversalSearch } from '@api/interfaces';

type Data = UniversalSearch.Posts.Response;

export function useFetchUniversalSearchPagePosts<TData = Data>(
  query: string,
  size: number,
  options?: UseQueryOptions<Data, AxiosResponse, TData>
) {
  return useQuery(keyUseFetchUniversalSearchPagePosts(query), () => {
    const source = Axios.CancelToken.source();

    const p = api.posts({
      query,
      size,
      cancelToken: source.token,
    });

    p.cancel = () => {
      source.cancel();
    };

    return p;
  }, {
    staleTime: 60000,
    ...options,
  });
}

export function keyUseFetchUniversalSearchPagePosts(query: string) {
  return ['universal-search', query, 'posts'];
}
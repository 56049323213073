import { useCallback, useMemo } from 'react';
import { SurveyOptionType } from '@enums';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { SurveyQuestionOption } from '@/types';
import { QuestionOptionsBuilder } from 'components/SurveyBuilder.Question/OptionsBuilder';
import { QuestionOptionsBuilderContainerProps } from './interfaces';

export const QuestionOptionsBuilderContainer = ({
  Component = QuestionOptionsBuilder,
  ...props
}: QuestionOptionsBuilderContainerProps) => {
  const [state, dispatch] = useSurveyBuilderState();

  const addOption = useCallback(() => {
    dispatch({
      questionIdentifier: props.item.base.identifier,
      type: 'add-question-option',
    });
  }, [
    dispatch,
    props.item.base.identifier,
  ]);

  const removeOption = useCallback((identifier: string) => () => {

    dispatch({
      option: {
        identifier,
      },
      questionIdentifier: props.item.base.identifier,
      type: 'remove-question-option',
    });

  }, [
    dispatch,
    props.item.base.identifier,
  ]);

  const updateOptionValue = useCallback((identifier: string) => (value: string) => {

    dispatch({
      option: {
        identifier,
      },
      questionIdentifier: props.item.base.identifier,
      type: 'update-question-option-value',
      value,
    });

  }, [
    dispatch,
    props.item.base.identifier,
  ]);

  const addOptionEnabled = useMemo(() => {
    return props.item.options.filter(f => !isExtendedOption(f)).length < props.maxOptions;
  }, [props.item.options, props.maxOptions]);

  const removeOptionEnabled = useMemo(() => {
    return props.item.options.filter(f => !isExtendedOption(f)).length > props.minOptions;
  }, [props.item.options, props.minOptions]);

  return (
    <Component
      addOption={addOption}
      addOptionEnabled={addOptionEnabled}
      className={props.className}
      options={props.item.options}
      removeOption={removeOption}
      removeOptionEnabled={removeOptionEnabled}
      updateOptionValue={updateOptionValue} />
  );
};

function isExtendedOption(option: SurveyQuestionOption) {
  return option.type !== SurveyOptionType.Default;
}

export default QuestionOptionsBuilderContainer;

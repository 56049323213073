import { WorkspaceObjectType } from '@enums';
import { safeJsonDate } from '@utils';
import { UserWorkspaceObject, WorkspaceObject} from '@/types';

export const normalizeWorkspaceObject = (data: WorkspaceObject<string>): WorkspaceObject => {
  if (!data) return null;

  return {
    ...data,
    createdOn: safeJsonDate(data.createdOn),
  };
};

export const normalizeWorkspaceObjects = (data: UserWorkspaceObject<WorkspaceObjectType, string>[]): Store.Objects => {

  return data.reduce((acc, { capabilities, ...object}) => ({
    ids: acc.ids,
    capabilities: {
      ...acc.capabilities,
      [object.id]: capabilities,
    },
    values: {
      ...acc.values,
      [object.id]: normalizeWorkspaceObject(object),
    },
  }), {
    capabilities: {},
    ids: data.map(m => m.id),
    values: {},
  } as Store.Objects);
};
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PayoutSetup } from '@containers/PayoutSetup';
import { useSelectPayoutSettings } from '@containers/Store';
import { path } from '@consts';
import * as Setup from '@screens/PayoutSetup/Setup';
import styles from './style/NewPayoutMethod.css';

export default function UpdatePayoutMethod() {
  const history = useHistory();
  const settings = useSelectPayoutSettings();

  const handleBack = useCallback(() => {
    history.replace(path.Settings.UserBilling);
  }, [history]);

  const handleNext = useCallback(() => {
    history.replace(path.Settings.UserBilling);
  }, [history]);

  const navigation = {
    back: handleBack,
    next: handleNext,
    skip: null,
  };

  return (
    <div className={styles.root}>
      <PayoutSetup>
        <Setup.Update
          settings={settings}
          navigation={navigation}
          nextLabel="Save" />
      </PayoutSetup>
    </div>
  );
}

export { UpdatePayoutMethod };
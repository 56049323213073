import { ChangeEvent, forwardRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProjectFrequency, ProjectType, utils as enumUtils } from '@enums';
import { InfoTooltip } from '@presentation/InfoTooltip';
import { useProjectFormContext, useProjectCreationNavigation } from '@screens/ProjectCreation/context';
import { Header, Subtitle, Title } from '@screens/ProjectCreation/Header';
import { cx } from '@utils';
import { TargetCompany } from '@/types';
import { Input } from 'components/Input';
import { Select, SelectOptionProps } from 'components/Select';
import { GoalInput, ProjectFrequencyDropdown, TargetCompanyInput, ProjectTargetCompletionInput } from 'components/GroupProject';
import { BackButton, Footer, NextButton } from './Footer';
import styles from './style/Overview.css';
import commonStyles from './style.css';

const selectGroupFeatures = (state: Store.State) => state.group.features;

const ProjectOverview = forwardRef<HTMLInputElement>((_, ref) => {
  const groupFeatures = useSelector(selectGroupFeatures);
  const [form, setFormState] = useProjectFormContext();
  const actions = useProjectCreationNavigation();

  const options = useMemo(() => {
    const allOptions = [
      { id: ProjectType.Call, name: enumUtils.ProjectType.getName(ProjectType.Call) },
      { id: ProjectType.Survey, name: enumUtils.ProjectType.getName(ProjectType.Survey) },
      { id: ProjectType.SurveyCall, name: enumUtils.ProjectType.getName(ProjectType.SurveyCall) },
    ];

    if (!groupFeatures.surveyProjects) {
      return [allOptions.find(i => i.id === ProjectType.Call)];
    }
    return allOptions;
  }, [groupFeatures.surveyProjects]);

  const tooltip = useMemo(() => {
    const type = [
      `The Call project type allows you to conduct calls on the platform, but does not require experts to submit answers to screening questions.`,
      groupFeatures.surveyProjects ? `The Survey project type allows you to collect survey responses from experts without call scheduling functions.` : null,
      groupFeatures.surveyProjects ? `The Survey and Call project type allows for both survey responses and calls to be conducted on the same project.` : null,
    ];

    const goal = groupFeatures.surveyProjects
      ? `This represents the targeted number of calls or survey responses you would like to complete for this project.`
      : `This represents the targeted number of calls you would like to complete for this project.`;

    const frequency = `Receive survey answers from respondents over multiple time periods to understand shifts in the markets or topics you are researching.`;

    return {
      frequency,
      goal,
      title: `This is an expert facing project title. Experts are far more receptive to projects with industry title names vs company title names (i.e. "Streaming Media" vs. "Netflix").`,
      type: type.filter(Boolean).join('\n\n'),
    };
  }, [
    groupFeatures.surveyProjects,
  ]);

  function handleChangeName({ target }: ChangeEvent<HTMLInputElement>) {
    setFormState({ name: target.value });
  }

  function handleChangeType(option: SelectOptionProps) {
    setFormState({ projectType: option.id });
  }

  function handleChangeGoal(value: number) {
    setFormState({ goal: value  });
  }

  function handleChangeTargetCompany(item: TargetCompany) {
    setFormState({ targetCompany: item });
  }

  function handleFrequencyChange(value: ProjectFrequency) {
    setFormState({ frequency: value });
  }

  function handleTargetDateChange(value: Date) {
    setFormState({ targetCompleteDate: value });
  }

  const goalLabel = form.projectType === ProjectType.Survey
    ? 'Total Responses Goal'
    : 'Completed Call Goal';

  const showFrequency = useMemo(() => {
    const types = [
      ProjectType.Survey,
      ProjectType.SurveyCall,
    ];

    return groupFeatures.repeatSurveys &&
      types.includes(form.projectType);
  }, [form.projectType, groupFeatures.repeatSurveys]);

  const canSubmit = useMemo(() => {
    const isNameValid = form.name.length > 4;
    const hasTargetCompleteDate = !!form.targetCompleteDate;

    return isNameValid;
  }, [
    form.name,
    form.targetCompleteDate,
  ]);

  return (
    <>
      <div className={commonStyles.section}>
        <div className={commonStyles.root}>
          <div className={commonStyles.wrap}>
            <Header className={commonStyles.row}>
              <Title className={commonStyles.row}>Overview</Title>
              <Subtitle className={commonStyles.row}>Input the title of the project and select the project type.</Subtitle>
            </Header>

            <Item
              title="Title"
              tooltip={tooltip.title}>
              <Input
                name="name"
                onChange={handleChangeName}
                placeholder='Enter the industry of your research'
                ref={ref}
                value={form.name} />
            </Item>

            {groupFeatures?.projectTargetCompany &&
              <Item
                title="Target Company">
                <TargetCompanyInput
                  defaultItem={form.targetCompany}
                  onChange={handleChangeTargetCompany} />
              </Item>
            }

            <div className={styles.inline}>
              <Item
                title="Type"
                tooltip={tooltip.type}>
                <div className={styles.type}>
                  <Select
                    className={styles.select}
                    defaultValue={options.find(x => x.id === form.projectType).name}
                    getItemValue={x => x.name}
                    onSelect={handleChangeType}
                    options={options} />
                </div>
              </Item>

              <Item
                title={goalLabel}
                tooltip={tooltip.goal}>
                <div className={styles.type}>
                  <GoalInput
                    onChange={handleChangeGoal}
                    type={form.projectType}
                    value={form.goal} />
                </div>
              </Item>

            </div>

            <div className={styles.inline}>
              {showFrequency &&
                <Item
                  title="Repeat Frequency"
                  tooltip={tooltip.frequency}>
                  <div className={styles.type}>
                    <ProjectFrequencyDropdown
                      onSelect={handleFrequencyChange}
                      value={form.frequency} />
                  </div>
                </Item>}

              <Item
                title="Target Completion Date">
                <div className={styles.picker}>
                  <ProjectTargetCompletionInput
                    onChange={handleTargetDateChange}
                    value={form.targetCompleteDate} />
                </div>
              </Item>
            </div>

          </div>
        </div>
      </div>
      <Footer>
        <BackButton />
        <NextButton
          disabled={!canSubmit}
          onClick={actions.next} />
      </Footer>
    </>
  );
});

type ItemProps = {
  title: string;
  tooltip?: string;
} & ChildrenProps;

const Item = ({ children, title, tooltip }: ItemProps) => {
  return (
    <div className={cx(commonStyles.row, styles.field)}>
      <div className={commonStyles.header}>
        <div className={commonStyles.title}>{title}</div>
        {tooltip &&
          <InfoTooltip
            className={styles.tooltip}
            text={tooltip} />}
      </div>
      {children}
    </div>
  );
};

export { ProjectOverview };
export default ProjectOverview;

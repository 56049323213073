import * as xform from '@services/api/transformers/helpers';
import { safeJsonDate } from '@utils';
import { Call, CallRecord, CompletedCall } from '@/types';

type CallType<T = string> =
  T extends Call<T>
    ? Call<T>
    : T extends CallRecord<T>
      ? CallRecord<T>
      : Call<T> & Partial<CallRecord<T>>;

export const normalizeCall = <T extends CallType<string> = CallType<string>, U extends CallType<Date> = CallType<Date>>(call: T) => {
  const runTransforms = xform.compose<U, T>(
    normalizeCallDates,
  );

  return runTransforms(call);
};

export const normalizeCompletedCall = (data: CompletedCall<string>): CompletedCall => {
  return {
    ...data,
    createdAt: safeJsonDate(data.createdAt),
    timeStart: safeJsonDate(data.timeStart),
    timeEnd: safeJsonDate(data.timeEnd),
  };
};

export const normalizeCalls = <T extends CallType<string>[] = CallType<string>[]>(calls: T) => {
  return xform.toMap<number, CallType<Date>>(calls.map(normalizeCall));
};

const dateFields = ['approvedOn', 'createdAt', 'screenedOn', 'timeEnd', 'timeStart'];

function normalizeCallDates<T extends CallType<string> = CallType<string>, U extends CallType<Date> = CallType<Date>>(call: T) {
  return Object.keys(call).reduce<U>((acc, key) => {
    const val = acc[key] as string;

    if (!dateFields.includes(key)) {
      acc[key] = val;
    } else {
      acc[key] = safeJsonDate(val);
    }

    return acc;
  }, {
    ...call,
    during: [],
  } as unknown as U);
}
import { ArrowRight } from 'react-feather';
import * as consts from '@consts';
import { Accordion } from 'components/Accordion';
import { faqs } from './data';
import styles from './style/FAQ.css';

type Props = unknown;

export const FAQ = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2 className={styles.title}>FAQs</h2>
        <div className={styles.subtitle}>Answers to common questions about pricing and billing.</div>
      </div>
      {faqs.map(x =>
        <Accordion
          classes={{
            label: styles.label,
            panel: styles.panel,
            value: styles.value,
          }}
          collapsed
          label={x.label}
          key={x.label}
          showCount={false}
          grows={false}>
          <div className={styles.text}>{x.value}</div>
        </Accordion>)}
      <div className={styles.footer}>
        <a
          className={styles.footer}
          href={consts.urls.FAQ}
          rel="noreferrer"
          target="_blank" >
          See other frequently asked questions <ArrowRight className={styles.icon} />
        </a>
      </div>
    </div>
  );
};

FAQ.displayName = 'Pricing.FAQ';
import { cx } from '@utils';
import { CalendarPickerBase } from 'components/CalendarPicker/CalendarPickerBase';
import { CalendarPickerBaseProps } from './interfaces';
import styles from './style.css';

type Props = {
  className?: string;
  dates?:     Date[];
  marked?:    Date[];
} & CalendarPickerBaseProps;

const CalendarPicker = (props: Props) => {
  const {
    calendarClassName,
    className,
    dates,
    highlightDates,
    includeDates,
    ...rest
  } = props;

  const enableHighlightedDatesOnly = dates?.length;

  const highlighted = highlightDates
    ? highlightDates
    : enableHighlightedDatesOnly
      ? [{ [styles.highlight]: dates }]
      : [{ [styles.highlight]: props.marked }];

  const included = includeDates
      ? includeDates
      : enableHighlightedDatesOnly
        ? dates
        : null;

  return (
    <CalendarPickerBase
      {...rest}
      calendarClassName={cx(calendarClassName, className)}
      highlightDates={highlighted}
      includeDates={included} />
  );
};

const defaultProps = {
  calendarClassName: styles.root,
  inline: true,
  marked: [],
};

CalendarPicker.defaultProps = defaultProps;

export { CalendarPicker };
export default CalendarPicker;
import { useTable, useFlexLayout, useSortBy, usePagination } from 'react-table';
import { GroupTable } from '@presentation/GroupTable';
import { ProjectsTableItem } from 'components/GroupProject';
import { useProjectsTableColumns } from 'components/GroupProject/hooks/useProjectsTableColumns';
import styles from './style/ActiveProjectsTable.css';

type Props = {
  data: ProjectsTableItem[];
  maxItems: number;
};

export const ActiveProjectsTable = ({ data, maxItems }: Props) => {
  const columns = useProjectsTableColumns();

  const tableInstance = useTable<ProjectsTableItem>(
    {
      columns,
      data,
      disableSortBy: true,
      initialState: {
        pageIndex: 0,
        pageSize: maxItems,
        sortBy: [{
          id: 'createdOn',
          desc: true,
        }],
      },
    },
    useFlexLayout,
    useSortBy,
    usePagination,
  );

  const tableClasses = {
    td: styles.td,
    tr: styles.tr,
  };

  return (
    <GroupTable
      classes={tableClasses}
      instance={tableInstance} />
  );
};

export default ActiveProjectsTable;
import { useCallback, useMemo } from 'react';
import TabBar from './TabBar';
import { TabViewProps } from './interfaces';

type Props<T> =
    TabViewProps<T>;

const TabView = <T extends unknown = unknown>(props: Props<T>) => {

  const jumpTo = useCallback((key: string) => {

    const next = props.routes.reduce((acc, x, i) => x.key === key ? i : acc, 0);

    props.onIndexChange(next);

  }, [props]);

  const route = useMemo(() => props.routes[props.index], [props.routes, props.index]);

  return (
    <>
      <TabBar
        index={props.index}
        onClick={props.onIndexChange}
        routes={props.routes} />
      {props.renderTabView({ route, jumpTo })}
    </>
  );
};

export { TabView };
export default TabView;
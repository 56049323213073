import { Fragment, useLayoutEffect, useCallback, useMemo, useRef, CSSProperties } from 'react';
import { isAfter } from 'date-fns';
import { TranscriptComment } from '@/types/transcribe.rich-text';
import { NewComment } from './Comment.New';
import { ParentComment } from './Comment.Parent';
import styles from './style/Comments.Highlights.css';

type Props = {
  highlight: TranscriptComment['highlight'];
  comments: TranscriptComment[];
  observer: ResizeObserver;
  position: {
    width: number;
    x: number;
    y: number;
  };
};

export const HighlightCommentsContainer = ({ comments, highlight, observer, position }: Props) => {

  const ref = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);

      return () => observer.disconnect();
    }
  }, [observer]);

  const style = useMemo(() => {
    return {
      position: 'absolute',
      width: `${position.width}px`,
      left: position.x,
      top: position.y,
    } as CSSProperties;
  }, [position]);

  const renderComments = useCallback(() => {

    const sorted = comments.sort((a, b) => {
      return isAfter(a.parentComment.createdOn, b.parentComment.createdOn) ? 1 : -1;
    });

    return sorted.map(m => {
      const isNew = !m.parentComment.id;

      return (
        <Fragment key={m.parentComment.identifier}>
          {isNew &&
            <NewComment
              className={styles.item}
              identifier={m.parentComment.identifier}
              highlight={highlight} />}
          {!isNew &&
            <ParentComment
              className={styles.item}
              item={m} />}
        </Fragment>
      );
    });
  }, [
    comments,
    highlight,
  ]);

  return (
    <div
      className={styles.root}
      data-highlight-identifier={highlight.identifier}
      ref={ref}
      style={style}>
      {renderComments()}
    </div>
  );
};
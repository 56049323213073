import { useCallback, useMemo } from 'react';
import { arr } from '@utils';
import { RankingQuestion as RQ } from '@/types';
import { RankingListContainer } from 'components/SurveyRankingList';

type Props = {
  answer: RQ.RespondentAnswer.Value;
  question: RQ.FormQuestion | RQ.Question;
  setAnswer: (value: RQ.RespondentAnswer.Value) => void;
};

export const RankingQuestion = ({ answer, question, setAnswer }: Props) => {

  const handleUpdate = useCallback((items: RQ.RespondentAnswer.Option[]) => {
    setAnswer({
      options: items,
    });
  }, [setAnswer]);

  const items = useMemo(() => {
    const initialized = !!answer;
    const items = question.options.map(option => ({
      ...option,
      ordinal: initialized
        ? answer.options.find(f => f.id === option.id)?.ordinal
        : null,
      value: option.value,
    }));

    return initialized
      ? items
      : arr.shuffle(items);

  }, [question.options, answer]);

  return (
    <RankingListContainer
      items={items}
      onUpdate={handleUpdate}
      settings={question.settings} />
  );
};

export default RankingQuestion;
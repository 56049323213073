import { useCallback, useMemo } from 'react';
import { MinusCircle } from 'react-feather';
import {
  useOptionsQuestionLogicContext,
  useOptionsQuestionLogicState,
} from '@containers/SurveyBuilder.Logic/Context';
import {
  useIncludeOrdinalColumn,
  useQuestionLogicBuilderState,
} from '@containers/SurveyBuilder.Logic/hooks';
import { cx } from '@utils';
import { MultipleChoiceQuestion } from '@/types';
import { FormButtons } from '@presentation/FormButtons';
import { QuestionText } from '@presentation/Survey';
import {
  ActionOrdinalDropdown,
  ActionTypeDropdown,
  AddLogicButton,
  ConditionTypeDropdown,
  OptionsDropdown,
} from '@presentation/SurveyBuilder';
import { QuestionBaseLogicBuilder } from './BaseLogic.Builder';
import styles from './style/OptionsLogicBuilder.css';
import baseStyles from './style/QuestionLogicBuilder.css';

export const QuestionOptionsLogicBuilder = () => {

  const {
    item,
    onCancel,
    onSubmit,
    responseActionTypes,
  } = useQuestionLogicBuilderState();
  const question = item as MultipleChoiceQuestion.Question;
  const includeOrdinalColumn = useIncludeOrdinalColumn();
  const {
    addItem,
    removeItem,
    updateActionIdentifier,
    updateActionType,
    updateConditionValue,
    updateConditionType,
  } = useOptionsQuestionLogicContext();
  const [logic] = useOptionsQuestionLogicState();

  const disabled = useMemo(() => {
    return logic.response.some(s => {
      return !s.condition.value.option.identifier ||
        !s.action.type;
    });
  }, [logic.response]);

  const handleSubmit = useCallback(() => {

    onSubmit(logic);
  }, [
    logic,
    onSubmit,
  ]);

  const renderItem = useCallback((item: MultipleChoiceQuestion.Logic.Response) => {
    const option = question.options.find(f => f.base.identifier === item.condition.value.option.identifier);

    return (
      <div className={styles.row} key={item.id}>
        <div className={styles.condition}>
          <ConditionTypeDropdown
            onSelect={updateConditionType(item.id)}
            value={item.condition.type} />
        </div>
        <div className={styles.choice}>
          <OptionsDropdown
            items={question.options}
            onSelect={updateConditionValue(item.id)}
            value={option} />
        </div>
        <div className={styles.action}>
          <ActionTypeDropdown
            items={responseActionTypes}
            onSelect={updateActionType(item.id)}
            value={item.action.type} />
        </div>
        <div className={styles.ordinal}>
          <ActionOrdinalDropdown
            action={item.action}
            updateActionIdentifier={updateActionIdentifier(item.id)} />
        </div>
        <MinusCircle
          className={styles.remove}
          onClick={removeItem(item.id)} />
      </div>
    );
  }, [
    removeItem,
    responseActionTypes,
    question.options,
    updateActionIdentifier,
    updateActionType,
    updateConditionValue,
    updateConditionType,
  ]);

  const renderResponseLogic = useCallback(() => {
    if (logic.base.action.type) return null;

    return (
      <div className={styles.root}>
        {!!logic.response.length &&
          <div className={styles.headerRow}>
            <div className={cx(styles.header, styles.condition)}>Condition</div>
            <div className={cx(styles.header, styles.choice)}>Answer Choice</div>
            <div className={cx(styles.header, styles.action)}>Action</div>
            {includeOrdinalColumn && <div className={styles.ordinal} />}
          </div>
        }
        {logic.response.map(renderItem)}
        <AddLogicButton
          className={styles.add}
          onClick={addItem} />
      </div>
    );
  }, [
    addItem,
    includeOrdinalColumn,
    logic.base.action.type,
    logic.response,
    renderItem,
  ]);

  return (
    <div className={baseStyles.root}>
      <QuestionText
        className={baseStyles.questionText}
        textClassName={baseStyles.text}
        item={item} />
      <div className={baseStyles.logic}>
        <QuestionBaseLogicBuilder />
        {renderResponseLogic()}
      </div>
      <FormButtons
        className={baseStyles.formBtns}
        archivable={false}
        disabled={disabled}
        deletable={false}
        onSubmit={handleSubmit}
        onCancel={onCancel} />
    </div>
  );
};

export default QuestionOptionsLogicBuilder;
import { useEffect, useRef } from 'react';
import { X } from 'react-feather';
import { useSelectUser } from '@containers/Store';
import styles from './style.css';

type Props = {
  dismiss: () => void;
};

const CloseAfter = 10 * 1000;

export const VerifiedBanner = (props: Props) => {
  const user = useSelectUser();
  const timeout = useRef<ReturnType<typeof setTimeout>>(null);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      props.dismiss();
    }, CloseAfter);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [props.dismiss]);

  const text = `Congratulations ${user.profile.firstName}, your account has been activated`;

  return (
    <div className={styles.root}>
      <div className={styles.text}>{text}</div>
      <X
        className={styles.x}
        onClick={props.dismiss}
        size={30} />
    </div>
  );
};

export default VerifiedBanner;
import { Fragment } from 'react';
import { Info } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { useSignupForm } from '$website/containers/Auth/hooks/useSignupForm';
import * as Auth from '$website/containers/Auth/interfaces';
import { Input } from 'components/Input';
import { PhoneInput, TelInputState } from 'components/TelInput';
import styles from './style/Signup.css';

const useTooltipStyles = makeStyles({
  tooltip: {
    fontSize: 13,
    fontFamily: 'HarmoniaSans W01',
  },
});

export const Signup = () => {
  const [form, dispatch] = useSignupForm();

  function handleTextInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      key: e.target.name as keyof Omit<Auth.Email.Signup.State, 'phone'>,
      value: e.target.value,
      type: 'value',
    });
  }

  function handlePhoneInputChange(e: React.ChangeEvent<HTMLInputElement>, data: TelInputState) {
    dispatch({
      key: e.target.name as keyof Pick<Auth.Email.Signup.State, 'phone'>,
      value: data,
      type: 'value',
    });
  }

  function handlePhoneCountryCodeChange(data: TelInputState) {
    dispatch({
      key: 'phone',
      value: data,
      type: 'value',
    });
  }

  const tooltipClasses = useTooltipStyles();

  return (
    <Fragment>
      <div className={styles.field}>
        <div className={styles.row}>
          <div className={styles.inline}>
            <Input
              required
              autoComplete="on"
              className={styles.input}
              name="firstName"
              onChange={handleTextInputChange}
              placeholder="First Name"
              type="text" />
          </div>
          <div className={styles.inline}>
            <Input
              required
              autoComplete="on"
              className={styles.input}
              name="lastName"
              onChange={handleTextInputChange}
              placeholder="Last Name"
              type="text" />
          </div>
        </div>
      </div>
      <div className={styles.field}>
        <Input
          required={false}
          autoComplete="off"
          className={styles.companyInput}
          name="company"
          onChange={handleTextInputChange}
          placeholder="Company or last employer"
          type="text" />
        <Tooltip
          classes={tooltipClasses}
          title="Vancery is a professional platform and our team verifies all new users. Please provide your current or most recent employer to help us guide our verification process!">
          <div className={styles.companyInfoWrap}>
            <Info className={styles.companyInfo} />
          </div>
        </Tooltip>
      </div>
      <div className={styles.field}>
        <PhoneInput
          className={styles.input}
          invalidClassName={styles.invalid}
          name="phone"
          onChange={handlePhoneInputChange}
          onChangeCountryCode={handlePhoneCountryCodeChange} />
      </div>
      <div className={styles.field}>
        <Input
          autoComplete="on"
          className={styles.input}
          name="email"
          onChange={handleTextInputChange}
          placeholder="Email"
          type="email" />
      </div>
      <div className={styles.field}>
        <Input
          autoComplete="off"
          className={styles.input}
          name="password"
          onChange={handleTextInputChange}
          placeholder="Password"
          type="password" />
      </div>
    </Fragment>
  );
};

Signup.displayName = 'AuthForm.Email.Signup';
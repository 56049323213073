import { Fragment, useMemo } from 'react';
import { Participant, Room, Track, LocalTrackPublication, RemoteTrackPublication } from 'twilio-video';
import { isCameraTrack, isScreenTrack } from 'components/Conference.Video/constants';
import { Publication } from './Publication';
import { usePublications } from './hooks/usePublications';

type ParticipantTracksProps = {
  participant: Participant;
  disableAudio?: boolean;
  videoPriority?: Track.Priority;
  room: Room;
};

function filterPublications(items: (LocalTrackPublication | RemoteTrackPublication)[]) {
  return items.some(p => isScreenTrack(p.trackName))
    ? items.filter(p => !isCameraTrack(p.trackName))
    : items;
}

export function ParticipantTracks({ disableAudio, participant, videoPriority, room }: ParticipantTracksProps) {
  const isLocal = participant === room.localParticipant;

  const publications = usePublications(participant);

  const filteredPublications = useMemo(() => filterPublications(publications), [publications]);

  return (
    <Fragment>
      {filteredPublications.map(publication => (
        <Publication
          key={publication.trackName}
          publication={publication}
          participant={participant}
          isLocal={isLocal}
          disableAudio={disableAudio}
          videoPriority={videoPriority} />
      ))}
    </Fragment>
  );
}

export default ParticipantTracks;

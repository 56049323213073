import { AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import * as api from '@api';
import * as API from '@api/interfaces';
import { querykey } from '@consts';

export const useFetchFollowedTopics = (options: Options = {}) => {
  const query = useQuery([querykey.Users.Topics.Get], async () => {

    const result = await api.users.topics.fetchFollowed();

    return result;

  }, options);

  return query;
};

type Options =
  UseQueryOptions<
    API.Users.FetchFollowedTopics.Response,
    AxiosResponse,
    API.Users.FetchFollowedTopics.Response>;
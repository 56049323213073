import { useCallback } from 'react';
import { GroupTag, GroupTags } from '@presentation/GroupTag';
import { CardContent, Divider } from '@presentation/Main';
import { TaggedProject } from '@containers/TaggedProjects';
import ProjectsAutocomplete from 'components/AutoComplete/Projects';
import { useTaggedProjects, useTaggedProjectsDispatch } from './hooks';
import styles from './style.css';

export default function PersonTags() {
  const { editing } = useTaggedProjects();

  return (
    <>
      <Divider />
      <CardContent className={styles.content}>
        {editing
          ? <Editing />
          : <Readonly />}
      </CardContent>
    </>
  );
}

function Editing() {
  const { items } = useTaggedProjects();
  const dispatch = useTaggedProjectsDispatch();

  const handleSelect = useCallback((item: TaggedProject) => {
    dispatch(state => ({
      ...state,
      items: [...state.items, item],
    }));
  }, [dispatch]);

  const handleRemove = useCallback((item: TaggedProject) => {
    dispatch(state => ({
      ...state,
      items: state.items.filter(t => t.id !== item.id),
    }));
  }, [dispatch]);

  return (
    <>
      <div className={styles.ac}>
        <ProjectsAutocomplete
          onSelect={handleSelect}
          ignoreList={items.map(m => m.id)} />
      </div>
      <Tags selected onRemove={handleRemove} />
    </>
  );
}

function Readonly() {
  return <Tags />;
}

function Tags({ selected, onRemove }: TagsProps) {
  const { items } = useTaggedProjects();

  if (!items.length) {
    return (
      <div className={styles.tags}>
        <div className={styles.empty}>No projects added.</div>
      </div>
    );
  }

  return (
    <GroupTags className={styles.tags}>
      {items.map(t => (
        <GroupTag
          key={t.id}
          selected={selected}
          onRemove={() => onRemove?.(t)}>
          {t.name}
        </GroupTag>))}
    </GroupTags>
  );
}

type TagsProps = {
  selected?: boolean;
  onRemove?: (item: TaggedProject) => unknown;
}

export { PersonTags };
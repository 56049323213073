import { cx } from '@utils';
import styles from './style.css';

type OptionProps<T> = {
  children:    React.ReactNode;
  className?:  string;
  item:        T;
  onMouseDown: (item: T) => unknown;
};

const Option = <T extends OptionItem>(props: OptionProps<T>) => {
  return (
    <div
      className={cx(styles.option, props.className)}
      onMouseDown={() => props.onMouseDown(props.item)}>
      {props.children}
    </div>
  );
};

type OptionItem = {
  id?: number | string;
};

type SelectListProps<T = OptionItem> = {
  className?: string;
  getOptionItem: (item: T) => unknown;
  items: T[];
  onMouseDown: (item: T) => unknown;
  open: boolean;
};

const SelectList = <T extends OptionItem>(props: SelectListProps<T>) => {
  function renderOption(item: T, index: number) {
    const key = item?.id ?? `option-${index}`;

    return (
      <Option
        className={props.className}
        key={key}
        item={item}
        onMouseDown={props.onMouseDown}>
        {props.getOptionItem(item)}
      </Option>
    );
  }

  const className = cx({
    [styles.options]: true,
    [styles.visible]: props.open,
    [styles.hidden]: !props.open,
  });

  return (
    <div className={styles.list}>
      <div className={className}>
        {props.items.map(renderOption)}
      </div>
    </div>
  );
};

export default SelectList;
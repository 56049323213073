import { useHistory } from 'react-router-dom';
import { SurveyApprovalActions } from '@containers/Review/Survey';
import * as consts from '@consts';
import { AcceptResponseButton, RejectResponseButton } from '@presentation';
import { Buttons } from 'scenes/surveys/CompletedSurvey/Buttons';
import { ApprovalButtonsProps } from './interfaces';

export const ApprovalButtons = (props: ApprovalButtonsProps) => {
  const history = useHistory();
  const navigateHome = () => {
    history.push(consts.pathname.HOME);
  };

  return (
    <Buttons>
      <SurveyApprovalActions
        onChange={navigateHome}
        item={{
          project: {
            id: props.project.id,
            name: props.project.name,
          },
          projectSurveyResponseId: props.project.responseId,
          user: {
            id: props.user.id,
            name: props.user.profile.fullname,
          },
        }}
        approveComponent={(
          <AcceptResponseButton
            onClick={navigateHome} />
        )}
        rejectComponent={(
          <RejectResponseButton
            onClick={navigateHome} />
        )} />
    </Buttons>
  );
};
import { useCallback } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useTranscriptCommentFocus } from '@containers/Transcript';
import { cx } from '@utils';
import styles from './style/Comment.Focus.css';

type Props = {
  className?: string;
  identifier: string;
  onBlur?: () => void;
} & ChildrenProps;

export const CommentFocusContainer = ({ children, className, identifier, onBlur }: Props) => {

  const [isFocused, setIsFocused] = useTranscriptCommentFocus(identifier);

  const handleFocus = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!e.isDefaultPrevented()) {
      setIsFocused(true);
    }
  }, [setIsFocused]);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    onBlur?.();
  }, [onBlur, setIsFocused]);

  const resolvedClassName = cx(styles.root, className, {
    [styles.focused]: isFocused,
    [styles.unfocused]: !isFocused,
  });

  return (
    <ClickAwayListener
      onClickAway={handleBlur}
      mouseEvent={isFocused ? 'onMouseDown' : false}>
      <div
        className={resolvedClassName}
        onClick={handleFocus}>
        {children}
      </div>
    </ClickAwayListener>
  );
};
import { SurveyActionType } from '@enums';
import {
  MatrixGridQuestion,
  MatrixMultiselectQuestion,
  MultipleChoiceQuestion,
  MultiselectQuestion,
  SurveyQuestion,
  SurveyLogic,
} from '@/types/survey';
import { OrdinalMap } from '../interfaces';

type RefreshLogic = {
  logic: SurveyQuestion['logic'];
  refreshAction: (action: SurveyLogic.Action) => SurveyLogic.Action;
};

type Response =
  MatrixGridQuestion.Logic.Response |
  MultipleChoiceQuestion.Logic.Response |
  MultiselectQuestion.Logic.Response |
  MatrixMultiselectQuestion.Logic.Response;

export const refreshLogic = (data: RefreshLogic): SurveyQuestion['logic'] => {

  return {
    base: refreshBaseLogicOrdinal(data.logic.base),
    response: (data.logic.response as Response[])?.map(refreshResponseLogicOrdinal),
  };

  function refreshBaseLogicOrdinal(item: SurveyLogic.Base): SurveyLogic.Base {
    return {
      action: data.refreshAction(item.action),
    };
  }
  function refreshResponseLogicOrdinal(item: Response): Response {
    return {
      id: item.id,
      action: data.refreshAction(item.action),
      condition: item.condition,
    };
  }
};

export const syncQuestionOrdinals = (ordinals: OrdinalMap) => (question: SurveyQuestion): SurveyQuestion => {

  return {
    ...question,
    ordinal: ordinals[question.base.identifier],
    logic: refreshLogic({
      logic: question.logic,
      refreshAction,
    }),
  } as SurveyQuestion;

  function refreshAction(action: SurveyLogic.Action): SurveyLogic.Action {

    if (action.type === SurveyActionType.SkipToQuestion) {
      const ordinal = ordinals[action.identifier];

      return ordinal
        ? action
        : { type: SurveyActionType.None };
    }

    return action;
  }
};

export const syncSectionOrdinals = (ordinals: OrdinalMap) => (question: SurveyQuestion) => {
  return {
    ...question,
    logic: refreshLogic({
      logic: question.logic,
      refreshAction,
    }),
  } as SurveyQuestion;

  function refreshAction(action: SurveyLogic.Action): SurveyLogic.Action {

    if (action.type === SurveyActionType.SkipToSection ||
      action.type === SurveyActionType.IncludeSection) {

      const ordinal = ordinals[action.identifier];

      return ordinal
        ? action
        : { type: SurveyActionType.None };
    }

    return action;
  }
};

const EmptyAction: SurveyLogic.Action = {
  type: SurveyActionType.None,
};

export const syncHiddenSections = (identifier: string, questions: SurveyQuestion[]) => {
  return questions.map(m => ({
    ...m,
    logic: refreshLogic({
      logic: m.logic,
      refreshAction,
    }),
  } as SurveyQuestion));

  function refreshAction(action: SurveyLogic.Action): SurveyLogic.Action {
    if (action.type === SurveyActionType.SkipToSection) {
      return action.identifier === identifier
        ? EmptyAction
        : action;
    } else if (action.type === SurveyActionType.SkipToQuestion) {
      const question = questions.find(f => f.base.identifier === action.identifier);
      return question.section.identifier === identifier
        ? EmptyAction
        : action;
    } else {
      return action;
    }
  }
};

export const syncIncludedSections = (identifier: string, questions: SurveyQuestion[]) => {
  return questions.map(m => ({
    ...m,
    logic: refreshLogic({
      logic: m.logic,
      refreshAction,
    }),
  } as SurveyQuestion));

  function refreshAction(action: SurveyLogic.Action): SurveyLogic.Action {
    if (action.type === SurveyActionType.IncludeSection) {
      return action.identifier === identifier
        ? EmptyAction
        : action;
    } else {
      return action;
    }
  }

};

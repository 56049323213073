import { cx } from '@utils';
import styles from './style/Terms.css';

type Props = {
  as?:      'div' | 'p' | 'span' | 'h1';
  bold?:    boolean;
  children: React.ReactNode;
  upcase?:  boolean;
};

export const Span = (props: Props) => {
  const classes = cx({
    [styles.bold]: props.bold,
    [styles.upcase]: props.upcase,
  });

  return (
    <props.as className={classes}>
      {props.children}
    </props.as>
  );
};

const defaultProps = {
  as: 'span',
  bold: false,
  upcase: false,
};

export const Bold = (props: Omit<Props, 'bold'>) => {
  return (
    <Span
      as={props.as}
      bold
      upcase={props.upcase}>
      {props.children}
    </Span>
  );
};

Span.defaultProps = defaultProps;
import { SurveyQuestionType } from '@enums';
import { LongTextResponseQuestion } from '@/types';
import { Validation } from '../interfaces';
import * as shortText from './question.short-text';
import { returnAssertFailure } from './validation.base';

export const DefaultSettings: LongTextResponseQuestion.Settings = {
  characterMinimum: 150,
};

export function validateQuestion(question: LongTextResponseQuestion.Question): Validation.ValidateQuestionResult<SurveyQuestionType.LongTextResponse> {
  const hasValidSettings = assertHasValidSettings(question.settings);

  if (hasValidSettings.success === false) {
    return returnAssertFailure(hasValidSettings, question);
  }

  return shortText.validateQuestion(question);
}

function assertHasMaxCharacterLength(settings: LongTextResponseQuestion.Settings): Validation.AssertionResult {
  const hasMissingValue = !settings.characterMinimum;

  if (hasMissingValue) {
    return {
      success: false,
      message: 'Character Minimum is empty.',
    };
  }

  return { success: true };
}

function assertHasValidSettings(settings: LongTextResponseQuestion.Settings): Validation.AssertionResult {

  return assertHasMaxCharacterLength(settings);
}
import { NavLink, NavLinkProps } from 'react-router-dom';
import styles from 'components/AppBar/style/Drawer.css';

type Props = {
  count?:  number;
  icon?:   React.ComponentType<{ className?: string; }>;
  name:    string;
  onClick: (e?: React.MouseEvent<HTMLDivElement>) => unknown;
} & Pick<NavLinkProps, 'to'>;

const DrawerItem = (props: Props) => {
  return (
    <div onClick={props.onClick}>
      <NavLink
        activeClassName={styles.active}
        to={props.to}>
        <div className={styles.item}>
          <div className={styles.box}>
            {props.icon && <props.icon className={styles.icon} />}
            {props.count > 0 && <div className={styles.badge}>{props.count}</div>}
          </div>
          <div className={styles.name}>{props.name}</div>
        </div>
      </NavLink>
    </div>
  );
};

export { DrawerItem };
export default DrawerItem;
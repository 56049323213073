import { useCallback, useRef, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Node } from 'slate';
import * as api from '@api';
import { emptyValue } from 'components/RichText';

export function useConferenceNotes(callId: number) {
  const [notes, setNotes] = useState<Node[]>(emptyValue);
  const updaterRef = useRef<NodeJS.Timeout>();

  const query = useQuery(['conference', callId, 'notes'], () => {
    api.conferences.getNotes({ callId })
      .then(result => {
        const value = JSON.parse(result.value) as Node[];
        if (value) {
          setNotes(value);
        }
      });
  }, {
    enabled: !!callId,
    refetchOnWindowFocus: false,
  });

  const mutator = useMutation(['conference', callId, 'update-notes'], async (value: Node[]) => {
    return api.conferences.updateNotes({
      callId,
      value: JSON.stringify(value),
    });
  });

  const update = useCallback((value: Node[]) => {
    setNotes(value);
    updaterRef.current && clearTimeout(updaterRef.current);
    updaterRef.current = setTimeout(() => mutator.mutate(value), 250);
  }, [mutator]);

  return { notes, query, update } as const;
}
import http from '@services/http';
import { Reports } from './interfaces';

export const downloadMarketingUsersReport = () => {
  return http.get<Blob>(`/admin/reports/marketing-users`, { responseType: 'blob' });
};

export const downloadAccountUsersReport = (data: Reports.DownloadAccountUsersReport.Request) => {
  return http.download(`/admin/reports/account-users?groupId=${data.groupId}`);
};

export const downloadExternalProjectLinks = (data: IProjectId) => {
  return http.download(`/admin/reports/external-project-links?projectId=${data.projectId}`).then(r=> r.blob);
};

export * as accountsActivity from './reports.accounts-activity';
export * as billing from './reports.billing';
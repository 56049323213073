import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useAsyncStateLazy } from '@utils';
import * as $metadata from '@services/auth/metadata';
import * as $oauth2 from '@services/auth/oauth2';
import { EnterpriseSubmitContext } from './Context';
import { Enterprise } from './interfaces';

type Props = ChildrenProps;

export default function EnterpriseAuthContainer({ children }: Props) {
  const location = useLocation();

  const search = queryString.parse(location.search) as Search;

  const handleSubmitEnterprise = useCallback((values: Enterprise.Login.State) => {
    return $metadata.get<'enterprise-login'>({
      action: 'enterprise-login',
      email: values.email,
      provider: values.provider,
      returnUrl: search.returnUrl,
    })
    .then(data => {
      if (data.success) {
        return $oauth2.authorize({
          url: data.authorizationUrl,
        });
      } else if (data.success === false) {
        throw new Error(data.error);
      }
    });
  }, [search?.returnUrl]);

  const [loginResponse, loginSubmit] = useAsyncStateLazy(handleSubmitEnterprise, []);

  return (
    <EnterpriseSubmitContext.Provider value={[loginResponse, loginSubmit]}>
      {children}
    </EnterpriseSubmitContext.Provider>
  );
}

export { EnterpriseAuthContainer };

type Search = {
  returnUrl: string;
}
import { isAfter } from 'date-fns';
import * as scheduler from '@containers/Scheduler/utils';
import * as enums from '@enums';
import { Call } from '@/types';

export const selectPendingApprovedCalls = (state: Store.State) => {
  return state.calls.ids.reduce((acc, callId) => {
    const item = state.calls[callId];
    const valid = scheduler.calls.passesApproval(item)
               && !scheduler.calls.needsScreening(item)
               && scheduler.calls.isPending(item);

    return valid ? acc.concat(item) : acc;
  }, [] as Call[]);
};

export const selectReschedulingCalls = (state: Store.State) => {
  return state.calls.ids.reduce((acc, callId) => {
    const item = state.calls[callId];
    const valid = scheduler.calls.isRescheduling(item);

    return valid ? acc.concat(item) : acc;
  }, [] as Call[]);
};

export const selectUnconfirmedCalls = (state: Store.State) => {
  return state.calls.ids.reduce((acc, callId) => {
    const item = state.calls[callId];
    const valid = scheduler.calls.isUnconfirmed(item);

    return valid ? acc.concat(item) : acc;
  }, [] as Call[]);
};

export const selectUpcomingCalls = ({ calls }: Store.State, limit = Infinity) => {
  const upcoming = calls.ids.reduce((acc, id) => isCallUpcoming(calls[id])
    ? [...acc, calls[id]]
    : acc, [] as Call[]);

  return [ ...upcoming ]
    .sort((a, b) => isAfter(a.timeStart, b.timeStart) ? 1 : -1)
    .slice(0, limit);

  function isCallUpcoming(call: Call) {
    const statuses = [
      enums.CallStatus.Scheduled,
    ];

    return statuses.includes(call.statusId)
        && isAfter(call.timeEnd, new Date());
  }
};

export const selectCall = (callId: number) => (state: Store.State) => state.calls[callId];
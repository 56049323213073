import { combineReducers } from 'redux';
import * as types from '@store/action-types';
import { documents as initialState } from '@store/initial-state';
import {
  ApplicationActions,
  DocumentsActions,
} from '@store/interfaces';

type Action =
    ApplicationActions
  | DocumentsActions;

function group(state = initialState.group, action: Action): Store.Documents.Group {
  switch (action.type) {
    case types.APP_DATA_FETCHED:
    case types.APP_STATE_REHYDRATED:
    case types.DOCUMENTS_FETCHED:
      return action.documents.group;

    default:
      return state;
  }
}

export default combineReducers({
  group,
});
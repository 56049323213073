import { TestScoreProps } from '../interfaces';
import styles from './style.css';

const PassingScore = 75;

const TestScore = (props: TestScoreProps) => (
  <div className={styles.root}>
    {props.score < PassingScore
      ? <div className={styles.fail}>{props.score}% - Review answers below</div>
      : <div
          className={styles.pass}
          onClick={props.handleNavigation}>
        {props.score}% - You passed! <span>❯❯</span>
      </div>
    }
  </div>
);

export default TestScore;

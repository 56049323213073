import {
  SurveySection,
} from '@/types/survey';
import { OrdinalMap, SurveySectionsBuilder } from '../interfaces';
import {
  generateNewSection,
} from './defaults';

export function sectionAdded(state: SurveySectionsBuilder.State, { value }: SurveySectionsBuilder.SectionAdded.State): SurveySectionsBuilder.State {

  const ordinal = value.ordinal;
  const ordinals = generateNewOrdinals();

  return [
    ...state.filter(f => f.ordinal < ordinal),
    value,
    ...state
      .filter(f => f.ordinal >= ordinal)
      .map(refreshSection),
  ];

  function generateNewOrdinals() {
    return state.reduce<OrdinalMap>((acc, x) => {
      return {
        ...acc,
        [x.identifier]: x.ordinal < ordinal
          ? x.ordinal
          : x.ordinal + 1,
      };
    }, {});
  }

  function refreshSection(section: SurveySection) {
    const newOrdinal = ordinals[section.identifier];
    return {
      ...section,
      name: section.name === `Section ${section.ordinal}`
        ? `Section ${newOrdinal}`
        : section.name,
      ordinal: newOrdinal,
    };
  }
}

export function newSectionAdded(state: SurveySectionsBuilder.State, { ordinal }: SurveySectionsBuilder.NewSectionAdded.Action): SurveySectionsBuilder.State {

  const newSection = generateNewSection(ordinal);

  return sectionAdded(state, { value: newSection });

}

export function removeSection(state: SurveySectionsBuilder.State, { identifier }: SurveySectionsBuilder.RemoveSection.State): SurveySectionsBuilder.State {
  const toRemove = state.find(f => f.identifier === identifier);

  const sectionOrdinals = generateNewSectionOrdinals();

  return state
    .filter(f => f.identifier !== identifier)
    .map(refreshSection);

  function refreshSection(section: SurveySection): SurveySection {
    return section.ordinal < toRemove.ordinal
      ? section
      : {
        ...section,
        name: section.name === `Section ${section.ordinal}`
          ? `Section ${sectionOrdinals[section.identifier]}`
          : section.name,
        ordinal: sectionOrdinals[section.identifier],
      };
  }

  function generateNewSectionOrdinals() {
    return state.reduce<OrdinalMap>((acc, x) => {
      return {
        ...acc,
        [x.identifier]: x.ordinal < toRemove.ordinal
          ? x.ordinal
          : x.ordinal > toRemove.ordinal
            ? x.ordinal - 1
            : null,
      };
    }, {});
  }
}

export function toggleSectionHidden(state: SurveySectionsBuilder.State, { identifier }: SurveySectionsBuilder.ToggleSectionHidden.Action): SurveySectionsBuilder.State {

  const section = state.find(f => f.identifier === identifier);
  const hidden = !section.hidden;

  return state.reduce((acc, x) => {
    if (x.identifier === identifier) {
      acc.push({
        ...x,
        hidden,
      });
    } else {
      acc.push(x);
    }

    return acc;
  }, []);

}

export function updateSection(state: SurveySectionsBuilder.State, { item }: SurveySectionsBuilder.UpdateSection.Action): SurveySectionsBuilder.State {
  return state.reduce((acc, x) => {
    if (x.ordinal === item.ordinal) {
      acc.push(item);
    } else {
      acc.push(x);
    }

    return acc;
  }, []);
}
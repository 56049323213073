import { useContext } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { cx } from '@utils';
import { ChipContext } from './Chip.Context';
import { ChipBodyBasicProps, ChipBodyActionProps } from './interfaces/ChipBody';
import styles from './style/ChipBody.css';

function Basic(props: ChipBodyBasicProps) {
  const { disabled } = useContext(ChipContext);

  const classes = cx(styles.bodyBase, {
    [styles.disabled]: props.disabled ?? disabled,
  });

  return (
    <div className={classes}>
      {props.children}
    </div>
  );
}

function Action(props: ChipBodyActionProps) {
  const { disabled, clickable } = useContext(ChipContext);

  const classes = cx(styles.bodyAction, {
    [styles.clickable]: clickable,
    [styles.disabled]: props.disabled ?? disabled,
  }, props.className);

  const Icon = ActionIcons[props.variant];

  return (
    <div className={classes}>
      {props.children}
      <div className={styles.action} onClick={!disabled ? props.onClick : undefined}>
        <Icon />
      </div>
    </div>
  );
}

const ActionIcons = {
  add: AddCircleIcon,
  remove: CancelIcon,
};

Action.defaultProps = {
  variant: 'add',
} as Partial<ChipBodyActionProps>;

export const ChipBody = {
  Basic,
  Action,
};
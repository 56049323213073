import { Call, PipelineRecord } from '@/types';
import { isActivelyScheduling, isAwaitingCompliance, isCallWithUser, isLastModifiedBy } from './utils.calls';
/* Request Call */
export const findActivelySchedulingCallWithUser = (state: Store.State) => (record: IProjectId & IUserId) => {
  const id = state.calls.ids.find(id => {
    const call = state.calls[id];

    return isCallWithUser(call, record)
        && isActivelyScheduling(call);
  });

  return state.calls[id];
};

/* Request Call */
/* Scheduling Status */
export const hasCallAwaitingCompliance = (state: Store.State) => (record: PipelineRecord) => {
  return state.calls.ids.some(id => {
    const call = state.calls[id];

    return isCallWithUser(call, record)
        && isAwaitingCompliance(call);
  });
};

/* Request Call */
export const hasCallAwaitingConsultant = (state: Store.State) => (record: PipelineRecord) => {
  return state.calls.ids.some(id => {
    const call = state.calls[id];

    return isCallWithUser(call, record)
        && isActivelyScheduling(call)
        && !isLastModifiedBy(call, { id: record.userId });
  });
};

/* Request Call */
export const isCallAwaitingAnalyst = (call: Pick<Call, 'lastModifiedBy'>, record: Pick<PipelineRecord, 'userId'>) => {
  return isLastModifiedBy(call, { id: record.userId });
};
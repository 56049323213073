import { useCallback } from 'react';
import { useIncludeOrdinalColumn } from '@containers/SurveyBuilder.Logic/hooks/useIncludeOrdinalColumn';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import { QuestionLogicItem } from 'components/SurveyBuilder.Question.Logic/QuestionItem';
import styles from './style/SurveyLogicBuilder.css';

export const SurveyLogicBuilder = () => {
  const [state, dispatch] = useSurveyBuilderState();

  const includeOrdinalColumn = useIncludeOrdinalColumn();

  const renderPlaceholder = useCallback(() => {
    return (
      <div className={styles.placeholder}>
        Add a choice question to add logic
      </div>
    );
  }, []);

  const toggleEditingOn = useCallback((ordinal: number) => () => {
    dispatch({
      type: 'toggle-logic-editing',
      ordinal,
    });
  }, [dispatch]);

  const renderQuestions = useCallback(() => (
    <div>
      {state.survey.questions.map(item => ((
        <div className={styles.question} key={item.ordinal}>
          <QuestionLogicItem
            includeOrdinalColumn={includeOrdinalColumn}
            canEdit={!state.editing.logicOrdinal}
            editing={state.editing.logicOrdinal === item.ordinal}
            item={item}
            toggleEditingOn={toggleEditingOn(item.ordinal)} />
        </div>
      )))}
    </div>
  ), [
    state.survey.questions,
    state.editing.logicOrdinal,
    includeOrdinalColumn,
    toggleEditingOn,
  ]);

  return (
    <>
      {!state.survey.questions.length && renderPlaceholder()}
      {state.survey.questions.length > 0 && renderQuestions()}
      <div className={styles.spacer} />
    </>
  );
};

export default SurveyLogicBuilder;
import { useCallback } from 'react';
import { SurveyTemplateEntityType } from '@enums/survey.template';
import { useSurveyBuilderState, useIsEditingSurvey } from '@containers/SurveyBuilder';
import { AddQuestionOptionButton, ConditionalResponseIcon } from '@presentation/SurveyBuilder';
import { Tooltip } from '@presentation/Tooltip';
import { SurveyQuestionOption, SurveyQuestionOptionMetadata } from '@/types/survey';
import {
  TemplateCompetitor,
  TemplateTarget,
  TemplateEvaluationCriteria,
  TemplatePartnershipCriteria,
  TemplateTargetRole,
} from '@presentation/SurveyPreview/Template';
import { TextEntryListItem, RenderTextEntryListInputProps, TextEntryItem, TextEntryListInput } from 'components/TextEntryList';
import { QuestionOptionsBuilderProps } from './interfaces';

type Props =
  QuestionOptionsBuilderProps;

type ListItem = {
  metadata: SurveyQuestionOptionMetadata;
} & TextEntryItem;

export const QuestionOptionsBuilder = (props: Props) => {

  const [state] = useSurveyBuilderState();
  const editing = useIsEditingSurvey();

  const renderConditionalIcon = useCallback((option: SurveyQuestionOption) => {
    if (!option.conditions.length) return null;

    const dependentOrdinal = state.survey.questions
      .find(f => f.base.identifier === option.conditions[0].question.identifier).ordinal;

    const tooltipText = `Conditional upon response to Q${dependentOrdinal}`;

    return (
      <Tooltip title={tooltipText}>
        <div>
          <ConditionalResponseIcon />
        </div>
      </Tooltip>
    );
  }, [state.survey.questions]);

  const renderItem = useCallback((p: RenderTextEntryListInputProps<ListItem>) => {

    switch (p.item.metadata.template.linkedEntity?.type) {
      case SurveyTemplateEntityType.Target:
        return (
          <TemplateTarget
            disabled={editing}
            value={p.item.value} />
        );

      case SurveyTemplateEntityType.Competitor:
        return (
          <TemplateCompetitor
            disabled={editing}
            value={p.item.value} />
        );

      case SurveyTemplateEntityType.EvaluationCriteria:
        return (
          <TemplateEvaluationCriteria
            disabled={editing}
            value={p.item.value} />
        );

      case SurveyTemplateEntityType.PartnershipCriteria:
        return (
          <TemplatePartnershipCriteria
            disabled={editing}
            value={p.item.value} />
        );

      case SurveyTemplateEntityType.TargetRole:
        return (
          <TemplateTargetRole
            disabled={editing}
            value={p.item.value} />
        );

      default:
        return <TextEntryListInput<ListItem> {...p} />;
    }

  }, [editing]);

  return (
    <div className={props.className}>
      {props.options.map(option => {
        const canRemove = props.removeOptionEnabled && option.metadata.canDelete;
        return (
          <TextEntryListItem<ListItem>
            key={option.ordinal}
            item={{
              key: option.ordinal,
              value: option.value,
              metadata: option.metadata,
            }}
            renderInput={renderItem}
            placeholder="Enter an answer choice"
            onRemove={props.removeOption(option.base.identifier)}
            onUpdate={props.updateOptionValue(option.base.identifier)}
            disabled={!option.metadata.canModifyValue}
            removeEnabled={canRemove}>
            {renderConditionalIcon(option)}
          </TextEntryListItem>
        );
      })}
      {props.addOptionEnabled &&
        <AddQuestionOptionButton
          onClick={props.addOption} />}
    </div>
  );
};

export default QuestionOptionsBuilder;
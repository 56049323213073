import { forwardRef, useCallback, useState, useMemo, memo } from 'react';
import { cx } from '@utils';
import { FocusableAnimation } from 'components/Focusable';
import { InputProps, InputRefObject } from './interfaces';
import styles from './style.css';

const Input = ({
  autoComplete = 'off',
  autoFocus = false,
  className,
  classes = {},
  focusableClasses = {},
  invalid = false,
  invalidClassName,
  onBlur,
  onFocus,
  rootClassName,
  ...other
}: InputProps, ref: InputRefObject) => {
  const [focused, setFocus] = useState(autoFocus);

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(false);
    onBlur?.(e);
  }, [onBlur]);

  const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    onFocus?.(e);
  }, [onFocus]);

  const invalidClasses = useMemo(() => cx(
    invalid && cx(invalidClassName, classes.invalid, focusableClasses.invalid)
  ), [invalid, invalidClassName, classes.invalid, focusableClasses.invalid]);

  const rootClasses = useMemo(() => cx(styles.root, rootClassName, classes.root), [rootClassName, classes.root]);
  const inputClasses = useMemo(() => cx(styles.input, className, classes.input), [className, classes.input]);

  return (
    <div className={rootClasses}>
      <input
        {...other}
        autoComplete={autoComplete}
        className={inputClasses}
        onBlur={handleBlur}
        onFocus={handleFocus}
        ref={ref} />
      <FocusableAnimation
        classes={{
          invalid: invalidClasses,
          focused: focusableClasses.focused,
          root: focusableClasses.root,
        }}
        focused={focused}
        readOnly={other.readOnly} />
    </div>
  );
};

const InputRef = memo(forwardRef<HTMLInputElement, InputProps>(Input));

export { InputRef as Input };
export default InputRef;
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { CallType } from '@enums';
import * as selectors from '@store/selectors';
import { selectUpcomingCalls } from '@store/selectors';
import { Call } from '@/types';
import { CallsAgenda, ConnectCalendar } from 'components/CallsAgenda';
import { BlankCalendar } from 'components/images';
import { AdHocCallAgendaItem } from './AgendaItem.AdHoc';
import { ProjectCallAgendaItem } from './AgendaItem.Project';
import styles from './style/Agenda.css';

type Props = unknown;

const Agenda = (props: Props) => {
  const connectedCalendar = useSelector(selectors.connectedCalendar);
  const items = useSelector(selectUpcomingCalls);
  const displayConnectCalendar = !items.length && !connectedCalendar;

  const renderItem = useCallback(({ item }: { item: Call; }) => {
    if (item.typeId === CallType.Project) {
      return (
        <ProjectCallAgendaItem item={item} />
      );
    } else if (item.typeId === CallType.AdHoc) {
      return <AdHocCallAgendaItem item={item} />;
    }
  }, []);

  const keyExtractor = useCallback((item: Call) => format(item.timeStart, 'MMM d'), []);

  return (
    <>
      <CallsAgenda
        ListEmptyComponent={ListEmpty}
        items={items}
        sectionKeyExtractor={keyExtractor}
        renderItem={renderItem} />
      {displayConnectCalendar &&
        <ConnectCalendar
          className={styles.connect} />
      }
    </>
  );
};

const ListEmpty = memo(() => {
  return (
    <div className={styles.empty}>
      <BlankCalendar
        className={styles.calendar} />
      <div className={styles.text}>No upcoming calls</div>
    </div>
  );
});

export { Agenda };
export default Agenda;
import { SurveyActionType, SurveyQuestionType } from '@enums';
import { SurveyQuestion } from '@/types';

const MultiselectActionTypes = [
  SurveyActionType.Disqualification,
  SurveyActionType.SkipToEnd,
  SurveyActionType.Flag,
  // SurveyActionType.None,
  SurveyActionType.IncludeSection,
];

const MultipleChoiceActionTypes = [
  SurveyActionType.Disqualification,
  SurveyActionType.Flag,
  // SurveyActionType.None,
  SurveyActionType.SkipToEnd,
  SurveyActionType.SkipToQuestion,
  SurveyActionType.SkipToSection,
  SurveyActionType.IncludeSection,
];

const MatrixGridActionTypes = [
  SurveyActionType.Disqualification,
  // SurveyActionType.None,
  SurveyActionType.IncludeSection,
];

const MatrixMultiselectActionTypes = [
  SurveyActionType.Disqualification,
  SurveyActionType.IncludeSection,
];

export const BaseActionTypes = [
  SurveyActionType.None,
  SurveyActionType.SkipToQuestion,
  SurveyActionType.SkipToEnd,
  SurveyActionType.SkipToSection,
  SurveyActionType.IncludeSection,
];

export const getValidResponseActionTypes = (item: SurveyQuestion): SurveyActionType[] => {
  switch (item.typeId) {
    case SurveyQuestionType.MatrixGrid:
      return MatrixGridActionTypes;

    case SurveyQuestionType.MultipleChoice:
      return MultipleChoiceActionTypes;

    case SurveyQuestionType.Multiselect:
      return MultiselectActionTypes;

    case SurveyQuestionType.MatrixMultiselect:
      return MatrixMultiselectActionTypes;

    default:
      return [];
  }

};
import { useSelectObject, useFolderCapabilities } from '@containers/Store';
import { useWorkspaceFolderState } from '@containers/Workspace/hooks/useWorkspaceFolderState';
import { useUploadFilesContext } from '@containers/WorkspaceFileUpload/hooks/useUploadFilesContext';
import { useFilesTableItems } from 'components/WorkspaceFiles/hooks/useFilesTableItems';
import FilesTable from './FilesTable';

export const FolderTabFiles = () => {
  const { folder } = useWorkspaceFolderState();
  const object = useSelectObject(folder.objectId);

  const capabilities = useFolderCapabilities(folder.id);
  const data = useFilesTableItems({ parentObjectId: folder.objectId });

  const uploadFiles = useUploadFilesContext({
    parentObjectId: object.id,
    workspaceId: object.workspaceId,
  });

  return (
    <FilesTable
      canUpload={capabilities.canAddChildren}
      data={data}
      onUpload={uploadFiles} />
  );
};

export default FolderTabFiles;
import { usePageContent } from '$website/containers';
import styles from './style/PressFeatures.css';

type Props = unknown;

const PressFeatures = (props: Props) => {
  const content = usePageContent<'main', 'press'>('press');

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{content.text.title}</h2>
        <div className={styles.main}>
          {content.collection.logos.map(x =>
            <div
              className={styles.item}
              key={x.image.src}>
              <img
                alt={x.image.alt}
                className={styles.img}
                src={x.image.src} />
            </div>)}
        </div>
      </div>
    </div>
  );
};

export { PressFeatures };
export default PressFeatures;
import { QuestionText } from '@presentation/Survey';
import { cx } from '@utils';
import { SurveyActionType } from './ActionType';
import {
  QuestionTextWithLogicProps,
} from './interfaces';
import styles from './style/QuestionTextWithLogic.css';

export const QuestionTextWithLogic = (props: QuestionTextWithLogicProps) => (
  <QuestionText
    item={props.item}
    textClassName={cx(props.className, styles.text)}>
    <SurveyActionType
      className={styles.action}
      action={props.item.logic.base.action} />
  </QuestionText>
);

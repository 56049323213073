import { createContext } from 'react';
import { LocationDescriptorObject } from 'history';
import * as API from '@api/interfaces';
import * as enums from '@enums';
import { StepperActions, createNamedContext } from '@utils';
import {
  Contact,
  IProjectSurveyResponseId,
  LegacyGroupProjectState,
  Project,
  ProjectGoal,
  ProjectParent,
  ProjectSurveyResponse,
  SurveyVersion,
  UserProfileProps,
  SurveyFiltering,
  SurveyQuestion,
} from '@/types';
import { ProjectOverviewFormState } from './interfaces';

export type ProjectState = {
  latestProject: Project<LegacyGroupProjectState>;
  project: Project<LegacyGroupProjectState> | null;
  projectParent: ProjectParent;
};

type GroupProjectGoal = {
  goal: ProjectGoal;
};

export type ProjectCloningStepperContextValue =
  StepperActions;

export type BaseGroupProjectCloningForm =
  Pick<Project,
      'id'
    | 'name'
    | 'description'>  &
  {
    frequency: enums.ProjectFrequency;
    goal: number;
    targetCompleteDate: Date;
  };

export type GroupProjectCloningForm =
  BaseGroupProjectCloningForm  &
  {
    survey: Pick<SurveyVersion, 'questions' | 'sections'>;
  };

export type GroupProjectCloningFormContextValue = {
  form: GroupProjectCloningForm;
  setFormState: (data: Partial<BaseGroupProjectCloningForm>) => void;
};

export type CloneGroupProjectContextValue = () => Promise<void>;

export type GroupProjectCloningContextValue = {
  actions: StepperActions;
  project: Project<LegacyGroupProjectState>;
  projectParent: ProjectParent;
  step: number;
  Routes: React.ComponentType;
};

export type SaveProjectOverview = (data: ProjectOverviewFormState) => Promise<Omit<API.Projects.UpdateProjectOverview.Response, 'object'>>;

export type AnonymousRespondent = {
  id: number;
  profile: Partial<UserProfileProps>;
  career: {
    current: Partial<Contact['career']['current']>;
    former: Partial<Contact['career']['former']>;
  };
};

export type SurveyRespondent =
  AnonymousRespondent |
  Contact;

export type RespondentResponseMetadata ={
  project: Project;
} &
  IProjectSurveyResponseId;

export type ProjectSurveyRespondentContextValue = {
  response: ProjectSurveyResponse;
  user: SurveyRespondent;
  versions: RespondentResponseMetadata[];
} & IProjectSurveyResponseId;

export type PipelineItemContextValue = {
  canHideUser: boolean;
  canRemoveUser: boolean;
  canRequestCall: boolean;
  canSelectTime: boolean;
  canSendSurveyInvite: boolean;
  canViewSurvey: boolean;

  onHideUser: () => void;
  onRemoveUser: () => void;
  onSubmitCallRequest: () => Promise<unknown>;

  projectSurveyLocation: LocationDescriptorObject;
  selectingLocation: LocationDescriptorObject;
};

export type ProjectActionsContextValue = {
  activate: () => void;
  archive: () => void;
  changeOwner: () => void;
  clone: () => void;
  downloadCallReport: () => unknown;
  downloadReport: () => unknown;
  downloadSurveyReport: () => unknown;
  editSurvey: () => void;
  manage: () => void;
  move: () => void;
  share: () => void;
  projectId: number;
  monitorSurvey: () => void;
};

export type ProjectActionsValidityContextValue = {
  canActivate: boolean;
  canArchive: boolean;
  canChangeOwner: boolean;
  canClone: boolean;
  canEditSurvey: boolean;
  canManage: boolean;
  canMonitorSurvey: boolean;
  canMove: boolean;
  canDownloadCalls: boolean;
  canDownloadSurveys: boolean;
  canDownloadReport: boolean;
  canShare: boolean;
  canPreview: boolean;
};

export type SurveyQuestionFilterContextValue = {
  filter: SurveyFiltering.FilterEntries;
  setFilter: (filter:SurveyFiltering.FilterEntries) => void;
  questionData: SurveyQuestion[];
}

export const ProjectResponseFilterContext = createNamedContext<SurveyQuestionFilterContextValue>(undefined, 'ProjectResponseFilterContext');
export const ProjectActionsContext = createContext<ProjectActionsContextValue>(undefined);
export const ProjectActionsValidityContext = createContext<ProjectActionsValidityContextValue>(undefined);

export const GroupProjectGoalContext = createContext<GroupProjectGoal | undefined>(undefined);
export const ProjectStateContext = createContext<ProjectState | undefined>(undefined);
export const SaveProjectOverviewContext = createContext<SaveProjectOverview | undefined>(undefined);

export const GroupProjectCloningFormContext = createContext<GroupProjectCloningFormContextValue | undefined>(undefined);
export const CloneGroupProjectContext = createContext<CloneGroupProjectContextValue | undefined>(undefined);
export const GroupProjectCloningContext = createContext<GroupProjectCloningContextValue | undefined>(undefined);

export const ProjectSurveyRespondentContext = createContext<ProjectSurveyRespondentContextValue | undefined>(undefined);
export const ProjectPipelineItemContext = createContext<PipelineItemContextValue | undefined>(undefined);

import { useEffect, useState } from 'react';
import { Room } from 'twilio-video';

// this is useless
export function useIsRecording(room: Room) {
  const [isRecording, setIsRecording] = useState(Boolean(room?.isRecording));

  useEffect(() => {
    if (room) {
      setIsRecording(room.isRecording);

      const handleRecordingStarted = () => setIsRecording(true);
      const handleRecordingStopped = () => setIsRecording(false);

      room.on('recordingStarted', handleRecordingStarted);
      room.on('recordingStopped', handleRecordingStopped);

      return () => {
        room.off('recordingStarted', handleRecordingStarted);
        room.off('recordingStopped', handleRecordingStopped);
      };
    }
  }, [room]);

  useEffect(() => {
    setIsRecording(Boolean(room?.isRecording));
  }, [room?.isRecording]);

  return isRecording;
}

export default useIsRecording;
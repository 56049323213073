import { Fragment, useCallback, useMemo } from 'react';
import { useRepeatQuestionResponsesData, useRepeatSurveyData, useRepeatSurveyResponsesState } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey as RS } from '@containers/RepeatSurveyResponses/interfaces';
import * as chart from '@containers/SurveyResponses/utils';
import { SurveyQuestionType } from '@enums';
import { AggregateDataHeaderRow, ProjectVersionDataRow, ResponseOption } from '@presentation';
import { roundToPlace } from '@utils';
import { RankingQuestion, SurveyQuestionOption } from '@/types';
import { SimpleAccordion } from 'components/Accordion';
import { useFilteredOptions, useQueryAccordionMap } from './hooks';
import styles from './style/Data.css';

export const RankingAggregateData = () => {
  const { query, resetQueryState, setQueryState } = useRepeatSurveyResponsesState<RS.QueryState.Ranking>();
  const { projectIds } = useRepeatSurveyData();
  const { aggregate, historical } = useRepeatQuestionResponsesData<SurveyQuestionType.Ranking>();
  const [isAccordionOpen, toggleAccordion] = useQueryAccordionMap('optionIds');

  const options = useFilteredOptions();

  const onVersionClick = useCallback((optionId: number, projectId: number) => () => {
    setQueryState({
      optionIds: [optionId],
      projectId,
    });
  }, [setQueryState]);

  const getOptionData = useCallback((option: SurveyQuestionOption) => {
    return aggregate[option.base.id];
  }, [aggregate]);

  const renderVersions = useCallback((option: SurveyQuestionOption) => {
    return [...projectIds]
      .sort((a, b) => b - a)
      .reduce((acc, projectId, i) => {
        const avg = historical[projectId]?.data?.indexes?.values?.[option.base.id]?.aggregate?.avg;
        if (avg !== undefined) {
          acc.push(
            <ProjectVersionDataRow
              key={projectId}
              label={`Avg. Rank ${roundToPlace(avg, 1)}`}
              onClick={onVersionClick(option.base.id, projectId)}
              version={projectIds.length - i} />
          );
        }

        return acc;
      }, []);
  }, [
    historical,
    onVersionClick,
    projectIds,
  ]);

  const renderOption = useCallback((option: SurveyQuestionOption) => {
    const data = getOptionData(option);
    return (
      <SimpleAccordion
        key={option.base.id}
        open={isAccordionOpen(option.base.id)}
        toggleOpen={toggleAccordion(option.base.id)}
        grows={false}
        className={styles.row}
        height={50}
        label={renderAccordionLabel(data, option)}>
        {renderVersions(option)}
      </SimpleAccordion>
    );
  }, [
    getOptionData,
    isAccordionOpen,
    renderVersions,
    toggleAccordion,
  ]);

  const sortedOptions = useMemo(() => {
    return [...options]
      .sort((a, b) => getOptionData(a).avg - getOptionData(b).avg);
  }, [
    getOptionData,
    options,
  ]);

  return <>
    <AggregateDataHeaderRow
      className={styles.row}
      label="Key"
      onClose={query.optionIds && resetQueryState} />
    {sortedOptions
      .map(option => (
        <Fragment key={option.base.id}>
          {renderOption(option)}
        </Fragment>
      ))
    }
  </>;
};

function renderAccordionLabel(data: RankingQuestion.RepeatData.Data, option: SurveyQuestionOption) {
  const color = chart.options.OptionsColors[option.ordinal - 1];
  const subtitle = `(Avg. Rank ${roundToPlace(data.avg, 1)})`;

  return (
    <ResponseOption
      color={color}
      label={option.value}
      subtitle={subtitle} />
  );
}

export default RankingAggregateData;
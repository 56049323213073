import { Link, LinkProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLocationFor, toTitleCase } from '@utils';
import { ComplianceReviewItem } from '@/types';
import styles from './style/ComplianceReview.css';

const mapState = (state: Store.State) => state.group;

type Props = {
  className?: string;
  item:       ComplianceReviewItem;
};

const ReviewLink = (props: Props) => {
  const group = useSelector(mapState);

  function renderLink(to: LinkProps['to']) {
    return (
      <Link
        className={styles.interaction}
        to={to}>
        {toTitleCase(props.item.type)}
      </Link>
    );
  }

  function renderReviewLocation() {
    switch (props.item.type) {

      case 'call':
        return renderLink(
          getLocationFor.compliance.review.call({
            callId: props.item.id,
            groupId: group.id,
          })
        );

      case 'message':
        return renderLink(
          getLocationFor.compliance.review.message({
            threadId: props.item.data.thread.id,
            groupId: group.id,
          })
        );

      case 'survey':
        return renderLink(
          getLocationFor.compliance.review.survey({
            responseId: props.item.id,
            groupId: group.id,
          })
        );

    }
  }

  return renderReviewLocation();
};

export { ReviewLink };
export default ReviewLink;
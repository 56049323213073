import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox } from 'components/Checkbox';

type Props = {
  checked: boolean;
  onChange: () => void;
};

const ItemCheckBox = (props: Props) => {
  const iconStyle = { width: 30, height: 30 };
  const style = { marginRight: 15 };

  return (
    <Checkbox
      checked={props.checked}
      checkedIcon={<CheckBoxIcon style={iconStyle} />}
      icon={<CheckBoxOutlineBlankIcon style={iconStyle} />}
      onChange={props.onChange}
      style={style} />
  );
};

export default ItemCheckBox;
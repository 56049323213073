import { useMemo } from 'react';
import { SurveyQuota } from '@/types';
import { useGetQuestionRawText, useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import styles from './style/Preview.Basic.css';

type Props = {
  item: SurveyQuota.Basic.Item;
};

export const BasicSurveyQuotaPreview = ({ item }: Props) => {
  const [state] = useSurveyBuilderState();
  const question = useMemo(() => {
    return state.survey.questions.find(f => f.base.identifier === item.question.identifier);
  }, [
    item.question.identifier,
    state.survey.questions,
  ]);

  const option = useMemo(() => {
    return question.options.find(f => f.base.identifier === item.condition.value.option.identifier);
  }, [
    item.condition.value.option.identifier,
    question.options,
  ]);

  const getQuestionText = useGetQuestionRawText();

  return (
    <div className={styles.root}>
      <div className={styles.question}>
        <div className={styles.ordinal}>Q{question.ordinal}:</div>
        {getQuestionText(question.value)}
      </div>
      <div className={styles.answer}>
        <div className={styles.ordinal}>A:</div>
        <div className={styles.condition}>{option.value}</div>
      </div>
    </div>
  );
};

export default BasicSurveyQuotaPreview;
import { useCallback, useEffect } from 'react';
import { useIsOwnProfile, useProfileState } from '@containers/AccountProfile';
import { useSelectUser } from '@containers/Store';
import { useRecentProfiles } from '@utils/api';
import * as api from '@api';
import * as mixpanel from '@services/mixpanel';
import useMatchUserIdFromUrl from './useMatchUserIdFromUrl';

export const useUserProfileTracking = () => {
  const { refetch } = useRecentProfiles({
    enabled: false,
  });

  const me = useSelectUser();
  const userId = useMatchUserIdFromUrl();
  const [user] = useProfileState();
  const isOwnProfile = useIsOwnProfile();

  const saveProfileVisit = useCallback(async () => {
    await api.profiles.trackProfileVisit({ userId });

    if (me.state.authenticated) {
      refetch();
    }
  }, [
    me.state.authenticated,
    refetch,
    userId,
  ]);

  useEffect(() => {
    if (!isOwnProfile) {
      mixpanel.event.profile.enter();
      saveProfileVisit();
    }
  }, [
    isOwnProfile,
    saveProfileVisit,
  ]);

  useEffect(() => {
    if (!isOwnProfile && user?.profile?.fullname) {

      return () => {
        mixpanel.event.profile.leave({
          name: user?.profile?.fullname,
          userId,
        });
      };
    }
  }, [
    isOwnProfile,
    user?.profile?.fullname,
    userId,
  ]);

};

export default useUserProfileTracking;
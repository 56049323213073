import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { ProjectType, UserProjectStatus } from '@enums';
import { ButtonActivityIndicator } from '@presentation';
import { useAsyncStateLazy, useDebounceValue } from '@utils';
import * as api from '$admin/api';
import { Select } from '$admin/components/Select';
import AutoComplete from 'components/AutoComplete';
import { ButtonSet } from 'components/Modal/ButtonSet';
import { Header } from 'components/Modal/Header';
import { Modal, ModalProps } from 'components/Modal/Modal';
import { Project } from '$admin/interfaces';
import styles from './style/Project.BulkAddToAProject.css';

type BulkAddItem = {
  user: Pick<Project.Members.Item['user'], 'id'>;
};

type Props = {
  project?: Descriptor;
  items: BulkAddItem[];
  onConfirm?: (project: Descriptor, status: StatusState, items: BulkAddItem[]) => unknown;
} & Pick<ModalProps, 'open' | 'onClose'>;

type StatusState = UserProjectStatus.Invited | UserProjectStatus.Recommended | UserProjectStatus.Staging;

export function BulkAddToAProject({ project, items, open, onClose, onConfirm }: Props) {
  const [selectedStatus, setSelectedStatus] = useState<StatusState>(UserProjectStatus.Staging);
  const [selectedProject, setSelectedProject] = useState<Descriptor>();

  const handleSubmit = useCallback(() => {
    return api.projects.members.bulkAddToAProject({
      targetProjectId: selectedProject.id,
      statusId: selectedStatus,
      userIds: items.map(i => i.user.id),
    })
    .then(result => {
      onConfirm(selectedProject, selectedStatus, items);
      return result;
    });
  }, [items, selectedProject, selectedStatus, onConfirm]);

  const [response, submit] = useAsyncStateLazy(handleSubmit, [handleSubmit]);

  const canSubmit = useMemo(() => !!selectedProject?.id, [selectedProject?.id]);

  return (
    <Modal
      onClose={onClose}
      open={open}>
      <div className={styles.root}>
        <Header>{`Add ${items.length} member${items.length > 1 ? 's' : ''} to a Project`}</Header>
        <div className={styles.row}>
          <div className={styles.label}>Target Project</div>
          {selectedProject
            ? (
              <div className={styles.project}>
                {`${selectedProject.name} (${selectedProject.id})`}
                <div
                  onClick={() => setSelectedProject(null)}
                  className={styles.clear}>
                  ✕
                </div>
              </div>
            )
            : (
              <ProjectAutocomplete
                excludeIds={project ? [project.id] : []}
                onSelect={item => setSelectedProject(item)} />
            )}
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Status</div>
          <Select
            className={styles.field}
            defaultValue={UserProjectStatus.Staging}
            id="status"
            name="status"
            onChange={e => setSelectedStatus(+e.target.value as StatusState)}
            options={OptionsMap}
            value={selectedStatus} />
        </div>
        <div className={styles.footer}>
          <ButtonSet>
            <ButtonActivityIndicator
              className={styles.btn}
              disabled={!canSubmit}
              loading={response.loading}
              onClick={submit}
              variant="brick">
              Add
            </ButtonActivityIndicator>
          </ButtonSet>
        </div>
      </div>
    </Modal>
  );
}

type ProjectAutocompleteProps = {
  excludeIds: number[];
  onSelect: (item: Descriptor) => unknown;
};

function ProjectAutocomplete({ excludeIds, onSelect }: ProjectAutocompleteProps) {
  const [text, setText] = useState('');
  const [items, setItems] = useState<Descriptor[]>([]);
  const name = useDebounceValue(text, 150);

  useQuery(['admin-project-autocomplete', name], async () => {
    const result = await api.projects.autocomplete({
      name,
      projectType: [ProjectType.Call, ProjectType.Survey, ProjectType.SurveyCall],
      isSourcing: true,
    });

    setItems(result.filter(r => !excludeIds.includes(r.id)).slice(0, 5));

    return result;
  }, {
    enabled: !!name.length,
    refetchOnWindowFocus: false,
    cacheTime: 5000,
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length) {
      setText(value);
    } else {
      setItems([]);
      setText('');
    }
  }, []);

  const handleSelect = useCallback((item: Descriptor) => {
    onSelect(item);
    setText('');
  }, [onSelect]);

  return (
    <AutoComplete
      getItemValue={(item: Descriptor) => `${item.name} (${item.id})`}
      items={items}
      name="projects"
      onChange={handleChange}
      onSelect={handleSelect}
      placeholder="Search for a project"
      value={text} />
  );
}

const OptionsMap = {
  [UserProjectStatus.Staging]: 'Staging',
  [UserProjectStatus.Recommended]: 'Recommended',
  [UserProjectStatus.Invited]: 'Invited',
};
import { SurveyQuestionConditionType, SurveyQuotaType } from '@enums';
import { SurveyQuota as SQ } from '@/types';
import { SurveyQuota } from './interfaces';
import {
  getDefaultAdvancedSet,
  getDefaultAdvancedSetItem,
  getDefaultBasicItem,
} from './utils';

function baseReducer(state: Omit<SurveyQuota.State, 'data'>, action: SurveyQuota.Action) {
  switch (action.type) {

    case 'update-action':
      return {
        ...state,
        actionType: action.value,
      };

    case 'update-limit':
      return {
        ...state,
        limit: action.value,
      };

    case 'update-name':
      return {
        ...state,
        name: action.value,
      };

    default:
      return state;
  }
}

function basicDataReducer(state: SQ.Basic.Value['items'], action: SurveyQuota.Action): SQ.Basic.Value['items'] {
  switch (action.type) {
    case 'add-basic-item': {
      return [
        ...state,
        getDefaultBasicItem(),
      ];
    }

    case 'remove-basic-item': {
      return state.filter(f => f.identifier !== action.identifier);
    }

    case 'update-basic-item-question': {
      return state.reduce((acc, x) => {
        if (x.identifier === action.identifier) {
          acc.push({
            ...x,
            condition: {
              type: SurveyQuestionConditionType.Chosen,
              value: null,
            },
            question: action.question,
          });
        } else {
          acc.push(x);
        }
        return acc;
      }, []);
    }

    case 'update-basic-item-condition': {
      return state.reduce((acc, x) => {
        if (x.identifier === action.identifier) {
          acc.push({
            ...x,
            condition: {
              ...x.condition,
              value: action.value,
            },
          });
        } else {
          acc.push(x);
        }
        return acc;
      }, []);
    }

    default:
      return state;
  }
}

function advancedDataReducer(state: SQ.Advanced.Value['sets'], action: SurveyQuota.Action): SQ.Advanced.Value['sets']  {
  switch (action.type) {
    case 'add-advanced-item': {
      return [
        ...state,
        getDefaultAdvancedSet(),
      ];
    }

    case 'remove-advanced-item': {
      return state.filter(f => f.identifier !== action.setIdentifier);
    }

    case 'update-advanced-item-question': {
      return state.reduce((acc, x) => {
        if (x.identifier === action.setIdentifier) {
          acc.push({
            ...x,
            items: [getDefaultAdvancedSetItem()],
            question: action.question,
          });
        } else {
          acc.push(x);
        }
        return acc;
      }, []);
    }

    case 'add-advanced-item-condition': {
      return state.reduce((acc, set) => {
        if (set.identifier === action.setIdentifier) {
          acc.push({
            ...set,
            items: [
              ...set.items,
              getDefaultAdvancedSetItem(),
            ],
          });

        } else {
          acc.push(set);
        }
        return acc;
      }, []);
    }

    case 'remove-advanced-item-condition': {
      return state.reduce((acc, set) => {
        if (set.identifier === action.setIdentifier) {
          acc.push({
            ...set,
            items: set.items.filter(f => f.identifier !== action.identifier),
          });

        } else {
          acc.push(set);
        }
        return acc;
      }, []);
    }

    case 'update-advanced-item-condition-pct': {
      return state.reduce((acc, set) => {
        if (set.identifier === action.setIdentifier) {
          const items = set.items.reduce((acc2, item) => {
            if (item.identifier === action.identifier) {
              acc2.push({
                ...item,
                pct: action.value,
              });
            } else {
              acc2.push(item);
            }

            return acc2;
          }, []);

          acc.push({
            ...set,
            items,
          });

        } else {
          acc.push(set);
        }
        return acc;
      }, []);
    }

    case 'update-advanced-item-condition-value': {
      return state.reduce((acc, set) => {
        if (set.identifier === action.setIdentifier) {
          const items = set.items.reduce((acc2, item) => {
            if (item.identifier === action.identifier) {
              acc2.push({
                ...item,
                condition: {
                  ...item.condition,
                  value: action.value,
                },
              });
            } else {
              acc2.push(item);
            }

            return acc2;
          }, []);

          acc.push({
            ...set,
            items,
          });

        } else {
          acc.push(set);
        }
        return acc;
      }, []);
    }

    default:
      return state;
  }
}

export function surveyQuotaReducer(state: SurveyQuota.State, action: SurveyQuota.Action): SurveyQuota.State {

  if (state.type === SurveyQuotaType.Basic) {
    return {
      ...baseReducer(state, action),
      type: SurveyQuotaType.Basic,
      data: {
        items: basicDataReducer(state.data.items, action),
      },
    };
  } else if (state.type === SurveyQuotaType.Advanced) {
    return {
      ...baseReducer(state, action),
      type: SurveyQuotaType.Advanced,
      data: {
        sets: advancedDataReducer(state.data.sets, action),
      },
    };
  }

  return null;
}
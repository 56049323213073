import { useEffect, useState } from 'react';
import { Participant, RemoteParticipant, Room, TrackPublication } from 'twilio-video';
import { isScreenTrack } from 'components/Conference.Video/constants';

export function useScreenShareParticipant(room: Room) {
  const [sharer, setSharer] = useState<Participant>();

  useEffect(() => {
    if (room) {
      const updateSharer = () => {
        setSharer(
          Array.from<Participant>((room.participants ?? new Map<string, RemoteParticipant>()).values())
            .concat(room?.localParticipant)
            .filter(Boolean)
            .find((participant: Participant) =>
              Array.from<TrackPublication>(participant.tracks.values()).find(track => isScreenTrack(track.trackName))
            )
        );
      };
      updateSharer();

      room.on('trackPublished', updateSharer);
      room.on('trackUnpublished', updateSharer);
      room.on('participantDisconnected', updateSharer);

      // the room object does not emit 'trackPublished' events for the localParticipant,
      // so we need to listen for them here.
      if (room.localParticipant) {
        room.localParticipant.on('trackPublished', updateSharer);
        room.localParticipant.on('trackUnpublished', updateSharer);
      }

      return () => {
        room.off('trackPublished', updateSharer);
        room.off('trackUnpublished', updateSharer);
        room.off('participantDisconnected', updateSharer);

        if (room.localParticipant) {
          room.localParticipant.off('trackPublished', updateSharer);
          room.localParticipant.off('trackUnpublished', updateSharer);
        }
      };
    }
  }, [room]);

  return sharer;
}

export default useScreenShareParticipant;
import { Helmet } from 'react-helmet';
import { useProjectMetricsLoading } from '@containers';
import { ScreenLayout } from '@screens/GroupMain/Layout';
import styles from '@screens/GroupMain/style/Main.css';
import AgendaCard from '@screens/GroupMain/AgendaCard';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ProjectsPanel from './ProjectsPanel';

const GroupProjects = () => {
  const metricsLoading = useProjectMetricsLoading();

  return (
    <ScreenLayout>
      <Helmet title="Projects" />
      <div className={styles.col}>
        {metricsLoading
          ? <ActivityIndicator show />
          : <ProjectsPanel />
        }
      </div>

      <div className={styles.col}>
        <div className={styles.sidebar}>
          <AgendaCard />
        </div>
      </div>

    </ScreenLayout>
  );
};

export { GroupProjects };
export default GroupProjects;
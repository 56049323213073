import { useRef, useState, useEffect } from 'react';
import { Pause, Play } from 'react-feather';
import styles from '$website/screens/Main/style/HowItWorks.css';
import { cx } from '@utils';
import poster from 'static/images/website/carl-on-beach.png';

const video = `https://cdn.vancery.com/videos/landing_1.mp4`;

const Video = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loadStart, setLoadStart] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(false);
  const [pause, setPause] = useState<boolean>(false);
  const [play, setPlay] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const [hasStarted, setHasStarted] = useState<boolean>(false);

  const player = useRef(null);

  const onEnded = () => {
    setPause(false);
    setPlay(false);
    setPlaying(false);
  };

  const onLoadedData = e => {
    setLoaded(true);
  };

  const onLoadStart = e => {
    setLoadStart(true);
  };

  const onPause = e => {
    setPause(true);
    setPlay(false);
  };

  const onPlay = e => {
    setHasStarted(true);
    setPause(false);
    setPlay(true);
  };

  const onPlaying = e => {
    setPlaying(true);
  };

  useEffect(() => {
    if (player?.current) {
      player.current.addEventListener('ended', onEnded);
      player.current.addEventListener('loadeddata', onLoadedData);
      player.current.addEventListener('loadstart', onLoadStart);
      player.current.addEventListener('pause', onPause);
      player.current.addEventListener('play', onPlay);
      player.current.addEventListener('playing', onPlaying);
    }

    return () => {
      if (player?.current) {
        player.current.removeEventListener('ended', onEnded);
        player.current.removeEventListener('loadeddata', onLoadedData);
        player.current.removeEventListener('loadstart', onLoadStart);
        player.current.removeEventListener('pause', onPause);
        player.current.removeEventListener('play', onPlay);
        player.current.removeEventListener('playing', onPlaying);
      }
    };
  }, [
    player,
    onEnded,
    onLoadedData,
    onLoadStart,
    onPause,
    onPlay,
    onPlaying,
  ]);

  const togglePlaying = () => {
    if (player.current && pause || !playing) {
      player.current.play();
    } else {
      player.current.pause();
    }
  };

  const toggleMuted = () => {
    setMuted(!muted);
  };

  const handleRestart = () => {
    player.current.play();
    setPlaying(true);
  };

  const renderControls = () => {
    if (!loadStart) {
      return null;
    }

    const controlsClass = cx({
      [styles.controls]: !playing,
      [styles.paused]: playing && pause,
      [styles.playing]: playing && !pause,
    });

    const pauseClass = cx({
      [styles.pause]: playing,
      [styles.hide]: !playing,
    });

    const playClass = cx({
      [styles.play]: pause || !playing,
      [styles.hide]: playing,
    });

    return (
      <>
        <div
          className={controlsClass}
          onClick={togglePlaying}>
          <div className={playClass}>
            <Play className={styles.control} />
          </div>
          <div
            className={pauseClass}
            onClick={togglePlaying}>
            <Pause className={styles.control} />
          </div>
        </div>
      </>
    );
  };

  const className = cx({
    [styles.video]: playing,
    [styles.poster]: !playing,
  });

  const containerClassName = cx({
    [styles.videoContainer]: playing,
    [styles.posterContainer]: !playing,
  });

  return (
    <div className={styles.box}>
      <video
        className={styles.player}
        controls={false}
        playsInline
        preload='metadata'
        ref={player}
        src={video} />
      {renderControls()}
      <div className={containerClassName}>
        <img
          alt='Vancery Video Poster'
          className={className}
          draggable={false}
          src={poster} />
      </div>
    </div>
  );
};

export default Video;
import { createContext, useContext, Dispatch } from 'react';
import { SurveyActionType, SurveyQuestionConditionType } from '@enums';
import {
  SurveyLogic,
  SurveyQuestion,
  MatrixGridQuestion,
  MultipleChoiceQuestion,
} from '@/types';
import { ActionOrdinalItem, MatrixQuestionLogic, OptionsQuestionLogic } from './interfaces';

export type QuestionLogicBuilderState = {
  onCancel: () => unknown;
  onSubmit: (logic: SurveyQuestion['logic']) => unknown;

  item: SurveyQuestion;

  baseActionTypes: SurveyActionType[];
  responseActionTypes: SurveyActionType[];

  includeSectionOptions: ActionOrdinalItem[];
  skipToQuestionOptions: ActionOrdinalItem[];
  skipToSectionOptions: ActionOrdinalItem[];
};

type MatrixQuestionLogicStateValue = [
  MatrixQuestionLogic.State,
  Dispatch<MatrixQuestionLogic.Action>,
];

type OptionsQuestionLogicStateValue = [
  OptionsQuestionLogic.State,
  Dispatch<OptionsQuestionLogic.Action>,
];

type MatrixRowLogicContextValue = {
  addItem: () => void;
  removeItem: (id: string) => () => void;
  canAddItem: boolean;
  items: MatrixGridQuestion.Logic.Response[];
  updateActionIdentifier: (id: string) => (identifier: string) => void;
  updateActionType: (id: string) => (type: SurveyActionType) => void;
  updateConditionValue: (id: string) => (identifier: string) => void;
  updateConditionType: (id: string) => (type: SurveyQuestionConditionType) => void;
};

type OptionsQuestionLogicContextValue = {
  addItem: () => void;
  items: MultipleChoiceQuestion.Logic.Response[];
  removeItem: (id: string) => () => void;
  updateActionIdentifier: (id: string) => (identifier: string) => void;
  updateActionType: (id: string) => (type: SurveyActionType) => void;
  updateConditionValue: (id: string) => (identifier: string) => void;
  updateConditionType: (id: string) => (type: SurveyQuestionConditionType) => void;
};

type BaseLogicContextValue = {
  item: SurveyLogic.Base;
  updateActionType: (type: SurveyActionType) => void;
  updateActionIdentifier: (identifier: string) => void;
};

export const BaseLogicContext = createContext<BaseLogicContextValue>(null!);
export const MatrixRowLogicContext = createContext<MatrixRowLogicContextValue>(null!);
export const MatrixQuestionLogicStateContext = createContext<MatrixQuestionLogicStateValue>(null!);
export const OptionsQuestionLogicStateContext = createContext<OptionsQuestionLogicStateValue>(null!);
export const OptionsQuestionLogicContext = createContext<OptionsQuestionLogicContextValue>(null!);
export const QuestionLogicBuilderContext = createContext<QuestionLogicBuilderState>(null!);

export const useBaseLogicContext = () => useContext(BaseLogicContext);
export const useMatrixRowLogicContext = () => useContext(MatrixRowLogicContext);
export const useMatrixQuestionLogic = () => useContext(MatrixQuestionLogicStateContext);
export const useOptionsQuestionLogicState = () => useContext(OptionsQuestionLogicStateContext);
export const useOptionsQuestionLogicContext = () => useContext(OptionsQuestionLogicContext);
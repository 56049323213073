import { cx } from '@utils';
import Bank from 'components/icons/Bank';
import styles from './style/ExistingBank.css';

type Props = {
  className?: string;
  bankName: string;
  currency: string;
  last4: string;
  verified: boolean;
};

export function ExistingBank({ className, bankName, currency, last4, verified }: Props) {
  return (
    <div className={cx(styles.root, className)}>
      <Bank className={styles.logo} />
      <div>
        <div className={styles.account}>
          <div className={styles.number}>••••{last4}</div>
          <div className={styles.currency}>{currency.toUpperCase()}</div>
          <Verified value={verified} />
        </div>
        <div className={styles.name}>{bankName}</div>
      </div>
    </div>
  );
}

type VerifiedProps = {
  value: Props['verified'];
};

function Verified({ value }: VerifiedProps) {
  return value
    ? <div className={styles.verified}>(Verified)</div>
    : <div className={styles.notverified}>(Not Verified)</div>;
}

export default ExistingBank;
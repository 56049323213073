import { useState, forwardRef } from 'react';
import * as api from '@api';
import { toTitleCase } from '@utils';
import AutoComplete from 'components/AutoComplete';

const minimumCustomLength = 3;

const SearchAutocomplete = ({
  onSelection,
  searchType,
  filterType,
  selectedItems,
  placeholder = '',
  autocompleteClass,
  displayCustomItem = true,
  displaySearchItems = true,
  minCharactersForFetch = 1,
  ...props
}, ref) => {

  const [results, setResults] = useState([]);
  const [inputText, setInputText] = useState('');

  const handleChange = value => {
    if (props.onChange) {
      return props.onChange({
        ids: selectedItems.map(x => x.id),
        value,
      });
    }

    return fetchSearchResults(value);
  };

  const handleSearchChange = e => {
    const text = e.target.value;
    setInputText(text);

    if (!text) {
      return;
    }

    if (displaySearchItems &&
        text.length >= minCharactersForFetch) {
      handleChange(text).then(setResults);
    }
  };

  const handleSearchSelect = item => {
    if (item.isCustom && displayCustomItem) {
      if (inputText.length >= minimumCustomLength) {
        onSelection({
          id: `${searchType}~${inputText}`,
          name: toTitleCase(item.name),
          isUserCreated: true,
        });
      }

      setInputText('');
      setResults([]);
    } else if (item.name.length) {
      onSelection(item);

      setInputText('');
      setResults([]);
    }
  };

  const fetchSearchResults = value => {
    const ignoreList = selectedItems.map(m => `${m.id}_${searchType}`);

    return api.search.getAutocompleteResults({
      type: searchType,
      text: value,
      ignoreList,
    });
  };

  const compareNames = (a, b) => {
    return (a || '').trim().toUpperCase() === (b || '').trim().toUpperCase();
  };

  const showCustomItem = () => {
    return displayCustomItem
           && inputText.trim().length >= minimumCustomLength
           && ![...selectedItems, ...results].some(s => compareNames(s.name, inputText));
  };

  const getResultsToShow = () => {
    if (showCustomItem()) {
      const customItem = {
        id: `${searchType}~${inputText}`,
        name: inputText,
        type: searchType,
        isCustom: true,
      };

      return [customItem, ...results];
    } else {
      return results;
    }
  };

  return (
    <>
      <div className={autocompleteClass}>
        <AutoComplete.Tags
          getItemValue={item => item.name}
          items={getResultsToShow()}
          placeholder={placeholder}
          name={filterType}
          onChange={handleSearchChange}
          onSelect={handleSearchSelect}
          refProp={ref} />
      </div>
    </>
  );
};

export default forwardRef(SearchAutocomplete);
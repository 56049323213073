import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHasConsultantRole } from '@containers/User';
import { UserSocketEvent } from '@enums';
import * as actions from '@store/actions';
import { useHydrateEffect, useSignOutEffect } from '@containers/AppReadyState';
import * as ws from '@services/websocket';
import { WS } from '@/types';

type Props =
  ChildrenProps;

export const UserSocketContainer = (props: Props) => {
  const dispatch = useDispatch();
  const isConsultant = useHasConsultantRole();

  const handleInfoUpdated = useCallback((data: WS.User.InfoUpdated.Payload) => {
    dispatch(actions.userInfoChange({ ...data }));
  }, [dispatch]);

  const handleStripeConnected = useCallback((data: WS.User.StripePayoutConnected.Payload) => {
    const payoutSetup = {
      stripeAccountId: data.accountId,
    };

    dispatch(actions.userInfoChange({ payoutSetup }));
  }, [dispatch]);

  const register = useCallback(() => {
    if (isConsultant) {
      ws.user.on(UserSocketEvent.InfoUpdated, handleInfoUpdated);
      ws.user.on(UserSocketEvent.StripePayoutConnected, handleStripeConnected);

      ws.user.open();
    }
  }, [
    handleInfoUpdated,
    handleStripeConnected,
    isConsultant,
  ]);

  const unregister = useCallback(() => {
    if (isConsultant) {
      ws.user.close();

      ws.user.off(UserSocketEvent.InfoUpdated, handleInfoUpdated);
      ws.user.off(UserSocketEvent.StripePayoutConnected, handleStripeConnected);
    }
  }, [
    handleInfoUpdated,
    handleStripeConnected,
    isConsultant,
  ]);

  useHydrateEffect(register);
  useSignOutEffect(unregister);

  useEffect(() => {
    return () => {
      unregister();
    };
  }, [unregister]);

  return (
    <>
      {props.children}
    </>
  );
};

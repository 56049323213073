import { Fragment } from 'react';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { useIsProjectTypeCallLike, useSelectProject } from '@containers/Store';
import { useRecordingConsentState } from '@containers/Call/hooks/useRecordingConsentState';
import { useProjectSurveyPayment } from '@containers/ProjectOnboarding/hooks/useProjectSurveyPayment';
import { useProjectRequestCallRate } from '@containers/ProjectOnboarding/hooks';
import * as enums from '@enums';
import { Copy } from '@screens/ProjectDetails/Copy';
import { ScreeningQuestions } from '@screens/ProjectDetails/ScreeningQuestions';
import { ShareProject } from '@screens/ProjectDetails/ShareProject';
import { Title } from '@screens/ProjectDetails/Title';
import { cx } from '@utils';
import { Spinner } from 'components/ActivityIndicator';
import { BackButton } from 'components/BackButton';
import { ButtonSet } from './RequestReview.ButtonSet';
import { ProjectRequestReviewProps } from './interfaces';
import styles from './style.css';

type Props =
  ProjectRequestReviewProps;

export const ProjectRequestReview = (props: Props) => {
  const project = useSelectProject(props.projectId);
  const consent = useRecordingConsentState();
  const { data: surveyPayment, isLoading } = useProjectSurveyPayment({
    projectId: props.projectId,
    projectType: project?.projectType,
  }, {
    refetchOnWindowFocus: false,
  });
  const hasCallLikeType = useIsProjectTypeCallLike(props.projectId);
  const { data: callRate } = useProjectRequestCallRate({ projectId: props.projectId });
  const { theme } = useSurveyThemingPalette();

  if (!project || isLoading) return null;

  const showCallRate = hasCallLikeType && !!callRate;
  const showDuration = !hasCallLikeType;
  const showSurveyRate = !hasCallLikeType && surveyPayment !== 0;
  const loadingConsent = hasCallLikeType && !consent;

  return (
    <div className={cx(styles.root, { [styles.theme]: theme })}>
      <div className={styles.wrap}>
        {!theme &&
          <div className={styles.link}>
            <BackButton />
          </div>}

        <div className={styles.main}>
          <div className={styles.left}>
            <div className={styles.row}>
              <Title text="Title" />
              <Copy text={project.name} />
            </div>

            {showCallRate &&
              <div className={styles.row}>
                <Title text="Hourly Rate" />
                <Copy text={`$${callRate}`} />
              </div>}

            {showDuration &&
              <div className={styles.row}>
                <Title text="Estimated Length" />
                <Copy text="10 - 15 minutes" />
              </div>}

            {showSurveyRate &&
              <div className={styles.row}>
                <Title text="Payment" />
                <Copy text={`$${surveyPayment}`} />
              </div>}

            <div className={styles.row}>
              <Title text="Description" />
              <Copy text={project.description} />
            </div>

            {project.isExternal &&
              <div className={cx(styles.row, styles.externalWarning)}>
                {copy.external}
              </div>}

            {project.projectType === enums.ProjectType.Call &&
              <ScreeningQuestions {...project} />}

            <div className={styles.consent}>
              {loadingConsent
                ? (
                  <div className={styles.spinner}>
                    <Spinner color="var(--gray)" />
                  </div>)
                : (!hasCallLikeType || hasCallLikeType && !consent?.requireRecordingConsent)
                  ? <ButtonSet
                      onAccept={props.onRequestAccepted}
                      onReject={props.onRequestDeclined} />
                  : (
                    <div className={styles.recording}>
                      <Copy text={copy.consent} />
                      <ButtonSet
                        onAccept={props.onRequestAccepted}
                        onReject={props.onRequestDeclined} />
                    </div>)}
            </div>
          </div>

          {(!project.memberReferrals
            ? null
            :  !theme) &&
              <Fragment>
                <div className={styles.divider} />
                <div className={styles.right}>
                  <ShareProject project={project} />
                </div>
              </Fragment>}
        </div>
      </div>
    </div>
  );
};

const copy = {
  consent: `By accepting this project, I consent to be recorded and transcribed`,
  external: `This survey will direct you to a third party website. If you qualify, upon completion you will be redirected to Vancery to receive compensation.`,
};
import extend from '@enums/extend';

export enum VICS {
  Sector      = 1,
  Industry    = 2,
  SubIndustry = 3,
}

const map = new Map([
  [VICS.Sector, { name: 'Sector' }],
  [VICS.Industry,    { name: 'Industry'    }],
  [VICS.SubIndustry, { name: 'Sub-Industry' }],
]);

export default {
  VICS: extend(map),
};
import { Calendar, MessageSquare } from 'react-feather';
import { useInitiatePlatformMessage } from '@containers/PlatformMessage/hooks/useInitiatePlatformMessage';
import { useMessagingEnabled } from '@containers/Store';
import { RequestCallContainer } from '@containers/RequestCall/RequestCallContainer';
import { useRequestCallWithUser } from '@containers/RequestCall/hooks/useRequestCall';
import { AskAnExpertItem } from '@/types';
import styles from './style/Actions.css';

type Props = {
 item: AskAnExpertItem;
};

const StateDependent = ({ item }: Props) => {

  const requestCall = useRequestCallWithUser(item.contact);

  const messagingEnabled = useMessagingEnabled();

  const initiatePlatformMessage = useInitiatePlatformMessage();
  const [initiateMessage, MessageModal] = initiatePlatformMessage({
    id: item.id,
    profile: item.contact.profile,
  });

  return (
    <>
      <div className={styles.root}>
        {messagingEnabled &&
          <MessageSquare
            className={styles.message}
            size={20}
            onClick={initiateMessage} />}
        <Calendar
          className={styles.icon}
          size={20}
          onClick={requestCall} />
      </div>

      <MessageModal />
    </>
  );
};

export const ExpertActions = (props: Props) => (
  <RequestCallContainer>
    <StateDependent {...props} />
  </RequestCallContainer>
);

export default ExpertActions;
import { SurveyOptionType } from '@enums';
import { SurveyQuestionOption } from '@/types';
import { SurveyQuestionsBuilder, SurveySettingsBuilder, SurveyBuilder } from '../interfaces.state';
import { generateNewOption } from './defaults';

export function toggleNoneOfTheAbove(state: SurveyQuestionsBuilder.State, action: SurveySettingsBuilder.ToggleNoneOfTheAbove.Action): SurveyQuestionsBuilder.State {

  if (action.value) {
    return state.reduce((acc, q) => {
      if (q.base.identifier === action.questionIdentifier) {

        const otherOption = q.options.find(f => f.type === SurveyOptionType.Other);
        const options = [
          ...q.options.filter(f => f.type !== SurveyOptionType.Other),
          otherOption,
          generateNewOption({
            metadata: {
              canDelete: false,
              canModifyValue: false,
              template: {},
            },
            ordinal: null,
            type: SurveyOptionType.NoneOfTheAbove,
            value: 'None of the above',
          }),
        ]
        .filter(Boolean)
        .map((m, i) => ({
          ...m,
          ordinal: i + 1,
        }));

        acc.push({
          ...q,
          options,
          settings: {
            ...q.settings,
            includeNoneOfTheAbove: true,
          },
        });
      } else {
        acc.push(q);
      }

      return acc;
    }, []);
  } else {
    const question = state.find(f => f.base.identifier === action.questionIdentifier);
    const removeItem = question.options.find(o => o.type === SurveyOptionType.NoneOfTheAbove);
    const identifier = removeItem.base.identifier;
    const ordinals = generateNewOrdinals(question.options, removeItem.ordinal);

    const options = question.options
      .filter(f => f.base.identifier !== identifier)
      .map(option => ({
        ...option,
        ordinal: ordinals[option.ordinal],
      }));

    const responseLogic = question.logic.response
      .filter(f => f.condition.value.option.identifier !== identifier);

    return state.reduce((acc, q) => {
      if (q.base.identifier === action.questionIdentifier) {
        acc.push({
          ...q,
          logic: {
            ...q.logic,
            response: responseLogic,
          },
          options,
          settings: {
            ...q.settings,
            includeNoneOfTheAbove: false,
          },
        });
      } else {
        acc.push(q);
      }
      return acc;
    }, []);
  }
}

export function toggleAllowOther(state: SurveyQuestionsBuilder.State, action: SurveySettingsBuilder.ToggleAllowOther.Action): SurveyQuestionsBuilder.State {
  if (action.value) {
    return state.reduce((acc, q) => {
      if (q.base.identifier === action.questionIdentifier) {

        const naOption = q.options.find(f => f.type === SurveyOptionType.NoneOfTheAbove);

        const options = [
          ...q.options.filter(f => f.type !== SurveyOptionType.NoneOfTheAbove),
          generateNewOption({
            metadata: {
              canDelete: false,
              canModifyValue: false,
              template: {},
            },
            ordinal: null,
            type: SurveyOptionType.Other,
            value: 'Other',
          }),
          naOption,
        ]
        .filter(Boolean)
        .map((m, i) => ({
          ...m,
          ordinal: i + 1,
        }));

        acc.push({
          ...q,
          options,
          settings: {
            ...q.settings,
            allowOther: true,
          },
        });
      } else {
        acc.push(q);
      }

      return acc;
    }, []);
  } else {
    const question = state.find(f => f.base.identifier === action.questionIdentifier);
    const removeItem = question.options.find(o => o.type === SurveyOptionType.Other);
    const identifier = removeItem.base.identifier;
    const ordinals = generateNewOrdinals(question.options, removeItem.ordinal);

    const options = question.options
      .filter(f => f.base.identifier !== identifier)
      .map(option => ({
        ...option,
        ordinal: ordinals[option.ordinal],
      }));

    const responseLogic = question.logic.response
      .filter(f => f.condition.value.option.identifier !== identifier);

    return state.reduce((acc, q) => {
      if (q.base.identifier === action.questionIdentifier) {
        acc.push({
          ...q,
          logic: {
            ...q.logic,
            response: responseLogic,
          },
          options,
          settings: {
            ...q.settings,
            allowOther: false,
          },
        });
      } else {
        acc.push(q);
      }
      return acc;
    }, []);
  }
}

export function toggleNotApplicable(state: SurveyQuestionsBuilder.State, action: SurveySettingsBuilder.ToggleNotApplicable.Action): SurveyQuestionsBuilder.State {
  if (action.value) {
    return state.reduce((acc, q) => {
      if (q.base.identifier === action.questionIdentifier) {
        acc.push({
          ...q,
          options: [
            ...q.options,
            generateNewOption({
              metadata: {
                canDelete: false,
                canModifyValue: false,
                template: {},
              },
              ordinal: q.options.length + 1,
              type: SurveyOptionType.NotApplicable,
              value: 'N/A',
            }),
          ],
          settings: {
            ...q.settings,
            includeNotApplicable: true,
          },
        });
      } else {
        acc.push(q);
      }
      return acc;
    }, []);
  } else {
    return state.reduce((acc, q) => {
      if (q.base.identifier === action.questionIdentifier) {
        acc.push({
          ...q,
          options: q.options.filter(f => f.type !== SurveyOptionType.NotApplicable),
          settings: {
            ...q.settings,
            includeNotApplicable: false,
          },
        });
      } else {
        acc.push(q);
      }
      return acc;
    }, []);
  }
}

export function toggleAllowEmptyValues(state: SurveyQuestionsBuilder.State, action: SurveySettingsBuilder.ToggleAllowEmptyValues.Action): SurveyQuestionsBuilder.State {

  return state.reduce((acc, q) => {
    if (q.base.identifier === action.questionIdentifier) {
      acc.push({
        ...q,
        settings: {
          ...q.settings,
          allowEmptyValues: action.value,
        },
      });
    } else {
      acc.push(q);
    }
    return acc;
  }, []);
}

function generateNewOrdinals(options: SurveyQuestionOption[], removed: number) {
  return options.reduce((acc, x) => {
    return {
      ...acc,
      [x.ordinal]: x.ordinal < removed
        ? x.ordinal
        : x.ordinal > removed
          ? x.ordinal - 1
          : null,
    };
  }, {} as { [ordinal: number]: number; });
}
export enum SocketNamespace {
  Conference = 'conference',
  Files = 'files',
  Notifications = 'notifications-v2',
  Pipeline = 'pipeline',
  Reports = 'reports',
  User = 'user',
}

export enum LifeCycleSocketEvent {
  Authenticated = 'authenticated',
  Authenticate  = 'authenticate',
  Connect       = 'connect',
  ConnectError  = 'connect_error',
  Disconnect    = 'disconnect',
  Unauthorized  = 'unauthorized',
}

export enum NotificationsSocketEvent {
  AppRehydrate  = 'app-rehydrate',
  Dismiss       = 'dismiss',
  New           = 'new',
  Seen          = 'seen',
}

export enum PipelineSocketEvent {
  RecordUpdated = 'record-updated',
}

export enum UserSocketEvent {
  InfoUpdated = 'info-updated',
  StripePayoutConnected = 'stripe-payout-connected',
}

export enum FileSocketEvent {
  PreviewAvailable = 'preview-available',
}

export enum ReportSocketEvent {
  ReportAvailable = 'report-available',
}

export enum ConferenceSocketEvent {
  Negotiate                    = 'negotiate',
  NegotiateReconnect           = 'negotiate-reconnect',
  Join                         = 'join',
  WaitingRoomParticipant       = 'waiting-room-participant',
  WaitingRoomAdmit             = 'waiting-room-admit',
  WaitingRoomReject            = 'waiting-room-reject',
  WaitingRoomStatus            = 'waiting-room-status',
  Enter                        = 'enter',
  ParticipantEntered           = 'participant-entered',
  ParticipantUpdated           = 'participant-updated',
  ParticipantLeft              = 'participant-left',
  ParticipantsList             = 'participants-list',
  MuteParticipant              = 'mute-participant',
  RemoveParticipant            = 'remove-participant',
  UpdateParticipantVisibility  = 'update-participant-visibility',
  GiveHost                     = 'give-host',
  ConferenceUpdate             = 'conference-update',
  Leave                        = 'leave',
  ConferenceEnd                = 'conference-end',
}
import { useCallback, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { useAppReadyState } from '@containers/AppReadyState';
import * as $api from '@services/api';
import { Bucket } from '@screens/Settings/Settings.Email.Bucket';
import ActivityIndicator from 'components/ActivityIndicator';
import { Button } from 'components/Button';
import Toast from 'components/Toast';
import styles from './style.css';

type Params = {
  token: string;
};

export const Unsubscribe = () => {
  const app = useAppReadyState();

  const hydrated = app.authenticated && app.hydrated;
  const ready = !app.authenticated || hydrated;

  if (!ready) {
    return <ActivityIndicator />;
  }

  if (app.authenticated) {
    return <Redirect to={{ pathname: '/settings/notifications' }} />;
  }

  return <UnsubscribeComponent />;
};

const UnsubscribeComponent = () => {
  const params = useParams<Params>();
  const [settings, setSettings] = useState<Store.Settings.Email>();
  const [email, setEmail] = useState<string>(null);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    $api.settings.unsubscribe.getEmailSettings(params).then(r => {
      setSettings(r.settings);
      setEmail(r.email);
    }).catch(() => {
      setHasError(true);
    });
  }, [setHasError, params]);

  const toggle = useCallback((field: string) => (value: boolean) => {
    setSettings(prev => ({ ...prev, [field]: value }));
  }, []);

  const handleSave = useCallback(() => {
    $api.settings.unsubscribe.updateUnsubscribeEmailSettings({ token: params.token, settings }).then(() => {
      Toast.success({
        title: 'Account update successful!',
      });
    });
  }, [params.token, settings]);

  if (hasError) {
    return (
      <div className={styles.root}>
        You have followed an invalid or expired link. Please login to update notification settings.
      </div>
    );
  }

  if (!settings) {
    return <ActivityIndicator />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>Manage Your Email Preferences For {email}</div>
      <div className={styles.settings}>
        <Bucket
          active={settings.billing}
          name='Receive billing emails regarding payments and payouts'
          description=''
          onClick={toggle('billing')} />
        <Bucket
          active={settings.projectUpdate}
          name='Receive emails regarding project events'
          description=''
          onClick={toggle('projectUpdate')} />
        <Bucket
          active={settings.scheduling}
          name='Receive emails for pre-call scheduling correspondence'
          description=''
          onClick={toggle('scheduling')} />
        <Bucket
          active={settings.callEvent}
          name='Receive emails regarding scheduled calls'
          description=''
          onClick={toggle('callEvent')} />
        <Bucket
          active={settings.newMessages}
          name='Receive emails when you receive messages on the platform'
          description=''
          onClick={toggle('newMessages')} />
        <Bucket
          active={settings.marketing}
          name='Receive emails for general platform features, reminders, and updates'
          description=''
          onClick={toggle('marketing')} />
      </div>
      <div>
        <Button
          variant={'brick'}
          onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
};
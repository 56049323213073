import { cx } from '@utils';
import { TabBarTitle } from './TabBarTitle';
import TabIndicator from './TabIndicator';
import { TabBarProps } from './interfaces';
import styles from './style.css';

type Props<T> = {
  className?: string;
} & TabBarProps<T>;

const TabBar = <T extends unknown = unknown>(props: Props<T>) => {

  function getClassName(i: number) {
    return cx(props.index === i ? styles.active : styles.tab);
  }

  function handleClick(i: number) {
    return () => props.onClick(i);
  }

  return (
    <div className={cx(styles.root,)}>
      <div className={styles.wrap}>
        <div className={styles.scrollable}>
          <div className={cx(styles.tabs, props.className)}>
            {props.routes.map((item, i) =>
              <TabBarTitle
                className={getClassName(i)}
                key={item.key}
                index={i}
                onClick={handleClick(i)}>
                {item.title}
              </TabBarTitle>)}
            <TabIndicator
              count={props.routes.length}
              index={props.index} />
          </div>
        </div>
        <div className={styles.bar} />
      </div>
    </div>
  );
};

export { TabBar };
export default TabBar;
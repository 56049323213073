import {
  ConjointAnalysisQuestion, SurveyQuestion,
} from '@/types';
import { ConjointAnalysis, SurveyBuilder } from '../interfaces';
import { getNewAttribute, getNewLevel } from './defaults';

type State = SurveyBuilder.State['survey']['questions'];

type UpdateQuestion = {
  ordinal: number;
  state: State;
  mutate: (item: ConjointAnalysisQuestion.Question) => ConjointAnalysisQuestion.Question;
};

function updateQuestion({ mutate, ordinal, state }: UpdateQuestion) {
  return state.reduce<SurveyQuestion[]>((acc, q) => {
    const question = q.ordinal !== ordinal
      ? q
      : mutate(q as ConjointAnalysisQuestion.Question);

    return [...acc, question];
  }, []);

}

const addAttribute = (data: ConjointAnalysis.AddAttribute.State) => (item: ConjointAnalysisQuestion.Question): ConjointAnalysisQuestion.Question => {

  return {
    ...item,
    attributes: [
      ...item.attributes,
      getNewAttribute(),
    ],
  };
};

const removeAttribute = ({ attributeId }: ConjointAnalysis.RemoveAttribute.State) => (item: ConjointAnalysisQuestion.Question): ConjointAnalysisQuestion.Question => {
  return {
    ...item,
    attributes: item.attributes.filter(f => f.base.identifier !== attributeId),
  };
};

const addLevel = ({ attributeId }: ConjointAnalysis.AddLevel.State) => (item: ConjointAnalysisQuestion.Question): ConjointAnalysisQuestion.Question => {
  const attributes = item.attributes.reduce<ConjointAnalysisQuestion.Attribute[]>((acc, a) => {
    const attribute = a.base.identifier !== attributeId
      ? a
      : {
        ...a,
        levels: [
          ...a.levels,
          getNewLevel(),
        ],
      };
    return [...acc, attribute];
  }, []);

  return {
    ...item,
    attributes,
  };
};

const removeLevel = ({ attributeId, levelId }: ConjointAnalysis.RemoveLevel.State) => (item: ConjointAnalysisQuestion.Question): ConjointAnalysisQuestion.Question => {
  const attributes = item.attributes.reduce<ConjointAnalysisQuestion.Attribute[]>((acc, a) => {
    const attribute = a.base.identifier !== attributeId
      ? a
      : {
        ...a,
        levels: a.levels.filter(f => f.base.identifier !== levelId),
      };
    return [...acc, attribute];
  }, []);

  return {
    ...item,
    attributes,
  };
};

const updateAttributeName = ({ attributeId, value }: ConjointAnalysis.UpdateAttributeName.State) => (item: ConjointAnalysisQuestion.Question): ConjointAnalysisQuestion.Question => {
  return {
    ...item,
    attributes: item.attributes.reduce<ConjointAnalysisQuestion.Attribute[]>((acc, a) => {
      const attribute = a.base.identifier !== attributeId
        ? a
        : {
          ...a,
          value,
        };
      return [...acc, attribute];
    }, []),
  };
};

const updateLevelName = ({ attributeId, levelId, value }: ConjointAnalysis.UpdateLevelName.State) => (item: ConjointAnalysisQuestion.Question): ConjointAnalysisQuestion.Question => {
  return {
    ...item,
    attributes: item.attributes.reduce<ConjointAnalysisQuestion.Attribute[]>((acc, a) => {
      const attribute = a.base.identifier !== attributeId
        ? a
        : {
          ...a,
          levels: a.levels.reduce<ConjointAnalysisQuestion.Level[]>((acc2, l) => {
            const level = l.base.identifier !== levelId
              ? l
              : {
                ...l,
                value,
              };
            return [...acc2, level];
          }, []),
        };
      return [...acc, attribute];
    }, []),
  };
};

const updateConceptsPerSet = ({ value }: ConjointAnalysis.UpdateConceptsPerSet.State) => (item: ConjointAnalysisQuestion.Question): ConjointAnalysisQuestion.Question => {
  return {
    ...item,
    settings: {
      ...item.settings,
      conceptsPerSet: value,
    },
  };
};

const updateIterations = ({ value }: ConjointAnalysis.UpdateNumberOfSets.State) => (item: ConjointAnalysisQuestion.Question): ConjointAnalysisQuestion.Question => {
  return {
    ...item,
    settings: {
      ...item.settings,
      numberOfSets: value,
    },
  };
};

function toggleNoneOfTheAbove(item: ConjointAnalysisQuestion.Question): ConjointAnalysisQuestion.Question {
  return {
    ...item,
    settings: {
      ...item.settings,
      includeNoneOfTheAbove: !item.settings.includeNoneOfTheAbove,
    },
  };
}

export function conjointAnalysisReducer(state: SurveyBuilder.State, action: ConjointAnalysis.Action): State {
  const questions = state.survey.questions;
  const ordinal = state.editing.questionOrdinal;

  switch (action.type) {
    case 'conjoint-add-attribute':
      return updateQuestion({
        mutate: addAttribute(action),
        ordinal,
        state: questions,
      });

    case 'conjoint-remove-attribute':
      return updateQuestion({
        mutate: removeAttribute(action),
        ordinal,
        state: questions,
      });

    case 'conjoint-add-level':
      return updateQuestion({
        mutate: addLevel(action),
        ordinal,
        state: questions,
      });

    case 'conjoint-remove-level':
      return updateQuestion({
        mutate: removeLevel(action),
        ordinal,
        state: questions,
      });

    case 'conjoint-update-attribute-name':
      return updateQuestion({
        mutate: updateAttributeName(action),
        ordinal,
        state: questions,
      });

    case 'conjoint-update-level-name':
      return updateQuestion({
        mutate: updateLevelName(action),
        ordinal,
        state: questions,
      });

    case 'conjoint-update-concepts-per-set':
      return updateQuestion({
        mutate: updateConceptsPerSet(action),
        ordinal,
        state: questions,
      });

    case 'conjoint-update-number-of-sets':
      return updateQuestion({
        mutate: updateIterations(action),
        ordinal,
        state: questions,
      });

    case 'conjoint-toggle-none-of-the-above':
      return updateQuestion({
        mutate: toggleNoneOfTheAbove,
        ordinal,
        state: questions,
      });

    default: return questions;
  }

}

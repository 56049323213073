import { useMemo } from 'react';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { SurveyQuestion } from '@/types/survey';
import { QuestionBuilderEditingContext, QuestionBuilderItemContext } from './Context';
import { QuestionBuilderDeleteContainer } from './Question.Delete.Container';

type Props = {
  item: SurveyQuestion;
} & ChildrenProps;

export const SurveyBuilderQuestionContainer = (props: Props) => {

  const [state] = useSurveyBuilderState();

  const canEdit = useMemo(() => !state.editing.questionOrdinal, [state.editing.questionOrdinal]);
  const editing = useMemo(() => state.editing.questionOrdinal === props.item.ordinal, [state.editing.questionOrdinal, props.item.ordinal]);

  return (
    <QuestionBuilderItemContext.Provider value={props.item}>
      <QuestionBuilderEditingContext.Provider value={{ canEdit, editing }}>
        <QuestionBuilderDeleteContainer>
          {props.children}
        </QuestionBuilderDeleteContainer>
      </QuestionBuilderEditingContext.Provider>
    </QuestionBuilderItemContext.Provider>
  );
};
import { useCallback } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Theme } from '@nivo/core';
import { useRepeatChartRef, useRepeatQuestionResponsesData } from '@containers/RepeatSurveyResponses/hooks';
import { TooltipContainer } from 'components/Tooltip';
import CustomChartLayer from './CustomChartLayer';
import { BaseLineChartProps, CustomLayerNivoProps } from './interfaces';
import styles from './style/BaseLineChart.css';

const theme: Theme = {
  axis: {
    legend: {
      text: {
        fill: 'var(--gray-d)',
        fontFamily: 'var(--font-semibold)',
      },
    },
    ticks: {
      line: {
        display: 'none',
      },
      text: {
        fontSize: 10,
        fontFamily: 'var(--font-reg)',
        fill: 'var(--gray-d)',
        padding: 5,
      },
    },
  },
};

export const BaseLineChart = ({
  onPointClick,
  onSerieClick,
  PointsTooltip,
  SeriesTooltip,
  ...chartProps
}: BaseLineChartProps) => {
  const { question } = useRepeatQuestionResponsesData();
  const chartRef = useRepeatChartRef();

  const Layer = useCallback((layerProps: CustomLayerNivoProps) => {
    return (
      <CustomChartLayer
        {...layerProps}
        onPointClick={onPointClick}
        onSerieClick={onSerieClick}
        PointsTooltip={PointsTooltip}
        SeriesTooltip={SeriesTooltip} />
    );
  }, [
    onPointClick,
    onSerieClick,
    PointsTooltip,
    SeriesTooltip,
  ]);

  return (
    <TooltipContainer>
      <div
        ref={chartRef}
        className={styles.chart}
        style={{ height: 250 }}>
        <ResponsiveLine
          {...chartProps}
          key={question.base.id}
          margin={{ top: 15, right: 20, bottom: 45, left: 40 }}
          colors={{ datum: 'color' }}
          xScale={{ type: 'point' }}
          axisBottom={{
            legend: 'Version',
            legendOffset: 37,
            legendPosition: 'middle',
          }}
          axisTop={null}
          axisRight={null}
          tooltip={null}
          animate={true}
          enableCrosshair={false}
          enablePoints={true}
          enableSlices={false}
          useMesh={false}

          pointColor="#ffffff"
          pointBorderColor={{ from: 'serieColor' }}

          theme={theme}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            'slices',
            'mesh',
            'legends',
            Layer,
          ]} />
      </div>
    </TooltipContainer>
  );
};

export default BaseLineChart;
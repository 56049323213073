import { useCallback, useEffect, useState } from 'react';
import * as api from '@api';
import {
  GroupEntityTagsContext,
  GroupEntityTagsDispatchContext,
  GroupEntityTagsFetchContext,
  GroupEntityTagsLoadingContext,
  GroupEntityTagsUpdateContext,
} from './Context';
import { useAsyncStateLazy } from '@utils';
import { GroupTagsContextValue, GroupPersonTagsProps, GroupTagUpdateItem } from './interfaces';

export default function GroupPersonTagsContainer(props: GroupPersonTagsProps) {
  const [state, dispatch] = useState<GroupTagsContextValue>(GroupPersonTagsContainer.initialState);

  const [response, fetch] = useAsyncStateLazy(async () => {
    const result = await api.tags.group.getPersonTags({
      userId: props.userId,
    });

    dispatch(result.tags);

    return result.tags;
  }, [props.userId]);

  const update = useCallback(async (tags: GroupTagUpdateItem[]) => {
    const result = await api.tags.group.updatePersonTags({
      tags,
      userId: props.userId,
    });
    dispatch(result.tags);
  }, [props.userId]);

  useEffect(() => {
    if (props.userId) {
      fetch();
    }
  }, [fetch, props.userId]);

  return (
    <GroupEntityTagsContext.Provider value={state}>
      <GroupEntityTagsLoadingContext.Provider value={response.loading}>
        <GroupEntityTagsFetchContext.Provider value={fetch}>
          <GroupEntityTagsUpdateContext.Provider value={update}>
            <GroupEntityTagsDispatchContext.Provider value={dispatch}>
              {props.children}
            </GroupEntityTagsDispatchContext.Provider>
          </GroupEntityTagsUpdateContext.Provider>
        </GroupEntityTagsFetchContext.Provider>
      </GroupEntityTagsLoadingContext.Provider>
    </GroupEntityTagsContext.Provider>
  );
}

const initial: GroupTagsContextValue = [];

GroupPersonTagsContainer.initialState = initial;

export { GroupPersonTagsContainer };
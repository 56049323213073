import { useCallback, useContext, Fragment } from 'react';
import { useAppReadyState } from '@containers/AppReadyState';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { cx, useMappedStepper } from '@utils';
import * as AuthButton from '$website/components/AuthButton';
import * as AuthForm from '$website/components/AuthForm';
import { AuthContainer } from '$website/containers/Auth/AuthContainer';
import { SocialAuthContainer } from '$website/containers/Auth/SocialAuthContainer';
import { useLoginSubmit } from '$website/containers/Auth/hooks/useLoginSubmit';
import { useSignupSubmit } from '$website/containers/Auth/hooks/useSignupSubmit';
import { useSocialAuth } from '$website/containers/Auth/hooks/useSocialAuth';
import { LoginError } from '$website/screens/Auth/LoginError';
import { Modal, ModalProps } from 'components/Modal/Modal';
import { UserAvatar } from 'components/UserAvatar';
import { FormNavigationContext } from './Context';
import { Step, AuthConnectRecipientUser } from './interfaces';
import styles from './style/AuthConnectModal.css';

const { Layout } = AuthForm;

type Props = {
  recipient: AuthConnectRecipientUser;
} & Pick<ModalProps, 'open' | 'onClose'>;

export const AuthConnectModal = ({ recipient, ...props }: Props) => {

  const [Component, actions, step] = useMappedStepper<Step>({
    map: StepMap,
    initial: Step.SignupStrategy,
  });

  return (
    <Modal
      className={styles.root}
      open={props.open}
      onClose={props.onClose}>
      <FormNavigationContext.Provider value={actions.dispatch}>
        <div className={styles.wrap}>

          <Layout.Header>
            <Layout.Row>
              <UserAvatar
                className={styles.avatar}
                pictureUrl={recipient.profile.pictureUrl} />
            </Layout.Row>

            <Layout.Row>
              <Layout.Subtitle className={styles.title}>
                {`Connect with ${recipient.profile.firstName} on Vancery`}
              </Layout.Subtitle>
            </Layout.Row>

            <Layout.Row className={styles.subtitle}>
              <Subheader navigation={step} />
            </Layout.Row>
          </Layout.Header>

          <AuthContainer>
            <SocialAuthContainer>
              <Component />
            </SocialAuthContainer>
          </AuthContainer>

        </div>

      </FormNavigationContext.Provider>

    </Modal>
  );
};

type SubheaderProps = {
  navigation: Step;
};

const Subheader = (props: SubheaderProps) => {
  const dispatch = useContext(FormNavigationContext);
  const showLoginStrategy = useCallback(() => dispatch(Step.LoginStratgey), [dispatch]);
  const showSignupStrategy = useCallback(() => dispatch(Step.SignupStrategy), [dispatch]);

  const signup = (
    <Fragment>
      <Layout.Subtitle>{`Don't have an account?`} <span className={styles.link} onClick={showSignupStrategy}>Sign up</span>.</Layout.Subtitle>
    </Fragment>
  );

  const login = (
    <Fragment>
      <Layout.Subtitle>Create an account to get started, or</Layout.Subtitle>
      <Layout.Subtitle>if you have an account, <span className={styles.link} onClick={showLoginStrategy}>Log In</span>.</Layout.Subtitle>
    </Fragment>
  );

  return props.navigation === Step.LoginStratgey
    ? signup
    : props.navigation === Step.SignupStrategy
      ? login
      : null;
};

const LoginStrategy = (props: unknown) => {
  const social = useSocialAuth();
  const [form, submit] = useLoginSubmit();
  const app = useAppReadyState();

  function handleEmail(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    submit();
  }

  return (
    <div className={styles.body}>
      <Layout.Row>
        <AuthButton.Google onClick={social.authorizeGoogle}>
          Log in with Google
        </AuthButton.Google>
      </Layout.Row>

      <Layout.Row>
        <AuthButton.Facebook onClick={social.authorizeFacebook}>
          Log in with Facebook
        </AuthButton.Facebook>
      </Layout.Row>

      <Layout.Row>
        <Layout.ThematicBreak>
          or
        </Layout.ThematicBreak>
      </Layout.Row>

      <form onSubmit={handleEmail}>
        <AuthForm.Email.Login className={cx(styles.login, styles.input)} />

        <Layout.Row className={styles.login}>
          <LoginError className={cx(styles.error)} />
        </Layout.Row>

        <Layout.Row className={styles.wrap}>
          <ButtonActivityIndicator
            className={styles.submit}
            loading={form.loading || app.hydrating}>
            Log In
          </ButtonActivityIndicator>
        </Layout.Row>
      </form>
    </div>
  );
};

const SignupStrategy = (props: unknown) => {
  const social = useSocialAuth();
  const dispatch = useContext(FormNavigationContext);

  const showEmailSignup = useCallback(() => dispatch(Step.SignupEmailForm), [dispatch]);

  return (
    <Fragment>
      <div className={styles.body}>
        <Layout.Row>
          <AuthButton.Google onClick={social.authorizeGoogle}>
            Sign up with Google
          </AuthButton.Google>
        </Layout.Row>

        <Layout.Row>
          <AuthButton.Facebook onClick={social.authorizeFacebook}>
            Sign up with Facebook
          </AuthButton.Facebook>
        </Layout.Row>

        <Layout.Row>
          <AuthButton.Apple onClick={social.authorizeApple}>
            Sign up with Apple
          </AuthButton.Apple>
        </Layout.Row>

        <Layout.Row>
          <Layout.ThematicBreak>
            or
          </Layout.ThematicBreak>
        </Layout.Row>

        <Layout.Row>
          <AuthButton.Email onClick={showEmailSignup}>
            Sign up with Email
          </AuthButton.Email>
        </Layout.Row>
      </div>

      <AuthForm.PrivacyPolicy />

    </Fragment>
  );
};

const SignupEmailForm = () => {
  const [form, submit] = useSignupSubmit();
  const app = useAppReadyState();

  function handleForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    submit();
  }

  return (
    <Fragment>
      <div className={styles.body}>
        <form onSubmit={handleForm}>
          <AuthForm.Email.Signup />
          <div className={styles.signup}>
            {form.error &&
              <div className={styles.e}>{form.error.message}</div>}
          </div>
          <Layout.Row className={styles.wrap}>
            <ButtonActivityIndicator
              className={styles.submit}
              loading={form.loading || app.hydrating}>
              Sign Up
            </ButtonActivityIndicator>
          </Layout.Row>
        </form>
      </div>

      <AuthForm.PrivacyPolicy />
    </Fragment>
  );
};

const StepMap = {
  [Step.SignupStrategy]: SignupStrategy,
  [Step.SignupEmailForm]: SignupEmailForm,
  [Step.LoginStratgey]: LoginStrategy,
};
import { useContext, useEffect, useState } from 'react';
import { ComplianceOnboardingContext } from '@containers';
import * as mixpanel from '@services/mixpanel';
import TestButtons from './TestButtons';
import TestQuestions from './TestQuestions';
import TestScore from './TestScore';
import * as utils from './utils';
import data from './data';
import { ComplianceTestProps, TestItemResult, TestItemSelection } from './interfaces';
import styles from './style.css';

export const TestScreen = () => {
  const ctx = useContext(ComplianceOnboardingContext);

  return (
    <ComplianceTest
      onBack={ctx.back}
      onComplete={ctx.next} />
  );
};

export const ComplianceTest = ({
  items = data,
  title = 'Compliance Test',
  ...props
}: ComplianceTestProps) =>  {
  const [results, setResults] = useState<TestItemResult[]>([]);
  const [score, setScore] = useState<number>(0);
  const [selections, setSelections] = useState<TestItemSelection>({});

  const handleSelection = selection => {
    const updatedSelections = { ...selections, ...selection };

    const selected = Object.keys(selection)
      .map(s => ({
        answer: selection[s] + 1,
        correct: items[s].answer === selection[s],
        question: +s + 1,
      }));

    if (selected.length) {
      mixpanel.event.onboarding.complianceTest.answer(selected[0]);
    }

    setSelections(updatedSelections);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [score]);

  const handleSubmit = () => {
    const results = utils.evaluateTest(items, selections);
    const score = utils.getScore(results);

    mixpanel.event.onboarding.complianceTest.submit({
      score,
    });

    setScore(score);
    setResults(results);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.h2}>{title}</div>
        {!results.length &&
          <div className={styles.subheader}>
            Complete the following questions on the compliance rules of
            consultations. After clicking submit, you will see your score.
          </div>
        }
      </div>
      {!!results.length && <TestScore
        handleNavigation={props.onComplete}
        score={score} />}
      <TestQuestions
        items={items}
        onChange={handleSelection}
        results={results}
        selections={selections} />
      <TestButtons
        disabled={items.length !== Object.keys(selections).length}
        onBack={props.onBack}
        onNext={props.onComplete}
        onSubmit={handleSubmit}
        results={results} />
    </div>
  );
};

export default ComplianceTest;

import { Children, useEffect, useState } from 'react';
import { cx } from '@utils';
import Icon from './Icon';
import Panel from './Panel';
import { SimpleAccordionProps } from './interfaces';
import styles from './style.css';

export const SimpleAccordion = ({
  children,
  className,
  classes,
  grows,
  height,
  label,
  open,
  toggleOpen,
}: SimpleAccordionProps) => {
  const [count, setCount] = useState(Children.count(children));

  useEffect(() => {
    setCount(Children.count(children));
  }, [children]);

  return (
    <>
      <Panel
        className={cx(className, classes.panel)}
        count={count}
        grows={grows}
        height={height}
        onClick={toggleOpen}
        open={open}>
        {label}
        <Icon height={height} />
      </Panel>

      <div className={cx(open ? styles.entered : styles.hidden, classes.value)}>
        {children}
      </div>
    </>
  );
};

SimpleAccordion.defaultProps = {
  classes: {},
  grows: true,
  height: 60,
  label: '',
  open: true,
};

export default SimpleAccordion;
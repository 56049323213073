import { useMemo } from 'react';
import { SurveyTaggingSectionBuilderContainer } from '@containers/SurveyBuilder.Tagging/SurveyTaggingSectionBuilderContainer';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks/useSurveyBuilderContext';
import styles from './style/SurveyTaggingSectionsBuilder.css';

export default function SurveyTaggingSectionsBuilder() {
  const [state] = useSurveyBuilderState();

  const Sections = useMemo(() => {
    return state.survey.sections.map(section => (
      <SurveyTaggingSectionBuilderContainer
        key={section.ordinal}
        className={styles.section}
        identifier={section.identifier} />
    ));
  }, [state.survey.sections]);

  return (
    <div className={styles.root}>
      {Sections}
    </div>
  );
}

export { SurveyTaggingSectionsBuilder };
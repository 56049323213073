import { useCallback, useMemo, useState } from 'react';
import { Route, Switch, useHistory, matchPath } from 'react-router-dom';
import { Companies } from '@api/interfaces';
import * as consts from '@consts';
import { TabView } from 'components/TabView';
import * as Tab from './Tab';
import { BuildSceneMap, Scene, SceneMap, CompanyTab } from './interfaces';
import styles from './style/CompanyLanding.css';

type Props =
  Pick<Companies.FetchOverview.Response,
  | 'item'
  | 'meta'>;

export function Tabs({ item, meta }: Props) {
  const history = useHistory();

  const scenemap = useMemo(() => {
    return buildSceneMap({
      hasCurrentEmployees: meta.hasCurrentEmployees,
      hasFormerEmployees: meta.hasFormerEmployees,
      slug: item.slug,
    });
  }, [
    meta.hasCurrentEmployees,
    meta.hasFormerEmployees,
    item.slug,
  ]);

  function matchTabIndexFromLocation(scenes: SceneMap) {
    const matchedScene = Object.values(scenes).find(s => matchPath(s.path, {
      path: window.location.pathname,
      exact: true,
    }));
    if (!matchedScene) return 0;

    return Object.keys(scenes).indexOf(matchedScene.key);
  }

  const [index, setIndex] = useState(matchTabIndexFromLocation(scenemap));
  const changeLocation = (idx: number) => {
    setIndex(idx);
    const tab = Object.keys(scenemap)[idx] as CompanyTab;
    const path = scenemap[tab].path;
    history.push(path);
  };

  const renderTabView = useCallback(() => {
    return (
      <div className={styles.tabview}>
        <Switch>
          <Route
            component={Tab.Overview}
            exact
            path={consts.path.Companies.Company} />
          <Route
            component={Tab.CurrentEmployees}
            exact
            path={consts.path.Companies.CurrentEmployees.Root} />
          <Route
            component={Tab.FormerEmployees}
            exact
            path={consts.path.Companies.FormerEmployees.Root} />
          <Route />
        </Switch>
      </div>
    );
  }, []);

  const routes = Object.values(scenemap);

  return (
    <TabView<Scene>
      index={index}
      onIndexChange={changeLocation}
      renderTabView={renderTabView}
      routes={routes} />
  );
}

function buildSceneMap({ hasCurrentEmployees, hasFormerEmployees, slug }: BuildSceneMap.Params): BuildSceneMap.Return {
  function slugify(path: string) {
    return path.replace(':slug', slug);
  }

  const scenemap = {
    [CompanyTab.CurrentEmployees]: hasCurrentEmployees ? {
      key: CompanyTab.CurrentEmployees,
      component: Tab.CurrentEmployees,
      title: 'Current Employees',
      path: slugify(consts.path.Companies.CurrentEmployees.Root),
    } : null,
    [CompanyTab.FormerEmployees]: hasFormerEmployees ? {
      key: CompanyTab.FormerEmployees,
      component: Tab.FormerEmployees,
      title: 'Former Employees',
      path: slugify(consts.path.Companies.FormerEmployees.Root),
    } : null,
  };

  return Object.entries(scenemap).reduce((acc, [key, val]) => {
    return val
      ? { ...acc, [key]: val }
      : acc;
  }, {} as SceneMap);
}
import { MatrixSliderQuestion } from '@/types';

export function isAnswerComplete(data: MatrixSliderQuestion.RespondentAnswer.Value, item: MatrixSliderQuestion.FormQuestion) {
  return data.items.length === item.matrixRows.length;
}

export function getRunningTotal(data: MatrixSliderQuestion.RespondentAnswer.Value) {
  return data.items.reduce((acc, x) => acc + x.value, 0);
}

export function isSumValid(data: MatrixSliderQuestion.RespondentAnswer.Value, item: MatrixSliderQuestion.FormQuestion) {
  if (!item.settings.slider.ensureAnswerTotalEqualsMax) return true;

  const sum = getRunningTotal(data);

  return sum === item.settings.slider.maxValue;
}

export function isAnswerValid(data: MatrixSliderQuestion.RespondentAnswer.Value, item: MatrixSliderQuestion.FormQuestion) {

  return isAnswerComplete(data, item) &&
    isSumValid(data, item);
}
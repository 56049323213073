import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHasConsultantRole } from '@containers/User';
import { useDisconnectUserAccount } from '@utils/api';
import * as selectors from '@store/selectors';
import {
  useGoogleCalendarSync,
} from 'components/ConnectAccount/hooks';
import { useAlert } from 'components/Modal/Alert';
import {
  GoogleCalendar,
} from 'components/icons';
import { Integration } from 'components/Settings';
import styles from './style/Settings.Integrations.css';

export const GoogleCalendarIntegration = () => {

  const [toggleAlert, Alert] = useAlert();

  const connected = useSelector(selectors.selectGoogleCalendar);
  const outlookCalendar = useSelector(selectors.selectMicrosoftCalendar);

  const hasConsultantRole = useHasConsultantRole();
  const { mutateAsync: disconnect } = useDisconnectUserAccount({
    onSuccess: _ => {
      toggleAlert();
    },
  });

  const googleCalendar = useGoogleCalendarSync();

  const handleDisconnect = useCallback(() => {
    if (!connected) return;
    return disconnect({
      id: connected.id,
    });
  }, [connected, disconnect]);

  const description = hasConsultantRole
    ? `Connect your Google calendar`
    : `Streamline the scheduling experience by allowing interviewees to see available time slots.`;

  return (
    <>
      <Integration
        className={styles.item}
        icon={GoogleCalendar}
        title={title}
        description={description}
        connected={!!connected}
        disabled={!!outlookCalendar}
        loading={googleCalendar.isLoading}
        onConnect={googleCalendar.connect}
        onDisconnect={toggleAlert} />
      <Alert
        message="Are you sure you want to remove your connected Google calendar?"
        onConfirm={handleDisconnect} />
    </>
  );
};

const title = `Google Calendar Sync`;

export default GoogleCalendarIntegration;

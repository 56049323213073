import * as pathname from '@consts/pathname';
import { extend, join } from '@utils/path';

const account = join(pathname.Account);
const companies = join(pathname.Companies);
const compliance = join(pathname.Compliance);
const conference = join(pathname.CONFERENCE);
const contacts = join(pathname.Contacts);
const discovery = join(pathname.Discovery);
const external = join(pathname.EXTERNALPROJECT);
const inbound = join(pathname.Inbound);
const invite = join(pathname.Invite);
const onboarding = join(pathname.ONBOARDING);
const messages = join(pathname.MESSAGES);
const posts = join(pathname.POSTS);
const profile = join(pathname.USER_PROFILE);
const projects = join(pathname.PROJECTS);
const settings = join(pathname.Settings);
const scheduling = join(pathname.SCHEDULING);
const topics = join(pathname.Topics);
const workspaces = join(pathname.WORKSPACES);

const accountprofile = join(account(`/profile`));

export const Account = {
  Profile: {
    Root:    accountprofile(),
    Checkup: accountprofile(`/checkup`),
    Setup:   accountprofile(`/setup`),
  },
  Root: account(),
};

export const Calendar = {
  Root: pathname.CALENDAR,
};

export const Companies = {
  Root: companies(),
  Company: companies(`/:slug`),
  NotFound: companies('/not-found'),
  CurrentEmployees: {
    Root: companies(`/:slug/current-employees`),
  },
  FormerEmployees: {
    Root: companies(`/:slug/former-employees`),
  },
};

const review = join(compliance('/:groupId/review'));

export const Compliance = {
  Review: {
    Call:    review('/calls/:callId'),
    Message: review('/messages/:threadId'),
    Root:    review(),
    Survey:  review('/surveys/:responseId'),
  },
  Root: compliance(),
};

export const Conference = {
  Root: conference(),
  Entry: conference('/:conferenceIdentifier'),
  Join: conference('/:conferenceIdentifier/join'),
  Room: conference('/:conferenceIdentifier/room'),
  Completed: conference('/:conferenceIdentifier/completed'),
  NotFound: conference('/:conferenceIdentifier/not-found'),
};

export const Contacts = {
  Import: contacts(`/import`),
  Root:   contacts(),
  Record: contacts(`/:slug`),
};

export const Discovery = {
  Root:   discovery(),
  Post:   discovery('/:postId'),
  Topics: {
    Root:  discovery(`/topics`),
    Topic: discovery(`/topics/:slug`),
  },
};

export const Inbound = {
  Actionable: {
    Root: inbound(`/actionable`),
    Token: inbound(`/actionable/:token`),
  },
  Confirmation: inbound(`/confirmation`),
  Scheduling: {
    AdHoc: {
      Confirmation:  inbound(`/scheduling/calls/:callId`),
      Proposal:      inbound(`/scheduling/calls/:callId/proposals`),
    },
    Root:               inbound(`/scheduling`),
    Confirmation:       inbound(`/scheduling/:projectId/calls/:callId`),
    Invitation:         inbound(`/scheduling/:projectId`),
    Proposal:           inbound(`/scheduling/:projectId/calls/:callId/proposals`),
  },
  Apple:                  inbound('/auth/apple'),
  Auth:                   inbound('/auth'),
  Facebook:               inbound(`/auth/facebook/`),
  Google:                 inbound(`/auth/google/`),
  Linkedin:               inbound(`/auth/linkedin`),
  OAuth2Logout:           inbound(`/auth/oauth2/logout`),
  OAuth2:                 inbound(`/auth/oauth2/:identifier`),
  ProjectReport:          inbound(`/project-report/:projectId`),
  Root:                   inbound(),
  HubSpot:                inbound(`/hubspot`),
  Slack:                  inbound(`/slack`),
  Stripe:                 inbound(`/stripe`),
};

export const Invite = {
  Root: invite(),
  Token: invite(`/:token`),
};

export const Messages = extend({
  Root: messages(),
});

export const Notifications = {
  Root: pathname.NOTIFICATIONS,
};

const projectonboarding = join(onboarding('/projects'));

export const Onboarding = {
  Calls:                    onboarding('/calls'),
  Compliance:               onboarding('/compliance'),
  ComplianceTerms:          onboarding('/compliance/terms'),
  ComplianceTest:           onboarding('/compliance/test'),
  ComplianceQuestions:      onboarding('/compliance/questions'),
  Profile:                  onboarding('/profile'),
  ProfileEducation:         onboarding('/profile/education'),
  ProfileEmployment:        onboarding('/profile/employment'),
  ProfileEnrichment:        onboarding(`/profile/enrichment`),
  ProfileSummary:           onboarding('/profile/summary'),
  Projects: {
    Root:         projectonboarding(),
    Archived:     projectonboarding('/archived'),
    Closed:       projectonboarding('/:projectId/closed'),
    Completion:   projectonboarding('/:projectId/completion'),
    Continue:     projectonboarding('/:projectId/continue'),
    Disqualified: projectonboarding('/disqualified'),
    End:          projectonboarding('/:projectId/end'),
    Exit:         projectonboarding('/:projectId/exit'),
    Project:      projectonboarding('/:projectId'),
  },
  Rate:                     onboarding('/rate'),
  RateSectors:              onboarding('/rate/sectors'),
  RateIndustries:           onboarding('/rate/inidustries'),
  RateSubIndustries:        onboarding('/rate/sub-industries'),
  RateSeniority:            onboarding('/rate/seniority'),
  RateSet:                  onboarding('/rate/set'),
  PayoutSetup:              onboarding('/payout-setup'),
  Root:                     pathname.ONBOARDING,
  Topics:                   onboarding('/topics'),
};

const callOnboarding = join(Onboarding.Calls);
export const CallOnboarding = {
  Call:                   callOnboarding('/:callId'),
  Details:                callOnboarding('/:callId/details'),
  Availability:           callOnboarding('/:callId/availability'),
  PayoutSetup:            callOnboarding('/:callId/payout-setup'),
  SchedulingTutorial:     callOnboarding('/:callId/scheduling-tutorial'),
};

const articleposts = join(posts(`/articles`));

export const Posts = {
  Articles: {
    Edit: articleposts(`/:postId/edit`),
    New:  articleposts(`/new`),
    Post: articleposts(`/:postId`),
    Root: articleposts(),
  },
  NotFound: posts(`/not-found`),
  Post:     posts(`/:postId`),
  Root:     posts(),
};

export const Profile = {
  NotFound:   profile('/not-found'),
  Root:       pathname.USER_PROFILE,
  User:       profile('/:slug?'),
  UserPosts:  profile('/:slug/posts'),
};

export const Projects = {
  Clone:            projects(`/:slug/clone`),
  New:              projects(`/new/:step`),
  Preview:          projects(`/:projectId/preview`),
  PreviewComplete:  projects(`/:projectId/preview/complete`),
  Project:          projects(`/:slug`),
  ProjectTabs:      projects(`/:slug/:tab?`),
  Root:             pathname.PROJECTS,
  Search:           projects(`/:slug/search`),
  SurveyMonitoring: projects(`/:slug/survey-monitoring`),
  SurveyEditor:     projects(`/:slug/survey`),
  SurveyResponse:   projects(`/:slug/survey-responses/:projectSurveyResponseId`),
  Transcript:       projects(`/:slug/summary/:callId/:type/transcript`),
};

export const ExternalProject = {
  Disqual: external('/:projectId/:secret/disqual'),
  Success: external('/:projectId/:secret/success'),
  QuotaLimit: external('/:projectId/:secret/quota'),
  Root: pathname.EXTERNALPROJECT,
};

const projectTabs = join(Projects.Project);

export const ProjectTabs = {
  Details:          projectTabs('/details'),
  Calls:            projectTabs('/calls'),
  Pipeline:         projectTabs('/pipeline'),
  Summary:          projectTabs('/summary'),
  SurveyResponses:  projectTabs('/survey-responses'),
};

export const AdHocCallScheduling = {
  Confirmations: scheduling(`/calls/:callId`),
  Proposals:     scheduling(`/calls/:callId/proposals`),
};

export const Scheduling = {
  Project:       scheduling(`/:projectId`),
  Confirmations: scheduling(`/:projectId/calls/:callId`),
  Root:          pathname.SCHEDULING,
  Proposals:     scheduling(`/:projectId/calls/:callId/proposals`),
};

export const Search = {
  Root: pathname.SEARCH,
};

export const Settings = {
  Root:                           pathname.Settings,
  Account:                        settings(`/account`),
  Branding:                       settings(`/branding`),
  Calendar:                       settings('/calendar'),
  Compliance:                     settings(`/compliance`),
  ComplianceSurvey:               settings(`/compliance-survey`),
  Integrations:                   settings(`/integrations`),
  EnterpriseBilling:              settings(`/enterprise/billing`),
  EnterpriseBillingNewMethod:     settings(`/enterprise/billing/new-payment-method`),
  EnterpriseBillingVerify:        settings(`/enterprise/billing/verify`),
  Notifications:                  settings(`/notifications`),
  PostCallQuestions:              settings(`/post-call-questions`),
  Privacy:                        settings(`/privacy`),
  Security:                       settings(`/security`),
  UserBilling:                    settings(`/billing`),
  UserBillingNewPayoutMethod:     settings(`/billing/new-payout-method`),
  UserBillingUpdatePayoutMethod:  settings(`/billing/update-payout-method`),
  UserBillingNewMethod:           settings(`/billing/new-payment-method`),
  UserBillingVerify:              settings(`/billing/verify`),
};

const selfservesurvey = join(pathname.SELFSERVESURVEY);

export const SelfServeSurvey = {
  Root: selfservesurvey(),
  NotAFit: selfservesurvey(`/naf`),
  Inactive: selfservesurvey(`/inact`),
};

export const Topics = {
  Root: topics(),
  Topic: topics(`/:slug`),
  NotFound: topics('/not-found'),
  Members: {
    Root: topics(`/:slug/members`),
  },
  News: {
    Root: topics(`/:slug/news`),
  },
  Posts: {
    Root: topics(`/:slug/posts`),
  },
};

const universalsearch = join(pathname.UniversalSearch);

export const UniversalSearch = {
  Root:  pathname.UniversalSearch,
};

const unsubscribe = join(pathname.Unsubscribe);
export const Unsubscribe = {
  Root: unsubscribe(),
  Token: unsubscribe(`/:token`),
};

export const Workspaces = {
  File:           workspaces('/files/:fileId'),
  Folder:         workspaces('/folders/:folderId'),
  FolderTabs:     workspaces('/folders/:folderId/:tab?'),
  Root:           pathname.WORKSPACES,
  Search:         workspaces('/search'),
  Shared:         workspaces('/shared'),
  SharedTabs:     workspaces(`/shared/:tab?`),
  Workspace:      workspaces(`/:workspace`),
  WorkspaceTabs:  workspaces(`/:workspace/:tab?`),
};

const workspaceTabs = join(Workspaces.Workspace);

export const WorkspaceTabs = {
  Files:       workspaceTabs('/files'),
  Folders:     workspaceTabs('/folders'),
  Projects:    workspaceTabs('/projects'),
};

const folderTabs = join(Workspaces.Folder);

export const WorkspaceFolderTabs = {
  Details:     folderTabs('/details'),
  Files:       folderTabs('/files'),
  Folders:     folderTabs('/folders'),
  Projects:    folderTabs('/projects'),
};

const sharedWorkspaceTabs = join(Workspaces.Shared);

export const SharedWorkspaceTabs = {
  Files:       sharedWorkspaceTabs('/files'),
  Folders:     sharedWorkspaceTabs('/folders'),
  Projects:    sharedWorkspaceTabs('/projects'),
};
import { MaxDifferenceQuestion } from '@/types';
import Options from './Options';
import styles from './style/MaxDiff.Question.css';

type Props = {
  item: MaxDifferenceQuestion.Question;
}

export default function MaxDiffQuestion({ item }: Props) {
  return (
    <>
      <div className={styles.settings}>
        <div>Attributes per set: {item.settings.optionsPerSet}</div>
        <div>Sets: {item.settings.sets}</div>
      </div>
      <div className={styles.options}>
        <Options item={item} />
      </div>
    </>
  );
}

export { MaxDiffQuestion };


import { SurveyQuestion } from '@/types';
import { QuestionRow } from './QuestionOption';
import styles from './style/Options.css';

type Props = {
  item: SurveyQuestion;
};

export const MatrixRows = ({ item }: Props) => (
  <>
    {item.matrixRows.map((option, i) => (
      <QuestionRow
        key={i}
        className={styles.option}
        item={option} />
    ))}
  </>
);

export default MatrixRows;
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as scheduler from '@containers/Scheduler/utils';
import { Contact } from '@/types';
import { useFilteredResponseUsers } from '@containers/GroupProject/hooks';

type UsersByStatus = {
  active: Contact[];
  bookmarked: Contact[];
  inactive: Contact[];
  inreview: Contact[];
  invited: Contact[];
  recommended: Contact[];
  removed: Contact[];
};

const {
  isActive,
  isBookmarked,
  isInactive,
  IsInReview,
  isInvited,
  isRecommended,
  isRemoved,
} = scheduler.projects;

const mapState = (state: Store.State) => ({
  contacts: state.contacts,
  pipeline: state.pipeline,
});

export const usePipelineUsers = (projectId: number) => {
  const { contacts, pipeline } = useSelector(mapState);
  const filteredUserIds = useFilteredResponseUsers();

  return useMemo(() => {
    const { users } = pipeline.project[projectId];

    return Object.keys(users).reduce<UsersByStatus>((acc, userId) => {
      if (filteredUserIds && !filteredUserIds.includes(+userId)) {
        return acc;
      }
      const record = users[+userId];
      const user = contacts[+userId];

      if (isActive(record)) {
        acc.active = acc.active.concat(user);
      } else if (isBookmarked(record)) {
        acc.bookmarked = acc.bookmarked.concat(user);
      } else if (isInactive(record)) {
        acc.inactive = acc.inactive.concat(user);
      } else if (IsInReview(record)) {
        acc.inreview = acc.inreview.concat(user);
      } else if (isInvited(record)) {
        acc.invited = acc.invited.concat(user);
      } else if (isRecommended(record)) {
        acc.recommended = acc.recommended.concat(user);
      } else if (isRemoved(record)) {
        acc.removed = acc.removed.concat(user);
      }

      return acc;
    }, {
      active: [],
      bookmarked: [],
      inactive: [],
      inreview: [],
      invited: [],
      recommended: [],
      removed: [],
    });
  }, [
    contacts,
    pipeline.project,
    projectId,
    filteredUserIds,
  ]);

};

export default usePipelineUsers;
import { useCallback } from 'react';
import { SurveyActionType } from '@enums';
import { useQuestionLogicBuilderState } from './useQuestionLogicBuilderState';

export const useGetDefaultIdentifier = () => {
  const {
    includeSectionOptions,
    skipToQuestionOptions,
    skipToSectionOptions,
  } = useQuestionLogicBuilderState();

  return useCallback((type: SurveyActionType) => {
    if (type === SurveyActionType.SkipToQuestion) {
      return skipToQuestionOptions[0].identifier;
    } else if (type === SurveyActionType.SkipToSection) {
      return skipToSectionOptions[0].identifier;
    } else if (type === SurveyActionType.IncludeSection) {
      return includeSectionOptions[0].identifier;
    } else {
      return null;
    }
  }, [
    includeSectionOptions,
    skipToQuestionOptions,
    skipToSectionOptions,
  ]);
};

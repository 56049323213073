import { useState } from 'react';
import { TranscriptFocusedCommentContext } from './context.comments';

type Props =
  ChildrenProps;

export const TranscriptFocusedCommentContainer = (props: Props) => {

  const [focusedComment, setFocusedComment] = useState<string>(null);

  return (
    <TranscriptFocusedCommentContext.Provider value={[focusedComment, setFocusedComment]}>
      {props.children}
    </TranscriptFocusedCommentContext.Provider>
  );
};
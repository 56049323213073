import { Fragment, useCallback, useState } from 'react';
import { RespondentAnswer, SurveyResponseItem } from '@/types';
import { EditingAnswer } from 'scenes/surveys';
import {
  EditableProjectSurveyResponseReviewItem,
  ProjectSurveyResponseReviewItem,
 } from './EditableQuestionItem';
import { EditableProjectSurveyProps } from './interfaces';

export const EditableProjectSurveyResponse = (props: EditableProjectSurveyProps) => {
  const [editing, setEditing] = useState(null);

  const toggleEditingOff = useCallback(() => {
    setEditing(null);
  }, [setEditing]);

  const toggleEditingOn = useCallback((ordinal: number) => () => {
    setEditing(ordinal);
  }, [setEditing]);

  const handleConfirm = useCallback((questionId: number) => (answer: RespondentAnswer) => {
    props.onSave({
      questionId,
      responseId: props.survey.id,
      answer,
    })
    .then(toggleEditingOff);
  }, [
    props.onSave,
    props.survey.id,
    toggleEditingOff,
  ]);

  const renderQuestion = (item: SurveyResponseItem) => {
    if (editing === item.question.ordinal) {
      return (
        <EditingAnswer
          item={item}
          onCancel={toggleEditingOff}
          onConfirm={handleConfirm(item.question.id)} />
      );
    } else if (!editing) {
      return (
        <EditableProjectSurveyResponseReviewItem
          onEdit={toggleEditingOn(item.question.ordinal)}
          item={item}>
          {props.renderQuestionButton && props.renderQuestionButton(item)}
        </EditableProjectSurveyResponseReviewItem>
      );
    } else {
      return (
        <ProjectSurveyResponseReviewItem
          item={item}>
          {props.renderQuestionButton && props.renderQuestionButton(item)}
        </ProjectSurveyResponseReviewItem>
      );
    }
  };

  return <>
    {
      props.survey.items.map(q => (
        <Fragment key={q.question.id}>
          {renderQuestion(q)}
        </Fragment>
      ))
    }
  </>;
};

export default EditableProjectSurveyResponse;
import { useEffect } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { path } from '@consts';
import { useCoordinator } from '@containers/Conference/hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';

type Props = {
  conferenceIdentifier: number;
  name: string;
};

export function ConferenceEntryAuthorized({ conferenceIdentifier, name }: Props) {
  const history = useHistory();
  const coordinator = useCoordinator();

  useEffect(() => {
    coordinator.negotiate({
      conferenceIdentifier,
      name,
    })
    .then(result => {
      if (result.success !== true) {
        console.error(result.reason);
        return;
      }

      setTimeout(() => {
        history.replace(generatePath(path.Conference.Join, { conferenceIdentifier }));
      }, 0);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ActivityIndicator show />;
}
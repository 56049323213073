import { Dispatch } from 'react';
import { createNamedContext } from '@utils';
import {
  Effects,
  FileUploadState,
} from './interfaces';

export type FilesUploadStateContextValue =
  [FileUploadState.State, Dispatch<FileUploadState.Actions>];

export type ExistingFilesMap = {
  [name: string]: number;
};
export type RenamedFilesMap = {
  [name: string]: string;
};

export type UploadFilesParams = {
  files: File[];
  ids?: ExistingFilesMap;
  names?: RenamedFilesMap;
  parentObjectId: number;
  workspaceId: number;
};
export type UploadFiles = (data: UploadFilesParams) => unknown;
export type AbortFileUpload = (fileUploadId: number) => unknown;
export type ToggleUploadDisplay = () => unknown;

export const AbortFileUploadContext = createNamedContext<AbortFileUpload>(undefined, 'abort-file-upload');
export const UploadFilesContext = createNamedContext<UploadFiles>(undefined, 'upload-files');
export const FileUploadEffectsContext = createNamedContext<Effects.FileUploadEffects>(undefined, 'file-upload-effects');
export const FileUploadsStateContext = createNamedContext<FilesUploadStateContextValue>(undefined, 'file-uploads-state');
export const ToggleUploadDisplayContext = createNamedContext<ToggleUploadDisplay>(undefined, 'toggle-upload-display');
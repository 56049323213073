import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FollowedTopicsContext } from '@containers/Topic/Context';
import * as enums from '@enums';
import * as selectors from '@store/selectors';
import { ChecklistItem } from 'components/OnboardingChecklist/interfaces';

const rateSet = (state: Store.State) => {
  return state.user.settings.hourlyRate &&
    state.user.settings.hourlyRate > 0;
};

const profileCompleted =  (state: Store.State ) => {
  return state.user.profile.status?.completed;
};

const payoutSetup = (state: Store.State ) => {
  return selectors.hasPayoutMethod(state);
};

const conditions = {
  [ChecklistItem.PayoutSetup]: payoutSetup,
  [ChecklistItem.CompleteProfile]: profileCompleted,
  [ChecklistItem.SetRate]: rateSet,
};

const selectIncompleteOnboardingSteps = (state: Store.State) => {
  return Object.entries(conditions).reduce((acc, [k, v]: Entry) => {
    return v(state)
      ? acc
      : acc.concat(k);
  }, [] as Key[]);
};

export const useIncompleteOnboardingSteps = () => {
  const items = useSelector(selectIncompleteOnboardingSteps);
  const ctx = useContext(FollowedTopicsContext);

  return useMemo(() => {
    return ctx.query.isLoading
      ? []
      : !ctx.topics.length
          ? [... new Set([...items, ChecklistItem.TopicFollowing])]
          : items;

  }, [
    items,
    ctx.query.isLoading,
    ctx.topics,
  ]);
};

type FromStore = typeof conditions;

type Key<T extends FromStore = FromStore, K extends keyof T = keyof T> = K;

type Val<K extends Key = Key> = FromStore[K];

type Entry = [Key, Val<Key>];
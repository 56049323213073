import { PaymentCardBrand } from '@/types';
import amex from 'static/images/payment/amex.png';
import diners from 'static/images/payment/diners.png';
import discover from 'static/images/payment/discover.png';
import jcb from 'static/images/payment/jcb.png';
import mastercard from 'static/images/payment/mastercard.png';
import visa from 'static/images/payment/visa.png';
import unknown from 'static/images/payment/unknown.png';

export function getLogoImage(card: PaymentCardBrand) {
  switch (card) {
    case 'American Express': return amex;
    case 'Diners Club': return diners;
    case 'Discover': return discover;
    case 'JCB': return jcb;
    case 'MasterCard': return mastercard;
    case 'Visa': return visa;
    case 'Unknown':
    default: return unknown;
  }
}
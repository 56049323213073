import { Cell } from 'react-table';
import { FileIcon } from 'components/icons';
import ClickContainer from './ResultClickContainer';
import { FilesTableItem } from './interfaces';
import styles from './style/Cell.Name.css';

export default function Name({ row: { original: data } }: Cell<FilesTableItem>) {
  return (
    <ClickContainer item={data}>
      <div className={styles.root}>
        <FileIcon
          className={styles.icon}
          extension={data.metadata.extension} />
        <div className={styles.details}>
          <div className={styles.name}>{data.name}</div>
        </div>
      </div>
    </ClickContainer>
  );
}

export { Name };
import { useMemo } from 'react';
import { SurveyQuestion } from '@/types/survey.question';
import { useSurvey } from './useSurvey';

export const useSegmentQuestions = (questionIdentifier: string) => {

  const survey = useSurvey();

  return useMemo(() => {
    return survey.questions.filter(q => hasConditionalOptions(q) || hasConditionalRows(q));

    function hasConditionalOptions(q: SurveyQuestion) {
      return q.options
        .some(o => o.conditions.some(c => c.question.identifier === questionIdentifier));
    }
    function hasConditionalRows(q: SurveyQuestion) {
      return q.matrixRows
        .some(r => r.conditions.some(c => c.question.identifier === questionIdentifier));
    }
  }, [
    questionIdentifier,
    survey.questions,
  ]);

};
import { useCallback, useMemo } from 'react';
import { ComputedSerie, Point } from '@nivo/line';
import { useRepeatSurveyResponsesState, useRepeatSurveyData } from '@containers/RepeatSurveyResponses/hooks';
import { RepeatSurvey as RS } from '@containers/RepeatSurveyResponses/interfaces';
import BaseLineChart from './BaseLineChart';
import PointsTooltip from './ConjointAnalysis.Chart.PointsTooltip';
import SeriesTooltip from './ConjointAnalysis.Chart.SeriesTooltip';
import { useRepeatConjointAnalysisChartData } from './hooks';

export const ConjointAnalysisLineChart = () => {
  const data = useRepeatConjointAnalysisChartData();
  const { projectIds } = useRepeatSurveyData();
  const { setQueryState } = useRepeatSurveyResponsesState<RS.QueryState.ConjointAnalysis>();

  const formatLeftAxisLabel = useCallback((value: string) => {
    return Number.isInteger(+value)
      ? value
      : '';
  }, []);

  const handleSerieClick = useCallback((series: ComputedSerie[]) => {
    setQueryState({
      attributeIds: series.map(m => +m.id),
    });
  }, [
    setQueryState,
  ]);

  const handlePointClick = useCallback((points: Point[]) => {
    const x = +points[0].data.x;
    setQueryState({
      attributeIds: points.map(m => +m.serieId),
      projectId: projectIds[x - 1],
    });
  }, [
    projectIds,
    setQueryState,
  ]);

  const maxYValue = useMemo(() => {
    const max = Math.max(...data.map(m => Math.max(...m.data.map(d => +d.y))));
    return Math.round(max + (max * 0.1 < 1 ? 1 : max * 0.1));
  }, [data]);

  const yValues = useMemo(() => [...Array(10).keys()].map(i => i * 10).filter(f => f <= maxYValue), [maxYValue]);

  return (
    <BaseLineChart
      data={data}
      axisLeft={{
        format: formatLeftAxisLabel,
        tickValues: yValues,
      }}
      yScale={{ type: 'linear', max: maxYValue, stacked: false, reverse: false }}
      onPointClick={handlePointClick}
      onSerieClick={handleSerieClick}
      PointsTooltip={PointsTooltip}
      SeriesTooltip={SeriesTooltip} />
  );
};

export default ConjointAnalysisLineChart;
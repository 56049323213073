import { useState, useEffect, useMemo, useCallback } from 'react';
import { useEditableSurveyContext, useSurveyBuilderState, useCanSaveSurvey, useHasUnsavedChanges } from '@containers/SurveyBuilder/hooks';
import { RouteLeavingGuard , RouteLeavingGuardState } from 'components/RouteLeavingGuard';
import { Alert } from 'components/Modal/Alert';
import { Button } from 'components/Button';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { SettingsTitle } from 'components/Settings';
import { EditableSurveyProps } from './interfaces';
import styles from './style.css';

export const EditableSurvey = ({
  helpText,
  renderContent,
  title,
}: EditableSurveyProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    deleteSurvey,
    fetchSurvey,
    saveSurvey,
    surveyId,
  } = useEditableSurveyContext();
  const [state] = useSurveyBuilderState();
  const canSave = useCanSaveSurvey();
  const hasUnsavedChanges = useHasUnsavedChanges();

  useEffect(() => {
    setLoading(true);

    fetchSurvey()
    .then(_ => setLoading(false));
  }, [fetchSurvey]);

  const showSave = useMemo(() => {
    return !loading &&
      (state.survey.questions.length || !surveyId);
  }, [
    loading,
    state.survey.questions.length,
    surveyId,
  ]);

  const handleSaveClicked = useCallback(() => {
    if (state.survey.questions.length) {
      saveSurvey();
    } else {
      deleteSurvey();
    }
  }, [
    deleteSurvey,
    saveSurvey,
    state.survey.questions.length,
  ]);

  const renderBody = useCallback(() => {
    if (loading) {
      return <ActivityIndicator show={true} />;
    }

    return renderContent();
  }, [
    loading,
    renderContent,
  ]);

  return (
    <>
      <div className={styles.root}>
        <SettingsTitle
          helpText={helpText}
          title={title} />
        {renderBody()}
        <div className={styles.footer}>
          {showSave &&
            <Button
              className={styles.save}
              disabled={!canSave}
              onClick={handleSaveClicked}
              variant="brick">
              Save
            </Button>}
        </div>
      </div>
      <RouteLeavingGuard block={hasUnsavedChanges()}>
        {(guard: RouteLeavingGuardState) => (
          <Alert
            confirmText="Discard"
            message="Are you sure you want to discard changes?"
            onClose={guard.closePrompt}
            onConfirm={guard.confirmNavigation}
            open={guard.open} />
        )}
      </RouteLeavingGuard>
    </>
  );
};

export default EditableSurvey;
import { Link } from 'react-router-dom';
import { useAskAnExpertData } from '@containers/AskAnExpert';
import { SidebarHeader, Divider } from '@presentation/Main';
import * as consts from '@consts';
import AskAnExpertItem from './Item';
import styles from './style/AskAnExpert.css';

type Props = {
  viewMoreLink?: string;
};

export const AskAnExpert = ({
  viewMoreLink = consts.pathname.SEARCH,
}: Props) => {

  const items = useAskAnExpertData();

  return (
    <div className={styles.root}>
      <SidebarHeader
        title="Ask an Expert" />
      <Divider />
      <div>
        {items.map(item => (
          <AskAnExpertItem key={item.id} item={item} />
        ))}
        <div className={styles.more}>
          <Link to={viewMoreLink}>
            View More...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AskAnExpert;
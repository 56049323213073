import { useState } from 'react';
import styles from '$website/screens/Main/style/Products.css';
import { cx } from '@utils';
import cardBackground from 'static/images/website/product-snapshot-background.png';
import Product from './Product';
import { DesktopCard } from './ProductCard';
import { ProductListProps } from './interfaces';

const ProductList = (props: ProductListProps) => {
  const [highlightedProduct, setHighlightedProduct] = useState<Product>(props.items[0].id);

  const renderItemList = () => {
    return props.items.map(item => {
      const className = cx(styles.productListItem, {
        [styles.activeProductListItem]: item.id === highlightedProduct,
      });

      return (
        <div
          key={item.id}
          className={className}
          onClick={() => setHighlightedProduct(item.id)}>
          <item.Icon className={styles.productListIcon} />
          <div className={styles.productListName}>
            {item.title}
          </div>
        </div>
      );
    });
  };

  const renderCards = () => {
    return (
      <div className={styles.productListCardContainer}>
        <img
          className={styles.productListBackground}
          src={cardBackground} />
        <div
          className={styles.productListCard}>
          {
            props.items.map(item => {
              return (
                <DesktopCard
                  key={item.id}
                  visible={item.id === highlightedProduct}
                  item={item} />
              );
            })
          }
        </div>
      </div>
    );
  };

  return (
    <div className={styles.productListRoot}>
      <div className={styles.productListWrap}>
        {renderCards()}
        <div className={styles.productListItems}>
          {renderItemList()}
        </div>
      </div>
    </div>
  );
};

export default ProductList;
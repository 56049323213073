import { Link, LinkProps } from 'react-router-dom';
import { path } from '@consts';
import { usePersistentProjectInvite, useProjectInviteWhitelabelState } from '@containers/ProjectLinkInvitation/hooks';
import { cx } from '@utils';
import * as Whitelabel from 'components/Whitelabel';
import { useNavbarLogo } from 'components/Whitelabel/hooks';
import logo from 'static/images/vancery-landscape.svg?url';
import styles from './style/Navbar.css';

type Props = Partial<Pick<LinkProps, 'to'>>;

export const Logo = (props: Props) => {
  const whitelabel = useProjectInviteWhitelabelState();
  const urlstate = usePersistentProjectInvite();
  const state = urlstate.get();
  const logodata = useNavbarLogo(state?.data?.theming?.logos as Store.Group.Branding['logos']);

  if (whitelabel.enabled && logodata?.url) {
    return (
      <Link to={props.to}>
        <Whitelabel.NavbarLogo
          classes={{
            root: cx(styles.logo, styles.theme),
            landscape: styles.landscape,
          }}
          orientation={logodata.orientation}
          url={logodata.url} />
      </Link>
    );
  }

  return (
    <Link
      className={styles.logo}
      to={props.to}>
      <img
        className={styles.logo}
        src={logo} />
    </Link>
  );
};

const defaultProps = {
  to: path.Website.Root,
};

Logo.defaultProps = defaultProps;
Logo.displayName = 'Nav.Logo';
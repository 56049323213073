import http from '@services/http';
import { Payment } from '@services/api/interfaces';

export const newMethod = (
  data: Payment.User.NewMethod.Request,
): Promise<Payment.User.NewMethod.Response> => {
  return http.post(`/payment/user/${data.userId}/payment-method`, data);
};

export const getMethodDetails = (
  data: Payment.User.GetMethodDetails.Request,
): Promise<Payment.User.GetMethodDetails.Response> => {
  return http.get(`/payment/user/${data.userId}/method-details`);
};

export const verifyBankAccount = (
  data: Payment.User.VerifyBankAccount.Request,
): Promise<Payment.User.VerifyBankAccount.Response> => {
  return http.post(`/payment/user/${data.userId}/verify-bank-account`, data);
};
import { forwardRef } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './style/Menu.css';

type Props = {
  onClick: () => void;
  disabled?: boolean;
} & ChildrenProps;

export const ActionButton = forwardRef<HTMLLIElement, Props>(({ onClick, children, disabled }, ref) => {
  return (
    <MenuItem
      ref={ref}
      className={styles.menuItem}
      onClick={onClick}
      disabled={disabled}>
      {children}
    </MenuItem>
  );
});
import { useMemo } from 'react';
import { useUserRoles } from './useUserRoles';
import * as utils from '@utils';

export const useHasTransientRole = () => {
  const roles = useUserRoles();

  const hasTransientRole = useMemo(() => {
    return utils.hasTransientRole({ roles });
  }, [roles]);

  return hasTransientRole;
};

export default useHasTransientRole;
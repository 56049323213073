import { useCallback, useState } from 'react';
import Video, { CreateLocalTrackOptions, LocalVideoTrack, LocalAudioTrack } from 'twilio-video';
import { generateCameraTrackName, generateMicrophoneTrackName } from 'components/Conference.Video/constants';

export function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();

  const getLocalAudioTrack = useCallback((): Promise<LocalAudioTrack> => {
    const options: CreateLocalTrackOptions = {
      name: generateMicrophoneTrackName(),
    };
    return Video.createLocalAudioTrack(options)
      .then(newTrack => {
        setAudioTrack(newTrack);
        return newTrack;
      });
  }, []);

  const getLocalVideoTrack = useCallback(async (): Promise<LocalVideoTrack> => {
    const options: CreateLocalTrackOptions = {
      frameRate: 24,
      height: 720,
      width: 1280,
      name: generateCameraTrackName(),
      facingMode: 'user',
    };
    return Video.createLocalVideoTrack(options)
      .then(newTrack => {
        setVideoTrack(newTrack);
        return newTrack;
      });
  }, []);

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const localTracks = [audioTrack, videoTrack].filter(t => t !== undefined);

  return {
    localTracks,
    getLocalAudioTrack,
    getLocalVideoTrack,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
  };
}
import { useCallback, useEffect, useRef, useState } from 'react';
import { AlertTriangle, Check } from 'react-feather';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { cx } from '@utils';
import { Textarea, TextareaProps } from 'components/Textarea';
import { LongTextResponseInputProps } from './interfaces';
import styles from './style/LongTextResponseInput.css';

const LongTextResponseInput = (props: LongTextResponseInputProps) => {
  const [showWarning, setShowWarning] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const { theme } = useSurveyThemingPalette();

  useEffect(() => {
    if (showThanks) {
      timeoutRef.current = setTimeout(() => {
        setShowThanks(false);
      }, 5000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [showThanks]);

  const onBlur = () => {
    if (showWarning) {
      if (props.isLongEnough) {
        setShowThanks(true);
        setShowWarning(false);
      }
    } else {
      if (!props.isLongEnough) {
        setShowThanks(false);
        setShowWarning(true);
      }
    }
  };

  const renderWarning = () => {
    return (
      <div className={styles.warning}>
        <AlertTriangle size={35} />
        <div className={styles.warningText}>
          We recommend providing a bit more detail in your response.
        </div>
      </div>
    );
  };

  const renderThanks = () => {
    return (
      <div className={styles.thanks}>
        <Check size={35} />
        <div className={styles.thanksText}>Thank you for the additional detail!</div>
      </div>
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.updateAnswer(e.target.value);
  };

  const inputprops = {
    className: cx(styles.ta, { [styles.taWarning]: showWarning }),
    invalid: theme ? showWarning : undefined,
    onBlur,
    onChange: handleChange,
    placeholder: 'Provide a few sentences in your response.',
    value: props.value,
  };

  return (
    <div className={props.className}>
      {theme
        ? <ThemedTextarea {...inputprops} />
        : <Textarea {...inputprops} />}
      <div className={styles.block}>
        {showWarning && renderWarning()}
        {showThanks && renderThanks()}
      </div>
    </div>
  );
};

export default LongTextResponseInput;

type Props = {
  invalid: boolean;
} & TextareaProps;

const ThemedTextarea = (props: Props) => {
  const [focused, setFocused] = useState(false);
  const { palette } = useSurveyThemingPalette();

  const onBlur = () => setFocused(false);
  const onFocus = () => setFocused(true);

  const handleBlur = useCallback((e: React.FocusEvent<HTMLTextAreaElement>) => {
    onBlur();
    props.onBlur?.(e);
  }, [props]);

  const handleFocus = useCallback((e: React.FocusEvent<HTMLTextAreaElement>) => {
    onFocus();
    props.onFocus?.(e);
  }, [props]);

  const style = focused && !props.invalid ? {
    border: `2px solid ${palette.primary.main}`,
  } : {};

  return (
    <Textarea
      {...props}
      onBlur={handleBlur}
      onFocus={handleFocus}
      style={style} />
  );
};
import { useSelector } from 'react-redux';
import { useWorkspaceFileState } from '@containers/WorkspaceFilePreview/hooks/useWorkspaceFileState';
import * as selectors from '@store/selectors';
import { WorkspaceBreadcrumbPath } from 'components/Workspace/ObjectPath';
import FilePreviewCard from './FilePreviewCard';
import styles from './style/FilePreview.css';

export const FilePreview = () => {
  const state = useWorkspaceFileState();
  const path = useSelector(selectors.selectObjectPath(state.file.objectId));

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <WorkspaceBreadcrumbPath
            items={path} />
        </div>
        <FilePreviewCard />
      </div>
    </div>
  );
};

export default FilePreview;
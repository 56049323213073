import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as api from '@api';
import { formatPossessive } from '@utils';
import { ComplianceReviewItem } from '@/types';
import Toast from 'components/Toast';
import { useAggregateReviewRefetch } from './useAggregateReviewRefetch';

const mapState = (state: Store.State) => state.group;

type DispatchChangeParams = {
  analyst: Descriptor;
  consultant: Descriptor;
  id: number;
};

const useAggregateReviewMessageActions = () => {
  const group = useSelector(mapState);
  const refetch = useAggregateReviewRefetch();

  const approve = useCallback((data: DispatchChangeParams) => {

    return api.groups.compliance.setMessageApprovalApproved({
      groupId: group.id,
      messageId: data.id,
    })
    .then(_ => {
      notifier.approve({
        analyst: data.analyst,
        consultant: data.consultant,
      });

      refetch();
    });

  }, [
    group.id,
    refetch,
  ]);

  const reject = useCallback((data: DispatchChangeParams) => {

    return api.groups.compliance.setMessageApprovalRejected({
      groupId: group.id,
      messageId: data.id,
    })
    .then(_ => {
      notifier.reject({
        analyst: data.analyst,
        consultant: data.consultant,
      });

      refetch();
    });

  }, [
    group.id,
    refetch,
  ]);

  return {
    approve,
    reject,
  };
};

type NotifierDispatchParams = {
  analyst:    Pick<ComplianceReviewItem<'message'>['analyst'], 'name'>;
  consultant: Pick<ComplianceReviewItem<'message'>['consultant'], 'name'>;
};

type NotifierDispatch = (params: NotifierDispatchParams) => void;

type Notifier = {
  approve: NotifierDispatch;
  reject:  NotifierDispatch;
};

const notifier: Notifier = {
  approve(params) {
    Toast.alert({
      title: 'Message Approved',
      body: `${formatPossessive(params.consultant.name)} message has been shared with ${params.analyst.name}.`,
    });
  },
  reject(params) {
    Toast.error({
      title: 'Message Rejected',
      body: `${formatPossessive(params.consultant.name)} message has been rejected. It will not be shared with ${params.analyst.name}.`,
    });
  },
};

export { useAggregateReviewMessageActions };
export default useAggregateReviewMessageActions;
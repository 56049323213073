import styles from './style.css';

const Tags = ({
  onClick,
  vics,
  items,
  isDeletable,
}) => {
  const phantomTag = (items.length % 3 !== 0) &&
    <div className={styles.item}>
      <button className={styles.phantom} />
    </div>;

  return (
      <>
        {items.map(x =>
          <div
            className={styles.item}
            key={x.id}>
            <button
              className={vics[x.id] ? styles.selected : styles.tag}
              onClick={() => onClick(x)}>
              <div className={styles.name}>{x.originalName || x.name}</div>
              {isDeletable ?
              <div className={styles.xContainer}>
                <div className={styles.x}>✕</div>
              </div> : null }
            </button>
          </div>)}
        {phantomTag}
      </>
  );
};

export default Tags;
import { useMemo } from 'react';
import { Cell, Column, useTable, useFlexLayout } from 'react-table';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ComplianceAggregateReviewContainer, useAggregateReviewState } from '@containers';
import { GroupTable } from '@presentation/GroupTable';
import { GroupCardHeader, Card, CardContent, Divider } from '@presentation/Main';
import { Pagination } from './Pagination';
import { ReviewFilter } from './ReviewFilter';
import { TableEmpty } from './TableEmpty';
import {
  ApprovalState,
  ConsultantLink,
  ProjectLink,
  ReviewLink,
} from './TableColumn';
import { ComplianceReviewItem } from '@/types';
import { AnimatedLoader } from 'components/ActivityIndicator';
import styles from './style/ComplianceReview.css';

type Props = unknown;

type ComplianceReviewCell = Cell<ComplianceReviewItem>;

function formatAnalystName(value: string) {
  if (!value) return '';
  const split = value.split(' ');
  return split.reduce((acc, x, i) => {
    const v = i === split.length - 1
      ? x[0] ? x[0].toLocaleUpperCase() + '.' : ''
      : x;
    return `${acc} ${v}`;
  }, '');
}

const ComplianceReviewPanel = (props: Props) => {
  const state = useAggregateReviewState();

  const isDesktop = useMediaQuery('(min-width:900px)');

  const columns = useMemo<Column<ComplianceReviewItem>[]>(() => [{
    Header: 'Interaction',
    Cell: ({ row: { original: data } }: ComplianceReviewCell) => <ReviewLink item={data} />,
    accessor: 'type',
    id: 'review',
    width: 75,
  },
  {
    Header: 'Member',
    Cell: ({ row: { original: data } }: ComplianceReviewCell) => <ConsultantLink item={data} />,
    accessor: 'consultant.name',
    id: 'consultant',
    maxWidth: 100,
  },
  isDesktop ? {
    Header: 'Project',
    Cell: ({ row: { original: data } }: ComplianceReviewCell) => <ProjectLink item={data} />,
    accessor: 'project',
    id: 'project',
  } : undefined,
  isDesktop ? {
    Header: 'Owner',
    Cell: ({ row: { original: data } }: ComplianceReviewCell) => formatAnalystName(data.analyst?.name),
    accessor: 'analyst.name',
    id: 'analyst',
    width: 125,
  } : undefined,
  {
    Cell: ({ row: { original: data } }: ComplianceReviewCell) => <ApprovalState item={data} />,
    id: 'action',
    width: 100,
  }].filter(Boolean), [isDesktop]);

  const tableInstance = useTable<ComplianceReviewItem>(
    {
      data: state.value?.items ?? [],
      columns,
    },
    useFlexLayout,
  );

  const tableClasses = {
    body: styles.body,
    header: styles.header,
    td: styles.td,
  };

  const hasData = !state.loading && !!state.value?.items?.length;
  const showEmpty = !state.loading && !state.value?.items?.length;

  return (
    <Card className={styles.card}>

      <GroupCardHeader>
        <div>Compliance Review</div>
        <ReviewFilter />
      </GroupCardHeader>
      <Divider />

      <CardContent
        fluid
        className={styles.content}>
        {showEmpty && <TableEmpty />}

        {state.loading &&
          <div className={styles.loader}>
            <AnimatedLoader />
          </div>}

        {hasData &&
          <>
            <GroupTable
              classes={tableClasses}
              instance={tableInstance} />
            <div className={styles.footer}>
              <Pagination />
            </div>
          </>}
      </CardContent>
    </Card>
  );
};

const ReviewPanel = (props: Props) => {
  return (
    <ComplianceAggregateReviewContainer>
      <ComplianceReviewPanel />
    </ComplianceAggregateReviewContainer>
  );
};

export { ReviewPanel };
export default ReviewPanel;
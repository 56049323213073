import { useCallback } from 'react';
import * as api from '@api';
import { useDownloader } from '@utils/hooks';
import { useSelectObject, useSelectFile } from '@containers/Store';
import Toast from 'components/Toast';
import { DownloadFileContext } from './Context';

type Props =
  IWorkspaceFileId &
  ChildrenProps;

export const DownloadFileContainer = (props: Props) => {

  const file = useSelectFile(props.fileId);
  const object = useSelectObject(file.objectId);
  const download = useDownloader();

  const handleDownload = useCallback(() => {
    return api.workspaces.file.downloadFile({
      fileId: props.fileId,
      workspaceId: object.workspaceId,
    }).then(({ url }) => {
      download({
        url,
        filename: file.name,
      });
    }).catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });
  }, [
    download,
    file.name,
    object.workspaceId,
    props.fileId,
  ]);

  return (
    <DownloadFileContext.Provider value={handleDownload}>
      {props.children}
    </DownloadFileContext.Provider>
  );
};

export default DownloadFileContainer;
import { useCallback } from 'react';
import { useDeleteFolder } from './hooks';
import { useSelectObject, useSelectFolder } from '@containers/Store';
import { DeleteFolderContext } from './Context';

type Props =
IWorkspaceFolderId &
  ChildrenProps;

export const DeleteFolderContainer = (props: Props) => {

  const folder = useSelectFolder(props.folderId);
  const object = useSelectObject(folder.objectId);
  const deleteFolder = useDeleteFolder();

  const handleDelete = useCallback(() => {
    return deleteFolder({
      folderId: props.folderId,
      objectId: object.id,
      workspaceId: object.workspaceId,
    });
  }, [
    deleteFolder,
    props.folderId,
    object.id,
    object.workspaceId,
  ]);

  return (
    <DeleteFolderContext.Provider value={handleDelete}>
      {props.children}
    </DeleteFolderContext.Provider>
  );
};

export default DeleteFolderContainer;
import { useCallback } from 'react';
import { SurveyActionType } from '@enums/Survey';
import { cx } from '@utils';
import { useSurveyBuilderState } from '@containers/SurveyBuilder';
import { useBaseLogicContext } from '@containers/SurveyBuilder.Logic/Context';
import { useQuestionLogicBuilderState } from '@containers/SurveyBuilder.Logic/hooks';
import {
  ActionTypeDropdown,
  QuestionOrdinalDropdown,
  SectionDropdown,
} from '@presentation/SurveyBuilder';
import styles from './style/BaseLogicBuilder.css';

export const QuestionBaseLogicBuilder = () => {

  const [state] = useSurveyBuilderState();
  const {
    item,
    updateActionIdentifier,
    updateActionType,
  } = useBaseLogicContext();

  const {
    baseActionTypes,
    includeSectionOptions,
    skipToQuestionOptions,
    skipToSectionOptions,
  } = useQuestionLogicBuilderState();

  const findSection = useCallback((identifier: string) => {
    return state.survey.sections.find(f => f.identifier === identifier);
  }, [state.survey.sections]);

  const findQuestion = useCallback((identifier: string) => {
    return state.survey.questions.find(f => f.base.identifier === identifier);
  }, [state.survey.questions]);

  const renderOrdinalDropdown = useCallback(() => {
    if (item.action.type === SurveyActionType.SkipToQuestion) {
      const section = findSection(item.action.identifier);
      return (
        <QuestionOrdinalDropdown
          items={skipToQuestionOptions}
          value={section.ordinal}
          onSelect={updateActionIdentifier} />
      );
    } else if (item.action.type === SurveyActionType.SkipToSection) {
      const section = findSection(item.action.identifier);
      return (
        <SectionDropdown
          items={skipToSectionOptions}
          value={section.ordinal}
          onSelect={updateActionIdentifier} />
      );
    } else if (item.action.type === SurveyActionType.IncludeSection) {
      const question = findQuestion(item.action.identifier);
      return (
        <SectionDropdown
          items={includeSectionOptions}
          value={question.ordinal}
          onSelect={updateActionIdentifier} />
      );
    } else {
      return null;
    }
  }, [
    findQuestion,
    findSection,
    item,
    includeSectionOptions,
    skipToQuestionOptions,
    skipToSectionOptions,
    updateActionIdentifier,
  ]);

  return (
    <div className={styles.root}>
      <div className={styles.headerRow}>
        <div className={cx(styles.header, styles.action)}>Action</div>
        <div className={styles.ordinal} />
      </div>

      <div className={styles.row}>
        <div className={styles.action}>
          <ActionTypeDropdown
            items={baseActionTypes}
            onSelect={updateActionType}
            value={item.action.type} />
        </div>
        <div className={styles.ordinal}>
          {renderOrdinalDropdown()}
        </div>
      </div>
    </div>
  );
};

export default QuestionBaseLogicBuilder;
import { WorkspaceObjectType } from '@enums';
import { WorkspaceObject, WorkspaceObjectTreeItem } from '@/types';

export const selectObject = (objectId: number) => (state: Store.State) => state.objects.values[objectId];
export const selectObjectValues = (state: Store.State) => state.objects.values;
export const selectObjectIds = (state: Store.State) => state.objects.ids;
export const selectGetObject = (state: Store.State) => (objectId: number) => state.objects.values[objectId];

export const selectObjectTree = (objectId: number, objectTypes?: WorkspaceObjectType[]) => (state: Store.State): WorkspaceObjectTreeItem => {
  return {
    // capabilities: state.objects.capabilities[objectId],
    children: Object.values(state.objects.values)
      .filter(f => f.parentObjectId === objectId &&
        (!objectTypes || objectTypes.includes(f.typeId)))
      .map(m => selectObjectTree(m.id, objectTypes)(state)),
    object: state.objects.values[objectId],
  };
};

export const selectObjectHierarchy = (objectId: number) => (state: Store.State) => {
  const hierarchy = [] as WorkspaceObject[];

  let object = state.objects.values[objectId];
  while (object) {
    hierarchy.push(object);
    object = state.objects.values[object.parentObjectId];
  }

  return hierarchy.reverse();
};

export const selectObjectPath = (objectId: number) => (state: Store.State) => {
  const hierarchy = selectObjectHierarchy(objectId)(state);

  return hierarchy.slice(0, hierarchy.length - 1);
};

export const selectObjectPathString = (objectId: number) => (state: Store.State) => {
  const path = selectObjectPath(objectId)(state);

  return path.reduce((acc, object) => {
    return [...acc, object.name];
  }, [] as string[]);
};

export const selectObjectChildren = (objectId: number) => (state: Store.State) =>  {
  return state.objects.ids
    .map(id => state.objects.values[id])
    .filter(f => f.parentObjectId === objectId);
};
import { SurveyOptionType } from '@enums';
import { SurveyQuestionOption } from '@/types';

export const NotApplicableColor = 'var(--gray-l)';

export function getSegmentColor({ colors = MatrixColors, ...data }: {
  colors?: MatrixColors;
  option: SurveyQuestionOption;
  options: SurveyQuestionOption[];
}) {
  if (data.option.type === SurveyOptionType.NotApplicable) {
    return NotApplicableColor;
  }

  const filtered = data.options
    .filter(f => f.type !== SurveyOptionType.NotApplicable);

  const keyIndex = filtered
    .findIndex(f => f.base.id === data.option.base.id);

  return colors[filtered.length][keyIndex];
}

type MatrixColors = {
  [ordinal: number]: string[];
};

export const MatrixColors: MatrixColors = {
  1: [
    '#467BF1',
  ],
  2: [
    '#467BF1',
    '#F25753',
  ],
  3: [
    '#467BF1',
    '#F1C337',
    '#F25753',
  ],
  4: [
    '#467BF1',
    '#7BC350',
    '#F1C337',
    '#F25753',
  ],
  5: [
    '#467BF1',
    '#7BC350',
    '#F1C337',
    '#F3914C',
    '#F25753',
  ],
  6: [
    '#467BF1',
    '#31BF90',
    '#7BC350',
    '#F1C337',
    '#F3914C',
    '#F25753',
  ],
  7: [
    '#467BF1',
    '#14AFB0',
    '#7BC350',
    '#F1C337',
    '#F1AF44',
    '#F3914C',
    '#F25753',
  ],
  8: [
    '#467BF1',
    '#14AFB0',
    '#31BF90',
    '#7BC350',
    '#F1C337',
    '#F1AF44',
    '#F3914C',
    '#F25753',
  ],
  9: [
    '#467BF1',
    '#14AFB0',
    '#31BF90',
    '#7BC350',
    '#F1C337',
    '#F1AF44',
    '#F3914C',
    '#F7784D',
    '#F25753',
  ],
  10: [
    '#467BF1',
    '#3191DC',
    '#14AFB0',
    '#31BF90',
    '#7BC350',
    '#F1C337',
    '#F1AF44',
    '#F3914C',
    '#F7784D',
    '#F25753',
  ],
  11: [
    '#467BF1',
    '#3191DC',
    '#14AFB0',
    '#31BF90',
    '#7BC350',
    '#F1C337',
    '#F1AF44',
    '#F3914C',
    '#F7784D',
    '#F25753',
    '#EE4A38',
  ],
  12: [
    '#467BF1',
    '#3191DC',
    '#14AFB0',
    '#31BF90',
    '#7BC350',
    '#C4D714',
    '#F1C337',
    '#F1AF44',
    '#F3914C',
    '#F7784D',
    '#F25753',
    '#EE4A38',
  ],
  13: [
    '#467BF1',
    '#3191DC',
    '#14AFB0',
    '#31BF90',
    '#7BC350',
    '#C4D714',
    '#F1C337',
    '#F1AF44',
    '#F3914C',
    '#F7784D',
    '#F25753',
    '#F85D48',
    '#EE4A38',
  ],
};
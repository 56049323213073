import { QueryParamConfig, StringParam, useQueryParams, encodeString, decodeString, withDefault } from 'use-query-params';
import { pathname } from '@consts';
import { BackLink } from '@presentation/Back';
import styles from './style.css';

type Reason = 'ended' | 'left' | 'removed-waiting-room' | 'removed-room';

const ReasonParam: QueryParamConfig<Reason, Reason> = {
  encode: (reason: Reason) => encodeString(reason),
  decode: (reason: string) => decodeString(reason) as Reason,
};

export function ConferenceCompleted() {
  const [params] = useQueryParams({
    title: withDefault(StringParam, 'Conference'),
    reason: withDefault(ReasonParam, 'ended'),
  });

  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <div className={styles.title}>{params.title} Call</div>
        <div className={styles.description}>{getDisplayReason(params.reason)}</div>
        <div className={styles.back}>
          <BackLink to={`${pathname.Home}`} />
        </div>
      </div>
    </div>
  );
}

function getDisplayReason(reason: Reason) {
  switch (reason) {
    case 'ended': return `The conference has been ended by the Host!`;
    case 'left': return `You have left the conference.`;
    case 'removed-waiting-room': return `You were not allowed entry into conference by Host.`;
    case 'removed-room': return `You were removed from the conference by the Host!`;
  }
}
import { useCallback, useState } from 'react';
import { TranscriptCommentPositionsContext } from './context.comments';
import { Transcript } from './interfaces';

type Props =
  ChildrenProps;

export const TranscriptCommentPositionsContainer = (props: Props) => {

  const [positions, setPositions] = useState<Transcript.Comments.PositionMap>({});

  const setCommentPosition: Transcript.Comments.SetPosition = useCallback(identifier => position => {
    setPositions(p => ({
      ...p,
      [identifier]: position,
    }));
  }, []);

  return (
    <TranscriptCommentPositionsContext.Provider value={[positions, setCommentPosition]}>
      {props.children}
    </TranscriptCommentPositionsContext.Provider>
  );
};
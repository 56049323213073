import mixpanel from 'mixpanel-browser';
import { Messages } from './interfaces';

export function home() {
  mixpanel.track('messages:home');
}

export function view({ user }: Messages.ChannelView) {
  mixpanel.track('messages:channel:view', {
    id: user.id,
    name: user.name,
  });
}
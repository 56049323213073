import { useDispatch } from 'react-redux';
import * as api from '@api';
import * as actions from '@actions';
import { useSelectChatMessages } from '@containers';
import Standard from './StandardMessage';
import Paid from './PaidMessage';
import { MessageContainerProps } from './interfaces';
import styles from './style/MessageContainer.css';

type Props =
    MessageContainerProps;

function MessageContainer(props: Props) {
  const dispatch = useDispatch();
  const conversationMessages = useSelectChatMessages(props.item.data.conversationSid);

  const userId = +props.item.data.userId;
  const sender = props.members[userId];
  const recipient = props.members[props.members.ids.find(id => +id !== sender.userId)];

  const handleAccept = (data: Pick<Props, 'item'>) => {
    const { item } = data;

    return api.messages.accept({
      userId: item.data.userId,
      messageId: item.data.attributes.messageId,
    })
    .then(result => {
      const updateActions = [
        result.connection ? actions.chatConnectionAdded(result.connection) : null,
        actions.platformMessageRemoved({
          conversationSid: item.data.conversationSid,
          messageId: item.data.attributes.messageId,
        }),
      ].filter(Boolean);

      dispatch(actions.batchActions(updateActions));
    });
  };

  const handleReject = (data: Pick<Props, 'item'>) => {
    const { item } = data;

    return api.messages.reject({
      userId: item.data.userId,
      messageId: item.data.attributes.messageId,
    })
    .then(() => {
      dispatch(
        actions.platformMessageRemoved({
          conversationSid: item.data.conversationSid,
          messageId: item.data.attributes.messageId,
        })
      );
      if (!conversationMessages.length) {
        props.onClose?.();
      }
    });
  };

  const Item = props.item.data.attributes.paid
    ? <Paid
        item={props.item}
        sender={sender}
        recipient={recipient}
        onAccept={handleAccept}
        onReject={handleReject} />
    : <Standard
        item={props.item}
        sender={sender}
        recipient={recipient}
        onAccept={handleAccept}
        onReject={handleReject} />;

  return (
    <div
      className={styles.root}
      ref={props.containerRef}>
      <div className={styles.wrap}>
        {props.children}
        {Item}
      </div>
    </div>
  );
}

export default MessageContainer;
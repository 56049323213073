import { pathname } from '@consts';
import { RouteAuth } from '@enums';
import { StateRouter } from '@routes/Routers';
import * as Routing from '@routes/interfaces';
import * as enums from '@enums';
import { AnalystMain, ComplianceMainContainer } from '@screens/GroupMain';
import * as Member from '@screens/Main.Member';
import { TransientMain } from '@screens/TransientMain';
import * as utils from '@utils';

const rootConfig = {
  exact: true,
  path: [pathname.Home, '/'],
};

const consultant: Routing.Route[]  = [
  {
    ...rootConfig,
    component: Member.Main,
    roles: [
      enums.Role.Expert,
    ],
  },
  {
    exact: true,
    component: Member.Main,
    path: `${pathname.HOME}/new`,
    roles: [
      enums.Role.Expert,
    ],
  },
];

const compliance: Routing.Route[]  = [
  {
    ...rootConfig,
    component: ComplianceMainContainer,
    roles: [
      enums.Role.FirmCompliance,
    ],
  },
];

const analyst: Routing.Route[] = [
  {
    ...rootConfig,
    component: AnalystMain,
    roles: [
      enums.Role.FirmAnalyst,
    ],
  },
];

const transient: Routing.Route[] = [
  {
    ...rootConfig,
    component: TransientMain,
    roles: [
      enums.Role.FirmTransient,
    ],
  },
];

const validator = (state: Store.State) => {
  if (utils.hasTransientRole(state.user)) return transient;
  else if (utils.hasComplianceRole(state.user)) return compliance;
  else if (utils.hasClientRole(state.user)) return analyst;
  else if (utils.hasConsultantRole(state.user)) return consultant;

  return [];
};

export const router = StateRouter(validator, {
  auth: RouteAuth.Authenticated,
  path: pathname.Home,
});
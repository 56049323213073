import extend from '@enums/extend';

export enum Role {
  Expert         = 1,
  FirmCompliance = 2,
  FirmAnalyst    = 3,
  InternalAdmin  = 4,
  FirmTransient  = 5,
  Recruiter      = 6,
}

const map = new Map([
  [Role.Expert,         { name: 'expert' }],
  [Role.FirmCompliance, { name: 'firm-compliance' }],
  [Role.FirmAnalyst,    { name: 'firm-analyst' }],
  [Role.InternalAdmin,  { name: 'internal-admin' }],
  [Role.FirmTransient,  { name: 'firm-transient' }],
  [Role.Recruiter,      { name: 'recruiter' }],
]);

export default {
  Role: extend(map),
};